import { MERCHANT_DEVICES } from './actions';
import {
  successResponse,
  requestState,
} from '../../../middleware/apiResolve/reduxUtils';

const initialState = {
  meta: {},
  items: [],
  isLoading: false,
};

const actionHandlers = new Map([
  [requestState('get', MERCHANT_DEVICES), handleLoading],
  [requestState('delete', MERCHANT_DEVICES), handleLoading],
  [successResponse('get', MERCHANT_DEVICES), handleDevices],
]);

export default function ClipDevicesReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleLoading(state) {
  return {
    ...state,
    isLoading: true,
  };
}

function handleDevices(state, action) {
  const {
    data: { items, meta },
  } = action.payload;
  return {
    ...state,
    items,
    meta,
    isLoading: false,
  };
}
