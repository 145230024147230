export const styles = (theme) => {
  const rootStyles = {
    container: {
      minHeight: `calc(100vh - ${theme.spacing(12.25)}px)`,
      maxWidth: theme.spacing(39),
      margin: `${theme.spacing(5.25)}px ${theme.spacing(3)}px
      ${theme.spacing(7)}px ${theme.spacing(3)}px`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    accountIssuedContainer: {
      top: 0,
      left: 0,
      display: 'flex',
      width: '100%',
      height: '3.5rem',
      overflow: 'auto',
      position: 'fixed',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      flexDirection: 'row',
    },
    cacaoLogo: {
      height: theme.spacing(1.5),
      margin: `0 ${theme.spacing(0.75)}px`,
      marginTop: `${theme.spacing(0.45)}px`,
    },
    cacaoTooltip: {
      padding: 0,
      marginTop: `${theme.spacing(0.75)}px`,
      marginRight: 'auto',
      position: 'fixed',
    },
    serviceName: {
      marginRight: '0.45em',
    },
    helpIcon: {
      height: theme.spacing(2),
      marginTop: '-5px',
      marginLeft: '18px',
    },
    serviceNameContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  };

  const cardStyles = {
    card: {
      width: '100%',
      margin: 'auto',
      maxWidth: theme.spacing(39),
      height: theme.spacing(23.5),
      borderRadius: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
      boxShadow: `${theme.spacing(0.5)}px ${theme.spacing(
        0.5,
      )}px ${theme.spacing(1)}px ${
        theme.spacing(0.5) + 1
      }px rgba(0, 0, 0, 0.1)`,
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 1fr',
    },
    cardChip: {
      alignSelf: 'center',
      marginLeft: theme.spacing(3),
      '& > img': {
        width: theme.spacing(5),
        height: theme.spacing(4),
      },
    },
    cardAmount: {
      alignSelf: 'center',
      marginLeft: theme.spacing(1),
    },
    cardIcons: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      margin: theme.spacing(3),
      marginLeft: theme.spacing(0),
      '& > img': {
        width: theme.spacing(6),
      },
    },
    cardActions: {
      marginBottom: theme.spacing(2),
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
    cardActionsFullWidth: {
      marginBottom: theme.spacing(2),
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr',
    },
    buttonContainer: {
      textAlign: 'center',
      width: '100%',
    },
    actionButton: {
      width: `calc(100% - ${theme.spacing(0.5)}px)`,
      height: theme.spacing(6),
      padding: 0,
      marginBottom: theme.spacing.unit,
      backgroundColor: `${theme.palette.common.white}`,
      border: `${theme.spacing(0.125)}px solid ${theme.palette.indigo.l90}`,
      boxShadow: `0px ${theme.spacing(0.25)}px ${theme.spacing(1)}px
      -${theme.spacing(0.25)}px rgba(102, 115, 123, 0.25)`,
      borderRadius: theme.spacing(1.5),
      display: 'flex',
      justifyContent: 'center',
    },
    actionButtonIcon: {
      margin: 'auto 0',
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    actionButtonText: {
      margin: 'auto 0',
      marginLeft: theme.spacing(1),
    },
  };

  const tooltipStyles = {
    tooltipPaper: {
      borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0px 0px!important`,
    },
    tooltipContainer: {
      width: '100%',
      height: 'auto',
      alignSelf: 'center',
      backgroundColor: theme.palette.white,
      overflow: 'auto',
      boxSizing: 'border-box',
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
      display: 'flex',
      flexFlow: 'column',
    },
    tooltipTitle: {
      marginBottom: theme.spacing(3),
    },
    tooltipButtonContainer: {
      alignSelf: 'flex-end',
      marginTop: theme.spacing(3),
      '& > button': {
        fontSize: theme.spacing(2),
      },
    },
  };

  return { ...rootStyles, ...cardStyles, ...tooltipStyles };
};
