export default {
  phoneLabel: 'Número celular (10 dígitos)',
  phoneLabelV4: 'Número de teléfono a 10 dígitos',
  extraPhone: 'Ingresa un número telefónico de contacto:',
  addPhone: 'AÑADIR OTRO TELÉFONO',
  addPhoneV3: 'Añadir otro teléfono',
  addPhoneV4: 'Añadir',
  tooltipTitle: 'Registro de nuevo número',
  codeValidation: 'Código de validación',
  codeValidationLabel: 'Enviamos un código por SMS a',
  codeValidationInstruction: 'Introduce el código que recibiste',
  codeValidationAddNumber: 'Agregar número',
  codeValidationCancel: 'Cancelar',
  telephonesAdded: 'Números de teléfono ingresados:',
  telephonesAddedWarning: 'Agrega al menos un número de teléfono',
  telephonesAddedError: 'Aún no agregas un número de teléfono',
  codeValidationResend: (counter) =>
    `Reenviar SMS ${counter > 0 ? `en ${counter}` : ''}`,
}
