import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import urls from 'helpers/urls';
import getUrl from 'helpers/getUrl';
import FormButton from 'helpers/FormUtils/FormButton';
import {
  LoansTabs,
  LoansCurrentProductName,
  LoansNewProductName,
  PreapprovalStatus,
} from 'constants/AppConstants';
import { loansProviderCodes } from 'constants/ProviderCodes';
import { ExternalLenderFooter } from 'components/loans/Commons/Footer';
import {
  getUserName,
  getPrestaClipNameChange,
  getPreapprovals,
} from 'components/loans/redux/selectors';
import LoansRedirection from 'components/loans/Commons/Redirection';
import {
  Container,
  TopDecoration,
  Title,
  Logo,
  TitleMessage,
  Content,
  ButtonContainer,
} from './styled';
import text from './text';
import { Clevertap } from 'helpers/Clevertap';

const AcceptedPage = ({
  userName,
  prestaClipNameChange,
  preapprovals,
  user,
  merchant,
  merchantInfo,
}) => {
  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (merchantInfo.proxy_id && user) {
      Clevertap.profile(user);
    }
  }, [user, merchant]);

  const goDashboard = () => {
    history.push(getUrl(urls.dashboard));
  };

  const renderNewProductName = (prevText) => {
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  const hasAccepted = (preapprovals) =>
    preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.ACCEPTED].includes(preapproval.status) &&
        !preapproval.loan_id,
    ).length > 0;

  const hasSigned = (preapprovals) =>
    preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.SIGNED].includes(preapproval.status) &&
        !preapproval.loan_id &&
        preapproval.provider_code.toLowerCase() ===
          loansProviderCodes.APH.toLowerCase(),
    ).length > 0;

  const isMobileWebView = location.pathname.startsWith(urls.mobile);
  return (
    <LoansRedirection tab={LoansTabs.NEW_OFFER}>
      {(hasAccepted(preapprovals) || hasSigned(preapprovals)) && (
        <Container>
          <TopDecoration />
          <Title>
            <Logo />
            <TitleMessage>
              {renderNewProductName(text.title)}
            </TitleMessage>
          </Title>
          <Content>
            {renderNewProductName(text.message(userName))}
          </Content>
          <ExternalLenderFooter />
        </Container>
      )}
      {!isMobileWebView && (
        <ButtonContainer>
          <FormButton
            variant="contained"
            text={text.button}
            onClick={goDashboard}
          />
        </ButtonContainer>
      )}
    </LoansRedirection>
  );
}

const mapStateToProps = (state) => {
  return {
    userName: getUserName(state),
    prestaClipNameChange: getPrestaClipNameChange(state),
    preapprovals: getPreapprovals(state),
    user: state.user,
    merchant: state.merchant,
    merchantInfo: state.merchant.info,
  };
};

export default withRouter(connect(mapStateToProps, null)(AcceptedPage));
