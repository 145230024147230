import React from 'react';
import { ClipTypography } from '@clipmx/clip-storybook';

import LoansRedirection from '../../Commons/Redirection';
import { ExternalLenderFooter } from '../Footer';
import { LoansTabs } from '../../../../constants/AppConstants';

import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import { styles } from './styles';
import text from './text';

import PrestaClipBanner from '../../../../assets/images/prestaclip.svg';
import LoansLogoBanner from '../../../../assets/images/logo-loans.svg';
import ExpiredImage from '../../../../assets/images/loans/expired.svg';

import ClipPlus from '../../../../assets/images/loans/clip-plus-2.svg';
import ReaderInHand from '../../../../assets/images/loans/reader_in_hand.svg';

const useStyles = makeStyles(styles);
const ExpiredPage = (props) => {
  const classes = useStyles(props);

  return (
    <LoansRedirection tab={LoansTabs.NEW_OFFER}>
      <Grid
        container={true}
        direction={'column'}
        classes={{ root: classes.container }}
      >
        <Grid
          container={true}
          direction={'column'}
          classes={{ root: classes.bannerContainer }}
        >
          <div className={classes.prestaClipContainer}>
            <img
              src={LoansLogoBanner}
              alt={text.title}
              className={classes.banner}
            />
            <img
              src={ExpiredImage}
              alt={text.title}
              className={classes.expiredIcon}
            />
          </div>
          <ClipTypography
            variant={'h3'}
            component={'h1'}
            fontWeight={'medium'}
            classes={{ root: classes.title }}
          >
            {text.title}
          </ClipTypography>
        </Grid>
        <ClipTypography
          component={'p'}
          fontWeight={'regular'}
          classes={{ root: classes.content }}
        >
          {text.message}
        </ClipTypography>
        <ClipTypography
          variant={'subtitle1'}
          component={'h2'}
          fontWeight={'semiBold'}
          classes={{ root: classes.subtitle }}
        >
          {text.subtitle}
        </ClipTypography>
        <div className={classes.advice}>
          <div className={classes.adviceIcon}>
            <img src={ReaderInHand} />
          </div>
          <ClipTypography
            variant={'body1'}
            component={'p'}
            fontWeight={'regular'}
          >
            {text.advice1}
          </ClipTypography>
        </div>
        <div className={classes.advice}>
          <div className={classes.adviceIcon}>
            <img src={ClipPlus} />
          </div>
          <ClipTypography
            variant={'body1'}
            component={'p'}
            fontWeight={'regular'}
          >
            {text.advice2}
          </ClipTypography>
        </div>
        <ClipTypography
          variant={'body1'}
          component={'p'}
          fontWeight={'regular'}
          classes={{ root: classes.recommendation }}
        >
          {text.recommendation}
        </ClipTypography>
      </Grid>
      <div className={classes.footerContainer}>
        <ExternalLenderFooter />
      </div>
    </LoansRedirection>
  );
};

export default ExpiredPage;
