export const getOnboardingActiveState = (state) => state.onboarding.activeState;
export const getOnboardingBankAccount = (state) => state.onboarding.bankAccount;
export const getOnboardingPersonalInfo = (state) =>
  state.onboarding.personalInformation;
export const getOnboardingBusiness = (state) => state.onboarding.business;
export const getOnboardingCollaborator = (state) =>
  state.onboarding.collaborator;
export const getOnboardingInstallments = (state) =>
  state.onboarding.installments;
export const getOnboardingState = (state) => state.onboarding.state;
export const getOnboardingTabs = (state) => state.onboarding.tabs;
export const getOnboardingIndustries = (state) => state.onboarding.industries;
export const getUserEmail = (state) => state.user.email;
export const getMerchantName = (state) => state.merchant.info.name;
