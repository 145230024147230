import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { getConfigValue } from '../config/config';
import { Forms } from '../constants/AppConstants';

const LDFlags = {};
// Loans Flags
LDFlags[getConfigValue('launchDarklyLoansShowCashAdvanceInfo')] = true;
LDFlags[getConfigValue('launchDarklyMaintenanceSetter')] = false;
LDFlags[getConfigValue('launchDarklyPrestaClipFlagName')] = true;
LDFlags[getConfigValue('launchDarklyLoansEligibleOpenData')] = false;
LDFlags[getConfigValue('launchDarklyOpenDataShowFeature')] = false;
LDFlags[getConfigValue('launchDarklyAphSignShowFeature')] = false;
LDFlags[getConfigValue('launchDarklySamsClubShowGift')] = false;
LDFlags[
  getConfigValue('launchDarklyFinancialShowTandemPrivacyPolicyWeb')
] = false;
LDFlags[getConfigValue('launchDarklyFinancialTandemFaqsWeb')] = {};
LDFlags[getConfigValue('launchDarklyFinancialFrecuentContactsWeb')] = false;
LDFlags[getConfigValue('loansMerchantDashboardRegularOffersVersion')] = false;
LDFlags[getConfigValue('loansMerchantDashboardFormVersionFrontEnd')] =
  Forms.VERSION_4;
// Berlin Flags
LDFlags[
  getConfigValue('launchDarklyFinancialRequestClipcardAvailableWeb')
] = false;
LDFlags[getConfigValue('launchDarklyFinancialChooseNipAvailableWeb')] = false;
LDFlags[getConfigValue('launchDarklyFinancialChangepinAvailable')] = false;
LDFlags[getConfigValue('launchDarklyFinancialSpeiOutAvailable')] = false;
LDFlags[getConfigValue('launchDarklyFinancialDynamicCvvAvailableWeb')] = false;
LDFlags[getConfigValue('launchDarklyFinancialMigrationWeb')] = false;
LDFlags[
  getConfigValue('launchDarklyFinancialCheckPrintedNipAvailableWeb')
] = false;
LDFlags[
  getConfigValue('launchDarklyFinancialGeolocationRequiredSpeiOutAvailableWeb')
] = false;
LDFlags[
  getConfigValue('launchDarklyFinancialOnboardingRequestTimeoutWeb')
] = 600;
LDFlags[getConfigValue('launchDarklyFinancialCdaAvailableWeb')] = true;
LDFlags[
  getConfigValue('launchDarklyFinancialUploadPhotoV2AvailableWeb')
] = true;
LDFlags[
  getConfigValue('launchDarklyFinancialRequestClipCardV2AvailableWeb')
] = true;
LDFlags[getConfigValue('launchDarklyFinancialGeneralMaintenanceAvailableWeb')] =
  'null';
LDFlags[
  getConfigValue('launchDarklyFinancialShowOnboardingV2AvailableWeb')
] = true;
LDFlags[getConfigValue('launchDarklyFinancialActivateUpgradeN3Web')] = true;
LDFlags[getConfigValue('launchDarklyFinancialActivateUpgradeN3Web')] = false;
LDFlags[getConfigValue('launchDarklyFinancialCdaInStatus')] = true;
LDFlags[getConfigValue('launchDarklyFinancialEncryptionAvailableWeb')] = false;
LDFlags[
  getConfigValue('launchDarklyFinancialAccountCreationAvailableWeb')
] = true;
LDFlags[getConfigValue('launchDarklyFinancialActivateSwapUpgradeWeb')] = false;
LDFlags[
  getConfigValue('launchDarklyFinancialActivateSwapToastUpgradeWeb')
] = false;
LDFlags[getConfigValue('launchDarklyFinancialTandemPrivacyPolicyWeb')] = false;
LDFlags[
  getConfigValue('launchDarklyFinancialShowTandemPrivacyPolicyWeb')
] = false;
LDFlags[getConfigValue('launchDarklyFinancialTandemFaqsWeb')] = {};
LDFlags[getConfigValue('launchDarklyFinancialGeneralScheduledMaintenance')] =
  {};
// Run the bussines flags
LDFlags[getConfigValue('launchDarklySettlementAllowListBackend')] = false;
// Payments
LDFlags[getConfigValue('launchDarklyXpayPage')] = false;

LDFlags[getConfigValue('launchDarklyMyStore')] = false;
LDFlags[getConfigValue('launchDarklyPublicProfile')] = false;
LDFlags[getConfigValue('launchDarklyXoHosted')] = false;

// Ecommerce
LDFlags[getConfigValue('launchDarklyOnlineStoreFeature')] = false;

// Mfe dashboard redesign
LDFlags[getConfigValue('launchDarklyMfeRedesignPilot')] = false;

LDFlags[getConfigValue('launchDarklyFinancialShowRequestClipCard')] = false;

const getLDProvider = async () => {
  return await asyncWithLDProvider({
    clientSideID: getConfigValue('launchDarklyKey'),
    deferInitialization: true,
    flags: LDFlags,
  });
};

export default getLDProvider;
