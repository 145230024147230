export default {
  myCrediClipTitle: 'Mi Credi Clip',
  paysHistoryTitle: 'Historial de pagos Credi Clip',
  moreDetail: 'Más detalles',
  debtDetail: 'Saldo adeudado',
  debtView: 'VER ADEUDO',
  loanDetail: 'Detalles del préstamo',
  loanDetailPrestaClip: 'Detalles del préstamo',
  detailView: 'VER DETALLE',
  collectionDetail: 'Historial de pagos',
  historicView: 'VER HISTORIAL',
  hide: 'CERRAR',
  activeLoans: 'Préstamos activos',
  onHoldLoans: 'Préstamos en pausa',
  paidLoans: 'Préstamos finalizados',
  totaly: 'Totalmente',
  cancelled: 'Cancelado',
  onhold: 'En Pausa',
  ended: 'Finalizado',
  paid: 'Saldo pagado:',
  byPay: 'Saldo adeudado:',
  amountLoan: 'Monto prestado:',
  charge: 'Cargo fijo + IVA:',
  monthlyCapMonthDebt: 'Saldo pendiente del mes:',
  stock: 'Capital:',
  fixedCharge: 'Interés total:',
  IVA: 'IVA:',
  total: 'Total:',
  overdueInterest: 'Intereses moratorios:',
  overdueAlertTitle: 'Fecha de pago vencida:',
  upcomingAlertTitle: 'Fecha límite de pago:',
  monthlyCapupcomingAlertTitle: 'Fecha límite de pago del periodo:',
  overdueAlertText: 'Tu saldo adeudado está generando intereses moratorios',
  upcomingAlertText: 'Evita sobrecargos, paga tu saldo adeuado antes del',
  totalPay: 'Total a Pagar:',
  monthlyCapTotalPay: 'Saldo pendiente de meses anteriores:',
  received: 'Recibido el',
  monthlyCapBegin: 'Inicio del préstamo:',
  term: 'Plazo:',
  wayPay: 'Retención de ventas:',
  monthlyPay: 'Pago mensual:',
  dailyPay: '%',
  numberPay: 'N° de pago',
  termMonths: (month) => `${month} meses`,
  period: 'Periodo',
  selectPeriod: 'Elegir periodo',
  periodOptions: [
    { id: 'seven', name: 'Últimos 7 pagos' },
    { id: 'fourteen', name: 'Últimos 14 pagos' },
    { id: 'thirty', name: 'Últimos 30 pagos' },
    { id: 'all', name: 'Todos los pagos' },
  ],
  date: 'Fecha',
  payLoanAmount: 'Pago a monto prestado',
  payFixCharge: 'Pago a cargo fijo',
  payTax: 'Pago a Intereses',
  payOverdue: 'Pago a intereses moratorios',
  iva: 'Pago a IVA',
  payTotal: 'Pago total',
  greatDay: (date) => `la fecha seleccionada debe ser mayor a ${date}`,
  lessDay: (date) => `la fecha seleccionada debe ser menor a ${date}`,
  advancePaymentTitle: 'Realizar pago',
  termsInfo: `Importante: El préstamo solicitado por el Usuario es otorgado por un acreditante 
  tercero e independiente (el “Acreditante”) a Payclip, S. de R.L. de C.V. La información 
  presentada en esta página es únicamente de carácter informativo, para más información da `,
  clickHere: 'clic aquí',
  termsTitle: 'Importante:',
  termsDescription: `La información presentada en esta página es únicamente de carácter informativo 
  para el Usuario de Clip, la cual se encuentra sujeta a la información, operación y confirmación del 
  Acreditante, pudiendo ésta ser ajustada, actualizada, o presentar cargos pendientes por concepto de 
  comisiones, cargos pendientes de autorización, entre otros. En caso de no presentar adeudos ni monto 
  pendiente de pago alguno conforme a la información mostrada, o bien, en caso de tener cualquier 
  pregunta o si desea solicitar aclaraciones sobre el estado actual de su préstamo, le sugerimos 
  realizar la consulta directamente con el Acreditante.`,
  termsFooter: 'Escríbenos por WhatsApp o llámanos al ',
  termsFooterTelephone: '55 6393-2323',
  termsFooterSendEmail: 'Envía un correo a ',
  termsFooterEmail: 'help@clip.mx',
  termsFooterEnd: ' o solicita apoyo en el chat de clip.mx.',
  understandBtn: 'Entendido',
  monthlyPaymentPending: 'Saldo por pagar del mes',
};
