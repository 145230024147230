const styles = (theme) => {
  return {
    container: {
      margin: '0 1rem',
    },
    title: {
      marginTop: '45px',
    },
    helperText: {
      textAlign: 'left',
      width: '100%',
      fontSize: '16px',
    },
    whiteButton: {
      width: '100%',
    },
    buttonContainer: {
      width: '100%',
      height: '100%',
      marginTop: '50%',
      '& button': {
        marginBottom: '8px',
      },
    },
    continueButton: {
      right: '1rem',
      bottom: '1rem',
      position: 'fixed',
    },
    createButton: {
      margin: 'auto',
      marginBottom: '1.5rem',
      marginTop: '24px',
    },
    twinButtonContainer: {
      right: '1rem',
      bottom: '1rem',
      position: 'fixed',
      '& button': {
        marginLeft: '4px',
      },
    },

    inePicture: {
      width: '100%',
      maxHeight: '40vh',
      objectFit: 'contain',
      marginTop: '2rem',
    },
    termsPicture: {
      width: '6.25rem',
      objectFit: 'contain',
      marginTop: '4rem',
    },
    list: {
      marginTop: '0',
    },
    noBottomMargin: {
      marginBottom: '0',
      fontSize: '16px',
    },
    lottieAnimation: {
      marginTop: '2rem',
    },
    continueText: {
      fontSize: '24px',
      fontWeight: 'normal',
      textAlign: 'left',
      lineHeight: '1.33',
    },
    continueLinks: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
    termsTextTop: {
      fontSize: '16px',
      fontWeight: 'normal',
      textAlign: 'left',
      lineHeight: '1.33',
      padding: '0 0 0 0',
      marginTop: '1rem',
      marginBottom: '1rem',
      whiteSpace: 'pre-line',
    },
    listMargins: {
      marginTop: '0',
      paddingTop: '1rem',
    },
    contractIframe: {
      width: '100vw',
      height: 'calc(100vh - 100px)',
    },
    contractPictures: {
      width: '100%',
      '& img': {
        width: '100%',
      },
    },
  };
};

export default styles;
