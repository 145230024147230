import React from 'react';

import * as Styled from './styled';
import Transaction from '../Transaction';

export class TransactionsGroup extends React.Component {
  renderMovements = () => {
    const movements = this.props.movements;
    let movementGroup = [];
    for (let [index, value] of movements.entries()) {
      if (index === movements.length - 1) {
        movementGroup.push(
          <Transaction
            selectTransaction={this.props.selectTransaction}
            key={index}
            transaction={value}
            divider={false}
          ></Transaction>,
        );
      } else {
        movementGroup.push(
          <Transaction
            selectTransaction={this.props.selectTransaction}
            key={index}
            transaction={value}
            divider={true}
          ></Transaction>,
        );
      }
    }
    return movementGroup;
  };

  render() {
    return (
      <Styled.TransactionsGroupContainer>
        <Styled.TransactionsGroupDate>
          {this.props.date}
        </Styled.TransactionsGroupDate>
        {this.renderMovements()}
      </Styled.TransactionsGroupContainer>
    );
  }
}

export default TransactionsGroup;
