import styled from '@emotion/styled';
import lightBlue from '@material-ui/core/colors/lightBlue';

export const RefillContactContainer = styled.div(({ theme }) => ({
  margin: `${theme.spacing.unit * 3}px 0 0`,
  padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
    theme.spacing.unit * 3
  }px`,
  textAlign: 'center',
  '& a ': {
    color: lightBlue[700],
  },
  [theme.breakpoints.down('xs')]: {
    marginTop: `${theme.spacing.unit * 5}px`,
  },
  '&.slate': {
    backgroundColor: theme.palette.naranja.iceBlue,
    [theme.breakpoints.down('xs')]: {
      marginTop: `${theme.spacing.unit * 5}px`,
    },
  },
}));
export const ExternalMessage = styled.p(({ theme }) => ({
  color: theme.palette.naranja.slate,
  fontSize: `${theme.spacing.unit * 1.5 + 1}px`,
  fontWeight: theme.typography.fontWeightLight,
  textAlign: 'center',
  lineHeight: 1.46,
  margin: '0 0 2px 0',
}));

export const ContactContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.naranja.iceBlue,
  marginBottom: 0,
  marginTop: `${theme.spacing.unit * 3}px`,
  padding: `${theme.spacing.unit * 3}px`,
  textAlign: 'center',
  '& a ': {
    color: lightBlue[700],
  },
  [theme.breakpoints.down('xs')]: {
    textAlign: 'center',
    paddingLeft: `${theme.spacing.unit}px`,
    paddingRight: `${theme.spacing.unit}px`,
  },
}));

export const Message = styled.p(({ theme }) => ({
  color: theme.palette.naranja.slate,
  fontSize: `${theme.spacing.unit * 1.5 + 1}px`,
  fontWeight: theme.typography.fontWeightLight,
  textAlign: 'center',
  lineHeight: 1.46,
  margin: `0 0 ${theme.spacing.unit * 0.25}px 0`,
}));

export const MessageSeparator = styled.div(({ theme }) => ({
  height: `${theme.spacing.unit - 2}px`,
}));
export const DoubleMessageSeparator = styled.div(({ theme }) => ({
  height: `${theme.spacing.unit * 1.75}px`,
}));
export const MessageLink = styled.a();
  