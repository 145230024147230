const styles = (theme) => ({
  whiteDot: {
    display: 'inline-block',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    border: 'solid 1px #979797',
    margin: '0 8px',
  },
  blackDot: {
    display: 'inline-block',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    margin: '0 8px',
    backgroundColor: 'black',
    border: 'solid 1px black',
  },
  '@keyframes blink': {
    '0%': { opacity: 0.3 },
    '25%': { opacity: 0.1 },
    '50%': { opacity: 0.3 },
  },
  blinkAnimation: {
    opacity: 0.3,
    animation: 'blink 2s infinite',
  },
});
export default styles;
