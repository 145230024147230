import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { connect } from 'react-redux';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import { Done } from '@clipmx/clip-storybook/dist/components/Icons';
import { NotificationsActive } from '@clipmx/clip-storybook/dist/components/Icons';
import { ThumbUpAltOutlined } from '@clipmx/clip-storybook/dist/components/Icons';
import BerlinSpinner from '../BerlinSpinner';
import text from './text.js';
import Lottie from 'lottie-react';
import BerlinService from '../service';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ClipHand from '../../../assets/images/berlin/lottie/WaitingList/data.json';

const useStyles = makeStyles(styles);

const WaitingList = (props) => {
  const classes = useStyles(props);

  const [isWaiting, setIsWaiting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    const { merchant } = props;
    if (merchant.info.id) {
      try {
        await BerlinService.getWaitingList(merchant.info.id);
        setIsWaiting(true);
        setIsLoading(false);
      } catch (error) {
        setIsWaiting(false);
        setIsLoading(false);
      }
    }
  }, [props.merchant.info.id]);

  const postWaitingList = async () => {
    setIsLoading(true);
    try {
      const { merchant, user } = props;
      const payload = {
        email: user.email,
        merchant_id: merchant.info.id,
      };
      await BerlinService.postWaitingList(merchant.info.id, payload);
      setIsLoading(false);
      setIsWaiting(true);
    } catch (error) {
      if (error.response.status === 422) {
        setIsLoading(false);
        setIsWaiting(true);
      }
    }
  };

  return isLoading ? (
    <BerlinSpinner />
  ) : (
    <div className={classes.container}>
      <ClipTypography
        variant={'h5'}
        component={'h2'}
        fontWeight={'regular'}
        className={classes.title}
      >
        {text.title}
      </ClipTypography>
      <Lottie animationData={ClipHand} className={classes.gif} />
      <ClipTypography
        variant={'body1'}
        component={'p'}
        classes={{ root: classes.typographyContent }}
      >
        <Done className={classes.checkIcon} />
        {text.feature1}
      </ClipTypography>
      <ClipTypography
        variant={'body1'}
        component={'p'}
        classes={{ root: classes.typographyContent }}
      >
        <Done className={classes.checkIcon} />
        {text.feature2}
      </ClipTypography>
      <ClipTypography
        variant={'body1'}
        component={'p'}
        classes={{ root: classes.typographyContent }}
      >
        <Done className={classes.checkIcon} />
        {text.feature3}
      </ClipTypography>
      <ClipTypography
        variant={'body1'}
        component={'p'}
        classes={{ root: classes.typographyContent }}
      >
        <Done className={classes.checkIcon} />
        {text.feature4}
      </ClipTypography>
      <ClipTypography
        variant={'body1'}
        component={'p'}
        classes={{ root: classes.typographyContent }}
      >
        <Done className={classes.checkIcon} />
        {text.feature5}
      </ClipTypography>
      {isWaiting === false ? (
        <ClipButton
          className={classes.button}
          variant={'outlined'}
          color={'secondary'}
          disabled={isLoading}
          onClick={() => postWaitingList()}
        >
          <NotificationsActive style={{ marginRight: 10 }} />
          {text.buttonTxt}
        </ClipButton>
      ) : (
        <div className={classes.divText}>
          <ThumbUpAltOutlined className={classes.likeIcon} />
          <ClipTypography
            variant={'body1'}
            component={'p'}
            className={classes.textDone}
          >
            {text.textDone}
          </ClipTypography>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    user: state.user,
  };
};

export default connect(mapStateToProps)(WaitingList);
