import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Card from '../../../../components/common/Card';
import ChartIcon from '../../../../assets/images/icons/chart_stars.svg';
import PrestaClipBanner from '../../../../assets/images/prestaclip.svg';
import FormButton from '../../../../helpers/FormUtils/FormButton';
import {
  LoansTabs,
  PreapprovalStatus,
} from '../../../../constants/AppConstants';
import { getPreapprovals, getLDFlagsReady } from '../../redux/selectors';
import GoogleAnalytics from '../../GoogleAnalytics';
import { RefillFooter } from '../../Commons/Footer';
import LoansRedirection from '../../Commons/Redirection';
import { ExpireWarningContent } from '../../MrPresta/styled';
import Description from '../Description';
import { styles } from '../styles';
import text from '../text';

export const Elegible = (props) => {
  const { classes, preapprovals, ldFlagsReady } = props;

  const typographyStyles = {
    body1: classes.typographyRoot,
    title: classes.typographyTitle,
    headline: classes.typographySubTitle,
    display1: classes.typographyNote,
  };

  const shouldRender =
    ldFlagsReady &&
    (preapprovals.length === 0 ||
      preapprovals.length ===
        preapprovals.filter(
          ({ loan_id, status }) =>
            [
              PreapprovalStatus.DECLINED,
              PreapprovalStatus.CANCELLED,
              PreapprovalStatus.CANCELED,
            ].includes(status) && !loan_id,
        ).length);

  return (
    <LoansRedirection tab={LoansTabs.NEW_OFFER}>
      {shouldRender && (
        <GoogleAnalytics>
          <Grid item sm={12} xs={12} className={classes.gridCard}>
            <div className={classes.bannerContainer}>
              <img
                className={classes.bannerTop}
                alt="PrestaClip Banner"
                src={PrestaClipBanner}
              />
            </div>
            <Card className={clsx('margin24', classes.crediCard)}>
              <div className={classes.textContainer}>
                <Typography variant="headline" classes={typographyStyles}>
                  {text.subtitle}
                </Typography>
                <Typography variant="title" classes={typographyStyles}>
                  {text.title}
                </Typography>
                <div className={classes.separator} />
                <Typography variant="body1" classes={typographyStyles}>
                  {text.messageTitle}
                </Typography>
                <Typography variant="body1" classes={typographyStyles}>
                  {text.message}
                </Typography>
                <Typography variant="display1" classes={typographyStyles}>
                  {text.note}
                </Typography>
              </div>
              <div className={classes.container}>
                <FormButton
                  variant="contained"
                  text={text.button}
                  onClick={(event) => {
                    window.location.href =
                      'https://civico-keo-portal-demo.moprestamo.com/?token=gR3MaJoR4R3C8rshY0';
                  }}
                />
              </div>
              <div className={classes.alertContainer}>
                <div className={classes.expireWarning}>
                  <img
                    className={classes.chartLeftIcon}
                    alt="PrestaClip Chart"
                    src={ChartIcon}
                  />
                  <ExpireWarningContent>
                    <ReactMarkdown source={text.advice} />
                  </ExpireWarningContent>
                </div>
              </div>
            </Card>
            <Card className={clsx('margin24', classes.crediCard)}>
              <div className={classes.descriptionContainer}>
                <Description />
              </div>
            </Card>
            <RefillFooter />
          </Grid>
        </GoogleAnalytics>
      )}
    </LoansRedirection>
  );
};
Elegible.propTypes = {
  classes: PropTypes.object.isRequired,
  preapprovals: PropTypes.array.isRequired,
  ldFlagsReady: PropTypes.bool.isRequired,
};
Elegible.defaultProps = {
  classes: {},
  preapprovals: [],
  ldFlagsReady: false,
};

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
    ldFlagsReady: getLDFlagsReady(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Elegible));
