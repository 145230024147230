import { SET_DASHBOARD_NAV, SET_DASHBOARD_TAB } from '../actions/Types';
import * as AppConstants from '../constants/AppConstants';

const initialState = {
  activeItem: AppConstants.DashboardNavConstants.CONTROL,
  activeTab: AppConstants.DashboardNavConstants.CONTROL,
};

export function getNavActiveItem(state) {
  return state.dashboardNav.activeItem;
}

export function getNavActiveTab(state) {
  return state.dashboardNav.activeTab;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_NAV:
      return { ...state, activeItem: action.item };
    case SET_DASHBOARD_TAB:
      return { ...state, activeTab: action.tab };
    default:
      return state;
  }
}
