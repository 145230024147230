export default {
  title: 'Adelantar pago',
  link: 'Mis Créditos',
  endDate: 'Fecha límite de pago:',
  endDateMonthlyCap: 'Fecha límite de pago del periodo:',
  totalPending: 'Saldo adeudado:',
  totalPendingMonth: 'Saldo pendiente del mes:',
  totalPendingTotal: 'Saldo total adeudado:',
  instuctions: 'Instrucciones para pagar',
  moreInfo: 'Mas información',
  whatsApp: 'WhatsApp:',
  whatsAppNumber: '55 6393 2323',
  writeUs: 'Escríbenos:',
  content: {
    mrp:
      '' +
      '1. Solicitar el saldo a customer happiness' +
      '\n\n\tRecuerda que es importante que antes de hacer el depósito, ' +
      'verifiques el saldo restante ya que este se actualiza todos los días.' +
      '\n1. Realiza una transferencia bancaria por el saldo total o el monto que ' +
      'deseas abonar con los siguientes datos:' +
      '\n\n\t- **Cuenta:** 12000001315' +
      '\n\t- **CLABE Interbancaria:** En la página 4 de tu contrato viene la CLABE a ' +
      'donde podrás hacer la transferencia.' +
      '\n\t- **Banco:** BANCREA' +
      '\n\t- **A nombre de:** MR Presta S.A. de C.V., SOFOM' +
      '\n\t- **RFC:** MPR131203JW9' +
      '\n1. Enviar el comprobante de esta transferencia o depósito al correo help@clip.mx, ' +
      'indicando como asunto del correo:' +
      '\n\n\tCLIP_CURPUsuario_' + ' NombreUsuario_' + ' ApellidoUsuario' +
      '\n\n\tRecuerda que es importante que antes de hacer el depósito, verifiques el ' +
      'saldo restante ya que este se actualiza todos los días.' +
      '\n\n\tEs muy importante que no olvides enviar el comprobante a este correo con los ' +
      'datos solicitados, ya que, de lo contrario, no podremos procesar tu pago.' +
      '\n\n\tTe sugerimos realizarlo en las horas de la mañana junto con el envío del soporte ' +
      'para que podamos procesar tu pago el mismo día.' +
      '\n\n\tCuando recibamos el comprobante, nos cercioramos de que el pago ha sido exitoso y ' +
      'se verá reflejado en tu préstamo.' +
      '\n\n\tConserva tu comprobante de pago, el cual es indispensable para realizar cualquier aclaración.\n',
    mot:
      '' +
      '1. Realizar una transferencia o consignación bancaria por el monto que desees abonar con los siguientes datos:' +
      '\n\n\t- **Banco:** BBVA' +     
      '\n\t- **Referencia:** CLIP_CURPUsuario_' + ' NombreUsuario_' + ' ApellidoUsuario' +
      '\n\t- **CLABE Interbancaria:** 012180001171718895' +
      '\n\t- **A nombre de:** KEO WORLD SA DE CV SOFOM' +
      '\n\t- **RFC:** MTE180606F98' +
      '\n1. Enviar el comprobante de esta transferencia o consignación al correo help@clip.mx, ' +
      'indicando como asunto del correo:' +
      '\n\n\tCLIP_CURPUsuario_' + ' NombreUsuario_' + ' ApellidoUsuario' +
      '\n\n\tEs muy importante que no olvides enviar el comprobante a este correo con los datos ' +
      'solicitados, ya que, de lo contrario, no podremos procesar tu pago.' +
      '\n\n\tComo recomendación, te sugerimos verificar en nuestro portal el total del saldo que ' +
      'debes antes de realizar el pago y de igual forma, realizarlo en las horas de la mañana junto ' +
      'con el envío del soporte para que podamos procesar tu pago el mismo día.' +
      '\n\n\tCuando recibamos el comprobante, nos cercioraremos de que el pago ha sido exitoso y ' +
      'se verá reflejado en tu préstamo.',
    aph:
      '' +
      '1. Solicitar el saldo a customer happiness' +
      '\n1. Realiza una transferencia bancaria por el saldo total o el monto que deseas abonar con los ' +
      'siguientes datos:' +
      '\n\n\tBBVA Bancomer, S.A., integrante del Grupo Financiero BBVA Bancomer, con número ' +
      '0112095114, CLABE interbancaria 012180001120951140 a nombre de BetaCredit, S.A. de C.V., ' +
      'SOFOM, ENR en referencia favor de poner CURPUsuario_' + ' NombreUsuario_' + ' ApellidoUsuario' +
      '\n1. Enviar el comprobante de esta transferencia al correo help@clip.mx, indicando como asunto ' +
      'del correo:' +
      '\n\n\tCLIP_CURPUsuario_' + ' NombreUsuario_' + ' ApellidoUsuario' +
      '\n\n\tEs muy importante que no olvides enviar el comprobante a este correo con los datos ' +
      'solicitados, ya que, de lo contrario, no podremos procesar tu pago.' +
      '\n\n\tTe sugerimos realizarlo en las horas de la mañana junto con el envío del soporte para ' +
      'que podamos procesar tu pago el mismo día.' +
      '\n\n\tCuando recibamos el comprobante, nos cercioramos de que el pago ha sido exitoso y se ' +
      'verá reflejado en tu préstamo.' +
      '\n\n\tConserva tu comprobante de pago, el cual es indispensable para realizar cualquier ' +
      'aclaración.',
      cto: 
      '' + 
      '\n1. Realizar una transferencia o consignación bancaria por el monto que desees abonar ' + 
      'con los siguientes datos:' + 
      '\n\n\t- **Banco destino:** BBVA Bancomer' + 
      '\n\t- **Cuenta bancaria:**  0115544149' + 
      '\n\t- **CLABE Interbancaria:**  012180001155441492' + 
      '\n\t- **Referencia:**  1743740' + 
      '\n\t- **Concepto:** Ingresar tu número identificador [CIE DEL CLIENTE] ' + 
      '\n\n\tDeberás llamar a nuestros agentes de Customer Happiness para conocer tu  CIE.' + 
      '\n1. Enviar el comprobante de esta transferencia o consignación al correo help@clip.mx, ' + 
      'indicando como asunto del correo:' + 
      '\n\n\tCLIP_CURPUsuario_' + ' NombreUsuario_' + ' ApellidoUsuario' +
      '\n\n\tEs muy importante que no olvides enviar el comprobante a este correo ' + 
      'con los datos solicitados, ya que, de lo contrario, no podremos procesar tu pago.\n' + 
      '\n\n\tComo recomendación, te sugerimos verificar en nuestro portal el total del saldo ' + 
      'que debes antes de realizar el pago y de igual forma, realizarlo en las horas de la mañana ' + 
      'junto con el envío del soporte para que podamos procesar tu pago el mismo día.' + 
      '\n\n\tCuando recibamos el comprobante, nos cercioramos de que el pago ha sido exitoso y ' + 
      'se verá reflejado en tu préstamo.\n',
    r2: 
      '' + 
      '\n1. Realizar una transferencia o consignación bancaria por el monto que desees abonar ' + 
      'con los siguientes datos:' + 
      '\n\t- **Banco destino:** Santander' + 
      '\n\t- **Número de cuenta:** 65-50883592-0' + 
      '\n\n\t- **CLABE Interbancaria:**  014180655088359209' + 
      '\n\n\t- **Referencia:**  CLIP_CURPUsuario_' + ' NombreUsuario_' + ' ApellidoUsuario' + 
      '\n\n\t- **A nombre de:**  R2 Capital Technologies MX, S.A. de C.V.' + 
      '\n1. Enviar el comprobante de esta transferencia o consignación al correo help@clip.mx, ' + 
      'indicando como asunto del correo:' + 
      '\n\n\tCLIP_CURPUsuario_' + ' NombreUsuario_' + ' ApellidoUsuario' +
      '\n\n\tEs muy importante que no olvides enviar el comprobante a este correo ' + 
      'con los datos solicitados, ya que, de lo contrario, no podremos procesar tu pago.\n' + 
      '\n\n\tComo recomendación, te sugerimos verificar en nuestro portal el total del saldo ' + 
      'que debes antes de realizar el pago y de igual forma, realizarlo en las horas de la mañana ' + 
      'junto con el envío del soporte para que podamos procesar tu pago el mismo día.' + 
      '\n\n\tCuando recibamos el comprobante, nos cercioramos de que el pago ha sido exitoso y ' + 
      'se verá reflejado en tu préstamo.\n',
    avl: 
      '' + 
      '\n1. Revisa el número de cuenta CLABE que aparece en el contrato que firmaste al inicio del ' + 
      'préstamo, este dato aparece en la **Cláusula 7° “Fecha y medio de pago”**' + 
      '\n2. Este número es único para ti de esta forma  podemos identificar que el pago es tuyo' + 
      '\n\nComo recomendación, te sugerimos verificar el total que debes antes de realizar el pago ' + 
      'y realizarlo antes de que tu saldo se ajuste nuevamente.\n',
    },
};
