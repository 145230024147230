// @ts-nocheck
import React, { useState, useCallback } from 'react';
import text from './text';
import BerlinService from '../../service';
import Proptypes from 'prop-types';
import ClipDivider from '@clipmx/clip-storybook/dist/components/Divider';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { styles } from './styles';
import { connect } from 'react-redux';
import { Forms } from '../../../../constants/AppConstants';
import { change, getFormValues, updateSyncErrors } from 'redux-form';
import {
  Check,
  HelpOutline,
} from '@clipmx/clip-storybook/dist/components/Icons';
import {
  ClipInput,
  ClipTypography,
  ClipIconButton,
  ClipButton,
} from '@clipmx/clip-storybook';
import Checkbox from '@clipmx/clip-storybook/dist/components/Checkbox';
import { useEffect } from 'react';

const useStyles = makeStyles(styles);

const ContactInfo = (props) => {
  const { action, openChange, change, setFromSaveContact } = props;
  const classes = useStyles(props);

  const [checked, setChecked] = useState(false);
  //CLABE
  const [clabe, setClabe] = useState(null);
  const [clabeCorrect, setclabeCorrect] = useState(false);
  const [clabeError, setClabeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(text.clabeError);
  //NAME
  const [name, setName] = useState(null);
  const [errorName, setErrorName] = useState(false);
  const [errorNameMessage, setErrorNameMessage] = useState(
    text.errorNameMessage,
  );
  //ALIAS
  const [alias, setAlias] = useState(null);
  const [erroAlias, setErrorAlias] = useState(false);
  const [errorAliasMessage, setErrorAliasMessage] = useState(
    text.errorNameMessage,
  );
  //BANK
  const [bank, setBank] = useState(text.bank);

  useEffect(() => {
    change('alias', '');
  }, []);

  const validateCLABE = (value) => {
    setClabeError(false);
    setClabe(value);
    change('clabe', value);
    change('bank', ' ');
    
    if (value.length !== 18) {
      setClabeError(true);
      setErrorMessage(text.clabeErrorLength);
      setclabeCorrect(false);
      setBank('');
    } else {
      BerlinService.getInterbankAccountInfo(value)
        .then((response) => {
          change(
            'bank',
            response.data.message.bank_acronym,
          );
          setBank(response.data.message.bank_acronym);
          setclabeCorrect(true);
        })
        .catch((error) => {
            setBank('');
            updateSyncErrors({
              clabe: 'verificar clabe',
            });
          setClabeError(true);
          setErrorMessage(text.clabeError);
        });
    }
  };

  const validateName =useCallback((value) => {
    setErrorName(false);
    setName(value);
    change('name', value);
    if (value.length < 3) {
      setErrorName(true);
      setErrorNameMessage(text.errorNameMessage);
    }
  }, [setErrorName, setName, change, setErrorNameMessage ]);

  const handleCheckbox = useCallback((event) => {
    setChecked(event.target.checked);
  }, [setChecked]);

  const validateAlias = useCallback((value) => {
    setErrorAlias(false);
    setAlias(value);
    change('alias', value);
    if (value.length < 3) {
      setErrorAlias(true);
      setErrorAliasMessage(text.errorNameMessage);
    }
  }, [setErrorAlias, setAlias, change, setErrorAliasMessage]);

  const validateButton = useCallback(() => {
    if (!clabe || clabe.trim() === '' || clabeCorrect === false) {
      return true;
    } else if (!name || name.trim() === '' || name.length < 3) {
      return true;
    } else if (
      checked === true &&
      (!alias || alias.trim() === '' || alias.length < 3)
    ) {
      return true;
    } else {
      return false;
    }
  }, [clabe, name, checked, alias, clabeCorrect]);

  const saveContact = async () => {
    if (checked === false) {
      change('contactSaved', false);
      change('fromNewContact', true);
      action(2);
    } else {
      const body = {
        recipient_account: clabe,
        nickname: alias,
        recipient_name: name,
        recipient_bank: bank,
      };
      try {
        await BerlinService.postSaveContact(body);
        setFromSaveContact(true);
        action(2);
        change('contactSaved', true);
        change('fromNewContact', true);
      } catch (error) {
        console.log(error);
        action(7);
      }
    }
  };

  return (
    <div className={classes.container}>
      <ClipTypography
        variant={'body1'}
        component={'p'}
        className={classes.title}
      >
        {text.title}
      </ClipTypography>
      <ClipInput
        fullWidth
        type={'text'}
        color={'secondary'}
        label={text.clabe}
        adornmentIcon={clabeCorrect && <Check color={'secondary'} />}
        helperText={text.clabeLenght}
        error={clabeError}
        errormessage={errorMessage}
        value={clabe}
        onChange={(e) => validateCLABE(e.target.value.replace(/[^0-9]/g, ''))}
      />
      <ClipDivider className={classes.divider} />
      <ClipInput
        fullWidth
        disabled
        type={'text'}
        color={'secondary'}
        label={bank}
      />
      <ClipDivider className={classes.dividerBank} />
      <ClipInput
        fullWidth
        type={'text'}
        color={'secondary'}
        label={text.name}
        helperText={text.fullName}
        inputProps={{ pattern: '[A-Za-z]' }}
        error={errorName}
        errormessage={errorNameMessage}
        value={name}
        onChange={(e) =>
          validateName(e.target.value.replace(/[^a-zA-ZÀ-ÿ\u00f1\u00d1 ]/ig, ''))
        }
      />
      <div className={classes.checkboxForm}>
        <Checkbox
          checked={checked}
          onChange={handleCheckbox}
          color="primary"
          className={classes.checkbox}
        />
        <ClipTypography variant={'body1'} component={'p'}>
          {text.saveContact}
        </ClipTypography>
        <ClipIconButton onClick={openChange}>
          <HelpOutline color={'primary'} />
        </ClipIconButton>
      </div>
      {checked && (
        <div>
          <ClipInput
            fullWidth
            type="text"
            color="secondary"
            inputProps={{ maxLength: 20 }}
            helperText={text.aliasHelper}
            label={text.alias}
            error={erroAlias}
            errormessage={errorAliasMessage}
            value={alias}
            onChange={(e) =>
              validateAlias(e.target.value.replace(/[^\w\s\][^,]/gi, ''))
            }
          />
        </div>
      )}
      <ClipDivider className={classes.divider} />
      <ClipButton
        fullWidth
        color="primary"
        variant="contained"
        disabled={validateButton()}
        onClick={() => saveContact()}
      >
        {text.continue}
      </ClipButton>
    </div>
  );
};

ContactInfo.propTypes = {
  openChange: Proptypes.func,
  dispatch: Proptypes.any,
  action: Proptypes.func,
  setFromSaveContact: Proptypes.func,
};

const mapStateToProps = (state) => {
  return {
    SpeiOutValues: getFormValues(Forms.BERLIN_SPEI_OUT)(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: (key, value) =>
      dispatch(change(Forms.BERLIN_SPEI_OUT, key, value)),
    updateSyncErrors: (value) =>
      dispatch(updateSyncErrors(Forms.BERLIN_SPEI_OUT, value)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);