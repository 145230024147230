import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { app } from '../config/config';
import { getRequestOptions } from '../helpers/fetchUtil';
import apiPaths from '../helpers/apiPaths';
import getUrl from '../helpers/getUrl';
import urls from '../helpers/urls';
import logger from '../helpers/logger';
import {
  UPDATE_USER,
  USER_ERROR,
  CLEAR_USER,
  DENY_PERMISSION,
  FETCH_USER,
  RESET_PERMISSION,
  SET_USER_TOKEN,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  RESET_PASSWORD_FAIL,
  FETCH_IDENTITY_VERIFICATION,
  UPDATE_PASSWORD,
  SET_DAYS_UNTIL_EXP,
} from './Types';
import { clearToken, saveToken } from '../helpers/tokenHelpers';
import { createGetRequest } from 'middleware/apiResolve/reduxUtils'
import { dataLoaded, dataLoadFailed, dataLoading } from './AppActions';

export function getUserInfo() {
  return (dispatch) => {
    dispatch({ type: FETCH_USER, payload: { loading: true } });

    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.userInfo}`;

    axios
      .get(url, options)
      .then((response) => {
        // Update user in Redux store
        const data = {
          ...response.data,
          passwordUpdatedAt: response.data.password_updated_at,
        };
        if (data.password_updated_at) {
          delete data.password_updated_at;
        }
        dispatch({ type: FETCH_USER, payload: { data, loading: false } });
      })
      .catch((err) => {
        logger.error('error getting user info: ', err.response);
        dispatch({ type: FETCH_USER, payload: { loading: false } });
        dispatch({ type: USER_ERROR, payload: 'Error del servidor' });
      });
  };
}

export function updateUserInfo(body) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.editUserInfo}`;
    const request = axios.create(options);

    request
      .post(url, body)
      .then((response) => {
        // Update user in Redux store
        dispatch({ type: UPDATE_USER, payload: response.data });
      })
      .catch((err) => {
        logger.error('err: ', err);

        dispatch({ type: USER_ERROR, payload: 'Error del servidor' });
      });
  };
}

export function updatePassword(body, onSuccess = null) {
  return (dispatch) => {
    dispatch(dataLoading(UPDATE_PASSWORD));
    const options = getRequestOptions();
    const url = `${app.apiUrlv3}${apiPaths.editPassword}`;
    const request = axios.create(options);

    request
      .post(url, body)
      .then((response) => {
        dispatch(dataLoaded(UPDATE_PASSWORD));
        // Update user in Redux store
        dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: response.data });
        onSuccess && onSuccess();
      })
      .catch((err) => {
        logger.error('err: ', err.response);

        dispatch(dataLoadFailed(UPDATE_PASSWORD, err))
        dispatch({ type: UPDATE_PASSWORD_FAIL, payload: err.response });

        setTimeout(() => {
          dispatch({ type: RESET_PASSWORD_FAIL });
        }, 3000);
      });
  };
}

export function setUserToken(token) {
  return (dispatch) => {
    dispatch({ type: SET_USER_TOKEN, payload: token });
  };
}

export function denyPersmission() {
  return dispatch => {
    let history = useHistory();
    // Update Redux state to be permission denied
    dispatch({ type: DENY_PERMISSION });

    // Redirect user to the dashboard
    history.push(getUrl(urls.dashboard));
  };
}

export function resetPermission() {
  return (dispatch) => {
    // Update Redux state so that user is not denied permission
    dispatch({ type: RESET_PERMISSION });
  };
}

export function failAuthentication() {
  return (dispatch) => {
    // Remove the user from Redux
    dispatch({ type: CLEAR_USER });

    clearToken();
  };
}

export function setIdentityVerification(verified) {
  return {
    type: FETCH_IDENTITY_VERIFICATION,
    payload: { verified, loading: false },
  };
}

export function getIdentityVerification() {
  return (dispatch) => {
    dispatch({ type: FETCH_IDENTITY_VERIFICATION, payload: { loading: true } });

    const { headers } = getRequestOptions();
    const options = {
      method: 'GET',
      url: `${app.apiUrlv2}${apiPaths.checkIdentityVerification}`,
      headers,
      json: true,
    };

    axios(options)
      .then((res) => {
        dispatch(setIdentityVerification(res.data.verified));
      })
      .catch((err) => {
        dispatch({
          type: FETCH_IDENTITY_VERIFICATION,
          payload: { loading: false, verified: false },
        });
      });
  };
}

export function savePasswordExpirationDays(daysUntilExp, saveCookie = false) {
  return (dispatch) => {
    const value = daysUntilExp !== undefined ? daysUntilExp : null
      saveCookie && saveToken(value, 'days_until_exp');
      dispatch({
        type: SET_DAYS_UNTIL_EXP,
        payload: value,
      })
  }
}

export function getUserPasswordExpirationCount() {
  const route = apiPaths.passwordExpiration;
  return {
    type: createGetRequest(),
    payload: {
      apiEndPoint: 'v3',
      route,
      autoSpinner: false,
      success: (response, store) => {
        const { dispatch } = store;
        const passwordStatus = response.data.item.password_status

        switch(passwordStatus) {
          case 'EXPIRED':
            dispatch(savePasswordExpirationDays(-1, true))
            break;
          case 'EXPIRES_SOON':
            const daysUntilExp = response.data.item.days_until_expiration
            dispatch(savePasswordExpirationDays(daysUntilExp, true))
            break;
          default:
            dispatch(savePasswordExpirationDays(null, true))
            break;
        }
      },
      failure: (err) => {
        logger.error('err: ', err);
      },
    },
  };
}
