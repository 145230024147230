import styled from '@emotion/styled';
import { rgba } from 'polished';
import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';
import Button from 'components/npm/clip-ui/Button';

export const BaseButton = styled(Button)({
  minHeight: 34,
});

export const SubmitButton = (props) => (
  <BaseButton color="primary" type="submit" {...props} />
);

const Button1 = (props) => (
  <BaseButton variant="outlined" color="secondary" type="button" {...props} />
);
export const CancelButton = styled(Button1)(({ theme }) => ({
  border: `1px solid ${rgba(theme.palette.secondary.main, 0.65)}`,
}));

const Button2 = (props) => (
  <BaseButton fullWidth={false} type="submit" {...props} />
);

export const ProcessButton = styled(Button2)(({ theme }) => ({
  backgroundColor: theme.palette.naranja.cerulean,
  letterSpacing: '.1rem',
  minWidth: theme.spacing.unit * 20,
  '&:hover': {
    backgroundColor: theme.palette.naranja.cerulean,
    opacity: '.9',
  },
}));

export const FormV3ProcessButton = styled(Button2)(({ theme }) => ({
  backgroundColor: theme.palette.naranja.bloodOrange,
  width: '100%',
  height: `${theme.spacing.unit * 6}px`,
  borderRadius: '17.6',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: theme.palette.naranja.bloodOrange,
    opacity: '.9',
  },
}));

export const ProcessButtonv2 = styled(Button2)(({ theme }) => ({
  backgroundColor: theme.palette.naranja.bloodOrange,
  letterSpacing: '0.5px',
  width: '188px',
  height: '36px',
  '&:hover': {
    backgroundColor: theme.palette.naranja.bloodOrange,
    opacity: '.9',
  },
  [theme.breakpoints.down('xs')]: {
    width: '103px',
    height: '36px',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.naranja.bloodOrange,
    letterSpacing: '0.5px',
    width: '188px',
    height: '34px',
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: theme.palette.naranja.bloodOrange,
      opacity: '.9',
    },
  },
}));

export const ProcessButtonv3 = (props) => {
  const classes = useStyles();
  return <Button2 classes={{ root: classes.root }} {...props} />;
};
