const styles = (theme) => {
  return {
    root: {
      width: '100%',
      maxWidth: '22.5rem',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: '#FFFFFF',
      overflow: 'auto',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      boxSizing: 'border-box',
    },
    tooltipTitle: {
      fontSize: '20px',
      marginBottom: '20px',
    },
    lineHeight15: {
      lineHeight: '1.5',
      margin: 0,
    },
    noMargin: {
      marginBottom: '1rem',
    },
    noMarginTop: {
      marginTop: 0,
    },
    buttonMargin: {
      marginBottom: '1rem',
    },
  };
};

export default styles;
