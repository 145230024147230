import React, { useState, useEffect, createRef } from 'react';
import text from './text';
import styles from './styles';
import PropTypes from 'prop-types';
import CurpTooltip from './CurpTooltip';
import BerlinService from '../../service';
import Drawer from '@material-ui/core/Drawer';
import WaitingForDataValidation from '../WaitingForDataValidation';
import HelpIcon from '../../../../assets/images/berlin/helpIcon.svg';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipButton, ClipTypography, ClipInput } from '@clipmx/clip-storybook';

const useStyles = makeStyles(styles);

const InputCurp = (props) => {
  const { step, setStep, setCurp, curp } = props;
  const classes = useStyles(props);
  const textInput = createRef();

  const [tooltipIsOpen, setTooltip] = useState();
  const [waiting, setWaiting] = useState(false);
  const [curpError, setError] = useState('');

  useEffect(() => {
    if (curpError !== '' && curp !== '') {
      setError('');
    }
  }, [curp]);

  const toggleTooltip = (isTooltipOpen) => {
    setTooltip(isTooltipOpen);
    textInput.current.focus();
  };

  const verifyCurp = async () => {
    setWaiting(true);
    try {
      let result = await BerlinService.getDataFromCurp(curp);
      setStep(step + 1);
      setWaiting(false);
    } catch (error) {
      if (error.response) {
        setError(text.invalidError);
        setWaiting(false);
        return;
      }
      setWaiting(false);
    }
  };

  if (waiting) {
    return <WaitingForDataValidation version="1" />;
  }

  return (
    <div className={classes.container}>
      <ClipTypography variant="h3">{text.inputCurp}</ClipTypography>
      <div className={classes.info}>
        <ClipTypography>{text.info}</ClipTypography>
      </div>
      <ClipInput
        type="text"
        color="primary"
        fullWidth={true}
        label={text.curp}
        inputRef={textInput}
        error={curpError !== ''}
        errormessage={curpError}
        clickable
        adornmentIcon={
          <div onClick={() => setTooltip(true)}>
            {text.help}
            <img src={HelpIcon} className={classes.helpIcon} alt="" />
          </div>
        }
        value={curp}
        onChange={setCurp}
      />
      <div className={classes.btnContainer}>
        <ClipButton
          variant="contained"
          color="primary"
          disabled={curp.length < 18}
          onClick={() => verifyCurp()}
        >
          {text.continue}
        </ClipButton>
      </div>
      <Drawer
        className={classes.drawer}
        classes={{ paper: classes.paper }}
        anchor="bottom"
        open={tooltipIsOpen}
      >
        <CurpTooltip toggleTooltip={toggleTooltip} />
      </Drawer>
    </div>
  );
};

InputCurp.propTypes = {
  setCurpInfo: PropTypes.func,
  step: PropTypes.number,
  totalStep: PropTypes.number,
  prevStep: PropTypes.func,
  setSkipCurp: PropTypes.func,
  skipCurp: PropTypes.bool,
  setCurp: PropTypes.func,
  curpValue: PropTypes.string,
  setFailedToCreateCurp: PropTypes.func,
  failedToCreateCurp: PropTypes.bool,
};

InputCurp.defaultProps = {
  setCurpInfo: () => {},
  step: 5,
  totalStep: 10,
  prevStep: () => {},
  setSkipCurp: () => {},
  skipCurp: false,
  setCurp: () => {},
  curpValue: '',
  setFailedToCreateCurp: () => {},
  failedToCreateCurp: false,
};

export default InputCurp;
