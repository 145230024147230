import React from 'react';
import PropTypes from 'prop-types';

const ClipLogoIcon = ({ switched, ...props }) => {
  const primary = '#FC4C02';
  const secondary = '#FFFFFF';
  const backgroundColor = switched ? secondary : primary;
  const wordColor = switched ? primary : secondary;

  return (
    <svg width="48" height="48" viewBox="0 0 49 49" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.999 8.2459C48.9773 3.68852 45.2763 0 40.7143 0C39.5811 0 38.4699 0.215216 37.5174 0.640086L7.32872 13.4596C3.02632 15.2792 0 19.5431 0 24.5V40.7148C0 45.2892 3.70875 49 8.28589 49C9.44636 49 10.5501 48.7592 11.554 48.3281L41.6149 35.5648C45.9465 33.7583 49 29.4804 49 24.5L48.999 8.2459Z"
        fill={backgroundColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.152 31.0261C20.7581 31.0261 20.4397 30.7069 20.4397 30.3138V15.2571C20.4397 14.8625 20.7581 14.5432 21.152 14.5432C21.5463 14.5432 21.8655 14.8625 21.8655 15.2571V30.3138C21.8655 30.7069 21.5463 31.0261 21.152 31.0261Z"
        fill={wordColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.807 30.3134V18.6892C26.807 18.2961 26.4878 17.9768 26.0919 17.9768C25.6996 17.9768 25.3809 18.2961 25.3809 18.6892V30.3134C25.3809 30.7069 25.6996 31.0262 26.0919 31.0262C26.4878 31.0262 26.807 30.7069 26.807 30.3134Z"
        fill={wordColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.807 15.299V15.257C26.807 14.8633 26.4878 14.5439 26.0919 14.5439C25.6996 14.5439 25.3809 14.8633 25.3809 15.257V15.299C25.3809 15.6933 25.6996 16.0117 26.0919 16.0117C26.4878 16.0117 26.807 15.6933 26.807 15.299Z"
        fill={wordColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7148 29.2376C16.993 28.9595 16.993 28.508 16.7148 28.2308C16.4359 27.9516 15.9851 27.9516 15.7079 28.2308C13.6518 30.2835 10.3092 30.2835 8.25394 28.2308C6.19971 26.174 6.19971 22.8316 8.25394 20.7754C9.24944 19.7814 10.5729 19.2326 11.9802 19.2326C13.3889 19.2326 14.7116 19.7814 15.7064 20.7754C15.9851 21.054 16.4359 21.054 16.7148 20.7754C16.993 20.4967 16.993 20.0466 16.7148 19.7684C15.4507 18.5033 13.7698 17.8064 11.9802 17.8064C10.1924 17.8064 8.5115 18.5033 7.24702 19.7684C4.63543 22.3778 4.63543 26.6258 7.24702 29.2376C8.5516 30.5437 10.2654 31.1951 11.9811 31.1951C13.6955 31.1951 15.4088 30.5437 16.7148 29.2376Z"
        fill={wordColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.7474 33.7454V24.5031C31.7474 21.5974 34.1103 19.2338 37.0172 19.2338C39.9229 19.2338 42.2872 21.5974 42.2872 24.5031C42.2872 27.4097 39.9229 29.7735 37.0172 29.7735C35.3413 29.7735 34.207 29.1335 34.1983 29.1295C33.8601 28.9301 33.4223 29.0423 33.223 29.3838C33.0254 29.7231 33.1392 30.1593 33.4779 30.3588C33.5365 30.3935 34.935 31.1983 37.0172 31.1983C40.7096 31.1983 43.7124 28.1953 43.7124 24.5031C43.7124 20.8115 40.7096 17.8079 37.0172 17.8079C33.3249 17.8079 30.3223 20.8115 30.3223 24.5031V33.7454C30.3223 34.1382 30.6413 34.459 31.0344 34.459C31.4271 34.459 31.7474 34.1382 31.7474 33.7454Z"
        fill={wordColor}
      />
    </svg>
  );
};

ClipLogoIcon.propTypes = {
  switched: PropTypes.bool,
};

export default ClipLogoIcon;
