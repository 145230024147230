import BerlinService from '../../../service';
import * as Crypto from '../../../CryptoManager/CryptoManager';
import * as CryptoRequests from '../../../CryptoManager/RequestsCryptoManager';
import * as CryptoUtils from '../../../CryptoManager/utils';

export const getNip = async(user_external_id, card_external_id, phone_number) => {
    try {
        await Crypto.init();
        const serverKey = await CryptoRequests.GetSwapServerKey(
            Crypto.keys().publicKey.base64,
            Crypto.keys().iv.hex,
        );
        const resultSeed = await BerlinService.tokenSeed({id: user_external_id});
        if (!localStorage.getItem('deviceId')) {
            localStorage.setItem('deviceId', Crypto.generateUuid());
        }
        const deviceId = localStorage.getItem('deviceId');
        const resultToken = await BerlinService.actionToken({
            id: user_external_id, 
            device_id: deviceId});
        const seedToken = deviceId + resultToken.data.data.action_token + resultSeed.data.data.seed;
        const phoneLastEight = phone_number.substring(2)
        const dynamicIv = phoneLastEight + phoneLastEight.split('').reverse().join('');
        const dynamicToken = CryptoUtils.encryptWithIV(seedToken, dynamicIv);

        const result = await BerlinService.getSwapCardNip({
            user_external_id: user_external_id,
            card_external_id: card_external_id,
            request_id: serverKey.request_id,
            device_id: deviceId,
            token: dynamicToken,
        });
        await Crypto.setServerPublicKey(serverKey.public_key);
        const dencrypted = await Crypto.decrypt(result.data.data.nip);
        const decoder = new TextDecoder('utf-8');
        const decodedString = decoder.decode(dencrypted);

        return { nip: decodedString }
    } catch (error) {
        return { error: error.response.data?.error?.message || error.message }
    }
}
