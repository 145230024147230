import styled from '@emotion/styled';

import ClipCard from 'components/common/Card';
import styledWithClasses from 'helpers/StyledWithClasses';
import grey from '@material-ui/core/colors/grey';

export const Group = styled.div(({ theme }) => ({
  maxWidth: 600,
  width: '100%',
  '& .group-card': {
    background: theme.palette.primary.contrastText,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.08)',
  },
}));

export const HeaderTitleWithoutIcon = styled.h3(({ theme }) => ({
  margin: `0 0 ${theme.spacing.unit}px 0`,
  color: theme.palette.naranja.slate,
  fontSize: '20px',
  fontWeight: theme.typography.fontWeightMedium,
  display: 'flex',
}));

export const HeaderTitle = styled.h3(({ theme }) => ({
  margin: `0 0 ${theme.spacing.unit}px 0`,
  color: theme.palette.naranja.slate,
  fontSize: '24px',
  lineHeight: '21px',
  fontWeight: theme.typography.fontWeightBold,
  display: 'flex',
  textAlign: 'left',
}));

export const HeaderSubTitle = styled.h4(({ theme }) => ({
  margin: `0 0 ${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
  color: grey[400],
  fontSize: `${theme.typography.fontSize}px`,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '17px',
}));

export const Card = styledWithClasses(
  styled(ClipCard)(({ theme }) => ({
    width: '100%',
    float: 'left',
    marginBottom: `${theme.spacing.unit * 1.5}px`,
    paddingBottom: `${theme.spacing.unit * 5}px`,
    paddingTop: `${theme.spacing.unit * 1}px`,
    overflow: 'unset',
    '& .contentRoot': {
      display: 'grid',
      textAlign: 'center',
    },
    '& .headerRoot': {
      [theme.breakpoints.up('md')]: {
        height: '60px',
      },
    },
    '& .headerTitle': {
      lineHeight: `${theme.spacing.unit * 4}px`,
    },
  })),
  {
    contentRoot: 'contentRoot',
    headerTitle: 'headerTitle',
    headerRoot: 'headerRoot',
  },
);

export const Footer = styled.div(({ theme }) => ({
  width: '100%',
  position: 'relative',
  display: 'inline-block',
  color: `${theme.palette.naranja.grey}`,
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '17px',
  top: '-16px',
}));
