import {
  createPostRequest,
  createGetRequest,
} from '../../../middleware/apiResolve/reduxUtils';
import {
  getToken,
  saveToken,
  clearToken,
  BUM_TOKEN_NAME,
} from '../../../helpers/tokenHelpers';
import { loginUserWithToken } from '../../auth/redux/actions';
import {
  MERCHANT_BUM,
  MERCHANT_BUM_LIST,
  MERCHANT_BUM_SWITCH,
  SET_NONCE_HAS_EXPIRED,
  SET_EMAIL_LIST,
  CLEAR_EMAIL_SENT_LISTS,
} from './types';

export function postInvites(
  invites,
  nonce,
  onSuccess = () => {},
  onFailure = () => {},
) {
  const route = '/mlm/request';
  return {
    type: createPostRequest(MERCHANT_BUM),
    payload: {
      apiEndPoint: 'v3',
      route,
      data: {
        emails: invites,
      },
      headers: {
        nonce,
      },
      success: onSuccess,
      failure: onFailure,
      autoSpinner: true,
    },
  };
}

export function getMerchantList(
  nonce,
  onSuccess = () => {},
  onFailure = () => {},
) {
  const route = '/mlm/descendants';
  return {
    type: createGetRequest(MERCHANT_BUM_LIST),
    payload: {
      apiEndPoint: 'v3',
      route,
      headers: {
        nonce,
      },
      success: onSuccess,
      failure: onFailure,
    },
  };
}

export function switchMerchant(
  merchantId,
  nonce,
  onSuccess = () => {},
  onFailure = () => {},
) {
  const route = '/mlm/switch';
  return {
    type: createPostRequest(MERCHANT_BUM_SWITCH),
    payload: {
      apiEndPoint: 'v3',
      route,
      data: {
        child_merchant_id: merchantId,
      },
      headers: {
        nonce,
      },
      autoSpinner: true,
      success: (res, store) => {
        const { dispatch } = store;
        const {
          data: {
            item: { token },
          },
        } = res;
        const originalToken = getToken();
        saveToken(token);
        saveToken(originalToken, BUM_TOKEN_NAME);
        dispatch(loginUserWithToken(token));
        onSuccess(res, store);
      },
      failure: onFailure,
    },
  };
}

export function getParentName(
  token,
  onSuccess = () => {},
  onFailure = () => {},
) {
  const route = `/mlm/token/${token}`;
  return {
    type: createGetRequest(MERCHANT_BUM),
    payload: {
      apiEndPoint: 'v3',
      route,
      success: onSuccess,
      failure: onFailure,
      autoSpinner: true,
    },
  };
}

export function postAcceptInvite(
  token,
  onSuccess = () => {},
  onFailure = () => {},
) {
  const route = '/mlm/accept';
  return {
    type: createPostRequest(MERCHANT_BUM),
    payload: {
      apiEndPoint: 'v3',
      route,
      data: {
        token,
        tc_accepted: true,
        tc_version: '1.0.0',
      },
      success: onSuccess,
      failure: onFailure,
      autoSpinner: true,
    },
  };
}

export function setNonceHasExpired(value) {
  return {
    type: SET_NONCE_HAS_EXPIRED,
    payload: {
      data: { nonceHasExpired: value },
    },
  };
}

export function setEmailList(emailList) {
  return {
    type: SET_EMAIL_LIST,
    payload: {
      data: { emailList },
    },
  };
}

export function clearEmailSentLists() {
  return {
    type: CLEAR_EMAIL_SENT_LISTS,
  };
}

export function getBackMainMerchant() {
  return (dispatch) => {
    const originalMerchantToken = getToken(BUM_TOKEN_NAME);
    clearToken(BUM_TOKEN_NAME);
    dispatch(loginUserWithToken(originalMerchantToken));
  };
}
