import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { connect } from 'react-redux';
import { BerlinSpinner } from '../BerlinSpinner';
import { change, getFormValues } from 'redux-form';
import { Forms } from '../../../constants/AppConstants';
import Form from './Form';
import text from './text';
import Dialog from './CloseDialog';
import Questions from './Questions';
import InputCurp from './InputCurp';
import BerlinService from '../service';
import DigitalSign from './DigitalSign';
import ClaveElector from './ClaveElector';
import FinishScreen from './FinishScreen';
import ErrorScreen from './ErrorScreen';
import ProgressNavigator from '../Commons/ProgressNavigator';
import {EVENTS, Clevertap} from '../../../helpers/Clevertap'
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

const useStyles = makeStyles(styles);

const UpgradeAccount = (props) => {
  const classes = useStyles(props);

  const [curp, setCurp] = useState('');
  const [signature, setSignature] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [publicPerson, setPublicPerson] = useState(false);
  const [politicalRelative, setPoliticalRelative] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [politicalPartner, setPoliticalPartner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [questions, setQuestions] = useState({
    question1: undefined,
    form1: { job: '', period: '' },
    question2: undefined,
    form2: { name: '', job: '', period: '' },
    question3: undefined,
    form3: { name: '', job: '', period: '' },
    question4: undefined,
    question5: undefined,
    question6: undefined,
  });

  useEffect(() => {
    if (props) {
      if (firstLoad) {
        switch (props.upgrade_status) {
          case 'REQUESTED':
            setStep(13);
            setLoading(false);
            setFirstLoad(false);
            Clevertap.event(
              EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT, 
              EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.REQUESTED,
              { status: 'requested' });
            break;
          case 'REJECTED':
            Clevertap.event(EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT, 
              EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.REQUESTED,
              { status: 'fail' });
            setStep(14);
            setLoading(false);
            setFirstLoad(false);
            break;
          default:
            if (props.account_rep.has_legal_identifier) {
              setStep(2);
            } else {
              setStep(1);
            }
            setLoading(false);
            setFirstLoad(false);
            break;
        }
      }
    } else {
      setLoading(false);
      setFirstLoad(false);
    }
  }, [props]);

  useEffect(() => {
    props.dispatch(change(Forms.BERLIN_N3, 'questions', questions));
  }, [questions]);

  const checkBackDisabled = () => {
    if (step === 1) {
      return true;
    } else if (props.account_rep.has_legal_identifier === true && step === 2) {
      return true;
    }
    return false;
  };

  const checkDisabledForm1 = () => {
    if (!questions.form1.job || questions.form1.job.trim() === '') {
      return true;
    } else if (
      !questions.form1.period ||
      questions.form1.period.trim() === ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkDisabledForm = (form) => {
    if (!form.name || form.name.trim() === '') {
      return true;
    } else if (!form.job || form.job.trim() === '') {
      return true;
    } else if (!form.period || form.period.trim() === '') {
      return true;
    } else {
      return false;
    }
  };

  const handleStepBack = () => {
    switch (step) {
      case 5:
        publicPerson ? setStep(step - 1) : setStep(step - 2);
        break;
      case 7:
        politicalRelative ? setStep(step - 1) : setStep(step - 2);
        break;
      case 9:
        politicalPartner ? setStep(step - 1) : setStep(step - 2);
        break;
      default:
        setStep(step - 1);
    }
  };

  const sendUpgradeRequest = () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      try {
        const { upgradeN3Values, merchant } = props;
        const { questions, electorClave } = upgradeN3Values;
        const {
          question1,
          question2,
          question3,
          question4,
          question5,
          question6,
          form2,
          form3,
        } = questions;
        const payload = {
          merchant_id: merchant.info.id,
          legal_identifier: curp,
          occupation: merchant.info.industry_name,
          profession: merchant.info.industry_name,
          business_sector: merchant.info.industry_name,
          location: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
          electoral_id: electorClave,
          public_person: question1,
          relationship_public_person: question2,
          relationship_name: form2.name,
          relationship_position: form2.job,
          relationship_period: form2.period,
          partner_public_person: question3,
          partner_position: form3.name,
          partner_period: form3.job,
          partner_name: form3.period,
          monthly_amount: question4,
          monthly_times: question5,
          monthly_frequency: question6,
        };
        await BerlinService.postUpgradeN3(payload);
        setLoading(false);
        Clevertap.event(EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT, 
          EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.REQUESTED,
          { status: 'requested' });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const renderSteps = () => {
    // eslint-disable-next-line default-case
    switch (step) {
      case 1:
        return (
          <InputCurp
            step={step}
            setStep={setStep}
            curp={curp}
            setCurp={({ target }) => setCurp(target.value)}
          />
        );
      case 2:
        return (
          <ClaveElector
            step={step}
            setStep={setStep}
            setOpen={setOpenDialog}
          />
        );
      case 3:
        return (
          <Questions
            title={text.step1}
            step={step}
            buttons={[
              {
                text: 'Si',
                disabled: false,
                action: () => {
                  setPublicPerson(true);
                  setQuestions({ ...questions, question1: true });
                  setStep(step + 1);
                },
                event: EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT,
                clevertapAction: EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.QUESTION_1OF3YES_CLICKED,
              },
              {
                text: 'No',
                disabled: false,
                action: () => {
                  setPublicPerson(false);
                  setQuestions({ ...questions, question1: false });
                  setStep(step + 2);
                },
              },
            ]}
          />
        );
      case 4:
        return (
          <Form
            title={text.step2}
            step={step}
            inputs={[
              {
                label: 'Puesto',
                error: '',
                value: questions.form1.job,
                setValue: (e) =>
                  setQuestions((prevState) => {
                    prevState.form1.job = e.target.value;
                    return { ...prevState };
                  }),
              },
              {
                label: 'Periodo',
                error: '',
                value: questions.form1.period,
                setValue: (e) =>
                  setQuestions((prevState) => {
                    prevState.form1.period = e.target.value;
                    return { ...prevState };
                  }),
              },
            ]}
            btn={{
              disabled: checkDisabledForm1(),
              text: 'Continuar',
              action: () => {
                setStep(step + 1);
              },
            }}
          />
        );
      case 5:
        return (
          <Questions
            title={text.step3}
            step={step}
            buttons={[
              {
                text: 'Si',
                disabled: false,
                action: () => {
                  setPoliticalRelative(true);
                  setQuestions({ ...questions, question2: true });
                  setStep(step + 1);
                },
                event: EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT,
                clevertapAction: EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.QUESTION_2OF3YES_CLICKED,
              },
              {
                text: 'No',
                disabled: false,
                action: () => {
                  setPoliticalRelative(false);
                  setQuestions({ ...questions, question2: false });
                  setStep(step + 2);
                },
              },
            ]}
          />
        );
      case 6:
        return (
          <Form
            title={text.step4}
            step={step}
            inputs={[
              {
                label: 'Nombre Completo',
                error: '',
                value: questions.form2.name,
                setValue: (e) =>
                  setQuestions((prevState) => {
                    prevState.form2.name = e.target.value;
                    return { ...prevState };
                  }),
              },
              {
                label: 'Puesto',
                error: '',
                value: questions.form2.job,
                setValue: (e) =>
                  setQuestions((prevState) => {
                    prevState.form2.job = e.target.value;
                    return { ...prevState };
                  }),
              },
              {
                label: 'Periodo',
                error: '',
                value: questions.form2.period,
                setValue: (e) =>
                  setQuestions((prevState) => {
                    prevState.form2.period = e.target.value;
                    return { ...prevState };
                  }),
              },
            ]}
            btn={{
              disabled: checkDisabledForm(questions.form2),
              text: 'Continuar',
              action: () => setStep(step + 1),
            }}
          />
        );
      case 7:
        return (
          <Questions
            title={text.step5}
            step={step}
            buttons={[
              {
                text: 'Si',
                disabled: false,
                action: () => {
                  setPoliticalPartner(true);
                  setQuestions({ ...questions, question3: true });
                  setStep(step + 1);
                },
                event: EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT,
                clevertapAction: EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.QUESTION_3OF3YES_CLICKED,
              },
              {
                text: 'No',
                disabled: false,
                action: () => {
                  setPoliticalPartner(false);
                  setQuestions({ ...questions, question3: false });
                  setStep(step + 2);
                },
              },
            ]}
          />
        );
      case 8:
        return (
          <Form
            title={text.step6}
            step={step}
            inputs={[
              {
                label: 'Nombre Completo',
                error: '',
                value: questions.form3.name,
                setValue: (e) =>
                  setQuestions((prevState) => {
                    prevState.form3.name = e.target.value;
                    return { ...prevState };
                  }),
              },
              {
                label: 'Puesto',
                error: '',
                value: questions.form3.job,
                setValue: (e) =>
                  setQuestions((prevState) => {
                    prevState.form3.job = e.target.value;
                    return { ...prevState };
                  }),
              },
              {
                label: 'Periodo',
                error: '',
                value: questions.form3.period,
                setValue: (e) =>
                  setQuestions((prevState) => {
                    prevState.form3.period = e.target.value;
                    return { ...prevState };
                  }),
              },
            ]}
            btn={{
              disabled: checkDisabledForm(questions.form3),
              text: 'Continuar',
              action: () => {
                setStep(step + 1);
              },
              event: EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT,
              clevertapAction: EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.QUESTIONS_INFO_INTRODUCED,
            }}
          />
        );
      case 9:
        return (
          <Questions
            title={text.step7}
            step={step}
            buttons={[
              {
                text: '$1 - $10,000',
                disabled: false,
                action: () => {
                  setQuestions({ ...questions, question4: 10000 });
                  setStep(step + 1);
                },
              },
              {
                text: '$10,001 - $50,000',
                disabled: false,
                action: () => {
                  setQuestions({
                    ...questions,
                    question4: 50000,
                  });
                  setStep(step + 1);
                },
              },
              {
                text: '$50,001 - adelante',
                disabled: false,
                action: () => {
                  setQuestions({
                    ...questions,
                    question4: 499000,
                  });
                  setStep(step + 1);
                },
              },
            ]}
          />
        );
      case 10:
        return (
          <Questions
            title={text.step8}
            subtitle={text.subtitle}
            step={step}
            buttons={[
              {
                text: 'Hasta 30 veces',
                disabled: false,
                action: () => {
                  setQuestions({ ...questions, question5: 30 });
                  setStep(step + 1);
                },
              },
              {
                text: 'Entre 30 y 60 veces',
                disabled: false,
                action: () => {
                  setQuestions({
                    ...questions,
                    question5: 60,
                  });
                  setStep(step + 1);
                },
              },
              {
                text: 'Más de 60 veces',
                disabled: false,
                action: () => {
                  setQuestions({
                    ...questions,
                    question5: 90,
                  });
                  setStep(step + 1);
                },
              },
            ]}
          />
        );
      case 11:
        return (
          <Questions
            title={text.step9}
            step={step}
            buttons={[
              {
                text: 'Diario',
                disabled: false,
                action: () => {
                  setStep(step + 1);
                  setQuestions({ ...questions, question6: 'Diario' });
                },
                event: EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT,
                payload: { option: '1' },
                clevertapAction:EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.QUESTION6_VIEWED, 
              },
              {
                text: 'Quincenal',
                disabled: false,
                action: () => {
                  setStep(step + 1);
                  setQuestions({ ...questions, question6: 'Quincenal' });
                },
                event: EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT,
                payload: { option: '2' },
                clevertapAction:EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.QUESTION6_VIEWED, 
              },
              {
                text: 'Semanal',
                disabled: false,
                action: () => {
                  setStep(step + 1);
                  setQuestions({ ...questions, question6: 'Semanal' });
                },
                event: EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT,
                payload: { option: '3' },
                clevertapAction:EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.QUESTION6_VIEWED, 
              },
            ]}
          />
        );
      case 12:
        return (
          <DigitalSign
            loading={loading}
            setLoading={setLoading}
            sendUpgradeRequest={sendUpgradeRequest}
            step={step}
            setStep={setStep}
            setSignature={setSignature}
          />
        );
      case 13:
        return <FinishScreen />;
      case 14:
        return (
          <ErrorScreen
            setStep={() =>
              props.account_rep.has_legal_identifier ? setStep(2) : setStep(1)
            }
          />
        );
    }
  };

  return (
    <>
      <div className={classes.progressNavigatorContainer}>
        {step !== 13 && step !== 14 && !loading && (
          <ProgressNavigator
            progress={step}
            total={12}
            handleClick={() =>
              checkBackDisabled() ? setOpenDialog(true) : handleStepBack()
            }
            handleClose={() => setOpenDialog(true)}
          />
        )}
      </div>
      <Dialog open={openDialog} setOpen={setOpenDialog} />
      {loading ? <BerlinSpinner /> : renderSteps()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    documentType: state.berlin.documentType,
    upgradeN3Values: getFormValues(Forms.BERLIN_N3)(state),
    merchant: state.merchant,
    upgrade_status: state.berlin.account_representation.upgrade_status,
    account_rep:
      state.berlin.account_representation.account_holder_representation,
    user: state.user,
  };
};

export default connect(mapStateToProps)(UpgradeAccount);
