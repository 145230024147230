import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Card from '../../../../components/common/Card';
import CalcIcon from '../../../../assets/images/icons/calc.svg';
import PrestaClipBanner from '../../../../assets/images/logo-loans.svg';
import {
  LoansTabs,
  PreapprovalStatus,
} from '../../../../constants/AppConstants';
import { getPreapprovals } from '../../redux/selectors';
import GoogleAnalytics from '../../GoogleAnalytics';
import LoansRedirection from '../../Commons/Redirection';
import { RefillFooter } from '../../Commons/Footer';
import { ExpireWarningContent } from '../../MrPresta/styled';
import Description from '../Description';
import { styles } from '../styles';
import text from '../text';
import { Clevertap, EVENTS } from 'helpers/Clevertap';
import { commonPayload } from 'components/loans/Commons/Clevertap';

export const NotElegible = (props) => {
  const { classes, preapprovals, showCashAdvance } = props;
  const typographyStyles = {
    body1: classes.typographyRoot,
    display1: classes.typographyBody3,
    display2: classes.typographyBody4,
    title: classes.typographyTitle,
    subheading: classes.typographySubTitle,
  };
  const loansFormVersion = 'v4';
  const providerCode = 'none';

  const withoutPreapprovals =
    preapprovals.length === 0 ||
    preapprovals.length ===
      preapprovals.filter(
        ({ status, loan_id }) =>
          [
            PreapprovalStatus.DECLINED,
            PreapprovalStatus.CANCELLED,
            PreapprovalStatus.CANCELED,
          ].includes(status) && !loan_id,
      ).length;

  useEffect(() => {
    Clevertap.event(
      EVENTS.LOANS.NEW_OFFERS_VIEW_AVAILABLES,
      null,
      commonPayload(
        { proxy_merchant_token: props.merchantInfo?.proxy_id?.proxy_merchant_token }, 
        { showCashAdvance, loansFormVersion, providerCode}, {
        offer: 'unavailable',
      }),
    )
  }, [])

  return (
    <LoansRedirection tab={LoansTabs.NEW_OFFER}>
      {withoutPreapprovals && (
        <GoogleAnalytics>
          <Grid item sm={12} xs={12} className={classes.gridCard}>
            <div className={classes.bannerContainer}>
              <img
                className={classes.bannerTop}
                alt="PrestaClip Banner"
                src={PrestaClipBanner}
              />
            </div>
            <Card className={clsx('margin24', classes.crediCard)}>
              <div className={clsx('notElegible', classes.textContainer)}>
                <Typography variant={'subheading'} classes={typographyStyles}>
                  {text.subtitle}
                </Typography>
                <Typography variant={'title'} classes={typographyStyles}>
                  {text.titleNotElegible}
                </Typography>
                <div className={classes.separator} />
                <Typography variant={'body1'} classes={typographyStyles}>
                  {text.messageNotElegible}
                </Typography>
              </div>
              <div className={clsx(classes.alertContainer, 'notElegible')}>
                <div className={classes.expireWarning}>
                  <img
                    className={classes.calcLeftIcon}
                    alt="PrestaClip Calc"
                    src={CalcIcon}
                  />
                  <ExpireWarningContent>
                    <ReactMarkdown source={text.adviceNotElegible} />
                  </ExpireWarningContent>
                </div>
              </div>
            </Card>
            <Card className={clsx('margin24', classes.crediCard)}>
              <div className={classes.descriptionContainer}>
                <Description />
              </div>
            </Card>
            <RefillFooter />
          </Grid>
        </GoogleAnalytics>
      )}
    </LoansRedirection>
  );
};
NotElegible.propTypes = {
  classes: PropTypes.object.isRequired,
  preapprovals: PropTypes.array.isRequired,
};
NotElegible.defaultProps = {
  classes: {},
  preapprovals: [],
};

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
    merchantInfo: state.merchant.info,
    showCashAdvance: state.loans.showCashAdvance,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(NotElegible));
