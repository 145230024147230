import {
  dataLoading,
  dataLoaded,
  dataLoadFailed,
} from '../../../actions/AppActions';
import {
  LOAD_COLLABORATOR_LIST,
  LOAD_COLLABORATOR,
  DELETE_COLLABORATOR,
  CLEAR_COLLABORATOR,
  CREATE_COLLABORATOR,
  UPDATE_COLLABORATOR,
} from './types';

import Collaborators from '../service';

export const fetchCollaboratorList = () => (dispatch) => {
  dispatch(dataLoading(LOAD_COLLABORATOR_LIST));

  return Collaborators.list()
    .then((data) => {
      dispatch(dataLoaded(LOAD_COLLABORATOR_LIST, data));
      dispatch({ type: LOAD_COLLABORATOR_LIST, payload: { data } });
    })
    .catch((err) => {
      dispatch(dataLoadFailed(LOAD_COLLABORATOR_LIST, err.message));
    });
};

export const fetchCollaborator = (id) => (dispatch) => {
  dispatch(dataLoading(LOAD_COLLABORATOR));

  return Collaborators.get(id)
    .then((data) => {
      dispatch(dataLoaded(LOAD_COLLABORATOR, data));
      dispatch({ type: LOAD_COLLABORATOR, payload: { data } });
    })
    .catch((err) => {
      dispatch(dataLoadFailed(LOAD_COLLABORATOR, err.message));
    });
};

export const deleteCollaborator = (user) => (dispatch) => {
  dispatch(dataLoading(DELETE_COLLABORATOR));

  return Collaborators.delete(user)
    .then(() => {
      dispatch(dataLoaded(DELETE_COLLABORATOR));
      dispatch(fetchCollaboratorList());
    })
    .catch((err) => {
      dispatch(
        dataLoadFailed(
          DELETE_COLLABORATOR,
          `Unable to delete collaborator ${user.id}: ${err.message}`,
        ),
      );
    });
};

export const clearCollaborator = () => ({
  type: CLEAR_COLLABORATOR,
});

export const createCollaborator = (values) => (dispatch) => {
  dispatch(dataLoading(CREATE_COLLABORATOR));

  return Collaborators.create(values)
    .then(() => {
      dispatch(dataLoaded(CREATE_COLLABORATOR));
      dispatch(fetchCollaboratorList());
    })
    .catch((err) => {
      dispatch(
        dataLoadFailed(
          CREATE_COLLABORATOR,
          `Unable to create collaborator: ${err.message}`,
        ),
      );
      throw err;
    });
};

export const updateCollaborator = (id, values) => (dispatch) => {
  dispatch(dataLoading(UPDATE_COLLABORATOR));

  return Collaborators.update({ id, ...values })
    .then(() => {
      dispatch(dataLoaded(UPDATE_COLLABORATOR, id));
      dispatch(fetchCollaboratorList());
    })
    .catch((err) => {
      dispatch(
        dataLoadFailed(
          UPDATE_COLLABORATOR,
          `Unable to update collaborator ${id}: ${err.message}`,
        ),
      );
      throw err;
    });
};
