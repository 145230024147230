import React from 'react';
import Transfers from './Transfers';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import OldTransfers from './OldTransfers';

const TransfersIndex = ({
  SCREENS,
  setActiveScreen,
  flags: { financialFrecuentContactsWeb },
}) => {
  return financialFrecuentContactsWeb ? (
    <Transfers SCREENS={SCREENS} setActiveScreen={setActiveScreen} />
  ) : (
    <OldTransfers SCREENS={SCREENS} setActiveScreen={setActiveScreen} />
  );
};

export default withLDConsumer()(TransfersIndex);
