export default {
  name: 'Nombre',
  fLastName: 'Apellido paterno',
  mLastName: 'Apellido materno',
  titleBirthday: 'Fecha de nacimiento',
  titleGender: 'Género',
  female: 'Femenino',
  male: 'Masculino',
  address1: 'Calle y número exterior',
  address2: 'Número interior',
  state: 'Estado',
  municipality: 'Municipio / Delegación',
  colony: 'Selecciona tu colonia',
  postalCode: 'Código Postal',
  save: 'Guardar',
  cancel: 'Cancelar',
  year: 'Año',
  month: 'Mes',
  day: 'Día',
  maritalStatus: 'Estado Civil',
  maritalOptions: [
    {
      id: 'Soltero(a)',
      name: 'Soltero(a)',
    },
    {
      id: 'Casado(a)',
      name: 'Casado(a)',
    },
    {
      id: 'Casado(a) con bienes mancomunados',
      name: 'Casado(a) con bienes mancomunados',
    },
    {
      id: 'Casado(a) con bienes separados',
      name: 'Casado(a) con bienes separados',
    },
    {
      id: 'Viudo(a)',
      name: 'Viudo(a)',
    },
    {
      id: 'Union Libre',
      name: 'Union Libre',
    },
    {
      id: 'Divorciado',
      name: 'Divorciado',
    },
    {
      id: 'Separado',
      name: 'Separado',
    },
  ],
  monthsOptions: [
    {
      id: '1',
      name: 'Enero',
    },
    {
      id: '2',
      name: 'Febrero',
    },
    {
      id: '3',
      name: 'Marzo',
    },
    {
      id: '4',
      name: 'Abril',
    },
    {
      id: '5',
      name: 'Mayo',
    },
    {
      id: '6',
      name: 'Junio',
    },
    {
      id: '7',
      name: 'Julio',
    },
    {
      id: '8',
      name: 'Agosto',
    },
    {
      id: '9',
      name: 'Septiembre',
    },
    {
      id: '10',
      name: 'Octubre',
    },
    {
      id: '11',
      name: 'Noviembre',
    },
    {
      id: '12',
      name: 'Diciembre',
    },
  ],
};
