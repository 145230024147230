import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  Field,
  isValid,
  submit,
  formValueSelector,
} from 'redux-form';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import urls from 'helpers/urls';
import { renderCheckbox } from 'helpers/FormUtils';
import { termsAndConditions } from 'helpers/validate';
import { asyncValidatePostalCode } from 'helpers/asyncValidate';
import { toCurrencyFormat } from 'helpers/formatCurrency';
import {
  Forms,
  LoansFilesStates,
  LoansCurrentProductName,
  LoansNewProductName,
} from 'constants/AppConstants';
import AttachDocuments from 'components/loans/Commons/AttachDocuments';
import WelcomeHeader from 'components/loans/Commons/Header';
import GroupFrom from 'components/loans/Commons/GroupForm';
import Footer from 'components/loans/Commons/Footer';
import PersonalInformation from 'components/loans/Commons/PersonalInformation';
import AddressInformation from 'components/loans/Commons/AddressInformation';
import {
  getLoansColonies,
  getLoansIdentityDocuments,
  getLoansProofAddressDocuments,
  getPrestaClipNameChange,
} from 'components/loans/redux/selectors';
import * as Styled from './styled';
import text from './text';

export class LoansForm extends PureComponent {
  state = {
    identityDocumentsRequired: false,
    proofOfAddressRequired: false,
  };

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  filterByStatusSaved = (file) => file.status === LoansFilesStates.SAVED;

  validateFilesAndSendForm = () => {
    const { addressProofFiles, identityFiles, isFormValid, submit } =
      this.props;
    const hasIdentityFiles =
      identityFiles.filter(this.filterByStatusSaved).length > 0;
    const hasAddressFiles =
      addressProofFiles.filter(this.filterByStatusSaved).length > 0;

    if (!hasIdentityFiles) {
      this.setState({ identityDocumentsRequired: true });
    }
    if (!hasAddressFiles) {
      this.setState({ proofOfAddressRequired: true });
    }

    if (isFormValid && (!hasIdentityFiles || !hasAddressFiles)) {
      return;
    }
    submit(Forms.LOANS);
  };

  renderNewProductName = (prevText) => {
    const { prestaClipNameChange } = this.props;
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  render() {
    const { colonies, onSubmit, month, year } = this.props;
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Styled.Form onSubmit={onSubmit}>
            <WelcomeHeader
              flowTitle={this.renderNewProductName(text.titleFlow)}
              amount={toCurrencyFormat(text.amount)}
              totalAmount={toCurrencyFormat(text.payback)}
            >
              <Styled.TitleMessage>
                {this.renderNewProductName(text.titleHeader)}
              </Styled.TitleMessage>
            </WelcomeHeader>
            <Styled.Instructions>
              <Styled.TitleInstreuctions>
                {text.instructionTitle}
              </Styled.TitleInstreuctions>
              <Styled.SubtitleInstreuctions>
                {text.instructionWarning}
              </Styled.SubtitleInstreuctions>
            </Styled.Instructions>
            <GroupFrom
              title={text.titlePersonalInformation}
              icon={<Styled.InfoIcon />}
            >
              <PersonalInformation year={year} month={month} />
            </GroupFrom>
            <GroupFrom title={text.titleAddress} icon={<Styled.HomeIcon />}>
              <AddressInformation colonies={colonies} />
            </GroupFrom>
            <GroupFrom
              title={text.titleUploadDocuments}
              subtitle={
                <Styled.HeaderSubTitle>
                  {text.subtitleUploadDocuments}
                </Styled.HeaderSubTitle>
              }
              icon={<Styled.PublishIcon size="1.3em" />}
              isFiles={true}
            >
              <AttachDocuments
                identityDocumentsRequired={this.state.identityDocumentsRequired}
                proofAddressDocumentsRequired={
                  this.state.proofOfAddressRequired
                }
              />
            </GroupFrom>
            <Styled.DocumentsInformation>
              {text.documentInformation}
            </Styled.DocumentsInformation>
            <Styled.Group>
              <Styled.TermsAndConditions>
                <Field
                  name="terms"
                  component={renderCheckbox}
                  label={
                    <span>
                      {text.termsAndConditions}
                      <Link to={urls.termConditionsLoans}>
                        {text.termsAndConditionsLink}
                      </Link>{' '}
                      {this.renderNewProductName(text.endTermsAndConditions)}
                    </span>
                  }
                  validate={[termsAndConditions]}
                  labelWithLinks={true}
                  type="checkbox"
                />
              </Styled.TermsAndConditions>
            </Styled.Group>
          </Styled.Form>
          <Styled.ButtonContainer>
            <Styled.SubmitButton onClick={this.validateFilesAndSendForm}>
              {text.buttonSubmit}  
            </Styled.SubmitButton>
          </Styled.ButtonContainer>
        </Grid>
        <Footer />
      </React.Fragment>
    );
  }
}

const selector = formValueSelector(Forms.LOANS);

const mapStateToProps = (state) => {
  return {
    colonies: getLoansColonies(state),
    isFormValid: isValid(Forms.LOANS)(state),
    year: selector(state, 'year'),
    month: selector(state, 'month'),
    identityFiles: getLoansIdentityDocuments(state),
    addressProofFiles: getLoansProofAddressDocuments(state),
    prestaClipNameChange: getPrestaClipNameChange(state),
  };
};

const mapDispatchToProps = {
  submit,
};

const ReduxLoansForm = reduxForm({
  form: Forms.LOANS,
  asyncValidate: asyncValidatePostalCode,
  asyncBlurFields: ['postalCode'],
})(LoansForm);

export default connect(mapStateToProps, mapDispatchToProps)(ReduxLoansForm);
