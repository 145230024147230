import { MerchantStates } from '../constants/AppConstants';
import {
  CLEAR_MERCHANT,
  UPDATE_MERCHANT,
  UPDATE_META_DATA,
  MERCHANT_ERROR,
  SET_MERCHANT_COLONIES,
  FORM_CHANGE,
  SET_MERCHANT_STATE,
} from '../actions/Types';

const initialState = {
  info: {
    id: '',
    name: '',
    state: MerchantStates.PREACTIVE,
    website_url: '',
    industry_custom_description: '',
    industry_id: '',
    industry_name: '',
    preferred_country: '',
    privacy_policy_version: null,
    terms_version: null,
    created_at: '',
    updated_at: '',
    has_catalog: null,
  },
  address: {
    address1: '',
    address2: '',
    colony: '',
    municipality: '',
    state: '',
    postal_code: '',
  },
  colonies: [],
  industries: [],
  industryOther: false,
  privacy: {
    id: '',
    url: '',
    version: null,
  },
  terms: {
    id: '',
    url: '',
    version: null,
  },
  error: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MERCHANT:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload.merchant,
          has_catalog: !!action.payload.merchant.has_catalog,
        },
        address: { ...state.address, ...action.payload.address },
        industryOther: !!action.payload.merchant.industry_custom_description,
      };
    case UPDATE_META_DATA:
      return {
        ...state,
        industries: action.payload.industries,
        privacy: action.payload.privacy,
        terms: action.payload.terms,
      };
    case MERCHANT_ERROR:
      return { ...state, error: action.payload };
    case SET_MERCHANT_COLONIES:
      return { ...state, colonies: action.payload };
    case SET_MERCHANT_STATE:
      return { ...state, info: { ...state.info, state: action.payload } };
    case FORM_CHANGE:
      if (state.industries.length > 0 && action.meta.field === 'industry') {
        let industryOther = false;

        for (let i = 0; i < state.industries.length; i++) {
          const industry = state.industries[i];

          if (
            industry.id === action.payload &&
            typeof industry.other !== 'undefined'
          ) {
            industryOther = true;
          }
        }

        return { ...state, industryOther: industryOther };
      }

      return state;
    case CLEAR_MERCHANT:
      return initialState;
    default:
      return state;
  }
}

export const getState = (state) => state.info.state;
