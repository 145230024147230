export default {
    pageTitle: '¡Crea tu tienda en línea gratis!',
    pageDescriptionPone:'Comienza a vender en línea de forma simple, rápida y segura hoy.',
    stepOneTitle: 'Carga la información básica de tu negocio',
    stepOneDescription: 
    'Ingresa el nombre de tu tienda y la descripción de tu negocio.',
    stepTwoTitle: 'Carga los productos que quieres vender en línea',
    stepTwoDescription: 'Si ya tienes productos publicados en tu catálogo podrás sincronizarlos, sino deberás cargarlos ahí.',
    cta: 'Crear mi tienda',
    stepThreeTitle: 'Completa los datos de tu tienda y ¡listo!',
    stepThreeDescription: 'Tus clientes necesitarán saber qué tipo de envíos ofreces. como pueden contactarte y cuales son tus horarios de atención.',
    customizePageTitle: 'Beneficios Tienda en Linea',
    perkOneTitle: 'Rápido y fácil',
    perkOneDescription: 
    '¡Crear tu tienda en línea con Clip es muy simple! No importa si no tienes conocimientos de diseño o programación.',
    perkTwoTitle: 'Personalizado',
    perkTwoDescription: 
    'Podrás elegir entre diferentes diseños y colores, tu foto de portada y más para que tu tienda sea única.',
    perkThreeTitle: 'Sin costo de mantenimiento',
    perkThreeDescription: 
    'Crea tu tienda online sin costos mensuales o comisiones adicionales sobre ventas.',
}