import {
  LOAD_TRANSACTIONS,
  LOAD_TRANSACTIONS_ERROR,
  GET_TRANSACTION_DETAIL,
  GET_TRANSACTION_DETAIL_ERROR,
  DOWNLOAD_TRANSACTION_REPORT,
  DOWNLOAD_TRANSACTION_REPORT_ERROR,
} from './types';

import Transactions from '../service';

export const loadTransactions = (params) => (dispatch) => {
  dispatch({ type: LOAD_TRANSACTIONS, payload: { loading: true } });
  return Transactions.getTransactions(params)
    .then((res) => {
      dispatch({
        type: LOAD_TRANSACTIONS,
        payload: { loading: false, ...res.data },
      });
    })
    .catch((err) => {
      dispatch({ type: LOAD_TRANSACTIONS, payload: { loading: false } });
      dispatch({ type: LOAD_TRANSACTIONS_ERROR, payload: err.message });
    });
};

export const loadTransactionDetail = (id) => (dispatch) => {
  dispatch({ type: GET_TRANSACTION_DETAIL, payload: { loading: true } });

  return Transactions.getTransaction(id)
    .then((res) => {
      dispatch({
        type: GET_TRANSACTION_DETAIL,
        payload: { loading: false, detail: res.data },
      });
    })
    .catch((err) => {
      dispatch({ type: GET_TRANSACTION_DETAIL, payload: { loading: false } });
      dispatch({ type: GET_TRANSACTION_DETAIL_ERROR, payload: err.message });
    });
};

export const downloadTransactionReport = (params) => (dispatch) => {
  dispatch({ type: DOWNLOAD_TRANSACTION_REPORT, payload: { loading: true } });

  return Transactions.getTransactionReport(params)
    .then((report) => {
      dispatch({
        type: DOWNLOAD_TRANSACTION_REPORT,
        payload: { loading: false, report },
      });
    })
    .catch((err) => {
      dispatch({
        type: DOWNLOAD_TRANSACTION_REPORT,
        payload: { loading: false },
      });
      dispatch({
        type: DOWNLOAD_TRANSACTION_REPORT_ERROR,
        payload: err.message,
      });
    });
};
