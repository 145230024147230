import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  isValid,
  submit,
  formValueSelector,
  change,
  Field,
} from 'redux-form';
import { withRouter, Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { asyncValidatePostalCode } from 'helpers/asyncValidate';
import {
  focusInputWithErrors,
  focusFileInputType,
  renderCheckbox,
} from 'helpers/FormUtils';
import urls from 'helpers/urls';
import getUrl, { getProviderCashAdvanceURL } from 'helpers/getUrl';
import { getProviderCodeForURL } from 'helpers/getProviderUrl';
import CrediclipFonelValidations from 'helpers/CrediclipFonelValidations';
import {
  Forms,
  LoansFilesStates,
  LoansDocuments,
  LoansCurrentProductName,
  LoansNewProductName,
} from 'constants/AppConstants';
import {
  showIdentityDocumentRadios,
  getProviderCode,
  loansProviderCodes,
} from 'constants/ProviderCodes';
import {
  turnOnDragFile,
  turnOffDragFile,
  deleteFilesNotSaved,
} from 'components/loans/redux/actions';
import {
  getLoansIdentityDocuments,
  getPrefilledForm,
  getLoansIdentityDocumentsBack,
  getLoansPassportDocuments,
  getLoansProofAddressDocuments,
  getSendingForm,
  getPreapprovalSelected,
  getPrestaClipNameChange,
} from 'components/loans/redux/selectors';

import IdentityStep from '../IdentityStep';
import HistoryStep from '../HistoryStep';
import AddressStep from '../AddressStep';
import DepositStep from '../DepositStep';

import {
  getLoansColonies,
  getMerchantId,
} from 'components/loans/redux/selectors';
import {
  Subtitlev2,
  Advice,
  AdviceBR,
  AdviceLink,
  Form,
  LoansFormV3ButtonContainer,
  SubmitButtonV3,
  ProgressIcon,
  TermsAndConditions,
} from 'components/loans/Commons/Form/styled';
import { termsAndConditions } from 'helpers/validate';
import text from 'components/loans/Commons/LoansContent/text';
import FormButton from 'helpers/FormUtils/FormButton';
import ClevertapComponent from '../../../../../helpers/Clevertap/ClevertapComponent';
import { EVENTS } from '../../../../../helpers/Clevertap';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GoogleAnalytics from '../../../GoogleAnalytics';
import { lenderPayload } from '../../../Commons/Clevertap';

class ExternalForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    turnOnDragFile: PropTypes.func.isRequired,
    turnOffDragFile: PropTypes.func.isRequired,
    deleteFilesNotSaved: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    prestaClipNameChange: PropTypes.bool.isRequired,
    step: PropTypes.number,
    changeStep: PropTypes.func.isRequired,
    merchantId: PropTypes.string,
    preapprovalSelected: PropTypes.object,
  };

  state = {
    proofOfAddressRequired: false,
    dragOverClass: '',
    showDialog: false,
    ineFrontRequired: false,
    ineBackRequired: false,
    passportRequired: false,
    isPassport: false,
    passportUpdated: false,
    identityStepComplete: false,
    historyStepComplete: false,
    addressStepComplete: false,
    depositStepComplete: false,
    prevStep: 0,
  };

  getBaseLocation = () =>
    this.props.location.pathname.startsWith(urls.mobile) ? urls.mobile : '';

  showIdentityRadios = () =>
    showIdentityDocumentRadios(this.props.location.pathname.toUpperCase());

  handleDialogClose = () => {
    this.setState({ showDialog: false });
  };

  isFileSaved = (file) => file.status === LoansFilesStates.SAVED;

  validateFilesAndSendForm = () => {
    const {
      addressProofFiles,
      identityFiles,
      identityFilesBack,
      errors,
      passportFiles,
      isFormValid,
      step,
      submit,
      identityDocumentType,
    } = this.props;
    const ineFrontNotSaved =
      identityFiles.filter(this.isFileSaved).length === 0;
    const ineBackNotSaved =
      identityFilesBack.filter(this.isFileSaved).length === 0;
    const passportNotSaved =
      passportFiles.filter(this.isFileSaved).length === 0;
    const identityFilesNotSaved =
      identityDocumentType === LoansDocuments.ineDocumentType
        ? ineFrontNotSaved || ineBackNotSaved
        : passportNotSaved;
    const addressProofFilesNotSaved =
      addressProofFiles.filter(this.isFileSaved).length === 0;
    const providerCode = getProviderCode(
      this.props.location.pathname.toUpperCase(),
    );

    const isINE = identityDocumentType === LoansDocuments.ineDocumentType;
    const isPassport =
      identityDocumentType === LoansDocuments.passportDocumentType;
    const totalErrors = errors ? Object.keys(errors).length : 0;

    if ((step === 3 && providerCode !== loansProviderCodes.MRP) || step === 4) {
      this.setState({
        ineFrontRequired: isINE && ineFrontNotSaved,
        ineBackRequired: isINE && ineBackNotSaved,
        passportRequired: passportNotSaved && isPassport,
        proofOfAddressRequired: addressProofFilesNotSaved,
      });

      if ((isFormValid || totalErrors > 0) && identityFilesNotSaved) {
        !isFormValid && submit(Forms.MR_PRESTA);

        (ineFrontNotSaved || ineBackNotSaved) &&
          isINE &&
          focusFileInputType('identity');
        passportNotSaved && isPassport && focusFileInputType('passport');

        return;
      } else if (
        (isFormValid || (totalErrors === 1 && errors.terms)) &&
        addressProofFilesNotSaved
      ) {
        !isFormValid && submit(Forms.MR_PRESTA);

        focusFileInputType('proofOfAddress');
        return;
      }
    }
    submit(Forms.MR_PRESTA);

    if (
      ((step === 3 && providerCode !== loansProviderCodes.MRP) || step === 4) &&
      !isFormValid
    ) {
      return focusInputWithErrors(errors);
    }
  };

  renderSubTitleAndAdvise = (
    prefix,
    subtitle,
    advice,
    adviceP2,
    adviceLink,
  ) => {
    return (
      <Fragment>
        <Subtitlev2 key={prefix + '_subtitle'}>{subtitle}</Subtitlev2>
        <Advice key={prefix + '_advice'}>
          {advice && !adviceP2 && { advice }}
          {adviceP2 && (
            <Fragment>
              <AdviceBR />
              {advice}
              <AdviceLink href={adviceLink}>{adviceP2}</AdviceLink>
            </Fragment>
          )}
        </Advice>
      </Fragment>
    );
  };

  setIdentityDocumentType = (event) => {
    const { change, deleteFilesNotSaved } = this.props;
    change('ine', '');

    if (event.target.value === LoansDocuments.ineDocumentType) {
      deleteFilesNotSaved(LoansDocuments.passport);
      this.setState({ ineFrontRequired: false });
      this.setState({ ineBackRequired: false });
      this.setState({ isPassport: false });
    } else if (event.target.value === LoansDocuments.passportDocumentType) {
      deleteFilesNotSaved(LoansDocuments.identity);
      deleteFilesNotSaved(LoansDocuments.identityBack);
      this.setState({ passportRequired: false });
      this.setState({ isPassport: true });
    }
  };

  ondragover = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.turnOnDragFile();
  };

  ondragleave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.turnOffDragFile();
  };

  ondrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.turnOffDragFile();
  };

  handleEventStepper = () => {
    const { step, preapprovalSelected, isFormValid, errors, showCashAdvance } =
      this.props;
    const providerCode = preapprovalSelected.provider_code;
    const providerCodeForURL = getProviderCodeForURL(providerCode);
    let urlForm;

    if (!isFormValid) {
      return focusInputWithErrors(errors);
    } else if (step === 1) {
      if (providerCode === loansProviderCodes.MRP) {
        urlForm = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormHistoryV3CA,
          urls.loansFormHistoryV3,
        );
      } else {
        urlForm = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormAddressV3CA,
          urls.loansFormAddressV3,
        );
      }
      this.setState({ prevStep: step });
    } else if (step === 2) {
      if (providerCode === loansProviderCodes.MRP) {
        urlForm = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormAddressV3CA,
          urls.loansFormAddressV3,
        );
      } else {
        urlForm = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormDepositV3CA,
          urls.loansFormDepositV3,
        );
      }
      this.setState({ prevStep: step });
    } else if (step === 3 && providerCode === loansProviderCodes.MRP) {
      urlForm = getProviderCashAdvanceURL(
        showCashAdvance,
        providerCodeForURL,
        urls.loansFormDepositV3CA,
        urls.loansFormDepositV3,
      );
      this.setState({ prevStep: step });
    } else {
      return;
    }
    this.props.history.replace(getUrl(`${this.getBaseLocation()}${urlForm}`));
  };

  handleInverseEventStepper = () => {
    const { step, changeStep } = this.props;

    this.setState({ prevStep: step - 2 });
    changeStep(step - 1);
  };

  isFullPersonalInformationData(providerCode) {
    if (
      CrediclipFonelValidations.countEmptyPersonalInformationInput(
        this.props,
        providerCode,
      ) > 1
    ) {
      return false;
    }
    return true;
  }

  updateComponentScroll(prevProps, props) {
    if (prevProps.step === 2 && props.step === 3) {
      this.setState({ prevStep: 3 });
    }
    if (
      (prevProps.step === 2 && props.step === 1) ||
      (prevProps.step === 1 && props.step === 2) ||
      (prevProps.step === 3 && props.step === 2) ||
      (prevProps.step === 3 && props.step === 1) ||
      (prevProps.step === 2 && props.step === 3)
    ) {
      this.forceUpdate();
    }
  }

  componentDidUpdate(prevProps) {
    const { passportUpdated } = this.state;
    const { identityDocumentType } = this.props;
    if (identityDocumentType === 'PASSPORT' && !passportUpdated) {
      this.setState({ isPassport: true, passportUpdated: true });
    }
    this.updateComponentScroll(prevProps, this.props);
  }

  handleIdentityStep = (enabled) => {
    this.setState({ identityStepComplete: enabled });
  };
  handleHistoryStep = (enabled) => {
    this.setState({ historyStepComplete: enabled });
  };
  handleAddressStep = (enabled) => {
    this.setState({ addressStepComplete: enabled });
  };
  handleDepositStep = (enabled) => {
    this.setState({ depositStepComplete: enabled });
  };

  renderNewProductName = (prevText) => {
    const { prestaClipNameChange } = this.props;
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  render() {
    const {
      identityStepComplete,
      historyStepComplete,
      addressStepComplete,
      depositStepComplete,
    } = this.state;
    const {
      handleSubmit,
      step,
      lastOne,
      sendingForm,
      preapprovalSelected,
      showCashAdvance,
      loansFormVersion,
    } = this.props;
    const providerCode =
      preapprovalSelected && preapprovalSelected.provider_code;

    const isMobileWebView = this.props.location.pathname.startsWith(
      urls.mobile,
    );

    let hasPreviousLoan = Object.keys(lastOne).length !== 0;

    return (
      <Grid item xs={12}>
        <Form
          onSubmit={handleSubmit}
          onDragOver={this.ondragover}
          onDragLeave={this.ondragleave}
          onDrop={this.ondrop}
        >
          {step === 1 && (
            <GoogleAnalytics>
              <IdentityStep
                providerCode={providerCode}
                hasPreviousLoan={hasPreviousLoan}
                previousLoan={hasPreviousLoan && lastOne}
                handleIdentityStep={this.handleIdentityStep}
              />
            </GoogleAnalytics>
          )}
          {step === 2 && providerCode === loansProviderCodes.MRP && (
            <GoogleAnalytics>
              <HistoryStep
                providerCode={providerCode}
                hasPreviousLoan={hasPreviousLoan}
                handleHistoryStep={this.handleHistoryStep}
              />
            </GoogleAnalytics>
          )}
          {step === 2 && providerCode !== loansProviderCodes.MRP && (
            <GoogleAnalytics>
              <AddressStep
                providerCode={providerCode}
                hasPreviousLoan={hasPreviousLoan}
                previousLoan={hasPreviousLoan && lastOne}
                handleAddressStep={this.handleAddressStep}
              />
            </GoogleAnalytics>
          )}
          {step === 3 && providerCode === loansProviderCodes.MRP && (
            <GoogleAnalytics>
              <AddressStep
                providerCode={providerCode}
                hasPreviousLoan={hasPreviousLoan}
                previousLoan={hasPreviousLoan && lastOne}
                handleAddressStep={this.handleAddressStep}
              />
            </GoogleAnalytics>
          )}
          {step === 3 && providerCode !== loansProviderCodes.MRP && (
            <GoogleAnalytics>
              <DepositStep
                providerCode={providerCode}
                hasPreviousLoan={hasPreviousLoan}
                handleDepositStep={this.handleDepositStep}
              />
            </GoogleAnalytics>
          )}
          {step === 4 && (
            <GoogleAnalytics>
              <DepositStep
                providerCode={providerCode}
                hasPreviousLoan={hasPreviousLoan}
                handleDepositStep={this.handleDepositStep}
              />
            </GoogleAnalytics>
          )}
          {step === 1 && (
            <LoansFormV3ButtonContainer>
              <FormButton
                variant="contained"
                text={text.continue}
                type="submit"
                onClick={this.handleEventStepper}
                disabled={!identityStepComplete}
              />
            </LoansFormV3ButtonContainer>
          )}
          {((step > 1 && step < 3 && providerCode !== loansProviderCodes.MRP) ||
            (step > 1 &&
              step < 4 &&
              providerCode === loansProviderCodes.MRP)) && (
            <LoansFormV3ButtonContainer>
              <Grid container spacing={16}>
                <Grid item xs={3}>
                  <FormButton
                    variant="outlined"
                    textIcon={<ArrowBackIcon />}
                    onClick={this.handleInverseEventStepper}
                  />
                </Grid>
                <Grid item xs={9}>
                  <FormButton
                    variant="contained"
                    text={text.continue}
                    type="submit"
                    onClick={this.handleEventStepper}
                    disabled={(() => {
                      switch (step) {
                        case 2:
                          if (
                            providerCode === loansProviderCodes.MRP &&
                            historyStepComplete
                          ) {
                            return false;
                          }
                          if (
                            providerCode !== loansProviderCodes.MRP &&
                            addressStepComplete
                          ) {
                            return false;
                          }
                          break;
                        case 3:
                          if (
                            providerCode === loansProviderCodes.MRP &&
                            addressStepComplete
                          ) {
                            return false;
                          }
                          if (
                            providerCode !== loansProviderCodes.MRP &&
                            depositStepComplete
                          ) {
                            return false;
                          }
                          break;
                        default:
                          if (depositStepComplete) {
                            return false;
                          }
                          break;
                      }
                      return true;
                    })()}
                  />
                </Grid>
              </Grid>
            </LoansFormV3ButtonContainer>
          )}
        </Form>
        {((step === 3 && providerCode !== loansProviderCodes.MRP) ||
          step === 4) && (
          <Fragment>
            <TermsAndConditions>
              <Field
                name="terms"
                type="checkbox"
                component={renderCheckbox}
                label={
                  <span>
                    {text.termsAndConditions}
                    <ClevertapComponent
                      renderComponent={<Link />}
                      events={[
                        {
                          callbackName: 'onClick',
                          event: EVENTS.LOANS.TC_SELECTED,
                          payload: lenderPayload(preapprovalSelected, {
                            showCashAdvance,
                            loansFormVersion,
                          }),
                        },
                      ]}
                      to={`${this.getBaseLocation()}${
                        urls.termConditionsLoans
                      }`}
                    >
                      {text.termsAndConditionsLink}
                    </ClevertapComponent>
                    {text.termsAndConditionsAnd}
                    {!isMobileWebView && (
                      <ClevertapComponent
                        renderComponent={<a />}
                        events={[
                          {
                            callbackName: 'onClick',
                            event: EVENTS.LOANS.PRIVACY_SELECTED,
                            payload: lenderPayload(preapprovalSelected, {
                              showCashAdvance,
                              loansFormVersion,
                            }),
                          },
                        ]}
                        href={urls.clipPrivacy}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {text.termsAndConditionsPrivacy}
                      </ClevertapComponent>
                    )}
                    {isMobileWebView && text.termsAndConditionsPrivacy}
                    {this.renderNewProductName(text.endTermsAndConditions)}
                  </span>
                }
                validate={[termsAndConditions]}
                labelWithLinks={true}
              />
            </TermsAndConditions>
            <LoansFormV3ButtonContainer>
              <Grid container spacing={16}>
                <Grid item xs={3}>
                  <FormButton
                    variant="outlined"
                    textIcon={<ArrowBackIcon />}
                    type="submit"
                    onClick={this.handleInverseEventStepper}
                  />
                </Grid>
                <Grid item xs={9}>
                  {!sendingForm && (
                    <FormButton
                      type="button"
                      onClick={this.validateFilesAndSendForm.bind(this)}
                      variant="contained"
                      text={this.renderNewProductName(text.finish)}
                      disabled={!depositStepComplete}
                    />
                  )}
                  {sendingForm && (
                    <SubmitButtonV3 type="button" disabled={true}>
                      <ProgressIcon
                        variant="indeterminate"
                        size={24}
                        thickness={6}
                      />
                    </SubmitButtonV3>
                  )}
                </Grid>
              </Grid>
            </LoansFormV3ButtonContainer>
          </Fragment>
        )}
        <Dialog
          fullScreen={false}
          open={this.state.showDialog}
          onClose={this.handleDialogClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {text.titleErrorDialog}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{text.messageErrorDialog}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary" autoFocus>
              {text.close}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

const selector = formValueSelector(Forms.MR_PRESTA);

const mapStateToProps = (state) => {
  return {
    colonies: getLoansColonies(state),
    isFormValid: isValid(Forms.MR_PRESTA)(state),
    errors: state.form[Forms.MR_PRESTA].syncErrors,
    postalCode: selector(state, 'postalCode'),
    identityFiles: getLoansIdentityDocuments(state),
    identityFilesBack: getLoansIdentityDocumentsBack(state),
    passportFiles: getLoansPassportDocuments(state),
    addressProofFiles: getLoansProofAddressDocuments(state),
    birthday: selector(state, 'birthday'),
    year: selector(state, 'year'),
    month: selector(state, 'month'),
    day: selector(state, 'day'),
    lastName: selector(state, 'lastName'),
    secondLastName: selector(state, 'secondLastName'),
    gender: selector(state, 'gender'),
    maritalStatus: selector(state, 'maritalStatus'),
    hasCreditCard: selector(state, 'hasCreditCard'),
    hasMortgageCredit: selector(state, 'hasMortgageCredit'),
    hasVehicleCredit: selector(state, 'hasVehicleCredit'),
    colony: selector(state, 'colony'),
    municipality: selector(state, 'municipality'),
    state: selector(state, 'state'),
    street: selector(state, 'street'),
    streetNumber: selector(state, 'streetNumber'),
    sendingForm: getSendingForm(state),
    initialValues: getPrefilledForm(state),
    identityDocumentType: selector(state, 'identityDocumentType'),
    preapprovalSelected: getPreapprovalSelected(state),
    merchantId: getMerchantId(state),
    prestaClipNameChange: getPrestaClipNameChange(state),
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  submit,
  turnOnDragFile,
  turnOffDragFile,
  deleteFilesNotSaved,
  change,
};

const ReduxMrPRestaForm = reduxForm({
  form: Forms.MR_PRESTA,
  asyncValidate: asyncValidatePostalCode,
  asyncChangeFields: ['postalCode'],
  touchOnChange: true,
  destroyOnUnmount: false,
})(ExternalForm);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReduxMrPRestaForm),
);
