import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'react-notifications-component';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as Crypto from '../CryptoManager/CryptoManager';
import * as CryptoRequests from '../CryptoManager/RequestsCryptoManager';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { setAccountRep } from '../redux/berlinActions';
import BerlinSpinner from '../BerlinSpinner';
import BerlinService from '../service';
import OneStepNavigator from '../Commons/OneStepNavigator';
import { BaseButton } from 'components/common/Buttons';
import { getTimeText } from '../utils';
import logger from 'helpers/logger';

import text from './text';

const styles = (theme) => {
  return {
    container: {
      marginTop: theme.spacing.unit * 3.5,
      height: 'calc(100vh - 7rem)',
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
    title: {
      marginTop: theme.spacing.unit * 2,
      padding: 0,
      textAlign: 'left',
      fontWeight: 500,
    },
    digitsContainer: {
      display: 'grid',
      gridAutoFlow: 'columns',
      gridTemplateColumns: '1fr 1fr 1fr',
      width: theme.spacing.unit * 10,
      margin: 'auto',
      marginBottom: theme.spacing.unit * 1.2,
    },
    cvvDigit: {
      textAlign: 'center',
      backgroundColor: 'white',
      border: '1px solid #e6e8e9',
      borderRadius: 8,
      width: 48,
      height: 48,
      lineHeight: '45px',
      margin: 0,
      padding: 0,
      fontWeight: 'bold',
    },
    timerContainer: {
      width: '100%',
      textAlign: 'center',
      marginBottom: theme.spacing.unit * 1.5,
    },
    timer: {
      fontWeight: 'bold',
      padding: 0,
      fontSize: '1.1rem',
      textAlign: 'center',
    },
    lowTime: {
      color: theme.palette.naranja.bloodOrange,
    },
  };
};

const DynamicCvv = ({
  classes,
  SCREENS,
  setActiveScreen,
  merchant,
  account_holder_id,
  account,
  setAccountRep,
  flags: { financialEncryptionAvailableWeb },
}) => {
  const [info, setInfo] = useState(null);
  const [seconds, setSeconds] = useState();

  const totalTime = 300;
  const totalSteps = 251;
  const threshold = 5;

  const getRemainingTime = (time) => {
    return (time * -totalSteps) / totalTime - totalSteps;
  };

  useEffect(() => {
    if (merchant) {
      BerlinService.accountInformationV2(merchant.info.id)
        .then((response) => {
          setAccountRep(response.data.message);
        })
        .catch(() => {});
    }
  }, [merchant]);

  const getCvv = async () => {
    try {
      let result;
      if (financialEncryptionAvailableWeb) {
        await Crypto.init();
        const serverKey = await CryptoRequests.GetServerKey(
          account_holder_id,
          Crypto.keys().publicKey.base64,
          Crypto.keys().iv.hex,
        );
        await Crypto.setServerPublicKey(serverKey);
        result = await BerlinService.getDynamicCvvV3(
          account.proxy_account_id
            ? account.proxy_account_id
            : account.card_representation[0].proxy_card_id
            ? account.card_representation[0].proxy_card_id
            : account.card_representation[0].proxy_card,
        );
        const dencrypted = await Crypto.decrypt(result.data.message.cvv);
        const decoder = new TextDecoder('utf-8');
        const decodedString = decoder.decode(dencrypted);
        let { message } = result.data;
        message.cvv = decodedString.split('');
        const timeDiff = Math.ceil(
          (new Date(message.expire_date_time).getTime() -
            new Date().getTime()) /
            1000,
        );
        setInfo(message);
        setSeconds(Number(message.expire_seconds) || timeDiff);
      } else {
        if (account.proxy_account_id) {
          result = await BerlinService.getDynamicCvvV2(
            account.proxy_account_id,
          );
        } else {
          result = await BerlinService.getDynamicCvv(
            account.card_representation[0].proxy_card_id
              ? account.card_representation[0].proxy_card_id
              : account.card_representation[0].proxy_card,
          );
        }
        let { message } = result.data;
        message.cvv = atob(message.cvv).split('');
        const timeDiff = Math.ceil(
          (new Date(message.expire_date_time).getTime() -
            new Date().getTime()) /
            1000,
        );

        setInfo(message);
        setSeconds(Number(message.expire_seconds) || timeDiff);
      }
    } catch (e) {
      logger.info(e);
      store.addNotification({
        title: text.errorTitle,
        message: text.errorMessage,
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          showIcon: true,
          duration: 0,
        },
      });
      setActiveScreen(SCREENS.BALANCE);
    }
  };

  useEffect(() => {
    if (!info) {
      getCvv();
    }

    let interval = setInterval(() => {
      if (info) {
        setSeconds(
          Math.ceil(
            (new Date(info.expire_date_time).getTime() - new Date().getTime()) /
              1000,
          ),
        );
      }
    }, 1000);

    if (seconds <= 0) {
      clearInterval(interval);
      getCvv();
    }

    return () => clearInterval(interval);
  }, [seconds]);

  const renderTimer = () => {
    return (
      <div className={classes.timerContainer}>
        <svg height="100" width="100">
          <circle
            cx="50"
            cy="50"
            r="40"
            stroke={seconds <= threshold ? '#f8d9cc' : '#cae1bd'}
            strokeWidth="5"
            fill={seconds <= threshold ? '#ffece4' : '#dbefd0'}
          />
          <circle
            cx="50"
            cy="50"
            r="40"
            stroke={seconds <= threshold ? '#fc4c02' : '#51853a'}
            strokeWidth="5"
            strokeDasharray="251"
            strokeLinecap="round"
            strokeDashoffset={getRemainingTime(seconds)}
            transform-origin="50 50"
            transform="rotate(-90)"
            fill="transparent"
          />
          <foreignObject x="0" y="38" width="100" height="25">
            <Typography className={classes.timer} variant="subheading">
              {seconds === totalTime
                ? `${seconds / 60} min`
                : getTimeText(seconds)}
            </Typography>
          </foreignObject>
        </svg>
      </div>
    );
  };

  return info ? (
    <>
      <OneStepNavigator
        title={text.navigatorTitle}
        stepBackFunction={setActiveScreen}
        stepBackTarget={SCREENS.BALANCE}
      />
      <div className={classes.container}>
        <Typography className={classes.title} variant="title">
          {text.title}
        </Typography>
        <Typography variant="body1">{text.message}</Typography>
        <div className={classes.digitsContainer}>
          <Typography
            className={`${classes.cvvDigit} ${
              seconds <= threshold ? classes.lowTime : ''
            }`}
            variant="subheading"
          >
            {info.cvv[0]}
          </Typography>
          <Typography
            className={`${classes.cvvDigit} ${
              seconds <= threshold ? classes.lowTime : ''
            }`}
            variant="subheading"
          >
            {info.cvv[1]}
          </Typography>
          <Typography
            className={`${classes.cvvDigit} ${
              seconds <= threshold ? classes.lowTime : ''
            }`}
            variant="subheading"
          >
            {info.cvv[2]}
          </Typography>
        </div>
        {renderTimer()}
        <BaseButton onClick={() => setActiveScreen(SCREENS.BALANCE)}>
          {text.okButton}
        </BaseButton>
      </div>
    </>
  ) : (
    <BerlinSpinner />
  );
};

DynamicCvv.propTypes = {
  classes: PropTypes.object,
  SCREENS: PropTypes.object,
  setActiveScreen: PropTypes.func,
  account: PropTypes.object.isRequired,
};

DynamicCvv.defaultProps = {
  classes: {},
  SCREENS: {},
  setActiveScreen: () => {},
  account: {},
};

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    account_holder_id: state.berlin.account_representation.account_holder_id,
  };
};
const mapDispatchToProps = {
  setAccountRep,
};

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DynamicCvv)),
);
