import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  LoansTabs,
  LoansStates,
  CollaboratorRoles,
  LoansDocuments,
  PreapprovalStatus,
  LoansCurrentProductName,
  LoansNewProductName,
} from 'constants/AppConstants';
import urls from 'helpers/urls';
import getUrl, { getProviderCashAdvanceURL } from 'helpers/getUrl';
import { getProviderCodeForURL } from 'helpers/getProviderUrl';
import { isEmpty } from 'helpers/validate';
import { toCurrencyFormat } from 'helpers/formatCurrency';
import {
  getLastOne,
  getUserName,
  getPreapprovals,
  getPreapprovalSelected,
  getLoansIdentityDocuments,
  getLoansIdentityDocumentsBack,
  getLoansPassportDocuments,
  getLoansProofAddressDocuments,
  isLoansFormSent,
  hasLastOne,
  getPrestaClipNameChange,
} from 'components/loans/redux/selectors';
import {
  changePreapproval,
  saveMerchantData,
  savePreapprovalSelected,
} from 'components/loans/redux/actions';
import LoansContent from 'components/loans/Commons/LoansContent';
import ExternalForm from './Form';
import {
  CenterContent,
  FormContainer,
  InitialAlert,
  InitialAlertContent,
  PrefilledAlert,
  PrefilledAlertContent,
  AssignmentTurnedIn,
  RequestAmount,
  ModalContent,
  ModalTitle,
} from './styled';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import {
  showCreditHistory,
  getProviderCode,
  loansProviderCodes,
} from 'constants/ProviderCodes';
import LoansStepper from 'components/loans/Commons/LoansStepper';
import LoansOffers from 'components/loans/Commons/LoansOffers';
import text from './text';

const styles = (theme) => ({
  buttonRoot: {
    padding: `0 ${theme.spacing.unit * 2}px`,
    fontSize: `${theme.spacing.unit * 1.75}px`,
    fontWeight: 600,
    letterSpacing: `${theme.spacing.unit * 0.025}px`,
    textTransform: 'initial',
  },
  buttonIconRoot: {
    width: `${theme.spacing.unit * 2 + 1}px`,
    height: `${theme.spacing.unit * 2 + 1}px`,
  },
  buttonClose: {
    display: 'block',
    marginRight: '0',
    marginLeft: 'auto',
    padding: `${theme.spacing.unit * 2}px`,
    color: theme.palette.common.black,
  },
});

export class ExternalInformation extends Component {
  static propTypes = {
    saveMerchantData: PropTypes.func.isRequired,
    changePreapproval: PropTypes.func.isRequired,
    savePreapprovalSelected: PropTypes.func.isRequired,
    lastOne: PropTypes.object,
    loansFormURL: PropTypes.string,
    preapprovalSelected: PropTypes.object,
    classes: PropTypes.object,
    hasLastOne: PropTypes.bool.isRequired,
  };

  state = {
    step: 1,
    stepsCompleted: [],
    open: false,
  };

  getBaseLocation = () =>
    this.props.location.pathname.startsWith(urls.mobile)
      ? urls.mobile
      : '';

  save = (form) => {
    const {
      preapprovalSelected,
      addressProofFiles,
      identityFiles,
      identityFilesBack,
      passportFiles,
      saveMerchantData,
      lastOne = {},
    } = this.props;
    const { step, stepsCompleted } = this.state;
    const lastPreapprovalId =
      Object.keys(lastOne).length > 0 ? lastOne.pre_approval_id : '';
    const providerCode = preapprovalSelected.provider_code;
    if (
      ((step < 3 && providerCode !== loansProviderCodes.MRP) ||
        (step < 4 && providerCode === loansProviderCodes.MRP)) &&
      stepsCompleted.indexOf(step) < 0
    )
      this.setState({
        step: step + 1,
        stepsCompleted: [...stepsCompleted, step],
      });
    else if (
      (step < 3 && providerCode !== loansProviderCodes.MRP) ||
      (step < 4 && providerCode === loansProviderCodes.MRP)
    ) {
      this.setState({ step: step + 1 });
    } else {
      const {
        amount,
        pre_approval_id: preApprovalId,
        provider_pre_approval_id: providerPreApprovalId,
        proxy_merchant_token: proxyMerchantToken,
        provider_code: providerCode,
      } = preapprovalSelected;
      saveMerchantData(
        lastPreapprovalId,
        preApprovalId,
        providerPreApprovalId,
        proxyMerchantToken,
        providerCode,
        amount,
        form,
        [
          ...identityFiles.map(
            this.mapDocuments(LoansDocuments.ineDocumentType),
          ),
          ...identityFilesBack.map(
            this.mapDocuments(LoansDocuments.ineDocumentType),
          ),
          ...passportFiles.map(
            this.mapDocuments(LoansDocuments.passportDocumentType),
          ),
        ],
        addressProofFiles.map(
          this.mapDocuments(LoansDocuments.addressDocumentType),
        ),
      );
    }
  };

  mapDocuments = (type) => (file) => ({
    document_type: type,
    document_content_type: file.type,
    document_key: file.key,
  });

  changeStep = (step) => {
    const { showCashAdvance } = this.props;
    const providerCode = this.props.preapprovalSelected.provider_code;
    const providerCodeForURL = getProviderCodeForURL(providerCode);
    let urlLoans;

    switch (step) {
      case 1:
        urlLoans = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormIdentityV3CA,
          urls.loansFormIdentityV3,
        );
        break;
      case 2:
        if (providerCode === loansProviderCodes.MRP) {
          urlLoans = getProviderCashAdvanceURL(
            showCashAdvance,
            providerCodeForURL,
            urls.loansFormHistoryV3CA,
            urls.loansFormHistoryV3,
          );
        } else {
          urlLoans = getProviderCashAdvanceURL(
            showCashAdvance,
            providerCodeForURL,
            urls.loansFormAddressV3CA,
            urls.loansFormAddressV3,
          );
        }
        break;
      case 3:
        if (providerCode === loansProviderCodes.MRP) {
          urlLoans = getProviderCashAdvanceURL(
            showCashAdvance,
            providerCodeForURL,
            urls.loansFormAddressV3CA,
            urls.loansFormAddressV3,
          );
        } else {
          urlLoans = getProviderCashAdvanceURL(
            showCashAdvance,
            providerCodeForURL,
            urls.loansFormDepositV3CA,
            urls.loansFormDepositV3,
          );
        }
        break;
      case 4:
      default:
        urlLoans = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormDepositV3CA,
          urls.loansFormDepositV3,
        );
        break;
    }
    this.setState({ step });
    this.props.history.replace(getUrl(`${this.getBaseLocation()}${urlLoans}`));
  };

  changeAmount = () => {
    const { loansFormURL } = this.props;
    this.props.changePreapproval();
    this.props.history.replace(getUrl(`${this.getBaseLocation()}${loansFormURL}`));
  };

  redirectWhenNotAuthorized = () => {
    const { role } = this.props;
    if (
      this.getBaseLocation() !== urls.mobile &&
      role !== CollaboratorRoles.ADMIN
    ) {
      this.props.history.replace(getUrl(urls.dashboard));
    }
  };

  redirectWhenAmountNotSelected = () => {
    const { preapprovalSelected = {}, isFormSent } = this.props;
    const { amount, status } = preapprovalSelected;
    if (!isFormSent && (status !== LoansStates.AVAILABLE || isEmpty(amount))) {
      const urlLoans = this.props.location.pathname
        .replace('/offer/identity', '')
        .replace('/offer/history', '')
        .replace('/offer/address', '')
        .replace('/offer/deposit', '')
        .replace('mobile/', '');
      this.props.history.replace(`${this.getBaseLocation()}${urlLoans}`);
    }
  };

  redirectWhenAcepted = () => {
    const { isFormSent } = this.props;
    if (isFormSent) {
      const providerCode = this.props.preapprovals[0].provider_code;
      const providerCodeForURL = getProviderCodeForURL(providerCode);

      const urlCongrats = urls.congrats.replace(':lender', providerCodeForURL);
      this.props.history.replace(getUrl(`${this.getBaseLocation()}${urlCongrats}`));
    }
  };

  componentDidUpdate() {
    this.redirectWhenNotAuthorized();
    this.redirectWhenAmountNotSelected();
    this.redirectWhenAcepted();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.redirectWhenNotAuthorized();
    this.redirectWhenAmountNotSelected();
    this.redirectWhenAcepted();
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderAlert = (hasPreviousLoan) => {
    const { username } = this.props;

    if (hasPreviousLoan) {
      return (
        <PrefilledAlert>
          <AssignmentTurnedIn />
          <p className="alertTitle">{text.previousLoan(username)}</p>
          <PrefilledAlertContent>
            {this.renderNewProductName(text.previousLoanContent)}
          </PrefilledAlertContent>
        </PrefilledAlert>
      );
    }
    return (
      <InitialAlert>
        <AssignmentTurnedIn />
        <p className="alertTitle">{text.initialAlertTitle}</p>
        <InitialAlertContent>
          <p>{text.initialAlertContent}</p>
          <li>{text.initialAlertIdentityDocument}</li>
          <li>{text.initialAlertDocument}</li>
          <li>{text.initialAlertAddress}</li>
        </InitialAlertContent>
      </InitialAlert>
    );
  };

  renderNewProductName = (prevText) => {
    const { prestaClipNameChange } = this.props;
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  render() {
    const { step, open } = this.state;
    const {
      preapprovals = [],
      username = '',
      lastOne = {},
      addressProofFiles = [],
      preapprovalSelected,
      classes,
      hasLastOne,
    } = this.props;

    const providerCode = getProviderCode(
      this.props.location.pathname.toUpperCase()
    );
    let hasProofAddressDocuments = addressProofFiles.length > 0;
    const withLoans = preapprovals.filter(
      (preapproval) => preapproval.loan_id !== null,
    );
    const availables = preapprovals.filter(
      (preapproval) => PreapprovalStatus.AVAILABLE === preapproval.status,
    );
    const currentAmount = preapprovalSelected
      ? preapprovalSelected.amount
      : null;
    const hasPreviousLoan = hasLastOne && lastOne.pre_approval_id;

    return (
      <LoansContent
        hasPreapprovals
        hasLoans={withLoans.length > 0}
        tab={LoansTabs.NEW_OFFER}
      >
        <CenterContent>
          <RequestAmount>
            {text.changeAmountTitle(
              toCurrencyFormat(
                preapprovalSelected ? preapprovalSelected.amount : null,
              ),
            )}
          </RequestAmount>
          <Button
            className={classes.buttonRoot}
            color="primary"
            onClick={this.handleOpen}
          >
            {text.changeAmount}{' '}
            <ArrowDropDownIcon className={classes.buttonIconRoot} />
          </Button>
          <Modal open={open} onClose={this.handleClose}>
            <ModalContent>
              <Button
                className={classes.buttonClose}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </Button>
              <ModalTitle>
                {text.modalTitle}
                <LoansOffers
                  preapprovals={availables}
                  currentAmount={currentAmount}
                  closeModal={this.handleClose}
                />
              </ModalTitle>
            </ModalContent>
          </Modal>
          <LoansStepper
            step={step - 1}
            showCreditHistory={showCreditHistory(providerCode)}
          />
          {step === 1 && this.renderAlert(hasPreviousLoan)}
          <FormContainer>
            <ExternalForm
              onSubmit={this.save}
              step={step}
              changeStep={this.changeStep}
              lastOne={lastOne}
              username={username}
              hasProofAddressDocuments={hasProofAddressDocuments}
              async={true}
            />
          </FormContainer>
        </CenterContent>
      </LoansContent>
    );
  }
}

ExternalInformation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loansFormURL: state.loans.loansFormURL,
    lastOne: getLastOne(state),
    hasLastOne: hasLastOne(state),
    username: getUserName(state),
    preapprovals: getPreapprovals(state),
    preapprovalSelected: getPreapprovalSelected(state),
    identityFiles: getLoansIdentityDocuments(state),
    identityFilesBack: getLoansIdentityDocumentsBack(state),
    passportFiles: getLoansPassportDocuments(state),
    addressProofFiles: getLoansProofAddressDocuments(state),
    isFormSent: isLoansFormSent(state),
    role: state.user.role,
    prestaClipNameChange: getPrestaClipNameChange(state),
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  saveMerchantData,
  changePreapproval,
  savePreapprovalSelected,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withWidth()(ExternalInformation)))
);
