import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';

import { getPrestaClipNameChange } from '../../redux/selectors';
import {
  Forms,
  LoansCurrentProductName,
  LoansNewProductName,
} from '../../../../constants/AppConstants';

import { required, isInteger, fourNumbers } from '../../../../helpers/validate';
import { ClipGrid } from '@clipmx/clip-storybook';
import RadioField from '../../../../helpers/FormUtils/RadioField';
import TextField from '../../../../helpers/FormUtils/TextField';
import FormControl from '@clipmx/clip-storybook/dist/components/FormControl';
import ClevertapField from '../../../forms/ClevertapField';
import { EVENTS } from '../../../../helpers/Clevertap';
import { lenderPayload } from '../../Commons/Clevertap';
import styles from './styles';
import text from './text';
import PropTypes from 'prop-types';

export const HistoryStep = ({
  hasCreditCard,
  prestaClipNameChange,
  change,
  preapprovalSelected,
  showCashAdvance,
  loansFormVersion,
}) => {
  const classes = styles();
  const deleteLast4 = () => {
    change(Forms.MR_PRESTA, 'lastCreditCardNumbers', '');
  };

  const renderNewProductName = (prevText) => {
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  return (
    <Fragment>
          <ClipGrid container spacing={2}>
      <div className={classes.StepTitleStyles}>{text.title}</div>
      <div className={classes.StepDescriptionStyles}>
        {renderNewProductName(text.description)}
      </div>

      <div className={classes.StepSectionStyles}>{text.creditCardSection}</div>
      <FormControl fullWidth>
        <ClevertapField
          name="hasCreditCard"
          component={RadioField}
          validate={[required]}
          options={text.creditCardOptions}
          onChange={deleteLast4}
          clevertap={{
            listener: 'onChange',
            event: EVENTS.LOANS.CREDIT_HISTORY_SELECTED,
            payload: lenderPayload(preapprovalSelected, {
              showCashAdvance,
            }),
          }}
        />
        {hasCreditCard === 'true' ? (
          <ClevertapField
            name="lastCreditCardNumbers"
            component={TextField}
            inputProps={{ maxLength: 4 }}
            type="text"
            label={text.creditCardLast4}
            validate={[required, isInteger, fourNumbers]}
            clevertap={{
              listener: 'onChange',
              event: EVENTS.LOANS.CREDIT_HISTORY_COMPLETED,
              payload: lenderPayload(preapprovalSelected, {
                showCashAdvance,
              }),
            }}
          />
        ) : null}
      </FormControl>

      <div className={classes.StepSectionStyles}>{text.mortgageSection}</div>
      <FormControl fullWidth>
        <ClevertapField
          name="hasMortgageCredit"
          component={RadioField}
          validate={[required]}
          options={text.mortgageOptions}
          clevertap={{
            listener: 'onChange',
            event: EVENTS.LOANS.CREDIT_HISTORY_MC_SELECTED,
            payload: lenderPayload(preapprovalSelected, {
              showCashAdvance,
            }),
          }}
        />
      </FormControl>

      <div className={classes.StepSectionStyles}>{text.automobileSection}</div>
      <FormControl fullWidth>
        <ClevertapField
          name="hasVehicleCredit"
          component={RadioField}
          validate={[required]}
          options={text.mortgageOptions}
          clevertap={{
            listener: 'onChange',
            event: EVENTS.LOANS.CREDIT_HISTORY_AC_SELECTED,
            payload: lenderPayload(preapprovalSelected, {
              showCashAdvance,
            }),
          }}
        />
      </FormControl>
      </ClipGrid>
    </Fragment>
  );
};

const selector = formValueSelector(Forms.MR_PRESTA);

const mapStateToProps = (state) => {
  return {
    hasCreditCard: selector(state, 'hasCreditCard'),
    lastCreditCardNumbers: selector(state, 'lastCreditCardNumbers'),
    hasMortgageCredit: selector(state, 'hasMortgageCredit'),
    hasVehicleCredit: selector(state, 'hasVehicleCredit'),
    prestaClipNameChange: getPrestaClipNameChange(state),
    preapprovalSelected: state.loans?.request?.preapproval,
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  change,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryStep);

HistoryStep.propTypes = {
  change: PropTypes.func.isRequired,
  prestaClipNameChange: PropTypes.bool.isRequired,
  lastCreditCardNumbers: PropTypes.string,
  hasCreditCard: PropTypes.string,
  hasMortgageCredit: PropTypes.string,
  hasVehicleCredit: PropTypes.string,
};
