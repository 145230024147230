import React from 'react';
import Button from '@clipmx/clip-storybook/dist/components/Button';
import Card from '@clipmx/clip-storybook/dist/components/Card';
import Accordion from 'components/paymentrequest/commons/Accordion';
import useStyles from './styles';
import './styles.css';
// assets
import shoppingCartImage from '../../../../assets/images/checkout/shoppingCart.png';
import visaIcon from '../../../../assets/images/checkout/icons/finance/visa.svg';
import mastercardIcon from '../../../../assets/images/checkout/icons/finance/masterCard.svg';
import amexIcon from '../../../../assets/images/checkout/icons/finance/amex.svg';
import carnetIcon from '../../../../assets/images/checkout/icons/finance/carnet.svg';
import upSiValeIcon from '../../../../assets/images/checkout/icons/finance/upSiVale.svg';
import edenredIcon from '../../../../assets/images/checkout/icons/finance/edenred.svg';
import monthlyPaymentsIcon from '../../../../assets/images/checkout/icons/finance/monthlyPayments.svg';
import lockIcon from '../../../../assets/images/checkout/icons/benefits/lock.svg';
import myChargesIcon from '../../../../assets/images/checkout/icons/benefits/myCharges.svg';
import paymentLinkIcon from '../../../../assets/images/checkout/icons/benefits/paymentLink.svg';
import mastercardSecureCodeIcon from '../../../../assets/images/checkout/icons/security/mastercardSecureCode.svg';
import safeKeyBlueBoxReverseIcon from '../../../../assets/images/checkout/icons/security/safeKeyBlueBoxReverse.svg';
import verifiedByVisaIcon from '../../../../assets/images/checkout/icons/security/verifiedByVisa.svg';
import payImage from '../../../../assets/images/checkout/steps/pay.png';
import tokenImage from '../../../../assets/images/checkout/steps/token.png';
import tokenCreationImage from '../../../../assets/images/checkout/steps/tokenCreation.png';




export const CheckoutClip = (props) => {

    const classes = useStyles();
    const showVideo = true;

    return (
        <main className={classes.checkoutContainer}>
            <section className="checkoutClip">
                <div className="content">
                    <h1>
                        Checkout Clip
                    </h1>
                    <p>
                        Integra tu propia pasarela de pago en tu sitio web y
                        acepta todas las tarjetas de crédito y débito.
                    </p>
                    <div className="controls">
                        <Button href="#installationSteps" className="startnNow" color="primary" variant="contained">
                            Comienza ahora
                        </Button>
                        <a href="https://developer.clip.mx/reference/introducci%C3%B3n-a-clip-checkout" target="blank">Ir a documentación</a>
                    </div>
                    <div className="financeLogos">
                        <img src={visaIcon} alt="Visa" />
                        <img src={mastercardIcon} alt="Master Card" />
                        <img src={amexIcon} alt="Amex" />
                        <img src={carnetIcon} alt="Carnet" />
                        <img src={upSiValeIcon} alt="Up Si Vale" />
                        <img src={edenredIcon} alt="Edenred" />
                        <img src={monthlyPaymentsIcon} alt="Monthly Payments" />
                    </div>
                </div>
                {!showVideo && (
                    <figure>
                        <img src={shoppingCartImage} alt="Shopping Cart" />
                    </figure>
                )}
                {showVideo && (
                    <div className="videoContainer">
                        <video autoPlay playsInline loop muted>
                            <source src="https://prod-ses-email-templates-assets.s3.amazonaws.com/payment/checkout.mp4" type="video/mp4" />
                            Your browser does not support HTML video.
                        </video>
                    </div>

                )}
            </section>

            <section className="beneficios">
                <h2>Beneficios</h2>
                <div className="cards">
                    <Card>
                        <img src={myChargesIcon} alt="My Charges Icon" />
                        <h4>Aumenta tus ventas</h4>
                        <p>Tus clientes podrán pagar fácil, rápido y con la mejor experiencia <strong>desde cualquier dispositivo o computadora</strong>, con altas tasas de aprobación.</p>
                    </Card>
                    <Card>
                        <img src={paymentLinkIcon} alt="Payment Link Icon" />
                        <h4>Fácil integración</h4>
                        <p>Te damos toda la documentación para que, en unas cuantas horas, puedas instalarlo en <strong>cualquier sitio web</strong> y tus clientes empiecen a pagarte.</p>
                    </Card>
                    <Card>
                        <img src={lockIcon} alt="Lock Icon" />
                        <h4>Prevención de fraudes</h4>
                        <p>Clip usa tecnología de clase mundial para <strong>prevención de fraude</strong>, por lo que sus transacciones serán seguras.</p>
                        <div className="icons">
                            <img src={mastercardSecureCodeIcon} alt="Mastercard Secure Code" />
                            <img src={safeKeyBlueBoxReverseIcon} alt="SafeKey Blue Box Reverse" />
                            <img src={verifiedByVisaIcon} alt="Verified By Visa" />
                        </div>
                    </Card>
                </div>
            </section>

            <section className="installationSteps" id="installationSteps">
                <h2>Instala Checkout Clip en tres pasos</h2>
                <div className="steps">
                    <div className="step">
                        <div className="info">
                            <span className="stepNumber">1</span>
                            <h3>Crea un token de autenticación desde el panel de desarrollador. </h3>
                            <figure className="mobile">
                                <img src={tokenCreationImage} alt="Token Creation" />
                            </figure>
                            <p>Esta es la llave para que puedas <strong>conectar Checkout Clip con tu sitio web</strong>. Genéralo en menos de 5 minutos. </p>
                            <a href="https://developer.clip.mx/reference/autenticaci%C3%B3n-1" target="blank">¿Cómo crear tu token?</a>
                        </div>
                        <figure className="desktop">
                            <img src={tokenCreationImage} alt="Token Creation" />
                        </figure>
                    </div>

                    <div className="step">
                        <div className="info">
                            <span className="stepNumber">2</span>
                            <h3>Valida que tu token de autenticación funciona</h3>
                            <figure className="mobile">
                                <img src={tokenImage} alt="Token" />
                            </figure>
                            <p>Copia y pega tu llave o token en el campo de header en nuestro validador. En segundos te diremos <strong>si ya estás listo para conectarte con Checkout Clip.</strong></p>
                            <a href="https://developer.clip.mx/reference/createnewpaymentlink" target="blank">Valida tu token en segundos</a>
                        </div>
                        <figure className="desktop">
                            <img src={tokenImage} alt="Token" />
                        </figure>
                    </div>
                    <div className="step">
                        <div className="info">
                            <span className="stepNumber">3</span>
                            <h3>Intégralo en tu sitio web</h3>
                            <figure className="mobile">
                                <img src={payImage} alt="Pay" />
                            </figure>
                            <p>Elige el lenguaje de programación que necesites, copia el código del paso anterior e instala en <strong>unas cuantas horas.</strong> Es todo lo que necesitas para comenzar a recibir pagos. </p>
                            <a href="https://developer.clip.mx/reference/introducci%C3%B3n-a-clip-checkout" target="blank">Ir a la documentación</a>
                        </div>
                        <figure className="desktop">
                            <img src={payImage} alt="Pay" />
                        </figure>
                    </div>
                </div>
            </section>

            <section className="faq">
                <h2>Preguntas frecuentes</h2>
                <div className="accodrions">
                    <Accordion title="¿Cuál es la comisión por aceptar Checkout Clip ?">
                        <ul>
                            <li>La comisión por transacción es de 3.6 % + IVA (4.18% IVA incluido) por cada transacción que realices.</li>
                            <li>No hay montos mínimos de facturación, ni cargos mensuales.</li>
                            <li>Nuestra comisión es transparente y solamente aplicamos la comisión una vez hayas recibido tu pago.</li>
                        </ul>
                    </Accordion>
                    <Accordion title="¿En cuánto tiempo recibiré el dinero de mis ventas realizadas a través de Checkout Clip?">
                        <ul>
                            <li>El dinero se te depositará 24 horas después de la transacción.</li>
                            <li>Todos los depósitos se realizarán sin importar si es día festivo o fin de semana.</li>
                            <li>Recuerda que en Clip no necesitas una banca en específico para que se realicen tus depósitos, con que tengas la CLABE interbancaria es suficiente.</li>
                        </ul>
                    </Accordion>
                    <Accordion title="¿Qué tarjetas puedo aceptar con Checkout Clip?">
                        <ul>
                            <li>Checkout Clip acepta las tarjetas de crédito y débito como: American Express, Visa, MasterCard, Carnet, Si Vale y entre otras.</li>
                        </ul>
                    </Accordion>
                    <Accordion title="¿Cuál es el monto máximo para estas ventas?">
                        <ul>
                            <li>Los nuevos comercios cuentan con un monto máximo inicial de $5,000 MXN por transacción, mientras que hay comercios que cuentan con un límite de monto máximo de hasta $200,000 MXN. Si requieres tramitar el incremento del monto puedes comunicarte a nuestra línea de atención: 55 6393-2323.</li>
                        </ul>
                    </Accordion>
                    <Accordion title="¿Con qué tecnologías puedo conectar Checkout Clip?">
                        <ul>
                            <li>Puedes integrar nuestro Checkout Clip con los lenguajes de programación más populares como Python, Java, C#, JavaScript, entre otros. Para contar con mayor información, puedes acceder a nuestra documentación técnica en esta <a href="https://developer.clip.mx/reference/createnewpaymentlink" target="blank">liga</a>.</li>
                        </ul>
                    </Accordion>
                </div>
            </section>

            <footer>
                <h3>¿Tienes dudas?</h3>
                <div className="footerInfo">
                    Escríbenos en <a target="blank" href="https://wa.me/5215563932323">Whatsapp</a>, llámanos al <a href="tel:55 6393-2323">55 6393-2323</a>, envía un correo a <a target="blank" href="mailto:help@clip.mx">help@clip.mx</a> o escríbenos en el chat de <a href="https://clip.mx" target="blank">clip.mx</a>.
                </div>
            </footer>
        </main>
    )
}

export default CheckoutClip;


