import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SignTopImage from '../../../../../assets/images/sign.svg';
import { getConfigValue } from '../../../../../config/config';
import {
  LoansTabs,
  PreapprovalStatus,
} from '../../../../../constants/AppConstants';
import urls from '../../../../../helpers/urls';
import FormButton from '../../../../../helpers/FormUtils/FormButton';
import { getPreapprovals } from '../../../redux/selectors';
import GoogleAnalytics from '../../../GoogleAnalytics';
import LoansRedirection from '../../Redirection';
import { ExternalLenderFooter } from '../../Footer';
import { styles } from './styles';
import {
  StepsMessage,
  InstructionMessage,
  WifiBGIcon,
  WifiIcon,
  Container,
} from '../styled';
import text from '../text';

export const Congrats = props => {
  let location = useLocation();
  const { classes } = props;

  const typographyStyles = {
    root: classes.typographyRoot,
    headline: classes.typographyHeadline,
    body1: classes.typographyBody1,
    body2: classes.typographyBody2,
    display1: classes.typographyBody3,
  };

  const isMobileWebView = location.pathname.startsWith(urls.mobile);

  const getPreapprovalSelected = preapprovals =>
    preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.ACCEPTED].includes(preapproval.status) &&
        !preapproval.loan_id,
    );
  const goSign = () => {
    const { preapprovals } = props;
    const preapprovalSelected = getPreapprovalSelected(preapprovals);
    const env = `${getConfigValue('env')
      .replace('stage', 'qa')
      .substring(0, 4)}${isMobileWebView ? '_mobile' : ''}`;

    if (
      preapprovalSelected.length > 0 &&
      preapprovalSelected[0].signature_redirect_url
    ) {
      window.location.href = `${preapprovalSelected[0].signature_redirect_url}?urlsuccess=${env}`;
    }
  };

  return (
    <LoansRedirection tab={LoansTabs.NEW_OFFER}>
      <GoogleAnalytics>
        <Container>
          <Typography variant={'headline'} classes={typographyStyles}>
            {text.titleCongrats}
          </Typography>
          <img
            className={classes.banner}
            src={SignTopImage}
            alt={text.banner}
          />
          <Typography variant={'body1'} classes={typographyStyles}>
            {text.paragraph1}
          </Typography>
          {isMobileWebView && (
            <React.Fragment>
              <Typography variant={'body1'} classes={typographyStyles}>
                {text.paragraph2} <b>{text.paragraph3}</b> {text.paragraph4}
              </Typography>
              <div className={classes.buttonContainer}>
                <FormButton
                  variant="contained"
                  text={text.signButton}
                  onClick={goSign}
                />
              </div>
            </React.Fragment>
          )}
          {!isMobileWebView && (
            <React.Fragment>
              <Typography variant={'body1'} classes={typographyStyles}>
                {text.paragraphDesk1}
              </Typography>
              <StepsMessage className="one">
                <Typography variant={'body2'} classes={typographyStyles}>
                  {text.paragraphDesk2}
                </Typography>
              </StepsMessage>
              <StepsMessage className="two">
                <Typography variant={'body2'} classes={typographyStyles}>
                  {text.paragraphDesk31} <b>{text.paragraphDesk32}</b>{' '}
                  {text.paragraphDesk33}
                </Typography>
              </StepsMessage>
              <InstructionMessage>
                <WifiBGIcon>
                  <WifiIcon />
                </WifiBGIcon>
                <Typography variant={'display1'} classes={typographyStyles}>
                  {text.paragraphDesk4}
                </Typography>
              </InstructionMessage>
              <Typography variant={'body1'} classes={typographyStyles}>
                {text.paragraphDesk5}
              </Typography>
            </React.Fragment>
          )}
          <ExternalLenderFooter />
        </Container>
      </GoogleAnalytics>
    </LoansRedirection>
  );
};

Congrats.propTypes = {
  classes: PropTypes.object.isRequired,
  preapprovals: PropTypes.array,
};
Congrats.defaultProps = {
  preapprovals: [],
};

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Congrats));
