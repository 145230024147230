export default {
    title: 'Enviar dinero',
    newAccount: 'A nueva cuenta',
    savedContacts: 'Contactos guardados',
    emptyText: ' No tienes contactos guardados',
    emptyInfo: 'Así se verán tus contactos guardados para que la próxima vez sea más fácil enviarles dinero.',
    emptyData: {
        alias: 'Alias de tu contacto',
        acount: 'Nombre de contacto',
        bank: 'BANCO',
        clabe: '000000000000000',
    },
    deleteTitle: 'Eliminar contacto',
    delete: '¿Quieres eliminar a alias de tus contactos?',
    captionCancel: 'No, regresar',
    captionDelete: 'Si, eliminar',
  };
  