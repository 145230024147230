import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ClipThemeProvider, theme } from '@clipmx/clip-storybook';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import Auth from '../../Auth/authHOC';
import clsx from 'clsx';
import { store } from 'react-notifications-component';
import OneStepNavigator from '../../Commons/OneStepNavigator';
import BottomNavigation from '../../BottomNavigation';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipTypography } from '@clipmx/clip-storybook';
import BerlinSpinner from '../../BerlinSpinner';
import Card from '@clipmx/clip-storybook/dist/components/Card';
import CardContent from '@clipmx/clip-storybook/dist/components/Card/CardContent';
import BerlinService from '../../service';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import {EVENTS, Clevertap} from '../../../../helpers/Clevertap';
import NewAddress from './NewAddress';
import Instructions from './Instructions';
import TrackCard from '../TrackCard';
import logger from '../../../../helpers/logger';

import { ArrowForward as ArrowForwardIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import DeliveryManIcon from '../../../../assets/images/berlin/deliveryMan.svg';
import { BERLIN_CARD_REQUEST_TABS } from '../../../../constants/AppConstants';

import { Forms } from '../../../../constants/AppConstants';
import { styles } from './styles';
import text from './text';

const useStyles = makeStyles(styles);

export const RequestCard = (props) => {
  let history = useHistory();
  const { user, reset, merchant } = props;
  const classes = useStyles(props);
  const [accountStatus, setAccountStatus] = useState(null);
  const [accountHolder, setAccountHolder] = useState(null);
  const [enrollmentStatus, setEnrollmentStatus] = useState(null);
  const [activeCard, setActiveCard] = useState(false);
  const [enrollmentInfo, setEnrollmentInfo] = useState(null);
  const [requestedCard, setRequestedCard] = useState(false);
  const [servicesLoaded, setServicesLoaded] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const [activeRequestTab, setActiveRequestTab] = useState(
    BERLIN_CARD_REQUEST_TABS.REQUEST_CARD,
  );

  const resetRequestForm = () => reset(Forms.CARD_REQUEST);

  useEffect(() => {
    if (accountStatus === null) getAccountStatus();
    if (accountStatus !== null && accountHolder === null) getAccountHolder();
    if (enrollmentInfo === null) getEnrollmentInfo();
    if (enrollmentInfo !== null && enrollmentStatus === null)
      getEnrollmentStatus();
    if (
      enrollmentInfo !== null &&
      enrollmentStatus !== null &&
      accountStatus !== null &&
      accountHolder !== null &&
      servicesLoaded === false
    ) {
      setServicesLoaded(true);
    }
  });

  useEffect(() => {
    if (requestedCard && accountStatus.card_representation.length === 1) {
      setActiveRequestTab(BERLIN_CARD_REQUEST_TABS.TRACK_CARD);
    }
  }, [requestedCard, accountStatus]);

  const getEnrollmentStatus = async () => {
    try {
      const enrollmentStatus = await BerlinService.getMerchantEnrollmentStatus(
        merchant.info.id,
      );
      setEnrollmentStatus(enrollmentStatus.data.message);
      setRequestedCard(true);
    } catch (error) {
      if (error.response.status === 404) {
        setEnrollmentStatus([]);
      } else {
        logger.info(error);
        store.addNotification({
          title: text.rejectedTitle,
          message: text.rejectedMessage,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            showIcon: true,
            duration: 0,
          },
        });
        setActiveRequestTab(BERLIN_CARD_REQUEST_TABS.REQUEST_MENU);
      }
    }
  };

  const getEnrollmentInfo = async () => {
    try {
      const enrollmentInfo = await BerlinService.getMerchantEnrollmentInfo(
        merchant.info.id,
      );
      setEnrollmentInfo(enrollmentInfo.data.message);
    } catch (error) {
      if (error.response.status === 404) {
        setRequestedCard(false);
        setEnrollmentInfo([]);
      } else {
        logger.info(error);
      }
    }
  };

  const getAccountStatus = async () => {
    try {
      const accountStatus = await BerlinService.accountInformationV2(
        merchant.info.id,
      );
      setAccountStatus(accountStatus.data.message);
      if (
        accountStatus.data.message.card_representation &&
        (accountStatus.data.message.card_representation.length > 1 ||
        accountStatus.data.message.card_representation[0].proxy_card ||
        accountStatus.data.message.card_representation[0].proxy_card_id)
      ) {
        setActiveCard(true);
      }
    } catch (error) {
      logger.info(error);
    }
  };

  const getAccountHolder = async () => {
    try {
      const accountHolder = await BerlinService.accountHolder(
        accountStatus.account_holder_id,
      );
      setAccountHolder(accountHolder.data.message);
    } catch (error) {
      logger.info(error);
    }
  };

  const renderRequestTab = (tab) => {
    switch (tab) {
      case BERLIN_CARD_REQUEST_TABS.REQUEST_CARD:
        return (
          <>
            <OneStepNavigator
              title={text.requestFlowTitle}
              stepBackFunction={() => history.goBack()}
              stepBackTarget={BERLIN_CARD_REQUEST_TABS.REQUEST_MENU}
            />
            <div className={classes.container}>
              <div className={classes.titleContainer}>
                <ClipTypography
                  variant={'h2'}
                  component={'h1'}
                  fontWeight={'semiBold'}
                  align={'center'}
                  className={classes.typographyTitle}
                >
                  {text.title}
                </ClipTypography>
                <ClipTypography
                  variant={'body1'}
                  component={'p'}
                  align={'center'}
                  className={classes.typographySubheading}
                >
                  {text.subTitle}
                </ClipTypography>
                <div className={classes.requestImageContainer}>
                  <img src={DeliveryManIcon} alt={text.title} />
                </div>
              </div>
              <div className={classes.cardsContainer}>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.cardContentText}>
                      <ClipTypography
                        variant={'body1'}
                        component={'p'}
                        classes={{ body1: classes.typographyBody1 }}
                      >
                        {text.savedAddress}
                      </ClipTypography>
                      <div className={classes.personalInfoAddress}>
                        <ClipTypography
                          variant={'body1'}
                          component={'p'}
                          classes={{ body1: classes.typographyBody2 }}
                        >
                          {text.fullName(
                            user.first_name,
                            user.last_name,
                            user.second_last_name,
                          )}
                        </ClipTypography>
                        <ClipTypography
                          variant={'body1'}
                          component={'p'}
                          classes={{ body1: classes.typographyBody2 }}
                        >
                          {user.mobile}
                        </ClipTypography>
                      </div>
                      <ClipTypography
                        variant={'body1'}
                        component={'p'}
                        classes={{ body1: classes.typographyBody2 }}
                      >
                        {accountHolder &&
                          text.fullAddress(
                            accountHolder.address.street,
                            accountHolder.address.external_number,
                            accountHolder.address.neighborhood,
                            accountHolder.address.municipality,
                            accountHolder.address.zip_code,
                            accountHolder.address.state,
                          )}
                      </ClipTypography>
                    </div>
                    <div className={classes.cardContentButton}>
                      <ClevertapComponent
                        renderComponent={<IconButton />}
                        events={[
                          {
                            callbackName: 'onClick',
                            event: EVENTS.BERLIN.CARD.EVENT,
                            action: EVENTS.BERLIN.CARD.ACTIONS.REQUEST_PREVIOUS_ADDRESS_CLICKED,
                          },
                        ]}
                        color={'primary'}
                        onClick={() => {
                          resetRequestForm();
                          setNewAddress(false);
                          setActiveRequestTab(
                            BERLIN_CARD_REQUEST_TABS.ADDRESS_INSTRUCTIONS,
                          );
                        }}
                        >
                          <ArrowForwardIcon />
                      </ClevertapComponent>
                    </div>
                  </CardContent>
                </Card>
                <Card className={classes.cardSpacer}>
                  <CardContent
                    className={clsx(
                      classes.cardContent,
                      classes.cardContentNewAddress,
                    )}
                  >
                    <div
                      className={clsx(
                        classes.cardContentText,
                        classes.cardContentTextNewAddress,
                      )}
                    >
                      <ClipTypography
                        variant={'body1'}
                        component={'p'}
                        className={classes.typographyBody1}
                      >
                        {text.newAddress}
                      </ClipTypography>
                    </div>
                    <div className={classes.cardContentButton}>
                      <ClevertapComponent
                        renderComponent={<IconButton />}
                        events={[
                          {
                            callbackName: 'onClick',
                            event: EVENTS.BERLIN.CARD.EVENT,
                            action: EVENTS.BERLIN.CARD.ACTIONS.REQUEST_NEW_ADDRESS_CLICKED,
                          },
                        ]}
                        color={'primary'}
                        onClick={() => {
                          resetRequestForm();
                          setNewAddress(true);
                          setActiveRequestTab(
                            BERLIN_CARD_REQUEST_TABS.NEW_ADDRESS,
                          );
                        }}
                        >
                          <ArrowForwardIcon />
                      </ClevertapComponent>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </>
        );
      case BERLIN_CARD_REQUEST_TABS.NEW_ADDRESS:
        return (
          <>
            <OneStepNavigator
              title={text.requestFlowTitle}
              stepBackFunction={setActiveRequestTab}
              stepBackTarget={BERLIN_CARD_REQUEST_TABS.REQUEST_CARD}
            />
            <NewAddress setActiveTab={setActiveRequestTab} />
          </>
        );
      case BERLIN_CARD_REQUEST_TABS.ADDRESS_INSTRUCTIONS:
        return (
          <>
            <OneStepNavigator
              title={text.requestFlowTitle}
              stepBackFunction={setActiveRequestTab}
              stepBackTarget={
                newAddress
                  ? BERLIN_CARD_REQUEST_TABS.NEW_ADDRESS
                  : BERLIN_CARD_REQUEST_TABS.REQUEST_CARD
              }
            />
            <Instructions
              setActiveTab={setActiveRequestTab}
              setEnrollmentStatus={setEnrollmentStatus}
              setEnrollmentInfo={setEnrollmentInfo}
              setRequestedCard={setRequestedCard}
              setRequestedEvent={() =>
                Clevertap.event(
                  EVENTS.BERLIN.CARD.EVENT,
                  EVENTS.BERLIN.CARD.ACTIONS.REQUEST_REQUESTED,
                  )
              }
              newAddress={newAddress}
              merchantId={merchant.info.id}
              merchantAddress={accountHolder.address}
              user={user}
            />
          </>
        );
      case BERLIN_CARD_REQUEST_TABS.TRACK_CARD:
        return (
          <>
            <OneStepNavigator
              title={text.requestFlowTitle}
              stepBackFunction={() => {
                history.goBack();
              }}
            />
            <TrackCard
              setDeliveryViewedEvent={(status) =>
                Clevertap.event(
                  EVENTS.BERLIN.CARD.EVENT, 
                  EVENTS.BERLIN.CARD.ACTIONS.DELIVERY_STATUS_VIEWED,
                  { status },
                )
              }
              setActiveTab={setActiveRequestTab}
              enrollmentInfo={enrollmentInfo}
              enrollmentStatus={enrollmentStatus}
            />
          </>
        );
      default:
        return;
    }
  };

  return (
      <BottomNavigation>
        <ClipThemeProvider theme={theme}>
          {servicesLoaded ? (
            renderRequestTab(activeRequestTab)
          ) : (
            <BerlinSpinner />
          )}
        </ClipThemeProvider>
      </BottomNavigation>
  );
};

RequestCard.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  merchantAddress: PropTypes.object,
  setActiveTab: PropTypes.func,
};

RequestCard.defaultProps = {
  classes: {},
  user: {},
  merchantAddress: {},
  setActiveTab: () => {},
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    merchant: state.merchant,
    account: state.berlin.account_representation,
  };
};
const mapDispatchToProps = {
  reset,
};

export default Auth(connect(mapStateToProps, mapDispatchToProps)(RequestCard));
