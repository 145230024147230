import React from 'react';

import * as Styled from './styled';
import { connect } from 'react-redux';
import { BerlinTheme } from '../Theme';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Datos from './Datos';
import Identifications from './Identifications';
import NumeroTarjeta from './NumeroTarjeta';
import CreatingAccount from './CreatingAccount';
import OneStepNavigator from '../Commons/OneStepNavigator';

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import BerlinService from '../service';
import { store } from 'react-notifications-component';
import PropTypes from 'prop-types';

import { uploadIDPictures } from '../redux/actions';
import { Terms } from './Terms';
import { PrivacyNotice } from './PrivacyNotice';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import text from './text';

import IdentificationsPhoto from '../Registration/IdentificationsPhoto';

export class Registration extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      location: null,
      registrationStarted: false,
      enableAheadOneStep: false,
      initialValues: null,
    };
  }

  static propTypes = {
    merchant: PropTypes.object,
    user: PropTypes.object,
    ldFlags: PropTypes.object,
  };

  static defaultProps = {
    merchant: { info: '1' },
    user: {},
    ldFlags: { onboardingRequestTimeout: '60000' },
  };

  startAccountCreation = async (values, files, type) => {
    const { merchant, ldFlags } = this.props;
    const document = {
      INE: 'INE',
      PASSPORT: 'PASSPORT',
    };
    try {
      if (!this.location) {
        store.addNotification({
          title: 'No pudimos obtener tu ubicación',
          message: 'Contacta a Customer Happiness.',
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        return;
      }
      let body = {
        merchant_id: merchant.info.id,
        document: type === document.INE ? 'INE' : 'PASSPORT',
        person: {
          name: values.name.trim(),
          last_name: values.last_name.trim(),
          second_last_name: values.second_last_name.trim(),
          citizenship: '000',
          birth_place: values.birth_place,
          birth_date: this.formatBirthDate(values.birth_date),
          gender: values.gender,
          email: values.email,
          phone_number: values.phone_number,
          occupation: values.occupation,
          profession: values.occupation,
          business_sector: values.business_sector,
          tax_identifier:
            values.tax_identifier.length === 13
              ? values.tax_identifier.toUpperCase()
              : null,
          legal_identifier:
            values.tax_identifier.length === 18
              ? values.tax_identifier.toUpperCase()
              : null,
        },
        location: {
          latitude: this.location.latitude.toString().substring(0, 14),
          longitude: this.location.longitude.toString().substring(0, 14),
        },
        address: {
          city: values.city,
          street: values.street,
          external_number: values.external_number,
          internal_number: values.internal_number,
          neighborhood: values.colony,
          municipality: values.municipality,
          state: values.state,
          zip_code: values.postal_code,
          country: '004',
        },
        card: {
          card_number: values.card_number.replace(/\s+/g, ''),
        },
      };
      this.setActiveTab(4);
      let documentsUrlResponse = await BerlinService.getDocumentsUrl();
      await uploadIDPictures(files, documentsUrlResponse.data, type);
      await BerlinService.postAccountCreation(body);
      setTimeout(() => {
        this.setState({ registrationStarted: true });
      }, parseInt(ldFlags.onboardingRequestTimeout));
    } catch (error) {
      this.setActiveTab(3);
      switch (error.response.status) {
        case 409:
          store.addNotification({
            title: '',
            message: error.response.data.message,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              showIcon: true,
              duration: 0,
            },
          });
          break;
        case 404:
          store.addNotification({
            title: text.ErrorIsUsTitle,
            message: text.ErrorIsUsMessage,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          break;
        default:
          store.addNotification({
            title: text.CallCustomerHappinesTitle,
            message: text.CallCustomerHappinesMessage,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
      }
    }
  };

  formatBirthDate = (date) => {
    return `${date.substring(10, 14)}-${date.substring(5, 7)}-${date.substring(
      0,
      2,
    )}`;
  };

  setRegistrationStatus = (value) => {
    this.setState({ registrationStarted: value });
  };

  setEnableAheadOneStep = (value) => {
    this.setState({ enableAheadOneStep: value });
  };

  componentDidMount() {
    const { merchant } = this.props;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      });
    }
    BerlinService.getMerchantEnrollmentInfo(merchant.info.id).then(
      (response) => {
        this.setState({
          initialValues: {
            name: response.data.message.person.name,
            last_name: response.data.message.person.last_name,
            second_last_name: response.data.message.person.second_last_name,
            phone_number: response.data.message.person.phone_number,
            street: response.data.message.address.street,
            internal_number: response.data.message.address.internal_number,
            external_number: response.data.message.address.external_number,
            postal_code: response.data.message.address.zip_code,
            colony: response.data.message.address.neighborhood,
            colonies: [
              {
                id: response.data.message.address.neighborhood,
                name: response.data.message.address.neighborhood,
              },
            ],
            municipality: response.data.message.address.municipality,
            city: response.data.message.address.city,
            state: response.data.message.address.state,
            email: response.data.message.email,
          },
        });
      },
    );
  }

  setActiveTab = (activeTab) => {
    let newState = Object.assign({}, this.state);
    newState.activeTab = activeTab;
    this.setState(newState);
  };

  renderTab = (tab) => {
    const { ldFlags } = this.props;
    switch (tab) {
      case 1:
        return (
          <React.Fragment>
            <NumeroTarjeta setActiveTab={this.setActiveTab}></NumeroTarjeta>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <OneStepNavigator
              title={''}
              stepBackFunction={this.setActiveTab}
              stepBackTarget={1}
            />
            <Datos
              setActiveTab={this.setActiveTab}
              initialValues={this.getInitialValues()}
              setEnableAheadOneStep={this.setEnableAheadOneStep}
            />
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            {ldFlags.enableIdPhotoV2 ? (
              <IdentificationsPhoto
                startAccountCreation={this.startAccountCreation}
                setActiveTab={this.setActiveTab}
              />
            ) : (
              <>
                <OneStepNavigator
                  title={''}
                  stepBackFunction={this.setActiveTab}
                  stepBackTarget={2}
                />
                <Identifications
                  startAccountCreation={this.startAccountCreation}
                  setActiveTab={this.setActiveTab}
                  setEnableAheadOneStep={this.setEnableAheadOneStep}
                />
              </>
            )}
          </React.Fragment>
        );
      case 4:
        return (
          <CreatingAccount
            setActiveTab={this.setActiveTab}
            registrationStarted={this.state.registrationStarted}
            setRegistrationStatus={this.setRegistrationStatus}
          ></CreatingAccount>
        );
      case 5:
        return (
          <React.Fragment>
            <OneStepNavigator
              title={''}
              stepBackFunction={this.setActiveTab}
              stepBackTarget={3}
            />
            <PrivacyNotice setActiveTab={this.setActiveTab} />
          </React.Fragment>
        );
      case 6:
        return (
          <React.Fragment>
            <OneStepNavigator
              title={''}
              stepBackFunction={this.setActiveTab}
              stepBackTarget={3}
            />
            <Terms setActiveTab={this.setActiveTab} />
          </React.Fragment>
        );
      default:
        return;
    }
  };

  getInitialValues = () => {
    const { user } = this.props;
    const { initialValues } = this.state;
    if (initialValues) return initialValues;
    const userValues = {
      email: user.email,
      phone_number: user.mobile,
    };
    return userValues;
  };

  render() {
    return (
      <MuiThemeProvider theme={BerlinTheme}>
        <EmotionThemeProvider theme={BerlinTheme}>
          <Styled.RegistrationContainer>
            <ReactNotification />
            {this.renderTab(this.state.activeTab)}
          </Styled.RegistrationContainer>
        </EmotionThemeProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    merchant: state.merchant,
    ldFlags: state.berlin.ldFlags,
  };
};

export default connect(mapStateToProps)(Registration);
