export default {
  title: 'FAQs Adelanto de Ventas Clip',
  sectionTitle: 'Preguntas frecuentes',
  faqs: [
    {
      question: '¿Qué es Adelanto de Ventas Clip?',
      answer: [
        `Es un préstamo que puedes utilizar o invertir en tu negocio ya sea para renovar, 
        comprar nuevos equipos o crecer tu negocio como tú lo desees.`,
      ],
    },
    {
      question: '¿Cómo solicito Adelanto de Ventas Clip?',
      answer: [
        `1. Cada mes encontrarás ofertas de préstamo en la sección Mis ofertas, 
        ahí debes seleccionar la que más te interese.`,
        '',
        `Importante: Si no tienes ofertas es porque tu nivel de ventas ó frecuencia 
        de ventas con Clip aún no cumple con los resquisitos para ser elegible.`,
        '',
        `2. Una vez que hayas realizado el proceso en la aplicación, 
        tu solicitud entrará en revisión y  tu aprobación quedará lista en 24 horas.`,
        '',
        `3. Una vez aprobado, deberás firmar el contrato digital que llegará a tu correo electrónico, 
        cuando recibamos tu firma realizaremos el desembolso en 24 hrs.`,
      ],
    },
    {
      question: '¿Cómo pago mi Adelanto de Ventas Clip?',
      answer: [
        `Se tomará un porcentaje de tus ventas diarias (retención diaria) 
        hasta que pagues totalmente tu Adelanto de Ventas Clip.`,
        '',
        `Si no cubres el pago mensual mediante retenciones, 
        deberás cubrirlo mediante depósito ó transferencia.`,
      ],
    },
    {
      question: '¿Cómo funciona el pago Mensual?',
      answer: [
        `El pago mensual es el pago mínimo que debes cubrir mensualmente a partir de la fecha de desembolso, 
        es importante que este monto se logre cubrir con retenciones sobre tus ventas, 
        ya que de otro modo tendrás que cubrirlo mediante depósito bancario ó transferencia.`,
      ],
    },
    {
      question: '¿Cuánto tiempo tengo para pagar mi Adelanto de Ventas Clip?',
      answer: [
        `El plazo es el que eliges en la oferta. 
        Debes mantenerlas todos los meses para poderlo pagar en el plazo elegido, 
        de no cubrir el pago mínimo mensual con tus Ventas Clip, 
        puedes realizar pagos directos a tu Adelanto de Ventas Clip.`,
      ],
    },
    {
      question:
        '¿En cuánto tiempo realizan el desembolso?',
      answer: [
        'Después de que tu solicitud sea aprobada, recibirás tu contrato vía correo electrónico y deberás firmarlo.',
        '',
        'Una vez firmado el contrato, tu préstamo estará desembolsado en 24 horas.',
      ],
    },
    {
      question: '¿Qué pasa si no tengo ventas en uno o varios días?',
      answer: [
        `No es obligatorio tener ventas todos los días, 
        sin embargo debes mantenerlas para cubrir el pago mínimo mensual, 
        en caso de que no alcances a cubrirlo con tus ventas siempre puedes realizar un pago directo a 
        tu Adelanto de Ventas Clip mediante transferencia o depósito bancario.`,
        '',
        'No alcanzar el pago mínimo mensual se considera incumplimiento del contrato de crédito.',
      ],
    },
    {
      question: `¿Qué pasa si llega la fecha límite de pago mensual y 
      aún no termino de pagar mi Adelanto de Ventas Clip?`,
      answer: [
        `Se te cobrará una tasa moratoria (podrás consultarla en tu contrato) sobre saldos vencidos a 
        partir del día en que se presente el incumplimiento de pago. 
        Considera que, en caso de caer en este supuesto, serás reportado al Buró de Crédito.`,
      ],
    },
    {
      question:
        '¿Qué pasa si no lo liquido a tiempo?',
      answer: [
        `Tu Adelanto de Ventas Clip es personalizado de acuerdo a tu histórico de ventas y 
        está diseñado para que pueda ser pagado dentro del plazo máximo de pago. 
        Es importante considerar que para cumplir con tu contrato es necesario que realices un pago mensual mínimo, 
        ya sea con el porcentaje de tus ventas o, en caso de ser necesario, 
        realices una transferencia/depósito a la cuenta correspondiente. 
        Si tú crees que podría vencer tu plazo de pago antes de que puedas cubrir el monto 
        mínimo mensual de tu préstamo, comunícate con nuestro centro de atención a clientes, Customer Happiness.`,
      ],
    },
    {
      question: 'Conceptos:',
      answer: [
        'Adelanto de ventas: Es el préstamo que puedes recibir para invertir en tu negocio como tú lo desees.',
        '',
        'Total a pagar: Es el monto total que pagarás y comprende el adelanto de ventas más el cargo fijo más IVA.',
        '',
        `Vencimiento de contrato: Es el periodo de tiempo que tendrás como máximo 
        para poder pagar el monto total de acuerdo a contrato.`,
        '',
        `Porcentaje de descuento ó retención: 
        Es el porcentaje de tus ventas que se destinará para el pago del monto total de tu Adelanto de Ventas Clip`,
        '',
        `Cargo Fijo: Es la comisión que Clip aplica por la prestación 
        del servicio de adelanto de ventas Ejemplo: Capital + Cargo fijo`,
        '',
        `Venta mínima mensual: Es la venta mínima que debes tener al mes con tu Clip 
        para poder cumplir con tu pago mínimo mensual sin necesidad de realizar ningún pago directo.`,
        '',
        `Pago mínimo mensual: Es la cuota mínima menusual que debes cubrir de tu Adelanto de Ventas Clip 
        para no caer en incumplimiento del contrato de Crédito. Este pago mínimo lo puedes alcanzar con el porcentaje 
        de descuento de tus ventas diarias Clip o realizando un pago directo a tu Adelanto de Ventas Clip.`,
      ],
    },
  ],
};
