import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavigateNext as NavigateNextIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import { theme } from '@clipmx/clip-storybook/dist/theme/theme';
import { ClipThemeProvider } from '@clipmx/clip-storybook/dist/theme/themeProvider';
import { useLocation } from 'react-router-dom';
import urls from '../../../helpers/urls';
import { MyStoreEmpty } from './MyStoreEmpty';
import * as Styled from './styled';
import text from './text';
import { setNavItem } from '../../../actions/DashboardNavActions';
import { DashboardNavConstants } from '../../../constants/AppConstants';

export const MyStorePage = (props) => {
  const location = useLocation();
  const isMobileWebView = location.pathname.startsWith(urls.myStoreWebview);
  const { setNavItem } = props;

  useEffect(() => {
    setNavItem(DashboardNavConstants.ONLINE_STORE);
  }, []);

  return (
    <ClipThemeProvider theme={theme}>
      <div>
        <Styled.PaperContent variant="elevation" className="paperContent">
          <Styled.PageContent>
            {!isMobileWebView && (
              <Styled.Breadcrumb>
                <Styled.LinkBreadcrumb to={urls.section}>
                  {text.section}
                </Styled.LinkBreadcrumb>
                <Styled.NavigateIcon>
                  <NavigateNextIcon style={{ width: 24, height: 24 }} />
                </Styled.NavigateIcon>
                <strong>{text.seccionTitle}</strong>
              </Styled.Breadcrumb>
            )}
            <div>
              <MyStoreEmpty isMobileWebView={isMobileWebView} />
            </div>
          </Styled.PageContent>
        </Styled.PaperContent>
      </div>
    </ClipThemeProvider>
  );
};

const mapDispatchToProps = {
  setNavItem,
};

export default connect(null, mapDispatchToProps)(MyStorePage);
