export const styles = (theme) => {
  const rootStyles = {
    container: {
      minHeight: `calc(100vh - ${theme.spacing(15)}px)`,
      marginBottom: `${theme.spacing(7)}px`,
      display: 'flex',
      flexDirection: 'column',
    },
    subContainer: {
      width: `calc(100% - ${theme.spacing(6)}px)`,
      margin: `${theme.spacing(7)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px `,
      display: 'flex',
      flexDirection: 'column',
    },
  };

  const BankInformationStyles = {
    title: {
      '& p': {
        marginTop: theme.spacing(0.5),
        color: '#33454F',
      },
    },
    content: {
      marginTop: theme.spacing(3),
      '& > p': {
        marginBottom: theme.spacing(2),
        color: '#66737B',
      },
    },
    accountCard: {
      marginBottom: theme.spacing(2),
      padding: `${theme.spacing(2)}px 0`,
    },
    actionHolder: {
      width: `calc(100%-${theme.spacing(4)}px)`,
      margin: `0 ${theme.spacing(2)}px`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& button': {
        padding: 0,
      },
      '& svg': {
        color: theme.palette.orange.base,
      },
    },
    infoHolder: {
      marginRight: theme.spacing(2),
    },
    cardDivider: {
      width: '100%',
      height: theme.spacing(0.125),
      margin: `${theme.spacing(2)}px 0`,
      backgroundColor: theme.palette.indigo.l90,
    },
    copyAll: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    copyAllButton: {
      height: 'inherit',
      fontSize: theme.spacing(2),
      padding: 0,
    },
    settingsCard: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      '& > p': {
        marginBottom: 0,
      },
      '& button': {
        padding: 0,
      },
      '& svg': {
        color: theme.palette.orange.base,
      },
    },
    inlineLimit: {
      display: 'flex',
      justifyContent: 'center',
    },
    contactContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const CdaKeypadStyles = {
    topContent: {
      marginTop: theme.spacing(7),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
    logo: {
      maxHeight: theme.spacing(8),
      margin: `${theme.spacing(4)}px 0`,
    },
    dotsContainer: {
      textAlign: 'center',
      margin: `${theme.spacing(2)}px 0`,
    },
    '@keyframes shake': {
      '10%, 90%': {
        transform: 'translate3d(-1px, 0, 0)',
      },

      '20%, 80%': {
        transform: 'translate3d(2px, 0, 0)',
      },

      '30%, 50%, 70%': {
        transform: 'translate3d(-4px, 0, 0)',
      },

      '40%, 60%': {
        transform: 'translate3d(4px, 0, 0)',
      },
    },
    shakeAnimation: {
      animation: '$shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
      transform: 'translate3d(0, 0, 0)',
      backfaceVisibility: 'hidden',
      perspective: '1000px',
    },
    keypad: {
      width: '78vw',
      height: '46vh',
      position: 'relative',
      float: 'left',
      margin: 'auto',
    },
  };

  const PasswordCheckStyles = {
    buttonContainer: {
      display: 'flex',
      marginTop: 'auto',
      justifyContent: 'flex-end',
      '& > button': {
        fontSize: theme.spacing(2),
      },
    },
    passwordContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 130px)',
      marginTop: theme.spacing(7),
      '& h1': {
        margin: `${theme.spacing(2)}px 0`,
        color: '#33454F',
      },
    },
    inputAdornment: {
      color: `${theme.palette.orange.base}!important`,
      '& svg': {
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginLeft: theme.spacing(0.5),
      },
      '& > button': {
        color: `${theme.palette.orange.base}!important`,
      },
    },
  };

  const tooltipStyles = {
    tooltipPaper: {
      borderRadius: `${theme.spacing(2)}px ${theme.spacing(
        2,
      )}px 0px 0px!important`,
    },
    tooltipContainer: {
      width: '100%',
      height: 'auto',
      alignSelf: 'center',
      backgroundColor: theme.palette.white,
      overflow: 'auto',
      boxSizing: 'border-box',
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
        2,
      )}px ${theme.spacing(3)}px`,
      display: 'flex',
      flexFlow: 'column',
    },
    tooltipTitle: {
      marginBottom: theme.spacing(3),
    },
    tooltipButtonContainer: {
      alignSelf: 'flex-end',
      marginTop: theme.spacing(3),
      '& > button': {
        fontSize: theme.spacing(2),
      },
    },
    helpIcon: {
      width: theme.spacing(2.5),
      marginLeft: theme.spacing(0.5),
    },
  };

  return {
    ...rootStyles,
    ...BankInformationStyles,
    ...CdaKeypadStyles,
    ...PasswordCheckStyles,
    ...tooltipStyles,
  };
};
