import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, change as updateFormField } from 'redux-form';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { Forms } from '../../../../constants/AppConstants';
import { required, postalCode } from '../../../../helpers/validate';
import { allowAlphaNumerical, replaceAccents } from '../../utils';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import {EVENTS, Clevertap} from '../../../../helpers/Clevertap';
import renderSelectField from '../../Commons/BerlinInputs/Select';
import renderClipInput from '../../Commons/BerlinInputs/Input';

import { processZipCode } from '../../redux/actions';

import text from './text';
import styles from './styles';

const useStyles = makeStyles(styles);
const checkReplaceAccents = (e) => {
  e.target.value = replaceAccents(e.target.value);
};
export const AddressForm = (props) => {
  const { nextStep, berlin, invalid, merchant, dispatch, user } = props;

  useEffect(() => {
    if (merchant) {
      if (merchant.address.postal_code) {
        dispatch(
          updateFormField(
            Forms.BERLIN_REGISTRATION,
            'zip_code',
            merchant.address.postal_code,
          ),
        );
        dispatch(
          processZipCode(
            merchant.address.postal_code,
            Forms.BERLIN_REGISTRATION,
          ),
        );
      }
    }
  }, [merchant]);

  useEffect(() => {
    Clevertap.event(
      EVENTS.BERLIN.ONBOARDING.EVENT,
      EVENTS.BERLIN.ONBOARDING.ACTIONS.ADDRESS_VIEWED,
      );
  }, []);

  const classes = useStyles(props);

  const validateZipcode = (e) => {
    const { dispatch } = props;
    dispatch(processZipCode(e.target.value, Forms.BERLIN_REGISTRATION));
  };
  const checkAllowAlphaNumerical = (maxLength, e) => {
    e.target.value = allowAlphaNumerical(maxLength, e.target.value);
  };
  return (
    <form className={classes.container}>
      <ClipTypography className={classes.title} variant="h3">
        {text.title}
      </ClipTypography>
      <Field
        label={text.street}
        name="street"
        component={renderClipInput}
        fullWidth={true}
        containerMargin={true}
        validate={[required]}
        inputProps={{ maxLength: 36 }}
        onInput={(e) => checkAllowAlphaNumerical(36, e)}
      />
      <Field
        label={text.number}
        name="number"
        component={renderClipInput}
        fullWidth={true}
        inputProps={{ maxLength: 10 }}
        containerMargin={true}
        validate={[required]}
      />

      <Field
        label={text.interior}
        name="interior"
        component={renderClipInput}
        fullWidth={true}
        containerMargin={true}
        inputProps={{ maxLength: 5 }}
        onInput={(e) => checkReplaceAccents(e)}
      />
      <Field
        label={text.zipCode}
        id="zip_code"
        name="zip_code"
        type="number"
        component={renderClipInput}
        onChange={validateZipcode}
        validate={[required, postalCode]}
        fullWidth={true}
        containerMargin={true}
      />
      <div className={classes.field}>
        <Field
          component={renderSelectField}
          label={text.colony}
          name="colony"
          options={berlin.colonies}
          validate={[required]}
        />
      </div>
      <Field
        component={renderClipInput}
        label={text.municipality}
        name="municipality"
        validate={[required]}
        fullWidth={true}
        containerMargin={true}
      />
      <Field
        component={renderClipInput}
        label={text.city}
        name="city"
        validate={[required]}
        fullWidth={true}
        containerMargin={true}
      />
      <Field
        component={renderClipInput}
        label={text.state}
        name="state"
        validate={[required]}
        fullWidth={true}
      />

      <div className={classes.btnContainer}>
        <ClevertapComponent
          renderComponent={<ClipButton />}
          events={[
            {
              callbackName: 'onClick',
              event: EVENTS.BERLIN.ONBOARDING.EVENT,
              action: EVENTS.BERLIN.ONBOARDING.ACTIONS.ADDRESS_SUBMITED,
            },
          ]}
          variant="contained"
          disabled={invalid}
          onClick={() => {
            nextStep();
          }}
          color="primary"
          >
            {text.continue}
        </ClevertapComponent>
      </div>
    </form>
  );
};
const AddressFormWithForm = reduxForm({
  form: Forms.BERLIN_REGISTRATION,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(AddressForm);

const mapStateToProps = (state) => {
  return {
    berlin: state.berlin,
    merchant: state.merchant,
  };
};
AddressForm.propTypes = {
  nextStep: PropTypes.func,
  berlin: PropTypes.object,
  invalid: PropTypes.bool,
};
AddressForm.defaultProps = {
  nextStep: () => {},
  berlin: {},
  invalid: false,
};

export default connect(mapStateToProps)(AddressFormWithForm);
