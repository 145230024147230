export const loansProviderCodes = {
  CLIP: 'CLIP',
  MRP: 'MRP',
  MOT: 'MOT',
  APH: 'APH',
  HCS: 'HCS',
  CTO: 'CTO',
  R2: 'R2',
  AVL: 'AVL',
  GENERAL: 'GENERAL',
};

export const showIdentityDocumentRadios = (formPath) => {
  return !formPath.includes(loansProviderCodes.MOT);
};

export const showINEorPassport = (formPath) => {
  return !formPath?.includes(loansProviderCodes.APH);
};

export const getProviderCode = (formPath) => {
  if (formPath.includes(loansProviderCodes.APH)) {
    return loansProviderCodes.APH;
  } else if (formPath.includes(loansProviderCodes.MOT)) {
    return loansProviderCodes.MOT;
  } else if (formPath.includes(loansProviderCodes.MRP)) {
    return loansProviderCodes.MRP;
  } else if (formPath.includes(loansProviderCodes.CTO)) {
    return loansProviderCodes.CTO;
  } else if (formPath.includes(loansProviderCodes.R2)) {
    return loansProviderCodes.R2;
  } else if (formPath.includes(loansProviderCodes.AVL)) {
    return loansProviderCodes.AVL;
  } else {
    return loansProviderCodes.CLIP;
  }
};

export const validExternalProvider = (providerCode) => {
  return (
    providerCode !== loansProviderCodes.CLIP &&
    providerCode in loansProviderCodes
  );
};
export const showCreditHistory = (providerCode) => {
  return providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL;
};
export const showBirth = (providerCode) => {
  return providerCode !== loansProviderCodes.APH;
};

export const showAddressTime = (providerCode) => {
  return providerCode === loansProviderCodes.MRP;
};

export const showContactInformation = (providerCode) => {
  return providerCode === loansProviderCodes.APH;
};

export const showMaritalStatus = (providerCode) => {
  return providerCode === loansProviderCodes.MRP;
};
