import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import LoadingTransactions from './LoadingTransactions';
import NoTransactions from './NoTransactions';
import Transactions from './Transactions';

import { getMovementsFromService } from '../redux/actions';

import { getMovements, getMovementsLoadingState } from '../redux/selectors';

import { ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { styles } from './styles';
import text from './text';

const useStyles = makeStyles(styles);
export const TransactionsSection = (props) => {
  const classes = useStyles(props);
  const {
    getMovementsFromService,
    account,
    isMovementsLoading,
    cardMovements,
    selectTransaction,
  } = props;

  useEffect(() => {
    const start_date = moment().add(-1, 'months').format('YYYY-MM-DD');
    const end_date = moment().format('YYYY-MM-DD');
    if (account) {
      getMovementsFromService(account, start_date, end_date);
    }
  }, []);

  return (
    <div className={classes.container}>
      <ClipTypography
        variant={'h3'}
        component={'h2'}
        align={'center'}
        classes={{ root: classes.title }}
      >
        {text.title}
      </ClipTypography>
      {isMovementsLoading ? (
        <LoadingTransactions />
      ) : cardMovements.length > 0 ? (
        <Transactions selectTransaction={selectTransaction} />
      ) : (
        <NoTransactions />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getMovementsFromService,
};

const mapStateToProps = (state) => {
  return {
    cardMovements: getMovements(state),
    isMovementsLoading: getMovementsLoadingState(state),
    account: state.berlin.account_representation,
  };
};

TransactionsSection.propTypes = {
  getMovementsFromService: PropTypes.func.isRequired,
  cardMovements: PropTypes.array,
  isMovementsLoading: PropTypes.bool,
  account: PropTypes.object,
};

TransactionsSection.default = {
  getMovementsFromService: () => {},
  cardMovements: [],
  isMovementsLoading: true,
  account: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionsSection);
