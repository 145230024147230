export default {
  changeAmountTitle: (value) => `Solicitud por ${value}`,
  changeAmount: 'Cambiar oferta',
  modalTitle: 'Elige otra oferta',
  initialAlertTitle: 'Antes de comenzar',
  initialAlertContent:
    'Ten a la mano los siguientes documentos (png, jpg o pdf) del titular de la cuenta Clip:',
  previousLoan: (name) => `¡Hola de nuevo, ${name}!`,
  previousLoanContent: `Para solicitar tu nuevo Credi Clip confirma que los datos de tu aplicación sean
  correctos y sigan vigentes.`,
  initialAlertIdentityDocument: 'Credencial INE o Pasarpote',
  initialAlertDocument: 'RFC y CURP',
  initialAlertAddress:
    'Comprobante de domicilio (máximo con 3 meses de antigüedad)',
};
