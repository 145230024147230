import { LoansFilesStates, PreapprovalStatus } from 'constants/AppConstants';
import {
  GET_LASTONE,
  SET_LASTONE_DOCUMENTATION,
  SET_PREFILLED_FORM,
  SET_PREFILLED_COLONIES,
  SET_PREFILLED_DAYS,
  GET_PREAPPROVALS,
  GET_LOANS,
  GET_COLLECTIONS,
  SEND_REQUEST_LOAN,
  LOAD_REQUEST_FORM,
  UPLOAD_DOCUMENTS,
  DELETE_DOCUMENT,
  DELETE_DOCUMENTS_NOT_SAVED,
  SET_PREAPPROVAL_SELECTED,
  SET_MERCHANT_LOANS_COLONIES,
  SET_MERCHANT_LOANS_DAYS,
  CLEAN_PREAPPROVAL,
  CLEAN_LOANS,
  DRAG_FILE_TURNON,
  DRAG_FILE_TURNOFF,
  SENDING_FORM,
  RESET_DOCUMENTS,
  CLEAN_IDENTITY,
  CLEAN_IDENTITY_BACK,
  CLEAN_PASSPORT,
  CLEAN_PROOF_OF_ADDRESS,
  SET_CONFIRM_ADDRESS,
  SET_LOANS_LOADED,
  SET_PRESTA_CLIP_NAME_CHANGE,
  SET_SHOW_CASH_ADVANCE,
  REFILL_REQUESTED,
  SET_LOANS_FORM_VERSION,
  SET_LOANS_REGULAR_OFFERS_VERSION,
  SET_LOANS_FORM_URL,
  SET_SHOW_LOANS_TESTING_FORM,
  SET_LD_FLAGS_READY,
  SET_AMOUNT_SENT_ELEGIBLE,
  SET_DISMISS_OFFER_ALERT,
  SET_SHOW_OFFER_ALERT,
} from './types';

const initialState = {
  colonies: [],
  days: [],
  request: {},
  isFormSent: false,
  documents: {
    proofOfAddress: [],
    identity: [],
    identityBack: [],
    passport: [],
  },
  loansFormVersion: '',
  loansFormURL: '',
  loansRegularOffers: null,
  showTestingForm: null,
  prestaClipNameChange: null,
  showCashAdvance: null,
  lastOne: {},
  prefilledForm: {},
  preapprovals: [],
  loans: [],
  collections: {},
  onDragFile: false,
  sendingForm: false,
  preapprovalsLoaded: false,
  loansLoaded: false,
  confirmAddress: false,
  ldFlagsReady: false,
  amountSentForElegible: undefined,
  changeOfferSuccess: false,
};

const actionHandlers = new Map([
  [GET_LASTONE, loadLastOneState],
  [SET_LASTONE_DOCUMENTATION, setLastOneDocumentationState],
  [SET_PREFILLED_FORM, setPrefilledFormState],
  [SET_PREFILLED_COLONIES, setPrefilledColoniesState],
  [SET_PREFILLED_DAYS, setPrefilledDaysState],
  [GET_PREAPPROVALS, loadPreapprovalsState],
  [GET_LOANS, updateLoanState],
  [GET_COLLECTIONS, updateCollections],
  [SEND_REQUEST_LOAN, sendRequestLoanState],
  [LOAD_REQUEST_FORM, updateLoanState],
  [UPLOAD_DOCUMENTS, addDocumentState],
  [DELETE_DOCUMENT, deleteDocumentState],
  [DELETE_DOCUMENTS_NOT_SAVED, deleteDocumentsNotSaved],
  [SET_MERCHANT_LOANS_COLONIES, updateLoanState],
  [SET_MERCHANT_LOANS_DAYS, updateLoanState],
  [DRAG_FILE_TURNON, dragFileOn],
  [DRAG_FILE_TURNOFF, dragFileOff],
  [SET_PREAPPROVAL_SELECTED, setPreapprovalSelected],
  [CLEAN_PREAPPROVAL, cleanPreapproval],
  [CLEAN_LOANS, cleanLoans],
  [SENDING_FORM, sendingFormState],
  [RESET_DOCUMENTS, resetDocuments],
  [CLEAN_IDENTITY, cleanIdentity],
  [CLEAN_IDENTITY_BACK, cleanIdentityBack],
  [CLEAN_PASSPORT, cleanPassport],
  [CLEAN_PROOF_OF_ADDRESS, cleanProofOfAddress],
  [SET_LOANS_FORM_VERSION, setLoansFormVersionState],
  [SET_LOANS_REGULAR_OFFERS_VERSION, setLoansRegularOffersVersionState],
  [SET_LOANS_FORM_URL, setLoansFormURLState],
  [SET_CONFIRM_ADDRESS, setConfirmAddress],
  [SET_LOANS_LOADED, setLoansLoaded],
  [SET_PRESTA_CLIP_NAME_CHANGE, setPrestaClipNameChangeState],
  [SET_SHOW_CASH_ADVANCE, setShowCashAdvanceState],
  [REFILL_REQUESTED, refillRequestedState],
  [SET_SHOW_LOANS_TESTING_FORM, setShowLoansTestingFormState],
  [SET_LD_FLAGS_READY, setLDFlagsReady],
  [SET_AMOUNT_SENT_ELEGIBLE, setAmountSentForElegible],
  [SET_DISMISS_OFFER_ALERT, setDismissOfferAlert],
  [SET_SHOW_OFFER_ALERT, setShowOfferAlert],
]);

function resetDocuments(state, action) {
  return { ...state, documents: initialState.documents };
}

function sendingFormState(state, action) {
  const {
    payload: { sendingForm },
  } = action;
  return { ...state, sendingForm };
}

function setConfirmAddress(state, action) {
  const {
    payload: { confirmAddress },
  } = action;
  return { ...state, confirmAddress };
}

function sendRequestLoanState(state) {
  const {
    preapprovals: preapprovalsOrgin = [],
    request: { preapproval: preapprovalSelected },
  } = state;
  const preapprovals = preapprovalsOrgin.map((preapproval) => {
    const newPreapproval = {};
    Object.assign(newPreapproval, preapproval);
    if (preapproval.pre_approval_id === preapprovalSelected.pre_approval_id) {
      newPreapproval.status = PreapprovalStatus.ACCEPTED;
    } else if (preapproval.status === PreapprovalStatus.AVAILABLE) {
      newPreapproval.status = PreapprovalStatus.DECLINED;
    }
    return newPreapproval;
  });
  return {
    ...state,
    isFormSent: true,
    request: {},
    preapprovals,
    documents: {
      proofOfAddress: [],
      identity: [],
      identityBack: [],
      passport: [],
    },
  };
}

function addDocumentState(state, action) {
  const {
    payload: { type, file },
  } = action;
  const item =
    state.documents[type].find((item) => item.name === file.name) || {};
  const { loaded = 0 } = item;
  const newItem = {
    status: file.status || item.status,
    name: file.name || item.name,
    type: file.type || item.type,
    id: file.id || item.id,
    loaded: file.loaded > loaded ? file.loaded : loaded,
    key: file.key || item.key,
    token: file.token || item.token,
    base64: file.base64 || item.base64,
  };
  return {
    ...state,
    documents: {
      ...state.documents,
      [type]: [
        ...state.documents[type].filter((item) => item.name !== file.name),
        newItem,
      ],
    },
  };
}

function deleteDocumentState(state, action) {
  const {
    payload: { type, fileName },
  } = action;

  return {
    ...state,
    documents: {
      ...state.documents,
      [type]: state.documents[type].filter((file) => {
        return file.name !== fileName;
      }),
    },
  };
}

function deleteDocumentsNotSaved(state, action) {
  const {
    payload: { type },
  } = action;
  return {
    ...state,
    documents: {
      ...state.documents,
      [type]: state.documents[type].filter(
        (file) => file.status === LoansFilesStates.SAVED,
      ),
    },
  };
}

function setPreapprovalSelected(state, action) {
  const {
    payload: { preapproval },
  } = action;
  return {
    ...state,
    request: {
      ...state.request,
      preapproval,
    },
  };
}
function setDismissOfferAlert(state) {
  return {
    ...state,
    changeOfferSuccess: false,
  };
}
function setShowOfferAlert(state) {
  return {
    ...state,
    changeOfferSuccess: true,
  };
}
function cleanLoans(state) {
  return {
    ...state,
    isFormSent: false,
    confirmAddress: false,
    collections: {},
    loans: [],
    preapprovals: [],
    lastOne: {},
    prefilledForm: {},
    preapprovalsLoaded: false,
    loansLoaded: false,
    ldFlagsReady: false,
    amountSentForElegible: undefined,
  };
}

function cleanPreapproval(state) {
  return {
    ...state,
    request: {},
  };
}

function cleanIdentity(state) {
  return {
    ...state,
    documents: {
      ...state.documents,
      identity: [],
    },
  };
}

function cleanIdentityBack(state) {
  return {
    ...state,
    documents: {
      ...state.documents,
      identityBack: [],
    },
  };
}

function cleanPassport(state) {
  return {
    ...state,
    documents: {
      ...state.documents,
      passport: [],
    },
  };
}

function cleanProofOfAddress(state) {
  return {
    ...state,
    documents: {
      ...state.documents,
      proofOfAddress: [],
    },
  };
}

function updateLoanState(state, action) {
  return { ...state, ...action.payload };
}

function setLoansLoaded(state, action) {
  return { ...state, loansLoaded: true };
}

function loadLastOneState(state, action) {
  return { ...state, ...action.payload };
}

function setLoansFormVersionState(state, action) {
  return { ...state, loansFormVersion: action.payload };
}

function setLoansRegularOffersVersionState(state, action) {
  return { ...state, loansRegularOffers: action.payload };
}

function setLoansFormURLState(state, action) {
  return { ...state, loansFormURL: action.payload };
}

function setPrestaClipNameChangeState(state, action) {
  return { ...state, prestaClipNameChange: action.payload };
}

function setShowCashAdvanceState(state, action) {
  return { ...state, showCashAdvance: action.payload };
}

function setShowLoansTestingFormState(state, action) {
  return { ...state, showTestingForm: action.payload };
}

function setLastOneDocumentationState(state, action) {
  return { ...state, ...action.payload };
}

function setPrefilledFormState(state, action) {
  return { ...state, ...action.payload };
}

function setPrefilledColoniesState(state, action) {
  return { ...state, ...action.payload };
}

function setPrefilledDaysState(state, action) {
  return { ...state, ...action.payload };
}

function loadPreapprovalsState(state, action) {
  return { ...state, ...action.payload, preapprovalsLoaded: true };
}

function updateCollections(state, action) {
  return {
    ...state,
    collections: {
      ...state.collections,
      [action.payload.id]: [...action.payload.collections],
    },
  };
}

function dragFileOn(state) {
  return { ...state, onDragFile: true };
}

function dragFileOff(state) {
  return { ...state, onDragFile: false };
}

function refillRequestedState(state, action) {
  const { preapprovals: preapprovalsOrgin = [] } = state;
  const preapprovals = preapprovalsOrgin.map((preapproval) => {
    const newPreapproval = {};
    Object.assign(newPreapproval, preapproval);
    if (preapproval.pre_approval_id === action.payload) {
      newPreapproval.status = PreapprovalStatus.ACCEPTED;
    } else if (preapproval.status === PreapprovalStatus.AVAILABLE) {
      newPreapproval.status = PreapprovalStatus.DECLINED;
    }
    return newPreapproval;
  });
  return { ...state, preapprovals, request: {}, isFormSent: true };
}

function setLDFlagsReady(state) {
  return { ...state, ldFlagsReady: true };
}

function setAmountSentForElegible(state, action) {
  return { ...state, amountSentForElegible: action.payload };
}

export default function loansReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}
