import axios from 'axios';
import Cookies from 'js-cookie';
import { app } from '../../../config/config';
import config from '../../../config';
import { getRequestOptions } from '../../../helpers/fetchUtil';
import AnalyticsEvents from '../../../helpers/AnalyticsEvents';
import apiPaths from '../../../helpers/apiPaths';
import { getToken } from '../../../helpers/tokenHelpers';
import { Analytics, AnalyticsCategories } from '../../../utils/Analytics';

export default class AuthService {
  static validateToken(userId, token) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.resetPasswordVerifyToken, config('apiUrlv3'));
    const body = { email: userId, reset_token: token };

    return axios.post(url, body, options);
  }

  static logout(email) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.logout, config('apiUrl'));
    const body = {
      email,
      type: 'LOGOUT',
      source: 'M_DASHBOARD',
      jwt_token: getToken(),
    };

    return axios.post(url, body, options).then((res) => {
      AnalyticsEvents.signOut();
      return res.data;
    });
  }

  static login(email, password) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.login, config('apiUrl'));
    const body = {
      email,
      password,
      type: 'LOGIN',
      source: 'M_DASHBOARD',
    };

    return axios.post(url, body, options).then((res) => res.data);
  }

  static validateResetToken(userId, token) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.resetPasswordVerifyToken, config('apiUrlv3'));
    const body = {
      email: userId,
      reset_token: token,
    };

    return axios.post(url, body, options);
  }

  static getPasswordResetToken(email) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.resetPasswordRequest, config('apiUrl'));
    const body = { email };

    return axios.post(url, body, options);
  }

  static resetPassword(id, token, password, confirmPassword) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.resetPasswordValidation, config('apiUrlv3'));
    const body = {
      email: id,
      reset_token: token,
      new_password: password,
      confirm_new_password: confirmPassword,
    };

    return axios.post(url, body, options);
  }

  static async getRegistrationMeta() {
    const options = getRequestOptions();
    const url = new URL(apiPaths.registrationMetaData, config('apiUrl'));

    const { data } = await axios.get(url, options);

    const industries = [];
    let others = {};

    const setOthers = (id) => {
      others = {
        id: id,
        name: 'Otros: Mi negocio es diferente a estas categorías',
        other: true,
      };
    };

    data.industries
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      })
      .map((item) =>
        item.name === 'Otros' ? setOthers(item.id) : industries.push(item),
      );

    industries.push(others);
    return { ...data, industries };
  }

  static async register({ formValues, terms, privacy, signupFlow }) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.signUp, config('apiUrlv2'));

    const getBody = () => {
      if (signupFlow === 'EMAIL_FLOW') {
        return {
          user: {
            email: formValues.email,
            password: formValues.password,
            mobile: formValues.phone,
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            second_last_name: formValues.secondLastName,
            curp: formValues.curp,
            merchant_attributes: formValues.merchant_attributes,
          },
        };
      }
      return {
        user: {
          referral_code: formValues.referralCode,
          email: formValues.email,
          password: formValues.password,
          first_name: formValues.firstName,
          last_name: formValues.lastName,
          second_last_name: formValues.secondLastName,
          mobile: formValues.phone,
          merchant_attributes: {
            name: formValues.businessName,
            industry_id: formValues.industry,
            industry_custom_description: formValues.customIndustry || '',
            terms_version: terms,
            privacy_policy_version: privacy,
          },
        },
      };
    };

    let body = getBody();

    if (signupFlow) {
      body.version_flow = signupFlow;
    }

    // Referrer information.
    const referrer = Cookies.getJSON('referrer');

    if (referrer) {
      body.referrer = {
        tag: referrer.utm_source,
        merchant_reference_id: referrer.utm_medium,
        custom_name: referrer.utm_campaign,
        custom_value: referrer.utm_content,
        terms: referrer.utm_term,
      };
    }

    if (signupFlow === 'EMAIL_FLOW') {
      options.headers.Accept = 'application/vnd.com.payclip.v2+json';
    }

    return axios.post(url, body, options).then((res) => {
      AnalyticsEvents.register();
      //Send event GTM, step 1 registration
      Analytics.populateStepOnboarding(
        1,
        AnalyticsCategories.REGISTRATION,
        'Submit',
        body.referral_code ? 'Success whit code' : 'Success',
      );
      return res.data;
    });
  }
  // envío de SMS
  static async phoneVerify(email, phone) {
    const options = getRequestOptions();
    const url = `${app.apiUrlv3}${apiPaths.phoneVerify}`;
    const payload = { countryCode: 'MX', uniqueId: email, phone };
    return axios.post(url, payload, options).then((res) => res.data);
  }
  //Confirmación de Sms
  static async phoneConfirm(code, validationId) {
    const options = getRequestOptions();
    const url = `${app.apiUrlv3}${apiPaths.phoneConfirm}`;
    const payload = { code, validationId };
    return axios.post(url, payload, options).then((res) => res.data);
  }

  static async checkIfEmailExists(email) {
    const options = getRequestOptions();
    const url = `${app.apiUrlv3}${apiPaths.checkEmailAddressExists}`;
    const payload = { email }
    return axios.post(url, payload, options);
  }

  static async validateSignupEmail(email, validationId) {
    const url = new URL(apiPaths.signUp, config('apiUrlv2'));
    const body = {
      user: { email, validation_id: validationId },
      version_flow: 'EMAIL_FLOW',
    };
    return axios
      .post(url, body, {
        headers: { Accept: 'application/vnd.com.payclip.v2+json' },
      })
      .then((res) => {
        if (res.data.status !== 200) {
          return Promise.reject({ err: res.data.message });
        }
        return res.data;
      });
  }
}
