export const styles = (theme) => {
  const stepperStyles = {
    stepperRoot: {
      padding: `${theme.spacing.unit * 2.25}px 0 ${theme.spacing.unit * 3}px 0`,
    },
  };

  const labelStyles = {
    stepLabel: {
      fontWeight: 600,
      marginTop: `${theme.spacing.unit * 0.5}px!important`,
      fontSize: `${theme.spacing.unit * 1.5}px`,
      color: '#b3b9bd',
    },
    stepActive: {
      color: `${theme.palette.naranja.bloodOrange}!important`,
    },
    stepIconContainer: {
      width: `${theme.spacing.unit * 4}px`,
      height: `${theme.spacing.unit * 4}px`,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.palette.naranja.bloodOrange,
      color: theme.palette.common.white,
      '& > svg': {
        marginTop: `${theme.spacing.unit * 0.5}px`,
      },
    },
    stepConnector: {
      marginTop: `${theme.spacing.unit * 0.5}px`,
    },
  };

  return { ...stepperStyles, ...labelStyles };
};
