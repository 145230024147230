import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import {
  SubmitButton as CommonSubmitButton,
  BaseButton,
} from 'components/common/Buttons';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const regularText = '#000b11';
const clipOrange = '#FC4C02';
const unselectedDots = '#d8d8d8';

export const SelectedIndicatorStyles = {
  background: clipOrange,
  width: 8,
  height: 8,
  display: 'inline-block',
  margin: '0 8px',
  borderRadius: '4px',
};

export const IndicatorStyles = {
  background: unselectedDots,
  width: 8,
  height: 8,
  display: 'inline-block',
  margin: '0 8px',
  borderRadius: '4px',
};

export const LandingContainer = styled.div(() => ({
  background: 'white',
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  minHeight: '600px',
  alignItems: 'center',
}));

export const LandingImage = styled.img(() => ({
  width: '19.5rem',
  height: '9.625rem',
  objectFit: 'contain',
}));

export const LandingLogoImage = styled.img(() => ({
  width: '5.75rem',
  height: '3.75rem',
  objectFit: 'contain',
  marginTop: '3rem',
  marginBottom: '1rem',
}));

const BaseButtonStyles = ({ theme }) => ({
  margin: '1rem 0rem',
  width: '15rem',
  textTransform: 'none',
});

const WhiteButtonStyles = ({ theme }) => ({
  margin: '1rem 0rem',
  width: '15rem',
  textTransform: 'none',
  color: clipOrange,
  backgroundColor: 'white',
  '&:hover': {
    color: clipOrange,
    backgroundColor: 'white',
    border: `solid 1px ${clipOrange}`,
  },
});

export const CreatingAccountContainer = styled.div(() => ({
  background: 'white',
  width: '20.5rem',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  marginTop: '5rem',
  minHeight: '600px',
}));

export const VerifyingData = styled.div(() => ({
  width: '20.5rem',
  height: '1.8rem',
  fontSize: '1.5rem',
  lineHeight: '1.5rem',
  fontWeight: '600',
  color: regularText,
  textAlign: 'center',
  marginBottom: '1rem',
}));

export const CheckingData = styled.div(() => ({
  width: '20.5rem',
  height: '3rem',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: 'normal',
  color: regularText,
  textAlign: 'center',
  marginBottom: '1rem',
}));

export const Thanks = styled.div(() => ({
  width: '20.5rem',
  height: '1.5rem',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: '500',
  color: regularText,
  textAlign: 'center',
  marginBottom: '3rem',
}));

export const ClockImage = styled.img(() => ({
  width: '7rem',
  height: '7rem',
  objectFit: 'contain',
  marginBottom: '3rem',
  alignSelf: 'center',
}));

export const LandingWelcomeMessage = styled.div(() => ({
  marginTop: '1.063rem',
  marginBottom: '1rem',
  width: '19.5rem',
  height: '3.563rem',
  fontFamily: 'Barlow',
  fontSize: '1rem',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#323232',
}));

export const LandingWelcomeMessageTextOne = styled.span(() => ({
  fontWeight: '500',
}));

export const ClipCardControl = styled.div(() => ({
  paddingTop: '1.5rem',
  width: '20rem',
  height: 'auto',
  fontSize: '1.5rem',
  fontWeight: '600',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: regularText,
  textAlign: 'center',
  marginBottom: '1.5rem',
}));

export const ClipCardControlContainer = styled.div(() => ({
  width: '19rem',
  height: 'auto',
  marginBottom: '1.5rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const ClipCardControlIcon = styled.img(() => ({
  width: '2.5rem !important',
  height: '2.5rem !important',
  objectFit: 'contain !important',
  marginLeft: '0.5rem',
}));

export const ClipCardControlText = styled.div(() => ({
  width: '16.5rem',
  height: 'auto',
  fontSize: '1rem',
  fontWeight: '500',
  color: regularText,
  textAlign: 'left',
  marginLeft: '0.75rem',
}));

export const ClipCardControlEasyText = styled.div(() => ({
  width: '100%',
  height: 'auto',
  fontSize: '1rem',
  fontWeight: '500',
  color: regularText,
  textAlign: 'center',
}));

export const SubmitButton = styled(CommonSubmitButton)(BaseButtonStyles);

export const WhiteSubmitButton = styled(BaseButton)(WhiteButtonStyles);

export const LdsRing = styled.div(() => ({
  display: 'inline-block',
  position: 'relative',
  width: '30px',
  height: '30px',
  '& div': {
    boxSizing: 'border-box',
    display: 'block',
    position: 'absolute',
    width: '15px',
    height: '15px',
    margin: '8px',
    border: '3px solid #fff',
    borderRadius: '50%',
    animation: `${ldsRingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
    borderColor: '#fc4c02 transparent transparent transparent',
  },
  '& div:nth-child(1)': {
    animationDelay: '-0.45s',
  },
  '& div:nth-child(2)': {
    animationDelay: '-0.3s',
  },
  '& div:nth-child(3)': {
    animationDelay: '-0.15s',
  },
}));

const ldsRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
    }
  100% {
    transform: rotate(360deg);
    }
`;
