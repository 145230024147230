export default {
  postalCode: 'Código Postal',
  adviceCP: 'Introduce un código postal',
  neighborhood: 'Colonia',
  delegation: 'Delegación / Municipio',
  city: 'Ciudad / Estado',
  street: 'Calle',
  streetNumber: 'Número exterior',
  interiorNumber: 'Número interior (opcional)',
  titleAddressType: 'La dirección que voy a dar es la de:',
  myHouse: 'mi casa',
  myHouseValue: 'Casa',
  myBusiness: 'mi negocio',
  myBusinessValue: 'Negocio',
  howlongHouseAddress: '¿Cuánto tiempo llevas en esta dirección?',
  howlongOfficeAddress: '¿Cuánto tiempo lleva tu negocio en esta dirección?',
  invalidMonth: 'El mes seleccionado no puede ser mayor al mes actual.',
};
