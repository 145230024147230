import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import grey from '@material-ui/core/colors/grey';
import lightBlue from '@material-ui/core/colors/lightBlue';
import lightGreen from '@material-ui/core/colors/lightGreen';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiDoneIcon from '@material-ui/icons/Done';
import { MdErrorOutline } from 'react-icons/md';
import styledWithClasses from 'helpers/StyledWithClasses';
import ClipCard from 'components/common/Card';

const displayInline = 'inline-block';
const blockDisplay = 'block';
const left = 'left';
const right = 'right';
const cursorPointer = 'pointer';
const positionAbsolute = 'absolute';
const positionRelative = 'relative';
const center = 'center';
const none = 'none';
const transform = 'transform .4s linear';
const uploadArrowTop = keyframes`
  0% {
    bottom:100%;
  }
  100% {
    bottom:140%;
  }
`;
const uploadBodyArrow = keyframes`
  0% {
    bottom:0;
  }
  100% {
    bottom:60%;
  }
`;
const round = keyframes`
   0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const FilesContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'grid',
  border: 0,
  '&.dragover': {
    width: '99.8%',
    border: `1px dashed ${theme.palette.naranja.cerulean}`,
    borderRadius: '3px',
    backgroundColor: `${theme.palette.naranja.cerulean}0d`,
    transition: 'border 250ms linear, background-color 250ms linear',
  },
}));

export const FilesCard = styledWithClasses(
  styled(ClipCard)(({ theme }) => ({
    width: '100%',
    float: 'left',
    marginBottom: `${theme.spacing.unit * 2}px`,
    paddingBottom: 0,
    borderRadius: '3px',
    '& .contentRoot': {
      textAlign: 'center',
      padding: 0,
      display: 'grid',
    },
    '& .headerRoot': {
      [theme.breakpoints.up('md')]: {
        height: '60px',
      },
    },
    '& .headerTitle': {
      lineHeight: `${theme.spacing.unit * 4}px`,
    },
  })),
  {
    contentRoot: 'contentRoot',
    headerTitle: 'headerTitle',
    headerRoot: 'headerRoot',
  },
);

export const FormControl = styled(MuiFormControl)(({ theme }) => ({
  width: '100%',
  display: 'block',
  float: 'left',
  marginTop: 0,
  marginBottom: 0,
  '& legend': {
    textAlign: 'left',
    marginTop: '8px',
    color: theme.palette.naranja.slate,
  },
}));

export const SeparatorFiles = styled.div(() => ({
  width: '100%',
  height: '1px',
  border: '0',
  backgroundColor: grey[300],
  display: 'inline-block',
}));

export const DoneIcon = styled(MuiDoneIcon)(({ theme }) => ({
  height: '19px',
  float: left,
  marginRight: `${theme.spacing.unit * 2}px`,
  color: theme.palette.text.primary,
  display: displayInline,
}));

export const DoneIconGreen = styled(MuiDoneIcon)({
  color: lightGreen['A400'],
  float: left,
});

export const PublishIconContainer = styled.div(({ theme }) => ({
  width: '28px',
  height: '28px',
  marginRight: '-12px',
  display: 'flex',
  float: 'right',
  cursor: 'pointer',
  alignItems: center,
  borderRadius: '100%',
  justifyContent: center,
  backgroundColor: theme.palette.common.white,
  '&:hover > div:before': {
    animation: `${uploadArrowTop} .5s infinite`,
    animationTimingFunction: 'ease',
  },
  '&:hover > div > div': {
    animation: `${uploadBodyArrow} .5s infinite`,
    animationTimingFunction: 'ease',
  },
}));

export const BodyArrow = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.naranja.cerulean,
  width: '100%',
  height: '100%',
  position: positionRelative,
  bottom: 0,
}));

export const PublishIcon = styled.div(({ theme }) => ({
  width: '20%',
  height: '25%',
  position: positionRelative,
  color: theme.palette.naranja.cerulean,
  '&:before, &:after': {
    content: '""',
    display: blockDisplay,
    position: positionAbsolute,
  },
  '&:before': {
    bottom: '100%',
    marginLeft: '-4',
    borderBottom: `${theme.spacing.unit}px solid currentColor`,
    borderLeft: '7px solid transparent',
    borderRight: '7px solid transparent',
  },
  '&:after': {
    top: '100%',
    left: '-80%',
    width: '260%',
    height: '3px',
    position: positionAbsolute,
    backgroundColor: theme.palette.naranja.cerulean,
  },
}));

export const ContainerLoadingIcon = styled.div({
  width: '24px',
  height: '20px',
  display: displayInline,
  float: left,
});

export const BgLoadingIcon = styled.div({
  width: '12px',
  height: '12px',
  position: positionAbsolute,
  border: `4px solid ${lightBlue[100]}`,
  borderRadius: '50%',
});

export const LoadingIcon = styled.div({
  width: '24px',
  height: '20px',
  display: displayInline,
  position: positionRelative,
});

export const LoadingPart = styled.div(({ theme }) => ({
  position: positionAbsolute,
  boxSizing: 'border-box',
  display: blockDisplay,
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  border: `${theme.spacing.unit * 0.5}px solid ${
    theme.palette.naranja.cerulean
  }`,
  borderColor: `${theme.palette.naranja.cerulean} transparent transparent transparent`,
  animation: `${round} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
  '&:nth-child(1)': {
    animationDelay: '-0.45s',
  },
  '&:nth-child(2)': {
    animationDelay: '-0.3s',
  },
  '&:nth-child(3)': {
    animationDelay: '-0.15s',
  },
}));

export const ErrorIcon = styled(MdErrorOutline)(({ theme }) => ({
  color: theme.palette.error.main,
  float: left,
  display: displayInline,
  marginRight: `${theme.spacing.unit * 1.6}px`,
}));

export const DeleteText = styled.span(({ theme }) => ({
  display: blockDisplay,
  fontSize: '16px',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '19px',
  color: theme.palette.naranja.cerulean,
  float: right,
  cursor: cursorPointer,
}));

export const DeleteLink = styled.div(({ theme }) => ({
  width: '16px',
  height: '16px',
  border: `2px solid ${theme.palette.common.white}`,
  marginRight: '-8px',
  display: blockDisplay,
  lineHeight: '19px',
  backgroundColor: grey[300],
  borderRadius: '50%',
  float: right,
  cursor: cursorPointer,
  '&:hover': {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.common.white,
    transition:
      'border-color .4s cubic-bezier(.215,.61,.355,1), background-color .4s linear',
  },
  '&:hover span': {
    backgroundColor: theme.palette.error.main,
    transition: 'background-color .4s linear',
  },
  '& span': {
    display: blockDisplay,
    width: '12px',
    height: '2px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '12px',
    transformOrigin: center,
    position: positionRelative,
    right: '-2',
  },
  '& span:nth-child(1)': {
    top: '7px',
    transform: 'rotate(45deg)',
  },
  '& span:nth-child(2)': {
    top: '5px',
    transform: 'rotate(-45deg)',
  },
}));

export const PlusLink = styled.div(({ theme }) => ({
  width: '16px',
  height: '16px',
  display: blockDisplay,
  lineHeight: '19px',
  backgroundColor: theme.palette.common.white,
  border: `2px solid ${theme.palette.naranja.cerulean}`,
  borderRadius: '50%',
  float: right,
  marginRight: '21px',
  cursor: cursorPointer,

  '&:hover span:nth-child(1)': {
    transform: 'rotate(270deg)',
    transition: transform,
  },
  '&:hover span:nth-child(2)': {
    transform: 'rotate(-180deg)',
    transition: transform,
  },
}));

export const PlusLinkPart = styled.span(({ theme }) => ({
  width: '12px',
  height: '2px',
  display: blockDisplay,
  backgroundColor: theme.palette.naranja.cerulean,
  borderRadius: '12px',
  transformOrigin: center,
  position: positionRelative,
  right: '-2',
  '&:nth-child(1)': {
    top: '7px',
    transform: 'rotate(90deg)',
  },
  '&:nth-child(2)': {
    top: '5px',
    transform: 'rotate(0deg)',
  },
}));

export const FileUploader = styled.div(({ theme }) => ({
  width: '100%',
  padding: '18px 0px',
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightLight,
  letterSpacing: 0,
  lineHeight: '19px',
  display: displayInline,
  color: theme.palette.naranja.slate,
  '&.dragover': {
    width: '99%',
    border: `1px dashed ${theme.palette.naranja.cerulean}`,
    borderRadius: '3px',
    backgroundColor: `${theme.palette.naranja.cerulean}0d`,
    transition: 'border 250ms linear, background-color 250ms linear',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    textAlign: left,
  },
}));

export const TitleContainer = styled.div({
  width: '100%',
  display: blockDisplay,
  float: left,
  textAlign: left,
});

export const Title = styled.span({
  float: left,
  marginLeft: '28px',
});

export const ProgressBar = styled.p({
  width: '98%',
  height: '8px',
  display: displayInline,
  position: positionRelative,
  margin: '0',
  paddingTop: '2px',
  paddingLeft: '2px',
  paddingRight: '2px',
  border: 0,
  backgroundColor: lightBlue[100],
});

export const MessageError = styled.p(({ theme }) => ({
  width: '70%',
  marginLeft: `${theme.spacing.unit * 4}px`,
  marginTop: 0,
  marginBottom: 0,
  color: theme.palette.error.main,
  clear: left,
  float: left,
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 300,
  textAlign: left,
  wordBreak: 'break-word',
}));

export const FileName = styled.span({
  maxWidth: '70%',
  display: blockDisplay,
  float: left,
  wordBreak: 'break-all',
  textAlign: left,
  color: grey[400],
  fontSize: '18px',
  lineHeight: '22px',
});

export const Progress = styled.span(({ theme }) => ({
  height: '6px',
  display: blockDisplay,
  backgroundColor: theme.palette.naranja.cerulean,
  border: 0,
}));

export const CancelProgress = styled.span(({ theme }) => ({
  cursor: cursorPointer,
  color: theme.palette.naranja.bloodOrange,
  fontSize: `${theme.typography.fontSize}px`,
  fontWeight: theme.typography.fontWeightMedium,
  position: positionAbsolute,
  float: right,
  display: blockDisplay,
  top: '-5px',
  left: '100%',
  paddingLeft: '1px',
}));

export const FileInputContainer = styled.span({
  display: none,
});

export const FileItems = styled.ul({
  width: '100%',
  float: left,
  display: 'inline-grid',
  paddingLeft: '0',
  margin: '0',
  listStyleType: none,
});

export const FileItem = styled.li(({ theme }) => ({
  maxWidth: '100%',
  marginLeft: `${theme.spacing.unit * 3.5}px`,
  paddingRight: `${theme.spacing.unit * 3.5}px`,
  marginTop: `${theme.spacing.unit * 2}px`,
}));

export const ChangeFileMessage = styled.span(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '25px',
  color: theme.palette.naranja.cerulean,
  float: 'right',
  cursor: 'pointer',
}));
