export default {
  title: '¿A quién quieres enviar?',
  clabe: 'CLABE INTERBANCARIA',
  clabeLenght: 'Número de 18 dígitos.',
  bank: 'BANCO',
  name: 'Nombre del destinatario',
  fullName: 'Nombre completo',
  alias: 'Alias',
  saveContact: 'Guardar Contacto',
  continue: 'Continuar',
  aliasHelper: 'Asigna un alias para identificar tu contacto.',
  clabeError: 'CLABE no válida, revísala y vuelve a intentarlo.',
  clabeErrorLength: 'Número debe contenter 18 dígitos',
  errorNameMessage: 'Debe ser mayor a  3 caracteres',
};
