import axios from 'axios';

import { app } from 'config/config';
import { getRequestOptions } from 'helpers/fetchUtil';
import apiPaths from 'helpers/apiPaths';
import logger from 'helpers/logger';
import {
  SET_PAYMENT_NOTIFICATION_STATE,
  SET_EMAIL_NOTIFICATION,
  SET_INSTALLMENTS_NOTIFICATION_STATE,
  SET_HAS_INSTALLMENTS,
  SET_INSTALLMENTS_TERM,
  SET_INSTALLMENTS_PRICE_THRESHOLD,
  SET_INSTALLMENTS_SPINNER,
  SET_PAYMENT_SPINNER,
  SET_POSTBACK_SPINNER,
  SET_INSTALLMENTS_INITIAL_DATA,
  SET_PAYMENT_INITIAL_DATA,
  SET_ENABLED_TIP,
  FETCH_TIPS_CONFIG,
  SET_TIPS_STATE,
  HANDLE_REDUX_TIPS_STATE,
  HANDLE_TIPS_ROUNDED_STATE,
  CANCEL_TIPS_EDITION,
  CAN_MANAGE_TIPS,
} from './types';

function createInstallmentsSpinnerAction(spinning) {
  return { type: SET_INSTALLMENTS_SPINNER, payload: { loading: spinning } };
}

function createPaymentSpinnerAction(spinning) {
  return { type: SET_PAYMENT_SPINNER, payload: { loading: spinning } };
}

function createPostbackSpinnerAction(spinning) {
  return { type: SET_POSTBACK_SPINNER, payload: { loading: spinning } };
}

export function setPaymentNotificationState(state) {
  return (dispatch) => {
    dispatch({
      type: SET_PAYMENT_NOTIFICATION_STATE,
      payload: state,
    });
  };
}

export function setEmailNotification(email) {
  return (dispatch) => {
    dispatch({
      type: SET_EMAIL_NOTIFICATION,
      payload: email,
    });
  };
}

export function setInstallmentsNotificationState(state) {
  return (dispatch) => {
    dispatch({
      type: SET_INSTALLMENTS_NOTIFICATION_STATE,
      payload: state,
    });
  };
}

export function setHasInstallments(hasInstallments) {
  return (dispatch) => {
    dispatch({
      type: SET_HAS_INSTALLMENTS,
      payload: hasInstallments,
    });
  };
}

export function setInstallmentsTerm(term) {
  return (dispatch) => {
    dispatch({
      type: SET_INSTALLMENTS_TERM,
      payload: term,
    });
  };
}

export function setInstallmentsPriceThreshold(threshold) {
  return (dispatch) => {
    dispatch({
      type: SET_INSTALLMENTS_PRICE_THRESHOLD,
      payload: threshold,
    });
  };
}

export function getBusinessToolsData() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.summaryMarketingTools}`;

    dispatch(createInstallmentsSpinnerAction(true));
    dispatch(createPaymentSpinnerAction(true));
    dispatch(createPostbackSpinnerAction(true));

    axios
      .get(url, options)
      .then(({ data }) => {
        if (data.installment_body) {
          dispatch({
            type: SET_INSTALLMENTS_INITIAL_DATA,
            payload: data.installment_body,
          });
        }

        if (data.notification_email) {
          dispatch({
            type: SET_PAYMENT_INITIAL_DATA,
            payload: data.notification_email,
          });
        }
      })
      .catch((err) => {
        logger.error('error getting business tools data: ', err);
        throw err;
      })
      .finally(() => {
        dispatch(createInstallmentsSpinnerAction(false));
        dispatch(createPaymentSpinnerAction(false));
        dispatch(createPostbackSpinnerAction(false));
      });
  };
}

export function saveInstallments(payload) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.installment}`;
    const request = axios.create(options);

    dispatch(createInstallmentsSpinnerAction(true));

    return request
      .post(url, payload)
      .then((response) => {
        dispatch({
          type: SET_INSTALLMENTS_INITIAL_DATA,
          payload: response.data.installment,
        });
      })
      .catch((err) => {
        logger.error('error saving installments: ', err);
        throw err;
      })
      .finally(() => {
        dispatch(createInstallmentsSpinnerAction(false));
      });
  };
}

export function toggleInstallments() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.toggleInstallment}`;
    const request = axios.create(options);

    dispatch(createInstallmentsSpinnerAction(true));

    return request
      .post(url)
      .then((response) => {
        dispatch({
          type: SET_INSTALLMENTS_INITIAL_DATA,
          payload: response.data.installment,
        });
        return response.data.installment;
      })
      .catch((err) => {
        logger.error('error on installments toggle: ', err);
        throw err;
      })
      .finally(() => {
        dispatch(createInstallmentsSpinnerAction(false));
      });
  };
}

export function saveNotificationEmail(payload) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.editPaymentEmail}`;
    const request = axios.create(options);

    dispatch(createPaymentSpinnerAction(true));

    return request
      .post(url, payload)
      .then((response) => {
        dispatch({
          type: SET_PAYMENT_INITIAL_DATA,
          payload: response.data.notification.email,
        });
        return response.data.installment;
      })
      .catch((err) => {
        logger.error('error saving notification email: ', err);
        throw err;
      })
      .finally(() => {
        dispatch(createPaymentSpinnerAction(false));
      });
  };
}

export function togglePaymentNotifications() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.togglePaymentEmail}`;
    const request = axios.create(options);

    dispatch(createPaymentSpinnerAction(true));

    return request
      .post(url, {})
      .then((response) => {
        dispatch({
          type: SET_PAYMENT_INITIAL_DATA,
          payload: response.data.notification.email,
        });
        return response.data.notification.email;
      })
      .catch((err) => {
        logger.error('error on payment notifications toggle: ', err);
        throw err;
      })
      .finally(() => {
        dispatch(createPaymentSpinnerAction(false));
      });
  };
}

export function fetchMerchantConfig() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrlv2}${apiPaths.paymentRequestTips}`;

    const apiResponse = axios.get(url, {
      params: {
        config_type: 'tip',
      },
      headers: {
        ...options.headers,
      },
    });
    return apiResponse
      .then((response) => {
        const { enabled, rounded } = response.data.data.tip;
        let componentState = enabled ? 'enabled' : 'disabled';
        let payload = {
          tipsEnabled: enabled,
          tipFromService: rounded,
          tipsComponentState: componentState,
        };
        dispatch({
          type: FETCH_TIPS_CONFIG,
          payload: payload,
        });
      })
      .catch((err) => {
        logger.error('error on postback notifications toggle: ', err);
        return Promise.reject(err);
      })
      .finally(() => {
        dispatch(createPostbackSpinnerAction(false));
      });
  };
}

export function setTipsComponentState(compState) {
  return (dispatch) => {
    let payload = {
      componentState: compState,
    };
    dispatch({
      type: SET_TIPS_STATE,
      payload: payload,
    });
  };
}
export function updateTipsConfig(isEnabled, isRounded) {
  return (dispatch) => {
    const config = getRequestOptions();
    const url = `${app.apiUrlv2}${apiPaths.paymentRequestTips}`;
    dispatch(createPostbackSpinnerAction(true));

    let data = {
      enabled: isEnabled,
      rounded: isRounded,
    };
    return axios
      .patch(
        url,
        { config: data },
        { params: { config_type: 'tip' }, headers: { ...config.headers } },
      )
      .then((response) => {
        const { updated } = response.data;
        let payload = {
          tipsEnabled: isEnabled,
          tipFromService: isRounded,
        };
        dispatch({
          type: SET_ENABLED_TIP,
          payload: payload,
        });
        if (updated) return response.data;
      })
      .catch((err) => {
        logger.error('error on tips toggle: ', err);
        return Promise.reject(err);
      })
      .finally(() => {
        dispatch(createPostbackSpinnerAction(false));
      });
  };
}

export function toogleTipsEnabled(isEnabled, componentState) {
  return (dispatch) => {
    let payload = {
      tipsEnabled: isEnabled,
      tipsComponentState: componentState,
    };
    dispatch({ type: HANDLE_REDUX_TIPS_STATE, payload });
  };
}

export function toogleTipsRounded(isRounded) {
  return (dispatch) => {
    let payload = {
      tipsOptionSelected: isRounded === 'rounded',
    };
    dispatch({ type: HANDLE_TIPS_ROUNDED_STATE, payload });
  };
}

export function onCancel(state) {
  return (dispatch) => {
    let payload = {
      tipsComponentState: state,
    };
    dispatch({ type: CANCEL_TIPS_EDITION, payload });
  };
}

export function canManageTips(doManage) {
  return (dispatch) => {
    let payload = {
      canManageTips: doManage,
    };
    dispatch({ type: CAN_MANAGE_TIPS, payload });
  };
}
