import React from 'react';
import text from './text';
import useStyles from './styles';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import { ClipTypography } from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import ClevertapComponent from '../../../../../helpers/Clevertap/ClevertapComponent';
import { EVENTS } from '../../../../../helpers/Clevertap';
import urls from 'helpers/urls';

const HelpNeedBlue = () => {
  const classes = useStyles();

  return (
    <>
    <div>
    <Grid item xs={12} sm={12} md={12} lg={12}
    >
    <ClipTypography
            className={classes.fontW500}
            color="textSecondary"
            variant="body2"
            gutterBottom
          >
            {text.helpNeed}
          </ClipTypography>
    </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.cardgris}>
          <ClipTypography className={classes.textCardGris}
            color="textSecondary"
            variant="body2"
            fontWeight="regular"
            gutterBottom
          >
            {text.customerSupport}&nbsp;
            <ClevertapComponent
                renderComponent={<a />}
                events={[
                  {
                    callbackName: 'onClick',
                  },
                ]}
              >
                {text.contactMobile}
           </ClevertapComponent>
            .&nbsp;
            {text.contactEmail}&nbsp;
            <ClevertapComponent
                renderComponent={<a />}
                events={[
                  {
                    callbackName: 'onClick',
                    event: EVENTS.LOANS.MAILCUSTOMER,
                  },
                ]}
                href={`mailto:${urls.clipHelpEmail}`}
                target={'_blank'}
              >
                <u>{urls.clipHelpEmail}</u>
           </ClevertapComponent>
            &nbsp;
            {text.contactChat}&nbsp;
            <ClevertapComponent
                renderComponent={<a />}
                events={[
                  {
                    callbackName: 'onClick',
                  },
                ]}
                href={urls.clipHomePage}
                target={'_blank'}
              >
            <u>{text.contactClipMx}</u> 
           </ClevertapComponent>

          </ClipTypography>      
        </Grid>
      </Grid>
    </div>
    </>
  );
};

export default HelpNeedBlue;
