import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const DetailSkeleton = ({ classes }) => {
  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.headerBlock}></div>
        <div className={classes.headerBlock}></div>
        <div className={classes.subheaderSection}>
          <div className={classes.subheaderBlock}></div>
          <div className={classes.subheaderBlock}></div>
        </div>
      </div>
      <div className={classes.bodySection}>
        <div className={classes.bodySubheaderBlock}></div>
        <div className={classes.bodyBlock}></div>
        <div className={classes.bodySubheaderBlock}></div>
        <div className={classes.bodyBlock}></div>
        <div className={classes.bodySubheaderBlock}></div>
        <div className={classes.bodyBlock}></div>
        <div className={classes.bodySubheaderBlock}></div>
        <div className={classes.bodyBlock}></div>
        <div className={classes.bodySubheaderBlock}></div>
        <div className={classes.bodyBlock}></div>
      </div>
    </>
  );
};

export default withStyles(styles)(DetailSkeleton);
