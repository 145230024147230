import React from 'react';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PrestaClipBanner from '../../../../assets/images/prestaclip.svg';
import ClipPlus2 from '../../../../assets/images/loans/clip-plus-2.svg';
import Doubting from '../../../../assets/images/loans/doubting.svg';
import ReaderInHand from '../../../../assets/images/loans/reader_in_hand.svg';
import {
  LoansTabs,
  PreapprovalStatus,
} from '../../../../constants/AppConstants';
import GoogleAnalytics from '../../GoogleAnalytics';
import { getPreapprovals } from '../../redux/selectors';
import { ExternalLenderFooter } from '../Footer';
import LoansRedirection from '../Redirection';
import { styles } from './styles';
import text from './text';

export const CanceledPage = ({ classes, preapprovals }) => (
  <LoansRedirection tab={LoansTabs.NEW_OFFER}>
    {preapprovals.filter(
      ({ status, loan_id }) =>
        [PreapprovalStatus.CANCELLED, PreapprovalStatus.CANCELED].includes(
          status,
        ) && !loan_id,
    ).length > 0 && (
      <GoogleAnalytics>
        <Grid item sm={12} xs={12} className={classes.gridCard}>
          <section className={classes.bannerContainer}>
            <img
              className={classes.bannerTop}
              alt="PrestaClip"
              src={PrestaClipBanner}
            />
          </section>
          <section className={classes.bannerContainer}>
            <img
              className={classes.bannerImage}
              alt="Canceled"
              src={Doubting}
            />
          </section>
          <section className={classes.title}>{text.title}</section>
          <section className={classes.text}>{text.description}</section>
          <section className={classes.subtitle}>{text.howTo}</section>
          <section className={classes.points}>
            <article className={classes.point}>
              <span className="icon">
                <img alt="Point One" src={ReaderInHand} />
              </span>
              <span>{text.pointOne}</span>
            </article>
            <article className={classes.point}>
              <span className="icon">
                <img alt="Point Two" src={ClipPlus2} />
              </span>
              <span>{text.pointTwo}</span>
            </article>
          </section>
          <section className={classes.text}>{text.adviceHowTo}</section>
          <ExternalLenderFooter />
        </Grid>
      </GoogleAnalytics>
    )}
  </LoansRedirection>
);

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(CanceledPage));
