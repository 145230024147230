import {
  CLEAR_PROMO_STATE,
  SET_EXISTING_PROMO,
  SET_PROMO_NAME,
  PROMO_CODE_ATTEMPT,
  REDEEM_CODE_SUCCESS,
  REDEEM_CODE_FAIL,
  EXISTING_PROMO_FAIL,
} from './types';

const initialState = {
  name: '',
  code: '',
  attempts: 0,
  existingPromo: false,
  isValid: false,
  error: '',
  success: false,
};

const actionHandlers = new Map([
  [CLEAR_PROMO_STATE, handleClearPromoState],
  [SET_EXISTING_PROMO, handleSetExistingPromo],
  [SET_PROMO_NAME, handleSetPromoName],
  [PROMO_CODE_ATTEMPT, handlePromoCodeAttempt],
  [REDEEM_CODE_SUCCESS, handleRedeemSuccess],
  [REDEEM_CODE_FAIL, handleRedeemFail],
  [EXISTING_PROMO_FAIL, handleExistingPromoFail],
]);

function handleClearPromoState(state, action) {
  return {
    ...state,
  };
}

function handleSetExistingPromo(state, action) {
  const data = action.payload;

  return data
    ? {
        ...state,
        existingPromo: true,
        name: data.internal_promo_code,
        code: data.promo_code,
      }
    : state;
}

function handleSetPromoName(state, action) {
  const data = action.payload;
  let { name, code, attempts } = state;

  if (data !== name) {
    name = data;
    code = '';
    attempts = 0;
  }

  return { ...state, name, code, attempts };
}

function handlePromoCodeAttempt(state, action) {
  const data = action.payload;
  const attempts = state.attempts + 1;

  return data
    ? {
        ...state,
        attempts,
        code: data.merchant_promo_code,
        isValid: data.is_valid,
      }
    : state;
}

function handleRedeemSuccess(state, action) {
  const data = action.payload;

  return {
    ...state,
    success: true,
    error: '',
    code: data.promoCode,
    name: data.name,
  };
}

function handleRedeemFail(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function handleExistingPromoFail(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

export default function PromoReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}
