import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@clipmx/clip-storybook/dist/components/Dialog';
import Sams from '../index';
import SamsConfirmed from '../Confirmed';
import styles from './styles';

export const SamsDialog = ({
  classes: { container },
  open,
  onClose,
  onSuccess,
  lender,
  proxyMerchantToken,
}) => {
  const [confirm, setConfirm] = useState(false);
  const [membership, setMembership] = useState('');
  return (
    <Dialog open={open} className={container} title="" type="simple">
      {!confirm && (
        <Sams
          onClose={onClose}
          onSubmit={() => setConfirm(true)}
          onContinue={onSuccess}
          onChange={(value) => setMembership(value)}
          membership={membership}
          lender={lender}
        />
      )}
      {confirm && (
        <SamsConfirmed
          onClose={onClose}
          onSuccess={onSuccess}
          membership={membership}
          proxyMerchantToken={proxyMerchantToken}
          lender={lender}
        />
      )}
    </Dialog>
  );
};

export default withStyles(styles)(SamsDialog);
