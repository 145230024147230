export default {
  inputCurp: 'Ingresa tu CURP',
  info: 'Esto nos ayudará a verificar tu identidad.',
  continue: 'Continuar',
  skip: 'Omitir',
  curp: 'CURP',
  help: 'Ayuda',
  verify: 'Verifica tu CURP',
  verifyInfo: 'Puedes encontrarlo en tu identificación oficial.',
  error: 'Algo salió mal, vuelve a intentar mas tarde.',
  invalidError: 'CURP no válida. Inténtalo de nuevo.',
};
