import React from 'react';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import * as Styled from './styled';
import BerlinService from '../../service';
import { withRouter } from 'react-router-dom';
import { setAccountRep } from '../../redux/berlinActions';
import { ACCOUNT, ROUTES } from '../../constants';
import PropTypes from 'prop-types';
import text from './text';

export class CreatingAccount extends React.PureComponent {
  static propTypes = {
    merchant: PropTypes.object,
    ldFlags: PropTypes.object,
  };

  static defaultProps = {
    merchant: {},
    ldFlags: {},
  };

  componentDidMount() {
    this.poll = setInterval(() => {
      const {
        merchant,
        setActiveTab,
        registrationStarted,
        setRegistrationStatus,
      } = this.props;
      if (registrationStarted) {
        BerlinService.accountInformationV2(merchant.info.id).then((response) => {
          if (response.data.message.account_status === ACCOUNT.STATUS.CREATED) {
            this.props.dispatch(setAccountRep(response.data.message));
            clearInterval(this.poll);
            this.props.history.push(ROUTES.ACTIVATION);
            store.addNotification({
              title: 'Cartera Clip creada',
              message: 'Activa tu tarjeta y ¡listo!',
              type: 'success',
              insert: 'top',
              container: 'top-center',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          } else if (response.data.message.account_status === ACCOUNT.STATUS.REJECTED) {
            const { card_status_description = 'UNKNOWN' } = response.data.message.card_representation[0];
            this.props.dispatch(setAccountRep(response.data.message));
            clearInterval(this.poll);
            setRegistrationStatus(false);
            store.addNotification({
              title: text.rejected[card_status_description].title,
              message: text.rejected[card_status_description].message,
              type: 'danger',
              insert: 'top',
              container: 'top-center',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                showIcon: true,
                duration: 0,
              },
            });
            setActiveTab(3);
          }
        })
      }
    }, 6000);
  }

  componentWillUnmount() {
    clearInterval(this.poll);
  }

  render() {
    const { ldFlags } = this.props;
    return (
      <Styled.CreatingAccountContainer>
        <Styled.LdsRing>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </Styled.LdsRing>
        <Styled.VerifyingData>{text.verifyingDocuments}</Styled.VerifyingData>
        <Styled.CheckingData>{text.wereChecking}</Styled.CheckingData>
        <Styled.Thanks>{text.thanks}</Styled.Thanks>
      </Styled.CreatingAccountContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    ldFlags: state.berlin.ldFlags,
  };
};

export default withRouter(connect(mapStateToProps)(CreatingAccount));
