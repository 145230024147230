import {
  CLEAR_USER,
  DENY_PERMISSION,
  FETCH_IDENTITY_VERIFICATION,
  FETCH_USER,
  RESET_PERMISSION,
  SET_USER_TOKEN,
  SET_DAYS_UNTIL_EXP,
  UPDATE_PASSWORD_FAIL,
  RESET_PASSWORD_FAIL,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_USER,
  USER_ERROR,
} from '../actions/Types';

import { saveCookie } from '../helpers/tokenHelpers';
import { successResponse } from '../middleware/apiResolve/reduxUtils';
import { SET_USER_NONCE, USER_NONCE } from '../components/auth/redux/types';
import { getToken } from 'helpers/tokenHelpers'

const daysUntilExp = getToken('days_until_exp') ? JSON.parse(getToken('days_until_exp')) : null

const initialState = {
  id: '',
  access_token: null,
  days_until_exp: daysUntilExp !== null ? daysUntilExp : null,
  email: '',
  first_name: '',
  last_name: '',
  second_last_name: '',
  date_of_birth: null,
  mobile: '',
  role: '',
  preferred_country: '',
  permission_denied: false,
  error: false,
  infoUpdated: false,
  infoUpdateAttempts: 0,
  passwordReset: false,
  passwordResetError: {
    error: false,
    code: '',
  },
  passwordResetAttempts: 0,
  verified: false,
  nonce: null,
};

const actionHandlers = new Map([
  [successResponse('post', USER_NONCE), handleFetchUserNonce],
  [SET_USER_NONCE, handleSetUserNonce],
  [FETCH_USER, handleFetchUser],
  [UPDATE_USER, handleUpdateUser],
  [USER_ERROR, handleUserError],
  [DENY_PERMISSION, handleDenyPermission],
  [RESET_PERMISSION, handleResetPermission],
  [CLEAR_USER, handleClearUser],
  [SET_USER_TOKEN, handleSetUserToken],
  [SET_DAYS_UNTIL_EXP, handleSetDaysUntilExp],
  [UPDATE_PASSWORD_SUCCESS, handleUpdatePasswordSuccess],
  [UPDATE_PASSWORD_FAIL, handleUpdatePasswordFail],
  [RESET_PASSWORD_FAIL, handleResetPasswordFail],
  [FETCH_IDENTITY_VERIFICATION, handleIdentityVerification],
]);

function handleSetDaysUntilExp(state, action) {
  return { ...state, days_until_exp: action.payload };
}

function handleFetchUserNonce(state, action) {
  const nonce = action.payload?.data.item.nonce;
  saveCookie('nonce', nonce);
  return { ...state, nonce };
}

function handleSetUserNonce(state, action) {
  const {
    data: { nonce },
  } = action.payload;
  saveCookie('nonce', nonce);
  return { ...state, nonce };
}

export default function UserReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleFetchUser(state, action) {
  const { data } = action.payload;
  if (data) data.fullName = `${data?.first_name} ${data?.last_name}`;
  return data ? { ...state, ...data } : state;
}

function handleUpdateUser(state, action) {
  const data = action.payload;

  return data
    ? {
        ...state,
        ...data,
        infoUpdated: true,
        infoUpdateAttempts: state.infoUpdateAttempts + 1,
      }
    : state;
}

function handleUserError(state, action) {
  return {
    ...state,
    error: action.payload,
    infoUpdated: false,
    infoUpdateAttempts: state.infoUpdateAttempts + 1,
  };
}

function handleDenyPermission(state) {
  return { ...state, permission_denied: true };
}

function handleResetPermission(state) {
  return { ...state, permission_denied: false };
}

function handleClearUser() {
  saveCookie('nonce', null);
  return initialState;
}

function handleSetUserToken(state, action) {
  return { ...state, access_token: action.payload };
}

function handleUpdatePasswordSuccess(state) {
  return {
    ...state,
    passwordReset: true,
    passwordResetAttempts: state.passwordResetAttempts + 1,
  };
}

function handleUpdatePasswordFail(state, action) {
  const { payload: { data: { messages } } } = action
  return {
    ...state,
    passwordReset: false,
    passwordResetAttempts: state.passwordResetAttempts + 1,
    passwordResetError: {
      error: true,
      code: messages[0].code,
    },
  };
}

function handleResetPasswordFail(state) {
  return {
    ...state,
    passwordResetError: {
      error: false,
      code: '',
    },
  }
}

function handleIdentityVerification(state, action) {
  const { verified } = action.payload;

  return verified ? { ...state, verified } : state;
}

// selectors
export const getVerification = (state) => state.verified;

export const getAccessToken = (state) => state.access_token;

export const getIsLoggedIn = (state) => {
  const token = getAccessToken(state);

  return typeof token !== 'undefined' && token !== null;
};

export const getIsUnauthorized = (state) => state.permission_denied;
