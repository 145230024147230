export const styles = (theme) => {
  const rootStyles = {
    container: {
      height: '100vh',
      background: theme.palette.indigo.l92,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    paper: {
      width: '100%',
      fontSize: theme.spacing(3),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    drawer: {
      zIndex: 3001,
    },
    clear: {
      alignSelf: 'flex-end',
    },
    paperDrawer: {
      borderRadius: '16px 16px 0 0',
      padding: `${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'center',
    },
    lottieAnimation: {
      position: 'absolute',
    },
    alert: {
      height: '48px',
      zIndex: 3001,
      justifyContent: 'center',
    },
    firstCard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      borderBottom: `1px dashed ${theme.palette.indigo.l90}`,
      '&:after': {
        content: '""',
        width: '21px',
        height: '21px',
        left: -10.5,
        bottom: -10.5,
        borderRadius: '100%',
        backgroundColor: theme.palette.indigo.l92,
        position: 'absolute',
      },
      '&:before': {
        content: '""',
        width: '21px',
        height: '21px',
        right: -10.5,
        bottom: -10.5,
        borderRadius: '100%',
        backgroundColor: theme.palette.indigo.l92,
        position: 'absolute',
      },
    },
    icon: {
      backgroundColor: theme.palette.mustard.l20,
      color: 'white',
      padding: 3,
      borderRadius: 20,
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    iconError: {
      backgroundColor: theme.palette.sangria.l10,
      color: 'white',
      padding: 3,
      borderRadius: 20,
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    amount: {
      marginTop: theme.spacing(1.5),
    },
    date: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    dashedLine: {
      border: 0,
      borderTop: `1px dashed ${theme.palette.indigo.l90}`,
    },
    secondCard: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    info: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
    },
    dashedLineInfo: {
      border: 0,
      marginRight: 2,
      marginLeft: 2,
      flex: 'auto',
      borderTop: `2px dotted ${theme.palette.indigo.l50}`,
    },
    key: {
      display: 'flex',
      width: '60%',
    },
    footer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: theme.spacing(3),
    },
    money: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8,
      border: `1px solid ${theme.palette.celurean.l70}`,
      backgroundColor: theme.palette.celurean.l94,
      width: '100%',
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    moneyIcon: {
      width: theme.spacing(3),
      height: theme.spacing(4),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(4),
    },
    saveContact: {
      marginBottom: theme.spacing(1),
    },
    status: {
      marginTop: theme.spacing(2.5),
      textAlign: 'center',
    },
    exit: {
      marginTop: theme.spacing(2),
    },
    shareIcon: {
      marginBottom: theme.spacing(3),
    },
  };
  return { ...rootStyles };
};
