export const styles = (theme) => {
  const imageStyles = {
    banner: {
      display: 'block',
      margin: '0 auto',
      width: `${theme.spacing.unit * 42.875}px`,
      height: `${theme.spacing.unit * 18}px`,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  };
  const typographyStyles = {
    typographyRoot: {
      margin: ` 0 auto ${theme.spacing.unit * 2}px auto`,
      textAlign: 'center',
      color: '#001724',
    },
    typographyHeadline: {
      fontSize: `${theme.spacing.unit * 2.5}px`,
      fontWeight: 600,
    },
    typographyBody1: {
      margin: `${theme.spacing.unit * 2}px  auto 0 auto`,
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 400,
      lineHeight: `${theme.spacing.unit * 2.5}px`,
    },
    typographyBody2: {
      margin: `${theme.spacing.unit}px  auto 0 auto`,
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 400,
      lineHeight: `${theme.spacing.unit * 2.5}px`,
    },
  };
  const buttonContainerStyles = {
    buttonContainer: {
      margin: `${theme.spacing.unit * 3}px auto ${
        theme.spacing.unit * 5
      }px auto`,
      width: '30%',
      fontWeight: 600,
      fontSize: `${theme.spacing.unit * 2}px`,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  };

  return { ...imageStyles, ...typographyStyles, ...buttonContainerStyles };
};
