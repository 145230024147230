/* eslint-disable  max-len */
import moment from 'moment-timezone';
import getText from './text';

const REGULAR_EMAIL = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function hasNumber(value) {
  return /\d/.test(value) ? undefined : getText().hasNumber;
}

export const isEmpty = (value) => {
  return (
    value === '' ||
    value === null ||
    typeof value === 'undefined' ||
    value.length === 0
  );
};

export const getValidationText = () => {
  return getText();
};

export const required = (value, values, props) => {
  return value && `${value}`.trim() ? undefined : getText().isRequired;
};


export const requiredCP = (value, values, props) => {
  return value && `${value}`.trim() ? undefined : getText().isRequiredCP;
};

export const requiredStreet = (value) => {
  return value && `${value}`.trim() ? undefined : getText().isStreeetRequired;
};

export const requiredTextName = (value, values, props) => {
  return value && `${value}`.trim() ? undefined : getText().isNameRequired;
};

export const requiredTextRazonSocial = (value, values, props) => {
  return value && `${value}`.trim() ? undefined : getText().isSocialRazonRequired;
};

export const requiredTextRegime = (value, values, props) => {
  return value && `${value}`.trim() ? undefined : getText().idRegimeRequire;
};

export const requiredDropdown = (value, values, props) => {
  return value && `${value}`.trim() ? undefined : getText().isRequiredDropdown;
};

export const requiredRfc = (value) => {
  return value && `${value}`.trim() ? undefined : getText().isRequiredRfc;
};

export const isValidInvoiceRFC = (value) => {
  const rfcRegex =
    /^([ÑA-Z\d&]{4})(\d{2}(0[1-9]|10|11|12)(0[1-9]|1\d|2\d|30|31))([A-Z\d]{2})(A|\d)$/;
  const rfcParts = rfcRegex.exec(value);
  if (rfcParts) {
    const isValidBirthDate = validateBirthDate(rfcParts[2]);
    return isValidBirthDate ? undefined : getText().isRequiredRfc;
  }
  return getText().isRequiredRfc;
};

export const email = (value) => {
  /* eslint-disable-next-line max-len */
  return value && !REGULAR_EMAIL.test(value) ? getText().isEmail : undefined;
};

export const invalidEmailInvoice = (value) => {
  /* eslint-disable-next-line max-len */
  return value && !REGULAR_EMAIL.test(value) ? getText().isInvalidEmail : undefined;
};

export const emailPerson = (value) => {
  /* eslint-disable-next-line max-len */
  return value && !REGULAR_EMAIL.test(value) ? getText().isEmailPersson : undefined;
};

export const phone = (value) => {
  return value && (/\(\d{2}\)\d{4}-\d{4}/.test(value) || /\d{10}/.test(value))
    ? undefined
    : getText().isPhoneNumber;
};

export const clabe = (value) => {
  return value && value.length === 18 ? undefined : getText().isBankCode;
};

export const matchEmail = (value, values) => {
  return value === values.email ? undefined : getText().isEmailMatching;
};

export const password = (value) => {
  return value &&
    /^.*(?=.{8,128})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d]).*$/.test(value)
    ? undefined
    : getText().isPassword;
};

export const isValidPassword = (value) => {
  return /^.*(?=.{8,128})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d]).*$/.test(value);
};

export const passwordCharacters = (value) => {
  return /^.*(?=.{8,128}).*$/.test(value);
};

export const passwordUpperCase = (value) => {
  return /^.*(?=.*[A-Z]).*$/.test(value);
};

export const passwordLowerCase = (value) => {
  return /^.*(?=.*[a-z]).*$/.test(value);
};

export const passwordNumber = (value) => {
  return /^.*(?=.*[\d]).*$/.test(value);
};

export const matchPassword = (value, values) => {
  return value === values.password ? undefined : getText().isPasswordMatching;
};

export const matchNewPassword = (value, values) => {
  return value === values.newPassword
    ? undefined
    : getText().isPasswordMatching;
};

export const noMatchCurrentPassword = (value, values) => {
  return value !== values.currentPassword
    ? undefined
    : getText().isNotCurrentPassword;
};

export const aboveMinimumThreshold = (value) => {
  const minimumThreshold = 500;
  const numericValue = parseFloat(value);
  return isNaN(numericValue) ||
    !isFinite(numericValue) ||
    numericValue < minimumThreshold
    ? getText().isInstallmentThreshold
    : undefined;
};

export const moneyValue = (value) => {
  return !/^\d+(?:\.\d{0,2})?$/.test(parseFloat(value))
    ? getText().isNumeric
    : undefined;
};

export const postalCode = (value, values, props) => {
  return isEmpty(value) || value.length !== 5
    ? getText().isPostalCode
    : undefined;
};

export const noZeroAtStart = (value) => {
  return value[0] !== 0 ? undefined : getText().startWithZero;
};

export const min3 = (value) => {
  return value.length >= 3 ? undefined : getText().isNameTooShort;
};

export const atLeast3Characters = (value) => {
  return !value || value.length >= 3 ? undefined : getText().isNameTooShort;
};

export const fourNumbers = (value) => {
  return value.length === 4 ? undefined : getText().shortNumber(4);
};

export const name = (value) => {
  return /^[a-zA-Z0-9üÜñÑ\s]+$/.test(value) || value === ''
    ? undefined
    : getText().isValidName;
};

export const text = (value) => {
  return /^[a-zA-ZüÜñÑáÁéÉíÍóÓúÚ\s]+$/.test(value) || value === ''
    ? undefined
    : getText().isValidText;
};

export const withNoHTML = (value) => {
  return !/((\%3C)|<)((\%2F)|\/)*[a-z0-9\%]+((\%3E)|>)/i.test(value)
    ? undefined
    : getText().withNoHTML;
};

export const withNoSQLInjection = (value) => {
  return /^(?=.*[A-Z0-9])[\w.,!"'\/$ ]+$/i.test(value)
    ? undefined
    : getText().withNoHTML;
};

export function minLength(length) {
  return (value) => {
    return value && value?.length < length
      ? getText().minLength(length)
      : undefined;
  };
}

export const isPromo = (value) => {
  return value === '' || value.length === 5 ? undefined : getText().isPromoCode;
};

export const isURL = (value) =>
  typeof value === 'undefined' ||
  value === null ||
  value === '' ||
  /^https?:\/\/\S+/.test(value)
    ? undefined
    : getText().isUrl;

export const isIndustry = (value) => {
  return value && value !== getText().businessType && value !== '0'
    ? undefined
    : getText().isRequired;
};

export const isNumeric = (value, values, props) => {
  return /^\S+$/.test(value) && !isNaN(value) && isFinite(value)
    ? undefined
    : getText().isNumeric;
};

export const isInteger = (value, values, props) => {
  return /^\S+$/.test(value) && /[0-9]+$/.test(value) && /^[^.]+$/.test(value)
    ? undefined
    : getText().isNumeric;
};

export const isInstallmentThreshold = (value, values, props) => {
  return value >= 500 ? undefined : getText().isInstallmentThreshold;
};

export const isReferralCode = (value) => {
  return !value || value.length === 7 ? undefined : getText().isReferralCode;
};

const validateBirthDate = (date) => {
  const parseTwoDigitYearOriginal = moment.parseTwoDigitYear;
  moment.parseTwoDigitYear = function (yearString) {
    const yearInt = parseInt(yearString);
    return yearInt + (yearInt >= 30 ? 1900 : 2000);
  };
  const isValidBirthDate = moment(date, 'YYMMDD', true).isValid();
  moment.parseTwoDigitYear = parseTwoDigitYearOriginal;
  return isValidBirthDate;
};

export const isValidPhysicalRFC = (value) => {
  const rfcRegex =
    /^([ÑA-Z\d&]{4})(\d{2}(0[1-9]|10|11|12)(0[1-9]|1\d|2\d|30|31))([A-Z\d]{2})(A|\d)$/;
  const rfcParts = rfcRegex.exec(value);
  if (rfcParts) {
    const isValidBirthDate = validateBirthDate(rfcParts[2]);
    return isValidBirthDate ? undefined : getText().isValidRFC;
  }
  return getText().isValidRFC;
};

export const isValidMoralRFC = (value) => {
  const rfcRegex =
    /^([ÑA-Z\d&]{3})(\d{2}(0[1-9]|10|11|12)(0[1-9]|1\d|2\d|30|31))([A-Z\d]{2})(A|\d)$/;
  const rfcParts = rfcRegex.exec(value);
  if (rfcParts) {
    const isValidBirthDate = validateBirthDate(rfcParts[2]);
    return isValidBirthDate ? undefined : getText().isValidRFC;
  }
  return getText().isValidRFC;
};

export const isValidRFC = (txt) => {
  const physicalRFCRegex =
    /^([ña-zA-ZÑ&]{4})(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))([a-zA-Z\d]{2})([aA\d])$/;
  const moralRFCRegex =
    /^([ña-zA-ZÑ&]{3})(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))([a-zA-Z\d]{2})([aA\d])$/;

  const physicalRFCParts = physicalRFCRegex.exec(txt);
  const moralRFCParts = moralRFCRegex.exec(txt);

  if (moralRFCParts) {
    return getText().isValidPhysicalRFC;
  } else if (physicalRFCParts) {
    const isValidBirthDate = validateBirthDate(physicalRFCParts[2]);
    return isValidBirthDate ? undefined : getText().isValidRFC;
  }
  return getText().isValidRFC;
};

export const isValidCURP = (txt) => {
  const rfcRegex = new RegExp(
    [
      '^([a-zA-Z][aeiouxAEIOUX][a-zA-Z]{2})(\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01]))([hmHM])',
      '((?:a[sS]|A[sS]|b[csCS]|B[csCS]|c[clmshCLMSH]|C[clmshCLMSH]|d[fgFG]|D[fgFG]|g[trTR]|G[trTR]|h[gG]|H[gG]|',
      'j[cC]|J[cC]|m[cnsCNS]|M[cnsCNS]|n[etlETL]|N[etlETL]|o[cC]|O[cC]|p[lL]|P[lL]|q[trTR]|Q[trTR]|s[plrPLR]|',
      'S[plrPLR]|t[cslCSL]|T[cslCSL]|v[zZ]|V[zZ]|y[nN]|Y[nN]|z[sS]|Z[sS]))([bB-dDfF-hHjJ-nNpP-tTvV-zZ]{3})',
      '([a-zA-Z\\d])(\\d)$',
    ].join(''),
  );
  const rfcParts = rfcRegex.exec(txt);
  if (rfcParts) {
    const isValidBirthDate = validateBirthDate(rfcParts[2]);
    return isValidBirthDate ? undefined : getText().isValidCURP;
  }
  return getText().isValidCURP;
};

export const maxPrice = (value) => {
  return value > 99999.99 ? getText().maxPrice : undefined;
};

export const minPrice = (min) => (value) => {
  return value >= min ? undefined : getText().minPrice(min);
};

export const maxStock = (value) => {
  return value > 999 ? getText().maxStock(999) : undefined;
};

export const minStock = (value) => {
  return value > -999 ? undefined : getText().minStock(-999);
};

export const isValidEmail = (value) => {
  /* eslint-disable-next-line max-len */
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

export const optionalNumeric = (value) => {
  if (!value) {
    return undefined;
  }
  const validNumeric =
    !isNaN(parseFloat(value)) &&
    isFinite(value) &&
    value > 0 &&
    value < 99999.99;
  return validNumeric ? undefined : getText().unitPrice;
};

export const termsAndConditions = (value) => {
  return value ? undefined : getText().termsAndConditions;
};

export const isAlphanumeric = (txt) => {
  return isEmpty(txt) || isEmpty(txt.match(/^[0-9a-zA-ZñÑ\s]+$/))
    ? getText().isValidName
    : undefined;
};

export const isAlphanumericWithAccents = (txt) => {
  return isEmpty(txt) || isEmpty(txt.match(/^[0-9a-zA-ZñÑüÜáÁéÉíÍóÓúÚ\s]+$/))
    ? getText().isValidName
    : undefined;
};

export const notRequiredNumber = (txt) => {
  return !isEmpty(txt) ? isNumeric(txt) : undefined;
};

export const isAdult = (year, month, day) => {
  const age =
    new Date(
      new Date() - new Date(year + '-' + month + '-' + day),
    ).getFullYear() - 1970;
  return age < 18 ? getText().isNotAdult : undefined;
};

export const isAdultForValues = (year, values) =>
  isAdult(year, values.month, values.day);

export const isValidDay = (strDay, month, year) => {
  const daysInMont = new Date(year, month, 0).getDate();
  const day = parseInt(strDay);
  if (day > daysInMont || day > 31 || day < 1) {
    return getText().isInvalidDay;
  }
};

export const isValidDayForValues = (day, values) =>
  isValidDay(day, values.month, values.year);

export const isAdultDateField = (txt) =>
  moment().diff(txt, 'years') < 18 ? getText().isNotAdult : undefined;

export const maxLimitGeneralXpay = (value) => {
  return value > 3000 ? getText().maxPricePilotXpay : undefined;
};

export const maxPriceLimit = (value) => {
  return value > 5000 ? getText().maxPriceLimit : undefined;
};

export const maxLimitBuenFinXpay = (value) => {
  return value > 15000 ? getText().maxpriceBuenFinXpay : undefined;
};

export const maxLimitgeneralChristmas = (value) => {
  return value > 10000 ? getText().generalPriceChristmas : undefined;
};

export const maxLimitChristmas = (value) => {
  return value > 30000 ? getText().maxPriceChristmas : undefined;
};

export const maxLength = (length) => (value) =>
  value && value.length === length ? getText().maxLength : undefined;

export const haveLength = (length) => (value) =>
  value && (value.length < length || value.length > length)
    ? getText().lengthRequired(length)
    : undefined;

export const isValidBirthdayYear = (value) => {
  const actualYear = moment().year();
  return parseInt(value) > actualYear || actualYear - parseInt(value) > 100
    ? getText().isValidBirthdayYear
    : undefined;
};

export const isValidBirthdayMonth = (value) => {
  return parseInt(value) > 12 || parseInt(value) <= 0
    ? getText().isValidBirthdayMonth
    : undefined;
};

export const minValue = (min) => (value) => {
  return value && value < min ? getText().minNumber(min) : undefined;
};
export const maxValue = (max) => (value) => {
  return value && value > max ? getText().maxNumber(max) : undefined;
};

export const isValidCard = (value) => {
  if (value.length !== 16) return getText().validCard;
  return !isNaN(parseFloat(value)) &&
    isFinite(value) &&
    !value.split('').every((char) => char === value[0])
    ? undefined
    : getText().validCard;
};

export const isValidMembership = (value) => {
  return isEmpty(value) ||
    value.length > 17 ||
    !/[\d]{17}/.test(value) ||
    membershipHasSameDigits(value)
    ? getText().isInvalidSamsMembership
    : undefined;
};

const membershipHasSameDigits = (value) => {
  if (value.length === 17) {
    const last = value.substr(value.length - 1);
    const rest = value.substr(0, value.length - 1);
    const preLast = rest.substr(rest.length - 1);
    return last === preLast && hasSameDigits(rest);
  }
  return hasSameDigits(value);
};

const hasSameDigits = (value) => {
  if (value < 10) return true;
  const first = value % 10;
  const rest = Math.floor(value / 10);
  return rest % 10 === first && hasSameDigits(rest);
};

export const optionalNumber = (value) => {
  if (!value) {
    return undefined;
  }
  const validNumeric = !isNaN(parseFloat(value)) && isFinite(value);
  return validNumeric ? undefined : getText().isNumber;
};

export const numericalExpression = (value) => {
  if(!value)
  {   
    return undefined;
  }

  const expresionNumber = (/^\d+(\.\d{1,9})?$/).test(value);
  return expresionNumber? undefined : getText().expNumber;
}

export const unrepeatedPhones = (value, values) => {

  if(!value)
  {
    return undefined;
  } else if(!values.extraTelephones) {
    return undefined;
  }

  return !(values.extraTelephones.includes(value)) ? undefined : getText().repeatedPhone;
}

export const addressYearValidation = (value) => {
  return (value > 99) ? getText().invalidYear : undefined;
 }; 

 export const addressMonthValidation = (value) => {
   return (value > 11) ? getText().invalidMonth : undefined;
};

export const optionalImage = (value) => {
  const validFiletypes = ['image/jpeg', 'image/png'];
  if (!value) return undefined;
  const { file } = value;
  if (!file) return undefined;
  if (file.size > 2000000) return 'debe de ser menor de 2MB';
  if (!validFiletypes.includes(file.type)) return 'debe de ser Jpg o Png';
  return undefined;
};

export const passwordHasRepeatedCharacter = (value) => {
  return !/^(?:(.)(?!\1{2}))+$/.test(value.toLowerCase()) ? getText().hasRepeatedCharacter : undefined;
}

export const passwordHasConsecutiveAscendingCharacters = (value) => {
  if (value.length > 2) {
    value.toLowerCase()
    for (let i = 0; i < value.length - 2; i++) {
      if (
        value.charCodeAt(i + 2) - value.charCodeAt(i + 1) === 1 &&
        value.charCodeAt(i + 1) - value.charCodeAt(i) === 1
      ) {
        return getText().hasConsecutiveAscendingCharacters
      }
    }
  }
  return undefined;
}

export const passwordHasConsecutiveDescendingCharacters = (value) => {
  if (value.length > 2) {
    value.toLowerCase()
    for (let i = 0; i < value.length - 2; i++) {
      if (
        value.charCodeAt(i) - value.charCodeAt(i + 1) === 1 &&
        value.charCodeAt(i + 1) - value.charCodeAt(i + 2) === 1
      ) {
        return getText().hasConsecutiveDescendingCharacters
      }
    }
  }
  return undefined;
}

export const passwordHasSwapIdentifier = (value) => {
  return !/^((?!swap).)*$/.test(value.toLowerCase()) ? getText().hasSwapIdentifier : undefined
}

export const passwordHasPocketGroupIdentifier = (value) => {
  return !/^((?!pocketgroup).)*$/.test(value.toLowerCase()) ? getText().hasPocketGroupIdentifier : undefined
}

export const swapPasswordValidations = (value) => {
  let validators = [
    passwordHasRepeatedCharacter, 
    passwordHasConsecutiveAscendingCharacters,
    passwordHasConsecutiveDescendingCharacters,
    passwordHasSwapIdentifier, 
    passwordHasPocketGroupIdentifier
  ]
  let errorMessage
  let currentValidator = 0

  while(currentValidator < validators.length && !errorMessage) {
    errorMessage = validators[currentValidator](value)
    currentValidator++
  }

  return errorMessage
}