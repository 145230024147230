export const ACCOUNT = {
  STATUS: {
    REJECTED: 'REJECTED',
    CREATED: 'CREATED',
    ACTIVATED: 'ACTIVATED',
    CAPTURED: 'CAPTURED',
    INACTIVATED: 'INACTIVATED',
  },
};

export const ROUTES = {
  HOME: '/mobile/berlin',
  REGISTRATION: '/mobile/berlin/registration',
  ACTIVATION: '/mobile/berlin/activation',
  BALANCE: '/mobile/berlin/balance',
  ACCOUNT: '/mobile/berlin/account',
  UPGRADE: '/mobile/berlin/account/upgrade',
  BERLINSWAP: '/mobile/berlin/account/swap/upgrade',
  CLIPCARD: '/mobile/berlin/clipcard',
};

export const ROUTES2 = {
  HOME: '/mobile/berlin',
  REGISTRATION: '/mobile/berlin/registration2',
  ACTIVATION: '/mobile/berlin/activation2',
  WAITINGLIST: '/mobile/berlin/waitingList',
  DEACTIVATED: '/mobile/berlin/deactivated',
  BALANCE: '/mobile/berlin/balance',
  CDA: '/mobile/berlin/cda',
  REQUESTCARD: '/mobile/berlin/card-request',
  CARDACTIVATION: '/mobile/berlin/activation',
  AUTH: '/mobile/berlin/auth',
};
