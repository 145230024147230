import axios from 'axios';

import { app } from '../config/config';
import { getRequestOptions } from '../helpers/fetchUtil';
import apiPaths from '../helpers/apiPaths';
import logger from '../helpers/logger';
import {
  FETCHING_DASHBOARD_STATE,
  FETCHING_DASHBOARD,
  SET_DASHBOARD_DATA,
  SET_DASHBOARD_STATE,
} from './Types';

function formatDashboardData(data) {
  return {
    recent: {
      amount: data.recent.amount,
      date: data.recent.date,
      transaction: data.recent.transaction,
      transactionCount: data.recent.transaction_count,
      topUp: data.recent.topup,
    },
    tabs: {
      bankAccount: data.tabs.bank_account,
      businessAddress: data.tabs.business_address,
    },
    merchantName: data.merchant_name,
  };
}

export function getDashboardData() {
  return (dispatch) => {
    dispatch({ type: FETCHING_DASHBOARD, payload: { loading: true } });
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.dashboardPage}`;

    axios
      .get(url, options)
      .then((response) => {
        const dashboardData = formatDashboardData(response.data);
        dispatch({ type: SET_DASHBOARD_DATA, payload: dashboardData });
        dispatch({ type: FETCHING_DASHBOARD, payload: { loading: false } });
      })
      .catch((err) => {
        logger.error('err getting user dashboard data: ', err.response);
        dispatch({ type: FETCHING_DASHBOARD, payload: { loading: false } });
      });
  };
}

export function getDashboardState() {
  return (dispatch) => {
    dispatch({ type: FETCHING_DASHBOARD_STATE, payload: { loading: true } });
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.dashboardState}`;

    axios
      .get(url, options)
      .then((response) => {
        const { current_state } = response.data;

        dispatch({
          type: FETCHING_DASHBOARD_STATE,
          payload: { loading: false },
        });
        dispatch({ type: SET_DASHBOARD_STATE, payload: current_state });
      })
      .catch((err) => {
        dispatch({
          type: FETCHING_DASHBOARD_STATE,
          payload: { loading: false },
        });
        logger.error('err getting user dashboard state: ', err.response);
      });
  };
}

export function setDashboardState(state) {
  return (dispatch) => {
    dispatch({ type: SET_DASHBOARD_STATE, payload: state });
  };
}
