import styled from '@emotion/styled';
import { theme } from '@clipmx/clip-storybook/dist/theme/theme';
import Paper from '@clipmx/clip-storybook/dist/components/Paper';
import { Link } from 'react-router-dom';

export const PageContent = styled.div({
    fontFamily: 'AvertaStd',
    marginLeft: '24px',
    marginRight: '24px',
    paddingTop: '34px',
    paddingBottom: '24px',

    [theme.breakpoints.down('xs')]: {
        marginLeft: '0px',
        marginRight: '0px',
        padding: '0px 0px 0px 0px',
    }
});

export const PaperContent = styled(Paper)({
    '&.paperContent': {
        fontFamily: 'AvertaStd',
        boxShadow: '0 8 16pt 0',
        background: '#FAFBFB',
        borderRadius: '24px',
        maxWidth: '968px',
        
        [theme.breakpoints.down('xs')]: {
            border: 0,
            backgroundColor: 'transparent',
            background: '#FFFFFF',
            borderRadius: '0px',
            top: '0px',
            boxShadow: '0px 0px 0px',
        },
    },
});

export const NavigateIcon = styled.span({
    display: 'flex', 
    float: 'inline-start', 
    marginRight: '1.125rem',
});

export const Breadcrumb = styled.div({
    color: '#181A36',
    display: 'flex', 
    fontFamily: 'AvertaStd',
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    
});

export const LinkBreadcrumb = styled(Link)({
    color: '#181A36',
    paddingRight: '1.125rem',
})

export const WrapLoading = styled.div(() => ({
    display: 'flex',
    marginTop: '34px',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      marginRight: '10px'
    },
    '& div':{
      width: '20px !important',
      height: '20px !important'
    }
  }));
