import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import { getPreapprovals } from 'components/loans/redux/selectors';
import LoansRedirection from 'components/loans/Commons/Redirection';
import HelpNeed from '../helpneed';
import ReactMarkdown from 'react-markdown';
import {
  LoansStates,
  LoansTabs,
  PreapprovalStatus,
} from 'constants/AppConstants';
import getUrl from 'helpers/getUrl';
import urls from 'helpers/urls';

import FormButton from 'helpers/FormUtils/FormButton';
import ClipBUMUnion from '@clipmx/clip-storybook/dist/components/Illustrations/BusinessUnitManagement/BUMUnion';
import { styles } from './styles';
import text from 'components/loans/Refill/text';
import { useHistory, useLocation } from 'react-router-dom';
import GoogleAnalytics from 'components/loans/GoogleAnalytics';

export const RefillCongrats = (props) => {
  let history = useHistory();
  let location = useLocation();
  const { classes, preapprovals = [] } = props;

  const typographyStyles = {
    root: classes.typographyRoot,
    headline: classes.typographyHeadline,
    body1: classes.typographyBody1,
  };

  const isMobileWebView = location.pathname.startsWith(urls.mobile);

  const goDashboard = () => {
    history.push(getUrl(urls.dashboard));
  };

  return (
    <LoansRedirection tab={LoansTabs.NEW_OFFER}>
      {preapprovals.filter(
        (preapproval) =>
          [LoansStates.REFILL].includes(preapproval.type) &&
          [PreapprovalStatus.ACCEPTED, PreapprovalStatus.ON_HOLD].includes(
            preapproval.status,
          ),
      ).length > 0 ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <GoogleAnalytics>
              <Typography variant={'headline'} classes={typographyStyles}>
                {text.titleCongrats}
              </Typography>
              <ClipBUMUnion 
              className={classes.banner}
               alt={text.cashAdvance}/>
              <Typography variant={'body1'} classes={typographyStyles}>
                <ReactMarkdown source={text.paragraph2} />
              </Typography>
              {!isMobileWebView && (
                <div className={classes.buttonContainer}>
                  <FormButton
                    variant="contained"
                    text={text.textButton}
                    onClick={goDashboard}
                  />
                </div>
              )}
              <HelpNeed />
            </GoogleAnalytics>
          </Grid>
        </Grid>
      ) : null}
    </LoansRedirection>
  );
};

RefillCongrats.propTypes = {
  classes: PropTypes.object.isRequired,
  preapprovals: PropTypes.array,
};
RefillCongrats.defaultProps = {
  preapprovals: [],
};

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
  };
};
export default connect(mapStateToProps)(withStyles(styles)(RefillCongrats));
