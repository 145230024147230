export default {
  title: '!Listo!',
  accountCreatedN3: 'Ya tienes tu Cartera Clip. Podrás recibir hasta',
  amount: '$18,000 cada mes.',
  checkInfo: 'Consulta tu CLABE en: Ajustes > Cartera Clip > Mi cuenta.',
  upgradeN3: '¿Quieres recibir hasta $500,000 cada mes?',
  upgradeN3Sub: 'Si quiero',
  upgradeButton: 'Lo quiero',
  contact: 'Contacta a  Customer Happiness al 55 6393 2323 y te ayudamos.',
  done: 'Finalizar',
};
