import React from 'react';

import * as Styled from '../IdentificationsIne/styled';
import idFront from '../../../../../assets/images/berlin/idFront.svg';
import addIcon from '../../../../../assets/images/berlin/add.svg';
import removeIcon from '../../../../../assets/images/berlin/delete.svg';

import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  reduxForm,
  change as updateFormField,
} from 'redux-form';
import BerlinFileInput from '../../../Commons/BerlinFileInput';
import { Forms } from 'constants/AppConstants';

export class IdentificationsPassport extends React.PureComponent {
  clickInput = () => {
    this.passFront.click();
  };

  deletePassFront = () => {
    const { dispatch } = this.props;
    dispatch(
      updateFormField(Forms.REGISTRATION_INFORMATION, 'passFront', null),
    );
  };

  render() {
    const { passFront } = this.props;
    return (
      <Styled.IdentificationsIneContainer>
        <Styled.IdentificationsIneCardContainer>
          <Styled.IneLabelContainer>
            <Styled.CardSide>
              {passFront ? passFront.name : 'Frente'}
            </Styled.CardSide>
            <Styled.CardSideDescription>
              Donde está tu foto.
            </Styled.CardSideDescription>
          </Styled.IneLabelContainer>
          {passFront ? (
            <Styled.FileInputContainer onClick={this.deletePassFront}>
              <Styled.INEFrontImage src={URL.createObjectURL(passFront)} />
              <Styled.RemoveImageOverlay
                src={removeIcon}
              ></Styled.RemoveImageOverlay>
              <Field
                refName={(ref) => {
                  this.passFront = ref;
                }}
                name="passFront"
                component={BerlinFileInput}
              ></Field>
            </Styled.FileInputContainer>
          ) : (
            <Styled.FileInputContainer onClick={this.clickInput}>
              <Styled.INEFrontImage src={idFront}></Styled.INEFrontImage>
              <Styled.AddImageOverlay src={addIcon}></Styled.AddImageOverlay>
              <Field
                refName={(ref) => {
                  this.passFront = ref;
                }}
                name="passFront"
                component={BerlinFileInput}
              ></Field>
            </Styled.FileInputContainer>
          )}
        </Styled.IdentificationsIneCardContainer>
      </Styled.IdentificationsIneContainer>
    );
  }
}

const selector = formValueSelector(Forms.REGISTRATION_INFORMATION);

const mapStateToProps = (state) => {
  return {
    passFront: selector(state, 'passFront'),
  };
};

const ReduxIdentificationsPassport = reduxForm(
  {
    form: Forms.REGISTRATION_INFORMATION,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
  },
  mapStateToProps,
)(IdentificationsPassport);

export default connect(mapStateToProps)(ReduxIdentificationsPassport);
