import styled from '@emotion/styled';
import { Field } from 'redux-form';

import MuiInfoIcon from '@material-ui/icons/AccountCircle';
import MuiHomeIcon from '@material-ui/icons/Home';
import grey from '@material-ui/core/colors/grey';
import MuiFormControl from '@material-ui/core/FormControl';
import { ProcessButton as CommonSubmitButton } from 'components/common/Buttons';
import { MdFileUpload } from 'react-icons/md';

const inlineBlock = 'inline-block';

export const HeaderSubTitle = styled.h4(({ theme }) => ({
  margin: `0 0 ${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
  color: grey[400],
  fontSize: `${theme.typography.fontSize}px`,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '17px',
}));

export const InfoIcon = styled(MuiInfoIcon)(({ theme }) => ({
  color: theme.palette.naranja.slate,
  display: inlineBlock,
  marginRight: theme.spacing.unit / 2,
}));

export const HomeIcon = styled(MuiHomeIcon)(({ theme }) => ({
  color: theme.palette.naranja.slate,
  marginRight: theme.spacing.unit / 2,
  display: inlineBlock,
}));

export const PublishIcon = styled(MdFileUpload)(({ theme }) => ({
  display: inlineBlock,
  color: theme.palette.naranja.slate,
  marginRight: theme.spacing.unit / 2,
}));

export const Form = styled.form(({ theme }) => ({
  color: theme.palette.naranja.slate,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
}));

export const FormField = styled(Field)(({ theme }) => ({
  marginBottom: theme.spacing.unit * 4,
  width: '95%',

  [theme.breakpoints.down('sm')]: {
    margin: `${theme.spacing.unit}px 0`,
    '& label': {
      fontSize: `${theme.typography.fontSize}px`,
    },
  },
}));

export const DoubleInput = styled(MuiFormControl)(({ theme }) => ({
  width: '50%',
  '&:first-child': {
    margin: `0 ${theme.spacing.unit}px 0 0!important`,
  },
}));

export const TrippleInput = styled(MuiFormControl)(({ theme }) => ({
  width: '33%',
  '&:first-child': {
    margin: `0 ${theme.spacing.unit}px 0 0!important`,
  },
}));

export const FormControl = styled(MuiFormControl)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

export const Group = styled.div(({ theme }) => ({
  maxWidth: '600px',
  width: '100%',

  '& .group-card': {
    background: theme.palette.primary.contrastText,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.08)',
  },
}));

export const TermsAndConditions = styled.div(({ theme }) => ({
  fontSize: '16px',
  lineHeight: `${theme.spacing.unit * 3}px`,
  color: theme.palette.naranja.slate,
  marginTop: `${theme.spacing.unit * 6}px`,
  marginBottom: `${theme.spacing.unit * 5}px`,
}));

const BaseButtonStyles = {
  width: '60%',
  flexWrap: 'wrap',
};

export const ButtonContainer = styled.div({
  display: 'block',
  width: '100%',
  textAlign: 'center',
});

export const SubmitButton = styled(CommonSubmitButton)(BaseButtonStyles);

export const HeaderContainer = styled.div(({ theme }) => ({
  width: '100%',
  paddingBottom: '10px',
  marginBottom: `${theme.spacing.unit * 7}px`,
  boxShadow: theme.shadows[1],
}));

export const TopDecoration = styled.div(({ theme }) => ({
  width: '100%',
  height: '5px',
  backgroundColor: theme.palette.naranja.cerulean,
}));

export const TitleMessage = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '32px',
  letterSpacing: 'normal',
  lineHeight: '38px',
  marginTop: `${theme.spacing.unit * 1.5}px`,
  color: theme.palette.naranja.slate,
  [theme.breakpoints.down('xs')]: {
    marginTop: `${theme.spacing.unit * 1.25}px`,
    marginBottom: `${theme.spacing.unit * 1.25}px`,
  },
}));

export const Message = styled.div(({ theme }) => ({
  paddingLeft: `${theme.spacing.unit * 3}px`,
  paddingRight: `${theme.spacing.unit * 3}px`,
  paddingTop: `${theme.spacing.unit * 3}px`,
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.naranja.slate,
  textAlign: 'center',
  lineHeight: '30px',
  '& a': {
    color: theme.palette.naranja.cerulean,
  },
  '& a:hover': {
    color: theme.palette.naranja.bloodOrange,
    transition: 'color 100ms linear',
  },
  [theme.breakpoints.down('xs')]: {
    textAlign: 'justify',
  },
}));

export const TitleFlow = styled.div(({ theme }) => ({
  width: '100%',
  paddingTop: `${theme.spacing.unit * 3}px`,
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '20px',
  letterSpacing: '0.33px',
  lineHeight: `${theme.spacing.unit * 3}px`,
}));

export const ContainerFlow = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 4}px`,
  '& ul': {
    listStyleType: 'none',
    margin: 0,
  },
  '& ul li': {
    width: '33%',
    paddingRight: `${theme.spacing.unit * 5}px`,
    display: 'table-cell',
  },
  [theme.breakpoints.down('xs')]: {
    marginTop: `${theme.spacing.unit * 2}px`,
    '& ul': {
      width: '100%',
      paddingLeft: 0,
    },
    '& ul li': {
      width: '100%',
      display: 'inline-grid',
      paddingBottom: `${theme.spacing.unit * 3}px`,
    },
  },
}));

export const IconStep = styled.img(() => ({
  display: 'block',
  margin: '0 auto',
}));

export const TitleStep = styled.span(({ theme }) => ({
  width: '100%',
  display: 'block',
  color: theme.palette.naranja.slate,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '18px',
  textAlign: 'center',
  lineHeight: `${theme.spacing.unit * 3}px`,
}));

export const ContentStep = styled.span(({ theme }) => ({
  width: '100%',
  display: 'block',
  color: theme.palette.naranja.slate,
  fontWeight: theme.typography.fontWeightLight,
  fontSize: '17px',
  textAlign: 'center',
  lineHeight: `${theme.spacing.unit * 3}px`,
}));

export const Instructions = styled.div(({ theme }) => ({
  width: '100%',
  marginBottom: `${theme.spacing.unit * 3}px`,
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
}));

export const TitleInstreuctions = styled.div(({ theme }) => ({
  width: '100%',
  maxWidth: '600px',
  color: theme.palette.naranja.slate,
  fontSize: '18px',
  lineHeight: '30px',
  textAlign: 'left',
}));

export const SubtitleInstreuctions = styled.div(({ theme }) => ({
  width: '100%',
  maxWidth: '600px',
  color: theme.palette.naranja.slate,
  fontSize: `${theme.typography.fontSize}px`,
  lineHeight: '17px',
  textAlign: 'left',
}));

export const FormTitle = styled.div(({ theme }) => ({
  textAlign: 'left',
  paddingTop: `${theme.spacing.unit * 2}px`,
  color: theme.palette.naranja.slate,
  fontSize: '18px',
  lineHeight: '22px',
}));

export const DocumentsInformation = styled.div(({ theme }) => ({
  width: '100%',
  maxWidth: '600px',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  color: grey[400],
  textAlign: 'center',
  fontSize: `${theme.typography.fontSize}px`,
  lineHeight: '17px',
  marginTop: `-${theme.spacing.unit * 2}px`,
}));

export const SeparatorFiles = styled.div(() => ({
  width: '100%',
  height: '1px',
  border: '0',
  backgroundColor: grey[300],
  display: inlineBlock,
}));
