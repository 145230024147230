export default {
  advice: '¡Buen trabajo! ya casi terminas tu solicitud.',
  title: 'Depósito',
  description:
    'RFC de facturación.',
  rfcLabel: 'RFC',
  curpLabel: 'CURP',
  confirmSection:
  'En esta cuenta se realizará el desembolso de tu Adelanto de Ventas Clip.',
  confirmSubsection: 'Si necesitas hacer un cambio, contacta a Customer Hapiness por correo a ',
  confirmSubsectionRef: ' También puedes escribir por WhatsApp o llamar al 55 6393-2323.',
  dataAdvice:
    'Si los datos de la CLABE son incorrectos ponte en contacto con Customer Hapiness.',
  dataAdviceLink: 'mailto:help@clip.mx',
  ownerLabel: 'Titular de la cuenta',
  bankLabel: 'Banco',
  clabeLabel: 'CLABE interbancaria',
};
