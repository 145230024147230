export const styles = (theme) => {
  const rootStyles = {
    container: {
      minHeight: `calc(100vh - ${theme.spacing.unit * 12.25}px)`,
      width: `calc(100% - ${theme.spacing.unit * 2}px)`,
      margin: `${theme.spacing.unit * 5.25}px ${theme.spacing.unit}px
      ${theme.spacing.unit * 7}px ${theme.spacing.unit}px`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    requestImageContainer: {
      textAlign: 'center',
      margin: `${theme.spacing.unit * 2.75}px 0px`,
      '& > img': {
        width: `${theme.spacing.unit * 9.25}px`,
        height: `${theme.spacing.unit * 9.25}px`,
      },
    },
    titleContainer: {
      margin: 'auto',
    },
    cardsContainer: {
      padding: `0px ${theme.spacing.unit}px`,
      marginBottom: `${theme.spacing.unit * 2}px`,
    },
    cardSpacer: {
      marginTop: `${theme.spacing.unit}px`,
    },
    cardContent: {
      display: 'flex',
      padding: `${theme.spacing(1)}px 0px!important`,
    },
    cardContentNewAddress: {
      paddingTop: '0px!important',
      paddingBottom: '0px!important',
    },
    cardContentText: {
      width: `calc(100% - ${theme.spacing.unit * 6.5}px)`,
      '& > p': {
        marginBottom: 0,
      },
    },
    cardContentTextNewAddress: {
      display: 'flex',
      alignItems: 'center',
    },
    cardContentButton: {
      width: `${theme.spacing.unit * 6.5}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  const typographyStyles = {
    typographyTitle: {
      fontWeight: 600,
      padding: 0,
      fontSize: `${theme.spacing.unit * 3}px`,
      textAlign: 'center',
    },
    typographySubheading: {
      fontWeight: 400,
      marginTop: theme.spacing(1),
      fontSize: `${theme.spacing.unit * 2}px`,
      textAlign: 'center',
    },
    typographyBody1: {
      fontWeight: 500,
      fontSize: `${theme.spacing.unit * 2}px`,
    },
    typographyBody2: {
      fontWeight: 400,
      fontSize: `${theme.spacing.unit * 2}px`,
    },
    personalInfoAddress: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  };

  return { ...rootStyles, ...typographyStyles };
};
