import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { isEmpty } from 'helpers/validate';
import { toDate } from 'helpers/dateHelpers';
import { toCurrencyFormat } from 'helpers/formatCurrency';
import { getCollections } from 'components/loans/redux/selectors';
import { getCollections as loadCollections } from 'components/loans/redux/actions';
import * as FormStyled from 'components/loans/Commons/Form/styled';
import * as Styled from 'components/loans/MyCrediClip/styled';
import text from 'components/loans/MyCrediClip/text';

export class MyCrediClipCollection extends Component {
  state = {
    period: 'seven',
    rowsPerPage: 7,
    page: 0,
  };

  static propTypes = {
    loan: PropTypes.object.isRequired,
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };

  setPage = (page) => {
    this.setState({ page });
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setRowsPerPage(+event.target.value);
    this.setPage(0);
  };

  onChangePeriod = (event) => {
    const period = event.target.value;
    const payments =
      period === 'seven'
        ? 7
        : period === 'fourteen'
        ? 14
        : period === 'thirty'
        ? 30
        : 0;
    this.props.loadCollections(this.props.loan.loan_id, payments);
    this.setState({ period });
  };

  dateToStringFormat = (date) => {
    if (!date) return '';
    const strDate = date.substr(0, 10).replace(/-/g, '/');
    return toDate(new Date(strDate));
  };

  render() {
    const { collections, loan } = this.props;
    const rows = isEmpty(collections[loan.loan_id])
      ? []
      : collections[loan.loan_id];
    return (
      <React.Fragment>
        <Styled.Form>
          <FormStyled.DoubleInput>
            <FormStyled.FormControl fullWidth>
              <Styled.LabelDate htmlFor="age-simple">
                {text.period}
              </Styled.LabelDate>
              <Styled.SelectDate
                displayEmpty
                value={this.state.period}
                onChange={this.onChangePeriod}
                renderValue={(selected) => {
                  if (selected === 'none') {
                    return text.selectPeriod;
                  }
                  return text.periodOptions.filter(
                    (item) => item.id === selected,
                  )[0].name;
                }}
              >
                <MenuItem value="none" disabled>
                  {text.selectPeriod}
                </MenuItem>
                {text.periodOptions.map((item) => {
                  return (
                    <MenuItem key={'item_period_' + item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Styled.SelectDate>
            </FormStyled.FormControl>
          </FormStyled.DoubleInput>
        </Styled.Form>
        <Styled.TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <Styled.Cell className="title">{text.date}</Styled.Cell>
                <Styled.Cell className="title" align="right">
                  {text.payLoanAmount}
                </Styled.Cell>
                <Styled.Cell className="title" align="right">
                  {text.payFixCharge}
                </Styled.Cell>
                <Styled.Cell className="title" align="right">
                  {text.iva}
                </Styled.Cell>
                {loan.overdue_interest && (
                  <Styled.Cell className="title" align="right">
                    {text.payOverdue}
                  </Styled.Cell>
                )}
                <Styled.Cell
                  className={['title', 'bold'].join(' ')}
                  align="right"
                >
                  {text.payTotal}
                </Styled.Cell>
              </TableRow>
            </TableHead>
            <TableBody id="historyPayments">
              {rows
                .map((row, index) => (
                  <TableRow key={'row_history_' + index}>
                    <Styled.Cell
                      className={['bold', 'light'].join(' ')}
                      component="th"
                      scope="row"
                    >
                      {this.dateToStringFormat(row.collection_date_local)}
                    </Styled.Cell>
                    <Styled.Cell
                      className={['bold', 'light'].join(' ')}
                      align="right"
                    >
                      {toCurrencyFormat(row.capital)}
                    </Styled.Cell>
                    <Styled.Cell
                      className={['bold', 'light'].join(' ')}
                      align="right"
                    >
                      {toCurrencyFormat(row.interest)}
                    </Styled.Cell>
                    <Styled.Cell
                      className={['bold', 'light'].join(' ')}
                      align="right"
                    >
                      {toCurrencyFormat(row.tax)}
                    </Styled.Cell>
                    {loan.overdue_interest && (
                      <Styled.Cell
                        className={['bold', 'light'].join(' ')}
                        align="right"
                      >
                        {toCurrencyFormat(row.overdue_interest)}
                      </Styled.Cell>
                    )}
                    <Styled.Cell className="bold" align="right">
                      <b>{toCurrencyFormat(row.amount)}</b>
                    </Styled.Cell>
                  </TableRow>
                ))
                .filter(
                  (row, index) =>
                    index >= this.state.rowsPerPage * this.state.page &&
                    index < this.state.rowsPerPage * (this.state.page + 1),
                )}
            </TableBody>
          </Table>
        </Styled.TableContainer>
        <Styled.Pagination
          component="div"
          count={rows.length}
          rowsPerPage={this.state.rowsPerPage}
          rowsPerPageOptions={[this.state.rowsPerPage]}
          page={this.state.page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collections: getCollections(state),
  };
};
export default connect(mapStateToProps, {
  loadCollections,
})(MyCrediClipCollection);
