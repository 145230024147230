export default {
  title: '¿A dónde enviamos tu tarjeta?',
  requestFlowTitle: 'Quiero mi Clip Card',
  subTitle: 'El envío es totalmente gratis.',
  savedAddress: 'Enviar a:',
  fullName: (firstName, lastName, secondLastName) =>
    `${firstName} ${lastName} ${secondLastName === null ? '' : secondLastName}`,
  fullAddress: (street, number, colony, municipality, postalCode, state) =>
    `${street} ${number}, ${colony}, ${municipality}, ${postalCode} ${state}`,
  newAddress: 'Enviar a otra dirección',
};
