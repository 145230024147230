import { SET_TOAST_STATE } from './actions';

const initialState = {
  open: false,
  severity: 'success',
  message: '',
  textAlign: 'left',
  position: {
    vertical: 'top',
    horizontal: 'center',
  },
};

const actionHandlers = new Map([[SET_TOAST_STATE, setToastState]]);

export default function ToastReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function setToastState(state, action) {
  const { payload } = action;
  return {
    ...state,
    ...payload,
  };
}
