import styled from '@emotion/styled';
import { ProcessButtonv3 as CommonSubmitButton } from 'components/common/Buttons';

const inlineBlock = 'inline-block';

export const Container = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 90}px`,
  display: inlineBlock,
  margin: `-${theme.spacing.unit}px -${theme.spacing.unit * 2}px 0`,
  [theme.breakpoints.down('xs')]: {
    margin: 0,
    width: '100%',
  },
}));

export const Title = styled.div(({ theme }) => ({
  width: '100%',
  display: inlineBlock,
  color: theme.palette.naranja.slate,
  fontSize: `${theme.spacing.unit * 3.75}px`,
  fontWeight: theme.typography.fontWeightMedium,
  [theme.breakpoints.down('xs')]: {
    textAlign: 'center',
  },
}));

export const ImageContainer = styled.div(({ theme }) => ({
  position: 'relative',
  width: `${theme.spacing.unit * 26.75}px`,
  height: `${theme.spacing.unit * 25}px`,
  float: 'left',
  zIndex: 2,
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));

export const ImageTitle = styled.img(({ theme }) => ({
  display: 'inline-block',
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));
export const ImageTitleMobile = styled.img(({ theme }) => ({
  marginTop: `${theme.spacing.unit}px`,
  display: 'none',
  [theme.breakpoints.down('xs')]: {
    display: 'inline-block',
  },
}));

export const ImageTitlePersonalized = styled.img(({ theme }) => ({
  display: 'inline-block',
  width: '83%',
  padding: '12px',
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));
export const ImageTitleMobilePersonalized = styled.img(({ theme }) => ({
  marginTop: `${theme.spacing.unit}px`,
  display: 'none',
  [theme.breakpoints.down('xs')]: {
    display: 'block',
    margin: 'auto',
  },
}));

export const TextContainer = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 60.25}px`,
  marginLeft: `${theme.spacing.unit * 3}px`,
  float: 'right',
  fontWeight: theme.typography.fontWeightLight,
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    marginLeft: '0px',
    textAlign: 'center',
  },
}));

export const BannerTitle = styled.img(({ theme }) => ({
  width: `${theme.spacing.unit * 22.5}px`,
  height: `${theme.spacing.unit * 5}px`,
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    height: `${theme.spacing.unit * 7.5}px`,
    marginTop: `${-theme.spacing.unit}px`,
  },
}));

export const TextTitle = styled.p(({ theme }) => ({
  marginTop: `${theme.spacing.unit * 2}px`,
  width: `${theme.spacing.unit * 57.5}px`,
  height: `${theme.spacing.unit * 7}px`,
  fontSize: `${theme.spacing.unit * 2.5}px`,
  fontWeight: 500,
  fontStretch: 'normal',
  lineHeight: 1.33,
  letterSpacing: 'normal',
  color: '#001724',
  [theme.breakpoints.down('xs')]: {
    marginBottom: '0',
    display: 'inline-block',
    width: '100%',
  },
}));

export const TextSubtitle = styled.p(({ theme }) => ({
  width: `${theme.spacing.unit * 57.5}px`,
  fontSize: `${theme.spacing.unit * 2.25}px`,
  fontWeight: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#001724',
  [theme.breakpoints.down('xs')]: {
    display: 'inline-block',
    width: '100%',
    fontSize: `${theme.spacing.unit * 2}px`,
  },
}));

export const ExpireWarning = styled.div(({ theme }) => ({
  display: 'inline-block',
  width: `${theme.spacing.unit * 86.75 + 5}px`,
  maxWidth: '100%',
  height: `${theme.spacing.unit * 5}px`,
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
  borderRadius: `${theme.spacing.unit * 0.5}px`,
  border: 'solid 1px rgba(0, 125, 142, 0.08)',
  borderLeft: 'solid 4px #007d8e',
  backgroundColor: 'rgba(0, 125, 142, 0.06)',
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));

export const ExpireWarningMobile = styled.div(({ theme }) => ({
  display: 'none',
  width: `calc(100% - ${theme.spacing.unit * 0.5}px)`,
  maxWidth: '100%',
  height: `${theme.spacing.unit * 6.25}px`,
  borderRadius: `${theme.spacing.unit * 0.5}px`,
  border: 'solid 1px rgba(0, 125, 142, 0.08)',
  borderLeft: 'solid 4px #007d8e',
  backgroundColor: 'rgba(0, 125, 142, 0.06)',
  [theme.breakpoints.down('xs')]: {
    display: 'inline-block',
  },
}));

export const TextTitlePersonalized = styled.p(({ theme }) => ({
  marginTop: `${theme.spacing.unit * 2}px`,
  height: `${theme.spacing.unit * 7}px`,
  fontSize: `${theme.spacing.unit * 2.5}px`,
  fontWeight: 500,
  fontStretch: 'normal',
  lineHeight: 1.33,
  letterSpacing: 'normal',
  color: '#001724',
  [theme.breakpoints.down('xs')]: {
    marginBottom: '0',
    display: 'inline-block',
    width: '100%',
  },
}));

export const TextSubtitlePersonalized = styled.p(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 2.25}px`,
  fontWeight: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#001724',
  [theme.breakpoints.down('xs')]: {
    display: 'inline-block',
    width: '100%',
    fontSize: `${theme.spacing.unit * 2}px`,
  },
}));

export const ExpireWarningPersonalized = styled.div(({ theme }) => ({
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
  borderRadius: `${theme.spacing.unit * 0.5}px`,
  border: 'solid 1px rgba(0, 125, 142, 0.08)',
  borderLeft: 'solid 4px #007d8e',
  backgroundColor: 'rgba(0, 125, 142, 0.06)',
}));

export const ExpireWarningContent = styled.div(({ theme }) => ({
  display: 'inline-block',
  fontSize: `${theme.spacing.unit * 1.75}px`,
  color: '#007d8e',
  paddingRight: '10px',
  '& > p': {
    marginTop: `${theme.spacing.unit * 1.5}px`,
    marginBottom: `${theme.spacing.unit * 1.5}px`,
  },
  [theme.breakpoints.down('xs')]: {
    width: '80%',
    '& > p': {
      marginTop: `${theme.spacing.unit}px`,
      marginBottom: `${theme.spacing.unit}px`,
    },
  },
}));

export const HourglassTop = styled.img(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  marginRight: `${theme.spacing.unit * 0.75}px`,
  verticalAlign: 'middle',
  top: `-${theme.spacing.unit * 0.25}px`,
  marginTop: `${theme.spacing.unit}px`,
  marginBottom: `${theme.spacing.unit}px`,
  marginLeft: `${theme.spacing.unit}px`,
  [theme.breakpoints.down('xs')]: {
    top: '-20%',
    marginTop: 0,
    marginBottom: 0,
  },
}));

export const Content = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 88}px`,
  position: 'relative',
  float: 'left',
  display: inlineBlock,
  marginTop: `${theme.spacing.unit * 1.5}px`,
  [theme.breakpoints.down('xs')]: {
    marginTop: `${theme.spacing.unit}px`,
    width: '100%',
  },
}));

export const Instructions = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 83.5}px`,
  position: 'relative',
  display: inlineBlock,
  paddingLeft: `${theme.spacing.unit * 2}px`,
  paddingRight: `${theme.spacing.unit * 2}px`,
  color: '#001724',
  fontSize: `${theme.spacing.unit * 2.25}px`,
  fontWeight: theme.typography.fontWeightLight,
  textAlign: 'center',
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    paddingLeft: '0',
    textAlign: 'center',
  },
}));

export const InstructionsPersonalized = styled.div(({ theme }) => ({
  position: 'relative',
  display: inlineBlock,
  paddingLeft: `${theme.spacing.unit * 2}px`,
  paddingRight: `${theme.spacing.unit * 2}px`,
  color: '#001724',
  fontSize: `${theme.spacing.unit * 2.25}px`,
  fontWeight: theme.typography.fontWeightLight,
  textAlign: 'center',
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    paddingLeft: '0',
    textAlign: 'center',
  },
}));

export const AmountsContainer = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 87.5}px`,
  position: 'relative',
  display: 'flex',
  '&:hover > div': {
    transform: 'scale(0.9)',
    transition: `transform ${theme.transitions.duration.shortest}ms`,
  },
  '&:hover > div:hover': {
    transform: 'scale(1.1)',
    transition: `transform ${theme.transitions.duration.shortest}ms`,
  },
  '& > div:not(:first-child)': {
    marginLeft: `${theme.spacing.unit * 2.5}px`,
  },
  '& > .recommended': {
    border: 'solid 2px #007d8e',
    width: `${theme.spacing.unit * 27.5 + 4}px!important`,
  },
  [theme.breakpoints.down('xs')]: {
    paddingBottom: `${theme.spacing.unit * 3}px`,
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    display: 'flex',
    '&:hover > div': {
      transform: 'none',
      transition: 'none',
    },
    '&:hover > div:hover': {
      transform: 'none',
      transition: 'none',
    },
  },
}));

export const Amount = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 27.5}px`,
  boxSizing: 'border-box',
  borderRadius: `${theme.spacing.unit}px`,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
  opacity: 1,
  '& > .recommendedLabel': {
    display: 'block',
    position: 'relative',
    top: `${theme.spacing.unit * 3}px`,
    borderRadius: '0 0 6px 6px',
  },
  '&.recommended:after': {
    content: '"RECOMENDADO"',
    backgroundColor: '#007d8e',
    color: 'white',
    width: '102%',
    left: '-2px',
    top: `${theme.spacing.unit * 2}px`,
    position: 'relative',
    display: 'block',
    textAlign: 'center',
    margin: 'auto auto',
    fontSize: `${theme.spacing.unit * 1.375}px`,
    fontWeight: 600,
    paddingTop: `${theme.spacing.unit * 0.5}px`,
    paddingBottom: `${theme.spacing.unit * 0.5}px`,
    borderBottomRightRadius: `${theme.spacing.unit}px`,
    borderBottomLeftRadius: `${theme.spacing.unit}px`,
  },
  [theme.breakpoints.down('xs')]: {
    width: `${theme.spacing.unit * 30.25}px`,
    '&:first-child': {
      marginLeft: '2px',
    },
    marginTop: '2px',
    display: 'inline-block',
  },
}));

export const AmountContent = styled.div(({ theme }) => ({
  height: `${theme.spacing.unit * 23.75}px`,
  backgroundColor: 'rgba(0, 125, 142, 0.06)',
  padding: `${theme.spacing.unit * 2}px`,
  textAlign: 'center',
}));
export const AmountTitle = styled.span(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 2.25}px`,
  fontWeight: 600,
  color: '#001724',
}));
export const AmountSubtitle = styled.span(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 'normal',
  color: '#001724',
}));
export const AmountHR = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 21}px`,
  height: '1px',
  marginTop: `${theme.spacing.unit}px`,
  marginLeft: `${theme.spacing.unit * 1.25}px`,
  backgroundColor: 'rgba(0, 125, 142, 0.24)',
}));
export const AmountDescription = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 1.25}px`,
  fontSize: '11px',
  color: '#001724',
}));
export const DescriptionText = styled.div(({ theme }) => ({
  float: 'left',
  width: 'calc(50% - 4px)',
  textAlign: 'right',
  height: 90,
}));
export const Description = styled.p(({ theme }) => ({
  margin: 0,
  padding: 0,
  marginTop: `${theme.spacing.unit * 0.5}px`,
  fontWeight: 500,
  whiteSpace: 'nowrap',
}));
export const ImportantNoteContainer = styled.div(({ theme }) => ({
  margin: 0,
  padding: 0,
}));
export const ImportantNote = styled.span(({ theme }) => ({
  margin: 0,
  padding: 0,
  fontWeight: 500,
}));
export const ImportantNoteText = styled.span(({ theme }) => ({
  margin: 0,
  padding: 0,
}));
export const ValuesText = styled.div(({ theme }) => ({
  float: 'right',
  width: 'calc(50% - 4px)',
  textAlign: 'left',
  height: 90,
}));
export const Values = styled.p(({ theme }) => ({
  margin: 0,
  padding: 0,
  marginTop: `${theme.spacing.unit * 0.5}px`,
  [theme.breakpoints.down('xs')]: {
    overflowWrap: 'break-word',
  },
}));

export const AmountFooter = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));
export const AmountContainer = styled.div(({ theme }) => ({
  marginTop: `${theme.spacing.unit}px`,
  textAlign: 'center',
}));
export const AmountReduced = styled.span(({ theme }) => ({
  fontWeight: 'bold',
  color: '#007d8e',
  fontSize: `${theme.spacing.unit * 2.25}px`,
  display: 'inline-block',
  verticalAlign: 'middle',
}));
export const AmountLoan = styled.span(({ theme }) => ({
  fontWeight: 'bold',
  color: '#007d8e',
  fontSize: `${theme.spacing.unit * 3.75}px`,
  marginLeft: `${theme.spacing.unit * 0.25}px`,
  marginRight: `${theme.spacing.unit * 0.25}px`,
  display: 'inline-block',
  verticalAlign: 'middle',
}));
export const ButtonAmount = styled(CommonSubmitButton)(({ theme }) => ({
  margin: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px 0px ${
    theme.spacing.unit * 2
  }px`,
}));

export const StepsTitle = styled.div(({ theme }) => ({
  marginTop: `${theme.spacing.unit * 7}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
  color: '#001724',
  fontSize: `${theme.spacing.unit * 2.75}px`,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: 1.33,
  textAlign: 'center',
  [theme.breakpoints.down('xs')]: {
    marginTop: `${theme.spacing.unit * 4}px`,
    fontSize: theme.typography.title.fontSize,
    lineHeight: `${theme.spacing.unit * 3.25}px`,
  },
}));

export const StepsContainer = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 4}px`,
  marginBottom: `${theme.spacing.unit * 4}px`,
  display: 'flex',
  color: theme.palette.naranja.slate,
  '& > div:not(:first-child)': {
    marginLeft: `${theme.spacing.unit * 2.5}px`,
  },
  [theme.breakpoints.down('xs')]: {
    display: 'inline-grid',
    marginTop: `${theme.spacing.unit * 2}px`,
    '& > div:not(:first-child)': {
      marginLeft: 0,
    },
  },
}));

export const Step = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 27.5}px`,
  position: 'relative',
  display: 'block',
  textAlign: 'center',
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    marginTop: `${theme.spacing.unit * 3}px`,
  },
}));

export const StepIconContainer = styled.div(({ theme }) => ({
  display: 'inline-flex',
  verticalAlign: 'middle',
}));

export const StepIcon = styled.img({
  width: '40px',
  height: '40px',
  display: 'block',
});

export const StepTitle = styled.span(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 1}px`,
  display: inlineBlock,
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightMedium,
  color: '#001724',
  [theme.breakpoints.down('xs')]: {
    fontSize: `${theme.spacing.unit * 2.25}px`,
  },
}));

export const StepDescription = styled.span(({ theme }) => ({
  width: '100%',
  minWidth: `${theme.spacing.unit * 12.5}px`,
  marginTop: `${theme.spacing.unit * 1}px`,
  display: inlineBlock,
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: `${theme.spacing.unit * 2.5}px`,
  color: '#001724',
  [theme.breakpoints.down('xs')]: {
    width: '90%',
    textAlign: 'center',
    fontSize: `${theme.spacing.unit * 2.25}px`,
  },
}));