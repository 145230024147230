export default {
  title: 'Ingresa tus datos',
  info: 'Escríbelos tal como aparecen en tu identificación oficial.',
  names: 'Nombre(s)',
  last_name: 'Apellido paterno',
  second_last_name: 'Apellido materno',
  birthday: 'Fecha de nacimiento',
  continue: 'Continuar',
  legalSex: 'Sexo legal',
  countryOfOrigin: 'País de nacimiento',
  birthPlace: 'Lugar de nacimiento',
  nationality: 'Nacionalidad',
  female: 'Femenino',
  male: 'Masculino',
  occupation: '¿A qué te dedicas?',
  verify: 'Verifica tus datos',
  errorMessage: 'No pudimos generar tu CURP de forma automática.',
  dateInvalid: 'La fecha no es válida.',
  underAge: 'Debes ser mayor de edad.',
};
