const styles = (theme) => {
  return {
    container: {
      marginTop: '42px',
    },
    title: {
      fontWeight: 'normal',
      textAlign: 'left',
      fontSize: '24px',
      width: '100%',
      lineHeight: '1.5rem',
      boxSizing: 'border-box',
      marginTop: '0',
      padding: '0 0 0 0',
      marginBottom: '0.5rem',
    },
    headline: {
      width: '100%',
      textAlign: 'left',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '0 0 0 0',
      marginBottom: '1rem',
    },
    imageContainer: {
      textAlign: 'center',
      marginTop: '31px',
      marginBottom: '37px',
    },
    lineContainer: {
      display: 'grid',
      gridTemplateColumns: '80% 1fr',
      alignItems: 'center',
    },
    switchText: {
      fontSize: '14px',
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  };
};

export default styles;
