import React from 'react';
import { Field } from 'redux-form';
import { onEventMiddleware } from '../../../helpers/Clevertap';

const ClevertapField = (props) => {
  const { clevertap, ...rest } = props;
  const onEvent = {
    [clevertap.listener]: onEventMiddleware(
      clevertap,
      props[clevertap.listener],
    ),
  };
  delete rest[clevertap.listener];
  return <Field {...onEvent} {...rest} />;
};

export default ClevertapField;
