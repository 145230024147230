import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { LoansTabs } from 'constants/AppConstants';
import { getLoans as loadLoans } from 'components/loans/redux/actions';
import {
  getPreapprovals,
  getLoans,
  getLoansLoaded,
  preapprovalsLoaded,
} from 'components/loans/redux/selectors';
import { requestRefill } from 'components/loans/redux/actions';
import LoansRedirection from 'components/loans/Commons/Redirection';
import RefillDetail from 'components/loans/Refill/detail';
import { GridCard } from '../styled';
import GoogleAnalytics from 'components/loans/GoogleAnalytics';
import HelpNeed from '../helpneed';

export class RefillConfirmation extends Component {
  static propTypes = {
    loans: PropTypes.array,
    loansLoaded: PropTypes.bool,
    preapprovals: PropTypes.array,
    preapprovalsLoaded: PropTypes.bool,
    loadLoans: PropTypes.func.isRequired,
    requestRefill: PropTypes.func.isRequired,
  };
  static defaultProps = {
    preapprovals: [],
    preapprovalsLoaded: false,
    loans: [],
    loansLoaded: false,
  };

  componentDidMount() {
    const { loadLoans, loansLoaded } = this.props;
    !loansLoaded && loadLoans();
  }

  render() {
    const {
      loansLoaded,
      loans,
      preapprovalsLoaded,
      preapprovals,
      requestRefill,
      loansFormVersion,
      showCashAdvance,
    } = this.props;
    return (
      <LoansRedirection tab={LoansTabs.NEW_OFFER}>
        {loansLoaded && preapprovalsLoaded ? (
          <GoogleAnalytics>
            <GridCard item xs={12} sm={12} md={12} lg={7}>
              <RefillDetail
                detail={false}
                preapprovals={preapprovals}
                loans={loans}
                action={requestRefill}
                loansFormVersion={loansFormVersion}
                showCashAdvance={showCashAdvance}
              />
              <HelpNeed  />      
            </GridCard>
          </GoogleAnalytics>
        ) : null}
      </LoansRedirection>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loans: getLoans(state),
    loansLoaded: getLoansLoaded(state),
    preapprovals: getPreapprovals(state),
    preapprovalsLoaded: preapprovalsLoaded(state),
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
  };
};
export default connect(mapStateToProps, { loadLoans, requestRefill })(
  RefillConfirmation,
);
