import React from 'react';
import { connect } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/source/animate.css';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { setAccountRep } from './redux/berlinActions';

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import PropTypes from 'prop-types';

import * as Styled from './Commons/BerlinContent/styled';
import BerlinSpinner from './BerlinSpinner';

import BalanceInformation from './BalanceInformation';
import PinChange from './PinChange';
import ShowPIN from './ShowPIN';

import Transfers from './Transfers';
import Auth from './Auth/authHOC';
import DynamicCvv from './DynamicCvv';
import { BerlinTheme } from './Theme';
import TransactionDetail from './TransactionDetail';
import { ScreenContext, SCREENS } from './contexts/ScreenContext';

import { SvgIcon } from '@material-ui/core';
import styles from './styles';

import CdaHelper from './CdaHelper';
const NavigationCardIcon = (props) => {
  const svgPath =
    'M21.1470588,5 C22.17041,5 23,5.82655123 23,6.84615385 L23,6.84615385 L23,19.1538462 ' +
    'C23,20.1734488 22.17041,21 21.1470588,21 L21.1470588,21 L3.85294118,21 C2.82959002,21 2,20.1734488 ' +
    '2,19.1538462 L2,19.1538462 L2,6.84615385 C2,5.82655123 2.82959002,5 3.85294118,5 L3.85294118,5 ' +
    'Z M19.9854257,7 C19.8466593,7 19.7106046,7.02635294 19.5939624,7.07837789 L15.8973945,8.64810984 ' +
    'C15.3705695,8.87092715 15,9.3930283 15,10 L15,11.9854836 C15,12.5456209 15.4541328,13 16.0145982,13 ' +
    'C16.1566976,13 16.2918444,12.9705172 16.4147702,12.9177277 L20.0957006,11.3548767 C20.6260976,11.1336721 ' +
    '21,10.6098387 21,10 L20.9998805,8.00970217 C20.9972165,7.45165542 20.5440395,7 19.9854257,7 Z';
  return (
    <SvgIcon {...props}>
      <path d={svgPath} id="Combined-Shape" fillRule="nonzero" />
    </SvgIcon>
  );
};
export class Berlin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeScreen: SCREENS.SPINNER,
      lastScreen: SCREENS.BALANCE,
      selectedTransaction: null,
      showNip: false,
      nipTooltip: false,
      cvvTooltip: false,
      cdaReady: false,
      cacaoTooltip: false,
    };
  }

  static defaultProps = {
    merchant: {},
    account: {},
    berlin: {},
    ldFlags: {},
  };

  static propTypes = {
    merchant: PropTypes.object,
    account: PropTypes.object,
    berlin: PropTypes.object,
    ldFlags: PropTypes.object,
  };

  componentDidMount() {
    const { ldFlags, account } = this.props;
    let cvvTooltip = false;

    let showNip = true;
    let nipTooltip = false;
    if (account?.card_representation) {
      if (account?.card_representation[0]?.pin_activation_counter > 0) {
        showNip = false;
      }
    }
    if (!ldFlags.showPrintedNip) {
      showNip = false;
    }

    if (ldFlags.showPrintedNip) {
      const showNipTooltip = localStorage.getItem('showNipTooltip');
      if (!showNipTooltip) {
        nipTooltip = true;
      }
    }
    if (ldFlags.enableCvv) {
      const showCvvTooltip = localStorage.getItem('showCvvTooltip');
      if (!showCvvTooltip) {
        cvvTooltip = true;
      }
    }

    if (showNip === false) {
      nipTooltip = showNip;
    }

    this.setActiveScreen(SCREENS.BALANCE);

    this.setState({ nipTooltip, showNip, cvvTooltip });
  }

  setActiveScreen = (activeScreen) => {
    this.setState({
      lastScreen: this.state.activeScreen,
      activeScreen: activeScreen,
    });
  };

  setNavigationScreen = (event, screen) => {
    this.setState({ activeScreen: screen });
  };

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  toggleNipTooltip = (arg) => {
    this.setState({ nipTooltip: arg });
    localStorage.setItem('showNipTooltip', true);
  };
  toggleCvvTooltip = (arg) => {
    this.setState({ cvvTooltip: arg });
    localStorage.setItem('showCvvTooltip', true);
  };
  toggleCacaoTooltip = (arg) => {
    this.setState({ cacaoTooltip: arg });
  };

  renderScreen = (screen) => {
    const { berlin, user } = this.props;
    switch (screen) {
      case SCREENS.BALANCE:
        return (
          <BalanceInformation
            selectTransaction={(transactionid) => {
              this.setActiveScreen(SCREENS.TRANSACTION);
              this.setState({
                selectedTransaction: transactionid,
              });
            }}
            setActiveScreen={this.setActiveScreen}
            SCREENS={SCREENS}
          />
        );

      case SCREENS.CHANGE_PIN:
        return (
          <PinChange
            lastScreen={this.state.lastScreen}
            showNip={this.state.showNip}
            SCREENS={SCREENS}
            hideShowNip={(arg) => this.setState({ showNip: arg })}
            setActiveScreen={this.setActiveScreen}
          />
        );
      case SCREENS.TRANSFERS:
        return (
          <Transfers SCREENS={SCREENS} setActiveScreen={this.setActiveScreen} />
        );
      case SCREENS.TRANSACTION:
        return (
          <TransactionDetail
            transaction={{ ...this.state.selectedTransaction }}
          />
        );
      case SCREENS.DYNAMIC_CVV:
        return (
          <DynamicCvv
            account={berlin.account_representation}
            SCREENS={SCREENS}
            setActiveScreen={this.setActiveScreen}
          />
        );
      case SCREENS.SHOW_PIN:
        return (
          <ShowPIN
            user={user}
            account={berlin.account_representation}
            SCREENS={SCREENS}
            setActiveScreen={this.setActiveScreen}
          />
        );
      case SCREENS.CDA:
        return (
          <CdaHelper
            cdaReady={this.state.cdaReady}
            recoverCda={true}
            user={user}
            setActiveScreen={() => this.setActiveScreen(SCREENS.BALANCE)}
            location={this.location}
            merchantId={berlin.account_representation.account_holder_id}
          />
        );
      case SCREENS.SPINNER:
        return <BerlinSpinner />;
      default:
        return;
    }
  };

  render() {
    return (
        <MuiThemeProvider theme={BerlinTheme}>
          <EmotionThemeProvider theme={BerlinTheme}>
            <Styled.BerlinContainer className={'mobile'}>
              <ScreenContext.Provider
                value={{
                  screenState: this.state,
                  setScreenState: (arg) => this.setState({ ...arg }),
                }}
              >
                <ReactNotification />
                {this.renderScreen(this.state.activeScreen)}
              </ScreenContext.Provider>
            </Styled.BerlinContainer>
          </EmotionThemeProvider>
        </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = {
  setAccountRep,
};
const mapStateToProps = (state) => {
  return {
    account: state.berlin.account_representation,
    merchant: state.merchant,
    berlin: state.berlin,
    ldFlags: state.berlin.ldFlags,
    user: state.user,
  };
};

export default Auth(withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(Berlin)),
));
