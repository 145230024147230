import React from 'react';

import * as Styled from './styled';

import { connect } from 'react-redux';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import { Forms } from 'constants/AppConstants';
import {
  renderRadio,
  renderCheckbox,
} from '../../Commons/BerlinInputs/FormUtils';
import { required } from 'helpers/validate';
import PropTypes from 'prop-types';

import { IdentificationsIne } from './IdentificationsIne';
import { IdentificationsPassport } from './IdentificationsPassport';
import text from './text';

import imageCompression from 'browser-image-compression';

import candado from '../../../../assets/images/berlin/candado.svg';
import lightbulb from '../../../../assets/images/berlin/lightbulb.svg';

export class Identifications extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      termsActive: false,
      privacyActive: false,
    };
  }

  static propTypes = {
    startAccountCreation: PropTypes.func.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    setEnableAheadOneStep: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { setEnableAheadOneStep } = this.props;
    setEnableAheadOneStep(false);
  }

  completeStepThree = async (values) => {
    const { selectedId, ineFront, ineBack, passFront, startAccountCreation } =
      this.props;
    const files = [];
    let dir = null;
    const options = {
      maxSizeMB: 2,
    };
    const frontFileName = 'front';
    const backFileName = 'back';
    if (selectedId === 'INE') {
      dir = 'INE';
      if (ineFront.size / 1048576 > 2) {
        const compressedFront = await imageCompression(ineFront, options);
        files.push({ file: compressedFront, name: frontFileName });
      } else {
        files.push({ file: ineFront, name: frontFileName });
      }
      if (ineBack.size / 1048576 > 2) {
        const compressedBack = await imageCompression(ineBack, options);
        files.push({ file: compressedBack, name: backFileName });
      } else {
        files.push({ file: ineBack, name: backFileName });
      }
    } else {
      dir = 'PAS';
      if (passFront.size / 1048576 > 2) {
        const compressedFront = await imageCompression(passFront, options);
        files.push({ file: compressedFront, name: frontFileName });
      } else {
        files.push({ file: passFront, name: frontFileName });
      }
    }
    startAccountCreation(values, files, dir);
  };

  idFormComplete = () => {
    const { ineFront, ineBack, passFront, selectedId } = this.props;
    if (
      (selectedId === 'INE' && ineFront && ineBack) ||
      (selectedId === 'PASSPORT' && passFront)
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { handleSubmit, selectedId, invalid, setActiveTab } = this.props;
    return (
      <Styled.IdentificationsContainer>
        <form onSubmit={handleSubmit(this.completeStepThree)}>
          <Styled.IdentificationsFormContainer>
            <Styled.VerifyData>{text.uploadPhoto}</Styled.VerifyData>
            <Styled.VerifyDataDescription>
              {text.findAPlace}
            </Styled.VerifyDataDescription>
            <Styled.WhiteBackgroundReminder>
              <Styled.LightbulbIcon src={lightbulb}></Styled.LightbulbIcon>
              <Styled.WhiteBackgroundReminderText>
                {text.whiteBackgroundReminder}
              </Styled.WhiteBackgroundReminderText>
            </Styled.WhiteBackgroundReminder>
            <Styled.IdentificationRadioLabel
              htmlFor="ineField"
              checked={selectedId === 'INE'}
            >
              <Field
                id="ineField"
                name="identification"
                component={renderRadio}
                type="radio"
                value="INE"
                validate={[required]}
              />
              {text.idCard}
            </Styled.IdentificationRadioLabel>
            {selectedId === 'INE' && <IdentificationsIne {...this.props} />}
            <Styled.IdentificationRadioLabel
              htmlFor="pasField"
              checked={selectedId === 'PASSPORT'}
            >
              <Field
                id="pasField"
                name="identification"
                component={renderRadio}
                type="radio"
                value="PASSPORT"
              />
              {text.passport}
            </Styled.IdentificationRadioLabel>
            {selectedId === 'PASSPORT' && (
              <IdentificationsPassport {...this.props} />
            )}
            <Field
              name="privacy"
              component={renderCheckbox}
              label={
                <span>
                  {text.readTerms1}
                  <a onClick={() => setActiveTab(6)}>
                    {' '}
                    Términos y Condiciones{' '}
                  </a>
                  {text.readTerms2}
                  <a onClick={() => setActiveTab(5)}> Aviso de Privacidad </a>
                  {text.readTerms3}
                </span>
              }
              validate={[required]}
              labelWithLinks={true}
              type="checkbox"
            />
            <Styled.SubmitButton
              disabled={invalid || !this.idFormComplete() || this.state.loading}
            >
              {text.createAccountButton}
            </Styled.SubmitButton>
            <Styled.AcceptContainer>
              <Styled.AcceptContainerImage src={candado} alt={'candado'} />
              <Styled.AcceptContainerText>
                {text.safeInformation}
              </Styled.AcceptContainerText>
            </Styled.AcceptContainer>
          </Styled.IdentificationsFormContainer>
        </form>
      </Styled.IdentificationsContainer>
    );
  }
}

const selector = formValueSelector(Forms.REGISTRATION_INFORMATION);

const mapStateToProps = (state) => {
  return {
    selectedId: selector(state, 'identification'),
    ineFront: selector(state, 'ineFront'),
    ineBack: selector(state, 'ineBack'),
    passFront: selector(state, 'passFront'),
  };
};

const ReduxIdentifications = reduxForm(
  {
    form: Forms.REGISTRATION_INFORMATION,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
  },
  mapStateToProps,
)(Identifications);

export default connect(mapStateToProps)(ReduxIdentifications);
