export const SET_PAYMENT_NOTIFICATION_STATE = 'SET_PAYMENT_NOTIFICATION_STATE';
export const SET_EMAIL_NOTIFICATION = 'SET_EMAIL_NOTIFICATION';
export const SET_INSTALLMENTS_NOTIFICATION_STATE =
  'SET_INSTALLMENTS_NOTIFICATION_STATE';
export const SET_HAS_INSTALLMENTS = 'SET_HAS_INSTALLMENTS';
export const SET_INSTALLMENTS_TERM = 'SET_INSTALLMENTS_TERM';
export const SET_INSTALLMENTS_PRICE_THRESHOLD =
  'SET_INSTALLMENTS_PRICE_THRESHOLD';
export const SET_INSTALLMENTS_SPINNER = 'SET_INSTALLMENTS_SPINNER';
export const SET_PAYMENT_SPINNER = 'SET_PAYMENT_SPINNER';
export const SET_POSTBACK_SPINNER = 'SET_POSTBACK_SPINNER';
export const SET_INSTALLMENTS_INITIAL_DATA = 'SET_INSTALLMENTS_INITIAL_DATA';
export const SET_PAYMENT_INITIAL_DATA = 'SET_PAYMENT_INITIAL_DATA';
export const SET_ENABLED_TIP = 'SET_ENABLED_TIP';
export const FETCH_TIPS_CONFIG = 'FETCH_TIPS_CONFIG';
export const SET_TIPS_STATE = 'SET_TIPS_STATE';
export const HANDLE_REDUX_TIPS_STATE = 'HANDLE_REDUX_TIPS_STATE';
export const HANDLE_TIPS_ROUNDED_STATE = 'HANDLE_TIPS_ROUNDED_STATE';
export const CANCEL_TIPS_EDITION = 'CANCEL_TIPS_EDITION';
export const CAN_MANAGE_TIPS = 'CAN_MANAGE_TIPS';
