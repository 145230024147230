/* eslint-disable  max-len */
export default {
  title: 'TÉRMINOS Y CONDICIONES',
  link: 'Volver a mi solicitud',
  content: {
    mot: `**TÉRMINOS Y CONDICIONES**

KEO World, S.A. de C.V. SOFOM E.N.R. (en adelante "KEO"), pone a disposición de los Usuarios o Clientes los presentes Términos y Condiciones del producto crediticio denominado "Adelanto de Ventas" (en adelante "Los Servicios"), el cual es ofrecido a Clientes de Payclip, S. de R.L. de C.V. (en adelante "Clip") y, conjuntamente (en adelante "Las Partes").

LOS PRESENTES TÉRMINOS Y CONDICIONES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE. CUALQUIER PERSONA QUE NO ACEPTE LOS PRESENTES TÉRMINOS Y CONDICIONES GENERALES DEBERÁ ABSTENERSE DE UTILIZAR LOS SERVICIOS.

Previo a realizar la inscripción como Usuario y/o Cliente (en adelante indistintamente el "Usuario" o el "Acreditado") de Adelanto de Ventas, el Usuario debe leer, entender y aceptar todas las condiciones establecidas en los presentes Términos y Condiciones, en el Aviso de Privacidad, en el Contrato de Crédito, así como en los demás documentos incorporados a los mismos por referencia (los "Documentos de Crédito").

**A.Capacidad.**

Los Servicios únicamente podrán ser utilizados por personas físicas y/o personas morales con capacidad legal para contratar y obligarse en los términos y condiciones establecidos en los Documentos de Crédito que pertenezcan a la cartera de clientes de Clip. Por lo   tanto,   las   personas   que   no   cuenten   con   ésta   capacidad,   no   podrán   tener   acceso   a   este   producto   crediticio.

**B.Información del Usuario.**

Para poder utilizar los Servicios, el Usuario deberá forzosamente completar, en todos sus campos, el formulario de inscripción con datos e información personal con validez legal, de manera precisa, verdadera y correcta (la "Información del Usuario"). El Usuario asumirá la responsabilidad de actualizar la Información del Usuario conforme sea necesario, en los plazos que KEO indique, de manera que la información continúe siendo válida y correcta en el tiempo.

KEO y Clip están facultados para utilizar diversos medios para solicitar, obtener y/o verificar, cuantas veces sea necesario, la Información del Usuario. Sin embargo, KEO y Clip no se responsabilizan por la veracidad de la Información del Usuario que los Usuarios le proporcionen. KEO y Clip estarán facultados, para solicitar en cualquier momento algún dato adicional y/o comprobante, en el caso que sea necesario corroborar todos o algunos de los datos de la Información del Usuario proporcionado. Asimismo, KEO se reserva el derecho de suspender temporal o definitivamente a cualquier Usuario, cuya Información del Usuario no pueda ser corroborada, o bien, cuya veracidad no pueda ser confirmada. En cualquier supuesto, el Usuario responderá y garantizará por la veracidad, vigencia, exactitud y autenticidad de la Información del Usuario que el mismo le proporcione a KEO y Clip.

**C.Otorgamiento de Crédito.**

KEO realizará la investigación de crédito correspondiente para definir la autorización o rechazo de la solicitud de crédito del Usuario. Esta investigación incluye de manera enunciativa y no limitativa la validación de los documentos de identificación proporcionados por el Usuario  y  consultar  en  alguna  de  las  Sociedades  de  Información  Crediticia  el  historial  crediticio  del  Usuario.  Posterior  a  esta consulta, KEO notificará el rechazo o autorización del otorgamiento del crédito.

En caso de ser aprobado, el Usuario deberá proceder a firmar el Contrato de Crédito. Dicho contrato se encuentra debidamente registrado en el Registro de Contratos de Adhesión (RECA) de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF) y el número de registro otorgado es el: 15092-439-032910/01-01184-0420

Finalmente, en lo que respecta al pago y demás condiciones del crédito se le informa al Usuario que el contrato mencionado en el párrafo anterior será el documento que establecerá la relación entre KEO y el Usuario.

**D.Modificaciones.**

KEO podrá modificar los Términos y Condiciones en cualquier momento y cuantas veces considere necesario, haciendo públicos en el Sitio los términos modificados.  Los términos modificados entrarán en vigor a los 10 (diez) días de su publicación en el Sitio.

En caso de que el Usuario se negase a aceptar las modificaciones a los presentes Términos y Condiciones, se entenderá por terminado el vínculo contractual. Lo anterior no afectara de ninguna manera la validez de las obligaciones de pago que, en su caso, tenga el Usuario con KEO de acuerdo con los Documentos de Crédito, por lo que las mismas seguirán vigentes hasta el pago total del Crédito, incluyendo sin limitar principal, intereses, comisiones y gastos.

**E.Confidencialidad.**

El uso indebido y la reproducción total o parcial de los contendidos en el Sitio y en los Documentos de Crédito quedan prohibidos, salvo autorización expresa y por escrito de KEO y/o Clip, los mismos están protegidos por las leyes nacionales y normas internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales.

**F.Indemnización.**

Las Partes acuerdan que el Usuario indemnizará y mantendrá en paz y a salvo a KEO y Clip, sus subsidiarias, directivos, funcionarios, administradores, representantes y empleados, por cualquier reclamo o demanda de Usuarios o terceros por sus actividades en el Sitio o por su incumplimiento los Términos y Condiciones y los Documentos de Crédito o por la violación de cualesquiera leyes o derechos de terceros.

Asimismo, el Usuario acepta en este acto que Clip es únicamente una solución de cobros, la cual permite la aceptación de pagos realizados por tarjetas de Crédito y/o Débito y su función en esta operación es la de ser un canal de comunicación entre los usuarios de su plataforma y KEO.

**G.Jurisdicción y Leyes Aplicables.**

Los presentes Términos y Condiciones y los Documentos de Crédito se rigen por lo dispuesto en dichos documentos y en su defecto por las disposiciones contenidas y que resulten aplicables en la Ley General de Organizaciones y Actividades Auxiliares del Crédito, la Ley General de Títulos y Operaciones de Crédito, la Ley para la Transparencia y Ordenamiento de los Servicios Financieros, la Disposición Única de la CONDUSEF aplicable a las Entidades Financieras, el Código Civil Federal y el Código de Comercio.

Para la interpretación y cumplimiento de los presentes Términos y Condiciones y los Documentos de Crédito, las Partes se someten de manera expresa a la jurisdicción de los Tribunales competentes de la Ciudad de México; renunciando a cualquier otra jurisdicción que por razón de su domicilio o cualquier otra causa les corresponda o pudiere corresponderles, ya sea en lo presente o futuro.

KEO World, S.A. de C.V. SOFOM E.N.R. (en adelante "KEO"), pone a disposición de los Usuarios o Clientes los presentes Términos y Condiciones del producto crediticio denominado "Adelanto de Ventas" (en adelante "Los Servicios"), el cual es ofrecido a Clientes de Payclip, S. de R.L. de C.V. (en adelante "Clip") y, conjuntamente (en adelante "Las Partes").

LOS PRESENTES TÉRMINOS Y CONDICIONES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE. CUALQUIER PERSONA QUE NO ACEPTE LOS PRESENTES TÉRMINOS Y CONDICIONES GENERALES DEBERÁ ABSTENERSE DE UTILIZAR LOS SERVICIOS.

Previo a realizar la inscripción como Usuario y/o Cliente (en adelante indistintamente el "Usuario" o el "Acreditado") de Adelanto de Ventas, el Usuario debe leer, entender y aceptar todas las condiciones establecidas en los presentes Términos y Condiciones, en el Aviso de Privacidad, en el Contrato de Crédito, así como en los demás documentos incorporados a los mismos por referencia (los "Documentos de Crédito").

1.  Capacidad.

Los Servicios únicamente podrán ser utilizados por personas físicas y/o personas morales con capacidad legal para contratar y obligarse en los términos y condiciones establecidos en los Documentos de Crédito que pertenezcan a la cartera de clientes de Clip. Por lo   tanto,   las   personas   que   no   cuenten   con   ésta   capacidad,   no   podrán   tener   acceso   a   este   producto   crediticio.

1.  Información del Usuario.

Para poder utilizar los Servicios, el Usuario deberá forzosamente completar, en todos sus campos, el formulario de inscripción con datos e información personal con validez legal, de manera precisa, verdadera y correcta (la "Información del Usuario"). El Usuario asumirá la responsabilidad de actualizar la Información del Usuario conforme sea necesario, en los plazos que KEO indique, de manera que la información continúe siendo válida y correcta en el tiempo.





KEO y Clip están facultados para utilizar diversos medios para solicitar, obtener y/o verificar, cuantas veces sea necesario, la Información del Usuario. Sin embargo, KEO y Clip no se responsabilizan por la veracidad de la Información del Usuario que los Usuarios le proporcionen. KEO y Clip estarán facultados, para solicitar en cualquier momento algún dato adicional y/o comprobante, en el caso que sea necesario corroborar todos o algunos de los datos de la Información del Usuario proporcionado. Asimismo, KEO se reserva el derecho de suspender temporal o definitivamente a cualquier Usuario, cuya Información del Usuario no pueda ser corroborada, o bien, cuya veracidad no pueda ser confirmada. En cualquier supuesto, el Usuario responderá y garantizará por la veracidad, vigencia, exactitud y autenticidad de la Información del Usuario que el mismo le proporcione a KEO y Clip.

1.  **Otorgamiento de Crédito.**

KEO realizará la investigación de crédito correspondiente para definir la autorización o rechazo de la solicitud de crédito del Usuario. Esta investigación incluye de manera enunciativa y no limitativa la validación de los documentos de identificación proporcionados por el Usuario  y  consultar  en  alguna  de  las  Sociedades  de  Información  Crediticia  el  historial  crediticio  del  Usuario.  Posterior  a  esta consulta, KEO notificará el rechazo o autorización del otorgamiento del crédito.

En caso de ser aprobado, el Usuario deberá proceder a firmar el Contrato de Crédito. Dicho contrato se encuentra debidamente registrado en el Registro de Contratos de Adhesión (RECA) de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF) y el número de registro otorgado es el: 15092-439-032910/01-01184-0420

Finalmente, en lo que respecta al pago y demás condiciones del crédito se le informa al Usuario que el contrato mencionado en el párrafo anterior será el documento que establecerá la relación entre KEO y el Usuario.

1.  Modificaciones.

KEO podrá modificar los Términos y Condiciones en cualquier momento y cuantas veces considere necesario, haciendo públicos en el Sitio los términos modificados. Los términos modificados entrarán en vigor a los 10 (diez) días de su publicación en el Sitio.

En caso de que el Usuario se negase a aceptar las modificaciones a los presentes Términos y Condiciones, se entenderá por terminado el vínculo contractual. Lo anterior no afectara de ninguna manera la validez de las obligaciones de pago que, en su caso, tenga el Usuario con KEO de acuerdo con los Documentos de Crédito, por lo que las mismas seguirán vigentes hasta el pago total del Crédito, incluyendo sin limitar principal, intereses, comisiones y gastos.

1.  Confidencialidad.

El uso indebido y la reproducción total o parcial de los contenidos en el Sitio y en los Documentos de Crédito quedan prohibidos, salvo autorización expresa y por escrito de KEO y/o Clip, los mismos están protegidos por las leyes nacionales y normas internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales.

1.  Indemnización.

Las Partes acuerdan que el Usuario indemnizará y mantendrá en paz y a salvo a KEO y Clip, sus subsidiarias, directivos, funcionarios, administradores, representantes y empleados, por cualquier reclamo o demanda de Usuarios o terceros por sus actividades en el Sitio o por su incumplimiento los Términos y Condiciones y los Documentos de Crédito o por la violación de cualesquiera leyes o derechos de terceros.

Asimismo, el Usuario acepta en este acto que Clip es únicamente una solución de cobros, la cual permite la aceptación de pagos realizados por tarjetas de Crédito y/o Débito y su función en esta operación es la de ser un canal de comunicación entre los usuarios de su plataforma y KEO.




1.  Jurisdicción y Leyes Aplicables.

Los presentes Términos y Condiciones y los Documentos de Crédito se rigen por lo dispuesto en dichos documentos y en su defecto por las disposiciones contenidas y que resulten aplicables en la Ley General de Organizaciones y Actividades Auxiliares del Crédito, la Ley General de Títulos y Operaciones de Crédito, la Ley para la Transparencia y Ordenamiento de los Servicios Financieros, la Disposición Única de la CONDUSEF aplicable a las Entidades Financieras, el Código Civil Federal y el Código de Comercio.

Para la interpretación y cumplimiento de los presentes Términos y Condiciones y los Documentos de Crédito, las Partes se someten de manera expresa a la jurisdicción de los Tribunales competentes de la Ciudad de México; renunciando a cualquier otra jurisdicción que por razón de su domicilio o cualquier otra causa les corresponda o pudiere corresponderles, ya sea en lo presente o futuro.

**CONTRATO DE CRÉDITO SIMPLE, (EN LO SUCESIVO, El "CONTRATO"), QUE CELEBRAN KEO WORLD, S.A. DE C.V. SOFOM, E.N.R., (EN LO SUCESIVO, "KEO"), Y LA PERSONA CUYOS DATOS CONSTAN EN LA CARÁTULA DE CRÉDITO (EN LO SUCESIVO "EL ACREDITADO"), Y CONJUNTAMENTE CON "KEO" COMO LAS "PARTES", DE CONFORMIDAD CON LAS SIGUIENTES:**

**DECLARACIONES**

I.- Declara **"KEO"** por conducto de su representante legal, que:

a).- Es una sociedad constituida y existente de conformidad con las leyes de los Estados Unidos Mexicanos, tal y como lo acredita mediante la Escritura Pública número 103,928 de fecha 6 de junio de 2018, otorgada ante la fe del Licenciado Javier Ceballos Lujambio, Notario Público número 110 de la Ciudad de México, misma que fue inscrita ante el Registro Público de Comercio de la Ciudad de México, con el Folio Electrónico Mercantil número N-2018057820.

b).- Es una Sociedad Financiera de Objeto Múltiple, Entidad No Regulada, que para su constitución y operación con tal carácter no requiere autorización de la Secretaría de Hacienda y Crédito Público, y está sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores únicamente para efectos de lo dispuesto por el artículo 56 de la Ley General de Organizaciones y Actividades Auxiliares del Crédito.

c).- Su representante cuenta con las facultades necesarias para celebrar el presente Contrato, y que las mismas no le han sido revocadas, modificadas ni limitadas en forma alguna lo que acredita con la Escritura Pública número 103,928 de fecha 6 de junio de 2018, otorgada ante la fe del Licenciado Javier Ceballos Lujambio, Notario Público número 110 de la Ciudad de México, misma que fue inscrita ante el Registro Público de Comercio de la Ciudad de México, con el Folio Electrónico Mercantil número N-2018057820.

d).- Su domicilio para efectos del presente Contrato se ubica en Avenida Ejército Nacional número 167, Piso 5, Colonia Verónica Anzures, C.P. 11300, Miguel Hidalgo, Ciudad de México.

e).- Cuenta con un registro vigente ante la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF).

f).- El Costo Anual Total (CAT) del presente Crédito es el que detalla en el Anexo A del presente Contrato y se tiene por aquí reproducido como si se insertase a la letra. **El CAT se encuentra calculado a la fecha de firma del presente Contrato en términos anuales. "****_CAT: El Costo Anual Total de financiamiento expresado en términos porcentuales anuales que, para fines informativos y de comparación, incorpora la totalidad de los costos y gastos inherentes a los Créditos._****"**

g).- Cuenta con un registro vigente ante la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF).

h).- El presente Contrato se encuentra registrado como un Contrato de Adhesión en el Registro de Contratos de Adhesión (RECA) de la CONDUSEF bajo el número: \[PENDIENTE\]

i).- Explicó e hizo saber a "EL ACREDITADO" que el texto íntegro de las disposiciones legales citadas en el presente Contrato puede ser consultado en la página www.gob.mx/condusef, dentro del apartado denominado RECA.

II.- Declara **"EL ACREDITADO"**, bajo protesta de decir verdad:

a).- Llamarse como ha quedado asentado en la Carátula de Crédito y que sus datos son ciertos.

b).- Que es su deseo el recibir de **"KEO"** el Crédito objeto del presente Contrato, así como que conoce y entiende perfectamente los términos y condiciones a los cuales quedará sujeto el mismo y las consecuencias en caso de incumplimiento.

c).- Que cuenta con los recursos económicos suficientes para dar cumplimiento a todas y cada una de las obligaciones, que a su cargo se derivan del presente Contrato.

d).- Que los recursos con los cuales ha de pagar el Crédito dispuesto han sido o serán obtenidos o generados a través de una fuente de origen lícito y que el destino que dará a los recursos obtenidos al amparo del presente Contrato serán destinados solo a fines permitidos por la ley, y que no se encuentran dentro de los supuestos establecidos en los artículos 139 Quáter y 400 Bis del Código Penal Federal.

e).- Que en términos de lo señalado por el artículo 87 – M de la Ley General de Organizaciones y Actividades Auxiliares del Crédito, **"KEO"** hizo de su conocimiento, previo a la celebración del presente Contrato: (i) el monto total del Crédito; (ii) el número y monto de los pagos parciales o individuales y la forma y periodicidad para liquidarlos; (iii) las cargas financieras; (iv) las comisiones aplicables; (v) monto y detalle de cualquier cargo, si lo hubiera; (vi) número de pagos a realizar, su periodicidad; (vii) el derecho que tiene a liquidar anticipadamente el presente Contrato y las condiciones para ello; (vii) los intereses y la forma de calcularlos, el monto de los intereses a pagar en cada período y el tipo de tasa de interés, y (ix) el CAT.

f).- En términos del artículo 87-J de la Ley General de Organizaciones y Actividades Auxiliares del Crédito, para su constitución y operación como Sociedad Financiera de Objeto Múltiple no requiere de autorización de la Secretaría de Hacienda y Crédito Público la ("SHCP") y está sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores (la "CNBV") únicamente para efectos de lo dispuesto por los artículos 56 y 95 Bis de la citada legislación, así como a la supervisión y regulación de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (la "CONDUSEF").

g).- Que con anterioridad a la celebración del presente Contrato y al llenado de la Solicitud de Crédito, **"KEO"** le explicó el tratamiento que le dará a sus datos personales mediante la entrega un Aviso de Privacidad, en términos de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, donde se señala, además del tratamiento que se le darán a sus datos personales, los derechos de acceso, rectificación, cancelación, oposición, revocación, limitación en el uso y/o divulgación con los que cuenta y la forma cómo los puede hacer valer.

Conformes las Partes con las declaraciones que anteceden, es su voluntad contraer las obligaciones que se derivan del presente instrumento, al contenido de las siguientes:

**CLÁUSULAS**

**PRIMERA. APERTURA DE CRÉDITO SIMPLE.- "KEO"** otorga a favor de **"EL ACREDITADO"** un Crédito simple hasta por el importe señalado en la Carátula del Crédito, en cuyo monto no quedan comprendidos los intereses y comisiones, que de conformidad con lo estipulado en el presente Contrato, **"EL ACREDITADO",** deba cubrir a **"KEO"** (en lo sucesivo el "**Crédito**"). **"EL ACREDITADO",** se obliga a destinar el importe del Crédito a cualquier fin lícito, teniendo pleno conocimiento de las penas y sanciones establecidas en los Artículos 139, 148 Bis o 400 Bis del Código Penal Federal, relativos a la prevención del lavado de dinero proveniente de actividades ilícitas y financiamiento al terrorismo.

Forma parte integrante del presente Contrato, el anexo siguiente:

**Anexo A.-** Carátula del Crédito,

A la firma del presente Contrato **"KEO"** entrega a **"EL ACREDITADO"** una tabla de amortizaciones respecto del Crédito otorgado. Posteriormente, la tabla de amortizaciones estará a disposición de **"EL ACREDITADO"** en la aplicación digital CLIP (la "**Tabla de Amortizaciones**").

**SEGUNDA. DISPOSICIÓN DEL CRÉDITO Y SUSCRIPCIÓN DE PAGARÉ.-** La entrega del importe del Crédito de parte de **"KEO"** a **"EL ACREDITADO"**, se realizará, a elección de **"KEO",** mediante cualquiera de las formas siguientes: (i) el depósito en la cuenta de **"EL ACREDITADO"** por el importe total o parcial del Crédito a favor de **"EL ACREDITADO"**, o (ii) por cualquier otra forma o medio de disposición que las partes convengan**.**

La disposición del Crédito será documentada mediante la suscripción de un pagaré por parte de **"EL ACREDITADO"**.

**TERCERA. PAGOS.-** Las Partes acuerdan lo siguiente:

**a).- Forma de pago.- "EL ACREDITADO"** se obliga a restituir a **"KEO"** el monto del Crédito dispuesto dentro del plazo señalado en el presente Contrato. Cada uno de los pagos que **"EL ACREDITADO"** deba efectuar a **"KEO"** para restituir el Crédito, deberán realizarse por los montos y con la periodicidad que se establece en el Anexo A y en la Tabla de Amortizaciones. Asimismo, **"KEO"** pone a disposición de **"EL ACREDITADO"**, en la aplicación digital CLIP la Tabla de Amortizaciones, en la cual **"EL ACREDITADO"** podrá consultar la periodicidad y las fechas límites de pago correspondientes al presente Crédito.

**"EL ACREDITADO"** efectuará los pagos sin necesidad de requerimiento previo: (i) mediante transferencia de fondos o deposito en efectivo a favor de **"KEO"**; o (ii) por cualquier otro medio de pago que convengan las Partes.

Cada uno de los pagos a que se refiere la presente Cláusula deberá realizarse por las cantidades y con la periodicidad que se establecen en la Carátula del Crédito, con independencia de los abonos a capital que de manera voluntaria realice **"EL ACREDITADO"**. Los intereses que se generen serán pagaderos conjuntamente con los abonos del monto principal del mismo y se calcularán de acuerdo con lo señalado en el presente instrumento y con la periodicidad que se determine en la Carátula del Crédito.

**b).- Pagos Anticipados.- "EL ACREDITADO"** podrá realizar pagos anticipados; los cuales serán aplicados por **"KEO"** al saldo insoluto del principal, siempre y cuando **"EL ACREDITADO"** esté al corriente en los pagos exigibles de conformidad con el presente Crédito.

Cualquier pago que **"KEO"** reciba de parte de **"EL ACREDITADO"** con antelación a la Fecha de Corte que corresponda será considerado como anticipado.

Cuando el importe de los pagos anticipados no sea suficiente para amortizar el saldo insoluto en su totalidad, **"KEO"** disminuirá el número de pagos señalados en el Anexo A y calculará el importe de intereses ordinarios por devengar, con base al nuevo saldo insoluto, lo cual se verá reflejado mediante la actualización de la Tabla de Amortización, la cual estará a disposición de **"EL ACREDITADO"** en la aplicación digital CLIP.

**c).- Días y horario de pago.- "EL ACREDITADO"** se obliga a restituir a **"KEO"** el monto del Crédito dentro del plazo señalado en el presente Contrato y conforme a los términos y condiciones pactadas entre las Partes, conforme al plazo señalado en el Anexo A.

Todos los pagos que por cualquier concepto **"EL ACREDITADO"** deba hacer a favor de **"KEO"** de conformidad con el presente Contrato, los hará libres y sin deducción o retención por cualquier concepto, a más tardar en los días señalados en la Carátula del Crédito, (i) mediante transferencia de fondos o depósito en efectivo a favor de **"KEO"**; o (ii) por cualquier otro medio de pago que convengan las Partes.

**d).- Pagos en días hábiles.-** Si el día en que **"EL ACREDITADO"** debiere realizar a favor de **"KEO"** cualquier pago derivado del presente Contrato no fuere día hábil, **"EL ACREDITADO"** deberá realizar el pago respectivo el día hábil inmediato posterior, en el entendido que la Fecha de Corte para el cálculo del pago principal y de los intereses ordinarios, será el día inhábil, siendo la fecha de pago el día hábil inmediato siguiente.

**e).- Obligaciones de pago.- "EL ACREDITADO"** se obliga, sin necesidad de previo requerimiento, a pagar a **"KEO"**, los cargos que se hagan a cuenta de **"EL ACREDITADO"** por:

1.  El importe dispuesto del Crédito;
2.  El importe de los intereses ordinarios que se causen de conformidad con lo pactado en el presente Contrato;
3.  El importe correspondiente a las comisiones establecidas en el presente Contrato;
4.  Los cargos que procedan por concepto de impuestos que sean a su cargo y deriven del presente Contrato conforme a las leyes aplicables, y
5.  En caso de que aplique y sea procedente, todos y cada uno de los gastos y costas con motivo del juicio derivado de la demanda en su contra por el incumplimiento de sus obligaciones conforme al presente Contrato.

**g).- Orden de Pagos.-** Todas las cantidades que de parte de **"EL ACREDITADO"** reciba en pago **"KEO"**, se aplicarán conforme al orden siguiente, cubriendo primero, en su caso, los pagos más atrasados: (i) gastos y costas de juicio, si así lo determina un juez competente; (ii) Impuesto al Valor Agregado sobre la comisión por gastos de cobranza, si se causa; (iii) la comisión por gastos de cobranza, en caso de ser aplicable; (iv) Impuesto al Valor Agregado sobre intereses ordinarios, si se causa; (v) intereses ordinarios; (vi) capital vencido; y (vii) capital vigente.

**CUARTA. COMISIONES.-** Sin perjuicio de las demás obligaciones a cargo de **"EL ACREDITADO"** en términos del presente Contrato, éste se obliga a pagar a favor de **"KEO"** las comisiones previstas en la presente cláusula.

1.  **a) COMISIÓN POR GASTOS DE COBRANZA-** Para efectos del presente Contrato se entenderá que la comisión por gastos de cobranza será la que se genere cada vez que **"EL ACREDITADO"** incumpla sus obligaciones de pago conforme al presente Contrato. Los gastos de cobranza que en su caso se generen deberán ser cubiertos por **"EL ACREDITADO"** al momento en que incurra en mora, gastos que deberán cubrirse junto con los pagos vencidos no cubiertos. Las partes acuerdan que **"KEO"** tendrá derecho a cobrarle a **"EL ACREDITADO"** la comisión por gastos de cobranza, cada vez que **"EL ACREDITADO"** incurra en mora en cualquiera de los pagos que deba realizar de conformidad con el presente Contrato, es decir por cada pago vencido que tenga **"EL ACREDITADO"**. Para calcular la comisión por gastos de cobranza por cada pago vencido, **"KEO"** multiplicará la Unidad de Medida y Actualización, por el número de días de atraso del pago y ese resultado lo multiplicará por el porcentaje establecido en la Carátula del Crédito, más el Impuesto al Valor Agregado correspondiente.

**QUINTA.- INTERESES ORDINARIOS.-** La tasa de interés ordinaria es fija y está expresada en términos porcentuales y anuales con relación al crédito contratado mediante el presente instrumento. El monto de la tasa de interés ordinaria es el expresado y pactado en la Carátula del Crédito.

**a).- Metodología para el cálculo de intereses ordinarios.- "EL ACREDITADO"** se obliga a pagar a **"KEO"** durante la vigencia del presente Contrato intereses ordinarios, mismos que comenzarán a generarse después de 30 (treinta) días naturales a partir de la fecha de disposición del Crédito. Los intereses ordinarios se calcularán conforme a la tasa y con base en la Fecha de Corte pactados con **"EL ACREDITADO"** en la Carátula del Crédito.

Para efectos del presente Contrato, se entenderá por "Fecha de Corte" a la fecha en la cual culmine el lapso de 30 (treinta) días naturales, de conformidad con lo señalado en la Carátula del Crédito, a partir de la cual se calcularán los intereses ordinarios que se causen derivados del presente Crédito. La Fecha de Corte podrá ser consultada por **"EL ACREDITADO"** en la Carátula del Crédito y en los estados de cuenta.

**"KEO"** quedará facultada para calcular los intereses ordinarios que se causen sobre la suerte del principal, multiplicando el saldo insoluto a la Fecha de Corte, por la tasa de interés ordinaria reflejada en la Carátula del Crédito, más el Impuesto al Valor Agregado correspondiente. El monto por concepto de intereses ordinarios a pagar por cada Fecha de Corte se obtendrá multiplicando el saldo insoluto del Crédito por la tasa de interés anual, dividida entre 360 (trescientos sesenta), multiplicada por el número de días efectivamente transcurridos dentro de cada Fecha de Corte. Los intereses que se generen, serán pagaderos conjuntamente con los abonos del monto principal del Crédito conforme a la periodicidad dispuesta en la Carátula del Crédito.

La tasa de interés ordinaria determinada según lo dispuesto en esta cláusula se aplicará durante cada Fecha de Corte. La primer Fecha de Corte comenzará a los 30 (treinta) días naturales a partir de la fecha de disposición del crédito y las Fechas de Corte subsecuentes comenzarán a partir del primer día inmediatamente posterior al último día de la Fecha de Corte inmediata anterior y así sucesivamente hasta la liquidación total del Crédito conforme a lo establecido para tal efecto en el presente Contrato.

**b).- Cobro del interés de manera anticipada.- "KEO"** no cobrará el interés del Crédito previsto en el presente Contrato, de manera anticipada, sino únicamente por periodos vencidos, de acuerdo con cada Fecha de Corte y según los términos y condiciones establecidos en la Carátula del Crédito.

**SEXTA. VIGENCIA DEL CONTRATO.-** El presente Contrato tendrá la vigencia establecida en la Carátula del Crédito, contada a partir de la fecha de firma del presente Contrato; vigencia que se prorrogará automática y sucesivamente por períodos iguales, sin necesidad de que las Partes tengan que suscribir documento alguno para acordar dichas prórrogas. En caso de que alguna de las Partes manifieste su intención de no renovar el Contrato, deberá hacerlo por escrito a la otra Parte, con acuse de recibo. En caso de que sea **"EL ACREDITADO"** el que no desee renovar el presente Contrato, éste deberá encontrarse al corriente en el cumplimiento de sus obligaciones, y en especial la de pago, ya que de lo contrario el Contrato continuará vigente hasta en tanto **"EL ACREDITADO"** liquide la totalidad de los montos adeudados a **"KEO"**. En caso de que por cualquier causa este Contrato se dé por vencido anticipadamente, las Partes acuerdan que **"KEO"** podrá dar por vencido anticipadamente el pagaré que documente la disposición del Crédito.

**SÉPTIMA. AUTORIZACIÓN PARA SOLICITAR INFORMACIÓN CREDITICIA.-** Por medio del presente contrato, **"EL ACREDITADO"** otorga su autorización de manera expresa para que **"KEO"** solicite su información crediticia; dicha autorización consta en la Solicitud de Crédito que **"EL ACREDITADO"** firmó para el otorgamiento del presente Crédito.

**OCTAVA. MODIFICACIÓN AL CONTRATO.- "EL ACREDITADO"** otorga su consentimiento para que **"KEO"** pueda modificar el presente Contrato, así como cualquiera de los Anexos que forman parte del mismo, notificando a **"EL ACREDITADO"** las modificaciones que se pretendan llevar a cabo con 30 (treinta) días naturales de anticipación a la entrada en vigor de dichas modificaciones, lo cual lo hará saber en el estado de cuenta disponible vía Internet, indicando la fecha en la que entrarán en vigor dichas modificaciones.

En caso de que **"EL ACREDITADO"** no esté de acuerdo con las modificaciones notificadas por **"KEO"**, podrá solicitar la terminación del presente Contrato, en un plazo que no podrá exceder de 30 (treinta) días naturales posteriores a la notificación señalada en el párrafo anterior, sin que por ello incurra en alguna responsabilidad o se generen comisiones a su cargo, debiendo en todo caso **"EL ACREDITADO"** cubrir el saldo insoluto del Crédito, así como los intereses y comisiones señalados en las cláusulas cuarta y quinta del presente Contrato que se hubieren generado a la fecha en la que se haya presentado la solicitud de terminación y se encuentren pendientes al momento de la misma.

Una vez transcurrido el plazo señalado en el párrafo inmediato anterior, sin que **"KEO"** haya recibido comunicación alguna por parte de **"EL ACREDITADO"**, se tendrán por aceptadas las modificaciones del Contrato.

Durante la vigencia del Contrato, **"KEO"** no podrá: (i) modificar la tasa de interés señalada en la cláusula quinta, ni (ii) establecer nuevas comisiones o incrementar su monto, salvo en caso de que se reestructure el presente Contrato y con el previo consentimiento de **"EL ACREDITADO"**.

**NOVENA. SOLICITUDES, CONSULTAS, ACLARACIONES, INCONFORMIDADES Y QUEJAS.-**

**a).- Servicio de atención al público en consulta y aclaraciones.-**. Para consultar saldos, transacciones o movimientos, así como para presentar cualquier solicitud, consulta y aclaración relacionadas con el Crédito contratado, **"EL ACREDITADO"** tendrá a su disposición la línea telefónica 01 800 9530767 y correo electrónico [prestamo@keoworld.com](mailto:prestamo@keoworld.com).

En caso de consultas, aclaraciones o reclamaciones, **"EL ACREDITADO"** deberá dirigirse a la Unidad Especializada de **"KEO"**, cuyos datos se señalan en el inciso b) siguiente.

En términos del artículo 23 de la Ley para la Transparencia y Ordenamiento de los Servicios Financieros, si **"EL ACREDITADO"** no está de acuerdo con alguno de los movimientos que aparezcan en su estado de cuenta o en la página de internet de **"KEO"**, podrá presentar una solicitud de aclaración dentro del plazo de 90 (noventa) días naturales contados a partir de la Fecha de Corte o, en su caso, de la realización de la operación. La solicitud respectiva deberá presentarse en la Unidad Especializada de **"KEO"**, mediante escrito, correo electrónico o cualquier otro medio por el que se pueda comprobar fehacientemente su recepción. **"KEO"** estará obligado a acusar recibo de dicha solicitud.

**"EL ACREDITADO"** tendrá derecho de no realizar el pago cuya aclaración solicita, así como el de cualquier otra cantidad relacionada con dicho pago, hasta en tanto se resuelva la aclaración conforme al procedimiento a que se señala a continuación:

Una vez que **"KEO"** reciba la solicitud de aclaración, tendrá un plazo máximo de 45 (cuarenta y cinco) días naturales contados a partir de la recepción de la solicitud, para entregar a **"EL ACREDITADO"** el dictamen correspondiente suscrito por personal facultado por **"KEO"** para su realización, así como un informe detallado en el que se respondan todos los hechos contenidos en la solicitud presentada por **"EL ACREDITADO"**.

**"KEO"** formulará el dictamen e informe referidos en el párrafo anterior por escrito. En el evento de que, conforme al dictamen que emita **"KEO"**, resulte procedente el cobro del monto respectivo, **"EL ACREDITADO"** deberá hacer el pago de la cantidad a su cargo, incluyendo los intereses ordinarios conforme a lo pactado, sin que proceda el cobro de otros accesorios generados por la suspensión del pago realizada conforme al presente procedimiento.

Dentro del plazo de 45 (cuarenta y cinco) días naturales contados a partir de la entrega del dictamen, **"KEO"** estará obligada a poner a disposición de **"EL ACREDITADO"** en las oficinas de **"KEO"**, el expediente generado con motivo de la solicitud de aclaración en la que se incorpore la documentación e información que se relaciona directamente con la solicitud correspondiente.

Lo anterior sin perjuicio del derecho de **"EL ACREDITADO"** de acudir ante la CONDUSEF o ante la autoridad jurisdiccional correspondiente conforme a las disposiciones legales aplicables.

**b).- Unidad Especializada.- "KEO"** cuenta con una Unidad Especializada, la cual se localiza en Avenida Ejército Nacional número 167, Piso 5, Colonia Verónica Anzures, C.P. 11300, Miguel Hidalgo, Ciudad de México, con número telefónico: (55) 2158-5224 y correo electrónico: [prestamo@keoworld.com](mailto:prestamo@keoworld.com).

**c).- Atención CONDUSEF.- "EL ACREDITADO"** podrá requerir atención de la CONDUSEF en la dirección de internet www.gob.mx/condusef, ó en su correo electrónico [asesoria@condusef.gob.mx](mailto:asesoria@condusef.gob.mx) ó en su teléfono: 01 800 999-8080.

**d).- Consultas.-** El presente Contrato se encuentra a disposición de **"EL ACREDITADO"** en las oficinas, será enviado a su correo electrónico y en la apicación digital CLIP así como en la página de la CONDUSEF con dirección [www.gob.mx/condusef](http://www.gob.mx/condusef) en el apartado del RECA.

**DÉCIMA. CAUSAS DE VENCIMIENTO ANTICIPADO.-****a).- "EL ACREDITADO"** manifiesta su consentimiento para que **"KEO"** pueda dar por vencido anticipadamente el presente Contrato, para lo cual bastará que de aviso por escrito a **"EL ACREDITADO"** con 30 (treinta) días naturales a la fecha efectiva de la terminación; sin más trámite que el aviso descrito y sin necesidad de declaración judicial alguna.

Una vez declarado el vencimiento anticipado del plazo al cual quedó sujeto el presente Contrato, se volverá exigible de inmediato el pago del saldo insoluto total, incluyendo los intereses y comisiones señalados en las cláusulas cuarta y quinta del presente Contrato que se hubieren derivado del importe del Crédito otorgado, que hubiera a cargo de **"EL ACREDITADO"** y en favor de **"KEO"**, por lo que **"EL ACREDITADO"** sin necesidad de requerimiento judicial o extrajudicial alguno, deberá cubrir de inmediato a **"KEO"** los montos que estuvieren pendientes de pago a dicha fecha.

**b).- "EL ACREDITADO"** podrá solicitar, en todo momento, la terminación anticipada del presente Contrato, debiendo cubrir el monto total del adeudo, incluyendo todos los intereses y comisiones señalados en las cláusulas cuarta y quinta del presente Contrato que éste hubiera generado a la fecha en que se solicite la terminación, bastando para ello la presentación de una solicitud por escrito con firma autógrafa en el domicilio de **"KEO"**, o bien, por teléfono. Para tal efecto **"KEO"** informará a **"EL ACREDITADO"** el monto insoluto del Crédito a fin de que éste pueda cubrir el monto total del adeudo, intereses y comisiones señalados en las cláusulas cuarta y quinta del presente Contrato que **"EL ACREDITADO"** hubiere generado a la fecha en que se solicite la terminación. En caso que no exista saldo pendiente, **"KEO"** deberá entregar acuse de recibo de terminación y el número de folio.

En caso de que **"EL ACREDITADO"** solicite la terminación anticipada del Contrato y no tenga adeudos pendientes a dicha fecha, **"KEO"** dará por terminado el presente Contrato el día hábil siguiente a la fecha en que haya recibido la solicitud de terminación anticipada. En caso de que existan adeudos pendientes al momento de recibir la solicitud de terminación anticipada, **"KEO"**, a más tardar el día hábil siguiente al de la recepción de la solicitud, comunicará a **"EL ACREDITADO"** el importe de los adeudos y pondrá a su disposición dicho dato dentro de los 5 (cinco) días hábiles siguientes a la fecha de recepción de la solicitud de terminación correspondiente, en la sucursal elegida por **"EL ACREDITADO"**, y una vez éste liquide los adeudos, se dará por terminado el Contrato.

**"KEO"** pondrá a disposición de **"EL ACREDITADO"**, dentro de los 10 (diez) días hábiles posteriores a que se realice el pago de los adeudos, el estado de cuenta en el que conste el fin de la relación contractual y la inexistencia de adeudos derivados exclusivamente del presente Contrato

Una vez recibido el aviso de terminación, **"KEO"** rechazará cualquier medio de disposición vinculado a su Contrato, que se pretenda efectuar con posterioridad a la fecha de presentación de la solicitud de cancelación, con excepción de los intereses generados hasta la fecha en la que se haya presentado la solicitud de dar por terminado el Contrato

En tanto no sea liquidada la totalidad de los adeudos, la terminación del presente Contrato no surtirá efectos; sin embargo, **"KEO"** no podrá efectuar cargos adicionales, con excepción de los cargos ya generados, pero no reflejados, la Comisión anual generada y comisiones por incumplimiento de pago que correspondan, en su caso, así como los intereses que se generen hasta el momento en que **"EL ACREDITADO"** liquide el saldo total del Crédito. **"EL ACREDITADO"** conservará la misma fecha de pago y condiciones que tenía previamente a la solicitud de terminación.

**c).- "EL ACREDITADO"** contará con un periodo de 10 (diez) días hábiles posteriores a la firma del presente Contrato, para cancelarlo, siempre y cuando no haya dispuesto, operado o utilizado el Crédito contratado, sin que se genere ninguna responsabilidad o costo adicional para **"EL ACREDITADO"**.

**DÉCIMA PRIMERA. RESCISIÓN.-** Las Partes acuerdan que el incumplimiento por parte de **"EL ACREDITADO"** a sus obligaciones señaladas en el presente Contrato, en especial la de pago, dará derecho a **"KEO"** para proceder a su inmediata rescisión; para lo cual bastará que **"KEO"** de aviso por escrito a **"EL ACREDITADO"** con 30 (treinta) días naturales de anticipación a la fecha efectiva en que la rescisión surta sus efectos, sin más trámite que el aviso descrito y sin necesidad de declaración judicial.

**"KEO"** podrá rescindir el presente Contrato en los términos señalados anteriormente o cuando concurran cualesquiera de los siguientes supuestos de manera conjunta o separada:

a).- Si **"EL ACREDITADO"** incumple cualesquiera de sus obligaciones previstas o derivadas del presente Contrato, incluyendo sus anexos; en especial, su obligación de pago.

b).- En caso de que **"EL ACREDITADO"** incumpliere con cualquiera de las obligaciones que a su cargo se derivasen de cualesquier otro contrato de apertura de crédito que tuviere celebrado con **"KEO"**.

Una vez que surta efectos la rescisión del Contrato, **"EL ACREDITADO"**, sin necesidad de declaración judicial o extrajudicial alguna, deberá cubrir de inmediato a **"KEO"** el saldo del Crédito que estuviere pendiente de pago a dicha fecha más los intereses correspondientes y las cantidades que deriven de cualquier otro concepto adeudado a favor de **"KEO"**.

**DÉCIMA SEGUNDA. ESTADOS DE CUENTA.- "EL ACREDITADO"** instruye expresamente a **"KEO"** para que éste ponga a su disposición su estado de cuenta a través de la página de la aplicación digital CLIP, cada vez que **"EL ACREDITADO"** ingrese a dicha página. Asimismo, y como servicio complementario, **"KEO"** podrá imprimir el estado de cuenta, si **"EL ACREDITADO"** lo solicita en sus oficinas y dentro de los horarios de atención establecidos por **"KEO"**. Por lo anterior, **"EL ACREDITADO"** manifiesta su conformidad para que **"KEO"** no envíe el estado de cuenta a su domicilio.

No obstante lo anterior, en caso de que **"EL ACREDITADO"** no solicite su estado de cuenta **"KEO"** lo generará mensualmente para su consulta y estará a disposición de **"EL ACREDITADO"** conforme a lo señalado en el párrafo anterior.

En el estado de cuenta se indicará, entre otros aspectos: (i) nombre de **"EL ACREDITADO"**; (ii) número de Contrato; (iii) las cantidades cargadas y abonadas; (iv) el saldo insoluto del Crédito; (v) las Fechas de Corte; (vi) los datos necesarios para realizar el cálculo de los intereses a pagar; así como (vii) cualquier otro movimiento, que se lleve a cabo con respecto al Crédito otorgado.

**"EL ACREDITADO"** podrá objetar el estado de cuenta, dentro de los 90 (noventa) días naturales contados a partir de que el estado de cuenta quedó a su disposición, una vez que lo requirió en una ventanilla de la sucursal.

El estado de cuenta certificado por el contador público autorizado para tal efecto por **"KEO"** hará fe, salvo prueba en contrario, en el juicio respectivo para la fijación del saldo resultante a cargo del deudor, en términos del artículo 87-E de la Ley General de Organizaciones y Actividades Auxiliares del Crédito.

En el supuesto que **"EL ACREDITADO"** no solicite la expedición del estado de cuenta, no quedará por este hecho eximido de efectuar el pago del monto del Crédito y los intereses y comisiones señalados en las cláusulas cuarta y quinta del presente Contrato que se causen.

**DÉCIMA TERCERA. DOMICILIOS.-** Para todos los efectos del presente Contrato, **"KEO"** señala como su domicilio el previsto en la declaración I inciso d) del presente Contrato y **"EL ACREDITADO"** señala como su domicilio el señalado en la Solicitud de Crédito.

**"EL ACREDITADO"** deberá informar por escrito a **"KEO"** del cambio de su domicilio, con cuando menos 10 (diez) días hábiles de anticipación. En caso de no hacerlo, todos los avisos, notificaciones y demás diligencias extrajudiciales o judiciales que se lleven a cabo en los domicilios indicados, se entenderán plenamente válidos y surtirán todos los efectos legales a que hubiere lugar.

**DÉCIMA CUARTA. CESIÓN Y TRASPASO DE LOS DERECHOS DEL PRESENTE CONTRATO.- "KEO"** queda expresamente autorizado por **"EL ACREDITADO"** para ceder, traspasar, endosar, otorgar en garantía o en cualquier otra forma negociar todos o parte de los derechos que a su favor se derivan del presente Contrato y del o los pagarés suscritos por **"EL ACREDITADO"**, aún antes de su respectivo vencimiento.

Las Partes convienen que **"EL ACREDITADO"** no podrá ceder o transmitir en cualquier forma, los derechos y obligaciones que respectivamente se generen a su favor y a su cargo del presente Contrato.

Si **"EL ACREDITADO"** violare en cualquier forma la prohibición a que se refiere el párrafo anterior, será causa de rescisión del presente Contrato.

**DÉCIMA QUINTA. AVISOS Y NOTIFICACIONES.-** Salvo que alguna cláusula del presente Contrato señale un procedimiento específico para hacer los avisos y/o notificaciones, como son los que se hacen a través de los estados de cuenta; todos los demás avisos y notificaciones que deban hacerse las Partes, se entenderán hechas cuando los mismos se realicen mediante medios electrónicos, por correo o por escrito, en las direcciones electrónicas o los domicilios de cada una de las Partes.

Mientras las Partes no notifiquen por escrito un cambio de domicilio, los avisos, notificaciones y demás diligencias judiciales y/o extrajudiciales que se hagan en los domicilios indicados, surtirán plenamente sus efectos.

**"KEO"**, de manera gratuita, informará a **"EL ACREDITADO"**, a través de los estados de cuenta o por cualquier otro medio de comunicación que opte **"KEO"**, cómo evitar fraudes respecto del crédito otorgado y la contratación, en su caso, de seguros opcionales.

**DÉCIMA SEXTA. PUBLICIDAD.- "EL ACREDITADO"** autoriza expresamente a **"KEO"** para contactarlo directamente o por vía telefónica en su lugar de trabajo, en un horario de las 9:00 a las 19:00 horas, o bien, para que le envíe a su domicilio o lugar de trabajo publicidad relativa a productos o servicios que **"KEO"** ofrece al público en general; asimismo, autoriza a **"KEO"** para que utilice sus datos personales para fines publicitarios o de mercadotecnia, sin perjuicio de la oposición que pueda llevar a cabo **"EL ACREDITADO"** para que su información pueda ser utilizada para los fines antes enunciados, en el entendido que una vez que **"EL ACREDITADO"** manifieste su oposición en las oficinas de **"KEO"** y ésta se haga constar en el Registro Público de Usuarios de la CONDUSEF, **"KEO"** no podrá volver a utilizarla para los fines previstos en la presente cláusula.

**DÉCIMA SÉPTIMA. PROTECCIÓN DE DATOS PERSONALES.-** Conforme a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP), **"KEO"**, previamente a la celebración del presente Contrato, solicitó datos personales de **"EL ACREDITADO"** con la finalidad de: (i) identificarlo, consultar y verificar la información proporcionada, incluyendo referencias personales (ii) poder celebrar el presente Contrato, (iii) realizar requerimientos de pago, (iv) ceder o enajenar los derechos que a su favor se deriven del presente Contrato, (v) otorgarle información relacionada con el presente Contrato, (vi) ofrecerle los diferentes productos y/o servicios otorgados directamente por **"KEO"** o indirectamente a través de la asociación de **"KEO"** con empresas que ofrecen productos y/o servicios similares o adicionales en colaboración con **"KEO"**, (vii) contactarlo con la finalidad de mantener actualizados sus datos personales, mientras se encuentre vigente la relación jurídica y dentro de los 10 (diez) años posteriores que termine la relación jurídica, en caso de que sea necesario actualizar algún dato personal en términos del artículo 95 de la Ley General de Organizaciones y Actividades Auxiliares del Crédito, y (viii) para fines mercadotécnicos, publicitarios y de prospección comercial relacionados a **"KEO"**.

**"KEO"** protegerá y mantendrá los datos personales de **"EL ACREDITADO"** por el tiempo razonablemente necesario, tomando en cuenta las disposiciones legales aplicables y sólo compartirá y/o transferirá dicha información con otra(s) entidad(es): (i) cuando **"EL ACREDITADO"** contrate otro producto y/o servicio a través de **"KEO"**; (ii) cuando **"KEO"** ceda y/o enajene la cartera de Créditos; (iii) para hacer requerimientos de pago a través de despachos de cobranza; (iv) para la prestación de servicios; (v) cuando **"KEO"** otorgue en garantía los derechos que a su favor se deriven en el presente Contrato; (vi) para consulta y verificación de los datos e información otorgada por **"EL ACREDITADO"**; (vii) para fines mercadotécnicos, publicitarios y de prospección comercial, (viii) cuando así se requiera por disposición legal o por orden de autoridad competente debidamente fundada y motivada, y (ix) en los supuestos señalados en el artículo 37 de la LFPDPPP.

**"EL ACREDITADO"** podrá ejercer en todo momento ante **"KEO"**, sus derechos de acceso, rectificación, cancelación, oposición, revocación, limitación en el uso y/o divulgación ("derechos ARCO") en el tratamiento de sus datos personales, en caso de que legalmente sea procedente, conforme a los lineamientos y requisitos que marca la LFPDPPP y demás disposiciones aplicables, presentándose en las oficinas de **"KEO"**.

**"EL ACREDITADO"** hace constar que con anterioridad a la celebración del presente Contrato, **"KEO"** puso a su disposición un Aviso de Privacidad, donde se le informó, entre otras cosas, sobre: (i) la identidad y domicilio de **"KEO"**; (ii) los datos personales que serán sometidos a tratamiento; (iii) las finalidades del tratamiento; (iv) los mecanismos para que **"EL ACREDITADO"** pueda manifestar su negativa para el tratamiento de sus datos personales para aquellas finalidades que no son necesarias, ni hayan dado origen a la relación jurídica con **"KEO"**; (v) las transferencias de datos personales que, en su caso, se efectúen a un tercero receptor de los datos personales, y las finalidades de las mismas; (vi) la cláusula que indique si **"EL ACREDITADO"** acepta o no la transferencia, cuando así se requiera; (vii) los medios y el procedimiento para ejercer los derechos ARCO, de revocación, limitación en el uso y/o divulgación; (viii) los mecanismos y procedimientos para que, en su caso, **"EL ACREDITADO"** pueda revocar su consentimiento al tratamiento de sus datos personales; (ix) las opciones y medios que **"KEO"** ofrece a **"EL ACREDITADO"** para limitar el uso o divulgación de los datos personales, y (x) los procedimientos y medios a través de los cuales **"KEO"** comunicará a **"EL ACREDITADO"** los cambios al Aviso de Privacidad.

Por último, señala **"KEO"** que el Aviso de Privacidad puede ser consultado a través de su sitio en internet [https://keomexico.com/](https://keomexico.com/) y de igual forma cualquier cambio y/o modificación total o parcial al Aviso de Privacidad se dará a conocer por medio de la mencionada página web y/o directamente en las oficinas de **"KEO"**.

**DÉCIMA OCTAVA**. **CASO FORTUITO O FUERZA MAYOR**.- **"EL ACREDITADO"** se obliga al cumplimiento del presente contrato aún en caso fortuito o de fuerza mayor en términos del artículo 2111 (dos mil ciento once) del Código Civil Federal.

**DÉCIMA NOVENA. JURISDICCIÓN Y COMPETENCIA.-** Para todo lo que se refiere a la interpretación y cumplimiento del presente Contrato, las Partes se someten a la jurisdicción y competencia de los tribunales del domicilio de **"EL ACREDITADO"** o de los competentes en la Ciudad de México, así como a las leyes vigentes y aplicables en dichos lugares, a elección de **"KEO"**, renunciando a cualquier otro fuero que por razón de sus domicilios presentes o futuros o por cualquier otra causa pudiera corresponderles.

Acepto, he leído y entendido lo contenido en el presente contrato de crédito simple.`,

    mrp: `**Términos y condiciones de uso para la Solicitud de Crédito "Adelanto de Ventas Clip"**  

Por este medio se le informa a los Usuarios los Términos y Condiciones de Uso para la Solicitud de Crédito Adelanto de Ventas Clip (los "Términos y Condiciones") que les serán aplicables por el simple uso o acceso que haga el Usuario al Ecosistema de PayClip mediante su ingreso y registro en las Aplicaciones, Sitios Web y/o Plataformas de PayClip para la solicitud de Adelanto de Ventas Clip. Los Términos y Condiciones serán aceptados por los Usuarios tanto de forma tácita por el simple uso o acceso antes referido, así como de forma expresa, a través de las manifestaciones de voluntad que realicen los Usuarios de tiempo en tiempo, por lo que manifiestan que han leído y entendido los presentes Términos y Condiciones y dan su consentimiento para obligarse en cumplimiento a los mismos.  
      
El Usuario deberá tener en cuenta que los productos de crédito que se muestran en la Aplicación, son ofrecidos por Entidades Financieras que mantienen una alianza vigente con PayClip, por lo que, en todo lo relacionado con las condiciones del crédito deberán sujetarse a las condiciones y obligaciones adicionales que cada una de las Entidades Financieras estipule en el contrato que ampara el otorgamiento de un crédito.  
      
Cualquier Usuario que no acepte estos Términos y Condiciones, así como el Aviso de Privacidad de PayClip, los cuales tienen un carácter obligatorio y vinculante deberán abstenerse de utilizar los servicios de Adelanto de Ventas Clip, sus Aplicaciones, Sitios Web y/o Plataformas.  
      
      
**1\. Definiciones.**  
      
**"Aplicaciones"** son aquellas creadas o desarrolladas por PayClip, o de las cuales tiene los derechos para su uso y explotación, para efectos de la prestación del servicio Adelanto de Ventas Clip.  
      
**"Datos de Registro"** significa la información que es proporcionada por el Usuario a través de las Aplicaciones, Sitios Web y/o Plataformas para acceder a Adelanto de Ventas Clip.  
      
**"Ecosistema PayClip"** significa el conjunto de Aplicaciones, Sitios Web y/o Plataformas de PayClip y/o de las Entidades Financieras relacionadas entre sí para la prestación de los servicios Adelanto de Ventas Clip en favor del Usuario.  
      
**"Entidades Financieras"** son aquellas personas morales cuya actividad habitual y profesional consiste en el otorgamiento de crédito y las cuales mantienen una relación comercial y de negocios con PayClip.  
      
**"Firma Electrónica"** Los datos en forma electrónica consignados en un mensaje de datos, o adjuntados o asociados a éste, que identifican al Usuario como firmante en relación con el mensaje de datos.  
      
**"PayClip"** significa PayClip, S. de R.L. de C.V., así como sus filiales, subsidiarias y/o controladoras.  
      
**"Plataformas"** son aquellas desarrolladas por PayClip y las Entidades Financieras que permiten al Usuario, de manera virtual, la ejecución de sistemas para acceder a los servicios de Adelanto de Ventas Clip a través de Internet.  
      
**"Adelanto de Ventas Clip"** significa el otorgamiento de un producto de crédito bajo cualquier modalidad en términos de lo previsto por la Ley General de Títulos y Operaciones de Créditos, por parte de las Entidades Financieras en favor de los Usuarios, previo análisis cualitativo y cuantitativo para determinar su perfil de riesgo.  
      
**"Sitios Web"** son las páginas web creadas y administradas por PayClip y/o las Entidades Financieras que son accesibles a través de internet, y enlazadas para la prestación del servicio Adelanto de Ventas Clip.  
      
**"Usuarios"** significa la persona física o moral, cliente de PayClip, que por sí o a través de cualquiera de sus apoderados y/o representantes legales lleve a cabo una solicitud formal para la contratación de Adelanto de Ventas Clip a través de la Entidad Financiera.  
      
      
**2\. Contratación de los servicios Adelanto de Ventas Clip.**  
      
Los Usuarios podrán contratar los servicios Adelanto de Ventas Clip con las Entidades Financieras que, para tal efecto le hubiesen otorgado un oferta previo análisis cualitativo y cuantitativo para determinar su perfil de riesgo y viabilidad y, siempre que tengan capacidad legal para contratar y obligarse contractualmente con las Entidades Financieras. En el caso de Usuarios personas morales la persona que realice el ingreso y registro en las Aplicaciones, Sitios Web y/o Plataformas de PayClip y/o de las Entidades Financieras para el servicio de Adelanto de Ventas Clip deberán tener la representación legal con facultades para actos de administración en términos del segundo párrafo del artículo 2554 del Código Civil Federal, en relación con el artículo 2° del Código de Comercio, otorgado ante fedatario público y demás requeridas por la Entidad Financiera. La persona que realice el registro y/o que haga uso de las Aplicaciones, Sitios Web y/o Plataformas de PayClip y/o de las Entidades Financieras en representación de una persona moral, está dando por entendido que cuenta con facultades suficientes para actuar en nombre y por cuenta de ella y que dicha persona moral acepta adherirse a los presentes Términos y Condiciones.  
      
Tanto PayClip como las Entidades Financieras se reservan el derecho de rechazar cualquier solicitud de contratación de los servicios Adelanto de Ventas Clip, incluso de negar el ingreso o registro en las Aplicaciones, Sitios Web y/o Plataformas o de suspender o cancelar cualquier prestación de servicios, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento en favor del Usuario.  
      
Para poder hacer uso de los servicios Adelanto de Ventas Clip, el Usuario deberá cumplir con los requisitos transaccionales en PayClip para recibir un ofrecimiento de préstamo con base en su actividad transaccional, llenar el formulario de registro en el Ecosistema PayClip, mediante la Firma Electrónica (algunos de los socios la requieren) del mismo. Para registrarse es obligatorio completar el formulario de registro en todos sus campos con datos válidos e información personal exacta, precisa y verdadera.  
      
Los Usuarios asumen el compromiso de actualizar los Datos de Registro conforme resulte necesario. PayClip no será responsable por la certeza de los Datos de Registro provistos. Los Usuarios garantizan y responden, en cualquier caso, por la veracidad, exactitud, integridad, vigencia y autenticidad de los Datos de Registro ingresados  
      
PayClip y/o las Entidades Financieras se reservan el derecho de solicitar comprobantes y/o información adicional a efectos de corroborar la información proporcionada por los Usuarios en relación con los Datos de Registro, así como de suspender temporal o definitivamente los servicios Adelanto de Ventas Clip a aquellos Usuarios cuya información no pueda ser confirmada.  
      
Los Usuarios se obligan a mantener la confidencialidad de su clave de ingreso al Ecosistema PayClip, siendo los únicos y exclusivos responsables por todas las operaciones efectuadas por sí o a través de sus apoderados o representantes legales  
      
      
**3\. De la integración del expediente del Usuario e información crediticia.**  
      
El Usuario manifiesta bajo protesta de decir verdad que la información y documentación transmitida y proporcionada a PayClip y/o a las Entidades Financieras ya sea directamente o a través de cualquier persona autorizada por cualquier medio, es verdadera y no ha sido alterada, así como aquellas que prevean información para preservar las obligaciones en materia de prevención de lavado de dinero y financiamiento al terrorismo por parte de las Entidades Financieras, por lo que en caso contrario la misma será rechazada, obligándome a sacar a PayClip y a las Entidades Financieras en paz y a salvo de cualquier controversia. El Usuario será el único responsable de verificar la veracidad, validez e idoneidad de la documentación ingresada al Portal de Usuarios.  
      
El Usuario está consciente que las Entidades Financieras (según sea el caso), por conducto de sus funcionarios facultados, ingrese cualquier documentación o información proporcionada por el Usuario por cualquier medio, lleve a cabo investigaciones sobre su comportamiento crediticio en las Sociedades de Información Crediticia que estime conveniente, lo anterior por haber proporcionado los datos que identifica al Usuario, previa autorización de este último de conformidad con las Décimo Primera regla de las Reglas Generales a las que deberán sujetarse las operaciones y actividades de las Sociedades de Información Crediticia y sus Usuarios Capítulo IV. Asimismo, el Usuario declara conocer la naturaleza y alcance de la información que solicitará PayClip y/o las Entidades Financieras a las Sociedades de Información Crediticia.  
      
      
**4\. Propiedad Intelectual**  
      
El contenido dentro del Ecosistema PayClip relativo a las solicitudes y/o consultas del Adelanto de Ventas Clip, así como los programas, bases de datos, redes, archivos que permiten al Usuario acceder y hacer uso de los servicios, son de la titularidad y propiedad de PayClip, no obstante aquellas marcas que no sean de la titularidad de PayClip que se muestran en dicho portal han sido expresamente autorizadas para su uso y explotación y por consecuencia están protegidas por las normas vigentes y aplicables en materia de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito de PayClip.  
      
El Usuario declara que las imágenes, logos y logotipos que podrá cargar en el Ecosistema PayClip, ya sea por sí mismo o por interpósita persona, son de su propiedad o cuenta con la autorización expresa para su utilización, por lo que el uso de las mismas no constituye la comisión de delitos en materia de derechos de autor tipificado en el artículo 424, fracción III del Código Penal Federal y sus correlativos en los demás Códigos Penales de la Ciudad de México y de los Estados de la República, por lo que autoriza a PayClip y las Entidades Financieras a la utilización de las imágenes, logos y logotipos de conformidad con lo dispuesto en los presente Términos y Condiciones y el Aviso de Privacidad.  
      
      
**5\. Límite de responsabilidad.**  
      
Bajo ninguna circunstancia PayClip será responsable en caso de que las Entidades Financieras se encuentren total o parcialmente imposibilitadas para prestar los servicio Adelanto de Ventas Clip, cuando lo anterior sea motivo de caso fortuito, insolvencia, fuerza mayor o cualquier otra circunstancia que se encuentre fuera del control de la Entidad Financiera.  
      
Asimismo, PayClip no será responsable ante el Usuario, cuando por caso fortuito o fuerza mayor la Entidad Financiera se encuentre impedida para cumplir de forma parcial con la oferta de Adelanto de Ventas Clip.  
      
En virtud de lo anterior, el Usuario renuncia explícitamente a la protección legal que resulte, directa o indirectamente, de caso fortuito o fuerza mayor conforme a lo estipulado en el artículo 2,111 del Código Civil Federal y sus correlativos de los Códigos Civiles para la Ciudad de México y los de los otros Estados de la República Mexicana.  
      
Cualquier reclamación que surja de la prestación de los servicios de Adelanto de Ventas Clip, deberá ser exclusivamente dirigida y resuelta por la Entidad Financiera que corresponda. En ese sentido, bajo ninguna circunstancia PayClip será responsable por cualquier hecho u omisión por parte de las Entidades Financieras que afecte a los Usuarios, siendo este último el único obligado para su atención.  
      
      
**6\. Datos Personales.**  
      
El Usuario autoriza a PayClip a tratar los Datos Personales que en el desarrollo y ejecución de estos Términos y Condiciones sean recabados, con la finalidad de cumplir con los mismos y en general, todo lo necesario para el desarrollo y ejecución de los servicios y subsecuentes actos. Los Datos Personales que los Usuarios transmitan a PayClip no podrán ser utilizados para fines distintos a los previstos en su Aviso de Privacidad y en estos Términos y Condiciones.  
      
Se le informa al Cliente que, como titular de los Datos Personales, le asisten los siguientes derechos:  
      
a)  Conocer, actualizar y rectificar sus Datos Personales frente a los responsables o encargados;
      
b)  Solicitar prueba de la autorización otorgada a los responsables o encargados;
      
c)  Ser informado por los responsables o encargados, previa solicitud, respecto del uso que les ha dado a sus Datos Personales y;
      
d)  Revocar la autorización y/o solicitar la supresión de los Datos Personales cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales.
      
      
      
**7\. Confidencialidad.**  
      
PayClip se obliga a mantener la confidencialidad de la información que reciba por parte del Usuario por cualquier medio, ya sea de manera verbal, escrita, gráfica o electrónica, y que tenga dicho carácter conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos y a no compartirlo con cualquier persona o entidad, excepto sus afiliadas, subsidiarias, cesionarios, partes relacionadas y terceros autorizados para tal efecto, conforme a lo establecido en el Aviso de Privacidad de PayClip.  
      
      
**8\. Modificaciones.**  
      
PayClip podrá realizar cambios y actualizaciones en los presentes Términos y Condiciones en cualquier momento, haciendo públicos los términos modificados en el Sitio Web. Todos los términos modificados entrarán en vigor al siguiente día de su publicación. El Usuario dentro de los 5 (cinco) días naturales siguientes a la publicación de los cambios introducidos deberá comunicar a PayClip la negativa de aceptación de los mismos; en este supuesto quedará cancelado el registro de Usuario y será inhabilitado del Ecosistema de PayClip.  
      
      
**9\. Sanciones y Responsabilidad del Usuario.**  
      
El Usuario acepta y reconoce que no podrá hacer uso de ninguna acción o dispositivo software, u otro medio tendiente a interferir con el funcionamiento del Ecosistema PayClip. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en estos Términos y Condiciones por parte del Usuario o de cualquier otro tercero lo hará responsable de las acciones legales pertinentes, y a las sanciones previstas por este acuerdo, obligándose a pagar una indemnización por los daños y perjuicios ocasionados.  
      
El Usuario reconoce y acepta que la utilización de los servicios Adelanto de Ventas Clip es de responsabilidad exclusiva de éste, por lo que se obliga a sacar en paz y a salvo a PayClip y a sus directores, gerentes, empleados, agentes, operarios, representantes y/o funcionarios respecto de cualquier queja, multa, denuncia, demanda y/o acción por parte de cualquier autoridad o tercero, derivado del uso negligente o inadecuado del Ecosistema PayClip.  
      
      
**10\. Entidades Financieras y Alianzas Comerciales.**  
      
El Usuario es consciente de la participación de las Entidades Financieras cuya intervención es necesaria para la prestación del servicio Adelanto de Ventas Clip, quienes tienen sus propias políticas y condiciones de uso para el otorgamiento de crédito bajo cualquier modalidad. Por lo anterior, el Usuario acepta y reconoce que el uso que haga de los servicios Adelanto de Ventas Clip, así como la aceptación de sus políticas y condiciones de uso son responsabilidad del Usuario.  
      
Como parte de una estrategia de negocios, PayClip establece alianzas comerciales con distintas Entidades Financieras, para efectos de que el Usuario, obtenga ofertas de crédito a través del servicio Adelanto de Ventas Clip. No obstante lo anterior, la Entidad Financiera es la única autorizada para aprobar el otorgamiento de algún crédito o la prestación de los servicios Adelanto de Ventas Clip, con independencia de las opiniones que al efecto emita PayClip, por lo que en caso de ser aprobado el Usuario debe asegurarse de entender los presentes Términos y Condiciones, así como los que de manera específica apliquen a los servicios Adelanto de Ventas Clip, según sea el caso.  
      
      
**11\. Jurisdicción y legislación aplicable.**  
      
Estos Términos y Condiciones se regirán en todos sus puntos por las leyes vigentes en la República Mexicana. Para la interpretación, cumplimiento y ejecución de estos Términos y Condiciones, PayClip y el Usuario expresamente se someten a la legislación y jurisdicción de los Tribunales competentes en la Ciudad de México, renunciando en consecuencia a cualquier fuero que en razón de su domicilio presente o futuro pudiera corresponderles.  
      
No obstante lo anterior, estos Términos y Condiciones se consideran como obligaciones adicionales e independientes a los derechos y obligaciones estipuladas en la legislación vigente y aplicable.
            `,

    aph: `Bontu Professional Services S.A.P.I. de C.V. (en adelante "BONTU") con domicilio en Avenida Antonio Dovalí Jaime número 70, Torre C, Piso 7, Colonia Zedec Santa Fe, Código Postal 01210, Alcaldía Álvaro Obregón, Ciudad de México, México, pone a disposición de los Usuarios, los presentes Términos y Condiciones de la plataforma en el cual, se podrá a disposición de los clientes de Payclip, S. de R.L. de C.V. (en adelante, "Clip"), el producto de crédito denominado "Adelanto de Ventas Clip".  

La sola utilización de la plataforma, denominada Clip, (en adelante, la "Plataforma") le confiere a la persona que ingrese y se registre, el carácter de Usuario; quien se compromete a leer, entender y aceptar todas las condiciones establecidas en los presentes Términos y Condiciones y en el Aviso de Privacidad, lo que le permitirá tener acceso a todos los servicios ofertados y en su caso, la contratación del crédito (en adelante, los "Servicios").  
      
Los presentes Términos y Condiciones tienen carácter obligatorio y vinculante, por lo cual la calidad de Usuario de la App implica la aceptación plena e incondicional de todas y cada una de las condiciones incluidas en los presentes, de conformidad con lo establecido en el artículo 1803 del Código Civil Federal.  
      
**A. Capacidad.**  
Los Servicios únicamente podrán ser utilizados por personas físicas con capacidad legal para contratar y obligarse en los términos y condiciones establecidos en los presentes Términos y Condiciones, que pertenezcan a la cartera de clientes de Clip. Por lo tanto, las personas que no cuenten con esta capacidad, no podrán tener acceso a los Servicios.  
      
**B. Información del Usuario.**  
Para poder utilizar los Servicios, el Usuario deberá forzosamente completar, en todos sus campos, el formulario de inscripción con datos e información personal verídica, precisa y correcta (la "Información del Usuario"). El Usuario asumirá la responsabilidad de actualizar su información conforme sea necesario, en los plazos que BONTU indique, de manera que la información continúe siendo válida y correcta en el tiempo.  
      
BONTU y Clip están facultados para utilizar diversos medios para solicitar, obtener y/o verificar, cuantas veces sea necesario, la Información del Usuario. Sin embargo, BONTU y Clip no se responsabilizan por la veracidad de la Información del Usuario que, los Usuarios le proporcionen. BONTU y Clip estarán facultados, para solicitar en cualquier momento uno o más datos adicionales y/o documentación en el caso que sea necesario corroborar todos o algunos de los datos de la Información del Usuario que éste hubiere proporcionado.  

Asimismo, BONTU y Clip se reservan el derecho de suspender temporal o definitivamente a cualquier Usuario, cuya Información del Usuario no pueda ser corroborada, o bien, cuya veracidad no pueda ser confirmada. En cualquier supuesto, el Usuario responderá y garantizará por la veracidad, vigencia, exactitud y autenticidad de la Información del Usuario que el mismo le proporcione a BONTU y Clip.  
      
El Usuario reconoce y acepta que BONTU y Clip podrán realizar las acciones que consideren necesarias para verificar su identidad y en el caso de que existan indicios de robo de identidad, acepta que BONTU y/o Clip actúen de manera inmediata, pudiendo enunciativa más no limitativamente, restringir el acceso a los Servicios sin responsabilidad alguna.  
      
BONTU y/o Clip podrán, en cualquier momento, dejar sin efectos los presentes Términos y Condiciones, sea de manera general o particular, así como dejar de ofrecer los Servicios en la Plataforma, sin necesidad de notificación alguna y sin que medie justificación.  
      
**C. Otorgamiento de Crédito.**  
Se hace de conocimiento de los Usuarios, que el crédito cuya oferta se presenta a través de la Plataforma, será otorgado y operado en su totalidad por BONTU, quien realizará el análisis de la solicitud de crédito correspondiente para definir la autorización o rechazo del crédito al Usuario. Este análisis incluye de manera enunciativa y no limitativa, la validación de los datos y documentos de identificación proporcionados por el Usuario, así como efectuar la consulta correspondiente ante alguna de las Sociedades de Información Crediticia respecto del historial crediticio del Usuario. Posterior a la terminación del proceso de análisis crediticio, BONTU notificará el rechazo o autorización respecto del otorgamiento del crédito.  
      
En caso de ser aprobado, el Usuario deberá proceder a firmar el contrato de crédito y demás documentos accesorios, (en adelante, el "Contrato") siguiendo las instrucciones que se le proporcionarán en su momento.  
      
Finalmente, en lo que respecta a las obligaciones de pago, tasas, comisiones, plazos y demás condiciones del crédito se le informa al Usuario que el Contrato será el documento que establecerá la relación entre BONTU y el Usuario.  
      
**D. De la Plataforma.**  
La información mostrada en la Plataforma es meramente con fines informativos. El material que se encuentra disponible está sujeto a cambios sin previo aviso.  
      
BONTU y/o Clip realizarán su mejor esfuerzo para mantener la Plataforma funcionando correctamente y libre de virus electrónicos u otras restricciones; sin embargo, Clip y/o BONTU por ningún motivo garantizan la ausencia de virus, errores o cualquier otro material contaminante o con funciones destructivas en la información o programas disponibles en o a través dela Plataforma siendo por ende, exclusiva responsabilidad del Usuario el contar con los antivirus, firewalls y demás sistemas de seguridad electrónica.  
      
El acceso a la Plataforma puede, en ocasiones, ser limitado o lento, o verse afectado debido a una serie de factores, que de manera enunciativa, más no limitativa son: servidores, redes, fallas en el hardware (incluyendo el dispositivo que use el Usuario), líneas y conexiones de telecomunicaciones y otros equipos electrónicos y mecánicos; falla del software, que incluye, entre otras cosas, errores, virus, problemas de configuración, incompatibilidad de sistemas, utilidades o aplicaciones, el funcionamiento de cortafuegos o programas de detección, códigos ilegibles o irregularidades dentro de documentos particulares u otro contenido; sobrecarga de capacidades del sistema; así como cualquier otra causa, por lo cual BONTU y/o Clip, no serán responsables de los daños y perjuicios que sufra el Usuario derivado de tales supuestos.  
      
El Usuario reconoce y acepta que la Plataforma, podrá utilizar Cookies y/o programas o herramientas para conocimiento del Usuario y verificación de identidad.  
      
**E. Responsabilidad respecto de la Plataforma.**  
BONTU y/o Clip procurarán asegurar la disponibilidad no interrumpida de la Plataforma. Sin embargo, el acceso puede verse temporalmente interrumpido por tareas de mantenimiento, actualizaciones, o por cuestiones ajenas a Clip y/o BONTU.  
      
Clip y/o BONTU no serán responsables, de manera enunciativa más no limitativa, de:  
      
      
1.  Uso indebido del nombre de usuario y contraseña de registro.
2.  Robos de identidad para creación de cuentas falsas.
3.  Uso indebido por parte del Usuario del contenido de la Plataforma.
4.  Violación de derechos de terceros, relacionados con la información a la que llegue a tener acceso el Usuario a través de la Plataforma.
5.  Eventuales daños y perjuicios causados a los Usuarios como consecuencia de un funcionamiento normal o anormal de la localización de los contenidos y/o acceso a la Plataforma.
6.  Envío de datos personales sin autorización de su titular y/o información fraudulenta, falsa o errónea.
7.  Errores o retrasos en el acceso a la App cuando estas incidencias respondan a problemas en la red Internet, causas de caso fortuito o fuerza mayor y cualquier otra contingencia imprevisible ajena a Clip y/o BONTU.
8.  Fallos, errores o daños producidos a la Plataforma por un uso ineficiente y de mala fe por parte del Usuario.
9.  Uso fraudulento de la marca, logotipo y denominación de Clip y/o BONTU llevado a cabo por terceros en perjuicio del Usuario

Clip y/o BONTU se compromete a solucionar los problemas que puedan surgir y a ofrecer todo el apoyo necesario al Usuario para llegar a una solución rápida y satisfactoria de las incidencias que puedan surgir en el Sitio.


**F. Modificaciones.**
BONTU y/o Clip podrán modificar los Términos y Condiciones en cualquier momento y cuantas veces consideren necesario, haciendo públicos en la Plataforma, los términos modificados. Los términos modificados entrarán en vigor a los 10 (diez) días de su publicación en la Plataforma.

En caso de que el Usuario se negase a aceptar las modificaciones a los presentes Términos y Condiciones, se entenderá por terminado el vínculo como Usuario de la Plataforma. Lo anterior no afectará de ninguna manera la validez de las obligaciones contraídas por el Usuario en virtud del Contrato suscrito con BONTU , por lo que las mismas seguirán vigentes hasta el cumplimiento total de las obligaciones, incluyendo sin limitar, el pago del principal, intereses, comisiones y gastos, que resultaren aplicables.

**G. Confidencialidad.**
El uso indebido y la reproducción total o parcial de los contendidos de la Plataforma y/o el Crédito quedan prohibidos, salvo autorización expresa y por escrito de BONTU y/o Clip, los mismos están protegidos por las leyes nacionales y normas internacionales en materia de propiedad industrial y derecho de autor.

**H. Indemnización.**
Las Partes acuerdan que el Usuario indemnizará y mantendrá en paz y a salvo a BONTU y/o Clip, sus subsidiarias, directivos, funcionarios, administradores, representantes y empleados, por cualquier reclamo o demanda de Usuarios o terceros por sus actividades en la Plataforma o por su incumplimiento los Términos y Condiciones o por la violación de cualesquiera leyes o derechos de terceros.

Asimismo, el Usuario acepta en este acto que Clip es únicamente una solución de cobros, la cual permite la aceptación de pagos realizados por tarjetas de Crédito y/o Débito, y su función en esta operación es la de ser un canal de comunicación entre los Usuarios de la Plataforma y BONTU.

De igual forma, el Usuario acepta y reconoce que BONTU es una sociedad mercantil otorgante de crédito, y su función es esta operación es la de otorgar el crédito a los Clientes de Clip, que ostenten la calidad de Usuarios de la Plataforma, quienes previamente le han otorgado a Clip su consentimiento para compartir la información necesaria a BONTU para la oferta de los servicios financieros otorgados por esta.

**I. Jurisdicción y Leyes Aplicables.**
Los presentes Términos y Condiciones y el Contrato se rigen por lo dispuesto en dichos documentos y en su defecto por las disposiciones contenidas y que resulten aplicables en la Ley General de Títulos y Operaciones de Crédito, la Ley para la Transparencia y Ordenamiento de los Servicios Financieros, las Disposiciones de carácter general a que se refiere la Ley para la Transparencia y Ordenamiento de los Servicios Financieros en materia de contratos de adhesión, publicidad, estados de cuenta y comprobantes de operación emitidos por las entidades comerciales emitidos por la Procuraduría Federal del Consumidor (PROFECO), Reglas de Carácter General a que se refiere la Ley para la Identificación de Operaciones con Recursos de Procedencia Ilícita, el Código Civil Federal y el Código de Comercio.

Para la interpretación y cumplimiento de los presentes Términos y Condiciones y el Contrato, se someten de manera expresa a la jurisdicción de los Tribunales competentes de la Ciudad de México; renunciando a cualquier otra jurisdicción que por razón de su domicilio o cualquier otra causa les corresponda o pudiere corresponderles, ya sea en lo presente o futuro.

**J. Aclaraciones.**
Para mayor información, dudas o aclaraciones deberá comunicarse por los siguientes medios: Tel: 800 02 26688, correo electrónico: contacto@bontu.mx
`,
    general: `**Términos y condiciones de uso para la Solicitud de "Adelanto de Ventas Clip"**
      
Por este medio se le informa a los Usuarios los Términos y Condiciones de Uso para la Solicitud de Adelanto de Ventas Clip (los “Términos y Condiciones”) que les serán aplicables al momento de usar o accesar al Ecosistema Clip. Los presentes Términos y Condiciones serán aceptados por los Usuarios tanto de forma tácita por el simple uso o acceso antes referido, así como de forma expresa, a través de las manifestaciones de voluntad que realicen los Usuarios de tiempo en tiempo, por lo que manifiestan que han leído y entendido los presentes Términos y Condiciones y dan su consentimiento para obligarse en cumplimiento a los mismos.

Los servicios de Adelanto de Ventas Clip se ofrecen a través de las Entidades que mantengan una alianza vigente con Clip. Las condiciones específicas de Adelanto de Ventas Clip serán definidas por las Entidades, en el entendido de que Clip únicamente es un intermediario entre las Entidades y los Usuarios, es decir, las Entidades serán las únicas responsables ante los Usuarios sobre las condiciones y ejecución de Adelanto de Ventas Clip. Los Usuarios que no acepten los presentes Términos y Condiciones, así como el Aviso de Privacidad de Clip, los cuales tienen un carácter obligatorio y vinculante, deberán abstenerse de utilizar los servicios de Adelanto de Ventas Clip así como el Ecosistema Clip.
      
**1.Definiciones.**
      
**"Aplicaciones"** son aquellas creadas o desarrolladas por Clip, o de las cuales éste último tiene los derechos para su uso y explotación, para efectos de la prestación del servicio Adelanto de Ventas Clip.
      
**"Datos de Registro"** significa la información que es proporcionada por los Usuarios a través de las Aplicaciones, Sitios Web y/o Plataformas para acceder a Adelanto de Ventas Clip.

**"Datos Personales"** significa el conjunto de información que los Usuarios proporcionan a través del Ecosistema Clip, misma que hace identificables a dichos Usuarios.
      
**"Ecosistema Clip"** significa el conjunto de Aplicaciones, Sitios Web y/o Plataformas de Clip y/o de las Entidades que se encuentran relacionadas entre sí para la prestación de los servicios de Adelanto de Ventas Clip en favor de los Usuarios.
      
**"Entidades"** son aquellas personas morales (ya sea entidades comerciales o financieras) que dentro de sus actividades habituales y profesionales se encuentran facultadas para el otorgamiento de préstamos, las cuales mantienen una relación comercial y de negocios con Clip.
            
**"Clip"** significa PayClip, S. de R.L. de C.V.
      
**"Oferta(s)"** significa la propuesta de préstamo presentada por las Entidades a los Usuarios, misma en la que se incluye, de forma enunciativa más no limitativa el monto del crédito, plazo, intereses ordinarios, intereses moratorios y comisiones en su caso.

**"Plataformas"** son los sistemas desarrollados por Clip y las Entidades en conjunto, que permiten a los Usuarios, de manera virtual, la ejecución de ambientes digitales para acceder a los servicios de Adelanto de Ventas Clip a través de internet.
      
**"Portal Adelanto de Ventas Clip"** se refiere al portal web utilizado por los Usuarios a través del dashboard de Clip para tener acceso a los Préstamos, consultas de estados de cuenta, saldos correspondientes de los mismos, descargar documentos pertinentes a la naturaleza del préstamo y solicitar ayuda o asistencia.

**"Adelanto de Ventas Clip"** significa el otorgamiento de un préstamo a través de las Entidades en favor de los Usuarios, previo su análisis de la información corporativa y financiera para determinar el perfil de riesgo de los Usuarios.
      
**"Sitios Web"** son las páginas web creadas y administradas por Clip que son accesibles a través de internet, y que pueden ser enlazadas a las Entidades para la prestación del servicio Adelanto de Ventas Clip.
      
**"Usuarios"** significa la persona física y/o moral, que por sí o a través de cualquiera de sus apoderados y/o representantes legales solicita la contratación de Adelanto de Ventas Clip a través de la Entidad.
      
      
**2.Contratación de los servicios Adelanto de Ventas Clip.**
      
Los Usuarios podrán contratar los presentes servicios, una vez que las Entidades hayan realizado la revisión de su información corporativa y financiera para determinar su perfil de riesgo y viabilidad y le hubiesen realizado una Oferta. En el caso de que los Usuarios sean personas morales, la persona que realice el ingreso y registro en el Ecosistema Clip, deberá tener la representación legal con facultades legales suficientes de acuerdo a la normatividad aplicable. La persona que realice el registro y/o que haga uso del Ecosistema Clip en representación de una persona moral, está dando por entendido que cuenta con facultades suficientes para actuar en nombre y por cuenta de ella y que dicha persona moral acepta adherirse a los presentes Términos y Condiciones.
      
Tanto Clip como las Entidades se reservan el derecho de rechazar cualquier solicitud de contratación, incluso de negar el ingreso o registro en el Ecosistema Clip o de suspender o cancelar cualquier prestación de servicios, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento en favor de los Usuarios.
      
Una vez que se haya realizado el ofrecimiento del préstamo por parte de las Entidades, los Usuarios deberán llenar un formulario de registro, mismo que Clip pondrá a su disposición. Para registrarse es obligatorio completar el formulario de registro en todos sus campos con datos válidos e información exacta, precisa y verdadera.
      
Los Usuarios asumen el compromiso de actualizar los datos de registro conforme resulte necesario, y así mismo garantizan y responden, en cualquier caso, por la veracidad, exactitud, integridad, vigencia y autenticidad de los datos ingresados en el formulario de registro, liberando así a Clip y a las Entidades de cualquier responsabilidad derivada de ello.
      
Las Entidades podrán solicitar información y/o documentación adicional a los Usuarios, a efecto de corroborar la información proporcionada en el formulario de registro o de cumplir con la regulación aplicable, a efecto de actualizar una Oferta, así como de suspender temporal o definitivamente los servicios de Adelanto de Ventas Clip a aquellos Usuarios cuya información no pueda ser confirmada. 

Los Usuarios se obligan a mantener la confidencialidad de su clave de ingreso al Ecosistema Clip, siendo los únicos y exclusivos responsables por todas las operaciones efectuadas por sí o a través de sus apoderados o representantes legales.
      
      
**3.Autorización para solicitar Información Crediticia.** 
      
Los Usuarios manifiestan bajo protesta de decir verdad, que la información y documentación transmitida y proporcionada a Clip y/o a las Entidades por cualquier medio y a través de personas autorizadas por los Usuarios, es verdadera, por lo que en caso contrario la misma será rechazada, obligándose a sacar a Clip y a las Entidades en paz y a salvo de cualquier controversia. Los Usuarios serán los únicos responsables de verificar la veracidad, validez e idoneidad de la documentación e información proporcionada a Clip y/o a las Entidades.
      
Por este medio los Usuarios autorizan y otorgan su consentimiento a las Entidades, para que por conducto de sus funcionarios facultados, ingresen cualquier documentación o información proporcionada por cualquier medio que lleve a cabo investigaciones sobre su comportamiento crediticio en las Sociedades de Información Crediticia que estime conveniente, lo anterior por haber proporcionado los datos que identifica a los Usuarios de conformidad con las Reglas Generales a las que deberán sujetarse las operaciones y actividades de las Sociedades de Información Crediticia y sus Usuarios. En este acto, los Usuarios declaran conocer la naturaleza y alcance de la información que solicitarán las Entidades a las Sociedades de Información Crediticia.
      

**4.Propiedad Intelectual.**
      
Los programas, bases de datos, redes, archivos que permiten a los Usuarios acceder y hacer uso de los servicios, son de la titularidad y propiedad de Clip, no obstante, aquellas marcas de terceros que no sean de la titularidad de Clip y que se muestran en el Portal Adelanto de Ventas Clip han sido expresamente autorizadas para su uso y explotación y por consecuencia están protegidas por las normas vigentes y aplicables en materia de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito de Clip.

Los Usuarios declaran que en caso de cargar imágenes en el Ecosistema Clip, son de su propiedad o cuentan con la autorización expresa para su utilización, por lo que el uso de las mismas no constituye la comisión de delitos en materia de derechos de autor, por lo que autoriza a Clip y a las Entidades la utilización de las imágenes de conformidad con lo dispuesto en los presentes Términos y Condiciones, el Aviso de Privacidad, así como con las políticas internas y externas aplicables de Clip y, así mismo deslinda de cualquier tipo de responsabilidad, a Clip y a las Entidades, derivada de reclamaciones o acciones legales por algún incumplimiento sobre este supuesto.
      

**5.Límite de Responsabilidad.**
      
Bajo ninguna circunstancia Clip será responsable en caso de que las Entidades se encuentren total o parcialmente imposibilitadas para prestar los servicios de Adelanto de Ventas Clip, cuando lo anterior sea motivo de caso fortuito, insolvencia, fuerza mayor o cualquier otra circunstancia que se encuentre fuera del control de la Entidad.
      
Asimismo, Clip no será responsable ante los Usuarios, cuando la Entidad por caso fortuito o fuerza mayor se encuentre impedida para cumplir de forma parcial o total con la Oferta de Adelanto de Ventas Clip.
      
En virtud de lo anterior, los Usuarios renuncian explícitamente a la protección legal que resulte, directa o indirectamente, de caso fortuito o fuerza mayor conforme a lo estipulado en el artículo 2,111 del Código Civil Federal y sus correlativos de los Códigos Civiles para la Ciudad de México y los de los otros Estados de la República Mexicana que resulten aplicables.
      
**6.Datos Personales.**
      
Los Usuarios autorizan y otorgan su consentimiento a Clip para recabar, tratar y compartir a terceros comerciales, incluyendo las Entidades, los Datos Personales que por la solicitud y/o ejecución del servicio Adelanto de Ventas Clip sean necesarios, así como durante el desarrollo y ejecución de los presentes Términos y Condiciones y hasta la conclusión del servicio y el periodo de tiempo subsecuente aplicable.

El tratamiento de los Datos Personales que los Usuarios transmitan a Clip, se sujetará a lo establecido en el Aviso de Privacidad, siendo resguardado en todo momento por Clip y las Entidades; asimismo no podrán ser utilizados para fines distintos a los previstos en los presentes Términos y Condiciones y en el Aviso de Privacidad, el cual podrá ser modificado de tiempo en tiempo por Clip, mismo que estará disponible en todo momento para los Usuarios en la página web **clip.mx/privacidad/**.
      
Los Usuarios, como titulares de sus Datos Personales, cuentan con los siguientes Derechos ARCO, mismos que podrán ejercer en los medios y procedimientos establecidos en el Aviso de Privacidad:
      
1.  Derecho de Acceso: Conocer con cuáles de sus Datos Personales cuenta Clip y cuáles son las finalidades de su tratamiento.
      
2.  Derecho de Rectificación: Solicitar la corrección de sus Datos Personales en caso de que carezcan de actualización, sean inexactos, erróneos o estén incompletos.
      
3.  Derecho de Cancelación: Solicitud de eliminación de todo registro o copia de sus Datos Personales, ya sea de forma física o electrónica.
      
4.  Derecho de Oposición: Solicitar el cese en el uso de sus Datos Personales.
      
**7.Confidencialidad.**
      
Clip se obliga a mantener la confidencialidad de la información que reciba por parte de los Usuarios por cualquier medio, ya sea de manera verbal, escrita, gráfica o electrónica, y que tenga dicho carácter conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos y a no compartirlo con cualquier persona o entidad, excepto sus afiliadas, subsidiarias, cesionarios, partes relacionadas y terceros autorizados para tal efecto, conforme a lo establecido en el Aviso de Privacidad de Clip.
      
**8.Modificaciones.**
      
Clip podrá realizar cambios y actualizaciones en los presentes Términos y Condiciones en cualquier momento, haciendo públicos los términos modificados en el Ecosistema Clip. Todos los términos modificados entrarán en vigor al siguiente día de su publicación. Los Usuarios dentro de los 5 (cinco) días naturales siguientes a la publicación de los cambios introducidos deberán comunicar a Clip la negativa de aceptación de los mismos; en estos casos los Usuarios únicamente podrán utilizar el Ecosistema Clip para llevar a cabo las acciones tendientes al cierre de las operaciones, para que Clip posteriormente cancele su registro y sea inhabilitado del Ecosistema de Clip. La negativa referida, no liberará a los Usuarios de las obligaciones jurídicas y/o contractuales contraídas con las Entidades que se encuentren vigentes al momento de que los Usuarios comuniquen la negativa de aceptación de los cambios en los Términos y Condiciones.      

**9.Sanciones y Responsabilidad de los Usuarios.** 
      
Los Usuarios aceptan y reconocen que no podrán hacer uso de ninguna acción o dispositivo software, u otro medio tendiente a interferir con el funcionamiento del Ecosistema Clip. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en los presentes Términos y Condiciones por parte de los Usuarios o de cualquier otro tercero lo hará responsable de las acciones legales pertinentes, y a las sanciones previstas por este acuerdo, obligándose a pagar una indemnización por los daños y perjuicios ocasionados.
      
Los Usuarios reconocen y aceptan que la utilización de los servicios Adelanto de Ventas Clip es de responsabilidad exclusiva de éstos, por lo que se obligan a sacar en paz y a salvo a Clip y a las Entidades, así como a sus directores, gerentes, empleados, agentes, operarios, representantes y/o funcionarios respecto de cualquier queja, multa, denuncia, demanda y/o cualquier tipo de acción legal por parte de cualquier autoridad o tercero, derivado del uso negligente o inadecuado del Ecosistema Clip.
      
**10.Entidades y Alianzas Comerciales.**
      
Tal y como se menciona al inicio de los presentes Términos y Condiciones, Clip establece alianzas con distintas Entidades, para efecto de que los Usuarios, obtengan Ofertas o actualizaciones de dichas Ofertas a través de Adelanto de Ventas Clip. Por lo anterior, la Entidad es la única autorizada para aprobar el otorgamiento del préstamo, así como de las actualizaciones de las Ofertas, siendo Clip únicamente un intermediario entre las Entidades y los Usuarios, por lo que en caso de ser aprobado, los Usuarios deben asegurarse de entender los presentes Términos y Condiciones.

Los Usuarios tienen conocimiento que, para la prestación del servicio Adelanto de Ventas Clip, las Entidades tienen sus propias políticas y condiciones de uso para el otorgamiento del préstamo. Por lo anterior, los Usuarios aceptan y reconocen que el uso que hagan del servicio Adelanto de Ventas Clip, así como la aceptación de sus políticas y condiciones de uso son responsabilidad de los Usuarios.
            
**11.Jurisdicción y Legislación Aplicable.**
      
Los presentes Términos y Condiciones se regirán en todos sus puntos por las leyes vigentes en los Estados Unidos Mexicanos. Para la interpretación, cumplimiento y ejecución de los presentes Términos y Condiciones, Clip y los Usuarios expresamente se someten a la legislación y jurisdicción de los Tribunales competentes en la Ciudad de México, renunciando en consecuencia a cualquier fuero que en razón de su domicilio presente o futuro pudiera corresponderles.
      
No obstante lo anterior, los presentes Términos y Condiciones se consideran como obligaciones adicionales e independientes a los derechos y obligaciones estipuladas en la legislación vigente y aplicable.
`,
    cto: `**Términos y condiciones de uso para la Solicitud de Crédito "Adelanto de Ventas Clip"**  

Por este medio se le informa a los Usuarios los Términos y Condiciones de Uso para la Solicitud de Crédito Adelanto de Ventas Clip (los "Términos y Condiciones") que les serán aplicables por el simple uso o acceso que haga el Usuario al Ecosistema de PayClip mediante su ingreso y registro en las Aplicaciones, Sitios Web y/o Plataformas de PayClip para la solicitud de Adelanto de Ventas Clip. Los Términos y Condiciones serán aceptados por los Usuarios tanto de forma tácita por el simple uso o acceso antes referido, así como de forma expresa, a través de las manifestaciones de voluntad que realicen los Usuarios de tiempo en tiempo, por lo que manifiestan que han leído y entendido los presentes Términos y Condiciones y dan su consentimiento para obligarse en cumplimiento a los mismos.

El Usuario deberá tener en cuenta que los productos de crédito que se muestran en la Aplicación, son ofrecidos por Entidades Financieras que mantienen una alianza vigente con PayClip, por lo que, en todo lo relacionado con las condiciones del crédito deberán sujetarse a las condiciones y obligaciones adicionales que cada una de las Entidades Financieras estipule en el contrato que ampara el otorgamiento de un crédito.

Cualquier Usuario que no acepte estos Términos y Condiciones, así como el Aviso de Privacidad de PayClip, los cuales tienen un carácter obligatorio y vinculante deberán abstenerse de utilizar los servicios de Adelanto de Ventas Clip, sus Aplicaciones, Sitios Web y/o Plataformas.


**1. Definiciones.**

**"Aplicaciones"** son aquellas creadas o desarrolladas por PayClip, o de las cuales tiene los derechos para su uso y explotación, para efectos de la prestación del servicio Adelanto de Ventas Clip.

**"Datos de Registro"** significa la información que es proporcionada por el Usuario a través de las Aplicaciones, Sitios Web y/o Plataformas para acceder a Adelanto de Ventas Clip.

**"Ecosistema PayClip"** significa el conjunto de Aplicaciones, Sitios Web y/o Plataformas de PayClip y/o de las Entidades Financieras relacionadas entre sí para la prestación de los servicios Adelanto de Ventas Clip en favor del Usuario.

**"Entidades Financieras"** son aquellas personas morales cuya actividad habitual y profesional consiste en el otorgamiento de crédito y las cuales mantienen una relación comercial y de negocios con PayClip.

**"Firma Electrónica"** Los datos en forma electrónica consignados en un mensaje de datos, o adjuntados o asociados a éste, que identifican al Usuario como firmante en relación con el mensaje de datos.

**"PayClip"** significa PayClip, S. de R.L. de C.V., así como sus filiales, subsidiarias y/o controladoras.

**"Plataformas"** son aquellas desarrolladas por PayClip y las Entidades Financieras que permiten al Usuario, de manera virtual, la ejecución de sistemas para acceder a los servicios de Adelanto de Ventas Clip a través de Internet.

**"Adelanto de Ventas Clip"** significa el otorgamiento de un producto de crédito bajo cualquier modalidad en términos de lo previsto por la Ley General de Títulos y Operaciones de Créditos, por parte de las Entidades Financieras en favor de los Usuarios, previo análisis cualitativo y cuantitativo para determinar su perfil de riesgo.

**"Sitios Web"** son las páginas web creadas y administradas por PayClip y/o las Entidades Financieras que son accesibles a través de internet, y enlazadas para la prestación del servicio Adelanto de Ventas Clip.

**"Usuarios"** significa la persona física o moral, cliente de PayClip, que por sí o a través de cualquiera de sus apoderados y/o representantes legales lleve a cabo una solicitud formal para la contratación de Adelanto de Ventas Clip a través de la Entidad Financiera.


**2. Contratación de los servicios Adelanto de Ventas Clip.**

Los Usuarios podrán contratar los servicios Adelanto de Ventas Clip con las Entidades Financieras que, para tal efecto le hubiesen otorgado un oferta previo análisis cualitativo y cuantitativo para determinar su perfil de riesgo y viabilidad y, siempre que tengan capacidad legal para contratar y obligarse contractualmente con las Entidades Financieras. En el caso de Usuarios personas morales la persona que realice el ingreso y registro en las Aplicaciones, Sitios Web y/o Plataformas de PayClip y/o de las Entidades Financieras para el servicio de Adelanto de Ventas Clip deberán tener la representación legal con facultades para actos de administración en términos del segundo párrafo del artículo 2554 del Código Civil Federal, en relación con el artículo 2° del Código de Comercio, otorgado ante fedatario público y demás requeridas por la Entidad Financiera. La persona que realice el registro y/o que haga uso de las Aplicaciones, Sitios Web y/o Plataformas de PayClip y/o de las Entidades Financieras en representación de una persona moral, está dando por entendido que cuenta con facultades suficientes para actuar en nombre y por cuenta de ella y que dicha persona moral acepta adherirse a los presentes Términos y Condiciones.

Tanto PayClip como las Entidades Financieras se reservan el derecho de rechazar cualquier solicitud de contratación de los servicios Adelanto de Ventas Clip, incluso de negar el ingreso o registro en las Aplicaciones, Sitios Web y/o Plataformas o de suspender o cancelar cualquier prestación de servicios, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento en favor del Usuario.

Para poder hacer uso de los servicios Adelanto de Ventas Clip, el Usuario deberá cumplir con los requisitos transaccionales en PayClip para recibir un ofrecimiento de préstamo con base en su actividad transaccional, llenar el formulario de registro en el Ecosistema PayClip, mediante la Firma Electrónica (algunos de los socios la requieren) del mismo. Para registrarse es obligatorio completar el formulario de registro en todos sus campos con datos válidos e información personal exacta, precisa y verdadera.

Los Usuarios asumen el compromiso de actualizar los Datos de Registro conforme resulte necesario. PayClip no será responsable por la certeza de los Datos de Registro provistos. Los Usuarios garantizan y responden, en cualquier caso, por la veracidad, exactitud, integridad, vigencia y autenticidad de los Datos de Registro ingresados

PayClip y/o las Entidades Financieras se reservan el derecho de solicitar comprobantes y/o información adicional a efectos de corroborar la información proporcionada por los Usuarios en relación con los Datos de Registro, así como de suspender temporal o definitivamente los servicios Adelanto de Ventas Clip a aquellos Usuarios cuya información no pueda ser confirmada.

Los Usuarios se obligan a mantener la confidencialidad de su clave de ingreso al Ecosistema PayClip, siendo los únicos y exclusivos responsables por todas las operaciones efectuadas por sí o a través de sus apoderados o representantes legales


**3. De la integración del expediente del Usuario e información crediticia.**

El Usuario manifiesta bajo protesta de decir verdad que la información y documentación transmitida y proporcionada a PayClip y/o a las Entidades Financieras ya sea directamente o a través de cualquier persona autorizada por cualquier medio, es verdadera y no ha sido alterada, así como aquellas que prevean información para preservar las obligaciones en materia de prevención de lavado de dinero y financiamiento al terrorismo por parte de las Entidades Financieras, por lo que en caso contrario la misma será rechazada, obligándome a sacar a PayClip y a las Entidades Financieras en paz y a salvo de cualquier controversia. El Usuario será el único responsable de verificar la veracidad, validez e idoneidad de la documentación ingresada al Portal de Usuarios.

El Usuario está consciente que las Entidades Financieras (según sea el caso), por conducto de sus funcionarios facultados, ingrese cualquier documentación o información proporcionada por el Usuario por cualquier medio, lleve a cabo investigaciones sobre su comportamiento crediticio en las Sociedades de Información Crediticia que estime conveniente, lo anterior por haber proporcionado los datos que identifica al Usuario, previa autorización de este último de conformidad con las Décimo Primera regla de las Reglas Generales a las que deberán sujetarse las operaciones y actividades de las Sociedades de Información Crediticia y sus Usuarios Capítulo IV. Asimismo, el Usuario declara conocer la naturaleza y alcance de la información que solicitará PayClip y/o las Entidades Financieras a las Sociedades de Información Crediticia.

**4. Propiedad Intelectual**

El contenido dentro del Ecosistema PayClip relativo a las solicitudes y/o consultas del Adelanto de Ventas Clip, así como los programas, bases de datos, redes, archivos que permiten al Usuario acceder y hacer uso de los servicios, son de la titularidad y propiedad de PayClip, no obstante aquellas marcas que no sean de la titularidad de PayClip que se muestran en dicho portal han sido expresamente autorizadas para su uso y explotación y por consecuencia están protegidas por las normas vigentes y aplicables en materia de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito de PayClip.

El Usuario declara que las imágenes, logos y logotipos que podrá cargar en el Ecosistema PayClip, ya sea por sí mismo o por interpósita persona, son de su propiedad o cuenta con la autorización expresa para su utilización, por lo que el uso de las mismas no constituye la comisión de delitos en materia de derechos de autor tipificado en el artículo 424, fracción III del Código Penal Federal y sus correlativos en los demás Códigos Penales de la Ciudad de México y de los Estados de la República, por lo que autoriza a PayClip y las Entidades Financieras a la utilización de las imágenes, logos y logotipos de conformidad con lo dispuesto en los presente Términos y Condiciones y el Aviso de Privacidad.


**5. Límite de responsabilidad.**

Bajo ninguna circunstancia PayClip será responsable en caso de que las Entidades Financieras se encuentren total o parcialmente imposibilitadas para prestar los servicio Adelanto de Ventas Clip, cuando lo anterior sea motivo de caso fortuito, insolvencia, fuerza mayor o cualquier otra circunstancia que se encuentre fuera del control de la Entidad Financiera.

Asimismo, PayClip no será responsable ante el Usuario, cuando por caso fortuito o fuerza mayor la Entidad Financiera se encuentre impedida para cumplir de forma parcial con la oferta de Adelanto de Ventas Clip.

En virtud de lo anterior, el Usuario renuncia explícitamente a la protección legal que resulte, directa o indirectamente, de caso fortuito o fuerza mayor conforme a lo estipulado en el artículo 2,111 del Código Civil Federal y sus correlativos de los Códigos Civiles para la Ciudad de México y los de los otros Estados de la República Mexicana.

Cualquier reclamación que surja de la prestación de los servicios de Adelanto de Ventas Clip, deberá ser exclusivamente dirigida y resuelta por la Entidad Financiera que corresponda. En ese sentido, bajo ninguna circunstancia PayClip será responsable por cualquier hecho u omisión por parte de las Entidades Financieras que afecte a los Usuarios, siendo este último el único obligado para su atención.


**6. Datos Personales.**

El Usuario autoriza a PayClip a tratar los Datos Personales que en el desarrollo y ejecución de estos Términos y Condiciones sean recabados, con la finalidad de cumplir con los mismos y en general, todo lo necesario para el desarrollo y ejecución de los servicios y subsecuentes actos. Los Datos Personales que los Usuarios transmitan a PayClip no podrán ser utilizados para fines distintos a los previstos en su Aviso de Privacidad y en estos Términos y Condiciones.

Se le informa al Cliente que, como titular de los Datos Personales, le asisten los siguientes derechos:

a)  Conocer, actualizar y rectificar sus Datos Personales frente a los responsables o encargados;

b)  Solicitar prueba de la autorización otorgada a los responsables o encargados;

c)  Ser informado por los responsables o encargados, previa solicitud, respecto del uso que les ha dado a sus Datos Personales y;

d)  Revocar la autorización y/o solicitar la supresión de los Datos Personales cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales.



**7. Confidencialidad.**

PayClip se obliga a mantener la confidencialidad de la información que reciba por parte del Usuario por cualquier medio, ya sea de manera verbal, escrita, gráfica o electrónica, y que tenga dicho carácter conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos y a no compartirlo con cualquier persona o entidad, excepto sus afiliadas, subsidiarias, cesionarios, partes relacionadas y terceros autorizados para tal efecto, conforme a lo establecido en el Aviso de Privacidad de PayClip.


**8. Modificaciones.**

PayClip podrá realizar cambios y actualizaciones en los presentes Términos y Condiciones en cualquier momento, haciendo públicos los términos modificados en el Sitio Web. Todos los términos modificados entrarán en vigor al siguiente día de su publicación. El Usuario dentro de los 5 (cinco) días naturales siguientes a la publicación de los cambios introducidos deberá comunicar a PayClip la negativa de aceptación de los mismos; en este supuesto quedará cancelado el registro de Usuario y será inhabilitado del Ecosistema de PayClip.


**9. Sanciones y Responsabilidad del Usuario.**

El Usuario acepta y reconoce que no podrá hacer uso de ninguna acción o dispositivo software, u otro medio tendiente a interferir con el funcionamiento del Ecosistema PayClip. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en estos Términos y Condiciones por parte del Usuario o de cualquier otro tercero lo hará responsable de las acciones legales pertinentes, y a las sanciones previstas por este acuerdo, obligándose a pagar una indemnización por los daños y perjuicios ocasionados.

El Usuario reconoce y acepta que la utilización de los servicios Adelanto de Ventas Clip es de responsabilidad exclusiva de éste, por lo que se obliga a sacar en paz y a salvo a PayClip y a sus directores, gerentes, empleados, agentes, operarios, representantes y/o funcionarios respecto de cualquier queja, multa, denuncia, demanda y/o acción por parte de cualquier autoridad o tercero, derivado del uso negligente o inadecuado del Ecosistema PayClip.


**10. Entidades Financieras y Alianzas Comerciales.**

El Usuario es consciente de la participación de las Entidades Financieras cuya intervención es necesaria para la prestación del servicio Adelanto de Ventas Clip, quienes tienen sus propias políticas y condiciones de uso para el otorgamiento de crédito bajo cualquier modalidad. Por lo anterior, el Usuario acepta y reconoce que el uso que haga de los servicios Adelanto de Ventas Clip, así como la aceptación de sus políticas y condiciones de uso son responsabilidad del Usuario.

Como parte de una estrategia de negocios, PayClip establece alianzas comerciales con distintas Entidades Financieras, para efectos de que el Usuario, obtenga ofertas de crédito a través del servicio Adelanto de Ventas Clip. No obstante lo anterior, la Entidad Financiera es la única autorizada para aprobar el otorgamiento de algún crédito o la prestación de los servicios Adelanto de Ventas Clip, con independencia de las opiniones que al efecto emita PayClip, por lo que en caso de ser aprobado el Usuario debe asegurarse de entender los presentes Términos y Condiciones, así como los que de manera específica apliquen a los servicios Adelanto de Ventas Clip, según sea el caso.


**11. Jurisdicción y legislación aplicable.**

Estos Términos y Condiciones se regirán en todos sus puntos por las leyes vigentes en la República Mexicana. Para la interpretación, cumplimiento y ejecución de estos Términos y Condiciones, PayClip y el Usuario expresamente se someten a la legislación y jurisdicción de los Tribunales competentes en la Ciudad de México, renunciando en consecuencia a cualquier fuero que en razón de su domicilio presente o futuro pudiera corresponderles.

No obstante lo anterior, estos Términos y Condiciones se consideran como obligaciones adicionales e independientes a los derechos y obligaciones estipuladas en la legislación vigente y aplicable.`,
    r2: `**Términos y condiciones de uso para la Solicitud de Crédito "Adelanto de Ventas Clip"**  

Por este medio se le informa a los Usuarios los Términos y Condiciones de Uso para la Solicitud de Crédito Adelanto de Ventas Clip (los "Términos y Condiciones") que les serán aplicables por el simple uso o acceso que haga el Usuario al Ecosistema de PayClip mediante su ingreso y registro en las Aplicaciones, Sitios Web y/o Plataformas de PayClip para la solicitud de Adelanto de Ventas Clip. Los Términos y Condiciones serán aceptados por los Usuarios tanto de forma tácita por el simple uso o acceso antes referido, así como de forma expresa, a través de las manifestaciones de voluntad que realicen los Usuarios de tiempo en tiempo, por lo que manifiestan que han leído y entendido los presentes Términos y Condiciones y dan su consentimiento para obligarse en cumplimiento a los mismos.

El Usuario deberá tener en cuenta que los productos de crédito que se muestran en la Aplicación, son ofrecidos por Entidades Financieras que mantienen una alianza vigente con PayClip, por lo que, en todo lo relacionado con las condiciones del crédito deberán sujetarse a las condiciones y obligaciones adicionales que cada una de las Entidades Financieras estipule en el contrato que ampara el otorgamiento de un crédito.

Cualquier Usuario que no acepte estos Términos y Condiciones, así como el Aviso de Privacidad de PayClip, los cuales tienen un carácter obligatorio y vinculante deberán abstenerse de utilizar los servicios de Adelanto de Ventas Clip, sus Aplicaciones, Sitios Web y/o Plataformas.


**1. Definiciones.**

**"Aplicaciones"** son aquellas creadas o desarrolladas por PayClip, o de las cuales tiene los derechos para su uso y explotación, para efectos de la prestación del servicio Adelanto de Ventas Clip.

**"Datos de Registro"** significa la información que es proporcionada por el Usuario a través de las Aplicaciones, Sitios Web y/o Plataformas para acceder a Adelanto de Ventas Clip.

**"Ecosistema PayClip"** significa el conjunto de Aplicaciones, Sitios Web y/o Plataformas de PayClip y/o de las Entidades Financieras relacionadas entre sí para la prestación de los servicios Adelanto de Ventas Clip en favor del Usuario.

**"Entidades Financieras"** son aquellas personas morales cuya actividad habitual y profesional consiste en el otorgamiento de crédito y las cuales mantienen una relación comercial y de negocios con PayClip.

**"Firma Electrónica"** Los datos en forma electrónica consignados en un mensaje de datos, o adjuntados o asociados a éste, que identifican al Usuario como firmante en relación con el mensaje de datos.

**"PayClip"** significa PayClip, S. de R.L. de C.V., así como sus filiales, subsidiarias y/o controladoras.

**"Plataformas"** son aquellas desarrolladas por PayClip y las Entidades Financieras que permiten al Usuario, de manera virtual, la ejecución de sistemas para acceder a los servicios de Adelanto de Ventas Clip a través de Internet.

**"Adelanto de Ventas Clip"** significa el otorgamiento de un producto de crédito bajo cualquier modalidad en términos de lo previsto por la Ley General de Títulos y Operaciones de Créditos, por parte de las Entidades Financieras en favor de los Usuarios, previo análisis cualitativo y cuantitativo para determinar su perfil de riesgo.

**"Sitios Web"** son las páginas web creadas y administradas por PayClip y/o las Entidades Financieras que son accesibles a través de internet, y enlazadas para la prestación del servicio Adelanto de Ventas Clip.

**"Usuarios"** significa la persona física o moral, cliente de PayClip, que por sí o a través de cualquiera de sus apoderados y/o representantes legales lleve a cabo una solicitud formal para la contratación de Adelanto de Ventas Clip a través de la Entidad Financiera.


**2. Contratación de los servicios Adelanto de Ventas Clip.**

Los Usuarios podrán contratar los servicios Adelanto de Ventas Clip con las Entidades Financieras que, para tal efecto le hubiesen otorgado un oferta previo análisis cualitativo y cuantitativo para determinar su perfil de riesgo y viabilidad y, siempre que tengan capacidad legal para contratar y obligarse contractualmente con las Entidades Financieras. En el caso de Usuarios personas morales la persona que realice el ingreso y registro en las Aplicaciones, Sitios Web y/o Plataformas de PayClip y/o de las Entidades Financieras para el servicio de Adelanto de Ventas Clip deberán tener la representación legal con facultades para actos de administración en términos del segundo párrafo del artículo 2554 del Código Civil Federal, en relación con el artículo 2° del Código de Comercio, otorgado ante fedatario público y demás requeridas por la Entidad Financiera. La persona que realice el registro y/o que haga uso de las Aplicaciones, Sitios Web y/o Plataformas de PayClip y/o de las Entidades Financieras en representación de una persona moral, está dando por entendido que cuenta con facultades suficientes para actuar en nombre y por cuenta de ella y que dicha persona moral acepta adherirse a los presentes Términos y Condiciones.

Tanto PayClip como las Entidades Financieras se reservan el derecho de rechazar cualquier solicitud de contratación de los servicios Adelanto de Ventas Clip, incluso de negar el ingreso o registro en las Aplicaciones, Sitios Web y/o Plataformas o de suspender o cancelar cualquier prestación de servicios, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento en favor del Usuario.

Para poder hacer uso de los servicios Adelanto de Ventas Clip, el Usuario deberá cumplir con los requisitos transaccionales en PayClip para recibir un ofrecimiento de préstamo con base en su actividad transaccional, llenar el formulario de registro en el Ecosistema PayClip, mediante la Firma Electrónica (algunos de los socios la requieren) del mismo. Para registrarse es obligatorio completar el formulario de registro en todos sus campos con datos válidos e información personal exacta, precisa y verdadera.

Los Usuarios asumen el compromiso de actualizar los Datos de Registro conforme resulte necesario. PayClip no será responsable por la certeza de los Datos de Registro provistos. Los Usuarios garantizan y responden, en cualquier caso, por la veracidad, exactitud, integridad, vigencia y autenticidad de los Datos de Registro ingresados

PayClip y/o las Entidades Financieras se reservan el derecho de solicitar comprobantes y/o información adicional a efectos de corroborar la información proporcionada por los Usuarios en relación con los Datos de Registro, así como de suspender temporal o definitivamente los servicios Adelanto de Ventas Clip a aquellos Usuarios cuya información no pueda ser confirmada.

Los Usuarios se obligan a mantener la confidencialidad de su clave de ingreso al Ecosistema PayClip, siendo los únicos y exclusivos responsables por todas las operaciones efectuadas por sí o a través de sus apoderados o representantes legales


**3. De la integración del expediente del Usuario e información crediticia.**

El Usuario manifiesta bajo protesta de decir verdad que la información y documentación transmitida y proporcionada a PayClip y/o a las Entidades Financieras ya sea directamente o a través de cualquier persona autorizada por cualquier medio, es verdadera y no ha sido alterada, así como aquellas que prevean información para preservar las obligaciones en materia de prevención de lavado de dinero y financiamiento al terrorismo por parte de las Entidades Financieras, por lo que en caso contrario la misma será rechazada, obligándome a sacar a PayClip y a las Entidades Financieras en paz y a salvo de cualquier controversia. El Usuario será el único responsable de verificar la veracidad, validez e idoneidad de la documentación ingresada al Portal de Usuarios.

El Usuario está consciente que las Entidades Financieras (según sea el caso), por conducto de sus funcionarios facultados, ingrese cualquier documentación o información proporcionada por el Usuario por cualquier medio, lleve a cabo investigaciones sobre su comportamiento crediticio en las Sociedades de Información Crediticia que estime conveniente, lo anterior por haber proporcionado los datos que identifica al Usuario, previa autorización de este último de conformidad con las Décimo Primera regla de las Reglas Generales a las que deberán sujetarse las operaciones y actividades de las Sociedades de Información Crediticia y sus Usuarios Capítulo IV. Asimismo, el Usuario declara conocer la naturaleza y alcance de la información que solicitará PayClip y/o las Entidades Financieras a las Sociedades de Información Crediticia.


**4. Propiedad Intelectual**

El contenido dentro del Ecosistema PayClip relativo a las solicitudes y/o consultas del Adelanto de Ventas Clip, así como los programas, bases de datos, redes, archivos que permiten al Usuario acceder y hacer uso de los servicios, son de la titularidad y propiedad de PayClip, no obstante aquellas marcas que no sean de la titularidad de PayClip que se muestran en dicho portal han sido expresamente autorizadas para su uso y explotación y por consecuencia están protegidas por las normas vigentes y aplicables en materia de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito de PayClip.

El Usuario declara que las imágenes, logos y logotipos que podrá cargar en el Ecosistema PayClip, ya sea por sí mismo o por interpósita persona, son de su propiedad o cuenta con la autorización expresa para su utilización, por lo que el uso de las mismas no constituye la comisión de delitos en materia de derechos de autor tipificado en el artículo 424, fracción III del Código Penal Federal y sus correlativos en los demás Códigos Penales de la Ciudad de México y de los Estados de la República, por lo que autoriza a PayClip y las Entidades Financieras a la utilización de las imágenes, logos y logotipos de conformidad con lo dispuesto en los presente Términos y Condiciones y el Aviso de Privacidad.


**5. Límite de responsabilidad.**

Bajo ninguna circunstancia PayClip será responsable en caso de que las Entidades Financieras se encuentren total o parcialmente imposibilitadas para prestar los servicio Adelanto de Ventas Clip, cuando lo anterior sea motivo de caso fortuito, insolvencia, fuerza mayor o cualquier otra circunstancia que se encuentre fuera del control de la Entidad Financiera.

Asimismo, PayClip no será responsable ante el Usuario, cuando por caso fortuito o fuerza mayor la Entidad Financiera se encuentre impedida para cumplir de forma parcial con la oferta de Adelanto de Ventas Clip.

En virtud de lo anterior, el Usuario renuncia explícitamente a la protección legal que resulte, directa o indirectamente, de caso fortuito o fuerza mayor conforme a lo estipulado en el artículo 2,111 del Código Civil Federal y sus correlativos de los Códigos Civiles para la Ciudad de México y los de los otros Estados de la República Mexicana.

Cualquier reclamación que surja de la prestación de los servicios de Adelanto de Ventas Clip, deberá ser exclusivamente dirigida y resuelta por la Entidad Financiera que corresponda. En ese sentido, bajo ninguna circunstancia PayClip será responsable por cualquier hecho u omisión por parte de las Entidades Financieras que afecte a los Usuarios, siendo este último el único obligado para su atención.


   **6. Datos Personales.**

El Usuario autoriza a PayClip a tratar los Datos Personales que en el desarrollo y ejecución de estos Términos y Condiciones sean recabados, con la finalidad de cumplir con los mismos y en general, todo lo necesario para el desarrollo y ejecución de los servicios y subsecuentes actos. Los Datos Personales que los Usuarios transmitan a PayClip no podrán ser utilizados para fines distintos a los previstos en su Aviso de Privacidad y en estos Términos y Condiciones.

Se le informa al Cliente que, como titular de los Datos Personales, le asisten los siguientes derechos:

a)  Conocer, actualizar y rectificar sus Datos Personales frente a los responsables o encargados;

b)  Solicitar prueba de la autorización otorgada a los responsables o encargados;

c)  Ser informado por los responsables o encargados, previa solicitud, respecto del uso que les ha dado a sus Datos Personales y;

d)  Revocar la autorización y/o solicitar la supresión de los Datos Personales cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales.



**7. Confidencialidad.**

PayClip se obliga a mantener la confidencialidad de la información que reciba por parte del Usuario por cualquier medio, ya sea de manera verbal, escrita, gráfica o electrónica, y que tenga dicho carácter conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos y a no compartirlo con cualquier persona o entidad, excepto sus afiliadas, subsidiarias, cesionarios, partes relacionadas y terceros autorizados para tal efecto, conforme a lo establecido en el Aviso de Privacidad de PayClip.


**8. Modificaciones.**

PayClip podrá realizar cambios y actualizaciones en los presentes Términos y Condiciones en cualquier momento, haciendo públicos los términos modificados en el Sitio Web. Todos los términos modificados entrarán en vigor al siguiente día de su publicación. El Usuario dentro de los 5 (cinco) días naturales siguientes a la publicación de los cambios introducidos deberá comunicar a PayClip la negativa de aceptación de los mismos; en este supuesto quedará cancelado el registro de Usuario y será inhabilitado del Ecosistema de PayClip.


**9. Sanciones y Responsabilidad del Usuario.**

El Usuario acepta y reconoce que no podrá hacer uso de ninguna acción o dispositivo software, u otro medio tendiente a interferir con el funcionamiento del Ecosistema PayClip. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en estos Términos y Condiciones por parte del Usuario o de cualquier otro tercero lo hará responsable de las acciones legales pertinentes, y a las sanciones previstas por este acuerdo, obligándose a pagar una indemnización por los daños y perjuicios ocasionados.

El Usuario reconoce y acepta que la utilización de los servicios Adelanto de Ventas Clip es de responsabilidad exclusiva de éste, por lo que se obliga a sacar en paz y a salvo a PayClip y a sus directores, gerentes, empleados, agentes, operarios, representantes y/o funcionarios respecto de cualquier queja, multa, denuncia, demanda y/o acción por parte de cualquier autoridad o tercero, derivado del uso negligente o inadecuado del Ecosistema PayClip.


**10. Entidades Financieras y Alianzas Comerciales.**

El Usuario es consciente de la participación de las Entidades Financieras cuya intervención es necesaria para la prestación del servicio Adelanto de Ventas Clip, quienes tienen sus propias políticas y condiciones de uso para el otorgamiento de crédito bajo cualquier modalidad. Por lo anterior, el Usuario acepta y reconoce que el uso que haga de los servicios Adelanto de Ventas Clip, así como la aceptación de sus políticas y condiciones de uso son responsabilidad del Usuario.

Como parte de una estrategia de negocios, PayClip establece alianzas comerciales con distintas Entidades Financieras, para efectos de que el Usuario, obtenga ofertas de crédito a través del servicio Adelanto de Ventas Clip. No obstante lo anterior, la Entidad Financiera es la única autorizada para aprobar el otorgamiento de algún crédito o la prestación de los servicios Adelanto de Ventas Clip, con independencia de las opiniones que al efecto emita PayClip, por lo que en caso de ser aprobado el Usuario debe asegurarse de entender los presentes Términos y Condiciones, así como los que de manera específica apliquen a los servicios Adelanto de Ventas Clip, según sea el caso.


**11. Jurisdicción y legislación aplicable.**

Estos Términos y Condiciones se regirán en todos sus puntos por las leyes vigentes en la República Mexicana. Para la interpretación, cumplimiento y ejecución de estos Términos y Condiciones, PayClip y el Usuario expresamente se someten a la legislación y jurisdicción de los Tribunales competentes en la Ciudad de México, renunciando en consecuencia a cualquier fuero que en razón de su domicilio presente o futuro pudiera corresponderles.

No obstante lo anterior, estos Términos y Condiciones se consideran como obligaciones adicionales e independientes a los derechos y obligaciones estipuladas en la legislación vigente y aplicable.
      `,
  },
};
