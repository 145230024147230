import { SET_CLEVERTAP_LAST_EVENT } from '../actions/Types';

const initialState = {
  lastEvent: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CLEVERTAP_LAST_EVENT:
      return { ...state, lastEvent: action.payload };
    default:
      return state;
  }
}
