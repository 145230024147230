export const styles = (theme) => {
  const rootStyles = {
    container: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1.5),
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(1),
      boxSizing: 'border-box',
    },
    bottomContainer: {
      padding: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(3),
    },
    searchInput: {
      height: theme.spacing(6.25),
      padding: 0,
    },
    input: {
      padding: `${theme.spacing(2.25)}px 
          ${theme.spacing(0.25)}px 
          ${theme.spacing(2)}px 
          ${theme.spacing(1.5)}px !important`,
      fontSize: theme.spacing(2),
    },
    inputIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    positionEnd: {
      transform: `translate(${theme.spacing(1)}px, ${theme.spacing(
        2.375,
      )}px) scale(1)`,
    },
    speiOutButton: {
      width: '100%',
      height: theme.spacing(6.25),
      padding: 0,
      marginBottom: theme.spacing.unit,
      display: 'flex',
      justifyContent: 'center',
    },
    actionButtonIcon: {
      margin: 'auto 0',
      color: theme.palette.orange.base,
      marginRight: theme.spacing(1.3),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    adornment:{
      '& p': {
        marginBottom: 0,
      },
    },
    actionButtonText: {
      margin: 'auto 0',
      marginLeft: theme.spacing(1),
    },
    contactsTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      fontSize: theme.spacing(2.25),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    actionTitle: {
      fontSize: theme.spacing(3),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    cardTitle: {
      fontSize: theme.spacing(2),
      fontWeight: '500',
    },
    cardInfo: {
      fontSize: theme.spacing(1.75),
    },
    card: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    cardButton: {
      justifyContent: 'flex-end',
      marginLeft: 'auto',
    },
    cardButtonEdit: {
      stroke: theme.palette.orange.base,
      strokeWidth: 2,
      color: 'transparent',
      minWidth: 0,
    },
    cardButtonDelete: {
      color: theme.palette.indigo.l20,
      marginLeft: 'auto',
      marginRight: theme.spacing(2.5),
      minWidth: 0,
    },
    disabled: {
      color: theme.palette.indigo.l80,
    },
  };
  return { ...rootStyles };
};
