const styles = (theme) => ({
  container: {
    minHeight: '100vh',
    minWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  img: {
    marginTop: '30%',
  },
  imgSe: {
    marginTop: '20%',
  },
  text: {
    marginTop: '10%',
  },
  title1: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    color: '#001724',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    padding: '0 20%',
  },
  sub: {
    margin: '10% 16px',
  },
  subtitile: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#001724',
  },
  subtitile2: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '17px',
    textAlign: 'center',
    margin: '0 16px',
  },
  btnContainer: {
    position: 'fixed',
    width: 'calc(100% - 2rem)',
    left: '1rem',
    bottom: '1rem',
    marginBottom: '60px',
  },
  btnContainerSe: {
    position: 'fixed',
    width: 'calc(100% - 2rem)',
    left: '1rem',
    bottom: '1rem',
  },
  btn: {
    width: '100%',
  },
});

export default styles;
