import { entityTypes } from 'constants/AppConstants';
import moment from 'moment-timezone';
import {
  INVOICES_GET_REQUEST_HISTORY,
  INVOICES_GET_TAX_PAYER_INFO,
  INVOICES_SET_TAX_PAYER_INFO,
  INVOICES_SWITCH_MONTHLY_REQUESTS,
  INVOICES_SET_HISTORY_PAGINATION,
  INVOICES_SET_CURRENT_REQUEST,
  INVOICES_SET_ZIPCODE_VALIDITY,
  INVOICES_SET_CFDI_OPTIONS,
  INVOICES_SET_TAX_REGIME_OPTIONS,
} from './types';

const initialState = {
  monthlyInvoicesEnabled: false,
  taxPayerId: null,
  legalEntityName: '',
  legalEntityLastName: '',
  legalEntitySecondLastName: '',
  currentRequest: null,
  history: [],
  zipCode: { valid: false, current: '' },
  cfdi: {
    current: '',
    options: [],
  },
  taxRegime: {
    current: '',
    options: [],
  },
  historyPagination: {
    totalRows: 0,
    rowsPerPage: 0,
    currentPage: 0,
    totalPages: 0,
  },
};

const actionHandlers = new Map([
  [INVOICES_SWITCH_MONTHLY_REQUESTS, switchMonthlyRequests],
  [INVOICES_GET_TAX_PAYER_INFO, getTaxPayerInfo],
  [INVOICES_SET_TAX_PAYER_INFO, setTaxPayerInfo],
  [INVOICES_GET_REQUEST_HISTORY, getRequestHistory],
  [INVOICES_SET_HISTORY_PAGINATION, setHistoryPagination],
  [INVOICES_SET_CURRENT_REQUEST, setCurrentRequest],
  [INVOICES_SET_ZIPCODE_VALIDITY, setZipCodeValidity],
  [INVOICES_SET_CFDI_OPTIONS, setCFDIOptions],
  [INVOICES_SET_TAX_REGIME_OPTIONS, setTaxRegimeOptions],
]);

export default (state = initialState, action = { type: null }) =>
  actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;

function switchMonthlyRequests(state, action) {
  const { is_recurrent: monthlyInvoicesEnabled } = action.payload.data;
  if (typeof monthlyInvoicesEnabled !== 'boolean') return state;
  return { ...state, monthlyInvoicesEnabled };
}

function getTaxPayerInfo(state, action) {
  const {
    tax_payer_id: taxPayerId,
    legal_entity_name: legalEntityName,
    legal_entity_last_name: legalEntityLastName,
    legal_entity_second_last_name: legalEntitySecondLastName,
    fiscal_address_postal_code: zipCode,
    sat_tax_regime_code: taxRegime,
    sat_invoice_use_code: cfdi,
  } = action.payload.data;

  const entityType =
    taxPayerId.length === entityTypes.moralLength
      ? entityTypes.MORAL
      : entityTypes.PHYSICAL;

  if (
    typeof taxPayerId !== 'string' ||
    typeof legalEntityName !== 'string' ||
    typeof entityType !== 'string'
  )
    return state;
  return {
    ...state,
    taxPayerId,
    legalEntityName,
    legalEntityLastName,
    legalEntitySecondLastName,
    entityType,
    zipCode: {
      ...state.zipCode,
      current: zipCode,
    },
    taxRegime: {
      ...state.taxRegime,
      current: taxRegime,
    },
    cfdi: {
      ...state.cfdi,
      current: cfdi,
    },
  };
}

function setTaxPayerInfo(state, action) {
  const {
    tax_payer_id: taxPayerId,
    legal_entity_name: legalEntityName,
    legal_entity_last_name: legalEntityLastName,
    legal_entity_second_last_name: legalEntitySecondLastName,
    entity_type: entityType,
    fiscal_address_postal_code: zipCode,
    sat_tax_regime_code: taxRegime,
    sat_invoice_use_code: cfdi,
  } = action.payload.data;
  if (
    typeof taxPayerId !== 'string' ||
    typeof legalEntityName !== 'string' ||
    typeof entityType !== 'string'
  )
    return state;
  return {
    ...state,
    taxPayerId,
    legalEntityName: legalEntityName.trim(),
    legalEntityLastName: legalEntityLastName.trim(),
    legalEntitySecondLastName: legalEntitySecondLastName.trim(),
    entityType,
    zipCode: { ...state.zipCode, current: zipCode },
    taxRegime: { ...state.taxRegime, current: taxRegime },
    cfdi: { ...state.cfdi, current: cfdi },
  };
}

function getRequestHistory(state, action) {
  const history = action.payload.data.requests;
  if (!Array.isArray(history)) return state;
  const resp =
    history.length &&
    moment(history[0].requested_date).isSame(moment(), 'month')
      ? { currentRequest: history[0] }
      : { currentRequest: null };
  return { ...state, history, ...resp };
}

function setHistoryPagination(state, action) {
  const { historyPagination } = action.payload.data;
  if (typeof historyPagination !== 'object') return state;
  return { ...state, historyPagination };
}

function setCurrentRequest(state, action) {
  const currentRequest = action.payload.data;
  if (typeof currentRequest !== 'object') return state;
  return { ...state, currentRequest };
}


function setZipCodeValidity(state, action) {
  return {
    ...state,
    zipCode: { ...state.zipCode, valid: action.payload.valid },
  }
}

function setCFDIOptions(state, action) {
  return {
    ...state,
    cfdi: { ...state.cfdi, options: action.payload  },
  }
}

function setTaxRegimeOptions(state, action) {
  return {
    ...state,
    taxRegime: { ...state.taxRegime, options: action.payload  },
  }
}
