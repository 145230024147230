import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Button from 'components/npm/clip-ui/Button';

export const BlueCheckbox = withStyles({
  root: {
    color: '#0074B7',
    '&$checked': {
      color: '#0074B7',
    },
  },
  checked: {},
})(Checkbox);

export const BlueButton = (props) => {
  return <Button type="submit" fullWidth {...props} />;
};
