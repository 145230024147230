export default {
  terms: '¡Comencemos!',
  link: 'Términos y Condiciones de Cacao Paycard Solutions S.A de C.V',
  info: 'Para crear tu cuenta digital Clip Card es necesario aceptar los %link%, nuestro aliado en tecnología financiera.',
  accept: 'Acepto',
  deny: 'No acepto',
  sorry: 'Lo sentimos',
  needsToAccept: 'Para continuar debes aceptar los Términos y Condiciones.',
  understood: 'Entendido',
};
