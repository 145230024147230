import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiCard from '@material-ui/core/Card';
import MuiCardHeader from '@material-ui/core/CardHeader';
import MuiCardContent from '@material-ui/core/CardContent';

import styles from './styles';

const Card = ({
  action,
  children,
  classes: {
    headerRoot,
    headerAction,
    headerContent,
    headerTitle,
    headerSubheader,
    contentRoot,
    ...otherClasses
  },
  title,
  subtitle,
  ...otherProps
}) => (
  <MuiCard {...otherProps} classes={otherClasses}>
    {title && (
      <MuiCardHeader
        title={title}
        subheader={subtitle}
        action={action}
        classes={{
          root: headerRoot,
          action: headerAction,
          content: headerContent,
          title: headerTitle,
          subheader: headerSubheader,
        }}
      />
    )}
    <MuiCardContent classes={{ root: contentRoot }}>{children}</MuiCardContent>
  </MuiCard>
);

Card.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({}),
  title: PropTypes.node,
  subtitle: PropTypes.node,
};

Card.defaultProps = {
  action: undefined,
  classes: {},
  title: undefined,
  subtitle: undefined,
};

export default withStyles(styles, { name: 'ClipCard' })(Card);
