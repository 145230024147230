import React from 'react';
import MuiBottomNavigation from '@material-ui/core/BottomNavigation';
import { withStyles } from '@material-ui/core/styles';
import { any, bool, func, node, shape } from 'prop-types';

import Styled from './styled';

const BottomNavigation = ({ children, ...rest }) => (
  <MuiBottomNavigation {...rest}>{children}</MuiBottomNavigation>
);

BottomNavigation.propTypes = {
  children: node,
  classes: shape({}),
  onChange: func,
  showLabels: bool,
  value: any,
};

BottomNavigation.defaultProps = {
  children: null,
  classes: {},
  onChange: null,
  showLabels: false,
  value: null,
};

export default withStyles(Styled)(BottomNavigation);
