const styles = {
  root: {},
  label: {},
  flatPrimary: {},
  flatSecondary: {},
  outlined: {},
  colorInherit: {},
  raised: {},
  raisedPrimary: {},
  raisedSecondary: {},
  focusVisible: {},
  disabled: {},
  fab: {},
  mini: {},
  sizeSmall: {},
  sizeLarge: {},
  fullWidth: {},
};

export default styles;
