export default {
  title: 'Enviaremos tu Clip Card a:',
  street: 'Calle',
  number: 'Número exterior',
  interior: 'Número interior - opcional',
  zipCode: 'Código Postal',
  colony: 'Colonia',
  city: 'Ciudad',
  municipality: 'Alcaldía o municipio',
  state: 'Estado',
  submitButton: 'Siguiente',
  title2: 'Información de contacto:',
  name: 'Nombre',
  lastName: 'Apellido paterno',
  secondLastName: 'Apellido materno',
  mobile: 'Teléfono',
};
