import React, { useEffect, Fragment } from 'react';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';

import { getPrestaClipNameChange } from 'components/loans/redux/selectors';
import {
  Forms,
  LoansCurrentProductName,
  LoansNewProductName,
} from 'constants/AppConstants';
import { showINEorPassport } from 'constants/ProviderCodes';

import {
  required,
  isAlphanumeric,
  maxLength,
  isEmpty,
  isValidRFC,
  isValidCURP,
} from 'helpers/validate';
import { loadBankAccountInformation } from 'components/account/redux/actions';
import ClevertapField from '../../../../forms/ClevertapField';
import { EVENTS } from '../../../../../helpers/Clevertap';
import TextField from 'helpers/FormUtils/TextField';
import { isInvalidFormField } from 'helpers/FormUtils';
import LockIcon from '@material-ui/icons/Lock';
import urls from 'helpers/urls';
import { lenderPayload } from '../../../Commons/Clevertap';
import {
  StepAlert,
  StepTitle,
  StepDescription,
  StepSection,
  FormControl,
} from './styled';
import text from './text';
import PropTypes from 'prop-types';

const DepositStep = (props) => {
  const browserHistory = useLocation();
  const enableSubmitButton = () => {
    const { rfc, curp, handleDepositStep } = props;

    let submitButtonEnabled = true;
    if (isInvalidFormField(rfc)) {
      submitButtonEnabled = false;
    }
    if (showINEorPassport() && isInvalidFormField(curp)) {
      submitButtonEnabled = false;
    }

    handleDepositStep(submitButtonEnabled);
  };

  useEffect(() => {
    enableSubmitButton();
    if (isEmpty(props.bankAccountNumber)) props.loadBankAccountInformation();
  }, []);

  const showINEORPassport = () =>
    showINEorPassport(props.location.pathname.toUpperCase());

  const {
    curp,
    rfc,
    bankAccountNumber,
    bankName,
    owner,
    preapprovalSelected,
    showCashAdvance,
    loansFormVersion,
  } = props;

  const isMobileWebView = browserHistory.pathname.startsWith(urls.mobile);

  return (
    <Fragment>
      <StepAlert>{text.advice}</StepAlert>

      <StepTitle>{text.title}</StepTitle>
      <StepDescription>{text.description}</StepDescription>

      <FormControl fullWidth>
        <ClevertapField
          name="rfc"
          component={TextField}
          inputProps={{ maxLength: 13 }}
          type="text"
          label={text.rfcLabel}
          validate={[required, isAlphanumeric, isValidRFC]}
          helperText={maxLength(13)(rfc)}
          clevertap={{
            listener: 'onChange',
            event: EVENTS.LOANS.RFC_CHANGED,
            payload: lenderPayload(preapprovalSelected, {
              showCashAdvance,
              loansFormVersion,
            }),
          }}
        />

        {showINEORPassport() && (
          <Field
            name="curp"
            component={TextField}
            inputProps={{ maxLength: 18 }}
            type="text"
            label={text.curpLabel}
            validate={[required, isAlphanumeric, isValidCURP]}
            helperText={maxLength(18)(curp)}
          />
        )}
      </FormControl>

      <StepDescription>{text.confirmSection}</StepDescription>
      <StepSection>
        {!isMobileWebView && (
          <Fragment>
            {text.confirmSubsection}{' '}
            <a href={text.dataAdviceLink}>{text.confirmSubsectionRef}</a>
          </Fragment>
        )}
        {isMobileWebView && text.dataAdvice}
      </StepSection>
      <FormControl fullWidth>
        <Field
          name="ownerCLABE"
          component={TextField}
          inputProps={{ readOnly: true }}
          type="text"
          label={text.ownerLabel}
          props={{ value: owner }}
          meta={{ touched: true }}
          endAdornment={<LockIcon />}
          disabled
        />

        <Field
          name="bankCLABE"
          component={TextField}
          inputProps={{ readOnly: true }}
          type="text"
          label={text.bankLabel}
          props={{ value: bankName }}
          meta={{ touched: true }}
          endAdornment={<LockIcon />}
          disabled
        />

        <Field
          name="interbankCLABE"
          component={TextField}
          inputProps={{ readOnly: true }}
          type="text"
          label={text.clabeLabel}
          props={{ value: bankAccountNumber }}
          meta={{ touched: true }}
          endAdornment={<LockIcon />}
          disabled
        />
      </FormControl>
    </Fragment>
  );
};
DepositStep.propTypes = {
  loadBankAccountInformation: PropTypes.func.isRequired,
  handleDepositStep: PropTypes.func.isRequired,
  prestaClipNameChange: PropTypes.bool.isRequired,
};

const selector = formValueSelector(Forms.MR_PRESTA);

const mapStateToProps = (state) => {
  return {
    curp: selector(state, 'curp'),
    rfc: selector(state, 'rfc'),
    bankAccountNumber: state.accountInfo.bankInfo.bankAccountNumber,
    bankName: state.accountInfo.bankInfo.bankName,
    owner: state.accountInfo.bankInfo.bankAccountName,
    prestaClipNameChange: getPrestaClipNameChange(state),
    preapprovalSelected: state.loans?.request?.preapproval,
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  loadBankAccountInformation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DepositStep),
);
