const styles = (theme) => {
    return {
      root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        backgroundColor: '#FFFFFF',
        overflow: 'auto',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        //paddingBottom: 5,
        boxSizing: 'border-box',
      },
      title: {
        fontSize: '16px',
      },
      message: {
        fontSize: '14px',
        textAlign: 'center',
      },
      lockButton: {
        backgroundColor: '#FDF6EA',
        borderRadius: 8,
        marginBottom: theme.spacing.unit * 2.3,
        marginTop: theme.spacing.unit * 2.5,
      },
      lockIcon: {
        color: '#FFBA47',
      },
      digitsContainer: {
        display: 'grid',
        gridAutoFlow: 'columns',
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(5, 1fr)',
        columnGap: '1em',
        width: theme.spacing.unit * 11,
        margin: 'auto',
        paddingTop: theme.spacing.unit / 2,
        marginBottom: theme.spacing.unit * 3,
      },
      pinDigit: {
        textAlign: 'center',
        backgroundColor: 'white',
        border: '1px solid #e6e8e9',
        borderRadius: 8,
        width: 48,
        height: 48,
        lineHeight: '45px',
        margin: 0,
        padding: 0,
        fontWeight: 'bold',
        color: '#FC4C02',
      },
      progressBarRoot: {
        width: '90%',
        height: '200px',
        margin: 'auto auto',
        flex: 1,
      },
    };
  };
  
  export default styles;