import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipTypography } from '@clipmx/clip-storybook';
import clsx from 'clsx';

import blueBox from '../../../../../assets/images/berlin/blue-box.svg';
import blueHand from '../../../../../assets/images/berlin/blue-hand.svg';
import blueTruck from '../../../../../assets/images/berlin/blue-truck.svg';
import greyHand from '../../../../../assets/images/berlin/grey-hand.svg';
import greyTruck from '../../../../../assets/images/berlin/grey-truck.svg';
import redTruck from '../../../../../assets/images/berlin/red-truck.svg';

import { styles } from './styles';

const useStyles = makeStyles(styles);
const OrderSteps = (props) => {
  const classes = useStyles(props);
  const { text, step } = props;

  let shippedSrc = greyTruck;
  let shippedColor = '';

  if (step > 1) {
    shippedSrc = blueTruck;
    shippedColor = classes.blue;
  }

  return (
    <>
      <ClipTypography
        variant={'subtitle'}
        component={'h2'}
        align={'center'}
        fontWeight={'bold'}
      >
        {text.info}
      </ClipTypography>

      <div className={classes.stepsContainer}>
        <hr className={classes.line} />
        <div className={classes.steps}>
          <div className={classes.stepContainer}>
            <img width="40" height="40" src={blueBox} alt={'PENDING'} />
            <ClipTypography
              variant={'body2'}
              component={'p'}
              fontWeight={step === 1 ? 'semiBold' : 'normal'}
              classes={{
                root: clsx(classes.stepText, step > 0 && classes.blue),
              }}
            >
              {text.processing}
            </ClipTypography>
          </div>
          <div
            className={clsx(
              classes.stepLine,
              step !== 1 && classes.activeStepLine,
            )}
          />
          <div className={classes.stepContainer}>
            <img
              width="40"
              height="40"
              src={step === 4 ? redTruck : shippedSrc}
              alt={'SHIPPED'}
            />
            <ClipTypography
              variant={'body2'}
              component={'p'}
              fontWeight={step === 2 || step === 4 ? 'semiBold' : ''}
              classes={{
                body2: clsx(
                  classes.stepText,
                  step === 4 ? classes.red : shippedColor,
                ),
              }}
            >
              {step === 4 ? text.notFound : text.onItsWay}
            </ClipTypography>
          </div>
          <div
            className={clsx(
              classes.stepLine,
              step === 3 && classes.activeStepLine,
            )}
          />
          <div className={classes.stepContainer}>
            <img
              width="40"
              height="40"
              src={step === 3 ? blueHand : greyHand}
              alt={'DELIVERED'}
            />
            <ClipTypography
              variant={'body2'}
              component={'p'}
              fontWeight={step === 3 ? 'semiBold' : ''}
              classes={{
                body2: clsx(classes.stepText, step === 3 ? classes.blue : ''),
              }}
            >
              {text.delivered}
            </ClipTypography>
          </div>
        </div>
      </div>
    </>
  );
};
OrderSteps.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.object,
  step: PropTypes.number,
};
OrderSteps.defaultProps = {
  classes: {},
  text: {},
  step: 1,
};
export default OrderSteps;
