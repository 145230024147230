import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import GoogleTagManager from '../middleware/googleTagManager';
import apiResolveMiddleware from '../middleware/apiResolve';
import rootReducer from '../reducers';

// Apply middlewares
const debug = process.env.NODE_ENV !== 'production';
const middlewares = [thunk, GoogleTagManager, apiResolveMiddleware];

let composeEnhancers = compose;
if (debug) {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    // use devtool if extension is installed
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  } else {
    // default to redux logger
    middlewares.push(createLogger());
  }
}

// Create Redux Store
const reduxStore = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares)),
);

export default reduxStore;
