import axios from 'axios';
import { app } from 'config/config';
import { HTTPStatusCodes } from 'constants/AppConstants';
import apiPaths from 'helpers/apiPaths';
import { getRequestOptions } from 'helpers/fetchUtil';


class PaymentRequestService {
  static async sendPaymentRequest(payment) {
    const url = `${app.apiUrlv2}${apiPaths.paymentRequest}`;
    const config = getRequestOptions();
    try {
      const response = await axios.post(url, payment, config);
      if (response.status === HTTPStatusCodes.OK) {
        return response.data;
      }
    } catch (err) {
      return err.response;
    }
  }

  static async getPaymentRequest(page, perPage) {
    const url = `${app.apiUrlv2}${apiPaths.paymentRequest}?page_number=${page}&page_size=${perPage}`;
    const config = getRequestOptions();
    const response = await axios.get(url, config);
    if (response.status === HTTPStatusCodes.OK) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Invalid status: ${response.status}`);
    }
  }

  static async getFilteredPaymentRequest(params) {
    const url = `${app.apiUrlv2}${apiPaths.paymentRequest}/paymentrequests/?${params}`;
    const config = getRequestOptions();
    const response = await axios.get(url, config);
    if (response.status === HTTPStatusCodes.OK) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Invalid status: ${response.status}`);
    }
  }

  static async paymentRequestFeatureFlag() {
    const url = `${app.apiUrlv2}${apiPaths.paymentRequestFeatureFlag}`;
    const config = getRequestOptions();
    const response = await axios.get(url, config);
    if (response.status === HTTPStatusCodes.OK) {
      return response.data;
    } else {
      throw new Error(
        'An error ocurred trying to ask if the user is allowed to use the payment request feature',
      );
    }
  }

  static async cancelPaymentRequest(paymentRequest) {
    const url = `${app.apiUrlv2}${apiPaths.paymentRequest}/${paymentRequest}`;
    const config = getRequestOptions();
    const response = await axios.delete(url, config);
    if (response.status === HTTPStatusCodes.OK) {
      return response;
    } else {
      throw new Error('An error ocurred trying to cancel the payment request');
    }
  }

  static async resendEmail(paymentRequest) {
    const url = `${app.apiUrlv2}${apiPaths.paymentRequest}/${paymentRequest}`;
    const config = getRequestOptions();
    const response = await axios.patch(url, {}, config);
    if (response.status === HTTPStatusCodes.OK) {
      return response.data;
    } else {
      throw new Error('An error ocurred trying to resend the payment request');
    }
  }

  static async filterPaymentRequest(searchType, searchParams, pageSize, page) {
    const config = getRequestOptions();
    const paramsMap = new Map();
    paramsMap.set('page_number', page);
    paramsMap.set('page_size', pageSize);

    const typeUrl = (type) => {
      return {
        email: `?&consumer_email=${searchParams.get('email')}&ecommerce=true`,
        amount: `?&amount=${searchParams.get('amount')}&ecommerce=true`,
        dateRange: `?&from=${new Date(
          searchParams.get('from'),
        ).toISOString()}&to=${new Date(
          searchParams.get('to'),
        ).toISOString()}&ecommerce=true`,
        folio: `?&payment_request_code=${searchParams.get(
          'folio',
        )}&ecommerce=true`,
        status: `?&status_code=${searchParams.get('status')}&ecommerce=true`,
      }[type];
    };
    if (searchParams.get('status') > 3) {
      let defaultUrl = new URL(`${app.apiUrlv2}${apiPaths.paymentRequest}`);
      paramsMap.forEach((value, key) =>
        defaultUrl.searchParams.set(key, value),
      );
      const response = await axios.get(defaultUrl, config);
      if (response.status === HTTPStatusCodes.OK) {
        return response.data;
      }
    } else {
      let url = new URL(
        `${app.apiUrlv2}${apiPaths.paymentRequest}${
          apiPaths.paymentRequestFilters
        }/${typeUrl(searchType)}`,
      );
      paramsMap.forEach((value, key) => url.searchParams.set(key, value));
      const response = await axios.get(url, config);
      if (response.status === HTTPStatusCodes.OK) {
        return response.data;
      }
    }
  }

  static async getUserInfo() {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.userInfo}`;

    const userResponse = await axios.get(url, options);
    if (userResponse.status === HTTPStatusCodes.OK) {
      return userResponse.data;
    }
  }

  static async handleMlStatus(payment, action) {
    const url =
      `${app.apiUrlv2}${apiPaths.paymentRequest}${apiPaths.paymentMLStatus}?`;

    const params = new URLSearchParams({
      payment_request_code: payment,
      action: action,
    }).toString();

    const config = getRequestOptions();

    const response = await axios.patch(`${url}${params}`, {}, config);
    if (response.status === HTTPStatusCodes.OK) {
      return response.data;
    } else {
      throw new Error('An error ocurred trying to resend the payment request');
    }
  }

  static async getKycInfo() {
    const url = `${app.apiUrlv2}${apiPaths.kycPath}`
    const config = getRequestOptions();

    const response = await axios.get(`${url}?version=1`, config);
    if (response.status === HTTPStatusCodes.OK) {
      return response;
    }
  }

  static async getCount() {
    const url = `${app.apiUrlv2}/pad/merchant/count-remote-payments`;
    const config = getRequestOptions();
    const response = await axios.get(url, config);
    if (response.status === HTTPStatusCodes.OK) {
    return response.data;
    } else {
      throw new Error(`Invalid status: ${response.status}`);
    }
  }

  static async getPublicProfileInfo(data) {
    const url = `${app.apiUrlv2}${apiPaths.getPublicProfile}?public_profile_request_type=MERCHANT_PUBLIC_PROFILE_GET_BY_MERCHANT_ID`;
    const config = getRequestOptions();
    const response = await axios.get(url, config);
    if (response.status === HTTPStatusCodes.OK) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Invalid status: ${response.status}`);
    }
  }

  static async updatePublicProfileData(data){

    const url = `${app.apiUrlv2}${apiPaths.createPublicProfile}`;
    const options = getRequestOptions();

    const params= {
        public_name: data.public_name,
        alias: data.alias,
        public_description:data.public_description,
        image: data.image,
        public_profile_request_type: 'MERCHANT_PUBLIC_PROFILE_UPDATE',
        profile_config: {
          profile_active: data.profile_config.profile_active,
          show_address: data.profile_config.show_address,
          show_email: data.profile_config.show_email,
          show_phone: data.profile_config.show_phone,
        },
    }
    try {
      const response = await axios.post(url, params, options);
      if (response.status === HTTPStatusCodes.OK) {
        return response.data;
      }
    } catch (err) {
      return err.response;
    }
  };
}

export default PaymentRequestService;
