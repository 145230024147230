import styled from '@emotion/styled';
import { ProcessButtonv3 as CommonSubmitButton } from 'components/common/Buttons';

export const Container = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 90}px`,
  margin: '0 auto',
  [theme.breakpoints.down('xs')]: {
    margin: 0,
    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
  },
}));

export const ExpireWarning = styled.div(({ theme }) => ({
  display: 'block',
  width: `calc(100% - ${theme.spacing.unit * 0.75}px)`,
  height: `${theme.spacing.unit * 5}px`,
  margin: `${theme.spacing.unit * 2}px 0`,
  borderRadius: `${theme.spacing.unit * 0.5}px`,
  border: 'solid 1px rgba(0, 125, 142, 0.08)',
  textAlign: 'left',
  borderLeft: 'solid 4px #007d8e',
  backgroundColor: 'rgba(0, 125, 142, 0.06)',
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    height: `${theme.spacing.unit * 6.25}px`,
    margin: `${theme.spacing.unit * 2}px`,
    marginLeft: `${theme.spacing.unit * 1.5}px`,
  },
}));

export const ExpireWarningContent = styled.div(({ theme }) => ({
  display: 'inline-block',
  marginTop: `${theme.spacing.unit * 1.5}px`,
  marginBottom: `${theme.spacing.unit * 1.5}px`,
  fontSize: `${theme.spacing.unit * 1.75}px`,
  color: '#007d8e',
  [theme.breakpoints.down('xs')]: {
    width: '80%',
    marginTop: `${theme.spacing.unit * 1}px`,
    marginBottom: `${theme.spacing.unit * 1}px`,
  },
}));

export const HourglassTop = styled.img(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  marginRight: `${theme.spacing.unit * 0.75}px`,
  verticalAlign: 'middle',
  top: `-${theme.spacing.unit * 0.25}px`,
  marginTop: `${theme.spacing.unit}px`,
  marginBottom: `${theme.spacing.unit}px`,
  marginLeft: `${theme.spacing.unit}px`,
  [theme.breakpoints.down('xs')]: {
    top: '-20%',
    marginTop: 0,
    marginBottom: 0,
  },
}));

export const AmountsContainer = styled.div(({ theme }) => ({
  width: '100%',
  margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
  position: 'relative',
  display: 'flex',
  '& > div:not(:first-child)': {
    marginLeft: `${theme.spacing.unit * 2.5}px`,
  },
  '& > .recommended': {
    border: 'solid 2px #007d8e',
    width: `${theme.spacing.unit * 27.5 + 4}px!important`,
  },
  [theme.breakpoints.down('xs')]: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 1.75}px`,
    margin: 0,
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    display: 'flex',
    '&:hover > div': {
      transform: 'none',
      transition: 'none',
    },
    '&:hover > div:hover': {
      transform: 'none',
      transition: 'none',
    },
  },
}));

export const Amount = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 27.5}px`,
  height: `${theme.spacing.unit * 34.25}px`,
  boxSizing: 'border-box',
  borderRadius: `${theme.spacing.unit}px`,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
  opacity: 1,
  '& > .recommendedLabel': {
    display: 'block',
  },
  [theme.breakpoints.down('xs')]: {
    width: `${theme.spacing.unit * 30.25}px`,
    '&:first-child': {
      marginLeft: '2px',
    },
    marginTop: '2px',
    display: 'inline-block',
  },
}));
export const AmountContent = styled.div(({ theme }) => ({
  height: `${theme.spacing.unit * 17.5}px`,
  backgroundColor: 'rgba(0, 125, 142, 0.06)',
  padding: `${theme.spacing.unit * 2}px`,
  textAlign: 'center',
}));
export const AmountTitle = styled.span(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 2.25}px`,
  fontWeight: 600,
  color: '#001724',
}));
export const AmountSubtitle = styled.span(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 'normal',
  color: '#001724',
}));
export const AmountHR = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 21}px`,
  height: '1px',
  marginTop: `${theme.spacing.unit}px`,
  marginLeft: `${theme.spacing.unit * 1.25}px`,
  backgroundColor: 'rgba(0, 125, 142, 0.24)',
}));
export const AmountDescription = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 1.25}px`,
  fontSize: '11px',
  color: '#001724',
}));
export const DescriptionText = styled.div(({ theme }) => ({
  float: 'left',
  width: 'calc(50% - 4px)',
  textAlign: 'right',
}));
export const Description = styled.p(({ theme }) => ({
  margin: 0,
  padding: 0,
  marginTop: `${theme.spacing.unit * 0.5}px`,
  fontWeight: 500,
}));
export const ValuesText = styled.div(({ theme }) => ({
  float: 'right',
  width: 'calc(50% - 4px)',
  textAlign: 'left',
}));
export const Values = styled.p(({ theme }) => ({
  margin: 0,
  padding: 0,
  marginTop: `${theme.spacing.unit * 0.5}px`,
  [theme.breakpoints.down('xs')]: {
    overflowWrap: 'break-word',
  },
}));

export const AmountFooter = styled.div(({ theme }) => ({
  height: `${theme.spacing.unit * 12.75}px`,
}));
export const AmountContainer = styled.div(({ theme }) => ({
  paddingTop: `${theme.spacing.unit}px`,
  height: `${theme.spacing.unit * 4.5}px`,
  textAlign: 'center',
}));
export const AmountReduced = styled.span(({ theme }) => ({
  fontWeight: 'bold',
  color: '#007d8e',
  fontSize: `${theme.spacing.unit * 2.25}px`,
  display: 'inline-block',
  verticalAlign: 'middle',
}));
export const AmountLoan = styled.span(({ theme }) => ({
  fontWeight: 'bold',
  color: '#007d8e',
  fontSize: `${theme.spacing.unit * 3.75}px`,
  marginLeft: `${theme.spacing.unit * 0.25}px`,
  marginRight: `${theme.spacing.unit * 0.25}px`,
  display: 'inline-block',
  verticalAlign: 'middle',
}));
export const ButtonAmount = styled(CommonSubmitButton)(({ theme }) => ({
  margin: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px ${
    theme.spacing.unit * 2
  }px ${theme.spacing.unit * 2}px`,
  '& span': {
    textTransform: 'capitalize',
  },
}));

export const AmountRecommended = styled.div(({ theme }) => ({
  display: 'none',
  height: `${theme.spacing.unit * 3}px`,
  width: `${theme.spacing.unit * 12.5}px`,
  marginTop: '-2px',
  marginLeft: '59px',
  marginRight: '59px',
  borderBottomLeftRadius: `${theme.spacing.unit * 0.25}px`,
  borderBottomRightRadius: `${theme.spacing.unit * 0.25}px`,
  backgroundColor: '#007d8e',
  color: theme.palette.common.white,
  textAlign: 'center',
}));
export const RecommendedLabel = styled.p(({ theme }) => ({
  height: `${theme.spacing.unit * 1.75}px`,
  margin: 'auto auto',
  paddingTop: `${theme.spacing.unit * 0.75}px`,
  textTransform: 'uppercase',
  fontSize: '11px',
  fontWeight: 600,
  letterSpacing: '0.5px',
  [theme.breakpoints.down('xs')]: {
    fontSize: '10px',
    letterSpacing: 'normal',
  },
}));
