import {
  FETCHING_DASHBOARD,
  FETCHING_DASHBOARD_STATE,
  FETCHING_MERCHANT_INFO,
  FETCH_USER,
  FETCH_IDENTITY_VERIFICATION,
} from 'actions/Types';

import {
  DATA_LOAD_START,
  DATA_LOAD_SUCCESS,
  DATA_LOAD_FAIL,
} from '../../actions/AppActions';
import {
  LOAD_TRANSACTIONS,
  GET_TRANSACTION_DETAIL,
} from 'components/Transactions/redux/types';
import {
  FETCH_CODI,
  FETCH_CODI_DETAIL,
  DOWNLOAD_CODI_REPORT,
} from 'components/Codi/redux/types';
import {
  SET_INSTALLMENTS_SPINNER,
  SET_PAYMENT_SPINNER,
  SET_POSTBACK_SPINNER,
} from 'components/BusinessTools/redux/types';
import { LOAD_ORDERS } from 'components/ClipReaderOrders/redux/types';

const actionHandlers = new Map([
  [DATA_LOAD_START, handleDataLoading],
  [DATA_LOAD_SUCCESS, handleDataLoading],
  [DATA_LOAD_FAIL, handleDataLoadFailure],
  [FETCHING_DASHBOARD, handleDataLoading],
  [FETCHING_DASHBOARD_STATE, handleDataLoading],
  [FETCHING_MERCHANT_INFO, handleDataLoading],
  [FETCH_USER, handleDataLoading],
  [FETCH_IDENTITY_VERIFICATION, handleDataLoading],
  [LOAD_TRANSACTIONS, handleDataLoading],
  [GET_TRANSACTION_DETAIL, handleDataLoading],
  [SET_INSTALLMENTS_SPINNER, handleDataLoading],
  [SET_PAYMENT_SPINNER, handleDataLoading],
  [SET_POSTBACK_SPINNER, handleDataLoading],
  [LOAD_ORDERS, handleDataLoading],
  [FETCH_CODI, handleDataLoading],
  [FETCH_CODI_DETAIL, handleDataLoading],
  [DOWNLOAD_CODI_REPORT, handleDataLoading],
]);

export const INITIAL_STATE = {
  loadingCount: 0,
  errors: [],
};

function App(state = INITIAL_STATE, action = { type: null }) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

export function handleDataLoading(state, action) {
  return { ...state, loadingCount: getLoadingCount(state, action) };
}

export function handleDataLoadSuccess(state) {
  return { ...state, loadingCount: state.loadingCount - 1 };
}

export function handleDataLoadFailure(state, action) {
  return {
    ...state,
    loadingCount: state.loadingCount - 1,
    errors: [state.errors, action.payload.error],
  };
}

export function getLoadingCount(state, action) {
  if (action.payload.loading) {
    return state.loadingCount + 1;
  }

  return state.loadingCount > 0 ? state.loadingCount - 1 : 0;
}

// selectors
export const getLoadCount = (state) => state.loadingCount;
export const getIsLoading = (state) => getLoadCount(state) > 0;

export default App;
