export const getUserName = (state) => state.user.first_name;
export const isLoansFormSent = (state) => state.loans.isFormSent;
export const getLoansColonies = (state) => state.loans.colonies;
export const getLoansDays = (state) => state.loans.days;
export const getLoansIdentityDocuments = (state) =>
  state.loans.documents.identity;
export const getLoansIdentityDocumentsBack = (state) =>
  state.loans.documents.identityBack;
export const getLoansPassportDocuments = (state) =>
  state.loans.documents.passport;
export const getLoansProofAddressDocuments = (state) =>
  state.loans.documents.proofOfAddress;
export const getOnDragFileLoans = (state) => state.loans.onDragFile;
export const getLastOne = (state) => state.loans.lastOne;
export const hasLastOne = (state) =>
  Object.keys(state.loans.lastOne ? state.loans.lastOne : {}).length > 0;
export const getPrefilledForm = (state) => state.loans.prefilledForm;
export const getPreapprovals = (state) => state.loans.preapprovals;
export const preapprovalsLoaded = (state) => state.loans.preapprovalsLoaded;
export const getPreapprovalSelected = (state) =>
  state.loans.request ? state.loans.request.preapproval : [];
export const getLoansLoaded = (state) => state.loans.loansLoaded;
export const getLoans = (state) => state.loans.loans;
export const getCollections = (state) => state.loans.collections;
export const hasPreapprovals = (state) => state.loans.preapprovals.length > 0;
export const getMerchantId = (state) => state.merchant.info.id;
export const getConfirmAddress = (state) => state.loans.confirmAddress;
export const getLDFlagsReady = (state) => state.loans.ldFlagsReady;
export const getSendingForm = (state) => state.loans.sendingForm;
export const getPrestaClipNameChange = (state) =>
  state.loans.prestaClipNameChange;
