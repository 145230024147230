import styled from '@emotion/styled';

import lightBlue from '@material-ui/core/colors/lightBlue';
import { IoIosArrowForward } from 'react-icons/io';

export const FAQDoubts = styled.div(({ theme }) => ({
  paddingLeft: `${theme.spacing.unit * 3}px`,
  paddingRight: `${theme.spacing.unit * 3}px`,
  textAlign: 'center',
  opacity: 1,
  '& a': {
    color: theme.palette.naranja.cerulean,
    fontWeight: theme.typography.fontWeightLight,
  },
  '& a:hover': {
    color: lightBlue[700],
    opacity: '.8',
    transition: 'opacity 100ms linear',
  },
  '& a img': {
    paddingLeft: '6px',
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
}));

export const Question = styled.p(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '30px',
  marginBottom: `${theme.spacing.unit * 4}px`,
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.naranja.slate,
  [theme.breakpoints.down('xs')]: {
    display: 'grid',
    fontSize: '17px',
  },
}));

export const LinkIcon = styled(IoIosArrowForward)({
  color: lightBlue[700],
  verticalAlign: 'text-bottom',
});
