import React, { useEffect, useState } from 'react';
import * as Styled from './styled';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import { Carousel } from 'react-responsive-carousel';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Lottie from 'lottie-react';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import { getConfigValue } from '../../../config/config';
import Carousel1 from '../../../assets/images/berlin/lottie/Carousel1/data.json';
import carousel2 from '../../../assets/images/berlin/carousel2.svg'
import carousel3 from '../../../assets/images/berlin/carousel3.svg'
import carousel4 from '../../../assets/images/berlin/carousel4.svg'
import clipAccountLogo from '../../../assets/images/berlin/clipAccountLogo.svg';
import ClevertapComponent from '../../../helpers/Clevertap/ClevertapComponent';
import {EVENTS, Clevertap} from '../../../helpers/Clevertap';
import BerlinService from '../service'
import { setAccountRep, setDocumentType } from '../redux/berlinActions';
import { ACCOUNT, ROUTES2 as ROUTES, ROUTES as OLD_ROUTES } from '../constants';
import { BerlinSpinner } from '../BerlinSpinner';
import ExistingMerchantsOnboarding from '../ExistingMerchantsOnboarding';
import { setLaunchDarklyFlags } from '../redux/actions';
import RequestedCardStatus from '../RequestedCardStatus';
import WaitingForDataValidation from '../Registration2.0/WaitingForDataValidation';
import Maintenance from '../Maintenance';
import moment from 'moment-timezone';
import text from './text';
import styles from './styles';
export const TABS = {
  MAINTENANCE: 'MAINTENANCE',
  CAROUSEL: 'CAROUSEL',
  CREATING_ACCOUNT: 'CREATING_ACCOUNT',
  SPINNER: 'SPINNER',
  REQUEST_CLIPCARD: 'REQUEST_CLIPCARD',
  REQUEST_STATUS: 'REQUEST_STATUS',
};
const LandingCarousel2 = (props) => {
  let history = useHistory();
  const [activeTab, setactiveTab] = useState(TABS.SPINNER);
  const [setdisableRequestButton] = useState(false);
  const [maintenanceChecked, setmaintenanceChecked] = useState(false);
  const [polling, setpolling] = useState(false);
  const [flagsLoaded, setflagsLoaded] = useState(false);
  const [redirectionFinished, setredirectionFinished] = useState(false);
  const [requested, setrequested] = useState(false);
  const routeOnClick = (url) => {
    history.push(url);
  };
  const getLaunchDarklyFlags = () => {
    return new Promise((resolve) => {
      const { merchant, dispatch } = props;
      const LDClient = require('launchdarkly-js-client-sdk');
      const proxy_merchant_token = merchant.info.proxy_id
        ? merchant.info.proxy_id.proxy_merchant_token
        : null;
      const user = {
        key: proxy_merchant_token || null,
      };
      const ldClient = LDClient.initialize(
        getConfigValue('launchDarklyKey'),
        user,
      );
      ldClient.on('ready', async () => {
        const enableRequestCard = ldClient.variation(
          getConfigValue('launchDarklyFinancialRequestClipcardAvailableWeb'),
          false,
        );
        const enableChooseNip = ldClient.variation(
          getConfigValue('launchDarklyFinancialChooseNipAvailableWeb'),
          false,
        );
        const enableChangePin = ldClient.variation(
          getConfigValue('launchDarklyFinancialChangepinAvailable'),
          false,
        );
        const enableSpeiOut = ldClient.variation(
          getConfigValue('launchDarklyFinancialSpeiOutAvailable'),
          false,
        );
        const enableCvv = ldClient.variation(
          getConfigValue('launchDarklyFinancialDynamicCvvAvailableWeb'),
          false,
        );
        const showPrintedNip = ldClient.variation(
          getConfigValue('launchDarklyFinancialCheckPrintedNipAvailableWeb'),
          false,
        );
        const enableSpeiGeolocation = ldClient.variation(
          getConfigValue(
            'launchDarklyFinancialGeolocationRequiredSpeiOutAvailableWeb',
          ),
          false,
        );
        const onboardingRequestTimeout = ldClient.variation(
          getConfigValue('launchDarklyFinancialOnboardingRequestTimeoutWeb'),
          60000,
        );
        const enableCdaLogin = ldClient.variation(
          getConfigValue('launchDarklyFinancialCdaAvailableWeb'),
          false,
        );
        const enableIdPhotoV2 = ldClient.variation(
          getConfigValue('launchDarklyFinancialUploadPhotoV2AvailableWeb'),
          false,
        );
        const termsAndConditionsPdfSrc = ldClient.variation(
          getConfigValue('launchDarklyFinancialTermsAndConditionsPdfSrcWeb'),
          'https://drive.google.com/file/d/1eNLPiYPi5jzcUEUcQHSLg0HtvztbOlqU/preview',
        );
        dispatch(
          setLaunchDarklyFlags({
            enableRequestCard,
            enableChooseNip,
            enableChangePin,
            enableSpeiOut,
            enableCvv,
            showPrintedNip,
            enableSpeiGeolocation,
            enableCdaLogin,
            onboardingRequestTimeout,
            enableIdPhotoV2,
            termsAndConditionsPdfSrc,
          }),
        );
        resolve();
      });
    });
  };
  const setMaintenanceTab = () => {
    setactiveTab(TABS.MAINTENANCE);
    setmaintenanceChecked(true);
  }
  useEffect(() => {
    const {
      merchant,
      flags: {
        financialCdaInStatus,
        financialAccountCreationAvailableWeb,
      },
    } = props;

    const init = async () => {
      if (requested) {
        BerlinService.accountInformationV2(merchant.info.id)
          .then((response) => {
            props.dispatch(setAccountRep(response.data.message));
            props.dispatch(setDocumentType(response.data.message.account_holder_representation.document_type));
            switch (response.data.message.account_status) {
              case ACCOUNT.STATUS.ACTIVATED:
                if (financialCdaInStatus) {
                  routeOnClick(response.data.message.has_security ? ROUTES.AUTH : ROUTES.ACTIVATION)
                } else {
                  navigator.geolocation.getCurrentPosition(async (position) => {
                    const location = {
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude,
                    }
                    let loginBody = {
                      pin: 'xx',
                      user_id: props.account.account_holder_id,
                      agent: 'Chrome',
                      location: location,
                    }
                    BerlinService.postLogin(loginBody).then(response => {
                      routeOnClick(ROUTES.AUTH)
                    })
                      .catch(error => {
                        if (error.response.status === 404) {
                          routeOnClick(ROUTES.ACTIVATION)
                        }
                        if (error.response.status === 406) {
                          routeOnClick(ROUTES.AUTH)
                          return
                        }
                      })
                  })
                }
                break;
              case ACCOUNT.STATUS.INACTIVATED:
                routeOnClick(ROUTES.DEACTIVATED)
                break;
              case ACCOUNT.STATUS.CREATED:
                if (response.data.message.proxy_account_id) {
                  setActiveTab(TABS.CREATING_ACCOUNT)
  
                  return
                }
                routeOnClick(OLD_ROUTES.ACTIVATION);
                break;
              case ACCOUNT.STATUS.CAPTURED:
                setActiveTab(TABS.CREATING_ACCOUNT);
                break;
              case ACCOUNT.STATUS.REJECTED:
                if (financialAccountCreationAvailableWeb) {
                  Clevertap.event(
                    EVENTS.BERLIN.ONBOARDING.EVENT,
                    EVENTS.BERLIN.ONBOARDING.ACTIONS.INTRO_STARTED,
                    );
                  setActiveTab(TABS.CAROUSEL);
                } else {
                  routeOnClick(ROUTES.WAITINGLIST)
                }
                break;
              default:
                setActiveTab(TABS.MAINTENANCE);
            }
            setredirectionFinished(true);
          })
          .catch((error) => {
            if (error.response.status === 404) {
                if (financialAccountCreationAvailableWeb) {
                  Clevertap.event(
                    EVENTS.BERLIN.ONBOARDING.EVENT,
                    EVENTS.BERLIN.ONBOARDING.ACTIONS.INTRO_STARTED,
                    );
                  setActiveTab(TABS.CAROUSEL);
                } else {
                  routeOnClick(ROUTES.WAITINGLIST)
                }
            }
            setredirectionFinished(true);
        })
      }
    }
    init()
  }, [requested]);
  
  const isMaintenanceEnabled = () => {
    const {
      flags: {
        financialGeneralScheduledMaintenance: {
          ends,
          isScheduled,
          starts,
          timezone,
        },
        financialGeneralScheduledMaintenance,
      },
    } = props;
    
    const currentDatetime = moment().tz(timezone)
    const startTime = moment(starts, 'DD/MM/YYYY HH:mm').tz(timezone)
    const endTime = moment(ends, 'DD/MM/YYYY HH:mm').tz(timezone)

    if(Object.keys(financialGeneralScheduledMaintenance).length === 0) {
      return false;
    } else if(
      (isScheduled && currentDatetime.isBetween(startTime, endTime))
      || !isScheduled
    ) {
      return true;
    }
  }

  useEffect(async () => {
    const {
      merchant,
    } = props;
    if (
      merchant && merchant.info.proxy_id && merchant.info.proxy_id !== ''
    ) {
      isMaintenanceEnabled() && !maintenanceChecked
        ? setMaintenanceTab()
        : setmaintenanceChecked(true);
      if (!flagsLoaded) {
        await getLaunchDarklyFlags();
        setflagsLoaded(true);
      }
      if (
        maintenanceChecked &&
        merchant.info.id.length > 1 &&
        activeTab !== TABS.MAINTENANCE &&
        !redirectionFinished
      ) {
        if (!requested) {
          await setrequested(true);
        }
      }
    }
  }, [props]);

  const setActiveTab = (activeTab) => {
    setactiveTab(activeTab);
  }

  const renderTab = (tab) => {
    const { classes, user, flags: {
      financialGeneralScheduledMaintenance,
    } } = props;
    const slideStyles = { background: 'white', height: '100%', paddingBottom: '2rem' };
    switch (tab) {
      case TABS.MAINTENANCE:
        return <Maintenance content={financialGeneralScheduledMaintenance.message} />;
      case TABS.SPINNER:
        return <BerlinSpinner />;
      case TABS.CAROUSEL:
        return <>
          <div className={classes.carouselContainer}>
            <Carousel width="calc(100vw - 32px)" showArrows={false} showStatus={false} showThumbs={false}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                if (isSelected) {
                  return (
                    <li
                      style={Styled.SelectedIndicatorStyles}
                      aria-label={`Selected: ${label} ${index + 1}`}
                      title={`Selected: ${label} ${index + 1}`}
                    />
                  );
                }
                return (
                  <li
                    style={Styled.IndicatorStyles}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    title={`${label} ${index + 1}`}
                    aria-label={`${label} ${index + 1}`}
                  />
                );
              }}>
              <div style={slideStyles}>
                <img className={classes.LandingLogoImage} src={clipAccountLogo} alt="clip" />
                <Lottie animationData={Carousel1} className={classes.LandingImage}></Lottie>
                <ClipTypography className={classes.LandingText}>
                  <label className={classes.boldText}>{text.landingText1Bold}</label>
                </ClipTypography>
                <ClipTypography className={classes.lightText} variant="body2">
                  {text.landingText1sub}
                </ClipTypography>
              </div>
              <div style={slideStyles}>
                <img className={classes.LandingLogoImage} src={clipAccountLogo} alt="clip" />
                <img className={classes.LandingImage} src={carousel2} alt="clip" />
                <ClipTypography className={classes.LandingText}>
                  {text.landingText2a}
                </ClipTypography>
                <ClipTypography className={classes.LandingText}>
                  {text.landingText2b}
                </ClipTypography>
                <ClipTypography className={classes.LandingText}>
                  {text.landingText2c}
                </ClipTypography>
                <ClipTypography className={classes.LandingText}>
                  {text.landingText2d}
                </ClipTypography>
              </div>
              <div style={slideStyles}>
                <img className={classes.LandingLogoImage} src={clipAccountLogo} alt="clip" />
                <img className={classes.LandingImage} src={carousel3} alt="clip" />
                <ClipTypography className={classes.LandingText}>
                  <label className={classes.boldText}>{text.landingText3}</label>{text.landingText3b}
                </ClipTypography>
              </div>
              <div style={slideStyles}>
                <img className={classes.LandingLogoImage} src={clipAccountLogo} alt="clip" />
                <img className={classes.LandingImage} src={carousel4} alt="clip" />
                <ClipTypography className={classes.LandingText}>
                  {text.landingText4a}
                </ClipTypography>
              </div>
            </Carousel>
          </div>
          <div className={classes.startButtonContainer} >
            <ClevertapComponent
              renderComponent={<ClipButton />}
              events={[
                {
                  callbackName: 'onClick',
                  event: EVENTS.BERLIN.ONBOARDING.EVENT,
                  action: EVENTS.BERLIN.ONBOARDING.ACTIONS.INTRO_VIEWED,
                },
              ]}
              variant="contained"
              className={classes.startButton} 
              onClick={() => {
                routeOnClick(ROUTES.REGISTRATION)
              }}
              color="primary"
              >
                {text.start}
            </ClevertapComponent>
          </div>
        </>
      case TABS.CREATING_ACCOUNT:
        return <WaitingForDataValidation version="2" />
      case TABS.REQUEST_CLIPCARD:
        return (
          <ExistingMerchantsOnboarding
            disableRequestButton={() =>
              setdisableRequestButton(true)
            }
            TABS={TABS}
            setTab={(tab) => setActiveTab(tab)}
          />
        );
      case TABS.REQUEST_STATUS:
        return <RequestedCardStatus merchantId={props.merchant.info.id} setTab={(tab) => setActiveTab(tab)} />
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      <ReactNotification />
      {renderTab(activeTab)}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    account: state.berlin.account_representation,
    user: state.user,
  };
};
LandingCarousel2.propTypes = {
  account: PropTypes.object,
  merchant: PropTypes.object,
  user: PropTypes.object,
  flags: PropTypes.object,
};
LandingCarousel2.defaultProps = {
  account: { account_status: '' },
  merchant: {
    info: {
      id: '',
      proxy_id: null,
    },
  },
  user: {
    email: '',
  },
  ldFlags: {},
}

export default withLDConsumer()(connect(mapStateToProps)(withStyles(styles)(LandingCarousel2)));