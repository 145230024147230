import styled from '@emotion/styled';
import { Field } from 'redux-form';
import { SubmitButton as CommonSubmitButton } from 'components/common/Buttons';

const regularText = '#000b11';

export const NumeroTarjetaContainer = styled.div(() => ({
  background: 'white',
  width: '20.5rem',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  marginTop: '5rem',
}));

export const IngresaTuTarjeta = styled.div(() => ({
  width: '20.5rem',
  height: '3.625rem',
  fontSize: '1.5rem',
  lineHeight: '1.625rem',
  color: regularText,
}));

export const DescripcionTarjeta = styled.div(() => ({
  marginTop: '0.5rem',
  width: '20.5rem',
  height: '2.625rem',
  fontSize: '1rem',
  lineHeight: '1.31rem',
  color: regularText,
}));

export const FormField = styled(Field)(({ theme }) => ({
  marginTop: '2.8rem',
  width: '20.5rem',
}));

const BaseButtonStyles = ({ theme }) => ({
  marginTop: '5rem',
  width: '20.5rem',
});

export const SubmitButton = styled(CommonSubmitButton)(BaseButtonStyles);
