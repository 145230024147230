export const styles = (theme) => {
  const labelStyles = {
    labelRoot: {
      transform: 'translate(0, 25px) scale(1)',
      paddingLeft: `${theme.spacing.unit * 2}px`,
      fontSize: `${theme.spacing.unit * 1.75}px`,
      color: '#66737b',
    },
    labelShrink: {
      fontWeight: 500,
      transform: 'translate(4px, 20px) scale(0.75)',
      zIndex: 1,
    },
  };

  const inputStyles = {
    inputRoot: {
      height: `${theme.spacing.unit * 6}px`,
      marginTop: `${theme.spacing.unit}px!important`,
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      boxShadow: '0 0 0 1px #ccd0d3',
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 500,
      color: '#001724',
      '&::-webkit-calendar-picker-indicator': {
        display: 'none',
      },
    },
    inputInput: {
      paddingTop: `${theme.spacing.unit * 2.5}px`,
      paddingLeft: `${theme.spacing.unit * 2}px`,
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&[type=number]': {
        MozAppearance: 'textfield',
      },
      '&[name=rfc], &[name=curp], &[name=ine]': {
        textTransform: 'uppercase',
      },
    },
    inputInputType: {
      height: `${theme.spacing.unit * 2.625}px`,
    },
    inputFocused: {
      boxShadow: '0 0 0 2px #fc4c02',
    },
    inputError: {
      boxShadow: '0 0 0 2px #d10202',
    },
    inputHelp: {
      boxShadow: '0 0 0 1px #CCD0D3',
    },
    inputDisabled: {
      backgroundColor: 'rgba(248, 248, 248, 0.3)',
      fontWeight: 500,
      color: theme.palette.common.black,
    },
  };

  const inputAdornmentStyles = {
    inputAdornmentPositionStart: {},
    inputAdornmentPositionEnd: {
      margin: `auto ${theme.spacing.unit * 2}px`,
      '& > svg': {
        width: `${theme.spacing.unit * 2.25}px`,
        height: `${theme.spacing.unit * 3}px`,
        color: '#CCD0D3',
      },
    },
  };

  const errorStyles = {
    alertRoot: {
      width: `calc(100% - ${theme.spacing.unit * 4}px)`,
      margin: '0 auto',
      padding: `${theme.spacing.unit * 0.75}px ${theme.spacing.unit * 1.5}px`,
      borderBottomLeftRadius: `${theme.spacing.unit * 0.5}px`,
      borderBottomRightRadius: `${theme.spacing.unit * 0.5}px`,
      fontSize: `${theme.spacing.unit * 1.5}px`,
    },
    errorRoot: {
      backgroundColor: '#ffe7e7',
      color: '#7c0b0b',
    },
    helperRoot: {
      backgroundColor: '#CCD0D3',
      color: '#66737b',
    },
  };

  return {
    ...inputStyles,
    ...labelStyles,
    ...inputAdornmentStyles,
    ...errorStyles,
  };
};
