export default {
  cardNumber: (lastNumbers) => `*** ${lastNumbers}`,
  changePIN: 'Cambiar NIP',
  cvv: 'CVV dinámico',
  showPIN: 'Ver NIP',
  rejectedTitle: 'No eres tú, somos nosotros.',
  rejectedMessage: 'Por favor, inténtalo de nuevo.',
  serviceName: 'Cartera Clip',
  accountIssued: 'operada por',
};
