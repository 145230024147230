export const styles = (theme) => {
  const buttonStyles = {
    buttonRoot: {
      width: '100%',
      height: `${theme.spacing.unit * 6}px`,
      borderRadius: `${theme.spacing.unit * 3}px`,
      textTransform: 'none',
      letterSpacing: `${theme.spacing.unit * 0.025}px`,
      transition: 'none',
    },
    buttonOutlined: {
      border: `2px solid ${theme.palette.naranja.bloodOrange}`,
    },
    buttonLink: {
      width: 'initial',
      height: `${theme.spacing.unit * 2.25}px`,
      borderRadius: `${theme.spacing.unit * 1}px`,
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 600,
      lineHeight: 1.29,
    },
  };

  return { ...buttonStyles };
};
