export default {

    title1: 'Muy pronto podrás',
    title2: 'tenerla en tus manos',

    subTitle1: 'Estamos preparando el regreso Clip Card; Mientras tanto ',
    subTitle2: 'únete a la lista de espera',
    subTitle3: ' para obtener los mejores beneficios para tu negocio.',

    details1: 'Tu dinero en 4h*',
    details12: ' los 365 días del año.',

    details2: ' costos y ',
    details22: 'sin comisiones ',
    details23: 'de por vida.',

    details3: 'El dinero de tus ventas en un solo lugar.',

    zero: '$0',

    enrolmmentMessage: '¡Listo! Te avisaremos antes que a nadie.',
    enrollmentButton: 'Únirme a la lista de espera',

    error: 'Lo sentimos, por el momento el servicio no está disponible.'

};