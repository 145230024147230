export const styles = (theme) => {
  const rootStyles = {
    mainContent: {
      width: `${theme.spacing.unit * 52}px`,
      [theme.breakpoints.down(600)]: {
        width: '100%',
      },
    },
    offerContent: {
      display: 'flex',
      marginBottom: `${theme.spacing.unit * 2}px`,
      flexDirection: 'column',
    },
    newOfferContainer: {
      padding: `${theme.spacing.unit * 0.375}px ${theme.spacing.unit * 0.5}px`,
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      border: `solid ${theme.spacing.unit * 0.125}px rgba(252, 76, 2, 0.16)`,
      backgroundColor: 'rgba(252, 76, 2, 0.04)',
      fontSize: `${theme.spacing.unit * 1.5}px`,
      fontWeight: 500,
      color: `${theme.palette.naranja.bloodOrange}`,
      height: `${theme.spacing.unit * 1.75}px`,
      width: `${theme.spacing.unit * 8.5}px`,
      right: '0%',
      top: '0%',
      position: 'absolute',
    },
    termsContainer: {
      marginBottom: `${theme.spacing.unit * 2}px`,
      display: 'flex',
      alignItems: 'center',
    },
    confirmationCard: {
      marginBottom: `${theme.spacing.unit * 5}px`,
    },
    singleContent: {
      marginTop: `${theme.spacing.unit}px`,
    },
    dualContent: {
      marginTop: `${theme.spacing.unit}px`,
      display: 'flex',
    },
    content: {
      width: '50%',
    },
    divider: {
      margin: `${theme.spacing.unit * 2}px 0px`,
      height: `${theme.spacing.unit * 0.25}px`,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    buttonContainer: {
      margin: '0 auto',
      width: '100%',
      '& > button': {
        fontSize: `${theme.spacing.unit * 2}px`,
        fontWeight: 600,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  };

  const typographyStyles = {
    typographyRoot: {
      fontWeight: 500,
      color: '#001724',
      lineHeight: 'normal',
    },
    typographyTitle: {
      fontSize: `${theme.spacing.unit * 2.5 + 1}px`,
      textAlign: 'center',
    },
    typographySubheading: {
      fontSize: `${theme.spacing.unit * 2.25}px`,
      fontWeight: 600,
      position: 'relative',
      '&.amount': {
        display: 'none',
        [theme.breakpoints.down(418)]: {
          display: 'block',
        },
      },
      '& > span': {
        marginLeft: `${theme.spacing.unit * 0.75}px`,
        [theme.breakpoints.down(418)]: {
          display: 'none',
        },
      },
    },
    typographyCaption: {
      fontSize: `${theme.spacing.unit * 1.5}px`,
    },
    typographyBody1: {
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 'normal',
    },
    typographyDisplay1: {
      fontSize: `${theme.spacing.unit * 1.5}px`,
      fontWeight: 300,
      color: `${theme.palette.common.black}`,
    },
  };

  return { ...rootStyles, ...typographyStyles };
};
