import React from 'react'
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipTypography } from '@clipmx/clip-storybook';
import closeView from '../../../../assets/images/berlin/closeView.svg'


import styles from './styles';
const useStyles = makeStyles(styles);

const CollapsableSection = (props) => {
    const classes = useStyles(props);
    const { index, title, body, isOpen, open, showIndex, strong } = props;
    const renderBody = () => {
        if (typeof body === 'string') {
            return <ClipTypography className={classes.body}>{body}</ClipTypography>
        }
        return body.map(text => {
            let subinfo = '';
            if (text.subinfo) {
                subinfo = <ClipTypography className={classes.body}>{text.subinfo}</ClipTypography>

            }
            return <>
                {subinfo}
                <div className={classes.subsection} key={text.title}>
                    <ClipTypography className={classes.subtitle} variant="subtitle1">{text.title}</ClipTypography>
                    <ClipTypography className={classes.body}>{text.body}</ClipTypography>
                </div>
            </>
        })
    }
    const toggle = () => {
        if (index === isOpen) {
            return open(0)
        }
        return open(index)
    }
    return (
        <>
            <div className={classes.header} onClick={() => toggle()}>
                <ClipTypography fontWeight={strong || index === isOpen ? 'bold': 'regular'} variant="subtitle1">{`${showIndex ? `${index}.`: ''} ${title}`}</ClipTypography>
                <img width="42" className={index === isOpen ? '' : classes.openIcon} src={closeView} alt="" />
            </div>
            {index === isOpen && renderBody()}
        </>
    )
}

export default CollapsableSection;