import React from 'react';
import { ClipTypography, ClipButton } from '@clipmx/clip-storybook';
import Lottie from 'lottie-react';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import SpeiOutAnimation from '../../../../assets/images/berlin/lottie/SpeiOutAnimation/data.json';
import text from '../oldText';
import { styles } from './styles';

const useStyles = makeStyles(styles);
const SendingAnimation = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.animationContainer}>
      <ClipTypography className={classes.onTheWayText} variant={'title'}>
        {text.onTheWay}
      </ClipTypography>
      <Lottie
        animationData={SpeiOutAnimation}
        className={classes.onTheWayAnimation}
      />
    </div>
  );
};

export default SendingAnimation;
