import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

export const useStyles = makeStyles((theme) => {
  return {
    TopBanner: {
      display: 'block',
      width: `${theme.spacing.unit * 23.125}px`,
      height: `${theme.spacing.unit * 5}px`,
      marginTop: `${theme.spacing.unit * 2.5}px`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    TopBannerImage: {
      width: `${theme.spacing.unit * 31.25}px`,
    },
    TopImage: {
      display: 'block',
      width: `${theme.spacing.unit * 16}px`,
      height: `${theme.spacing.unit * 18}px`,
      marginTop: `${theme.spacing.unit * 2.5}px`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    textFont: {
      fontSize: '32px',
    },
    fontW100: {
      fontWeight: '100',
    },
    icons: {
      display: 'grid',
      '& > img': {
        margin: 'auto',
        marginBottom: '8px',
        marginTop: '32px',
      },
    },
    borderTest: {
      border: '1px solid',
    },
    titleDesc: {
      marginleft: theme.spacing(2),
      display: 'flex',
      textAlign: 'center',
    },

    titleConfirmation: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right',
      marginTop: theme.spacing(2),
    },
    titles2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },

    circle: {
      borderRadius: '100px',
      padding: '5px',
      color: theme.palette.common.white,
      background: theme.palette.orange.base,
      margin: 'auto',
      marginTop: '32px',
    },

    circleDone: {
      borderRadius: '100px',
      padding: '8px',
      color: theme.palette.common.white,
      background: theme.palette.orange.base,
      margin: 'auto',
      marginTop: '32px',
    },

    buttonContent: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    halfSizeContainer: {
      width: 'width100%',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
        display: 'flex',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
        display: 'flex',
      },
    },
  };
});

export default useStyles;
