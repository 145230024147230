import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'react-notifications-component';

import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import OneStepNavigator from '../Commons/OneStepNavigator';
import MerchantInfo from './MerchantInfo';
import OrderSteps from './OrderSteps';
import logger from 'helpers/logger';
import successImg from '../../../assets/images/berlin/successImg.svg';
import clipCardPackage from '../../../assets/images/berlin/clipCardPackage.svg';
import shippedTruck from '../../../assets/images/berlin/shippedTruck.svg';
import deliveredCard from '../../../assets/images/berlin/deliveredCard.svg';
import notDelivered from '../../../assets/images/berlin/notDelivered.svg';

import { BaseButton } from 'components/common/Buttons';
import { BerlinTheme } from '../Theme';
import BerlinService from '../service';
import BerlinSpinner from '../BerlinSpinner';

import text from './text';
import { TABS } from '../LandingCarousel';

const styles = (theme) => {
  return {
    root: {
      width: '100%',
      margin: '0',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      overflow: 'auto',
      paddingLeft: theme.spacing.unit * 1.5,
      paddingRight: theme.spacing.unit * 1.5,
      marginTop: '3.5rem',
    },
    message: {
      padding: `0 ${theme.spacing.unit * 3}`,
      marginBottom: '0',
    },
    email: {
      lineBreak: 'anywhere',
      marginBottom: '0',
    },
    boxImg: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit * 1.5,
    },
    topIcon: {
      marginTop: theme.spacing.unit * 3,
    },
    button: {
      marginBottom: theme.spacing.unit * 4,
    },
  };
};
const RequestedCardStatus = (props) => {
  const { classes, setTab, merchantId } = props;
  const [merchantInfo, setInfo] = useState({});
  const [orderStatus, setStatus] = useState(null);

  useEffect(() => {
    const getInfo = async () => {
      try {
        const status = await BerlinService.getMerchantEnrollmentStatus(
          merchantId,
        );
        const response = await BerlinService.getMerchantEnrollmentInfo(
          merchantId,
        );
        const validStatus = [
          'PENDING',
          'SHIPPED',
          'DELIVERY_EXCEPTION',
          'DELIVERED',
        ];
        if (!validStatus.includes(status.data.message.status)) {
          store.addNotification({
            title: text.rejectedTitle,
            message: text.rejectedMessage,
            type: 'danger',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              showIcon: true,
              duration: 0,
            },
          });
          setTab(TABS.CAROUSEL);
        }
        setStatus(status.data.message);
        setInfo({
          name: response.data.message.person.name,
          last_name: response.data.message.person.last_name,
          second_last_name: response.data.message.person.second_last_name,
          phone_number: response.data.message.person.phone_number,
          street: response.data.message.address.street,
          internal_number: response.data.message.address.internal_number,
          external_number: response.data.message.address.external_number,
          postal_code: response.data.message.address.zip_code,
          colony: response.data.message.address.neighborhood,
          colonies: [
            {
              id: response.data.message.address.neighborhood,
              name: response.data.message.address.neighborhood,
            },
          ],
          neighborhood: response.data.message.address.neighborhood,
          municipality: response.data.message.address.municipality,
          city: response.data.message.address.city,
          state: response.data.message.address.state,
          email: response.data.message.email,
        });
      } catch (e) {
        logger.info(e);
        store.addNotification({
          title: text.rejectedTitle,
          message: text.rejectedMessage,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            showIcon: true,
            duration: 0,
          },
        });
        setTab(TABS.CAROUSEL);
      }
    };
    getInfo();
  }, []);

  const renderStatusBody = (step) => {
    return (
      <>
        <OrderSteps text={text} step={step} />
        <MerchantInfo
          merchantInfo={merchantInfo}
          orderStatus={orderStatus}
          shipping={step}
          text={text}
        />
        <BaseButton
          className={classes.button}
          onClick={() => setTab(TABS.CAROUSEL)}
        >
          {text.okButton}
        </BaseButton>
      </>
    );
  };

  const renderStatusInfo = () => {
    switch (orderStatus.status) {
      case 'PENDING':
        return (
          <Grid className={classes.root} container spacing={16}>
            <img alt="component" className={classes.topIcon} src={successImg} />
            <Typography variant="title">{text.title}</Typography>
            <Typography
              className={classes.message}
              variant="body1"
              align="center"
            >
              {text.message1}
            </Typography>
            <Typography
              className={classes.email}
              variant="body2"
              align="center"
            >
              {merchantInfo.email}
            </Typography>
            <Typography
              className={classes.message}
              variant="body1"
              align="center"
            >
              {text.message2}
            </Typography>
            <img alt="component" className={classes.boxImg} src={clipCardPackage} />
            {renderStatusBody(1)}
          </Grid>
        );
      case 'SHIPPED':
        return (
          <Grid className={classes.root} container spacing={16}>
            <Typography variant="title">{text.shippedTitle}</Typography>
            <Typography
              className={classes.message}
              variant="body1"
              align="center"
            >
              {text.shippedMessage}
            </Typography>
            <img alt="component" className={classes.boxImg} src={shippedTruck} />
            {renderStatusBody(2)}
          </Grid>
        );
      case 'DELIVERY_EXCEPTION':
        return (
          <Grid className={classes.root} container spacing={16}>
            <Typography variant="title">{text.errorTitle}</Typography>
            <Typography
              className={classes.message}
              variant="body1"
              align="center"
            >
              {text.errorMessage}
            </Typography>
            <img alt="component" className={classes.boxImg} src={notDelivered} />
            {renderStatusBody(4)}
          </Grid>
        );
      case 'DELIVERED':
        return (
          <Grid className={classes.root} container spacing={16}>
            <Typography variant="title">{text.deliveredTitle}</Typography>
            <img alt="component" className={classes.boxImg} src={deliveredCard} />
            {renderStatusBody(3)}
          </Grid>
        );
      default:
        logger.info('Card status not valid');
        store.addNotification({
          title: text.rejectedTitle,
          message: text.rejectedMessage,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            showIcon: true,
            duration: 0,
          },
        });
        setTab(TABS.CAROUSEL);
    }
  };

  return merchantInfo.email ? (
    <MuiThemeProvider theme={BerlinTheme}>
      <OneStepNavigator
        title={text.navigatorTitle}
        stepBackFunction={props.setTab}
        stepBackTarget={TABS.CAROUSEL}
      />
      {renderStatusInfo()}
    </MuiThemeProvider>
  ) : (
    <BerlinSpinner />
  );
};
RequestedCardStatus.propTypes = {
  classes: PropTypes.object,
  setTab: PropTypes.func,
  merchantId: PropTypes.string,
};
RequestedCardStatus.defaultProps = {
  classes: {},
  setTab: () => {},
  merchantId: '',
};
export default withStyles(styles)(RequestedCardStatus);
