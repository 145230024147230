import styled from '@emotion/styled';

import Checkbox from '@material-ui/core/Checkbox';

export const CheckboxContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const Terms = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& a': {
    color: theme.palette.naranja.cerulean,
    fontWeight: theme.typography.fontWeightMedium,
  },
  '& a:hover': {
    color: theme.palette.naranja.bloodOrange,
    transition: 'color 100ms linear',
  },
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
}));

export const ErrorMessage = styled.label(({ theme }) => ({
  color: theme.palette.error.main,
  textAlign: 'left',
  fontSize: '12px',
  fontWeight: theme.typography.fontWeightLight,
}));

export const ClipCheckbox = styled(Checkbox)(({ theme }) => ({
  color: `${theme.palette.naranja.cerulean} !important`,
  height: '100%',
}));
