import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { cleanLastOneProofOfAddress } from 'components/loans/redux/actions';
import {
  getLoansColonies,
  getLoansProofAddressDocuments,
} from 'components/loans/redux/selectors';

import {
  required,
  isIndustry,
  isAlphanumericWithAccents,
  notRequiredNumber,
  maxLength,
} from 'helpers/validate';
import utils from 'helpers/utils';
import { Forms, LoansDocuments } from 'constants/AppConstants';
import { showAddressTime, getProviderCode } from 'constants/ProviderCodes';
import textPersonalInfo from 'components/loans/Commons/PersonalInformation/text';
import AttachAddressProofDocuments from 'components/loans/Commons/AttachDocumentsV2/AttachAddressProofDocuments';

import Grid from '@material-ui/core/Grid';
import TextField from 'helpers/FormUtils/TextField';
import SelectFieldV2 from 'helpers/FormUtils/SelectFieldV2';
import { isInvalidFormField } from 'helpers/FormUtils';

import {
  StepTitle,
  StepDescription,
  StepSection,
  FormControl,
  ListContainer,
} from './styled';
import {
  PrefilledProofAlert,
  ProofOfAddress,
  PrefilledProofAlertContent,
} from 'components/loans/Commons/Form/styled';
import text from './text';
import { lenderPayload } from '../../../Commons/Clevertap';
import ProofOfAddressIcon from 'assets/images/icons/ProofOfAddress.svg';
import Typography from '@material-ui/core/Typography';
import ClevertapField from '../../../../forms/ClevertapField';
import { Clevertap, EVENTS } from '../../../../../helpers/Clevertap';
import PropTypes from 'prop-types';

class AddressStep extends Component {
  state = {
    proofOfAddressRequired: false,
    yearOptions: utils.getYearOptions(),
    monthOptions: textPersonalInfo.monthsOptions,
    disabledAddressFields: true,
    disabledAddressFieldsUpdated: false,
    showAddressInfo: false,
    showAddressInfoUpdated: false,
  };

  static propTypes = {
    handleAddressStep: PropTypes.func.isRequired,
    cleanLastOneProofOfAddress: PropTypes.func.isRequired,
    previousLoan: PropTypes.object.isRequired,
    hasPreviousLoan: PropTypes.bool,
  };

  getFullYear = (day) => day.getFullYear();

  getMonthNumber = (day) => day.getMonth() + 1;

  fileUploaded = () => {
    const { preapprovalSelected, showCashAdvance, loansFormVersion } =
      this.props;
    this.setState({ proofOfAddressRequired: false }, () =>
      Clevertap.event(
        EVENTS.LOANS.PROOF_ADDRESS_UPLOADED,
        'UPLOADED_PROOF_ADDRESS',
        lenderPayload(preapprovalSelected, {
          showCashAdvance,
          loansFormVersion,
        }),
      ),
    );
  };

  monthGreaterThanToday = (month, year) => {
    const { month: birthdayMont, year: birthdayYear } = this.props;
    const currentDate = new Date();
    const actualYear = this.getFullYear(currentDate);
    const actualMonth = this.getMonthNumber(currentDate);
    return (
      (year === actualYear && parseInt(month) > actualMonth) ||
      (year === parseInt(birthdayYear) &&
        parseInt(month) < parseInt(birthdayMont))
    );
  };

  monthGreaterThanTodayValidation = (addressMonth, values) => {
    const { addressYear } = values;
    return this.monthGreaterThanToday(addressMonth, addressYear)
      ? text.invalidMonth
      : undefined;
  };

  deleteProofOfAddressDocuments = () => {
    const { previousLoan, cleanLastOneProofOfAddress, addressProofFiles } =
      this.props;

    let proofOfAddressLastOne = '';
    previousLoan.documents &&
      previousLoan.documents.forEach((document) => {
        if (document.document_type === LoansDocuments.addressDocumentType) {
          proofOfAddressLastOne = document.document_key;
        }
      });
    const proofOfAddressCurrent = addressProofFiles[0]
      ? addressProofFiles[0].key
      : '';

    if (previousLoan && proofOfAddressLastOne === proofOfAddressCurrent) {
      cleanLastOneProofOfAddress();
    }
  };

  enableFields = () => {
    const { postalCode } = this.props;
    const { disabledAddressFieldsUpdated } = this.state;

    if (
      postalCode !== undefined &&
      postalCode.length === 5 &&
      !disabledAddressFieldsUpdated
    ) {
      this.setState({ disabledAddressFields: false });
      this.setState({ disabledAddressFieldsUpdated: true });
    }
  };

  enableSections = () => {
    const { addressProofFiles } = this.props;
    const { showAddressInfoUpdated } = this.state;

    if (!showAddressInfoUpdated && addressProofFiles.length > 0) {
      this.setState({ showAddressInfo: true });
      this.setState({ showAddressInfoUpdated: true });
    }
  };

  enableNextStep = () => {
    const {
      handleAddressStep,
      addressProofFiles,
      postalCode,
      colony,
      municipality,
      state,
      street,
      streetNumber,
      addressYear,
      addressMonth,
    } = this.props;

    const providerCode = getProviderCode(
      this.props.location.pathname.toUpperCase(),
    );

    let nextStepEnabled = true;
    if (addressProofFiles.length === 0) {
      nextStepEnabled = false;
    }
    if (
      isInvalidFormField(postalCode) ||
      isInvalidFormField(colony) ||
      isInvalidFormField(municipality) ||
      isInvalidFormField(state) ||
      isInvalidFormField(street) ||
      isInvalidFormField(streetNumber)
    ) {
      nextStepEnabled = false;
    }
    if (
      showAddressTime(providerCode) &&
      (isInvalidFormField(addressYear) || isInvalidFormField(addressMonth))
    ) {
      nextStepEnabled = false;
    }

    handleAddressStep(nextStepEnabled);
  };

  componentDidMount() {
    this.enableSections();
    this.enableFields();
    this.enableNextStep();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.enableSections();
    this.enableFields();
    this.enableNextStep();
  }

  render() {
    const { showAddressInfo, disabledAddressFields } = this.state;
    const {
      addressYear,
      addressMonth,
      year: yearSelected,
      month: monthSelected,
      colonies,
      street,
      streetNumber,
      optionalStreetNumber,
      providerCode,
      hasPreviousLoan,
      previousLoan,
      addressProofFiles,
      preapprovalSelected,
      showCashAdvance,
      loansFormVersion,
    } = this.props;

    const hasProofAddressDocuments = addressProofFiles.length > 0;

    return (
      <Fragment>
        <StepTitle>{text.title}</StepTitle>
        <StepDescription>{text.description}</StepDescription>

        {previousLoan.documents && !hasProofAddressDocuments && (
          <PrefilledProofAlert>
            <ProofOfAddress src={ProofOfAddressIcon} />
            <Typography color="inherit" className="alertTitle">
              {text.prefilledProofAlertTitle}
            </Typography>
            <PrefilledProofAlertContent>
              {text.prefilledProofAlertContent}
            </PrefilledProofAlertContent>
          </PrefilledProofAlert>
        )}
        <StepSection>{text.proofOfAddressSection}</StepSection>
        <FormControl fullWidth>
          <AttachAddressProofDocuments
            fieldRequired={this.state.proofOfAddressRequired}
            fileUploaded={this.fileUploaded}
            previousLoan={hasPreviousLoan && previousLoan.documents}
          />
          <ListContainer>
            {text.proofOfAddressRequirements.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ListContainer>
        </FormControl>

        {showAddressInfo && (
          <Fragment>
            <StepSection>{text.addressInfoSection}</StepSection>
            <FormControl fullWidth>
              <Field
                name="postalCode"
                component={TextField}
                inputProps={{ maxLength: 5, minLength: 5 }}
                type="text"
                label={text.addressInfoPostalCode}
                autoComplete="postalCode"
                validate={[required]}
                onChange={this.deleteProofOfAddressDocuments}
              />
              <Field
                name="colony"
                component={SelectFieldV2}
                label={text.addressInfoColony}
                options={colonies}
                validate={[required, isIndustry]}
                onChange={this.deleteProofOfAddressDocuments}
                disabled={disabledAddressFields}
              />
              <Field
                name="municipality"
                component={TextField}
                inputProps={{ readOnly: true }}
                type="text"
                label={text.addressInfoMunicipality}
                validate={[required]}
                onChange={this.deleteProofOfAddressDocuments}
                disabled={disabledAddressFields}
              />
              <Field
                name="state"
                component={TextField}
                inputProps={{ readOnly: true }}
                type="text"
                label={text.addressInfoState}
                validate={[required]}
                onChange={this.deleteProofOfAddressDocuments}
                disabled={disabledAddressFields}
              />
              <Field
                name="street"
                component={TextField}
                inputProps={{ maxLength: 45 }}
                type="text"
                label={text.addressInfoStreet}
                validate={[required, isAlphanumericWithAccents]}
                helperText={maxLength(45)(street)}
                onChange={this.deleteProofOfAddressDocuments}
                disabled={disabledAddressFields}
              />
              <Field
                name="streetNumber"
                component={TextField}
                inputProps={{ maxLength: 20 }}
                type="text"
                label={text.addressInfoExternalNumber}
                validate={[required, isAlphanumericWithAccents]}
                helperText={maxLength(20)(streetNumber)}
                onChange={this.deleteProofOfAddressDocuments}
                disabled={disabledAddressFields}
              />
              <Field
                name="optionalStreetNumber"
                component={TextField}
                inputProps={{ maxLength: 20 }}
                type="text"
                label={text.addressInfoInternalNumber}
                validate={[notRequiredNumber]}
                helperText={maxLength(20)(optionalStreetNumber)}
                onChange={this.deleteProofOfAddressDocuments}
                disabled={disabledAddressFields}
              />
            </FormControl>

            {showAddressTime(providerCode) && (
              <Fragment>
                <StepSection>{text.addressTimeSection}</StepSection>
                <FormControl>
                  <Grid container spacing={24}>
                    <Grid item xs={6}>
                      <Field
                        name="addressYear"
                        component={SelectFieldV2}
                        label={text.addressTimeYear}
                        options={this.state.yearOptions.map((year) => ({
                          ...year,
                          disabled:
                            year.id < yearSelected ||
                            (parseInt(year.id) === parseInt(yearSelected) &&
                              parseInt(addressMonth) < parseInt(monthSelected)),
                        }))}
                        validate={[required]}
                        disabled={disabledAddressFields}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ClevertapField
                        component={SelectFieldV2}
                        name="addressMonth"
                        label={text.addressTimeMonth}
                        options={this.state.monthOptions.map((month) => ({
                          ...month,
                          disabled: this.monthGreaterThanToday(
                            month.id,
                            addressYear,
                          ),
                        }))}
                        validate={[
                          required,
                          this.monthGreaterThanTodayValidation,
                        ]}
                        disabled={disabledAddressFields}
                        clevertap={{
                          listener: 'onChange',
                          event: EVENTS.LOANS.RESIDENCE_SELECTED,
                          payload: lenderPayload(preapprovalSelected, {
                            showCashAdvance,
                            loansFormVersion,
                          }),
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const selector = formValueSelector(Forms.MR_PRESTA);

const mapStateToProps = (state) => {
  return {
    addressProofFiles: getLoansProofAddressDocuments(state),
    colonies: getLoansColonies(state),
    year: selector(state, 'year'),
    month: selector(state, 'month'),
    postalCode: selector(state, 'postalCode'),
    colony: selector(state, 'colony'),
    municipality: selector(state, 'municipality'),
    state: selector(state, 'state'),
    street: selector(state, 'street'),
    streetNumber: selector(state, 'streetNumber'),
    optionalStreetNumber: selector(state, 'optionalStreetNumber'),
    addressYear: selector(state, 'addressYear'),
    addressMonth: selector(state, 'addressMonth'),
    preapprovalSelected: state.loans?.request?.preapproval,
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  cleanLastOneProofOfAddress,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddressStep),
);
