import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import AcceptTermsImg from '../../../../assets/images/berlin/accept-terms.svg';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import text from './text';

const AcceptTerms = ({
  classes,
  documentType,
  setStep,
  steps,
  setDisableCreateButton,
  disableCreateButton,
}) => {
  const [termsAccepted, setTermsAccepted] = useState({
    contract: false,
    terms: false,
    geo: false,
    data: false,
  });

  const handleChange = (name) => (event) => {
    setTermsAccepted({ ...termsAccepted, [name]: event.target.checked });
  };
  const allTrue = (obj) => {
    for (var o in obj) if (!obj[o]) return false;

    return true;
  };

  useEffect(() => {
    if (allTrue(termsAccepted)) {
      setDisableCreateButton(false);
      return;
    }
    if (!disableCreateButton) {
      setDisableCreateButton(true);
      return;
    }
  }, [termsAccepted]);

  let contract = text.contract.split('%').map((line) => {
    if (line === 'link') {
      return (
        <span
          className={classes.link}
          key={text.contractFile}
          onClick={() => {
            setStep(steps.CONTRACT);
          }}
        >
          {text.contractLink}
        </span>
      );
    }
    return line;
  });

  let privacy = text.acceptPrivacy.split('%').map((line) => {
    if (line === 'link1') {
      return (
        <span
          className={classes.link}
          key={text.privacyLink}
          onClick={() => {
            setStep(steps.PRIVACY);
          }}
        >
          {text.privacyLink}
        </span>
      );
    }
    if (line === 'link2') {
      return (
        <span
          className={classes.link}
          key={text.conditionsLink}
          onClick={() => {
            setStep(steps.TERMS);
          }}
        >
          {text.conditionsLink}
        </span>
      );
    }
    return line;
  });

  return (
    <div>
      <Typography variant="title" className={classes.title}>
        {text.title}
      </Typography>
      <Typography variant="headline" className={classes.headline}>
        {text.info}
      </Typography>
      <div className={classes.imageContainer}>
        <img width="104" src={AcceptTermsImg} alt="" />
      </div>
      <div className={classes.lineContainer}>
        <Typography className={classes.switchText}>{contract}</Typography>
        <Switch
          checked={termsAccepted.contract}
          onChange={handleChange('contract')}
          color="primary"
        />
      </div>
      <div className={classes.lineContainer}>
        <Typography className={classes.switchText}>{privacy}</Typography>
        <Switch
          checked={termsAccepted.terms}
          onChange={handleChange('terms')}
          color="primary"
        />
      </div>
      <div className={classes.lineContainer}>
        <Typography className={classes.switchText}>
          {text.authorizeGeo}
        </Typography>
        <Switch
          checked={termsAccepted.geo}
          onChange={handleChange('geo')}
          color="primary"
        />
      </div>
      <div className={classes.lineContainer}>
        <Typography className={classes.switchText}>
          {documentType === 'ine'
            ? text.authorizeDataIne
            : text.authorizeDataPassport}
        </Typography>
        <Switch
          checked={termsAccepted.data}
          onChange={handleChange('data')}
          color="primary"
        />
      </div>
    </div>
  );
};

AcceptTerms.propTypes = {
  classes: PropTypes.object.isRequired,
  documentType: PropTypes.string.isRequired,
  setStep: PropTypes.any,
  steps: PropTypes.any,
  setDisableCreateButton: PropTypes.func.isRequired,
  disableCreateButton: PropTypes.bool.isRequired,
};

export default withStyles(styles)(AcceptTerms);
