import {
  FETCHING_TOP_UPS,
  SET_TOP_UPS,
  SET_TOP_UPS_DAILY_TOTAL,
  TOP_UPS_ERROR,
  DOWNLOAD_TOPUP_REPORT,
  DOWNLOAD_TOPUP_REPORT_ERROR,
} from './types';

import { formatTimestamp } from '../../../helpers/dateHelpers';

const initialState = {
  transactions: [],
  dailyTotalAmount: null,
  pagination: {
    total: 0,
    perPage: 0,
    currentPage: 1,
    totalPages: 0,
  },
  loading: false,
  error: null,
  detail: {},
  report: null,
};

const actionHandlers = new Map([
  [FETCHING_TOP_UPS, handleFetchTopups],
  [SET_TOP_UPS, handleSetTopups],
  [SET_TOP_UPS_DAILY_TOTAL, handleSetTopupsDailyTotal],
  [TOP_UPS_ERROR, handleTopupError],
  [DOWNLOAD_TOPUP_REPORT, handleTopupReportDownload],
  [DOWNLOAD_TOPUP_REPORT_ERROR, handleTopupError],
]);

export default function (state = initialState, action = { type: null }) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleSetTopups(state, action) {
  const { transactions, pagination } = action.payload;
  return {
    ...state,
    transactions: transactions.map((txn) => ({
      ...txn,
      ...{ date: formatTimestamp(txn.date) },
    })),
    pagination: {
      total: pagination.totalRows,
      perPage: pagination.limit,
      currentPage: Math.floor(pagination.offset / pagination.limit) + 1,
      totalPages: Math.ceil(pagination.totalRows / pagination.limit),
    },
  };
}

function handleFetchTopups(state, action) {
  return { ...state, loading: action.payload };
}

function handleSetTopupsDailyTotal(state, action) {
  const { totalAmount } = action.payload;

  return totalAmount
    ? { ...state, dailyTotalAmount: totalAmount.daily }
    : { ...state, dailyTotalAmount: null };
}

function handleTopupError(state, action) {
  const message = action.payload;

  return message ? { ...state, error: message } : state;
}

function handleTopupReportDownload(state, action) {
  const { report, loading } = action.payload;

  return { ...state, report: report ? report : null, loading };
}
