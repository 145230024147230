import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ROUTES2 } from '../../constants';
import { useHistory } from 'react-router-dom';
import { setAccountRep } from '../../redux/berlinActions';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import text from './text';
import styles from './styles';
import BerlinService from '../../service';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import WaitingSign from '../../../../assets/images/berlin/waitingSign.svg';

const useStyles = makeStyles(styles);

const FinishScreen = (props) => {
  let history = useHistory();
  const media = window.matchMedia('(max-height: 625px)');
  const classes = useStyles();

  useEffect(() => {
    BerlinService.accountInformationV2(props.merchant.info.id)
      .then((response) => {
        props.setAccountRep(response.data.message);
      })
      .catch((err) => {
        console.log('status error');
      })
      .finally(() => {});
  }, []);

  const routeOnClick = (url) => {
    history.push(url);
  };

  return (
    <div className={classes.container}>
      <img
        src={WaitingSign}
        alt="sign"
        className={media.matches ? classes.imgSe : classes.img}
      />
      <div className={classes.text}>
        <ClipTypography className={classes.title1}>
          {text.title1}
        </ClipTypography>
        <ClipTypography className={classes.title1}>
          {text.title2}
        </ClipTypography>
      </div>
      <div className={classes.sub}>
        <ClipTypography className={classes.subtitile}>
          {text.subtitle}
          <b>{text.sub}</b>
          {text.subtitle1}
        </ClipTypography>
        <ClipTypography className={classes.subtitile}>
          {text.path}
        </ClipTypography>
      </div>
      <ClipTypography className={classes.subtitile2}>
        {text.subtitle2}
        <b style={{ color: '#FC4C02' }}>{text.ch}</b>
      </ClipTypography>
      <div
        className={
          media.matches ? classes.btnContainerSe : classes.btnContainer
        }
      >
        <ClipButton
          className={classes.btn}
          variant="contained"
          color="primary"
          onClick={() => routeOnClick(ROUTES2.AUTH)}
        >
          {text.btnText}
        </ClipButton>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setAccountRep,
};

const mapStateToProps = (state) => {
  return {
    account: state.berlin.account_representation,
    merchant: state.merchant,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinishScreen);
