export default {
  verifyingDocuments: 'Verificando datos',
  wereChecking: 'Regresamos contigo en 5 minutos.',
  thanks: 'Mientras tanto, puedes seguir usando Clip.',
  rejected: {
    DOCUMENTS_VALIDATION_ERROR: {
      title: 'No pudimos validar tu identificación',
      message:
        'Procura que tus datos sean legibles en la foto o prueba con otra identificación.',
    },
    DOCUMENTS_INVALID_DATA: {
      title: 'Verifica tu información',
      message:
        'Tus datos personales no coinciden con los que aparecen en tu identificación.',
    },
    DIGITAL_ONBOARDING_FINISHED: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    DOCUMENTS_NAME_ERROR: {
      title: 'Verifica tu información',
      message:
        'Escribe nombre y apellidos tal como aparecen en tu identificación.',
    },
    DOCUMENTS_CURP_ERROR: {
      title: 'Verifica tu información',
      message: 'Tu CURP es distinta a la que aparece en tu identificación.',
    },
    DOCUMENTS_BIRTHDATE_ERROR: {
      title: 'Verifica tu información',
      message:
        'Tu fecha de nacimiento es distinta a la que aparece en tu identificación.',
    },
    DOCUMENTS_GENRE_ERROR: {
      title: 'Verifica tu información',
      message: 'Ingresa el género tal como aparece en tu identificación.',
    },
    ALREADY_PERSONALIZED_CARD: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    NON_EXISTENT_TRACKING_ID: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    TAMPERED_DOCUMENT: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    INVALID_CURP: {
      title: 'Verifica tu información',
      message: 'Tu CURP es distinta a la que aparece en tu identificación.',
    },
    NO_INE_DATA_FOUND: {
      title: 'No pudimos validar tu identificación',
      message:
        'Procura que tus datos sean legibles en la foto o prueba con otra identificación.',
    },
    UNKNOWN_CODE: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
  },
};
