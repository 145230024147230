import axios from 'axios';
import { reset as clearForm, change as updateFormField } from 'redux-form';

import { app } from 'config/config';
import { getRequestOptions } from 'helpers/fetchUtil';
import apiPaths from 'helpers/apiPaths';
import analyticsEvents from 'helpers/AnalyticsEvents';
import logger from 'helpers/logger';
import { OnboardingStates, Forms } from 'constants/AppConstants';
import getText from '../../../actions/Actions.text';
import { Analytics, AnalyticsCategories } from 'utils/Analytics';
import {
  CLEAR_ONBOARDING_STATE,
  SET_ONBOARDING_ACTIVE_STATE,
  SET_ONBOARDING_STATE,
  SUBMIT_BANK_INFORMATION_FULFILLED,
  SUBMIT_BANK_INFORMATION_ERROR,
  GET_BANK_ACCOUNT_FULFILLED,
  GET_BANK_ACCOUNT_ERROR,
  UPDATE_BUSINESS_INFO,
  SET_BUSINESS_INFO_ERROR,
  CREATE_COLLABORATOR_FULFILLED,
  CREATE_COLLABORATOR_ERROR,
  GET_COLLABORATORS_FULFILLED,
  GET_COLLABORATORS_ERROR,
  DELETE_COLLABORATOR_FULFILLED,
  UPDATE_COLLABORATOR_SUCCESS,
  UPDATE_INSTALLMENTS,
  SET_INSTALLMENTS_ERROR,
  SET_BUSINESS_INFO_LOCATION,
  SET_BUSINESS_INFO_INDUSTRIES,
  SET_DASHBOARD_STATE,
} from './types';

import {
  dataLoading,
  dataLoaded,
  dataLoadFailed,
} from '../../../actions/AppActions';

const text = getText().errors;

export function updateDashboardState(state) {
  return {
    type: SET_DASHBOARD_STATE,
    payload: state,
  };
}

export function clearOnboardingState() {
  return { type: CLEAR_ONBOARDING_STATE };
}

export function setOnboardingState(state) {
  return {
    type: SET_ONBOARDING_STATE,
    payload: state,
  };
}

export function setActiveOnboardingState(state) {
  return {
    type: SET_ONBOARDING_ACTIVE_STATE,
    payload: state,
  };
}

export function getMerchantOnboardingState() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.preactiveStatus}`;

    axios
      .get(url, options)
      .then((response) => {
        const { active_state, onboarding_state } = response.data.onboarding;

        dispatch(setOnboardingState(onboarding_state));
        dispatch(setActiveOnboardingState(active_state));
      })
      .catch((err) => {
        logger.error('err getting user onboarding state: ', err.response);
      });
  };
}

export function updateUserStateV2(activeState) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.preactiveStatus}`;
    const request = axios.create(options);
    const payload = {
      active_state: activeState,
      version: 'v2',
    };

    request
      .post(url, payload)
      .then((response) => {
        const {
          dashboard,
          dashboard: { dashboard_state: dashboardState },
          onboarding,
        } = response.data;

        if (dashboard && dashboardState) {
          // Update the dashboard state
          dispatch(updateDashboardState(dashboardState));
        }

        dispatch(setActiveOnboardingState(onboarding.active_state));
        dispatch(setOnboardingState(onboarding.onboarding_state));
      })
      .catch((err) => {
        logger.error('err updating user onboarding state: ', err.response);
      });
  };
}

export function updateUserState(activeState) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.preactiveStatus}`;
    const request = axios.create(options);
    const payload = {
      active_state: activeState,
    };

    request
      .post(url, payload)
      .then((response) => {
        const { dashboard, onboarding } = response.data;

        if (dashboard && dashboard.dashboard_state) {
          // Update the dashboard state
          dispatch(updateDashboardState(dashboard.dashboard_state));
        }

        dispatch(setActiveOnboardingState(onboarding.active_state));
        dispatch(setOnboardingState(onboarding.onboarding_state));
      })
      .catch((err) => {
        logger.error('err updating user onboarding state: ', err.response);
      });
  };
}

export function getBankInformation(code) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.bankJson}${code}`;

    return axios
      .get(url, options)
      .then((response) => {
        if (
          typeof response.data === 'object' &&
          typeof response.data.name === 'string'
        ) {
          dispatch(
            updateFormField(
              Forms.BANK_ACCOUNT_FORM,
              'bank_name',
              response.data.name,
            ),
          );
        }
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function submitBankAccount(
  payload,
  isContinueClicked,
  currentState,
  bankName,
) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.bankAccount}`;
    const request = axios.create(options);

    if (isContinueClicked) {
      request
        .post(url, payload)
        .then((response) => {
          dispatch({
            type: SUBMIT_BANK_INFORMATION_FULFILLED,
            payload: response.data.bank,
          });

          dispatch(updateUserState(OnboardingStates.BANK_ACCOUNT_INFO));

          analyticsEvents.insertBankAccount(payload.bank_name);

          if (currentState === 'BANK_ACCOUNT_INFO') {
            // trigger bank account gtm
            analyticsEvents.bankInfo();
            //Send event GTM, step 6 Bank Info
            Analytics.populateStepOnboarding(
              6,
              AnalyticsCategories.BACKINFO,
              'Submit',
              'Success',
            );
          }
        })
        .catch((err) => {
          dispatch({
            type: SUBMIT_BANK_INFORMATION_ERROR,
            payload: err.response.data,
          });
        });
    } else {
      if (currentState === 'BANK_ACCOUNT_INFO') {
        analyticsEvents.skipBank();
        //Send event GTM, step 6 Bank Info
        Analytics.populateStepOnboarding(
          6,
          AnalyticsCategories.BACKINFO,
          'Skip',
        );
      }
    }
  };
}

export function getBankAccount() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.bankDetails}`;

    axios
      .get(url, options)
      .then((response) => {
        const bankAccount = response.data.bank_account_name
          ? response.data
          : null;

        dispatch({ type: GET_BANK_ACCOUNT_FULFILLED, payload: bankAccount });
      })
      .catch((err) => {
        logger.error('error fetching bank account: ', err.response);
        dispatch({ type: GET_BANK_ACCOUNT_ERROR, payload: err });
      });
  };
}

export function getBusinessInformation() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.businessInfo}`;

    axios
      .get(url, options)
      .then((response) => {
        const business = {
          name: response.data.merchant.name,
          ...response.data.address,
        };

        if (response.data.address.postal_code)
          dispatch(getColonies(response.data.address.postal_code));

        dispatch({
          type: UPDATE_BUSINESS_INFO,
          payload: business,
        });
      })
      .catch((err) => {
        logger.error('error fetching business information: ', err.response);
        dispatch({
          type: SET_BUSINESS_INFO_ERROR,
          payload: err,
        });
      });
  };
}

export function postBusinessInformation(payload, activeState) {
  return async (dispatch) => {
    try {
      const options = getRequestOptions();
      const businessInfoUrl = `${app.apiUrl}${apiPaths.businessInfo}`;
      const request = axios.create(options);
      const businessResponse = await request.post(businessInfoUrl, payload);
      const business = {
        name: businessResponse.data.merchant.name,
        ...businessResponse.data.address,
      };

      dispatch({
        type: UPDATE_BUSINESS_INFO,
        payload: business,
      });

      // trigger business info gtm
      analyticsEvents.businessInfo();
    } catch (error) {
      logger.error(
        'error in postBusinessInformation and signup industry action: ',
        error,
      );
      dispatch({
        type: SET_BUSINESS_INFO_ERROR,
        payload: text.serverError,
      });
    }
  };
}

export function submitCollaborator(payload) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.createCollaborator}`;
    const request = axios.create(options);

    request
      .post(url, payload)
      .then((response) => {
        dispatch({
          type: CREATE_COLLABORATOR_FULFILLED,
          payload: response.data,
        });
        dispatch(clearForm(Forms.COLLABORATOR_FORM));

        setTimeout(() => {
          dispatch({ type: UPDATE_COLLABORATOR_SUCCESS, payload: false });
        }, 3000);
      })
      .catch((err) => {
        logger.error('error creating collaborator: ', err.response);
        dispatch({
          type: CREATE_COLLABORATOR_ERROR,
          payload: err.response.data,
        });
      });
  };
}

export function getCollaborators() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.getCollaboratorList}`;

    axios
      .get(url, options)
      .then((response) => {
        dispatch({ type: GET_COLLABORATORS_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        logger.error('error fetching collaborators', err.response);
        dispatch({ type: GET_COLLABORATORS_ERROR, payload: err });
      });
  };
}

export function deleteCollaborator(payload) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.deleteCollaborator}${payload.id}`;
    const request = axios.create(options);

    request
      .post(url, {})
      .then((response) => {
        const collaborator = {
          role: payload.role,
          index: payload.index,
          data: response.data,
        };

        dispatch({
          type: DELETE_COLLABORATOR_FULFILLED,
          payload: collaborator,
        });
      })
      .catch((err) => {
        logger.error('error deleting collaborator: ', err.response);
      });
  };
}

export function getInstallments() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.onboardingInstallment}`;

    axios
      .get(url, options)
      .then((response) => {
        dispatch({
          type: UPDATE_INSTALLMENTS,
          payload: response.data.installment,
        });
      })
      .catch((err) => {
        logger.error('error fetching installments: ', err.response);
        dispatch({
          type: SET_INSTALLMENTS_ERROR,
          payload: err,
        });
      });
  };
}

export function postInstallments(
  payload,
  activeState,
  isContinueClicked,
  currentState,
) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.onboardingInstallment}`;
    const request = axios.create(options);

    if (isContinueClicked) {
      request
        .post(url, payload)
        .then((response) => {
          dispatch({
            type: UPDATE_INSTALLMENTS,
            payload: response.data.installment,
          });

          dispatch(updateUserState(OnboardingStates.INSTALLMENT));

          analyticsEvents.insertInstallments(
            payload.configuration_term,
            payload.price_threshold,
          );

          if (currentState === 'INSTALLMENT') {
            // trigger installments gtm
            analyticsEvents.installmentInfo();
            //Send event GTM, step 5 installments
            Analytics.populateStepOnboarding(
              5,
              AnalyticsCategories.INSTALLMENTS,
              'Submit',
              'Success',
            );
          }
        })
        .catch((err) => {
          logger.error('error in postInstallments action: ', err.response);
          dispatch({
            type: SET_INSTALLMENTS_ERROR,
            payload: text.serverError,
          });
        });
    } else {
      if (currentState === 'INSTALLMENT') {
        analyticsEvents.installmentSkip();
        Analytics.populateStepOnboarding(
          5,
          AnalyticsCategories.INSTALLMENTS,
          'Skip',
        );
      }
    }
  };
}

export function getColonies(zipcode) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.zipcodes}${zipcode}`;
    return axios
      .get(url, options)
      .then((response) => {
        dispatch(setColonies(response.data.mexico_zipcodes));
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function setColonies(payload) {
  return (dispatch) => {
    const location = payload.reduce(
      (memo, item) => {
        memo.zipcode = item.zipcode;
        memo.municipality = item.municipality;
        memo.state = item.state;
        memo.colonies.push({ id: item.colony, name: item.colony });

        return memo;
      },
      { zipcode: null, municipality: null, state: null, colonies: [] },
    );

    dispatch({ type: SET_BUSINESS_INFO_LOCATION, payload: location });
    if (location.colonies.length > 0) {
      dispatch(
        updateFormField(
          Forms.BUSINESS_INFO_FORM,
          'colony',
          location.colonies[0].id,
        ),
      );
    }

    dispatch(
      updateFormField(
        Forms.BUSINESS_INFO_FORM,
        'municipality',
        location.municipality,
      ),
    );
    dispatch(
      updateFormField(Forms.BUSINESS_INFO_FORM, 'state', location.state),
    );
  };
}

export function getIndustries() {
  return (dispatch) => {
    dispatch(dataLoading(SET_BUSINESS_INFO_INDUSTRIES));

    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.registrationMetaData}`;

    return axios
      .get(url, options)
      .then((response) => {
        dispatch(dataLoaded(SET_BUSINESS_INFO_INDUSTRIES, response.data));
        dispatch({
          type: SET_BUSINESS_INFO_INDUSTRIES,
          payload: response.data.industries,
        });
        return response.data;
      })
      .catch((err) => {
        dispatch(dataLoadFailed(SET_BUSINESS_INFO_INDUSTRIES, err));

        return Promise.reject(err);
      });
  };
}
