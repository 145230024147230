import { createGetRequest } from '../middleware/apiResolve/reduxUtils';
import apiPaths from 'helpers/apiPaths';

export const DATA_LOAD_START = 'app/DATA_LOAD_START';
export const DATA_LOAD_SUCCESS = 'app/DATA_LOAD_SUCCESS';
export const DATA_LOAD_FAIL = 'app/DATA_LOAD_FAIL';
export const ONLINE_STORE = 'app/ONLINE_STORE';

export const dataLoading = (action, loading = true) => ({
  type: DATA_LOAD_START,
  payload: { action, loading },
});

export const dataLoaded = (action, data) => ({
  type: DATA_LOAD_SUCCESS,
  payload: { action, loading: false, data },
});

export const dataLoadFailed = (action, error) => ({
  type: DATA_LOAD_FAIL,
  payload: { action, loading: false, error },
});

export const getOnlineStoreConfig = (proxyToken, onSuccess) => {
  return {
    type: createGetRequest(ONLINE_STORE),
    payload: {
      route: apiPaths.onlineStoreConfig.replace(
        '{proxy_merchant_token}',
        proxyToken,
      ),
      success: onSuccess,
      showAppSpinner: true,
    },
  };
};
