import { FEEDBACK_CLEAR, FEEDBACK_SET } from './types';

const initialState = {
  queue: [],
};

export default function feedbackReducer(state = initialState, action) {
  switch (action.type) {
    case FEEDBACK_SET:
      return { queue: [...state.queue, action.payload] };
    case FEEDBACK_CLEAR:
      return { queue: state.queue.slice(1) };
    default:
      return state;
  }
}
