const styles = theme => {
    return {
      form: {
        height: 'calc(100vh - 3.5rem)',
        width: '100%',
        overflow: 'auto',
      },
      root: {
        backgroundColor: '#F3F3F3',
        width: '100vw',
        textAlign: 'center',
        margin: 0,
        marginTop: '3rem',
      },
      title: {
        textAlign: 'left',
        padding: 0,
      },
      buttons: {
        margin: '0 auto',
        marginBottom: 25,
        width: '19.5rem',
        display: 'inline-block',
      },
      cardContainer: {
        padding: theme.spacing.unit,
      },
      dashedLine: {
        height: '1px',
        border: '0',
        borderTop: '1px dashed #e6e8e9',
        width: '100%',
        margin: '1rem 0',
      },
      card: {
        marginLeft: theme.spacing.unit / 2,
        marginRight: theme.spacing.unit / 2,
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: theme.spacing.unit * 1.5,
        width: '100%',
        paddingBottom: theme.spacing.unit,
      },
      paper: {
        margin: theme.spacing.unit * 2,
      },
      dialogContainer: {
        textAlign: 'center',
        padding: theme.spacing.unit,
      },
      dialogText: {
        color: theme.palette.text.primary,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
      },
      groupedButtons: {
        marginTop: theme.spacing.unit * 1.5,
        gridColumnGap: theme.spacing.unit + 'px',
        display: 'grid',
        gridAutoFlow: 'column',
      },
      clearButton: {
        backgroundColor: 'transparent',
        color: theme.palette.naranja.bloodOrange,
        border: 'solid 1px ' + theme.palette.naranja.bloodOrange,
      },
      noGeoContainer: {
        textAlign: 'center',
        padding: '0 ' + theme.spacing.unit * 2 + 'px',
        paddingTop: theme.spacing.unit * 7.2,
        backgroundColor: 'white',
        height: 'calc(100vh - 10.7rem)',
      },
      geoTitle: {
        fontSize: '18px',
      },
      animationContainer: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
      },
      onTheWayText: {
        fontSize: '18px',
        fontWeight: '500',
        marginBottom: '0',
      },
      onTheWayAnimation: {
        height: '8.9rem',
        width: '8.9rem',
      },
      failedTransferInfo: {
        fontSize: '14px',
        fontWeight: '600',
        color: theme.palette.text.auxiliar,
      },
    }
  }
  export default styles;
  