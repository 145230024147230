const styles = (theme) => ({
  container: {
    margin: '1rem',
    marginTop: '42px',
  },
  btnContainer: {
    textAlign: 'right',
    marginTop: '1rem',
    '& button': {
      marginLeft: '4px',
    },
  },
  title: {
    marginBottom: '1rem',
  },
  info: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
});

export default styles;
