import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { IoIosArrowForward } from 'react-icons/io';

export const Container = styled.div(() => ({
  width: '100%',
}));

export const Title = styled.div(({ theme }) => ({
  width: '100%',
  color: theme.palette.naranja.slate,
  fontSize: '24px',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: 'normal',
  display: 'flex',
  textAlign: 'center',
  marginBottom: `${theme.spacing.unit * 3}px`,
}));

export const Content = styled.div(({ theme }) => ({
  width: '100%',
  paddingTop: `${theme.spacing.unit * 4}px`,
  paddingBottom: `${theme.spacing.unit * 6}px`,
  color: theme.palette.naranja.slate,
  fontFamily: theme.typography.fontFamily,
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '30px',
  textAlign: 'justify',
}));

export const MessageContent = styled.p(({ theme }) => ({
  width: '100%',
  textAlign: 'justify',
  '& b': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '& a': {
    color: lightBlue[700],
    cursor: 'pointer',
  },
}));

export const LinkContainer = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
}));

export const LinkIcon = styled(IoIosArrowForward)({
  color: lightBlue[700],
  verticalAlign: 'text-bottom',
  height: '20px',
});

const LinkStyle = ({ theme }) => ({
  color: lightBlue[700],
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '30px',
  textAlign: 'center',

  '&:hover': {
    color: lightBlue[700],
    opacity: '.8',
  },
});

export const FormLink = styled(Link)(LinkStyle);
