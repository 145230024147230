import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import ClipAlert from '@clipmx/clip-storybook/dist/components/Alert';
import ClipLink from '@clipmx/clip-storybook/dist/components/Link';
import { ClipMaskInfo } from '@clipmx/clip-storybook/dist/components/Illustrations/ClipMask';
import Dialog from '@clipmx/clip-storybook/dist/components/Dialog/DialogTypes/SimpleDialog';
import getUrl from '../../../helpers/getUrl';
import urls from '../../../helpers/urls';
import { isEmpty } from '../../../helpers/validate';
import {
  LoansStates,
  PreapprovalStatus,
  LoansTabs,
} from '../../../constants/AppConstants';
import {
  getLoans,
  getPreapprovals,
  preapprovalsLoaded,
  getLoansLoaded,
} from '../redux/selectors';
import LoansContent from '../../loans/Commons/LoansContent';
import { commonPayload } from '../Commons/Clevertap';
import { Clevertap, EVENTS } from '../../../helpers/Clevertap';
import MyCrediClipDetail from './Details';
import * as Styled from './styled';
import styles from './styles';
import text from './text';

const MyCrediClip = ({
  loans = [],
  preapprovals = [],
  preapprovalsLoaded,
  loansLoaded,
  loansFormVersion,
  showCashAdvance,
  proxyMerchantToken,
  getCollections,
  classes,
  user,
  merchant,
  merchantInfo,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const getBaseLocation = () =>
    location.pathname.startsWith(urls.mobile) ? urls.mobile : '';

  useEffect(() => {
    if (loansLoaded && loans.length === 0) {
      history.replace(
        getUrl(`${getBaseLocation()}${urls.loans}`),
      );
    }
  }, [loansLoaded, loans])

  useEffect(() => {
    if (merchantInfo.proxy_id && user) {
      Clevertap.profile(user);
    }
  }, [user, merchant]);

  const closeDialog = () => {
    setOpenDialog(false);
    Clevertap.event(
      EVENTS.LOANS.TERMS_UNDERSTOOD_SELECTED,
      'TERMS_UNDERSTOOD_SELECTED',
      commonPayload(
        { proxy_merchant_token: proxyMerchantToken },
        { showCashAdvance, loansFormVersion, providerCode },
      ),
    )
  }

  const availables = preapprovals.filter(
    (preapproval) =>
      [
        PreapprovalStatus.AVAILABLE,
        PreapprovalStatus.ACCEPTED,
        PreapprovalStatus.SIGNED,
      ].includes(preapproval.status) && !preapproval.loan_id,
  );
  const expired = preapprovals.filter((preapproval) =>
    [PreapprovalStatus.EXPIRED].includes(preapproval.status),
  );
  const paid =
    loans.length > 0
      ? loans.filter((loan) =>
          [LoansStates.PAID, LoansStates.CANCELLED].includes(loan.status),
        )
      : [];
  const onhold =
    loans.length > 0
      ? loans.filter((loan) => [LoansStates.ON_HOLD].includes(loan.status))
      : [];
  const providerCode = loans.length > 0 ?? loans[0].provider_code;
  const monthlyCapCheck = loans.some(loan => loan.installment_type === 'FIXED');

  return (
    <LoansContent
      hasPreapprovals={availables.length > 0 || expired.length > 0}
      hasLoans
      tab={LoansTabs.CREDI_CLIP}
    >
      {loans.length > 0 &&
        loans
          .filter((loan) => loan.status === LoansStates.ACTIVE)
          .map((loan, index) => (
            <React.Fragment key={`activeLoan_${index}`}>
              <Styled.TitleLoans>{text.activeLoans}</Styled.TitleLoans>
              <Grid container spacing={16} key={`activeLoan_${index}`}>
                <Styled.GridCrediCard item sm={12} xs={12}>
                  <MyCrediClipDetail loan={loan} monthlyCapCheck={monthlyCapCheck} />
                </Styled.GridCrediCard>
              </Grid>
              <Styled.LoansDivider />
            </React.Fragment>
          ))}
      {onhold.length > 0 && (
        <React.Fragment>
          <Styled.TitleLoans className="ended">
            {text.onHoldLoans}
          </Styled.TitleLoans>
        </React.Fragment>
      )}
      {onhold.map((loan, index) => (
        <Grid container spacing={16} key={`paidLoan_${index}`}>
          <Styled.GridCrediCard item sm={12} xs={12}>
            <MyCrediClipDetail loan={loan} />
          </Styled.GridCrediCard>
        </Grid>
      ))}
      {paid.length > 0 && (
        <React.Fragment>
          <Styled.TitleLoans className="ended">
            {text.paidLoans}
          </Styled.TitleLoans>
        </React.Fragment>
      )}
      {paid.map((loan, index) => (
        <Grid container spacing={16} key={`paidLoan_${index}`}>
          <Styled.GridCrediCard item sm={12} xs={12}>
            <MyCrediClipDetail loan={loan} />
          </Styled.GridCrediCard>
        </Grid>
      ))}
      <ClipAlert style={{ marginBottom: 11, marginTop: 11 }} severity="info">
        {text.termsInfo}
        <b>
          <ClipLink
            className={classes.linkDialog}
            color="inherit"
            onClick={(e) => {
              e.stopPropagation();
              setOpenDialog(true);
            }}
          >
            {text.clickHere}
          </ClipLink>
        </b>
        .
      </ClipAlert>
      <Dialog
        open={openDialog}
        primaryText={text.understandBtn}
        primaryAction={() =>
          closeDialog()
        }
        className={classes.dialog}
      >
        <Grid container spacing={8} className={classes.textContainer}>
          <Grid item xs={12} className={classes.logoContent}>
            <ClipMaskInfo color="primary" />
          </Grid>
          <Grid item xs={12} className={classes.titleContent}>
            <b>{text.termsTitle}</b>
          </Grid>
          <Grid item xs={12} className={classes.fontContent}>
            <p>{text.termsDescription}</p>
            <p>
              {text.termsFooter}
              <i>
                <a href={urls.clipPhone} target={'_blank'}>
                  {text.termsFooterTelephone}
                </a>
              </i>
              .
            </p>
            <p>
              {text.termsFooterSendEmail}
              <i>
                <a href={`mailto:${urls.clipHelpEmail}`} target={'_blank'}>
                  {text.termsFooterEmail}
                </a>
              </i>
              {text.termsFooterEnd}
            </p>
          </Grid>
        </Grid>
      </Dialog>
    </LoansContent>
  );
}

const mapStateToProps = (state) => {
  return {
    loans: getLoans(state),
    preapprovals: getPreapprovals(state),
    preapprovalsLoaded: preapprovalsLoaded(state),
    loansLoaded: getLoansLoaded(state),
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
    proxyMerchantToken: state.merchant?.info.proxy_id?.proxy_merchant_token,
    user: state.user,
    merchant: state.merchant,
    merchantInfo: state.merchant.info,
  };
};

export default withRouter(
  connect(mapStateToProps, null)(withStyles(styles)(MyCrediClip)),
);
