import ReactDOM from 'react-dom';
import { isEmpty } from './validate';

export function isClassComponent(component) {
  return (
    typeof component === 'function' && !!component.prototype.isReactComponent
  );
}

export function isFunctionComponent(component) {
  return (
    typeof component === 'function' &&
    String(component).includes('.createElement')
  );
}

export function isReactComponent(component) {
  return isClassComponent(component) || isFunctionComponent(component);
}

export function unescapeHtmlEntities(textWithHtmlEntities) {
  if (typeof textWithHtmlEntities  === 'undefined' || textWithHtmlEntities == null )  {
    return textWithHtmlEntities;
  }
  
  let unescapedText = textWithHtmlEntities;
   unescapedText = unescapedText.replace(/&amp;/gi, '&');
   unescapedText = unescapedText.replace(/&quot;/gi, '"');
   unescapedText = unescapedText.replace(/&lt;/gi, '<');
   unescapedText = unescapedText.replace(/&gt;/gi, '>');
   unescapedText = unescapedText.replace(/&#34;/gi, '"');
   unescapedText = unescapedText.replace(/&#39;/gi, '\'');

   return unescapedText;
}

export class DecodedURLParams {
  /**
   *
   * @param search the search string of your URL
   */
  constructor(search) {
    this.queryString = search;
    this.params = {};
    this.parseQueryString();
  }

  parseQueryString() {
    this.queryString.split('&').reduce((a, b) => {
      let [key, value] = b.split('=');
      a[key] = value;
      return a;
    }, this.params);
  }

  get(key) {
    return this.params[key];
  }
}

export default class Utils {
  static fallbackCopyTextToClipboard = (input) => {
    return new Promise((resolve, reject) => {
      input.select();
      const success = document.execCommand('copy');

      if (success) {
        resolve(input.value);
      } else {
        reject(new Error('Unable to copy to clipboard'));
      }
    });
  };

  static copyTextToClipboard = (input) => {
    if (!navigator.clipboard) return Utils.fallbackCopyTextToClipboard(input);

    return navigator.clipboard.writeText(input.value);
  };

  static setTimeout = (timeout) => {
    return new Promise((resolve) => setTimeout(resolve, timeout));
  };

  static findDomNodeInComponent(component, tagName) {
    if (typeof component !== 'object' || typeof tagName !== 'string') {
      throw TypeError(
        'utils:findDomElementInComponent - The component should be an object and the tagName should be an string',
      );
    }
    try {
      const formDomRoot = ReactDOM.findDOMNode(component);
      const formInstance =
        formDomRoot.nodeName === tagName.toUpperCase()
          ? [formDomRoot]
          : formDomRoot.getElementsByTagName(tagName);
      return formInstance;
    } catch (err) {
      /* Do nothing */
    }
  }

  static getDays = (month, year) => {
    const days = [];
    if (!isEmpty(year) && !isEmpty(month)) {
      const daysPerMont = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      const monthSelected = parseInt(month, 10);

      if (
        monthSelected === 2 &&
        new Date(year, monthSelected - 1, 29).getMonth() === 1
      ) {
        daysPerMont[1]++;
      }
      for (let day = 1; day <= daysPerMont[monthSelected - 1]; day++) {
        days.push({ id: day, name: day });
      }
    }
    return days;
  };

  static getYearOptions = () => {
    let years = [];
    let currentYear = new Date().getFullYear();
    let initialYear = currentYear - 100;
    for (var year = currentYear; year > initialYear; year--) {
      years.push({ id: year, name: year });
    }
    return years;
  };

  static dateToString = (month, day, year) => {
    return `${`${month}`.padStart(2, '0')}/${`${day}`.padStart(
      2,
      '0',
    )}/${year}`;
  };

  static camelCase = (str) => {
    return str
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      .replace(/^(.)/, function (text) {
        return text.toLowerCase();
      });
  };

  static dateToShortenTextFormat = (strDate) => {
    if (!isEmpty(strDate)) {
      const dateFormated = strDate.substr(0, 10).replace(/-/g, '/');
      const date = new Date(dateFormated);
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return Utils.camelCase(date.toLocaleDateString('es', options)).replace(
        '.',
        ' ',
      );
    }
    return 'NA';
  };

  static getTimeFromStrDate = (strDate) => {
    if (!strDate) return 0;
    const dateFormated = strDate.substr(0, 10).replace(/-/g, '/');
    return new Date(dateFormated).getTime();
  };

  static dateToLongTextFormat = (strDate) => {
    if (!isEmpty(strDate)) {
      const dateFormated = strDate.substr(0, 10).replace(/-/g, '/');
      const date = new Date(dateFormated);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return Utils.camelCase(date.toLocaleDateString('es', options)).replace(
        '.',
        ' ',
      );
    }
    return 'NA';
  };
}
