import React, { useState, useEffect, useCallback } from 'react';
import text from './text';
import BerlinService from '../../service';
import ClipDivider from '@clipmx/clip-storybook/dist/components/Divider';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { styles } from '../ContactInfo/styles';
import { Edit, Check } from '@clipmx/clip-storybook/dist/components/Icons';
import { ClipTypography, ClipInput, ClipButton } from '@clipmx/clip-storybook';

const useStyles = makeStyles(styles);

const EditContact = (props) => {
  const { contact, setLoadContact, closeDrawer } = props;
  const classes = useStyles(props);

  //CLABE
  const [clabe, setClabe] = useState(null);
  const [clabeCorrect, setclabeCorrect] = useState(false);
  const [clabeError, setClabeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(text.clabeError);
  //NAME
  const [name, setName] = useState(null);
  const [errorName, setErrorName] = useState(false);
  const [errorNameMessage, setErrorNameMessage] = useState(
    text.errorNameMessage,
  );
  //ALIAS
  const [alias, setAlias] = useState(null);
  const [erroAlias, setErrorAlias] = useState(false);
  const [errorAliasMessage, setErrorAliasMessage] = useState(
    text.errorNameMessage,
  );
  //BANK
  const [bank, setBank] = useState(null);

  useEffect(() => {
    if (contact) {
      setName(contact.name);
      setAlias(contact.alias);
      setClabe(contact.clabe);
      validateCLABE(contact.clabe);
      setBank(contact.bank);
    }
  }, [contact]);

  const validateCLABE = (value) => {
    setClabeError(false);
    setClabe(value);
    if (value.length !== 18) {
      setClabeError(true);
      setErrorMessage(text.clabeErrorLength);
      setclabeCorrect(false);
    } else {
      BerlinService.getInterbankAccountInfo(value)
        .then((response) => {
          setBank(response.data.message.bank_acronym);
          setclabeCorrect(true);
        })
        .catch((error) => {
          setClabeError(true);
          setErrorMessage(text.clabeError);
        });
    }
  };

  const validateName = useCallback((value) => {
    setErrorName(false);
    setName(value);
    if (value.length < 3) {
      setErrorName(true);
      setErrorNameMessage(text.errorNameMessage);
    }
  }, [setErrorName, setName, setErrorNameMessage]);

  const updateContact = async () => {
    const body = {
      recipient_id: contact.id,
      nickname: alias,
      recipient_name: name,
      recipient_bank: bank,
      recipient_account: clabe,
    };
    try {
      await BerlinService.putUpdateContact(body);
      setLoadContact(true);
      closeDrawer()
    } catch (error) {
      console.log(error);
    }
  };

  const validateAlias =  useCallback((value) => {
    setErrorAlias(false);
    setAlias(value);
    if (value.length < 3) {
      setErrorAlias(true);
      setErrorAliasMessage(text.errorNameMessage);
    }
  }, [setAlias, setErrorAlias, setErrorAliasMessage]);

  const validateButton = useCallback(() => {
    if(clabe !== contact.clabe || name !== contact.name || alias !== contact.alias){
      if (!clabe || clabe.trim() === '' || clabeCorrect === false) {
        return true;
      } else if (!name || name.trim() === '' || name.length < 3) {
        return true;
      } else if (!alias || alias.trim() === '' || alias.length < 3) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }, [clabe, contact, name, alias, clabeCorrect]);

  return (
    <div>
      <ClipTypography
        variant="h3"
        fontWeight="semiBold"
        gutterBottom
        align="center"
      >
        {text.title}
      </ClipTypography>
      <div className={classes.editTitleContainer}>
        <Edit className={classes.contactsIcon} />
        <ClipTypography
          variant={'body1'}
          className={classes.contactsTitle}
          component={'p'}
        >
          {text.title}
        </ClipTypography>
      </div>
      <ClipInput
        fullWidth
        type={'text'}
        color={'secondary'}
        label={text.clabe}
        adornmentIcon={clabeCorrect && <Check color={'secondary'} />}
        helperText={text.clabeLenght}
        error={clabeError}
        errormessage={errorMessage}
        value={clabe}
        onChange={(e) => validateCLABE(e.target.value.replace(/\s/g, ''))}
      />
      <ClipDivider className={classes.divider} />
      <ClipInput
        fullWidth
        disabled
        type={'text'}
        color={'secondary'}
        label={text.bank}
        value={bank}
      />
      <ClipDivider className={classes.dividerBank} />
      <ClipInput
        fullWidth
        type={'text'}
        color={'secondary'}
        label={text.name}
        helperText={text.fullName}
        error={errorName}
        errormessage={errorNameMessage}
        value={name}
        onChange={(e) => validateName(e.target.value)}
      />
      <ClipInput
        fullWidth
        type="text"
        color="secondary"
        inputProps={{ maxLength: 20 }}
        helperText={text.aliasHelper}
        label={text.alias}
        error={erroAlias}
        errormessage={errorAliasMessage}
        value={alias}
        onChange={(e) => validateAlias(e.target.value)}
      />
      <ClipDivider className={classes.divider} />
      <ClipButton
        fullWidth
        variant="contained"
        color="primary"
        disabled={validateButton()}
        onClick={() => updateContact()}
      >
        {text.save}
      </ClipButton>
      <ClipDivider className={classes.dividerBank} />
    </div>
  );
};

export default EditContact;
