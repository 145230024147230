const styles = (theme) => ({
  title: {
    marginBottom: '1rem',
  },
  container: {
    margin: '1rem',
    marginTop: '42px',
  },
  btnContainer: {
    textAlign: 'right',
    marginTop: '1rem',
    '& button': {
      marginLeft: '4px',
    },
  },
  field: {
    marginBottom: '16px',
  },
});

export default styles;
