export const styles = (theme) => {
  const selectStyles = {
    selectRoot: {
      height: `${theme.spacing.unit * 6}px`,
      marginTop: `-${theme.spacing.unit}px`,
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      boxShadow: '0 0 0 1px #ccd0d3',
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 500,
      color: '#001724',
    },
    selectSelect: {
      height: `${theme.spacing.unit * 6}px`,
    },
    selectMenu: {
      height: `${theme.spacing.unit * 2.75}px`,
      paddingTop: `${theme.spacing.unit * 2.5}px`,
      paddingLeft: `${theme.spacing.unit * 2}px`,
      paddingRight: `${theme.spacing.unit * 2}px`,
      textAlign: 'left',
    },
    selectIcon: {
      color: '#99A2A7',
      marginRight: `${theme.spacing.unit}px`,
    },
    selectError: {
      paddingLeft: `${theme.spacing.unit * 2}px`,
      paddingRight: `${theme.spacing.unit * 2}px`,
      boxShadow: '0 0 0 2px #d10202',
    },
    selectDisabled: {
      backgroundColor: 'rgba(248, 248, 248, 0.5)',
    },
  };

  const labelStyles = {
    labelRoot: {
      paddingTop: `${theme.spacing.unit * 0.125}px`,
      paddingLeft: `${theme.spacing.unit * 2}px`,
      fontSize: `${theme.spacing.unit * 1.75}px`,
      color: '#66737b!important',
    },
    labelShrink: {
      transform: 'translate(4.5px, 18px) scale(0.75)',
      transformOrigin: 'top left',
    },
  };

  const errorStyles = {
    errorRoot: {
      width: `calc(100% - ${theme.spacing.unit * 4}px)`,
      margin: '0 auto',
      padding: `${theme.spacing.unit * 0.75}px ${theme.spacing.unit * 1.5}px`,
      borderBottomLeftRadius: `${theme.spacing.unit * 0.5}px`,
      borderBottomRightRadius: `${theme.spacing.unit * 0.5}px`,
      backgroundColor: '#ffe7e7',
      fontSize: `${theme.spacing.unit * 1.5}px`,
      color: '#7c0b0b',
    },
  };

  const menuItemStyles = {
    menuItemRoot: {
      fontSize: `${theme.spacing.unit * 1.75}px`,
      color: '#66737b!important',
    },
    menuItemSelected: {
      color: '#001724!important',
      border: `solid ${theme.spacing.unit * 0.25}px ${
        theme.palette.naranja.bloodOrange
      }`,
      backgroundColor: `${theme.palette.common.white}!important`,
    },
  };

  return { ...selectStyles, ...labelStyles, ...errorStyles, ...menuItemStyles };
};
