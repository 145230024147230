import React from 'react';

import * as Styled from './styled';
import PropTypes from 'prop-types';

export class Terms extends React.PureComponent {
  static propTypes = {
    setActiveTab: PropTypes.func.isRequired,
  };

  return = () => {
    const { setActiveTab } = this.props;
    setActiveTab(3);
  };

  render() {
    return (
      <Styled.TermsContainer>
        <Styled.Title>Términos y condiciones</Styled.Title>
        <Styled.RegularText>
          Por medio del presente documento, CACAO PAYCARD SOLUTIONS, S.A. DE
          C.V., INSTITUCIÓN DE FONDO DE PAGO ELECTRÓNICO (en adelante
          “CACAOPAY”), manifiesta que es una sociedad mercantil constituida en
          los Estados Unidos Mexicanos con domicilio social en Boulevard Manuel
          Ávila Camacho 50, 5º piso, Lomas de Chapultepec, C.P. 11000, Alcaldía
          Miguel Hidalgo, Ciudad de México, y pone a disposición del USUARIO los
          siguientes Términos y Condiciones Generales de Uso que tienen carácter
          obligatorio y vinculante (en adelante “TCG”) que regulan el acceso y
          uso de las aplicaciones informáticas, interfaces, página de Internet
          www.cacaopaycard.com o cualquier otro medio de comunicación
          electrónica o digital que CACAOPAY utiliza para operar con sus
          usuarios (en lo sucesivo la “PLATAFORMA CACAOPAY”.{' '}
        </Styled.RegularText>
        <Styled.Subtitle>1. Manifestaciones</Styled.Subtitle>
        <Styled.RegularText>
          Usted, (en lo sucesivo el “Usuario”) manifiesta ser una persona física
          mayor de 18 años, con capacidad para obligarse en términos de los
          presentes TCG; así como manifiesta expresamente que actúa por cuenta
          propia y conoce que CACAOPAY se encuentra regulada conforme lo
          establecido en la Ley para Regular las Instituciones de Tecnología
          Financiera, las Disposiciones de carácter general aplicables a las
          Instituciones de tecnología Financiera y demás normativa aplicable
          vigente (en lo sucesivo “Ley Fintech”). Los TCG se encuentran a su
          disposición y se muestra la última fecha de su actualización con el
          objetivo de hacer de su conocimiento cualquier modificación. Al hacer
          uso de la PLATAFORMA CACAOPAY, el Usuario manifiesta que (i) conocer
          este documento, así como que se encuentra de acuerdo con su contenido
          y acepta sujetarse a las disposiciones de éste, al seguir usando los
          productos y servicios de CACAOPAY y al expresamente continuar con el
          registro en la PLATAFORMA, lo que se considera como una aceptación
          expresa; (ii) garantiza, bajo protesta de decir verdad, ser mayor de
          edad, residente mexicano, tener domicilio u establecimiento en los
          Estados Unidos Mexicanos y (iii) declara a través de la aceptación de
          los TCG que se encuentra actuando por cuenta propia, en términos del
          artículo 11 de las Disposiciones de Carácter General a que se refiere
          el artículo 58 de la Ley para Regular las Instituciones de Tecnología
          Financiera. Adicional a la aceptación de los TCG, el USUARIO, que
          desee hacer uso de los productos o servicios de CACAOPAYCARD, deberá
          formalizar con CACAOPAY un contrato de emisión y depósito mercantil de
          fondos de pago electrónico (en adelante “el Contrato”), el cual se
          pone a disposición del USUARIO de manera electrónica a través de la
          PLATAFORMA CACAOPAY. El Usuario acuerda leer los TCG y manifestar su
          aceptación sujeto en todo momento al cumplimiento de las Disposiciones
          de carácter general de la CONDUSEF en materia de transparencia y sanas
          prácticas aplicables a las instituciones de tecnología financiera, en
          adelante (“Disposiciones CONDUSEF”). Cualquier Usuario que no acepte
          en forma absoluta y completa los TCG aquí consignados, deberá
          abstenerse de acceder, utilizar y observar el Sitio Web y la
          Plataforma Paycard.
        </Styled.RegularText>
        <Styled.Subtitle>2. Definiciones</Styled.Subtitle>
        <Styled.RegularText>
          a) Cuenta: A la cuenta de fondos de pago electrónico que CACAOPAY abra
          a nombre del USUARIO.
        </Styled.RegularText>
        <Styled.RegularText>
          b) Día Hábil Bancario: Significa a los días en que las instituciones
          de crédito no estén obligadas a cerrar sus puertas ni a suspender
          Operaciones, en términos de las disposiciones de carácter general que,
          para tal efecto, emita la Comisión Nacional Bancaria y de Valores.
        </Styled.RegularText>
        <Styled.RegularText>
          c) FPE: a los Fondos de Pago Electrónico, que se emiten por CACAOPAY
          contra la recepción de una cantidad de dinero.
        </Styled.RegularText>
        <Styled.RegularText>
          d) Ley Fintech: a la Ley para Regular las Instituciones de Tecnología
          Financiera.
        </Styled.RegularText>
        <Styled.RegularText>
          e) Plataforma Paycard: Entiéndase de manera indistinta o de manera
          conjunta la Aplicación y el Sitio Web.
        </Styled.RegularText>
        <Styled.RegularText>
          f) Sitio Web: Sitio Web propiedad de CACAOPAY cuya dirección es:
          www.cacaopaycard.com.
        </Styled.RegularText>
        <Styled.RegularText>
          g) Tarjeta CACAOPAY, se refiere al producto ofrecido por CACAOPAY,
          consistente en un medio de disposición respecto de los FPE de la
          Cuenta del USUARIO.
        </Styled.RegularText>
        <Styled.RegularText>
          h) TCG: Al presente documento, denominado Términos y Condiciones
          Generales del Uso de la PLATAFORMA PAYCARD.
        </Styled.RegularText>
        <Styled.RegularText>
          i) Transferencias: A aquella operación realizada entre CACAOPAY y otra
          institución de fondos de pago electrónico o Entidad Financiera,
          conforme al cual CACAOPAY realiza el abono o una orden de en una
          Cuenta, derivada del cargo que esa otra institución de fondos de pago
          electrónico o entidad haga en la cuenta correspondiente, equivalente a
          aquella cantidad de dinero que el USUARIO haya indicado.
        </Styled.RegularText>
        <Styled.RegularText>
          j) Transferencias de FPE: A aquella operación realizada por CACAOPAY
          de conformidad con los contratos celebrados con sus USUARIOS para la
          apertura de Cuentas, de acuerdo con la cual dicha institución abona
          una cantidad determinada de fondos de pago electrónico en una de
          dichas Cuentas, derivado del cargo por la referida cantidad en alguna
          otra de esas Cuentas.
        </Styled.RegularText>
        <Styled.RegularText>
          k) Usuario: La persona física que hace uso de los servicios que se
          prestan en la PLATAFORMA PAYCARD.
        </Styled.RegularText>
        <Styled.Subtitle>3. Aceptación</Styled.Subtitle>
        <Styled.RegularText>
          Al ingresar a la PLATAFORMA CACAOPAY, el USUARIO reconoce que CACAOPAY
          les está otorgando una licencia de uso temporal de la PLATAFORMA
          CACAOPAY, limitada, no exclusiva, no sublicenciable, revocable y no
          transferible, para la realización de operaciones y el uso de la
          Tarjeta CACAOPAY. El USUARIO, respecto de la PLATAFORMA CACAOPAY está
          de acuerdo que el uso de ésta se realiza bajo su propia
          responsabilidad, con relación a los servicios y/o productos que ahí se
          prestan, y en específico respecto de la Tarjeta CACAOPAY y que conoce
          los posibles riesgos tecnológicos, cibernéticos y de fraude en los
          productos y servicios financieros comercializados por CACAOPAY, tales
          como el envío de notificaciones de operaciones inusuales vía
          telefónica, mensajes de texto SMS o correo electrónico, entre otros.
          La PLATAFORMA CACAOPAY, incluyendo sin limitación alguna, todo el
          contenido, como son de forma enunciativa más no limitativa, textos,
          fotografías, ilustraciones, gráficos, videos, audio, diseños, códigos,
          datos y cualquier otra información son propiedad única y exclusiva de
          CACAOPAY, sin condición a, cualesquiera de los derechos de autor,
          derechos de marca, derechos de patente, derechos de base de datos,
          derechos morales, y otras propiedades intelectuales y derechos
          patrimoniales del mismo, toda vez que se encuentran regulados y
          protegidos por la Ley Federal del Derecho de Autor, la Ley de la
          Propiedad Industrial, así como cualquier otro ordenamiento en materia
          de Propiedad Intelectual aplicable. Los signos distintivos, incluyendo
          las marcas y avisos comerciales, expuestos en los servicios y en la
          Tarjeta CACAOPAY serán en todo momento propiedad de CACAOPAY, aun
          cuando los mismos se encuentren registrados o no, y no pueden ser
          usados por el USUARIO sin consentimiento expreso y por escrito de
          CACAOPAY.
        </Styled.RegularText>
        <Styled.Subtitle>4. Acceso a la Plataforma CACAOPAY</Styled.Subtitle>
        <Styled.RegularText>
          El USUARIO reconoce, acepta y entiende que para hacer uso de la
          PLATAFORMA CACAOPAY deberá contar con un equipo de cómputo con las
          características operativas mínimas para la ejecución de la PLATAFORMA
          CACAOPAY, debiendo ser conseguido y costeado por él mismo; en caso de
          contravenir esta disposición, el USUARIO será responsable de cualquier
          tipo de daño que pudiera sucederles, inclusive por virus trasmitidos
          por medios informáticos o vías de comunicación y, en general, por
          cualquier otro tipo de pérdida, menoscabo, destrucción o deterioro que
          pudiera sufrir el USUARIO en el uso de dicho equipo de cómputo. Los
          servicios ofrecidos en la PLATAFORMA CACAOPAY y la Tarjeta CACAOPAY
          sólo están disponibles para personas que tengan capacidad legal para
          contratar. Por lo tanto, no podrán utilizar los servicios las personas
          que no tengan esa característica o los menores de edad.
        </Styled.RegularText>
        <Styled.Subtitle>5. Registro y datos proporcionados</Styled.Subtitle>
        <Styled.RegularText>
          Para poder acceder y hacer uso de los productos y servicios, el
          USUARIO, deberá registrarse, en la PLATAFORMA CACAOPAY completando los
          formularios que CACAOPAY ponga a su disposición para tales efectos,
          con los datos personales solicitados, los cuales de manera enunciativa
          mas no limitativa podrán ser: (i) nombre completo o razón social, (ii)
          domicilio, compuesto por calle, número exterior e interior, código
          postal, colonia, demarcación política, estado, país, (iii) ocupación o
          actividad, (iv) correo electrónico, (v) contraseña (iv) teléfono
          celular, (v) perfiles en redes sociales, (vi) geolocalización, entre
          otros. El USUARIO manifiesta, bajo protesta de decir verdad que, toda
          la información y documentación que ha proporcionado y/o proporcione
          con relación a cualquier actividad que se promueva en la PLATAFORMA
          CACAOPAY, es verdadera, completa y correcta quedando, por ende,
          obligado a indemnizar y sacar en paz y a salvo a CACAOPAY de cualquier
          daño, perjuicio, demanda y/o acción que dicha omisión o falsedad lo
          provoque. CACAOPAY podrá utilizar diversos medios para identificar a
          sus USUARIOS, pero CACAOPAY no se responsabiliza por la certeza de los
          Datos Personales provistos por sus USUARIOS. Asimismo se reserva el
          derecho de solicitar algún comprobante y/o dato adicional y/o
          documentación a efectos de corroborar los Datos Personales recabados
          para el registro en la PLATAFORMA CACAOPAY, mismos que serán tratados
          conforme al Aviso de Privacidad puesto a disposición del USUARIO. En
          caso de existir sospecha de falsedad de información, documentos o
          manifestaciones por los USUARIOS, CACAOPAY podrá suspender temporal o
          definitivamente a aquellos USUARIOS cuyos datos no hayan podido ser
          confirmados. En este entendido, el USUARIO, es el único responsable
          del resguardo de dichos factores de autenticación, contraseñas y
          claves de acceso al sistema, por lo que en este acto se comprometen a
          tomar las medidas de seguridad necesarias a fin de que no sean
          utilizadas por ningún tercero en contraposición a lo establecido en
          los presentes TCG. Está prohibido que un mismo USUARIO posea más de
          una cuenta de USUARIO en la PLATAFORMA CACAOPAY. En caso de que
          CACAOPAY detecte cuentas de USUARIO que contengan datos relacionados o
          coincidentes, podrá cancelaras o inhabilitarlas, realizando la debida
          notificación al USUARIO. El USUARIO autoriza a CACAOPAY, directamente
          o a través de terceros, para hacer cualquier pregunta o investigación
          que considere necesaria para validar su identidad y/o autenticar su
          identidad e información para la utilización de la PLATAFORMA CACAOPAY.
          Esto puede incluir, pero no está limitado a información y/o
          documentación acerca del uso de la PLATAFORMA o requerir que tome
          acciones para confirmar dirección de correo electrónico, número de
          teléfono celular/móvil, y la verificación de la información en bases
          de datos de terceros o por medio de otras fuentes. Este proceso es
          para fines de verificación interna.
        </Styled.RegularText>
        <Styled.Subtitle>
          6. Autenticación y responsabilidad de contraseñas
        </Styled.Subtitle>
        <Styled.RegularText>
          Para el acceso a la Cuenta del USUARIO inmediato posterior al proceso
          de registro, CACAOPAY le podrá proporcionar y validar una contraseña
          de acceso, factores de autenticación, claves de acceso y/o algún medio
          de identificación (en lo sucesivo “Contraseña”), las cuales se
          considerarán un elemento personalísimo y habilitador para acceder a
          los servicios de la PLATAFORMA CACAOPAY. Al segundo acceso a la
          PLATAFORMA CACAOPAY, el USUARIO deberá generar una Contraseña que
          tendrá carácter personal e intransferible, por lo que El USUARIO se
          obliga a guardar la confidencialidad de su Contraseña de acceso,
          asumiendo en caso contrario, las consecuencias que pudieran derivarse
          del uso de esta, por terceras personas distintas del USUARIO, debiendo
          mantener en paz y a salvo a CACAOPAY por cualquier tipo de uso no
          autorizado de la Contraseña. Derivado de lo anterior el USUARIO
          reconoce que será responsable por todas las operaciones efectuadas con
          su Contraseña.
        </Styled.RegularText>
        <Styled.Subtitle>7. Niveles de cuenta de FPE</Styled.Subtitle>
        <Styled.RegularText>
          CACAOPAY clasificará cada una de las Cuentas en alguno de los tres
          niveles indicados a continuación, dependiendo de los criterios y
          requisitos para la apertura de cuenta de que se trate. Nivel 1 750
          UDIS (mensual) y Saldos no mayores a 1000 UDIS Nivel 2 3000 UDIS
          (mensual) Nivel 3 Sin límite Cabe señalar, que se dará a conocer al
          USUARIO a la contratación del servicio con CACAOPAY la información y/o
          documentación necesaria para integrar su expediente de identificación.
        </Styled.RegularText>
        <Styled.Subtitle>8. Protección de datos personales</Styled.Subtitle>
        <Styled.RegularText>
          El USUARIO reconoce y acepta que CACAOPAY realiza el tratamiento de
          datos personales y patrimoniales de conformidad con los principios de
          licitud, consentimiento, información, calidad, finalidad, lealtad,
          proporcionalidad y responsabilidad en términos de lo dispuesto en la
          Ley Federal de Protección de Datos Personales en Posesión de los
          Particulares (LFPDPPP), y en apego a su aviso de privacidad dado a
          conocer con anticipación en la PLATAFORMA CACAOPAY. El USUARIO
          mantendrá en paz y a salvo a CACAOPAY por cualquier sanción o multa a
          la que se pudiera hacer acreedor este último por el mal uso de datos
          personales de sus USUARIOS.
        </Styled.RegularText>
        <Styled.Subtitle>9. Notificaciones</Styled.Subtitle>
        <Styled.RegularText>
          El USUARIO reconoce que CACAOPAY recibirá y realizará notificaciones
          de confirmación o cancelación de operaciones, cambios y recepción de
          información por medio de la PLATAFORMA CACAOPAY, no obstante las
          mismas podrá realizarlas por medios electrónicos previamente
          informados al USUARIO, no obstante bajo ninguna circunstancia CACAOPAY
          utilizará canales de comunicación no oficiales, por su parte el
          USUARIO se obliga a realizar las siguientes notificaciones: 9.1
          Cambios El USUARIO deberá notificar a CACAOPAY a través de la
          PLATAFORMA CACAOPAY, a la brevedad posible, si existe algún cambio en
          sus Datos Personales proporcionados conforme al primer párrafo del
          apartado anterior y, en general, de cualquier información que sea
          actualizada o modificada. 9.2 Robo o extravío de contraseñas En caso
          de extravío, robo o vulneración de los factores de autenticación,
          claves de acceso y/o contraseñas en general el USUARIO deberá
          comunicar tal hecho a CACAOPAY, a través de la PLATAFORMA CACAOPAY de
          forma inmediata, en el entendido de que, en ninguna circunstancia,
          CACAOPAY será responsable de los cargos u operaciones y/o
          autorizaciones dadas con anterioridad al reporte de robo o extravió,
          aun cuando se hayan realizado por un tercero. 9.3 Robo o extravío de
          tarjeta CACAOPAY En caso de que el USUARIO extravíe o sea objeto del
          robo de la Tarjeta CACAOPAY, éste deberá avisar a CACAOPAY en
          cualquier día natural a través del correo electrónico
          contacto@cacaopaycard.com, respecto de dicha circunstancia. El aviso
          anterior, podrá realizarse también por los cargos no reconocidos
          respecto de la Tarjeta CACAOPAY desde el momento del aviso de robo o
          extravío. CACAOPAY no solicita un trámite adicional para el caso de
          robo o extravío de la Tarjeta CACAOPAY. CACAOPAY le proporcionará por
          correo electrónico un acuse de recibo y un folio que indique el número
          progresivo de atención a su solicitud, indicando la fecha y hora de la
          solicitud. Esta información se dará a conocer al USUARIO al momento de
          la solicitud o a más tardar el día hábil siguiente a la solicitud.
        </Styled.RegularText>
        <Styled.Subtitle>
          10. Usos y restricciones de la plataforma CACAOPAY
        </Styled.Subtitle>
        <Styled.RegularText>
          Para que el USUARIO pueda acceder a la PLATAFORMA CACAOPAY, será
          necesario que este se autentique a través de la Contraseña que
          CACAOPAY le solicité, y los cuales le fueron asignados durante el
          proceso de registro. Una vez autenticado podrá hacer uso de la
          PLATAFORMA CACAOPAY para efectuar las operaciones. Mediante los TCG,
          se le informa al USUARIO que a través de la PLATAFORMA CACAOPAY éste
          podrá contratar con CACAOPAY, la apertura de una Cuenta de FPE con
          medio de disposición consistente en la Tarjeta CACAOPAY. Al respecto
          los servicios que se ofrecen en la PLATAFORMA CACAOPAY, son los
          consistentes en el abono de recursos a la Cuenta, Transferencias,
          Transferencias de FPE, remisión de FPE y administración de la Cuenta
          de FPE, incluyendo el registro de las operaciones que en su caso
          realice el USUARIO. Como consecuencia del registro de las operaciones
          que se realicen con la Tarjeta CACAOPAY, el USUARIO podrá consultar en
          la propia PLATAFORMA CACAOPAY sus movimientos y saldos. El USUARIO
          acepta que no todos los usos o funcionalidades estarán disponibles de
          inicio, ya que algunos de ellos podrán ser utilizados solamente con
          posterioridad a su contratación, activación o registro, según se
          indique en las condiciones de contratación que se establezcan en la
          documentación respectiva. No obstante lo anterior CACAOPAY informa que
          la PLATAFORMA CACAOPAY, no podrá ser usada para fines distintos a los
          expresamente enunciados en los presente TCG. El USUARIO acepta que no
          todos los usos o funcionalidades estarán disponibles de inicio, ya que
          algunos de ellos podrán ser utilizados solamente con posterioridad a
          su contratación, activación o registro del USUARIO, según se indique
          en las condiciones de contratación que se establezcan en la
          documentación respectiva. No obstante lo anterior CACAOPAY informa que
          la PLATAFORMA CACAOPAY, no podrá ser usada para fines distintos a los
          expresamente enunciados en el apartado anterior.
        </Styled.RegularText>
        <Styled.Subtitle>11. Modificaciones</Styled.Subtitle>
        <Styled.RegularText>
          11.1 Modificaciones a la plataforma CACAOPAY se reserva el derecho de
          suprimir o modificar el contenido de la PLATAFORMA CACAOPAY, así
          mismo, no será responsable por cualquier falla o retraso que se genere
          al eliminar tal material. En ninguna circunstancia CACAOPAY será
          responsable de cualquier daño y/o perjuicio, directo o indirecto,
          causado en virtud de la confianza del USUARIO en información obtenida
          a través de la PLATAFORMA CACAOPAY. De esta forma, el USUARIO está de
          acuerdo en revisar dichos TCG periódicamente con la finalidad de
          mantenerse al tanto de dichas modificaciones. No obstante, lo
          anterior, cada vez que el USUARIO acceda al sitio señalado se
          considerará como una aceptación absoluta a las modificaciones del
          presente acuerdo. 11.2 Modificaciones a TCG Las partes reconocen y
          aceptan que estos TCG, así como cualquier otro documento que forme
          parte del presente acuerdo, podrán ser modificadas de forma unilateral
          por CACAOPAY, bastando para ello la notificación previa con 30
          (treinta) días naturales de anticipación a la fecha en que la
          modificación surta efectos. Las modificaciones serán válidas mediante
          la aceptación expresa o tácita del USUARIO, por lo que se entiende que
          una vez que dichos documentos hayan sido entregados o dados a conocer
          al USUARIO, y siempre y cuando no manifieste nada en contrario dentro
          de los 3 (Tres) días hábiles siguientes a su entrega o conocimiento,
          dichos documentos y su contenido serán aplicables y válidos para el
          USUARIO.
        </Styled.RegularText>
        <Styled.Subtitle>12. Disponibilidad de la Plataforma</Styled.Subtitle>
        <Styled.RegularText>
          El USUARIO tendrá acceso a la PLATAFORMA CACAOPAY en cualquier
          momento, es decir los 365 días naturales durante las 24 horas del día,
          para realizar operaciones en la Cuenta siempre que se cuente con los
          FPE suficientes. En caso contrario, deberá sujetarse a los horarios de
          las instituciones financieras de las cuentas bancarias mediante las
          cueles realizarán Transferencias o Transferencias de Fondos de Pago
          Electrónico a sus Cuentas. Las operaciones realizadas en la PLATAFORMA
          CACAOPAY distintas a la emisión ejecutadas con éxito dentro de los
          días y horas permitidos, según se pacta anteriormente, surtirán sus
          efectos el día en que se efectúen, en el entendido de que las
          operaciones que hayan quedado pendientes por no haberse ejecutado
          dentro de los días y horarios establecidos anteriormente, surtirán sus
          efectos al siguiente Día Hábil al que se realicen, siempre y cuando no
          sobreviniere algún impedimento para su ejecución. Respecto de las
          operaciones de emisión de FPE, CACAOPAY los emitirá en los tres
          segundos inmediatos posteriores a aquel en que CACAOPAY reciba los
          recursos respectivos, para lo cual CACAOPAY cuenta a través de éstos
          TGC y del Contrato con el consentimiento expreso del USUARIO para
          llevar a cabo la referida emisión de FPE. Dicha autorización consta al
          momento de realizar el registro y la aceptación del Contrato
          correspondiente. El USUARIO en este momento reconoce y acepta que
          CACAOPAY no será responsable si por caso fortuito, fuerza mayor, falta
          o interrupción de línea, fallas en la PLATAFORMA CACAOPAY o por
          cualquier otra causa ajena al control de CACAOPAY no se pudiere
          acceder a éste. No obstante, CACAOPAY llevará a cabo todas las
          diligencias necesarias a fin de lograr la continuidad en los servicios
          en la PLATAFORMA CACAOPAY. Cualquier notificación que deba hacerse al
          USUARIO respecto de fallas del sistema o procesos para la continuidad
          de los servicios serán hechas a través del correo electrónico
          registrado por el USUARIO. Paralelamente, a la información que ponga a
          disposición CACAOPAY respecto de fallas en la PLATAFORMA CACAOPAY, se
          hará del conocimiento de la Comisión Nacional para la Protección y
          Defensa de los USUARIOS de Servicios Financieros (CONDUSEF), de manera
          que pueda atender a los USUARIOS en los medios que considere
          pertinentes en lo que toca a consultas o reclamaciones.
        </Styled.RegularText>
        <Styled.Subtitle>13. Privacidad de la Información</Styled.Subtitle>
        <Styled.RegularText>
          CACAOPAY garantiza que toda la información personal se procesa y
          almacena en servidores o medios magnéticos que mantienen altos
          estándares de seguridad y protección tanto física como tecnológica,
          adicional a que garantiza el cumplimiento del artículo 73 de la Ley
          Fintech.
        </Styled.RegularText>
        <Styled.Subtitle>14. Documentos relacionados</Styled.Subtitle>
        <Styled.RegularText>
          El USUARIO reconoce que cualquier documento impreso o digital donde se
          establezcan obligaciones derivadas de la utilización de la PLATAFORMA
          CACAOPAY, es parte integrante de estos TCG y por lo tanto tendrá
          completa validez y hará prueba plena.
        </Styled.RegularText>
        <Styled.Subtitle>
          15. Riesgos de uso de la plataforma CACAOPAY
        </Styled.Subtitle>
        <Styled.RegularText>
          Todos los riesgos derivados de la utilización de la PLATAFORMA
          CACAOPAY, el uso o contratación de cualquiera de los servicios
          ofrecidos en o en relación con CACAOPAY, y / o el uso de cualquier
          contenido serán responsabilidad del USUARIO, por lo que CACAOPAY
          recomienda actuar con prudencia y sentido común al momento de realizar
          operaciones y uso de la PLATAFORMA CACAOPAY. En ningún caso, CACAOPAY
          o cualquiera de sus irectores, empleados, agentes o afiliados serán
          responsables por daños, directos, indirectos, derivados por el uso de
          estas condiciones. Adicional se informa al USUARIO que el Gobierno
          Federal y las Entidades de la Administración Pública Paraestatal no
          podrán responsabilizarse o garantizar los recursos de los USUARIOS que
          sean utilizados en las operaciones que celebren con CACAOPAY o frente
          a otros, ni asumir alguna responsabilidad por las obligaciones
          contraídas por CACAOPAY o por algún USUARIO frente a otro, en virtud
          de las operaciones que celebren.
        </Styled.RegularText>
        <Styled.Subtitle>16. Indemnización</Styled.Subtitle>
        <Styled.RegularText>
          El USUARIO indemnizará a CACAOPAY, sus filiales, empresas
          relacionadas, directivos, administradores, representantes, empleados,
          proveedores, vendedores y asesores por cualquier acción, demanda o
          reclamación derivadas de cualquier incumplimiento por parte del
          USUARIO a los presente TCG; incluyendo, sin limitación de alguna de
          las derivadas de: i) Cualquier aspecto relativo al uso de la
          PLATAFORMA CACAOPAY; ii) La información contenida o disponible en o a
          través de dicho sitio o de injurias, difamación o cualquier otra
          conducta violatoria del presente documento por parte del USUARIO en el
          uso de la PLATAFORMA CACAOPAY señalada; iii) La violación a las leyes
          aplicables o tratados internacionales relativos a los derechos de
          propiedad intelectual, contenidos o disponibles en, o a través de
          dicho sitio web.
        </Styled.RegularText>
        <Styled.Subtitle>17. Aclaraciones y comunicación</Styled.Subtitle>
        <Styled.RegularText>
          Cualquier aclaración o reclamación que los USUARIOS tuvieran
          relacionados con estos TCG o en su caso el Contrato especifico,
          deberán ser canalizadas para su atención directamente a CACAOPAY con
          la documentación que acredite su validez y de conformidad con la
          cláusula que se indique en dicho documento. El USUARIO que desee
          interponer una queja, aclaración o reclamación deberá ponerse en
          contacto con la Unidad Especializada de Atención a USUARIOS de
          CACAOPAY (UNE) en el teléfono 55 5005 9999, correo electrónico
          une@cacaopaycard.com Adicional CACAOPAY, pone a disposición los
          siguientes medios de comunicación con el USUARIO: Página Web
          www.cacaopaycard.com Twitter https://twitter.com/cacaopaycard Linkedin
          https://www.linkedin.com/company/cacaopaycard
        </Styled.RegularText>
        <Styled.Subtitle>18. Cargos no reconocidos</Styled.Subtitle>
        <Styled.RegularText>
          En caso de cargos no reconocidos, el USUARIO debe notificar a CACAOPAY
          un aviso por dicho concepto a través de correo electrónico a
          contacto@cacaopaycard.com, en un plazo máximo de 90 (noventa) días a
          que fue realizado el cargo, en cuyo caso CACAOPAY deberá abonar, a más
          tardar el segundo día hábil bancario posterior a aquel en que haya
          recibido el aviso respectivo, el monto equivalente al cargo no
          reconocido, siempre y cuando el citado aviso haya sido generado en el
          plazo mencionado en este apartado. Al respecto, se da a conocer al
          USUARIO que CACAOPAY no estará obligada a realizar el abono al que se
          refiere el párrafo anterior, cuando CACAOPAY compruebe que en la
          operación que haya ocasionado el cargo no reconocido por, se
          utilizaron, al momento de realizarla, dos de los elementos
          independientes aplicables para autenticar las operaciones como
          autorizadas por el USUARIO, es decir dos Contraseñas, o bien, se haya
          utilizado solo uno de dichos elementos al momento de realizar la
          operación y otro de dichos elementos al momento de entregar el bien o
          servicio adquirido en virtud de dicha operación. Al respecto, CACAOPAY
          no responderá respecto de los cargos no reconocidos en la Cuenta o en
          la Tarjeta CACAOPAY anteriores al aviso de robo o extravío del medio
          de disposición referido, mientras que el USUARIO no será responsable
          desde el momento en que realice el aviso. En todo caso, CACAOPAY
          tendrá el derecho de exigir el pago de los cargos realizados y
          autorizados previamente por el USUARIO. CACAOPAY deberá abonar, a más
          tardar el segundo día hábil bancario contado a partir del día en que
          el USUARIO haya presentado el aviso correspondiente a robo o extravío
          de la Tarjeta CACAOPAY, el monto equivalente a los cargos reclamados,
          siempre que se cumplan con los siguientes supuestos:
        </Styled.RegularText>
        <Styled.Subtitle>19. Vigencia y periodo de gracia</Styled.Subtitle>
        <Styled.RegularText>
          La vigencia de los presentes TCG será indefinida a partir de la fecha
          de aceptación, no obstante, el USUARIO podrá cerrar en cualquier
          momento su Cuenta, mediante la debida notificación. El USUARIO contará
          con un periodo de diez días hábiles posteriores a la firma del
          Contrato respectivo, para cancelarlo sin responsabilidad para el
          mismo, en cuyo caso CACAOPAY no podrá cobrar comisión alguna, debido a
          que el USUARIO no hayan utilizado u operado los productos contratados,
          bastando para ello la presentación de una solicitud de cancelación a
          través de la PLATAFORMA CACAOPAY. Dicha cancelación será procedente
          siempre y cuando el USUARIO no haya dispuesto de los servicios.
        </Styled.RegularText>
        <Styled.Subtitle>
          20. Terminación y vencimiento anticipado
        </Styled.Subtitle>
        <Styled.RegularText>
          a) Terminación anticipada: El USUARIO podrá solicitar en cualquier
          momento a CACAOPAY, durante la vigencia de los TCG, la terminación
          anticipada, y como consecuencia el cierre de la Cuenta, sin condición
          alguna, sin informar o justificar las razones, mediante solicitud
          dirigida y entregada a este último a través de los canales
          establecidos dentro de la PLATAFORMA CACAOPAY, o bien comunicándose al
          número telefónico 55 5005 9999, o al correo electrónico
          contacto@cacaopaycard.com. CACAOPAY le proporcionará por correo
          electrónico un acuse de recibo y un folio que indique el número
          progresivo de atención a su solicitud, indicando la fecha y hora de la
          solicitud. Esta información se dará a conocer al USUARIO al momento de
          la solicitud o a más tardar el Día Hábil siguiente a la solicitud. En
          atención a dichas solicitudes, CACAOPAY, deberá cerciorarse de la
          autenticidad y veracidad de la identidad del USUARIO. A la terminación
          anticipada, CACAOPAY ya no dará curso a las solicitudes de altas de
          aquellos USUARIOS, ni les proporcionará algún otro servicio.
        </Styled.RegularText>
        <Styled.Subtitle>21. Intransferabilidad</Styled.Subtitle>
        <Styled.RegularText>
          El USUARIO ni CACAOPAY podrán ceder o transferir los derechos y
          obligaciones del presente acuerdo de TCG.
        </Styled.RegularText>
        <Styled.Subtitle>22. Jurisdicción y ley aplicable</Styled.Subtitle>
        <Styled.RegularText>
          Estos TCG estarán regidos en todos sus puntos por las leyes vigentes
          en los Estados Unidos Mexicanos, en particular respecto de mensajes de
          datos, contratación y comercio electrónicos se regirá por lo dispuesto
          por la legislación federal respectiva. Cualquier controversia derivada
          del presente acuerdo, su existencia, validez, interpretación, alcance
          o cumplimiento, será sometida a las leyes aplicables y a los
          Tribunales competentes. Estos TCG estarán sujetos y serán
          interpretados de acuerdo con las leyes y ante los tribunales de la
          Ciudad de México.
        </Styled.RegularText>
        <Styled.Subtitle>
          23. Autorización para solicitar información crediticia
        </Styled.Subtitle>
        <Styled.RegularText>
          El USUARIO autoriza por este medio electrónico por una sola ocasión a
          CACAOPAY por conducto de sus funcionarios facultados que lleve a cabo
          investigaciones sobre su comportamiento crediticio en las sociedades
          de información crediticia que estime conveniente y en caso de así
          requerirlo. El USUARIO manifiesta estar consciente y aceptar
          expresamente que este medio electrónico y/o mensaje de datos quede
          bajo propiedad de ACAOPAY y/o cualquier Sociedad de Información
          Crediticia a quien solicite el servicio de información crediticia,
          para efectos de control y cumplimiento del artículo 28 de la Ley para
          regular a las Sociedades de Información Crediticia.
        </Styled.RegularText>
        <Styled.Subtitle>24. Domicilio</Styled.Subtitle>
        <Styled.RegularText>
          Se señala como domicilio físico de CACAOPAY: Boulevard Manuel Ávila
          Camacho 50, 5º piso, Lomas de Chapultepec, C.P. 11000, Miguel Hidalgo,
          Ciudad de México, número telefónico 55 5005 9999 y como contacto
          electrónico contacto@cacaopaycard.com.
        </Styled.RegularText>
        <Styled.Subtitle>
          25. Autorización con fines mercadotécnicos, publicitarios y con
          respecto al tratamiento de datos personales
        </Styled.Subtitle>
        <Styled.RegularText>
          Autorización para Fines Mercadotécnicos y Publicitarios. El USUARIO
          podrá autorizar CACAOPAY, para que la información contenida en los TGC
          sea utilizada con fines mercadotécnicos y publicitarios, así como para
          recibir en su domicilio publicidad de CACAOPAY. No obstante, lo
          anterior, el USUARIO podrá en cualquier momento revocar la
          autorización proporcionada a CACAOPAY en términos de su Aviso de
          Privacidad.
        </Styled.RegularText>
        <Styled.Subtitle>
          26. Prevención de lavado de dinero y financiamiento al terrorismo
        </Styled.Subtitle>
        <Styled.RegularText>
          EL USUARIO declara y garantiza que los fondos que serán utilizados
          para realizar las operaciones con CACAOPAY, en ejecución de los
          presentes TCG, no provienen ni provendrán de actividades relacionadas
          con el narcotráfico, terrorismo u otras actividades ilícitas. EL
          USUARIO reconoce que se obliga a proporcionar la información
          solicitada por CACAOPAY, para la debida identificación de EL USUARIO
          en cumplimiento con la normatividad nacional en materia de prevención
          de lavado de dinero y financiamiento al terrorismo.
        </Styled.RegularText>
        <Styled.Subtitle>27. Anticorrupción</Styled.Subtitle>
        <Styled.RegularText>
          Las Partes se comprometen a que, en la fecha de aceptación de los
          presentes TCG, ni ellas, ni sus directores, funcionarios o empleados
          habrán ofrecido, prometido, entregado, autorizado, solicitado o
          aceptado ninguna ventaja indebida, económica o de otro tipo (o
          insinuado que lo harán o podrían hacerlo en algún momento futuro)
          relacionada de algún modo con los presentes TCG y que habrán adoptado
          medidas razonables para evitar que lo hagan los subcontratistas,
          agentes o cualquier otro tercero que esté sujeto a su control o a su
          influencia determinante.
        </Styled.RegularText>
        <Styled.Subtitle>28. Seguridad</Styled.Subtitle>
        <Styled.RegularText>
          Lo que debes tomar en cuenta sobre los fraudes cibernéticos: Los
          fraudes cibernéticos son aquellas estafas que se emplean a través de
          las redes para realizar transacciones ilícitas. El delincuente usa
          herramientas tecnológicas sofisticadas para acceder a distancia a tu
          información de identidad cibernética. Muchas veces las personas que
          realizan este tipo de fraudes se aprovechan del desconocimiento o del
          poco cuidado que las personas llegan a tener al utilizar cualquier
          equipo cibernético, como pueden ser una table, un teléfono o laptop,
          convirtiéndose en un blanco fácil para los estafadores. Estas estafas
          pueden darse de muchas maneras y en cualquier momento por eso es
          recomendable conocer las vertientes más típicas que utilizan estos
          delincuentes, entre las más frecuentes se encuentran: 1. El correo
          basura Comúnmente conocido como SPAM, se trata de un mensaje enviado a
          varios destinatarios que usualmente no lo solicitaron, con fines
          aparentemente publicitarios o comerciales. La información contenida en
          dicho correo te invita a visitar una página o descargar algún archivo
          que generalmente es un virus que roba la información de tu
          dispositivo. 2. Smishing En esta modalidad de fraude, te envían
          mensajes SMS a tu teléfono móvil con la finalidad de que visites una
          página web fraudulenta, con la finalidad de obtener tu información
          bancaria, para realizar transacciones en tu nombre. 3. Phishing En
          este tipo de fraude se hacen pasar por una Institución Financiera,
          enviando un mensaje indicándote un error en tu cuenta bancaria, y al
          ingresar tus datos financieros, obtienen tu información confidencial
          como: números de tus tarjetas de crédito, claves, datos de cuentas
          bancarias, contraseñas, etc. Una variante a este fraude es el phishing
          telefónico (vishing), en donde los delincuentes simulan ser empleados
          de alguna Institución y te informan que tus cuentas están registrando
          cargos irregulares o te requieren alguna información confidencial. 4.
          Pharming En esta modalidad te redirigen a una página de internet falsa
          mediante ventanas emergentes, para robar tu información. Suelen
          mostrar leyendas similares en las cuales te indican que fuiste
          acreedor a un apremio por visitar su página. 5. Transferencias
          Electrónicas En este caso se hacen pasar por una empresa que realiza
          ofertas muy llamativas, por lo que al comprar te piden realizar una
          transferencia a cambio de dicho producto y al acudir dicha empresa te
          das cuenta que, nunca existió dicha oferta ni compra. 6. Malware En
          este tipo de fraude roban tus datos por medio de un Malware (código
          maligno/sotfware malicioso), el cual se inserta en tu teléfono móvil
          al ingresar a la aplicación móvil, el Malware muestra una venta falsa,
          en donde se solicita tus datos confidenciales como son los datos
          financieros los cuales son enviados a los estafadores para que estos
          puedan hacer un uso indebido de los mismos. 7. Descarga de Software Al
          descargar de una página poco confiable o de manera gratuita algún
          software este puede contener un virus que permite al estafador tomar
          tu información personal directamente de tu ordenador. Pero no solo las
          personas físicas el “Usuario común” se ve afectado por este tipo de
          fraudes también se ven afectados las Empresas, quienes se enfrentan al
          robo de patentes, secretos tecnológicos, phishing, en otros. ¿Cómo lo
          puedes evitarlo? a) Instala en tu computadora o dispositivo móvil un
          buen antivirus, utiliza un software tipo firewall o anti-spyware para
          proteger tus equipos de algún virus malicioso. b) No des "clic" o
          abras vínculos sospechosos. c) Si descargas aplicaciones y/o software
          realízalo por medio de las tiendas y desarrolladores oficiales d) No
          respondas mensajes de correo que te dicen haber ganado un premio,
          viaje o sorteo e) Verifica que el sitio en el que navegas cuente con
          el protocolo de seguridad "https://" y un candado cerrado en la barra
          de direcciones. f) Nunca entregues tus datos por correo electrónico.
          g) Las empresas y bancos nunca te van a solicitar tus datos
          financieros o números de tarjetas de crédito por teléfono o internet,
          cuando no seas tú quien inicie una operación. Si aún te queda duda del
          correo, llama o asiste a tu banco y verifica los hechos. h) Desconfía
          de las compras en línea cuando te pidan que hagas un depósito a
          cuentas bancarias distintas de la empresa. i) Cuida tus claves
          personales, número de identificación personal (NIP), cámbialas
          periódicamente. Recuerda que las personas que realizan este tipo de
          fraudes son hábiles y te engañan con tácticas alarmistas o solicitudes
          urgentes para preocuparte y evitar que pienses bien la situación, no
          entres en pánico y antes de realizar cualquier acción verifica la
          fuente de procedencia.
        </Styled.RegularText>
        <Styled.Subtitle>29. Consentimiento</Styled.Subtitle>
        <Styled.RegularText>
          En ningún caso el USUARIO podrá hacer uso de los servicios que presta
          CACAOPAY a menos que haya aceptado, o que se considere que ha aceptado
          (mediante su acceso a la PLATAFORMA el uso de los servicios). Por lo
          que acepta y reconoce que la aceptación de los presentes TCG tiene el
          mismo efecto jurídico que el poner su firma física en cualquier
          contrato o acuerdo de voluntades, constituyendo su voluntad y,
          consentimiento libre de cualquier vicio, aceptando todos los términos
          contenidos.
        </Styled.RegularText>
        <Styled.Subtitle>30. Condusef</Styled.Subtitle>
        <Styled.RegularText>
          En cualquier tiempo, el USUARIO podrá acudir directamente a la
          CONDUSEF para cualquier aclaración o consulta, o bien, contactarla a
          través de su Centro de Atención Telefónica (01 800 999 80 80 / 53 40
          09 99), su página de internet www.condusef.gob.mx, o en los correos
          electrónicos: y asesoria@condusef.gob.mx.{' '}
        </Styled.RegularText>
        <Styled.Subtitle>31. Divulgación de la información</Styled.Subtitle>
        <Styled.RegularText>
          CACAOPAY no incurrirán en responsabilidad alguna, cuando la
          Información Confidencial que se le haya proporcionado, sea conocida
          por cualquier tercera persona, por alguna de las siguientes causas: a)
          Cuando la Información Confidencial se haga del dominio público b)
          Cuando el titular de la Información Confidencial autorice por escrito
          que la Información Confidencial sin restricciones a terceras Personas,
          según lo establezca; c) En caso de que por disposición legal,
          administrativa o por orden judicial a entregar, total o parcialmente
          la Información Confidencial. d) Cuando obtenga la Información
          Confidencial de un tercero sin que medie incumplimiento. En todo caso,
          la revelación de cualquier tipo de información del USUARIO por parte
          de CACAOPAY a las Autoridades Financieras no implicará transgresión
          alguna a la obligación de confidencialidad legal, ni constituirá
          violación a las restricciones sobre revelación de información
          establecidas por cualquier vía contractual. Última actualización: 04
          de marzo de 2020.
        </Styled.RegularText>
        <Styled.SubmitButton onClick={() => this.return()}>
          Regresar
        </Styled.SubmitButton>
      </Styled.TermsContainer>
    );
  }
}

export default Terms;
