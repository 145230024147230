export const styles = (theme) => {
  const componentsStyles = {
    container: {
      paddingBottom: `${theme.spacing.unit * 2}px`,
      width: '100%',
      float: 'left',
      '&.mobile': {
        margin: 'auto',
        width: `calc(100% - ${theme.spacing.unit * 4}px)`,
        padding: `0 ${theme.spacing.unit * 2}px`,
        marginTop: `${theme.spacing.unit * 5}px`,
        marginBottom: `${theme.spacing.unit * 7}px`,
      },
    },
  };
  return {
    ...componentsStyles,
  };
};
