import styled from '@emotion/styled';
import { theme } from '@clipmx/clip-storybook/dist/theme/theme';
import Avatar from '@clipmx/clip-storybook/dist/components/Avatar';
import Done from '@clipmx/clip-storybook/dist/components/Icons/Done';
import Button from 'components/npm/clip-ui/Button';
import { Link } from 'react-router-dom';

export const ContentPage = styled.div({
    maxWidth: '49rem',

    [theme.breakpoints.down('xs')]: {
        maxWidth: '20.5rem',
        padding: '0 1rem 1rem 1rem',
    },
});

export const Container = styled.div({
    paddingTop: '25px',
});

export const StyledDone = styled(Done)({
    color: '#181A36',
})

export const CardContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '32px',
    paddingBottom: '40px',
    
    [theme.breakpoints.down('xs')]: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
    },
});

export const PageTitle = styled.div({
    fontFamily: 'AvertaStd',
    fontSize: '2.25rem',
    fontWeight: 'bold',
    lineHeight: '54px',
    color: '#181A36',
});

export const PageDescription = styled.div({
    fontFamily: 'AvertaStd',
    fontSize: '1rem', 
    color: '#181A36', 
    lineHeight: '1.5rem',
    marginBottom: '2rem', 
    marginTop: '1.5rem',
});

export const Icon = styled.span({
    display: 'flex', 
    float: 'left', 
    marginRight: '1rem',
});

export const FrameTitle = styled.span({
    display: 'flex',
    float: 'left',
    fontFamily: 'AvertaStd',
    fontSize: '1.25rem', 
    fontWeight: 600,
    lineHeight: '1.875rem',
    color: '#181A36', 
});

export const SectionDescription = styled.div({
    marginLeft: '2.5rem',
    display: 'flex',
    flexDirection: 'column',
})

export const FrameDescription = styled.div({
    fontFamily: 'AvertaStd',
    fontSize: '1rem', 
    color: '#5E5F73',
});

export const StepAvatar = styled.div({
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-start',
});

export const AvatarEmpty = styled(Avatar)({
    '&.small': {
        display: 'flex',
        float: 'left',
        width: '16px',
        height: '16px',
        backgroundColor: '#FC4C02',
        marginRight: '23px',
        fontFamily: 'AvertaStd',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '18px',
        textAlign: 'center',
    },
    '&.square': {
        width: 40, 
        height: 40,
        backgroundColor: '#F6F6F7',
        borderRadius: '8px',
    },
});

export const CellPhoneImage = styled.img({
    [theme.breakpoints.down('xs')]: {
       display: 'none',
    }
})

export const FrameButton = styled(Button)({
    fontFamily: 'AvertaStd',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    textTransform: 'none',
    width: '177px',
    height: '24px',
    borderRadius: '8px',
    padding: '12px 16px 12px 16px',
});

export const ImageButton = styled(Button)({
    backgroundColor: 'transparent',
    outline: 'none',
    borderColor: '#fff',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    [theme.breakpoints.down('xs')]: {
        paddingTop: '2rem',
    },
});

export const CustomizeTitle = styled.div({
    fontFamily: 'AvertaStd',
    fontSize: '1.25rem',
    lineHeight: '1.875rem',
    fontWeight: 600,
    color: '#001724',
    paddingTop: '3rem',
    paddingBottom: '1rem',

    [theme.breakpoints.down('xs')]: {
        paddingTop: '1rem',
        paddingBottom: '1.5rem',
    },
});

export const FooterTitle = styled.div({
    fontFamily: 'AvertaStd',
    fontWeight: 600,
    fontSize: '1rem', 
    lineHeight: '1.5rem',
    color: '#181A36',
    paddingTop: '1rem',
});

export const WrapImageLink = styled.div({
    width: '100%',
    textAlign: 'end',
    [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        padding: '35px 0 15px',
    },
});

export const FooterDescription = styled.div({
    fontFamily: 'AvertaStd',
    fontSize: '0.8rem',
    color: '#5E5F73',
    paddingTop: '0.5rem',
    paddingRight: '4rem',
    paddingBottom: '2rem',

    [theme.breakpoints.down('xs')]: {
        paddingRight: '0px',
    },
});

export const LinkMyStore = styled(Link)({
    fontFamily: 'AvertaStd',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    textTransform: 'none',
    width: '177px',
    height: '24px',
    borderRadius: '8px',
    padding: '12px 16px 12px 16px',
    color: '#fff',
    boxShadow: '0px 0px 2px 0px rgb(252 76 2 / 12%), 0px 4px 6px -3px rgb(0 0 0 / 40%)',
    backgroundColor: '#FC4C02',
    margin: '24px 0 41px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
