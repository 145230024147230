const styles = theme => {
  return {
    container: {
      height: '100vh',
      width: '100vw',
      background: 'white',
    },
    drawer: {
      zIndex: 3001,
    },
    paper: {
      borderRadius: '16px 16px 0 0',
      padding: `${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'center',
    },
    clear: {
      alignSelf: 'flex-end',
    },
  };
};
export default styles;
