import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';

import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import FormControl from '@clipmx/clip-storybook/dist/components/FormControl';

import { Forms } from '../../../../../constants/AppConstants';
import renderClipInput from '../../../Commons/BerlinInputs/Input';
import renderSelectField from '../../../Commons/BerlinInputs/Select';
import { processZipCode } from '../../../redux/actions';
import {
  required,
  isNumeric,
  haveLength,
} from '../../../../../helpers/validate';
import { isInvalidFormField } from '../../../../../helpers/FormUtils';
import { BERLIN_CARD_REQUEST_TABS } from '../../../../../constants/AppConstants';

import { styles } from './styles';
import text from './text';

const useStyles = makeStyles(styles);

export const NewAddress = (props) => {
  const {
    berlin,
    street,
    number,
    zipCode,
    colony,
    city,
    municipality,
    state,
    name,
    lastname,
    secondLastname,
    mobile,
  } = props;
  const classes = useStyles(props);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const updateTab = () => {
    const { setActiveTab } = props;
    setActiveTab(BERLIN_CARD_REQUEST_TABS.ADDRESS_INSTRUCTIONS);
  };

  const validateZipcode = (e) => {
    const { dispatch } = props;
    dispatch(processZipCode(e.target.value, Forms.CARD_REQUEST));
  };

  const enableNextButton = () => {
    let nextStepDisabled = false;

    if (
      isInvalidFormField(street) ||
      isInvalidFormField(number) ||
      isInvalidFormField(zipCode) ||
      isInvalidFormField(colony) ||
      isInvalidFormField(city) ||
      isInvalidFormField(municipality) ||
      isInvalidFormField(state) ||
      isInvalidFormField(name) ||
      isInvalidFormField(lastname) ||
      isInvalidFormField(secondLastname) ||
      isInvalidFormField(mobile)
    ) {
      nextStepDisabled = true;
    }

    setButtonDisabled(nextStepDisabled);
  };

  useEffect(() => {
    enableNextButton();
  });

  return (
    <div className={classes.container}>
      <form className={classes.formContent}>
        <ClipTypography
          variant={'h3'}
          component={'h1'}
          fontWeight={'regular'}
          align={'left'}
          classes={{ h3: classes.typographyTitle }}
        >
          {text.title}
        </ClipTypography>
        <FormControl className={classes.formControl} fullWidth>
          <Field
            name="street"
            component={renderClipInput}
            inputProps={{ maxLength: 30 }}
            type="text"
            label={text.street}
            validate={[required]}
            fullWidth
          />
          <Field
            name="number"
            component={renderClipInput}
            inputProps={{ maxLength: 10 }}
            type="text"
            label={text.number}
            validate={[required]}
            fullWidth
          />
          <Field
            name="interior"
            component={renderClipInput}
            inputProps={{ maxLength: 10 }}
            type="text"
            label={text.interior}
            fullWidth
          />
          <Field
            name="zip_code"
            component={renderClipInput}
            inputProps={{ maxLength: 5 }}
            onChange={validateZipcode}
            type="text"
            label={text.zipCode}
            validate={[required, isNumeric, haveLength(5)]}
            fullWidth
          />
          <Field
            component={renderSelectField}
            label={text.colony}
            name="colony"
            options={berlin.colonies}
            validate={[required]}
          />
          <Field
            name="city"
            component={renderClipInput}
            inputProps={{ maxLength: 30 }}
            type="text"
            label={text.city}
            validate={[required]}
            fullWidth
          />
          <Field
            name="municipality"
            component={renderClipInput}
            inputProps={{ maxLength: 30 }}
            type="text"
            label={text.municipality}
            validate={[required]}
            fullWidth
          />
          <Field
            name="state"
            component={renderClipInput}
            inputProps={{ maxLength: 30 }}
            type="text"
            label={text.state}
            validate={[required]}
            fullWidth
          />
        </FormControl>
        <ClipTypography
          variant={'h3'}
          component={'h1'}
          fontWeight={'regular'}
          align={'left'}
          classes={{ h3: classes.typographyTitle2 }}
        >
          {text.title2}
        </ClipTypography>
        <FormControl className={classes.formControl} fullWidth>
          <Field
            name="name"
            component={renderClipInput}
            inputProps={{ maxLength: 30 }}
            type="text"
            label={text.name}
            validate={[required]}
            fullWidth
          />
          <Field
            name="lastname"
            component={renderClipInput}
            inputProps={{ maxLength: 30 }}
            type="text"
            label={text.lastName}
            validate={[required]}
            fullWidth
          />
          <Field
            name="second_lastname"
            component={renderClipInput}
            inputProps={{ maxLength: 30 }}
            type="text"
            label={text.secondLastName}
            validate={[required]}
            fullWidth
          />
          <Field
            name="mobile"
            component={renderClipInput}
            inputProps={{ maxLength: 10 }}
            type="text"
            label={text.mobile}
            validate={[required, isNumeric, haveLength(10)]}
            fullWidth
          />
        </FormControl>
        <div className={classes.buttonContainer}>
          <ClipButton
            variant={'contained'}
            color={'primary'}
            onClick={updateTab}
            fullWidth
            disabled={buttonDisabled}
          >
            {text.submitButton}
          </ClipButton>
        </div>
      </form>
    </div>
  );
};

NewAddress.propTypes = {
  classes: PropTypes.object,
  setActiveTab: PropTypes.func,
  berlin: PropTypes.object,
};

NewAddress.defaultProps = {
  classes: {},
  setActiveTab: () => {},
  berlin: {},
};

const RequestCardForm = reduxForm({
  form: Forms.CARD_REQUEST,
  asyncChangeFields: ['zip_code'],
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(NewAddress);
const selector = formValueSelector(Forms.CARD_REQUEST);

const mapStateToProps = (state) => {
  return {
    berlin: state.berlin,
    street: selector(state, 'street'),
    number: selector(state, 'number'),
    zipCode: selector(state, 'zip_code'),
    colony: selector(state, 'colony'),
    city: selector(state, 'city'),
    municipality: selector(state, 'municipality'),
    state: selector(state, 'state'),
    name: selector(state, 'name'),
    lastname: selector(state, 'lastname'),
    secondLastname: selector(state, 'second_lastname'),
    mobile: selector(state, 'mobile'),
    initialValues: {
      name: state.user.first_name,
      lastname: state.user.last_name,
      second_lastname: state.user.second_last_name,
      mobile: state.user.mobile,
    },
  };
};

export default connect(mapStateToProps)(RequestCardForm);
