import React from 'react';
import PropTypes from 'prop-types';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import { Close as CloseIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { useHistory } from 'react-router-dom';
import errorImg from '../../../../assets/images/berlin/error.svg';
import { ROUTES2 } from '../../constants';
import styles from './styles';
import text from './text';

const useStyles = makeStyles(styles);

const ErrorScreen = ({ setStep }) => {
  let history = useHistory();
  const classes = useStyles();

  const routeOnClick = (url) => {
    history.push(url);
  };

  return (
    <div className={classes.container}>
      <IconButton
        classes={{ root: classes.iconButton }}
        onClick={() => routeOnClick(ROUTES2.AUTH)}
      >
        <CloseIcon classes={{ root: classes.closeIcon }} />
      </IconButton>
      <ClipTypography
        variant="h3"
        align="center"
        className={classes.title}
        fontWeight="bold"
      >
        {text.title1}
      </ClipTypography>
      <img className={classes.img} src={errorImg} alt="sign" />
      <ClipTypography align="center" className={classes.subtitle}>
        {text.subtitle}
        <u>{text.phone}</u>
      </ClipTypography>
      <div className={classes.btnContainer}>
        <ClipButton
          className={classes.btn}
          variant="contained"
          color="primary"
          onClick={() => setStep()}
        >
          {text.btnText}
        </ClipButton>
      </div>
    </div>
  );
};

ErrorScreen.propTypes = {
  setStep: PropTypes.func,
};

ErrorScreen.defaultProps = {
  setStep: () => {},
};
export default ErrorScreen;
