import axios from 'axios';

import config from 'config';
import { getRequestOptions } from 'helpers/fetchUtil';
import apiPath from 'helpers/apiPaths';
import AnalyticsEvents from 'helpers/AnalyticsEvents';
export default class CollaboratorService {
  static list() {
    const options = getRequestOptions();
    const url = new URL(apiPath.getCollaboratorList, config('apiUrl'));

    return axios.get(url, options).then((res) => {
      const types = Object.keys(res.data);
      const users = {};
      types.forEach((type) => {
        users[type] = this.mapCollaborators(res.data[type]);
      });

      return users;
    });
  }

  static get(id) {
    const options = getRequestOptions();
    const url = new URL(`${apiPath.getCollaborator}${id}`, config('apiUrl'));

    return axios
      .get(url, options)
      .then((res) => this.mapCollaborators([res.data])[0]);
  }

  static create(values) {
    const options = getRequestOptions();
    const url = new URL(`${apiPath.createCollaborator}`, config('apiUrl'));
    const payload = { user: this.prepareUser(values) };

    return axios.post(url, payload, options).then((res) => {
      AnalyticsEvents.insertCollaborator(values.role);
      return res.data;
    });
  }

  static update(values) {
    const options = getRequestOptions();
    const url = new URL(`${apiPath.editCollaborator}`, config('apiUrl'));
    const payload = { user: { id: values.id, ...this.prepareUser(values) } };

    return axios.post(url, payload, options).then((res) => {
      AnalyticsEvents.editCollaborator(values.role);

      return res.data;
    });
  }

  static delete({ id, role }) {
    const options = getRequestOptions();

    // TODO: update this once merchant service accepts id as URL param
    const url = new URL(apiPath.deleteCollaborator, config('apiUrl'));
    url.searchParams.set('id', id);

    return axios.post(url, {}, options).then((res) => {
      if (res.data.status.error) throw new Error(res.data.status);

      AnalyticsEvents.deleteCollaborator(role);

      return res.data;
    });
  }

  static prepareUser(values) {
    const acceptedKeys = [
      { camel: 'firstName', snake: 'first_name' },
      { camel: 'lastName', snake: 'last_name' },
      { camel: 'secondLastName', snake: 'second_last_name' },
      { camel: 'email', snake: 'email' },
      { camel: 'role', snake: 'role' },
      { camel: 'password', snake: 'password' },
      { camel: 'confirmPassword', snake: 'confirm_password' },
    ];

    return acceptedKeys.reduce((user, key) => {
      user[key.snake] = values[key.camel];
      return user;
    }, {});
  }

  static mapCollaborators = (items) =>
    items.map((collaborator) => ({
      id: collaborator.id,
      firstName: collaborator.first_name,
      lastName: collaborator.last_name,
      secondLastName: collaborator.second_last_name,
      email: collaborator.email,
      role: collaborator.role,
      createdAt: collaborator.created_at,
    }));
}
