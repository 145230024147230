import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import AddPhoneIcon from 'assets/images/icons/icon-add-phone.svg';
import CloseIcon from '@material-ui/icons/Close';

import TextField from 'helpers/FormUtils/TextField';
import { required, isNumeric, haveLength } from 'helpers/validate';
import { Forms } from 'constants/AppConstants';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ClevertapComponent from '../../../../../helpers/Clevertap/ClevertapComponent';
import { lenderPayload } from '../../Clevertap';
import { EVENTS } from '../../../../../helpers/Clevertap';
import { styles } from '../styles';
import text from '../text';

export const RenderExtraPhonesV3 = (props) => {
  const {
    fields,
    classes,
    classesV4,
    preapprovalSelected,
    showCashAdvance,
    loansFormVersion,
  } = props;

  const endAdornment = (index) => (
    <Button
      className={classes.buttonV2Dismiss}
      onClick={() => fields.remove(index)}
    >
      <CloseIcon />
    </Button>
  );

  return (
    <Fragment>
      {fields.map((extraPhone, index) => (
        <div className={classes.extraFieldContainer}>
          <Field
            name={extraPhone}
            key={index}
            component={TextField}
            inputProps={{ maxLength: 10 }}
            type="text"
            label={text.phoneLabel}
            validate={[required, isNumeric, haveLength(10)]}
            endAdornment={endAdornment(index)}
          />
        </div>
      ))}
      {fields.length < 4 && (
        <ClevertapComponent
          renderComponent={<Button />}
          className={clsx(
            classes.addButtonV1Styles,
            classes.addButtonV3Styles,
            { [classes.addColorV4Styles]: classesV4 },
          )}
          onClick={() => fields.push()}
          events={[
            {
              callbackName: 'onClick',
              event: EVENTS.LOANS.TELEPHONE_COMPLETED,
              payload: lenderPayload(preapprovalSelected, {
                showCashAdvance,
              }),
            },
          ]}
        >
          <img
            src={AddPhoneIcon}
            alt={text.addPhone}
            className={clsx({ [classes.addImageV4Styles]: classesV4 })}
          />
          {text.addPhoneV3}
        </ClevertapComponent>
      )}
    </Fragment>
  );
};

const selector = formValueSelector(Forms.MR_PRESTA);
const mapStateToProps = (state) => {
  return {
    extraTelephones: selector(state, 'extraTelephones'),
    preapprovalSelected: state.loans.request?.preapproval,
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
  };
};

RenderExtraPhonesV3.propTypes = {
  classes: PropTypes.object.isRequired,
  extraTelephones: PropTypes.array,
  fields: PropTypes.object,
};

export default compose(
  connect(mapStateToProps, null),
  withStyles(styles),
)(RenderExtraPhonesV3);
