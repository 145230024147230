import React from 'react';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ClipEmptyState from '@clipmx/clip-storybook/dist/components/Illustrations/Emptystate';
import text from './text';
import { styles } from './styles';
import { ClipTypography, ClipButton } from '@clipmx/clip-storybook';

const useStyles = makeStyles(styles);

const Error = (props) => {
  const { action } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <ClipEmptyState />
      <ClipTypography
        align={'center'}
        variant={'subtitle1'}
        fontWeight={'bold'}
      >
        {text.lostConection}
      </ClipTypography>
      <ClipTypography
        variant={'body1'}
        className={classes.conection}
        align={'center'}
      >
        {text.checkConection}
      </ClipTypography>
      <ClipButton
        fullWidth
        color="primary"
        variant="contained"
        onClick={action}
      >
        {text.tryAgain}
      </ClipButton>
    </div>
  );
};

export default Error;
