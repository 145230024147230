export default {
  initialAlertTitle: 'Antes de comenzar',
  initialAlertContent:
    'Ten a la mano los siguientes documentos (png, jpg o pdf) del titular de la cuenta Clip:',
  initialAlertIdentityDocument: 'Credencial INE o Pasarpote',
  initialAlertDocument: 'RFC y CURP',
  initialAlertAddress:
    'Comprobante de domicilio (máximo con 3 meses de antigüedad)',
  titlePersonalInformation: 'Datos personales',
  completeInformation: 'Por favor, completa tu información',
  fillFields:
    'Necesitamos algunos datos sobre ti, para saber quién solicita Credi Clip.',
  titleCreditHistory: 'Historial de crédito',
  answerQuestions: 'Por favor, contesta las siguientes preguntas',
  adviceCreditHistory: `Haremos una consulta a tu historial crediticio para que puedas disfrutar de Credi Clip.
  Por favor contesta las siguientes preguntas:`,
  titleAddress: 'Domicilio',
  titleAddressBusiness: 'Domicilio de tu negocio',
  titleDocumentation: 'Documentación',
  subtitleDocumentation: 'Datos de identificación',
  subtitleMRPLabelI: 'Ingresa tu clave de elector INE, tu RFC y CURP:',
  subtitleMRPLabelP: 'Ingresa número de pasaporte, tu RFC y CURP:',
  subtitleMOTLabel: 'Ingresa tu clave de elector INE, tu RFC y CURP:',
  subtitleAPHLabel: 'Ingresa tu RFC:',
  confidentialNotice: `Ingresa la siguiente información sobre el domicilio del propietario de tu cuenta Clip,
  para saber más sobre tu negocio.`,
  previousLoan: (name) => `¡Hola de nuevo, ${name}!`,
  previousLoanContent: `Para solicitar tu nuevo Credi Clip confirma que los datos de tu aplicación sean
  correctos y sigan vigentes.`,
  prefilledProofAlertTitle: 'Actualiza tu comprobante',
  prefilledProofAlertContent: `Es necesario que actualices tu comprobante de domicilio (formato pdf, jpg o png)
  a uno que tenga menos de 3 meses de antigüedad.`,
  almostReady:
    '¡Buen trabajo! Estás a punto de terminar tu solicitud de Credi Clip.',
  titleAttachDocuments: 'Documentos',
  subtitleAttachDocuments: `Necesitamos comprobar tu identidad para verificar que realmente eres tú quien
  solicita Credi Clip.`,
  footerAttachDocuments:
    'Tus documentos no serán guardados hasta que envíes tu solicitud',
  uploadDocuments:
    'Necesitamos comprobar tu identidad para verificar que realmente eres tú quien solicita Credi Clip.',
  kindOfDocuments:
    'El formato de tus documentos debe ser pdf, jpg, jpeg, png, doc o docx',
  dragDropDocuments: 'Arrástralos directemente aquí:',
  termsAndConditions: 'He leído, entiendo y acepto los ',
  termsAndConditionsLink: 'Términos y Condiciones',
  termsAndConditionsAnd: ' y el ',
  termsAndConditionsPrivacy: 'Aviso de Privacidad',
  endTermsAndConditions: ' del programa Adelanto de Ventas Clip.',
  continue: 'Continuar',
  finish: 'Enviar solicitud',
  titleErrorDialog: 'Información sin guardar.',
  messageErrorDialog:
    'No fue posible guardar su información, por favor cargue nuevamente los archivos y vuelva a intentarlo.',
  close: 'Cerrar',
  clabeTitle: 'Cuenta CLABE registrada en Clip',
  clabeAdvice:
    'Confirma que los datos sean correctos, pues en esta cuenta depositaremos tu Credi Clip.',
  clabeSubadviceP1: 'Si los datos no coinciden ponte en contacto con ',
  clabeSubadviceP2: 'Customer Hapiness.',
  clabeSubadviceLink: 'mailto:help@clip.mx',
  identityDocumentType: 'Tipo de identificación',
  identityDocumentMOT: 'Credencial INE',
  identityAdvice:
    'Sube una copia de tu documento (en formato pdf, jpg o png) para verificar tu identidad:',
  subtitleIdentityDocument:
    'Sube una copia de tu documento (en formato pdf, jpg o png) para verificar tu identidad:',
  subtitleIdentityDocumentMOT: 'Sube tu credencial por ambos lados:',
  addressProofDocumentType: 'Comprobante de domicilio',
  subtitleAddressProofDocumentType: `Sube una copia de tu documento (en formato pdf, jpg o png),
  para comprobar la dirección del  domicilio del propietario de tu cuenta Clip:`,
  addressProofDocumentNotice: 'Máximo con 3 meses de antigüedad',
  addressAdvice:
    'Tus documentos no serán guardados hasta que envíes tu solicitud',
  ineRadioLabel: 'INE',
  passportRadioLabel: 'Pasaporte',
  confirmAddress: 'Confirmo que mi dirección es correcta',
  confirmAddressTitle: 'Confirma tu dirección',
  confirmAddressDesc: 'Para continuar activa la casilla de verificación',
  offerChanged: 'Se cambió la oferta correctamente',
};
