/**
 * PRODUCT_SUBPRODUCT_VERTICAL_OBJECT_ACTION
 * PRODUCT: CX
 * SUBPRODUCT: ME-DASHBOARD
 * VERTICAL: DASHBOARD
 * OBJECT: MENU
 * ACTION: TXN-CLICKED
 * RESULT => "CX_ME-DASHBOARD_DASHBOARD_MENU_TXN-CLICKED"
 */
export const EVENTS = {
  MENU: {
    DASHBOARD: 'CX_ME-DASHBOARD_DASHBOARD_MENU_PANEL-CLICKED',
    TRANSACTIONS: 'CX_ME-DASHBOARD_DASHBOARD_MENU_TXN-CLICKED',
    PAYMENT_REQUEST: 'CX_ME-DASHBOARD_DASHBOARD_MENU_XPAY-CLICKED',
    DAILY_DEPOSIT_REPORTS: 'CX_ME-DASHBOARD_DASHBOARD_MENU_DEPOSITS-CLICKED',
    BUM: 'CX_ME-DASHBOARD_DASHBOARD_MENU_BUSSINES-UNITSM-CLICKED',
    MERCHANT_USERS: 'CX_ME-DASHBOARD_DASHBOARD_MENU_COLLABORATORS-CLICKED',
    ME: 'CX_ME-DASHBOARD_DASHBOARD_MENU_ACCOUNT-INFO-CLICKED',
    MANAGE_CATALOG: 'CX_ME-DASHBOARD_DASHBOARD_MENU_CATALOG-CLICKED',
    MARKETING_TOOLS: 'CX_ME-DASHBOARD_DASHBOARD_MENU_BUSSINES-TOOLS-CLICKED',
    MANAGE_INVOICES: 'CX_ME-DASHBOARD_DASHBOARD_MENU_INVOICES-CLICKED',
    LOANDS_OPEN_DATA_NOT_ELEGIBE:
      'CX_ME-DASHBOARD_DASHBOARD_MENU_LOANDS-CLICKED',
    ONLINE_STORE: 'MYSTORE_CLIP-ME-DASH_SETTINGS_LINK-MY-STORE-SELECTED',
  },
  LOANS: {
    NEW_OFFERS_VIEW: 'FIN-SERV_LOANS_OFFERS_PAGE_VIEWED',
    NEW_OFFERS_VIEW_AVAILABLES: 'FIN-SERV_LOANS_OFFERS_PAGE_AVAILABLE_VIEWED',
    PRESTACLIP_VIEW: 'FIN-SERV_LOANS_MD_LOAN_ACTIVE_MAIN_VIEWED',
    REVIEW_VIEW: 'FIN-SERV_LOANS_REVIEW_PAGE_VIEWED',
    REFILL_REVIEW_VIEW: 'FIN-SERV_LOANS_REFILL_REVIEW_PAGE_VIEWED',
    TC_VIEW: 'FIN-SERV_LOANS_T&C_VIEWED',
    FAQS_VIEW: 'FIN-SERV_LOANS_FAQS_VIEWED',
    CONGRATS_VIEW: 'FIN-SERV_LOANS_CONGRATS_PAGE_VIEWED',
    IDENTITY_VIEW: 'FIN-SERV_LOANS_IDENTITY_PAGE_VIEWED',
    CREDIT_HISTORY_VIEW:
      'FIN-SERV_LOANS_IDENTITY_PAGE_MRP_CREDIT_HISTORY_VIEWED',
    ADRESS_VIEW: 'FIN-SERV_LOANS_ADRESS_PAGE_VIEWED',
    DEPOSIT_VIEW: 'FIN-SERV_LOANS_DEPOSIT_PAGE_VIEWED',
    REFILL_VIEW: 'FIN-SERV_LOANS_REFILL_CONFIRMATION_PAGE_VIEWED',
    PHONECUSTOMER: 'FIN-SERV_LOANS_OFFERS_PAGE_PHONECUSTOMER_LAUNCHED',
    MAILCUSTOMER: 'FIN-SERV_LOANS_OFFERS_PAGE_MAILCUSTOMER_LAUNCHED',
    FRONT_INE_UPLOADED: 'FIN-SERV_LOANS_IDENTITY_PAGE_INE_FRONT_UPLOADED',
    BACK_INE_UPLOADED: 'FIN-SERV_LOANS_IDENTITY_PAGE_INE_BACK_UPLOADED',
    PASSPORT_UPLOADED: 'FIN-SERV_LOANS_IDENTITY_PAGE_PASAPORTE_UPLOADED',
    PROOF_ADDRESS_UPLOADED: 'FIN-SERV_LOANS_ADRESS_PAGE_DOCUMENT_UPLOADED',
    INE_SELECTED: 'FIN-SERV_LOANS_IDENTITY_PAGE_INE_SELECTED',
    PASSPORT_SELECTED: 'FIN-SERV_LOANS_IDENTITY_PAGE_PASAPORTE_SELECTED',
    MARITAL_STATUS_SELECTED:
      'FIN-SERV_LOANS_IDENTITY_PAGE_MRP_ESTADO_CIVIL_SELECTED',
    RESIDENCE_SELECTED:
      'FIN-SERV_LOANS_ADRESS_PAGE_MRP_RESIDENCE_TIME_SELECTED',
    RFC_CHANGED: 'FIN-SERV_LOANS_DEPOSIT_PAGE_RFC_COMPLETED',
    TC_SELECTED: 'FIN-SERV_LOANS_DEPOSIT_PAGE_T&C_SELECTED',
    PRIVACY_SELECTED:
      'FIN-SERV_LOANS_DEPOSIT_PAGE_AVISO_DE_PRIVACIDAD_SELECTED',
    CREDIT_HISTORY_SELECTED:
      'FIN-SERV_LOANS_IDENTITY_PAGE_MRP_CREDIT_HISTORY_CC_SELECTED',
    CREDIT_HISTORY_COMPLETED:
      'FIN-SERV_LOANS_IDENTITY_PAGE_MRP_CREDIT_HISTORY_CC_COMPLETED',
    CREDIT_HISTORY_MC_SELECTED:
      'FIN-SERV_LOANS_IDENTITY_PAGE_MRP_CREDIT_HISTORY_MC_SELECTED',
    CREDIT_HISTORY_AC_SELECTED:
      'FIN-SERV_LOANS_IDENTITY_PAGE_MRP_CREDIT_HISTORY_AC_SELECTED',
    ACTIVE_LOAN_DETAIL: 'FIN-SERV_LOANS_MD_LOAN_ACTIVE_LOAN_DETAIL_VIEWED',
    ENDED_LOAN_DETAIL: 'FIN-SERV_LOANS_MD_LOAN_ENDED_LOAN_DETAIL_VIEWED',
    ACTIVE_COLLECTIONS_DETAIL:
      'FIN-SERV_LOANS_MD_LOAN_ACTIVE_COLLECTIONS_DETAIL_VIEWED',
    ENDED_COLLECTIONS_DETAIL:
      'FIN-SERV_LOANS_MD_LOAN_ENDED_COLLECTIONS_DETAIL_VIEWED',
    LOAN_ACTIVE_BALANCE: 'FIN-SERV_LOANS_MD_LOAN_ACTIVE_DUE_BALANCE_VIEWED',
    AMOUNT_MODIFIED: (page) => `FIN-SERV_LOANS_${page}_PAGE_AMOUNT_MODIFIED`,
    HIGHER_OFFER_SELECTED: 'FIN-SERV_LOANS_OFFERS_PAGE_HIGHER_OFFER_SELECTED',
    MEDIUM_OFFER_SELECTED: 'FIN-SERV_LOANS_OFFERS_PAGE_MEDIUM_OFFER_SELECTED',
    LOWER_OFFER_SELECTED: 'FIN-SERV_LOANS_OFFERS_PAGE_LOWER_OFFER_SELECTED',
    OFFER_SELECTED: 'FIN-SERV_LOANS_OFFERS_PAGE_OFFER_SELECTED',
    OFFERS_PAGE_SCROLLED_RIGHT: 'FIN-SERV_LOANS_OFFERS_PAGE_SCROLLED_RIGHT',
    TELEPHONE_COMPLETED: 'FIN-SERV_LOANS_IDENTITY_PAGE_TELEPHONE_COMPLETED',
    CONTACT_INFORMATION_SELECTED:
      'FIN-SERV_LOANS_IDENTITY_PAGE_TELEPHONE_CONTACT_INFORMATION_SELECTED',
    TERMS_UNDERSTOOD_SELECTED:
      'FIN-SERV_LOANS_OFFERS_PAGE_TERMS_UNDERSTOOD_SELECTED',
    VALIDATION_PAGE_VIEWED:'FIN-SERV_LOANS_IDENTITY_PAGE_TELEPHONE_CODE_VALIDATION_PAGE_VIEWED',
    VALIDATION_CODE_COMPLETED:'FIN-SERV_LOANS_IDENTITY_PAGE_TELEPHONE_VALIDATION_CODE_COMPLETED',
    CUSTOM_OFFER_SELECTED: 'FIN-SERV_LOANS_CUSTOM_OFFER_SELECTED',
    ADVANCED_PAYMENT_SELECTED: 'FIN-SERV_LOANS_MD_LOAN_ACTIVE_LOAN_ADVANCED_PAYMENT_SELECTED',
    ADVANCED_PAYMENT_VIEWED: 'FIN-SERV_LOANS_MD_LOAN_ACTIVE_LOAN_ADVANCED_PAYMENT_VIEWED',
  },
  ONBOARDINGV2: {
    SIGNUP: {
      EVENT: 'CX_ME-DASHBOARD_DASHBOARD_ONBOARDING_SIGN-UP_VIEWED',
      ACTIONS: {
        WRITE_EMAIL: 'WRITE_EMAIL',
        WRITE_PASSWORD: 'WRITE_PASSWORD',
        WRITE_PHONE: 'WRITE_PHONE',
        CONTINUE_CLICKED: 'CONTINUE_CLICKED',
      },
    },
    PERSONAL_INFO: {
      EVENT: 'CX_ME-DASHBOARD_DASHBOARD_ONBOARDING_PERSONAL-INFO_VIEWED',
      ACTIONS: {
        WRITE_NAME: 'WRITE_NAME',
        WRITE_LAST_NAME: 'WRITE_LAST_NAME',
        WRITE_MOTHER_LAST_NAME: 'WRITE_MOTHER_LAST_NAME',
        WRITE_CURP: 'WRITE_CURP',
        CONTINUE_CLICKED: 'CONTINUE_CLICKED',
      },
    },
    EMAIL_VALIDATION: {
      EVENT: 'CX_ME-DASHBOARD_DASHBOARD_ONBOARDING_MAIL-VALIDATION_VIEWED',
      ACTIONS: {
        RESEND_EMAIL: 'RESEND_EMAIL',
      },
    },

    PHONE_VALIDATION: {
      EVENT: 'CX_ME-DASHBOARD_DASHBOARD_ONBOARDING_SMS-VALIDATION_VIEWED',
      ACTIONS: {
        WRITE_CODE: 'WRITE_CODE',
        VALIDATION_CLICKED: 'VALIDATION_CLICKED',
        SKIP_CLICKED: 'SKIP_CLICKED',
        RESEND_SMS: 'RESEND_SMS',
      },
    },
    BUSINESS_INFO: {
      EVENT: 'CX_ME-DASHBOARD_DASHBOARD_ONBOARDING_BUSINESS-INFO_VIEWED',
      ACTIONS: {
        WRITE_PERSON_TYPE: 'WRITE_PERSON_TYPE',
        WRITE_BUSSINESS_NAME: 'WRITE_BUSSINESS_NAME',
        WRITE_GIRO: 'WRITE_GIRO',
        WRITE_PRINCIPAL_ACTIVITIE: 'WRITE_PRINCIPAL_ACTIVITIE',
        WRITE_STREET: 'WRITE_STREET',
        WRITE_EXTERNAL_NUMBER: 'WRITE_EXTERNAL_NUMBER',
        WRITE_INTERNAL_NUMBER: 'WRITE_INTERNAL_NUMBER',
        WRITE_ZIPCODE: 'WRITE_ZIPCODE',
        CONTINUE_CLICKED: 'CONTINUE_CLICKED',
      },
    },

    WELCOME: {
      EVENT: 'CX_ME-DASHBOARD_DASHBOARD_ONBOARDING_WELCOME_VIEWED',
      ACTIONS: {
        ACTIVATE_CLICKED: 'ACTIVATE_CLICKED',
      },
    },
  },
  BERLIN: {
    CARD: {
      EVENT: 'FIN-SERV_BERLIN_CARD',
      ACTIONS: {
        REQUEST_CLICKED: 'REQUEST_CLICKED',
        REQUEST_VIEWED: 'REQUEST_VIEWED',
        ACTIVATE_CLICKED: 'ACTIVATE_CLICKED',
        DELIVERY_STATUS_CLICKED: 'DELIVERY-STATUS_CLICKED',
        ACTIVATE_INPUT_CONTINUED: 'ACTIVATE-INPUT_CONTINUED',
        REQUEST_PREVIOUS_ADDRESS_CLICKED: 'REQUEST_PREVIOUS_ADDRESS_CLICKED',
        REQUEST_NEW_ADDRESS_CLICKED: 'REQUEST_NEW_ADDRESS_CLICKED',
        REQUEST_REQUESTED: 'REQUEST_REQUESTED',
        DELIVERY_STATUS_VIEWED: 'DELIVERY_STATUS_VIEWE',
        VIEW_NIP_CLICKED: 'VIEW-NIP_CLICKED',
        VIEW_NIP_VIEWED: 'VIEW-NIP_VIEWED',
        VIEW_NIP_CHANGE_CLICKED: 'VIEW-NIP_CHANGE_CLICKED',
      },
    },
    ACCOUNT_UPGRADE: {
      EVENT: 'FIN-SERV_BERLIN_ACCOUNT_UPGRADE',
      ACTIONS: {
        ACCOUNT_CREATED_UPGRADE_CLICKED: 'ACCOUNT_CREATED_UPGRADE_CLICKED',
        CLICKED: 'CLICKED',
        QUESTION_1OF3YES_CLICKED: 'QUESTION-1OF3YES_CLICKED',
        QUESTION_2OF3YES_CLICKED: 'QUESTION-2OF3YES_CLICKED',
        QUESTION_3OF3YES_CLICKED: 'QUESTION-3OF3YES_CLICKED',
        REQUESTED: 'REQUESTED',
        QUESTIONS_INFO_INTRODUCED: 'QUESTIONS-INFO_INTRODUCED',
        QUESTION6_VIEWED: 'QUESTION6-VIEWED',
        INE_ID_HELP_CLICKED: 'INE-ID_HELP_CLICKED',
        INE_ID_CONTINUED: 'INE-ID_CONTINUED',
        SIGNATURE_REQUEST_CLICKED: 'SIGNATURE-REQUEST_CLICKED',
      },
    },
    ONBOARDING: {
      EVENT: 'FIN-SERV_BERLIN_ONBOARDING',
      ACTIONS: {
        ID_FRONT_CHECK_CONTINUED: 'ID_FRONT_CHECK_CONTINUED',
        ID_BACK_VIEWED: 'ID_BACK_VIEWED',
        ID_FRONT_CHECK_RETRY: 'ID_FRONT_CHECK_RETRY',
        ID_BACK_CHECK_CONTINUED: 'ID_BACK_CHECK_CONTINUED',
        CONTRACT_VIEWED: 'CONTRACT_VIEWED',
        ID_BACK_CHECK_RETRY: 'ID_BACK_CHECK_RETRY',
        SELECTID_VIEWED: 'SELECTID_VIEWED',
        ID_FRONT_VIEWED: 'ID_FRONT_VIEWED',
        ID_BACK_CHECK_VIEWED: 'ID_BACK_CHECK_VIEWED',
        ID_FRONT_CHECK_VIEWED: 'ID_FRONT_CHECK_VIEWED',
        CONTRACT_CREATE_ACCOUNT_CLICKED: 'CONTRACT_CREATE_ACCOUNT_CLICKED',
        ADDRESS_SUBMITED: 'ADDRESS_SUBMITED',
        ADDRESS_VIEWED: 'ADDRESS_VIEWED',
        INTRO_VIEWED: 'INTRO_VIEWED',
        INTRO_STARTED: 'INTRO_STARTED',
        CURP_REFUSED: 'CURP_REFUSED',
        SUBMIT_CURP_HELP_CLICKED: 'SUBMIT_CURP_HELP_CLICKED',
        SUBMIT_CURP_VIEWED: 'SUBMIT_CURP_VIEWED',
        CURP_CONFIRM_CONTINUED: 'CURP_CONFIRM_CONTINUED',
        CURP_REVIEWED: 'CURP_REVIEWED',
        TYC_VIEWED: 'TYC_VIEWED',
        TYC_ACCEPTED: 'TYC_ACCEPTED',
        TYC_NOT_ACCEPTED: 'TYC_NOT_ACCEPTED',
        TYC_LINK_CLICKED: 'TYC_LINK_CLICKED',
        ASK_CURP_DATA_CONTINUED: 'ASK_CURP_DATA_CONTINUED',
        ASK_CURP_DATA_VIEWED: 'ASK_CURP_DATA_VIEWED',
        ASK_CURP_DATA_VALIDATING_VIEWED: 'ASK_CURP_DATA_VALIDATING_VIEWED',
        ACCOUNT_CREATED: 'ACCOUNT_CREATED',
      },
    },
    BALANCE: {
      EVENT: 'FIN-SERV-BERLIN_BALANCE',
      ACTIONS: {
        ENVIAR_DINERO_FINISHED: 'ENVIAR-DINERO_FINISHED',
        ENVIAR_DINERO_CONTINUED: 'ENVIAR-DINERO_CONTINUED',
        ENVIAR_DINERO_CLICKED: 'ENVIAR-DINERO_CLICKED',
      },
    },
    MY_ACCOUNT: {
      EVENT: 'FIN-SERV-BERLIN_MY-ACCOUNT',
      ACTIONS: {
        CHANGE_CDA_CLICKED: 'CHANGE-CDA_CLICKED',
        CHANGE_CDA_CHANGED: 'CHANGE-CDA_CHANGED',
      },
    },
    SWAP_MIGRATION: {
      EVENT: 'FIN-SERV-BERLIN_SWAP_MIGRATION',
      ACTIONS: {
        CLIP_DASHBOARD: 'ClipDashboard',
        CLIP_LOG_IN: 'ClipLogIn',
        CLIP_EXPIRED_CACAO_ACCOUNT: 'ClipExpiredCacaoAccount',
        CLIP_SWAP_REGULATORY_INFO: 'ClipSwapRegulatoryInfo',
        CLIP_CLIP_CARD_WAITING_LIST: 'ClipClipCardWaitingList',
      },
    },
  },
  PASSWORD_POLICY: {
    POP_UP_CHANGE_PASSWORD: 'CX_ME-DASHBOARD_POP_UP_CHANGE_PASSWORD-CLICKED',
    POP_UP_DISMISSED: 'CX_ME-DASHBOARD_POP_UP_DISMISSED-CLICKED',
    USER_HAS_CHANGED_PASSWORD_SUCCESSFULLY:
      'CX_ME-DASHBOARD_DASHBOARD_MENU_ACCOUNT-INFO-USER-UPDATE-PASSWORD',
  },
};
