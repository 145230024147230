export const CREATED = 'CREATED';
export const SEND_PAYMENT_REQUEST_FORM = 'SEND_PAYMENT_REQUEST_FORM';
export const CANCEL_PAYMENT_REQUEST = 'CANCEL_PAYMENT_REQUEST';
export const END_PAYMENT_REQUEST_FORM = 'END_PAYMENT_REQUEST_FORM';
export const GET_PAYMENT_REQUEST_LIST = 'GET_PAYMENT_REQUEST_LIST';
export const GET_PAYMENT_REQUEST_DETAIL = 'GET_PAYMENT_REQUEST_DETAIL';
export const CANCEL_SENT_PAYMENT_REQUEST = 'CANCEL_SENT_PAYMENT_REQUEST';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const FILTER_PAYMENT_LIST = 'FILTER_PAYMENT_LIST';
export const MERCHANT_LIMIT = 'MERCHANT_LIMIT';
export const MULTILINK_FLAG = 'MULTILINK_FLAG';
export const KYC_NOTICE_FLAG = 'KYC_NOTICE_FLAG';
export const PUBLIC_PROFILE_INFO = 'PUBLIC_PROFILE_INFO';
export const ENABLE_PUBLIC_PROFILE = 'ENABLE_PUBLIC_PROFILE';
export const UPDATE_PRIVACY_INFO = 'UPDATE_PRIVACY_INFO';
export const UPDATE_ALIAS = 'UPDATE_ALIAS';
