import {
  CLEAR_REFERRAL_REGISTRATION_CODE,
  CLEAR_REFERRAL_STATE,
  SET_REFERRAL_REGISTRATION_CODE,
  REFERRAL_CODE_ATTEMPT,
  SEND_REFERRAL_CODE_EMAIL,
  REGISTERED_WITH_REFERRAL_CODE,
  SET_REFERRAL_AGGREGATES,
  SET_REFERRER_BENEFIT,
} from '../actions/Types';

const initialState = {
  referralCode: null,
  registrationCode: null,
  attempts: 0,
  valid: false,
  codeFromUrl: false,
  sendEmail: null,
  registeredWithCode: false,
  rewardType: null,
  referralAggregates: {
    benefitAmount: null,
    benefitAmountRemaining: null,
    numReferredMerchantsRedeemed: null,
    numReferredMerchantsPending: null,
    numReferredMerchants: null,
    totalBenefitsEarned: null,
    totalPendingBenefits: null,
    totalBenefitsRedeemed: null,
    merchantsReferred: [],
  },
};

const actionHandlers = new Map([
  [CLEAR_REFERRAL_REGISTRATION_CODE, handleClearReferralRegistrationCode],
  [CLEAR_REFERRAL_STATE, handleClearReferralState],
  [SET_REFERRAL_REGISTRATION_CODE, handleSetReferralRegistrationCode],
  [REFERRAL_CODE_ATTEMPT, handleReferralCodeAttempt],
  [SEND_REFERRAL_CODE_EMAIL, handleSendReferralCodeEmail],
  [REGISTERED_WITH_REFERRAL_CODE, handleRegisteredWithCode],
  [SET_REFERRAL_AGGREGATES, handleSetReferralAggregates],
  [SET_REFERRER_BENEFIT, handleGetReferrerBenefit],
]);

function handleClearReferralState(state, action) {
  return initialState;
}

function handleClearReferralRegistrationCode(state, action) {
  let { registrationCode } = state;
  registrationCode = null;

  return { ...state, registrationCode };
}

function handleSetReferralRegistrationCode(state, action) {
  const data = action.payload;
  let { registrationCode, attempts, valid, codeFromUrl } = state;

  if (data.merchant_referral_code !== registrationCode) {
    registrationCode = data.merchant_referral_code;
    attempts = 0;
    valid = data.valid;
    codeFromUrl = true;
  }

  return { ...state, registrationCode, attempts, valid, codeFromUrl };
}

function handleSetReferralAggregates(state, action) {
  const data = action.payload;
  let { referralAggregates, referralCode } = state;

  referralCode = data.referral_code;
  referralAggregates = {
    benefitAmount: data.benefit_amount,
    disbursmentAmount: data.promo_disbursment_amount || 1000,
    benefitAmountRemaining: data.benefit_amount_remaining,
    numReferredMerchantsRedeemed: data.num_referred_merchants_redeemed,
    numReferredMerchantsPending: data.num_referred_merchants_pending,
    numReferredMerchants: data.num_referred_merchants,
    totalBenefitsEarned: data.total_benefits_earned,
    totalPendingBenefits: data.total_pending_benefits,
    totalBenefitsRedeemed: data.total_benefits_redeemed,
    merchantsReferred: data.merchants_referred,
  };

  return { ...state, referralAggregates, referralCode };
}

function handleReferralCodeAttempt(state, action) {
  const data = action.payload;
  const attempts = state.attempts + 1;

  return data
    ? {
        ...state,
        attempts,
        registrationCode: data.merchant_referral_code,
        valid: data.valid,
      }
    : { ...state, attempts };
}

function handleSendReferralCodeEmail(state, action) {
  const data = action.payload;

  return data ? { ...state, sendEmail: data } : state;
}

function handleRegisteredWithCode(state, action) {
  const { registered } = action.payload;

  return {
    ...state,
    registeredWithCode: registered,
  };
}

export function isMerchantPartOfReferralProgram(state) {
  return state.referral.referralCode !== null;
}

export default function referralReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleGetReferrerBenefit(state, action) {
  const data = action.payload;
  let { registrationCode, referrerBenefit, rewardType, valid } = state;

  if (data.merchant_referral_code !== registrationCode) {
    registrationCode = data.merchant_referral_code;
    referrerBenefit = data.benefit_amount;
    rewardType = data.reward_type;
    valid = data.valid;
  }

  return { ...state, registrationCode, referrerBenefit, rewardType, valid };
}
