import {
  CLEAR_DAILY_REPORT,
  CLEAR_DAILY_REPORTS,
  CLEAR_MONTHLY_REPORT,
  GET_DAILY_REPORT,
  GET_DAILY_REPORTS,
  GET_MONTHLY_REPORT,
  GET_MONTHLY_REPORTS,
  GET_LAST_DEPOSIT,
  GET_PENDING_DEPOSITS,
  GET_MONTHLY_LEDGER_REPORT,
} from '../actions/Types';

const initialState = {
  currentDailyReport: null,
  currentMonthlyReport: null,
  daily: null,
  monthly: null,
  monthlyLedger: {
    rows: [],
    lastEvaluatedKey: null,
  },
  last: null,
  pending: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_DAILY_REPORT:
      return { ...state, currentDailyReport: null };
    case CLEAR_DAILY_REPORTS:
      return { ...state, daily: null };
    case CLEAR_MONTHLY_REPORT:
      return { ...state, currentMonthlyReport: null };
    case GET_DAILY_REPORT:
      return { ...state, currentDailyReport: action.payload };
    case GET_DAILY_REPORTS:
      if(state.daily !== null){
        return { ...state, daily: [...state.daily, ...action.payload] };
      }else{
        return { ...state, daily: action.payload };
      }
      
    case GET_MONTHLY_REPORT:
      return { ...state, currentMonthlyReport: action.payload };
    case GET_MONTHLY_REPORTS:
      return { ...state, monthly: action.payload };
    case GET_MONTHLY_LEDGER_REPORT:
      const newState = {
        rows: [
          ...state.monthlyLedger.rows,
          ...action.payload.rows,
        ],
        lastEvaluatedKey: action.payload.lastEvaluatedKey,
      }
      return {...state, monthlyLedger: newState }
    case GET_LAST_DEPOSIT:
      return { ...state, last: action.payload };
    case GET_PENDING_DEPOSITS:
      return { ...state, pending: action.payload };
    default:
      return state;
  }
}
