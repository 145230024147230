import React, { useState, useEffect } from 'react';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipTypography } from '@clipmx/clip-storybook';
import chevron from '../../../../assets/images/berlin/chevron-left.svg';

import CollapsableSection from '../../Commons/CollapsableSection';

import text from './text';

import styles from './styles';
const useStyles = makeStyles(styles);

const TermsAndConditions = () => {
  const classes = useStyles();
  const [selectedTab, openTab] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const renderSections = () => {
    let sections = [];
    for (let i = 1; i <= 31; i++) {
      sections.push(
        <CollapsableSection
          key={text['title' + i]}
          index={i}
          title={text['title' + i]}
          body={text['body' + i]}
          isOpen={selectedTab}
          open={openTab}
        />,
      );
    }
    return sections;
  };

  return (
    <div className={classes.root}>
      <ClipTypography fontWeight="bold" variant="h2" className={classes.title}>
        {text.termsTitle}
      </ClipTypography>
      <ClipTypography>{text.termsInfo}</ClipTypography>

      {renderSections()}
      <ClipTypography variant="body2" className={classes.lastUpdated}>
        {text.lastUpdated}
      </ClipTypography>

      {isVisible && (
        <div onClick={scrollToTop} className={classes.scrollToTop}>
          <img className={classes.chevron} src={chevron} alt="Go to top" />
        </div>
      )}
    </div>
  );
};

export default TermsAndConditions;
