export const styles = (theme) => {
  const rootStyles = {
    container: {
      width: `calc(100vw - ${theme.spacing(1)})`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      background: 'white',
    },
    title: {
      fontSize: theme.spacing(3),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    contactsTitle: {
      fontWeight: 500,
      marginLeft: theme.spacing(1.25),
      fontSize: theme.spacing(2.25),
    },
    contactsIcon: {
      color: theme.palette.indigo.l30,
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
    divider: {
      backgroundColor: 'transparent',
      height: theme.spacing(0.8),
      margin: 0,
      padding: 0,
    },
    dividerBank: {
      backgroundColor: 'transparent',
      height: theme.spacing(1),
    },
    checkbox: {
      color: theme.palette.orange.base,
    },
    checkboxForm: {
      display: 'flex',
      alignItems: 'center',
    },
    editTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  };
  return { ...rootStyles };
};
