import axios from 'axios';

import config from '../../../config';
import { getRequestOptions } from '../../../helpers/fetchUtil';
import apiPath from '../../../helpers/apiPaths';

class TransactionService {
  static getTransactions(params) {
    const options = getRequestOptions();
    const url = new URL(apiPath.searchTransaction, config('apiUrl'));
    params.forEach((value, key) => url.searchParams.set(key, value));
    return axios.get(url, options);
  }

  static getTransaction(id) {
    const options = getRequestOptions();
    const url = new URL(`${apiPath.transactionDetail}${id}`, config('apiUrl'));

    return axios.get(url, options);
  }

  static getTransactionReport(params) {
    const options = getRequestOptions();
    const url = new URL(apiPath.getReport, config('apiUrl'));

    url.searchParams.set('page', 1);
    params.forEach((value, key) => url.searchParams.set(key, value));

    return axios.post(url, {}, options).then((res) => {
      return res.data.url;
    });
  }
}

export default TransactionService;
