import React, { useState } from 'react';
import PropTypes from 'prop-types';

import OneStepNavigator from '../../Commons/OneStepNavigator';
import WhyPasswordTooltip from '../../PinChange/WhyPasswordTooltip';
import AuthService from '../../../auth/service';
import { required } from '../../../../helpers/validate';

import { ClipTypography, ClipButton, ClipInput } from '@clipmx/clip-storybook';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import ClipInputAdornment from '@clipmx/clip-storybook/dist/components/Input/Adornment/index';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';

import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { styles } from '../styles';
import text from '../text';
import BerlinService from 'components/Berlin/service';

const useStyles = makeStyles(styles);
const PasswordCheck = (props) => {
  const classes = useStyles(props);
  const {
    email,
    password,
    buttonDisabled,
    changeCdaPath,
    setScreen,
    setPassword,
    SCREENS,
  } = props;

  const [tooltips, toggleTooltips] = useState({
    passwordTooltip: false,
  });
  const [invalid, setInvalid] = useState({
    status: true,
    errorMessage: '',
    error: false,
  });

  const validateUser = async () => {
    try {
      const response = await BerlinService.getMerchantNonce({ password });
      if (response.data.item.nonce) {
        setInvalid({ status: true, errorMessage: '', error: false });
        if (changeCdaPath) {
          setScreen(SCREENS.CREATE_CDA);
          setPassword('');
          return;
        }
        setScreen(SCREENS.CREATE_CDA);
        setPassword('');
      } else {
        setInvalid({
          status: false,
          error: true,
          errorMessage: text.passwordError,
        });
        setPassword('');
      }
    } catch (error) {
      setInvalid({
        status: false,
        error: true,
        errorMessage: text.passwordError,
      });
      setPassword('');
    }
  };

  return (
    <div className={classes.container}>
      <OneStepNavigator
        title={text.changeCDA}
        stepBackFunction={setScreen}
        stepBackTarget={SCREENS.ACCOUNT}
      />
      <div className={classes.passwordContainer}>
        <ClipTypography
          variant={'subtitle1'}
          component={'h1'}
          fontWeight={'bold'}
        >
          {text.enterPassword}
        </ClipTypography>
        <ClipInput
          label={text.passwordField}
          error={invalid.error}
          errormessage={invalid.errorMessage}
          type={'password'}
          name={'password'}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setInvalid({ ...invalid, status: e.target.value.length < 8 });
          }}
          validate={[required]}
          adornmentIcon={
            <ClipInputAdornment
              classes={{ root: classes.inputAdornment }}
              position={'end'}
            >
              <IconButton
                aria-label={text.help}
                onClick={() => toggleTooltips({ passwordTooltip: true })}
              >
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </ClipInputAdornment>
          }
          helperText={invalid.errorMessage}
          fullWidth
        />
        <div className={classes.buttonContainer}>
          <ClipButton
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            disabled={buttonDisabled}
            onClick={() => validateUser()}
          >
            {text.submitButton}
          </ClipButton>
        </div>
      </div>
      <ClipDrawer anchor={'bottom'} open={tooltips.passwordTooltip}>
        <WhyPasswordTooltip
          togglePasswordTooltip={(arg) =>
            toggleTooltips({ passwordTooltip: arg })
          }
        />
      </ClipDrawer>
    </div>
  );
};

PasswordCheck.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string,
  buttonDisabled: PropTypes.bool.isRequired,
  changeCdaPath: PropTypes.bool.isRequired,
  setScreen: PropTypes.func,
  setPassword: PropTypes.func,
  SCREENS: PropTypes.object,
};

PasswordCheck.default = {
  email: '',
  password: '',
  buttonDisabled: true,
  changeCdaPath: false,
  setScreen: () => {},
  setPassword: () => {},
  SCREENS: {},
};

export default PasswordCheck;
