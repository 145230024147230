import React from 'react';
import { ClipTypography } from '@clipmx/clip-storybook';
import text from './text';
import styles from './styles';
import geoSpot from '../../../../assets/images/berlin/geoSpot.svg';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

const useStyles = makeStyles(styles);

const LocationError = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.noGeoContainer}>
      <img src={geoSpot} alt="geooloation" />
      <ClipTypography className={classes.geoTitle} variant={'title'}>
        {text.shareGeo}
      </ClipTypography>
      <ClipTypography variant={'subheading2'}>{text.geoMessage}</ClipTypography>
    </div>
  );
};

export default LocationError;
