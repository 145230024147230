const textColorStyle = (color, theme) => {
  switch (color) {
    case 'primary':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.main;
    default:
      return '#fc4c02';
  }
};
const styles = (theme) => {
  return {
    root: {
      border: 'solid 1px #ccd0d3',
      borderRadius: 4,
      fontFamily: 'Barlow',
      backgroundColor: 'white',
      textAlign: 'left',
      '& .helper-select-icon': {
        color: theme.palette.primary.main,
      },
    },
    formControl: (props) => ({
      marginLeft: 14,
      transform: 'translate(0, 30px) scale(1)',
    }),
    input: {
      padding: '20px 14px 5px 14px',
      margin: 0,
      color: `${theme.palette.indigo.base}`,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      zIndex: 10,
    },
    focused: {
      fontSize: theme.typography.caption.fontSize,
    },
    inputFocused: (props) => ({
      outline: 4,
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
      '& .MuiInputAdornment-root': {
        color: theme.palette.primary.main,
      },
    }),
    shrink: {
      zIndex: 15,
      transform: 'translate(0, 25px) scale(0.75) !important',
      transformOrigin: 'top left',
    },
    disabled: {
      backgroundColor: '#f7f7f8',
      color: '#99a2a7',
      opacity: '0.42 !important',
      borderColor: '#ccd0d3',
      '& .MuiInputAdornment-root': {
        color: '#99a2a7',
      },
    },
    label: {
      color: '#808b91',
      padding: 0,
      fontSize: '1rem',
      fontFamily: 'Barlow',
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: '0.00938em',
      zIndex: 1,
    },
    icon: {
      marginRight: '16px',
    },
  };
};

export default styles;
