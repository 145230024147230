import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { Forms } from '../../../constants/AppConstants';
import { Clear } from '@clipmx/clip-storybook/dist/components/Icons';
import Error from './Error';
import styles from './styles';
import Voucher from './Voucher';
import Contacts from './Contacts';
import BerlinService from '../service';
import ContactInfo from './ContactInfo';
import DepositStep from './DepositStep';
import EditContact from './EditContact';
import LocationError from './LocationError';
import ChangeContact from './ChangeContact';
import CdaHelper from './../CdaHelper';
import BerlinSpinner from '../BerlinSpinner';
import SaveContactInfo from './SaveContactInfo';
import SPEIConfirmation from './SPEIConfirmation';
import SendingAnimation from './SendingAnimation';
import OneStepNavigator from '../Commons/OneStepNavigator';
import ProgressNavigator from '../Commons/ProgressNavigator';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import {EVENTS, Clevertap} from '../../../helpers/Clevertap';

const useStyles = makeStyles(styles);

const Transfers = (props) => {
  const { setActiveScreen, SCREENS, account, change, user } = props;
  const classes = useStyles(props);

  const [step, setStep] = useState(-2);
  const [fromContact, setFromContact] = useState(false);
  const [fromConfirmation, setFromConfirmation] = useState(false);
  const [fromSaveContact, setFromSaveContact] = useState(false);
  const [loadContacts, setLoadContact] = useState(true);
  const [location, setLocation] = useState(null);
  const [reference, setReference] = useState(null);
  const [date, setDate] = useState(null);
  const [drawerState, setDrawerState] = useState(null);
  const [speiError, setSpeiError] = useState(false);

  useEffect(() => {
    const tempLocation = {};
    navigator.geolocation.getCurrentPosition(
      (position) => {
        tempLocation.latitude = position.coords.latitude
          .toString()
          .substring(0, 10);
        tempLocation.longitude = position.coords.longitude
          .toString()
          .substring(0, 10);
        setLocation(tempLocation);
        setStep(0);
      },
      (error) => {
        console.log(error);
        setStep(-1);
      },
    );
  }, []);

  const restartFlow = useCallback(() => {
    change('bank', '');
    change('name', '');
    change('alias', '');
    change('clabe', '');
    change('amount', null);
    change('concept', '');
    change('contactSaved', '');
    change('fromNewContact', '');
    setStep(1);
    setDrawerState(null);
  }, [change, setStep, setDrawerState]);

  const closeFlow = useCallback(() => {
    change('bank', '');
    change('name', '');
    change('alias', '');
    change('clabe', '');
    change('amount', null);
    change('concept', '');
    change('contactSaved', '');
    change('fromNewContact', '');
    setDrawerState(null);
    setActiveScreen(SCREENS.BALANCE);
  }, [change, setActiveScreen, setDrawerState, SCREENS]);

  const sendSpeiCda = () => {
    const { name, clabe, concept, amount } = props;
    const body = {
      recipient_name: name,
      recipient_account: clabe,
      description: concept,
      amount: amount,
      location: location,
    };
    setStep(5);

    BerlinService.postSpeiOutV3(account.proxy_account_id, account.card_representation[0].proxy_card, body)
      .then((response) => {
        Clevertap.event(
          EVENTS.BERLIN.BALANCE.EVENT,
          EVENTS.BERLIN.BALANCE.ACTIONS.ENVIAR_DINERO_FINISHED,
          )
        setSpeiError(false);
        setReference(response.data.message.reference);
        setDate(response.data.message.date);
        setStep(6);
      })
      .catch((error) => {
        if (error?.response?.status_code === 500) {
          setReference(error.response.body.message.reference);
          setSpeiError(true);
          setStep(6);
        }
        setStep(7);
      });
  };

  const stepBack = () => {
    setStep(0);
    setFromContact(false);
    setLoadContact(true);
    change('amount', null);
    change('concept', '');
  };

  const renderHeader = () => {
    if (step <= 0 || step === 4) {
      return (
        <OneStepNavigator
          v2
          title={step === -1 && 'Enviar dinero'}
          stepBackFunction={step === 4 ? setStep : setActiveScreen}
          stepBackTarget={step === 4 ? step - 1 : SCREENS.BALANCE}
        />
      );
    }
    if (step > 0 && step <= 3) {
      return (
        <ProgressNavigator
          progress={step}
          total={3}
          v2
          handleClick={() => {
            if (step === 2) {
              if (fromConfirmation) {
                setStep(3)
                setFromConfirmation(false)
                return;
              }
              if (fromContact) {
                stepBack();
                setFromConfirmation(false)
              }
              setStep(step - 1);
            } else {
              setStep(step - 1);
              if (step === 1) {
                change('amount', null);
                change('concept', '');
                setLoadContact(true);
              }
            }
          }}
          handleClose={() => closeFlow()}
        />
      );
    }
    return null;
  };

  const renderSteps = () => {
    switch (step) {
      case -2:
        return <BerlinSpinner />;
      case -1:
        return <LocationError />;
      case 0:
        return (
          <Contacts
            loadContacts={loadContacts}
            setLoadContact={setLoadContact}
            setFromContact={() => setFromContact(true)}
            action={setStep}
            openEdit={(contact) =>
              setDrawerState({ value: 'edit-contact', contact })
            }
          />
        );
      case 1:
        return (
          <ContactInfo
            setFromSaveContact={setFromSaveContact}
            action={setStep}
            openChange={() => setDrawerState({ value: 'save-contact-info' })}
          />
        );
      case 2:
        return (
          <DepositStep
            setFromSaveContact={setFromSaveContact}
            fromSaveContact={fromSaveContact}
            action={() => setStep(3)}
            openChange={() => setDrawerState({ value: 'change-contact' })}
          />
        );
      case 3:
        return (
          <SPEIConfirmation
            openChange={() => setDrawerState({ value: 'change-contact' })}
            prevStep={() => {
              setStep(2)
              setFromConfirmation(true)
            }}
            action={() => {
              setStep(4);
            }}
          />
        );
      case 4:
        return (
          <CdaHelper
            cdaReady={true}
            recoverCda={false}
            user={user}
            setActiveScreen={() => sendSpeiCda()}
            location={location}
            merchantId={account.account_holder_id}
          />
        );
      case 5:
        return <SendingAnimation />;
      case 6:
        return (
          <Voucher
            closeFlow={() => closeFlow()}
            action={() => setStep(0)}
            date={date}
            reference={reference}
            speiError={speiError}
          />
        );
      case 7:
        return <Error action={() => setStep(0)} />;
      default:
        break;
    }
  };

  const renderDrawerContent = () => {
    switch (drawerState.value) {
      case 'change-contact':
        return (
          <ChangeContact
            closeDrawer={() => setDrawerState(null)}
            Forms={Forms}
            restartFlow={restartFlow}
          />
        );
      case 'save-contact-info':
        return <SaveContactInfo closeDrawer={() => setDrawerState(null)} />;
      case 'edit-contact':
        return (
          <EditContact
            contact={drawerState.contact}
            setLoadContact={setLoadContact}
            closeDrawer={() => setDrawerState(null)}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className={classes.container}>
      {renderHeader()}
      {renderSteps()}
      <ClipDrawer
        onClose={() => {
          setDrawerState(null);
        }}
        classes={{ root: classes.drawer, paper: classes.paper }}
        anchor="bottom"
        open={drawerState}
      >
        <Clear className={classes.clear} onClick={() => setDrawerState(null)} />
        {drawerState && renderDrawerContent()}
      </ClipDrawer>
    </div>
  );
};

const selector = formValueSelector(Forms.BERLIN_SPEI_OUT);

const mapStateToProps = (state) => {
  return {
    account: state.berlin.account_representation,
    user: state.user,
    clabe: selector(state, 'clabe'),
    name: selector(state, 'name'),
    amount: selector(state, 'amount'),
    concept: selector(state, 'concept'),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    change: (key, value) =>
      dispatch(change(Forms.BERLIN_SPEI_OUT, key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfers);
