import React, { PureComponent } from 'react';

import * as Styled from './styled';
import { FilesCard } from 'components/loans/Commons/FileUploader/styled';

class GroupForm extends PureComponent {
  render() {
    return (
      <Styled.Group>
        {!this.props.icon && (
          <Styled.HeaderTitle>
            {this.props.icon}
            {this.props.title}
          </Styled.HeaderTitle>
        )}
        {this.props.icon && (
          <Styled.HeaderTitleWithoutIcon>
            {this.props.icon}
            {this.props.title}
          </Styled.HeaderTitleWithoutIcon>
        )}
        {this.props.subtitle}
        {!this.props.isFiles && (
          <Styled.Card>{this.props.children}</Styled.Card>
        )}
        {this.props.isFiles && <FilesCard>{this.props.children}</FilesCard>}
        {this.props.footer && (
          <Styled.Footer>{this.props.footer}</Styled.Footer>
        )}
      </Styled.Group>
    );
  }
}

export default GroupForm;
