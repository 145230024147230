import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { ClipButton } from '@clipmx/clip-storybook';
import text from './text';
import styles from './styles';
import { BERLIN_FINANCIAL_SERVICE_PROVIDERS } from '../../../constants/AppConstants';

const CacaoTooltip = (props) => {
  const { classes, toggleTooltip, provider } = props;

  return (
    <div className={classes.root}>
      <Typography variant="title" className={classes.title}>
        {provider === BERLIN_FINANCIAL_SERVICE_PROVIDERS.CACAO ? text.titleCacao : text.title}
      </Typography>
      <Typography variant="body1" className={classes.message}>
        {provider === BERLIN_FINANCIAL_SERVICE_PROVIDERS.CACAO ? text.messageCacao : text.message}
      </Typography>
      <ClipButton
        className={classes.closeButton}
        variant={'contained'}
        color={'primary'}
        onClick={toggleTooltip}
      >
        {text.buttonLabel}
      </ClipButton>
    </div>
  );
};

CacaoTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleTooltip: PropTypes.func,
  provider: PropTypes.string,
};

export default withStyles(styles)(CacaoTooltip);
