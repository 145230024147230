export default {
  title: 'Domicilio',
  description:
    'Ahora necesitamos tu ayuda para comprobar el domicilio de tu negocio.',
  proofOfAddressSection: 'Sube una copia de tu comprobante de domicilio:',
  proofOfAddressRequirements: [
    'Recibo de luz, agua, internet, teléfono, etc.',
    'En formato jpg, png o pdf',
    'Con máximo 3 meses de antigüedad',
  ],
  addressInfoSection:
    'Ingresa los siguientes datos de tu comprobante correspondientes a tu domicilio:',
  addressInfoPostalCode: 'Código postal',
  addressInfoColony: 'Colonia',
  addressInfoMunicipality: 'Delegación / Municipio',
  addressInfoState: 'Ciudad / Estado',
  addressInfoStreet: 'Calle',
  addressInfoExternalNumber: 'Número exterior',
  addressInfoInternalNumber: 'Número interior (opcional)',
  addressTimeSection: '¿Cuánto tiempo llevas en esta dirección?',
  addressTimeYear: 'Año',
  addressTimeMonth: 'Mes',
  prefilledProofAlertTitle: 'Actualiza tu comprobante',
  prefilledProofAlertContent:
    'Es necesario que actualices tu comprobante de domicilio.',
};
