import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

const useStyles = makeStyles((theme) => ({
  titlesContent: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sd')]: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  titles1: {
    marginTop: theme.spacing(2),
  },
  titles2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },

  buttonContent: {
    display: 'flex',
    justifyContent: 'right',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'right',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },    
  },
}));

export default useStyles;
