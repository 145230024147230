import React from 'react';

import * as Styled from './styled';
import idFront from '../../../../../assets/images/berlin/idFront.svg';
import idBack from '../../../../../assets/images/berlin/idBack.svg';
import addIcon from '../../../../../assets/images/berlin/add.svg';
import removeIcon from '../../../../../assets/images/berlin/delete.svg';

import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  reduxForm,
  change as updateFormField,
} from 'redux-form';
import { Forms } from 'constants/AppConstants';
import BerlinFileInput from '../../../Commons/BerlinFileInput';

export class IdentificationsIne extends React.PureComponent {
  clickIneFront = () => {
    this.ineFront.click();
  };

  clickIneBack = () => {
    this.ineBack.click();
  };

  deleteIneFront = () => {
    const { dispatch } = this.props;
    dispatch(updateFormField(Forms.REGISTRATION_INFORMATION, 'ineFront', null));
  };

  deleteIneBack = () => {
    const { dispatch } = this.props;
    dispatch(updateFormField(Forms.REGISTRATION_INFORMATION, 'ineBack', null));
  };

  render() {
    const { ineFront, ineBack } = this.props;
    return (
      <Styled.IdentificationsIneContainer>
        <Styled.IdentificationsIneCardContainer>
          <Styled.IneLabelContainer>
            <Styled.CardSide>
              {ineFront ? ineFront.name : 'Frente'}
            </Styled.CardSide>
            <Styled.CardSideDescription>
              Donde está tu foto
            </Styled.CardSideDescription>
          </Styled.IneLabelContainer>
          {ineFront ? (
            <Styled.FileInputContainer onClick={this.deleteIneFront}>
              <Styled.INEFrontImage src={URL.createObjectURL(ineFront)} />
              <Styled.RemoveImageOverlay
                src={removeIcon}
              ></Styled.RemoveImageOverlay>
              <Field
                refName={(ref) => {
                  this.ineFront = ref;
                }}
                name="ineFront"
                component={BerlinFileInput}
              ></Field>
            </Styled.FileInputContainer>
          ) : (
            <Styled.FileInputContainer onClick={this.clickIneFront}>
              <Styled.INEFrontImage src={idFront}></Styled.INEFrontImage>
              <Styled.AddImageOverlay src={addIcon}></Styled.AddImageOverlay>
              <Field
                refName={(ref) => {
                  this.ineFront = ref;
                }}
                name="ineFront"
                component={BerlinFileInput}
              ></Field>
            </Styled.FileInputContainer>
          )}
        </Styled.IdentificationsIneCardContainer>
        <Styled.IdentificationsIneCardContainer>
          <Styled.IneLabelContainer key={'back'}>
            <Styled.CardSide>
              {ineBack ? ineBack.name : 'Vuelta'}
            </Styled.CardSide>
            <Styled.CardSideDescription>
              Donde está tu firma
            </Styled.CardSideDescription>
          </Styled.IneLabelContainer>
          {ineBack ? (
            <Styled.FileInputContainer onClick={this.deleteIneBack}>
              <Styled.INEFrontImage src={URL.createObjectURL(ineBack)} />
              <Styled.RemoveImageOverlay
                src={removeIcon}
              ></Styled.RemoveImageOverlay>
              <Field
                refName={(ref) => {
                  this.ineBack = ref;
                }}
                name="ineBack"
                component={BerlinFileInput}
              ></Field>
            </Styled.FileInputContainer>
          ) : (
            <Styled.FileInputContainer onClick={this.clickIneBack}>
              <Styled.INEFrontImage src={idBack}></Styled.INEFrontImage>
              <Styled.AddImageOverlay src={addIcon}></Styled.AddImageOverlay>
              <Field
                refName={(ref) => {
                  this.ineBack = ref;
                }}
                name="ineBack"
                component={BerlinFileInput}
              ></Field>
            </Styled.FileInputContainer>
          )}
        </Styled.IdentificationsIneCardContainer>
      </Styled.IdentificationsIneContainer>
    );
  }
}

const selector = formValueSelector(Forms.REGISTRATION_INFORMATION);

const mapStateToProps = (state) => {
  return {
    ineFront: selector(state, 'ineFront'),
    ineBack: selector(state, 'ineBack'),
  };
};

const ReduxIdentificationsIne = reduxForm(
  {
    form: Forms.REGISTRATION_INFORMATION,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
  },
  mapStateToProps,
)(IdentificationsIne);

export default connect(mapStateToProps)(ReduxIdentificationsIne);
