import React, { useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Lottie from 'lottie-react';
import imageCompression from 'browser-image-compression';
import Signature from '../../../../assets/images/berlin/lottie/Signature/data.json';
import BerlinService from '../../service';
import { uploadIDPictures } from '../../redux/actions';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import {EVENTS} from '../../../../helpers/Clevertap';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import { styles } from './styles';
import text from './text';

const useStyles = makeStyles(styles);
const DigitalSign = (props) => {
  const classes = useStyles(props);
  const {
    step,
    setStep,
    setSignature,
    sendUpgradeRequest,
    loading,
    setLoading,
  } = props;

  const [signatureCanvas, setSignatureCanvas] = useState({});
  const [disabledButton, setDisabledButton] = useState(true);

  const dataURIToBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const arrayBuffer = new ArrayBuffer(byteString.length);
    var integerArray = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
      integerArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  };

  const saveSignature = async () => {
    const files = [];
    const options = {
      maxSizeMB: 2,
    };
    const fileName = 'signature';
    const bytesInMb = 1048576;

    if (!signatureCanvas.isEmpty()) {
      setLoading(true);
      const signature = dataURIToBlob(signatureCanvas.toDataURL());
      if (signature.size / bytesInMb > 2) {
        const compressedSignature = await imageCompression(signature, options);
        files.push({ file: compressedSignature, name: fileName });
      } else {
        files.push({ file: signature, name: fileName });
      }
      let documentsUrlResponse = await BerlinService.getDocumentsUrl();
      await uploadIDPictures(files, documentsUrlResponse.data, 'INE');
      setSignature(
        documentsUrlResponse.data.signature.url +
          documentsUrlResponse.data.signature.fields.key,
      );
      sendUpgradeRequest();
      setStep(step + 1);
    }
  };

  const renderSignatureContent = () => {
    switch (step) {
      case 11:
      default:
        if (signatureCanvas && Object.keys(signatureCanvas).length > 0) {
          setSignatureCanvas({});
          setDisabledButton(true);
        }
        return (
          <>
            <ClipTypography
              variant={'h5'}
              component={'h2'}
              fontWeight={'regular'}
            >
              {text.title}
            </ClipTypography>
            <ClipTypography
              variant={'body1'}
              component={'p'}
              classes={{ root: classes.typographyContent }}
            >
              {text.content}
            </ClipTypography>
            <Lottie animationData={Signature} className={classes.lottie} />
            <ClevertapComponent
              renderComponent={<ClipButton />}
              events={[
                {
                  callbackName: 'onClick',
                  event: EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT,
                  action: EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.SIGNATURE_REQUEST_CLICKED,
                },
              ]}
              variant={'contained'}
              color={'primary'}
              fullWidth
              onClick={() => {
                setStep(step + 1);
              }}
              >
                {text.signButton}
              </ClevertapComponent>
          </>
        );
      case 12:
        return (
          <>
            <ClipTypography
              variant={'h5'}
              component={'h2'}
              fontWeight={'regular'}
            >
              {text.titleSign}
            </ClipTypography>
            <ClipTypography
              variant={'body1'}
              component={'p'}
              classes={{ root: classes.typographyContent }}
            >
              {text.contentSign}
            </ClipTypography>
            <SignatureCanvas
              penColor="black"
              dotSize={2}
              throttle={32}
              canvasProps={{
                style: {
                  width: '100%',
                  height: '60vh',
                  border: '1px solid #E6E8E9',
                  borderRadius: '8px',
                },
              }}
              ref={(ref) => setSignatureCanvas(ref)}
              onEnd={() => setDisabledButton(false)}
            />
            <div className={classes.buttonContainer}>
              <ClipButton
                variant="text"
                color="primary"
                onClick={() => {
                  setDisabledButton(true);
                  signatureCanvas.clear();
                }}
                disabled={disabledButton}
              >
                {text.eraseButton}
              </ClipButton>
              <ClipButton
                variant="contained"
                color="primary"
                onClick={() => saveSignature()}
                disabled={disabledButton}
              >
                {text.continueButton}
              </ClipButton>
            </div>
          </>
        );
    }
  };

  return <div className={classes.container}>{renderSignatureContent()}</div>;
};

export default DigitalSign;
