import React, { Component } from 'react';

import * as Styled from './styled';

class TabsContainer extends Component {
  render() {
    const { children, ...props } = this.props;
    return <Styled.Container {...props}>{children}</Styled.Container>;
  }
}

export default TabsContainer;
