import urls  from 'helpers/urls';
import { loansClipPhone as ClipPhone } from 'constants/AppConstants';

export default {
    helpNeed: '¿Necesitas ayuda?',
    contactExternalWhatsapp: 'Escríbenos en ',
    contactWhatsApp: 'Whatsapp',
    contactExternalMessageAdvice: 'Llámanos al ',
    contactMobile: `${ClipPhone}`,
    contactEmail: ' Envía un correo a ',
    // contactUrlEmail: `${urls.clipHelpEmail}`,
    contactChat: ' o solicita apoyo en el chat de',
    contactClipMx: 'clip.mx.',
    contacCustomer: 'Customer Happiness® te atiende 24/7, todo el año.',
    customerSupport: `Nuestro servicio de atención a clientes Customer Happiness® 
    te brinda atención personalizada los 7 días de la semana, las 24h del día. Escríbenos por Whatsapp o llámanos al `,
  };


  