import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import Switch from '@material-ui/core/Switch';
import AcceptTermsImg from '../../../../assets/images/berlin/accept-terms.svg';

import styles from './styles';
import text from './text';
const useStyles = makeStyles(styles);

const AcceptPrivacy = (props) => {
  const {
    documentType,
    setStep,
    steps,
    setDisableCreateButton,
    disableCreateButton,
    termsAccepted,
    handleChange,
  } = props;

  const classes = useStyles();

  const allTrue = (obj) => {
    for (var o in obj) if (!obj[o]) return false;

    return true;
  };

  useEffect(() => {
    if (allTrue(termsAccepted)) {
      setDisableCreateButton(false);
      return;
    }
    if (!disableCreateButton) {
      setDisableCreateButton(true);
      return;
    }
  }, [termsAccepted]);

  let contract = text.contract.split('%').map((line) => {
    if (line === 'link') {
      return (
        <span
          className={classes.link}
          key={text.contractFile}
          onClick={() => {
            setStep(steps.CONTRACT);
          }}
        >
          {text.contractLink}
        </span>
      );
    }
    return line;
  });

  let privacy = text.acceptPrivacy.split('%').map((line) => {
    if (line === 'link1') {
      return (
        <span
          className={classes.link}
          key={text.privacyLink}
          onClick={() => {
            setStep(steps.PRIVACY);
          }}
        >
          {text.privacyLink}
        </span>
      );
    }
    return line;
  });

  return (
    <div className={classes.container}>
      <ClipTypography variant="h3" className={classes.title}>
        {text.title}
      </ClipTypography>
      <ClipTypography className={classes.headline}>{text.info}</ClipTypography>
      <div className={classes.imageContainer}>
        <img width="104" src={AcceptTermsImg} alt="" />
      </div>
      <div className={classes.lineContainer}>
        <ClipTypography variant="body2" className={classes.switchText}>
          {contract}
        </ClipTypography>
        <Switch
          checked={termsAccepted.contract}
          onChange={handleChange('contract')}
          color="primary"
        />
      </div>
      <div className={classes.lineContainer}>
        <ClipTypography variant="body2" className={classes.switchText}>
          {privacy}
        </ClipTypography>
        <Switch
          checked={termsAccepted.terms}
          onChange={handleChange('terms')}
          color="primary"
        />
      </div>
      <div className={classes.lineContainer}>
        <ClipTypography variant="body2" className={classes.switchText}>
          {text.authorizeGeo}
        </ClipTypography>
        <Switch
          checked={termsAccepted.geo}
          onChange={handleChange('geo')}
          color="primary"
        />
      </div>
      <div className={classes.lineContainer}>
        <ClipTypography variant="body2" className={classes.switchText}>
          {documentType === 'ine'
            ? text.authorizeDataIne
            : text.authorizeDataPassport}
        </ClipTypography>
        <Switch
          checked={termsAccepted.data}
          onChange={handleChange('data')}
          color="primary"
        />
      </div>
    </div>
  );
};

AcceptPrivacy.propTypes = {
  documentType: PropTypes.string.isRequired,
  setStep: PropTypes.any,
  steps: PropTypes.any,
  setDisableCreateButton: PropTypes.func.isRequired,
  disableCreateButton: PropTypes.bool.isRequired,
  termsAccepted: PropTypes.object,
  handleChange: PropTypes.func,
};
AcceptPrivacy.defaultProps = {
  documentType: '',
  setStep: () => {},
  steps: {},
  setDisableCreateButton: () => {},
  disableCreateButton: true,
  termsAccepted: {},
  handleChange: () => {},
};

export default AcceptPrivacy;
