import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import backspaceIcon from '../../../assets/images/berlin/backspace-icon.svg';

const styles = (theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr 1fr',
    columnGap: '2vw',
    rowGap: '10px',
    justifyItems: 'center',
    alignItems: 'center',
    height: '100%',
  },
  buttonArea: {
    fontSize: '28px',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    fontWeight: 'normal',
  },
  lowOpacity: {
    opacity: 0.26,
  },
});

const OnScreenKeypad = ({
  classes,
  setValue,
  value,
  maxLength,
  keypadDisabled,
}) => {
  const renderNumber = () => {
    let numButtons = [];
    for (let i = 1; i <= 9; i++) {
      numButtons.push(
        <Button
          disabled={keypadDisabled}
          key={i}
          onClick={() => addNumber(i)}
          className={classes.buttonArea}
        >
          {i}
        </Button>,
      );
    }
    return numButtons;
  };

  const addNumber = (num) => {
    if (value.length < maxLength) {
      const newValue = [...value];
      newValue.push(num);
      setValue(newValue);
    }
  };

  const removeNumber = () => {
    const newValue = [...value];
    newValue.pop();
    setValue(newValue);
  };

  return (
    <div className={classes.grid}>
      {renderNumber()}
      <Button disabled className={classes.buttonArea}>
        {''}
      </Button>
      <Button
        disabled={keypadDisabled}
        onClick={() => addNumber(0)}
        className={classes.buttonArea}
      >
        0
      </Button>
      <Button
        disabled={keypadDisabled}
        onClick={() => removeNumber()}
        className={classes.buttonArea}
      >
        <img
          className={keypadDisabled ? classes.lowOpacity : ''}
          width="38"
          height="38"
          src={backspaceIcon}
        />
      </Button>
    </div>
  );
};

OnScreenKeypad.propTypes = {
  classes: PropTypes.object,
  setValue: PropTypes.func,
  value: PropTypes.array,
};

OnScreenKeypad.defaultProps = {
  classes: {},
  setValue: () => {},
  value: [],
};

export default withStyles(styles)(OnScreenKeypad);
