// @ts-nocheck
import React from 'react';
import { styles } from './styles';
import { ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

const useStyles = makeStyles(styles);

const Info = (props) => {
  const { prop, value } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.info}>
      <ClipTypography
        variant={'body2'}
        fontWeight={'semiBold'}
        color={'textSecondary'}
      >
        {prop}
      </ClipTypography>
      <hr className={classes.dashedLineInfo} />
      <ClipTypography variant={'body2'}>{value}</ClipTypography>
    </div>
  );
};

export default Info;
