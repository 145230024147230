export default {
  // Authentication Routes
  signUp: '/signup',
  registrationMetaData: '/api/md/signup/meta',
  checkEmailAddressExists: '/iam/vu',
  login: '/api/login',
  logout: '/api/logout',
  resetPasswordRequest: '/api/md/password/reset',
  passwordExpiration: '/iam/password/expiration',
  resetPasswordValidation: '/iam/reset-password',
  resetPasswordVerifyToken: '/iam/reset-password/token-verify',
  phoneVerify: '/phone-validation/verify',
  phoneConfirm: '/phone-validation/confirm',

  alreadyHaveReader: '/api/merchant/onboarding/retail',

  installment: '/api/merchant/installment/edit',
  readerInfo: '/api/merchant/reader_info',
  businessAddress: '/api/merchant/business_address',

  // Catalog Routes
  catalogCategories: 'api/catalog/categories',
  catalogItems: 'api/catalog/items',
  catalogSkus: 'api/catalog/skus',
  catalogBulk: '/catalog/bulk',
  bulkUpload: '/catalog/bulk/upload',
  bulkState: '/catalog/bulk/status',
  publishInfo: '/catalog/online/config',

  // Dashboard Routes
  dashboardState: '/api/merchant/state/dashboard',

  // Email Validation Routes
  validateEmail: '/api/md/validate',
  resendValidationEmail: '/api/md/resendEmail',

  // Invoices Routes
  taxpayerId: '/api/merchant/invoice/taxPayerId',
  invoiceRecurrentGeneration: '/api/merchant/invoice/recurrent',
  invoiceRequest: '/api/merchant/invoice/requests',
  consumerInvoice: '/consumer-invoice/auth/catalog/query',

  // Merchant Referrals Routes
  verifyReferralCode: '/ref/verify',
  checkIdentityVerification: '/ref/check-identity-verification',
  sendMerchantReferralCodeEmail: '/ref/send-merchant-ref-code-email',
  registeredReferralCode: '/ref/check-merchant-registered-w-ref-code',
  onboardingCompleted: '/ref/merchant-onboard-completed',
  referralAggregates: '/ref/merchant-aggregates',

  // Onboarding Routes
  onboardingReaderInfo: '/api/merchant/onboarding/reader_info',
  onboardingBusinessAddress: '/api/merchant/onboarding/business_address',
  bankAccount: '/api/merchant/onboarding/bank',
  businessInfo: '/api/merchant/onboarding/business_info',
  onboardingInstallment: '/api/merchant/onboarding/installment',
  continuePreactive: '/api/merchant/onboarding/completed',
  preactiveStatus: '/api/merchant/state/onboarding',
  latestAddress: '/api/merchant/onboarding/address',
  backgroundProcesses: '/api/merchant/onboarding/processes',
  bankAccountCancelActivation: '/bank-account/activation',

  // Promo Routes
  isPromoCode: '/api/md/promo/info?merchant_promo_code=',
  redeemPromoCode: '/api/merchant/promo',
  storePromoCode: '/api/merchant/onboarding/promo',
  hasPromo: '/api/merchant/promo/merchant',

  userInfo: '/api/merchant/user/info',
  editUserInfo: '/api/merchant/user/info/edit',
  merchantInfo: '/api/merchant/info',
  editMerchantInfo: '/api/merchant/info/edit',
  editPassword: '/iam/change-password',

  testNotification: '/api/merchant/notification/postback/test',
  editPaymentEmail: '/api/merchant/notification/email/edit',
  editPaymentPostback: '/api/merchant/notification/postback/edit',
  toggleInstallment: '/api/merchant/notification/installments/toggle',
  togglePaymentEmail: '/api/merchant/notification/email/toggle',
  togglePaymentPostback: '/api/merchant/notification/postback/toggle',

  summaryMarketingTools: '/api/merchant/settings',

  createCollaborator: '/api/merchant/collaborator/add',
  editCollaborator: '/api/merchant/collaborator/edit',
  deleteCollaborator: '/api/merchant/collaborator/delete?id=',
  getCollaboratorList: '/api/merchant/collaborator',
  getCollaborator: '/api/merchant/collaborator/',
  getManagerList: '/api/merchant/collaborator/manager',
  getCashierList: '/api/merchant/collaborator/cashier',
  getFinanceList: '/api/merchant/collaborator/finance',

  dashboardPage: '/api/merchant/dashboard',
  transactionDetail: '/api/merchant/transaction/details?receipt_no=',
  searchTransaction: '/api/merchant/transaction',
  getReport: '/api/merchant/report/download',
  getTopupsReport: '/api/merchant/topup/search',

  confirmCatalogPilotInvitation:
    '/api/md/catalog/pilot-invitation-confirmation',

  addBankInformation: '/api/merchant/bank/add',
  bankInformationV2: '/api/merchant/bank-account',
  bankInformationIam: '/bank-account',
  cancelBankInformationV2: '/api/merchant/bank-account/stop',
  cancelBankInformation: '/api/merchant/bank/delete',
  bankDetails: '/api/merchant/bank',
  bankJson: '/api/md/bankcodes?code=',
  ordersList: '/api/reader/order/merchant',
  zipcodes: '/api/md/zipcodes?code=',

  topUpsSearch: 'topupreport/search',

  //Loans
  lastOne: '/api/loans/v2/preapprovals/lastone',
  loansMerchantInfo: '/api/loans/merchant',
  preapprovals: '/api/loans/v2/preapprovals',
  loans: '/api/loans/v2/loans',
  collections: '/api/loans/v2/loan/{loanId}/collections?payments={payments}',
  requestLoan: '/api/loans/merchant/info',
  requestLoanV2: '/api/loans/v2/request',
  documents: '/api/loans/v2/preapproval/{preapprovalId}/document',
  requestRefill: '/api/loans/v2/refill',
  postSamsMembership: '/api/loans/v2/samsclub/membership',
  postOpenDataOffer: '/api/loans/v2/opendata/request',

  // topup
  topupSettlementType: '/api/merchant/topup/settlement-type',

  // Deposits
  getDepositReport: '/api/merchant/transactions/consolidation-report',
  getLedgerDepositReport: '/activity/report/consolidation',
  getLedgerReport: '/activity/report/daily/pdf',

  //Payment Request
  paymentRequest: '/paymentreqmd',
  paymentRequestFilters: '/paymentrequests',
  paymentRequestTips: '/merchants/configs',
  paymentMLStatus: '/multipayment-status',
  getPublicProfile: '/public-profile/get-profile',
  createPublicProfile: '/public-profile/create-profile',
  picturePublicProfile: '/public-profile/profile-picture',

  // Codi
  codiSettings: '/api/merchant/codi/settings',
  codiTrxSearch: '/api/merchant/codi/transactions',
  codiTrxDetail: '/api/merchant/codi/transaction/detail',
  codiReport: '/api/merchant/codi/report',

  // Berlin
  cardBalance: '/cards/{card}/balance',
  cardTransactions: '/cards/{card}/movements',
  cardMovements: '/accounts/{card}/movements',
  onBoardingSignUp: '/onboarding/signup',
  accountHandhsake: '/accounts/cipher/handshake',
  upgradeN3: '/onboarding/upgrade',
  cardActivation: '/cards/{proxy_card_id}/activate',
  accountInformation: '/merchants/{merchantId}/account/status',
  changePin: '/cards/{proxy_card_id}/pin/update',
  waitingList: '/merchants/{merchant_id}/enrollment/waiting-list',
  getWaitingList: '/merchants/{merchant_id}/enrollment/info',
  speiOut: '/accounts/{proxy_card_id}/spei-out',
  interbankAccountValidation:
    '/interbanks/{interbank_account}/information?interbank_type=CLABE',
  enrollmentStatus: '/merchants/{merchant_id}/enrollment/order/status',
  existingMerchantsOnboarding: '/merchants/{merchant_id}/enrollment',
  existingMerchantsOnboardingInfo: '/merchants/{merchant_id}/enrollment/info',
  existingMerchantsOnboardingInfoV2: '/merchants/enrollment/info',
  enrollmentStatusV2: '/merchants/enrollment/order/status',
  dynamicCvv: '/cards/{proxy_card_id}/dynamic-cvv',
  getPIN: '/cards/{proxy_card_id}/pin/default',
  getDocumentsUrl: '/onboarding/documents',
  createCdaPIN: '/accounts/auth-code',
  accountLogin: '/accounts/login',
  speiDetails: '/accounts/spei-out/{reference}',
  // OnBoarding 2.0
  physicalCardActivation: '/cards/physical/{proxy_account_id}/activate',
  physicalCardActivationV2: '/cards/physical/v2/{proxy_account_id}/activate',
  accountInformationV2: '/merchants/v2/{merchantId}/account/status',
  cardRequest: '/enrollments',
  accountHolder: '/account-holders/{account_holder_id}',
  getDataFromCurp: '/validations/legal/{legal_identifier}',
  getCurpFromData: '/validations/legal',
  accountRegistration: '/onboarding/v2/signup',
  // OnBoarding 3.0 (swap)
  swapAuthorizerTokenSeed: '/swap-api/authorizer/token/seed',
  swapAuthorizerActionToken: '/swap-api/authorizer/token',
  swapCardInformation: '/swap-api/card',
  swapCardHandshake: '/swap-api/card/handshake',
  swapCardNip: '/swap-api/card/nip',
  swapCardMovements: '/swap-api/movements',
  swapAccountLogin: '/swap-api/account/login',
  swapEvent: '/swap-api/notification/event',
  // Endpoints
  cardBalanceV2: '/cards/v2/{proxy_account_id}/balance',
  changePinV2: '/cards/v2/{proxy_account_id}/pin/update',
  changePinV3: '/cards/v3/{proxy_account_id}/pin/update',
  getPINV2: '/cards/v2/{proxy_account_id}/pin/default',
  getPINV3: '/cards/v3/{proxy_account_id}/pin/default',
  dynamicCvvV2: '/cards/v2/{proxy_account_id}/dynamic-cvv',
  dynamicCvvV3: '/cards/v3/{proxy_account_id}/dynamic-cvv',
  cardMovementsV2: '/accounts/v2/{proxy_account_id}/movements',
  speiOutV2: '/accounts/v2/{proxy_account_id}/spei-out',
  contacts: '/account-holders/contact/',
  mlmNonce: '/mlm/nonce',
  // Ecommerce
  onlineStoreConfig: '/ecommerce/config/{proxy_merchant_token}',
};
