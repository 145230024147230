import React from 'react';
import Paper from '@clipmx/clip-storybook/dist/components/Paper';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import Edit from '@clipmx/clip-storybook/dist/components/Icons/Edit';
import CashIcon from '@clipmx/clip-storybook/dist/components/Icons/CashIcon';
import text from './text';
import * as Styled from './styled';
import Cellphone from './cellphone.jpg'

import { getConfigValue } from '../../../../config/config';

export const MyStoreEmpty = ({isMobileWebView}) => {
    const goMyStore = () => {
        if (isMobileWebView) {
            window.location.href = getConfigValue('onlineStoreURL');
        } else {
            window.open(getConfigValue('onlineStoreURL'), '_blank');
        }
    };
    return (
        <Styled.Container>
            <Paper>
                <Styled.CardContainer>
                        <Styled.ContentPage>
                            <div>
                                <Styled.PageTitle>{text.pageTitle}</Styled.PageTitle>
                            </div>
                            <div>
                                <Styled.PageDescription>
                                    {text.pageDescriptionPone}
                                </Styled.PageDescription>
                            </div>
                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={12} md={4}>
                                         <Styled.CellPhoneImage src={Cellphone} width={220} />
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={8}>
                                        <Styled.StepAvatar>
                                            <Styled.AvatarEmpty alt="Step one" className="small">1</Styled.AvatarEmpty>
                                            <Styled.FrameTitle>{text.stepOneTitle}</Styled.FrameTitle>
                                        </Styled.StepAvatar><br/>
                                            <Styled.SectionDescription>
                                                <Styled.FrameDescription>
                                                    {text.stepOneDescription}
                                                </Styled.FrameDescription>
                                            </Styled.SectionDescription><br/><br/>
                                            <Styled.StepAvatar>
                                                <Styled.AvatarEmpty alt="Step two" className="small">2</Styled.AvatarEmpty>
                                                <Styled.FrameTitle>{text.stepTwoTitle}</Styled.FrameTitle>
                                            </Styled.StepAvatar><br/>
                                            <Styled.SectionDescription>
                                                <Styled.FrameDescription>
                                                    {text.stepTwoDescription}
                                                </Styled.FrameDescription>
                                            </Styled.SectionDescription><br/><br/>
                                            <Styled.StepAvatar>
                                                <Styled.AvatarEmpty alt="Step three" className="small">3</Styled.AvatarEmpty>
                                                <Styled.FrameTitle>{text.stepThreeTitle}</Styled.FrameTitle>
                                            </Styled.StepAvatar><br/>
                                        <Styled.SectionDescription>
                                            <Styled.FrameDescription>
                                                {text.stepThreeDescription}
                                            </Styled.FrameDescription>
                                        </Styled.SectionDescription><br/>
                                        <Styled.LinkMyStore onClick={() => goMyStore()} to={'#'}>
                                            {text.cta}
                                        </Styled.LinkMyStore>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Styled.CustomizeTitle>
                                            {text.customizePageTitle}
                                        </Styled.CustomizeTitle>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                    <Styled.AvatarEmpty variant="square" alt="Money" className="square"><Styled.StyledDone /></Styled.AvatarEmpty>
                                        <Styled.FooterTitle>
                                            {text.perkOneTitle}
                                        </Styled.FooterTitle>
                                        <Styled.FooterDescription>
                                            {text.perkOneDescription}
                                        </Styled.FooterDescription>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                    <Styled.AvatarEmpty variant="square" alt="Money" className="square"><Edit style={{color: '#181A36'}} /></Styled.AvatarEmpty>
                                        <Styled.FooterTitle>
                                            {text.perkTwoTitle}
                                        </Styled.FooterTitle>
                                        <Styled.FooterDescription>
                                            {text.perkTwoDescription}
                                        </Styled.FooterDescription>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Styled.AvatarEmpty variant="square" alt="Money" className="square"><CashIcon /></Styled.AvatarEmpty>
                                        <Styled.FooterTitle>
                                            {text.perkThreeTitle}
                                        </Styled.FooterTitle>
                                        <Styled.FooterDescription style={{paddingBottom: '0px'}}>
                                            {text.perkThreeDescription}
                                        </Styled.FooterDescription>
                                    </Grid>
                                </Grid>
                            </div>
                        </Styled.ContentPage>
                </Styled.CardContainer>
            </Paper>
        </Styled.Container>
    )
}