export const styles = (theme) => {
  const rootStyles = {
    container: {
      background: theme.palette.common.white,
      width: theme.spacing(43),
      float: 'left',
      borderRadius: theme.spacing(3),
      border: `solid ${theme.spacing(0.125)}px rgba(230, 232, 233, 0.5)`,
      height: 'auto',
      minHeight: theme.spacing(30),
      display: 'flex',
      flexDirection: 'column',
      margin: `${theme.spacing(2)}px 0`,
      alignSelf: 'center',
      boxShadow: `0 ${theme.spacing(0.25)}px 21${theme.spacing(2.625)}px 
      -${theme.spacing(1)}px rgba(102, 115, 123, 0.25)`,
    },
    title: {
      marginTop: theme.spacing(3.5),
    },
  };

  return {
    ...rootStyles,
  };
};
