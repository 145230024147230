export default () => ({
  isRequired: 'No puede estar en blanco.',
  isRequiredDropdown: 'Selecciona una de las opciones.',
  isRequiredRfc: 'Introduce un RFC válido.',
  isPhoneNumber: 'Por favor incluye un número celular válido.',
  isBankCode: 'Número de cuenta bancaria inválido.',
  isEmail: 'No es válida, por favor intente de nuevo.',
  isEmailTaken: 'Este correo ya está en uso. Por favor inicia sesión.',
  isEmailInvalid: 'El correo electrónico es inválido, intenta con otro.',
  isEmailMatching: 'Tu correo electrónico no es igual.',
  isNumeric: 'No es un número.',
  isPassword: 'Introduce una contraseña correcta.',
  isPasswordMatching: '¡Oh no! Las contraseñas no coinciden.',
  isNotCurrentPassword: 'Tu nueva contraseña no puede ser igual a la anterior.',
  isInstallmentThreshold: 'Debe ser mayor o igual que $500 MXN.',
  isPostalCode: 'Longitud errónea (debe ser de 5 caracteres).',
  isValidPostalCode: 'El código postal no existe.',
  startWithZero: 'El número telefónico no puede iniciar con cero.',
  isNameTooShort: 'Es demasiado corto (mínimo 3 caracteres).',
  isValidName: 'Solo puedes usar números y letras.',
  isValidText: 'Solo puedes usar letras.',
  withNoHTML: 'El texto tiene caracteres inválidos.',
  isPromoCode: 'El código promocional es incorrecto.',
  isUrl: `Por favor, introduzca la dirección URL que comience por http o https (ejemplo: ${window.location.origin}).`,
  isValidRFC: 'Introduzca un RFC válido.',
  isValidCURP: 'Introduzca un CURP válido.',
  isValidPhysicalRFC: 'Debes ingresar un RFC de persona física.',
  serverError: 'Error del servidor. Por favor, inténtelo de nuevo.',
  businessType: '¿Qué es el negocio?',
  isReferralCode: 'El código de referencia es incorrecto.',
  maxPrice: 'El precio no puede superar $99,999.99',
  minPrice: (value) => `El monto debe ser mayor a $${value}`,
  maxStock: (value) => `El inventario no puede superar ${value}`,
  minStock: (value) => `El inventario debe ser mayor a ${value}`,
  unitPrice: 'El precio unitario es inválido',
  termsAndConditions: 'Por favor, acepta los términos y condiciones',
  isNotAdult: 'La fecha de nacimiento no corresponde a un adulto.',
  differentClabeAccounts: 'No coincide el número con el ingresado previamente',
  shortNumber: (number) => `Es demasiado corto (mínimo ${number} números).`,
  isInvalidDay: 'El día seleccionado es incorrecto.',
  maxPriceDefaultXpay: 'El monto máximo es de $1,500',
  maxPricePilotXpay: 'El monto máximo es de $3,000',
  maxPriceLimit: 'El monto máximo es de $5,000',
  maxpriceBuenFinXpay: 'El monto máximo es de $15,000',
  generalPriceChristmas: 'El monto máximo es de $10,000',
  maxPriceChristmas: 'El monto máximo es de $30,000',
  maxLength: 'Límite de caracteres alcanzado',
  minLength: (length) =>
    `¡Oh no!El nombre debe contener más de ${length} caracteres`,
  isValidBirthdayYear: 'Año de nacimiento inválido',
  isValidBirthdayMonth: 'Mes inválido',
  lengthRequired: (number) => `El campo debe tener ${number} dígitos`,
  isNumber: 'El valor debe de ser numérico',
  minNumber: (min) => `Debe de ser mayor o igual a ${min}`,
  maxNumber: (max) => `Debe de ser menor o igual a ${max}`,
  hasNumber: 'Ingrese número exterior',
  validCard: 'Verifica que el número sea correcto.',
  isInvalidSamsMembership: 'La mambresía no es válida o tiene un error.',
  noBadWords:
    '¡Oh no! No hemos podido registar este nombre. Por favor, intenta con uno diferente',
  notSpace: 'Solo puedes usar letras y números, sin espacios.',
  invalidYear: 'Los años de residencia no pueden ser mayores a 99',
  invalidMonth: 'Los meses de residencia no pueden ser mayores a 11',
  hasRepeatedCharacter: 'Por tu seguridad, no uses 3 letras ni números identícos (“aaa”, “111”) en la contraseña.',
  hasConsecutiveAscendingCharacters: 'Por tu seguridad, no uses 3 letras ni números consecutivos (“123”, “abc”) en la contraseña.',
  hasConsecutiveDescendingCharacters: 'Por tu seguridad, no uses 3 letras ni números consecutivos (“321”, “cba”) en la contraseña.',
  hasSwapIdentifier: 'Por tu seguridad, no uses “swap” en la contraseña.',
  hasPocketGroupIdentifier: 'Por tu seguridad, no uses “pocketgroup” en la contraseña.'
});
