import styled from '@emotion/styled';

export const IdentificationsIneContainer = styled.div(() => ({
  width: '18.5rem',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
}));

export const IdentificationsIneCardContainer = styled.div(() => ({
  width: '18.5rem',
  height: '5.812rem',
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'center',
  borderRadius: '8px',
  border: 'solid 1px #e6e8e9',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1rem',
}));

export const IneLabelContainer = styled.div(() => ({
  width: '9rem',
  height: '2.5rem',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1rem',
  justifyContent: 'space-between',
}));

export const CardSide = styled.div(() => ({
  width: '9rem',
  height: '1.188rem',
  fontSize: '1rem',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const CardSideDescription = styled.div(() => ({
  width: '9rem',
  height: '1.062rem',
  fontSize: '0.875rem',
  fontWeight: 'normal',
}));

export const FileInputContainer = styled.div(() => ({
  width: '3.625rem',
  height: '3.625rem',
  marginRight: '1rem',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
}));

export const INEFrontImage = styled.img(() => ({
  width: '3.625rem',
  height: '3.625rem',
  objectFit: 'contain',
  position: 'relative',
  zIndex: '1',
}));

export const AddImageOverlay = styled.img(() => ({
  width: '3.625rem',
  height: '3.625rem',
  objectFit: 'contain',
  zIndex: '2',
  position: 'absolute',
}));

export const RemoveImageOverlay = styled.img(() => ({
  width: '3.625rem',
  height: '3.625rem',
  objectFit: 'contain',
  zIndex: '2',
  top: '-1.3rem',
  right: '-1.6rem',
  position: 'absolute',
}));
