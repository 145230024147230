export default {
  goodHistory: 'Por tu buen historial de pagos, Adelanto de Ventas Clip crece contigo.' +  
  '¡Crece tu negocio! y obtén tu dinero al siguiente día hábil. Con esta extensión:',
  beneOne: 'Liquidas el saldo de tu préstamo actual.',
  beneTwo: 'El plazo máximo de pago será rennovado.',
  beneThree: 'No necesitas llenar formularios.',
  title: 'Adelanto de Ventas Clip Max',
  hello:  (name) =>
  `Hola,  ${name}. Tienes una extensión en tu adelanto de ventas`,
  congrats: (name) => `Hola ${name}. Tienes una extensión en tu adelanto de ventas`,
  offer:
   'Tienes una nueva oferta de préstamo',
   detail:
   'Por tu buen historial de pagos, Adelanto de Ventas Clip crece contigo.', 
   detailBold:
     '¡Crece tu negocio!',
   complementDetail:
     ' y obtén tu dinero al siguiente día hábil.Con esta extensión:', 
  // CONFIRMATION
  show: 'Detalle',
  hide: 'Ocultar',
  confirmationTitle: 'Confirmación',
  yourRefill: 'Tu oferta Adelanto de Ventas Clip Max:',
  refillTitle: 'Te depositaremos ',
  newOfferTitle: 'Tu nuevo préstamo',
  newOffer: 'Nueva oferta',
  termTitle: 'Plazo máximo de pago',
  term: (months) => `${months} ${months > 1 ? 'meses' : 'mes'}`,
  chargeTitle: 'Comisión de apertura',
  payTitle: 'Forma de pago',
  chargeIva: 'Cargo fijo + IVA',
  pay: (percent) => `${percent}% de tus ventas diarias`,
  totalPay: 'Total a pagar',
  whereSubtitle: '¿A dónde irá mi Adelanto de Ventas Clip Max?',
  offerCalculated: (date) => `Oferta calculada con tus ventas al ${date}`,
  extend: 'Extiende',
  extendMaxTerm: 'tu plazo máximo de pago por',
  toPayLoan: 'A pagar tu adeudo',
  toBanck: 'A tu cuenta bancaria',
  benefitOne: 'Liquidas el saldo de tu préstamo actual',
  benefitTwo: 'El plazo máximo de pago será rennovado',
  benefitThree: 'Sin necesidad de llenar formularios',
  refillSubtitle: 'Al obtener el préstamo, depositaremos el dinero a la cuenta donde recibes tus ventas',
  reafirmTitle: 'Al obtener este préstamo:',
  disbursement: 'Haremos el depósito al siguiente día hábil',
  partOne: 'El adeudo actual de tu Adelanto de Ventas Clip por ',
  termOne: (toPay) =>`${toPay}`, 
  partTwo: 'quedará 100% saldado.',
  termTwo: 'Haremos el depósito al siguiente día hábil.',
  termThree: 'Depositaremos tu nuevo dinero en 24 horas',
  condition:
    'Al hacer click en "Obtener oferta" acepto modificación a las características de Adelanto de Ventas Clip mencionadas en la sección de términos y condiciones.',
  expiration: (expirationDate) =>
    `**Vigencia:** Esta oferta estará disponible hasta el **${expirationDate}**`,
  button: 'Obtener Oferta',
  continue: 'Me interesa',
  // CONGRATS
  titleCongrats: '¡Tu solicitud fue enviada!',
  paragraph1: '¡Bien hecho! ya estamos trabajando en tu solicitud.',
  paragraph2:
    '**Después de 24 horas hábiles** te enviaremos un correo electrónico confirmando ' +
    'la activación de tu nuevo préstamo y el depósito de tu dinero.  ',
  textButton: 'Panel de Control',
  // MYPRESTACLIP
  titleMyPrestaClip: 'Tu Adelanto de Ventas Clip actual:',
  subtitle: (amount) => `Adeudo ${amount}`,
  active: 'Activo',
  statusPaid: 'Pagado',
  termMyPrestaClip: 'Fecha límite de pago',
  expired: (day) => `Expiró hace ${day} ${day !== 1 ? 'días' : 'dia'}`,
  ends: 'Finaliza en',
  days: (day) => `${day} ${day !== 1 ? 'días' : 'dia'}`,
  paid: (percent) => `${percent} pagado`,
  toPay: (percent) => `${percent} adeudo`,
  // REVIEW
  titleReview: 'Estamos trabajando solicitud',
  detailReview: (
    merchantName,
  ) => `Hola ${merchantName}, estamos revisando tu solicitud. En breve, 
  recibirás un correo electrónico confirmando la extensión de tu adelanto 
  de ventas y el depósito de tu dinero.`,
};
