import React, { Component } from 'react';
import urls from 'helpers/urls';
import text from './text';
import * as Styled from './styled';

export class Footer extends Component {
  render() {
    return (
      <Styled.Footer path={this.props.path}>
        <Styled.Wrapper>
          <Styled.Row>
            <Styled.Link href={urls.clipContact} target="_blank">
              {text.contact}
            </Styled.Link>
          </Styled.Row>
          <Styled.Divider />
          <Styled.Row>
            <span>{text.copyright(new Date().getFullYear())}</span>
            <span>
              <Styled.Link href={urls.clipPrivacy} target="_blank">
                {text.privacyPolicy}
              </Styled.Link>
              <span className="separator">|</span>
              <Styled.Link href={urls.clipTerms} target="_blank">
                {text.termsAndConditions}
              </Styled.Link>
            </span>
          </Styled.Row>
        </Styled.Wrapper>
      </Styled.Footer>
    );
  }
}

export default Footer;
