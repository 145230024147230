const skeletonFake = 'rgba(230,232,233,0.9)';
const gradientColor = 'rgba(238,241,243,0.9)';
const skeletonFakeLight = 'rgba(230,232,233,0.5)';
const gradientColorLight = 'rgba(238,241,243,0.5)';

const styles = () => ({
  '@keyframes shine': {
    '0%': {
      backgroundPosition: '-100px',
    },
    '40%, 100%': {
      backgroundPosition: '100vw',
    },
  },
  headerContainer: {
    textAlign: 'center',
  },
  headerBlock: {
    margin: '2px auto',
    height: '30px',
    width: '70vw',
    borderRadius: '8px',
    backgroundColor: skeletonFake,
    backgroundImage: `linear-gradient(90deg, ${skeletonFake} 0px, ${gradientColor} 40px, ${skeletonFake} 80px)`,
    animation: 'shine 1.6s infinite linear',
    backgroundSize: '600px',
    opacity: '90%',
  },
  subheaderSection: {
    marginTop: '8px',
  },
  subheaderBlock: {
    margin: '2px auto',
    height: '19px',
    width: '40vw',
    borderRadius: '8px',
    backgroundColor: skeletonFakeLight,
    backgroundImage: `linear-gradient(90deg, ${skeletonFakeLight} 0px, ${gradientColorLight} 40px, ${skeletonFakeLight} 80px)`,
    animation: 'shine 1.6s infinite linear',
    backgroundSize: '600px',
    opacity: '90%',
  },
  bodySection: {
    marginLeft: '2rem',
    marginTop: '30px',
  },
  bodySubheaderBlock: {
    marginTop: '16px',
    height: '19px',
    width: '40vw',
    borderRadius: '8px',
    backgroundColor: skeletonFakeLight,
    backgroundImage: `linear-gradient(90deg, ${skeletonFakeLight} 0px, ${gradientColorLight} 40px, ${skeletonFakeLight} 80px)`,
    animation: 'shine 1.6s infinite linear',
    backgroundSize: '600px',
    opacity: '90%',
  },
  bodyBlock: {
    marginTop: '4px',
    height: '19px',
    width: '70vw',
    borderRadius: '8px',
    backgroundColor: skeletonFake,
    backgroundImage: `linear-gradient(90deg, ${skeletonFake} 0px, ${gradientColor} 40px, ${skeletonFake} 80px)`,
    animation: 'shine 1.6s infinite linear',
    backgroundSize: '600px',
    opacity: '90%',
  },
});
export default styles;
