export default (theme) => ({
  container: {
    '& .crc-MuiDialogTitle-root': {
      display: 'none',
    },
    '& .crc-MuiDialogActions-root': {
      display: 'none',
    },
    '& .crc-MuiPaper-root': {
      [theme.breakpoints.down(theme.spacing.unit * 75.4)]: {
        maxWidth: '100%',
        minHeight: '100%',
        margin: 0,
      },
    },
    '& .crc-MuiDialogContent-root': {
      width: `${theme.spacing.unit * 62.5}px`,
      paddingTop: `${theme.spacing.unit * 2}px`,
      marginLeft: 0,
      marginRight: 0,
      marginTop: `${theme.spacing.unit}px`,
      [theme.breakpoints.down(theme.spacing.unit * 75.4)]: {
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
  },
});
