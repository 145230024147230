import React from 'react';
import Accordion from '../../Commons/Accordion';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipTypography } from '@clipmx/clip-storybook';
import ScrollTopContainer from '../../Commons/ScrollTopContainer';
import styles from './styles';

const useStyles = makeStyles(styles);

const PrivacyPolicy = (props) => {
    const classes = useStyles();
    const { flags: {
        financialTandemPrivacyPolicyWeb,
    } } = props;

    return (
        <ScrollTopContainer>
            <ClipTypography fontWeight="semiBold" variant="h2" className={classes.title}>{financialTandemPrivacyPolicyWeb.title}</ClipTypography>
            <ClipTypography variant="body1">{financialTandemPrivacyPolicyWeb.subtitle}</ClipTypography>
            <Accordion showIndex={false} data={financialTandemPrivacyPolicyWeb.privacyPolicy} />
        </ScrollTopContainer>
    )
}

export default withLDConsumer()(PrivacyPolicy);