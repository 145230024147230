export const styles = (theme) => {
  const imageStyles = {
    banner: {
      display: 'block !important',
      margin: '0 auto !important',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  };
  const typographyStyles = {
    typographyRoot: {
      margin: `${theme.spacing.unit * 3}px auto`,
      textAlign: 'center',
      color: '#001724',
    },
    typographyHeadline: {
      fontSize: `${theme.spacing.unit * 2.5}px`,
      fontWeight: 600,
    },
    typographyBody1: {
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 400,
    },
  };
  const buttonContainerStyles = {
    buttonContainer: {
      margin: '0 auto',
      marginTop: `${theme.spacing.unit * 5}px`,
      marginBottom: `${theme.spacing.unit * 5}px`,
      width: '30%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  };

  return { ...imageStyles, ...typographyStyles, ...buttonContainerStyles };
};
