export const SET_TOAST_STATE = 'SET_TOAST_STATE';

export const openSnackBar = (
  severity,
  message,
  textAlign = 'left',
  variant = 'clip',
) => {
  return {
    type: SET_TOAST_STATE,
    payload: { open: true, severity, message, textAlign, variant },
  };
};

export const handleClose = () => {
  return {
    type: SET_TOAST_STATE,
    payload: { open: false },
  };
};

export const setPosition = (position) => {
  return {
    type: SET_TOAST_STATE,
    payload: { position },
  };
};
