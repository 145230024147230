import urls  from 'helpers/urls';
import { loansClipPhone as ClipPhone } from 'constants/AppConstants';

export default {
    contactWriteUs: 'Escríbenos en',
    contactWhatsaap: 'Whatsapp',
    contactExternalMessageAdvice: 'Llámanos al',
    contactMessageNumber: '55 6393-2323', 
    contactWeb: 'clip.mx',
    contactMobile: ` ${ClipPhone} `,
    contactEmial: 'Envía un correo a',
    contactUrlEmail: `${urls.clipHelpEmail}`,
    contactClipMx: 'clip.mx.',
    contactChat: 'o escríbenos en el chat de',
  };