import { LoansDocuments } from 'constants/AppConstants';

export default {
  title: 'Identidad',
  titleV4: 'Tu identidad',
  description: `Necesitamos algunos datos para comprobar tu identidad y verificar que en verdad eres 
    tú quien solicita Credi Clip.`,
  documentTypeTitle: 'Tipo de identificación',
  documentTypeSection:
    'Selecciona el documento de identificación que usarás para realizar tu solicitud:',
  documentTypeOptions: [
    {
      id: LoansDocuments.ineDocumentType,
      name: 'Credencial INE',
    },
    {
      id: LoansDocuments.passportDocumentType,
      name: 'Pasaporte',
    },
  ],
  documentTypeOptionsV4: [
    {
      id: LoansDocuments.ineDocumentType,
      name: 'INE / IFE',
    },
    {
      id: LoansDocuments.passportDocumentType,
      name: 'Pasaporte',
    },
  ],
  contactMethodOptions: [
    {
      id: 'CALL',
      name: 'Llamada',
    },
    {
      id: 'SMS',
      name: 'SMS',
    },
    {
      id: 'WHATSAPP',
      name: 'WhatsApp',
    },
  ],
  documentSectionINE:
    'Sube tu INE por ambos lados, los datos deben verse claramente y no estar expirada.',
  documentSectionINEV4:
    'Sube tu INE por ambos lados, los datos deben verse claramente y no estar expirada.',
  documentSectionPassport:
    'Sube una copia de tu pasaporte:',
  documentSectionPassportV4:
    'Sube una copia de tu pasaporte, los datos deben verse claramente.',
  subtitleIRC: 'Ingresa tu clave de elector INE',
  subtitlePRC: 'Ingresa número de pasaporte',
  subtitleRFC: 'Ingresa tu RFC:',
  identificationTitleINE: 'Clave de elector INE',
  identificationTitlePassport: 'Número de pasaporte',
  identificationTitleRFC: 'RFC',
  identificationTitleCURP: 'CURP',
  personalInformationSection: 'Ingresa los datos de tu INE',
  personalInformationPassportSection: 'Ingresa los datos de tu pasaporte',
  personalInformationName: 'Nombre',
  personalInformationLastName: 'Apellido paterno',
  personalInformationSecondLastName: 'Apellido materno',
  personalInformationBirthday: 'Fecha de nacimiento',
  personalInformationDay: 'Dia',
  personalInformationMonth: 'Mes',
  personalInformationYear: 'Año',
  genderSection: 'Elige tu género',
  genderOptions: [
    {
      id: 'F',
      name: 'Femenino',
    },
    {
      id: 'M',
      name: 'Masculino',
    },
  ],
  maritalStatusSection: '¿Cuál es tu estado civil?',
  selectPlaceholder: 'Elige una opción',
  maritalOptions: [
    {
      id: 'Soltero(a)',
      name: 'Soltero(a)',
    },
    {
      id: 'Casado(a)',
      name: 'Casado(a)',
    },
    {
      id: 'Casado(a) con bienes mancomunados',
      name: 'Casado(a) con bienes mancomunados',
    },
    {
      id: 'Casado(a) con bienes separados',
      name: 'Casado(a) con bienes separados',
    },
    {
      id: 'Viudo(a)',
      name: 'Viudo(a)',
    },
    {
      id: 'Union Libre',
      name: 'Union Libre',
    },
    {
      id: 'Divorciado',
      name: 'Divorciado',
    },
    {
      id: 'Separado',
      name: 'Separado',
    },
  ],
  monthsOptions: [
    {
      id: '1',
      name: 'Enero',
    },
    {
      id: '2',
      name: 'Febrero',
    },
    {
      id: '3',
      name: 'Marzo',
    },
    {
      id: '4',
      name: 'Abril',
    },
    {
      id: '5',
      name: 'Mayo',
    },
    {
      id: '6',
      name: 'Junio',
    },
    {
      id: '7',
      name: 'Julio',
    },
    {
      id: '8',
      name: 'Agosto',
    },
    {
      id: '9',
      name: 'Septiembre',
    },
    {
      id: '10',
      name: 'Octubre',
    },
    {
      id: '11',
      name: 'Noviembre',
    },
    {
      id: '12',
      name: 'Diciembre',
    },
  ],
  ineHelp: 'Ayuda',
  ine: 'INE',
  titleTooltip: 'Tu clave de elector está sobre el anverso de tu INE',
  contactInformationSection: 'Número de teléfono',
  contactInformationPhone: 'Número de teléfono a 10 dígitos',
  contactMethodSection: 'En caso de ser necesario, ¿cómo prefieres que te contactemos?',
  errorCustomPhone: 'Introduce un número de teléfono válido',
  errorContactMethod: 'Selecciona una de las opciones de contacto',
};
