export default {
  expiration: (expirationDate) => `
    <b>Vigencia:</b> Tienes hasta el <b>${expirationDate}</b> para solicitar tu Adelanto de Ventas Clip
  `,
  expirationOffer: (expirationDate) =>
    `Oferta por tiempo limitado. Solicita tu préstamo antes del ${expirationDate} `,
  amountTitles: ['Crece tu negocio', 'Renuévate', 'Nuevos clientes'],
  amountSubtitles: [
    'Renuévate y date a conocer',
    'Invierte en nuevos productos',
    'Promocionate en redes sociales',
  ],
  maxLimit: 'Plazo máximo:',
  fixedCharge: 'Cargo fijo + IVA: ',
  total: 'Total a pagar: ',
  refund: 'Forma de pago: ',
  months: 'meses',
  dailySales: 'de tus ventas diarias',
  buttonAmmount: 'Solicitar ',
  buttonSelected: 'Seleccionada',
  recommendedLabel: 'Recomendado',
  loanPersonalized: (username) => `${username}, personaliza tu préstamo`,
  loanDetails: 'Detalles del préstamo',
  terms: 'Plazos',
  mount: 'Monto',
  monthlySales: 'En cada venta descontaremos este % para pagar tu préstamo',
  totalAmount: 'Préstamo por',
  fixedPaymentMcap: 'Pago mensual fijo*',
  fixedPaymentDetailsMcap: (retention) => `*Se descontará el ${retention}% de tus ventas diarias 
    hasta cubrir el pago mensual fijo.`,
};
