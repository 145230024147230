import styled from '@emotion/styled';
import { Field } from 'redux-form';
import { SubmitButton as CommonSubmitButton } from 'components/common/Buttons';

export const IdentificationsContainer = styled.div(({ theme }) => ({
  marginTop: theme.spacing.unit * 4,
  background: 'white',
  width: '20.5rem',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
}));

export const IdentificationsFormContainer = styled.div(() => ({
  background: 'white',
  width: '20.5rem',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
}));

export const VerifyData = styled.div(({ theme }) => ({
  width: '20.5rem',
  height: '3.625rem',
  fontSize: '1.5rem',
  lineHeight: '1.625rem',
  color: theme.palette.text.primary,
  textAlign: 'justify',
}));

export const AcceptContainer = styled.div(({ theme }) => ({
  width: '20.5rem',
  height: 'auto',
  fontSize: '0.875rem',
  lineHeight: 'normal',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.spacing.unit * 2,
}));

export const AcceptContainerText = styled.div(({ theme }) => ({
  width: '18.5rem',
  height: '2.125rem',
  fontSize: '0.875rem',
  lineHeight: 'normal',
  color: theme.palette.text.auxiliar,
}));

export const AcceptContainerImage = styled.img(() => ({
  width: '0.75rem',
  height: '0.938rem',
  objectFit: 'contain',
  marginRight: '0.875rem',
}));

export const VerifyDataDescription = styled.div(({ theme }) => ({
  marginTop: theme.spacing.unit * 0.5,
  width: '20.5rem',
  height: 'auto',
  fontSize: '1rem',
  lineHeight: '1.31rem',
  color: theme.palette.text.primary,
  textAlign: 'justify',
}));

export const IdentificationRadioLabel = styled.label((props) => ({
  marginTop: '0.5rem',
  width: '20.5rem',
  height: '3.125rem',
  fontSize: '1rem',
  lineHeight: '3.125rem',
  borderRadius: '12px',
  background: props.checked ? '#feede6' : 'white',
  marginBottom: '1rem',
  alignSelf: 'center',
}));

export const FormField = styled(Field)(({ theme }) => ({
  marginTop: theme.spacing.unit * 2.8,
  width: '20.5rem',
}));

const BaseButtonStyles = ({ theme }) => ({
  marginTop: theme.spacing.unit,
  width: '20.5rem',
  marginBottom: theme.spacing.unit * 1.7,
});

export const SubmitButton = styled(CommonSubmitButton)(BaseButtonStyles);

export const WhiteBackgroundReminder = styled.div(() => ({
  width: '20.5rem',
  height: '4rem',
  color: '#0288d1',
  alignSelf: 'center',
  textAlign: 'left',
  borderRadius: '2px',
  border: 'solid 1 px rgba(2, 136, 209, 0.15)',
  backgroundColor: 'rgba(2, 136, 209, 0.05)',
  marginTop: '0.5rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const WhiteBackgroundReminderText = styled.div(() => ({
  width: '15rem',
  height: 'auto',
  fontSize: '0.875rem',
  color: '#0288d1',
  alignSelf: 'center',
  textAlign: 'left',
  lineHeight: '1.125rem',
}));

export const LightbulbIcon = styled.img(() => ({
  width: '1.5rem',
  height: '1.5rem',
  objectFit: 'contain',
  marginLeft: '1rem',
  marginRight: '1rem',
}));
