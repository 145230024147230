import React, { PureComponent, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useState } from 'react';
import HelpNeedBlue from './helpNeedBlue';
import ModalHelpNeed from './modalHelpNeed';
import urls from 'helpers/urls';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { EVENTS } from '../../../../helpers/Clevertap';
import * as Styled from './styled';
import text from './text';
import { Event } from '@material-ui/icons';
import { setTopupSettlementTypeState } from 'components/BusinessTools/redux/actions';

class RefillFooterBase extends PureComponent {
  render() {

    const isMobileWebView = this.props.location.pathname.startsWith(urls.mobile);

    return (
      <Styled.RefillContactContainer>
        <Styled.ExternalMessage>
          {text.contactExternalMessageAdvice}
          <b>{text.timeMessage}</b>
        </Styled.ExternalMessage>
        <Styled.DoubleMessageSeparator />
        {!isMobileWebView && (
          <React.Fragment>
            <Styled.ExternalMessage>
              {text.contactExternalMessagePart2}
              <ClevertapComponent
                renderComponent={<a />}
                events={[
                  {
                    callbackName: 'onClick',
                    event: EVENTS.LOANS.PHONECUSTOMER,
                  },
                ]}
                href={urls.clipPhone}
                target={'_blank'}
              >
                {text.contactMessageNumber}.
              </ClevertapComponent>
            </Styled.ExternalMessage>
            <Styled.ExternalMessage>
              {text.contactExternalMessagePart3}
              <ClevertapComponent
                renderComponent={<a />}
                events={[
                  {
                    callbackName: 'onClick',
                    event: EVENTS.LOANS.MAILCUSTOMER,
                  },
                ]}
                href={`mailto:${urls.clipHelpEmail}`}
                target={'_blank'}
              >
                {urls.clipHelpEmail}
              </ClevertapComponent>
              {text.contactExternalMessagePart4}
            </Styled.ExternalMessage>
          </React.Fragment>
        )}
        {isMobileWebView && (
          <Styled.ExternalMessage>
            {text.contactExternalMessageMobile}
          </Styled.ExternalMessage>
        )}
      </Styled.RefillContactContainer>
    );
  }
}

export const RefillFooter = withRouter(RefillFooterBase);



class ExternalLenderFooterBase extends PureComponent {
  render() {
    return (
      <HelpNeedBlue />
    )
  }
}


export const ExternalLenderFooter = withRouter(ExternalLenderFooterBase);

class Footer extends PureComponent {
  render() {
    const isMobileWebView = this.props.location.pathname.startsWith(urls.mobile);
    return (
      <Styled.ContactContainer>
        <Styled.Message>
          {text.contactExternalMessageAdvice}
          <b>{text.timeMessage}</b>
        </Styled.Message>
        <Styled.MessageSeparator />
        {!isMobileWebView && (
          <React.Fragment>
            <Styled.Message>
              {text.contactExternalMessagePart2}
              <ClevertapComponent
                renderComponent={<a />}
                events={[
                  {
                    callbackName: 'onClick',
                    event: EVENTS.LOANS.PHONECUSTOMER,
                  },
                ]}
                href={urls.clipPhone}
                target={'_blank'}
              >
                {text.contactMessageNumber}
              </ClevertapComponent>
            </Styled.Message>
            <Styled.Message>
              {text.contactExternalMessagePart3}
              <ClevertapComponent
                renderComponent={<a />}
                events={[
                  {
                    callbackName: 'onClick',
                    event: EVENTS.LOANS.MAILCUSTOMER,
                  },
                ]}
                href={`mailto:${urls.clipHelpEmail}`}
                target={'_blank'}
              >
                {urls.clipHelpEmail}
              </ClevertapComponent>
              {text.contactExternalMessagePart4}
            </Styled.Message>
          </React.Fragment>
        )}
        {isMobileWebView && (
          <Styled.Message>{text.contactExternalMessageMobile}</Styled.Message>
        )}
      </Styled.ContactContainer>
    );
  }
}

export default withRouter(Footer);