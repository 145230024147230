export const styles = (theme) => {
  const selectStyles = {
    formControlRoot: {
      backgroundColor: theme.palette.common.white,
      '& > div': {
        marginTop: theme.spacing.unit,
      },
    },
    selectRoot: {
      height: `${theme.spacing.unit * 5.75}px`,
      marginTop: `-${theme.spacing.unit}px`,
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      boxShadow: '0 0 0 1px #ccd0d3',
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 500,
      color: '#001724',
    },
    selectSelect: {
      height: `${theme.spacing.unit * 6}px`,
    },
    selectMenu: {
      height: `${theme.spacing.unit * 2.75}px`,
      padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px
      ${theme.spacing.unit * 0.25}px ${theme.spacing.unit}px`,
      textAlign: 'left',
      fontSize: theme.spacing.unit * 2,
    },
    selectIcon: {
      color: '#99A2A7',
      marginRight: `${theme.spacing.unit}px`,
    },
    selectError: {
      paddingLeft: `${theme.spacing.unit * 2}px`,
      paddingRight: `${theme.spacing.unit * 2}px`,
      boxShadow: '0 0 0 1px rgb(168, 9, 60)',
    },
    selectDisabled: {
      backgroundColor: 'rgba(248, 248, 248, 0.5)',
    },
  };

  const labelStyles = {
    labelRoot: {
      marginTop: -theme.spacing.unit,
      paddingLeft: `${theme.spacing.unit * 2}px`,
      fontSize: `${theme.spacing.unit * 1.5}px`,
      color: '#66737b!important',
    },
    labelShrink: {
      transform: 'translate(4.5px, 18px) scale(0.75)',
      transformOrigin: 'top left',
    },
  };

  const errorStyles = {
    errorRoot: {
      display: 'flex',
      alignItems: 'center',
      width: `calc(100% - ${theme.spacing.unit * 2}px)`,
      margin: '0 auto',
      padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit}px`,
      minHeight: theme.spacing.unit * 3.25,
      borderBottomLeftRadius: `${theme.spacing.unit * 0.5}px`,
      borderBottomRightRadius: `${theme.spacing.unit * 0.5}px`,
      backgroundColor: '#f7e8e8',
      fontSize: '0.875rem',
      color: '#b11518!important',
    },
  };

  const menuItemStyles = {
    menuItemRoot: {
      fontSize: `${theme.spacing.unit * 1.75}px`,
      color: '#66737b!important',
    },
    menuItemSelected: {
      color: '#001724!important',
      border: `solid ${theme.spacing.unit * 0.25}px ${
        theme.palette.orange.base
      }`,
      backgroundColor: `${theme.palette.common.white}!important`,
    },
  };

  return { ...selectStyles, ...labelStyles, ...errorStyles, ...menuItemStyles };
};
