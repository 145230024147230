import {
  GET_LASTONE,
  SET_LASTONE_DOCUMENTATION,
  SET_PREFILLED_FORM,
  SET_PREFILLED_COLONIES,
  SET_PREFILLED_DAYS,
  GET_PREAPPROVALS,
  GET_LOANS,
  GET_COLLECTIONS,
  SEND_REQUEST_LOAN,
  LOAD_REQUEST_FORM,
  UPLOAD_DOCUMENTS,
  DELETE_DOCUMENT,
  DELETE_DOCUMENTS_NOT_SAVED,
  SET_PREAPPROVAL_SELECTED,
  SET_MERCHANT_LOANS_COLONIES,
  SET_MERCHANT_LOANS_DAYS,
  CLEAN_PREAPPROVAL,
  CLEAN_LOANS,
  DRAG_FILE_TURNON,
  DRAG_FILE_TURNOFF,
  SENDING_FORM,
  CLEAN_IDENTITY,
  CLEAN_IDENTITY_BACK,
  CLEAN_PASSPORT,
  CLEAN_PROOF_OF_ADDRESS,
  SET_CONFIRM_ADDRESS,
  SET_LOANS_LOADED,
  SET_PRESTA_CLIP_NAME_CHANGE,
  SET_SHOW_CASH_ADVANCE,
  REFILL_REQUESTED,
  SET_LOANS_FORM_VERSION,
  SET_LOANS_REGULAR_OFFERS_VERSION,
  SET_LOANS_FORM_URL,
  SET_SHOW_LOANS_TESTING_FORM,
  SET_LD_FLAGS_READY,
  SET_AMOUNT_SENT_ELEGIBLE,
  SET_DISMISS_OFFER_ALERT,
  SET_SHOW_OFFER_ALERT,
} from './types';

export const setLoansLoadedSuccess = () => ({
  type: SET_LOANS_LOADED,
});

export const setConfirmAddressSuccess = (confirmAddress) => ({
  type: SET_CONFIRM_ADDRESS,
  payload: { confirmAddress },
});

export const setSendingFormSuccess = (sendingForm) => ({
  type: SENDING_FORM,
  payload: { sendingForm },
});

export const getLastOneSuccess = (lastOne) => ({
  type: GET_LASTONE,
  payload: { lastOne },
});

export const setLastOneDocumentationSuccess = (documents) => ({
  type: SET_LASTONE_DOCUMENTATION,
  payload: { documents },
});

export const setPrefilledFormSuccess = (prefilledForm) => ({
  type: SET_PREFILLED_FORM,
  payload: { prefilledForm },
});

export const setPrefilledColoniesSuccess = (colonies) => ({
  type: SET_PREFILLED_COLONIES,
  payload: { colonies },
});

export const setPrefilledDaysSuccess = (days) => ({
  type: SET_PREFILLED_DAYS,
  payload: { days },
});

export const setPreapprovalSuccess = (preapproval) => ({
  type: SET_PREAPPROVAL_SELECTED,
  payload: { preapproval },
});

export const setShowChangeOfferAlert = () => ({
  type: SET_SHOW_OFFER_ALERT,
});

export const setDismissChangeOfferAlert = () => ({
  type: SET_DISMISS_OFFER_ALERT,
});

export const getPreapprovalsSuccess = (preapprovals) => ({
  type: GET_PREAPPROVALS,
  payload: { preapprovals },
});

export const getLoansSuccess = (loans) => ({
  type: GET_LOANS,
  payload: { loans },
});

export const getCollectionsSuccess = (id, collections) => ({
  type: GET_COLLECTIONS,
  payload: { id, collections },
});

export const requesLoanSentSuccess = () => ({
  type: SEND_REQUEST_LOAN,
});

export const loadRequestFormSuccess = (request) => ({
  type: LOAD_REQUEST_FORM,
  payload: { request },
});

export const cleanPreapproval = () => ({ type: CLEAN_PREAPPROVAL });

export const cleanIdentity = () => ({ type: CLEAN_IDENTITY });
export const cleanIdentityBack = () => ({ type: CLEAN_IDENTITY_BACK });
export const cleanPassport = () => ({ type: CLEAN_PASSPORT });
export const cleanProofOfAddress = () => ({ type: CLEAN_PROOF_OF_ADDRESS });

export const cleanLoansSuccess = () => ({ type: CLEAN_LOANS });

export const uploadDocumentSuccess = (payload) => ({
  type: UPLOAD_DOCUMENTS,
  payload,
});

export const deleteDocumentSuccess = (payload) => ({
  type: DELETE_DOCUMENT,
  payload,
});

export const deleteDocumentsNotSavedSuccess = (payload) => ({
  type: DELETE_DOCUMENTS_NOT_SAVED,
  payload,
});

export const turnOnDragFileSuccess = () => ({
  type: DRAG_FILE_TURNON,
});

export const turnOffDragFileSuccess = () => ({
  type: DRAG_FILE_TURNOFF,
});

export const getColoniesSuccess = (colonies) => ({
  type: SET_MERCHANT_LOANS_COLONIES,
  payload: { colonies },
});

export const getDaysSuccess = (days) => ({
  type: SET_MERCHANT_LOANS_DAYS,
  payload: { days },
});

export const setLoansFormVersion = (formVersion) => ({
  type: SET_LOANS_FORM_VERSION,
  payload: formVersion,
});

export const setLoansRegularOfferVersion = (offerVersion) => ({
  type: SET_LOANS_REGULAR_OFFERS_VERSION,
  payload: offerVersion,
});

export const setLoansFormURL = (formURL) => ({
  type: SET_LOANS_FORM_URL,
  payload: formURL,
});

export const setPrestaClipNameChange = (prestaClipNameChange) => ({
  type: SET_PRESTA_CLIP_NAME_CHANGE,
  payload: prestaClipNameChange,
});

export const setShowCashAdvance = (showCashAdvanceInfo) => ({
  type: SET_SHOW_CASH_ADVANCE,
  payload: showCashAdvanceInfo,
});

export const requesRefillSuccess = (preapprovalId) => ({
  type: REFILL_REQUESTED,
  payload: preapprovalId,
});

export const setShowLoansTestingForm = (showTestingForm) => ({
  type: SET_SHOW_LOANS_TESTING_FORM,
  payload: showTestingForm,
});

export const setLDFlagsReadySuccess = () => ({
  type: SET_LD_FLAGS_READY,
});

export const setAmountSentForElegibleSuccess = (status) => ({
  type: SET_AMOUNT_SENT_ELEGIBLE,
  payload: status,
});
