import React, { Fragment, useEffect } from 'react';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import FormControl from '@clipmx/clip-storybook/dist/components/FormControl';
import LockIcon from '@material-ui/icons/Lock';
import { getPrestaClipNameChange } from '../../redux/selectors';
import {
  Forms,
  LoansCurrentProductName,
  LoansNewProductName,
} from '../../../../constants/AppConstants';

import {
  isAlphanumeric,
  maxLength,
  isEmpty,
  isValidRFC,
  requiredRfc,
} from '../../../../helpers/validate';
import { loadBankAccountInformation } from '../../../account/redux/actions';
import { ClipGrid } from '@clipmx/clip-storybook';
import TextField from '../../../../helpers/FormUtils/TextField';
import { isInvalidFormField } from '../../../../helpers/FormUtils';
import urls from '../../../../helpers/urls';
import ClevertapField from '../../../forms/ClevertapField';
import { EVENTS } from '../../../../helpers/Clevertap';
import { lenderPayload } from '../../Commons/Clevertap';

import styles from './styles';
import text from './text';
import PropTypes from 'prop-types';

export const DepositStep = ({
  rfc,
  curp,
  handleDepositStep,
  bankAccountNumber,
  bankName,
  owner,
  prestaClipNameChange,
  loadBankAccountInformation,
  preapprovalSelected,
  showCashAdvance,
  loansFormVersion,
}) => {
  let location = useLocation();
  const classes = styles();
  const isMobileWebView = location.pathname.startsWith(urls.mobile);

  const enableSubmitButton = () => {
    let submitButtonEnabled = true;

    if (rfc && rfc !== '' && isInvalidFormField(rfc)) {
      submitButtonEnabled = false;
    }

    handleDepositStep?.(submitButtonEnabled);
  };

  useEffect(() => {
    enableSubmitButton();
    if (isEmpty(bankAccountNumber)) {
      loadBankAccountInformation();
    }
  }, [rfc, curp, bankAccountNumber, bankName, owner]);

  const renderNewProductName = (prevText) => {
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  return (
    <Fragment>
      <ClipGrid container spacing={2}>
        <div className={classes.StepTitleStyles}>{text.title}</div>
        <FormControl className={classes.FormControlStyles} fullWidth>
          <ClevertapField
            name="rfc"
            component={TextField}
            inputProps={{ maxLength: 13 }}
            type="text"
            label={text.rfcLabel}
            validate={[requiredRfc, isAlphanumeric, isValidRFC]}
            helperText={maxLength(13)(rfc)}
            clevertap={{
              listener: 'onChange',
              event: EVENTS.LOANS.RFC_CHANGED,
              payload: lenderPayload(preapprovalSelected, {
                showCashAdvance,
                loansFormVersion,
              }),
            }}
          />
        </FormControl>

        <div className={classes.StepDescriptionStyles}>
          {renderNewProductName(text.confirmSection)}
        </div>
        <div className={classes.StepSectionStyles}>
          {!isMobileWebView && (
            <Fragment>
              {text.confirmSubsection}{' '}
              <a href={text.dataAdviceLink}>{text.confirmSubsectionRef}</a>
            </Fragment>
          )}
          {isMobileWebView && text.dataAdvice}
        </div>
        <FormControl className={classes.FormControlStyles} fullWidth>
          <Field
            name="ownerCLABE"
            component={TextField}
            inputProps={{ readOnly: true }}
            type="text"
            label={text.ownerLabel}
            props={{ value: owner }}
            meta={{ touched: true }}
            endAdornment={<LockIcon />}
            disabled
          />

          <Field
            name="bankCLABE"
            component={TextField}
            inputProps={{ readOnly: true }}
            type="text"
            label={text.bankLabel}
            props={{ value: bankName }}
            meta={{ touched: true }}
            endAdornment={<LockIcon />}
            disabled
          />

          <Field
            name="interbankCLABE"
            component={TextField}
            inputProps={{ readOnly: true }}
            type="text"
            label={text.clabeLabel}
            props={{ value: bankAccountNumber }}
            meta={{ touched: true }}
            endAdornment={<LockIcon />}
            disabled
          />
        </FormControl>
      </ClipGrid>
    </Fragment>
  );
};

const selector = formValueSelector(Forms.MR_PRESTA);

const mapStateToProps = (state) => {
  return {
    curp: selector(state, 'curp'),
    rfc: selector(state, 'rfc'),
    bankAccountNumber: state.accountInfo.bankInfo.bankAccountNumber,
    bankName: state.accountInfo.bankInfo.bankName,
    owner: state.accountInfo.bankInfo.bankAccountName,
    prestaClipNameChange: getPrestaClipNameChange(state),
    preapprovalSelected: state.loans?.request?.preapproval,
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  loadBankAccountInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositStep);

DepositStep.propTypes = {
  loadBankAccountInformation: PropTypes.func.isRequired,
  handleDepositStep: PropTypes.func.isRequired,
  prestaClipNameChange: PropTypes.bool.isRequired,
};
