import { apiSendData } from './request';
import {
  getActionTypeInfo,
  createSuccessResponse,
  createErrorResponse,
  isFunction,
} from './reduxUtils';
import {
  dataLoading,
  dataLoaded,
  dataLoadFailed,
} from '../../actions/AppActions';
import { setResourceState } from '../../actions/resourceAction';

function processMiddleware(action, store, actionInfo) {
  const {
    payload: {
      route,
      query,
      data = {},
      headers = {},
      success: successAction,
      failure: failureAction,
      apiEndPoint,
      token,
      autoSpinner,
    },
  } = action;
  function success(res) {
    if (autoSpinner)
      store.dispatch(dataLoaded(actionInfo.responseActionType, res));
    store.dispatch(setResourceState(actionInfo.resource, false));
    store.dispatch(createSuccessResponse(actionInfo, res));
    if (isFunction(successAction)) {
      successAction(res, store);
    }
  }

  function failure(error) {
    if (autoSpinner)
      store.dispatch(dataLoadFailed(actionInfo.responseActionType, error));
    store.dispatch(createErrorResponse(actionInfo, error.response));
    store.dispatch(setResourceState(actionInfo.resource, false));
    if (isFunction(failureAction)) {
      failureAction(error, store);
    }
    throw error;
  }

  if (autoSpinner) store.dispatch(dataLoading(actionInfo.responseActionType));
  store.dispatch(setResourceState(actionInfo.resource, true));
  apiSendData(
    actionInfo.verb.toLowerCase(),
    route,
    query,
    data,
    headers,
    apiEndPoint,
    token,
  ).then(success, failure);
}

const apiResolveMiddleware = (store) => (next) => (action) => {
  const actionInfo = getActionTypeInfo(action.type);
  if (actionInfo.correctActionType === true) {
    processMiddleware(action, store, actionInfo);
  }
  next(action);
};

export default apiResolveMiddleware;
