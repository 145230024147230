import React, { Fragment, useContext } from 'react';
import { connect } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import urls from '../../../helpers/urls';
import getUrl from '../../../helpers/getUrl';
import {
  getMerchantState,
  getDashboardState,
  getIsMerchantOnboarding,
  getIsSessionExpired,
  getIsUnauthorized,
  getIsLoggedIn,
  getUserVerified,
  getIsLoading,
} from '../../../helpers/ReduxSelectors';
import {
  Clevertap,
  createPayloadForClevertap,
} from '../../../helpers/Clevertap';
import {
  DashboardStates,
  MerchantStates,
} from '../../../constants/AppConstants';
import { getOnboardingActiveState } from '../../onboarding/redux/selectors';
import { setLDFlagsReadySuccess } from '../../loans/redux/loansActions';
import Header from '../../../components/common/Header';
import Footer from '../../../components/common/Footer';
import { logoutUser } from '../redux/actions';
import { ContainerContext } from '../../../routes';
import * as Styled from './styled';
import { getConfigValue } from '../../../config/config';
// import ErrorBoundary from 'helpers/ErrorBoundary';

const DefaultLayout = ({ children }) => <Fragment>{children}</Fragment>;

const initializeLDClient = (restOfProps, ldCalled, setLdCalled) => {
  const { ldClient, merchantInfo, setLDFlagsReadySuccess } = restOfProps;
  let os;
  if (/Android/i.test(navigator.userAgent)) {
    os = 'android';
  } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    os = 'ios';
  } else {
    os = 'web';
  }
  if (
    !ldCalled &&
    merchantInfo.proxy_id &&
    (ldClient.getUser().anonymous ||
      ldClient.getUser().key !== merchantInfo.proxy_id.proxy_merchant_token)
  ) {
    ldClient.identify(
      {
        key: merchantInfo.proxy_id.proxy_merchant_token,
        custom: {
          webviewOS: os,
        },
      },
      null,
    );
    setTimeout(function () {
      setLDFlagsReadySuccess();
    }, 200);
    setLdCalled(true);
  }
};

const initializeClevertap = (
  restOfProps,
  clevertapCalled,
  setClevertapCalled,
) => {
  const { merchantInfo, user } = restOfProps;
  if (!clevertapCalled && merchantInfo.proxy_id && user) {
    Clevertap.profile(user);
    setClevertapCalled(true);
  }
};

const getCurrentOnboardingRoute = (merchantState) => {
  if (merchantState === MerchantStates.VALIDATED) {
    return urls.PERSONAL_INFO;
  }
  if (merchantState === MerchantStates.MERCHANT_INFORMATION) {
    return urls.BUSINESS_INFO;
  }
  if (merchantState === MerchantStates.PREACTIVE) {
    return urls.WELCOME;
  }
};

const authenticateUser = (restOfProps, location, history) => {
  if (restOfProps.isLoading) {
    return;
  }

  const path = location.pathname;
  const queryString = location.search;
  const newPath = encodeURIComponent(path + queryString);
  const validationPath = getUrl(urls.REGISTRATION_VALIDATION_HELP);
  const {
    isLoggedIn,
    isSessionExpired,
    isUnauthorized,
    dashboardState,
    isVerified,
    isMerchantOnboarding,
    merchantState,
  } = restOfProps;

  if (!isLoggedIn) {
    return history.push(getUrl(urls.signIn + '?path=' + newPath));
  }

  if (isSessionExpired) {
    return restOfProps.logoutUser();
  }

  if (isUnauthorized) {
    return history.push(getUrl(urls.dashboard));
  }

  const validationUrlRegex = new RegExp(`^${validationPath}/*`, 'g');

  if (
    !isVerified &&
    path !== validationPath &&
    dashboardState !== DashboardStates.MERCHANT_COMPLETED
  ) {
    return validationUrlRegex.test(path) ? null : history.push(validationPath);
  }

  if (isVerified && isMerchantOnboarding) {
    // should reach this point only if merchant hasn't completed onboarding process
    // allow user to redeem promo or see email validation loading without redirecting
    if (
      path === getUrl(urls.redeemPromo) ||
      path === getUrl(urls.REGISTRATION_VALIDATION)
    ) {
      return;
    }

    const url = getCurrentOnboardingRoute(merchantState);

    const activeOnboardingPath = getUrl(url);

    if (path !== activeOnboardingPath) {
      return history.push(activeOnboardingPath);
    }
  }
};

const AuthControllerNew = ({
  component: Component,
  layout: Layout = DefaultLayout,
  subLayout: SubLayout = DefaultLayout,
  clevertap,
  flags,
  ...restOfProps
}) => {
  let location = useLocation();
  let history = useHistory();
  const path = location.pathname;
  const { setIsNewLayout } = useContext(ContainerContext);
  const [ldCalled, setLdCalled] = React.useState(false);
  const [clevertapCalled, setClevertapCalled] = React.useState(false);
  const mdMfeRedesign = flags.mdMfeRedesignPilot;

  React.useEffect(() => {
    setIsNewLayout(!!restOfProps.title);
  }, [restOfProps.title, setIsNewLayout]);

  React.useEffect(() => {
    authenticateUser(restOfProps, location, history);
    initializeLDClient(restOfProps, ldCalled, setLdCalled);
    if (process.env.NODE_ENV !== 'test') {
      initializeClevertap(restOfProps, clevertapCalled, setClevertapCalled);
    }
  }, [clevertapCalled, history, ldCalled, location, restOfProps]);

  React.useEffect(() => {
    if (restOfProps.isLoggedIn) {
      if (mdMfeRedesign && location.pathname !== urls.myStoreWebview) {
        window.location.replace(
          `${getConfigValue('mfeContainerDomain')}/md${location.pathname}${
            location.search
          }`,
        );
        return null;
      }
    }
  }, [location.pathname, location.search, mdMfeRedesign, restOfProps]);

  React.useEffect(() => {
    let interval = null;
    if (clevertap?.event && clevertapCalled) {
      interval = setInterval(() => {
        Clevertap.event(
          clevertap.event,
          null,
          createPayloadForClevertap(clevertap?.payload),
        );
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [clevertap?.event, clevertapCalled]);
  const showContent =
    restOfProps.merchantState &&
    restOfProps.isLoggedIn &&
    (!mdMfeRedesign || restOfProps.isMobileWebView);

  return (
    <Route
      {...restOfProps}
      render={(props) => {
        return (
          <Styled.Container>
            {!restOfProps.isMobileWebView && <Header />}
            <Styled.Content maxWidth={restOfProps.maxWidth}>
              {showContent && (
                <Layout {...props} {...restOfProps}>
                  <SubLayout {...props} {...restOfProps}>
                    {/* <ErrorBoundary> */}
                    <Component {...props} {...restOfProps} />
                    {/* </ErrorBoundary> */}
                  </SubLayout>
                </Layout>
              )}
            </Styled.Content>
            {!restOfProps.isMobileWebView && <Footer path={path} />}
          </Styled.Container>
        );
      }}
    />
  );
};

function mapStateToProps(state) {
  return {
    isLoading: getIsLoading(state),
    merchantState: getMerchantState(state),
    activeOnboardingState: getOnboardingActiveState(state),
    dashboardState: getDashboardState(state),
    isLoggedIn: getIsLoggedIn(state),
    isSessionExpired: getIsSessionExpired(state),
    isUnauthorized: getIsUnauthorized(state),
    isVerified: getUserVerified(state),
    isMerchantOnboarding: getIsMerchantOnboarding(state),
    merchantInfo: state.merchant.info,
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  logoutUser,
  setLDFlagsReadySuccess,
})(withLDConsumer()(AuthControllerNew));
