const styles = (theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'absolute',
      textAlign: 'center',
      padding: '16px',
      boxSizing: 'border-box',
      overflowX: 'hidden',
      width: '100%',
      height: '100%',
    },
    okImg: {
      marginTop: '16px',
      width: '63px',
    },
    okImgForSE: {
      marginTop: '8px',
      width: '63px',
    },
    title: {
      fontWeight: 'bold',
      marginTop: '8px',
      fontSize: 24,
    },
    titleForSE: {
      fontWeight: 'bold',
      marginTop: '8px',
      fontSize: 18,
    },
    subTitle: {
      marginTop: '8px',
      fontSize: 18,
    },
    subTitleForSE: {
      marginTop: '8px',
      fontSize: 16,
    },
    info: {
      fontSize: 18,
      margin: '14px 35px 0 35px',
      marginBottom: '20px',
    },
    infoForSE: {
      margin: '8px 35px 0 35px',
      marginBottom: '18px',
      fontSize: 12,
    },
    LandingImage: {
      width: '19.5rem',
      height: '9.625rem',
      objectFit: 'contain',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    upgradeContainer: {
      justifyContent: 'center',
      marginTop: '27px',
      marginBottom: '23px',
      backgroundColor: '#F5F5F6',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
    },
    upgradeContainerForSE: {
      justifyContent: 'center',
      marginTop: '15px',
      marginBottom: '14px',
      backgroundColor: '#F5F5F6',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
    },
    color: {
      color: '#00707F',
      marginBottom: '10px',
    },
    colorSub: {
      color: '#FC4C02',
    },
    growAccount: {
      margin: '25px 20px 10px 20px',
      color: '#00707F',
    },
    growAccountSE: {
      margin: '10px 40px',
      color: '#00707F',
    },
    customerHappines: {
      margin: '0 30px 0px 30px',
      marginBottom: theme.spacing(3),
    },
    btnContainer: {
      width: '-moz-calc(100% - 2rem)',
      width: '-webkit-calc(100% - 2rem)',
      width: '-o-calc(100% - 2rem)',
      width: 'calc(100% - 2rem)',
      left: '1rem',
      marginBottom: '1rem',
    },
    btn: {
      width: '100%',
    },
  };
};
export default styles;
