import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AddIcon from '@material-ui/icons/Add';
import ClipIcon from 'assets/images/loans/clip-icon.svg';
import BankIcon from 'assets/images/loans/bank-icon.svg';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import text from './text';

class OpenDataPayment extends Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes, className, collapsable } = this.props;
    const { expanded } = this.state;

    const typographyStyles = {
      title: classes.typographyTitle,
      headline: classes.typographyTitleDesktop,
      subheading: classes.typographySubheading,
      body1: classes.typographyBody1,
      body2: classes.typographyBody2,
      display1: classes.typographyBod1Desktop,
      display2: classes.typographyTitle2,
    };
    const isDesktop = className === 'desktop';
    const desktopClass = isDesktop ? classes.cardDesktop : '';

    return (
      <Card className={desktopClass}>
        {isDesktop && (
          <Typography variant={'headline'} classes={typographyStyles}>
            {text.title}
          </Typography>
        )}
        {!isDesktop && collapsable && (
          <CardActions className={classes.cardActions} disableActionSpacing>
            <Typography variant={'title'} classes={typographyStyles}>
              {text.title}
            </Typography>
            <IconButton
              onClick={this.handleExpandClick}
              aria-expanded={expanded}
              aria-label="Show more"
              className={classes.iconExpand}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </CardActions>
        )}
        {!isDesktop && !collapsable && (
          <Typography variant={'display2'} classes={typographyStyles}>
            {text.title}
          </Typography>
        )}
        <Collapse in={expanded || !collapsable} timeout="auto" unmountOnExit>
          <CardContent className={classes.cardContent}>
            {!isDesktop && (
              <Typography variant={'body1'} classes={typographyStyles}>
                {text.firstParagraph}
              </Typography>
            )}
            {isDesktop && (
              <Typography variant={'display1'} classes={typographyStyles}>
                {text.firstParagraph}
              </Typography>
            )}
            <div className={classes.paymentsBorder}>
              <div className={classes.paymentsContent}>
                <div className={classes.paymentsPayment}>
                  <img
                    className={classes.paymentsImage}
                    src={ClipIcon}
                    alt={text.automatic}
                  />
                  <Typography variant={'subheading'} classes={typographyStyles}>
                    {text.automatic}
                  </Typography>
                  <Typography variant={'body2'} classes={typographyStyles}>
                    {text.automaticText}
                  </Typography>
                </div>
                <AddIcon className={classes.paymentsIcon} />
                <div className={classes.paymentsPayment}>
                  <img
                    className={classes.paymentsImage}
                    src={BankIcon}
                    alt={text.payment}
                  />
                  <Typography variant={'subheading'} classes={typographyStyles}>
                    {text.payment}
                  </Typography>
                  <Typography variant={'body2'} classes={typographyStyles}>
                    {text.paymentText}
                  </Typography>
                </div>
              </div>
            </div>
            {!isDesktop && (
              <Typography variant={'body1'} classes={typographyStyles}>
                {text.secondParagraph}
              </Typography>
            )}
            {isDesktop && (
              <Typography variant={'display1'} classes={typographyStyles}>
                {text.secondParagraph}
              </Typography>
            )}
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

OpenDataPayment.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  collapsable: PropTypes.bool,
};
OpenDataPayment.defaultProps = {
  className: '',
  collapsable: true,
};

export default withStyles(styles)(OpenDataPayment);
