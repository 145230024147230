import React, {
  useState,
  Fragment,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import {
  Field,
  formValueSelector,
  change,
  FieldArray,
  Fields,
} from 'redux-form';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  deleteFilesNotSaved,
  cleanLastOneIdentity,
  cleanLastOneIdentityBack,
  cleanLastOnePassport,
} from '../../redux/actions';
import  ClipGrid  from '@clipmx/clip-storybook/dist/components/Grid';
import {
  required,
  requiredDropdown,
  optionalNumber,
  numericalExpression,
  haveLength,
  isNumeric,
  unrepeatedPhones,
} from '../../../../helpers/validate';
import { showTelephoneValidation } from '../../../../constants/FeatureFlags';
import { Forms, LoansDocuments } from '../../../../constants/AppConstants';
import {
  showIdentityDocumentRadios,
  showMaritalStatus,
} from '../../../../constants/ProviderCodes';
import {
  getLoansIdentityDocuments,
  getLoansIdentityDocumentsBack,
  getLoansPassportDocuments,
  getPrestaClipNameChange,
  getPreapprovalSelected,
} from '../../redux/selectors';
import styles from './styles';
import WarningIcon from '@material-ui/icons/Warning';
import RenderExtraPhonesV4 from '../../Commons/ContactInformation/renderExtraPhonesV4';
import RenderExtraPhonesV3 from '../../Commons/ContactInformation/renderExtraPhonesV3';
import FormControl from '@clipmx/clip-storybook/dist/components/FormControl';
import FormHelperText from '@clipmx/clip-storybook/dist/components/FormHelperText';

import text from './text';

import TextField from '../../../../helpers/FormUtils/TextField';
import SelectFieldV2 from '../../../../helpers/FormUtils/SelectFieldV2';
import RadioField from '../../../../helpers/FormUtils/RadioField';
import AttachIdentityDocuments from '../../Commons/AttachDocumentsV2/AttachIdentityDocuments';
import { lenderPayload } from '../../Commons/Clevertap';
import ClevertapField from '../../../forms/ClevertapField';
import { Clevertap, EVENTS } from '../../../../helpers/Clevertap';
import PropTypes from 'prop-types';


const IdentityStep = (
  {
    identityFiles,
    identityFilesBack,
    passportFiles,
    identityDocumentType,
    ine,
    extraTelephone,
    preapprovalSelected,
    contactMethodType,
    previousLoan,
    hasPreviousLoan,
    deleteFilesNotSaved,
    change,
    showCashAdvance,
    loansFormVersion,
    extraTelephones,
  },
  ref,
) => {
  const [ineFrontRequired, changeIneFrontRequired] = useState(false);
  const [ineBackRequired, changeIneBackRequired] = useState(false);
  const [passportRequired, changePassportRequired] = useState(false);
  const [disabledDataFieldsUpdated, changeDisabledDataFieldsUpdated] =
    useState(false);
  const [showUploadFields, changeShowUploadFields] = useState(false);
  const [showUploadFieldsUpdated, changeShowUploadFieldsUpdated] =
    useState(false);
  const [showDataFieldsUpdated, changeShowDataFieldsUpdated] = useState(false);
  const [errorPhone, changeErrorPhone] = useState(false);
  const [errorContactMethod, changeErrorContactMethod ] = useState(false);
  const [errorTelephones, changeErrorTelephones ] = useState(false);

  const extraPhoneComponent = showTelephoneValidation ? RenderExtraPhonesV4 : RenderExtraPhonesV3;

  const documentTypeOptions = text.documentTypeOptionsV4;
  const contactMethodOptions = text.contactMethodOptions;
  const maritalStatusOptions = text.maritalOptions;

  let location = useLocation();
  const classes = styles();
  
  const fileUploaded = useCallback(
    (type) => {
      if (type === LoansDocuments.identity) {
        changeIneFrontRequired(false);
        Clevertap.event(
          EVENTS.LOANS.FRONT_INE_UPLOADED,
          'UPLOADED_INE_FRONT',
          lenderPayload(preapprovalSelected, {
            showCashAdvance,
          }),
        );
      }
      if (type === LoansDocuments.identityBack) {
        changeIneBackRequired(false);
        Clevertap.event(
          EVENTS.LOANS.BACK_INE_UPLOADED,
          'UPLOADED_INE_BACK',
          lenderPayload(preapprovalSelected, {
            showCashAdvance,
          }),
        );
      }
      if (type === LoansDocuments.passport) {
        changePassportRequired(false);
        Clevertap.event(
          EVENTS.LOANS.PASSPORT_UPLOADED,
          'UPLOADED_PASSPORT',
          lenderPayload(preapprovalSelected, {
            showCashAdvance,
          }),
        );
      }
    },
    [changeIneFrontRequired, changeIneBackRequired, changePassportRequired],
  );

  useImperativeHandle(ref, () => ({
    checkDocuments() {
      let isPassport =
        identityDocumentType === LoansDocuments.passportDocumentType;

      if (isPassport && passportFiles.length === 0) {
        changePassportRequired(true);
        return false;
      }
      if (!isPassport) {
        let status = true;
        if (identityFilesBack.length === 0) {
          status = false;
          changeIneBackRequired(true);
        }
        if (identityFiles.length === 0) {
          status = false;
          changeIneFrontRequired(true);
        }

        if (!status) {
          return false;
        }
      }

      return true;
    },

    checkContactMethod() {
      if (!contactMethodType) {
        changeErrorContactMethod(true);
        return false;
      }

      return true;
    },

    checkTelephones() {
   
      if (!extraTelephones ||  extraTelephones?.length === 0) {
        changeErrorTelephones(true);
        return false;
      }

      return true;

    }
  }));

  const showIdentityRadios = () =>
    showIdentityDocumentRadios(location.pathname.toUpperCase());

  const setIdentityDocumentType = useCallback(
    (event) => {
      change(Forms.MR_PRESTA, 'ine', '');

      if (event.target.value === LoansDocuments.ineDocumentType) {
        deleteFilesNotSaved(LoansDocuments.passport);
        changeIneFrontRequired(false);
        changeIneBackRequired(false);
      } else if (event.target.value === LoansDocuments.passportDocumentType) {
        deleteFilesNotSaved(LoansDocuments.identity);
        deleteFilesNotSaved(LoansDocuments.identityBack);
        changePassportRequired(false);
      }
    },
    [
      change,
      deleteFilesNotSaved,
      changeIneFrontRequired,
      changeIneBackRequired,
      changePassportRequired,
    ],
  );

  const enableFields = () => {
    if (ine !== undefined && !disabledDataFieldsUpdated) {
      changeDisabledDataFieldsUpdated(true);
    }
  };

  const enableSections = () => {
    if (identityDocumentType !== undefined && !showUploadFieldsUpdated) {
      changeShowUploadFields(true);
      changeShowUploadFieldsUpdated(true);
    }

    if (
      !showDataFieldsUpdated &&
      ((identityDocumentType === 'PASSPORT' && passportFiles.length > 0) ||
        (identityDocumentType !== 'PASSPORT' &&
          identityFiles.length > 0 &&
          identityFilesBack.length > 0))
    ) {
      changeShowDataFieldsUpdated(true);
    }
  };

  const checkPhoneFilled = useCallback(() => {
    if (!extraTelephone) {
      changeErrorPhone(true);
      return false;
    }

    return true;   
  }, [extraTelephone, changeErrorPhone]);

  useEffect(() => {
    enableSections();
    enableFields();
  });

  const { provider_code: providerCode } = preapprovalSelected;
  let isPassport = identityDocumentType === LoansDocuments.passportDocumentType;

  const documentLabel = isPassport
    ? text.documentSectionPassportV4
    : text.documentSectionINEV4;

  return (
  <Fragment>
    <ClipGrid container spacing={0}>

        <div className={classes.StepTitleStyles}>{text.titleV4}</div>
        {showIdentityRadios() && (
          <Fragment>
            <div className={classes.StepDescriptionTitleStyles}>
              {text.documentTypeTitle}
            </div>
            <FormControl className={classes.FormControlStyles} fullWidth>
              <ClevertapField
                className={classes.ContactFieldStyles}
                name="identityDocumentType"
                component={RadioField}
                validate={[required]}
                options={documentTypeOptions}
                onChange={setIdentityDocumentType}
                clevertap={{
                  listener: 'onChange',
                  event: {
                    ine: EVENTS.LOANS.INE_SELECTED,
                    passport: EVENTS.LOANS.PASSPORT_SELECTED,
                  },
                  payload: lenderPayload(preapprovalSelected, {
                    showCashAdvance,
                  }),
                }}
              />
            </FormControl>
          </Fragment>
        )}

        {showUploadFields && (
          <Fragment>
            <div className={classes.StepSectionStyles}>{documentLabel}</div>
            <FormControl className={classes.FormControlStyles} fullWidth>
              <AttachIdentityDocuments
                ineFrontRequired={ineFrontRequired}
                ineBackRequired={ineBackRequired}
                passportRequired={passportRequired}
                fileUploaded={fileUploaded}
                identityDocumentType={identityDocumentType}
                previousLoan={hasPreviousLoan && previousLoan.documents}
                newestTitle={true}
                requiredv4={true}
              />
            </FormControl>
          </Fragment>
        )}

        <Fragment>
          {showMaritalStatus(providerCode) && (
            <Fragment>
              <FormControl className={classes.FormControlStyles} fullWidth>
                <ClevertapField
                  component={SelectFieldV2}
                  label={text.selectPlaceholder}
                  name="maritalStatus"
                  options={maritalStatusOptions}
                  validate={[requiredDropdown]}
                  clevertap={{
                    listener: 'onChange',
                    event: EVENTS.LOANS.MARITAL_STATUS_SELECTED,
                    payload: lenderPayload(preapprovalSelected, {
                      showCashAdvance,
                    }),
                  }}
                />
              </FormControl>
            </Fragment>
          )}
          <Fragment>
            <div className={classes.StepSectionStyles}>
              <b>{text.contactInformationSection}</b>
            </div>
            <FormControl className={classes.FormControlStyles}>
              <div className={classes.addPhone}>
                <Field
                  name="extraTelephone"
                  component={TextField} 
                  inputProps={{ maxLength: 10 }}
                  type="text"
                  label={text.contactInformationPhone}
                  validate={
                    showTelephoneValidation
                      ? [optionalNumber, haveLength(10), numericalExpression, unrepeatedPhones]
                      : [required, isNumeric, haveLength(10)]
                  }
                />
                {!extraTelephone &&  errorPhone && (                 
                      <FormHelperText
                        classes={{ root: classes.ErrorHelperTextStyles }}
                      >
                        {text.errorCustomPhone}
                      </FormHelperText>
                )}
              </div>
              <FieldArray
              name="extraTelephones"
              checkPhoneFilled={checkPhoneFilled}
              classesV4={true}
              component={extraPhoneComponent}  
              handleExtraPhonesFilled={errorTelephones}       
            /> 
            </FormControl>
          </Fragment>
        </Fragment>
        </ClipGrid>
      </Fragment>
  );
};

const selector = formValueSelector(Forms.MR_PRESTA);

const mapStateToProps = (state) => {
  return {
    firstName: selector(state, 'firstName'),
    secondLastName: selector(state, 'secondLastName'),
    lastName: selector(state, 'lastName'),
    identityFiles: getLoansIdentityDocuments(state),
    identityFilesBack: getLoansIdentityDocumentsBack(state),
    passportFiles: getLoansPassportDocuments(state),
    identityDocumentType: selector(state, 'identityDocumentType'),
    gender: selector(state, 'gender'),
    maritalStatus: selector(state, 'maritalStatus'),
    curp: selector(state, 'curp'),
    ine: selector(state, 'ine'),
    rfc: selector(state, 'rfc'),
    day: selector(state, 'day'),
    month: selector(state, 'month'),
    year: selector(state, 'year'),
    prestaClipNameChange: getPrestaClipNameChange(state),
    extraTelephone: selector(state, 'extraTelephone'),
    extraTelephones: selector(state, 'extraTelephones'),
    preapprovalSelected: getPreapprovalSelected(state),
    contactMethodType: selector(state, 'contactMethodType'),
    showCashAdvance: state.loans.showCashAdvance,
    loansFormVersion: state.loans.loansFormVersion,
  };
};

const mapDispatchToProps = {
  deleteFilesNotSaved,
  cleanLastOneIdentity,
  cleanLastOneIdentityBack,
  cleanLastOnePassport,
  change,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    forwardRef(IdentityStep),
  ),
);

IdentityStep.propTypes = {
  deleteFilesNotSaved: PropTypes.func.isRequired,
  cleanLastOneIdentity: PropTypes.func.isRequired,
  cleanLastOneIdentityBack: PropTypes.func.isRequired,
  cleanLastOnePassport: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  prestaClipNameChange: PropTypes.bool.isRequired,
  identityDocumentType: PropTypes.string,
  identityFiles: PropTypes.array,
  identityFilesBack: PropTypes.array,
  passportFiles: PropTypes.array,
  fields: PropTypes.object,
};