import React from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipIconButton } from '@clipmx/clip-storybook';
import { ArrowBackRounded, ArrowBackIos } from '@clipmx/clip-storybook/dist/components/Icons';

const styles = (theme) => {
  return {
    root: {
      width: '100%',
      height: '3.5rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      alignSelf: 'center',
      backgroundColor: 'white',
      overflow: 'auto',
      boxSizing: 'border-box',
      boxShadow: `0px 2px 4px ${theme.palette.indigo.l90}`,
      position: 'fixed',
      top: 0,
      left: 0,
    },
    headerTitle: {
      fontSize: '1.125rem',
      marginBottom: 0,
      marginTop: 0,
      width: 'auto',
    },
    rootV2: {
      width: '100%',
      height: '3.5rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: 'white',
      overflow: 'auto',
      boxSizing: 'border-box',
      borderBottom: `1px solid ${theme.palette.indigo.l90}`,
      boxShadow: `0px 2px 4px ${theme.palette.indigo.l90}`,
      position: 'fixed',
      top: 0,
      left: 0,
    },
    headerTitleV2: {
      fontSize: theme.spacing(2.5),
      fontWeight: 700,
      marginBottom: 0,
      marginTop: 0,
      padding: 0,
      width: 'auto',
    },
    buttonContainer: {
      width: '3rem',
    },
  };
};
const useStyles = makeStyles(styles);

const OneStepNavigator = (props) => {
  const classes = useStyles(props);
  const { title, stepBackFunction, stepBackTarget, v2 } = props;
  return (
    <div className={v2 ? classes.rootV2: classes.root}>
      <ClipIconButton color="primary" size="medium" onClick={() => stepBackFunction(stepBackTarget)}>
        {v2 ? <ArrowBackRounded/> : <ArrowBackIos color="primary" />}
      </ClipIconButton>
      <Typography variant="title" className={v2 ? classes.headerTitleV2: classes.headerTitle}>
        {title}
      </Typography>
      <div className={classes.buttonContainer} />
    </div>
  );
};

export default OneStepNavigator;
