export default {
  btnText: 'Ir a Cartera Clip',
  title1: '¡Listo!',
  title2: 'Estamos validando tu información',
  subtitle: 'Te enviaremos una notificación en ',
  sub: 'los próximos 5 minutos ',
  subtitle1: 'para que puedas consultar el resultado de tu solicitud en ',
  path: 'Cartera Clip > Mi cuenta > Ver mi solicitud.',
  subtitle2: 'Puedes seguir usando tu Cartera Clip o, si necesitas ayuda ',
  ch: 'llama a Customer Happiness.',
};
