import { normalize, schema } from 'normalizr';
import moment from 'moment-timezone';
import { SubmissionError } from 'redux-form';

import {
  dataLoading,
  dataLoaded,
  dataLoadFailed,
} from '../../../actions/AppActions';
import { CatalogBulkStates } from '../../../constants/AppConstants';
import {
  CATALOG_DELETE_ITEM,
  CATALOG_CREATE_ITEM,
  CATALOG_DELETE_SKU,
  CATALOG_CREATE_SKU,
  CATALOG_GET_ITEMS,
  CATALOG_GET_ITEM,
  CATALOG_UPDATE_ITEM,
  CATALOG_UPDATE_SKU,
  CATALOG_GET_CATEGORIES,
  CATALOG_GET_CATEGORIES_RESET,
  CATALOG_CONFIRM_PILOT_INVITATION,
  CATALOG_BULK_UPLOAD,
  CATALOG_BULK_GET_STATE,
  CATALOG_BULK_UPDATE_STATE,
  CATALOG_BULK_IN_PROCESS,
  CATALOG_CREATE_CATEGORIES,
  CATALOG_CLEAR,
  CATALOG_GET_PUBLISH_INFO,
  CATALOG_SET_PUBLISH_INFO,
  CATALOG_SET_URL,
  CATALOG_CHANGE_URL,
  CATALOG_GET_URL,
  // CATALOG_BETA,
} from './types';
import Catalog from '../service';
import config from '../../../config';
import {
  createGetRequest,
  createPostRequest,
} from '../../../middleware/apiResolve/reduxUtils';

const dateToUTC = (date) => moment(date).utc();

export const setUrl = (url) => {
  return {
    type: CATALOG_SET_URL,
    payload: { url },
  };
};

export const getPublishInfo = (onSuccess, onError) => (dispatch) => {
  dispatch(dataLoading(CATALOG_GET_PUBLISH_INFO));

  return Catalog.getPublishInfo()
    .then(({ data }) => {
      dispatch(dataLoaded(CATALOG_GET_PUBLISH_INFO, data));
      dispatch({ type: CATALOG_GET_PUBLISH_INFO, payload: { data } });
      if (onSuccess && {}.toString.call(onSuccess) === '[object Function]') {
        return onSuccess(data);
      }
    })
    .catch((err) => {
      dispatch({ type: CATALOG_GET_PUBLISH_INFO, payload: { data: false } });
      dispatch(dataLoadFailed(CATALOG_GET_PUBLISH_INFO, err.message));
      if (onError && {}.toString.call(onError) === '[object Function]') {
        return onError(err);
      }
    });
};

export const setPublishInfo =
  (onlineCatalog, termsConditions, description, onSuccess, onError) =>
  (dispatch) => {
    dispatch(dataLoading(CATALOG_SET_PUBLISH_INFO));

    return Catalog.setPublishInfo({
      online_catalog: onlineCatalog,
      terms_conditions: termsConditions,
      description,
    })
      .then(({ data }) => {
        dispatch(dataLoaded(CATALOG_SET_PUBLISH_INFO, data));
        dispatch({ type: CATALOG_SET_PUBLISH_INFO, payload: { data } });
        if (onSuccess && {}.toString.call(onSuccess) === '[object Function]') {
          return onSuccess(data);
        }
      })
      .catch((err) => {
        dispatch({ type: CATALOG_SET_PUBLISH_INFO, payload: { data: null } });
        dispatch(dataLoadFailed(CATALOG_SET_PUBLISH_INFO, err.message));
        if (onError && {}.toString.call(onError) === '[object Function]') {
          return onError(err);
        }
      });
  };

export const getCatalogItems =
  (limit, offset, includeSkus, updatedAt) => (dispatch) => {
    dispatch(dataLoading(CATALOG_GET_ITEMS));
    return Catalog.getItems({ limit, offset, includeSkus, updatedAt })
      .then((data) => {
        dispatch(dataLoaded(CATALOG_GET_ITEMS, data));
        const skus = new schema.Entity('skus', {}, { idAttribute: 'skuId' });
        const item = new schema.Entity(
          'items',
          { skus: [skus] },
          { idAttribute: 'itemId' },
        );
        const rawItems = data.items
          ? data.items.filter((item) => {
              return item.skus && item.skus.length > 0;
            })
          : [];
        const normalized = normalize(rawItems, [item]);
        // if (data.items[0] && data.items[0].skus[0] && typeof data.items[0].skus[0].totalSold !== 'undefined') {
        //   dispatch({ type: CATALOG_BETA, payload: true });
        // }
        const { items } = normalized.entities;
        !!items &&
          Object.values(items).forEach(
            ({ color, categoryId, defaultSkuId, skus: itemSkus }) => {
              const { skus } = normalized.entities;
              const productName = skus[defaultSkuId]?.name;
              // const isAVariant = hasVariants && itemSkus.length > 1;
              itemSkus.forEach((id) => {
                skus[id].type = skus[id].name;
                skus[id].name = productName;
                skus[id].color = color;
                skus[id].categoryId = categoryId;
                skus[id].productName = productName;
                if (defaultSkuId === id) {
                  skus[id].isDefault = true;
                  delete skus[id].type;
                }
              });
            },
          );
        dispatch({
          type: CATALOG_GET_ITEMS,
          payload: {
            data: { ...normalized.entities },
            meta: data.meta,
            rawData: data,
          },
        });
      })
      .catch((err) => {
        dispatch(dataLoadFailed(CATALOG_GET_ITEMS, err.message));
      });
  };

export const getCatalogItem =
  (itemId, includeSkus, updatedAt) => (dispatch) => {
    dispatch(dataLoading(CATALOG_GET_ITEM));

    return Catalog.getItem({ itemId, includeSkus, updatedAt })
      .then((data) => {
        dispatch(dataLoaded(CATALOG_GET_ITEM, data));
        dispatch({ type: CATALOG_GET_ITEM, payload: { data } });
      })
      .catch((err) => {
        dispatch(dataLoadFailed(CATALOG_GET_ITEM, err.message));
      });
  };

export const deleteItem = (itemId) => (dispatch) => {
  dispatch(dataLoading(CATALOG_DELETE_ITEM));

  return Catalog.deleteItem(itemId)
    .then((data) => {
      dispatch(dataLoaded(CATALOG_DELETE_ITEM, data));
      dispatch({ type: CATALOG_DELETE_ITEM, payload: { data } });
    })
    .catch((err) => {
      dispatch(dataLoadFailed(CATALOG_DELETE_ITEM, err.message));
    });
};

export const deleteSku = (skus) => (dispatch) => {
  dispatch(dataLoading(CATALOG_DELETE_SKU));

  return Catalog.deleteSku(skus)
    .then((data) => {
      dispatch(dataLoaded(CATALOG_DELETE_SKU, data));
      dispatch({ type: CATALOG_DELETE_SKU, payload: { data } });
    })
    .catch((err) => {
      dispatch(dataLoadFailed(CATALOG_DELETE_SKU, err.message));
    });
};

export const updateSku =
  (skuId, name, price, cost, sku, stock, image, description) => (dispatch) => {
    dispatch(dataLoading(CATALOG_UPDATE_SKU));
    return Catalog.updateSku({
      skuId,
      name,
      price,
      cost,
      sku,
      stock,
      image,
      description,
    })
      .then((data) => {
        dispatch(dataLoaded(CATALOG_UPDATE_SKU, data));
        dispatch({
          type: CATALOG_UPDATE_SKU,
          payload: { data, prevSkuId: skuId },
        });
      })
      .catch((err) => {
        dispatch(dataLoadFailed(CATALOG_UPDATE_SKU, err.message));
        throw new SubmissionError(err);
      });
  };

export const updateItem =
  (itemId, color, categoryId, hasVariants, skus) => (dispatch) => {
    dispatch(dataLoading(CATALOG_UPDATE_ITEM));

    return Catalog.updateItem({ itemId, color, categoryId, hasVariants, skus })
      .then((data) => {
        dispatch(dataLoaded(CATALOG_UPDATE_ITEM, data));
        dispatch({ type: CATALOG_UPDATE_ITEM, payload: { data } });
      })
      .catch((err) => {
        dispatch(dataLoadFailed(CATALOG_UPDATE_ITEM, err.message));
        throw new SubmissionError(err);
      });
  };

export const createItem =
  (
    color,
    name,
    price,
    cost,
    sku,
    stock,
    image,
    categoryId,
    description,
    hasVariants,
    skus,
  ) =>
  (dispatch) => {
    dispatch(dataLoading(CATALOG_CREATE_ITEM));

    return Catalog.createItem({
      color,
      name,
      price,
      cost,
      sku,
      stock,
      image,
      categoryId,
      description,
      hasVariants,
      skus,
    })
      .then((data) => {
        dispatch(dataLoaded(CATALOG_CREATE_ITEM, data));
        dispatch({ type: CATALOG_CREATE_ITEM, payload: { data } });
      })
      .catch((err) => {
        dispatch(dataLoadFailed(CATALOG_CREATE_ITEM, err.message));
        throw new SubmissionError(err);
      });
  };

export const createSku =
  (name, price, sku, stock, image, itemId) => (dispatch) => {
    dispatch(dataLoading(CATALOG_CREATE_SKU));

    return Catalog.createSku({ name, price, sku, stock, image, itemId })
      .then((data) => {
        dispatch(dataLoaded(CATALOG_CREATE_SKU, data));
        dispatch({ type: CATALOG_CREATE_SKU, payload: { data } });
      })
      .catch((err) => {
        dispatch(dataLoadFailed(CATALOG_CREATE_SKU, err.message));
        throw new SubmissionError(err);
      });
  };

export const createCatalogCategories =
  (name, type, isActive, color, onSuccess) => (dispatch) => {
    dispatch(dataLoading(CATALOG_CREATE_CATEGORIES));

    return Catalog.createCategories(name, type, isActive, color)
      .then((data) => {
        dispatch(dataLoaded(CATALOG_CREATE_CATEGORIES, data));
        dispatch({ type: CATALOG_CREATE_CATEGORIES, payload: { data } });
        if (onSuccess && {}.toString.call(onSuccess) === '[object Function]')
          onSuccess(data);
      })
      .catch((err) => {
        dispatch(dataLoadFailed(CATALOG_CREATE_CATEGORIES, err.message));
        throw new SubmissionError(err);
      });
  };

export const getCatalogCategories = () => (dispatch) => {
  dispatch(dataLoading(CATALOG_GET_CATEGORIES));
  dispatch({ type: CATALOG_GET_CATEGORIES_RESET });
  return Catalog.getCategories()
    .then((data) => {
      dispatch(dataLoaded(CATALOG_GET_CATEGORIES, data));
      dispatch({ type: CATALOG_GET_CATEGORIES, payload: { data } });
    })
    .catch((err) => {
      dispatch(dataLoadFailed(CATALOG_GET_CATEGORIES, err.message));
    });
};

export const confirmPilotInvitation = (name, email, phone) => (dispatch) => {
  dispatch(dataLoading(CATALOG_CONFIRM_PILOT_INVITATION));

  return Catalog.confirmPilotInvitation({ name, email, phone })
    .then((data) => {
      dispatch(dataLoaded(CATALOG_CONFIRM_PILOT_INVITATION, data));
      dispatch({ type: CATALOG_CONFIRM_PILOT_INVITATION, payload: { data } });
    })
    .catch((err) => {
      dispatch(dataLoadFailed(CATALOG_CONFIRM_PILOT_INVITATION, err.message));
      throw err;
    });
};

export const uploadBulkTemplate = (base64file, ext) => (dispatch) => {
  dispatch(dataLoading(CATALOG_BULK_UPLOAD));
  return Catalog.uploadBulkTemplate(base64file, ext)
    .then((data) => {
      dispatch(dataLoaded(CATALOG_BULK_UPLOAD, data));
      dispatch(getBulkState());
    })
    .catch((err) => {
      dispatch(dataLoadFailed(CATALOG_BULK_UPLOAD, err.message));
      throw err;
    });
};

export const getBulkState = () => (dispatch) => {
  dispatch(dataLoading(CATALOG_BULK_GET_STATE, false));
  return Catalog.getBulkState()
    .then((data) => {
      const states = [CatalogBulkStates.INITIAL, CatalogBulkStates.IN_PROCESS];
      const parsedData = JSON.parse(JSON.stringify(data));

      dispatch(dataLoaded(CATALOG_BULK_GET_STATE, parsedData));

      const isProcessingBulk = states.includes(data.status);

      if (!data.bulk_status_id || !isProcessingBulk) {
        dispatch({ type: CATALOG_BULK_IN_PROCESS, payload: false });
      }

      dispatch({ type: CATALOG_BULK_GET_STATE, payload: parsedData });

      if (isProcessingBulk) {
        dispatch({ type: CATALOG_BULK_IN_PROCESS, payload: true });

        const maxDueDate = dateToUTC(new Date(data.created_at))
          .add(config('bulkUploadProcessMaxTime'), 'milliseconds')
          .format();

        let currentDate;
        const interval = setInterval(async () => {
          try {
            const response = await Catalog.getBulkState();

            if (!states.includes(response.status)) {
              dispatch({ type: CATALOG_BULK_IN_PROCESS, payload: false });
              clearInterval(interval);
            }

            currentDate = dateToUTC(new Date()).format();

            if (moment(currentDate).isSameOrAfter(maxDueDate)) {
              await Catalog.deleteBulkProcess(
                [response.bulk_status_id],
                CatalogBulkStates.CANCELED_BY_USER,
              );
              const newBulkState = await Catalog.getBulkState();
              dispatch({ type: CATALOG_BULK_IN_PROCESS, payload: false });
              dispatch({ type: CATALOG_BULK_GET_STATE, payload: newBulkState });
              clearInterval(interval);
              return;
            }

            dispatch({ type: CATALOG_BULK_GET_STATE, payload: response });
          } catch (e) {
            clearInterval(interval);
          }
        }, config('bulkFetchInterval'));
      }
    })
    .catch((err) => {
      dispatch(dataLoadFailed(CATALOG_BULK_GET_STATE, err.message));
      throw err;
    });
};

export const updateBulkState =
  (bulkStatusId, notifiedToMerchant) => (dispatch) => {
    dispatch(dataLoading(CATALOG_BULK_UPDATE_STATE));
    return Catalog.updateBulkState(bulkStatusId, notifiedToMerchant)
      .then((data) => {
        dispatch(dataLoaded(CATALOG_BULK_UPDATE_STATE, data));
        dispatch({
          type: CATALOG_BULK_UPDATE_STATE,
          payload: JSON.parse(JSON.stringify(data)),
        });
        dispatch({ type: CATALOG_BULK_IN_PROCESS, payload: false });
      })
      .catch((err) => {
        dispatch(dataLoadFailed(CATALOG_BULK_UPDATE_STATE, err.message));
        throw err;
      });
  };

export const clearCatalog = () => (dispatch) => {
  dispatch({ type: CATALOG_CLEAR });
};

export const getCatalogUrl = (onSuccess = () => {}, onFailure = () => {}) => {
  return {
    type: createGetRequest(CATALOG_GET_URL),
    payload: {
      route: '/catalog/online/url',
      success: onSuccess,
      failure: onFailure,
      showAppSpinner: true,
    },
  };
};

export const postChangeUrl = (
  slug,
  onSuccess = () => {},
  onFailure = () => {},
) => {
  const route = '/catalog/online/slug';

  return {
    type: createPostRequest(CATALOG_CHANGE_URL),
    payload: {
      apiEndPoint: 'v2',
      route,
      data: {
        slug: slug,
      },
      success: onSuccess,
      failure: onFailure,
      autoSpinner: true,
    },
  };
};
