export const states = [
  { id: 'Aguascalientes', name: 'Aguascalientes' },
  { id: 'Baja California', name: 'Baja California' },
  { id: 'Baja California Sur', name: 'Baja California Sur' },
  { id: 'Chihuahua', name: 'Chihuahua' },
  { id: 'Chiapas', name: 'Chiapas' },
  { id: 'Campeche', name: 'Campeche' },
  { id: 'Ciudad de Mexico', name: 'Ciudad de Mexico' },
  { id: 'Coahuila', name: 'Coahuila' },
  { id: 'Colima', name: 'Colima' },
  { id: 'Durango', name: 'Durango' },
  { id: 'Guerrero', name: 'Guerrero' },
  { id: 'Guanajuato', name: 'Guanajuato' },
  { id: 'Hidalgo', name: 'Hidalgo' },
  { id: 'Jalisco', name: 'Jalisco' },
  { id: 'Michoacan', name: 'Michoacan' },
  { id: 'Estado de Mexico', name: 'Estado de Mexico' },
  { id: 'Morelos', name: 'Morelos' },
  { id: 'Nayarit', name: 'Nayarit' },
  { id: 'Nuevo Leon', name: 'Nuevo Leon' },
  { id: 'Oaxaca', name: 'Oaxaca' },
  { id: 'Puebla', name: 'Puebla' },
  { id: 'Quintana Roo', name: 'Quintana Roo' },
  { id: 'Queretaro', name: 'Queretaro' },
  { id: 'Sinaloa', name: 'Sinaloa' },
  { id: 'San Luis Potosi', name: 'San Luis Potosi' },
  { id: 'Sonora', name: 'Sonora' },
  { id: 'Tabasco', name: 'Tabasco' },
  { id: 'Tlaxcala', name: 'Tlaxcala' },
  { id: 'Tamaulipas', name: 'Tamaulipas' },
  { id: 'Veracruz', name: 'Veracruz' },
  { id: 'Yucatan', name: 'Yucatan' },
  { id: 'Zacatecas', name: 'Zacatecas' },
  { id: 'Extranjero', name: 'Extranjero' },
];
export const shortStates = [
  { name: 'AGUASCALIENTES', id: 'AS' },
  { name: 'BAJA CALIFORNIA', id: 'BC' },
  { name: 'BAJA CALIFORNIA SUR', id: 'BS' },
  { name: 'CAMPECHE', id: 'CC' },
  { name: 'COAHUILA DE ZARAGOZA', id: 'CL' },
  { name: 'COLIMA', id: 'CM' },
  { name: 'CHIAPAS', id: 'CS' },
  { name: 'CHIHUAHUA', id: 'CH' },
  { name: 'DISTRITO FEDERAL', id: 'DF' },
  { name: 'DURANGO', id: 'DG' },
  { name: 'GUANAJUATO', id: 'GT' },
  { name: 'GUERRERO', id: 'GR' },
  { name: 'HIDALGO', id: 'HG' },
  { name: 'JALISCO', id: 'JC' },
  { name: 'MEXICO', id: 'MC' },
  { name: 'MICHOACAN DE OCAMPO', id: 'MN' },
  { name: 'MORELOS', id: 'MS' },
  { name: 'NAYARIT', id: 'NT' },
  { name: 'NUEVO LEON', id: 'NL' },
  { name: 'OAXACA', id: 'OC' },
  { name: 'PUEBLA', id: 'PL' },
  { name: 'QUERETARO DE ARTEAGA', id: 'QT' },
  { name: 'QUINTANA ROO', id: 'QR' },
  { name: 'SAN LUIS POTOSI', id: 'SP' },
  { name: 'SINALOA', id: 'SL' },
  { name: 'SONORA', id: 'SR' },
  { name: 'TABASCO', id: 'TC' },
  { name: 'TAMAULIPAS', id: 'TS' },
  { name: 'TLAXCALA', id: 'TL' },
  { name: 'VERACRUZ', id: 'VZ' },
  { name: 'VERACRUZ DE IGNACIO DE LA LLAVE', id: 'VZ' },
  { name: 'YUCATAN', id: 'YN' },
  { name: 'ZACATECAS', id: 'ZS' },
  { name: 'NACIDO EN EL EXTRANJERO', id: 'NE' },
  { name: 'DESCONOCIDO', id: 'DESCONOCIDO' },
];

export const nationalities = [
  { id: '000', name: 'Mexicana' },
  { id: 'FOREING', name: 'Extranjero' },
];

export const country = [
  { id: 'MEX', name: 'Mexico' },
  { id: 'EXTRANJERO', name: 'Extranjero' },
];
export const business_sectors = [
  {
    id: 'Servicios Profesionales y de Salud',
    name: 'Servicios Profesionales y de Salud',
  },
  { id: 'Alimentos y Bebidas', name: 'Alimentos y Bebidas' },
  { id: 'Viajes y Turismo', name: 'Viajes y Turismo' },
  { id: 'Servicios Generales', name: 'Servicios Generales' },
  { id: 'Gasolineras', name: 'Gasolineras' },
  { id: 'Electrónica y Computación', name: 'Electrónica y Computación' },
  { id: 'Belleza y Cuidado Personal', name: 'Belleza y Cuidado Personal' },
  { id: 'Asociaciones', name: 'Asociaciones' },
  {
    id: 'Entretenimiento, Recreativo, Cultural y Deporte',
    name: 'Entretenimiento, Recreativo, Cultural y Deporte',
  },
  {
    id: 'Alquiler y Servicios Inmobiliarios',
    name: 'Alquiler y Servicios Inmobiliarios',
  },
  { id: 'Servicios Educativos', name: 'Servicios Educativos' },
];
