export default {
  PROMO_STORES: ['Walmart2016', 'Bodega2017', 'HomeDepot2018'],
  MAX_ATTEMPTS: 5,
  Walmart2016: {
    name: 'Walmart',
    rate: '2.8',
    terms_text: 'Términos y Condiciones Beneficio Clientes Walmart',
    terms: 'https://clip.mx/partner/walmart/terminos',
  },
  Bodega2017: {
    name: 'Bodega Aurrera',
    rate: '2.6',
    terms_text: 'Términos y Condiciones Beneficio Clientes Bodega Aurrera',
    terms: 'https://clip.mx/partner/bodega-aurrera/terminos',
  },
  HomeDepot2018: {
    name: 'Home Depot',
    rate: '3.2',
    terms_text: 'Términos y Condiciones Beneficio Clientes Home Depot',
    terms: 'https://clip.mx/partner/home-depot/terminos',
  },
};
