import React from 'react';
import 'react-notifications-component/dist/theme.css';
import * as Styled from './styled';
import * as Crypto from '../CryptoManager/CryptoManager';
import * as CryptoRequests from '../CryptoManager/RequestsCryptoManager';
import { connect } from 'react-redux';
import { BaseButton } from '../../common/Buttons';
import { InputAdornment } from '@material-ui/core';
import { required } from '../../../helpers/validate';
import { withStyles } from '@material-ui/core/styles';
import { setAccountRep } from '../redux/berlinActions';
import { Forms } from '../../../constants/AppConstants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { renderInput } from '../Commons/BerlinInputs/FormUtils';
import text from './text';
import styles from './styles';
import PropTypes from 'prop-types';
import BerlinService from '../service';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import AuthService from '../../auth/service';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import WhatsAppModule from '../WhatsAppModule';
import ListItem from '@material-ui/core/ListItem';
import WhatIsANipTooltip from './WhatIsANipTooltip';
import IconButton from '@material-ui/core/IconButton';
import WhyPasswordTooltip from './WhyPasswordTooltip';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import OneStepNavigator from '../Commons/OneStepNavigator';
import CheckMyBalanceTooltip from './CheckMyBalanceTooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moneyBlue from '../../../assets/images/berlin/moneyBlue.svg';
import pinBanks from '../../../assets/images/berlin/group-banks.jpg';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  minLength,
  checkValidPinRepeatedNumbers,
  checkValidPinConsecutiveNumbers,
} from '../utils';
import {
  formValueSelector,
  reduxForm,
  updateSyncErrors,
  propTypes,
} from 'redux-form';

const creditcardutils = require('creditcardutils');

const validateConsecutiveNumbers = checkValidPinConsecutiveNumbers(
  text.consecutiveNumbersError,
);

const validateRepeatedNumbers = checkValidPinRepeatedNumbers(
  text.repeatedNumbersError,
);

const checkValidPinLength = minLength(4, text.pinLengthError);

const checkValidPasswordLength = minLength(8, text.passwordLengthError);

const checkMatchingPin = (pin2, allValues) => {
  return pin2 === allValues.pin1 ? undefined : text.pinNotMatchingError;
};

export class PinChange extends React.PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      changeNIPDialog: false,
      activeStep: 0,
      pinTooltipOpen: false,
      passwordTooltipOpen: false,
      checkBalanceTooltipOpen: false,
      disableSubmit: false,
    };
  }

  static propTypes = {
    SCREENS: PropTypes.object.isRequired,
    setActiveScreen: PropTypes.func.isRequired,
    lastScreen: PropTypes.string,
    ...propTypes,
  };

  static defaultProps = {
    account: {},
    merchant: {},
    user: {},
  };

  componentDidMount() {
    creditcardutils.cards = [
      {
        type: 'PIN',
        pattern: /[0-9]/,
        format: /(\d{1,4})/g,
        length: [4],
        cvcLength: [3],
        luhn: true,
      },
    ];
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.activeStep === 0 && this.state.activeStep === 1) {
      this.textInput.current.focus();
    }
  }

  handleClose = () => {
    this.setState({ changeNIPDialog: false });
  };

  formatPin = (e) => {
    e.target.value = creditcardutils.formatCardNumber(e.target.value);
  };

  getLastScreen = () => {
    const { lastScreen, SCREENS } = this.props;
    return lastScreen === SCREENS.SHOW_PIN
      ? SCREENS.BALANCE
      : SCREENS.ACCOUNT_INFO;
  };

  updateAccountInformation = (dispatch, merchant) => {
    BerlinService.accountInformationV2(merchant.info.id).then((response) => {
      dispatch(setAccountRep(response.data.message));
    });
  };

  postChangeNip = () => {
    const {
      pin2,
      password,
      user,
      account,
      dispatch,
      merchant,
      account_holder_id,
      flags: { financialEncryptionAvailableWeb },
    } = this.props;
    this.setState({ disableSubmit: true });
    BerlinService.getMerchantNonce({ password })
      .then(async () => {
        if (financialEncryptionAvailableWeb) {
          await Crypto.init();
          const serverKey = await CryptoRequests.GetServerKey(
            account_holder_id,
            Crypto.keys().publicKey.base64,
            Crypto.keys().iv.hex,
          );
          await Crypto.setServerPublicKey(serverKey);
          var enc = new TextEncoder();
          const encryptedData = await Crypto.encrypt(enc.encode(pin2));
          BerlinService.changePinV3(
            account.proxy_account_id
              ? account.proxy_account_id
              : account.card_representation[0].proxy_card_id
              ? account.card_representation[0].proxy_card_id
              : account.card_representation[0].proxy_card,
            encryptedData,
          ).then(() => {
            this.updateAccountInformation(dispatch, merchant);
            this.setActiveStep(2);
            this.props.hideShowNip(false);
          });
        } else {
          if (account.proxy_account_id) {
            BerlinService.changePinV2(account.proxy_account_id, pin2).then(
              () => {
                this.updateAccountInformation(dispatch, merchant);
                this.setActiveStep(2);
                this.props.hideShowNip(false);
              },
            );
          } else {
            BerlinService.changePin(
              account.card_representation[0].proxy_card_id
                ? account.card_representation[0].proxy_card_id
                : account.card_representation[0].proxy_card,
              pin2,
            ).then(() => {
              this.updateAccountInformation(dispatch, merchant);
              this.setActiveStep(2);
              this.props.hideShowNip(false);
            });
          }
        }
      })
      .catch((e) => {
        dispatch(
          updateSyncErrors(Forms.PIN_CHANGE, {
            password: text.invalidPasswordError,
          }),
        );
        this.textInput.current.focus();
        this.setState({ disableSubmit: false });
      });
  };

  setActiveStep = (step) => {
    this.setState({ activeStep: step });
    window.scrollTo(0, 0);
  };

  toggleNipTooltip = (isTooltipOpen) => {
    this.setState({ pinTooltipOpen: isTooltipOpen });
    this.textInput.current.focus();
  };

  togglePasswordTooltip = (isTooltipOpen) => {
    this.setState({ passwordTooltipOpen: isTooltipOpen });
    this.textInput.current.focus();
  };

  toggleCheckBalanceTooltip = (isTooltipOpen) => {
    this.setState({ checkBalanceTooltipOpen: isTooltipOpen });
  };

  renderStep = (tab) => {
    const { invalid, classes, setActiveScreen, showNip } = this.props;
    const { pinTooltipOpen, passwordTooltipOpen, disableSubmit } = this.state;
    switch (tab) {
      case 0:
        return (
          <React.Fragment>
            <OneStepNavigator
              title={text.navigatorTitle}
              stepBackFunction={setActiveScreen}
              stepBackTarget={this.getLastScreen()}
            />
            <Typography
              className={classes.sectionSubheading}
              variant={'body1'}
              color={'textPrimary'}
            >
              {text.createNip}
            </Typography>
            <Typography
              variant={'body1'}
              className={classes.nipSuggestion}
              color={'textPrimary'}
            >
              {text.nipSuggestion}
            </Typography>
            <List className={classes.listBottomMargin}>
              <ListItem disableGutters>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant={'body1'}
                    className={classes.noBottomMargin}
                  >
                    {text.nipSuggestion1}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant={'body1'}
                    className={classes.noBottomMargin}
                  >
                    {text.nipSuggestion3}
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Styled.FormField
              component={renderInput}
              label={text.pin1Label}
              name={'pin1'}
              inputProps={{
                maxLength: 4,
                inputMode: 'numeric',
                pattern: '[0-9]',
                endAdornment: (
                  <InputAdornment
                    position={'end'}
                    color={'primary'}
                    onClick={() => this.toggleNipTooltip(true)}
                  >
                    <IconButton>
                      <HelpOutlineIcon color={'primary'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              validate={[
                required,
                checkValidPinLength,
                validateConsecutiveNumbers,
                validateRepeatedNumbers,
              ]}
              type={'password'}
              pattern={'[0-9]*'}
              onInput={this.formatPin}
              inputRef={this.textInput}
            />
            <Styled.FormField
              component={renderInput}
              label={text.pin2Label}
              name={'pin2'}
              inputProps={{
                maxLength: 4,
                inputMode: 'numeric',
                pattern: '[0-9]',
              }}
              validate={[required, checkMatchingPin]}
              type={'password'}
              onInput={this.formatPin}
            />
            <div className={classes.blueField}>
              <img src={moneyBlue} />
              <Typography variant={'body1'} className={classes.blueFieldText}>
                {text.bankComission}
                <span style={{ fontWeight: 'bold' }}>{text.banks}</span>
                {text.comissionWillApply}
                <span style={{ fontWeight: 'bold' }}>{text.comission}</span>
              </Typography>
            </div>
            <Styled.CommonButton
              onClick={() => this.setActiveStep(1)}
              disabled={invalid}
            >
              {text.continue}
            </Styled.CommonButton>
            <Drawer anchor={'bottom'} open={pinTooltipOpen}>
              <WhatIsANipTooltip toggleNipTooltip={this.toggleNipTooltip} />
            </Drawer>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <OneStepNavigator
              title={text.navigatorTitle}
              stepBackFunction={this.setActiveStep}
              stepBackTarget={0}
            />
            <Typography
              className={classes.sectionSubheading}
              variant={'body1'}
              color={'textPrimary'}
            >
              {text.subheading2}
            </Typography>
            <Styled.FormField
              component={renderInput}
              label={text.passwordLabel}
              name={'password'}
              validate={[required, checkValidPasswordLength]}
              type={'password'}
              inputProps={{
                endAdornment: (
                  <InputAdornment
                    position={'end'}
                    color={'primary'}
                    onClick={() => this.togglePasswordTooltip(true)}
                  >
                    <IconButton>
                      <HelpOutlineIcon color={'primary'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputRef={this.textInput}
            />
            <BaseButton
              onClick={() => {
                if (showNip) {
                  this.setState({ changeNIPDialog: true });
                  return;
                }
                this.postChangeNip();
              }}
              disabled={invalid || disableSubmit}
            >
              {text.continue}
            </BaseButton>
            <div className={classes.module}>
              <WhatsAppModule isSmall={true} />
            </div>
            <Drawer anchor={'bottom'} open={passwordTooltipOpen}>
              <WhyPasswordTooltip
                togglePasswordTooltip={this.togglePasswordTooltip}
              />
            </Drawer>
            <Dialog
              classes={{ paper: classes.paper }}
              open={this.state.changeNIPDialog}
              onClose={this.handleClose}
            >
              <div className={classes.dialogContainer}>
                <Typography className={classes.dialogText} variant={'display2'}>
                  {text.dialogTitle}
                </Typography>
                <Typography className={classes.dialogText} variant={'display1'}>
                  {text.dialogMessage}
                </Typography>
                <div className={classes.groupedButtons}>
                  <Styled.SubmitButton
                    className={classes.clearButton}
                    onClick={() => this.handleClose()}
                  >
                    {text.cancelDialog}
                  </Styled.SubmitButton>
                  <BaseButton
                    disabled={invalid || disableSubmit}
                    onClick={() => {
                      this.postChangeNip();
                      this.handleClose();
                    }}
                  >
                    {text.okDialog}
                  </BaseButton>
                </div>
              </div>
            </Dialog>
          </React.Fragment>
        );
    }
  };

  render() {
    const { handleSubmit, setActiveScreen, classes } = this.props;
    const { checkBalanceTooltipOpen } = this.state;
    return (
      <>
        {this.state.activeStep !== 2 ? (
          <Styled.ChangePinContainer>
            <form onSubmit={handleSubmit(this.postChangeNip)}>
              <Styled.ChangePin>
                {this.renderStep(this.state.activeStep)}
              </Styled.ChangePin>
            </form>
          </Styled.ChangePinContainer>
        ) : (
          <React.Fragment>
            <OneStepNavigator
              title={text.navigatorTitle}
              stepBackFunction={setActiveScreen}
              stepBackTarget={this.getLastScreen()}
            />
            <Card className={classes.almostDoneContainer}>
              <Typography className={classes.doneTitle} variant={'title'}>
                {text.almostDone}
              </Typography>
              <Typography
                variant={'body1'}
                className={classes.checkYourBalance}
              >
                {text.useNewNip}
              </Typography>
              <img alt="component" className={classes.banksImage} src={pinBanks} />
            </Card>

            <div
              className={classes.howToCheckBalanceClickArea}
              onClick={() => this.toggleCheckBalanceTooltip(true)}
            >
              <Typography
                className={classes.howToCheckBalance}
                variant={'body1'}
              >
                {text.howToCheckBalance}
              </Typography>
            </div>
            <Styled.DoneButton
              onClick={() => setActiveScreen(this.getLastScreen())}
            >
              {text.understood}
            </Styled.DoneButton>
            <WhatsAppModule isSmall={true} />
            <Drawer anchor={'bottom'} open={checkBalanceTooltipOpen}>
              <CheckMyBalanceTooltip
                toggleCheckBalanceTooltip={this.toggleCheckBalanceTooltip}
              />
            </Drawer>
          </React.Fragment>
        )}
      </>
    );
  }
}

const selector = formValueSelector(Forms.PIN_CHANGE);

const mapStateToProps = (state) => {
  return {
    user: state.user,
    merchant: state.merchant,
    account: state.berlin.account_representation,
    pin2: selector(state, 'pin2'),
    acceptComission: selector(state, 'acceptComission'),
    password: selector(state, 'password'),
    account_holder_id: state.berlin.account_representation.account_holder_id,
  };
};

const ReduxPinChange = reduxForm(
  { form: Forms.PIN_CHANGE },
  mapStateToProps,
)(PinChange);

export default withLDConsumer()(
  connect(mapStateToProps)(withStyles(styles)(ReduxPinChange)),
);
