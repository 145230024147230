import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import 'react-notifications-component/dist/theme.css';

import { required } from 'helpers/validate';
import { Forms } from 'constants/AppConstants';
import logger from 'helpers/logger';

import BerlinService from '../service';
import OneStepNavigator from '../Commons/OneStepNavigator';
import BerlinSpinner from '../BerlinSpinner';
import { SubmitButton } from 'components/common/Buttons';
import { asyncValidatePostalCode } from '../../../helpers/asyncValidate';
import {
  renderInput,
  renderSelectField,
} from '../Commons/BerlinInputs/FormUtils';
import {
  allowAlphaNumerical,
  allowNumerical,
  allowAlphabetical,
  minLength,
  removeExtraWhiteSpaces,
} from '../utils';
import { BerlinTheme } from '../Theme';
import { processZipCode } from '../redux/actions';

import text from './text';

const styles = (theme) => {
  return {
    root: {
      width: '100%',
      margin: '0',
      minHeight: '600px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: 'white',
      overflow: 'auto',
      paddingTop: '5rem',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      boxSizing: 'border-box',
    },
    form: {
      width: '100%',
    },
    title: {
      fontWeight: 'normal',
      textAlign: 'left',
      width: '100%',
      lineHeight: '1.44rem',
      boxSizing: 'border-box',
      marginBottom: '2rem',
      padding: '0 0 0 0',
    },
    headline: {
      textAlign: 'left',
      fontWeight: '600',
      padding: '0 0 0 0',
      marginBottom: '1rem',
    },
    sectionDescription: {
      padding: '0 0 0 0',
      marginBottom: '1rem',
    },
    button: {
      marginTop: '1rem',
      marginBottom: '2rem',
    },
    fields: {
      marginBottom: '2rem',
    },
    fields50: {
      width: '45%',
      marginBottom: '2rem',
    },
    fields50Container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: 'auto',
      width: '100%',
    },
  };
};

const validateLength3 = minLength(3, text.requiredError);

const validateLength10 = minLength(10, text.requiredError);

const validateLength5 = minLength(5, text.requiredError);

const allowNumericalInput = (maxLength, e) => {
  e.target.value = allowNumerical(maxLength, e.target.value);
};

const allowAlphaNumericalInput = (maxLength, e) => {
  e.target.value = allowAlphaNumerical(maxLength, e.target.value);
};

const allowAlphabeticalInput = (maxLength, e) => {
  e.target.value = allowAlphabetical(maxLength, e.target.value);
};

export const ExistingMerchantsOnboarding = (props) => {
  const { classes, berlin, invalid, handleSubmit, merchant, user, TABS } =
    props;
  const [step, setStep] = useState(0);

  const sendOnboardingRequest = (values) => {
    setStep(1);
    for (let key in values) {
      values[key] = removeExtraWhiteSpaces(values[key]);
    }
    let body = {
      person_representation: {
        name: values.name,
        last_name: values.last_name,
        second_last_name: values.second_last_name,
        phone_number: values.phone_number,
      },
      address_representation: {
        street: values.street,
        internal_number: values.internal_number,
        external_number: values.external_number,
        neighborhood: values.colony,
        zip_code: values.postal_code,
        state: values.state,
        municipality: values.municipality,
        city: values.city,
        country: 'MX',
        reference: {
          house: values.house,
          location: values.location,
        },
      },
      origin: 'NEW_MERCHANT',
      email: user.email,
    };

    BerlinService.postExistingMerchantsOnboarding(merchant.info.id, body)
      .then((response) => {
        props.disableRequestButton();
        props.setTab(TABS.REQUEST_STATUS);
      })
      .catch((error) => {
        logger.info(error);
        store.addNotification({
          title: text.errorTitle,
          message: text.errorMessage,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            showIcon: true,
            duration: 0,
          },
        });
        setStep(0);
      });
  };

  const validateZipcode = (e) => {
    const { dispatch } = props;
    dispatch(
      processZipCode(
        e.target.value,
        Forms.EXISTING_MERCHANTS_REGISTRATION_FORM,
      ),
    );
  };

  return (
    <MuiThemeProvider theme={BerlinTheme}>
      {step === 0 && (
        <OneStepNavigator
          title={text.navigatorTitle}
          stepBackFunction={props.setTab}
          stepBackTarget={TABS.CAROUSEL}
        />
      )}
      <Grid className={classes.root} container spacing={16}>
        <form
          className={classes.form}
          onSubmit={handleSubmit(sendOnboardingRequest)}
        >
          {step === 0 && (
            <React.Fragment>
              <Typography variant="title" className={classes.title}>
                {text.title}
              </Typography>
              <Typography variant="headline" className={classes.headline}>
                {text.contact}
              </Typography>
              <Typography
                variant="subheading"
                className={classes.sectionDescription}
              >
                {text.contactDescription}
              </Typography>
              <Field
                className={classes.fields}
                component={renderInput}
                label={text.nameField}
                name="name"
                validate={[required, validateLength3]}
                type="text"
                onInput={(e) => allowAlphabeticalInput(35, e)}
              />
              <Field
                className={classes.fields}
                component={renderInput}
                label={text.lastNameField}
                name="last_name"
                validate={[required, validateLength3]}
                type="text"
                onInput={(e) => allowAlphabeticalInput(35, e)}
              />
              <Field
                className={classes.fields}
                component={renderInput}
                label={text.secondLastNameField}
                name="second_last_name"
                validate={[required, validateLength3]}
                type="text"
                onInput={(e) => allowAlphabeticalInput(35, e)}
              />
              <Field
                className={classes.fields}
                component={renderInput}
                label={text.phoneField}
                name="phone_number"
                validate={[required, validateLength10]}
                type="text"
                onInput={(e) => allowNumericalInput(10, e)}
                inputProps={{ inputMode: 'numeric' }}
              />
              <Typography variant="headline" className={classes.headline}>
                {text.address}
              </Typography>
              <Typography
                variant="subheading"
                className={classes.sectionDescription}
              >
                {text.receiveYourCard}
              </Typography>
              <Field
                className={classes.fields}
                component={renderInput}
                label={text.streetField}
                name="street"
                validate={[required, validateLength3]}
                type="text"
                onInput={(e) => allowAlphaNumericalInput(34, e)}
              />
              <div className={classes.fields50Container}>
                <Field
                  className={classes.fields50}
                  component={renderInput}
                  label={text.externalNumberField}
                  name="external_number"
                  validate={[required]}
                  type="text"
                  onInput={(e) => allowAlphaNumericalInput(17, e)}
                />
                <Field
                  className={classes.fields50}
                  component={renderInput}
                  label={text.internalNumberField}
                  name="internal_number"
                  type="text"
                  onInput={(e) => allowAlphaNumericalInput(17, e)}
                />
              </div>
              <Field
                className={classes.fields}
                component={renderInput}
                label={text.postalCodeField}
                name="postal_code"
                validate={[required, validateLength5]}
                type="text"
                onInput={(e) => allowNumericalInput(5, e)}
                onChange={validateZipcode}
                inputProps={{ inputMode: 'numeric' }}
              />
              <Field
                className={classes.fields}
                component={renderSelectField}
                label={text.colonyField}
                name="colony"
                validate={[required]}
                options={berlin.colonies}
              />
              <Field
                className={classes.fields}
                component={renderInput}
                label={text.municipalityField}
                name="municipality"
                validate={[required]}
                type="text"
                disabled={true}
              />
              <Field
                className={classes.fields}
                component={renderInput}
                label={text.cityField}
                name="city"
                validate={[required]}
                type="text"
                disabled={true}
              />
              <Field
                className={classes.fields}
                component={renderInput}
                label={text.stateField}
                name="state"
                validate={[required]}
                type="text"
                disabled={true}
              />
              <Typography variant="headline" className={classes.headline}>
                {text.deliveryMessage}
              </Typography>
              <Typography
                variant="subheading"
                className={classes.sectionDescription}
              >
                {text.deliveryQuestion}
              </Typography>
              <Field
                className={classes.fields}
                component={renderInput}
                label={text.deliveryField}
                name="location"
                type="text"
                validate={[required]}
                onInput={(e) => allowAlphaNumericalInput(30, e)}
              />
              <Field
                className={classes.fields}
                component={renderInput}
                label={text.references}
                name="house"
                type="text"
                validate={[required]}
                onInput={(e) => allowAlphaNumericalInput(30, e)}
              />
              <SubmitButton className={classes.button} disabled={invalid}>
                {text.submitButton}
              </SubmitButton>
            </React.Fragment>
          )}
          {step == 1 && <BerlinSpinner />}
        </form>
      </Grid>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    merchant: state.merchant,
    account: state.berlin.account_representation,
    berlin: state.berlin,
  };
};

ExistingMerchantsOnboarding.propTypes = {
  classes: PropTypes.object,
  berlin: PropTypes.object,
  invalid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  merchant: PropTypes.object,
  user: PropTypes.object,
  TABS: PropTypes.object,
};
ExistingMerchantsOnboarding.defaultProps = {
  classes: {},
  berlin: { colonies: [] },
  invalid: false,
  handleSubmit: () => {},
  merchant: {
    info: {
      id: '',
    },
  },
  user: {
    email: '',
  },
  TABS: {
    CAROUSEL: '',
  },
};

const ReduxExistingMerchantsOnboarding = reduxForm(
  {
    form: Forms.EXISTING_MERCHANTS_REGISTRATION_FORM,
    asyncValidate: asyncValidatePostalCode,
    asyncBlurFields: ['postal_code'],
  },
  mapStateToProps,
)(ExistingMerchantsOnboarding);

export default connect(mapStateToProps)(
  withStyles(styles)(ReduxExistingMerchantsOnboarding),
);
