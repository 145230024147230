const styles = (theme) => {
  return {
    buttonBorder: {
      textTransform: 'none !important',
      border:`${theme.spacing.unit * 0.25}px`,
      borderRadius: `${theme.spacing.unit * 5}px`,
      minHeight: theme.spacing.unit * 6.00,
      marginTop: theme.spacing.unit * 3.80,
      marginBottom: theme.spacing.unit * 3.90,
      },
    modal2: {
      width: 'fit-content',
      margin: 'auto',
      padding: '10px',
      position: 'fixed',
      marginTop: '360px',
      left: '0',
      right: '0',
    },
    modalPadding:{
      padding: '20px',
    },
    stepper: {
      marginBottom: '50px',
    },
    spaces: {
      width: '10vw',
      height: theme.spacing.unit * 0.65,
    },
    barContainer: {
      width: 'fit-content',
      height: theme.spacing.unit * 0.65,
      borderRadius: theme.spacing.unit * 0.25,
      margin: 'auto',
    },
    barProgress: (props) => ({
      backgroundColor: theme.palette.indigo.l90,
      width: theme.spacing.unit * 11.86,
      height: theme.spacing.unit * 0.65,
      borderRadius: theme.spacing.unit * 0.25,
      marginLeft: theme.spacing.unit * 1.0,
      float: 'left',
      marginTop: theme.spacing.unit * 1.25,
      [theme.breakpoints.down('xs')]: {
        width: theme.spacing.unit * 5.5,
      },
    }),
    barProgressActive: (props) => ({
      backgroundColor: theme.palette.orange.base,
    }),
    iconButton: {
      padding: 0,
      borderRadius: 'none',
      float: 'left',
      '&:hover': {
        background: 'none',
      },
    },
    iconButtonCa: {
      padding: 0,
      borderRadius: 'none',
      float: 'right',
      '&:hover': {
        background: 'none',
      },
    },
    arrowBack: {
      color: theme.palette.indigo.l90,
    },
    arrowBackActive: {
      color: theme.palette.orange.base,
    },
    closeIcon: {
      color: theme.palette.indigo.base,
    },
    titleAsk: {
      textAlign: 'center',
    }
       
  };
};
export default styles;
