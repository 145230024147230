import { createSelector } from 'reselect';

import { isTokenExpired } from '../tokenHelpers';
import { DashboardStates } from '../../constants/AppConstants';
import * as fromDashboard from '../../reducers/dashboardReducer';
import * as fromMerchant from '../../reducers/merchantReducer';
import * as fromUser from '../../reducers/userReducer';
import * as fromApp from '../../reducers/app';

export const getIsLoading = (state) => fromApp.getIsLoading(state.app);

export const getDashboardState = (state) =>
  fromDashboard.getState(state.dashboard);

export const getMerchantState = (state) =>
  fromMerchant.getState(state.merchant);

export const getUser = (state) => state.user;
export const getUserVerified = (state) => fromUser.getVerification(state.user);
export const getAccessToken = (state) => fromUser.getAccessToken(state.user);
export const getIsLoggedIn = (state) => fromUser.getIsLoggedIn(state.user);
export const getIsUnauthorized = (state) =>
  fromUser.getIsUnauthorized(state.user);

// ReSelectors
export const getIsSessionExpired = createSelector(getAccessToken, (token) =>
  isTokenExpired(token),
);

export const getIsMerchantOnboarding = createSelector(
  getUserVerified,
  getDashboardState,
  (verified, dashboardState) => {
    if (!verified) return false;

    return (
      dashboardState &&
      dashboardState !== DashboardStates.MERCHANT_COMPLETED &&
      dashboardState !== 'merchant completed' &&
      dashboardState !== DashboardStates.READER_ORDERED &&
      dashboardState !== 'reader ordered'
    );
  },
);
