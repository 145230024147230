export default {
  title: 'Historial de crédito',
  description: `Haremos una consulta a tu historial crediticio para que puedas 
  disfrutar de Credi Clip. Por favor contesta las siguientes preguntas:`,
  creditCardSection: '¿Tienes una tarjeta de crédito?',
  creditCardOptions: [
    {
      id: 'true',
      name: 'Si',
    },
    {
      id: 'false',
      name: 'No',
    },
  ],
  creditCardLast4: 'Ingresa los últimos 4 números de tu tarjeta',
  mortgageSection: '¿Has tenido un crédito hipotecario, infonavit o fovissste?',
  mortgageOptions: [
    {
      id: 'true',
      name: 'Si',
    },
    {
      id: 'false',
      name: 'No',
    },
  ],
  automobileSection: '¿Tienes o solicitaste un crédito automotriz?',
  automobileOptions: [
    {
      id: 'true',
      name: 'Si',
    },
    {
      id: 'false',
      name: 'No',
    },
  ],
};
