import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

import errorImg from '../../../../assets/images/berlin/error.svg';
import ProgressNavigator from '../../Commons/ProgressNavigator';

import text from './text';
import styles from './styles';
const useStyles = makeStyles(styles);

export const OnboardingError = ({ error, clearError }) => {
  const classes = useStyles();
  let errorMessage = text.rejected[error];
  if (!errorMessage) {
    errorMessage = text.rejected['UNKNOWN'];
  }

  return (
    <>
      <ProgressNavigator handleClick={() => clearError()} />
      <div className={classes.root}>
        <ClipTypography className={classes.title} variant="h3">
          {errorMessage.title}
        </ClipTypography>
        <img className={classes.LandingImage} src={errorImg} alt="" />
        <ClipTypography className={classes.messsage}>
          {errorMessage.message}
        </ClipTypography>
        <ClipButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => clearError()}
        >
          {text.retry}
        </ClipButton>
      </div>
    </>
  );
};

OnboardingError.propTypes = {
  error: PropTypes.string,
  clearError: PropTypes.func,
};
OnboardingError.defaultProps = {
  error: '',
  clearError: () => {},
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(OnboardingError);
