const styles = (theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: `${theme.spacing(2)}px`,
        marginBottom:`${theme.spacing(1)}px`,
    },
    subsection:{
        marginLeft:`${theme.spacing(1)}px`,
    },
    subtitle:{
        fontWeight:'bold',
        marginTop:`${theme.spacing(2)}px`,
        marginBottom:`${theme.spacing(1)}px`,
    },
    openIcon:{
        transform: 'rotate(45deg)',
    },
    body:{
        whiteSpace:'pre-line',
    },
})

export default styles;
