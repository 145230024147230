const styles = (theme) => ({
  container: {
    margin: '1rem',
    marginTop: '20px',
  },
  imgContainer: {
    textAlign: 'center',
  },
  btnContainer: {
    right: '1rem',
    position: 'fixed',
    bottom: '1rem',
    '& button': {
      marginLeft: '4px',
    },
  },
  info: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  helpIcon: {
    width: '16px',
    marginLeft: '2px',
    marginRight: '14px',
    verticalAlign: 'middle',
  },
  drawer: {
    zIndex: 3001,
  },
  paper: {
    borderRadius: '16px 16px 0 0',
  },
});

export default styles;
