export const styles = (theme) => {
  const rootStyles = {
    container: {
      minHeight: `calc(100vh - ${theme.spacing(12.25)}px)`,
      width: `calc(100% - ${theme.spacing(6)}px)`,
      margin: `${theme.spacing(5.25)}px ${theme.spacing(3)}px
      ${theme.spacing(7)}px ${theme.spacing(3)}px`,
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageBanner: {
      width: theme.spacing(19),
      height: theme.spacing(19),
      marginBottom: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing.unit,
    },
  };

  return { ...rootStyles };
};
