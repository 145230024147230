import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

const ellipsis = () => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const useStyles = makeStyles((theme) => {
  return {
    buttonMenu: (props) => ({
      border: props.switched
        ? `1px solid ${theme.palette.orange.l94}`
        : `1px solid ${theme.palette.indigo.base}`,
      borderRadius: '8px',
      marginRight: '30px',
      width: '189px',
      height: '40px',
      boxSizing: 'border-box',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    }),
    buttonMenuText: (props) => ({
      ...ellipsis(),
      color: props.switched
        ? theme.palette.orange.l94
        : theme.palette.indigo.base,
    }),
    menu: {
      boxShadow: theme.shadows[5],
      width: '268px',
    },
    arrowIcon: {
      position: 'absolute',
      right: 4,
    },
    liName: {
      margin: '24px 16px 4px 16px',
    },
    textName: {
      ...ellipsis(),
    },
    liEmail: {
      margin: '4px 16px 24px 16px',
    },
    textEmail: {
      ...ellipsis(),
    },
    divisor: {
      background: '#E6E8E9',
      height: '1px',
      margin: '0px 16px 16px 16px',
    },
    logout: {
      color: theme.palette.primary.main,
    },
  };
});

export default useStyles;
