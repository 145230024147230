export default {
  retry: 'Reintentar',
  rejected: {
    DOCUMENTS_VALIDATION_ERROR: {
      title: 'Verifica tu información',
      message:
        'Los datos no eran visibles y la calidad de la imágen no es la óptima.',
    },
    DOCUMENTS_INVALID_DATA: {
      title: 'Verifica tu información',
      message:
        'Tus datos personales no coinciden con los de tu identificación.',
    },
    DIGITAL_ONBOARDING_FINISHED: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    DOCUMENTS_NAME_ERROR: {
      title: 'Verifica tu información',
      message:
        'Escribe tu nombre(s) y apellidos tal como aparecen en tu identificación.',
    },
    DOCUMENTS_CURP_ERROR: {
      title: 'Verifica tu información',
      message: 'Tu CURP es distinta a la que aparece en tu identificación.',
    },
    DOCUMENTS_BIRTHDATE_ERROR: {
      title: 'Verifica tu información',
      message:
        'Tu fecha de nacimiento no coincide con la que aparece en tu identificación.',
    },
    DOCUMENTS_GENRE_ERROR: {
      title: 'Verifica tu información',
      message: 'Tu sexo legal tal como aparece en tu identificación.',
    },
    ALREADY_PERSONALIZED_CARD: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    NON_EXISTENT_TRACKING_ID: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    TAMPERED_DOCUMENT: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    INVALID_CURP: {
      title: 'Verifica tu información',
      message: 'Tu CURP es distinta a la que aparece en tu identificación.',
    },
    NO_INE_DATA_FOUND: {
      title: 'No pudimos validar tu identificación',
      message:
        'Procura que tus datos sean legibles en la foto o prueba con otra identificación.',
    },
    UNKNOWN_CODE: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    UNKNOWN: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    NO_ACCESS_ERROR: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    NO_IMAGE_ERROR: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    IMAGE_TRANSFER_ERROR: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    IMAGE_NOT_VALID: {
      title: 'Verifica tu información',
      message:
        'Los datos no eran visibles y la calidad de la imágen no es la óptima.',
    },
    IMAGE_NAME_ERROR: {
      title: 'Verifica tu información',
      message:
        'Escribe tu nombre(s) y apellidos tal como aparecen en tu identificación.',
    },
    DIGITAL_SIGNUP_NOT_RECEIVED: {
      title: 'No pudimos crear tu cuenta.',
      message: 'Por favor, verifica tu información e inténtalo de nuevo.',
    },
    IMAGE_NOT_LEGIBLE: {
      title: 'Verifica tu información',
      message:
        'Los datos no eran visibles y la calidad de la imágen no es la óptima.',
    },
  },
};
