import React, { Component } from 'react';

import { CheckboxContainer, Terms, ErrorMessage, ClipCheckbox } from './styled';

export class TermsCheckbox extends Component {
  render() {
    return (
      <CheckboxContainer style={{ alignItems: 'center' }}>
        <ClipCheckbox
          {...this.props.input}
          {...this.props.rest}
          value={`${this.props.input.value}`}
        />
        <Terms>
          {this.props.labelWithLinks ? (
            this.props.label
          ) : (
            <label>{this.props.label}</label>
          )}
        </Terms>
      </CheckboxContainer>
    );
  }
}
