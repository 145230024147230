import { OnboardingStates, CollaboratorRoles } from 'constants/AppConstants';
import {
  CLEAR_ONBOARDING_STATE,
  SET_ONBOARDING_ACTIVE_STATE,
  SET_ONBOARDING_STATE,
  SUBMIT_BANK_INFORMATION_FULFILLED,
  SUBMIT_BANK_INFORMATION_ERROR,
  GET_BANK_ACCOUNT_FULFILLED,
  UPDATE_BUSINESS_INFO,
  SET_BUSINESS_INFO_ERROR,
  CREATE_COLLABORATOR_FULFILLED,
  CREATE_COLLABORATOR_ERROR,
  GET_COLLABORATORS_FULFILLED,
  DELETE_COLLABORATOR_FULFILLED,
  UPDATE_COLLABORATOR_SUCCESS,
  UPDATE_INSTALLMENTS,
  SET_INSTALLMENTS_ERROR,
  SET_BUSINESS_INFO_LOCATION,
  SET_BUSINESS_INFO_INDUSTRIES,
} from './types';

const initialState = {
  activeState: null,
  personalInformation: {},
  bankAccount: {
    error: null,
    info: null,
  },
  business: {
    location: {
      colonies: [],
    },
    industries: [],
  },
  collaborator: {
    error: null,
    success: false,
    collaborators: {
      cashier: [],
      manager: [],
      finance: [],
    },
  },
  installments: {},
  state: null,
  tabs: [
    OnboardingStates.BUSINESS_INFO,
    OnboardingStates.COLLABORATOR,
    OnboardingStates.INSTALLMENT,
    OnboardingStates.BANK_ACCOUNT_INFO,
  ],
};
//tabs magicas
export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_ONBOARDING_STATE:
      return initialState;
    case SET_ONBOARDING_ACTIVE_STATE:
      return { ...state, activeState: action.payload };
    case SET_ONBOARDING_STATE:
      return { ...state, state: action.payload };
    case SUBMIT_BANK_INFORMATION_FULFILLED:
      return {
        ...state,
        bankAccount: {
          error: null,
          info: action.payload,
        },
      };
    case SUBMIT_BANK_INFORMATION_ERROR:
      return {
        ...state,
        bankAccount: { error: action.payload.error.message, info: null },
      };
    case GET_BANK_ACCOUNT_FULFILLED:
      return { ...state, bankAccount: { error: null, info: action.payload } };
    case UPDATE_BUSINESS_INFO:
      return {
        ...state,
        business: {
          ...state.business,
          error: null,
          receivedData: true,
          data: action.payload,
        },
      };
    case SET_BUSINESS_INFO_ERROR:
      return {
        ...state,
        business: { ...state.business, error: action.payload },
      };
    case CREATE_COLLABORATOR_FULFILLED: {
      const collaborators = { ...state.collaborator.collaborators };
      const user = action.payload.user;
      const role = user.role.toLowerCase();

      switch (role) {
        case CollaboratorRoles.CASHIER:
          collaborators.cashier.push(user);
          break;
        case CollaboratorRoles.MANAGER:
          collaborators.manager.push(user);
          break;
        case CollaboratorRoles.FINANCE:
          collaborators.finance.push(user);
          break;
        // no default
      }

      return {
        ...state,
        collaborator: {
          collaborators: collaborators,
          success: true,
          error: null,
        },
      };
    }
    case CREATE_COLLABORATOR_ERROR:
      return {
        ...state,
        collaborator: {
          ...state.collaborator,
          success: false,
          error: action.payload.error.message,
        },
      };
    case GET_COLLABORATORS_FULFILLED:
      return {
        ...state,
        collaborator: {
          collaborators: action.payload,
          success: false,
          error: null,
        },
      };
    case DELETE_COLLABORATOR_FULFILLED: {
      const collaboratorsDelete = { ...state.collaborator.collaborators };

      switch (action.payload.role) {
        case CollaboratorRoles.CASHIER:
          collaboratorsDelete.cashier.splice(action.payload.index, 1);
          break;
        case CollaboratorRoles.MANAGER:
          collaboratorsDelete.manager.splice(action.payload.index, 1);
          break;
        case CollaboratorRoles.FINANCE:
          collaboratorsDelete.finance.splice(action.payload.index, 1);
          break;
        // no default
      }

      return {
        ...state,
        collaborator: {
          collaborators: collaboratorsDelete,
          success: false,
          error: null,
        },
      };
    }
    case UPDATE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        collaborator: {
          ...state.collaborator,
          success: action.payload,
        },
      };
    case UPDATE_INSTALLMENTS:
      return {
        ...state,
        installments: { error: null, receivedData: true, ...action.payload },
      };
    case SET_INSTALLMENTS_ERROR:
      return {
        ...state,
        installments: {
          ...state.installments,
          error: action.payload,
          receivedData: false,
        },
      };
    case SET_BUSINESS_INFO_LOCATION:
      return {
        ...state,
        business: { ...state.business, location: action.payload },
      };
    case SET_BUSINESS_INFO_INDUSTRIES:
      return {
        ...state,
        industries: action.payload,
      };
    default:
      return state;
  }
}
