import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { LoansDocuments } from 'constants/AppConstants';
import StyledFileUploader from 'components/loans/Commons/StyledFileUploader';
import {
  getLoansIdentityDocuments,
  getLoansIdentityDocumentsBack,
  getLoansPassportDocuments,
} from 'components/loans/redux/selectors';
import text from './text';

export class AttachIdentityDocuments extends Component {
  static propTypes = {
    fileUploaded: PropTypes.func,
    ineFrontRequired: PropTypes.bool.isRequired,
    ineBackRequired: PropTypes.bool.isRequired,
    passportRequired: PropTypes.bool.isRequired,
    identityFiles: PropTypes.array,
    identityFilesBack: PropTypes.array,
    passportFiles: PropTypes.array,
    identityDocumentType: PropTypes.string.isRequired,
    previousLoan: PropTypes.array,
  };

  render() {
    const {
      ineFrontRequired,
      ineBackRequired,
      passportRequired,
      identityFiles,
      identityFilesBack,
      passportFiles,
      identityDocumentType,
      fileUploaded,
      previousLoan,
      newestTitle,
      requiredv4,
    } = this.props;
    const filesCount =
      identityFiles.length + identityFilesBack.length + passportFiles.length;
    const newVersion4 = requiredv4 ? requiredv4 : false;

    let hasPreviousIdentity = false;
    let hasPreviousIdentityBack = false;
    let hasPreviousPassport = false;
    previousLoan &&
      previousLoan.forEach((document) => {
        switch (document.document_type) {
          case 'INE':
            hasPreviousIdentity =
              identityFiles.length > 0 &&
              identityFiles[0].key === document.document_key
                ? true
                : hasPreviousIdentity;
            hasPreviousIdentityBack =
              identityFilesBack.length > 0 &&
              identityFilesBack[0].key === document.document_key
                ? true
                : hasPreviousIdentityBack;
            break;
          case 'PASSPORT':
            hasPreviousPassport =
              passportFiles.length > 0 &&
              passportFiles[0].key === document.document_key
                ? true
                : hasPreviousPassport;
            break;
          default:
            break;
        }
      });

    if (identityDocumentType === LoansDocuments.ineDocumentType) {
      let useNewestTitleFront = newestTitle
        ? text.identityDocumentV4
        : text.identityDocument;
      let useNewestTitleBack = newestTitle
        ? text.identityDocumentBackV4
        : text.identityDocumentBack;
      return (
        <Fragment>
          <StyledFileUploader
            key={'0'}
            title={useNewestTitleFront}
            documentType={LoansDocuments.identity}
            accept={LoansDocuments.acceptFiles}
            maxFiles={LoansDocuments.maxFilesLoaded}
            fieldRequired={ineFrontRequired}
            files={identityFiles}
            fileUploaded={fileUploaded}
            idPrefix={LoansDocuments.idPrefix}
            fileID={1}
            filesCount={filesCount}
            previousLoan={hasPreviousIdentity}
            withIcon={true}
            uploaderVersion={'2'}
            requiredV4={newVersion4}
          />
          <StyledFileUploader
            key={'1'}
            title={useNewestTitleBack}
            documentType={LoansDocuments.identityBack}
            accept={LoansDocuments.acceptFiles}
            maxFiles={LoansDocuments.maxFilesLoaded}
            fieldRequired={ineBackRequired}
            files={identityFilesBack}
            fileUploaded={fileUploaded}
            idPrefix={LoansDocuments.idPrefix}
            fileID={2}
            filesCount={filesCount}
            previousLoan={hasPreviousIdentityBack}
            withIcon={true}
            uploaderVersion={'2'}
            requiredV4={newVersion4}
          />
        </Fragment>
      );
    }
    let useNewestTitle = newestTitle
      ? text.passportDocumentV4
      : text.passportDocument;
    return (
      <StyledFileUploader
        key={'2'}
        title={useNewestTitle}
        documentType={LoansDocuments.passport}
        accept={LoansDocuments.acceptFiles}
        maxFiles={LoansDocuments.maxFilesLoaded}
        fieldRequired={passportRequired}
        files={passportFiles}
        fileUploaded={fileUploaded}
        idPrefix={LoansDocuments.idPrefix}
        fileID={3}
        filesCount={filesCount}
        previousLoan={hasPreviousPassport}
        withIcon={true}
        uploaderVersion={'2'}
        requiredV4={newVersion4}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    identityFiles: getLoansIdentityDocuments(state),
    identityFilesBack: getLoansIdentityDocumentsBack(state),
    passportFiles: getLoansPassportDocuments(state),
  };
};

export default connect(mapStateToProps)(AttachIdentityDocuments);
