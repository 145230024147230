const styles = (theme) => ({
  container: {
    margin: '1rem',
    marginTop: '20px',
  },
  title: {
    marginBottom: 20,
    marginTop: 20,
  },
  btnContainer: {
    right: '1rem',
    position: 'fixed',
    bottom: '1rem',
  },
  btnContainer: {
    position: 'fixed',
    width: '-moz-calc(100% - 2rem)',
    width: '-webkit-calc(100% - 2rem)',
    width: '-o-calc(100% - 2rem)',
    width: 'calc(100% - 2rem)',
    left: '1rem',
    bottom: '1rem',
  },
  btn: {
    width: '100%',
  },
  input: {
    marginBottom: '16px',
  },
  info: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  helpIcon: {
    width: '16px',
    marginLeft: '2px',
    marginRight: '14px',
    verticalAlign: 'middle',
  },
  drawer: {
    zIndex: 3001,
  },
  paper: {
    borderRadius: '16px 16px 0 0',
  },
});

export default styles;
