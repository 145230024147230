import styled from '@emotion/styled';
import { Field } from 'redux-form';
import { FaCalendarAlt } from 'react-icons/fa';

import {
  Divider,
  InputLabel,
  Grid,
  Paper,
  Select,
  TableCell,
  TablePagination,
} from '@material-ui/core';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  PlayArrow,
  Warning as WarningIcon,
} from '@material-ui/icons';
import Card from 'components/common/Card';
import styledWithClasses from 'helpers/StyledWithClasses';

export const TitleLoans = styled.div(({ theme }) => ({
  width: '100%',
  marginBottom: `${theme.spacing.unit * 3}px`,
  fontSize: '1.5rem',
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.naranja.slate,
  '&.ended': {
    color: theme.palette.naranja.lightGreyBrown,
  },
}));

export const LoansDivider = styled(Divider)(({ theme }) => ({
  marginTop: `${theme.spacing.unit * 2.5}px`,
  marginBottom: `${theme.spacing.unit * 4}px`,
}));

export const ExpandLess = styled(ExpandLessIcon)({
  marginTop: '-2px',
});

export const ExpandMore = styled(ExpandMoreIcon)({
  marginTop: '-2px',
});

export const TableContainer = styled(Paper)(({ theme }) => ({
  overflowX: 'auto',
  boxShadow: 'none',
  '& > table': {
    backgroundColor: theme.palette.background.default,
  },
  [theme.breakpoints.down('xs')]: {
    '&::-webkit-scrollbar': {
      width: '16px',
    },
    '&::-webkit-scrollbar-track': {
      WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: '1px solid slategrey',
    },
  },
}));

export const Cell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.naranja.grey,
  fontSize: '16px',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '19px',
  '&.title': {
    borderBottom: 0,
  },
  '&.bold': {
    color: theme.palette.naranja.lightGreyBrown,
  },
  '&.light': {
    fontWeight: theme.typography.fontWeightLight,
  },
}));

export const Form = styled.form(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  marginBottom: `${theme.spacing.unit * 5.4}px`,
  paddingTop: `${theme.spacing.unit * 2}px`,
}));

export const Container = styled.div(({ theme }) => ({
  width: '100%',
  float: 'left',
}));

export const LabelDate = styled(InputLabel)(({ theme }) => ({
  paddingLeft: '5%',
}));

export const FieldDate = styled(Field)(({ theme }) => ({
  maxWidth: '155px',
  '&:last-child': {
    paddingLeft: `${theme.spacing.unit * 0.6}px`,
  },
  '&.left:after': {
    content: '"-"',
    position: 'absolute',
    top: `${theme.spacing.unit * 0.75}px`,
    right: 0,
    height: '100%',
    backgroundColor: theme.palette.common.white,
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: '136px',
    display: 'block',
    float: 'left',
    '&:last-child': {
      maxWidth: '125px',
    },
  },
}));

export const SelectDate = styled(Select)(({ theme }) => ({
  width: '60%',
  marginLeft: '4%',
  '&:after': {
    borderBottom: `2px solid ${theme.palette.naranja.bloodOrange}`,
  },
  '& > div > svg': {
    color: `${theme.palette.naranja.bloodOrange} !important`,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  [theme.breakpoints.down('xs')]: {
    width: '90%',
  },
}));

export const CalendarIcon = styled(FaCalendarAlt)(({ theme }) => ({
  color: theme.palette.naranja.bloodOrange,
  paddingRight: `${theme.spacing.unit * 3}px`,
  [theme.breakpoints.down('xs')]: {
    display: 'block',
  },
}));

export const GridCrediCard = styled(Grid)(({ theme }) => ({
  marginBottom: `${theme.spacing.unit * 3}px`,
}));

export const CrediCard = styledWithClasses(
  styled(Card)(({ theme }) => ({
    label: 'dashboard-card',
    width: '100%',
    height: 'auto',
    paddingBottom: `${theme.spacing.unit * 2}px`,
    '&:last-child, & > .common-card-content-root': {
      display: 'block',
      paddingTop: '0px !important',
      paddingLeft: '1px !important',
      paddingRight: '1px !important',
      paddingBottom: '0 !important',
    },
    '& .common-card-header-root': {
      border: 0,
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    },
    '& .common-card-header-title': {
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.naranja.blueGrey,
    },
    '& .card-header-action': {
      marginRight: '0px',
    },
  })),
  {
    headerAction: 'card-header-action',
  },
);

export const DetailCard = styled.div(({ theme }) => ({
  width: '100%',
  borderTop: `2px solid ${theme.palette.naranja.lightGrey}`,
  float: 'left',
  '&.margin': {
    marginTop: `${theme.spacing.unit * 3.1}px`,
  },
}));

export const DetailCardContent = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  width: '100%',
  float: 'left',
  opacity: 0,
  visibility: 'hidden',
  height: 0,
  transition: 'visibility 0s linear 0.33s',
  '&.selected': {
    opacity: 1,
    visibility: 'visible',
    transitionDelay: '0s',
    height: 'auto',
  },
}));

export const RowDetail = styled.div(({ theme }) => ({
  '&:after': {
    content: '""',
    display: 'table',
    clear: 'both',
  },
}));

export const ResponsiveDetail = styled.div(({ theme }) => ({
  float: 'left',
  width: '49%',
  '&:first-child': {
    '& ul': {
      borderRight: '2px solid #ededed',
    },
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    '&:first-child': {
      '& ul': {
        borderRight: 'none',
      },
    },
    '&:nth-child(2)': {
      '& ul': {
        marginTop: `-${theme.spacing.unit * 2}px`,
        '& li:first-child': {
          marginTop: 0,
        },
      },
    },
  },
}));

export const PayAlert = styled.div(({ theme }) => ({
  maxWidth: '100%',
  minHeight: `${theme.spacing.unit * 4}px`,
  borderRadius: '2px',
  overflowWrap: 'break-word',
  overflow: 'hidden',
  margin: `${theme.spacing.unit * 2}px`,
  marginTop: '0',
  '&.overdue': {
    color: '#dc143c',
    border: 'solid 1px #dc143c',
    backgroundColor: 'rgba(220, 20, 60, 0.1)',
  },
  '&.upcoming': {
    color: '#dc9e00',
    border: 'solid 1px #dc9e00',
    backgroundColor: 'rgba(220, 158, 0, 0.1)',
  },
}));

export const WarningContainer = styled.div(({ theme }) => ({
  width: '3%',
  float: 'left',
  [theme.breakpoints.down('md')]: {
    width: '5%',
  },
  [theme.breakpoints.down('xs')]: {
    width: '10%',
  },
}));
export const Warning = styled(WarningIcon)(({ theme }) => ({
  width: `${theme.spacing.unit * 2}px`,
  height: `${theme.spacing.unit * 2}px`,
  margin: `${theme.spacing.unit}px ${theme.spacing.unit}px 0 ${theme.spacing.unit}px`,
}));

export const TextAlertContainer = styled.div(({ theme }) => ({
  width: '97%',
  float: 'left',
  [theme.breakpoints.down('md')]: {
    width: '95%',
  },
  [theme.breakpoints.down('xs')]: {
    width: '90%',
  },
}));
export const TextAlert = styled.p(({ theme }) => ({
  margin: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px 0`,
  fontSize: `${theme.spacing.unit * 2 - 2}px`,
  span: {
    fontWeight: 500,
  },
}));

export const ProgressBG = styled.div(({ theme }) => ({
  width: `100% - ${theme.spacing.unit * 4}px`,
  height: '24px',
  borderRadius: `${theme.shape.borderRadius * 3.15}px`,
  paddingTop: 0,
  paddingBottom: '1px',
  backgroundColor: `${theme.palette.naranja.teal}30`,
  marginTop: `${theme.spacing.unit * 1.38}px`,
  marginBottom: `${theme.spacing.unit * 1}px`,
  marginLeft: `${theme.spacing.unit * 2}px`,
  marginRight: `${theme.spacing.unit * 2}px`,
}));

export const Progress = styled.span(({ theme }) => ({
  height: '25px',
  display: 'block',
  position: 'relative',
  backgroundColor: `${theme.palette.naranja.teal}`,
  backgroundSize: '200% 200%',
  borderRadius: `${theme.shape.borderRadius * 3.15}px`,
  '&.finished': {
    backgroundColor: `${theme.palette.naranja.grey}`,
  },
}));

export const SeeMoreText = styled.span(({ theme }) => ({
  fontSize: '18px',
  width: '40%',
  fontWeight: theme.typography.fontWeightLight,
  paddingLeft: `${theme.spacing.unit * 2}px`,
  height: '100%',
  display: 'flex',
  float: 'left',
  marginTop: `${theme.spacing.unit * 1.5}px`,
  marginBottom: `${theme.spacing.unit * 1.5}px`,
  [theme.breakpoints.down('xs')]: {
    paddingLeft: `${theme.spacing.unit * 1.5}px`,
    fontSize: '16px',
  },
}));

export const SeeMoreButton = styled.span(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  paddingRight: `${theme.spacing.unit}px`,
  marginTop: `${theme.spacing.unit * 1.5}px`,
  marginBottom: `${theme.spacing.unit * 1.5}px`,
  float: 'right',
  height: '100%',
  display: 'flex',
  cursor: 'pointer',
  '&.finished': {
    color: theme.palette.naranja.lightGreyBrown,
  },
}));

export const ArrowIcon = styled(PlayArrow)(({ theme }) => ({
  transform: 'rotate(0deg)',
  transition: 'transform 100ms linear',
  '&.selected': {
    transform: 'rotate(90deg)',
    transition: 'transform 150ms linear',
  },
}));
export const PaysContainerLeft = styled.div(({ theme }) => ({
  display: 'grid',
  position: 'relative',
  marginLeft: `${theme.spacing.unit * 2}px`,
  float: 'left',
  textAlign: 'left',
}));

export const PaysContainerRight = styled.div(({ theme }) => ({
  display: 'grid',
  position: 'relative',
  marginRight: `${theme.spacing.unit * 2}px`,
  float: 'right',
  textAlign: 'right',
}));

export const DatesContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  marginBottom: `${theme.spacing.unit * 1.75}px`,
}));

export const TermsContainerRight = styled.div(({ theme }) => ({
  width: '65px',
  display: 'grid',
  position: 'relative',
  float: 'right',
  marginRight: '5%',
}));

export const Label = styled.span(({ theme }) => ({
  fontSize: '16px',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: `${theme.spacing.unit * 3.75}px`,
  color: theme.palette.naranja.greyBrown,
  float: 'left',
  display: 'block',
  '&.full': {
    width: '100%',
  },
  '&.bold': {
    fontWeight: '600 !important',
  },
  '&.medium': {
    fontSize: '18px',
  },
  '&.left': {
    width: '100%',
    float: 'left',
    paddingLeft: '5%',
  },
  '&.right': {
    textAlign: 'right',
  },
  '& > .orange': {
    color: theme.palette.naranja.bloodOrange,
    fontWeight: theme.typography.fontWeightMedium,
  },
  [theme.breakpoints.down('xs')]: {
    '&.mobile-full': {
      width: '100%',
    },
  },
}));

export const Value = styled.span(({ theme }) => ({
  display: 'block',
  fontSize: '16px',
  fontWeight: theme.typography.fontWeightLight,
  marginTop: `-${theme.spacing.unit}px`,
  lineHeight: `${theme.spacing.unit * 3.75}px`,
  float: 'left',
  color: theme.palette.naranja.greyBrown,
  '&.upcoming': {
    color: '#dc9e00',
  },
  '&.overdue': {
    color: '#dc143c',
  },
  '&.bold': {
    fontWeight: '400 !important',
  },
  '&.medium': {
    fontSize: '18px',
  },
  '&.right': {
    float: 'left',
  },
  '&.orange': {
    color: theme.palette.naranja.bloodOrange,
  },
  '&.teil': {
    color: theme.palette.naranja.teal,
  },
  '&.finished': {
    color: theme.palette.naranja.lightGreyBrown,
  },
  '& > .bold': {
    fontWeight: 400,
  },
  '&.active': {
    color: '#B98500',
  },
  [theme.breakpoints.down('xs')]: {
    width: '40%',
    '&.full': {
      width: '100%',
    },
    '&.right': {
      float: 'right',
    },
    '&.mobile-full': {
      width: '100%',
    },
  },
}));

export const Leaders = styled.ul(({ theme }) => ({
  maxWidth: '100%',
  overflowX: 'hidden',
  listStyle: 'none',
  padding: 0,
  '& li': {
    marginTop: `${theme.spacing.unit}px`,
    marginBottom: `${theme.spacing.unit}px`,
    padding: '0px',
  },
  '& li:before': {
    float: 'left',
    width: 0,
    whiteSpace: 'nowrap',
    content:
      '". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ' +
      '. . . . . . . . . . . . . . . . . . . . . . . ."',
    [theme.breakpoints.down('xs')]: {
      content:
        '". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . "',
    },
  },
  '& span:first-child': {
    fontWeight: 400,
    paddingRight: '0.16em',
    paddingLeft: `${theme.spacing.unit * 2}px`,
    background: '#FAFAFA',
  },
  '& span + span': {
    fontWeight: 300,
    float: 'right',
    paddingLeft: '0.16em',
    paddingRight: `${theme.spacing.unit * 2}px`,
    background: '#FAFAFA',
  },
  '& li.bolder > span': {
    fontWeight: 600,
  },
}));

export const LeadersHorizontalDivider = styled.hr(({ theme }) => ({
  maxWidth: '95%',
  marginTop: `${theme.spacing.unit * 2}px`,
  border: '1px solid #ededed',
}));

export const LabelValue = styled.div(({ theme }) => ({
  width: '100%',
  paddingRight: '5%',
  paddingBottom: `${theme.spacing.unit * 2.5}px`,
  display: 'inline-block',
  '&.bold': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  [theme.breakpoints.down('xs')]: {
    '&.no-padding': {
      paddingBottom: 0,
    },
  },
}));

export const Percent = styled.span(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.naranja.bloodOrange,
}));

export const CalendarContainer = styled.div(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('xs')]: {
    marginLeft: `${theme.spacing.unit * 0.5}%`,
  },
}));

export const Pagination = styled(TablePagination)(({ theme }) => ({
  '& button >span>svg': {
    color: theme.palette.naranja.cerulean,
  },
  '& button:hover': {
    backgroundColor: `${theme.palette.naranja.cerulean}40`,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'transparent',
    },
  },
  '& button:hover >span:last-child': {
    backgroundColor: 'transparent',
    transition: 'background-color 150ms linear',
  },
  '& button:disabled >span>svg, & button[disabled] >span>svg': {
    color: theme.palette.naranja.grey,
  },
}));

export const HeaderAction = styled.span(({ theme }) => ({
  fontSize: '18px',
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
  },
}));
