import loadable from '@loadable/component';

const Step1 = loadable(() =>
  import('../components/onboarding/v2/Registration'),
);
const Step2 = loadable(() => import('../components/onboarding/v2/EmailSent'));
const Step3 = loadable(() =>
  import('../components/onboarding/v2/EmailValidation'),
);
const Step4 = loadable(() =>
  import('../components/onboarding/v2/PersonalInfo'),
);
const Step5 = loadable(() =>
  import('../components/onboarding/v2/BusinessInfo'),
);
const Step6 = loadable(() => import('../components/onboarding/v2/Welcome'));

export const newOnboardingRoute = '/onboard';
export const onboardRegister = `${newOnboardingRoute}/register`;
const onboardEmailSent = `${newOnboardingRoute}/emailSent`;

const usersRoute = '/users';
const onboardingRoute = '/onboarding';
const onboardMerchant = '/onboardMerchant';
const onboardMerchantPersonalInfo = `${onboardMerchant}/PersonalInfo`;
const onboardMerchantBusinessInfo = `${onboardMerchant}/BusinessInfo`;
const onboardMerchantWelcome = `${onboardMerchant}/Welcome`;
const clipWeb = 'https://clip.mx';
const clipMkt = 'https://mkt.clip.mx';

export const publicOnboardRoutes = [
  {
    route: onboardRegister,
    title: 'Registra tus datos',
    component: Step1,
  },
  {
    route: onboardEmailSent,
    title: 'Registra tus datos',
    component: Step2,
  },
  {
    route: '/onboarding/validate/:validationId',
    title: 'Registra tus datos',
    component: Step3,
  },
];

export const protectedOnboardRoutes = [
  {
    route: onboardMerchantPersonalInfo,
    title: 'Registra tus datos',
    component: Step4,
  },
  {
    route: onboardMerchantBusinessInfo,
    title: 'Cuéntanos sobre tu negocio',
    component: Step5,
  },
  {
    route: onboardMerchantWelcome,
    title: 'Bienvenida',
    component: Step6,
  },
];

export default {
  // Social
  facebook: 'https://www.facebook.com/clipmx',
  twitter: 'https://twitter.com/clip_mx',
  youtube: 'https://www.youtube.com/user/clipmx/',
  instagram: 'https://www.instagram.com/clip.mx/',

  // App stores
  appStore: 'https://itunes.apple.com/app/id771245360',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.payclip.clip',
  deepLink: '/app',

  // Compropago
  compropagoReceiptState: 'https://compropago.com/comprobante/estado',

  // External
  clipPhone: 'https://api.whatsapp.com/send?phone=525563932323',
  clipHelpEmail: 'help@clip.mx',
  clipHomePage: `${clipWeb}`,
  clipFindStore: `${clipWeb}/tiendas/`,
  clipDevices: `${clipWeb}/dispositivos/`,
  clipCommissions: `${clipWeb}/costos-y-comisiones/`,
  clipPrivacy: `${clipWeb}/privacidad/`,
  clipTerms: `${clipWeb}/terminos/`,
  clipTermsReferrals: `${clipMkt}/terminos-condiciones-programa-referidos-deposito`,
  clipReferralCashv1Terms:
    'https://mkt.clip.mx/terminos-condiciones-programa-referidos-deposito',
  clipReferralCashv2Terms:
    'https://mkt.clip.mx/terminos-condiciones-programa-referidos-deposito-nuevo-dispositivo',
  docusignHowToSign:
    'https://www.docusign.mx/recursos/centro-de-asistencia/como-puedo-firmar-un-documento-de-docusign',
  clipSupport: 'https://ayuda.clip.mx',
  clipContact: `${clipWeb}/#contacto`,
  clipAbout: `${clipWeb}/nosotros`,
  clipNews: `${clipWeb}/noticias`,

  // Accounts
  signIn: `${usersRoute}/sign_in`,
  signUp: '/sign_up',
  simpleSignUp: '/simple_sign_up',
  newPassword: `${usersRoute}/password/new`,
  editPassword: `${usersRoute}/password/edit`,
  resetPasswordSent: `${usersRoute}/password/reset/sent`,
  resetPasswordSuccess: `${usersRoute}/password/reset/success`,
  bankAccountConfirmation: '/merchant/bankaccount/cancel_activation',
  bankAccountCancelActivationSuccess:
    '/merchant/bankaccount/cancel_activation/success',

  // Catalog
  catalogConfirmPilotInvitation: '/catalog/pilot-invitation-confirmation',
  manageCatalog: '/catalog',
  catalogNewItem: '/catalog/product/new',
  catalogEditItem: '/catalog/product/edit/:itemId',
  catalogBulkUpload: '/catalog/import',
  catalogPublish: '/catalog/publish',

  // Invoices
  manageInvoices: '/invoices',

  // Referrals
  referralLanding: '/refer',
  referralWelcome: '/refer/:code/:benefit',
  referralProgram: '/referral/:code',
  referralTerms: '/refer/terminos',
  merchantsReferred: '/refer/mis-referidos',

  // Onboarding
  onboarding: `${onboardingRoute}`,
  onboardEmailSent,
  onboardMerchant: onboardMerchant,
  promo: `${onboardingRoute}/promo`,
  BANK_ACCOUNT_INFO: `${onboardingRoute}/bank_information`,
  PERSONAL_INFO: onboardMerchantPersonalInfo,
  BUSINESS_INFO: onboardMerchantBusinessInfo,
  WELCOME: onboardMerchantWelcome,
  COLLABORATOR: `${onboardingRoute}/collaborators`,
  INSTALLMENT: `${onboardingRoute}/installments`,
  SUMMARY: `${onboardingRoute}/summary`,
  REGISTRATION_VALIDATION: `${onboardingRoute}/validate/:validationId`,
  REGISTRATION_VALIDATION_HELP: `${onboardingRoute}/validate`,

  // Dashboard
  dashboard: '/dashboard',
  transactions: '/transactions',
  transactionsReports: '/transactions/reports',
  transactionDetails: '/transactions/:id',
  topUps: '/top_ups',
  topUpsReports: '/top_ups/reports',
  topUpDetails: '/top_ups/:id',
  merchantUsers: '/merchant_users',
  merchantUsersNew: '/merchant_users/new',
  merchantUsersEdit: '/merchant_users/:id/edit',
  me: '/me',
  marketingTools: '/marketing_tools',
  businessInformation: '/account_information/merchant',
  bankAccounts: '/bank_accounts',
  bankAccountsNew: '/bank_accounts/new',
  bankAccountsRequestPassword: '/bank_accounts/request_password',
  clipReaders: '/orders',
  dailyDepositReports: '/deposits/daily',
  dailyDepositReport: '/deposits/daily/:id/:date',
  dailyDepositReportLedger: '/deposits/daily/:id/:date',
  merchantDevices: '/devices',
  monthlyDepositReports: '/deposits/monthly',
  monthlyDepositReport: '/deposits/monthly/:id/:date',
  promos: '/promos',
  myStore: '/mystore',
  myStoreWebview: '/mitienda',

  mobile: '/mobile',

  // BUM
  bum: '/business_unitsm',
  bumInvite: '/business_unitsm/invite',
  bumAcceptInvite: '/business_unitsm/acceptInvite/:token',

  //Loans
  loans: '/loans',
  preapprovalsExpiredV3: '/loans/offers/:lender/expired/v3',
  preapprovalsExpiredV4: '/loans/offers/:lender/expired/v4',
  inreview: '/loans/inreview',
  termConditionsLoans: '/loans/terms',
  loansFAQs: '/loans/faqs',
  loansCanceled: '/loans/:lender/canceled',

  //sams
  loansSams: '/loans/:lender/sams/v3/ca',
  loansSamsConfirmed: '/loans/:lender/sams/v3/ca/confirmed',

  // Open data
  loansOpenDataElegible: '/loans/elegible',
  loansOpenDataNotElegible: '/loans/notelegible',
  loansOpenDataOffers: '/loans/mot/opendata',
  loansOpenDataConfirmation: '/loans/mot/opendata/confirmation',
  loansOpenDataInterested: '/loans/notelegible/interested',
  loansOpenDataApproved: '/loans/mot/opendata/congrats',
  loansOpenDataInReview: '/loans/mot/opendata/inreview',

  //congrats
  congrats: '/loans/:lender/congrats',
  congratsPersonalized: '/loans/personalized/:lender/congrats',
  loansCongratsForSign: '/loans/:lender/congrats/forsign',
  loansCongratsSigned: '/loans/:lender/congrats/signed',
  loansRefillCongrats: '/loans/:lender/congrats/refill',
  loansCongratsAction: '/loans/:lender/congrats/:action',
  congratsVersion: '/loans/:lender/:version/congrats',
  congratsVersionAction: '/loans/:lender/:version/congrats/:action',
  loansCongratsPersonalizedAction:
    '/loans/personalized/:lender/congrats/:action',
  congratsPersonalizedVersion: '/loans/personalized/:lender/:version/congrats',
  congratsPersonalizedVersionAction:
    '/loans/personalized/:lender/:version/congrats/:action',

  // V3 - Base
  loansV3: '/loans/:lender/v3',
  loansFormIdentityV3: '/loans/offer/identity/:lender/v3',
  loansFormHistoryV3: '/loans/offer/history/:lender/v3',
  loansFormAddressV3: '/loans/offer/address/:lender/v3',
  loansFormDepositV3: '/loans/offer/deposit/:lender/v3',

  // V3 Cash Advance - Base
  loansV3CA: '/loans/:lender/v3/ca',
  loansFormIdentityV3CA: '/loans/offer/identity/:lender/v3/ca',
  loansFormHistoryV3CA: '/loans/offer/history/:lender/v3/ca',
  loansFormAddressV3CA: '/loans/offer/address/:lender/v3/ca',
  loansFormDepositV3CA: '/loans/offer/deposit/:lender/v3/ca',

  // V4 - Base
  loansV4: '/loans/:lender/v4',
  loansPersonalizedV4: '/loans/personalized/:lender/v4',
  loansFormIdentityV4: '/loans/offer/identity/:lender/v4',
  loansFormHistoryV4: '/loans/offer/history/:lender/v4',
  loansFormAddressV4: '/loans/offer/address/:lender/v4',
  loansFormDepositV4: '/loans/offer/deposit/:lender/v4',

  // V4 Cash Advance - Base
  loansV4CA: '/loans/:lender/v4/ca',
  loansPersonalizedV4CA: '/loans/personalized/:lender/v4/ca',
  loansFormIdentityV4CA: '/loans/offer/identity/:lender/v4/ca',
  loansFormHistoryV4CA: '/loans/offer/history/:lender/v4/ca',
  loansFormAddressV4CA: '/loans/offer/address/:lender/v4/ca',
  loansFormDepositV4CA: '/loans/offer/deposit/:lender/v4/ca',

  // My CrediClip
  loansMyCrediClipPersonalInformation: '/loans/mycrediclip',
  loansMyCrediClipAddress: '/loans/mycrediclip',
  loansMyCrediClipDocumentation: '/loans/mycrediclip',
  loansMyCrediClip: '/loans/mycrediclip',
  loansMyPrestaClip: '/loans/myprestaclip',

  loansMrPrestaAdvancePayment: '/loans/advancepayment/mrpresta',
  loansMOTAdvancePayment: '/loans/advancepayment/mot',
  loansAPHAdvancePayment: '/loans/advancepayment/aph',
  loansCTOAdvancePayment: '/loans/advancepayment/cto',
  loansR2AdvancePayment: '/loans/advancepayment/r2',
  loansAVLAdvancePayment: '/loans/advancepayment/avl',
  loansRefill: '/loans/:lender/refill',
  loansRefillConfirmation: '/loans/:lender/refill/confirmation',
  loansRefillInReview: '/loans/refill/inreview',
  loansPersonalizedConfirmation: '/loans/:lender/personalized/confirmation',
  loansPersonalizedInReview: '/loans/personalized/inreview',

  pad: '/pad',
  paymentRequest: '/paymentrequest',
  paymentRequestPendingPayments: '/paymentrequest/pending',
  paymentRequestPendingPaymentDetail: '/paymentrequest/pending/:id',
  paymentLinkPage: '/link_page',
  editLinkPage: '/link_page/edit',
  checkoutClip: '/checkout_clip',

  // Codi
  codi: '/codi',
  codiReports: '/codi/reports',
  codiDetails: '/codi/:id',

  // 404
  notFound: '*',

  // Berlin
  berlin: '/berlin',
  berlinBalance: '/berlin/balance',
  berlinAuth: '/berlin/auth',
  berlinRegistration: '/berlin/registration',
  berlin2: '/berlin2',
  berlinRegistration2: '/berlin/registration2',
  berlinDeactivated: '/berlin/deactivated',
  berlinWaitingList: '/berlin/waitingList',
  berlinActivation2: '/berlin/activation2',
  berlinUpgrade: '/berlin/account/upgrade',
  berlinSwap: '/berlin/account/swap/upgrade',
  berlinClipCard: '/berlin/clipcard',
  berlinClipCardRequest: '/berlin/card-request',
  berlinClipCardActivation: '/berlin/activation',
  berlinAccount: '/berlin/account',
  berlinCda: '/berlin/cda',

  // external redirects
  index: '/',
  wpSite: '/wp_site',
  terms: '/terminos',
  usTerms: '/terms',
  privacy: '/privacidad',
  usPrivacy: '/privacy',

  // health check
  health: '/health',
};
