import styled from '@emotion/styled';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { Field } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiFormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    display: 'inline-block',
    backgroundColor: theme.palette.naranja.iceBlue,
    opacity: 1,
    maxWidth: '400px',
    border: '1px solid #dadde9',
    paddingBottom: `${theme.spacing.unit * 1.5}px`,
  },
  popper: {
    opacity: 1,
  },
}))(Tooltip);

export const TitleTooltip = styled.span(({ theme }) => ({
  width: '100%',
  float: 'left',
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: `${theme.spacing.unit * 3}px`,
  color: theme.palette.naranja.slate,
  marginTop: `${theme.spacing.unit * 1}px`,
  marginBottom: `${theme.spacing.unit * 3}px`,
  textAlign: 'center',
}));

export const INELink = styled.a(({ theme }) => ({
  color: theme.palette.naranja.bloodOrange,
  fontSize: `${theme.spacing.unit * 1.5}px`,
  float: 'left',
  cursor: 'pointer',
  display: 'flex',
  '& > p': {
    flex: 1,
    marginRight: `${theme.spacing.unit * 0.5}px`,
  },
  '& > svg': {
    margin: 'auto',
    width: `${theme.spacing.unit * 2}px`,
    height: `${theme.spacing.unit * 2}px`,
    flex: 1,
  },
}));

export const Identification = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  float: 'left',
  paddingTop: `${theme.spacing.unit * 1.25}px`,
  paddingBottom: `${theme.spacing.unit * 1.25}px`,
}));

export const ImageIdentification = styled.img({
  width: '80%',
});

export const FormField = styled(Field)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginBottom: `${theme.spacing.unit}px`,
    '& label': {
      fontSize: `${theme.typography.fontSize}px`,
      paddingLeft: 0,
    },
  },
}));

export const FormControl = styled(MuiFormControl)(({ theme }) => ({
  width: '100%',
  display: 'block',
  float: 'left',
  marginBottom: `${theme.spacing.unit}px`,
  marginTop: `${theme.spacing.unit}px`,
  '& legend': {
    textAlign: 'left',
    marginTop: `${theme.spacing.unit}px`,
    color: `${theme.palette.naranja.slate}`,
  },
  '&:first-child': {
    marginTop: 0,
    marginBottom: `${theme.spacing.unit}px`,
  },
}));

export const DoubleInput = styled(MuiFormControl)(({ theme }) => ({
  width: '100%',
  float: 'left',
  display: 'block',
  flexDirection: 'unset',
  '& > div input': {
    paddingLeft: 0,
  },
  '& > div:nth-child(1)': {
    width: '48%',
    marginRight: '2%',
    float: 'left',
    marginBottom: `${theme.spacing.unit * 2}px`,
  },
  '& > div:nth-child(2)': {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      marginTop: `${theme.spacing.unit * 1}px`,
      marginBottom: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& > div:nth-child(1), & > div:nth-child(2)': {
      width: '100%',
      marginRight: 0,
    },
  },
}));

export const StepTitle = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  fontSize: `${theme.spacing.unit * 2.5}px`,
  fontWeight: 600,
  color: '#001724',
}));

export const StepDescription = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 2}px`,
  textAlign: 'left',
  fontSize: `${theme.spacing.unit * 2}px`,
  color: '#001724',
}));

export const StepSection = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 3}px`,
  textAlign: 'left',
  fontSize: `${theme.spacing.unit * 1.75}px`,
  color: '#001724',
}));

export const OrangeRadio = withStyles((theme) => ({
  root: {
    color: `${theme.palette.naranja.grey}`,
    '&$checked': {
      color: `${theme.palette.naranja.bloodOrange}`,
    },
    '&:hover': {
      color: `${theme.palette.naranja.bloodOrange}`,
      backgroundColor: `${theme.palette.naranja.bloodOrange}40`,
      transition: 'background-color 150ms linear',
    },
  },
  checked: {
    '& + span': {
      color: `${theme.palette.text.primary}`,
    },
  },
}))(Radio);

export const RadioFields = styled.div(({ theme }) => ({
  width: '100%',
  '& label': {
    width: '100%',
    margin: 0,
    marginTop: `${theme.spacing.unit}px`,
    borderRadius: `${theme.spacing.unit * 0.5}px`,
    border: 'solid 1px #ccd0d3',
  },
}));

export const TextFieldV2 = styled(Field)(({ theme }) => ({
  '& label': {
    paddingLeft: `${theme.spacing.unit * 2}px`,
    margin: 'auto 0',
  },
  '& input': {
    paddingLeft: `${theme.spacing.unit * 2}px`,
    width: '100%',
    fontSize: `${theme.spacing.unit * 1.75}px`,
    fontWeight: 500,
    color: '#001724',
  },
  '& p': {
    marginTop: '0',
    paddingTop: '4px',
    paddingLeft: '12px',
    width: `calc(100% - ${theme.spacing.unit * 1.5}px)`,
    height: `${theme.spacing.unit * 2.25}px`,
    borderBottomLeftRadius: `${theme.spacing.unit * 0.5}px`,
    borderBottomRightRadius: `${theme.spacing.unit * 0.5}px`,
    backgroundColor: '#ffe7e7',
    color: '#7c0b0b!important',
    fontSize: `${theme.spacing.unit * 1.5}px`,
  },
}));

export const FormControlRadioV2 = withStyles((theme) => ({
  label: {
    fontSize: `${theme.spacing.unit * 1.75}px`,
    color: theme.palette.grey[500],
    fontWeight: 500,
  },
}))(FormControlLabel);
