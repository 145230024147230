export const styles = (theme) => {
  const rootStyles = {
    container: {
      minHeight: `calc(100vh - ${theme.spacing(12.25)}px)`,
      width: `calc(100% - ${theme.spacing(4)}px)`,
      margin: `${theme.spacing(5.25)}px ${theme.spacing.unit * 2}px
        ${theme.spacing.unit * 7}px ${theme.spacing.unit * 2}px`,
    },
    buttonContainer: {
      marginTop: theme.spacing(1.5),
      '& button': {
        width: '50%',
      },
    },
    typographyContent: {
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px 0`,
    },
    lottie: {
      marginBottom: theme.spacing(2),
    },
  };
  return { ...rootStyles };
};
