import React, { useState } from 'react';
import text from './text';
import Info from './info';
import moment from 'moment';
import Drawer from './drawer';
import Lottie from 'lottie-react';
import Proptypes from 'prop-types';
import BerlinService from '../../service';
import ClipAlert from '@clipmx/clip-storybook/dist/components/Alert';
import Money from '@clipmx/clip-storybook/dist/components/Icons/Money';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { Forms } from '../../../../constants/AppConstants';
import ClockTime from '@clipmx/clip-storybook/dist/components/Icons/ClockTime';
import Confetti from '../../../../assets/images/berlin/lottie/SpeiOutSucces/data.json';
import { styles } from './styles';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Grid, Paper } from '@material-ui/core';
import { toCurrency } from 'helpers/formatCurrency';
import { ClipTypography, ClipButton } from '@clipmx/clip-storybook';
import { Clear } from '@clipmx/clip-storybook/dist/components/Icons';

const useStyles = makeStyles(styles);

const Voucher = (props) => {
  const classes = useStyles(props);
  const {
    name,
    clabe,
    concept,
    alias,
    amount,
    bank,
    fromNewContact,
    contactSaved,
    action,
    reference,
    date,
    closeFlow,
    speiError,
  } = props;

  const [drawerState, setDrawerState] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const saveContact = async () => {
    const body = {
      recipient_account: clabe,
      nickname: alias,
      recipient_name: name,
      recipient_bank: bank,
    };
    try {
      await BerlinService.postSaveContact(body);
      setIsSaved(true);
      setDrawerState(false);
      succesAlert();
    } catch (error) {
      setIsSaved(false);
      console.log(error);
    }
  };

  const succesAlert = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const getSpeiDate = (date) => {
    var stillUtc = moment.utc(date).toDate();
    var local = moment(stillUtc).local().format(`D MMMM YYYY [ ${text.transferDateConnector} ] HH:mm:ss A`);
    return local;
  }

  return (
    <Grid container className={classes.container}>
      {speiError === false && (
        <Lottie
          animationData={Confetti}
          className={classes.lottieAnimation}
          loop={false}
        />
      )}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {showAlert && (
            <ClipAlert className={classes.alert}>{text.success}</ClipAlert>
          )}
          <div className={classes.firstCard}>
            <ClockTime
              className={speiError === false ? classes.icon : classes.iconError}
            />
            <ClipTypography variant={'subtitle1'} fontWeight={'bold'}>
              {speiError === false ? text.validate : text.failed}
            </ClipTypography>
            <ClipTypography
              variant={'h3'}
              fontWeight={'bold'}
              className={classes.amount}
            >
              {toCurrency(amount)}
            </ClipTypography>
            <ClipTypography variant={'body2'}>Comisión: $0.00</ClipTypography>
            <ClipTypography variant={'body2'} className={classes.date}>
              {getSpeiDate(date)}
            </ClipTypography>
          </div>
          <div className={classes.secondCard}>
            <Info prop={text.addressee} value={name} />
            <Info
              prop={text.clabe}
              value={`*${clabe.substr(clabe.length - 4)}`}
            />
            <Info prop={text.bank} value={bank} />
            <Info prop={text.concept} value={concept} />
            <Info prop={text.reference} value={reference} />
          </div>
        </Paper>
        <div className={classes.footer}>
          {speiError && (
            <div className={classes.money}>
              <Money color={'secondary'} className={classes.moneyIcon} />
              <ClipTypography
                variant={'body2'}
                color={'secondary'}
                fontWeight={'semiBold'}
              >
                {text.money}
              </ClipTypography>
            </div>
          )}
          {contactSaved === false &&
            fromNewContact === true &&
            isSaved === false && (
              <ClipButton
                fullWidth
                color="primary"
                variant="outlined"
                className={classes.saveContact}
                onClick={() => setDrawerState(true)}
              >
                {text.saveContact}
              </ClipButton>
            )}
          <ClipButton
            onClick={speiError === false ? closeFlow : action}
            fullWidth
            className={classes.exit}
            color="primary"
            variant="contained"
          >
            {speiError === false ? text.getOut : text.tryAgain}
          </ClipButton>
          {speiError === false && (
            <ClipTypography
              variant={'body2'}
              className={classes.status}
              color={'textSecondary'}
              fontWeight={'semiBold'}
            >
              {text.status}
            </ClipTypography>
          )}
        </div>
        <ClipDrawer
          anchor="bottom"
          open={drawerState}
          classes={{ root: classes.drawer, paper: classes.paperDrawer }}
          onClose={() => {
            setDrawerState(false);
          }}
        >
          <Clear
            className={classes.clear}
            onClick={() => setDrawerState(false)}
          />
          <Drawer savedContact={() => saveContact()} />
        </ClipDrawer>
      </Grid>
    </Grid>
  );
};

Voucher.propTypes = {
  closeFlow: Proptypes.func,
  speiError: Proptypes.bool,
  reference: Proptypes.string,
  date: Proptypes.string,
  action: Proptypes.func,
};

const selector = formValueSelector(Forms.BERLIN_SPEI_OUT);

const mapStateToProps = (state) => {
  return {
    account: state.berlin.account_representation,
    user: state.user,
    balance: state.berlin.cardBalance,
    berlin: state.berlin,
    ldFlags: state.berlin.ldFlags,
    merchant: state.merchant,
    alias: selector(state, 'alias'),
    clabe: selector(state, 'clabe'),
    bank: selector(state, 'bank'),
    name: selector(state, 'name'),
    amount: selector(state, 'amount'),
    concept: selector(state, 'concept'),
    fromNewContact: selector(state, 'fromNewContact'),
    contactSaved: selector(state, 'contactSaved'),
  };
};

export default connect(mapStateToProps)(Voucher);
