import axios from 'axios';

import { app } from '../config/config';
import apiPaths from '../helpers/apiPaths';
import { getRequestOptions } from '../helpers/fetchUtil';
import logger from '../helpers/logger';

import {
  CLEAR_REFERRAL_REGISTRATION_CODE,
  CLEAR_REFERRAL_STATE,
  SET_REFERRAL_REGISTRATION_CODE,
  REFERRAL_CODE_ATTEMPT,
  SEND_REFERRAL_CODE_EMAIL,
  SET_MERCHANT_STATE,
  REGISTERED_WITH_REFERRAL_CODE,
  SET_REFERRAL_AGGREGATES,
  SET_REFERRER_BENEFIT,
} from './Types';

/* Clears the referral redux state */
export function clearReferralState() {
  return { type: CLEAR_REFERRAL_STATE };
}

/* Clears the referral redux state */
export function clearReferralCode() {
  return { type: CLEAR_REFERRAL_REGISTRATION_CODE };
}

// Updates the referral code in redux state from url param if the code is valid */
export function setReferralCode(code) {
  return (dispatch) => {
    return isReferralCodeValid(code)
      .then((res) => {
        dispatch({ type: SET_REFERRAL_REGISTRATION_CODE, payload: res });
      })
      .catch((err) => {
        logger.error('Error setting referral code: ', err);
      });
  };
}

// verify the referral code and updates redux state code and adds 1 to attempt
export function verifyReferralCodeAttempt(code) {
  return (dispatch) => {
    return isReferralCodeValid(code)
      .then((res) => {
        dispatch({ type: REFERRAL_CODE_ATTEMPT, payload: res });
        return res;
      })
      .catch((err) => {
        logger.error('Error validating referral code: ', err);
      });
  };
}

// verifies the referral code
export function isReferralCodeValid(code) {
  const options = getRequestOptions();
  const url = `${app.apiUrlv2}${apiPaths.verifyReferralCode}/${code}`;

  return axios
    .get(url, options)
    .then((res) => res.data)
    .catch((err) => {
      logger.error(err);
      return false;
    });
}

export function sendMerchantReferralCodeEmail(code) {
  return (dispatch) => {
    dispatch({
      type: SEND_REFERRAL_CODE_EMAIL,
      payload: { code, status: 'sending' },
    });

    const { headers } = getRequestOptions();

    const options = {
      method: 'POST',
      url: `${app.apiUrlv2}${apiPaths.sendMerchantReferralCodeEmail}`,
      data: { referral_code: code },
      json: true,
      headers,
    };

    return axios(options)
      .then((res) => {
        dispatch({
          type: SEND_REFERRAL_CODE_EMAIL,
          payload: { code, status: 'sent', response: res.body },
        });
      })
      .catch((err) => {
        dispatch({
          type: SEND_REFERRAL_CODE_EMAIL,
          payload: { code, status: 'failure', response: err.message },
        });
      });
  };
}

// checks to see if merchant registered with a referral code
export function didRegisterWithReferralCode() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrlv2}${apiPaths.registeredReferralCode}`;

    return axios
      .get(url, options)
      .then((res) => {
        dispatch({ type: REGISTERED_WITH_REFERRAL_CODE, payload: res.data });
      })
      .catch((err) => {
        logger.error(err);
        return false;
      });
  };
}

// Marks merchant as ACTIVATED
export function merchantOnboardingComplete() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrlv2}${apiPaths.onboardingCompleted}`;
    const request = axios.create(options);

    return request
      .post(url)
      .then((res) => {
        dispatch({
          type: SET_MERCHANT_STATE,
          payload: res.data.merchantStatus,
        });
      })
      .catch((err) => {
        logger.error(err);
        return false;
      });
  };
}

// checks to see if merchant registered with a referral code
export function getReferralAggregates() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrlv2}${apiPaths.referralAggregates}`;

    return axios
      .get(url, options)
      .then((res) => {
        if (res.data) {
          dispatch({ type: SET_REFERRAL_AGGREGATES, payload: res.data });
        }
      })
      .catch((err) => {
        logger.error(err);

        return false;
      });
  };
}

// Updates the referral code and benefit in redux state if the code is valid
export function setReferrerBenefit(code) {
  return (dispatch) => {
    return isReferralCodeValid(code)
      .then((res) => {
        dispatch({ type: SET_REFERRER_BENEFIT, payload: res });
      })
      .catch((err) => {
        logger.error('Error getting referrer benefit: ', err);
      });
  };
}
