export const styles = (theme) => {
  const rootStyles = {
    container: {
      height: `calc(100vh - ${theme.spacing(7)}px)`,
      overflow: 'hidden',
      marginTop: theme.spacing(5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      background: theme.palette.indigo.l94,
    },
    bottomContainer: {
      height: `calc(100vh - ${theme.spacing(30.87)}px)`,
      maxHeight: `calc(100vh - ${theme.spacing(30.87)}px)`,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      overflowY: 'auto',
    },
    bottomContainerDrawer: {
      height: '50vh',
      maxHeight: '50vh',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      overflowY: 'auto',
    },
    searchInput: {
      height: theme.spacing(4),
      padding: 0,
    },
    input: {
      padding: `${theme.spacing(2.25)}px 
            ${theme.spacing(0.25)}px 
            ${theme.spacing(2)}px 
            ${theme.spacing(3.625)}px !important`,
      fontSize: theme.spacing(1.75),
    },
    inputIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    positionEnd: {
      transform: `translate(${theme.spacing(1)}px, ${theme.spacing(
        2.375,
      )}px) scale(1)`,
    },
    speiOutButton: {
      width: '100%',
      height: theme.spacing(6.75),
      padding: 0,
      marginBottom: theme.spacing(3),
      backgroundColor: `${theme.palette.common.white}`,
      border: `${theme.spacing(0.125)}px solid ${theme.palette.indigo.l90}`,
      boxShadow: `0px ${theme.spacing(0.25)}px ${theme.spacing(1)}px
              -${theme.spacing(0.25)}px rgba(102, 115, 123, 0.25)`,
      borderRadius: theme.spacing(1.5),
      display: 'flex',
      justifyContent: 'center',
    },
    actionButtonIcon: {
      margin: 'auto 0',
      color: theme.palette.orange.base,
      marginRight: theme.spacing(1.3),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    actionButtonText: {
      margin: 'auto 0',
      marginLeft: theme.spacing(1),
    },
    contactsTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    actionTitle: {
      fontSize: theme.spacing(3),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    contactsTitle: {
      fontWeight: 500,
      marginLeft: theme.spacing(1.25),
      fontSize: theme.spacing(2.25),
      color: theme.palette.indigo.l30,
    },
    contactsIcon: {
      color: theme.palette.indigo.l30,
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
    emptyTitle: {
      color: theme.palette.indigo.l30,
      fontSize: theme.spacing(2),
      marginTop: theme.spacing(3),
    },
    changeContainer: {
      width: '100%',
    },
    listItem: {
      fontSize: `${theme.spacing(2)}px`,
      marginBottom: `${theme.spacing(3)}px`,
    },
    editIcon: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      stroke: theme.palette.orange.base,
      strokeWidth: 2,
      color: 'transparent',
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(9),
    },
    dialogSecondary: {
      marginRight: '0px!important',
    },
    dialogPrimary: {
      padding: '16px 18px 16px 18px',
    },
    dialogPaper: {
      margin: 12,
      maxWidth: 'calc(100% - 25px)',
      textAlign: 'center',
    },
    divider: {
      backgroundColor: 'transparent',
    },
  };
  return { ...rootStyles };
};
