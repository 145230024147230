import React, { Fragment, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, clearFields } from 'redux-form';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import ClipButton from '@clipmx/clip-storybook/dist/components/Button';
import ClipTypography from '@clipmx/clip-storybook/dist/components/Typography';
import ClipDialog from '@clipmx/clip-storybook/dist/components/Dialog';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';
import ClipChip from '@clipmx/clip-storybook/dist/components/Chip';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import { Close as CloseIcon2 } from '@clipmx/clip-storybook/dist/components/Icons';
import {
  LocalPhoneOutlined,
  Delete as DeleteIcon,
  Warning,
} from '@clipmx/clip-storybook/dist/components/Icons';

import TextField from '../../../../../helpers/FormUtils/TextField';
import {
  required,
  isNumeric,
  haveLength,
  optionalNumber,
} from '../../../../../helpers/validate';
import urls from '../../../../../helpers/urls';
import { Forms } from '../../../../../../src/constants/AppConstants';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import clsx from 'clsx';
import ClevertapComponent from '../../../../../helpers/Clevertap/ClevertapComponent';
import { lenderPayload } from '../../Clevertap';
import { Clevertap, EVENTS } from '../../../../../helpers/Clevertap';
import { styles } from '../styles';
import text from '../text';
import LoansService from 'components/loans/service';
import logger from 'helpers/logger';
import { FaBullseye } from 'react-icons/fa';



const useStyles = makeStyles(styles);

export const RenderExtraPhonesV4 = (props) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [isValidateError, setIsValidateError] = useState(false);
  const [isSendError, setIsSendError] = useState(false);
  const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(true);
  const [isServiceError, setIsServiceError ] = useState(false);
  const [counter, setCounter] = useState(0);
  const browserHistory = useLocation();

  let {
    fields,
    extraTelephone,
    codeValidation,
    checkPhoneFilled,
    clearFields,
    handleExtraPhonesFilled,
    preapprovalSelected,
    showCashAdvance,
    classesV4,
    loansFormVersion,
  } = props;

  const classes = useStyles(props);

  useEffect(() => {
    let timer;
    if (displayTooltip || isDialogOpen) {
      timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    }

    return () => clearInterval(timer);
  }, [counter]);

  const isMobileWebView = browserHistory.pathname.startsWith(urls.mobile);

  const sendCode = () => {
    setIsValidateError(false);
    setIsSendError(false);
    setIsInvalidCode(false);
    setIsServiceError(false);
    clearFields(Forms.MR_PRESTA, false, false, 'codeValidation');
    setResendButtonIsDisabled(true);
    LoansService.sendPhoneVerificationCode({
      phone_number: extraTelephone,
    })
      .then((response) => {
        let time = response.data.ttl_in_seconds;
        setCounter(time);
      })
      .catch((err) => {       
        setCounter(0);
        const sendStatusCode = err.response ? err.response.status : 500;
        if (sendStatusCode === 500) {
          setIsServiceError(true)
        } else {
          setIsSendError(true);
        }
      })
      .finally(() => {
        setResendButtonIsDisabled(false);
      });
  };

  const verifyCode = () => {
    return LoansService.validatePhoneVerificationCode({
      phone_number: extraTelephone,
      verification_code: codeValidation,
    }).then((response) => {
      let valid = response.data.is_valid;
      return valid;
    });
  };

  const validationContent = (displayCloseIcon) => {
    return (
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltipTitleContainer}>
          <ClipTypography
            variant={'h3'}
            component={'h2'}
            align={'left'}
            fontWeight={'bold'}
            classes={{
              root: classes.tooltipTitle,
            }}
          >
            {text.tooltipTitle}
          </ClipTypography>
          {displayCloseIcon && (
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={() => {
                setDisplayTooltip(false);
              }}
              onClose={() => window.scrollTo(0, 0 + 15)}
            >
              <CloseIcon2
                fontSize="small"
                classes={{ root: classes.closeIcon }}
              />
            </IconButton>
          )}
        </div>
        <ClipTypography variant={'body1'} component={'p'} align={'left'}>
          {text.codeValidationLabel}
        </ClipTypography>

        <ClipTypography
          variant={'h3'}
          component={'p'}
          fontWeight={'semiBold'}
          align={'left'}
          onClick={sendCode}
        >
          {extraTelephone}
        </ClipTypography>

        <ClipTypography
          variant={'body1'}
          component={'p'}
          align={'left'}
          classes={{ root: classes.tooltipCodeLabel }}
        >
          {text.codeValidationInstruction}
        </ClipTypography>
        <Field
          name="codeValidation"
          component={TextField}
          inputProps={{ maxLength: 4 }}
          type="text"
          label={text.codeValidation}
          validate={[optionalNumber, haveLength(4)]}
          onFocus={(e) => setIsInvalidCode(false)}
        />

        <ClipButton
          variant={'outlined'}
          color={'primary'}
          classes={{
            root: classes.tooltipCodeResendButton,
            disabled: classes.tooltipCodeResendButtonDisabled,
          }}
          disabled={counter > 0 || resendButtonIsDisabled}
          onClick={sendCode}
        >
          {text.codeValidationResend(counter)}
        </ClipButton>

        {isSendError && (
          <ClipTypography
            variant={'body2'}
            component={'p'}
            align={'left'}
            className={classes.warningContainer}
          >
            {text.sendError}
          </ClipTypography>
        )}
        {isValidateError && (
          <ClipTypography
            variant={'body2'}
            component={'p'}
            align={'left'}
            className={classes.warningContainer}
          >
            {text.validateError}
          </ClipTypography>
        )}
        {isInvalidCode && (
          <ClipTypography
            variant={'body2'}
            component={'p'}
            align={'left'} 
            className={classes.warningContainer}
          >
            {text.invalidCode}
          </ClipTypography>
        )}
        {isServiceError && (
          <ClipTypography
          variant={'body2'}
          component={'p'}
          align={'left'}
          className={classes.warningContainer}
        >
          {text.errorService}
        </ClipTypography>
        )}
        <Grid container classes={{ root: classes.tooltipButtonContainer }}>
          <Grid item xs={12} sm={6}>
            <ClipButton
              variant={'contained'}
              color={'primary'}
              disabled={
                !codeValidation ||
                !parseInt(codeValidation) ||
                codeValidation.length != 4
              }
              onClick={() => {
                setIsValidateError(false);
                setIsSendError(false);
                setIsInvalidCode(false);
                verifyCode()
                  .then((res) => {
                    if (res) {
                      Clevertap.event(
                        EVENTS.LOANS.VALIDATION_CODE_COMPLETED,
                        null,
                        lenderPayload(preapprovalSelected, {
                          showCashAdvance,
                        }),
                      );
                      fields.push(extraTelephone);
                      clearFields(Forms.MR_PRESTA, false, false, 'extraTelephone',);
                      clearFields(Forms.MR_PRESTA, false, false, 'codeValidation',);
                      displayCloseIcon
                        ? setDisplayTooltip(false)
                        : setIsDialogOpen(false);
                    } else {
                      setCounter(0);
                      setIsInvalidCode(true);
                    }
                  })
                  .catch((err) => {
                    setIsValidateError(true);
                    setCounter(0);
                    clearFields(Forms.MR_PRESTA, false, false, 'codeValidation',);
                  });
              }}
              onClose={() => window.scrollTo(0, 0 + 15)}
            >
              {text.codeValidationAddNumber}
            </ClipButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ClipButton
              variant={'text'}
              color={'primary'}
              onClick={() => {
                clearFields('codeValidation');
                displayCloseIcon
                  ? setDisplayTooltip(false)
                  : setIsDialogOpen(false);
              }}
              onClose={() => window.scrollTo(0, 0 + 15)}
            >
              {text.codeValidationCancel}
            </ClipButton>
          </Grid> 
        </Grid>
      </div>
    );
  };

  const Drawer = (props) => {
    return (
      <ClipDrawer anchor={'bottom'} open={displayTooltip}>
        {validationContent(true)}
      </ClipDrawer>
    );
  };

  const telephoneChip = ({
    input,
    label,
    type,
    meta: { touched, error },
    index,
  }) => (
    <ClipChip
      label={input.value}
      onDelete={() => fields.remove(index)}
      deleteIcon={<DeleteIcon />}
      variant="outlined"
      classes={{
        root: classes.chipContainer,
      }}
    />    
  );

  const endAdornment = (index) => (
    <Button
      className={classes.buttonV2Dismiss}
      onClick={() => fields.remove(index)}
    >
      <CloseIcon />
    </Button>
  );

  const dialogFunctionality = () => {
    return (
      <ClipDialog
        title=""
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        type="form"
        classes={{ root: classes.rootDialog }}
      >
        {validationContent(false)}
      </ClipDialog>
    );
  };

  const handleClick = () => {
    if (!extraTelephone) {
      checkPhoneFilled();
      return;
    }
    sendCode();
    isMobileWebView ? setDisplayTooltip(true) : setIsDialogOpen(true);
  };

  return (
    <Fragment>
      {fields.length < 5 && (
        <ClevertapComponent
          renderComponent={
            <ClipButton
              variant="outlined"
              color="primary"
              disabled={
                !(extraTelephone) ||
                !(parseInt(extraTelephone)) ||
                (extraTelephone.length != 10) ||
                (!(props.extraTelephones)
                  ? false
                  :(props.extraTelephones.includes(extraTelephone)))
                  || !(/^\d+(\.\d{1,9})?$/).test(extraTelephone)
              }
            />
          }
          startIcon={<LocalPhoneOutlined />}
          className={clsx(classes.addButtonV4Styles, classes.addButtonV3Styles)}
          onClick={handleClick}
          events={[
            {
              callbackName: 'onClick',
              event: EVENTS.LOANS.VALIDATION_PAGE_VIEWED,
              payload: lenderPayload(preapprovalSelected, {
                showCashAdvance,
              }),
            },
          ]}
        >
          {text.addPhoneV4}
        </ClevertapComponent>
      )}
      <div className={classes.oneSpace}>
        <ClipTypography variant={'body2'} component={'p'} align={'left'}>
          {text.telephonesAdded}
        </ClipTypography>
      </div>
      {handleExtraPhonesFilled && fields.length === 0 && (
        <ClipTypography
          variant={'body2'}
          component={'p'}
          align={'left'}
          className={classes.warningContainer}
        >
          <Warning />
          {text.telephonesAddedWarning}
        </ClipTypography>
      )}
      {fields.length === 0 && (
        <ClipTypography
          variant={'body2'}
          component={'p'}
          align={'left'}
          classes={{ body2: classes.infoLabel }}
        >
          {text.telephonesAddedError}
        </ClipTypography>
      )}
      <div
        className={clsx({
          [classes.extraTelephonesContainer]: fields.length > 0,
        })}
      >
        {fields.map((extraPhone, index) => (
          <div className={classes.extraFieldContainer} key={index}>
            <Field
              name={extraPhone}
              component={telephoneChip}
              index={index}
              inputProps={{ maxLength: 10 }}
              type="text"
              label={text.phoneLabelV4}
              validate={[required, isNumeric, haveLength(10)]}
              endAdornment={endAdornment(index)}
            />
          </div>
        ))}
      </div>
      {isMobileWebView && Drawer()}
      {!isMobileWebView && dialogFunctionality()}
    </Fragment>
  );
};

const selector = formValueSelector(Forms.MR_PRESTA);
const mapStateToProps = (state) => {
  return {
    extraTelephone: selector(state, 'extraTelephone'),
    extraTelephones: selector(state, 'extraTelephones'),
    codeValidation: selector(state, 'codeValidation'),
    preapprovalSelected: state.loans.request?.preapproval,
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  clearFields,
};

RenderExtraPhonesV4.propTypes = {
  classes: PropTypes.object.isRequired,
  extraTelephones: PropTypes.array,
  fields: PropTypes.object,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenderExtraPhonesV4,
);