import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { renderInput, renderSelectField } from 'helpers/FormUtils';
import {
  required,
  isIndustry,
  isAlphanumericWithAccents,
  notRequiredNumber,
  isEmpty,
  maxLength,
} from 'helpers/validate';
import utils from 'helpers/utils';
import { Forms } from 'constants/AppConstants';
import { showAddressTime } from 'constants/ProviderCodes';
import { cleanLastOneProofOfAddress } from 'components/loans/redux/actions';
import {
  getLoansColonies,
  getPreapprovalSelected,
  getLastOne,
  hasLastOne,
} from 'components/loans/redux/selectors';
import * as Styled from 'components/loans/Commons/Form/styled';
import textPersonalInfo from 'components/loans/Commons/PersonalInformation/text';
import text from './text';

export class AddressInformation extends Component {
  state = {
    yearOptions: utils.getYearOptions(),
    monthOptions: textPersonalInfo.monthsOptions,
    addressType: text.howlongHouseAddress,
    documentsDeleted: false,
  };

  setAddressType = (event) => {
    const addressType =
      event.target.value === `${text.myBusinessValue}`
        ? text.howlongOfficeAddress
        : text.howlongHouseAddress;
    this.setState({ addressType });
  };

  getFullYear = (day) => day.getFullYear();

  getMonthNumber = (day) => day.getMonth() + 1;

  monthGreaterThanToday = (month, year) => {
    const { month: birthdayMont, year: bithdayYear } = this.props;
    const currentDate = new Date();
    const actualYear = this.getFullYear(currentDate);
    const actualMonth = this.getMonthNumber(currentDate);
    return (
      (year === actualYear && parseInt(month) > actualMonth) ||
      (year === bithdayYear && parseInt(month) < birthdayMont)
    );
  };

  monthGreaterThanTodayValidation = (addressMonth, values) => {
    const { addressYear } = values;
    return this.monthGreaterThanToday(addressMonth, addressYear)
      ? text.invalidMonth
      : undefined;
  };

  deleteProofOfAddressDocuments = () => {
    const { previousLoans, cleanLastOneProofOfAddress } = this.props;
    if (previousLoans && !this.state.documentsDeleted) {
      cleanLastOneProofOfAddress();
      this.setState({ documentsDeleted: true });
    }
  };

  render() {
    const {
      addressYear,
      preapprovalSelected = {},
      year: yearSelected,
      street,
      streetNumber,
      optionalStreetNumber,
      postalCode,
      colony,
      municipality,
      state,
      colonies,
      hasLastOne,
      lastOne,
    } = this.props;
    const { provider_code } = preapprovalSelected;
    const isPrefilledByMerchantInfo = hasLastOne && !lastOne.pre_approval_id;
    return (
      <React.Fragment>
        <Styled.FormControl fullWidth>
          <Styled.TextField
            autoFocus
            className={
              isPrefilledByMerchantInfo &&
              postalCode &&
              postalCode === lastOne.address_information.postal_code &&
              'preloaded'
            }
            name="postalCode"
            component={renderInput}
            inputProps={{ maxLength: 5, minLength: 5 }}
            type="text"
            label={text.postalCode}
            autoComplete="postalCode"
            validate={[required]}
            onChange={this.deleteProofOfAddressDocuments}
          />
          {isEmpty(postalCode) && (
            <Styled.FormAdvice>{text.adviceCP}</Styled.FormAdvice>
          )}
        </Styled.FormControl>
        <Styled.FormControl margin="normal" fullWidth>
          <Styled.DoubleInput>
            <Styled.FormField
              className={
                isPrefilledByMerchantInfo &&
                colony &&
                colony === lastOne.address_information.colony &&
                'preloaded'
              }
              name="colony"
              component={renderSelectField}
              label={text.neighborhood}
              validate={[required, isIndustry]}
              options={colonies}
              onChange={this.deleteProofOfAddressDocuments}
            />
            <Styled.FormField
              className={
                isPrefilledByMerchantInfo &&
                municipality &&
                municipality === lastOne.address_information.municipality &&
                'preloaded'
              }
              name="municipality"
              component={renderInput}
              inputProps={{ readOnly: true }}
              type="text"
              label={text.delegation}
              validate={[required]}
              onChange={this.deleteProofOfAddressDocuments}
            />
          </Styled.DoubleInput>
        </Styled.FormControl>
        <Styled.FormControl margin="normal" fullWidth>
          <Styled.TextField
            className={
              isPrefilledByMerchantInfo &&
              state &&
              state === lastOne.address_information.state &&
              'preloaded'
            }
            name="state"
            component={renderInput}
            inputProps={{ readOnly: true }}
            type="text"
            label={text.city}
            validate={[required]}
            onChange={this.deleteProofOfAddressDocuments}
          />
        </Styled.FormControl>
        <Styled.FormControl margin="normal" fullWidth>
          <Styled.TextField
            className={
              isPrefilledByMerchantInfo &&
              street &&
              street === lastOne.address_information.street &&
              'preloaded'
            }
            name="street"
            component={renderInput}
            inputProps={{ maxLength: 45 }}
            type="text"
            label={text.street}
            validate={[required, isAlphanumericWithAccents]}
            helperText={maxLength(45)(street)}
            onChange={this.deleteProofOfAddressDocuments}
          />
        </Styled.FormControl>
        <Styled.FormControl margin="normal" fullWidth>
          <Styled.DoubleInput>
            <Styled.TextField
              className={
                isPrefilledByMerchantInfo &&
                streetNumber &&
                streetNumber ===
                  lastOne.address_information.street_no_exterior &&
                'preloaded'
              }
              name="streetNumber"
              component={renderInput}
              inputProps={{ maxLength: 20 }}
              type="text"
              label={text.streetNumber}
              validate={[required, isAlphanumericWithAccents]}
              helperText={maxLength(20)(streetNumber)}
              onChange={this.deleteProofOfAddressDocuments}
            />
            <Styled.TextField
              className={
                isPrefilledByMerchantInfo &&
                optionalStreetNumber &&
                optionalStreetNumber ===
                  lastOne.address_information.street_no_interior &&
                'preloaded'
              }
              name="optionalStreetNumber"
              component={renderInput}
              inputProps={{ maxLength: 20 }}
              label={text.interiorNumber}
              validate={[notRequiredNumber]}
              helperText={maxLength(20)(optionalStreetNumber)}
              onChange={this.deleteProofOfAddressDocuments}
            />
          </Styled.DoubleInput>
        </Styled.FormControl>
        {showAddressTime(provider_code) && (
          <Styled.FormControl margin="normal" fullWidth>
            <Styled.CompoundFormLabel component="legend">
              {this.state.addressType}
            </Styled.CompoundFormLabel>
            <Styled.DoubleInput>
              <Styled.FormField
                component={renderSelectField}
                label={textPersonalInfo.year}
                name="addressYear"
                options={this.state.yearOptions.map((year) => ({
                  ...year,
                  disabled: year.id < yearSelected,
                }))}
                validate={[required]}
              />
              <Styled.FormField
                component={renderSelectField}
                label={textPersonalInfo.month}
                name="addressMonth"
                options={this.state.monthOptions.map((month) => ({
                  ...month,
                  disabled: this.monthGreaterThanToday(month.id, addressYear),
                }))}
                validate={[required, this.monthGreaterThanTodayValidation]}
              />
            </Styled.DoubleInput>
          </Styled.FormControl>
        )}
      </React.Fragment>
    );
  }
}

const selector = formValueSelector(Forms.MR_PRESTA);

const mapStateToProps = (state) => {
  return {
    preapprovalSelected: getPreapprovalSelected(state),
    colonies: getLoansColonies(state),
    year: selector(state, 'year'),
    month: selector(state, 'month'),
    addressYear: selector(state, 'addressYear'),
    street: selector(state, 'street'),
    streetNumber: selector(state, 'streetNumber'),
    optionalStreetNumber: selector(state, 'optionalStreetNumber'),
    colony: selector(state, 'colony'),
    municipality: selector(state, 'municipality'),
    state: selector(state, 'state'),
    lastOne: getLastOne(state),
    hasLastOne: hasLastOne(state),
  };
};

const mapDispatchToProps = { cleanLastOneProofOfAddress };

export default connect(mapStateToProps, mapDispatchToProps)(AddressInformation);
