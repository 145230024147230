export default {
  uploadPhoto: 'Sube una foto de tu identificación vigente',
  findAPlace:
    'Busca un espacio iluminado y coloca tu INE o pasaporte en una superficie plana.',
  whiteBackgroundReminder:
    'Usa un fondo blanco o neutro para facilitar la legibilidad de tus datos.',
  idCard: 'Credencial de elector (INE)',
  passport: 'Pasaporte',
  readTerms1: 'He leído y acepto los',
  readTerms2: 'y el',
  readTerms3: 'de Clip Card',
  createAccountButton: 'Crear Cartera Clip',
  safeInformation: 'Tu información personal está segura',
};
