// import * as AppConstants from '../constants/AppConstants';
// import reduxStore from 'helpers/reduxStore';

const getUrl = function (url) {
  /* const { locale } = reduxStore.getState();
  console.log(locale);
  const route = locale === AppConstants.Locales.ES_MX ? `/us/${url}` : url;
  */

  return url;
};

export const getProviderCashAdvanceURL = (
  showCashAdvance,
  providerCode,
  cashAdvanceURL,
  simpleURL,
) => {
  return showCashAdvance
    ? cashAdvanceURL.replace(':lender', providerCode)
    : simpleURL.replace(':lender', providerCode);
};

export default getUrl;
