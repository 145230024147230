import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import ReactNotification, { store } from 'react-notifications-component';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-notifications-component/dist/theme.css';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import BerlinService from '../service';
import { setAccountRep } from '../redux/berlinActions';
import { ACCOUNT, ROUTES } from '../constants';
import { BerlinSpinner } from '../BerlinSpinner';
import rejectedCopy from '../Registration/CreatingAccount/text';
import ExistingMerchantsOnboarding from '../ExistingMerchantsOnboarding';
import { setLaunchDarklyFlags } from '../redux/actions';
import RequestedCardStatus from '../RequestedCardStatus';
import Maintenance from '../Maintenance';

import { getConfigValue } from '../../../config/config';
import * as Styled from './styled';
import text from './text';

import landingDrawing from '../../../assets/images/berlin/landingDrawing.svg';
import clipAccountLogo from '../../../assets/images/berlin/clipAccountLogo.svg';
import clock from '../../../assets/images/berlin/clock.png';
import carousel2 from '../../../assets/images/berlin/carousel2.png';
import landingHand from '../../../assets/images/berlin/landingHand.svg';
import landingCamera from '../../../assets/images/berlin/landingCamera.svg';
import landingClock from '../../../assets/images/berlin/landingClock.svg';
import activeIcon from '../../../assets/images/berlin/activeIcon.svg';
import moneyDrawing from '../../../assets/images/berlin/moneyDrawing.svg';
export const TABS = {
  MAINTENANCE: 'MAINTENANCE',
  CAROUSEL: 'CAROUSEL',
  CREATING_ACCOUNT: 'CREATING_ACCOUNT',
  SPINNER: 'SPINNER',
  REQUEST_CLIPCARD: 'REQUEST_CLIPCARD',
  REQUEST_STATUS: 'REQUEST_STATUS',
};
export class LandingCarousel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      waitingForActivation: false,
      activeTab: TABS.SPINNER,
      registeringUser: false,
      disableRequestButton: false,
      maintenanceChecked: false,
      landingDisabled: false,
      flagsLoaded: false,
      redirectionFinished: false,
    };
    this.props.history.push('/mobile/berlin2');
  }

  routeOnClick = (url) => {
    this.props.history.push(url);
  };

  componentWillUnmount() {
    clearInterval(this.poll);
  }

  pollAccountStatus = () => {
    const { merchant } = this.props;
    BerlinService.accountInformationV2(merchant.info.id).then((response) => {
      this.props.dispatch(
        setAccountRep(response.data.message.card_representation),
      );
      if (response.data.message.account_status === ACCOUNT.STATUS.CREATED) {
        this.routeOnClick(ROUTES.ACTIVATION);
      } else if (
        response.data.message.account_status === ACCOUNT.STATUS.REJECTED
      ) {
        clearInterval(this.poll);
        this.setActiveTab(TABS.CAROUSEL);
        const { card_status_description = 'UNKNOWN' } =
          response.data.message.card_representation[0];
        store.addNotification({
          title: rejectedCopy.rejected[card_status_description].title,
          message: rejectedCopy.rejected[card_status_description].message,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
        });
      }
    });
  };

  getLaunchDarklyFlags = () => {
    return new Promise((resolve) => {
      const { merchant, dispatch } = this.props;
      const LDClient = require('launchdarkly-js-client-sdk');
      const proxy_merchant_token = merchant.info.proxy_id
        ? merchant.info.proxy_id.proxy_merchant_token
        : null;
      const user = {
        key: proxy_merchant_token || null,
      };
      const ldClient = LDClient.initialize(
        getConfigValue('launchDarklyKey'),
        user,
      );
      ldClient.on('ready', async () => {
        const enableRequestCard = ldClient.variation(
          getConfigValue('launchDarklyFinancialRequestClipcardAvailableWeb'),
          false,
        );
        const enableChooseNip = ldClient.variation(
          getConfigValue('launchDarklyFinancialChooseNipAvailableWeb'),
          false,
        );
        const enableChangePin = ldClient.variation(
          getConfigValue('launchDarklyFinancialChangepinAvailable'),
          false,
        );
        const enableSpeiOut = ldClient.variation(
          getConfigValue('launchDarklyFinancialSpeiOutAvailable'),
          false,
        );
        const enableCvv = ldClient.variation(
          getConfigValue('launchDarklyFinancialDynamicCvvAvailableWeb'),
          false,
        );
        const showPrintedNip = ldClient.variation(
          getConfigValue('launchDarklyFinancialCheckPrintedNipAvailableWeb'),
          false,
        );
        const enableSpeiGeolocation = ldClient.variation(
          getConfigValue(
            'launchDarklyFinancialGeolocationRequiredSpeiOutAvailableWeb',
          ),
          false,
        );
        const onboardingRequestTimeout = ldClient.variation(
          getConfigValue('launchDarklyFinancialOnboardingRequestTimeoutWeb'),
          60000,
        );
        const enableCdaLogin = ldClient.variation(
          getConfigValue('launchDarklyFinancialCdaAvailableWeb'),
          false,
        );
        const enableIdPhotoV2 = ldClient.variation(
          getConfigValue('launchDarklyFinancialUploadPhotoV2AvailableWeb'),
          false,
        );
        const termsAndConditionsPdfSrc = ldClient.variation(
          getConfigValue('launchDarklyFinancialTermsAndConditionsPdfSrcWeb'),
          'https://drive.google.com/file/d/1eNLPiYPi5jzcUEUcQHSLg0HtvztbOlqU/preview',
        );

        dispatch(
          setLaunchDarklyFlags({
            enableRequestCard,
            enableChooseNip,
            enableChangePin,
            enableSpeiOut,
            enableCvv,
            showPrintedNip,
            enableSpeiGeolocation,
            enableCdaLogin,
            onboardingRequestTimeout,
            enableIdPhotoV2,
            termsAndConditionsPdfSrc,
          }),
        );
        resolve();
      });
    });
  };

  getMerchantEnrollment = () => {
    const { merchant } = this.props;
    return BerlinService.getMerchantEnrollmentStatus(merchant.info.id).then(
      (response) => {
        this.setState({ disableRequestButton: true });
      },
    );
  };

  setMaintenanceTab() {
    this.setState({ activeTab: TABS.MAINTENANCE });
    this.setState({ maintenanceChecked: true });
  }

  async componentDidUpdate(prevProps) {
    const {
      merchant,
      ldClient,
      flags: { financialGeneralMaintenanceAvailableWeb },
    } = this.props;
    const {
      activeTab,
      maintenanceChecked,
      landingDisabled,
      flagsLoaded,
      redirectionFinished,
    } = this.state;

    if (
      merchant.info.proxy_id &&
      merchant.info.proxy_id.proxy_merchant_token === ldClient.getUser().key
    ) {
      financialGeneralMaintenanceAvailableWeb !== 'null' && !maintenanceChecked
        ? this.setMaintenanceTab()
        : this.setState({ maintenanceChecked: true });

      if (!flagsLoaded) {
        await this.getLaunchDarklyFlags();
        this.setState({ flagsLoaded: true });
      }
      if (
        !landingDisabled &&
        maintenanceChecked &&
        merchant.info.id.length > 1 &&
        activeTab !== TABS.MAINTENANCE &&
        !redirectionFinished
      ) {
        this.landingRedirection(merchant);
        this.setState({ redirectionFinished: true });
      }
    }
  }

  landingRedirection = (merchant) => {
    const {
      flags: {
        financialShowOnboardingV2AvailableWeb,
        financialChooseNipAvailableWeb,
      },
    } = this.props;

    if (financialShowOnboardingV2AvailableWeb) {
      clearInterval(this.poll);
      this.props.history.push('/mobile/berlin2');
      return
    }
    BerlinService.accountInformationV2(merchant.info.id)
      .then((response) => {
        this.props.dispatch(setAccountRep(response.data.message));
        switch (response.data.message.account_status) {
          case ACCOUNT.STATUS.ACTIVATED:
            if (financialChooseNipAvailableWeb) {
              response.data.message.card_representation[0]
                .pin_activation_counter > 0
                ? this.routeOnClick(ROUTES.BALANCE)
                : this.routeOnClick(ROUTES.ACTIVATION);
            } else {
              this.routeOnClick(ROUTES.BALANCE);
            }
            break;
          case ACCOUNT.STATUS.CREATED:
            this.routeOnClick(ROUTES.ACTIVATION);
            break;
          case ACCOUNT.STATUS.CAPTURED:
            this.setActiveTab(TABS.CREATING_ACCOUNT);
            this.poll = setInterval(this.pollAccountStatus, 5000);
            break;
          case ACCOUNT.STATUS.REJECTED:
            this.setActiveTab(TABS.CAROUSEL);
            break;
          default:
            this.setActiveTab(TABS.MAINTENANCE);
        }
      })
      .catch(() => {
        this.getMerchantEnrollment().finally(() => {
          this.setActiveTab(TABS.CAROUSEL);
        });
      });
  };

  componentDidMount() {
    const { account, merchant } = this.props;
    if (account) {
      switch (account.account_status) {
        case ACCOUNT.STATUS.ACTIVATED:
          this.routeOnClick(ROUTES.BALANCE);
          break;
        case ACCOUNT.STATUS.CREATED:
          this.routeOnClick(ROUTES.ACTIVATION);
          break;
        case ACCOUNT.STATUS.CAPTURED:
          this.poll = setInterval(this.pollAccountStatus, 5000);
          this.setActiveTab(TABS.CREATING_ACCOUNT);
          break;
        default:
          return;
      }
    } else {
      if (merchant.info.id.length > 1) this.setActiveTab(TABS.CAROUSEL);
    }
  }

  setActiveTab(activeTab) {
    this.setState({ activeTab: activeTab });
  }

  postToWaitingList = () => {
    const { merchant, user } = this.props;
    const body = {
      email: user.email,
    };
    this.setState({ registeringUser: true });
    BerlinService.postWaitingList(merchant.info.id, body)
      .then(() => {
        this.setState({ registeringUser: false });
        store.addNotification({
          title: 'Registro exitoso',
          message:
            'Nos pondremos en contacto contigo para enviarte tu Clip Card.',
          type: 'success',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      })
      .catch((error) => {
        this.setState({ registeringUser: false });
        if (error.response.status === 422) {
          store.addNotification({
            title: 'Usuario ya registrado',
            message:
              'Sabemos que la espera es difícil pero espera un poco, un poquito más.',
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      });
  };
  renderRequestButton = () => {
    if (this.state.disableRequestButton) {
      return (
        <Styled.WhiteSubmitButton
          onClick={() => {
            this.setState({ landingDisabled: true });
            this.setActiveTab(TABS.REQUEST_STATUS);
          }}
        >
          {text.requestStatus}
        </Styled.WhiteSubmitButton>
      );
    }
    return (
      <Styled.WhiteSubmitButton
        onClick={() => {
          this.setState({ landingDisabled: true });
          this.setActiveTab(TABS.REQUEST_CLIPCARD);
        }}
      >
        {text.requestClipCard}
      </Styled.WhiteSubmitButton>
    );
  };
  renderTab = (tab) => {
    const { registeringUser } = this.state;
    const {
      flags: {
        financialChooseNipAvailableWeb,
        financialRequestClipcardAvailableWeb,
        financialGeneralMaintenanceAvailableWeb,
      },
    } = this.props;
    const slideStyle = {
      background: 'white',
      height: '28rem',
      paddingBottom: '1rem',
    };
    switch (tab) {
      case TABS.MAINTENANCE:
        return (
          <Maintenance content={financialGeneralMaintenanceAvailableWeb} />
        );
      case TABS.SPINNER:
        return <BerlinSpinner />;
      case TABS.CAROUSEL:
        return (
          <Styled.LandingContainer>
            <Carousel
              width="19.5rem"
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                if (isSelected) {
                  return (
                    <li
                      style={Styled.SelectedIndicatorStyles}
                      aria-label={`Selected: ${label} ${index + 1}`}
                      title={`Selected: ${label} ${index + 1}`}
                    />
                  );
                }
                return (
                  <li
                    style={Styled.IndicatorStyles}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    title={`${label} ${index + 1}`}
                    aria-label={`${label} ${index + 1}`}
                  />
                );
              }}
            >
              <div style={slideStyle}>
                <Styled.LandingLogoImage src={clipAccountLogo} />
                <Styled.LandingImage src={landingDrawing} />
                <Styled.LandingWelcomeMessage>
                  <Styled.LandingWelcomeMessageTextOne>
                    {text.landingText1}
                  </Styled.LandingWelcomeMessageTextOne>{' '}
                  {text.landingText2}
                </Styled.LandingWelcomeMessage>
              </div>
              <div style={slideStyle}>
                <Styled.LandingLogoImage src={clipAccountLogo} />
                <Styled.LandingImage src={carousel2} />
                <Styled.LandingWelcomeMessage>
                  <Styled.LandingWelcomeMessageTextOne>
                    {text.landingText3_1}
                  </Styled.LandingWelcomeMessageTextOne>
                  {text.landingText3_2}
                </Styled.LandingWelcomeMessage>
              </div>
              <div style={slideStyle}>
                <Styled.LandingLogoImage src={clipAccountLogo} />
                <Styled.LandingImage src={moneyDrawing} />
                <Styled.LandingWelcomeMessage>
                  <Styled.LandingWelcomeMessageTextOne>
                    {text.landingText9_1}
                  </Styled.LandingWelcomeMessageTextOne>
                  {text.landingText9_2}
                </Styled.LandingWelcomeMessage>
              </div>
              <div style={slideStyle}>
                <Styled.ClipCardControl>
                  {text.landingText4}
                </Styled.ClipCardControl>
                <Styled.ClipCardControlContainer>
                  <Styled.ClipCardControlIcon src={landingHand} />
                  <Styled.ClipCardControlText>
                    {text.landingText5}
                  </Styled.ClipCardControlText>
                </Styled.ClipCardControlContainer>
                <Styled.ClipCardControlContainer>
                  <Styled.ClipCardControlIcon src={landingCamera} />
                  <Styled.ClipCardControlText>
                    {text.landingText6}
                  </Styled.ClipCardControlText>
                </Styled.ClipCardControlContainer>
                <Styled.ClipCardControlContainer>
                  <Styled.ClipCardControlIcon src={landingClock} />
                  <Styled.ClipCardControlText>
                    {text.landingText7}
                  </Styled.ClipCardControlText>
                </Styled.ClipCardControlContainer>
                <Styled.ClipCardControlContainer>
                  <Styled.ClipCardControlIcon src={activeIcon} />
                  <Styled.ClipCardControlText>
                    {financialChooseNipAvailableWeb
                      ? text.landingText8_nip
                      : text.landingText8}
                  </Styled.ClipCardControlText>
                </Styled.ClipCardControlContainer>
                <Styled.ClipCardControlEasyText>
                  {text.landingText10}
                </Styled.ClipCardControlEasyText>
              </div>
            </Carousel>
            <Styled.SubmitButton
              onClick={() => this.routeOnClick(ROUTES.REGISTRATION)}
            >
              {text.haveACardButton}
            </Styled.SubmitButton>
            {financialRequestClipcardAvailableWeb ? (
              this.renderRequestButton()
            ) : (
              <Styled.WhiteSubmitButton
                onClick={() => this.postToWaitingList()}
              >
                {registeringUser && (
                  <Styled.LdsRing>
                    <div />
                    <div />
                    <div />
                    <div />
                  </Styled.LdsRing>
                )}
                {text.waitingListButton}
              </Styled.WhiteSubmitButton>
            )}
          </Styled.LandingContainer>
        );
      case TABS.CREATING_ACCOUNT:
        return (
          <Styled.CreatingAccountContainer>
            <Styled.ClockImage src={clock} />
            <Styled.VerifyingData>{text.verifyingData}</Styled.VerifyingData>
            <Styled.CheckingData>{text.checkingData}</Styled.CheckingData>
            <Styled.Thanks>{text.thanksForChoosing}</Styled.Thanks>
          </Styled.CreatingAccountContainer>
        );
      case TABS.REQUEST_CLIPCARD:
        return (
          <ExistingMerchantsOnboarding
            disableRequestButton={() =>
              this.setState({ disableRequestButton: true })
            }
            TABS={TABS}
            setTab={(tab) => {
              this.setState({ landingDisabled: false }, () => {
                this.setActiveTab(tab);
              });
            }}
          />
        );
      case TABS.REQUEST_STATUS:
        return (
          <RequestedCardStatus
            merchantId={this.props.merchant.info.id}
            setTab={(tab) => {
              this.setState({ landingDisabled: false }, () => {
                this.setActiveTab(tab);
              });
            }}
          />
        );
      default:
        return;
    }
  };

  render() {
    const { activeTab } = this.state;
    return (
      <React.Fragment>
        <ReactNotification />
        {this.renderTab(activeTab)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    account: state.berlin.account_representation,
    user: state.user,
  };
};

LandingCarousel.propTypes = {
  account: PropTypes.object,
  merchant: PropTypes.object,
  user: PropTypes.object,
  flags: PropTypes.object,
};

LandingCarousel.defaultProps = {
  account: { account_status: '' },
  merchant: {
    info: {
      id: '',
      proxy_id: null,
    },
  },
  user: {
    email: '',
  },
  flags: {},
};
export default withRouter(connect(mapStateToProps)(withLDConsumer()(LandingCarousel)));
