import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { Select as MUISelect } from '@material-ui/core';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

import styledWithClasses from '../../../../../helpers/StyledWithClasses';

import styles from './styles';

export const StyledSelect = styledWithClasses(MUISelect, {
  icon: 'helper-select-icon',
});

const useStyles = makeStyles(styles);

const RenderSelectField = (props) => {
  const {
    input,
    label,
    color = 'primary',
    children,
    options,
    meta: { touched, error },
    ...rest
  } = props;
  const classes = useStyles({ color });

  return (
    <FormControl fullWidth {...rest}>
      <InputLabel
        classes={{
          root: classes.label,
          formControl: classes.formControl,
          shrink: classes.shrink,
        }}
      >
        {label}
      </InputLabel>
      <StyledSelect
        disableUnderline={true}
        classes={{
          root: classes.root,
          select: classes.input,
          disabled: classes.disabled,
          icon: classes.icon,
        }}
        {...input}
        onChange={(event) => input.onChange(event.target.value)}
      >
        {options
          ? options.map((option, key) => (
              <MenuItem key={key} value={option.id} disabled={option.disabled}>
                {option.name}
              </MenuItem>
            ))
          : children}
      </StyledSelect>
      {touched && error && (
        <FormHelperText style={{ paddingLeft: '8px' }} error>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default RenderSelectField;
