import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import urls from '../../helpers/urls';
import AnalyticsEvents from '../../helpers/AnalyticsEvents';
import { Events } from '../../constants/GTMConstants';   

import AcceptedPage from '../../components/loans/Commons/AcceptedPage';
import Activation2 from '../../components/Berlin/Activation2.0';
import AdvancePayment from '../../components/loans/Commons/AdvancePayment';
import AuthController from '../../components/auth/AuthController';
import Berlin from '../../components/Berlin';    
import CanceledPage from '../../components/loans/Commons/CanceledPage';
import Elegible from '../../components/loans/OpenData/Elegible';
import ExpiredPage from '../../components/loans/Commons/ExpiredPage';
import ExternalInformationV3 from '../../components/loans/ExternalLenderV3/Information';
import FAQPage from '../../components/loans/Commons/FAQPage';
import Interested from '../../components/loans/OpenData/Interested';
import LandingCarousel2 from 'components/Berlin/LandingCarousel2.0';
import Loans from '../../components/loans';
import LoansCongrats from '../../components/loans/Commons/CongratulationPage';
import LoansForm from '../../components/loans/LoansForm';
import LoanSignCongrats from '../../components/loans/Commons/CongratulationPage/ForSign';
import LoanSigned from '../../components/loans/Commons/AcceptedPage/Signed';
import MyCrediClip from '../../components/loans/MyCrediClip';
import NotElegible from '../../components/loans/OpenData/NotElegible';
import OpenDataApproved from '../../components/loans/OpenData/Approved';
import OpenDataConfirmation from '../../components/loans/OpenData/Confirmation';
import OpenDataInReview from '../../components/loans/OpenData/InReview';
import OpenDataOffers from '../../components/loans/OpenData/Offers';
import TermConditionsPage from '../../components/loans/Commons/TermConditionsPage';
import Refill from '../../components/loans/Refill';
import RefillConfirmation from '../../components/loans/Refill/confirmation';
import RefillCongrats from '../../components/loans/Refill/congrats';
import RefillReview from '../../components/loans/Refill/review';
import Registration from '../../components/Berlin/Registration';
import Auth from '../../components/Berlin/Auth';
import Registration2 from '../../components/Berlin/Registration2.0';
import UpgradeAccount from '../../components/Berlin/UpgradeAccount';
import BerlinSwap from '../../components/Berlin/BerlinSwap';
import ClipCard from '../../components/Berlin/ClipCard';
import RequestCard from '../../components/Berlin/ClipCard/RequestCard';
import ActivateCard from '../../components/Berlin/ClipCard/ActivateCard';
import AccountInformation from '../../components/Berlin/AccountInformation';
import CdaHelper from '../../components/Berlin/CdaHelper';
import WaitingList from '../../components/Berlin/WaitingList';
import Deactivated from '../../components/Berlin/Deactivated';
import NewLayout from '../../components/NewLayout';
import MyStorePage from '../../components/mystore/MyStorePage';
import { EVENTS } from '../../helpers/Clevertap';
import { loansRoutePayload } from '../../components/loans/Commons/Clevertap';
import loadable from '@loadable/component';
import CheckoutClip from 'components/paymentrequest/RemotePayments/CheckoutClip';


const clevertapLoansOfferPayload = loansRoutePayload(
  EVENTS.LOANS.NEW_OFFERS_VIEW,
  null,
  { type: 'regular' },
);
const clevertapLoansCongratsPayload = loansRoutePayload(
  EVENTS.LOANS.CONGRATS_VIEW,
  null,
  { type: 'regular' },
);
const clevertapLoansReviewPayload = loansRoutePayload(
  EVENTS.LOANS.REVIEW_VIEW,
  { loans: { 0: { status: 'status'} } },
  { type: 'regular' },
);
const clevertapLoansOfferRefillPayload = loansRoutePayload(
  EVENTS.LOANS.NEW_OFFERS_VIEW,
  null,
  { type: 'refill' },
);
const clevertapLoansRefillReviewPayload = loansRoutePayload(
  EVENTS.LOANS.REFILL_REVIEW_VIEW,
  null,
  { type: 'refill' },
);
const clevertapLoansPersonalizedReviewPayload = loansRoutePayload(
  EVENTS.LOANS.REVIEW_VIEW,
  null,
  { type: 'custom' },
);
const clevertapLoansCongratsPersonalizedPayload = loansRoutePayload(
  EVENTS.LOANS.CONGRATS_VIEW,
  null,
  { type: 'custom' },
);
const clevertapLoansOfferCustomPayload = loansRoutePayload(
  EVENTS.LOANS.NEW_OFFERS_VIEW,
  null,
  { type: 'custom' },
);
const clevertapLoansLenderPayload = (event) =>
  loansRoutePayload(event, {
    request: { preapproval: { provider_code: 'lender' } },
  });
const sendGoogleAnalyticsPageView = function (path) {
  AnalyticsEvents.pageView(path);
};

function rootEnterCB(pathname) {
  sendGoogleAnalyticsPageView(pathname);
}

function usePageView() {
  let location = useLocation();

  useEffect(() => {
    if (!window.dataLayer) return;

    window.dataLayer.push({
      event: Events.VIRTUAL_PAGE_VIEW,
      page: location.pathname,
      title: '',
    });
  }, [location]);
}

const EditLinkPage = loadable(() =>
  import('components/paymentrequest/PaymentRequest/PublicProfile/EditPage'),
);

const RemotePayment = loadable(() =>
  import('components/paymentrequest/RemotePayments'),
);

const PaymentLinkPage = loadable(() =>
  import('components/paymentrequest/PaymentPage'),
);

const PaymentRequest = loadable(() =>
  import('components/paymentrequest/PaymentRequest'),
);

const PendingPaymentRequest = loadable(() =>
  import('components/paymentrequest/PendingPaymentRequest'),
);

const NewLayoutComp = (props) => <NewLayout {...props} />;

const MobileRoutes = (props) => {
  let location = useLocation();
  usePageView();

  useEffect(() => {
    rootEnterCB(location.pathname);
  }, [location.pathname]);

  return (
    <Switch>
      {/* Mobile routes - loans */}
      <AuthController
        path={`${urls.mobile}${urls.loans}`}
        component={Loans}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansCanceled}`}
        component={CanceledPage}
        isMobileWebView
        exact
      />

      {/* Routes - Open Data */}
      <AuthController
        path={`${urls.mobile}${urls.loansOpenDataElegible}`}
        component={Elegible}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansOpenDataNotElegible}`}
        component={NotElegible}
        isMobileWebView
        exact
        clevertap={{ event: EVENTS.MENU.LOANDS_OPEN_DATA_NOT_ELEGIBE }}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansOpenDataConfirmation}`}
        component={OpenDataConfirmation}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansOpenDataOffers}`}
        component={OpenDataOffers}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansOpenDataInterested}`}
        component={Interested}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansOpenDataApproved}`}
        component={OpenDataApproved}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansOpenDataInReview}`}
        component={OpenDataInReview}
        isMobileWebView
        exact
      />

      {/* Routes - Regular Form */}
      <AuthController
        path={`${urls.mobile}${urls.loansMyCrediClip}`}
        component={MyCrediClip}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansMyPrestaClip}`}
        component={MyCrediClip}
        isMobileWebView
        exact
        clevertap={loansRoutePayload(EVENTS.LOANS.PRESTACLIP_VIEW)}
      />
      <AuthController
        path={`${urls.mobile}${urls.preapprovalsExpiredV4}`}
        component={ExpiredPage}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.inreview}`}
        component={AcceptedPage}
        isMobileWebView
        exact
        clevertap={clevertapLoansReviewPayload}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansPersonalizedInReview}`}
        component={AcceptedPage}
        isMobileWebView
        exact
        clevertap={clevertapLoansPersonalizedReviewPayload}
      />
      <AuthController
        path={`${urls.mobile}${urls.termConditionsLoans}`}
        component={TermConditionsPage}
        isMobileWebView
        exact
        clevertap={loansRoutePayload(EVENTS.LOANS.TC_VIEW)}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansFAQs}`}
        component={FAQPage}
        isMobileWebView
        exact
        clevertap={loansRoutePayload(EVENTS.LOANS.FAQS_VIEW)}
      />

      {/* Congrats */}
      <AuthController
        path={`${urls.mobile}${urls.loansCongratsForSign}`}
        component={LoanSignCongrats}
        isMobileWebView
        exact
        clevertap={clevertapLoansCongratsPayload}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansCongratsSigned}`}
        component={LoanSigned}
        isMobileWebView
        exact
        clevertap={clevertapLoansCongratsPayload}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansRefillCongrats}`}
        component={RefillCongrats}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.congrats}`}
        component={LoansCongrats}
        isMobileWebView
        exact
        clevertap={clevertapLoansCongratsPayload}
      />
      <AuthController
        path={`${urls.mobile}${urls.congratsPersonalized}`}
        component={LoansCongrats}
        isMobileWebView
        exact
        clevertap={clevertapLoansCongratsPersonalizedPayload}
      />
      <AuthController
        path={`${urls.mobile}${urls.congratsVersion}`}
        component={LoansCongrats}
        isMobileWebView
        exact
        clevertap={clevertapLoansCongratsPayload}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansCongratsAction}`}
        component={LoansCongrats}
        isMobileWebView
        exact
        clevertap={clevertapLoansCongratsPayload}
      />
      <AuthController
        path={`${urls.mobile}${urls.congratsVersionAction}`}
        component={LoansCongrats}
        isMobileWebView
        exact
        clevertap={clevertapLoansCongratsPayload}
      />
      <AuthController
        path={`${urls.mobile}${urls.congratsPersonalizedVersion}`}
        component={LoansCongrats}
        isMobileWebView
        exact
        clevertap={clevertapLoansCongratsPersonalizedPayload}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansCongratsPersonalizedAction}`}
        component={LoansCongrats}
        isMobileWebView
        exact
        clevertap={clevertapLoansCongratsPersonalizedPayload}
      />
      <AuthController
        path={`${urls.mobile}${urls.congratsPersonalizedVersionAction}`}
        component={LoansCongrats}
        isMobileWebView
        exact
        clevertap={clevertapLoansCongratsPersonalizedPayload}
      />

      {/* Refill */}
      <AuthController
        path={`${urls.mobile}${urls.loansRefill}`}
        component={Refill}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansRefillConfirmation}`}
        component={RefillConfirmation}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansRefillInReview}`}
        component={RefillReview}
        isMobileWebView
        exact
        clevertap={clevertapLoansRefillReviewPayload}
      />

      {/* Fast Payment */}
      <AuthController
        path={`${urls.mobile}${urls.loansMrPrestaAdvancePayment}`}
        component={AdvancePayment}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansMOTAdvancePayment}`}
        component={AdvancePayment}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansAPHAdvancePayment}`}
        component={AdvancePayment}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansCTOAdvancePayment}`}
        component={AdvancePayment}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansR2AdvancePayment}`}
        component={AdvancePayment}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansAVLAdvancePayment}`}
        component={AdvancePayment}
        isMobileWebView
        exact
      />

      {/* Fonel sections */}
      {/* V4 - Cash Advance */}
      <AuthController
        path={`${urls.mobile}${urls.loansV4CA}`}
        component={Loans}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansPersonalizedV4CA}`}
        component={Loans}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansFormIdentityV4CA}`}
        component={LoansForm}
        layout={NewLayoutComp}
        isMobileWebView
        exact
        clevertap={clevertapLoansLenderPayload(EVENTS.LOANS.IDENTITY_VIEW)}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansFormHistoryV4CA}`}
        component={LoansForm}
        layout={NewLayoutComp}
        isMobileWebView
        exact
        clevertap={clevertapLoansLenderPayload(
          EVENTS.LOANS.CREDIT_HISTORY_VIEW,
        )}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansFormAddressV4CA}`}
        component={LoansForm}
        layout={NewLayoutComp}
        isMobileWebView
        exact
        clevertap={clevertapLoansLenderPayload(EVENTS.LOANS.ADRESS_VIEW)}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansFormDepositV4CA}`}
        component={LoansForm}
        layout={NewLayoutComp}
        isMobileWebView
        exact
        clevertap={clevertapLoansLenderPayload(EVENTS.LOANS.DEPOSIT_VIEW)}
      />

      {/* MyStore */}
      <AuthController
        path={urls.myStoreWebview}
        isMobileWebView
        component={MyStorePage}
      />

      {/* V4 */}
      <AuthController
        path={`${urls.mobile}${urls.loansV4}`}
        component={Loans}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansPersonalizedV4}`}
        component={Loans}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.loansFormIdentityV4}`}
        component={LoansForm}
        layout={NewLayoutComp}
        isMobileWebView
        exact
        clevertap={clevertapLoansLenderPayload(EVENTS.LOANS.IDENTITY_VIEW)}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansFormHistoryV4}`}
        component={LoansForm}
        layout={NewLayoutComp}
        isMobileWebView
        exact
        clevertap={clevertapLoansLenderPayload(
          EVENTS.LOANS.CREDIT_HISTORY_VIEW,
        )}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansFormAddressV4}`}
        component={LoansForm}
        layout={NewLayoutComp}
        isMobileWebView
        exact
        clevertap={clevertapLoansLenderPayload(EVENTS.LOANS.ADRESS_VIEW)}
      />
      <AuthController
        path={`${urls.mobile}${urls.loansFormDepositV4}`}
        component={LoansForm}
        layout={NewLayoutComp}
        isMobileWebView
        exact
        clevertap={clevertapLoansLenderPayload(EVENTS.LOANS.DEPOSIT_VIEW)}
      />

      <AuthController
        path={`${urls.mobile}${urls.berlin}`}
        component={LandingCarousel2}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinBalance}`}
        component={Berlin}
        layout={NewLayoutComp}
        clevertap={{ event: EVENTS.BERLIN.BALANCE.EVENT }}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinAuth}`}
        component={Auth}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinRegistration}`}
        component={Registration}
        isMobileWebView
        exact
      />

      <AuthController
        path={`${urls.mobile}${urls.berlin2}`}
        component={LandingCarousel2}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinRegistration2}`}
        component={Registration2}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinUpgrade}`}
        component={UpgradeAccount}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinSwap}`}
        component={BerlinSwap}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinClipCard}`}
        component={ClipCard}
        clevertap={{ event: EVENTS.BERLIN.CARD.EVENT }}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinClipCardRequest}`}
        component={RequestCard}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinClipCardActivation}`}
        component={ActivateCard}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinAccount}`}
        component={AccountInformation}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinActivation2}`}
        component={Activation2}
        clevertap={{ event: EVENTS.BERLIN.ONBOARDING.ACTIONS.ACCOUNT_CREATED }}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinWaitingList}`}
        component={WaitingList}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      <AuthController
        path={`${urls.mobile}${urls.berlinDeactivated}`}
        component={Deactivated}
        layout={NewLayoutComp}
        isMobileWebView
        exact
      />
      {/* Payments */}
      <AuthController
        path={`${urls.mobile}${urls.pad}`}
        isMobileWebView
        component={RemotePayment}
      />
      <AuthController
        exact
        path={`${urls.mobile}${urls.paymentRequest}`}
        isMobileWebView
        component={PaymentRequest}
      />
      <AuthController
        exact
        path={`${urls.mobile}${urls.paymentRequestPendingPayments}`}
        isMobileWebView
        component={PendingPaymentRequest}
      />
      <AuthController
        path={`${urls.mobile}${urls.checkoutClip}`}
        isMobileWebView
        component={CheckoutClip}
      />
      <AuthController
        exact
        path={`${urls.mobile}${urls.paymentLinkPage}`}
        isMobileWebView
        component={PaymentLinkPage}
      />
      <AuthController
        exact
        path={`${urls.mobile}${urls.editLinkPage}`}
        isMobileWebView
        component={EditLinkPage}
      />
    </Switch>
  );
};

export default MobileRoutes;
