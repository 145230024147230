export default {
  congratulation: '¡Tu solicitud fue enviada!',
  successSubmit: `
  Estamos verificando tus datos.

  Si tu documentación está en orden, recibirás tu contrato por correo.

  **Firma tu contrato en línea** y obtén tu Adelanto de ventas Clip en 24 horas* hábiles.
  `,
  successSubmitMO: `
  Estamos verificando tus documentos.

  Si tu documentación está en orden, recibirás un correo de confirmación y **depositaremos 
  tu Adelanto de ventas Clip en 24 horas* hábiles**.
  `,
  textButton: 'Entendido',
  titleCongrats: '¡Último paso!',
  banner: 'Solicitud enviada',
  paragraph1: 'Estás a punto de obtener tu Adelanto de ventas Clip.',
  paragraph2: 'Solo',
  paragraph3: 'firma tu contrato',
  paragraph4: `100% en línea y si todo está en orden depositaremos 
  tu dinero en 24 horas hábiles.`,
  signButton: 'Firmar contrato',
  paragraphDesk1: `Tu contrato está listo para ser firmado de manera 100% digital, 
  solo sigue estos pasos:`,
  paragraphDesk2: 'Ingresa a tu aplicación de Clip desde tu celular.',
  paragraphDesk31: 'Sigue las instrucciones y',
  paragraphDesk32: 'firma tu contrato',
  paragraphDesk33: '100% en línea desde la aplicación.',
  paragraphDesk4:
    'Para una mejor experincia de firma, procura conectarte a una red WiFi.',
  paragraphDesk5:
    'Si todo está en orden depositaremos tu dinero en 24 horas hábiles.',
};
