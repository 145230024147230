import React from 'react';
import { ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ContactCard from './ContactCard';
import { styles } from './styles';
import text from './text';

const useStyles = makeStyles(styles);

const EmptyState = (props) => {
    const classes = useStyles(props);
    return(
        <div>
            <ContactCard disabled/>
            <ClipTypography
                variant={'body1'}
                align="center"
                className={classes.emptyTitle}
                component={'p'}
            >
                {text.emptyInfo}
            </ClipTypography>
        </div>
    )
}

export default EmptyState;
