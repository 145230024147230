import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { ClipTypography, ClipButton } from '@clipmx/clip-storybook';
import ChipLogo from '../../../../assets/images/berlin/chipLogo.svg';
import ClipLogo from '../../../../assets/images/berlin/clipLogo2.svg';
import VisaLogo from '../../../../assets/images/berlin/visaLogo.svg';
import ShowNipIcon from '../../../../assets/images/berlin/icon-view.svg';
import ChangeNipIcon from '../../../../assets/images/berlin/changePin.svg';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { EVENTS } from '../../../../helpers/Clevertap';
import ShowPIN from '../../ShowPIN';
import DynamicCvv from '../../DynamicCvv';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';

import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { SCREENS } from '../../contexts/ScreenContext';
import { styles } from './styles';
import text from './text';
import PinChange from '../../PinChange';
import Token from '../Token';
import * as DynamicToken from '../Token/DynamicToken';
import { store } from 'react-notifications-component';
import logger from '../../../../helpers/logger';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import swapLogo from '../../../../assets/images/berlin/swap.svg';
import cacaoLogo from '../../../../assets/images/berlin/cacao-gris.svg';
import infoIcon from '../../../../assets/images/berlin/infoIcon.svg';
import CacaoTooltip from '../../CacaoTooltip';
import { BERLIN_FINANCIAL_SERVICE_PROVIDERS } from '../../../../constants/AppConstants';

const useStyles = makeStyles(styles);
export const CardInfo = (props) => {
  const {
    user,
    account,
    cardRepresentation,
    flags: { financialCheckPrintedNipAvailableWeb, financialDynamicCvvAvailableWeb, financialChangenipAvailable },
  } = props;
  const classes = useStyles(props);
  const [screen, setScreen] = useState(SCREENS.BALANCE);
  const [showNip, setShowNip] = useState(false);
  const [swapPIN, setSwapPIN] = useState(null);
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [displaySwapTooltip, setDisplaySwapTooltip] = useState(false);

  useEffect(() => {
    let showNip = true;
    if (account && account.card_representation[0].pin_activation_counter > 0) {
      showNip = false;
    }
    if (financialCheckPrintedNipAvailableWeb) {
      showNip = false;
    }
    setShowNip(showNip);
  }, []);

  const renderScreen = (screen) => {
    switch (screen) {
      case SCREENS.BALANCE:
      default:
        return (
          <div className={classes.container}>
            <div className={classes.accountIssuedContainer}>
              <IconButton
                classes={{ root: classes.cacaoTooltip }}
                onClick={() => setDisplaySwapTooltip(true)}
              >
              <img className={classes.helpIcon} src={infoIcon} alt={'help'} />
              </IconButton>
              <div className={classes.serviceNameContainer}>

              
              <ClipTypography className={classes.serviceName} variant={'caption'} component={'p'} fontWeight={'medium'}>
                {text.serviceName}
              </ClipTypography>
              <ClipTypography variant={'caption'}>
                {text.accountIssued}
              </ClipTypography>
              <img 
              className={classes.cacaoLogo} 
              src={account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.CACAO ? cacaoLogo : swapLogo} 
              alt={account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.CACAO ? 'cacao' : 'swap'} 
              />
              </div>
            </div>
            <div className={classes.card}>
              <div className={classes.cardChip}>
                <img src={ChipLogo} alt={'Chip'} />
              </div>
              <div className={classes.cardAmount}>
                <ClipTypography variant={'subtitle1'} fontWeight={'regular'} component={'p'}>
                  {text.cardNumber(cardRepresentation.last_numbers)}
                </ClipTypography>
              </div>
              <div className={classes.cardIcons}>
                <img src={ClipLogo} alt={'Clip'} />
                <img src={VisaLogo} alt={'Visa'} />
              </div>
            </div>
            <div className={classes.cardActionsFullWidth}>
              {(account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.CACAO) 
                && financialChangenipAvailable && account.card_representation[0].pin_activation_counter > 0 && (
                <div className={classes.buttonContainer}>
                  <ClevertapComponent
                    renderComponent={<ClipButton />}
                    events={[
                      {
                        callbackName: 'onClick',
                        event: EVENTS.BERLIN.CARD.EVENT,
                        action: EVENTS.BERLIN.CARD.ACTIONS.VIEW_NIP_CHANGE_CLICKED,
                      },
                    ]}
                    className={classes.actionButton}
                    onClick={() => setScreen(SCREENS.CHANGE_PIN)}
                  >
                    <img className={classes.actionButtonIcon} src={ChangeNipIcon} alt={text.changePIN} />
                    <ClipTypography
                      classes={{ root: classes.actionButtonText }}
                      variant={'body2'}
                      component={'span'}
                      fontWeight={'bold'}
                    >
                      {text.changePIN}
                    </ClipTypography>
                  </ClevertapComponent>
                </div>
              )}
              {((account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.SWAP) ||
            (financialCheckPrintedNipAvailableWeb && account.card_representation[0].pin_activation_counter === 0)) && (
                <div className={classes.buttonContainer}>
                  <ClevertapComponent
                    renderComponent={<ClipButton />}
                    events={[
                      {
                        callbackName: 'onClick',
                        event: EVENTS.BERLIN.CARD.EVENT,
                        action: EVENTS.BERLIN.CARD.ACTIONS.VIEW_NIP_CLICKED,
                      },
                    ]}
                    className={classes.actionButton}
                    onClick={() => {
                      if ((account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.SWAP)) {
                        setDisplayTooltip(true)
                      } else {
                        setScreen(SCREENS.SHOW_PIN)
                      }
                    }}
                  >
                    <img className={classes.actionButtonIcon} src={ShowNipIcon} alt={text.showPIN} />
                    <ClipTypography
                      classes={{ root: classes.actionButtonText }}
                      variant={'body2'}
                      component={'span'}
                      fontWeight={'bold'}
                    >
                      {text.showPIN}
                    </ClipTypography>
                  </ClevertapComponent>
                </div>
              )}
            </div>
            <ClipDrawer
              anchor={'bottom'}
              open={displayTooltip}
              classes={{ paper: classes.tooltipPaper }}
            >
              <Token toggleTooltip={async () => { 
                const result = await DynamicToken.getNip(
                  account.account_holder_representation.user_external_id,
                  account.card_representation[0].proxy_card,
                  account.account_holder_representation.phone_number,
                );
                if (result.nip) {
                  setSwapPIN(result);
                  setScreen(SCREENS.SHOW_PIN);
                } else {
                  logger.info(result.error);
                  store.addNotification({
                    title: text.rejectedTitle,
                    message: text.rejectedMessage,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-center',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                      showIcon: true,
                      duration: 0,
                    },
                  });
                }
                setDisplayTooltip(false);
              }}/>
            </ClipDrawer>
            <ClipDrawer
              anchor={'bottom'}
              open={displaySwapTooltip}
              classes={{ paper: classes.tooltipPaper }}
            >
              <CacaoTooltip toggleTooltip={() => setDisplaySwapTooltip(false)} provider={account.provider_code} />
            </ClipDrawer>
          </div>
        );
      case SCREENS.CHANGE_PIN:
        return (
          <PinChange
            lastScreen={SCREENS.SHOW_PIN}
            showNip={showNip}
            SCREENS={SCREENS}
            hideShowNip={(arg) => setShowNip(arg)}
            setActiveScreen={setScreen}
          />
        );
      case SCREENS.SHOW_PIN:
        return <ShowPIN user={user} account={account} SCREENS={SCREENS} setActiveScreen={setScreen} swapPIN={swapPIN}/>;
      case SCREENS.DYNAMIC_CVV:
        return <DynamicCvv account={account} SCREENS={SCREENS} setActiveScreen={setScreen} />;
    }
  };

  return account && renderScreen(screen);
};

CardInfo.propTypes = {
  classes: PropTypes.object,
  flags: PropTypes.object,
  user: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  cardRepresentation: PropTypes.object.isRequired,
};

CardInfo.default = {
  classes: {},
  flags: {},
  user: {},
  account: {},
  cardRepresentation: {},
};

export default withLDConsumer()(CardInfo);