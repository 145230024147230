import { CHANGE_FLAG_VALUE, CHANGE_FLAGS_VALUE } from '../actions/ldActions';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_FLAG_VALUE:
      const {
        payload: { flag, value },
      } = action;
      return { ...state, [flag]: value };
    case CHANGE_FLAGS_VALUE:
      const {
        payload: { flags },
      } = action;
      return { ...state, ...flags };
    default:
      return state;
  }
}
