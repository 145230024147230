import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

export const styles = makeStyles((theme) => {
  return {
    snackAlert: {
      top: 34,
      left: 8,
    },
    toastAlert:{
      marginTop: `${theme.spacing.unit * 1.5}px`,
      marginBottom: `${theme.spacing.unit * 1.5}px`,
      color: '#527739',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '80%',
        width: '100%',
        margin: 'auto',
      },
    },
    snackAlertDesktop: {
      [theme.breakpoints.down('xs')]: {
        bottom: 'auto',
        top: 65,
      },
    },
    Desk: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    Mobil: {
      bottom: '0',
      right: '0',
      zIndex: 3,
      position: 'fixed',
      width: '50%',
      float: 'right',
      [theme.breakpoints.down('xl')]: {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        padding: `${theme.spacing.unit * 2.5}px`,
      },
    },
    Buttoncontinue: {
      textTransform: 'none !important',
      border:`${theme.spacing.unit * 0.25}px solid`,
      marginTop: `${theme.spacing.unit * 1.5}px`,
      borderRadius: `${theme.spacing.unit * 5}px`,
      height: `${theme.spacing.unit * 6.25}px`,
      '&:hover': {
        border:`${theme.spacing.unit * 0.25}px solid`,
      },
    },
    Buttonfinish: {
      textTransform: 'none !important',
      border:`${theme.spacing.unit * 0.25}px`,
      marginTop: `${theme.spacing.unit * 1.5}px`,
      borderRadius: `${theme.spacing.unit * 5}px`,
      [theme.breakpoints.down('md')]: {
        marginTop: `${theme.spacing.unit * - 0.6}px`,
      },
    },
  };
  
});

export default styles;
