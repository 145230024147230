export default {
  title: 'Por último, necesitamos tu firma',
  content:
    'Asegúrate que sea lo más idéntica posible a la firma que aparece en tu identificación.',
  titleSign: 'Firma en el recuadro:',
  contentSign: 'Presiona “Borrar” para volver a comenzar.',
  rejectedTitle: 'Ups!',
  rejectedMessage: 'La firma no puede ir vacía',
  signButton: 'Firmar',
  eraseButton: 'Borrar',
  continueButton: 'Continuar',
};
