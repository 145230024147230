import React from 'react';
import moment from 'moment-timezone';

import * as Styled from './styled';

import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm, propTypes } from 'redux-form';
import { Forms } from 'constants/AppConstants';
import { states, business_sectors } from './FormSelectValues';
import { required, postalCode } from 'helpers/validate';
import {
  renderInput,
  renderRadio,
  renderSelectField,
} from '../../Commons/BerlinInputs/FormUtils';
import { allowAlphaNumerical, replaceAccents } from '../../utils';
import text from './text';
import PropTypes from 'prop-types';
import { processZipCode } from '../../redux/actions';

const validateTaxIdentifier = (value) => {
  const errorMessage = 'Verifica tu información.';
  if (value && value.length === 13) {
    const rfcRegex =
      /^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9]([0-2][0-9]|30|31)[A-Z,0-9][A-Z,0-9][0-9,A-Z]/;
    return rfcRegex.test(value.toUpperCase()) ? undefined : errorMessage;
  } else if (value && value.length === 18) {
    const curpRegex =
      /^[A-Z][AEIOUX][A-Z]{2}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[MH]([ABCMTZ]S|[BCJMOT]C|[CNPST]L|[GNQ]T|[GQS]R|C[MH]|[MY]N|[DH]G|NE|VZ|DF|SP)[BCDFGHJ-NP-TV-Z]{3}[0-9A-Z][0-9]/;
    return curpRegex.test(value.toUpperCase()) ? undefined : errorMessage;
  }
  return errorMessage;
};

const validateDayMonthYear = (value) => {
  if (value.length < 2) {
    return 'Ingresa el día';
  } else if (value.length > 4 && value.length < 7) {
    return 'Ingresa el mes';
  } else if (value.length > 7 && value.length < 14) {
    return 'Ingresa el año usando 4 dígitos';
  }
  return undefined;
};

const validate18Years = (value) => {
  const errorMessage =
    'Debes ser mayor de 18 años para solicitar una Clip Card';
  const date = moment(value.replace(/\s+/g, ''), 'DD-MM-YYYY');
  if (value && value.length === 14) {
    return date.diff(moment(), 'years') <= -18 ? undefined : errorMessage;
  }
  return undefined;
};

const validateMatchingAge = (value, allValues) => {
  return value.length > 5 &&
    allValues.birth_date &&
    allValues.birth_date.length === 14 &&
    (value.substr(4, 2) !== allValues.birth_date.substr(12, 2) ||
      value.substr(6, 2) !== allValues.birth_date.substr(5, 2) ||
      value.substr(8, 2) !== allValues.birth_date.substr(0, 2))
    ? 'Tu fecha de nacimiento no coincide'
    : undefined;
};

export class Datos extends React.PureComponent {
  static propTypes = {
    setActiveTab: PropTypes.func.isRequired,
    setEnableAheadOneStep: PropTypes.func.isRequired,
    selectedGender: PropTypes.string,
    birthDate: PropTypes.string,
    berlin: PropTypes.object,
    merchant: PropTypes.object,
    ...propTypes,
  };

  static defaultProps = {
    selectedGender: null,
    birthDate: null,
    berlin: {},
    merchant: {},
  };

  completeStepTwo = () => {
    this.props.setActiveTab(3);
  };

  isGenderSelected = () => {
    if (this.props.selectedGender) {
      return true;
    } else {
      return false;
    }
  };

  birthDateComplete = () => {
    return this.props.birthDate && this.props.birthDate.length === 14;
  };

  checkValue = (str, max) => {
    if (str.charAt(0) !== '0' || str === '00') {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str =
        num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
          ? '0' + num
          : num.toString();
    }
    return str;
  };

  formatDate = (e) => {
    e.target.type = 'text';
    let input = e.target.value;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    const values = input.split('/').map(function (v) {
      return v.replace(/\D/g, '');
    });
    if (values[0]) values[0] = this.checkValue(values[0], 31);
    if (values[1]) values[1] = this.checkValue(values[1], 12);
    const output = values.map(function (v, i) {
      return v.length === 2 && i < 2 ? v + ' / ' : v;
    });
    e.target.value = output.join('').substr(0, 14);
  };

  blurDate = (e) => {
    e.target.type = 'text';
    const input = e.target.value;
    const values = input.split('/').map(function (v, i) {
      return v.replace(/\D/g, '');
    });
    let output = '';

    if (values.length === 3) {
      const year =
        values[2].length !== 4
          ? parseInt(values[2]) + 2000
          : parseInt(values[2]);
      const month = parseInt(values[0]) - 1;
      const day = parseInt(values[1]);
      const d = new Date(year, month, day);
      if (!isNaN(d)) {
        e.target.innerText = d.toString();
        const dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
        output = dates
          .map(function (v) {
            v = v.toString();
            return v.length === 1 ? '0' + v : v;
          })
          .join(' / ');
      }
    }
    e.target.value = output;
  };

  allowAlphaNumerical = (maxLength, e) => {
    e.target.value = allowAlphaNumerical(maxLength, e.target.value);
  };

  replaceAccents = (e) => {
    e.target.value = replaceAccents(e.target.value);
  };

  componentDidMount() {
    const { setEnableAheadOneStep, invalid } = this.props;
    setEnableAheadOneStep(false);
  }

  componentDidUpdate() {
    const { setEnableAheadOneStep, invalid } = this.props;
    if (!invalid && this.isGenderSelected() && this.birthDateComplete()) {
      setEnableAheadOneStep(true);
    } else {
      setEnableAheadOneStep(false);
    }
  }

  validateZipcode = (e) => {
    const { dispatch } = this.props;
    dispatch(processZipCode(e.target.value, Forms.REGISTRATION_INFORMATION));
  };

  render() {
    const { handleSubmit, invalid, berlin, selectedGender } = this.props;
    return (
      <Styled.RegistroDocumentosContainer>
        <form onSubmit={handleSubmit(this.completeStepTwo)}>
          <Styled.DocumentosContainer>
            <Styled.VerificaDatos>{text.title}</Styled.VerificaDatos>
            <Styled.VerificaDescripcion>
              {text.subtitle}
            </Styled.VerificaDescripcion>
            <Styled.VerificaSubDescripcion>
              {text.subtitle2}
            </Styled.VerificaSubDescripcion>
            <Styled.FormField
              component={renderInput}
              label={text.name}
              name="name"
              inputProps={{ maxLength: 36 }}
              validate={[required]}
              autoFocus={true}
              onInput={(e) => this.allowAlphaNumerical(36, e)}
            />
            <Styled.FormField
              component={renderInput}
              label={text.lastName}
              name="last_name"
              inputProps={{ maxLength: 36 }}
              validate={[required]}
              onInput={(e) => this.allowAlphaNumerical(36, e)}
            />
            <Styled.FormField
              component={renderInput}
              label={text.secondLastName}
              name="second_last_name"
              inputProps={{ maxLength: 36 }}
              validate={[required]}
              onInput={(e) => this.allowAlphaNumerical(36, e)}
            />
            <Styled.GeneroContainer>
              <Styled.GeneroLabel>{text.legalGender}</Styled.GeneroLabel>
              <Styled.GeneroRadioContainer>
                <Styled.GeneroRadioLabel
                  htmlFor="femenino"
                  checked={selectedGender === 'FEMALE'}
                >
                  <Field
                    id="femenino"
                    name="gender"
                    component={renderRadio}
                    type="radio"
                    value="FEMALE"
                  />
                  {text.female}
                </Styled.GeneroRadioLabel>
                <Styled.GeneroRadioLabel
                  htmlFor="masculino"
                  checked={selectedGender === 'MALE'}
                >
                  <Field
                    id="masculino"
                    name="gender"
                    component={renderRadio}
                    type="radio"
                    value="MALE"
                  />
                  {text.male}
                </Styled.GeneroRadioLabel>
              </Styled.GeneroRadioContainer>
            </Styled.GeneroContainer>
            <Styled.FormField
              component={renderInput}
              label={text.birthDate}
              placeholder="DD / MM / AAAA"
              helperText="DD / MM / AAAA"
              name="birth_date"
              onInput={this.formatDate}
              onBlur={this.blurDate}
              validate={[required, validateDayMonthYear, validate18Years]}
            />
            <Styled.FormField
              component={renderSelectField}
              label={text.birthPlace}
              name="birth_place"
              options={states}
              validate={[required]}
            />
            <Styled.FormField
              component={renderInput}
              label={text.occupation}
              name="occupation"
              inputProps={{ maxLength: 24 }}
              validate={[required]}
              onInput={(e) => this.allowAlphaNumerical(24, e)}
            />
            <Styled.FormField
              component={renderSelectField}
              label={text.businessSector}
              name="business_sector"
              options={business_sectors}
              validate={[required]}
            />
            <Styled.FormField
              component={renderInput}
              label={text.rfcCurp}
              name="tax_identifier"
              validate={[required, validateMatchingAge, validateTaxIdentifier]}
              inputProps={{
                maxLength: 18,
                style: { textTransform: 'uppercase' },
              }}
            />
            <Styled.Direccion>{text.address}</Styled.Direccion>
            <Styled.VerificaDireccion>
              {text.addressReminder}
            </Styled.VerificaDireccion>
            <Styled.FormField
              component={renderInput}
              label={text.street}
              name="street"
              inputProps={{ maxLength: 36 }}
              validate={[required]}
              onInput={(e) => this.allowAlphaNumerical(36, e)}
            />
            <Styled.CodigoPostalContainer>
              <Styled.FormFieldHalf
                component={renderInput}
                label={text.externalNumber}
                name="external_number"
                type="number"
                validate={[required]}
              />
              <Styled.FormFieldHalf
                component={renderInput}
                label={text.internalNumber}
                type="text"
                inputProps={{ maxLength: 5 }}
                name="internal_number"
                onInput={(e) => this.replaceAccents(e)}
              />
            </Styled.CodigoPostalContainer>
            <Styled.FormField
              component={renderInput}
              label={text.postalCode}
              type="number"
              name="postal_code"
              validate={[required, postalCode]}
              onChange={this.validateZipcode}
            />
            <Styled.FormField
              component={renderSelectField}
              label={text.colony}
              name="colony"
              options={
                berlin.colonies.length > 0
                  ? berlin.colonies
                  : this.props.initialValues.colonies
              }
              validate={[required]}
            />
            <Styled.FormField
              component={renderInput}
              label={text.municipality}
              name="municipality"
              validate={[required]}
              disabled={true}
            />
            <Styled.FormField
              component={renderInput}
              label={text.city}
              name="city"
              validate={[required]}
              disabled={true}
            />
            <Styled.FormField
              component={renderInput}
              label={text.state}
              name="state"
              validate={[required]}
              disabled={true}
            />
            <Styled.Contacto>{text.contact}</Styled.Contacto>
            <Styled.ContactoDescripcion>
              {text.contactDescription}
            </Styled.ContactoDescripcion>
            <Styled.FormField
              component={renderInput}
              label={text.phone}
              name="phone_number"
              type="number"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              validate={[required]}
            />
            <Styled.FormField
              component={renderInput}
              label={text.email}
              name="email"
              inputProps={{ maxLength: 64 }}
              validate={[required]}
            />
            <Styled.SubmitButton
              disabled={
                invalid || !this.isGenderSelected() || !this.birthDateComplete()
              }
            >
              {text.buttonText}
            </Styled.SubmitButton>
          </Styled.DocumentosContainer>
        </form>
      </Styled.RegistroDocumentosContainer>
    );
  }
}

const selector = formValueSelector(Forms.REGISTRATION_INFORMATION);

const mapStateToProps = (state) => {
  return {
    berlin: state.berlin,
    selectedGender: selector(state, 'gender'),
    birthDate: selector(state, 'birth_date'),
  };
};

const ReduxDocumentos = reduxForm(
  {
    form: Forms.REGISTRATION_INFORMATION,
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
  },
  mapStateToProps,
)(Datos);

export default connect(mapStateToProps)(ReduxDocumentos);
