import { Actions, Categories, Events } from '../constants/GTMConstants';

export default {
  /*
   These events are related to CREDICLIP section
   */
  pageView: (preapprovalId, merchantId, urlLoans) => {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: Events.CREDICLIP_PAGE_VIEW,
      eventCategory: Categories.CREDICLIP_FONEL,
      eventAction: Actions.CREDICLIP_FONEL_PAGEVIEW,
      preapprovalId: preapprovalId,
      merchantId: merchantId,
      url: urlLoans,
    });
  },

  // Virtual Page View
  virtualPageView: (nextState) => {
    if (!window.dataLayer) return;
    window.dataLayer.push({
      event: Events.VIRTUAL_PAGE_VIEW,
      page: nextState.location.pathname,
      title: '',
    });
  },
  /*
  End of Crediclip events
  */
};
