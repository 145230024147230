import urls  from 'helpers/urls';
import { loansClipPhone as ClipPhone } from 'constants/AppConstants';

export default {
    helpNeed: '¿Necesitas ayuda?',
    contactExternalMessageAdvice: 'Nuestro servicio de atención a clientes Customer Happiness® te brinda atención personalizada os 7 días de la semana, las 24 h del día. Escríbenos por WhatsApp  o llámanos al',
    contactMessageNumber: '55 6393-2323',
    contactWeb: 'www.clip.mx',
    contactMobile: ` ${ClipPhone} `,
    contactEmial: 'Envía un correo a ',
    contactUrlEmail: `${urls.clipHelpEmail}`,
    contactSoluctud: ' o solicita apoyo en el chat de',
    contactClipMx: 'clip.mx.',
  };
  