import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import BerlinService from '../service';

import {
  getHour,
  amountFormat,
  getAmountColor,
  formatDateDetail,
} from '../utils';
import { ScreenContext, SCREENS } from '../contexts/ScreenContext';

import text from './text';
import OneStepNavigator from '../Commons/OneStepNavigator';
import DetailSkeleton from './DetailSkeleton';
import styles from './styles';
import WhatsAppModule from '../WhatsAppModule';

const formatStatus = (status, type) => {
  if (status === 'COMPLETED') {
    return text.statusList[`${status}_${type}`];
  }
  return text.statusList[status];
};

const isSPEI = (transaction, classes) => {
  if (transaction.movement_type.includes('ACCOUNT_INTERBANK')) {
    return (
      <>
        <Typography variant="subheading">{text.referenceTitle}</Typography>
        <Typography variant="body2">{transaction.reference}</Typography>

        <Typography variant="subheading">{text.status}</Typography>
        <Typography
          variant="body2"
          className={
            transaction.operation_status === 'COMPLETED' ? classes.positive : ''
          }
        >
          {formatStatus(transaction.operation_status, transaction.cash_flow)}
        </Typography>

        <Typography variant="subheading">{text.movementType}</Typography>
        <Typography variant="body2" className={classes.title}>
          {transaction.description}
        </Typography>

        {transaction.cash_flow === 'IN' ? (
          <>
            <Typography variant="subheading">{text.idTitle}</Typography>
            <Typography variant="body2">{transaction.movement_id}</Typography>
          </>
        ) : (
          ''
        )}
      </>
    );
  }

  if (transaction.movement_type === 'CARD_ATM_WITHDRAWAL') {
    return (
      <>
        <Typography variant="subheading">{text.establishment}</Typography>
        <Typography variant="body2" className={classes.title}>
          {transaction.title || text.withdrawal}
        </Typography>

        <Typography variant="subheading">{text.idTitle}</Typography>
        <Typography variant="body2">{transaction.movement_id}</Typography>
      </>
    );
  }

  return (
    <>
      <Typography variant="subheading">{text.movementType}</Typography>
      <Typography variant="body2" className={classes.title}>
        {transaction.description}
      </Typography>
      <Typography variant="subheading">{text.idTitle}</Typography>
      <Typography variant="body2">{transaction.movement_id}</Typography>
      <Typography variant="subheading">{text.authNumber}</Typography>
      <Typography variant="body2">{transaction.authorization_code}</Typography>
    </>
  );
};

const getTitle = (transaction) => {
  if (transaction.movement_type === 'CARD_ATM_WITHDRAWAL') {
    return text.withdrawal;
  }
  return transaction.title || transaction.description || '';
};

const TransactionDetail = (props) => {
  const { classes, transaction } = props;
  const { setScreenState } = useContext(ScreenContext);
  const [speiDetails, setSpeiDetails] = useState(null);

  useEffect(() => {
    const fetchSpeiDetails = async () => {
      let details = await BerlinService.speiDetails(transaction.reference);
      setSpeiDetails(details.data.message);
      console.log(details.data.message);
    };
    if (
      transaction.movement_type.includes('ACCOUNT_INTERBANK') &&
      transaction.cash_flow === 'OUT'
    ) {
      fetchSpeiDetails();
    }
  }, [transaction]);

  const renderDetail = () => {
    if (
      transaction.movement_type.includes('ACCOUNT_INTERBANK') &&
      transaction.cash_flow === 'OUT'
    ) {
      if (speiDetails) {
        return (
          <>
            <Typography variant="headline">{`${text.transferTo} ${
              speiDetails.recipient.name.split(' ')[0]
            }`}</Typography>
            <Typography
              variant="title"
              className={`${classes.amount} ${
                getAmountColor(transaction.cash_flow) ? classes.positive : ''
              }`}
              align="center"
            >
              {amountFormat(transaction.amount, transaction.cash_flow)}
            </Typography>
            <div className={classes.dateSection}>
              <Typography variant="subheading" align="center">
                {formatDateDetail(transaction.movement_date)}
              </Typography>
              <Typography variant="subheading" align="center">
                {getHour(transaction.movement_date)}
              </Typography>
            </div>

            <Typography variant="subheading">{text.detail}</Typography>
            <Typography variant="body2">{speiDetails.detail}</Typography>

            <Typography variant="subheading">{text.referenceTitle}</Typography>
            <Typography variant="body2">{speiDetails.reference}</Typography>

            <Typography variant="subheading">{text.state}</Typography>
            <Typography
              variant="body2"
              className={
                transaction.operation_status === 'COMPLETED'
                  ? classes.positive
                  : ''
              }
            >
              {formatStatus(
                transaction.operation_status,
                transaction.cash_flow,
              )}
            </Typography>
            <hr className={classes.sectionLine} />
            <Typography className={classes.sectionSplit} variant="subheading">
              {text.senderInfo}
            </Typography>

            <Typography variant="subheading">{text.receiver}</Typography>
            <Typography variant="body2">
              {speiDetails.recipient.name}
            </Typography>

            <Typography variant="subheading">{text.clabe}</Typography>
            <Typography variant="body2">
              {speiDetails.recipient.account}
            </Typography>

            <Typography variant="subheading">{text.trackingKey}</Typography>
            <Typography variant="body2">{speiDetails.tracking_key}</Typography>

            <Typography variant="subheading">{text.idTitle}</Typography>
            <Typography variant="body2">{transaction.movement_id}</Typography>
          </>
        );
      }
      return <DetailSkeleton />;
    }
    return (
      <>
        <Typography variant="headline" align="center" className={classes.title}>
          {getTitle(transaction)}
        </Typography>
        <Typography
          variant="title"
          className={`${classes.amount} ${
            getAmountColor(transaction.cash_flow) ? classes.positive : ''
          }`}
          align="center"
        >
          {amountFormat(transaction.amount, transaction.cash_flow)}
        </Typography>
        <div className={classes.dateSection}>
          <Typography variant="subheading" align="center">
            {formatDateDetail(transaction.movement_date)}
          </Typography>
          <Typography variant="subheading" align="center">
            {getHour(transaction.movement_date)}
          </Typography>
        </div>

        {isSPEI(transaction, classes)}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <OneStepNavigator
        title={text.navigatorTitle}
        stepBackFunction={setScreenState}
        stepBackTarget={{ activeScreen: SCREENS.BALANCE }}
      />
      <div className={classes.section}>{renderDetail()}</div>
      <WhatsAppModule />
    </div>
  );
};

TransactionDetail.defaultProps = {
  transaction: {},
  classes: {},
};

TransactionDetail.propTypes = {
  transaction: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(TransactionDetail);
