import { change as updateFormField, reset } from 'redux-form';

import logger from '../../../helpers/logger';
import utils from '../../../helpers/utils';
import {
  Forms,
  HTTPStatusCodes,
  LoansFilesStates,
  LoansDocuments,
} from '../../../constants/AppConstants';
import {
  loansProviderCodes,
  validExternalProvider,
} from '../../../constants/ProviderCodes';
import {
  getLastOneSuccess,
  setLastOneDocumentationSuccess,
  setPrefilledFormSuccess,
  setPrefilledColoniesSuccess,
  setPrefilledDaysSuccess,
  getPreapprovalsSuccess,
  getLoansSuccess,
  getCollectionsSuccess,
  requesLoanSentSuccess,
  uploadDocumentSuccess,
  deleteDocumentsNotSavedSuccess,
  turnOnDragFileSuccess,
  turnOffDragFileSuccess,
  getColoniesSuccess,
  setPreapprovalSuccess,
  getDaysSuccess,
  deleteDocumentSuccess,
  cleanPreapproval,
  cleanLoansSuccess,
  setSendingFormSuccess,
  cleanIdentity,
  cleanIdentityBack,
  cleanPassport,
  cleanProofOfAddress,
  setConfirmAddressSuccess,
  setLoansLoadedSuccess,
  setPrestaClipNameChange,
  setShowCashAdvance,
  requesRefillSuccess,
  setLoansFormVersion,
  setLoansRegularOfferVersion,
  setLoansFormURL,
  setShowLoansTestingForm,
  setDismissChangeOfferAlert,
  setShowChangeOfferAlert,
} from './loansActions';
import LoansService from '../service';
import { getConfigValue } from '../../../config/config';
import LDClient from 'launchdarkly-js-client-sdk';
import { getProviderCodeForURL } from '../../../helpers/getProviderUrl';
import urls from '../../../helpers/urls';

export const turnOnDragFile = () => (dispatch) =>
  dispatch(turnOnDragFileSuccess());

export const turnOffDragFile = () => (dispatch) =>
  dispatch(turnOffDragFileSuccess());

/**
 * Used to follow up the files loaded, since they are selected, uploaded
 * received by the server, removed or canceled when they are in process of loading
 * @param {*} type document type associated to the file received
 * @param {*} file object containing the main fields associated to each file added in the form used to request a loan
 */
export const addFile = (type, file) => (dispatch) =>
  dispatch(uploadDocumentSuccess({ type, file }));

export const deleteStateFile = (type, fileName) => (dispatch) =>
  dispatch(deleteDocumentSuccess({ type, fileName }));

export const changePreapproval = () => (dispatch) =>
  dispatch(cleanPreapproval());

export const cleanLoans = () => (dispatch) => dispatch(cleanLoansSuccess());

export const cleanLastOneIdentity = () => (dispatch) =>
  dispatch(cleanIdentity());
export const cleanLastOneIdentityBack = () => (dispatch) =>
  dispatch(cleanIdentityBack());
export const cleanLastOnePassport = () => (dispatch) =>
  dispatch(cleanPassport());
export const cleanLastOneProofOfAddress = () => (dispatch) =>
  dispatch(cleanProofOfAddress());

export const deleteFilesNotSaved = (type) => (dispatch) =>
  dispatch(deleteDocumentsNotSavedSuccess({ type }));

export const uploadFile =
  (
    preapprovalId,
    type,
    file,
    id,
    onUploadProgress,
    onFileUploaded,
    cancelToken,
  ) =>
  (dispatch) => {
    const token = cancelToken ? cancelToken.token : null;
    LoansService.uploadFile(
      preapprovalId,
      file,
      id,
      (progressEvent) => {
        onUploadProgress(type, file.name, progressEvent);
      },
      token,
    )
      .then((response) => {
        if (
          response.status === HTTPStatusCodes.ACCEPTED ||
          response.status === HTTPStatusCodes.OK
        ) {
          const { document_key } = response.data;
          onFileUploaded && onFileUploaded(type, file.name, document_key);
        } else {
          dispatch(
            addFile(type, {
              status: LoansFilesStates.ERROR,
              name: file.name,
            }),
          );
        }
      })
      .catch((err) => {
        if (err.message === LoansDocuments.CANCEL_UPLOAD) {
          dispatch(deleteStateFile(type, file.name));
        } else {
          logger.error(`error trying to upload a loans file: ${id}`, err);
          dispatch(
            addFile(type, {
              status:
                err.message === 'Network Error'
                  ? LoansFilesStates.DISCONNECTED
                  : LoansFilesStates.ERROR,
              name: file.name,
            }),
          );
        }
      });
  };

export const deleteFile =
  (id, preapprovalId, documentType, fileName) => (dispatch) => {
    LoansService.deleteFile(id, preapprovalId)
      .then((response) => {
        const status =
          response.status === HTTPStatusCodes.NO_CONTENT
            ? LoansFilesStates.REMOVED
            : LoansFilesStates.NOT_FOUND;
        dispatch(onDeletedFile(status, documentType, fileName));
      })
      .catch((err) => {
        logger.error(`error trying to remove a loans file: ${id}`, err);
        dispatch(
          onDeletedFile(LoansFilesStates.NOT_FOUND, documentType, fileName),
        );
      });
  };

const onDeletedFile = (status, documentType, name) => (dispatch) => {
  if (status === LoansFilesStates.REMOVED) {
    dispatch(deleteStateFile(documentType, name));
  } else {
    dispatch(addFile(documentType, { status, name }));
  }
};

export const saveMerchantData = (
  last_pre_approval_id,
  pre_approval_id,
  provider_pre_approval_id,
  proxy_merchant_token,
  provider_code,
  amount,
  form,
  indentityDocuments,
  proodAddressDocuments,
) => {
  const {
    birthday: formBirthday,
    month,
    day,
    year,
    firstName: name,
    lastName: last_name,
    secondLastName: second_last_name,
    postalCode: postal_code,
    colony,
    municipality,
    state,
    street,
    streetNumber: street_no_exterior,
    optionalStreetNumber: street_no_interior,
    extraTelephone: extra_telephone,
  } = form;
  let { extraTelephones: extra_telephones } = form;
  let birthday;
  let today = new Date();
  birthday =
    provider_code === loansProviderCodes.APH
      ? utils.dateToString(
          today.getMonth() + 1,
          today.getDate(),
          today.getFullYear() - 18,
        )
      : formBirthday
      ? formBirthday
      : utils.dateToString(month, day, year);
  extra_telephones = extra_telephones
    ? [].concat(extra_telephone, extra_telephones) 
    : [].concat(extra_telephone);

  const documents = [...indentityDocuments, ...proodAddressDocuments];
  const loansFields = {
    amount,
    provider_code,
    name,
    last_name,
    second_last_name,
    birthday,
    postal_code,
    colony,
    municipality,
    state,
    street,
    street_no_interior,
    street_no_exterior,
    documents,
    extra_telephones,
  };
  if (validExternalProvider(provider_code)) {
    const {
      gender,
      maritalStatus: marital_status,
      hasCreditCard: has_credit_card = false,
      lastCreditCardNumbers: last4,
      hasMortgageCredit: has_mortgage_loan = false,
      hasVehicleCredit: has_vehicle_loan = false,
      addressType: address_type,
      addressYear: address_year,
      addressMonth: address_month,
    } = form;
    let { ine, curp, rfc } = form;
    ine = ine ? ine.toUpperCase() : null;
    curp = curp ? curp.toUpperCase() : null;
    rfc = rfc ? rfc.toUpperCase() : null;
    const mrPrestaFields = {
      last_pre_approval_id,
      pre_approval_id,
      provider_pre_approval_id,
      proxy_merchant_token,
      gender,
      marital_status,
      has_credit_card,
      last4,
      has_mortgage_loan,
      has_vehicle_loan,
      address_type,
      address_year,
      address_month,
      ine,
      rfc,
      curp,
    };
    return callSaveMerchantData({ ...loansFields, ...mrPrestaFields });
  }
  return callSaveMerchantData(loansFields);
};

const callSaveMerchantData = (form) => (dispatch) => {
  dispatch(setSendingFormSuccess(true));
  return LoansService.requestLoan(mapPreapprovalRequestToV2Request(form))
    .then((response) => {
      dispatch(setSendingFormSuccess(false));
      if (
        [
          HTTPStatusCodes.OK,
          HTTPStatusCodes.CREATED,
          HTTPStatusCodes.NO_CONTENT,
          HTTPStatusCodes.ACCEPTED,
        ].includes(response.status)
      ) {
        const formOrigin =
          form.provider_code === loansProviderCodes.MRP
            ? Forms.MR_PRESTA
            : Forms.LOANS;
        dispatch(reset(formOrigin));
        dispatch(requesLoanSentSuccess());
      }
    })
    .catch((err) => {
      dispatch(setSendingFormSuccess(false));
      logger.error('error trying to save merchant data', err);
    });
};

const mapPreapprovalRequestToV2Request = (form) => {
  const {
    last_pre_approval_id = '',
    pre_approval_id = '',
    provider_pre_approval_id = '',
    proxy_merchant_token = '',
    provider_code,
    amount,
    documents,
    //personal information
    name,
    last_name,
    second_last_name,
    birthday,
    gender,
    ine,
    rfc,
    curp,
    marital_status,
    extra_telephones,
    //address information
    address_year,
    address_month,
    postal_code,
    colony,
    municipality,
    state,
    street,
    street_no_interior,
    street_no_exterior,
    //bank information
    has_credit_card,
    has_mortgage_loan,
    has_vehicle_loan,
    last4,
  } = form;
  let updated_address_type =
    provider_code === loansProviderCodes.APH ? 'Trabajo' : 'Casa';
  return {
    last_pre_approval_id,
    pre_approval_id,
    provider_code,
    provider_pre_approval_id,
    proxy_merchant_token,
    amount,
    personal_information: {
      name,
      last_name,
      second_last_name,
      birthday,
      gender,
      ine,
      rfc,
      curp,
      marital_status,
      extra_telephones,
    },
    address_information: {
      address_type: updated_address_type,
      address_year,
      address_month,
      postal_code,
      colony,
      municipality,
      state,
      street,
      street_no_interior,
      street_no_exterior,
    },
    bank_information: {
      has_credit_card,
      has_mortgage_loan,
      has_vehicle_loan,
      last4,
    },
    documents,
  };
};

export const setLastOneDocumentation = (documents) => (dispatch) =>
  dispatch(setLastOneDocumentationSuccess(documents));

export const setPrefilledForm = (prefilledForm) => (dispatch) =>
  dispatch(setPrefilledFormSuccess(prefilledForm));

export const setPrefilledColonies = (colonies) => (dispatch) =>
  dispatch(setPrefilledColoniesSuccess(colonies));

export const setPrefilledDays = (days) => (dispatch) =>
  dispatch(setPrefilledDaysSuccess(days));

export const getPreapprovals = () => (dispatch) =>
  LoansService.getPreapprovals()
    .then((response) => {
      const { data } = response;
      if (
        response.status === HTTPStatusCodes.OK &&
        Array.isArray(data) &&
        data.length > 0
      ) {
        if ('loan_payback' in data[0]) dispatch(getPreapprovalsSuccess(data));
        else {
          return dispatch(getPreapprovals());
        }

        const user = {
          key: data[0].proxy_merchant_token,
        };
        const ldClient = LDClient.initialize(
          getConfigValue('launchDarklyKey'),
          user,
        );
        ldClient.on('ready', function () {
          const showCashAdvanceInfo = ldClient.variation(
            getConfigValue('launchDarklyLoansShowCashAdvanceInfo'),
            false,
          );
          dispatch(setShowCashAdvance(showCashAdvanceInfo));

          const loansFormVersion = ldClient.variation(
            getConfigValue('loansMerchantDashboardFormVersionFrontEnd'),
            Forms.VERSION_3,
          );
          dispatch(setLoansFormVersion(loansFormVersion));

          const loansRegularOfferVersion = ldClient.variation(
            getConfigValue('loansMerchantDashboardRegularOffersVersion'),
            false,
          );
          dispatch(setLoansRegularOfferVersion(loansRegularOfferVersion));

          let loansFormURL;
          if (loansFormVersion.V4.includes(data[0].provider_code)) {
            loansFormURL = showCashAdvanceInfo
              ? urls.loansV4CA.replace(
                  ':lender',
                  getProviderCodeForURL(data[0].provider_code),
                )
              : urls.loansV4.replace(
                  ':lender',
                  getProviderCodeForURL(data[0].provider_code),
                );
          } else {
            loansFormURL = showCashAdvanceInfo
              ? urls.loansV3CA.replace(
                  ':lender',
                  getProviderCodeForURL(data[0].provider_code),
                )
              : urls.loansV3.replace(
                  ':lender',
                  getProviderCodeForURL(data[0].provider_code),
                );
          }
          dispatch(setLoansFormURL(loansFormURL));

          const showNewProductName = ldClient.variation(
            getConfigValue('launchDarklyPrestaClipFlagName'),
            false,
          );
          dispatch(setPrestaClipNameChange(showNewProductName));

          const showTestingForm = ldClient.variation(
            getConfigValue('launchDarklyLoansFormV3FlagName'),
            false,
          );
          dispatch(setShowLoansTestingForm(showTestingForm));

          LoansService.getLastOne()
            .then((response) => response.data)
            .then((data) => dispatch(getLastOneSuccess(data)))
            .catch((err) => {
              logger.info(
                'no previous preapproval fetched for actual merchant',
              );
              LoansService.getMerchantInfo()
                .then((response) => response.data)
                .then((data) => dispatch(getLastOneSuccess(data)))
                .catch((err) =>
                  logger.info('no merchant infor fetched from loans services'),
                );
            });
        });
        return;
      }
      dispatch(getPreapprovalsSuccess([]));
    })
    .catch((err) => {
      logger.info('no preapprovals fetched for actual merchant');
      dispatch(getPreapprovalsSuccess([]));
    });

export const getLoans = () => (dispatch) =>
  LoansService.getLoans()
    .then((response) => response.data)
    .then((data) => {
      if (Array.isArray(data) && data.length > 0) {
        if ('end_date_local' in data[0]) {
          dispatch(getLoansSuccess(data));
          dispatch(setLoansLoadedSuccess());
        } else {
          return dispatch(getLoans());
        }
      } else {
        dispatch(getLoansSuccess(data));
        dispatch(setLoansLoadedSuccess());
      }
    })
    .catch((err) => {
      logger.error('error trying to fetch loan approved', err);
      dispatch(setLoansLoadedSuccess());
    });

export const getCollections = (loanId, payments) => (dispatch) =>
  LoansService.getCollections(loanId, payments)
    .then((response) => {
      if (response.status === HTTPStatusCodes.OK && response.data.length > 0) {
        dispatch(getCollectionsSuccess(loanId, response.data));
      }
    })
    .catch((err) => {
      logger.error('error trying to fetch loan collections', err);
    });

export const getColonies = (zipcode, form) => (dispatch) => {
  if (!zipcode) {
    dispatch(updateFormField(form, 'colony', ''));
    dispatch(updateFormField(form, 'municipality', ''));
    dispatch(updateFormField(form, 'state', ''));
    dispatch(getColoniesSuccess([]));
    return Promise.resolve({ mexico_zipcodes: [0] });
  }
  return LoansService.getColonies(zipcode)
    .then((response) => {
      const location = response.data.mexico_zipcodes.reduce(
        (memo, item) => {
          memo.zipcode = item.zipcode;
          memo.municipality = item.municipality;
          memo.state = item.state;
          memo.colonies.push({ id: item.colony, name: item.colony });
          return memo;
        },
        { zipcode: null, municipality: null, state: null, colonies: [] },
      );

      if (location.colonies.length > 0) {
        dispatch(getColoniesSuccess(location.colonies));
        dispatch(updateFormField(form, 'colony', location.colonies[0].id));
      }
      dispatch(updateFormField(form, 'municipality', location.municipality));
      dispatch(updateFormField(form, 'state', location.state));
      return response.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const clearAddressFields = (form) => (dispatch) => {
  dispatch(updateFormField(form, 'colony', ''));
  dispatch(updateFormField(form, 'municipality', ''));
  dispatch(updateFormField(form, 'state', ''));
  dispatch(getColoniesSuccess([]));
};

export const setColonies = (payload, form) => (dispatch) => {};

export const savePreapprovalSelected = (preapproval) => (dispatch) => {
  dispatch(setPreapprovalSuccess(preapproval));
};

export const savePreapprovalSelectedWithAlert = (preapproval) => (dispatch) => {
  dispatch(setPreapprovalSuccess(preapproval));
  dispatch(setShowChangeOfferAlert());
};

export const dismissChangeOfferAlert = () => (dispatch) => {
  dispatch(setDismissChangeOfferAlert());
};

export const setLoansDays = (year, month) => (dispatch) => {
  const days = utils.getDays(month, year);
  dispatch(getDaysSuccess(days));
};

export const setConfirmAddress = (confirmAddress) => (dispatch) => {
  dispatch(setConfirmAddressSuccess(confirmAddress));
};

export const requestRefill = (preapproval, success) => (dispatch) => {
  const {
    provider_code,
    pre_approval_id,
    provider_pre_approval_id,
    proxy_merchant_token,
  } = preapproval;
  const request = {
    provider_code,
    pre_approval_id,
    provider_pre_approval_id,
    proxy_merchant_token,
  };
  LoansService.requestRefill(request)
    .then((response) => response.status)
    .then(
      (status) =>
        HTTPStatusCodes.ACCEPTED === status &&
        (dispatch(requesRefillSuccess(request.pre_approval_id)) || success()),
    )
    .catch((err) => {
      logger.error('error trying to save a refill', err);
      dispatch(cleanPreapproval());
    });
};

export const saveSamsclubMembership =
  (proxy_merchant_token, membership, onSuccess) => (dispatch) => {
    const request = {
      proxy_merchant_token,
      membership,
    };
    LoansService.requestSamsclubMembership(request)
      .then(({ status }) => status === HTTPStatusCodes.ACCEPTED && onSuccess())
      .catch((err) =>
        logger.error('error trying to store sams club membership', err),
      );
  };

export const saveOpenDataOffer =
  (pre_approval_id, provider_pre_approval_id, proxy_merchant_token) =>
  (dispatch) => {
    const request = {
      pre_approval_id,
      proxy_merchant_token,
      provider_pre_approval_id,
    };
    LoansService.requestOpenData(request)
      .then(
        ({ status }) =>
          status === HTTPStatusCodes.ACCEPTED &&
          dispatch(requesRefillSuccess(pre_approval_id)),
      )
      .catch((err) => {
        logger.error('error trying to store open-data offer selected', err);
      });
  };
