// Codi Actions
export const FETCH_CODI = 'codi/FETCH_CODI';
export const FETCH_CODI_ERROR = 'codi/FETCH_CODI_ERROR';
export const SET_CODI = 'codi/SET_CODI';
export const FETCH_CODI_DETAIL = 'codi/FETCH_CODI_DETAIL';
export const FETCH_CODI_DETAIL_ERROR = 'codi/FETCH_CODI_DETAIL_ERROR';
export const SET_CODI_DETAIL = 'codi/SET_CODI_DETAIL';
export const DOWNLOAD_CODI_REPORT = 'codi/DOWNLOAD_CODI_REPORT';
export const DOWNLOAD_CODI_REPORT_ERROR = 'codi/DOWNLOAD_CODI_REPORT_ERROR';
export const GET_CODI_SETTINGS = 'codi/GET_CODI_SETTINGS';
export const GET_CODI_SETTINGS_ERROR = 'codi/GET_CODI_SETTINGS_ERROR';
