import React from 'react';

import * as Styled from './styled';

export class LoadingTransactions extends React.Component {
  render() {
    return (
      <div>
        <Styled.NoTransactionContainer>
          <Styled.DatePlaceholder></Styled.DatePlaceholder>
          <Styled.PlaceholdersContainer>
            <Styled.PlaceholdersIconContainer>
              <Styled.PlaceholdersIcon></Styled.PlaceholdersIcon>
            </Styled.PlaceholdersIconContainer>
            <Styled.PlaceholdersMidContainer>
              <Styled.PlaceholderDescription></Styled.PlaceholderDescription>
              <Styled.PlaceholderHour></Styled.PlaceholderHour>
            </Styled.PlaceholdersMidContainer>
            <Styled.PlaceholdersEndContainer>
              <Styled.PlaceholderHour></Styled.PlaceholderHour>
              <Styled.PlaceholderBalance></Styled.PlaceholderBalance>
            </Styled.PlaceholdersEndContainer>
          </Styled.PlaceholdersContainer>
          <Styled.PlaceholdersContainer>
            <Styled.PlaceholdersIconContainer>
              <Styled.PlaceholdersIcon></Styled.PlaceholdersIcon>
            </Styled.PlaceholdersIconContainer>
            <Styled.PlaceholdersMidContainer>
              <Styled.PlaceholderDescription></Styled.PlaceholderDescription>
              <Styled.PlaceholderHour></Styled.PlaceholderHour>
            </Styled.PlaceholdersMidContainer>
            <Styled.PlaceholdersEndContainer>
              <Styled.PlaceholderHour></Styled.PlaceholderHour>
              <Styled.PlaceholderBalance></Styled.PlaceholderBalance>
            </Styled.PlaceholdersEndContainer>
          </Styled.PlaceholdersContainer>
        </Styled.NoTransactionContainer>
      </div>
    );
  }
}

export default LoadingTransactions;
