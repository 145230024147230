import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PreapprovalStatus, LoansTabs } from 'constants/AppConstants';
import { getPreapprovals } from 'components/loans/redux/selectors';
import LoansContent from 'components/loans/Commons/LoansContent';
import * as Styled from './styled';
import text from './text';

import FAQDetail from './Details/index';
import FAQIcon from '../../../../assets/images/faqs.svg';

export class FAQPage extends PureComponent {
  render() {
    const { preapprovals = [] } = this.props;
    const availables = preapprovals.filter(
      (preapproval) =>
        [
          PreapprovalStatus.AVAILABLE,
          PreapprovalStatus.ACCEPTED,
          PreapprovalStatus.SIGNED,
        ].includes(preapproval.status) && !preapproval.loan_id,
    );
    const expired = preapprovals.filter((preapproval) =>
      [PreapprovalStatus.EXPIRED].includes(preapproval.status),
    );
    const withLoans = preapprovals.filter(
      (preapproval) => preapproval.loan_id !== null,
    );

    return (
      <LoansContent
        hasPreapprovals={availables.length > 0 || expired.length > 0}
        hasLoans={withLoans.length > 0}
        tab={LoansTabs.FAQS}
      >
        <Styled.Container>
          <Styled.Title>
            <Styled.TitleText>{text.sectionTitle}</Styled.TitleText>
            <Styled.TitleIconContainer>
              <Styled.TitleIcon src={FAQIcon} />
            </Styled.TitleIconContainer>
          </Styled.Title>
          <Styled.Content>
            {text.faqs.map((faq, index) => (
              <FAQDetail faq={faq} index={index} key={`questions_${index}`} />
            ))}
          </Styled.Content>
        </Styled.Container>
      </LoansContent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
  };
};

export default connect(mapStateToProps)(FAQPage);
