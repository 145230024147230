import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { getPrestaClipNameChange } from '../../redux/selectors';
import { connect } from 'react-redux';
import { Link, withRouter, useLocation } from 'react-router-dom';

import {
  LoansStates,
  LoansCurrentProductName,
  LoansNewProductName,
} from '../../../../constants/AppConstants';
import { toCurrencyFormat } from '../../../../helpers/formatCurrency';
import MyCrediClipCollection from '../Collection';
import Alert from '../../MyCrediClip/Details/Alert';
import DebtDetail from '../../MyCrediClip/Details/Debt';
import text from '../../MyCrediClip/text';
import * as Styled from '../../MyCrediClip/styled';
import { getCollections as loadCollections } from '../../redux/actions';
import getUrl from '../../../../helpers/getUrl';
import urls from '../../../../helpers/urls';
import { ClipTypography } from '@clipmx/clip-storybook';
import { Clevertap, EVENTS } from '../../../../helpers/Clevertap';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { getProviderUrl } from '../../../../helpers/getProviderUrl';
import GTMCrediClipAnalyticsEvents from '../../../../helpers/GTMCrediClipAnalyticsEvents';
import moment from 'moment-timezone';
import { lenderPayload } from 'components/loans/Commons/Clevertap';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ClipButton from 'components/npm/clip-ui/Button';
import styles from '../styles';

const decimalFormatWithDecimals = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const MyCrediClipDetail = ({
  prestaClipNameChange,
  loan: {
    loan_amount: amount,
    payback: totalPayback,
    paid_amount: totalPaid,
    interest,
    tax,
    start_date_local: startDate,
    end_date_local: endDateTotal,
    retention_percentage: retentationPercentage,
    status,
    balance: { overdue_interest: overdueInterest = 0, total },
    balance,
    provider_code: providerCode,
    installment,
  },
  loan,
  showCashAdvance,
  monthlyCapCheck,
}) => {
  const [detail, setDetail] = useState(false);
  const [historic, setHistoric] = useState(false);

  let location = useLocation();

  const endDate = monthlyCapCheck ? installment?.expiration_date : endDateTotal;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const calculateMothsBetween = (strStartDate, strEndDate) => {
    return Math.round(
      moment(strEndDate).diff(moment(strStartDate), 'months', true),
    );
  };
  const calculateOverdueDays = (strEndDate) => {
    const endDate = new Date(strEndDate);
    const today = new Date();
    const difference = today.getTime() - endDate.getTime();
    return Math.ceil(difference / (1000 * 60 * 60 * 24));
  };
  const formatDate = (strDate) => {
    return strDate.split('-').reverse().join('/');
  };
  const getAlertType = (status, overdueInterest, overdueDays, providerCode) => {
    if (
      providerCode === 'MRP' &&
      ![LoansStates.CANCELLED, LoansStates.PAID, LoansStates.ON_HOLD].includes(
        status,
      )
    ) {
      if (overdueDays && overdueDays < 8) {
        return 'upcoming';
      }
      if (overdueInterest) {
        return 'overdue';
      }
    }
  };

  const getAdvancePaymentUrlByProvider = (providerCode) => {
    return getUrl(
      getProviderUrl(
        providerCode,
        urls.loansMrPrestaAdvancePayment,
        urls.loansMOTAdvancePayment,
        urls.loansAPHAdvancePayment,
        urls.loansCTOAdvancePayment,
        urls.loansR2AdvancePayment,
        urls.loansAVLAdvancePayment,
        urls.loans,
      ),
    );
  };

  const getBaseLocation = () =>
    location.pathname.startsWith(urls.mobile) ? urls.mobile : '';

  const visitAdvancePaymentEvent = (loan) => {
    const advancePaymentURL = getAdvancePaymentUrlByProvider(
      loan.provider_code,
    );
    const merchant_id = loan.merchant_id;
    GTMCrediClipAnalyticsEvents.pageView('NA', merchant_id, advancePaymentURL);
  };

  const renderNewProductName = (prevText) => {
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  const interestAndTax = parseFloat(interest) + parseFloat(tax);
  const months = calculateMothsBetween(startDate, endDateTotal);
  const progressWidth = Math.floor((totalPaid / totalPayback) * 100);
  const alertType = getAlertType(
    status,
    overdueInterest,
    calculateOverdueDays(endDate),
    providerCode,
  );
  const finishedLoan = [
    LoansStates.CANCELLED,
    LoansStates.PAID,
    LoansStates.ON_HOLD,
  ].includes(status);
  const monthBalance = installment?.current_month_balance; 
  const monthlyCapDebt = installment?.debt; 
  const monthlyExpiration = installment?.expiration_date; 
  const actualPeriod = installment?.number; 
  const totalPeriod = installment?.total_installments;
  const debtDetail = !finishedLoan && <DebtDetail balance={balance} monthlyCapCheck={monthlyCapCheck} />;
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const formattedEndDatePeriod = monthlyCapCheck ? formatDate(monthlyExpiration) : null;
  const approvalActive = { provider_code: providerCode,  proxy_merchant_token: loan?.merchant_token}
  return (
    <Styled.CrediCard
      title={`${renderNewProductName(text.myCrediClipTitle)}`}
    >
      <Styled.ProgressBG>
        <Styled.Progress
          style={{ width: `${progressWidth > 100 ? 100 : progressWidth}%` }}
          className={finishedLoan && 'finished'}
        />
      </Styled.ProgressBG>
      <div className={classes.paysContainer}>
        <Styled.PaysContainerLeft>
          <Styled.Label>{text.paid}</Styled.Label>
          <Styled.Value
            className={[
              finishedLoan ? 'finished' : 'teil',
              'medium',
              'bold',
            ].join(' ')}
          >
            {LoansStates.PAID === status && text.totaly}
            {LoansStates.CANCELLED === status && text.cancelled}
            {![LoansStates.PAID, LoansStates.CANCELLED].includes(status) &&
              toCurrencyFormat(totalPaid)}
          </Styled.Value>
        </Styled.PaysContainerLeft>
        <Styled.PaysContainerRight>
          <Styled.Label className="full">{text.byPay}</Styled.Label>
          <Styled.Value
            className={[
              finishedLoan ? 'finished' : 'active',
              'medium',
              'bold',
              'full',
              'right',
              alertType,
            ].join(' ')}
          >
            {LoansStates.PAID === status && text.ended}
            {LoansStates.CANCELLED === status && text.cancelled}
            {![LoansStates.PAID, LoansStates.CANCELLED].includes(status) &&
              toCurrencyFormat(total)}
          </Styled.Value>
        </Styled.PaysContainerRight>
      </div>
      {monthlyCapCheck && (
        <>
          <ClipTypography
            className={classes.textToPay}
            variant="body1"
            fontWeight="semiBold"
            gutterBottom
          >
            {text.monthlyPaymentPending}
          </ClipTypography>
          <ClipTypography
            className={classes.amountToPay}
            variant="body1"
            fontWeight="regular"
            gutterBottom
          >
            {toCurrencyFormat(monthBalance)}
          </ClipTypography>
        </>
      )}
      {debtDetail}
      <Styled.DetailCard
        className={
          [LoansStates.PAID, LoansStates.ON_HOLD].includes(status) && 'margin'
        }
      >
        <Styled.SeeMoreText>{monthlyCapCheck ? text.debtDetail : text.loanDetail}</Styled.SeeMoreText>
        <ClevertapComponent
          renderComponent={<Styled.SeeMoreButton />}
          events={[
            {
              callbackName: 'onClick',
              event:
                LoansStates.PAID === status
                  ? EVENTS.LOANS.ENDED_LOAN_DETAIL
                  : EVENTS.LOANS.ACTIVE_LOAN_DETAIL,
            },
          ]}
          validation={!detail}
          className={
            [LoansStates.PAID, LoansStates.ON_HOLD].includes(status) &&
            'finished'
          }
          onClick={() => {
            setDetail(detail => !detail);
          }}
        >
          {!detail ? (monthlyCapCheck ? text.debtView : text.detailView) : text.hide}
          {!detail ? <Styled.ExpandMore /> : <Styled.ExpandLess />}
        </ClevertapComponent>
        <Styled.DetailCardContent className={detail && 'selected'}>
          <Styled.RowDetail>
            <Styled.ResponsiveDetail>
              <Styled.Leaders>
                <li>
                  <span>{monthlyCapCheck ? text.monthlyCapBegin : text.received}</span>
                  <span>{formattedStartDate}</span>
                </li>
                {!monthlyCapCheck && (
                  <li>
                    <span>{text.upcomingAlertTitle}</span>
                    <span>{formattedEndDate}</span>
                  </li>
                )}
                <li>
                  <span>{text.term}</span>
                  <span>{text.termMonths(months)}</span>
                </li>
                <li>
                  <span>
                    <b>{text.wayPay}</b>
                  </span>
                  <span>
                    <b>
                      {decimalFormatWithDecimals.format(
                        retentationPercentage,
                      )}
                    </b>
                    {text.dailyPay}
                  </span>
                </li>
                {monthlyCapCheck && (
                  <li>
                    <span>
                      <b>{text.monthlyPay}</b>
                    </span>
                    <span>
                      <b>
                        {toCurrencyFormat(monthBalance)}
                      </b>
                    </span>
                  </li>
                )}
              </Styled.Leaders>
            </Styled.ResponsiveDetail>
            <Styled.ResponsiveDetail>
              <Styled.Leaders>
                {monthlyCapCheck && (
                  <>
                    <li>
                      <span>{text.monthlyCapupcomingAlertTitle}</span>
                      <span>{formattedEndDatePeriod}</span>
                    </li>
                    <li>
                      <span>{text.numberPay}</span>
                      <span>{`${actualPeriod} de ${totalPeriod}`}</span>
                    </li>
                  </>
                )}
                {!monthlyCapCheck && (
                  <li>
                    <span>{text.amountLoan}</span>
                    <span>{toCurrencyFormat(amount)}</span>
                  </li>
                )}
                <li>
                  <span>{monthlyCapCheck ? text.monthlyCapMonthDebt : text.charge}</span>
                  <span>{monthlyCapCheck ? toCurrencyFormat(monthBalance) : toCurrencyFormat(interestAndTax)}</span>
                </li>
                <li>
                  <span>{monthlyCapCheck ? text.monthlyCapTotalPay : text.totalPay}</span>
                  <span>{monthlyCapCheck ? toCurrencyFormat(monthlyCapDebt) : toCurrencyFormat(totalPayback)}</span>
                </li>
              </Styled.Leaders>
            </Styled.ResponsiveDetail>
          </Styled.RowDetail>
        </Styled.DetailCardContent>
      </Styled.DetailCard>
      {loan.status === LoansStates.ACTIVE && (
        <Link
          to={
            getBaseLocation() +
            getAdvancePaymentUrlByProvider(loan.provider_code)
          }
          onClick={() => {
            Clevertap.event(
              EVENTS.LOANS.ADVANCED_PAYMENT_SELECTED,
              null,
              lenderPayload(
                approvalActive,
                { showCashAdvance },
                { type: monthlyCapCheck ? 'MCAP' : 'regular' },
              ),
            );
            visitAdvancePaymentEvent(loan)}
          }
        >
          <ClipButton
            className={classes.paymentButton}
            variant="contained"
            color="primary"
            type="button"
          >
            {text.advancePaymentTitle}
          </ClipButton>
        </Link>
      )}
      <Styled.DetailCard>
        <Styled.SeeMoreText>{text.collectionDetail}</Styled.SeeMoreText>
        <ClevertapComponent
          renderComponent={<Styled.SeeMoreButton />}
          events={[
            {
              callbackName: 'onClick',
              event:
                LoansStates.PAID === status
                  ? EVENTS.LOANS.ENDED_COLLECTIONS_DETAIL
                  : EVENTS.LOANS.ACTIVE_COLLECTIONS_DETAIL,
            },
          ]}
          validation={!historic}
          className={
            [LoansStates.PAID, LoansStates.ON_HOLD].includes(status) &&
            'finished'
          }
          onClick={() => {
            setHistoric(historic => !historic);
            loadCollections(loan.loan_id, 7);
          }}
        >
          {!historic ? text.historicView : text.hide}
          {!historic ? (
            <Styled.ExpandMore />
          ) : (
            <Styled.ExpandLess />
          )}
        </ClevertapComponent>
        <Styled.DetailCardContent
          className={historic && 'selected'}
        >
          <MyCrediClipCollection loan={loan} />
        </Styled.DetailCardContent>
      </Styled.DetailCard>
    </Styled.CrediCard>
  );
}
const mapStateToProps = (state) => ({
  prestaClipNameChange: getPrestaClipNameChange(state),
  showCashAdvance: state.loans.showCashAdvance,
});

MyCrediClipDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, {
    loadCollections,
  })(MyCrediClipDetail),
);
