export default {
  referenceTitle: 'Número de referencia',
  status: 'Estatus',
  establishment: 'Establecimiento',
  movementType: 'Tipo de movimiento',
  idTitle: 'ID de movimiento',
  authNumber: 'Número de autorización',
  helpTitle: '¿Necesitas ayuda?',
  helpBody: 'Customer Happiness® te atiende 24/7, incluso días festivos.',
  phone: 'teléfono',
  statusList: {
    COMPLETED_IN: 'Aplicado',
    COMPLETED_OUT: 'Liquidado',
    REJECTED: 'Devuelto',
    IN_PROGRESS: 'En tránsito',
    UNKNOWN: 'Desconocido',
  },
  withdrawal: 'Retiro de efectivo',
  navigatorTitle: 'Detalle de movimiento',
  detail: 'Concepto',
  state: 'Estado',
  sender: 'Ordenante',
  trackingKey: 'Clave de rastreo',
  receiver: 'Nombre',
  bank: 'Banco',
  transferTo: 'Enviaste a ',
  senderInfo: 'Información del destinatario',
  clabe: 'CLABE',
};
