import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import moment from 'moment-timezone';
import clsx from 'clsx';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import HourglassTopIcon from '../../../../assets/images/icons/HourglassTop.svg';
import TopImage from '../../../../assets/images/topImageCrediClip.svg';
import EasyApplyIcon from '../../../../assets/images/easy-apply-icon-2.svg';
import QuickToGetIcon from '../../../../assets/images/fast-to-get-icon-2.svg';
import PayAsSaleIcon from '../../../../assets/images/pay-as-sale-icon-2.svg';
import PrestaClipBanner from '../../../../assets/images/logo-loans.svg';
import WarningIcon from '@clipmx/clip-storybook/dist/components/Icons/Warning';
import LoanPhone from '@clipmx/clip-storybook/dist/components/Illustrations/LoanPhone';
import { HelpOutline as HelpIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import urls from '../../../../helpers/urls';
import getUrl, { getProviderCashAdvanceURL } from '../../../../helpers/getUrl';
import { getProviderCodeForURL } from '../../../../helpers/getProviderUrl';
import { toCurrencyFormat } from '../../../../helpers/formatCurrency';
import GTMCrediClipAnalyticsEvents from '../../../../helpers/GTMCrediClipAnalyticsEvents';
import { PreapprovalStatus } from '../../../../constants/AppConstants';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { Clevertap, EVENTS } from '../../../../helpers/Clevertap';
import { savePreapprovalSelected } from '../../redux/actions';
import { getUserName } from '../../redux/selectors';
import { ExternalLenderFooter } from '../Footer';
import LoansLandingV4 from '../LoansLandingV4';
import CashAdvance from '../CashAdvance';
import { SamsClipIcon } from '../../Sams';
import SamsDialog from '../../Sams/Dialog';
import GoogleAnalytics from '../../GoogleAnalytics';
import Grid from '@material-ui/core/Grid';
import text from './text';
import useStyles from './styles';
import ClipButton from '@clipmx/clip-storybook/dist/components/Button';
import { lenderPayload } from '../Clevertap';
import { ClipTypography } from '@clipmx/clip-storybook';

const LoansCustomLanding = ({
  savePreapprovalSelected,
  merchantId,
  loansFormVersion,
  showCashAdvance,
  userName,
  preapprovals,
  flags: { loansMerchantWithSamsclubBenefitsWeb: isElegibleForSams },
  user,
  merchant,
  merchantInfo,
}) => {
  const [showSamsDialog, setShowSamsDialog] = useState(false);
  const [itemAmount, setItemAmount] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [firstSent, setFirstSent] = useState(false);
  const handleClose = () =>  { setModalView(false) };
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    if (merchantInfo.proxy_id && user) {
      Clevertap.profile(user);
    }
  }, [user, merchant]);

  const selectPreapproval = (preapproval, isElegibleForSams) => {
    if (isElegibleForSams) {
      setShowSamsDialog(true);
      setItemAmount(preapproval);
      return;
    }
    const providerCode = preapproval.provider_code;
    const providerCodeForURL = getProviderCodeForURL(providerCode);

    let urlForm = getProviderCashAdvanceURL(
        showCashAdvance,
        providerCodeForURL,
        urls.loansFormIdentityV4CA,
        urls.loansFormIdentityV4,
      );

    savePreapprovalSelected(preapproval);
    GTMCrediClipAnalyticsEvents.pageView(
      preapproval.pre_approval_id,
      merchantId,
      urlForm,
    );
    history.replace(getUrl(`${getBaseLocation()}${urlForm}`));
  };

  const getBaseLocation = () =>
    location.pathname.startsWith(urls.mobile) ? urls.mobile : '';

  const expirationDate = moment(
    preapprovals[0].expiration_date_local,
    'YYYY-MM-DD',
  ).format('L');
  const preapproval_available = preapprovals.filter(
    ({ status }) => PreapprovalStatus.AVAILABLE === status,
  )[0];
  const {
    provider_code: providerCode,
    proxy_merchant_token: proxyMerchantToken,
    provider_code: lender,
  } = preapproval_available;

  const openOfferHelp = () => {
    setModalView(true);
    Clevertap.event(
      EVENTS.LOANS.NEW_OFFERS_VIEW_AVAILABLES,
      null,
      lenderPayload(preapproval_available, {
        showCashAdvance,
      }, {
        type: 'custom',
        navigation: 'CASH_ADVANCE_INFO_VIEWED',
      }),
    );
  }

  useEffect(() => {
    Clevertap.event(
      EVENTS.LOANS.NEW_OFFERS_VIEW,
      null,
      lenderPayload(preapproval_available, {
        showCashAdvance,
      }, {
        type: 'custom',
        offer: 'available',
      }),
    )

    setFirstSent(true);
  }, [])

  useEffect(() => {
    if (firstSent) {
      Clevertap.event(
        EVENTS.LOANS.NEW_OFFERS_VIEW_AVAILABLES,
        null,
        lenderPayload(preapproval_available, {
          showCashAdvance,
        }, {
          type: 'custom',
        }),
      )
    }
  }, [firstSent])

  const cashAdvanceContent = () => {
    return (
      <>
        {showCashAdvance && <CashAdvance />}
        {!showCashAdvance && (
          <Fragment>
            <div className={classes.stepsTitleStyles}>{text.stepsTitle}</div>
            <div className={classes.instructionStyles}>{text.stepsDescription}</div>
            <div className={classes.stepsContainerStyles}>
              <div className={classes.stepStyles}>
                <div className={classes.stepIconContainerStyles}>
                  <img src={EasyApplyIcon} className={classes.stepIconStyles} />
                </div>
                <span className={classes.stepTitleStyles}>{text.stepOneTitle}</span>
                <span className={classes.stepDescriptionStyles}>{text.stepOneDescription}</span>
              </div>
              <div className={classes.stepStyles}>
                <div className={classes.stepIconContainerStyles}>
                  <img src={QuickToGetIcon} className={classes.stepIconStyles} />
                </div>
                <span className={classes.stepTitleStyles}>{text.stepTwoTitle}</span>
                <span className={classes.stepDescriptionStyles}>{text.stepTwoDescription}</span>
              </div>
              <div className={classes.stepStyles}>
                <div className={classes.stepIconContainerStyles}>
                  <img src={PayAsSaleIcon} className={classes.stepIconStyles} />
                </div>
                <span className={classes.stepTitleStyles}>{text.stepThreeTitle}</span>
                <span className={classes.stepDescriptionStyles}>
                  {text.stepThreeDescription}
                </span>
              </div>
            </div>
          </Fragment>
        )}

        <ExternalLenderFooter />
      </>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={10}>
        <GoogleAnalytics>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <LoanPhone classes={{root: classes.imageTitleStyles}} />
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <img src={PrestaClipBanner} className={classes.bannerTitleStyles} />
            <div className={classes.expiredWarningMobileStyles}>
              <img src={HourglassTopIcon} className={classes.hourglassTopStyles} />
              <div className={clsx(classes.expiredWarningContentStyles, classes.bannerWarningStyles)}>
                <ReactMarkdown source={text.limitedOffer} />
                <ReactMarkdown
                  source={`${text.untilThen(expirationDate)}`}
                />
              </div>
            </div> 
            <p className={classes.textTitleStyles}>{`${text.titleP1}${userName}${text.titleP2}`}</p>
            <p className={classes.textTitleStyles}>{text.titleP3}</p>
            <ClipTypography
                      className={classes.textKnowOfferStyles}
                      variant="body1"
                      fontWeight="medium"
                      gutterBottom
                    >
                      {text.knowMoreOffer}
                        <HelpIcon className={classes.helpButton} onClick={openOfferHelp}/>
            </ClipTypography>
            {modalView && (<CashAdvance modalView={modalView} 
                            handleClose={handleClose}  />
                           )}           
            <div className={classes.expiredWarningStyles}>
              <img src={HourglassTopIcon} className={classes.hourglassTopStyles} />
              <div className={classes.expiredWarningContentStyles}>
                <ReactMarkdown
                  source={`${text.expiration(expirationDate)}`}
                />
              </div>
            </div>
          </Grid>
          </Grid>
          <LoansLandingV4 
            modalView={false}
            selectPreapproval={selectPreapproval} 
            preapprovals={preapprovals}
            isElegibleForSams={isElegibleForSams}
          />

          {cashAdvanceContent()}

          {isElegibleForSams && (
            <SamsDialog
              open={showSamsDialog}
              onClose={() => setShowSamsDialog(false)}
              onSuccess={selectPreapproval(itemAmount, false)}
              lender={lender.toLowerCase()}
              proxyMerchantToken={proxyMerchantToken}
            />
          )}
        </GoogleAnalytics>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  loansFormVersion: state.loans.loansFormVersion,
  userName: getUserName(state),
  showCashAdvance: state.loans.showCashAdvance,
  user: state.user,
  merchant: state.merchant,
  merchantInfo: state.merchant.info,
});

const mapDispatchToProps = {
  savePreapprovalSelected,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(withLDConsumer()(LoansCustomLanding))),
);