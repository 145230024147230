import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import blueBox from '../../../../assets/images/berlin/blue-box.svg';
import blueHand from '../../../../assets/images/berlin/blue-hand.svg';
import blueTruck from '../../../../assets/images/berlin/blue-truck.svg';

import greyHand from '../../../../assets/images/berlin/grey-hand.svg';
import greyTruck from '../../../../assets/images/berlin/grey-truck.svg';

import redTruck from '../../../../assets/images/berlin/red-truck.svg';

const styles = (theme) => {
  return {
    stepsContainer: {
      width: '100%',
    },
    stepContainer: {
      display: 'inline-block',
      textAlign: 'center',
      marginBottom: theme.spacing.unit * 2,
    },
    steps: {
      width: '100%',
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    stepText: {
      padding: 0,
    },
    blue: {
      color: theme.palette.naranja.cerulean,
    },
    red: {
      color: theme.palette.error.main,
    },
    line: {
      position: 'relative',
      top: '21px',
      zIndex: '-1',
      width: '69%',
      border: 'none',
      borderTop: 'solid 2px #ccd0d3',
      marginTop: 0,
      marginBottom: 0,
    },
  };
};
const OrderSteps = ({ classes, text, step }) => {
  let shippedSrc = greyTruck;
  let shippedColor = '';

  if (step > 1) {
    shippedSrc = blueTruck;
    shippedColor = classes.blue;
  }

  return (
    <>
      <Typography variant="body2" align="center">
        {text.info}
      </Typography>
      <div className={classes.stepsContainer}>
        <hr className={classes.line} />
        <div className={classes.steps}>
          <div className={classes.stepContainer}>
            <img alt="component" width="40" height="40" src={blueBox} />
            <Typography
              className={`${classes.stepText} ${step > 0 ? classes.blue : ''}`}
              variant="display1"
            >
              {text.processing}
            </Typography>
          </div>
          <div className={classes.stepContainer}>
            <img
              alt="component"
              width="40"
              height="40"
              src={step === 4 ? redTruck : shippedSrc}
            />
            <Typography
              className={`${classes.stepText} ${
                step === 4 ? classes.red : shippedColor
              }`}
              variant="display1"
            >
              {step === 4 ? text.notFound : text.onItsWay}
            </Typography>
          </div>
          <div className={classes.stepContainer}>
            <img
              alt="component"
              width="40"
              height="40"
              src={step === 3 ? blueHand : greyHand}
            />
            <Typography
              className={`${classes.stepText} ${
                step === 3 ? classes.blue : ''
              }`}
              variant="display1"
            >
              {text.delivered}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};
OrderSteps.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.object,
  step: PropTypes.number,
};
OrderSteps.defaultProps = {
  classes: {},
  text: {},
  step: 1,
};
export default withStyles(styles)(OrderSteps);
