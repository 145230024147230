import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withWidth } from '@material-ui/core';
import moment from 'moment-timezone';
import urls from '../../helpers/urls';
import { Forms, LoansTabs, PreapprovalStatus } from '../../constants/AppConstants';
import {
  getProviderCode,
  loansProviderCodes,
  showAddressTime,
  showBirth,
  showCreditHistory,
  validExternalProvider,
} from '../../constants/ProviderCodes';
import {
  getColonies,
  setLastOneDocumentation,
  setPrefilledColonies,
  setPrefilledDays,
  setPrefilledForm,
} from './redux/actions';

import {
  getLastOne as getLastOneSelector,
  getMerchantId,
  getPreapprovals,
  getPreapprovalSelected,
  getPrestaClipNameChange,
  hasLastOne,
  isLoansFormSent,
  preapprovalsLoaded,
} from './redux/selectors';
import CongratulationPage from './Commons/CongratulationPage';
import LoansClipLender from './Lender';
import LoansLanding from './Commons/LoansLanding';
import LoansLandingRegular from './Commons/LoansLandingRegular';
import LoansCustomLanding from './Commons/LoansCustomLanding';
import LoansRedirection from './Commons/Redirection';
import utils from '../../helpers/utils';
import { getDifferenceInMonths } from '../../helpers/dateHelpers';
import GoogleAnalytics from './GoogleAnalytics';

export class Loans extends React.PureComponent {
  state = {
    propsAreLoaded: false,
    proofOfAddress: [],
    identity: [],
    identityBack: [],
    passport: [],
    documentsReady: false,
    documentsLoaded: false,
  };

  filterByStatus = (status) => (preapproval) => preapproval.status === status;

  getBaseLocation = () =>
    this.props.location.pathname.startsWith(urls.mobile)
      ? urls.mobile
      : '';

  updateDocument = (document, type, replacement) => {
    const documentToSave = [
      {
        status: 'saved',
        name: document.document_key.split('/').pop().replace(replacement, ''),
        type: document.document_content_type,
        id: document.document_key.split('/').pop(),
        loaded: 100,
        key: document.document_key,
        token: {
          token: {
            promise: {},
          },
        },
      },
    ];

    switch (type) {
      case 'identity':
        this.setState({ identity: documentToSave });
        break;
      case 'identityBack':
        this.setState({ identityBack: documentToSave });
        break;
      case 'passport':
        this.setState({ passport: documentToSave });
        break;
      case 'proofOfAddress':
        this.setState({ proofOfAddress: documentToSave });
        break;
      default:
        break;
    }
  };

  updateDocumentState = (documents, monthsBetween) => {
    const providerCode = getProviderCode(
      this.props.location.pathname.toUpperCase()
    );
    const { documentsReady } = this.state;
    if (!documentsReady) {
      if (documents.length === 4) {
        this.updateDocument(documents[0], 'identity', 'ID_01_');
        this.updateDocument(documents[1], 'identityBack', 'ID_02_');
        this.updateDocument(documents[2], 'passport', 'ID_03_');
        if (monthsBetween < 3) {
          this.updateDocument(documents[3], 'proofOfAddress', 'PROOF_01_');
        }
      } else if (documents.length === 3) {
        this.updateDocument(documents[0], 'identity', 'ID_01_');
        this.updateDocument(documents[1], 'identityBack', 'ID_02_');
        if (monthsBetween < 3) {
          this.updateDocument(documents[2], 'proofOfAddress', 'PROOF_01_');
        }
      } else if (
        documents.length === 2 &&
        providerCode !== loansProviderCodes.MOT
      ) {
        this.updateDocument(documents[0], 'passport', 'ID_01_');
        if (monthsBetween < 3) {
          this.updateDocument(documents[1], 'proofOfAddress', 'PROOF_01_');
        }
      } else if (
        documents.length === 2 &&
        providerCode === loansProviderCodes.MOT
      ) {
        if (monthsBetween < 3) {
          this.updateDocument(documents[1], 'proofOfAddress', 'PROOF_01_');
        }
      }
      this.setState({ documentsReady: true });
    }
  };

  getBankInformation = (lastOne, providerCode) => {
    const { bank_information: bankInformation } = lastOne;
    if (bankInformation) {
      return {
        hasCreditCard: showCreditHistory(providerCode)
          ? bankInformation.has_credit_card.toString()
          : false,
        lastCreditCardNumbers:
          showCreditHistory(providerCode) && bankInformation.has_credit_card
            ? bankInformation.last4
            : '',
        hasMortgageCredit: showCreditHistory(providerCode)
          ? bankInformation.has_mortgage_loan.toString()
          : false,
        hasVehicleCredit: showCreditHistory(providerCode)
          ? bankInformation.has_vehicle_loan.toString()
          : false,
      };
    }
    return {};
  };

  getAddressInformation = (lastOne, providerCode) => {
    const { getColonies } = this.props;
    const { address_information: addressInformation } = lastOne;
    if (addressInformation) {
      addressInformation.postal_code &&
        getColonies(addressInformation.postal_code, Forms.MR_PRESTA);
      return {
        colony: addressInformation.colony,
        municipality: addressInformation.municipality,
        state: addressInformation.state,
        street: addressInformation.street,
        postalCode: addressInformation.postal_code,
        streetNumber: addressInformation.street_no_exterior,
        optionalStreetNumber: addressInformation.street_no_interior,
        addressYear: showAddressTime(providerCode)
          ? addressInformation.address_year
            ? parseInt(addressInformation.address_year)
            : ''
          : '',
        addressMonth: showAddressTime(providerCode)
          ? addressInformation.address_month
            ? addressInformation.address_month
            : ''
          : '',
      };
    }
    return {};
  };

  getPersonalInformation = (lastOne, providerCode) => {
    const { personal_information: personalInformation, provider_code } =
      lastOne;
    if (personalInformation) {
      const birthday = moment(personalInformation.birthday);
      const birthdayYear =
        birthday.format('YYYY').length === 4
          ? parseInt(birthday.format('YYYY'))
          : '';
      const birthdayMonth =
        birthday.format('M').length <= 2 ? birthday.format('M') : '';
      const birthdayDay =
        birthday.format('D').length <= 2 ? parseInt(birthday.format('D')) : '';

      return {
        firstName: personalInformation.name,
        lastName: personalInformation.last_name,
        secondLastName: personalInformation.second_last_name,
        year: showBirth(provider_code) ? birthdayYear : '',
        month: showBirth(provider_code) ? birthdayMonth : '',
        day: showBirth(provider_code) ? birthdayDay : '',
        gender: personalInformation.gender,
        maritalStatus: personalInformation.marital_status,
        ine:
          providerCode !== loansProviderCodes.APH
            ? personalInformation.ine
            : '',
        rfc: personalInformation.rfc,
        curp:
          providerCode !== loansProviderCodes.APH
            ? personalInformation.curp
            : '',
      };
    }
    return {};
  };

  loadData = (lastOne) => {
    const providerCode = getProviderCode(
      this.props.location.pathname.toUpperCase()
    );

    const identityDocumentType =
      lastOne.documents &&
      lastOne.documents.length > 0 &&
      providerCode !== loansProviderCodes.MOT
        ? lastOne.documents[0].document_type
        : 'INE';
    return {
      identityDocumentType,
      ...this.getPersonalInformation(lastOne, providerCode),
      ...this.getAddressInformation(lastOne, providerCode),
      ...this.getBankInformation(lastOne, providerCode),
      identity: {},
      identityBack: {},
      passport: {},
      proofOfAddress: {},
    };
  };

  loadColony = (colony) => {
    return [
      {
        id: colony,
        name: colony,
      },
    ];
  };

  fillIfPreviousLoan = () => {
    const {
      propsAreLoaded,
      proofOfAddress,
      identity,
      identityBack,
      passport,
      documentsReady,
      documentsLoaded,
    } = this.state;
    const {
      lastOne = {},
      setPrefilledForm,
      setLastOneDocumentation,
      setPrefilledColonies,
      setPrefilledDays,
    } = this.props;

    if (!propsAreLoaded && lastOne.documents) {
      this.setState({ propsAreLoaded: true });
      const documents = lastOne.documents;
      let monthsBetween = getDifferenceInMonths(lastOne.loan_created_at);
      this.updateDocumentState(documents, monthsBetween);
    }

    if (documentsReady && !documentsLoaded) {
      this.setState({ documentsLoaded: true });
      setLastOneDocumentation({
        proofOfAddress,
        identity,
        identityBack,
        passport,
      });
    }
    setPrefilledForm(this.loadData(lastOne));
    lastOne.address_information &&
      setPrefilledColonies(this.loadColony(lastOne.address_information.colony));

    if (lastOne.personal_information && lastOne.personal_information.birthday) {
      const days = utils.getDays(
        lastOne.personal_information.birthday.split('-')[1],
        lastOne.personal_information.birthday.split('-')[0],
      );
      setPrefilledDays(days);
    }
  };

  componentDidMount() {
    const { setPrefilledForm } = this.props;
    setPrefilledForm({ identityDocumentType: 'INE' });
  }

  componentDidUpdate(prevProps, prepState, snapshot) {
    const { hasLastOne } = this.props;
    hasLastOne && this.fillIfPreviousLoan();
  }

  render() {
    const { isFormSent, preapprovals = [], loansRegularOffers } = this.props;
    const availables = preapprovals.filter(
      this.filterByStatus(PreapprovalStatus.AVAILABLE),
    );
    const regularCheck = availables.some(available => available.type === 'REGULAR');
    const { provider_code: providerCode } =
      availables.length > 0 ? availables[0] : { provider_code: '' };
    let preapprovalsComponent = regularCheck ? 
      ( loansRegularOffers ? <LoansLanding preapprovals={availables} /> :
        <LoansLandingRegular preapprovals={availables} />  ) :
      <LoansCustomLanding preapprovals={availables} />;
  
    const loanExternalForm =
      !isFormSent &&
      availables.length > 0 &&
      validExternalProvider(providerCode) &&
      preapprovalsComponent;
    const loanClipLender = availables.length > 0 &&
      providerCode === loansProviderCodes.CLIP &&
      !isFormSent && <LoansClipLender />;

    return (
      <LoansRedirection tab={LoansTabs.NEW_OFFER} >
        {isFormSent && <CongratulationPage />}
        {loanClipLender}
        {loanExternalForm}
      </LoansRedirection>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loansFormVersion: state.loans.loansFormVersion,
    loansFormURL: state.loans.loansFormURL,
    loansRegularOffers: state.loans.loansRegularOffers,
    lastOne: getLastOneSelector(state),
    hasLastOne: hasLastOne(state),
    isFormSent: isLoansFormSent(state),
    preapprovals: getPreapprovals(state),
    preapprovalSelected: getPreapprovalSelected(state),
    role: state.user.role,
    merchantId: getMerchantId(state),
    preapprovalsLoaded: preapprovalsLoaded(state),
    prestaClipNameChange: getPrestaClipNameChange(state),
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  setLastOneDocumentation,
  setPrefilledForm,
  setPrefilledColonies,
  setPrefilledDays,
  getColonies,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withWidth()(Loans)));
