/* eslint-disable  max-len */
export default {
  title: 'Swap, “Pocketgroup Technologies”',
  message: `Swap PayCard
PocketGroup Technologies S.A de C.V., Institución de Fondos de Pago Electrónico (Swap) es el único responsable de la operación y administración por la apertura de cuentas a través de varios comisionista, así como de la emisión y operación de las tarjetas asociadas de estas cuentas, mismas que se utilizan como medio de disposición. PayClip, S. de R.L. de C.V. mejor conocido como "Clip" no ofrece los servicios de emisión, administración, redención y transmisión de fondos de pago electrónico, y ha celebrado un contrato de comisión mercantil con Swap bajo la cual actúa a nombre y por cuenta de Swap como comisionista, para los fines ahí establecidos.
Swap obtuvo la autorización de forma definitiva por acuerdo de un Comité Interinstitucional conformado por funcionarios de la Comisión Nacional Bancaria y de Valores (CNBV), la Secretaría de Hacienda y Crédito Público (SHCP) y el Banco de México, para la operación de la plataforma Swap como IFPE. Con ello, Swap y Clip podrán seguir ofreciendo a sus usuarios apertura y mantenimiento de cuentas de fondos de pago electrónico,  para la realización diferentes tipos de pagos y transferencias en moneda nacional con cargo al saldo en cuenta, así como tarjetas para la disposición de efectivo.
Para mayor información puedes escribirnos al correo legal@swapme.com, con el fin de que podamos resolver cualquier duda que puedas tener.`,
  titleCacao: 'Cacao Paycard',
  messageCacao: `Cacao Paycard Solutions, S.A. de C.V. mejor conocido como “Cacao”, es el único responsable de la operación y administración por la apertura de cuentas a través de varios comisionistas, así como de la emisión y operación de las tarjetas asociadas a estas cuentas, mismas que se utilizan como medio de disposición.
Payclip, S. de R.L. de C.V. mejor conocido como “Clip” no ofrece los servicios de emisión, administración, redención y transmisión de fondos de pago electrónico, y ha celebrado un contrato de comisión mercantil con Cacao bajo el cual actúa a nombre y por cuenta de Cacao como comisionista, para los fines ahí establecidos.
En apego a la regulación financiera y con motivo de la negativa por parte de las autoridades financieras a la solicitud presentada por Cacao para operar como Institución de Fondos de Pago Electrónico (IFPE), Cacao ha dejado de prestar sus servicios para la apertura de nuevas operaciones correspondientes a IFPEs.
Sin embargo, si ya eres cliente de Cacao y tienes una cuenta y tarjeta activas, éstas seguirán operando. Para mayor información puedes escribirnos al correo electrónico legal@cacaopaycard.com, con el fin de que podamos resolver cualquier duda que puedas tener. Asimismo, ponemos a tu disposición el siguiente link https://www.cacaopaycard.com/preguntas/ donde podrás consultar información al respecto.`,  
  buttonLabel: 'Entendido',
};
