import React, { useState } from 'react';
import { connect } from 'react-redux';
import text from './text';
import useStyles from './styles';
import { ClipTypography } from '@clipmx/clip-storybook';
import Grid from '@material-ui/core/Grid';
import ClipButton from 'components/npm/clip-ui/Button';
import ClipGrid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipPaper from '@clipmx/clip-storybook/dist/components/Paper';
import ClipSlider from '@clipmx/clip-storybook/dist/components/Slider';
import AddIcon from '@clipmx/clip-storybook/dist/components/Icons/Add';
import { Remove as RemoveIcon, HelpOutline as HelpIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import clsx from 'clsx';
import urls from 'helpers/urls';

import { savePreapprovalSelectedWithAlert } from 'components/loans/redux/actions';
import { showPersonalizedButtons } from 'constants/FeatureFlags';
import { useLocation } from 'react-router-dom';
import ModalPaymentScheme from '../ModalPaymentScheme/ModalPaymentScheme';
import { Clevertap, EVENTS } from 'helpers/Clevertap';
import { lenderPayload } from '../Clevertap';
import PropTypes from 'prop-types';

export const modalPaymentScheme = 'modalPaymentScheme';

const LoansLandingV4 = (props) => {
  const {
    modalView,
    selectPreapproval,
    preapprovals,
    isElegibleForSams,
    currentAmount,
    setIsDialogOpen,
    setDisplayTooltip,
    savePreapprovalSelectedWithAlert,
    showCashAdvance,
  } = props;

  const [modalViews, setModalView] = useState(false);
  const [changeSlider, setChangeSlider] = useState(0);
  const handleClose = () => { setModalView(false);};
  const classes = useStyles();

  preapprovals.sort((preA, preB) => Number(preA.amount) - Number(preB.amount));
  const maxValue = (preapprovals.length-1) * 100;
  const minAmount = preapprovals[0]?.amount;
  const maxAmount = preapprovals[preapprovals.length-1]?.amount;
  const actualValue = currentAmount ? 
    preapprovals.findIndex(preApp => preApp.amount === currentAmount) * 100 : maxValue;

  const [sliderValue, setSliderValue] = useState(actualValue);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const amountValue = () => {
    const index = sliderValue/100;

    return formatter.format(preapprovals[index]?.amount);
  }

  const preapprovalSelected = preapprovals[sliderValue/100];

  const formatedMin = formatter.format(minAmount);
  const formatedMax = formatter.format(maxAmount);
  const interestTax = formatter.format(Number(preapprovalSelected?.interest) + Number(preapprovalSelected?.tax));
  const retentionRate = Number(preapprovalSelected?.retention_rate).toFixed(1);
  const totalAmount = formatter.format(preapprovalSelected?.payback);
  const monthlyPayment = formatter.format(Math.round(preapprovalSelected?.payback/preapprovalSelected?.term_maximum));

  const browserHistory = useLocation();
  const isMobileWebView = browserHistory.pathname.startsWith(urls.mobile);

  const changeToPrevious = () => {
    if (sliderValue > 0) {
      setSliderValue(value => value - 100);
    }
  }

  const changeToNext = () => {
    if (sliderValue < maxValue) {
      setSliderValue(value => value + 100);
    }
  }

  const openSchemePayment = () => {
    setModalView(true);

    Clevertap.event(
      EVENTS.LOANS.NEW_OFFERS_VIEW_AVAILABLES,
      null,
      lenderPayload(preapprovalSelected, {
        showCashAdvance,
      }, {
        type: 'custom',
        navigation: 'RETENTION_INFO',
      }),
    );
  }

  const beginRequest = () => {
    selectPreapproval(preapprovalSelected, isElegibleForSams);

    Clevertap.event(
      EVENTS.LOANS.CUSTOM_OFFER_SELECTED,
      null,
      lenderPayload(preapprovalSelected, {
        showCashAdvance,
      }, {
        slider_interactions: changeSlider,
        max_amount: formatedMax,
        min_amount: formatedMin,
        selected_amount: preapprovalSelected.amount,
      }),
    );
  };

  const selectNewAmount = () => {
    savePreapprovalSelectedWithAlert(preapprovalSelected);

    if(isMobileWebView) {
      setDisplayTooltip(false);

      return;
    }

    setIsDialogOpen(false);
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ClipPaper
            className={clsx({
              [classes.card]: !modalView,
              [classes.cardModal]: modalView,
            })}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={clsx({
                [classes.cardSlider]: !modalView,
                [classes.cardModalGrid]: modalView,
              })}
            >
              <div
                className={clsx({
                  [classes.slider]: !modalView,
                  [classes.cardModalGrid]: modalView,
                })}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={clsx({[classes.cardModalGrid]: modalView})}
                >
                  <ClipTypography
                    component="p"
                    color="textPrimary"
                    align="center"
                    gutterBottom
                  >
                    {text.howMuchYouNeed}
                  </ClipTypography>
                  <div className={clsx({[classes.textSlider]: showPersonalizedButtons, 
                    [classes.textSliderCenter]: !showPersonalizedButtons})}>
                    {showPersonalizedButtons ?? (
                      <ClipButton
                        className={classes.addButton}
                        variant="contained"
                        color="primary"
                        onClick={changeToPrevious}
                      >
                        <RemoveIcon />
                      </ClipButton>
                    )}
                    <ClipTypography
                      variant="h2"
                      component="p"
                      fontWeight="medium"
                      color="textPrimary"
                      gutterBottom
                    >
                      {amountValue()}
                    </ClipTypography>
                    {showPersonalizedButtons ?? (
                      <ClipButton
                        className={classes.addButton}
                        variant="contained"
                        color="primary"
                        onClick={changeToNext}
                      >
                        <AddIcon />
                      </ClipButton>
                    )}
                  </div>
                </Grid>
                <div className={clsx({
                  [classes.paddingSlider]: true, 
                  [classes.sliderModal]: modalView, 
                  [classes.sliderModalMobile]: isMobileWebView,
                })}>
                  <ClipSlider 
                    classes={{rail: classes.sliderRailStyles, 
                      track: classes.sliderTrackStyles, 
                      thumb: classes.sliderThumbStyles, root: classes.sliderRailStyles}}
                    onChangeCommitted={() => setChangeSlider(count => count + 1)}
                    min={0}
                    max={maxValue} 
                    step={100} value={sliderValue} onChange={handleSliderChange} />
                </div>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className={clsx({
                    [classes.textSlider]: true, 
                    [classes.textSliderModal]: modalView, 
                    [classes.sliderModalMobile]: isMobileWebView,
                  })}>
                    <div className={classes.sliderAmountLabel}>
                      <ClipTypography
                        variant="caption"
                        fontWeight="regular"
                        color="textSecondary"
                        gutterBottom
                      >
                        {text.minimum}
                      </ClipTypography>
                      <ClipTypography
                        variant="caption"
                        fontWeight="regular"
                        color="textSecondary"
                        gutterBottom
                      >
                        {formatedMin}
                      </ClipTypography>
                    </div>
                    <div className={classes.sliderMaxAmountLabel}>
                      <ClipTypography
                        variant="caption"
                        fontWeight="regular"
                        color="textSecondary"
                        gutterBottom
                      >
                        {text.maximum}
                      </ClipTypography>
                      <ClipTypography
                        className={classes.maxAmount}
                        variant="caption"
                        fontWeight="medium"
                        color="primary"
                        gutterBottom
                      >
                        {formatedMax}
                      </ClipTypography>
                    </div>
                    
                  </div>
                </Grid>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={clsx({
                [classes.cardPay]: !modalView,
                [classes.cardModalGrid]: modalView,
                [classes.cardDesktopLabels]: modalView,
              })}
            >
              <div className={classes.titleLabelContainer}>
                <ClipTypography
                  variant="h5"
                  fontWeight="medium"
                  gutterBottom
                >
                  {text.requestBreakdown}
                </ClipTypography>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                <div className={clsx({[classes.amountContainer]: true, [classes.amountContainerModal]: modalView})}>
                  <ClipTypography
                    variant="body1"
                    fontWeight="medium"
                    gutterBottom
                  >
                    {text.amountToPay}
                  </ClipTypography>
                </div>
                <div className={clsx({[classes.amountContainer]: true, [classes.amountContainerModal]: modalView})}>
                    <ClipTypography
                      variant="body1"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {text.amountRequested}
                    </ClipTypography>
                    <ClipTypography
                      variant="body1"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {amountValue()}
                    </ClipTypography>
                  </div>
                  <div className={clsx({[classes.amountContainer]: true, [classes.amountContainerModal]: modalView})}>
                    <ClipTypography
                      variant="body1"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {text.chargeIva}
                    </ClipTypography>
                    <ClipTypography
                      variant="body1"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {interestTax}
                    </ClipTypography>
                  </div>
                  <div className={clsx({[classes.amountContainer]: true, [classes.amountContainerModal]: modalView})}>
                    <ClipTypography
                      variant="body1"
                      fontWeight="semiBold"
                      gutterBottom
                    >
                      {text.totalLabel}
                    </ClipTypography>
                    <ClipTypography
                      variant="subtitle1"
                      component="p"
                      fontWeight="semiBold"
                      gutterBottom
                    >
                      {totalAmount}
                    </ClipTypography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className={clsx({[classes.amountContainer]: true, [classes.amountContainerModal]: modalView})}>
                    <ClipTypography
                      variant="body1"
                      fontWeight="medium"
                      gutterBottom
                    >
                      {text.paymentSchedule}
                    </ClipTypography>
                  </div>
                  <div className={clsx({[classes.amountContainer]: true, [classes.amountContainerModal]: modalView})}>
                    <ClipTypography
                      variant="body1"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {text.monthlyPayment}
                    </ClipTypography>
                    <ClipTypography
                      variant="body1"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {monthlyPayment}
                    </ClipTypography>
                  </div>
                  <div className={clsx({[classes.amountContainer]: true, [classes.amountContainerModal]: modalView})}>
                    <ClipTypography
                      variant="body1"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {text.maximumTime}
                    </ClipTypography>
                    <ClipTypography
                      variant="body1"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {preapprovalSelected?.term_maximum} {text.monthTerm}
                    </ClipTypography>
                  </div>
                  <div className={clsx({[classes.amountContainer]: true, [classes.amountContainerModal]: modalView})}>
                    <ClipTypography
                      className={classes.helpText}
                      variant="body1"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {text.retentionRate}
                      {!modalView && (
                        <HelpIcon className={classes.helpButton} onClick={openSchemePayment}/>
                      )}
                    </ClipTypography>
                    <ClipTypography
                      variant="body1"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {retentionRate}%
                    </ClipTypography>
                  </div>
                </Grid>
                <div className={classes.importantNoteContainer}>
                  <span className={classes.importantNote}>{text.importantNote}</span>
                  <span>{text.importantNoteText}</span>
                </div>
              </Grid>
              <ClipGrid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                className={clsx({
                  [classes.buttonCenter]: !modalView, 
                  [classes.buttonsModal]: modalView, 
                  [classes.buttonsModalMobile]: isMobileWebView,
                })}
              >
                {!modalView && (
                  <ClipButton
                    className={classes.buttonMargin}
                    variant="contained"
                    color="primary"
                    onClick={beginRequest}
                  >
                    {text.startRequest}
                  </ClipButton>
                )}
                {modalView && (
                  <>
                   <ClipButton
                      className={clsx({[classes.closeModal]: true, [classes.closeModalMobile]: isMobileWebView})}
                      variant="text"
                      onClick={() => isMobileWebView ? setDisplayTooltip(false) : setIsDialogOpen(false)}
                      onClose={() => window.scrollTo(0, 0 + 15)}
                    >
                      {text.cancel}
                    </ClipButton>
                    <ClipButton
                      className={classes.buttonMargin}
                      variant="contained"
                      color="primary"
                      disabled={preapprovalSelected.amount === currentAmount}
                      onClick={selectNewAmount}
                      onClose={() => window.scrollTo(0, 0 + 15)}
                    >
                      {text.changeOffer}
                    </ClipButton>
                  </>
                )}
              </ClipGrid>
            </Grid>
          </ClipPaper>
        </Grid>
      </Grid>
      <ModalPaymentScheme
        modalViews={modalViews}
        modalFunct={setModalView}
        handleClose={handleClose}
        retentionRate={retentionRate}
      />
    </>
  );
};

LoansLandingV4.propTypes = {
  selectPreapproval: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  savePreapprovalSelectedWithAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoansLandingV4);
