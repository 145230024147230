export default {
  changeAmountTitle: (value) => `Solicitud por ${value}`,
  changeAmount: 'Cambiar oferta',
  modalTitle: 'Elige el monto que necesitas',
  initialAlertTitle: 'Antes de comenzar',
  initialAlertContent:
    'Ten a la mano los siguientes documentos (png, jpg o pdf) del titular de la cuenta Clip:',
  previousLoan: (name) => `¡Hola de nuevo, ${name}!`,
  previousLoanContent: `Para solicitar tu nuevo Adelanto de Ventas Clip confirma que los datos de tu aplicación sean
  correctos y sigan vigentes.`,
  initialAlertIdentityDocument: 'Credencial INE o Pasarpote',
  initialAlertDocument: 'RFC y CURP',
  initialAlertAddress:
    'Comprobante de domicilio (máximo con 3 meses de antigüedad)',
  identity: 'Tu identidad',
  summaryOfYourPrestaClip: 'Resumen del préstamo:',
  amountRequested: 'Monto solicitado',
  maxLimit: 'Plazo',
  fixedCharge: 'Interés total + IVA',
  fixedPayment: 'Pago mensual fijo',
  retention: 'Retención de ventas',
  total: 'Total a pagar',
  request: 'Solicitud por',
  changeNewOffer: 'Cambiar oferta',
  termMonths: (month) => `${month} meses`,
  dailyPay: (pays) => `${pays}%`,
  changeOffer: 'Cambiar oferta',
  continue: 'Continuar',
};
