import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const PasswordDots = ({ classes, color, counter, waiting }) => {
  if (color === 'black') {
    return (
      <div
        key={counter}
        style={{ animationDelay: `${0.2 * counter}s` }}
        className={`${classes.blackDot} ${
          waiting ? classes.blinkAnimation : ''
        }`}
      ></div>
    );
  }
  return <div key={counter} className={classes.whiteDot}></div>;
};

PasswordDots.defaultProps = {
  classes: {},
  color: 'white',
  counter: 0,
  waiting: false,
};

PasswordDots.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  counter: PropTypes.number,
  waiting: PropTypes.bool,
};

export default withStyles(styles)(PasswordDots);
