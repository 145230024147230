export default {
  show: 'Ver más',
  hide: 'Ocultar',
  titleHeader: 'Bienvenido al programa Credi Clip',
  messageHeader: `
    Estás a un paso para recibir tu adelanto de $𝟏𝟎,𝟎𝟎𝟎.𝟎𝟎 (al final, el monto total a pagar es de $11,740.00)
    Necesitamos confirmar tus datos antes de que firmes tu contrato. Verifica tu cuenta completando el siguiente
    formulario.
  `,
  amount: '10000',
  payback: '11740',
  titleFlow: '¿Cómo recibir tu Credi Clip?',
  titleStepOne: '1. Completa tu perfil',
  titleStepTwo: '2. Revisamos tu solicitud',
  titleStepThree: '3. Recibes tu dinero',
  contentStepOne:
    'Llena el siguiente formulario en línea. Ten a la mano una identificación oficial y un comprobante de domicilio.',
  contentStepTwo:
    'Una vez aprobada, te mandaremos un correo donde podrás confirmar que aceptas tu Credi Clip firmando un pagaré.',
  contentStepThree:
    '¡Listo! Dentro de 3 días hábiles, abonamos el monto solicitado a tu cuenta CLABE registrada con Clip.',
  instructionTitle: 'Llena los siguientes campos para completar tu perfil:',
  instructionWarning: 'Es necesario llenar todos los campos',
  titlePersonalInformation: 'Información personal',
  inputFirstName: 'Nombre',
  inputLastName: 'Apellido paterno',
  inputSecondLastName: 'Apellido materno',
  titleAddress: 'Dirección',
  titleUploadDocuments: 'Adjuntar documentos',
  subtitleUploadDocuments:
    'El formato de tus documentos debe ser pdf, jpg, jpeg, png, doc o docx',
  titleBirthday: 'Fecha de nacimiento',
  year: 'Año',
  month: 'Mes',
  day: 'Día',
  identityDocument: 'INE (ambos lados) o Pasaporte',
  proofOfAddress: 'Comprobante de domicilio (3 meses de antigüedad máximo)',
  documentInformation:
    'Tus documentos no serán guardados hasta que envies tu solicitud ',
  termsAndConditions: 'He leído, entiendo y acepto los ',
  termsAndConditionsLink: 'Términos y Condiciones',
  endTermsAndConditions: 'del programa Credi Clip.',
  buttonSubmit: 'Continuar',
  cancelFile: 'Carga de archivo cancelada',
  monthsOptions: [
    {
      id: '1',
      name: 'Enero',
    },
    {
      id: '2',
      name: 'Febrero',
    },
    {
      id: '3',
      name: 'Marzo',
    },
    {
      id: '4',
      name: 'Abril',
    },
    {
      id: '5',
      name: 'Mayo',
    },
    {
      id: '6',
      name: 'Junio',
    },
    {
      id: '7',
      name: 'Julio',
    },
    {
      id: '8',
      name: 'Agosto',
    },
    {
      id: '9',
      name: 'Septiembre',
    },
    {
      id: '10',
      name: 'Octubre',
    },
    {
      id: '11',
      name: 'Noviembre',
    },
    {
      id: '12',
      name: 'Diciembre',
    },
  ],
};
