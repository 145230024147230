const styles = (theme) => {
  return {
    paper: {
      margin: theme.spacing.unit * 2,
      padding: theme.spacing.unit * 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    btnsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing.unit * 2,
    },
    desc: {
      marginTop: theme.spacing.unit * 2,
    },
    btn1: {
      marginRight: theme.spacing.unit / 2,
    },
    btn2: {
      marginLeft: theme.spacing.unit / 2,
    },
  };
};
export default styles;
