import React from 'react';
import { bool, shape, oneOf } from 'prop-types';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Input = ({
  FormHelperTextProps,
  InputLabelProps,
  InputProps,
  classes,
  color,
  ...props
}) => {
  const styledTextProps = {
    ...FormHelperTextProps,
    classes: {
      root: classes.fhRoot,
      error: classes.fhError,
      disabled: classes.fhDisabled,
      marginDense: classes.fhMarginDense,
      ...FormHelperTextProps.classes,
    },
    className: classNames({
      [classes.colorInherit]: color === 'inherit',
      [classes.colorPrimary]: color === 'primary',
      [classes.colorSecondary]: color === 'secondary',
    }),
  };

  const styledLabelProps = {
    ...InputLabelProps,
    classes: {
      root: classes.ilRoot,
      formControl: classes.ilFormControl,
      marginDense: classes.ilMarginDense,
      shrink: classes.ilShrink,
      animated: classes.ilAnimated,
      ...InputLabelProps.classes,
    },
    FormLabelClasses: {
      focused: classes.ilFocused,
      error: classes.error,
    },
    className: classNames({
      [classes.colorInherit]: color === 'inherit',
      [classes.colorPrimary]: color === 'primary',
      [classes.colorSecondary]: color === 'secondary',
    }),
  };

  const styledInputProps = {
    ...InputProps,
    classes: {
      root: classes.root,
      formControl: classes.formControl,
      focused: classes.focused,
      disabled: classes.disabled,
      underline: classes.underline,
      error: classes.error,
      multiline: classes.multiline,
      fullWidth: classes.fullWidth,
      input: classes.input,
      inputMarginDense: classes.inputMarginDense,
      inputMultiline: classes.inputMultiline,
      inputType: classes.inputType,
      inputTypeSearch: classes.inputTypeSearch,
      ...InputProps.classes,
    },
    className: classNames({
      [classes.colorInherit]: color === 'inherit',
      [classes.colorPrimary]: color === 'primary',
      [classes.colorSecondary]: color === 'secondary',
    }),
  };

  return (
    <TextField
      {...props}
      FormHelperTextProps={styledTextProps}
      InputLabelProps={styledLabelProps}
      InputProps={styledInputProps}
    />
  );
};

Input.propTypes = {
  fullWidth: bool,
  classes: shape({}),
  FormHelperTextProps: shape({}),
  InputLabelProps: shape({}),
  InputProps: shape({}),
  color: oneOf(['default', 'inherit', 'primary']),
};

Input.defaultProps = {
  classes: {},
  fullWidth: true,
  FormHelperTextProps: {},
  InputLabelProps: {},
  InputProps: {},
  color: 'default',
};

export default withStyles(styles, { name: 'ClipInput' })(Input);
