export const styles = (theme) => {
  const componentsStyles = {
    item: {
      display: 'flex',
      width: '220px',
      height: '210px',
      color: '#FFF',
      borderRadius: `${theme.spacing.unit * 1.5}px`,
      boxShadow:
        '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
      backgroundColor: '#FFF',
      margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
      marginBottom: 0,
      '&.desktop': {
        marginLeft: 0,
        [theme.breakpoints.down(600)]: {
          margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
          marginBottom: 0,
        },
      },
      '&.Recomended': {
        backgroundColor: '#007d8e',
        width: '224px',
        height: '235px',
        '& > div': {
          margin: '2px 2px',
        },
        '& > div:after': {
          content: '"RECOMENDADA"',
          backgroundColor: '#007d8e',
          width: '102%',
          left: '-2px',
          height: `${theme.spacing.unit * 1.75}px`,
          position: 'relative',
          display: 'block',
          textAlign: 'center',
          margin: 'auto auto',
          fontSize: `${theme.spacing.unit * 1.375}px`,
          fontWeight: 600,
          paddingTop: `${theme.spacing.unit * 0.5}px`,
          paddingBottom: `${theme.spacing.unit * 0.5}px`,
          borderBottomRightRadius: `${theme.spacing.unit}px`,
          borderBottomLeftRadius: `${theme.spacing.unit}px`,
        },
      },
    },
    container: {
      width: '220px',
      borderRadius: `${theme.spacing.unit * 1.25}px`,
      backgroundColor: '#FFF',
    },
    amount: {
      fontSize: '30px',
      fontWeight: 'bold',
      color: '#007d8e',
      margin: 'auto auto',
      marginTop: `${theme.spacing.unit * 2}px`,
      marginBottom: `${theme.spacing.unit * 1.25}px`,
      textAlign: 'center',
      '& > sup': {
        fontSize: `${theme.spacing.unit * 2.25}px`,
        position: 'relative',
        verticalAlign: 'top',
        top: `${theme.spacing.unit * 0.75}px`,
      },
    },
    buttonContainer: {
      width: `${theme.spacing.unit * 11.25}px`,
      margin: 'auto auto',
      '& > button': {
        minHeight: `${theme.spacing.unit * 4.375}px`,
        height: `${theme.spacing.unit * 4.375}px`,
        paddingTop: `${theme.spacing.unit * 0.75}px`,
        '& > span': {
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    information: {
      borderRadius: `${theme.spacing.unit}px`,
      backgroundColor: 'rgba(0, 125, 142, 0.06)',
      marginTop: `${theme.spacing.unit * 2.5}px`,
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    },
    title: {
      width: '100%',
      fontSize: `${theme.spacing.unit * 2.25}px`,
      fontWeight: 600,
      color: '#001724',
      margin: 'auto auto',
      textAlign: 'center',
    },
    separator: {
      width: '100%',
      height: '1px',
      backgroundColor: 'rgba(0, 125, 142, 0.24)',
      padding: '0 0',
      marginTop: `${theme.spacing.unit * 1}px`,
      marginBottom: `${theme.spacing.unit * 1}px`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    keyValue: {
      width: '100%',
      fontSize: `${theme.spacing.unit * 1.375}px`,
      fontWeight: 'normal',
      color: '#001724',
      display: 'flex',
    },
    key: {
      width: '60%',
      fontWeight: 500,
      textAlign: 'right',
    },
    value: {
      width: '40%',
      textAlign: 'left',
      marginLeft: `${theme.spacing.unit}px`,
    },
  };
  return {
    ...componentsStyles,
  };
};
