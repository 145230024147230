import React from 'react';
import { getConfigValue } from '../../../config/config';
import { ClipButton, ClipTypography, ClipLink } from '@clipmx/clip-storybook';
import { WhatsApp } from '@clipmx/clip-storybook/dist/components/Icons';
import { PhoneInTalk } from '@clipmx/clip-storybook/dist/components/Icons';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import clsx from 'clsx';
import text from './text';
import styles from './style';
import { BERLIN_CONTACT_LINKS } from '../../../constants/AppConstants';

const useStyles = makeStyles(styles);

const WhatsAppModule = (props) => {
  const { isSmall = false } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ClipTypography className={classes.title}>{text.needHelp}</ClipTypography>
      {!isSmall && (
        <>
          <ClipTypography className={classes.subTitle}>
            {text.cusHap}
          </ClipTypography>
          <div className={classes.buttons}>
            <ClipButton
              className={classes.btn}
              variant={'outlined'}
              color={'secondary'}
              startIcon={<WhatsApp />}
              href={getConfigValue('berlinWhatsApp')}
              target={'_blank'}
            >
              {text.helpWa}
            </ClipButton>
            <ClipButton
              className={classes.btn}
              variant={'outlined'}
              color={'secondary'}
              startIcon={<PhoneInTalk />}
              href={BERLIN_CONTACT_LINKS.PHONE}
              target={'_blank'}
            >
              {text.callNow}
            </ClipButton>
          </div>
        </>
      )}
      <div className={clsx(classes.subContainer, isSmall && classes.subContainerTop)}>
        <ClipTypography variant={'body2'}>
          {text.callTo}
          <ClipLink
            variant={'body2'}
            color={'secondary'}
            href={BERLIN_CONTACT_LINKS.PHONE}
            target={'_blank'}
          >
            {text.phone}
          </ClipLink>
          {text.sendMail}
          <ClipLink
            variant={'body2'}
            color={'secondary'}
            href={BERLIN_CONTACT_LINKS.EMAIL}
            target={'_blank'}
          >
            {text.email}
          </ClipLink>
          {text.writeUs}
          <ClipLink
            variant={'body2'}
            color={'secondary'}
            href={BERLIN_CONTACT_LINKS.SITE}
            target={'_blank'}
          >
            {text.web}
          </ClipLink>
        </ClipTypography>
      </div>
      {isSmall && (
        <ClipTypography className={classes.subTitle}>
          {text.cusHap}
        </ClipTypography>
      )}
    </div>
  );
};

export default WhatsAppModule;
