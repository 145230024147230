const mobileExp =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
const commonPayload = (
  preapproval,
  { loansFormVersion, showCashAdvance, providerCode },
  staticFields,
) => {
  const { proxy_merchant_token: proxyMerchantToken } = preapproval || {};
  return {
    proxyMerchantToken,
    ca: showCashAdvance,
    ...staticFields,
    ...devicePayload(),
    ...versionField(loansFormVersion, providerCode),
  };
};

const lenderPayload = (preapproval, props, staticFields) => ({
  lender: preapproval?.provider_code,
  ...commonPayload(preapproval, {
    providerCode: preapproval?.provider_code,
    ...props,
  }, {
    ...staticFields,
  }),
});

const amountPayload = (preapproval, props, index) => ({
  amount_selected: index === 0 ? 'Higher' : index === 1 ? 'Medium' : 'Lower',
  terms: preapproval?.term_maximum,
  retention: preapproval?.retention_rate,
  amount: preapproval?.loan_amount || preapproval?.amount,
  payback: preapproval?.payback,
  ...lenderPayload(preapproval, props),
});

const commonRoutePayload = (fields, staticValues) => ({
  static: {
    ...devicePayload(),
    ...staticValues,
  },
  redux: {
    loans: {
      showCashAdvance: 'ca',
      ...fields,
    },
    merchant: {
      info: {
        proxy_id: { proxy_merchant_token: 'proxyMerchantToken' },
      },
    },
  },
});

const loansRoutePayload = (event, payload, staticValues) => ({
  event,
  payload: commonRoutePayload(payload, staticValues),
});

const devicePayload = () => ({
  device:
    window.location.href.includes('mobile') || isMobile()
      ? 'mobile'
      : 'desktop',
});

const isMobile = () =>
  mobileExp.test(navigator.userAgent) ||
  mobileExp.test(navigator.platform) ||
  ('ontouchstart' in document.documentElement &&
    navigator.userAgent.match(/Mobi/)) ||
  (window.matchMedia &&
    window.matchMedia('only screen and (max-width: 760px)').matches);

const versionField = (loansFormVersion, providerCode) => ({
  version:
    !loansFormVersion || typeof loansFormVersion === 'string'
      ? loansFormVersion
      : Object.keys(loansFormVersion).find((key) =>
          loansFormVersion[key].includes(providerCode),
        ),
});

export { commonPayload, lenderPayload, amountPayload, loansRoutePayload };
