import React, { Fragment, useEffect } from 'react';
import { Field, formValueSelector, change, FieldArray } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  deleteFilesNotSaved,
  cleanLastOneIdentity,
  cleanLastOneIdentityBack,
  cleanLastOnePassport,
} from 'components/loans/redux/actions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
  required,
  atLeast3Characters,
  text as isText,
  maxLength,
  isAlphanumeric,
  isNumeric,
  isInteger,
  isAdultForValues,
  isValidDayForValues,
  isValidBirthdayYear,
  haveLength,
} from 'helpers/validate';

import {
  Forms,
  LoansDocuments,
  LoansCurrentProductName,
  LoansNewProductName,
} from 'constants/AppConstants';
import {
  validExternalProvider,
  showIdentityDocumentRadios,
  loansProviderCodes,
  showBirth,
  getProviderCode,
  showINEorPassport,
  showContactInformation,
} from 'constants/ProviderCodes';
import {
  getLoansIdentityDocuments,
  getLoansIdentityDocumentsBack,
  getLoansPassportDocuments,
  getPrestaClipNameChange,
  getPreapprovalSelected,
} from 'components/loans/redux/selectors';
import {
  TitleTooltip,
  Identification,
  ImageIdentification,
  HtmlTooltip,
  INELink,
  StepTitle,
  StepDescription,
  StepSection,
  FormControl,
} from './styled';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RenderExtraPhonesV3 from 'components/loans/Commons/ContactInformation/renderExtraPhonesV3';

import ImageINE from 'assets/images/front_INE.jpg';
import text from './text';

import TextField from 'helpers/FormUtils/TextField';
import SelectFieldV2 from 'helpers/FormUtils/SelectFieldV2';
import RadioField from 'helpers/FormUtils/RadioField';
import { isInvalidFormField, isInvalidArray } from 'helpers/FormUtils';
import AttachIdentityDocuments from 'components/loans/Commons/AttachDocumentsV2/AttachIdentityDocuments';
import Grid from '@material-ui/core/Grid';
import { lenderPayload } from '../../../Commons/Clevertap';
import { Clevertap, EVENTS } from '../../../../../helpers/Clevertap';
import ClevertapField from '../../../../forms/ClevertapField';

class IdentityStep extends React.Component {
  static propTypes = {
    deleteFilesNotSaved: PropTypes.func.isRequired,
    cleanLastOneIdentity: PropTypes.func.isRequired,
    cleanLastOneIdentityBack: PropTypes.func.isRequired,
    cleanLastOnePassport: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    handleIdentityStep: PropTypes.func.isRequired,
    prestaClipNameChange: PropTypes.bool.isRequired,
    identityDocumentType: PropTypes.string,
    identityFiles: PropTypes.array,
    identityFilesBack: PropTypes.array,
    passportFiles: PropTypes.array,
  };

  state = {
    open: false,
    ineFrontRequired: false,
    ineBackRequired: false,
    passportRequired: false,
    documentTypeOptions: text.documentTypeOptions,
    genderOptions: text.genderOptions,
    maritalStatusOptions: text.maritalOptions,
    monthsOptions: text.monthsOptions,
    disabledDataFields: true,
    disabledDataFieldsUpdated: false,
    showUploadFields: false,
    showUploadFieldsUpdated: false,
    showDataFields: false,
    showDataFieldsUpdated: false,
  };

  deleteAddressTime = () => {
    const { change } = this.props;
    change(Forms.MR_PRESTA, 'addressYear', '');
    change(Forms.MR_PRESTA, 'addressMonth', '');
    this.deleteIdentityDocuments();
  };

  handleTooltipOpen = () => {
    this.setState({ open: true });
  };

  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  fileUploaded = (type) => {
    const { preapprovalSelected, showCashAdvance, loansFormVersion } =
      this.props;
    if (type === LoansDocuments.identity) {
      this.setState({ ineFrontRequired: false }, () =>
        Clevertap.event(
          EVENTS.LOANS.FRONT_INE_UPLOADED,
          'UPLOADED_INE_FRONT',
          lenderPayload(preapprovalSelected, {
            showCashAdvance,
            loansFormVersion,
          }),
        ),
      );
    }
    if (type === LoansDocuments.identityBack) {
      this.setState({ ineBackRequired: false }, () =>
        Clevertap.event(
          EVENTS.LOANS.BACK_INE_UPLOADED,
          'UPLOADED_INE_BACK',
          lenderPayload(preapprovalSelected, {
            showCashAdvance,
            loansFormVersion,
          }),
        ),
      );
    }
    if (type === LoansDocuments.passport) {
      this.setState({ passportRequired: false }, () =>
        Clevertap.event(
          EVENTS.LOANS.PASSPORT_UPLOADED,
          'UPLOADED_PASSPORT',
          lenderPayload(preapprovalSelected, {
            showCashAdvance,
            loansFormVersion,
          }),
        ),
      );
    }
    if (type === LoansDocuments.proofOfAddress) {
      this.setState({ proofOfAddressRequired: false }, () =>
        Clevertap.event(
          EVENTS.LOANS.PROOF_ADDRESS_UPLOADED,
          'UPLOADED_PROOF_ADDRESS',
          lenderPayload(preapprovalSelected, {
            showCashAdvance,
            loansFormVersion,
          }),
        ),
      );
    }
  };

  renderTooltip = () => {
    return (
      <Fragment>
        <TitleTooltip>
          <b>{text.titleTooltip}</b>
        </TitleTooltip>
        <Identification>
          <ImageIdentification src={ImageINE} alt={text.ine} />
        </Identification>
      </Fragment>
    );
  };

  showIdentityRadios = () =>
    showIdentityDocumentRadios(this.props.location.pathname.toUpperCase());

  setIdentityDocumentType = (event) => {
    const { change, deleteFilesNotSaved } = this.props;
    change(Forms.MR_PRESTA, 'ine', '');

    if (event.target.value === LoansDocuments.ineDocumentType) {
      deleteFilesNotSaved(LoansDocuments.passport);
      this.setState({ ineFrontRequired: false });
      this.setState({ ineBackRequired: false });
    } else if (event.target.value === LoansDocuments.passportDocumentType) {
      deleteFilesNotSaved(LoansDocuments.identity);
      deleteFilesNotSaved(LoansDocuments.identityBack);
      this.setState({ passportRequired: false });
    }
  };

  deleteIdentityDocuments = () => {
    const {
      previousLoan,
      cleanLastOneIdentity,
      cleanLastOneIdentityBack,
      cleanLastOnePassport,
      identityFiles,
      identityFilesBack,
      passportFiles,
      identityDocumentType,
    } = this.props;

    switch (identityDocumentType) {
      case LoansDocuments.ineDocumentType:
        let ineLastOne = [];
        previousLoan.documents &&
          previousLoan.documents.forEach((document) => {
            if (document.document_type === LoansDocuments.ineDocumentType) {
              ineLastOne.push(document.document_key);
            }
          });
        let ineCurrent = [];
        identityFiles.length > 0 && ineCurrent.push(identityFiles[0].key);
        identityFilesBack.length > 0 &&
          ineCurrent.push(identityFilesBack[0].key);

        if (
          previousLoan &&
          JSON.stringify(ineLastOne) === JSON.stringify(ineCurrent)
        ) {
          cleanLastOneIdentity();
          cleanLastOneIdentityBack();
        }
        break;

      case LoansDocuments.passportDocumentType:
        let passportLastOne = '';
        previousLoan.documents &&
          previousLoan.documents.forEach((document) => {
            if (
              document.document_type === LoansDocuments.passportDocumentType
            ) {
              passportLastOne = document.document_key;
            }
          });
        const passportCurrent = passportFiles[0] ? passportFiles[0].key : '';

        if (previousLoan && passportLastOne === passportCurrent) {
          cleanLastOnePassport();
        }
        break;

      default:
        break;
    }
  };

  enableFields = () => {
    const { ine } = this.props;
    const { disabledDataFieldsUpdated } = this.state;

    if (ine !== undefined && !disabledDataFieldsUpdated) {
      this.setState({ disabledDataFields: false });
      this.setState({ disabledDataFieldsUpdated: true });
    }
  };

  enableSections = () => {
    const {
      identityDocumentType,
      identityFiles,
      identityFilesBack,
      passportFiles,
    } = this.props;
    const { showUploadFieldsUpdated, showDataFieldsUpdated } = this.state;

    if (identityDocumentType !== undefined && !showUploadFieldsUpdated) {
      this.setState({ showUploadFields: true });
      this.setState({ showUploadFieldsUpdated: true });
    }

    if (
      !showDataFieldsUpdated &&
      ((identityDocumentType === 'PASSPORT' && passportFiles.length > 0) ||
        (identityDocumentType !== 'PASSPORT' &&
          identityFiles.length > 0 &&
          identityFilesBack.length > 0))
    ) {
      this.setState({ showDataFields: true });
      this.setState({ showDataFieldsUpdated: true });
    }
  };

  enableNextStep = () => {
    const {
      handleIdentityStep,
      identityDocumentType,
      identityFiles,
      identityFilesBack,
      passportFiles,
      ine,
      firstName,
      lastName,
      secondLastName,
      gender,
      maritalStatus,
      day,
      month,
      year,
      extraTelephone,
      extraTelephones,
    } = this.props;
    const providerCode = getProviderCode(
      this.props.location.pathname.toUpperCase(),
    );
    let isPassport =
      identityDocumentType === LoansDocuments.passportDocumentType;

    let nextStepEnabled = true;
    if (isPassport && passportFiles.length === 0) {
      nextStepEnabled = false;
    }
    if (
      !isPassport &&
      (identityFilesBack.length === 0 || identityFiles.length === 0)
    ) {
      nextStepEnabled = false;
    }
    if (showINEorPassport(providerCode) && isInvalidFormField(ine)) {
      nextStepEnabled = false;
    }
    if (
      isInvalidFormField(firstName) ||
      isInvalidFormField(lastName) ||
      isInvalidFormField(secondLastName) ||
      isInvalidFormField(gender) ||
      isInvalidFormField(maritalStatus)
    ) {
      nextStepEnabled = false;
    }
    if (
      showBirth(providerCode) &&
      (isInvalidFormField(day) ||
        isInvalidFormField(month) ||
        isInvalidFormField(year))
    ) {
      nextStepEnabled = false;
    }
    if (
      showContactInformation(providerCode) &&
      isInvalidFormField(extraTelephone)
    ) {
      nextStepEnabled = false;
    }
    if (
      extraTelephones &&
      extraTelephones.length > 0 &&
      isInvalidArray(extraTelephones)
    ) {
      nextStepEnabled = false;
    }

    handleIdentityStep(nextStepEnabled);
  };

  componentDidMount() {
    this.enableSections();
    this.enableFields();
    this.enableNextStep();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.enableSections();
    this.enableFields();
    this.enableNextStep();
  }

  renderNewProductName = (prevText) => {
    const { prestaClipNameChange } = this.props;
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  render() {
    const {
      documentTypeOptions,
      genderOptions,
      maritalStatusOptions,
      monthsOptions,
      open,
      ineFrontRequired,
      ineBackRequired,
      passportRequired,
      disabledDataFields,
      showUploadFields,
      showDataFields,
    } = this.state;
    const {
      firstName,
      lastName,
      secondLastName,
      ine,
      identityDocumentType,
      hasPreviousLoan,
      previousLoan,
      preapprovalSelected,
      showCashAdvance,
      loansFormVersion,
    } = this.props;

    const { provider_code: providerCode } = preapprovalSelected;
    const isValidProvider = validExternalProvider(providerCode);
    let isPassport =
      identityDocumentType === LoansDocuments.passportDocumentType;

    const identificationFieldLabel = isPassport
      ? text.identificationTitlePassport
      : text.identificationTitleINE;
    const showINEHelp = !isPassport;
    const documentLabel = isPassport
      ? text.documentSectionPassport
      : text.documentSectionINE;
    const documentationLabel =
      providerCode === loansProviderCodes.MOT
        ? text.subtitleIRC
        : isPassport
        ? text.subtitlePRC
        : text.subtitleIRC;

    const endAdornment = showINEHelp ? (
      <ClickAwayListener onClickAway={this.handleTooltipClose}>
        <HtmlTooltip
          PopperProps={{
            disablePortal: false,
          }}
          open={open}
          placement="left"
          onClose={this.handleTooltipClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={this.renderTooltip()}
        >
          <INELink onClick={this.handleTooltipOpen}>
            <p>{text.ineHelp}</p>
            <HelpOutlineIcon />
          </INELink>
        </HtmlTooltip>
      </ClickAwayListener>
    ) : null;

    return (
      <Fragment>
        <StepTitle>{text.title}</StepTitle>
        <StepDescription>
          {this.renderNewProductName(text.description)}
        </StepDescription>

        {this.showIdentityRadios() && (
          <Fragment>
            <StepSection>{text.documentTypeSection}</StepSection>
            <FormControl fullWidth>
              <ClevertapField
                name="identityDocumentType"
                component={RadioField}
                validate={[required]}
                options={documentTypeOptions}
                onChange={this.setIdentityDocumentType}
                clevertap={{
                  listener: 'onChange',
                  event: {
                    ine: EVENTS.LOANS.INE_SELECTED,
                    passport: EVENTS.LOANS.PASSPORT_SELECTED,
                  },
                  payload: lenderPayload(preapprovalSelected, {
                    showCashAdvance,
                    loansFormVersion,
                  }),
                }}
              />
            </FormControl>
          </Fragment>
        )}

        {showUploadFields && (
          <Fragment>
            <StepSection>{documentLabel}</StepSection>
            <FormControl fullWidth>
              <AttachIdentityDocuments
                ineFrontRequired={ineFrontRequired}
                ineBackRequired={ineBackRequired}
                passportRequired={passportRequired}
                fileUploaded={this.fileUploaded}
                identityDocumentType={identityDocumentType}
                previousLoan={hasPreviousLoan && previousLoan.documents}
              />
            </FormControl>
          </Fragment>
        )}

        {showDataFields && (
          <Fragment>
            {showINEorPassport(providerCode) && (
              <Fragment>
                <StepSection>{documentationLabel}</StepSection>
                <FormControl fullWidth>
                  <Field
                    name="ine"
                    component={TextField}
                    inputProps={{ maxLength: 20 }}
                    type="text"
                    endAdornment={endAdornment}
                    label={identificationFieldLabel}
                    validate={[required, isAlphanumeric]}
                    helperText={maxLength(20)(ine)}
                    onChange={this.deleteAddressTime}
                  />
                </FormControl>
              </Fragment>
            )}

            <StepSection>
              {isPassport
                ? text.personalInformationPassportSection
                : text.personalInformationSection}
            </StepSection>
            <FormControl fullWidth>
              <Field
                name="firstName"
                component={TextField}
                inputProps={{ maxLength: 35 }}
                type="text"
                label={text.personalInformationName}
                validate={[required, isText, atLeast3Characters]}
                helperText={maxLength(35)(firstName)}
                disabled={disabledDataFields}
                onChange={this.deleteIdentityDocuments}
              />
              <Field
                name="lastName"
                component={TextField}
                inputProps={{ maxLength: 35 }}
                type="text"
                label={text.personalInformationLastName}
                validate={[required, isText, atLeast3Characters]}
                helperText={maxLength(35)(lastName)}
                disabled={disabledDataFields}
                onChange={this.deleteIdentityDocuments}
              />
              <Field
                name="secondLastName"
                component={TextField}
                inputProps={{ maxLength: 35 }}
                type="text"
                label={text.personalInformationSecondLastName}
                validate={[required, isText]}
                helperText={maxLength(35)(secondLastName)}
                disabled={disabledDataFields}
                onChange={this.deleteIdentityDocuments}
              />
            </FormControl>

            {showBirth(providerCode) && (
              <Fragment>
                <StepSection>{text.personalInformationBirthday}</StepSection>
                <FormControl fullWidth>
                  <Grid container spacing={8}>
                    <Grid item xs={4}>
                      <Field
                        name="day"
                        component={TextField}
                        inputProps={{ minLength: 1, maxLength: 2 }}
                        type="text"
                        label={text.personalInformationDay}
                        validate={[required, isInteger, isValidDayForValues]}
                        disabled={disabledDataFields}
                        onChange={this.deleteAddressTime}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={SelectFieldV2}
                        label={text.personalInformationMonth}
                        name="month"
                        options={monthsOptions}
                        validate={[required]}
                        disabled={disabledDataFields}
                        onChange={this.deleteAddressTime}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        name="year"
                        component={TextField}
                        inputProps={{ minLength: 4, maxLength: 4 }}
                        type="text"
                        label={text.personalInformationYear}
                        validate={[
                          required,
                          isInteger,
                          isValidBirthdayYear,
                          isAdultForValues,
                        ]}
                        disabled={disabledDataFields}
                        onChange={this.deleteAddressTime}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Fragment>
            )}

            {isValidProvider && (
              <Fragment>
                <StepSection>{text.genderSection}</StepSection>
                <FormControl fullWidth>
                  <Field
                    name="gender"
                    component={RadioField}
                    validate={[required]}
                    options={genderOptions}
                    disabled={disabledDataFields}
                    onChange={this.deleteIdentityDocuments}
                  />
                </FormControl>
              </Fragment>
            )}

            {isValidProvider && (
              <Fragment>
                <StepSection>{text.maritalStatusSection}</StepSection>
                <FormControl fullWidth>
                  <ClevertapField
                    component={SelectFieldV2}
                    label={text.selectPlaceholder}
                    name="maritalStatus"
                    options={maritalStatusOptions}
                    validate={[required]}
                    disabled={disabledDataFields}
                    clevertap={{
                      listener: 'onChange',
                      event: EVENTS.LOANS.MARITAL_STATUS_SELECTED,
                      payload: lenderPayload(preapprovalSelected, {
                        showCashAdvance,
                        loansFormVersion,
                      }),
                    }}
                  />
                </FormControl>
              </Fragment>
            )}
            {showContactInformation(providerCode) && (
              <Fragment>
                <StepSection>{text.contactInformationSection}</StepSection>
                <FormControl fullWidth>
                  <Field
                    name="extraTelephone"
                    component={TextField}
                    inputProps={{ maxLength: 10 }}
                    type="text"
                    label={text.contactInformationPhone}
                    validate={[required, isNumeric, haveLength(10)]}
                    disabled={disabledDataFields}
                  />
                  <FieldArray
                    name="extraTelephones"
                    handleExtraPhonesFilled={this.handleExtraPhonesFilled}
                    component={RenderExtraPhonesV3}
                  />
                </FormControl>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const selector = formValueSelector(Forms.MR_PRESTA);

const mapStateToProps = (state) => {
  return {
    firstName: selector(state, 'firstName'),
    secondLastName: selector(state, 'secondLastName'),
    lastName: selector(state, 'lastName'),
    identityFiles: getLoansIdentityDocuments(state),
    identityFilesBack: getLoansIdentityDocumentsBack(state),
    passportFiles: getLoansPassportDocuments(state),
    identityDocumentType: selector(state, 'identityDocumentType'),
    gender: selector(state, 'gender'),
    maritalStatus: selector(state, 'maritalStatus'),
    curp: selector(state, 'curp'),
    ine: selector(state, 'ine'),
    rfc: selector(state, 'rfc'),
    day: selector(state, 'day'),
    month: selector(state, 'month'),
    year: selector(state, 'year'),
    prestaClipNameChange: getPrestaClipNameChange(state),
    extraTelephone: selector(state, 'extraTelephone'),
    extraTelephones: selector(state, 'extraTelephones'),
    preapprovalSelected: state.loans?.request?.preapproval,
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  deleteFilesNotSaved,
  cleanLastOneIdentity,
  cleanLastOneIdentityBack,
  cleanLastOnePassport,
  change,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IdentityStep),
);
