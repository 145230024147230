export const styles = (theme) => {
  const rootStyles = {
    root: {
      width: '100%',
      overflow: 'auto',
      alignSelf: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: `calc(100vh - ${theme.spacing.unit * 7}px)`,
      padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 1.5}px
      ${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 1.5}px`,
      marginTop: `${theme.spacing.unit * 6}px`,
    },
    subContainer: {
      marginBottom: `${theme.spacing.unit * 7}px`,
    },
    message: {
      marginBottom: '0',
      padding: `0 ${theme.spacing.unit * 3}`,
    },
    email: {
      marginBottom: '0',
      lineBreak: 'anywhere',
    },
    boxImg: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
    },
    topIcon: {
      marginBottom: theme.spacing.unit,
    },
    button: {
      marginBottom: theme.spacing.unit * 4,
    },
  };
  return { ...rootStyles };
};
