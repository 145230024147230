import {
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  RESET_LOGIN,
  SET_PASSWORD_RESET_TOKEN,
  CLEAR_PASSWORD_RESET_TOKEN,
  PASSWORD_RESET_TOKEN_ERROR,
  PASSWORD_RESET_EMAIL_SUCCESS,
  PASSWORD_RESET_EMAIL_ERROR,
  SET_PASSWORD_RESET_EMAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOAD_REGISTRATION_META,
  LOAD_REGISTRATION_META_FAIL,
  LOAD_CHECK_EMAIL,
  LOAD_CHECK_EMAIL_FAIL,
  FORM_CHANGE,
} from './types';

const initialState = {
  login: {
    locked: false,
    active: false,
    users: [],
    errors: [],
  },
  registration: {
    industries: [],
    privacy: {},
    terms: {},
    industryOther: false,
    complete: false,
    accessToken: '',
    errors: [],
    error: false,
  },
  passwordReset: {
    token: '',
    tokenVerified: false,
    tokenVerificationAttempts: 0,
    email: '',
    emailSent: false,
    emailSendAttempts: 0,
  },
};

const actionHandlers = new Map([
  [LOGIN_FULFILLED, handleLoginFulfilled],
  [LOGIN_REJECTED, handleLoginRejected],
  [RESET_LOGIN, handleLoginReset],

  [SET_PASSWORD_RESET_TOKEN, handlePasswordResetToken],
  [PASSWORD_RESET_TOKEN_ERROR, handlePasswordResetTokenError],
  [CLEAR_PASSWORD_RESET_TOKEN, handleClearPasswordResetToken],
  [PASSWORD_RESET_EMAIL_SUCCESS, handlePasswordResetEmail],
  [PASSWORD_RESET_EMAIL_ERROR, handlePasswordResetEmailError],

  [SET_PASSWORD_RESET_EMAIL, handleSetPasswordResetEmail],

  [LOAD_REGISTRATION_META, handleRegistrationMeta],
  [LOAD_REGISTRATION_META_FAIL, handleRegistrationMetaError2],
  [LOAD_CHECK_EMAIL, handleRegistrationMeta],
  [LOAD_CHECK_EMAIL_FAIL, handleRegistrationMetaError2],
  [REGISTER_SUCCESS, handleRegistration],
  [REGISTER_FAIL, handleRegistrationError],
  [FORM_CHANGE, handleFormChange],
]);

export default function Auth(state = initialState, action = { type: null }) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleLoginFulfilled(state, action) {
  const login = { ...state.login, active: true };
  return { ...state, login };
}

function handleLoginReset(state, action) {
  return { ...state, login: initialState.login };
}

function handleLoginRejected(state, action) {
  const users = state.login.users.slice();
  const userIndex = users.findIndex(
    (user) => user.email === action.payload.email,
  );

  if (userIndex > -1) {
    // user already exists in state.users array
    // just update the users attempts
    users[userIndex].attempts = action.payload.attempts;
  } else {
    // user does not exist in state.users array
    // Add the new user with their attempts
    users.push({
      email: action.payload.email,
      attempts: action.payload.attempts,
    });
  }

  const login = {
    ...state.login,
    locked: action.payload.accountLocked,
    users: users,
    errors: [...state.login.errors, action.payload.error],
    active: false,
  };

  return { ...state, login };
}

function handlePasswordResetToken(state, action) {
  return {
    ...state,
    passwordReset: {
      ...state.passwordReset,
      token: action.payload.resetToken,
      tokenVerified: true,
      tokenVerificationAttempts:
        state.passwordReset.tokenVerificationAttempts + 1,
    },
  };
}

function handleClearPasswordResetToken(state, action) {
  return { ...state, passwordReset: initialState.passwordReset };
}

function handlePasswordResetTokenError(state, action) {
  return {
    ...state,
    passwordReset: {
      ...state.passwordReset,
      token: action.payload.resetToken,
      tokenVerified: false,
      tokenVerificationAttempts:
        state.passwordReset.tokenVerificationAttempts + 1,
      email: action.payload.email,
    },
  };
}

function handlePasswordResetEmail(state, action) {
  return {
    ...state,
    passwordReset: {
      ...state.passwordReset,
      emailSent: true,
      emailSendAttempts: state.passwordReset.emailSendAttempts + 1,
    },
  };
}

function handlePasswordResetEmailError(state, action) {
  return {
    ...state,
    passwordReset: {
      ...state.passwordReset,
      emailSent: false,
      emailSendAttempts: state.passwordReset.emailSendAttempts + 1,
    },
  };
}

function handleSetPasswordResetEmail(state, action) {
  return {
    ...state,
    passwordReset: {
      ...state.passwordReset,
      email: action.payload,
    },
  };
}

function handleRegistrationMeta(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      ...action.payload,
    },
  };
}

function handleRegistrationMetaError(state, action) {
  const errors = [...state.registration.errors, action.payload];

  const registration = { ...state.registration, errors };

  return { ...state, registration };
}

function handleRegistrationMeta2(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      ...action.payload,
    },
  };
}

function handleRegistrationMetaError2(state, action) {
  const errors = [...state.registration.errors, action.payload];

  const registration = { ...state.registration, errors };

  return { ...state, registration };
}

function handleRegistration(state, action) {
  return {
    ...state,
    registration: { ...state.registration, complete: true, error: false },
  };
}

function handleRegistrationError(state, action) {
  const errors = [...state.registration.errors, action.payload];

  const registration = { ...state.registration, errors, error: true };

  return { ...state, registration };
}

function handleFormChange(state = initialState, action) {
  if (
    state.registration.industries.length > 0 &&
    action.meta.field === 'industry'
  ) {
    for (let i = 0; i < state.registration.industries.length; i++) {
      const industry = state.registration.industries[i];

      if (
        industry.id === action.payload &&
        typeof industry.other !== 'undefined'
      ) {
        state.registration.industryOther = true;
      } else {
        state.registration.industryOther = false;
      }
    }

    return { ...state };
  }

  return state;
}
