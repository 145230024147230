const styles = (theme) => ({
  root: {
    margin: '16px',
  },
  title: {
    marginBottom: '24px',
    fontWeight: 'bold',
  },
  scrollToTop: {
    textAlign: 'center',
    position: 'fixed',
    left: '17px',
    bottom: '17px',
    backgroundColor: theme.palette.orange.l80,
    borderRadius: '50%',
    width: '52px',
    height: '52px',
  },
  chevron: {
    width: '38px',
    marginTop: '7px',
  },
  lastUpdated: {
    marginTop: '35px',
    marginBottom: '62px',
    color: theme.palette.indigo.l50,
  },
});

export default styles;
