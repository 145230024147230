import React, {
  Suspense,
  useEffect,
  createContext,
  useState,
  useMemo,
} from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';

import loadable from '@loadable/component';

import urls, {
  publicOnboardRoutes,
  newOnboardingRoute,
  protectedOnboardRoutes,
  onboardRegister,
} from '../helpers/urls';
import { Events } from '../constants/GTMConstants';

import { EVENTS } from '../helpers/Clevertap';
import { loansRoutePayload } from '../components/loans/Commons/Clevertap';

import AnalyticsEvents from '../helpers/AnalyticsEvents';

const App = loadable(() => import('../components/App'));
// const Error404 = loadable(() => import('../components/pages/errors/Error404'));
// const GTMCrediClipAnalyticsEvents = loadable(() => import('../helpers/GTMCrediClipAnalyticsEvents'));

const NewOnboardLayout = loadable(() =>
  import('../components/onboarding/v2/Layout'),
);
const AdvancePayment = loadable(() =>
  import('../components/loans/Commons/AdvancePayment'),
);
const AcceptedPage = loadable(() =>
  import('../components/loans/Commons/AcceptedPage'),
);
const AuthController = loadable(() =>
  import('../components/auth/AuthController'),
);
const BankAccounts = loadable(() =>
  import('../components/account/BankAccount'),
);
const BankAccountRequestPassword = loadable(() =>
  import('../components/account/BankAccount/BankAccountRequestPassword'),
);

const BusinessInformation = loadable(() =>
  import('../components/account/BusinessInformation'),
);
const BusinessTools = loadable(() => import('../components/BusinessTools'));
const BusinessUnitsManagement = loadable(() =>
  import('../components/BusinessUnitsManagement'),
);
const CatalogHome = loadable(() => import('../components/Catalog/Home'));
const CheckoutClip = loadable(() =>
  import('../components/paymentrequest/RemotePayments/CheckoutClip'),
);
const InviteBUM = loadable(() =>
  import('../components/BusinessUnitsManagement/Invite'),
);
const AcceptInviteBUM = loadable(() =>
  import('../components/BusinessUnitsManagement/AcceptInvite'),
);
const ProductCrud = loadable(() => import('../components/Catalog/ProductCrud'));
const PublishCatalog = loadable(() =>
  import('../components/Catalog/PublishCatalog'),
);
const CanceledPage = loadable(() =>
  import('../components/loans/Commons/CanceledPage'),
);
const CatalogConfirmPilotInvitation = loadable(() =>
  import('../components/Catalog/ConfirmPilotInvitation'),
);
const CatalogBulkUpload = loadable(() =>
  import('../components/Catalog/BulkUpload'),
);
const ClipReaderOrders = loadable(() =>
  import('../components/ClipReaderOrders'),
);
const ClipDevices = loadable(() => import('../components/ClipDevices'));
const Codi = loadable(() => import('../components/Codi'));
const CodiDetails = loadable(() => import('../components/Codi/CodiDetails'));
const CodiReports = loadable(() => import('../components/Codi/CodiReports'));
const Collaborators = loadable(() => import('../components/Collaborators'));
const Collaborator = loadable(() =>
  import('../components/Collaborators/Collaborator'),
);
const ControlPanel = loadable(() => import('../components/ControlPanel'));
const DailyDepositReport = loadable(() =>
  import('../components/deposits/DailyDepositReports/Report'),
);
const DailyDepositReports = loadable(() =>
  import('../components/deposits/DailyDepositReports'),
);
const DashboardContainer = loadable(() =>
  import('../components/DashboardContainer'),
);
const EditLinkPage = loadable(() =>
  import('../components/paymentrequest/PaymentRequest/PublicProfile/EditPage'),
);
const Elegible = loadable(() =>
  import('../components/loans/OpenData/Elegible'),
);
const ExpiredPage = loadable(() =>
  import('../components/loans/Commons/ExpiredPage'),
);
const ExternalInformationV3 = loadable(() =>
  import('../components/loans/ExternalLenderV3/Information'),
);
const FAQPage = loadable(() => import('../components/loans/Commons/FAQPage'));
const Interested = loadable(() =>
  import('../components/loans/OpenData/Interested'),
);
const Invoices = loadable(() => import('../components/Invoices'));
const LDButton = loadable(() => import('../components/Catalog/LDButton'));
const Loans = loadable(() => import('../components/loans'));
const LoansCongrats = loadable(() =>
  import('../components/loans/Commons/CongratulationPage'),
);
const LoansForm = loadable(() => import('../components/loans/LoansForm'));
const LoanSignCongrats = loadable(() =>
  import('../components/loans/Commons/CongratulationPage/ForSign'),
);
const LoanSigned = loadable(() =>
  import('../components/loans/Commons/AcceptedPage/Signed'),
);
const Login = loadable(() => import('../components/auth/Login'));
const MerchantsReferred = loadable(() =>
  import('../components/referrals/MerchantsReferred'),
);
const MonthlyDepositReport = loadable(() =>
  import('../components/deposits/MonthlyDepositReports/Report'),
);
const MonthlyDepositReports = loadable(() =>
  import('../components/deposits/MonthlyDepositReports'),
);
const MyCrediClip = loadable(() => import('../components/loans/MyCrediClip'));

const MyStorePage = loadable(() => import('../components/mystore/MyStorePage'));

const NewLayout = loadable(() => import('../components/NewLayout'));
const NewBankAccount = loadable(() =>
  import('../components/account/BankAccount/NewBankAccount'),
);
const NotElegible = loadable(() =>
  import('../components/loans/OpenData/NotElegible'),
);
const OpenDataApproved = loadable(() =>
  import('../components/loans/OpenData/Approved'),
);
const OpenDataConfirmation = loadable(() =>
  import('../components/loans/OpenData/Confirmation'),
);
const OpenDataInReview = loadable(() =>
  import('../components/loans/OpenData/InReview'),
);
const OpenDataOffers = loadable(() =>
  import('../components/loans/OpenData/Offers'),
);
const PasswordEdit = loadable(() =>
  import('../components/auth/passwords/PasswordEdit'),
);
const PasswordReset = loadable(() =>
  import('../components/auth/passwords/PasswordReset'),
);
const PasswordResetSent = loadable(() =>
  import('../components/auth/passwords/PasswordResetSent'),
);
const PasswordResetSuccess = loadable(() =>
  import('../components/auth/passwords/PasswordResetSuccess'),
);
const PaymentLinkPage = loadable(() =>
  import('../components/paymentrequest/PaymentPage'),
);
const PaymentRequest = loadable(() =>
  import('../components/paymentrequest/PaymentRequest'),
);
const RemotePayment = loadable(() =>
  import('../components/paymentrequest/RemotePayments'),
);
const PendingPaymentRequest = loadable(() =>
  import('../components/paymentrequest/PendingPaymentRequest'),
);
const PersonalInformation = loadable(() =>
  import('../components/account/PersonalInformation'),
);
const Refill = loadable(() => import('../components/loans/Refill'));
const RefillConfirmation = loadable(() =>
  import('../components/loans/Refill/confirmation'),
);
const RefillCongrats = loadable(() =>
  import('../components/loans/Refill/congrats'),
);
const RefillReview = loadable(() =>
  import('../components/loans/Refill/review'),
);
const Registration = loadable(() => import('../components/auth/Registration'));
const SimpleRegistration = loadable(() =>
  import('../components/auth/SimpleRegistration'),
);
const SecondAuth = loadable(() =>
  import('../components/multiLogin/SecondAuth'),
);
const ReferralsPage = loadable(() => import('../components/referrals/Page'));
const ReferralWelcome = loadable(async () => {
  const { ReferralWelcome } = await import('../components/referrals');
  return (props) => <ReferralWelcome {...props} />;
});
const ReferralTerms = loadable(async () => {
  const { ReferralTerms } = await import('../components/referrals');
  return (props) => <ReferralTerms {...props} />;
});
const Refer = loadable(async () => {
  const { Refer } = await import('../components/referrals');
  return (props) => <Refer {...props} />;
});

const RegistrationValidation = loadable(() =>
  import('../components/onboarding/RegistrationValidation'),
);
const TermConditionsPage = loadable(() =>
  import('../components/loans/Commons/TermConditionsPage'),
);
const TopUpDetails = loadable(() =>
  import('../components/TopUps/TopUpDetails'),
);
const TopUps = loadable(() => import('../components/TopUps'));
const TopUpsReports = loadable(() =>
  import('../components/TopUps/TopUpsReports'),
);
const TransactionDetails = loadable(() =>
  import('../components/Transactions/TransactionDetails'),
);
const Transactions = loadable(() => import('../components/Transactions'));
const TransactionsReports = loadable(() =>
  import('../components/Transactions/TransactionReports'),
);
const BankAccountConfirmation = loadable(() =>
  import('components/account/BankAccount/BankAccountConfirmation'),
);
const BankAccountSuccess = loadable(() =>
  import('components/account/BankAccount/BankAccountSuccess'),
);

export const ContainerContext = createContext({
  isNewLayout: '',
  setIsNewLayout: () => {},
});

const breadcrumbNameMap = {
  '/': 'Panel de control',
  '/catalog': 'Catálogo',
  '/catalog/product/new': 'Nuevo',
  '/catalog/product/edit': 'Editar',
  [urls.catalogPublish]: 'Publicar Catálogo',
};

const breadcrumbBUMMap = {
  '/': 'Panel de control',
  '/business_unitsm': 'Unidades de negocio',
  '/business_unitsm/invite': 'Crear',
};

const breadcrumbPADMap = {
  '/pad': 'Pagos a Distancia',
  '/checkout_clip': 'Checkout Clip',
  '/link_page': 'Link de Negocio',
  '/link_page/edit': 'Editar Link de Negocio'

};

const clevertapLoansOfferPayload = loansRoutePayload(
  EVENTS.LOANS.NEW_OFFERS_VIEW,
  null,{ type: 'regular' },
);
const clevertapLoansReviewPayload = loansRoutePayload(
  EVENTS.LOANS.REVIEW_VIEW,
  { loans: { 0: { status: 'status'} } },
  { type: 'regular' },
);
const clevertapLoansCongratsPayload = loansRoutePayload(
  EVENTS.LOANS.CONGRATS_VIEW,
  null,
  { type: 'regular' },
);

const clevertapLoansOfferRefillPayload = loansRoutePayload(
  EVENTS.LOANS.NEW_OFFERS_VIEW,
  null,
  { type: 'refill' },
);
const clevertapLoansRefillReviewPayload = loansRoutePayload(
  EVENTS.LOANS.REFILL_REVIEW_VIEW,
  null,
  { type: 'refill' },
);
const clevertapLoansPersonalizedReviewPayload = loansRoutePayload(
  EVENTS.LOANS.REVIEW_VIEW,
  null,
  { type: 'custom' },
);
const clevertapLoansCongratsPersonalizedPayload = loansRoutePayload(
  EVENTS.LOANS.CONGRATS_VIEW,
  null,
  { type: 'custom' },
);
const clevertapLoansOfferCustomPayload = loansRoutePayload(
  EVENTS.LOANS.NEW_OFFERS_VIEW,
  null,
  { type: 'custom' },
);
const clevertapLoansLenderPayload = (event) =>
  loansRoutePayload(event, {
    request: { preapproval: { provider_code: 'lender' } },
  });
const sendGoogleAnalyticsPageView = function (path) {
  AnalyticsEvents.pageView(path);
};
function rootEnterCB(pathname) {
  sendGoogleAnalyticsPageView(pathname);
}

function usePageView() {
  let location = useLocation();

  useEffect(() => {
    if (!window.dataLayer) return;

    window.dataLayer.push({
      event: Events.VIRTUAL_PAGE_VIEW,
      page: location.pathname,
      title: '',
    });
  }, [location]);
}

const NewLayoutComp = (props) => <NewLayout {...props} />;
const NewLayoutWithSecondAuth = (props) => (
  <SecondAuth>
    <NewLayout {...props} />
  </SecondAuth>
);

const Routes = (props) => {
  let location = useLocation();
  const [isNewLayout, setIsNewLayout] = useState(false);
  usePageView();
  const value = useMemo(() => ({ isNewLayout, setIsNewLayout }), [isNewLayout]);

  useEffect(() => {
    rootEnterCB(location.pathname);
  }, []);

  return (
    <ContainerContext.Provider value={value}>
      <App>
        <Suspense fallback={<div>LOADING</div>}>
          <Switch>
            <Route
              path={newOnboardingRoute}
              render={(routeProps) => (
                <NewOnboardLayout steps={publicOnboardRoutes} {...routeProps} />
              )}
            />
            <Route
              path={`${urls.REGISTRATION_VALIDATION_HELP}/:validationId`}
              render={(routeProps) => {
                return (
                  <NewOnboardLayout
                    steps={publicOnboardRoutes}
                    {...routeProps}
                  />
                );
              }}
            />
            <Route exact path={urls.deepLink}>
              <Redirect to={urls.myStore} />
            </Route>
            <Route exact path="/">
              <Redirect to={urls.signIn} />
            </Route>
            <Route exact path={urls.signIn} component={Login} />
            <Route exact path={urls.signUp} component={Registration}>
              <Redirect to={onboardRegister} />
            </Route>
            <Route
              exact
              path={urls.simpleSignUp}
              component={SimpleRegistration}
            />
            <Route exact path={urls.newPassword} component={PasswordReset} />
            <Route exact path={urls.editPassword} component={PasswordEdit} />
            <Route
              exact
              path={urls.resetPasswordSent}
              component={PasswordResetSent}
            />
            <Route
              exact
              path={urls.resetPasswordSuccess}
              component={PasswordResetSuccess}
            />
            <Route exact path={urls.referralTerms} component={ReferralTerms} />
            <Route
              exact
              path={urls.referralWelcome}
              component={ReferralWelcome}
            />
            <Route exact path={urls.referralProgram} component={Refer} />
            <Route
              exact
              path={urls.catalogConfirmPilotInvitation}
              component={CatalogConfirmPilotInvitation}
            />
            <Route
              exact
              path={urls.bankAccountConfirmation}
              component={BankAccountConfirmation}
            />

            <Route
              exact
              path={urls.bankAccountCancelActivationSuccess}
              component={BankAccountSuccess}
            />

            {/* 📖 - New Onboarding */}
            <AuthController
              path={urls.onboardMerchant}
              maxWidth={false}
              component={(routeProps) => {
                return (
                  <NewOnboardLayout
                    steps={protectedOnboardRoutes}
                    {...routeProps}
                  />
                );
              }}
            />
            {/* 📖 - Onboarding */}
            <AuthController
              exact
              path={urls.REGISTRATION_VALIDATION}
              component={RegistrationValidation}
            />
            {/* 🚀 - Dashboard */}
            <AuthController
              exact
              path={urls.dashboard}
              component={ControlPanel}
              clevertap={{ event: EVENTS.MENU.DASHBOARD }}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.manageCatalog}
              component={CatalogHome}
              clevertap={{ event: EVENTS.MENU.MANAGE_CATALOG }}
              layout={DashboardContainer}
              subLayout={NewLayoutComp}
              breadcrumbNameMap={breadcrumbNameMap}
              title={
                <LDButton
                  title="Catálogo"
                  buttonCaption="Ir a catálogo en línea"
                />
              }
            />
            <AuthController
              exact
              path={urls.catalogNewItem}
              component={ProductCrud}
              layout={DashboardContainer}
              subLayout={NewLayoutComp}
              breadcrumbNameMap={breadcrumbNameMap}
              title="Catálogo"
            />
            <AuthController
              exact
              path={urls.catalogPublish}
              component={PublishCatalog}
              layout={DashboardContainer}
              subLayout={NewLayoutComp}
              breadcrumbNameMap={breadcrumbNameMap}
              title="Catálogo"
            />
            <AuthController
              exact
              path={urls.catalogEditItem}
              component={ProductCrud}
              layout={DashboardContainer}
              subLayout={NewLayoutComp}
              breadcrumbNameMap={breadcrumbNameMap}
              title="Catálogo"
            />
            <AuthController
              exact
              path={urls.catalogBulkUpload}
              component={CatalogBulkUpload}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.manageInvoices}
              clevertap={{ event: EVENTS.MENU.MANAGE_INVOICES }}
              component={Invoices}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.transactions}
              layout={DashboardContainer}
              clevertap={{ event: EVENTS.MENU.TRANSACTIONS }}
              component={Transactions}
            />
            <AuthController
              exact
              path={urls.transactionsReports}
              component={TransactionsReports}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.transactionDetails}
              component={TransactionDetails}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.topUps}
              component={TopUps}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.topUpsReports}
              component={TopUpsReports}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.topUpDetails}
              component={TopUpDetails}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.codi}
              component={Codi}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.codiReports}
              component={CodiReports}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.codiDetails}
              component={CodiDetails}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.merchantUsers}
              clevertap={{ event: EVENTS.MENU.MERCHANT_USERS }}
              component={Collaborators}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.merchantUsersNew}
              component={Collaborator}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.merchantUsersEdit}
              component={Collaborator}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.me}
              clevertap={{ event: EVENTS.MENU.ME }}
              component={PersonalInformation}
              layout={DashboardContainer}
              subLayout={NewLayoutComp}
            />
            <AuthController
              exact
              path={urls.marketingTools}
              clevertap={{ event: EVENTS.MENU.MARKETING_TOOLS }}
              component={BusinessTools}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.clipReaders}
              component={ClipReaderOrders}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.merchantDevices}
              component={ClipDevices}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.businessInformation}
              component={BusinessInformation}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.bankAccounts}
              component={BankAccounts}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.bankAccountsNew}
              component={NewBankAccount}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.bankAccountsRequestPassword}
              component={BankAccountRequestPassword}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.dailyDepositReport}
              component={DailyDepositReport}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.dailyDepositReportLedger}
              component={DailyDepositReport}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              clevertap={{ event: EVENTS.MENU.DAILY_DEPOSIT_REPORTS }}
              path={urls.dailyDepositReports}
              component={DailyDepositReports}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.monthlyDepositReport}
              component={MonthlyDepositReport}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.monthlyDepositReports}
              component={MonthlyDepositReports}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.referralLanding}
              component={ReferralsPage}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.merchantsReferred}
              component={MerchantsReferred}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              clevertap={{ event: EVENTS.MENU.PAYMENT_REQUEST }}
              path={urls.pad}
              component={RemotePayment}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.paymentRequest}
              component={PaymentRequest}
              layout={DashboardContainer}
            />

            <AuthController
              exact
              path={urls.paymentRequestPendingPayments}
              component={PendingPaymentRequest}
              layout={DashboardContainer}
            />

            <AuthController
              exact
              path={urls.paymentLinkPage}
              component={PaymentLinkPage}
              layout={DashboardContainer}
            />

            <AuthController
              exact
              path={urls.editLinkPage}
              component={EditLinkPage}
              layout={DashboardContainer}
              subLayout={NewLayoutComp}
              breadcrumbNameMap={breadcrumbPADMap}
            />

            <AuthController
              exact
              path={urls.myStore}
              clevertap={{ event: EVENTS.MENU.ONLINE_STORE }}
              component={MyStorePage}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.checkoutClip}
              component={CheckoutClip}
              layout={DashboardContainer}
              subLayout={NewLayoutComp}
              breadcrumbNameMap={breadcrumbPADMap}
            />

            {/* Routes - loans */}
            <AuthController
              exact
              path={urls.loans}
              component={Loans}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              clevertap={{ event: EVENTS.MENU.LOANDS_OPEN_DATA_NOT_ELEGIBE }}
              path={urls.loansOpenDataNotElegible}
              component={NotElegible}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansFAQs}
              component={FAQPage}
              layout={DashboardContainer}
              clevertap={loansRoutePayload(EVENTS.LOANS.FAQS_VIEW)}
            />
            <AuthController
              exact
              path={urls.termConditionsLoans}
              component={TermConditionsPage}
              layout={DashboardContainer}
              clevertap={loansRoutePayload(EVENTS.LOANS.TC_VIEW)}
            />
            <AuthController
              exact
              path={urls.loansCanceled}
              component={CanceledPage}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansOpenDataElegible}
              component={Elegible}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansOpenDataOffers}
              component={OpenDataOffers}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansOpenDataConfirmation}
              component={OpenDataConfirmation}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansOpenDataInterested}
              component={Interested}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansOpenDataApproved}
              component={OpenDataApproved}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansOpenDataInReview}
              component={OpenDataInReview}
              layout={DashboardContainer}
            />

            {/* Routes - Regular Form */}
            <AuthController
              exact
              path={urls.loansMyCrediClip}
              component={MyCrediClip}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansMyPrestaClip}
              clevertap={loansRoutePayload(EVENTS.LOANS.PRESTACLIP_VIEW)}
              component={MyCrediClip}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.preapprovalsExpiredV4}
              component={ExpiredPage}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.inreview}
              component={AcceptedPage}
              clevertap={clevertapLoansReviewPayload}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansPersonalizedInReview}
              component={AcceptedPage}
              clevertap={clevertapLoansPersonalizedReviewPayload}
              layout={DashboardContainer}
            />

            {/* Congrats */}
            <AuthController
              exact
              path={urls.loansCongratsForSign}
              clevertap={clevertapLoansCongratsPayload}
              component={LoanSignCongrats}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansCongratsSigned}
              clevertap={clevertapLoansCongratsPayload}
              component={LoanSigned}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansRefillCongrats}
              component={RefillCongrats}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.congrats}
              clevertap={clevertapLoansCongratsPayload}
              component={LoansCongrats}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.congratsPersonalized}
              clevertap={clevertapLoansCongratsPersonalizedPayload}
              component={LoansCongrats}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.congratsVersion}
              clevertap={clevertapLoansCongratsPayload}
              component={LoansCongrats}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansCongratsAction}
              clevertap={clevertapLoansCongratsPayload}
              component={LoansCongrats}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.congratsVersionAction}
              clevertap={clevertapLoansCongratsPayload}
              component={LoansCongrats}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.congratsPersonalizedVersion}
              clevertap={clevertapLoansCongratsPersonalizedPayload}
              component={LoansCongrats}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansCongratsPersonalizedAction}
              clevertap={clevertapLoansCongratsPersonalizedPayload}
              component={LoansCongrats}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.congratsPersonalizedVersionAction}
              clevertap={clevertapLoansCongratsPersonalizedPayload}
              component={LoansCongrats}
              layout={DashboardContainer}
            />

            {/* Refill */}
            <AuthController
              exact
              path={urls.loansRefill}
              component={Refill}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansRefillConfirmation}
              component={RefillConfirmation}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansRefillInReview}
              clevertap={clevertapLoansRefillReviewPayload}
              component={RefillReview}
              layout={DashboardContainer}
            />

            {/* Fast Payment */}
            <AuthController
              exact
              path={urls.loansMrPrestaAdvancePayment}
              component={AdvancePayment}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansMOTAdvancePayment}
              component={AdvancePayment}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansAPHAdvancePayment}
              component={AdvancePayment}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansCTOAdvancePayment}
              component={AdvancePayment}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansR2AdvancePayment}
              component={AdvancePayment}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansAVLAdvancePayment}
              component={AdvancePayment}
              layout={DashboardContainer}
            />

            {/* Fonel sections */}
            {/* V4 - Cash Advance */}
            <AuthController
              exact
              path={urls.loansV4CA}
              component={Loans}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansPersonalizedV4CA}
              component={Loans}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansFormIdentityV4CA}
              component={LoansForm}
              clevertap={clevertapLoansLenderPayload(
                EVENTS.LOANS.IDENTITY_VIEW,
              )}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansFormHistoryV4CA}
              component={LoansForm}
              clevertap={clevertapLoansLenderPayload(
                EVENTS.LOANS.CREDIT_HISTORY_VIEW,
              )}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansFormAddressV4CA}
              component={LoansForm}
              clevertap={clevertapLoansLenderPayload(EVENTS.LOANS.ADRESS_VIEW)}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansFormDepositV4CA}
              component={LoansForm}
              clevertap={clevertapLoansLenderPayload(EVENTS.LOANS.DEPOSIT_VIEW)}
              layout={DashboardContainer}
            />

            {/* V4 - Cash Advance */}
            <AuthController
              exact
              path={urls.loansV4}
              component={Loans}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansPersonalizedV4}
              component={Loans}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansFormIdentityV4}
              component={LoansForm}
              clevertap={clevertapLoansLenderPayload(
                EVENTS.LOANS.IDENTITY_VIEW,
              )}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansFormHistoryV4}
              component={LoansForm}
              clevertap={clevertapLoansLenderPayload(
                EVENTS.LOANS.CREDIT_HISTORY_VIEW,
              )}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansFormAddressV4}
              component={LoansForm}
              clevertap={clevertapLoansLenderPayload(EVENTS.LOANS.ADRESS_VIEW)}
              layout={DashboardContainer}
            />
            <AuthController
              exact
              path={urls.loansFormDepositV4}
              component={LoansForm}
              clevertap={clevertapLoansLenderPayload(EVENTS.LOANS.DEPOSIT_VIEW)}
              layout={DashboardContainer}
            />

            {/* Routes - BUM */}
            <AuthController
              exact
              path={urls.bumAcceptInvite}
              component={AcceptInviteBUM}
              layout={DashboardContainer}
              subLayout={NewLayoutComp}
            />
            <AuthController
              exact
              clevertap={{ event: EVENTS.MENU.BUM }}
              path={urls.bum}
              component={BusinessUnitsManagement}
              layout={DashboardContainer}
              subLayout={NewLayoutWithSecondAuth}
            />
            <AuthController
              exact
              path={urls.bumInvite}
              component={InviteBUM}
              layout={DashboardContainer}
              subLayout={NewLayoutWithSecondAuth}
              breadcrumbNameMap={breadcrumbBUMMap}
              title="Crear unidad de negocio"
            />
            {/* <Route path={urls.notFound}>
            <Error404 />
          </Route> */}
          </Switch>
        </Suspense>
      </App>
    </ContainerContext.Provider>
  );
};

export default Routes;
