import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import urls from 'helpers/urls';
import { LoansTabs, PreapprovalStatus } from 'constants/AppConstants.js';
import { loansProviderCodes } from 'constants/ProviderCodes';
import LoansContent from 'components/loans/Commons/LoansContent';
import { getPreapprovals } from 'components/loans/redux/selectors';
import * as Styled from './styled';
import text from './text';

const lenderWithTerms = ['MOT'];
class TermConditionsPage extends Component {
  getActiveProvider = (preapprovals, withLoans) => {
    if (
      preapprovals.length > 0 &&
      lenderWithTerms.includes(preapprovals[0].provider_code)
    )
      return preapprovals[0].provider_code;
    if (
      withLoans.length > 0 &&
      lenderWithTerms.includes(withLoans[0].provider_code)
    )
      return withLoans[0].provider_code;
    return loansProviderCodes.GENERAL;
  };

  render() {
    const { preapprovals = [] } = this.props;
    const availables = preapprovals.filter(
      (preapproval) =>
        [
          PreapprovalStatus.AVAILABLE,
          PreapprovalStatus.ACCEPTED,
          PreapprovalStatus.SIGNED,
        ].includes(preapproval.status) && !preapproval.loan_id,
    );
    const expired = preapprovals.filter((preapproval) =>
      [PreapprovalStatus.EXPIRED].includes(preapproval.status),
    );
    const withLoans = preapprovals.filter(
      (preapproval) => preapproval.loan_id !== null,
    );
    const activeProvider = this.getActiveProvider(
      availables.length > 0 ? availables : expired,
      withLoans,
    );

    const baseLocation = this.props.location.pathname.startsWith(urls.mobile)
      ? urls.mobile
      : '';

    return (
      <LoansContent
        hasPreapprovals={availables.length > 0 || expired.length > 0}
        hasLoans={withLoans.length > 0}
        tab={LoansTabs.TERMS}
      >
        <Styled.Container>
          <Styled.Title>{text.title}</Styled.Title>
          <Styled.Content>
            <ReactMarkdown
              source={text.content[activeProvider.toLowerCase()]}
            />
          </Styled.Content>
        </Styled.Container>
        <Styled.LinkContainer>
          <Styled.FormLink to={`${baseLocation}${urls.loans}`}>
            {text.link}
            <Styled.LinkIcon />
          </Styled.FormLink>
        </Styled.LinkContainer>
      </LoansContent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
  };
};
export default withRouter(connect(mapStateToProps)(TermConditionsPage));
