export const styles = (theme) => {
  const rootStyles = {
    container: {
      minHeight: `calc(100% - ${theme.spacing(9)}px)`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
    },
    sectionTitle: {
      marginBottom: theme.spacing(6),
    },
    cardTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
      color: theme.palette.indigo.l30,
    },
    cardInfo: {
      padding: `0 ${theme.spacing(4.5)}px`,
      color: theme.palette.indigo.l10,
    },
    iconTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    greyText: {
      color: theme.palette.indigo.l40,
    },
    icon: {
      marginRight: theme.spacing(1),
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    },
    divider: {
      width: '85%',
      height: '1px',
      backgroundColor: theme.palette.orange.l40,
      margin: '32px auto',
    },
    concept: {
      marginTop: theme.spacing(1),
    },
    conceptText: {
      color: theme.palette.indigo.l40,
    },
  };
  return { ...rootStyles };
};
