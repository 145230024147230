export const styles = (theme) => {
  const cardStyles = {
    cardDesktop: {
      boxShadow: 'none',
      width: '100%',
      margin: 'auto',
    },
    cardActions: {
      justifyContent: 'center',
      padding: 0,
    },
    cardContent: {
      padding: `0px ${theme.spacing.unit * 2}px`,
      paddingTop: 0,
      paddingBottom: `${theme.spacing.unit * 2}px!important`,
    },
    paymentsBorder: {
      padding: `${theme.spacing.unit}px`,
      margin: `${theme.spacing.unit * 2}px 0px`,
      borderRadius: `${theme.spacing.unit * 2}px`,
      backgroundImage: 'linear-gradient(to bottom, #fc4c02, #fe8305)',
    },
    paymentsContent: {
      padding: `${theme.spacing.unit * 2}px 0px`,
      backgroundColor: `${theme.palette.common.white}`,
      borderRadius: `${theme.spacing.unit}px`,
      display: 'flex',
    },
    paymentsPayment: {
      width: 'calc(50% - 12px)',
      padding: `0px ${theme.spacing.unit * 2}px`,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: `0px ${theme.spacing.unit / 2}px`,
      },
    },
    paymentsImage: {
      width: `${theme.spacing.unit * 5}px`,
      height: `${theme.spacing.unit * 5}px`,
    },
    paymentsIcon: {
      color: `${theme.palette.naranja.bloodOrange}`,
      fontSize: `${theme.spacing.unit * 5}px`,
      margin: 'auto',
    },
  };

  const typographyStyles = {
    typographyTitle: {
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 500,
      textAlign: 'center',
      color: '#001724',
      marginLeft: 'auto',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: `${theme.spacing.unit * 3.25}px`,
      },
    },
    typographyTitle2: {
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 500,
      textAlign: 'center',
      color: '#001724',
      marginLeft: 'auto',
      marginTop: `${theme.spacing.unit * 2}px`,
      marginBottom: `${theme.spacing.unit}px`,
    },
    typographyTitleDesktop: {
      fontSize: `${theme.spacing.unit * 2.625}px`,
      fontWeight: 500,
      textAlign: 'center',
      color: '#001724',
      margin: 'auto',
      marginBottom: `${theme.spacing.unit * 2}px`,
    },
    typographyBody1: {
      fontSize: `${theme.spacing.unit * 1.75}px`,
      lineHeight: 1.29,
      textAlign: 'center',
      color: '#001724',
    },
    typographyBod1Desktop: {
      fontSize: `${theme.spacing.unit * 2}px`,
      color: '#001724',
      textAlign: 'center',
      lineHeight: 1.13,
      fontWeight: 300,
    },
    typographyBody2: {
      fontSize: `${theme.spacing.unit * 1.5}px`,
      color: `${theme.palette.common.black}`,
      lineHeight: '1.2em',
    },
    typographySubheading: {
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 600,
      color: `${theme.palette.naranja.bloodOrange}`,
    },
  };

  const iconStyles = {
    iconExpand: {
      marginLeft: 'auto',
      color: `${theme.palette.naranja.bloodOrange}`,
    },
  };

  return { ...typographyStyles, ...cardStyles, ...iconStyles };
};
