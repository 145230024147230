export default {
  greeting: 'Hola,',
  crediClip: 'Adelanto de Ventas Clip',
  titleP1: 'El préstamo que necesitas para invertir y crecer tu negocio',
  titleP2: `Adelanto de Ventas Clip es un sistema de préstamos que te permite 
    obtener dinero para tu negocio de manera muy fácil y rápida, porque se paga 
    por medio de las ventas que realices con tu Clip.`,
  subtitle:
    'Personaliza tu oferta, realiza tu solicitud y pronto recibirás tu dinero.',
  expiration: (expirationDate) =>
    `Oferta por tiempo limitado. Solicita tu préstamo antes del ${expirationDate} `,
  maxLimit: 'Plazo:',
  months: 'meses',
  fixedCharge: 'Pago mensual: ',
  total: 'Total a pagar: ',
  totalAmount: 'Préstamo por',
  refund: 'Forma de pago: ',
  mount: 'Monto requerido',
  mountSlider: 'Monto',
  dailySales: 'retención de ventas diarias',
  monthlySales: 'En cada venta descontaremos este % para pagar tu préstamo',
  buttonAmmount: 'Solicitar ',
  recommendedLabel: 'Recomendado',
  amountTitles: ['Crece tu negocio', 'Renuévate', 'Nuevos clientes'],
  amountSubtitles: [
    'Renuévate y date a conocer',
    'Invierte en nuevos productos',
    'Promocionate en redes sociales',
  ],
  stepsTitle: 'Beneficios de tu Presta Clip',
  stepsDescription: `Con Presta Clip podrás recibir un adelanto de tus ventas para llegar 
  a nuevos clientes, renovar o comprar más productos, o crecer tu negocio como tú lo desees.`,
  stepOneTitle: 'Fácil de solicitar',
  stepOneDescription:
    'Elige una de las tres ofertas disponibles e inicia tu solicitud en un solo clic.',
  stepTwoTitle: 'Rápido de obtener',
  stepTwoDescription:
    'Recibe tu Presta Clip en 24 horas, una vez que la solicitud sea aprobada.',
  stepThreeTitle: 'Pagas como vendes',
  stepThreeDescription:
    'Pagarás tu Presta Clip con un porcentaje de tus ventas diarias. Sin enganche ni aval.',
  preSamsGift: '+ un regalo',
  postSamsGift: 'por parte de',
  importantNote: 'Importante: ',
  importantNoteText: 
    'Si no cubres el pago mínimo mediante retenciones, deberás cubrirlo por transferencia ó depósito bancario.',
  loanPersonalized: (username) => `${username}, personaliza tu préstamo`,
  loanDetails: 'Detalles del préstamo',
  terms: 'Plazos mensuales',
  titleMcapP1: '¡Tienes un préstamo pre aprobado para tu negocio!',
  titleMcapP2: `Adelanto de ventas Clip te ofrece préstamos para crecer tu negocio, 
    solicítalo aquí y págalo en cómodas mensualidades fijas. #Así de Fácil`,
  fixedPaymentMcap: 'Pago mensual fijo*',
  fixedPaymentDetailsMcap: (retention) => `*Se descontará el ${retention}% de tus ventas diarias 
    hasta cubrir el pago mensual fijo.`,
  videoTitleMcap: 'Un préstamo muy fácil de pagar',
  videoSubtitleMcap: 'Conoce como funcionan los pagos mensuales fijos y las retenciones de tus ventas en este vídeo.',
};
