import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Clevertap } from '../../../helpers/Clevertap';
import { ClipThemeProvider, theme } from '@clipmx/clip-storybook';
import { setAccountRep } from '../redux/berlinActions';
import RequestCardNavigation from './RequestCardNavigation';
import CardInfo from './CardInfo';
import BerlinSpinner from '../BerlinSpinner';
import Auth from '../Auth/authHOC';
import BottomNavigation from '../BottomNavigation';
import { BERLIN_CARD_REQUEST_TABS, BERLIN_FINANCIAL_SERVICE_PROVIDERS } from '../../../constants/AppConstants';

import text from './text';
import logger from '../../../helpers/logger';
import BerlinService from '../service';

export const ClipCard = (props) => {
  const { merchant, user, account } = props;
  const { financialShowRequestClipCard = false } = props?.flags ?? {};

  const [activeRequestTab, setActiveRequestTab] = useState(
    BERLIN_CARD_REQUEST_TABS.REQUEST_MENU,
  );
  const [activeCard, setActiveCard] = useState(false);

  const [servicesLoaded, setServicesLoaded] = useState(false);
  // Enrollment info
  const [requestedCard, setRequestedCard] = useState(false);
  const [enrollmentStatus, setEnrollmentStatus] = useState(null);
  const [enrollmentInfo, setEnrollmentInfo] = useState(null);
  // Account status
  const [accountStatus, setAccountStatus] = useState(null);
  const [accountHolder, setAccountHolder] = useState(null);

  useEffect(() => {
    if (accountStatus === null) getAccountStatus();
    if (accountStatus !== null && accountHolder === null) getAccountHolder();
    if (enrollmentInfo === null) getEnrollmentInfo();
    if (enrollmentInfo !== null && enrollmentStatus === null)
      getEnrollmentStatus();
    if (
      enrollmentInfo !== null &&
      enrollmentStatus !== null &&
      accountStatus !== null &&
      accountHolder !== null &&
      servicesLoaded === false
    ) {
      setServicesLoaded(true);
    }
  });

  useEffect(() => {
    const { user, merchantInfo } = props;
    if (merchantInfo.proxy_id && user) {
      Clevertap.profile(user);
    }
  }, [props.user, props.merchant]);

  const getEnrollmentStatus = async () => {
    try {
      const enrollmentStatus = await BerlinService.getMerchantEnrollmentStatusV2();
      setEnrollmentStatus(enrollmentStatus.data.message);
      setRequestedCard(true);
    } catch (error) {
      if (error.response.status === 404) {
        setEnrollmentStatus([]);
      } else {
        logger.info(error);
        store.addNotification({
          title: text.rejectedTitle,
          message: text.rejectedMessage,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            showIcon: true,
            duration: 0,
          },
        });
        setActiveRequestTab(BERLIN_CARD_REQUEST_TABS.REQUEST_MENU);
      }
    }
  };

  const getEnrollmentInfo = async () => {
    try {
      const enrollmentInfo = await BerlinService.getMerchantEnrollmentInfoV2();
      setEnrollmentInfo(enrollmentInfo.data.message);
    } catch (error) {
      if (error.response.status === 404) {
        setRequestedCard(false);
        setEnrollmentInfo([]);
      } else {
        logger.info(error);
      }
    }
  };

  const getAccountHolder = async () => {
    try {
      const accountHolder = await BerlinService.accountHolder(
        accountStatus.account_holder_id,
      );
      setAccountHolder(accountHolder.data.message);
    } catch (error) {
      logger.info(error);
    }
  };

  const getAccountStatus = async () => {
    try {
      const accountStatus = await BerlinService.accountInformationV2(
        merchant.info.id,
      );

      if (
        accountStatus.data.message.card_representation &&
        (accountStatus.data.message.card_representation.length > 1 ||
        accountStatus.data.message.card_representation[0].proxy_card ||
        accountStatus.data.message.card_representation[0].proxy_card_id)
        ) {
          setActiveCard(true);
          if ((accountStatus.data.message.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.SWAP)) {
            const swapResponse = await BerlinService.accountInformationV3({
                user_external_id: accountStatus.data.message.account_holder_representation.user_external_id,
                card_external_id: accountStatus.data.message.card_representation[0].proxy_card
              });
            accountStatus.data.message.card_representation[0].last_numbers=swapResponse.data.data.last_4;
          }
        }
      props.setAccountRep(accountStatus.data.message);
      setAccountStatus(accountStatus.data.message);
    } catch (error) {
      logger.info(error);
    }
  };

  const setPathFlow = (flow) => {
    switch (flow) {
      case 'REQUEST':
        return setActiveRequestTab(BERLIN_CARD_REQUEST_TABS.REQUEST_CARD);
      case 'TRACK':
        return setActiveRequestTab(BERLIN_CARD_REQUEST_TABS.TRACK_CARD);
      case 'ACTIVATE':
        return setActiveRequestTab(BERLIN_CARD_REQUEST_TABS.ACTIVATE_CARD);
      default:
        return;
    }
  };

  const renderRequestTab = (tab) => {
    switch (tab) {
      case BERLIN_CARD_REQUEST_TABS.REQUEST_MENU:
        return (
          <RequestCardNavigation
            financialShowRequestClipCard={financialShowRequestClipCard}
            requestedCard={requestedCard && accountStatus.card_representation.length === 1}
            setFlow={setPathFlow}
          />
        );
      default:
        return;
    }
  };

  const renderCardInfoTab = () => (
    <CardInfo
      user={user}
      account={account}
      cardRepresentation={accountStatus.card_representation[0]}
    />
  );

  return (
    <BottomNavigation>
      <ClipThemeProvider theme={theme}>
        {!servicesLoaded && <BerlinSpinner />}
        {(servicesLoaded && activeCard) && renderCardInfoTab()}
        {(servicesLoaded && !activeCard) && renderRequestTab(activeRequestTab)}
      </ClipThemeProvider>
    </BottomNavigation>
  );
};

ClipCard.propTypes = {
  user: PropTypes.object,
};

ClipCard.defaultProps = {
  user: {},
  merchant: {
    address: {},
    info: {
      id: '',
    },
  },
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    merchant: state.merchant,
    account: state.berlin.account_representation,
    merchantInfo: state.merchant.info,
  };
};
const mapDispatchToProps = {
  setAccountRep,
};

export default Auth(
  withLDConsumer()(
    connect(mapStateToProps, mapDispatchToProps)(ClipCard)
  )
);
