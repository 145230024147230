export default {
  show: 'Ver más',
  hide: 'Ocultar',
  messageHeader: (amount, totalAmount) => `
    Estás a un paso para recibir tu adelanto de <b>${amount}</b> (al final, el monto total a pagar es de
    ${totalAmount}).<br/>
    Necesitamos confirmar tus datos antes de que firmes tu contrato. Verifica tu cuenta completando el siguiente
    formulario.
  `,
  titleStepOne: '1. Completa tu perfil',
  titleStepTwo: '2. Revisamos tu solicitud',
  titleStepThree: '3. Recibes tu dinero',
  contentStepOne:
    'Llena el siguiente formulario en línea. Ten a la mano una identificación oficial y un comprobante de domicilio.',
  contentStepTwo:
    'Una vez aprobada, te llegará un correo donde podrás confirmar que aceptas tu Credi Clip firmando un pagaré.',
  contentStepThree:
    '¡Listo! Dentro de 3 días hábiles, abonamos el monto solicitado a tu cuenta CLABE registrada con Clip.',
};
