import config from 'config';

function isConsoleDefined() {
  return !!window.console;
}

function logWithQuality(quality, args) {
  if (config('env') === 'dev' && isConsoleDefined()) {
    console[quality].apply(console, args);
  }
}

export default {
  dir() {
    return logWithQuality('dir', arguments);
  },
  error() {
    return logWithQuality('error', arguments);
  },
  info() {
    return logWithQuality('info', arguments);
  },
  log() {
    return logWithQuality('log', arguments);
  },
  warn() {
    return logWithQuality('warn', arguments);
  },
};
