import { useHistory } from 'react-router-dom';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import { ClipThemeProvider, theme } from '@clipmx/clip-storybook';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Forms } from '../../../../constants/AppConstants';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import { isInvalidFormField } from '../../../../helpers/FormUtils';
import { required, isValidCard } from '../../../../helpers/validate';
import { setAccountRep } from '../../redux/berlinActions';
import { store } from 'react-notifications-component';
import { styles } from './styles';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import * as Crypto from '../../CryptoManager/CryptoManager';
import * as CryptoRequests from '../../CryptoManager/RequestsCryptoManager';
import BerlinService from '../../service';
import BerlinSpinner from '../../BerlinSpinner';
import Auth from '../../Auth/authHOC';
import BottomNavigation from '../../BottomNavigation';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { EVENTS } from '../../../../helpers/Clevertap';
import ClipCardBackIcon from '../../../../assets/images/berlin/clipCardBack.svg';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';
import FormControl from '@clipmx/clip-storybook/dist/components/FormControl';
import logger from '../../../../helpers/logger';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import OneStepNavigator from '../../Commons/OneStepNavigator';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import renderClipInput from '../../Commons/BerlinInputs/Input';
import text from './text';

const useStyles = makeStyles(styles);

export const ActivateCard = (props) => {
  let history = useHistory();
  const { cardNumber, merchant } = props;
  const classes = useStyles(props);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [accountStatus, setAccountStatus] = useState(null);

  useEffect(() => {
    if (accountStatus === null) getAccountStatus();
  });

  useEffect(() => {
    if (props.merchant) {
      BerlinService.accountInformationV2(props.merchant.info.id)
        .then((response) => {
          props.setAccountRep(response.data.message);
        })
        .catch(() => {
          console.log('error status');
        });
    }
  }, [props.merchant]);

  const getAccountStatus = async () => {
    try {
      const accountStatus = await BerlinService.accountInformationV2(
        merchant.info.id,
      );
      setAccountStatus(accountStatus.data.message);
    } catch (error) {
      logger.info(error);
    }
  };

  const activateCard = async () => {
    const {
      flags: { financialEncryptionAvailableWeb },
    } = props;

    try {
      let cardActivation;
      if (financialEncryptionAvailableWeb) {
        await Crypto.init();
        const serverKey = await CryptoRequests.GetServerKey(
          props.account_holder_id,
          Crypto.keys().publicKey.base64,
          Crypto.keys().iv.hex,
        );
        await Crypto.setServerPublicKey(serverKey);
        var enc = new TextEncoder();
        const encryptedData = await Crypto.encrypt(enc.encode(cardNumber));
        const payload = {
          card_number: encryptedData,
        };
        cardActivation = await BerlinService.physicalCardActivationV2(
          accountStatus.proxy_account_id,
          payload,
        );
      } else {
        const payload = {
          card_number: btoa(cardNumber),
        };
        cardActivation = await BerlinService.physicalCardActivation(
          accountStatus.proxy_account_id,
          payload,
        );
      }

      if (cardActivation.status === 200) {
        const accountInformation = await BerlinService.accountInformationV2(
          merchant.info.id,
        );
        setAccountStatus(accountInformation.data.message);
        history.goBack();
      }
    } catch (error) {
      logger.info(error);
      store.addNotification({
        title: text.rejectedTitle,
        message: text.rejectedMessage,
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          showIcon: true,
          duration: 0,
        },
      });
      setLoadingServices(false);
    }
  };

  useEffect(() => {
    enableNextButton();
  });

  const enableNextButton = () => {
    let nextStepDisabled = false;
    if (isInvalidFormField(cardNumber) || cardNumber.length < 16) {
      nextStepDisabled = true;
    }
    setButtonDisabled(nextStepDisabled);
  };

  return (
    <BottomNavigation>
      <ClipThemeProvider theme={theme}>
        <OneStepNavigator
          title={text.requestFlowTitle}
          stepBackFunction={() => history.goBack()}
        />
        {loadingServices ? (
          <BerlinSpinner />
        ) : (
          <div className={classes.container}>
            <ClevertapComponent
              renderComponent={<form />}
              events={[
                {
                  callbackName: 'onSubmit',
                  event: EVENTS.BERLIN.CARD.EVENT,
                  action: EVENTS.BERLIN.CARD.ACTIONS.ACTIVATE_INPUT_CONTINUED,
                },
              ]}
              className={classes.formContent}
              onSubmit={() => {
                setLoadingServices(true);
                activateCard();
              }}
            >
              <ClipTypography
                variant={'h3'}
                component={'h1'}
                fontWeight={'regular'}
                align={'left'}
                classes={{ h3: classes.typographyTitle }}
              >
                {text.title}
              </ClipTypography>
              <FormControl className={classes.formControl} fullWidth>
                <Field
                  name={'card_number'}
                  component={renderClipInput}
                  inputProps={{ maxLength: 16, minLength: 16 }}
                  type={'text'}
                  validate={[required, isValidCard]}
                  placeholder={text.streets}
                  className={classes.text}
                  fullWidth
                  adornmentIcon={
                    <div className={classes.adorment}>
                      <ClipTypography
                        variant={'caption'}
                        component={'p'}
                        fontWeight={'semiBold'}
                        classes={{ root: classes.helpText }}
                        onClick={() => setDisplayTooltip(true)}
                      >
                        {text.help}
                      </ClipTypography>
                      <HelpOutlineOutlinedIcon
                        onClick={() => setDisplayTooltip(true)}
                      />
                    </div>
                  }
                />
              </FormControl>
              <div className={classes.buttonContainer}>
                <ClipButton
                  variant={'contained'}
                  color={'primary'}
                  type={'submit'}
                  fullWidth
                  disabled={buttonDisabled}
                >
                  {text.submitButton}
                </ClipButton>
              </div>
            </ClevertapComponent>
            <ClipDrawer
              anchor={'bottom'}
              open={displayTooltip}
              classes={{ paper: classes.tooltipPaper }}
            >
              <div className={classes.tooltipContainer}>
                <ClipTypography
                  variant={'h3'}
                  component={'h2'}
                  align={'center'}
                  fontWeight={'semiBold'}
                >
                  {text.tooltipTitle}
                </ClipTypography>
                <img
                  src={ClipCardBackIcon}
                  alt={text.cardNumber}
                  className={classes.tooltipImage}
                />
                <ClipTypography
                  variant={'body1'}
                  component={'p'}
                  align={'left'}
                >
                  {text.tooltipAdvice}
                </ClipTypography>
                <div className={classes.tooltipButtonContainer}>
                  <ClipButton
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => setDisplayTooltip(false)}
                  >
                    {text.tooltipButton}
                  </ClipButton>
                </div>
              </div>
            </ClipDrawer>
          </div>
        )}
      </ClipThemeProvider>
    </BottomNavigation>
  );
};

ActivateCard.propTypes = {
  classes: PropTypes.object,
  setAccountStatus: PropTypes.func,
  merchantId: PropTypes.string,
};

ActivateCard.defaultProps = {
  classes: {},
  setAccountStatus: () => {},
  merchantId: '',
};

const ActivateCardForm = reduxForm({
  form: Forms.CARD_ACTIVATION,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(ActivateCard);
const selector = formValueSelector(Forms.CARD_ACTIVATION);

const mapStateToProps = (state) => {
  return {
    cardNumber: selector(state, 'card_number'),
    merchant: state.merchant,
    account_holder_id: state.berlin.account_representation.account_holder_id,
  };
};
const mapDispatchToProps = {
  setAccountRep,
};

export default Auth(
  withLDConsumer()(
    connect(mapStateToProps, mapDispatchToProps)(ActivateCardForm),
  ),
);
