import React from 'react';
import * as Styled from './styled';

const message1 = 'Espera un poco...';
const message2 = 'Un poquito más...';

export class BerlinSpinner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: message1,
    };
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ message: message2 });
    }, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return (
      <Styled.SpinnerContainer>
        <Styled.SpinnerText>{this.state.message}</Styled.SpinnerText>
        <Styled.LdsRing>
          <div />
          <div />
          <div />
          <div />
        </Styled.LdsRing>
      </Styled.SpinnerContainer>
    );
  }
}

export default BerlinSpinner;
