import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Auth from '../Auth/authHOC';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import Switch from '@clipmx/clip-storybook/dist/components/Switch';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';
import {
  ClipButton,
  ClipTypography,
  ClipIconButton,
  ClipLink,
} from '@clipmx/clip-storybook';
import {
  LooksOne,
  LooksTwo,
  HelpOutline,
  Clear,
} from '@clipmx/clip-storybook/dist/components/Icons';
import SwapLogo from '../../../assets/images/berlin/swap.svg';

import PrivacyPolicy from './PrivacyPolicy';
import Accordion from '../Commons/Accordion';
import { setAccountRep } from '../redux/berlinActions';
import BerlinService from '../service';
import OneStepNavigator from '../Commons/OneStepNavigator';
import { getConfigValue } from '../../../config/config';

import { ROUTES } from '../constants';
import { styles } from './styles';
import text from './text';

const useStyles = makeStyles(styles);

const UPGRADE_TABS = {
  INFO: 'INFO',
  PRIVACY: 'PRIVACY',
  BANK_INFO: 'BANK_INFO',
};

export const BerlinSwap = (props) => {
  let history = useHistory();
  const classes = useStyles(props);
  const {
    merchant,
    flags: { financialShowTandemPrivacyPolicyWeb, financialTandemFaqsWeb },
  } = props;

  const [swapLink, setSwapLink] = useState('');
  const [privacy, setPrivacy] = useState(false);
  const [openFaqs, setOpenFaqs] = useState(false);
  const [openBenefits, setOpenBenefits] = useState(false);
  const [activeUpgradeTab, setActiveUpgradeTab] = useState(UPGRADE_TABS.INFO);

  const handleSwitch = () => {
    setPrivacy(!privacy);
  };

  const getSwapLink = async (proxyAccount) => {
    const body = {
      merchant_id: proxyAccount,
    };

    try {
      const response = await BerlinService.getSwapLink(body);
      if (response.status === 200) {
        setSwapLink(response.data.result.data.link);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const getAccountInfo = async () => {
    try {
      const accountInfo = await BerlinService.accountInformationV2(
        merchant.info.id,
      );
      return accountInfo.data.message.proxy_account_id;
    } catch (e) {
      return '';
    }
  };

  const renderUpgradeScreen = (tab) => {
    switch (tab) {
      case UPGRADE_TABS.INFO:
      default:
        return (
          <>
            <div>
              <ClipTypography variant={'h3'} fontWeight={'regular'}>
                {text.title}
              </ClipTypography>
              <ClipTypography
                variant={'body1'}
                component={'p'}
                fontWeight={'regular'}
                className={classes.content}
              >
                {text.limit}
                <span className={classes.color2}>{text.limitAmount}</span>
              </ClipTypography>
              <ClipTypography
                variant={'body1'}
                component={'p'}
                fontWeight={'regular'}
                className={classes.content}
              >
                <span className={classes.color}>{text.swap}</span>
                {text.content}
                <span className={classes.color2}>{text.cartera}</span>
                <ClipIconButton
                  onClick={() => setOpenBenefits(true)}
                  className={classes.helpIconButton}
                >
                  <HelpOutline color={'primary'} className={classes.helpIcon} />
                </ClipIconButton>
              </ClipTypography>
              <ClipTypography
                variant={'body1'}
                component={'p'}
                fontWeight={'medium'}
              >
                {text.nextStep}
              </ClipTypography>
              <div className={classes.iconsContainer}>
                <div className={classes.iconsDiv}>
                  <div className={classes.iconDiv}>
                    <LooksOne className={classes.icons} />
                  </div>
                  <ClipTypography
                    variant={'subtitle1'}
                    component={'p'}
                    fontWeight={'regular'}
                  >
                    {text.download}
                  </ClipTypography>
                  <img src={SwapLogo} className={classes.swapLogo} />
                </div>
                <div className={classes.iconsDiv}>
                  <div className={classes.iconDiv}>
                    <LooksTwo className={classes.icons} />
                  </div>
                  <ClipTypography
                    variant={'subtitle1'}
                    component={'p'}
                    fontWeight={'regular'}
                  >
                    {text.complete}
                  </ClipTypography>
                </div>
                <ClipLink
                  underline={'always'}
                  onClick={() => setOpenFaqs(true)}
                  variant={'body1'}
                >
                  {text.questions}
                </ClipLink>
              </div>
            </div>
            <div>
              {financialShowTandemPrivacyPolicyWeb && (
                <div className={classes.privacy}>
                  <ClipTypography variant={'body2'}>
                    {text.read}
                    <ClipLink
                      underline={'always'}
                      variant={'body2'}
                      onClick={() => setActiveUpgradeTab(UPGRADE_TABS.PRIVACY)}
                    >
                      {text.privacy}
                    </ClipLink>
                  </ClipTypography>
                  <Switch
                    checked={privacy}
                    onChange={handleSwitch}
                    color={'primary'}
                  />
                </div>
              )}
              <ClipButton
                variant={'contained'}
                color={'primary'}
                href={swapLink}
                target="_blank"
                disabled={privacy !== financialShowTandemPrivacyPolicyWeb}
                fullWidth
              >
                {text.btn}
              </ClipButton>
              <ClipButton
                color={'secondary'}
                onClick={() => setActiveUpgradeTab(UPGRADE_TABS.BANK_INFO)}
                fullWidth
              >
                {text.useClabe}
              </ClipButton>
            </div>
          </>
        );
      case UPGRADE_TABS.PRIVACY:
        return <PrivacyPolicy />;
      case UPGRADE_TABS.BANK_INFO:
        return (
          <>
            <div>
              <ClipTypography variant={'h3'} fontWeight={'regular'}>
                {text.bankTitle}
              </ClipTypography>
              <div className={classes.bankTextContainer}>
                <ClipTypography variant={'body1'} component={'p'}>
                  {text.bankText1}
                </ClipTypography>
                <ClipTypography
                  variant={'body1'}
                  component={'p'}
                  className={classes.bankText}
                >
                  {text.bankText2}
                </ClipTypography>
                <ClipTypography
                  variant={'body1'}
                  component={'p'}
                  fontWeight={'semiBold'}
                  className={classes.bankText}
                >
                  {text.bankText3}
                  <ClipIconButton
                    onClick={() => setOpenBenefits(true)}
                    className={classes.helpIconButton}
                  >
                    <HelpOutline
                      color={'primary'}
                      className={classes.helpIcon}
                    />
                  </ClipIconButton>
                </ClipTypography>
                <ClipTypography
                  variant={'body1'}
                  component={'p'}
                  className={classes.bankText}
                >
                  {text.bankText4}
                </ClipTypography>
                <ClipTypography
                  variant={'body1'}
                  component={'p'}
                  className={classes.bankText}
                >
                  {text.bankText5}
                </ClipTypography>
              </div>
            </div>
            <div>
              <ClipTypography
                fontWeight={'semiBold'}
                align={'center'}
                className={classes.bankContinueText}
              >
                {text.bankContinue}
              </ClipTypography>
              <ClipButton
                variant={'contained'}
                color={'primary'}
                href={text.bankHrefButton}
                target="_blank"
                fullWidth
              >
                {text.bankContinueButton}
              </ClipButton>
              <ClipButton
                color={'secondary'}
                onClick={() => setActiveUpgradeTab(UPGRADE_TABS.INFO)}
                fullWidth
              >
                {text.bankBackButton}
              </ClipButton>
            </div>
          </>
        );
    }
  };

  useEffect(async () => {
    if (merchant.info.id) {
      const proxyAccount = await getAccountInfo();
      await getSwapLink(proxyAccount);
    }
  }, [merchant.info.id]);

  return (
    <>
      <OneStepNavigator
        title={text.header}
        stepBackFunction={
          activeUpgradeTab === UPGRADE_TABS.INFO
            ? history.replace
            : setActiveUpgradeTab
        }
        stepBackTarget={
          activeUpgradeTab === UPGRADE_TABS.INFO
            ? ROUTES.ACCOUNT
            : UPGRADE_TABS.INFO
        }
      />
      <div className={classes.container}>
        {renderUpgradeScreen(activeUpgradeTab)}
      </div>
      <ClipDrawer
        onClose={() => {
          setOpenBenefits(false);
        }}
        classes={{ root: classes.drawer, paper: classes.paper }}
        anchor="bottom"
        open={openBenefits}
      >
        <ClipTypography
          variant="h3"
          fontWeight="semiBold"
          gutterBottom
          align="center"
        >
          {text.benefitsTitle}
        </ClipTypography>
        <ul>
          {text.benefitsBody.map((benefit) => (
            <li className={classes.listItem}>{benefit}</li>
          ))}
        </ul>
        <ClipButton
          variant="outlined"
          color="secondary"
          onClick={() => {
            setOpenBenefits(false);
          }}
        >
          {text.benefitsBtn}
        </ClipButton>
      </ClipDrawer>

      <ClipDrawer
        onClose={() => {
          setOpenFaqs(false);
        }}
        classes={{ root: classes.drawer, paper: classes.paper }}
        anchor="bottom"
        open={openFaqs}
      >
        <Clear
          onClick={() => {
            setOpenFaqs(false);
          }}
        />
        <ClipTypography
          variant="h3"
          fontWeight="semiBold"
          gutterBottom
          align="center"
        >
          {text.faqsTitle}
        </ClipTypography>
        <Accordion
          data={financialTandemFaqsWeb.faqs}
          showIndex={false}
          strong={false}
        />
      </ClipDrawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  merchant: state.merchant,
});
const mapDispatchToProps = {
  setAccountRep,
};

export default Auth(
  withLDConsumer()(connect(mapStateToProps, mapDispatchToProps)(BerlinSwap)),
);
