export const CHANGE_FLAG_VALUE = 'CHANGE_FLAG_VALUE';
export const CHANGE_FLAGS_VALUE = 'CHANGE_FLAGS_VALUE';

export function changeLDFlagValue(flag, value) {
  return {
    type: CHANGE_FLAG_VALUE,
    payload: {
      flag,
      value,
    },
  };
}

export function changeLDFlags(flags) {
  return {
    type: CHANGE_FLAGS_VALUE,
    payload: {
      flags,
    },
  };
}
