export default {
  newCode: 'Genera tu clave de acceso',
  confirmCode: 'Confirma tu clave',
  enterPassword: 'Ingresa la contraseña de tu cuenta Clip',
  passwordField: 'Contraseña Clip',
  passwordError: 'Contraseña inválida. Inténtalo de nuevo.',
  continueButton: 'Continuar',
  startButton: 'Comenzar',
  infoTitle: 'Protege tu cuenta',
  infoText:
    'Genera una clave de acceso para que solo tú puedas ingresar a tu Cartera Clip.',
  done: '¡Listo!',
  secureClipCard:
    'Te pediremos esta clave cada vez que necesites entrar a tu Cartera Clip.',
  finalize: 'Finalizar',
  hello: '¡Hola',
  enterCode: 'Ingresa tu clave de acceso',
  error406: 'No coincide, inténtalo de nuevo.',
  forgotCda: 'Olvidé mi clave de acceso',
  changeCda: 'Genera una nueva clave',
  recoverCdaSuccess: 'Cambiaste tu clave de acceso',
  navigatorTitle: 'Olvidé mi clave de acceso',
  bottomText: 'Operada por',
  redirectText1: 'Te estamos llevando a',
  redirectText2: 'swap.',
};
