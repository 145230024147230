import React, { useState, useRef, useEffect } from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lottie from 'lottie-react';
import 'react-notifications-component/dist/theme.css';
import imageCompression from 'browser-image-compression';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

import BerlinFileInput from '../../Commons/BerlinFileInput';
import OneStepNavigator from '../../Commons/OneStepNavigator';
import AcceptPrivacy from '../AcceptPrivacy';
import ProgressNavigator from '../../Commons/ProgressNavigator';
import PrivacyPolicy from '../PrivacyPolicy';
import { Forms } from '../../../../constants/AppConstants';
import IneFrontAnimation from '../../../../assets/images/berlin/lottie/IneFrontAnimation/data.json';
import IneBackAnimation from '../../../../assets/images/berlin/lottie/IneBackAnimation/data.json';
import PassportFrontAnimation from '../../../../assets/images/berlin/lottie/PassportFrontAnimation/data.json';
import ClevertapComponent from '../.././../../helpers/Clevertap/ClevertapComponent';
import {EVENTS, Clevertap} from '../.././../../helpers/Clevertap';
import text from './text';
import styles from './styles';
const useStyles = makeStyles(styles);

export const IdentificationsPhoto = (props) => {
  const {
    dispatch,
    ineFront,
    ineBack,
    passFront,
    handleSubmit,
    startAccountCreation,
    navStep,
    totalStep,
    user,
    prevStep,
    nextStep,
  } = props;

  const STEPS = {
    IDPHOTOSTART: 'IDPHOTOSTART',
    INEFRONT: 'INEFRONT',
    INEBACK: 'INEBACK',
    PASSPORT: 'PASSWORD',
    CONTINUE: 'CONTINUE',
    TERMS: 'TERMS',
    PRIVACY: 'PRIVACY',
    CONTRACT: 'CONTRACT',
  };
  const [termsAccepted, setTermsAccepted] = useState({
    contract: false,
    terms: false,
    geo: false,
    data: false,
  });
  const handleChange = (name) => (event) => {
    if (name === 'contract') {
      const value = event.target.checked;
      setTermsAccepted({
        contract: value,
        terms: value,
        geo: value,
        data: value,
      });
      return;
    }
    setTermsAccepted({ ...termsAccepted, [name]: event.target.checked });
  };
  const classes = useStyles(props);

  const [step, setStep] = useState(STEPS.IDPHOTOSTART);
  const [disableCreateButton, setDisableCreateButton] = useState(true);

  const fieldRef = useRef();

  const clickInput = () => {
    fieldRef.current.click();
  };

  const clearIneFront = () => {
    dispatch(change(Forms.BERLIN_REGISTRATION, 'ineFront', null));
  };

  const clearIneBack = () => {
    dispatch(change(Forms.BERLIN_REGISTRATION, 'ineBack', null));
  };

  const clearPassFront = () => {
    dispatch(change(Forms.BERLIN_REGISTRATION, 'passFront', null));
  };

  const selectIne = () => {
    clearPassFront();
    setStep(STEPS.INEFRONT);
  };
  const selectPassport = () => {
    clearIneFront();
    clearIneBack();
    setStep(STEPS.PASSPORT);
  };

  const setStepBack = (step) => {
    setStep(step);
  };

  useEffect(() => {
    if (ineBack) {
      Clevertap.event(
        EVENTS.BERLIN.ONBOARDING.EVENT,
        EVENTS.BERLIN.ONBOARDING.ACTIONS.ID_BACK_CHECK_VIEWED,
        );
    }
  }, [ineBack]);

  useEffect(() => {
    if (ineFront || passFront) {
      Clevertap.event(
        EVENTS.BERLIN.ONBOARDING.EVENT,
        EVENTS.BERLIN.ONBOARDING.ACTIONS.ID_FRONT_CHECK_VIEWED,
        );
    }
  }, [ineFront, passFront]);

  const completeSubmit = async (values) => {
    const files = [];
    const options = {
      maxSizeMB: 2,
    };
    const frontFileName = 'front';
    const backFileName = 'back';
    const bytesInMb = 1048576;
    let dir = null;

    if (ineFront) {
      dir = 'INE';
      if (ineFront.size / bytesInMb > 2) {
        const compressedFront = await imageCompression(ineFront, options);
        files.push({ file: compressedFront, name: frontFileName });
      } else {
        files.push({ file: ineFront, name: frontFileName });
      }
      if (ineBack.size / bytesInMb > 2) {
        const compressedBack = await imageCompression(ineBack, options);
        files.push({ file: compressedBack, name: backFileName });
      } else {
        files.push({ file: ineBack, name: backFileName });
      }
    } else if (passFront) {
      dir = 'PAS';
      if (passFront.size / bytesInMb > 2) {
        const compressedFront = await imageCompression(passFront, options);
        files.push({ file: compressedFront, name: frontFileName });
      } else {
        files.push({ file: passFront, name: frontFileName });
      }
    }
    Clevertap.event(
      EVENTS.BERLIN.ONBOARDING.EVENT,
      EVENTS.BERLIN.ONBOARDING.ACTIONS.CONTRACT_CREATE_ACCOUNT_CLICKED,
      );
    startAccountCreation(values, files, dir);
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.IDPHOTOSTART:
        return (
          <>
            <ProgressNavigator
              progress={navStep}
              total={totalStep}
              handleClick={() => {
                prevStep();
              }}
            />
            <div className={classes.container}>
              <ClipTypography variant="h3" className={classes.title}>
                {text.letsTake}
              </ClipTypography>
              <ClipTypography className={classes.headline}>
                {text.thisHelps}
              </ClipTypography>
              <div className={classes.buttonContainer}>
                <ClevertapComponent
                  renderComponent={<ClipButton />}
                  events={[
                    {
                      callbackName: 'onClick',
                      event: EVENTS.BERLIN.ONBOARDING.EVENT,
                      payload: { 
                        selection: 'INE',
                      },
                      action: EVENTS.BERLIN.ONBOARDING.ACTIONS.SELECTID_VIEWED,
                    },
                    {
                      callbackName: 'onClick',
                      event: EVENTS.BERLIN.ONBOARDING.EVENT,
                      action: EVENTS.BERLIN.ONBOARDING.ACTIONS.ID_FRONT_VIEWED,
                    },
                  ]}
                  variant="outlined"
                  color="primary"
                  className={classes.whiteButton}
                  onClick={() => {
                    nextStep();
                    selectIne();
                  }}
                  >
                    {text.ineButton}
                </ClevertapComponent>
                <ClevertapComponent
                  renderComponent={<ClipButton />}
                  events={[
                    {
                      callbackName: 'onClick',
                      event: EVENTS.BERLIN.ONBOARDING.EVENT,
                      payload: { 
                        selection: 'Passport',
                      },
                      action: EVENTS.BERLIN.ONBOARDING.ACTIONS.SELECTID_VIEWED,
                    },
                    {
                      callbackName: 'onClick',
                      event: EVENTS.BERLIN.ONBOARDING.EVENT,
                      action: EVENTS.BERLIN.ONBOARDING.ACTIONS.ID_FRONT_VIEWED,
                    },
                  ]}
                  variant="outlined"
                  color="primary"
                  className={classes.whiteButton}
                  onClick={() => {
                    nextStep();
                    selectPassport();
                  }}
                  >
                    {text.passportButton}
                </ClevertapComponent>
              </div>
            </div>
          </>
        );
      case STEPS.INEFRONT:
        return (
          <>
            <ProgressNavigator
              progress={navStep}
              total={totalStep}
              handleClick={() => {
                prevStep();
                setStep(STEPS.IDPHOTOSTART);
              }}
            />
            <div className={classes.container}>
              {ineFront ? (
                <>
                  <ClipTypography variant="h3" className={classes.title}>
                    {text.verifyIneFront}
                  </ClipTypography>
                  <ul className={classes.listMargins}>
                    <li>
                      <ClipTypography className={classes.noBottomMargin}>
                        {text.info1}
                      </ClipTypography>
                    </li>
                    <li>
                      <ClipTypography className={classes.noBottomMargin}>
                        {text.info2}
                      </ClipTypography>
                    </li>
                    <li>
                      <ClipTypography className={classes.noBottomMargin}>
                        {text.info3}
                      </ClipTypography>
                    </li>
                  </ul>
                  <img
                    className={classes.inePicture}
                    alt="Frente INE"
                    src={URL.createObjectURL(ineFront)}
                  />
                  <div className={classes.twinButtonContainer}>
                    <ClevertapComponent
                      renderComponent={<ClipButton />}
                      events={[
                        {
                          callbackName: 'onClick',
                          event: EVENTS.BERLIN.ONBOARDING.EVENT,
                          action: EVENTS.BERLIN.ONBOARDING.ACTIONS.ID_FRONT_CHECK_RETRY,
                        },
                      ]}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        clearIneFront();
                      }}
                      >
                        {text.retry}
                    </ClevertapComponent>
                    <ClevertapComponent
                      renderComponent={<ClipButton />}
                      events={[
                        {
                          callbackName: 'onClick',
                          event: EVENTS.BERLIN.ONBOARDING.EVENT,
                          action: EVENTS.BERLIN.ONBOARDING.ACTIONS.ID_FRONT_CHECK_CONTINUED,
                        },
                        {
                          callbackName: 'onClick',
                          event: EVENTS.BERLIN.ONBOARDING.EVENT,
                          action: EVENTS.BERLIN.ONBOARDING.ACTIONS.ID_BACK_VIEWED,
                        },
                      ]}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        nextStep();
                        setStep(STEPS.INEBACK);
                      }}
                      >
                        {text.continue}
                      </ClevertapComponent>
                  </div>
                </>
              ) : (
                <>
                  <ClipTypography variant="h3" className={classes.title}>
                    {text.takeFrontIne}
                  </ClipTypography>
                  <ClipTypography className={classes.helperText}>
                    {text.makeSure}
                  </ClipTypography>
                  <Lottie
                    animationData={IneFrontAnimation}
                    className={classes.lottieAnimation}
                  />
                  <ClipButton
                    variant="contained"
                    color="primary"
                    className={classes.continueButton}
                    onClick={() => clickInput()}
                  >
                    {text.understood}
                  </ClipButton>
                  <Field
                    refName={fieldRef}
                    name="ineFront"
                    component={BerlinFileInput}
                  />
                </>
              )}
            </div>
          </>
        );
      case STEPS.INEBACK:
        return (
          <>
            <ProgressNavigator
              progress={navStep}
              total={totalStep}
              handleClick={() => {
                prevStep();
                setStep(STEPS.INEFRONT);
              }}
            />
            <div className={classes.container}>
              {ineBack ? (
                <>
                  <ClipTypography variant="h3" className={classes.title}>
                    {text.verifyIneBack}
                  </ClipTypography>
                  <ul className={classes.listMargins}>
                    <li>
                      <ClipTypography className={classes.noBottomMargin}>
                        {text.info1_back}
                      </ClipTypography>
                    </li>
                    <li>
                      <ClipTypography className={classes.noBottomMargin}>
                        {text.info2}
                      </ClipTypography>
                    </li>
                    <li>
                      <ClipTypography className={classes.noBottomMargin}>
                        {text.info3}
                      </ClipTypography>
                    </li>
                  </ul>
                  <img
                    className={classes.inePicture}
                    alt="Reverso INE"
                    src={URL.createObjectURL(ineBack)}
                  />
                  <div className={classes.twinButtonContainer}>
                    <ClevertapComponent
                      renderComponent={<ClipButton />}
                      events={[
                        {
                          callbackName: 'onClick',
                          event: EVENTS.BERLIN.ONBOARDING.EVENT,
                          action: EVENTS.BERLIN.ONBOARDING.ACTIONS.ID_BACK_CHECK_RETRY,
                        },
                      ]}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        clearIneBack();
                      }}
                      >
                        {text.retry}
                    </ClevertapComponent>
                    <ClevertapComponent
                      renderComponent={<ClipButton />}
                      events={[
                        {
                          callbackName: 'onClick',
                          event: EVENTS.BERLIN.ONBOARDING.EVENT,
                          action: EVENTS.BERLIN.ONBOARDING.ACTIONS.ID_BACK_CHECK_CONTINUED,
                        },
                        {
                          callbackName: 'onClick',
                          event: EVENTS.BERLIN.ONBOARDING.EVENT,
                          action: EVENTS.BERLIN.ONBOARDING.ACTIONS.CONTRACT_VIEWED,
                        },
                      ]}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        nextStep();
                        setStep(STEPS.CONTINUE);
                      }}
                      >
                        {text.continue}
                      </ClevertapComponent>
                  </div>
                </>
              ) : (
                <>
                  <ClipTypography variant="h3" className={classes.title}>
                    {text.takeBackIne}
                  </ClipTypography>
                  <ClipTypography className={classes.helperText}>
                    {text.makeSure}
                  </ClipTypography>
                  <Lottie
                    animationData={IneBackAnimation}
                    className={classes.lottieAnimation}
                  />
                  <ClipButton
                    variant="contained"
                    color="primary"
                    className={classes.continueButton}
                    onClick={() => clickInput()}
                  >
                    {text.understood}
                  </ClipButton>
                  <Field
                    refName={fieldRef}
                    name="ineBack"
                    component={BerlinFileInput}
                  />
                </>
              )}
            </div>
          </>
        );
      case STEPS.PASSPORT:
        return (
          <>
            <ProgressNavigator
              progress={navStep}
              total={totalStep}
              handleClick={() => {
                prevStep();
                setStep(STEPS.IDPHOTOSTART);
              }}
            />

            <div className={classes.container}>
              {passFront ? (
                <>
                  <ClipTypography variant="h3" className={classes.title}>
                    {text.verifyPassportFront}
                  </ClipTypography>
                  <ul className={classes.listMargins}>
                    <li>
                      <ClipTypography className={classes.noBottomMargin}>
                        {text.info1}
                      </ClipTypography>
                    </li>
                    <li>
                      <ClipTypography className={classes.noBottomMargin}>
                        {text.info2}
                      </ClipTypography>
                    </li>
                    <li>
                      <ClipTypography className={classes.noBottomMargin}>
                        {text.info3}
                      </ClipTypography>
                    </li>
                  </ul>
                  <img
                    className={classes.inePicture}
                    alt="Frente pasaporte"
                    src={URL.createObjectURL(passFront)}
                  />
                  <div className={classes.twinButtonContainer}>
                    <ClevertapComponent
                      renderComponent={<ClipButton />}
                      events={[
                        {
                          callbackName: 'onClick',
                          event: EVENTS.BERLIN.ONBOARDING.EVENT,
                          action: EVENTS.BERLIN.ONBOARDING.ACTIONS.ID_FRONT_CHECK_RETRY,
                        },
                      ]}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        clearPassFront();
                      }}
                      >
                        {text.retry}
                    </ClevertapComponent>
                    <ClevertapComponent
                      renderComponent={<ClipButton />}
                      events={[
                        {
                          callbackName: 'onClick',
                          event: EVENTS.BERLIN.ONBOARDING.EVENT,
                          action: EVENTS.BERLIN.ONBOARDING.ACTIONS.ID_BACK_CHECK_CONTINUED,
                        },
                        {
                          callbackName: 'onClick',
                          event: EVENTS.BERLIN.ONBOARDING.EVENT,
                          action: EVENTS.BERLIN.ONBOARDING.ACTIONS.CONTRACT_VIEWED,
                        },
                      ]}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        nextStep();
                        setStep(STEPS.CONTINUE);
                      }}
                      >
                        {text.continue}
                      </ClevertapComponent>
                  </div>
                </>
              ) : (
                <>
                  <ClipTypography variant="h3" className={classes.title}>
                    {text.takePassportFront}
                  </ClipTypography>
                  <ClipTypography className={classes.helperText}>
                    {text.makeSure}
                  </ClipTypography>
                  <Lottie
                    animationData={PassportFrontAnimation}
                    className={classes.lottieAnimation}
                  />
                  <ClipButton
                    variant="contained"
                    color="primary"
                    className={classes.continueButton}
                    onClick={() => clickInput()}
                  >
                    {text.understood}
                  </ClipButton>
                  <Field
                    refName={fieldRef}
                    name="passFront"
                    component={BerlinFileInput}
                  />
                </>
              )}
            </div>
          </>
        );
      case STEPS.CONTINUE:
        return (
          <>
            <ProgressNavigator
              progress={totalStep}
              total={totalStep}
              handleClick={() => {
                prevStep();
                setStep(ineFront ? STEPS.INEBACK : STEPS.PASSPORT);
              }}
            />
            <div className={classes.container}>
              <AcceptPrivacy
                steps={STEPS}
                setStep={setStep}
                termsAccepted={termsAccepted}
                handleChange={handleChange}
                documentType={ineFront ? 'ine' : 'passport'}
                disableCreateButton={disableCreateButton}
                setDisableCreateButton={setDisableCreateButton}
              />
              <ClipButton
                variant="contained"
                color="primary"
                disabled={disableCreateButton}
                className={classes.continueButton}
                type="submit"
              >
                {text.createAccount}
              </ClipButton>
            </div>
          </>
        );
      case STEPS.PRIVACY:
        return (
          <>
            <ProgressNavigator
              handleClick={() => {
                setStep(STEPS.CONTINUE);
              }}
            />
            <PrivacyPolicy />
          </>
        );
      case STEPS.CONTRACT:
        return (
          <>
            <OneStepNavigator
              title={text.contract}
              stepBackFunction={setStep}
              stepBackTarget={STEPS.CONTINUE}
            />
            <div className={classes.contractPictures}>
              {Array.from({ length: 18 }, (_, i) => i).map((i) => {
                return (
                  <img
                    src={`/images/berlin/cacaoContract/cacao-contract-${
                      i + 1
                    }.jpg`}
                    alt={'Cacao Contract'}
                  />
                );
              })}
            </div>
          </>
        );
      default:
        return;
    }
  };

  return <form onSubmit={handleSubmit(completeSubmit)}>{renderStep()}</form>;
};

const selector = formValueSelector(Forms.BERLIN_REGISTRATION);

const mapStateToProps = (state) => {
  return {
    ineFront: selector(state, 'ineFront'),
    ineBack: selector(state, 'ineBack'),
    passFront: selector(state, 'passFront'),
  };
};

IdentificationsPhoto.propTypes = {
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  startAccountCreation: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  nextStep: PropTypes.func,
};
IdentificationsPhoto.defaultProps = {
  dispatch: () => {},
  handleSubmit: () => {},
  startAccountCreation: () => {},
  setActiveTab: () => {},
  nextStep: () => {},
};

const ReduxIdentificationsPhoto = reduxForm(
  {
    form: Forms.BERLIN_REGISTRATION,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
  },
  mapStateToProps,
)(IdentificationsPhoto);

export default connect(mapStateToProps)(ReduxIdentificationsPhoto);
