import axios from 'axios';
import { change as updateFormField } from 'redux-form';

import { app } from '../config/config';
import { Forms } from '../constants/AppConstants';
import apiPaths from '../helpers/apiPaths';
import { getRequestOptions } from '../helpers/fetchUtil';
import logger from '../helpers/logger';
import getText from './Actions.text';
import { logoutUser } from '../components/auth/redux/actions'
import {
  FETCHING_MERCHANT_INFO,
  UPDATE_MERCHANT,
  UPDATE_META_DATA,
  MERCHANT_ERROR,
  SET_MERCHANT_COLONIES,
} from './Types';

export function clearColonies() {
  return { type: SET_MERCHANT_COLONIES, payload: [] };
}

export function getMerchantInfo() {
  return (dispatch) => {
    dispatch({ type: FETCHING_MERCHANT_INFO, payload: { loading: true } });
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.merchantInfo}`;

    axios
      .get(url, options)
      .then((response) => {
        dispatch({ type: FETCHING_MERCHANT_INFO, payload: { loading: false } });
        dispatch({ type: UPDATE_MERCHANT, payload: response.data });

        if (response.data.address.postal_code)
          dispatch(getColonies(response.data.address.postal_code));
      })
      .catch(err => {
        if (err.response.status === 401) {
          dispatch(logoutUser())
        }
        logger.error('error getting merchant info, response:', err.response);
        dispatch({ type: FETCHING_MERCHANT_INFO, payload: { loading: false } });
        dispatch({ type: MERCHANT_ERROR, payload: 'Server error.' });
      });
  };
}

export function getMetaData() {
  return (dispatch) => {
    const textForms = getText().forms;
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.registrationMetaData}`;

    axios
      .get(url, options)
      .then((response) => {
        const industries = [];
        let others = {};

        response.data.industries.forEach(({ id, name }) => {
          if (name === 'Otros') {
            others = { id, name: textForms.businessOther, other: true };
          } else {
            industries.push({ id, name });
          }
        });

        industries.push(others);
        response.data.industries = industries;

        dispatch({ type: UPDATE_META_DATA, payload: response.data });
      })
      .catch((err) => {
        logger.error('error getting merchant info, response:', err.response);
        dispatch({ type: MERCHANT_ERROR, payload: 'Server error.' });
      });
  };
}

export function updateMerchantState(state) {
  return (dispatch) => {
    dispatch({ type: UPDATE_MERCHANT, payload: { merchant: { state } } });
  };
}

export function saveMerchantInfo(payload) {
  return (dispatch) => {
    const options = getRequestOptions();
    const request = axios.create(options);
    const url = `${app.apiUrl}${apiPaths.editMerchantInfo}`;

    return request
      .post(url, payload)
      .then((response) => {
        dispatch({ type: UPDATE_MERCHANT, payload: response.data });

        return response.data;
      })
      .catch((err) => {
        logger.error('error saving merchant info: ', err);
        return Promise.reject(err);
      });
  };
}

export function getColonies(zipcode) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.zipcodes}${zipcode}`;

    return axios
      .get(url, options)
      .then((response) => {
        dispatch(setColonies(response.data.mexico_zipcodes));
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function setColonies(payload) {
  return (dispatch) => {
    const location = {
      zipcode: '',
      municipality: '',
      state: '',
      colonies: [],
    };

    payload.forEach(({ zipcode, municipality, state, colony }) => {
      location.zipcode = zipcode;
      location.municipality = municipality;
      location.state = state;
      location.colonies.push({ id: colony, name: colony });
    });

    if (location.colonies.length > 0) {
      dispatch({ type: SET_MERCHANT_COLONIES, payload: location.colonies });
      dispatch(updateFormField(Forms.EDIT_MERCHANT_INFO_FORM, 'colony', null));
    }

    dispatch(
      updateFormField(
        Forms.EDIT_MERCHANT_INFO_FORM,
        'municipality',
        location.municipality,
      ),
    );
    dispatch(
      updateFormField(Forms.EDIT_MERCHANT_INFO_FORM, 'state', location.state),
    );
  };
}
