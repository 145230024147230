export default {
  copySuccessNotification: 'Copiado',
  copyAllGreeting: '¡Hola! estos son los datos de mi Cartera Clip:',
  name: 'Nombre',
  clabe: 'CLABE',
  bank: 'Banco',
  myAccountTitle: 'Mi Cuenta',
  changeNipText: 'Por seguridad, te recomendamos generar un nuevo NIP.',
  security: 'Seguridad',
  dialogTitle: 'Una mala y una buena noticia.',
  cancelDialog: 'Cancelar',
  okDialog: 'Continuar',
  accountHolder: (name, lastName, secondLastName) =>
    `${name} ${lastName} ${secondLastName === null ? '' : secondLastName}`,
  infoGreeting: (name) => `Hola ${name}`,
  lastSession: (date) => `Última sesión: ${date}`,
  accountInfo: 'Información de tu cuenta',
  settings: 'Configuración de cuenta',
  changeCDA: 'Cambiar clave de acceso',
  name: 'Nombre',
  clabe: 'Cuenta CLABE',
  bank: 'Banco',
  copyAllButton: 'Copiar todo',
  passwordError: 'Contraseña inválida. Inténtalo de nuevo.',
  passwordField: 'Contraseña Clip',
  enterPassword: 'Ingresa la contraseña de tu cuenta Clip',
  submitButton: 'Continuar',
  newCode: 'Genera tu clave de acceso',
  confirmCode: 'Confirma tu clave',
  recoverCdaSuccess: 'Cambiaste tu clave de acceso',
  tooltipTitle: 'Límite de depósitos',
  amountN2: '$18,000',
  amountN3: '$500,000',
  tooltipAdvice: (amount) =>
    `Tu cuenta digital tiene un límite de depósitos de ${amount} cada mes.`,
  tooltipAdvice2: (
    amount,
  ) => `En caso de que los depósitos a tu cuenta superen los ${amount} mensuales,
  retendremos la cantidad adicional y la depositaremos en tu cuenta el día 1 del siguiente mes.`,
  tooltipButton: 'Entendido',
  increase: 'Aumentar',
  showRequest: 'Ver solicitud',
  toast:
    'Listo merchant_name, tu límite de depósitos mensual ahora es de $500 mil pesos. 🤑💸',
    beneficiaries: 'Beneficiarios',
    changePass: 'Cambiar contraseña',
    accountStatus: 'Estados de cuenta',
};
