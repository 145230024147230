export default {
  title: 'Completa tu dirección',
  street: 'Calle',
  number: 'Número exterior',
  interior: 'Número interior (opcional)',
  zipCode: 'Código Postal',
  colony: 'Colonia',
  municipality: 'Delegación o municipio',
  city: 'Ciudad',
  state: 'Estado',
  continue: 'Continuar',
};
