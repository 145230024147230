import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { ClipTypography } from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import ClipGrid from '@clipmx/clip-storybook/dist/components/Grid';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipCard from '@clipmx/clip-storybook/dist/components/Card';
import ClipDivider from '@clipmx/clip-storybook/dist/components/Divider';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import PrestaClipActiveIcon from 'assets/images/loans/prestaclipactive.svg';
import HourglassTopIcon from 'assets/images/icons/HourglassTop.svg';
import getUrl from 'helpers/getUrl';
import urls from 'helpers/urls';
import { toCurrencyFormat } from 'helpers/formatCurrency';
import { LoansStates, PreapprovalStatus } from 'constants/AppConstants';
import {
  ButtonCentar,
  BlueCheckbox as StyledBlueCheckBox,
  ListItem,
  MarginContainer,
  Label,
} from '../styled';
import FormButton from 'helpers/FormUtils/FormButton';
import text from 'components/loans/Refill/text';
import ClevertapComponent from 'helpers/Clevertap/ClevertapComponent';
import { lenderPayload } from 'components/loans/Commons/Clevertap';
import { EVENTS } from 'helpers/Clevertap';
const BlueCheckbox = StyledBlueCheckBox((props) => (
  <Checkbox color="default" {...props} />
));
class RefillDetail extends Component {
  static propTypes = {
    detail: PropTypes.bool,
    preapprovals: PropTypes.array,
    loans: PropTypes.array,
    action: PropTypes.func.isRequired,
  };
  static defaultProps = {
    detail: true,
    preapprovals: [],
    loans: [],
  };
  state = {
    accepted: false,
    showHowTo: false,
  };

  togglePopup = () => {
    const { showHowTo } = this.state;
    this.setState({ showHowTo: !showHowTo });
  };
  switchAccepted = () => this.setState({ accepted: !this.state.accepted });
  getBaseLocation = () =>
    this.props.location.pathname.startsWith(urls.mobile) ? urls.mobile : '';

  selectPreapproval = (preapprovalSelected) => () => {
    const { action } = this.props;
    action(preapprovalSelected);
    this.props.history.replace(
      getUrl(
        `${this.getBaseLocation()}${urls.loansRefillConfirmation.replace(
          ':lender',
          preapprovalSelected.provider_code.toLowerCase(),
        )}`,
      ),
    );
  };

  requestPreapproval = (preapprovalSelected) => () => {
    const { action } = this.props;
    action(preapprovalSelected, () =>
      this.props.history.replace(
        getUrl(
          `${this.getBaseLocation()}${urls.loansRefillCongrats.replace(
            ':lender',
            preapprovalSelected.provider_code.toLowerCase(),
          )}`,
        ),
      ),
    );
  };

  render() {
    const { preapprovals = [], loans = [], showCashAdvance } = this.props;
    const { accepted: chkAccepted, showHowTo } = this.state;
    const availables = preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.AVAILABLE].includes(preapproval.status) &&
        preapproval.type === LoansStates.REFILL,
    );

    const preapprovalSelected = availables.length > 0 ? availables[0] : {};
    const {
      amount,
      payback,
      term_maximum: termMaximum,
      expiration_date_local: expirationDate,
      retention_rate: retentionRate,
    } = preapprovalSelected;
    const accepted = preapprovals.filter((preapproval) =>
      [PreapprovalStatus.ACCEPTED, PreapprovalStatus.ACTIVE].includes(
        preapproval.status,
      ),
    );
    const loanSelected = loans.filter(
      (loan) =>
        accepted.length > 0 &&
        loan.pre_approval_id === accepted[0].pre_approval_id,
    );
    const toRefill =
      loanSelected.length > 0 ? loanSelected[0] : { current_balance: 0 };
    const deposit = amount - toRefill.current_balance;
    const comision = payback - amount;
    const totalPayback = parseFloat(amount) + comision;

    return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ClipTypography
              style={{
                marginTop: '24px',
                marginBottom: '13px',
              }}
              variant="h3"
              fontWeight="medium"
              align="center"
              gutterBottom
            >
              {text.confirmationTitle}
            </ClipTypography>
            <ClipCard
              style={{
                borderRadius: '8px 8px 0px 0px',
              }}
            >
              <ClipGrid container spacing={3}>
                <ClipGrid item xs={12} sm={12} md={12} lg={12}>
                  <ClipTypography
                    style={{
                      marginTop: '35px',
                      marginBottom: '27px',
                    }}
                    variant="body1"
                    fontWeight="regular"
                    height="400"
                    align="left"
                    gutterBottom
                  >
                    {text.refillSubtitle}
                  </ClipTypography>
                </ClipGrid>
              </ClipGrid>
              <ClipGrid container spacing={3}>
                <ClipGrid item xs={12} sm={12} md={12} lg={12}>
                  <ClipTypography
                    variant="subtitle1"
                    fontWeight="semiBold"
                    height="600"
                    gutterBottom
                  >
                    {text.newOfferTitle}
                  </ClipTypography>
                  <ClipTypography
                    style={{
                      color: '#00707F',
                    }}
                    fontWeight="semiBold"
                    height="600"
                    color="secondary"
                    gutterBottom
                  >
                    {toCurrencyFormat(amount)}
                  </ClipTypography>
                </ClipGrid>
              </ClipGrid>
              <ClipGrid container spacing={3}>
                <ClipGrid item xs={12} sm={6} md={6} lg={6}>
                  <ClipTypography
                    variant="body1"
                    fontWeight="semiBold"
                    height="600"
                    align="left"
                    gutterBottom
                  >
                    {text.termTitle}
                  </ClipTypography>
                  <ClipTypography
                    variant="body1"
                    fontWeight="regular"
                    height="600"
                    align="left"
                    gutterBottom
                  >
                    {text.term(termMaximum)}
                  </ClipTypography>
                </ClipGrid>
                <ClipGrid item xs={12} sm={6} md={6} lg={6}>
                  <ClipTypography
                    variant="body1"
                    fontWeight="semiBold"
                    height="600"
                    align="left"
                    gutterBottom
                  >
                    {text.payTitle}
                  </ClipTypography>
                  <ClipTypography
                    variant="body1"
                    fontWeight="regular"
                    height="600"
                    align="left"
                    gutterBottom
                  >
                    {text.pay(parseFloat(retentionRate))}
                  </ClipTypography>
                </ClipGrid>
                <ClipGrid item xs={12} sm={6} md={6} lg={6}>
                  <ClipTypography
                    variant="body1"
                    fontWeight="semiBold"
                    height="600"
                    align="left"
                    gutterBottom
                  >
                    {text.chargeIva}
                  </ClipTypography>

                  <ClipTypography
                    variant="body1"
                    fontWeight="regular"
                    height="600"
                    align="left"
                    gutterBottom
                  >
                    {toCurrencyFormat(comision)}
                  </ClipTypography>
                </ClipGrid>
                <ClipGrid item xs={12} sm={6} md={6} lg={6}>
                  <ClipTypography
                    variant="body1"
                    fontWeight="semiBold"
                    height="600"
                    align="left"
                    gutterBottom
                  >
                    {text.totalPay}
                  </ClipTypography>
                  <ClipTypography
                    variant="body1"
                    fontWeight="regular"
                    height="600"
                    align="left"
                    gutterBottom
                  >
                    {toCurrencyFormat(totalPayback)}
                  </ClipTypography>
                </ClipGrid>
              </ClipGrid>
              <ClipDivider
                style={{
                  marginTop: '24px',
                  marginBottom: '24px',
                }}
              />
              <ClipGrid container spacing={3}>
                <ClipGrid item xs={12} sm={12} md={12} lg={12}>
                  <ClipTypography
                    data-testid="confirmTitle"
                    variant="body1"
                    fontWeight="semiBold"
                    height="600"
                    align="left"
                    gutterBottom
                  >
                    {text.reafirmTitle}
                  </ClipTypography>
                  <ClipGrid container spacing={3}>
                    <ClipGrid item xs={12} sm={12} md={12} lg={12}>
                      <ListItem>
                        <ClipTypography
                          variant="body1"
                          fontWeight="regular"
                          height="600"
                        >
                          {text.partOne}&nbsp;
                          <b>
                            {text.termOne(
                              toCurrencyFormat(toRefill.current_balance),
                            )}
                          </b>
                          &nbsp;
                          {text.partTwo}
                        </ClipTypography>
                      </ListItem>
                      <ListItem>
                        <ClipTypography
                          variant="body1"
                          fontWeight="regular"
                          height="600"
                        >
                          {text.termTwo}
                        </ClipTypography>
                      </ListItem>
                    </ClipGrid>
                  </ClipGrid>
                </ClipGrid>
              </ClipGrid>
            </ClipCard>
            <ClipCard
              style={{
                borderRadius: '0px 0px 8px 8px',
                background: '#FAFAFA',
              }}
            >
              <ClipGrid container spacing={3}>
                <ClipGrid item xs={12} sm={12} md={12} lg={12}>
                  <MarginContainer>
                    <BlueCheckbox
                      checked={chkAccepted}
                      onChange={(event) => this.switchAccepted()}
                    />
                    <Label>
                      <ClipTypography
                        variant="body1"
                        fontWeight="regular"
                        height="600"
                      >
                        {text.condition}
                      </ClipTypography>
                    </Label>
                  </MarginContainer>
                </ClipGrid>
              </ClipGrid>

              <ButtonCentar>
                <ClipGrid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                  lg={8}
                  style={{
                    marginTop: '24px',
                    marginBottom: '24px',
                  }}
                >
                  <ClevertapComponent
                    renderComponent={<FormButton />}
                    variant="contained"
                    text={text.button}
                    onClick={this.requestPreapproval(preapprovalSelected)}
                    disabled={!chkAccepted}
                    events={[
                      {
                        callbackName: 'onClick',
                        event: EVENTS.LOANS.CONGRATS_VIEW,
                        payload: lenderPayload(
                          preapprovalSelected,
                          {
                            showCashAdvance,
                          },
                          {
                            type: 'refill',
                          },
                        ),
                      },
                    ]}
                  />
                </ClipGrid>
              </ButtonCentar>
            </ClipCard>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withRouter(RefillDetail);