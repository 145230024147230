import styled from '@emotion/styled';
import { Field } from 'redux-form';
import { SubmitButton as CommonSubmitButton } from 'components/common/Buttons';

export const RegistrationContainer = styled.div(() => ({
  background: 'white',
  height: 'auto',
  minHeight: '600px',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  alignItems: 'center',
  paddingTop: '1.5rem',
}));

export const FormField = styled(Field)(({ theme }) => ({
  marginTop: '2.8rem',
  width: '21.5rem',
}));

const BaseButtonStyles = ({ theme }) => ({
  marginTop: '5rem',
  width: '21.5rem',
});

export const SubmitButton = styled(CommonSubmitButton)(BaseButtonStyles);
