import styled from '@emotion/styled';

import lightBlue from '@material-ui/core/colors/lightBlue';
import { IoIosArrowDown } from 'react-icons/io';

const inlineBlock = 'inline-block';

export const CenterContent = styled.div(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('xs')]: {
    display: 'inline-block',
  },
}));

export const HeaderContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  paddingBottom: '0',
  marginBottom: `${theme.spacing.unit * 7}px`,
  boxShadow: theme.shadows[1],
  [theme.breakpoints.down('xs')]: {
    marginBottom: `${theme.spacing.unit * 3}px`,
  },
}));

export const TopDecoration = styled.div(({ theme }) => ({
  width: '100%',
  height: '5px',
  backgroundColor: theme.palette.naranja.cerulean,
}));

export const ShowHideContainer = styled.div(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  textAlign: 'right',
  marginTop: `${theme.spacing.unit * 1.25}px`,
  [theme.breakpoints.down('xs')]: {
    marginTop: `${theme.spacing.unit * 1.5}px`,
  },
}));

export const HowToButton = styled.span(({ theme }) => ({
  color: lightBlue[700],
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '22px',
  textAlign: 'left',
  marginRight: `${theme.spacing.unit * 2.25}px`,
  cursor: 'pointer',
  '&:hover': {
    color: lightBlue[700],
    opacity: '.8',
  },
}));

export const ShowHideIcon = styled(IoIosArrowDown)(({ theme }) => ({
  display: inlineBlock,
  marginLeft: `${theme.spacing.unit}px`,
  verticalAlign: 'middle',
  transform: 'rotate(0deg)',
  transition: 'transform 150ms linear',
  '&.hide': {
    transform: 'rotate(180deg)',
    transition: 'transform 150ms linear',
  },
}));

export const HeaderContent = styled.div(({ theme }) => ({
  width: '100%',
  float: 'left',
  textAlign: 'center',
  visibility: 'visible',
  opacity: '1',
  transform: 'scaleY(1)',
  transition: 'opacity 200ms, visibility 200ms',
  '&.hide': {
    visibility: 'hidden',
    opacity: '0',
    maxHeight: '0px',
    transition: 'max-height 200ms linear',
  },
}));

export const Message = styled.div(({ theme }) => ({
  paddingLeft: `${theme.spacing.unit * 3.75}px`,
  paddingRight: `${theme.spacing.unit * 3.75}px`,
  paddingTop: `${theme.spacing.unit * 2}px`,
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.naranja.slate,
  textAlign: 'center',
  lineHeight: '30px',
  '& a': {
    color: theme.palette.naranja.cerulean,
  },
  '& a:hover': {
    color: theme.palette.naranja.bloodOrange,
    transition: 'color 100ms linear',
  },
  [theme.breakpoints.down('xs')]: {
    textAlign: 'justify',
  },
}));

export const TitleFlow = styled.div(({ theme }) => ({
  width: '100%',
  paddingTop: `${theme.spacing.unit * 1.5}px`,
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '22px',
  letterSpacing: '0.33px',
  lineHeight: `${theme.spacing.unit * 3}px`,
  color: theme.palette.naranja.slate,
  [theme.breakpoints.down('xs')]: {
    paddingTop: `${theme.spacing.unit * 1}px`,
    paddingBottom: `${theme.spacing.unit * 1}px`,
    fontSize: '20px',
  },
}));

export const ContainerFlow = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 4}px`,
  color: theme.palette.naranja.slate,
  '& ul': {
    listStyleType: 'none',
    margin: 0,
  },
  '& ul li': {
    width: '33%',
    paddingRight: `${theme.spacing.unit * 5}px`,
    display: 'table-cell',
  },
  [theme.breakpoints.down('xs')]: {
    marginTop: `${theme.spacing.unit * 2}px`,
    '& ul': {
      width: '100%',
      paddingLeft: 0,
    },
    '& ul li': {
      width: '100%',
      display: 'inline-grid',
      paddingBottom: `${theme.spacing.unit * 3}px`,
    },
  },
}));

export const IconStep = styled.img(({ theme }) => ({
  display: 'block',
  margin: '0 auto',
  paddingBottom: `${theme.spacing.unit * 2.75}px`,
  [theme.breakpoints.down('xs')]: {
    paddingBottom: `${theme.spacing.unit * 1}px`,
  },
}));

export const TitleStep = styled.span(({ theme }) => ({
  width: '100%',
  display: 'block',
  color: theme.palette.naranja.slate,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '18px',
  textAlign: 'center',
  lineHeight: `${theme.spacing.unit * 3}px`,
  paddingBottom: `${theme.spacing.unit * 2}px`,
  [theme.breakpoints.down('xs')]: {
    paddingBottom: `${theme.spacing.unit * 1}px`,
  },
}));

export const ContentStep = styled.span(({ theme }) => ({
  width: '100%',
  display: 'block',
  color: theme.palette.naranja.slate,
  fontWeight: theme.typography.fontWeightLight,
  fontSize: '17px',
  textAlign: 'center',
  lineHeight: `${theme.spacing.unit * 3}px`,
  [theme.breakpoints.down('xs')]: {
    width: '92%',
    marginLeft: `${theme.spacing.unit * 1.5}px`,
    marginRight: `${theme.spacing.unit * 1.5}px`,
    wordBreak: 'break-word',
  },
}));
