const styles = (theme) => ({
    root: {
        margin: `${theme.spacing(3)}px`,
    },
    title: {
        marginBottom: `${theme.spacing(3)}px`,
    },
    lastUpdated: {
        marginTop: `${theme.spacing(4.5)}px`,
        marginBottom: `${theme.spacing(7.75)}px`,
        color: theme.palette.indigo.l50,
    },
}
)

export default styles;