import { PreapprovalStatus, LoansStates } from '../constants/AppConstants';
import urls from './urls';
import { getProviderCashAdvanceURL } from './getUrl';

const getProviderCodeForURL = (providerCode) => {
  switch (providerCode) {
    case 'MRP':
      return 'mrpresta';
    default:
      return providerCode.toLowerCase();
  }
};

// May we find a way to refactor this into some kind
// of data structure that contains all URLs that differ
// from provider to provider.
const getProviderUrl = (
  providerCode,
  mrpPrestaUrl,
  motUrl,
  aphUrl,
  ctoUrl,
  r2Url,
  avlUrl,
  defaultUrl,
) => {
  let url;
  switch (providerCode) {
    case 'MRP':
      url = mrpPrestaUrl;
      break;
    case 'MOT':
      url = motUrl;
      break;
    case 'APH':
      url = aphUrl;
      break;
    case 'CTO':
      url = ctoUrl;
      break;
    case 'R2':
      url = r2Url;
      break;
    case 'AVL':
      url = avlUrl;
      break;
    default:
      url = defaultUrl;
      break;
  }
  return url;
};

const getLoanUrlByStatus = (
  preapprovals,
  preapprovalSelected,
  isFormSent,
  loansFormVersion,
  loansFormURL,
  prestaClipNameChange,
  showCashAdvance,
) => {
  const availables = preapprovals.filter(
    (preapproval) => preapproval.status === PreapprovalStatus.AVAILABLE,
  );
  const accepted = preapprovals.filter(
    (preapproval) =>
      [PreapprovalStatus.ACCEPTED, PreapprovalStatus.SIGNED].includes(
        preapproval.status,
      ) && !preapproval.loan_id,
  );
  const expired = preapprovals.filter(
    (preapproval) => preapproval.status === PreapprovalStatus.EXPIRED,
  );
  const withLoans = preapprovals.filter(
    (preapproval) =>
      preapproval.loan_id ||
      [
        PreapprovalStatus.ACTIVE,
        PreapprovalStatus.PAID,
        PreapprovalStatus.CANCELLED,
        PreapprovalStatus.CANCELED,
      ].includes(preapproval.status),
  );
  const refillAvailable = preapprovals.filter((preapproval) =>
    [LoansStates.REFILL].includes(preapproval.type),
  );
  if (
    refillAvailable.length > 0 &&
    [PreapprovalStatus.AVAILABLE].includes(refillAvailable[0].status) &&
    !preapprovalSelected
  ) {
    return urls.loansRefill.replace(
      ':lender',
      refillAvailable[0].provider_code.toLowerCase(),
    );
  }
  if (
    refillAvailable.length > 0 &&
    [PreapprovalStatus.AVAILABLE].includes(refillAvailable[0].status) &&
    preapprovalSelected
  ) {
    return urls.loansRefillConfirmation.replace(
      ':lender',
      refillAvailable[0].provider_code.toLowerCase(),
    );
  }
  if (
    refillAvailable.length > 0 &&
    !refillAvailable[0].loan_id &&
    [PreapprovalStatus.ACCEPTED, PreapprovalStatus.ON_HOLD].includes(
      refillAvailable[0].status,
    ) &&
    isFormSent
  ) {
    return urls.loansCongratsAction
      .replace(':lender', refillAvailable[0].provider_code.toLowerCase())
      .replace(':action', 'refill');
  }
  if (
    refillAvailable.length > 0 &&
    [PreapprovalStatus.ACCEPTED, PreapprovalStatus.ON_HOLD].includes(
      refillAvailable[0].status,
    ) &&
    !isFormSent
  ) {
    return urls.loansRefillInReview;
  }

  let loanUrl = loansFormURL;
  if (!isFormSent && availables.length === 0 && accepted.length > 0) {
    loanUrl = urls.inreview;
  }
  if (
    expired.length > 0 &&
    loansFormVersion !== '' &&
    availables.length === 0 &&
    accepted.length === 0
  ) {
    let baseURL = urls.preapprovalsExpiredV4;

    loanUrl = baseURL.replace(
      ':lender',
      getProviderCodeForURL(expired[0].provider_code),
    );
  }
  if (isFormSent && loansFormVersion && showCashAdvance) {
    return urls.congratsVersionAction
      .replace(':lender', getProviderCodeForURL(preapprovals[0].provider_code))
      .replace(
        ':version',
        'v4',
      )
      .replace(':action', 'ca');
  }
  if (isFormSent && loansFormVersion) {
    return urls.congratsVersion
      .replace(':lender', getProviderCodeForURL(preapprovals[0].provider_code))
      .replace(
        ':version',
        'v4',
      );
  }
  if (
    expired.length === 0 &&
    availables.length === 0 &&
    withLoans.length > 0 &&
    accepted.length === 0
  ) {
    loanUrl = prestaClipNameChange
      ? urls.loansMyPrestaClip
      : urls.loansMyCrediClip;
  }
  if (preapprovalSelected) {
    const { provider_code: providerCode } = preapprovalSelected;
    const providerCodeForURL = getProviderCodeForURL(providerCode);
    let urlPersonalInformation = getProviderCashAdvanceURL(
        showCashAdvance,
        providerCodeForURL,
        urls.loansFormIdentityV4CA,
        urls.loansFormIdentityV4,
      );

    loanUrl = urlPersonalInformation;
  }
  return loanUrl;
};

export { getProviderUrl, getProviderCodeForURL, getLoanUrlByStatus };
