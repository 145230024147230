export const RESOURCE_STATE = 'RESOURCE_STATE';

export const setResourceState = (resourceName, isWorking) => {
  return {
    type: RESOURCE_STATE,
    payload: {
      isWorking,
      resourceName,
    },
  };
};
