const styles = (theme) => {
  return {
    form: {
      height: 'calc(100vh - 3.5rem)',
      width: '100%',
      overflow: 'auto',
    },
    formContainer: {
      background: 'white',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingTop: 0,
      paddingBottom: theme.spacing.unit,
      marginTop: theme.spacing.unit * 5,
      marginRight: theme.spacing.unit,
      marginLeft: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      borderRadius: '24px',
      boxSizing: 'border-box',
    },
    bottomButton: {
      marginRight: theme.spacing.unit * 1.5,
      marginLeft: theme.spacing.unit * 1.5,
      marginBottom: theme.spacing.unit * 1.5,
      width: `calc(100vw - ${theme.spacing.unit * 3}px)`,
      padding: 0,
      position: 'absolute',
      bottom: theme.spacing.unit * 3.5,
    },
    clearButton: {
      backgroundColor: 'transparent',
      color: theme.palette.naranja.bloodOrange,
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit,
      boxShadow: 'none',
    },
    formField: {
      marginBottom: theme.spacing.unit * 2,
    },
    container: {
      marginTop: theme.spacing.unit * 5.5,
      paddingLeft: theme.spacing.unit * 1.5,
      paddingRight: theme.spacing.unit * 1.5,
    },
    title: {
      marginTop: theme.spacing.unit * 2,
      padding: 0,
      textAlign: 'left',
      fontWeight: 500,
    },
    titlePassword: {
      padding: 0,
      textAlign: 'left',
      marginTop: 0,
      paddingTop: theme.spacing.unit * 1.5,
    },
    digitsContainer: {
      display: 'grid',
      gridAutoFlow: 'columns',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      width: theme.spacing.unit * 11,
      margin: 'auto',
      paddingTop: theme.spacing.unit / 2,
      marginBottom: theme.spacing.unit * 1.2,
    },
    pinDigit: {
      textAlign: 'center',
      backgroundColor: 'white',
      border: '1px solid #e6e8e9',
      borderRadius: 8,
      width: 32,
      height: 48,
      lineHeight: '45px',
      margin: 0,
      padding: 0,
      fontWeight: 'bold',
    },
    nipTooltip: {
      width: '32px',
    },
    cdaHelper: {
      width: '100%',
    },
    accountIssuedContainer: {
      top: 0,
      left: 0,
      display: 'flex',
      width: '100%',
      height: '3.5rem',
      overflow: 'auto',
      position: 'fixed',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      flexDirection: 'row',
    },
    cacaoLogo: {
      height: '12px',
      margin: '0 6px',
      marginTop: '4.6px',
    },
    cacaoTooltip: {
      padding: 0,
      marginTop: '6px',
      marginRight: 'auto',
      position: 'fixed',
    },
    serviceName: {
      marginRight: '0.45em',
      fontWeight: 500,
      color: '#000B11',
      marginTop: '-1px',
    },
    accountIssued: {
      marginTop: '-1px',
      color: '#000B11',
    },
    helpIcon: {
      height: '16px',
      marginTop: '-5px',
      marginLeft: '3px',
    },
    serviceNameContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  };
};
export default styles;
