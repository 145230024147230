const styles = (theme) => {
  return {
    paper: {
      borderRadius: '0px !important',
    },
    smallPaper: {
      borderRadius: '14px 14px 0 0 !important',
    },
    bottomNavigation: {
      zIndex: 14,
      width: '100%',
      position: 'fixed',
      bottom: 0,
      boxShadow: '0 2px 24px 0 rgba(102, 115, 123, 0.3)',
    },
    buttonContainer: {
      textAlign: 'center',
      width: theme.spacing.unit * 8,
      margin: '0 ' + theme.spacing.unit + 'px',
    },
    buttonTitle: {
      margin: 'auto',
      padding: 0,
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: 'normal',
      marginTop: theme.spacing.unit / 2,
    },
    cacaoText: {
      fontSize: '12px',
      color: '#7d7d7d',
      marginTop: '17px',
    },
    cacaoLogo: {
      height: '16px',
      display: 'inline',
      marginLeft: '5px',
    },
    helpIcon: {
      height: '16px',
      marginLeft: '4px',
    },
  };
};
export default styles;
