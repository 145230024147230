import React from 'react';
import PropTypes from 'prop-types';

import OneStepNavigator from '../../Commons/OneStepNavigator';
import OnScreenKeypad from '../../OnScreenKeypad';
import PasswordDots from '../../CdaHelper/PasswordDots';

import { ClipTypography } from '@clipmx/clip-storybook';
import clipAccountLogo from '../../../../assets/images/berlin/clipAccountLogo.svg';

import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { styles } from '../styles';
import text from '../text';

const useStyles = makeStyles(styles);
const CdaKeypad = (props) => {
  const classes = useStyles(props);
  const { waiting, screen, cdaError, value, setScreen, setValue, SCREENS } =
    props;

  const maxLength = 6;

  const renderInputPlaceholders = () => {
    const dots = [];
    let counter = 0;
    while (counter < maxLength) {
      let color = 'white';
      if (counter < value.length) {
        color = 'black';
      }
      dots.push(
        <PasswordDots
          key={counter}
          counter={counter}
          color={color}
          waiting={waiting}
        />,
      );
      counter++;
    }

    return dots;
  };

  return (
    <div className={classes.container}>
      <OneStepNavigator
        title={text.changeCDA}
        stepBackFunction={setScreen}
        stepBackTarget={SCREENS.PASSWORD}
      />
      <div className={classes.topContent}>
        <img
          className={classes.logo}
          src={clipAccountLogo}
          alt={'Clipcard Logo'}
        />
        <ClipTypography variant={'body1'} component={'p'} align={'center'}>
          {screen === SCREENS.CREATE_CDA ? text.newCode : text.confirmCode}
        </ClipTypography>
        <div
          className={`${classes.dotsContainer} ${
            cdaError && classes.shakeAnimation
          }`}
        >
          {renderInputPlaceholders()}
        </div>
      </div>
      <div className={classes.keypad}>
        <OnScreenKeypad
          keypadDisabled={waiting}
          maxLength={maxLength}
          value={value}
          setValue={setValue}
        />
      </div>
    </div>
  );
};

CdaKeypad.propTypes = {
  waiting: PropTypes.bool.isRequired,
  screen: PropTypes.string.isRequired,
  cdaError: PropTypes.object,
  value: PropTypes.string,
  setScreen: PropTypes.func,
  setValue: PropTypes.func,
  SCREENS: PropTypes.object,
};

CdaKeypad.default = {
  waiting: false,
  screen: 'ACCOUNT',
  cdaError: {},
  value: '',
  setScreen: () => {},
  setValue: () => {},
  SCREENS: {},
};

export default CdaKeypad;
