export default {
    success: 'Se guardó el contacto exitosamente.',
    title: 'A quién envías',
    change: 'Cambiar',
    howMuch: '¿Cuánto quieres enviar?',
    error: 'Saldo insuficiente. Tu saldo es de $balance',
    concept: 'Concepto',
    continue: 'Continuar',
    amount: 'Monto',
    helper: '$balance en tu cuenta'
  };
  