import styled from '@emotion/styled';
import Card from 'components/npm/clip-ui/Card';
import styledWithClasses from 'helpers/StyledWithClasses';

export default styledWithClasses(
  styled(Card)(({ theme }) => ({
    '& .common-card-content-root': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down('xs')]: {
      '& .common-card-header-title': {
        fontSize: '1.35rem',
        lineHeight: '1.15417em',
        marginBottom: theme.spacing.unit / 2,
      },
      '& .common-card-header-subheader': {
        fontSize: '0.81rem',
      },
      '& .common-card-header-root': {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
      },
      '& .common-card-content-root': {
        padding: theme.spacing.unit * 2,
      },
    },
  })),
  {
    contentRoot: 'common-card-content-root',
    headerRoot: 'common-card-header-root',
    headerTitle: 'common-card-header-title',
    headerSubheader: 'common-card-header-subheader',
  },
);
