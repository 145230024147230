import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

export const styles = makeStyles((theme) => {
  return {
    FormControlStyles: {
      width: '100%',
      display: 'block',
      float: 'left',
      marginBottom: `${theme.spacing.unit}px`,
      marginTop: `${theme.spacing.unit}px`,
      '& legend': {
        textAlign: 'left',
        marginTop: `${theme.spacing.unit}px`,
        color: `${theme.palette.naranja.slate}`,
      },
      '&:first-child': {
        marginTop: 0,
        marginBottom: `${theme.spacing.unit}px`,
      },
    },
    StepTitleStyles: {
      width: '100%',
      textAlign: 'left',
      fontSize: `${theme.spacing.unit * 2.5}px`,
      fontWeight: 600,
      color: `${theme.palette.naranja.slate}`,
      filter: 'brightness(0.5)',
    },
    StepDescriptionStyles: {
      width: '100%',
      marginTop: `${theme.spacing.unit * 2}px`,
      textAlign: 'left',
      fontSize: `${theme.spacing.unit * 2}px`,
      color: `${theme.palette.naranja.slate}`,
      filter: 'brightness(0.5)',
    },
    StepSectionStyles: {
      width: '100%',
      marginTop: `${theme.spacing.unit * 3}px`,
      textAlign: 'left',
      fontSize: `${theme.spacing.unit * 1.75}px`,
      color: `${theme.palette.naranja.slate}`,
      filter: 'brightness(0.5)',
    },
    ListContainerStyles: {
      width: '100%',
      marginTop: `${theme.spacing.unit}px`,
      textAlign: 'left',
      fontSize: `${theme.spacing.unit * 1.75}px`,
      color: `${theme.palette.naranja.slate}`,
      filter: 'brightness(0.5)',
    },
  };
});

export default styles;
