import {
  EMAIL_VALIDATION_CLEAR,
  EMAIL_VALIDATION_SUCCESS,
  EMAIL_VALIDATION_FAILED,
  EMAIL_VALIDATION_RESEND_SUCCESS,
  EMAIL_VALIDATION_RESEND_FAIL,
} from '../actions/Types';

const initialState = {
  validationAttempted: false,
  emailValidated: false,
  validationError: '',
  validationErrorStatus: null,
  resendAttempted: false,
  resendSuccessful: false,
  resendError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EMAIL_VALIDATION_CLEAR:
      return initialState;
    case EMAIL_VALIDATION_SUCCESS:
      return {
        ...state,
        validationAttempted: true,
        emailValidated: true,
        validationError: '',
        validationErrorStatus: null,
      };
    case EMAIL_VALIDATION_FAILED:
      return { ...state, ...action.payload };
    case EMAIL_VALIDATION_RESEND_SUCCESS:
      return {
        ...state,
        resendAttempted: true,
        resendSuccessful: true,
        resendError: '',
      };
    case EMAIL_VALIDATION_RESEND_FAIL:
      return {
        ...state,
        resendAttempted: true,
        resendSuccessful: false,
        resendError: action.payload,
      };
    default:
      return state;
  }
}
