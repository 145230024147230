import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { DialogContext } from '@clipmx/clip-storybook/dist/components/Dialog/dialogContext';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import {EVENTS, Clevertap} from '../../../../helpers/Clevertap';
import termsIcon from '../../../../assets/images/berlin/termsIcon.svg';

import text from './text';
import styles from './styles';

const useStyles = makeStyles(styles);

const AcceptTerms = (props) => {
  const classes = useStyles(props);
  const { nextStep, seeTerms, user } = props;
  const { dialogActions } = useContext(DialogContext);

  useEffect(() => {
    dialogActions.closeDialog('simpleDialog');
    Clevertap.event(
      EVENTS.BERLIN.ONBOARDING.EVENT,
      EVENTS.BERLIN.ONBOARDING.ACTIONS.TYC_VIEWED,
      );
  }, []);

  let termsText = text.info.split('%').map((line, i) => {
    if (line === 'link') {
      return (
        <ClevertapComponent
          renderComponent={<span />}
          events={[
            {
              callbackName: 'onClick',
              event: EVENTS.BERLIN.ONBOARDING.EVENT,
              action: EVENTS.BERLIN.ONBOARDING.ACTIONS.TYC_LINK_CLICKED,
            },
          ]}
          className={classes.link}
          key={i}
          onClick={() => {
            seeTerms();
          }}
          >
            {text.link}
        </ClevertapComponent>
      );
    }
    return <span key={i}>{line}</span>;
  });
  return (
    <>
      <div className={classes.container}>
        <ClipTypography variant="h3">{text.terms}</ClipTypography>
        <div className={classes.info}>
          <ClipTypography>{termsText}</ClipTypography>
        </div>
        <div className={classes.imgContainer}>
          <img src={termsIcon} alt="terms" />
        </div>
        <div className={classes.btnContainer}>
          <ClevertapComponent
            renderComponent={<ClipButton />}
            events={[
              {
                callbackName: 'onClick',
                event: EVENTS.BERLIN.ONBOARDING.EVENT,
                action: EVENTS.BERLIN.ONBOARDING.ACTIONS.TYC_NOT_ACCEPTED,
              },
            ]}
            variant="outlined"
            color="primary"
            onClick={() => {
              dialogActions.registerDialog('simpleDialog', {
                open: true,
                className: classes.dialog,
                showDialogActions: true,
                dialogActions: (
                  <div className={classes.dialogActions}>
                    <ClipButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        dialogActions.closeDialog('simpleDialog');
                      }}
                    >
                      {text.understood}
                    </ClipButton>
                  </div>
                ),
                noCloseButton: true,
                type: 'form',
                title: text.sorry,
                children: text.needsToAccept,
                name: 'simpleDialog',
              });
            }}
            >
              {text.deny}
          </ClevertapComponent>
          <ClevertapComponent
              renderComponent={<ClipButton />}
              events={[
                {
                  callbackName: 'onClick',
                  event: EVENTS.BERLIN.ONBOARDING_TYC_ACCEPTED,
                  action: EVENTS.BERLIN.ONBOARDING.ACTIONS.TYC_ACCEPTED,
                },
              ]}
              variant="contained"
              color="primary"
              onClick={() => {
                nextStep();
              }}
              >
                {text.accept}
            </ClevertapComponent>
        </div>
      </div>
    </>
  );
};

AcceptTerms.propTypes = {
  nextStep: PropTypes.func,
  seeTerms: PropTypes.func,
};
AcceptTerms.defaultProps = {
  nextStep: () => {},
  seeTerms: () => {},
};

export default AcceptTerms;
