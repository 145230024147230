import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { getPrestaClipNameChange } from 'components/loans/redux/selectors';

import {
  LoansCurrentProductName,
  LoansNewProductName,
} from 'constants/AppConstants';

import * as Styled from '../styled';

export class FAQDetail extends Component {
  static propTypes = {
    faq: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
  };

  state = {
    activeDetail: this.props.index === 0,
  };

  renderAnswer = (line, index) => (links) => {
    const hasLinks = line.indexOf('~links') >= 0;
    const lineSegment = hasLinks && links[parseInt(line.split('=')[1])];
    return (
      <React.Fragment key={`faq_link_${index}`}>
        {hasLinks ? (
          <a href={lineSegment.reference}>{lineSegment.content}</a>
        ) : (
          this.renderNewProductName(line)
        )}
        <br />
      </React.Fragment>
    );
  };

  renderNewProductName = (prevText) => {
    const { prestaClipNameChange } = this.props;
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  render() {
    const { faq } = this.props;
    const { activeDetail } = this.state;
    return (
      <Styled.FAQCard className={activeDetail && 'selected'}>
        <Styled.TitleContainer
          onClick={() => {
            this.setState({ activeDetail: !activeDetail });
          }}
        >
          <Styled.SeeMoreText>
            {this.renderNewProductName(faq.question)}
          </Styled.SeeMoreText>
          <Styled.SeeMoreButton>
            {!activeDetail ? <Styled.ExpandMore /> : <Styled.ExpandLess />}
          </Styled.SeeMoreButton>
        </Styled.TitleContainer>
        <Styled.FAQCardContent className={activeDetail && 'selected'}>
          <Styled.FAQCardText>
            {faq.answer.map((line, index) =>
              this.renderAnswer(line, index)(faq.links),
            )}
          </Styled.FAQCardText>
        </Styled.FAQCardContent>
      </Styled.FAQCard>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    prestaClipNameChange: getPrestaClipNameChange(state),
  };
};

export default connect(mapStateToProps)(FAQDetail);
