export const styles = (theme) => {
  const rootStyles = {
    container: {
      width: theme.spacing(76),
      margin: 0,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    bannerContainer: {
      alignItems: 'flex-start',
      margin: 0,
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        width: '100%',
      },
    },
    prestaClipContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    banner: {
      width: theme.spacing(28.75),
      height: theme.spacing(6.5),
    },
    expiredIcon: {
      width: theme.spacing(28),
      height: theme.spacing(28),
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(17),
        height: theme.spacing(17),
      },
    },
    title: {
      marginTop: theme.spacing(3),
      fontSize: theme.spacing(2.625),
    },
    content: {
      marginTop: theme.spacing(2),
      fontSize: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3),
        fontSize: theme.spacing(1.75),
      },
    },
    subtitle: {
      marginTop: theme.spacing(4),
      lineHeight: `${theme.spacing(3)}px`,
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        padding: `0 ${theme.spacing(2)}px`,
      },
    },
    advice: {
      marginTop: theme.spacing(3),
      display: 'flex',
      '& > p': {
        alignSelf: 'center',
      },
    },
    adviceIcon: {
      alignSelf: 'center',
      marginRight: theme.spacing(2),
      backgroundColor: '#FEEDE5',
      '& > img': {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
      borderRadius: theme.spacing(2.5),
    },
    recommendation: {
      marginTop: theme.spacing(3),
    },
    footerContainer: {
      width: theme.spacing(76),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  };

  return { ...rootStyles };
};
