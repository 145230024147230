export const styles = (theme) => {
  const rootStyles = {
    NoTransactionsContainer: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    NoTransactionsImageContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    NoTransactionsImage: {
      width: theme.spacing(29),
      height: theme.spacing(21),
    },
  };

  return {
    ...rootStyles,
  };
};
