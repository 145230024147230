import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

const useStyles = makeStyles((theme) => ({
  checkoutContainer: {
    '& *': {
      boxSizing: 'border-box'
    },
    fontFamily: 'AvertaStd',
    color: '#5E5F73',
    backgroundColor: 'white',
    padding: '36px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px'
    },
    borderRadius: '8px',
    maxWidth: '100%',
    '& h1': {
      color: '#181A36',
      fontSize: '32px',
      fontWeight: '700'
    },
    '& h2': {
      color: '#181A36',
      fontSize: '24px',
      fontWeight: '600'
    },
    '& h3': {
      color: '#181A36',
      fontSize: '20px',
      fontWeight: '600',
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        lineHeight: '28px'
      },
    },
    '& h4': {
      color: '#181A36',
      fontSize: '16px',
      fontWeight: '600'
    },
    '& p': {
      color: '#5E5F73',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px'
    },
    '& .startnNow': {
      borderRadius: '8px',
      height: '48px',
      fontSize: '16px',
      fontFamily: 'AvertaStd',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
    },
    '& .desktop': {
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    '& .mobile': {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    '& .checkoutClip': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '32px',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
      '& .content': {
        flex: '434px 1 1',
        paddingRight: '21.5px',
        minWidth: '240px',
        [theme.breakpoints.down('xs')]: {
          padding: '0'
        },
        '& p': {
          fontSize: '20px',
          fontWeight: '400',
          lineHeight: '30px',
          [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px'
          },
        },
      },
      '& figure': {
        margin: '0',
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
        '& img': {
          flex: '405px 0 1',
          maxWidth: '100%'
        },
      },
      '& .videoContainer': {
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
        '& video': {
          'pointer-events': 'none',
          maxWidth: '70vw',
          [theme.breakpoints.down('xs')]: {
            maxWidth: '120vw',
          },
        },
        flex: '405px 0 1',
        maxWidth: '100%',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        overflow: 'hidden'
      },
      '& .controls': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '20px',
        marginTop: '36px',
        [theme.breakpoints.down('xs')]: {
          justifyContent: 'center'
        },
      },
      '& .financeLogos': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '16px',
        margin: '38px 0 38px 0',
        flexWrap: 'wrap',
        [theme.breakpoints.down('xs')]: {
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          gap: '0',
        },
        '& img': {
          width: '14%',
          maxWidth: '41px'
        }
      }
    },
    '& .beneficios': {
      marginTop: '73px',
      [theme.breakpoints.down('xs')]: {
        marginTop: '0'
      },
      '& .cards': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        gap: '32px',
        marginTop: '36px',
        '& > div': {
          flex: '274px 0 1',
          height: '250px',
          padding: '20px',
          [theme.breakpoints.down('xs')]: {
            flex: '274px 1 1',
          },
        },
        '& .icons': {
          marginTop: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px'
        },
        '& p': {
          fontSize: '14px',
          lineHeight: '20px'
        },
        '& h4': {
          marginTop: '20px',
          marginBottom: '16px'
        },
      }
    },
    '& .installationSteps': {
      marginTop: '73px',
      '& .steps': {
        '& .step': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
          },
          gap: '48px',
          marginTop: '56px',
          '& .info': {
            paddingLeft: '36px',
            position: 'relative',
            flex: '403px 1 1',
            [theme.breakpoints.down('xs')]: {
              paddingLeft: '0',
            },
            '& h3': {
              marginTop: '0'
            },
            '& .stepNumber': {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              borderRadius: '50%',
              fontSize: '12px',
              lineHeight: '18px',
              fontWeight: '600',
              position: 'absolute',
              left: '0',
              top: '0',
              width: '20px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              [theme.breakpoints.down('xs')]: {
                position: 'static',
                marginBottom: '12px'
              },
            }
          },
          '& figure': {
            margin: '0',
            '& img': {
              flex: '450px 0 1',
              maxWidth: '100%'
            },
          }
        }
      }
    },
    '& .faq': {
      marginTop: '73px',
      '& .accodrions': {
        padding: '20px',
        backgroundColor: '#F6F6F7',
        marginTop: '36px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        '& ul': {
          padding: '0 0 0 16px'
        },
        '& li': {
          color: 'black',
          lineHeight: '24px',
          margin: '16px'
        }
      }
    },
    '& footer': {
      marginTop: '73px',
      with: '100%',
      textAlign: 'center',
      '& h3': {
        fontSize: '18px',
      },
      '& .footerInfo': {
        fontSize: '16px',
        lineHeight: '24px',
        backgroundColor: '#F6F6F7',
        maxWidth: '659px',
        margin: '23px auto',
        padding: '16px 8px'
      }
    }
  }
}));

export default useStyles;