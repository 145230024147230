import styled from '@emotion/styled';
import { SubmitButton as CommonSubmitButton } from 'components/common/Buttons';

export const TermsContainer = styled.div(() => ({
  marginTop: '4rem',
  background: 'white',
  width: '20.5rem',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2.25rem',
  alignSelf: 'center',
}));

export const Title = styled.div(() => ({
  background: 'white',
  width: '20.5rem',
  height: '1.625rem',
  fontSize: '1.5rem',
  fontWeight: '600',
  textAlign: 'center',
  marginBottom: '1rem',
}));

export const Subtitle = styled.div(() => ({
  background: 'white',
  width: '20.5rem',
  height: 'auto',
  fontSize: '1.125rem',
  fontWeight: '600',
  textAlign: 'center',
  lineHeight: '1.44',
  marginBottom: '1rem',
}));

export const RegularText = styled.div(() => ({
  background: 'white',
  width: '20.5rem',
  height: 'auto',
  fontSize: '1rem',
  textAlign: 'justify',
  lineHeight: '1.63',
  marginBottom: '1rem',
}));

const BaseButtonStyles = ({ theme }) => ({
  marginTop: '1rem',
  marginBottom: '2rem',
  width: '15rem',
  alignSelf: 'center',
});

export const SubmitButton = styled(CommonSubmitButton)(BaseButtonStyles);
