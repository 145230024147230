import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { renderFileInput } from 'helpers/FormUtils';
import { LoansFilesStates } from 'constants/AppConstants';
import text from './text';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';

import clsx from 'clsx';
import { styles } from './styles';

export class FileUploader extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    accept: PropTypes.string.isRequired,
    maxFiles: PropTypes.number,
    notFound: PropTypes.bool.isRequired,
    files: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onCancelUpload: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  state = {
    dragOverClass: '',
    status: LoansFilesStates.NONE,
    deleting: [],
    loading: false,
    fileCanceled: false,
  };

  deletingTimeout = null;

  componentWillUnmount = () => {
    clearTimeout(this.deletingTimeout);
  };

  openFileDialog = () => {
    const formDomRoot = this.fileInputRef
      ? ReactDOM.findDOMNode(this.fileInputRef)
      : ReactDOM.findDOMNode(this.lastFileInputRef);
    formDomRoot.click();
  };

  onUploadFile = (event) => {
    let { files: filesLoaded, maxFiles, onChange } = this.props;
    const files = event.target.files || event.dataTransfer.files;
    const acceptedStatus = [LoansFilesStates.SAVED, LoansFilesStates.LOADING];
    const hasFilesCurrenltyLoaded =
      filesLoaded.filter(({ status }) => acceptedStatus.includes(status))
        .length > 0;

    if (
      hasFilesCurrenltyLoaded &&
      filesLoaded.filter(({ name }) => name === files[0].name).length > 0
    )
      return;
    if (hasFilesCurrenltyLoaded) {
      filesLoaded.forEach(
        (file) =>
          file.status === LoansFilesStates.SAVED &&
          this.onDeleteFileConfirmation(file),
      );
      filesLoaded = [];
    }

    const filesLoadedLength = filesLoaded.filter((file) =>
      acceptedStatus.includes(file.status),
    ).length;

    if (files.length + filesLoadedLength > maxFiles) {
      this.setState({ status: LoansFilesStates.MAX_FILES });
    } else {
      this.setState({ status: LoansFilesStates.NONE });
      onChange(files);
    }
    event.target.value = '';
  };

  onDeleteFileConfirmation = (file) => {
    this.props.onDelete(file);
    this.setState({ status: LoansFilesStates.NONE });
  };

  onDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ dragOverClass: 'dragover' });
  };

  onDragLeave = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ dragOverClass: '' });
  };

  onDrop = (event) => {
    this.onDragLeave(event);
    const { name } = this.props;
    const files = event.dataTransfer.items || event.dataTransfer.files;
    const mappedFiles = [];
    for (let index = 0; index < files.length; index++) {
      mappedFiles[index] = files[index].getAsFile
        ? files[index].getAsFile()
        : files[index];
    }
    mappedFiles.length > 0 &&
      this.onUploadFile({
        target: {
          files: mappedFiles,
          name,
        },
      });
  };

  renderUploadButton = (file) => {
    const { classes, onCancelUpload } = this.props;
    const status = file ? file.status : null;
    const iconButtonStyles = {
      root:
        status === LoansFilesStates.SAVED
          ? classes.iconButtonFilled
          : classes.iconButtonRoot,
    };

    switch (status) {
      case LoansFilesStates.LOADING:
        return (
          <IconButton
            classes={iconButtonStyles}
            onClick={() => {
              onCancelUpload(file);
              this.openFileDialog();
            }}
            aria-label="Upload"
          >
            <AddIcon />
            <CircularProgress
              size={24}
              className={classes.iconButtonProgress}
            />
          </IconButton>
        );
      case LoansFilesStates.SAVED:
      case LoansFilesStates.ERROR:
      case LoansFilesStates.MAX_SIZE:
      case LoansFilesStates.INCORRECT_TYPE:
      case LoansFilesStates.MAX_FILES:
      case LoansFilesStates.DISCONNECTED:
      case LoansFilesStates.REQUIRED:
      case LoansFilesStates.NOT_FOUND:
      default:
        return (
          <IconButton
            classes={iconButtonStyles}
            onClick={this.openFileDialog}
            aria-label="Upload"
          >
            <AddIcon />
          </IconButton>
        );
    }
  };

  renderMessageErrorByFile = (status) => {
    switch (status) {
      case LoansFilesStates.MAX_SIZE:
        return text.maxSize;
      case LoansFilesStates.INCORRECT_TYPE:
        return text.incorrectType;
      case LoansFilesStates.DISCONNECTED:
        return text.disconnected;
      case LoansFilesStates.ERROR:
        return text.tryAgain;
      case LoansFilesStates.NOT_FOUND:
        return text.notFound;
      default:
        return '';
    }
  };

  renderProgressBar = (file, index) => {
    if (file.status === LoansFilesStates.LOADING) {
      const { onCancelUpload } = this.props;
      const { classes } = this.props;

      const progressBarStyles = { root: classes.progressBarRoot };
      const loaded = Math.round(file.loaded % 101);
      return (
        <div key={'progress_' + index} className={classes.progressBarHolder}>
          <LinearProgress
            classes={progressBarStyles}
            variant="determinate"
            value={loaded}
          />
          <IconButton color="primary" onClick={() => onCancelUpload(file)}>
            <CancelIcon />
          </IconButton>
        </div>
      );
    }
    return '';
  };

  renderListItemText = (title, inputFilled, loadedFiles) => {
    const { classes } = this.props;

    const listItemTextStyles = {
      root: classes.listItemTextRoot,
      primary: inputFilled
        ? classes.listItemTextPrimary
        : classes.listItemTextPrimaryEmpty,
      secondary: classes.listItemTextSecondary,
    };

    return (
      <ListItemText
        classes={listItemTextStyles}
        primary={title}
        secondary={inputFilled ? loadedFiles.join('-') : null}
      />
    );
  };

  render() {
    const {
      classes,
      title,
      accept,
      name,
      notFound,
      files,
      maxFiles,
      withIcon,
      versionV4,
    } = this.props;
    const { status } = this.state;
    const AvatarIcon = ['identity', 'passport'].includes(name) ? (
      <AccountBoxIcon />
    ) : (
      <AutorenewIcon />
    );

    const inputFilled = files.length > 0;

    const listStyles = { root: classes.listRoot };
    const listItemStyles = {
      root: clsx(
        classes.listItemRoot,
        !withIcon && classes.listItemRootWithoutIcon,
      ),
    };
    const listItemIconStyles = { root: classes.listItemIconRoot };
    const errorStyles = { root: classes.errorRoot };
    const requiredText = versionV4 ? text.incorrectType : text.required;

    let loadedFiles = [];
    let progressBar = null;
    let errorMessage = '';
    let uploadButton = this.renderUploadButton();
    files
      .filter((_, index) => index < maxFiles)
      .forEach((file, index) => {
        loadedFiles.push(file.name);
        progressBar = this.renderProgressBar(file, index);
        uploadButton = this.renderUploadButton(file);
        errorMessage = this.renderMessageErrorByFile(file.status);
      });

    return (
      <Fragment>
        <List classes={listStyles}>
          <ListItem classes={listItemStyles}>
            {withIcon && (
              <ListItemIcon classes={listItemIconStyles}>
                {AvatarIcon}
              </ListItemIcon>
            )}
            {this.renderListItemText(title, inputFilled, loadedFiles)}
            <ListItemSecondaryAction>{uploadButton}</ListItemSecondaryAction>
          </ListItem>
        </List>
        <span className={classes.hidden}>
          <Field
            component={renderFileInput}
            accept={accept}
            multiple
            name={name}
            onChange={this.onUploadFile}
            ref={(input) => {
              if (input) this.fileInputRef = input;
            }}
          />
        </span>
        {(notFound ||
          status === LoansFilesStates.MAX_FILES ||
          errorMessage.length > 0) && (
          <FormHelperText classes={errorStyles}>
            {errorMessage.length > 0
              ? errorMessage
              : status === LoansFilesStates.MAX_FILES
              ? text.maxFiles
              : requiredText}
          </FormHelperText>
        )}
        {progressBar}
      </Fragment>
    );
  }
}

export default withStyles(styles)(FileUploader);
