import React, { useContext } from 'react';
import urls from '../../../../../helpers/urls';
import { DialogContext } from '@clipmx/clip-storybook/dist/components/Dialog/dialogContext';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import Typography from '@clipmx/clip-storybook/dist/components/Typography';
import ClipButton from '@clipmx/clip-storybook/dist/components/Button';
import useStyles from './styles';
import text from '../text';

export default function OutModal(props) {
  const { modalName, history, loansFormURL } = props;
  const { dialogActions } = useContext(DialogContext);
  const closeModal = () => {
    dialogActions.closeDialog(modalName); 
  }

  const accept = () => { 
    if ( history.location.pathname.split('/')[1] === 'mobile' ) {
      history.go(0)
      history.push(`/mobile${loansFormURL}`)
    }
    else {
      history.go(0)
      history.push(loansFormURL)
    }
    dialogActions.closeDialog(modalName); 
  };

  const classes = useStyles();

  return (  
    <Grid>    
      <div className={classes.titlesContent}>
        <Typography
          variant="body1"
          fontWeight="bold"
          className={classes.titles1}
        >
          {text.title}
        </Typography>
        <Typography variant="body1" className={classes.titles2}>
          {text.body}
        </Typography>
      </div>
        <div className={classes.buttonContent}>
          <ClipButton
            variant="text"
            color="primary"
            onClick={closeModal}
          >
            {text.leftText}
          </ClipButton>
          <ClipButton
            variant="contained"
            color="primary"
            onClick={accept}
          >
            {text.rightText}
          </ClipButton>
        </div>
    </Grid>
  );
}
