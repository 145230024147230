import React from 'react';
import text from './text';
import useStyles from './styles';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import { ClipTypography } from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { EVENTS } from '../../../../helpers/Clevertap';
import urls from 'helpers/urls';

const HelpNeed = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.cardgris}>
          <ClipTypography
            className={classes.fontW500}
            color="textSecondary"
            variant="body2"
            fontWeight="regular"
            gutterBottom
          >
            {text.helpNeed}
          </ClipTypography>
          <ClipTypography
            color="textSecondary"
            variant="body2"
            fontWeight="regular"
            gutterBottom
          >
            {text.contactExternalMessageAdvice}&nbsp;
            <div className={classes.colorna}>
              <ClevertapComponent
                renderComponent={<a />}
                events={[
                  {
                    callbackName: 'onClick',
                    event: EVENTS.LOANS.PHONECUSTOMER,
                  },
                ]}
                href={urls.clipPhone}
                target={'_blank'}
              >
                {text.contactMessageNumber}
              </ClevertapComponent>
            </div>
            .&nbsp;
            {text.contactEmial}&nbsp;
            <div className={classes.colorna}>
              <ClevertapComponent
                renderComponent={<a />}
                events={[
                  {
                    callbackName: 'onClick',
                    event: EVENTS.LOANS.MAILCUSTOMER,
                  },
                ]}
                href={`mailto:${urls.clipHelpEmail}`}
                target={'_blank'}
              >
                {urls.clipHelpEmail}
              </ClevertapComponent>
            </div>
            &nbsp;
            {text.contactSoluctud}&nbsp;
            <div className={classes.colorna}>{text.contactClipMx}</div>&nbsp;
          </ClipTypography>
        </Grid>
      </Grid>
    </>
  );
};

export default HelpNeed;
