import PromoConstants from '../constants/PromoConstants';
import { searchForBankName } from 'components/account/redux/actions';
import {
  getBankInformation,
  getColonies as getColoniesOnboarding,
} from 'components/onboarding/redux/actions';
import { getColonies as getColoniesMerchant } from '../actions/MerchantActions';
import {
  getColonies as getColoniesLoans,
  clearAddressFields,
} from 'components/loans/redux/actions';
import { getColonies as getColoniesBerlin } from 'components/Berlin/redux/actions';

import { checkPromoCode } from 'components/promos/redux/actions';
import { getValidationText, isEmpty } from './validate';
import { Forms } from '../constants/AppConstants';
import AnalyticsEvents from 'helpers/AnalyticsEvents';
import logger from 'helpers/logger';

const asyncValidateClabe = (values, dispatch) => {
  return new Promise((resolve, reject) => {
    const bankCode = values.bank_account_number.substr(0, 3);

    dispatch(getBankInformation(bankCode))
      .then((data) => {
        if (!data || typeof data.name !== 'string')
          reject({ bank_account_number: getValidationText().isBankCode });
        else resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const asyncValidateNewClabe = (values, dispatch) => {
  const msg = {};
  if (
    values.confirmBankNumber &&
    values.bankNumber !== values.confirmBankNumber
  ) {
    msg.confirmBankNumber = getValidationText().differentClabeAccounts;
  }
  return new Promise((resolve, reject) => {
    const bankCode = values.bankNumber.substr(0, 3);
    dispatch(searchForBankName(bankCode))
      .then((data) => {
        if (data.code && msg.confirmBankNumber) {
          reject(msg);
        } else if (!data.code) {
          msg.bankNumber = getValidationText().isBankCode;
          if (!msg.confirmBankNumber) {
            msg.confirmBankNumber = getValidationText().isBankCode;
          }
          reject(msg);
        } else {
          resolve();
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const asyncValidatePostalCode = (values, dispatch, props) => {
  return new Promise((resolve, reject) => {
    function dispatchGetColonies(getColonies, form) {
      const postalCode = values.postal_code || values.postalCode;
      if (isEmpty(postalCode)) {
        resolve();
      } else if (`${postalCode}`.trim().length === 0) {
        dispatch(clearAddressFields(form));
        values.postal_code &&
          reject({ postal_code: getValidationText().isRequired });
        values.postalCode &&
          reject({ postalCode: getValidationText().isRequired });
      } else if (!(/[0-9]+$/.test(postalCode) && /^[^.]+$/.test(postalCode))) {
        dispatch(clearAddressFields(form));
        values.postal_code &&
          reject({ postal_code: getValidationText().isNumeric });
        values.postalCode &&
          reject({ postalCode: getValidationText().isNumeric });
      } else if (postalCode && postalCode.length !== 5) {
        dispatch(clearAddressFields(form));
        values.postal_code &&
          reject({ postal_code: getValidationText().isPostalCode });
        values.postalCode &&
          reject({ postalCode: getValidationText().isPostalCode });
      } else if (!isEmpty(postalCode)) {
        dispatch(clearAddressFields(form));
        dispatch(getColonies(postalCode, form))
          .then((data) => {
            const { mexico_zipcodes } = data;
            if (Array.isArray(mexico_zipcodes) && mexico_zipcodes.length > 0) {
              resolve();
            } else {
              dispatch(clearAddressFields(form));
              values.postal_code &&
                reject({
                  postal_code: getValidationText().isValidPostalCode,
                });
              values.postalCode &&
                reject({ postalCode: getValidationText().isValidPostalCode });
            }
          })
          .catch((err) => {
            logger.warn('Postal code validation backend error: ', err);
            dispatch(clearAddressFields(form));
            values.postal_code &&
              reject({ postal_code: getValidationText().isValidPostalCode });
            values.postalCode &&
              reject({ postalCode: getValidationText().isValidPostalCode });
          });
      } else {
        resolve();
      }
    }

    switch (props.form) {
      case Forms.BUSINESS_INFO_FORM:
        dispatchGetColonies(getColoniesOnboarding, Forms.BUSINESS_INFO_FORM);
        break;
      case Forms.EDIT_MERCHANT_INFO_FORM:
        dispatchGetColonies(getColoniesMerchant, Forms.EDIT_MERCHANT_INFO_FORM);
        break;
      case Forms.MR_PRESTA:
      case Forms.LOANS:
        dispatchGetColonies(getColoniesLoans, props.form);
        break;
      case Forms.REGISTRATION_INFORMATION:
        dispatchGetColonies(getColoniesBerlin, props.form);
        break;
      case Forms.EXISTING_MERCHANTS_REGISTRATION_FORM:
        dispatchGetColonies(getColoniesBerlin, props.form);
        break;
      default:
        reject({ postal_code: getValidationText().isValidPostalCode });
        break;
    }
  });
};

const asyncValidatePromoCode = (values, dispatch, props) => {
  return new Promise((resolve, reject) => {
    if (values.promoCode) AnalyticsEvents.enterPromoCode();
    if (props.promo.attempts < PromoConstants.MAX_ATTEMPTS) {
      dispatch(checkPromoCode(values.promoCode))
        .then((data) => {
          if (!data.is_valid && props.promo.attempts > 0)
            AnalyticsEvents.incorrectPromoCodeReenter();

          if (data.is_valid) {
            AnalyticsEvents.correctPromoCode();
            resolve();
          } else if (props.promo.attempts >= PromoConstants.MAX_ATTEMPTS - 1) {
            AnalyticsEvents.incorrectPromoCode();
            // max attempts reached - just clear the error
            resolve({ promoCode: '' });
          } else {
            AnalyticsEvents.incorrectPromoCode();
            // show error message
            logger.error(
              'error in asyncValidatePromoCode, the code is not valid ',
            );
            reject({ promoCode: getValidationText().isPromoCode });
          }
        })
        .catch((err) => {
          logger.error('error in asyncValidatePromoCode: ', err);
          reject({ promoCode: getValidationText().serverError });
        });
    } else {
      // max attempts reached - just clear the error
      resolve({ promoCode: '' });
    }
  });
};

const asyncValidate = (values, dispatch, props) => {
  switch (props.forms.registrationForm.active) {
    case 'promoCode':
      return asyncValidatePromoCode(values, dispatch, props);
    default:
      return Promise.resolve();
  }
};

export {
  asyncValidate,
  asyncValidateClabe,
  asyncValidateNewClabe,
  asyncValidatePostalCode,
  asyncValidatePromoCode,
};
