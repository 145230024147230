import axios from 'axios';

import { getRequestOptions } from 'helpers/fetchUtil';
import apiPaths from 'helpers/apiPaths';
import { app } from 'config/config';

class LoansService {
  static getLastOne() {
    return axios.get(`${app.apiUrl}${apiPaths.lastOne}`, getRequestOptions());
  }

  static getMerchantInfo() {
    return axios.get(
      `${app.apiUrl}${apiPaths.loansMerchantInfo}`,
      getRequestOptions(),
    );
  }

  static getPreapprovals() {
    return axios.get(
      `${app.apiUrl}${apiPaths.preapprovals}`,
      getRequestOptions(),
    );
  }

  static getLoans() {
    return axios.get(`${app.apiUrl}${apiPaths.loans}`, getRequestOptions());
  }

  static getCollections(loanId, payments) {
    const path = apiPaths.collections
      .replace('{loanId}', loanId)
      .replace('{payments}', payments);
    return axios.get(`${app.apiUrl}${path}`, getRequestOptions());
  }

  static requestLoan(data) {
    return axios.post(
      `${app.apiUrl}${apiPaths.requestLoanV2}`,
      data,
      getRequestOptions(),
    );
  }

  static uploadFile(
    preapprovalId,
    file,
    fileName,
    onUploadProgress,
    cancelToken,
  ) {
    const options = getRequestOptions();
    const config = {
      headers: options.headers,
      onUploadProgress,
      cancelToken,
    };
    const formData = new FormData();
    formData.append('file', file, fileName);
    return axios.post(
      `${app.apiUrl}${apiPaths.documents.replace(
        '{preapprovalId}',
        preapprovalId,
      )}`,
      formData,
      config,
    );
  }

  static deleteFile(file, preapprovalId) {
    const options = getRequestOptions();
    return axios.delete(
      `${app.apiUrl}${apiPaths.documents.replace(
        '{preapprovalId}',
        preapprovalId,
      )}`,
      { headers: options.headers, data: { file } },
    );
  }

  static generateCancelToken() {
    const cancelToken = axios.CancelToken;
    return cancelToken.source();
  }

  static getColonies(zipcode) {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.zipcodes}${zipcode}`;
    return axios.get(url, options);
  }

  static requestRefill(data) {
    return axios.post(
      `${app.apiUrl}${apiPaths.requestRefill}`,
      data,
      getRequestOptions(),
    );
  }

  static requestOpenData(data) {
    return axios.post(
      `${app.apiUrl}${apiPaths.postOpenDataOffer}`,
      data,
      getRequestOptions(),
    );
  }

  static requestSamsclubMembership(data) {
    return axios.post(
      `${app.apiUrl}${apiPaths.postSamsMembership}`,
      data,
      getRequestOptions(),
    );
  }

  static sendPhoneVerificationCode(data) {
    return axios.post(
      `${app.apiUrlv2}${apiPaths.sendPhoneVerificationCode}`,
     
      data,
      getRequestOptions(),         
    )
  }
  
  static validatePhoneVerificationCode(data) {
    return axios.post(
      `${app.apiUrlv2}${apiPaths.validatePhoneVerificationCode}`,
      data,
      getRequestOptions(),      
    )
  }
}

export default LoansService;
