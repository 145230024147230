const styles = (theme) => ({
  iconButton: {
    marginLeft: 'auto',
    marginBottom: 'auto',
    padding: 0,
  },
  img: {
    marginTop: `${theme.spacing(2)}px`,
  },
  container: {
    margin: '1rem',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: '30%',
    fontWeight: 'bold',
  },
  subtitile: {
    marginTop: `${theme.spacing(2)}px`,
  },
  btnContainer: {
    position: 'fixed',
    width: 'calc(100% - 2rem)',
    left: '1rem',
    bottom: '1rem',
  },
  btn: {
    width: '100%',
  },
});

export default styles;
