import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { store } from 'react-notifications-component';

import { ClipButton, ClipTypography, ClipInput } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import BerlinService from '../../service';
import HelpIcon from '../../../../assets/images/berlin/helpIcon.svg';
import ProgressNavigator from '../../Commons/ProgressNavigator';
import { shortStates, country } from '../../Commons/FormSelectValues';
import WaitingForDataValidation from '../WaitingForDataValidation';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import {EVENTS, Clevertap} from '../../../../helpers/Clevertap';
import CurpTooltip from './CurpTooltip';
import text from './text';
import styles from './styles';

const useStyles = makeStyles(styles);

const InputCurp = (props) => {
  const {
    nextStep,
    setCurpInfo,
    step,
    totalStep,
    prevStep,
    setSkipCurp,
    skipCurp,
    setCurp,
    curpValue,
    user,
    setFailedToCreateCurp,
    failedToCreateCurp,
  } = props;
  const classes = useStyles(props);
  const textInput = React.createRef();

  const [tooltipIsOpen, setTooltip] = useState();
  const [waiting, setWaiting] = useState(false);
  const [curpError, setError] = useState('');
  const toggleTooltip = (isTooltipOpen) => {
    setTooltip(isTooltipOpen);
    textInput.current.focus();
  };
  useEffect(() => {
    if (curpError !== '' && curpValue !== '') {
      setError('');
    }
  }, [curpValue]);

  useEffect(() => {
    Clevertap.event(
      EVENTS.BERLIN.ONBOARDING.EVENT,
      EVENTS.BERLIN.ONBOARDING.ACTIONS.SUBMIT_CURP_VIEWED,
      );
  }, []);

  const verifyCurp = async () => {
    if (skipCurp && !failedToCreateCurp) {
      Clevertap.event(
        EVENTS.BERLIN.ONBOARDING.EVENT,
        EVENTS.BERLIN.ONBOARDING.ACTIONS.CURP_CONFIRM_CONTINUED,
        );
      nextStep(2);
      return;
    }
    setWaiting(true);
    try {
      let result = await BerlinService.getDataFromCurp(curpValue);
      for (const field in result.data) {
        if (
          field !== 'gender' &&
          field !== 'legal_identifier' &&
          field !== 'birth_place'
        ) {
          let words = result.data[field].split(' ');
          let capitalized = words.map(
            (val) =>
              val.charAt(0).toUpperCase() + val.substring(1).toLowerCase(),
          );
          result.data[field] = capitalized.join(' ');
        }
        if (field === 'birth_date') {
          result.data[field] = result.data[field].split('/').join(' / ');
        }
        if (field === 'birth_place') {
          const shortState = shortStates.find(
            (state) => state.name === result.data[field].replace('  ', ' '),
          );
          result.data[field] = shortState.id;
        }
        if (field === 'birth_country') {
          if (result.data[field] === 'Mexico') {
            result.data.nationality = '000';
            const shortCountry = country.find(
              (c) => c.name === result.data[field],
            );
            result.data[field] = shortCountry.id;
          } else {
            result.data.nationality = 'FOREIGN';
            result.data[field] = 'EXTRANGERO';
          }
        }
      }
      setCurpInfo(result.data);
      if (failedToCreateCurp) {
        nextStep(2);
        return;
      }
      Clevertap.event(
        EVENTS.BERLIN.ONBOARDING.EVENT,
        EVENTS.BERLIN.ONBOARDING.ACTIONS.CURP_REVIEWED,
        );
      nextStep();
    } catch (error) {
      if (error.response) {
        setError(text.invalidError);
        setCurp('');
        setWaiting(false);
        return;
      }
      store.addNotification({
        title: '',
        message: text.error,
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      setCurp('');
      setWaiting(false);
    }
  };
  const stepBack = () => {
    if (skipCurp) {
      if (failedToCreateCurp) {
        setFailedToCreateCurp(false);
      }
      nextStep();
      return;
    }
    prevStep();
  };
  const renderHeader = () => {
    if (skipCurp) {
      return (
        <>
          <ClipTypography variant="h3">{text.verify}</ClipTypography>
          <div className={classes.info}>
            <ClipTypography>{text.verifyInfo}</ClipTypography>
          </div>
        </>
      );
    }
    return (
      <>
        <ClipTypography variant="h3">{text.inputCurp}</ClipTypography>
        <div className={classes.info}>
          <ClipTypography>{text.info}</ClipTypography>
        </div>
      </>
    );
  };
  if (waiting) {
    return <WaitingForDataValidation version="1" />;
  }

  return (
    <>
      <ProgressNavigator
        progress={step}
        total={totalStep}
        handleClick={() => stepBack()}
      />
      <div className={classes.container}>
        {renderHeader()}
        <ClipInput
          disabled={skipCurp && !failedToCreateCurp && curpValue.length === 18}
          inputRef={textInput}
          type="text"
          label={text.curp}
          color="primary"
          fullWidth={true}
          adornmentIcon={
            <ClevertapComponent
              renderComponent={<div />}
              events={[
                {
                  callbackName: 'onClick',
                  event: EVENTS.BERLIN.ONBOARDING.EVENT,
                  action: EVENTS.BERLIN.ONBOARDING.ACTIONS.SUBMIT_CURP_HELP_CLICKED,
                },
              ]}
              onClick={() => {
                setTooltip(true);
              }}
              >
                {text.help}
                <img src={HelpIcon} className={classes.helpIcon} alt="" />
            </ClevertapComponent>
          }
          error={curpError !== ''}
          errormessage={curpError}
          value={curpValue}
          onChange={(e) => setCurp(e.target.value.toUpperCase())}
          clickable
        />
        <div className={classes.btnContainer}>
          {!skipCurp && (
            <ClevertapComponent
              renderComponent={<ClipButton />}
              events={[
                {
                  callbackName: 'onClick',
                  event: EVENTS.BERLIN.ONBOARDING.EVENT,
                  action: EVENTS.BERLIN.ONBOARDING.ACTIONS.CURP_REFUSED,
                },
              ]}
              variant="outlined"
              color="primary"
              onClick={() => {
                setSkipCurp(true);
                nextStep();
              }}
              >
                {text.skip}
            </ClevertapComponent>
          )}
          <ClipButton
            variant="contained"
            color="primary"
            disabled={curpValue.length < 18}
            onClick={() => verifyCurp()}
          >
            {text.continue}
          </ClipButton>
        </div>
        <Drawer
          className={classes.drawer}
          classes={{ paper: classes.paper }}
          anchor="bottom"
          open={tooltipIsOpen}
        >
          <CurpTooltip toggleTooltip={toggleTooltip} />
        </Drawer>
      </div>
    </>
  );
};

InputCurp.propTypes = {
  nextStep: PropTypes.func,
  setCurpInfo: PropTypes.func,
  step: PropTypes.number,
  totalStep: PropTypes.number,
  prevStep: PropTypes.func,
  setSkipCurp: PropTypes.func,
  skipCurp: PropTypes.bool,
  setCurp: PropTypes.func,
  curpValue: PropTypes.string,
  setFailedToCreateCurp: PropTypes.func,
  failedToCreateCurp: PropTypes.bool,
};
InputCurp.defaultProps = {
  nextStep: () => {},
  setCurpInfo: () => {},
  step: 5,
  totalStep: 10,
  prevStep: () => {},
  setSkipCurp: () => {},
  skipCurp: false,
  setCurp: () => {},
  curpValue: '',
  setFailedToCreateCurp: () => {},
  failedToCreateCurp: false,
};
export default InputCurp;
