import { RESOURCE_STATE } from '../actions/resourceAction';

const initialState = {};

const actionHandlers = new Map([[RESOURCE_STATE, handleResource]]);

export default function resourceReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleResource(state, action) {
  const {
    payload: { resourceName, isWorking },
  } = action;
  return {
    ...state,
    [resourceName]: isWorking,
  };
}
