import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import {
  SubmitButton as CommonSubmitButton,
  BaseButton,
  CancelButton,
} from 'components/common/Buttons';
import { Field } from 'redux-form';

export const TransfersContainer = styled.div(({ theme }) => ({
  background: 'white',
  width: '100%',
  maxWidth: '21.5rem',
  borderRadius: '24px',
  border: 'solid 1px rgba(230, 232, 233, 0.5)',
  boxShadow: theme.customShadows.berlinContainers,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  marginTop: '2rem',
  alignItems: 'center',
}));

export const FormContainer = styled.div(({ theme }) => ({
  background: 'white',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing.unit * 2,
  paddingRight: theme.spacing.unit * 2,
  paddingTop: theme.spacing.unit * 2,
  paddingBottom: theme.spacing.unit * 1,
  marginTop: theme.spacing.unit * 5,
  marginRight: theme.spacing.unit,
  marginLeft: theme.spacing.unit,
  marginBottom: theme.spacing.unit,
  borderRadius: '24px',
  boxSizing: 'border-box',
}));

export const FormField = styled(Field)(({ theme }) => ({
  marginBottom: theme.spacing.unit * 2,
}));

const BaseButtonStyles = ({ theme }) => ({
  marginBottom: theme.spacing.unit * 2,
});
const ReturnButtonStyles = ({ theme }) => ({
  marginBottom: 0,
});
const OutlineButtonStyles = ({ theme }) => ({
  marginBottom: 0,
  marginTop: 8,
  backgroundColor: 'white',
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
});
export const SubmitButton = styled(CommonSubmitButton)(BaseButtonStyles);

export const NextStepButton = styled(BaseButton)(BaseButtonStyles);
export const ReturnButton = styled(CancelButton)(OutlineButtonStyles);
export const RetryButton = styled(BaseButton)(ReturnButtonStyles);

export const ClipCardLogo = styled.img(({ theme }) => ({
  width: theme.spacing.unit * 3.36,
  height: theme.spacing.unit * 1.93,
  objectFit: 'contain',
  marginTop: theme.spacing.unit * 1.5,
  marginBottom: theme.spacing.unit * 0.5,
  alignSelf: 'center',
}));

export const SpeiAmountContainer = styled.div(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing.unit * 0.5,
  alignItems: 'baseline',
  justifyContent: 'center',
}));

const shine = keyframes`
  0% {
    background-position: -100px
    }
  40%, 100% {
    background-position: 140px
    }
`;

const skeletonFake = 'rgba(230,232,233,0.9)';
const gradientColor = 'rgba(238,241,243,0.9)';
