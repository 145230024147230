import text from './text';

export const installmentOptions = [
  {
    id: '03',
    name: text.term03,
  },
  {
    id: '06',
    name: text.term06,
  },
  {
    id: '09',
    name: text.term09,
  },
  {
    id: '12',
    name: text.term12,
  },
];
