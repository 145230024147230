export default {
  needHelp: '¿Necesitas ayuda?',
  cusHap: 'Customer Happiness® te atiende 24/7, todo el año.',
  writeIn: 'Escríbenos en ',
  callNow: 'Llamar ahora',
  helpWa: 'Ayuda vía Whatsapp',
  callTo: 'Llámanos al teléfono ',
  call: ', Llámanos al ',
  phone: '55 6393-2323',
  sendMail: ', envía un correo a ',
  email: 'help@clip.mx',
  writeUs: ' o escríbenos en el chat de ',
  web: 'clip.mx.',
  whatsapp: 'Whatsapp',
};
