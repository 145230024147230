import styled from '@emotion/styled';

export const Footer = styled('footer')(({ theme, path }) => ({
  background: theme.palette.common.white,
  fontSize: 12,
  color: theme.palette.text.secondary,
  zIndex: 1,
  width: '100%',
  marginBottom: path !== '/link_page' ? 0 : 80,
}));

export const Wrapper = styled('div')(({ theme }) => ({
  width: 'auto',
  margin: '0 auto',
  padding: `${theme.spacing.unit * 3}px`,
  maxWidth: theme.spacing.unit * 180,

  [theme.breakpoints.down('md')]: {
    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing.unit * 8,
  },
}));

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& .separator': {
    padding: `0 ${theme.spacing.unit}px`,
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const Divider = styled('hr')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey['100']}`,
}));

export const Link = styled('a')(({ theme }) => ({
  color: 'inherit',
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));
