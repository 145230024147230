import React from 'react';

import * as Styled from './styled';

import { reduxForm } from 'redux-form';
import { Forms } from 'constants/AppConstants';
import { required } from 'helpers/validate';
import { renderInput } from '../../Commons/BerlinInputs/FormUtils';
import { app } from 'config/config';
import text from './text';
const creditcardutils = require('creditcardutils');

const validate = (value) =>
  value && value.length < 19 ? text.numberFieldError : undefined;

const validateClip = (value) => {
  if (app.env === 'dev') {
    return undefined;
  } else {
    return value &&
      ((value.length > 0 && value.charAt(0) !== '4') ||
        (value.length > 1 && value.charAt(1) !== '1') ||
        (value.length > 2 && value.charAt(2) !== '2') ||
        (value.length > 3 && value.charAt(3) !== '4') ||
        (value.length > 5 && value.charAt(5) !== '0') ||
        (value.length > 6 && value.charAt(6) !== '8'))
      ? text.numberFieldError
      : undefined;
  }
};

export class NumeroTarjeta extends React.PureComponent {
  componentDidMount() {
    creditcardutils.cards.push({
      type: 'allCards',
      pattern: /[0-9]/,
      format: /(\d{1,4})/g,
      length: [16],
      cvcLength: [3],
      luhn: true,
    });
  }

  formatDate = (e) => {
    e.target.value = creditcardutils.formatCardNumber(e.target.value);
  };

  checkCard = (values) => {
    const { setActiveTab } = this.props;
    setActiveTab(2);
  };

  render() {
    const { handleSubmit, invalid } = this.props;
    return (
      <form onSubmit={handleSubmit(this.checkCard)}>
        <Styled.NumeroTarjetaContainer>
          <Styled.IngresaTuTarjeta>{text.title}</Styled.IngresaTuTarjeta>
          <Styled.FormField
            component={renderInput}
            label={text.numberField}
            name="card_number"
            type="text"
            validate={[required, validateClip, validate]}
            autoFocus={true}
            inputProps={{ maxLength: 19, inputMode: 'numeric' }}
            onInput={this.formatDate}
          />
          <Styled.SubmitButton disabled={invalid}>
            {text.submitButton}
          </Styled.SubmitButton>
        </Styled.NumeroTarjetaContainer>
      </form>
    );
  }
}

const ReduxNumeroTarjeta = reduxForm({
  form: Forms.REGISTRATION_INFORMATION,
  destroyOnUnmount: false,
  validate,
})(NumeroTarjeta);

export default ReduxNumeroTarjeta;
