const styles = (theme) => ({
    scrollToTop: {
        textAlign: 'center',
        position: 'fixed',
        left: `${theme.spacing(2)}px`,
        bottom: `${theme.spacing(2)}px`,
        backgroundColor: theme.palette.orange.base,
        borderRadius: '50%',
        width: `${theme.spacing(6.5)}px`,
        height: `${theme.spacing(6.5)}px`,
    },
    chevron: {
        width: `${theme.spacing(4.5)}px`,
        height: `${theme.spacing(4.5)}px`,
        marginTop: `${theme.spacing(1)}px`,
        color: 'white',
    },
}
)

export default styles;