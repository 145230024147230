import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { EVENTS } from '../../../../../helpers/Clevertap';
import ClevertapComponent from '../../../../../helpers/Clevertap/ClevertapComponent';
import { toCurrencyFormat } from '../../../../../helpers/formatCurrency';
import text from '../../text';
import * as Styled from '../../styled';

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'MXN',
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
});

export default class DebtDetail extends Component {
  static propTypes = {
    balance: PropTypes.object.isRequired,
    overdueInterest: PropTypes.number,
  };

  state = {
    debt: false,
  };

  render() {
    const {
      balance: {
        overdue_interest: overdueInterest = 0,
        capital,
        interest,
        tax,
        total,
      },
      monthlyCapCheck,
    } = this.props;
    const overdueRow = overdueInterest > 0 && (
      <li>
        <span>{text.overdueInterest}</span>
        <span>
          {currencyFormat.format(parseFloat(overdueInterest)).replace('MX', '')}
        </span>
      </li>
    );
    const cardTitle = !this.state.debt ? (
      <Fragment>
        {monthlyCapCheck ? text.detailView : text.debtView}
        <Styled.ExpandMore />
      </Fragment>
    ) : (
      <Fragment>
        {text.hide}
        <Styled.ExpandLess />
      </Fragment>
    );

    return (
      <Styled.DetailCard className="margin">
        <Styled.SeeMoreText>{monthlyCapCheck ? text.loanDetail : text.debtDetail}</Styled.SeeMoreText>
        <ClevertapComponent
          renderComponent={<Styled.SeeMoreButton />}
          onClick={() => {
            this.setState({ debt: !this.state.debt });
          }}
          events={[
            {
              callbackName: 'onClick',
              event: EVENTS.LOANS.LOAN_ACTIVE_BALANCE,
            },
          ]}
        >
          {cardTitle}
        </ClevertapComponent>
        <Styled.DetailCardContent className={this.state.debt && 'selected'}>
          <Styled.RowDetail>
            <Styled.ResponsiveDetail>
              <Styled.Leaders>
                <li>
                  <span>{text.stock}</span>
                  <span>
                    {currencyFormat
                      .format(parseFloat(capital))
                      .replace('MX', '')}
                  </span>
                </li>
                <li>
                  <span>{text.fixedCharge}</span>
                  <span>
                    {currencyFormat
                      .format(parseFloat(interest))
                      .replace('MX', '')}
                  </span>
                </li>
                <li>
                  <span>{text.IVA}</span>
                  <span>
                    {currencyFormat.format(parseFloat(tax)).replace('MX', '')}
                  </span>
                </li>
                {overdueRow}
                <Styled.LeadersHorizontalDivider />
                <li className={'bolder'}>
                  <span>{text.total}</span>
                  <span>{toCurrencyFormat(total)}</span>
                </li>
              </Styled.Leaders>
            </Styled.ResponsiveDetail>
          </Styled.RowDetail>
        </Styled.DetailCardContent>
      </Styled.DetailCard>
    );
  }
}
