import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

export const useStyles = makeStyles((theme) => {
  return {
    cardgris: {
      width: '100%',
      borderRadius: '8px',
      background: '#FAFAFA',
      marginTop: '30px',
      marginLeft: '10px',
    },
    textFont: {
      fontSize: '28px',
    },
    fontW500: {
      fontWeight: '500',
    },
    icons: {
      display: 'grid',
      '& > img': {
        margin: 'auto',
        marginBottom: '8px',
        marginTop: '32px',
      },
    },
    borderTest: {
      border: '1px solid',
    },
    colorna: {
      display: 'contents',
      color: theme.palette.primary.main,
    },
  };
});

export default useStyles;
