// @ts-nocheck
import { change as updateFormField } from 'redux-form';
import {
  dataLoading,
  dataLoaded,
  dataLoadFailed,
} from '../../../actions/AppActions';

import { Forms } from 'constants/AppConstants';
import {
  GET_BANK_ACCOUNT_INFORMATION,
  GET_BANK_ACCOUNT_INFORMATION_ERROR,
  DELETE_BANK_ACCOUNT_CHANGE_REQUEST,
  DELETE_BANK_ACCOUNT_CHANGE_REQUEST_ERROR,
  ADD_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_ERROR,
  UPDATE_BANK_ACCOUNT_ERROR,
  PUBLIC_PROFILE_DATA,
} from './types';
import Account from '../service';
import apiPaths from 'helpers/apiPaths';
import { createDeleteRequest } from 'middleware/apiResolve/reduxUtils';

export function searchForBankName(code) {
  return (dispatch) => {
    return Account.getBankName(code)
      .then((response) => {
        if (response.data) {
          const bank = response.data.name ? response.data.name : '';
          dispatch(
            updateFormField(Forms.NEW_BANK_ACCOUNT_FORM, 'bankName', bank),
          );
        }

        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function loadBankAccountInformation() {
  return (dispatch) => {
    return Account.getBankAccountInformationV2()
      .then(({ data }) => {
        if (data) {
          const payload = {
            bankAccountName: data.bank_account_owner_name,
            bankAccountNumber: data.bank_account_number,
            bankName: data.bank_name,
            changeAccount: data.account_changed,
            dateAdded: data.date_added,
            updatedAt: data.updated_at,
            flowVersion: data.flow_version,
            isBankAccountSet: true,
            code: data.code,
          };

          dispatch({ type: GET_BANK_ACCOUNT_INFORMATION, payload });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_BANK_ACCOUNT_INFORMATION_ERROR,
          payload: err.message,
        });
      });
  };
}

export function cancelBankAccountChange() {
  return (dispatch) => {
    dispatch(dataLoading(DELETE_BANK_ACCOUNT_CHANGE_REQUEST));
    return Account.deleteBankAccountChangeRequest()
      .then(() => {
        dispatch(dataLoaded(DELETE_BANK_ACCOUNT_CHANGE_REQUEST));
        dispatch({
          type: DELETE_BANK_ACCOUNT_CHANGE_REQUEST,
          payload: { changeAccount: false, flowVersion: 'V2', code: '' },
        });
      })
      .catch((err) => {
        dispatch(
          dataLoadFailed(DELETE_BANK_ACCOUNT_CHANGE_REQUEST_ERROR, err.message),
        );
        dispatch({
          type: DELETE_BANK_ACCOUNT_CHANGE_REQUEST_ERROR,
          payload: err.message,
        });
      });
  };
}

export function saveBankAccount(data) {
  return (dispatch) => {
    dispatch(dataLoading(ADD_BANK_ACCOUNT));
    return Account.addBankAccount(data)
      .then(({ data }) => {
        dispatch(dataLoaded(ADD_BANK_ACCOUNT));
        dispatch({ type: ADD_BANK_ACCOUNT, payload: data });
        return data;
      })
      .catch((err) => {
        dispatch(dataLoadFailed(ADD_BANK_ACCOUNT_ERROR, err.message));
        dispatch({ type: ADD_BANK_ACCOUNT_ERROR, payload: err.message });
        return Promise.reject(err);
      });
  };
}

export function updateBankAccount(data) {
  return (dispatch) => {
    dispatch(dataLoading(UPDATE_BANK_ACCOUNT));
    return Account.updateBankAccount(data)
      .then(({ data }) => {
        dispatch(dataLoaded(UPDATE_BANK_ACCOUNT));
        dispatch({ type: UPDATE_BANK_ACCOUNT, payload: data });
        return data;
      })
      .catch((err) => {
        dispatch(dataLoadFailed(UPDATE_BANK_ACCOUNT_ERROR, err.message));
        dispatch({ type: UPDATE_BANK_ACCOUNT_ERROR, payload: err.message });
        return Promise.reject(err);
      });
  };
}

export function stopBankAccountUpdate(data) {
  return (dispatch) => {
    dispatch(dataLoading(UPDATE_BANK_ACCOUNT));
    return Account.stopBankAccountUpdate(data)
      .then(({ data }) => {
        dispatch(dataLoaded(UPDATE_BANK_ACCOUNT));
        dispatch({ type: UPDATE_BANK_ACCOUNT, payload: data });
        return data;
      })
      .catch((err) => {
        dispatch(dataLoadFailed(UPDATE_BANK_ACCOUNT_ERROR, err.message));
        dispatch({ type: UPDATE_BANK_ACCOUNT_ERROR, payload: err.message });
        return Promise.reject(err);
      });
  };
}

export function cancelBankAccountActivation(cancellationId, onSuccess = null, onFailure = null) {
  const route = apiPaths.bankAccountCancelActivation
  return {
    type: createDeleteRequest(),
    payload: {
      apiEndPoint: 'v3',
      route,
      autoSpinner: true,
      data: {
        cancellation_id: cancellationId
      },
      success: onSuccess,
      failure: onFailure
    }
  }
}

export function getProfile(merchantData) {
  return async (dispatch) => {
    const response = await Account.getPublicProfileData()
    const data = response.items[0];
    let payload;
    if (data?.alias){
      payload = {
        description: data.public_description,
        businessName:data.public_name,
        alias:data.alias,
        image:data.image,
        hasProfile: true,
      }
    }else{
      payload = {
        description:'',
        businessName: merchantData.info.name,
        alias: merchantData.info.name.replace(/ /g, ''),
        image: data?.image,
      }
    }
    dispatch({ type: PUBLIC_PROFILE_DATA, payload: payload})
  }
}