import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

export const useStyles = makeStyles((theme) => {
  return {

    clasModal: {
      borderRadius: '20px 20px 0 0',
      borderLeft: '5px solid rgba(0, 0, 0, 0.5)',
      borderRight: '5px solid rgba(0, 0, 0, 0.5)',
    },
    meetContainer: { 
      display: 'flex',
      height: '150px',
      justifyContent: 'space-between',
    },  
    
  deskNone: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

    imgenLeft: {
float: 'left',
marginTop: 'auto',
textAlign: 'center',
    },
    
    imgenLeftDesk: {
      float: 'left',
      marginTop: '15px',
      textAlign: 'center',
          },
    cardCentral:
    {
       margin: 'auto',
      display: 'flex',    },
    buttonRigth: {
      textAlign: 'rigth',
    },
    iconButtonCa: {
      padding: '15px',
      float: 'right',
    }, 

    meetCard: { 
      display: 'flex',
      justifyContent: 'space-between',
    },
    meetgraph: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    meetgraphMobile: {
      width: 'auto',
      height: '200px',
      marginBottom: '5px',
      textAlign: 'center',
    },
    card: {
      padding: '12px',
      float: 'left',
      width: '100%',
    },
    meetCardModal: { 
      borderRadius: '16px',
      width: '100%',
      height: 'auto',
      background: '#bdc0f0',
          },
    meetContainerGraph:  { 
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px -4px 0px 0px',
    },    
    bordernone: {
      border: 'none',
      boxShadow: 'none',
    },
    cardModalGrid: {
      width: '400px',
      maxWidth: '100%',
    },
    cardModalLabels: {
      [theme.breakpoints.down('sm')]: {
        '& >div': {
          margin: '20px 0',
        },
      },
    },
    cardDesktopLabels: {
      '& >div': {
        margin: '20px 0',
      },
    },
    desktopImage: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    typographyRoot: {
      marginTop: '20px',
      textAlign: 'center',
      color: '#001724',
      fontFamily: 'AvertaStd',
    },
    typographyRootMobil: {
      marginTop: '-10px',
      textAlign: 'center',
      color: '#001724',
      fontFamily: 'AvertaStd',
      fontSize: '21px',
      fontWeight: '500', 
    },
    typographyBody1: {
      marginLeft: '10px',
      display: 'inline-block',
      marginTop: '10px',
      fontFamily: 'AvertaStd',
      fontSize: '18px',
      fontWeight: '400',
    },
    typographyBody1Mobil: {
      marginLeft: '22px',
      display: 'inline-block',
      marginTop: '25px',
      marginButtom: '10px',
      fontFamily: 'AvertaStd',
      fontSize: '16px',
      fontWeight: '500', 
    },

    typographyBody2Mobil: {
      marginLeft: '10px',
      marginBottom: '3px',
      display: 'inline-block',
      marginTop: '0px',
      fontFamily: 'AvertaStd',
      fontSize: '16px',
      fontWeight: '400', 
    },

    iconButton: {
      alignItems: 'start',
      flexGrow: 1,
      padding: '0 0 0 12px',
    },

    
  };

  
});

export default useStyles;