import styled from '@emotion/styled';

const regularText = '#000b11';
const secondaryText = '#32454f';
const greenAmount = '#29660a';
const blackAmount = '#001724';
const auxiliarText = '#66737b';

export const TransactionContainer = styled.div(() => ({
  background: 'white',
  height: 'auto',
  width: '19.5rem',
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'center',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'pointer',
}));

export const TransactionDivider = styled.div(() => ({
  height: '0.063rem',
  width: '17rem',
  alignSelf: 'center',
  border: 'solid 1px #E6E8E9',
}));

export const TransactionIconContainer = styled.div(() => ({
  background: 'white',
  height: 'auto',
  width: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  justifyContent: 'center',
}));

export const TransactionInfoContainer = styled.div(() => ({
  background: 'white',
  height: 'auto',
  width: '11.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  marginLeft: '0.5rem',
}));

export const TransactionAmountsContainer = styled.div(() => ({
  background: 'white',
  height: 'auto',
  width: '6rem',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  justifyContent: 'space-between',
}));

export const TransactionDescription = styled.div(() => ({
  width: '10rem',
  height: 'auto',
  fontSize: '1rem',
  fontWeight: '500',
  textAlign: 'left',
  color: regularText,
  textTransform: 'lowercase',
  '&::first-letter': {
    textTransform: 'capitalize',
  },
}));

export const TransactionHour = styled.div(() => ({
  width: '10rem',
  height: '1.4rem',
  fontSize: '1rem',
  lineHeight: '1.4rem',
  textAlign: 'left',
  color: secondaryText,
  marginTop: '0.5rem',
}));

export const TransactionIcon = styled.img(() => ({
  width: '2rem',
  height: '2rem',
  objectFit: 'contain',
}));

export const TransactionAmount = styled.div(({ positive }) => ({
  width: '6rem',
  height: '1.4rem',
  fontSize: '1rem',
  fontWeight: '500',
  lineHeight: '1.4rem',
  textAlign: 'right',
  color: positive ? greenAmount : blackAmount,
}));

export const TransactionBalance = styled.div(() => ({
  width: '6rem',
  height: '1.4rem',
  fontSize: '1rem',
  fontWeight: 'normal',
  lineHeight: '1.4rem',
  textAlign: 'right',
  color: auxiliarText,
  marginTop: '0.5rem',
}));
