import ReactDOM from 'react-dom';
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import loadable from '@loadable/component';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { ClipThemeProvider, theme as newTheme } from '@clipmx/clip-storybook';

import ClipTheme, { ThemeProvider } from 'components/npm/clip-ui/Theme';

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import './index.css';

import MobileRoutes from './routes/mobile';
import reduxStore from './helpers/reduxStore';
import { Clevertap } from './helpers/Clevertap';
import { isTokenExpired, getToken } from './helpers/tokenHelpers';
import { loginUserWithToken } from './components/auth/redux/actions';
import { registerServiceWorker } from './serviceWorker';
import SiftScript from './components/Sift';
import { useFlags } from 'launchdarkly-react-client-sdk';

import getLDProvider from './helpers/launchDarkly';
import urls from './helpers/urls';
import { getConfigValue } from './config/config';

const DialogProvider = loadable(async () => {
  const { DialogProvider } = await import(
    '@clipmx/clip-storybook/dist/components/Dialog/dialogContext'
  );
  return (props) => <DialogProvider {...props} />;
});

Clevertap.initialize();

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// Get token if one exists
const token = getToken();
if (token && !isTokenExpired(token)) {
  reduxStore.dispatch(loginUserWithToken(token));
}

/**
 * Material UI CSS has the highest specificity possible as they inject
 * their styles at the bottom of the <head />, which makes overriding their
 * default styles tricky without going through their `classes` api.
 *
 * By changing the injection order we can have greater control overriding
 * the default MUI CSS.
 *
 * See https://material-ui.com/customization/css-in-js/#other-html-element for more details
 */
/**
 * We implmement a SIFT script in all the web in order to send user navigation to them
 */
const generateClassName = createGenerateClassName({
  productionPrefix: 'Mdc',
});
const jss = create(jssPreset());
// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
// this element has been added to the header.pug file
jss.options.insertionPoint = document.getElementById('mui-jss-insertion-point');

const appElement = document.getElementById('root');

const Redirect = () => {
  const location = useLocation();
  const flags = useFlags();
  const mdMfeRedesign = flags.mdMfeRedesignPilot;
  useEffect(() => {
    const url =
      location.pathname !== urls.dashboard
        ? `${getConfigValue('mfeContainerDomain')}/md${location.pathname}${
            location.search
          }`
        : `${getConfigValue('mfeContainerDomain')}/reporting${location.search}`;

    if (mdMfeRedesign && location.pathname !== urls.myStoreWebview) {
      window.location.replace(url);
      return null;
    }
  }, [location, mdMfeRedesign]);
  return null;
};

newTheme.palette.naranja = ClipTheme.palette.naranja;
(async () => {
  const LDProvider = await getLDProvider();
  ReactDOM.render(
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <Provider store={reduxStore}>
        {/* <SiftScript
          location={GetCurrentLocation}
        /> */}
        <ThemeProvider theme={ClipTheme}>
          <EmotionThemeProvider theme={ClipTheme}>
            <ClipThemeProvider theme={newTheme}>
              <DialogProvider>
                <LDProvider>
                  <Suspense fallback={<div>LOADING</div>}>
                    <Router>
                      <SiftScript />
                      <ScrollToTop />
                      <MobileRoutes />
                      <Redirect />
                    </Router>
                  </Suspense>
                </LDProvider>
              </DialogProvider>
            </ClipThemeProvider>
          </EmotionThemeProvider>
        </ThemeProvider>
      </Provider>
    </JssProvider>,
    appElement,
  );
})();

registerServiceWorker();
