export default (theme) => ({
  root: {},
  formControl: {},
  focused: {
    '&:not($error) + $fhRoot': {
      color: theme.palette.secondary ? theme.palette.secondary.main : '',
    },
    '&$colorPrimary:not($error) + $fhRoot': {
      color: theme.palette.primary ? theme.palette.primary.main : '',
    },
    '&$colorInherit:not($error) + $fhRoot': {
      color: 'inherit',
    },
  },
  disabled: {},
  underline: {
    '&:not($error):after': {
      borderBottomColor: theme.palette.secondary
        ? theme.palette.secondary.main
        : '',
    },
    '&$colorPrimary:not($error):after': {
      borderBottomColor: theme.palette.primary
        ? theme.palette.primary.main
        : '',
    },
    '&$colorInherit:not($error):after': {
      borderBottomColor: 'inherit',
    },
  },
  error: {},
  multiline: {},
  fullWidth: {},
  input: {
    paddingLeft: 8,
  },
  inputMarginDense: {},
  inputMultiline: {},
  inputType: {},
  inputTypeSearch: {},

  // FormControl
  fcRoot: {},
  fcDisabled: {},
  fcLabel: {},

  // FormHelperText
  fhRoot: {
    paddingLeft: 8,
  },
  fhError: {
    paddingLeft: 8,
  },
  fhDisabled: {},
  fhMarginDense: {},

  // InputLabel
  ilRoot: {
    paddingLeft: 8,
    '&$error': {
      color: theme.palette.text.secondary,
    },
  },
  ilFocused: {
    '&$ilRoot:not($error)': {
      color: theme.palette.secondary ? theme.palette.secondary.main : '',
    },
    '&$ilRoot$colorPrimary:not($error)': {
      color: theme.palette.primary ? theme.palette.primary.main : '',
    },
    '&$ilRoot$colorInherit:not($error)': {
      color: 'inherit',
    },
    '&$ilRoot$error': {
      color: theme.palette.error.main,
    },
  },
  ilFormControl: {},
  ilMarginDense: {},
  ilShrink: {},
  ilAnimated: {},

  colorInherit: {},
  colorPrimary: {},
});
