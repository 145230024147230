import axios from 'axios';

import {
  getRequestOptions,
  getRequestSwapOptions,
  getRequestApiSwapOptions,
} from '../../../helpers/fetchUtil';
import apiPaths from '../../../helpers/apiPaths';
import { app, getConfigValue } from '../../../config/config';

class BerlinService {
  static getCardBalance(card) {
    const options = getRequestOptions();
    const path = apiPaths.cardBalance.replace('{card}', card);
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static getCardTransactions(card, start_date, end_date) {
    const options = getRequestOptions();
    options.params = {
      start_date,
      end_date,
    };
    //old endpoint: apiPaths.cardMovements
    const path = apiPaths.cardTransactions.replace('{card}', card);
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static postAccountCreation(body) {
    const options = getRequestOptions();
    const path = apiPaths.onBoardingSignUp;
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static postAccountHandShake(body) {
    const options = getRequestOptions();
    const path = apiPaths.accountHandhsake;
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }
  
  static swapCardHandShake(body) {
    const options = getRequestApiSwapOptions();
    const path = apiPaths.swapCardHandshake;
    return axios.post(`${app.swapServices}${path}`, body, options);
  }

  static postUpgradeN3(body) {
    const options = getRequestOptions();
    const path = apiPaths.upgradeN3;
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static cardActivation(proxy_card, merchant_id) {
    const options = getRequestOptions();
    const path = apiPaths.cardActivation.replace('{proxy_card_id}', proxy_card);
    return axios.patch(
      `${app.berlinServices}${path}`,
      { merchant_id: merchant_id },
      options,
    );
  }

  static changePin(proxy_card, pin) {
    const options = getRequestOptions();
    const path = apiPaths.changePin.replace('{proxy_card_id}', proxy_card);
    return axios.patch(
      `${app.berlinServices}${path}`,
      { pin: btoa(pin) },
      options,
    );
  }

  static accountInformation(merchant_id) {
    const options = getRequestOptions();
    const path = apiPaths.accountInformation.replace(
      '{merchantId}',
      merchant_id,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static getMerchantInfo() {
    const options = getRequestOptions();
    const path = apiPaths.merchantInfo;
    return axios.get(`${app.apiUrl}${path}`, options);
  }

  static getWaitingList(merchant_id) {
    const options = getRequestOptions();
    const path = apiPaths.getWaitingList.replace('{merchant_id}', merchant_id);
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static postWaitingList(merchant_id, body) {
    const options = getRequestOptions();
    const path = apiPaths.waitingList.replace('{merchant_id}', merchant_id);
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static postSpeiOut(proxy_card_id, body) {
    const options = getRequestOptions();
    const path = apiPaths.speiOut.replace('{proxy_card_id}', proxy_card_id);
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static getInterbankAccountInfo(interbank_account) {
    const options = getRequestOptions();
    const path = apiPaths.interbankAccountValidation.replace(
      '{interbank_account}',
      interbank_account,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static postExistingMerchantsOnboarding(merchant_id, body) {
    const options = getRequestOptions();
    const path = apiPaths.existingMerchantsOnboarding.replace(
      '{merchant_id}',
      merchant_id,
    );
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static getMerchantEnrollmentStatus(merchant_id) {
    const options = getRequestOptions();
    const path = apiPaths.enrollmentStatus.replace(
      '{merchant_id}',
      merchant_id,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }
  
  static getMerchantEnrollmentInfo(merchant_id) {
    const options = getRequestOptions();
    const path = apiPaths.existingMerchantsOnboardingInfo.replace(
      '{merchant_id}',
      merchant_id,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }
    
    static getMerchantEnrollmentStatusV2() {
      const options = getRequestOptions();
      const path = apiPaths.enrollmentStatusV2;
      return axios.get(`${app.berlinServices}${path}`, options);
    }

    static getMerchantEnrollmentInfoV2() {
      const options = getRequestOptions();
      const path = apiPaths.existingMerchantsOnboardingInfoV2;
      return axios.get(`${app.berlinServices}${path}`, options);
  }

  static getDynamicCvv(proxy_card) {
    const options = getRequestOptions();
    const path = apiPaths.dynamicCvv.replace('{proxy_card_id}', proxy_card);
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static getPIN(proxy_card) {
    const options = getRequestOptions();
    const path = apiPaths.getPIN.replace('{proxy_card_id}', proxy_card);
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static getDocumentsUrl() {
    const options = getRequestOptions();
    const path = apiPaths.getDocumentsUrl;
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static postDocumentToS3(file, uploadInfo) {
    const formData = new FormData();
    Object.keys(uploadInfo.fields).forEach((key) =>
      formData.append(key, uploadInfo.fields[key]),
    );
    formData.append('file', file);
    return axios.post(uploadInfo.url, formData);
  }

  static createCdaPin(body) {
    const options = getRequestOptions();
    const path = apiPaths.createCdaPIN;
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static confirmCdaPin(body) {
    const options = getRequestOptions();
    const path = apiPaths.createCdaPIN;
    return axios.patch(`${app.berlinServices}${path}`, body, options);
  }

  static postLogin(body) {
    const options = getRequestOptions();
    const path = apiPaths.accountLogin;
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static speiDetails(reference) {
    const options = getRequestOptions();
    const path = apiPaths.speiDetails.replace('{reference}', reference);
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  // OnBoarding 2.0
  static accountInformationV2(merchant_id) {
    const options = getRequestOptions();
    const path = apiPaths.accountInformationV2.replace(
      '{merchantId}',
      merchant_id,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }
  // Swap API
  static postSwapEvent(user_external_id, event_type, event_properties) {
    const options = getRequestApiSwapOptions();
    const path = apiPaths.swapEvent;
    const body = {
      user_external_id,
      device_id: 'web+'+user_external_id,
      event_type,
      event_properties,
      platform: 'web',
      ip: 'web',
      os_version: 'web',
      app_version: 'web',
      device_manufacturer: 'Berlin',
    }
    return axios.post(`${app.swapServices}${path}`, body, options);
  }

  static postSwapAccountLogin(email, phone, password) {
    const options = getRequestApiSwapOptions();
    const path = apiPaths.swapAccountLogin;
    const body = {
      email,
      phone,
      password,
      os_name: 'android',
      os_version: 'Tiramisu',
      app: 'Berlin',
      app_version: '2022.12.28',
      device_id: 'Berlin',
      device_model: 'Berlin',
    }
    return axios.post(`${app.swapServices}${path}`, body, options);
  }
  
  static tokenSeed(body) {
    const options = getRequestApiSwapOptions();
    const path = apiPaths.swapAuthorizerTokenSeed;
    return axios.post(`${app.swapServices}${path}`, body, options);
  }
  
  static actionToken(body) {
    const options = getRequestApiSwapOptions();
    const path = apiPaths.swapAuthorizerActionToken;
    return axios.put(`${app.swapServices}${path}`, body, options);
  }
  
  static accountInformationV3(body) {
    const options = getRequestApiSwapOptions();
    const path = apiPaths.swapCardInformation;
    return axios.post(`${app.swapServices}${path}`, body, options);
  }
  
  static getSwapCardNip(body) {
    const options = getRequestApiSwapOptions();
    const path = apiPaths.swapCardNip;
    return axios.post(`${app.swapServices}${path}`, body, options);
  }

  static postRequestCard(body) {
    const options = getRequestOptions();
    const path = apiPaths.cardRequest;
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static accountHolder(accountHolderId) {
    const options = getRequestOptions();
    const path = apiPaths.accountHolder.replace(
      '{account_holder_id}',
      accountHolderId,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static physicalCardActivation(proxyAccountId, payload) {
    const options = getRequestOptions();
    const path = apiPaths.physicalCardActivation.replace(
      '{proxy_account_id}',
      proxyAccountId,
    );
    return axios.patch(`${app.berlinServices}${path}`, payload, options);
  }

  static physicalCardActivationV2(proxyAccountId, payload) {
    const options = getRequestOptions();
    const path = apiPaths.physicalCardActivationV2.replace(
      '{proxy_account_id}',
      proxyAccountId,
    );
    return axios.patch(`${app.berlinServices}${path}`, payload, options);
  }

  static getCardBalanceV2(proxy_account_id) {
    const options = getRequestOptions();
    const path = apiPaths.cardBalanceV2.replace(
      '{proxy_account_id}',
      proxy_account_id,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static changePinV2(proxy_account_id, pin) {
    const options = getRequestOptions();
    const path = apiPaths.changePinV2.replace(
      '{proxy_account_id}',
      proxy_account_id,
    );
    return axios.patch(
      `${app.berlinServices}${path}`,
      { pin: btoa(pin) },
      options,
    );
  }

  static changePinV3(proxy_account_id, pin) {
    const options = getRequestOptions();
    const path = apiPaths.changePinV3.replace(
      '{proxy_account_id}',
      proxy_account_id,
    );
    return axios.patch(`${app.berlinServices}${path}`, { pin }, options);
  }

  static getPINV2(proxy_account_id) {
    const options = getRequestOptions();
    const path = apiPaths.getPINV2.replace(
      '{proxy_account_id}',
      proxy_account_id,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static getPINV3(proxy_account_id) {
    const options = getRequestOptions();
    const path = apiPaths.getPINV3.replace(
      '{proxy_account_id}',
      proxy_account_id,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static getDynamicCvvV2(proxy_account_id) {
    const options = getRequestOptions();
    const path = apiPaths.dynamicCvvV2.replace(
      '{proxy_account_id}',
      proxy_account_id,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static getDynamicCvvV3(proxy_account_id) {
    const options = getRequestOptions();
    const path = apiPaths.dynamicCvvV3.replace(
      '{proxy_account_id}',
      proxy_account_id,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static postSpeiOutV2(proxy_account_id, body) {
    const options = getRequestOptions();
    const path = apiPaths.speiOutV2.replace(
      '{proxy_account_id}',
      proxy_account_id,
    );
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static postSpeiOutV3(proxy_account_id = null, proxy_card_id = null, body = {}) {
    const options = getRequestOptions();
    let path;
    if (proxy_account_id) {
      path = apiPaths.speiOutV2.replace('{proxy_account_id}', proxy_account_id);
    } else {
      path = apiPaths.speiOut.replace('{proxy_card_id}', proxy_card_id);
    }

    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static postSaveContact(body) {
    const options = getRequestOptions();
    const path = apiPaths.contacts;
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static putUpdateContact(body) {
    const options = getRequestOptions();
    const path = apiPaths.contacts;
    return axios.put(`${app.berlinServices}${path}`, body, options);
  }

  static getContacts() {
    const options = getRequestOptions();
    const path = apiPaths.contacts;
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static getCardTransactionsV2(proxy_account_id, start_date, end_date) {
    const options = getRequestOptions();
    options.params = {
      start_date,
      end_date,
    };
    const path = apiPaths.cardMovementsV2.replace(
      '{proxy_account_id}',
      proxy_account_id,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }
  
  static getSwapCardTransactions(user_external_id, after, before, type='merged', limit=100, _skip=0) {
    const options = getRequestApiSwapOptions();
    const path = apiPaths.swapCardMovements;
    const body = {
      user_external_id, 
      after, 
      before, 
      type, 
      limit, 
      _skip,
    } 
    return axios.post(`${app.swapServices}${path}`, body, options);
  }

  static getCurpFromData(body) {
    const options = getRequestOptions();
    const path = apiPaths.getCurpFromData;
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static getDataFromCurp(reference) {
    const options = getRequestOptions();
    const path = apiPaths.getDataFromCurp.replace(
      '{legal_identifier}',
      reference,
    );
    return axios.get(`${app.berlinServices}${path}`, options);
  }

  static postAccountRegistration(body) {
    const options = getRequestOptions();
    const path = apiPaths.accountRegistration;
    return axios.post(`${app.berlinServices}${path}`, body, options);
  }

  static getSwapLink(body) {
    const options = getRequestSwapOptions();
    const url = new URL(getConfigValue('berlinTandemEndpoint'));
    return axios.post(url, body, options);
  }

  static getMerchantNonce(body) {
    const options = getRequestOptions();
    const url = `${app.apiUrlv3}${apiPaths.mlmNonce}`;
    return axios.post(url, body, options);
  }
}

export default BerlinService;
