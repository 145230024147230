import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CashAdvanceDesktop from '../../../../assets/images/elegibleBannerCA.svg';
import { styles } from './styles';
import text from '../text';

export const Description = (props) => {
  const { classes } = props;

  const typographyStyles = {
    body1: classes.typographyRoot,
    title: classes.typographyTitle,
  };
  return (
    <React.Fragment>
      <Typography variant={'title'} classes={typographyStyles}>
        {text.titleDescription}
      </Typography>
      <Typography variant={'body1'} classes={typographyStyles}>
        {text.messageOneDescription}
      </Typography>
      <img
        className={clsx(classes.image, classes.desktopImage)}
        src={CashAdvanceDesktop}
        alt={text.cashAdvance}
      />
      <Typography variant={'body1'} classes={typographyStyles}>
        {text.messageTwoDescription}
      </Typography>
    </React.Fragment>
  );
};
Description.propTypes = {
  classes: PropTypes.object.isRequired,
};
Description.defaultProps = {
  classes: {},
};

export default withStyles(styles)(Description);
