import React, { useState, useContext, useEffect, useCallback } from 'react';
import { styles } from './styles';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { ClipButton } from '@clipmx/clip-storybook';
import { ClipTypography } from '@clipmx/clip-storybook';
import { Forms } from '../../../../constants/AppConstants';
import { setContactsFromService } from '../../redux/actions';
import { DialogContext } from '@clipmx/clip-storybook/dist/components/Dialog/dialogContext';
import text from './text';
import EmptyState from './EmptyState';
import ContactCard from './ContactCard';
import BerlinService from '../../service';
import Loader from '../../BerlinSpinner/Loader';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ItemDialog, {
  ActionButtons,
} from '../../../Catalog/ItemsTable/ItemDialog';
import {
  Add,
  AccountCircleOutlined,
  Edit,
} from '@clipmx/clip-storybook/dist/components/Icons';

const useStyles = makeStyles(styles);


const Contacts = (props) => {
  const {
    action,
    openEdit,
    setFromContact,
    setContactsFromService,
    changeFormValue,
    loadContacts,
    setLoadContact,
  } = props;
  const media = window.matchMedia('(max-height: 630px)');
  const classes = useStyles(props);
  const { dialogActions } = useContext(DialogContext);
  const [empty, setEmpty] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [contacts, setContacts] = useState([]);

  const handleSelectContact = (e) => {
    changeFormValue('clabe', e.recipient_account);
    changeFormValue('name', e.recipient_name);
    changeFormValue('alias', e.nickname);
    changeFormValue('bank', e.recipient_bank);
    setFromContact();
    action(2);
  };

  useEffect(() => {
    if (loadContacts) {
      setLoading(true);
      getContacts();
    }
  }, [loadContacts]);

  const getContacts = () => {
    BerlinService.getContacts()
      .then((response) => {
        setLoading(false);
        setLoadContact(false);
        setContactsFromService(response.data.message.contact);
        if (response.data.message.contact.length === 0) {
          setEmpty(true);
        } else {
          setContacts(response.data.message.contact);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setEmpty(true);
        }
        setLoading(false);
        setLoadContact(false);
        console.log('error loading contacts');
      });
  };
const deleteContact = async (id) => {
    const body = {
      recipient_id: id,
      is_remove: true,
    };
    try {
      await BerlinService.putUpdateContact(body);
      setLoadContact(true);
      setEdit(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleMenuDelete = useCallback((contact) => {
    dialogActions.registerDialog('publishDialog', {
      open: true,
      type: 'form',
      title: text.deleteTitle,
      className: classes.dialog,
      dialogActions: (
        <ActionButtons
          buttons={[
            {
              caption: text.captionCancel,
              variant: 'outlined',
              color: 'primary',
              action: () => setDeleteId(null),
            },
            {
              caption: text.captionDelete,
              color: 'primary',
              className: classes.dialogPrimary,
              action: () => {
                deleteContact(contact.id);
                setDeleteId(null);
              },
            },
          ]}
        />
      ),
      classes: {
        paper: classes.dialogPaper,
      },
      children: (
        <ItemDialog>{text.delete.replace('alias', contact.alias)}</ItemDialog>
      ),
      name: 'publishDialog',
    });
  }, [setDeleteId, classes, deleteContact, dialogActions]);

  const renderContacts = useCallback(() => {
    return contacts.map((e) => (
      <ContactCard
        onEdit={edit}
        onClick={() => handleSelectContact(e)}
        onDelete={(e) => handleMenuDelete(e)}
        openEdit={openEdit}
        alias={e.nickname}
        name={e.recipient_name}
        bank={e.recipient_bank}
        id={e.recipient_id}
        clabe={e.recipient_account}
      />
    ));
  }, [contacts, handleSelectContact, handleMenuDelete, openEdit, edit]);

  return (
    <div className={classes.container}>
      <ClipTypography
        variant={'body1'}
        component={'p'}
        className={classes.actionTitle}
      >
        {text.title}
      </ClipTypography>
      <ClipButton onClick={() => action(1)} className={classes.speiOutButton}>
        <Add className={classes.actionButtonIcon} />
        {text.newAccount}
      </ClipButton>
      {loading ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        [
          <div className={classes.contactsTitleContainer}>
            <AccountCircleOutlined className={classes.contactsIcon} />
            <ClipTypography
              variant={'body1'}
              className={classes.contactsTitle}
              component={'p'}
            >
              {empty ? text.emptyText : text.savedContacts}
            </ClipTypography>
            {!empty && (
              <Edit
                onClick={() => setEdit(!edit)}
                className={classes.editIcon}
              />
            )}
          </div>,
          <div className={classes.bottomContainer}>
            {empty ? <EmptyState /> : renderContacts()}
          </div>,
        ]
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setContactsFromService: (contacts) =>
      dispatch(setContactsFromService(contacts)),
    changeFormValue: (key, value) =>
      dispatch(change(Forms.BERLIN_SPEI_OUT, key, value)),
  };
};

export default connect(null, mapDispatchToProps)(Contacts);
