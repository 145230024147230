import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withWidth } from '@material-ui/core';
import {
  DashboardNavConstants,
  LoansTabs,
  PreapprovalStatus,
} from '../../../../constants/AppConstants';
import urls from '../../../../helpers/urls';
import getUrl from '../../../../helpers/getUrl';
import { setNavItem, setNavTab } from '../../../../actions/DashboardNavActions';
import DashboardTab from '../../../../components/common/DashboardTabs';
import Tab from '../../../../components/common/DashboardTabs/Tab';
import { getPreapprovals } from '../../redux/selectors';
import text from '../../../../components/loans/Commons/Form/text';
import * as Styled from '../../../../components/loans/Commons/Form/styled';
import { styles } from '../Form/styles';
import LegalIcon from './LegalIcon';
import FAQsIcon from './FAQsIcon';
import MyPrestaClipIcon from './MyPrestaClipIcon';
import NewOfferIcon from './NewOfferIcon';

export class LoansContent extends PureComponent {
  static propTypes = {
    hasLoans: PropTypes.bool.isRequired,
    tab: PropTypes.string.isRequired,
  };

  hasOffers = (preapprovals) =>
    preapprovals.filter(
      (preapproval) =>
        ![PreapprovalStatus.DECLINED].includes(preapproval.status) &&
        !preapproval.loan_id,
    ).length > 0;
  isElegible = (preapprovals) =>
    preapprovals.length === 0 ||
    preapprovals.length ===
      preapprovals.filter(
        ({ status, loan_id }) =>
          [
            PreapprovalStatus.DECLINED,
            PreapprovalStatus.CANCELED,
            PreapprovalStatus.CANCELLED,
          ].includes(status) && !loan_id,
      ).length;

  componentDidMount() {
    const { setNavItem, setNavTab, tab } = this.props;
    setNavItem(DashboardNavConstants.LOANS);
    setNavTab(tab);
  }

  render() {
    const {
      classes,
      hasLoans,
      children,
      preapprovals = [],
      tab,
      width,
    } = this.props;

    const isMobilePath = this.props.location.pathname.startsWith(urls.mobile);

    const baseLocation = isMobilePath ? urls.mobile : '';
    const showNewOffer =
      this.isElegible(preapprovals) || this.hasOffers(preapprovals);
    return (
      <React.Fragment>
        {!isMobilePath && <Styled.Title data-testid="title">{text.tab}</Styled.Title>}
        {(!isMobilePath || !['sm', 'xs'].includes(width)) && (
          <DashboardTab>
            {showNewOffer && (
              <Tab
                tab={LoansTabs.NEW_OFFER}
                url={`${baseLocation}${urls.loans}`}
                name={text.tabOffers}
              />
            )}
            {hasLoans && (
              <Tab
                tab={LoansTabs.CREDI_CLIP}
                url={`${baseLocation}${urls.loansMyPrestaClip}`}
                name={text.tabMyCrediClip}
              />
            )}
            <Tab
              tab={LoansTabs.FAQS}
              url={`${baseLocation}${urls.loansFAQs}`}
              name={text.tabFAQ}
            />
            <Tab
              tab={LoansTabs.TERMS}
              url={`${baseLocation}${urls.termConditionsLoans}`}
              name={text.tabTerms}
            />
          </DashboardTab>
        )}
        <div data-testid="container" className={clsx(classes.container, { mobile: isMobilePath })}>
          {children}
        </div>

        {isMobilePath && ['sm', 'xs'].includes(width) && (
          <Styled.BottomNav value={tab}>
            {showNewOffer && (
              <Styled.BottomNavAction
                icon={<NewOfferIcon active={tab === LoansTabs.NEW_OFFER} />}
                onClick={() => {
                  this.props.history.push(getUrl(`${baseLocation}${urls.loans}`));
                }}
                value={LoansTabs.NEW_OFFER}
              />
            )}
            {hasLoans && (
              <Styled.BottomNavAction
                icon={
                  <MyPrestaClipIcon active={tab === LoansTabs.CREDI_CLIP} />
                }
                onClick={() => {
                  this.props.history.push(
                    getUrl(`${baseLocation}${urls.loansMyPrestaClip}`)
                  );
                }}
                value={LoansTabs.CREDI_CLIP}
              />
            )}
            <Styled.BottomNavAction
              icon={<FAQsIcon active={tab === LoansTabs.FAQS} />}
              onClick={() => {
                this.props.history.push(getUrl(`${baseLocation}${urls.loansFAQs}`));
              }}
              value={LoansTabs.FAQS}
            />
            <Styled.BottomNavAction
              icon={<LegalIcon active={tab === LoansTabs.TERMS} />}
              onClick={() => {
                this.props.history.push(
                  getUrl(`${baseLocation}${urls.termConditionsLoans}`)
                );
              }}
              value={LoansTabs.TERMS}
            />
          </Styled.BottomNav>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  setNavItem,
  setNavTab,
};
const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
  };
};

const ThemedLoansContent = withWidth()(
  withTheme()(withStyles(styles)(LoansContent)),
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ThemedLoansContent)
);