import { createMuiTheme } from '@material-ui/core/styles';

var _polished = require('polished');

export const BerlinTheme = createMuiTheme({
  spacing: {
    unit: 16,
  },
  palette: {
    primary: {
      main: '#FC4C02',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#09A5B5',
      contrastText: '#FFF',
    },
    text: {
      primary: '#000B11',
      secondary: (0, _polished.rgba)('#000B11', 0.64),
      auxiliar: '#66737b',
    },
    positive: {
      main: '#29660a',
    },
    success: {
      main: '#03DAC6',
    },
    error: {
      main: '#F54B5E',
    },
    warning: {
      main: '#F8C51C',
    },
    background: {
      paper: '#FFF',
      default: '#FAFAFA',
    },
    action: {
      active: (0, _polished.rgba)('#FC4C02', 0.12),
      hover: (0, _polished.rgba)('#FC4C02', 0.12),
      hoverOpacity: 0.12,
      selected: (0, _polished.rgba)('#FC4C02', 0.14),
    },
    naranja: {
      light: '#FEEDE6',
      bloodOrange: '#FC4C02',
      teal: '#0B99AC',
      slate: '#455A64',
      lightGreyBrown: '#6E6E6E',
      greyBrown: '#4A4A4A',
      cerulean: '#0288D1',
      blueGrey: '#78909C',
      grey: '#B1B1B1',
      lightGrey: '#ECEFF1',
      iceBlue: '#F8FBFE',
      neonPink: '#F54B5E',
      neonTeal: '#03DAC6',
      yellow: '#F8C51C',
      bannerShadow: 'rgba(100,100,100,0.22)',
      contentShadow: 'rgba(0,0,0,0.50)',
      boxShadow: 'rgba(0,0,0,0.16)',
    },
  },
  customShadows: {
    toolbar: '0 8px 32px 0 rgba(0,0,0,0.08)',
    berlinContainers: '0 2px 21px -8px rgba(102, 115, 123, 0.25)',
    berlinButtonsShadow: '0 2px 8px -2px rgba(102, 115, 123, 0.25)',
    oneStepNavigatorShadow: '0 2px 4px 0 rgba(102, 115, 123, 0.26)',
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: ['Barlow', 'Helvetica', 'Arial', 'sans-serif'].join(','),

    title: {
      fontWeight: '600',
      fontSize: '1.5rem',
      textAlign: 'center',
      width: '100%',
      marginTop: '1.5rem',
      marginBottom: '1rem',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      boxSizing: 'border-box',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '1rem',
      textAlign: 'left',
      width: '100%',
      lineHeight: 'normal',
      boxSizing: 'border-box',
      marginBottom: '1rem',
      whiteSpace: 'pre-wrap',
    },
    display1: {
      fontWeight: 'normal',
      fontSize: '0.875rem',
      textAlign: 'center',
      width: '100%',
      marginBottom: '5px',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      lineHeight: '1rem',
      boxSizing: 'border-box',
      color: '#66737b',
    },
    display2: {
      fontWeight: '600',
      fontSize: '1rem',
      textAlign: 'center',
      width: '100%',
      marginBottom: '1rem',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      lineHeight: '1rem',
      boxSizing: 'border-box',
    },
    display3: {
      fontWeight: '500',
      fontSize: '1.5rem',
      textAlign: 'center',
      width: '100%',
      marginBottom: '1rem',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      lineHeight: '1.5rem',
      boxSizing: 'border-box',
    },
    display4: {
      fontWeight: '500',
      fontSize: '1.125rem',
      textAlign: 'center',
      width: 'auto',
      paddingLeft: '0.225rem',
      paddingRight: '0.225rem',
      lineHeight: '1.5rem',
      boxSizing: 'border-box',
      color: '#66737b',
    },
    headline: {
      fontWeight: '500',
      fontSize: '1.125rem',
      textAlign: 'center',
      width: 'auto',
      paddingLeft: '0.225rem',
      paddingRight: '0.225rem',
      lineHeight: '1.5rem',
      boxSizing: 'border-box',
    },
    subheading: {
      color: '#32454f',
      fontWeight: 'normal',
      fontSize: '1rem',
      textAlign: 'left',
      width: '100%',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      lineHeight: '1.5rem',
      boxSizing: 'border-box',
    },
    body2: {
      fontWeight: '500',
      fontSize: '1rem',
      textAlign: 'left',
      width: '100%',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      lineHeight: '1.5rem',
      boxSizing: 'border-box',
      marginBottom: '1.1rem',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        minHeight: '2.8rem !important',
        height: 'auto',
        borderRadius: '8px',
        fontWeight: '600',
        textTransform: 'none',
        fontSize: '1.125rem',
        padding: '0px 16px',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#B1B1B1',
        '&$focused': {
          color: '#FC4C02 !important',
        },
      },
      focused: {
        '&$focused': {
          color: '#FC4C02',
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: '#FC4C02 !important',
        },
      },
    },
    MuiList: {
      root: {
        width: '100%',
      },
    },
    MuiListItem: {
      root: {
        paddingLeft: '0.5rem',
      },
    },
    MuiListItemText: {
      root: {
        padding: 0,
      },
    },
    MuiListItemIcon: {
      root: {
        fontSize: 'small',
        margin: 0,
        color: '#E6E8E9',
      },
    },
    MuiPaper: {
      root: {
        borderRadius: '16px !important',
      },
    },
    MuiSelect: {
      root: {
        paddingLeft: '8px',
      },
    },
  },
});
