import {
  CLEAR_DASHBOARD,
  FETCHING_DASHBOARD,
  SET_DASHBOARD_DATA,
  SET_DASHBOARD_STATE,
} from '../actions/Types';

const initialState = {
  data: {
    recent: {
      amount: 0,
      date: '',
      transaction: [],
      transactionCount: 0,
    },
    tabs: {
      bankAccount: false,
      businessAddress: false,
    },
    merchantName: '',
  },
  loading: false,
  state: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_DASHBOARD:
      return initialState;
    case FETCHING_DASHBOARD:
      return { ...state, loading: action.payload.loading };
    case SET_DASHBOARD_DATA:
      return { ...state, data: action.payload };
    case SET_DASHBOARD_STATE:
      return { ...state, state: action.payload };
    default:
      return state;
  }
}

export const getState = (state) => state.state;
