import { GET_APP_TEXT } from '../actions/Types';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_APP_TEXT:
      return action.payload;
    default:
      return state;
  }
}
