import React from 'react';

/**
 * HOC that forwards the classes object passed as argument to the classes prop of the Component.
 * All other props are forwarded as well.
 *
 * Usage:
  ```
  import React from 'react';
  import styled from '@emotion/styled';
  import Card from 'components/npm/clip-ui/Card';
  import styledWithClasses from 'helpers/styledWithClasses';

  export const StyledCard = styledWithClasses(
    styled(Card)({
      '& .card-content': {
        padding: '0',
        '&:last-child': { paddingBottom: 0 },
      },
    }),
    { contentRoot: 'card-content' }
  );
  ```

 * @param {React.Component} Component A component to pass the classes prop
 * @param {Object} classes An object containing a mapping
 */
export default (Component, classes) => {
  return ({ classes: restClasses, children, ...moreProps }) => {
    return (
      <Component classes={mergeClasses(classes, restClasses)} {...moreProps}>
        {children}
      </Component>
    );
  };
};

function mergeClasses(css1, css2) {
  const result = Object.assign({}, css1);
  for (let k in css2) {
    const className = css2[k];
    if (typeof className === 'string') {
      if (typeof result[k] === 'string') {
        result[k] += ' ' + className;
      } else {
        result[k] = className;
      }
    }
  }
  return result;
}
