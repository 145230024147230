import styled from '@emotion/styled';
import { Field } from 'redux-form';
import { SubmitButton as CommonSubmitButton } from 'components/common/Buttons';

export const RegistroDocumentosContainer = styled.div(({ theme }) => ({
  background: 'white',
  width: '21.5rem',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  marginTop: theme.spacing.unit * 4,
}));

export const DocumentosContainer = styled.div(() => ({
  background: 'white',
  width: '21.5rem',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
}));

export const VerificaDatos = styled.div(({ theme }) => ({
  width: '21.5rem',
  height: '3.625rem',
  fontSize: '1.5rem',
  lineHeight: '1.625rem',
  color: theme.palette.text.primary,
  textAlign: 'justify',
}));

export const VerificaDescripcion = styled.div(({ theme }) => ({
  marginTop: '0.5rem',
  width: '21.5rem',
  height: '2.625rem',
  fontSize: '1rem',
  lineHeight: '1.31rem',
  color: theme.palette.text.primary,
  textAlign: 'justify',
}));

export const VerificaSubDescripcion = styled.div(({ theme }) => ({
  width: '21.5rem',
  height: 'auto',
  fontSize: '1rem',
  lineHeight: '1rem',
  color: theme.palette.text.auxiliar,
  marginBottom: theme.spacing.unit * 2.8,
  textAlign: 'justify',
}));

export const FormField = styled(Field)(({ theme }) => ({
  width: '21.5rem',
  marginBottom: '3rem',
}));

export const FormFieldHalf = styled(Field)(({ theme }) => ({
  width: '9.5rem',
  marginBottom: theme.spacing.unit * 3,
}));

export const Direccion = styled.div(({ theme }) => ({
  width: '4.75rem',
  height: '1.375rem',
  fontSize: '1.125rem',
  lineHeight: '1.625rem',
  color: theme.palette.text.primary,
  fontWeight: '600',
}));

export const Contacto = styled.div(({ theme }) => ({
  width: '21.5rem',
  height: 'auto',
  fontSize: '1.125rem',
  lineHeight: '1.625rem',
  color: theme.palette.text.primary,
  fontWeight: '600',
}));

export const ContactoDescripcion = styled.div(({ theme }) => ({
  marginTop: theme.spacing.unit,
  width: '21.5rem',
  height: 'auto',
  fontSize: '1rem',
  lineHeight: '1rem',
  color: theme.palette.text.auxiliar,
  marginBottom: theme.spacing.unit * 2.8,
}));

export const VerificaDireccion = styled.div(({ theme }) => ({
  marginTop: theme.spacing.unit,
  width: '21.5rem',
  height: 'auto',
  fontSize: '1rem',
  lineHeight: '1rem',
  color: theme.palette.text.auxiliar,
  marginBottom: theme.spacing.unit * 2,
}));

export const CodigoPostalContainer = styled.div(({ theme }) => ({
  marginTop: theme.spacing.unit * 0.5,
  width: '21.5rem',
  height: 'auto',
  fontSize: '1rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const BaseButtonStyles = ({ theme }) => ({
  marginBottom: theme.spacing.unit * 3,
  width: '21.5rem',
});

export const SubmitButton = styled(CommonSubmitButton)(BaseButtonStyles);

export const GeneroRadioLabel = styled.label((props) => ({
  width: '10rem',
  height: '3.125rem',
  fontSize: '1rem',
  lineHeight: '3.125rem',
  borderRadius: '12px',
  background: props.checked ? '#feede6' : 'white',
  alignSelf: 'center',
}));

export const GeneroContainer = styled.div(({ theme }) => ({
  marginTop: theme.spacing.unit * 0.5,
  width: '21.5rem',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing.unit * 3,
}));

export const GeneroRadioContainer = styled.div((props) => ({
  width: '21.5rem',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
}));

export const GeneroLabel = styled.div((props) => ({
  width: '21.5rem',
  height: '1.188rem',
  fontSize: '1rem',
  textAlign: 'left',
  color: '#282321',
}));
