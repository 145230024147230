import styled from '@emotion/styled';

const regularText = '#000b11';
const secondaryText = '#32454f';

export const NeedHelp = styled.div(() => ({
  width: '19.5rem',
  height: '1.625rem',
  fontSize: '1.25rem',
  fontWeight: '500',
  color: regularText,
  alignSelf: 'center',
  textAlign: 'center',
  marginBottom: '1.5rem',
}));

export const ChangeNipLeftContainer = styled.div(() => ({
  width: 'auto',
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: '0 2px 8px -2px rgba(102,115,123,0.25)',
  borderRadius: '12px',
  margin: '1rem',
  backgroundColor: 'white',
  padding: '1rem',
  border: ' solid 1px #e6e8e9',
}));

export const ChangeNip = styled.div(() => ({
  width: 'auto',
  fontSize: '1.125rem',
  fontWeight: '500',
  color: regularText,
  alignSelf: 'left',
  textAlign: 'left',
}));

export const HelpContacts = styled.div(() => ({
  width: '19.5rem',
  height: '7.5rem',
  fontSize: '1rem',
  color: regularText,
  alignSelf: 'center',
  textAlign: 'center',
  marginBottom: '1rem',
}));

export const IDReminder = styled.div(() => ({
  width: '19.5rem',
  height: '3.063rem',
  fontSize: '1rem',
  color: '#0288d1',
  alignSelf: 'center',
  textAlign: 'center',
  lineHeight: '3.063rem',
  borderRadius: '2px',
  border: 'solid 1 px rgba(2, 136, 209, 0.05)',
  backgroundColor: 'rgba(2, 136, 209, 0.05)',
  marginBottom: '1rem',
}));

export const WhatsappContact = styled.div(() => ({
  width: '19.5rem',
  height: 'auto',
  fontSize: '1rem',
  color: secondaryText,
  alignSelf: 'center',
  textAlign: 'center',
  lineHeight: '1.313rem',
  fontWeight: '500',
  borderRadius: '2px',
  marginBottom: '0.5rem',
}));

export const WhatsappNumber = styled.div(() => ({
  width: '8.75rem',
  height: '1.938rem',
  fontSize: '1.5rem',
  color: regularText,
  alignSelf: 'center',
  textAlign: 'center',
  lineHeight: '1.938rem',
  fontWeight: '500',
  borderRadius: '2px',
  marginLeft: '0.75rem',
}));

export const PhoneContainer = styled.div(() => ({
  width: '19.5rem',
  height: 'auto',
  fontSize: '1.5rem',
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1.5rem',
  paddingBottom: '3.5rem',
}));
export const PhoneIcon = styled.img(() => ({
  width: '1.5rem',
  height: '1.5rem',
  objectFit: 'contain',
}));
export const RightArrow = styled.img(() => ({
  width: '3rem',
  height: '3rem',
  objectFit: 'contain',
}));
export const LockIcon = styled.img(() => ({
  width: '1.5rem',
  height: '1.5rem',
  objectFit: 'contain',
  marginRight: '1rem',
  marginLeft: '1rem',
}));
export const BerlinContainer = styled.div(({ theme }) => ({
  background: '#F2F3F3',
  width: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
}));

export const CardActionsContainer = styled.div(({ theme }) => ({
  width: '100%',
  maxWidth: '19.5rem',
  height: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'baseline',
  alignSelf: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing.unit * 1,
}));

export const CardActionButton = styled.button(({ theme }) => ({
  background: 'white',
  width: theme.spacing.unit * 2.9,
  height: theme.spacing.unit * 2.9,
  display: 'flex',
  marginBottom: '10px',
  margin: 'auto',
  color: theme.palette.text.primary,
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'center',
  borderRadius: '12px',
  boxShadow: theme.customShadows.berlinButtonsShadow,
  border: 'solid 1px #e6e8e9',
  '&:disabled': {
    opacity: '50%',
  },
}));

export const TransfersIcon = styled.img(({ theme }) => ({
  width: theme.spacing.unit * 1.5,
  height: theme.spacing.unit * 1.5,
  margin: 'auto',
  objectFit: 'contain',
}));
