export const styles = (theme) => {
  const rootStyles = {
    container: {
      minHeight: `calc(100vh - ${theme.spacing(12.25)}px)`,
      width: `calc(100% - ${theme.spacing(6)}px)`,
      margin: `${theme.spacing(5.25)}px ${theme.spacing.unit * 3}px
      ${theme.spacing.unit * 7}px ${theme.spacing.unit * 3}px`,
    },
    buttonContainer: {
      display: 'flex',
      marginTop: 'auto',
      justifyContent: 'flex-end',
      '& > button': {
        fontSize: theme.spacing(2),
      },
    },
    formContent: {
      minHeight: 'calc(100vh - 130px)',
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      '& > div': {
        marginBottom: theme.spacing(2),
      },
    },
    text: {
      fontSize: '14px',
      [theme.breakpoints.up(304)]: {
        fontSize: '15px',
      },
    },
    adorment: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: `${theme.palette.orange.base}!important`,
      '& svg': {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0),
        [theme.breakpoints.up(304)]: {
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(0.5),
          width: theme.spacing(3),
          height: theme.spacing(3),
        },
      },
      '& span': {
        alignItems: 'normal',
      },
      '& > button': {
        color: `${theme.palette.orange.base}!important`,
      },
    },
  };
  const typographyStyles = {
    typographyTitle: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  };
  const inputStyles = {
    inputAdornment: {
      color: `${theme.palette.orange.base}!important`,
      '& svg': {
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginLeft: theme.spacing(0.5),
        [theme.breakpoints.up(304)]: {
          marginLeft: theme.spacing(0),
          width: theme.spacing(0),
          height: theme.spacing(0),
        },
      },
      '& span': {
        alignItems: 'normal',
      },
      '& > button': {
        color: `${theme.palette.orange.base}!important`,
      },
    },
    helpText: {
      display: 'none',
      [theme.breakpoints.up(304)]: {
        display: 'flex',
      },
    },
  };
  const tooltipStyles = {
    tooltipPaper: {
      borderRadius: `${theme.spacing(2)}px ${theme.spacing(
        2,
      )}px 0px 0px!important`,
    },
    tooltipContainer: {
      width: '100%',
      height: 'auto',
      alignSelf: 'center',
      backgroundColor: theme.palette.white,
      overflow: 'auto',
      boxSizing: 'border-box',
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
        2,
      )}px ${theme.spacing(3)}px`,
      display: 'flex',
      flexFlow: 'column',
    },
    tooltipImage: {
      margin: `${theme.spacing(2)}px auto ${theme.spacing(2)}px!important`,
      width: theme.spacing(24),
      height: theme.spacing(15),
    },
    tooltipButtonContainer: {
      alignSelf: 'flex-end',
      marginTop: theme.spacing(3),
      '& > button': {
        fontSize: theme.spacing(2),
      },
    },
  };
  return {
    ...rootStyles,
    ...typographyStyles,
    ...inputStyles,
    ...tooltipStyles,
  };
};
