export default {
  requestTitle: 'Clip Card',
  requestSubtitle:
    'La tarjeta de débito sin costo con la que podrás acceder a tus ganancias más fácil y rápido.',
  requestBullets: [
    'Pídela gratis y actívala en 5 minutos.',
    'Realiza compras físicas o en línea, seguras. ',
    'Retira sin comisión en supermercados y tiendas de autoservicio.',
  ],
  buttonRequest: 'Quiero mi Clip Card',
  buttonTrack: 'Ver solicitud',
  buttonAlready: 'Ya tengo mi Clip Card',
  requestFlowTitle: 'Quiero mi Clip Card',
};
