import { getToken } from './tokenHelpers';
import { getConfigValue } from '../config/config';

/**
 * Returns an options obj with necessary headers to make an authenticated
 * request to API Gateway
 **/
export const getRequestOptions = function () {
  const token = getToken();
  const headers = {
    Accept: 'application/vnd.com.payclip.v1+json',
  };

  if (token) {
    headers.Authorization = token;
  }

  return { headers };
};

export const getRequestOptionsV2 = function () {
  const token = getToken();
  const headers = {
    Accept: 'application/vnd.com.payclip.v2+json',
  };

  if (token) {
    headers.Authorization = token;
  }

  return { headers };
};

export const getRequestSwapOptions = function () {
  const applicationId = getConfigValue('berlinTandemApplicationId');
  const restAPIKey = getConfigValue('berlinTandemRestAPIKey');
  const APIKey = getConfigValue('berlinTandemAPIKey');

  return {
    headers:
    {
      'X-Parse-Application-Id': applicationId,
      'X-Parse-REST-API-Key': restAPIKey,
    },
  }
}

export const getRequestApiSwapOptions = function () {
  const XApiKey = getConfigValue('swapXAPIKey');
  const headers = {
    'x-api-key': XApiKey,
  }
  return { headers }
}