import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import Lottie from 'lottie-react';

import DataValidation from '../../../../assets/images/berlin/lottie/DataValidation/data.json';
import text from './text';
import styles from './styles';
const useStyles = makeStyles(styles);

const WaitingForDataValidation = (props) => {
  const classes = useStyles(props);
  const { version } = props;
  return (
    <div className={classes.centerContainer}>
      <Lottie animationData={DataValidation} className={classes.icon}></Lottie>
      <ClipTypography fontWeight="bold" variant="h3" align="center">
        {text['title' + version]}
      </ClipTypography>
      <ClipTypography className={classes.message} align="center">
        {text['message' + version]}
      </ClipTypography>
    </div>
  );
};

WaitingForDataValidation.propTypes = {
  version: PropTypes.number,
};
WaitingForDataValidation.defaultProps = {
  version: 1,
};
export default WaitingForDataValidation;
