import styled from '@emotion/styled';

export const Container = styled.div(({ theme, isTableHead }) => {
  return {
    position: 'relative',
    fontSize: 12,
    whiteSpace: 'nowrap',
    marginBottom: !!isTableHead ? 8 : 24,
    marginTop: !!isTableHead ? 15 : 0,
    display: 'table',
    width: '100%',
    borderBottom: '1px solid #E4E5E1',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
});

export const Tab = styled.div(({ theme, active }) => ({
  marginRight: 50,
  paddingBottom: 15,
  fontWeight: active ? 'bold' : 'normal',
  borderBottom: active ? `1px solid ${theme.palette.primary.main}` : 'none',
  float: 'left',
  textTransform: 'uppercase',
  [theme.breakpoints.down('xs')]: {
    width: 'fit-content',
    paddingBottom: 8, // active ? 15 : 16,
    paddingTop: 9,
    '&:last-child': {
      marginRight: '50px !important',
    },
  },
  a: {
    color: active ? theme.palette.primary.main : theme.palette.text.primary,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },

  '&:last-child': {
    marginRight: 0,
  },
}));
