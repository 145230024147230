export const styles = (theme) => {
  const rootStyles = {
    container: {
      margin: `${theme.spacing(5.25)}px ${theme.spacing.unit * 3.5}px
        ${theme.spacing.unit * 7}px ${theme.spacing.unit * 3.5}px`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      marginTop: theme.spacing(5),
      width: '100%',
      borderWidth: '2px',
      '& img': {
        marginRight: `${theme.spacing(1.5)}px`,
      },
    },
    title: {
      textAlign: 'center',
    },
    checkIcon: {
      color: theme.palette.green.l10,
      marginRight: 15,
    },
    likeIcon: {
      color: theme.palette.green.l20,
      marginRight: 5,
    },
    textDone: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '17px',
      color: theme.palette.green.l20,
      flex: 'none',
      order: '1',
      flexGrow: '0',
      margin: ' 0px 8px',
    },
    divText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(5),
    },
    typographyContent: {
      marginTop: `${theme.spacing(2)}px`,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& img': {
        marginRight: `${theme.spacing(2)}px`,
      },
    },
    gif: {
      width: theme.spacing(20.25),
    },
  };
  return { ...rootStyles };
};
