const styles = (theme) => ({
  container: {
    marginBottom: '16px',
  },
  helperText: {
    padding: theme.spacing(1),
  },
});

export default styles;
