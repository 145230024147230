export default {
  title: '¡La oferta ha expirado!',
  message:
    'El periodo para solicitar esta oferta expiró. Recuerda iniciar el proceso de solicitud antes de la fecha límite para poder disfrutar de este beneficio.',
  subtitle: '¿Cómo obtener un Adelanto de ventas Clip?',
  advice1: 'Mantén una actividad transaccional constante utilizando tu Clip.',
  advice2:
    'Asegúrate de realizar transacciones durante los días previos a tu solicitud.',
  recommendation:
    'Sigue nuestras recomendaciones y pronto recibirás una nueva oferta en tu correo electrónico.',
};
