import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import ClipGrid from '@clipmx/clip-storybook/dist/components/Grid';
import { ClipTypography } from '@clipmx/clip-storybook';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';
import ClipDialog from '@clipmx/clip-storybook/dist/components/Dialog';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import CloseIconClip from '@clipmx/clip-storybook/dist/components/Icons/Close';
import { toCurrencyFormat } from '../../../../helpers/formatCurrency';
import ClipButton from 'components/npm/clip-ui/Button';
import { PreapprovalStatus } from '../../../../constants/AppConstants';
import LoansOffers from '../../Commons/LoansOffers';
import { ModalContent, ModalTitle } from '../styled';
import styles from './styles';
import text from '../text';
import urls from 'helpers/urls';
import LoansLandingV4 from '../../Commons/LoansLandingV4';
import { useLocation } from 'react-router-dom';

const SwitchOffer = ({
  preapprovals,
  preApproval,
}) => {
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const availables = preapprovals?.filter((preapproval) =>
    [PreapprovalStatus.AVAILABLE].includes(preapproval.status),
  );
  const regularCheck = availables.some(available => available.type === 'REGULAR');
  const monthlyCapCheck = availables.some(preapproval => preapproval.installment_type === 'FIXED');

  const classes = styles();
  const browserHistory = useLocation();

  const isMobileWebView = browserHistory.pathname.startsWith(urls.mobile);

  const modalFunctionality = () => {
    return (
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalContent className={classes.modalContent}>
          <ClipButton
            variant="outlined"
            color="secondary"
            className={classes.buttonClose}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </ClipButton>
          <ModalTitle>
            {text.modalTitle}
            <LoansOffers
              preapprovals={availables}
              currentAmount={preApproval?.amount}
              closeModal={() => setOpen(false)}
            />
          </ModalTitle>
        </ModalContent>
      </Modal>
    );
  };

  const dialogFunctionality = () => {
    return (
      <ClipDialog
        title=" "
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        type="form"
        maxWidth="sm"
        fullWidth={true}
        classes={{
          root: classes.cardSize,
        }}
      >
        {validationContent(false)}
      </ClipDialog>
    );
  };

  const Drawer = () => {
    return (
      <ClipDrawer anchor={'bottom'} open={displayTooltip} classes={{ paper: classes.tooltipPaper }}>
        {validationContent(true)}
      </ClipDrawer>
    );
  };

  const personalizedOffers = () => isMobileWebView ? Drawer() : dialogFunctionality();
  const personalizedOffersAction = () => isMobileWebView ? setDisplayTooltip(true) : setIsDialogOpen(true);

  const validationContent = (displayCloseIcon) => {
    return (
      <>
        <div className={classes.tooltipTitleContainer}>
          <ClipTypography
            variant={'h3'}
            component={'h2'}
            align={'left'}
            fontWeight={'bold'}
            classes={{
              root: classes.tooltipTitle,
            }}
          >
            {text.changeNewOffer}
          </ClipTypography>
          {displayCloseIcon && (
            <IconButton
              className={classes.iconBase}
              classes={{ root: classes.iconButton }}
              onClick={() => {
                setDisplayTooltip(false);
              }}
              onClose={() => window.scrollTo(0, 0 + 15)}
            >
              <CloseIconClip
                fontSize="small"
                classes={{ root: classes.closeIcon }}
              />
            </IconButton>
          )}
        </div>

        <ClipTypography
          variant={'body1'}
          component={'p'}
          align={'left'}
        >
          <LoansLandingV4 
            modalView={true} 
            preapprovals={availables} 
            currentAmount={preApproval?.amount} 
            setIsDialogOpen={setIsDialogOpen}
            setDisplayTooltip={setDisplayTooltip} />
        </ClipTypography>
      </>
    );
  };

  return (
    <Fragment>
      <div className={classes.Desk}>
        <ClipTypography variant="subtitle1" fontWeight="medium" gutterBottom>
          {text.summaryOfYourPrestaClip}
        </ClipTypography>
        <div className={classes.OrderTotalParent}>
          <div className={classes.AmountContainer}>
            <ClipTypography variant="body1" fontWeight="regular" gutterBottom>
              {text.amountRequested}
            </ClipTypography>
            <ClipTypography variant="body1" fontWeight="regular" gutterBottom>
              {toCurrencyFormat(preApproval?.amount)}
            </ClipTypography>
          </div>
          <div className={classes.AmountContainer}>
            <ClipTypography variant="body1" fontWeight="regular" gutterBottom>
              {text.maxLimit}
            </ClipTypography>
            <ClipTypography variant="body1" fontWeight="regular" gutterBottom>
              {text.termMonths(parseInt(preApproval?.term_maximum))}
            </ClipTypography>
          </div>
          <div className={classes.AmountContainer}>
            <ClipTypography variant="body1" fontWeight="regular" gutterBottom>
              {text.fixedCharge}
            </ClipTypography>
            <ClipTypography variant="body1" fontWeight="regular" gutterBottom>
              {toCurrencyFormat(
                parseFloat(preApproval?.payback) -
                  parseFloat(preApproval?.amount),
              )}
            </ClipTypography>
          </div>
          {monthlyCapCheck && (
            <div className={classes.AmountContainer}>
              <ClipTypography variant="body1" fontWeight="regular" gutterBottom>
                {text.fixedPayment}
              </ClipTypography>
              <ClipTypography variant="body1" fontWeight="regular" gutterBottom>
                {toCurrencyFormat(
                  parseFloat(preApproval?.installment?.breakdown?.[0]?.amount),
                )}
              </ClipTypography>
            </div>
          )}
          <div className={classes.AmountContainer}>
            <ClipTypography variant="body1" fontWeight="regular" gutterBottom>
              {monthlyCapCheck ? text.retention : text.fixedPayment }
            </ClipTypography>
            <ClipTypography
              variant="body1"
              align="right"
              fontWeight="regular"
              gutterBottom
            >
              {text.dailyPay(parseInt(preApproval?.retention_rate))}
            </ClipTypography>
          </div>
          <div className={classes.AmountContainer}>
            <ClipTypography variant="body1" fontWeight="medium" gutterBottom>
              {text.total}
            </ClipTypography>
            <ClipTypography variant="body1" fontWeight="medium" gutterBottom>
              {toCurrencyFormat(preApproval?.payback)}
            </ClipTypography>
          </div>
          <ClipButton
            className={classes.MarginTop}
            variant="text"
            color="primary"
            onClick={() => regularCheck ? setOpen(true) : personalizedOffersAction()}
          >
            {text.changeOffer}{' '}
          </ClipButton>
        </div>
        {!regularCheck ? personalizedOffers() : modalFunctionality()}

      </div>
      <ClipGrid className={classes.Mobil}>
        <ClipGrid container spacing={2}>
          <ClipGrid item xs={6}>
            <ClipTypography
              variant="caption"
              className={classes.TextTotal}
              gutterBottom
            >
              {text.total}
            </ClipTypography>
            <ClipTypography
              variant="subtitle1"
              fontWeight="semiBold"
              gutterBottom
            >
              {toCurrencyFormat(preApproval?.payback)}
            </ClipTypography>
            <ClipTypography
              variant="body2"
              fontWeight="semiBold"
              color="primary"
              onClick={() => regularCheck ? setOpen(true) : personalizedOffersAction()}
            >
              {text.changeOffer}{' '}
            </ClipTypography>
          </ClipGrid>
        </ClipGrid>
      </ClipGrid>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  preapprovals: state.loans.preapprovals,
});

export default connect(mapStateToProps)(SwitchOffer);
