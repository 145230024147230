import React from 'react';
import { ClipTypography } from '@clipmx/clip-storybook';

const PageTitle = (props) => {
  const { children } = props;
  return (
    <ClipTypography
      variant={'h3'}
      component={'h1'}
      gutterBottom
      style={{ paddingTop: 16, paddingBottom: 16, position: 'relative' }}
    >
      {children}
    </ClipTypography>
  );
};

export default PageTitle;
