import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getUserEmail } from 'components/account/redux/selectors';
import SiftScript from './SiftScript';

/**
 * Container wich connects with redux to get the user email and then send it to  the dummy component
 */
class Sift extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderComponent: false,
    };
  }

  /* Check if there is a diff in the props to avoid double rendering of the component */
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ renderComponent: true });
    }
  }

  render() {
    const { userEmail } = this.props;
    const { renderComponent } = this.state;
   
    return (
      <div>{renderComponent ? <SiftScript userEmail={userEmail} /> : null}</div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userEmail: getUserEmail(state),
    ...state,
  };
};

export default compose(connect(mapStateToProps, null))(Sift);
