export const states = [
  { id: 'Aguascalientes', name: 'Aguascalientes' },
  { id: 'Baja California', name: 'Baja California' },
  { id: 'Baja California Sur', name: 'Baja California Sur' },
  { id: 'Chihuahua', name: 'Chihuahua' },
  { id: 'Chiapas', name: 'Chiapas' },
  { id: 'Campeche', name: 'Campeche' },
  { id: 'Ciudad de México', name: 'Ciudad de México' },
  { id: 'Coahuila', name: 'Coahuila' },
  { id: 'Colima', name: 'Colima' },
  { id: 'Durango', name: 'Durango' },
  { id: 'Guerrero', name: 'Guerrero' },
  { id: 'Guanajuato', name: 'Guanajuato' },
  { id: 'Hidalgo', name: 'Hidalgo' },
  { id: 'Jalisco', name: 'Jalisco' },
  { id: 'Michoacán', name: 'Michoacán' },
  { id: 'Estado de México', name: 'Estado de México' },
  { id: 'Morelos', name: 'Morelos' },
  { id: 'Nayarit', name: 'Nayarit' },
  { id: 'Nuevo León', name: 'Nuevo León' },
  { id: 'Oaxaca', name: 'Oaxaca' },
  { id: 'Puebla', name: 'Puebla' },
  { id: 'Quintana Roo', name: 'Quintana Roo' },
  { id: 'Querétaro', name: 'Querétaro' },
  { id: 'Sinaloa', name: 'Sinaloa' },
  { id: 'San Luis Potosí', name: 'San Luis Potosí' },
  { id: 'Sonora', name: 'Sonora' },
  { id: 'Tabasco', name: 'Tabasco' },
  { id: 'Tlaxcala', name: 'Tlaxcala' },
  { id: 'Tamaulipas', name: 'Tamaulipas' },
  { id: 'Veracruz', name: 'Veracruz' },
  { id: 'Yucatán', name: 'Yucatán' },
  { id: 'Zacatecas', name: 'Zacatecas' },
];

export const business_sectors = [
  {
    id: 'Servicios Profesionales y de Salud',
    name: 'Servicios Profesionales y de Salud',
  },
  { id: 'Alimentos y Bebidas', name: 'Alimentos y Bebidas' },
  { id: 'Viajes y Turismo', name: 'Viajes y Turismo' },
  { id: 'Servicios Generales', name: 'Servicios Generales' },
  { id: 'Gasolineras', name: 'Gasolineras' },
  { id: 'Electrónica y Computación', name: 'Electrónica y Computación' },
  { id: 'Belleza y Cuidado Personal', name: 'Belleza y Cuidado Personal' },
  { id: 'Asociaciones', name: 'Asociaciones' },
  {
    id: 'Entretenimiento, Recreativo, Cultural y Deporte',
    name: 'Entretenimiento, Recreativo, Cultural y Deporte',
  },
  {
    id: 'Alquiler y Servicios Inmobiliarios',
    name: 'Alquiler y Servicios Inmobiliarios',
  },
  { id: 'Servicios Educativos', name: 'Servicios Educativos' },
];
