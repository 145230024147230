const width100 = '100%';
const flex = 'flex';
const center = 'center';
const left = 'left';
const auto = 'auto';

export const styles = (theme) => {
  const typographyStyles = {
    title: {
      fontSize: `${theme.spacing.unit * 2.875}px !important`,
      lineHeight: `${theme.spacing.unit * 3.6}px !important`,
      fontWeight: '600 !important',
      textAlign: 'center !important',
      color: 'black !important',
      marginBottom: `${theme.spacing.unit * 2}px !important`,
      width: '100% !important',
      margin: 'auto !important',
      lineBreak: 'normal !important',
      [theme.breakpoints.down('xs')]: {
        width: '75% !important',
      },
      '&.confirm': {
        marginBottom: `${theme.spacing.unit}px !important`,
      },
    },
    body1: {
      fontSize: `${theme.spacing.unit * 1.75}px !important`,
      lineHeight: `${theme.spacing.unit * 2.1}px !important`,
      fontWeight: '400 !important',
      textAlign: 'justify !important',
      color: 'black !important',
      marginBottom: `${theme.spacing.unit * 2}px !important`,
      '&.confirm': {
        marginBottom: '0 !important',
      },
    },
  };
  const componentsStyles = {
    gridCard: {
      marginBottom: `${theme.spacing.unit * 4}px`,
      paddingLeft: `${theme.spacing.unit * 4}px`,
      paddingRight: `${theme.spacing.unit * 4}px`,
      width: `${theme.spacing.unit * 75.4}px`,
      display: flex,
      flexDirection: 'column',
      [theme.breakpoints.down(theme.spacing.unit * 75.4)]: {
        width: width100,
        marginBottom: `${theme.spacing.unit * 5}px`,
        paddingLeft: `${theme.spacing.unit * 2}px`,
        paddingRight: `${theme.spacing.unit * 2}px`,
      },
    },
    header: {
      display: flex,
    },
    bannerContainer: {
      display: flex,
      marginTop: `${theme.spacing.unit * 2.75}px`,
      marginBottom: `${theme.spacing.unit * 1.92}px`,
      '& img': {
        margin: auto,
      },
    },
    closeContainer: {
      position: 'relative',
      right: `-${theme.spacing.unit * 2}px`,
      top: `-${theme.spacing.unit}px`,
      [theme.breakpoints.down(theme.spacing.unit * 75.4)]: {
        right: `-${theme.spacing.unit}px`,
      },
    },
    buttons: {
      display: 'flex',
      marginTop: `${theme.spacing.unit * 5}px`,
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      [theme.breakpoints.down(theme.spacing.unit * 75.4)]: {
        flexDirection: 'column',
      },
    },
    continue: {
      order: 1,
      '& button': {
        fontWeight: 500,
      },
      [theme.breakpoints.down(theme.spacing.unit * 75.4)]: {
        order: 0,
      },
    },
    omit: {
      order: 0,
      '& button': {
        border: 'none',
        fontWeight: 500,
      },
      '& button:hover': {
        backgroundColor: 'white',
      },
      [theme.breakpoints.down(theme.spacing.unit * 75.4)]: {
        order: 1,
      },
    },
    bannerHeader: {
      margin: auto,
      width: `${theme.spacing.unit * 22.5}px`,
      display: flex,
      alignItems: center,
      flexWrap: 'nowrap',
      '& img:first-child': {
        marginRight: `${theme.spacing.unit * 4.25}px`,
        width: `${theme.spacing.unit * 14.25}px`,
      },
      '&.small': {
        width: `${theme.spacing.unit * 13.375}px`,
      },
      '&.small img:first-child': {
        width: `${theme.spacing.unit * 8.57}px`,
        marginRight: `${theme.spacing.unit * 2.54}px`,
      },
      '&.small img:last-child': {
        width: `${theme.spacing.unit * 2.4025}px`,
      },
    },
    closeButton: {
      top: 0,
      display: flex,
      backgroundColor: '#FFFFFF',
      borderRadius: `${theme.spacing.unit * 4}px`,
      width: `${theme.spacing.unit * 3}px`,
      height: `${theme.spacing.unit * 3}px`,
      justifyContent: 'space-around',
      alignItems: center,
      border: 'none',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#fc4c01',
        transition: 'background-color 0.5s linear',
      },
      '&:hover > svg': {
        color: 'white',
      },
      '& > svg': {
        margin: auto,
        fontSize: `${theme.spacing.unit * 2.25}px`,
      },
      [theme.breakpoints.down(theme.spacing.unit * 75.4)]: {
        backgroundColor: '#E6E8E9',
      },
    },
    formControl: {
      width: width100,
      float: left,
      marginBottom: `${theme.spacing.unit}px`,
      marginTop: `${theme.spacing.unit}px`,
      '& legend': {
        textAlign: left,
        marginTop: `${theme.spacing.unit}px`,
        color: `${theme.palette.naranja.slate}`,
      },
      '&:first-child': {
        marginTop: 0,
        marginBottom: `${theme.spacing.unit}px`,
      },
    },
  };

  return {
    ...typographyStyles,
    ...componentsStyles,
  };
};
