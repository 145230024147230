export default {
  title: 'Ingresa los datos del destinatario',
  clabeField: 'Cuenta CLABE',
  nameField: 'Nombre del destinatario',
  bankField: 'Banco',
  amountField: 'Monto',
  referenceField: 'Concepto',
  passwordField: 'Contraseña Clip',
  transferButton: 'Continuar',
  enterPassword: 'Ingresa la contraseña de tu cuenta Clip',
  successfulTransfer: 'Dinero enviado',
  unsuccessfulTransfer: 'Envío fallido',
  recipient: 'Destinatario',
  trackingNumber: 'Clave de rastreo',
  referenceNumber: 'Referencia',
  returnButton: 'Finalizar',
  transferDateConnector: 'a las',
  verifyPassword: 'Verifica tu contraseña.',
  verifyClabe: 'Verifica que la CLABE Interbancaria sea correcta',
  failedTransferNotificationTitle: 'Transferencia fallida',
  failedTransferNotificationDescription:
    'Verifica los datos que ingresaste o intenta más tarde.',
  navigatorTitle: 'Enviar dinero',
  retry: 'Reintentar',
  failedTransferInfo: 'El dinero sigue en tu cuenta',
  verifyInfoError: 'Verifica tu información.',
  passwordLengthError: 'Mínimo 8 caracteres.',
  clabeLengthError: '18 caracteres requeridos.',
  dialogTitle: 'Necesitamos tu ubicación',
  dialogMessage:
    'Por disposición oficial, y para brindarte mayor seguridad, accederemos a tu ubicación cuando envíes dinero.',
  okDialog: 'Entendido',
  cancelDialog: 'Cancelar',
  shareGeo: 'Compartir ubicación',
  geoMessage:
    'Para enviar dinero necesitas habilitar la ubicación de tu dispositivo en Ajustes (Android y Huawei) o Configuración (iOS).',
  onTheWay: 'Enviando',
};
