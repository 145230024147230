const width100 = '100%';
const flex = 'flex';
const block = 'block';

export const styles = (theme) => {
  const typographyStyles = {
    typographyRoot: {
      margin: 'auto auto',
      lineHeight: 1.29,
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 400,
      textAlign: 'center',
      color: '#001724',
    },
    typographyBody: {
      margin: 'auto auto',
      lineHeight: 'normal',
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 400,
      textAlign: 'center',
      color: '#001724',
    },
    typographyBody2: {
      margin: 'auto auto',
      lineHeight: 'normal',
      fontSize: `${theme.spacing.unit * 2.25}px`,
      fontWeight: 500,
      textAlign: 'center',
      color: '#001724',
      marginBottom: `${theme.spacing.unit}px`,
    },
    typographyBody3: {
      margin: 'auto auto',
      textAlign: 'center',
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 300,
      color: '#001724',
      marginTop: `${theme.spacing.unit * 3}px`,
      marginBottom: `${theme.spacing.unit}px`,
    },
    typographyBody4: {
      margin: 'auto auto',
      textAlign: 'center',
      fontSize: `${theme.spacing.unit * 3}px`,
      fontWeight: 500,
      color: '#001724',
      marginTop: `${theme.spacing.unit / 2}px`,
      marginBottom: `${theme.spacing.unit * 1.5}px`,
    },
    typographyBody5: {
      margin: 'auto auto',
      textAlign: 'center',
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 300,
      lineHeight: 1.25,
      color: '#001724',
      marginTop: `${theme.spacing.unit * 2}px`,
      marginBottom: `${theme.spacing.unit * 4}px`,
    },
    typographyBody6: {
      margin: 'auto auto',
      textAlign: 'center',
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 300,
      lineHeight: 1.25,
      color: '#001724',
    },
    typographyTitle: {
      margin: 'auto auto',
      fontSize: `${theme.spacing.unit * 3}px`,
      fontWeight: 600,
      lineHeight: 1.17,
      color: '#001724',
      width: '100%',
      textAlign: 'center',
    },
    typographyTitle2: {
      margin: 'auto auto',
      fontSize: `${theme.spacing.unit * 2.5}px`,
      fontWeight: 600,
      lineHeight: 1.17,
      color: '#001724',
      width: '100%',
      textAlign: 'center',
    },
    typographyTitle3: {
      margin: 'auto auto',
      fontSize: `${theme.spacing.unit * 2.5}px`,
      fontWeight: 600,
      lineHeight: 1.17,
      color: '#001724',
      width: '100%',
      textAlign: 'center',
      marginBottom: `${theme.spacing.unit * 2}px`,
    },
    typographySubTitle: {
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 600,
      lineHeight: 1.29,
      width: '100%',
      color: '#001724',
      textAlign: 'center',
    },
    typographyNote: {
      margin: 'auto auto',
      marginTop: `${theme.spacing.unit * 2}px`,
      lineHeight: 1.29,
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 400,
      textAlign: 'center',
      color: '#001724',
    },
    typographyAdvice: {
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 400,
      color: '#007d8e',
    },
  };
  const typographyDesktopStyles = {
    typographyBodyDesktop: {
      lineHeight: 'normal',
      fontSize: `${theme.spacing.unit * 2.25}px`,
      fontWeight: 300,
      textAlign: 'left',
      color: '#001724',
    },
    typographyBody2Desktop: {
      lineHeight: 1.33,
      fontSize: `${theme.spacing.unit * 2.625}px`,
      fontWeight: 500,
      textAlign: 'left',
      color: '#001724',
      margiTop: `${theme.spacing.unit * 2}px`,
      marginBottom: `${theme.spacing.unit}px`,
    },
    typographyTitleDesktop: {
      fontSize: `${theme.spacing.unit * 3}px`,
      fontWeight: 600,
      lineHeight: 1.17,
      color: '#001724',
      width: '100%',
      textAlign: 'left',
      marginBottom: `${theme.spacing.unit * 2}px`,
    },
    typographySubTitleDesktop: {
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 600,
      lineHeight: 1.29,
      width: '100%',
      color: '#001724',
      textAlign: 'left',
    },
  };
  const buttonStyles = {
    buttonContainer: {
      margin: '0 auto',
      marginTop: `${theme.spacing.unit * 5}px`,
      width: '30%',
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 600,
      letterSpacing: '0.4px',
      textAlign: 'center',
      borderRadius: '25px',
      backgroundColor: '#fc4c02',
      color: '#ffffff',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  };
  const componentsStyles = {
    gridCard: {
      marginBottom: `${theme.spacing.unit * 3}px`,
      width: `${theme.spacing.unit * 57.5}px`,
      '&.offers': {
        width: `${theme.spacing.unit * 94.25}px`,
      },
      [theme.breakpoints.down(460)]: {
        width: width100,
      },
    },
    crediCard: {
      display: block,
      width: width100,
      height: 'auto',
      paddingBottom: `${theme.spacing.unit * 2}px`,
      '&.margin24': {
        marginBottom: `${theme.spacing.unit * 3}px`,
      },
      '&:last-child, & > .common-card-content-root': {
        display: flex,
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        width: width100,
        paddingTop: '0px !important',
        paddingLeft: '1px !important',
        paddingRight: '1px !important',
        paddingBottom: '0 !important',
      },
      '& .common-card-header-root': {
        border: 0,
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
      },
      '& .common-card-header-title': {
        fontWeight: theme.typography.fontWeightLight,
        color: theme.palette.naranja.blueGrey,
      },
      '& .card-header-action': {
        marginRight: 0,
      },
    },
    separator: {
      width: width100,
      height: '2px',
      backgroundColor: theme.palette.naranja.iceBlue,
      padding: '0 0',
      marginTop: `${theme.spacing.unit * 2}px`,
      marginBottom: `${theme.spacing.unit * 2}px`,
      marginLeft: 'auto',
      marginRight: 'auto',
      '&.gray': {
        backgroundColor: '#e6e8e9',
      },
    },
    alertContainer: {
      display: flex,
      width: width100,
      marginBottom: 0,
      marginTop: `${theme.spacing.unit * 2}px`,
      '&.notTopMargin': {
        marginTop: 0,
      },
      '&.notElegible': {
        marginTop: `${theme.spacing.unit * 2.375}px`,
        marginBottom: `${theme.spacing.unit * 1.75}px`,
      },
      marginLeft: `${theme.spacing.unit * 2}px`,
      marginRight: `${theme.spacing.unit * 2}px`,
      [theme.breakpoints.down(460)]: {
        marginLeft: `${theme.spacing.unit}px`,
        marginRight: `${theme.spacing.unit}px`,
      },
    },
    container: {
      width: width100,
      marginLeft: `${theme.spacing.unit * 2}px`,
      marginRight: `${theme.spacing.unit * 2}px`,
      [theme.breakpoints.down(460)]: {
        marginLeft: `${theme.spacing.unit}px`,
        marginRight: `${theme.spacing.unit}px`,
      },
    },
    textContainer: {
      width: width100,
      marginTop: `${theme.spacing.unit * 2}px`,
      marginLeft: `${theme.spacing.unit * 2}px`,
      marginRight: `${theme.spacing.unit * 2}px`,
      marginBottom: `${theme.spacing.unit * 2}px`,
      '&.notElegible': {
        marginBottom: 0,
      },
      '&.desktop': {
        width: '68%',
        marginTop: 0,
        [theme.breakpoints.down(600)]: {
          width: width100,
          marginTop: `${theme.spacing.unit * 2}px`,
        },
      },
    },
    descriptionContainer: {
      width: width100,
      marginTop: `${theme.spacing.unit * 2}px`,
      marginLeft: `${theme.spacing.unit * 2}px`,
      marginRight: `${theme.spacing.unit * 2}px`,
      marginBottom: 0,
    },
    expireWarning: {
      width: width100,
      height: width100,
      margin: '0 0',
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      border: 'solid 1px rgba(0, 125, 142, 0.08)',
      borderLeft: 'solid 4px #007d8e',
      backgroundColor: 'rgba(0, 125, 142, 0.06)',
      display: flex,
      '&.desktop': {
        marginLeft: '27%',
        [theme.breakpoints.down(600)]: {
          margin: 0,
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: `calc(${width100} - ${theme.spacing.unit * 0.5}px)`,
      },
    },
    bannerContainer: {
      width: width100,
      display: flex,
      justifyContent: 'center',
    },
    bannerTop: {
      width: '230px',
      height: `${theme.spacing.unit * 6.5}px`,
      marginBottom: `${theme.spacing.unit * 3}px`,
      [theme.breakpoints.down('xs')]: {
        width: width100,
        marginTop: `${-theme.spacing.unit}px`,
      },
    },
    calcLeftIcon: {
      display: 'inline-block',
      position: 'relative',
      marginRight: `${theme.spacing.unit}px`,
      verticalAlign: 'middle',
      top: `-${theme.spacing.unit * 0.25}px`,
      marginTop: `${theme.spacing.unit}px`,
      marginBottom: `${theme.spacing.unit}px`,
      marginLeft: `${theme.spacing.unit}px`,
      [theme.breakpoints.down('xs')]: {
        verticalAlign: 'top',
        height: width100,
        marginTop: `${theme.spacing.unit * 1.5}px`,
        marginBottom: 0,
      },
    },
    offersContainer: {
      width: width100,
      height: `${theme.spacing.unit * 31.375}px`,
      margin: 0,
      paddingLeft: `${theme.spacing.unit}px`,
      position: 'relative',
      display: 'flex',
      '&.desktop': {
        marginTop: `${theme.spacing.unit * 2.125}px`,
        marginBottom: `${theme.spacing.unit * 7.125}px`,
        paddingLeft: 0,
        [theme.breakpoints.down(600)]: {
          margin: 0,
          paddingLeft: `${theme.spacing.unit}px`,
        },
      },
      '&:hover > div': {
        transform: 'scale(0.9)',
        transition: `transform ${theme.transitions.duration.shortest}ms`,
      },
      '&:hover > div:hover': {
        transform: 'scale(1.1)',
        transition: `transform ${theme.transitions.duration.shortest}ms`,
      },
      '& > div:not(:first-child)': {
        marginLeft: `${theme.spacing.unit * 2.5}px`,
      },
      '& > .recommended': {
        border: 'solid 2px #007d8e',
        width: `${theme.spacing.unit * 27.5 + 4}px!important`,
      },
      [theme.breakpoints.down(460)]: {
        marginRight: `${theme.spacing.unit}px`,
        padding: 0,
      },
      [theme.breakpoints.down(800)]: {
        width: '100%',
        marginRight: `${theme.spacing.unit * 2}px`,
        paddingBottom: `${theme.spacing.unit / 2}px`,
        overflowX: 'scroll',
        overflowY: 'hidden',
        display: 'flex',
        '&:hover > div': {
          transform: 'none',
          transition: 'none',
        },
        '&:hover > div:hover': {
          transform: 'none',
          transition: 'none',
        },
      },
    },
    headerDesktopContainer: {
      width: width100,
      marginTop: `${theme.spacing.unit * 2}px`,
      display: 'flex',
    },
    chartLeftIcon: {
      display: 'inline-block',
      position: 'relative',
      marginRight: `${theme.spacing.unit * 0.75}px`,
      verticalAlign: 'middle',
      top: `-${theme.spacing.unit * 0.25}px`,
      marginTop: `${theme.spacing.unit}px`,
      marginBottom: `${theme.spacing.unit}px`,
      marginLeft: `${theme.spacing.unit}px`,
      [theme.breakpoints.down('xs')]: {
        verticalAlign: 'top',
        height: width100,
        marginTop: `${theme.spacing.unit * 1.25}px`,
        marginBottom: 0,
      },
    },
    paymentContainer: {
      width: `${theme.spacing.unit * 52}px`,
      margin: 'auto',
    },
    image: {
      marginTop: `${theme.spacing.unit * 2.5}px`,
      '&.desktop': {
        display: block,
        width: '26%',
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
      '&.mobile': {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
          display: block,
        },
      },
    },
    bannerInterested: {
      width: width100,
      marginBottom: `${theme.spacing.unit * 2}px`,
    },
    bannerApproved: {
      width: width100,
      marginTop: `${theme.spacing.unit * 2}px`,
      marginBottom: `${theme.spacing.unit * 3}px`,
    },
    containerApproved: {
      width: width100,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: `${theme.spacing.unit * 4.75}px`,
    },
    bannerInReview: {
      margin: 'auto',
      display: flex,
      width: `${theme.spacing.unit * 4}px`,
      marginBottom: `${theme.spacing.unit * 2.5}px`,
    },
    blueButton: {
      backgroundColor: theme.palette.naranja.cerulean,
      letterSpacing: '.1rem',
      minWidth: theme.spacing.unit * 20,
      borderRadius: `${theme.spacing.unit * 3}px`,
      textTransform: 'none',
      fontSize: `${theme.spacing.unit * 2}px`,
      '&:hover': {
        backgroundColor: theme.palette.naranja.cerulean,
        opacity: '.9',
      },
    },
  };

  return {
    ...typographyStyles,
    ...typographyDesktopStyles,
    ...buttonStyles,
    ...componentsStyles,
  };
};
