import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  isValid,
  submit,
  formValueSelector,
  Field,
} from 'redux-form';
import { withRouter, Link, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ClipButton from 'components/npm/clip-ui/Button';
import { asyncValidatePostalCode } from '../../../../helpers/asyncValidate';
import {
  focusInputWithErrors,
  focusFileInputType,
  renderCheckbox,
} from '../../../../helpers/FormUtils';
import urls from '../../../../helpers/urls';
import getUrl, { getProviderCashAdvanceURL } from '../../../../helpers/getUrl';
import { getProviderCodeForURL } from '../../../../helpers/getProviderUrl';
import {
  Forms,
  LoansFilesStates,
  LoansDocuments,
  LoansCurrentProductName,
  LoansNewProductName,
} from '../../../../constants/AppConstants';
import {
  getProviderCode,
  loansProviderCodes,
} from '../../../../constants/ProviderCodes';
import { turnOnDragFile, turnOffDragFile } from '../../redux/actions';
import {
  getLoansIdentityDocuments,
  getPrefilledForm,
  getLoansIdentityDocumentsBack,
  getLoansPassportDocuments,
  getLoansProofAddressDocuments,
  getSendingForm,
  getPreapprovalSelected,
  getPrestaClipNameChange,
} from '../../redux/selectors';

import IdentityStep from '../IdentityStep';
import HistoryStep from '../HistoryStep';
import AddressStep from '../AddressStep';
import DepositStep from '../DepositStep';
import SwitchOffer from '../SwitchOffer';
import { getLoansColonies, getMerchantId } from '../../redux/selectors';
import {
  Form,
  SubmitButtonV3,
  ProgressIcon,
  TermsAndConditions,
} from '../../Commons/Form/styled';
import { termsAndConditions } from '../../../../helpers/validate';
import text from '../../Commons/LoansContent/text';
import ClipGrid from '@clipmx/clip-storybook/dist/components/Grid';
import styles from './styles';
import GoogleAnalytics from '../../GoogleAnalytics';
import { lenderPayload } from '../../Commons/Clevertap';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { Clevertap, EVENTS } from '../../../../helpers/Clevertap';
import { showTelephoneValidation } from '../../../../constants/FeatureFlags';
import ClipAlert from '@clipmx/clip-storybook/dist/components/Alert';
import ClipSnackbar from '@clipmx/clip-storybook/dist/components/Snackbar';
import { FeedbackQualities } from 'constants/AppConstants';
import { dismissChangeOfferAlert } from 'components/loans/redux/actions';
import clsx from 'clsx';

const ExternalForm = ({
  isFormValid,
  errors,
  identityFiles,
  identityFilesBack,
  passportFiles,
  addressProofFiles,
  sendingForm,
  identityDocumentType,
  preapprovalSelected,
  prestaClipNameChange,
  showCashAdvance,
  step,
  lastOne,
  submit,
  turnOnDragFile,
  turnOffDragFile,
  changeStep,
  handleSubmit,
  loansFormVersion,
  changeOfferSuccess,
  dismissChangeOfferAlert,
}) => {
  const [showDialog, changeShowDialog] = useState(false);
  const [passportUpdated, changePassportUpdated] = useState(false);
  const [depositStepComplete, changeDepositStepComplete] = useState(false);

  let identityRef = React.createRef();
  let addressRef = React.createRef();
  let location = useLocation();
  const classes = styles();
  const history = useHistory();

  const getBaseLocation = () =>
    location.pathname.startsWith(urls.mobile) ? urls.mobile : '';

  const handleDialogClose = useCallback(() => {
    changeShowDialog(false);
  }, [changeShowDialog]);

  const isFileSaved = (file) => file.status === LoansFilesStates.SAVED;

  const validateFilesAndSendForm = () => {
    const ineFrontNotSaved = identityFiles.filter(isFileSaved).length === 0;
    const ineBackNotSaved = identityFilesBack.filter(isFileSaved).length === 0;
    const passportNotSaved = passportFiles.filter(isFileSaved).length === 0;
    const identityFilesNotSaved =
      identityDocumentType === LoansDocuments.ineDocumentType
        ? ineFrontNotSaved || ineBackNotSaved
        : passportNotSaved;
    const addressProofFilesNotSaved =
      addressProofFiles.filter(isFileSaved).length === 0;
    const providerCode = getProviderCode(location.pathname.toUpperCase());

    const isINE = identityDocumentType === LoansDocuments.ineDocumentType;
    const isPassport =
      identityDocumentType === LoansDocuments.passportDocumentType;
    const totalErrors = errors ? Object.keys(errors).length : 0;

    if (
      (step === 3 && providerCode !== loansProviderCodes.MRP && providerCode !== loansProviderCodes.R2 && providerCode !== loansProviderCodes.AVL) ||
      step === 4
    ) {
      if ((isFormValid || totalErrors > 0) && identityFilesNotSaved) {
        !isFormValid && submit(Forms.MR_PRESTA);

        (ineFrontNotSaved || ineBackNotSaved) &&
          isINE &&
          focusFileInputType('identity');
        passportNotSaved && isPassport && focusFileInputType('passport');

        return;
      } else if (
        (isFormValid || (totalErrors === 1 && errors.terms)) &&
        addressProofFilesNotSaved
      ) {
        !isFormValid && submit(Forms.MR_PRESTA);

        focusFileInputType('proofOfAddress');
        return;
      }
    }
    submit(Forms.MR_PRESTA);

    if (
      ((step === 3 && providerCode !== loansProviderCodes.MRP && providerCode !== loansProviderCodes.R2 && providerCode !== loansProviderCodes.AVL) ||
        step === 4) &&
      !isFormValid
    ) {
      return focusInputWithErrors(errors);
    }
  };

  const ondragover = (event) => {
    event.preventDefault();
    event.stopPropagation();
    turnOnDragFile();
  };

  ondragleave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    turnOffDragFile();
  };

  ondrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    turnOffDragFile();
  };

  const handleEventStepper = (e) => {
    const providerCode = preapprovalSelected.provider_code;
    const providerCodeForURL = getProviderCodeForURL(providerCode);
    let urlForm;

    if (!isFormValid) {
      if (step === 1) {
        identityRef.current.checkDocuments();
        if (showTelephoneValidation) {
          identityRef.current.checkTelephones();
        }
      }
      focusInputWithErrors(errors);
      return false;
    } else if (step === 1) {
      const documentsFilled = identityRef.current.checkDocuments();
      const telephonesFilled = showTelephoneValidation ? identityRef.current.checkTelephones() : true;
      if (!documentsFilled ||  !telephonesFilled) {
        return false;
      }

      if (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL) {
        urlForm = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormHistoryV4CA,
          urls.loansFormHistoryV4,
        );
      } else {
        urlForm = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormAddressV4CA,
          urls.loansFormAddressV4,
        );
      }
    } else if (step === 2) {
      if (providerCode !== loansProviderCodes.MRP && providerCode !== loansProviderCodes.R2 && providerCode !== loansProviderCodes.AVL) {
        const proofAddressFilled = addressRef.current.verifyProofOfAddress();
        if (!proofAddressFilled) {
          return false;
        }
      }

      if (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL) {
        urlForm = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormAddressV4CA,
          urls.loansFormAddressV4,
        );
      } else {
        urlForm = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormDepositV4CA,
          urls.loansFormDepositV4,
        );
      }
    } else if (step === 3 && (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL)) {
      const proofAddressFilled = addressRef.current.verifyProofOfAddress();
      if (!proofAddressFilled) {
        return false;
      }

      urlForm = getProviderCashAdvanceURL(
        showCashAdvance,
        providerCodeForURL,
        urls.loansFormDepositV4CA,
        urls.loansFormDepositV4,
      );
    } else {
      return;
    }
    history.replace(getUrl(`${getBaseLocation()}${urlForm}`));
  };

  const handleInverseEventStepper = useCallback(() => {
    changeStep(step - 1);
  }, [step, changeStep]);

  const checkStepper = useCallback(() => {
    const providerCode = preapprovalSelected?.provider_code;

    if (step === 3 && (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL)) {
      return true;
    }

    return false;
  }, [step, preapprovalSelected?.provider_code]);

  useEffect(() => {
    if (identityDocumentType === 'PASSPORT' && !passportUpdated) {
      changePassportUpdated(true);
    }
  }, [identityDocumentType, passportUpdated]);

  const handleDepositStep = useCallback(
    (enabled) => {
      changeDepositStepComplete(enabled);
    },
    [changeDepositStepComplete],
  );

  const renderNewProductName = (prevText) => {
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  const submitForm = (e) => {
    const status = handleEventStepper();

    if (status === false && isFormValid) {
      e.preventDefault();
      return;
    }

    handleSubmit(e);
  };
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const providerCode = preapprovalSelected?.provider_code;
  const isMobileWebView = location.pathname.startsWith(urls.mobile);
  let hasPreviousLoan = Object.keys(lastOne).length !== 0;

  const SnackBar = ({ open, handleClose }) => {
    const state = {
      severity: FeedbackQualities.SUCCESS,
      message: 'Ha ocurrido un error',
      textAlign: 'left',
      variant: 'standard',
    }
    const positionDesktop = {
      vertical: 'bottom',
      horizontal: 'left',
    }
    const positionMobile = {
      vertical: 'top',
      horizontal: 'left',
    }
  
    const { severity, message, variant } = state;
    const { vertical, horizontal } = isMobileWebView ? positionMobile : positionDesktop;
    
  
  return (
    <ClipSnackbar
      className={clsx({[classes.snackAlert]: isMobileWebView, [classes.snackAlertDesktop]: !isMobileWebView})}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      autoHideDuration={6000}
      key={vertical + horizontal}>
      <ClipAlert
        className={classes.toastAlert}
        elevation={6}
        variant={variant}
        severity={severity}
      >
        {text.offerChanged}
      </ClipAlert>
    </ClipSnackbar>
  )
}

  return (
    <Fragment>
      <Form
        onSubmit={submitForm}
        onDragOver={ondragover}
        onDragLeave={ondragleave}
        onDrop={ondrop}
      >
        <ClipGrid container spacing={2}>
       
          <ClipGrid item xs={12} sm={6} md={6} lg={8} xl={8}>
            <SnackBar open={changeOfferSuccess} handleClose={() => dismissChangeOfferAlert()} />
            {step === 1 && (
              <GoogleAnalytics>
                <IdentityStep
                  providerCode={providerCode}
                  hasPreviousLoan={hasPreviousLoan}
                  previousLoan={hasPreviousLoan && lastOne}
                  wrappedComponentRef={identityRef}
                />
              </GoogleAnalytics>
            )}
            {step === 2 && (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL) && (
              <GoogleAnalytics>
                <HistoryStep
                  providerCode={providerCode}
                  hasPreviousLoan={hasPreviousLoan}
                />
              </GoogleAnalytics>
            )}
            {step === 2 && (providerCode !== loansProviderCodes.MRP && providerCode !== loansProviderCodes.R2 && providerCode !== loansProviderCodes.AVL) && (
              <GoogleAnalytics>
                <AddressStep
                  providerCode={providerCode}
                  hasPreviousLoan={hasPreviousLoan}
                  previousLoan={hasPreviousLoan && lastOne}
                  wrappedComponentRef={addressRef}
                />
              </GoogleAnalytics>
            )}
            {step === 3 && (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL) && (
              <GoogleAnalytics>
                <AddressStep
                  providerCode={providerCode}
                  hasPreviousLoan={hasPreviousLoan}
                  previousLoan={hasPreviousLoan && lastOne}
                  wrappedComponentRef={addressRef}
                />
              </GoogleAnalytics>
            )}
            {step === 3 && (providerCode !== loansProviderCodes.MRP && providerCode !== loansProviderCodes.R2 && providerCode !== loansProviderCodes.AVL) && (
              <GoogleAnalytics>
                <DepositStep
                  providerCode={providerCode}
                  hasPreviousLoan={hasPreviousLoan}
                  handleDepositStep={handleDepositStep}
                />
              </GoogleAnalytics>
            )}
            {step === 4 && (
              <GoogleAnalytics>
                <DepositStep
                  providerCode={providerCode}
                  hasPreviousLoan={hasPreviousLoan}
                  handleDepositStep={handleDepositStep}
                />
              </GoogleAnalytics>
            )}
          </ClipGrid>
          <ClipGrid item sm={6} md={6} lg={4} xl={4}>
            <SwitchOffer
              preApproval={preapprovalSelected}
              checkStepper={checkStepper}
              step={step}
            />
            <div className={classes.Desk}>
              {step === 1 && (
                <ClipButton
                  className={classes.Buttoncontinue}
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={checkStepper}
                >
                  {text.continue}
                </ClipButton>
              )}
              {((step > 1 &&
                step < 3 &&
                providerCode !== loansProviderCodes.MRP &&
                providerCode !== loansProviderCodes.R2 &&
                providerCode !== loansProviderCodes.AVL) ||
                (step > 1 &&
                  step < 4 &&
                  (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL))) && (
                <ClipButton
                  className={classes.Buttoncontinue}
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={checkStepper}
                  disabled={(() => {
                    switch (step) {
                      case 2:
                        return false;
                      case 3:
                        if (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL) {
                          return false;
                        }
                        if (
                          providerCode !== loansProviderCodes.MRP &&
                          providerCode !== loansProviderCodes.R2 &&
                          providerCode !== loansProviderCodes.AVL &&
                          depositStepComplete
                        ) {
                          return false;
                        }
                        break;
                      default:
                        if (depositStepComplete) {
                          return false;
                        }
                        break;
                    }
                    return true;
                  })()}
                >
                  {text.continue}
                </ClipButton>
              )}
            </div>
            <ClipGrid className={classes.Mobil}>
              {step === 1 && (
                <ClipButton
                  className={classes.Buttoncontinue}
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={checkStepper}
                >
                  {text.continue}
                </ClipButton>
              )}
              {((step > 1 &&
                step < 3 &&
                providerCode !== loansProviderCodes.MRP &&
                providerCode !== loansProviderCodes.R2 &&
                providerCode !== loansProviderCodes.AVL) ||
                (step > 1 &&
                  step < 4 &&
                  (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL))) && (
                <ClipButton
                  className={classes.Buttoncontinue}
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={checkStepper}
                  disabled={(() => {
                    switch (step) {
                      case 2:
                        return false;
                      case 3:
                        if (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL) {
                          return false;
                        }
                        if (
                          providerCode !== loansProviderCodes.MRP &&
                          providerCode !== loansProviderCodes.R2 &&
                          providerCode !== loansProviderCodes.AVL &&
                          depositStepComplete
                        ) {
                          return false;
                        }
                        break;
                      default:
                        if (depositStepComplete) {
                          return false;
                        }
                        break;
                    }
                    return true;
                  })()}
                >
                  {text.continue}
                </ClipButton>
              )}
            </ClipGrid>
          </ClipGrid>
        </ClipGrid>
      </Form>
      {((step === 3 && providerCode !== loansProviderCodes.MRP && providerCode !== loansProviderCodes.R2 && providerCode !== loansProviderCodes.AVL) ||
        step === 4) && (
        <ClipGrid container spacing={2}>
          <ClipGrid item sm={6} md={6} lg={8} xl={8}>
            <TermsAndConditions>
              <Field
                name="terms"
                type="checkbox"
                component={renderCheckbox}
                label={
                  <span>
                    {text.termsAndConditions}
                    <ClevertapComponent
                      renderComponent={<Link />}
                      events={[
                        {
                          callbackName: 'onClick',
                          event: EVENTS.LOANS.TC_SELECTED,
                          payload: lenderPayload(preapprovalSelected, {
                            showCashAdvance,
                          }),
                        },
                      ]}
                      to={`${getBaseLocation()}${urls.termConditionsLoans}`}
                    >
                      {text.termsAndConditionsLink}
                    </ClevertapComponent>
                    {text.termsAndConditionsAnd}
                  
                      <ClevertapComponent
                        renderComponent={<a />}
                        events={[
                          {
                            callbackName: 'onClick',
                            event: EVENTS.LOANS.PRIVACY_SELECTED,
                            payload: lenderPayload(preapprovalSelected, {
                              showCashAdvance,
                            }),
                          },
                        ]}
                        href={urls.clipPrivacy}
                        target="_blank"
                        rel="noopener noreferrer"
                      >

                        {text.termsAndConditionsPrivacy}

                        
                      </ClevertapComponent>
                   
                  
                    {renderNewProductName(text.endTermsAndConditions)}
                  </span>
                }
                validate={[termsAndConditions]}
                labelWithLinks={true}
              />
            </TermsAndConditions>
          </ClipGrid>
          <ClipGrid item sm={6} md={6} lg={4} xl={4}>
            <div className={classes.Desk}>
              {!sendingForm && (
                <ClipButton
                  className={classes.Buttonfinish}
                  type="button"
                  onClick={validateFilesAndSendForm.bind(this)}
                  variant="contained"
                  disabled={!depositStepComplete}
                >
                  {renderNewProductName(text.finish)}
                </ClipButton>
              )}
              {sendingForm && (
                <SubmitButtonV3 type="button" disabled={true}>
                  <ProgressIcon
                    variant="indeterminate"
                    size={24}
                    thickness={6}
                  />
                </SubmitButtonV3>
              )}
            </div>
            <ClipGrid className={classes.Mobil}>
              <ClipGrid>
                {!sendingForm && (
                  <ClipButton
                    className={classes.Buttonfinish}
                    type="button"
                    onClick={validateFilesAndSendForm.bind(this)}
                    variant="contained"
                    disabled={!depositStepComplete}
                  >
                    {text.finish}
                  </ClipButton>
                )}
                {sendingForm && (
                  <SubmitButtonV3 type="button" disabled={true}>
                    <ProgressIcon
                      variant="indeterminate"
                      size={24}
                      thickness={6}
                    />
                  </SubmitButtonV3>
                )}
              </ClipGrid>
            </ClipGrid>
          </ClipGrid>
        </ClipGrid>
      )}
      <Dialog
        fullScreen={false}
        open={showDialog}
        onClose={handleDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {text.titleErrorDialog}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{text.messageErrorDialog}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            {text.close}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const selector = formValueSelector(Forms.MR_PRESTA);

const mapStateToProps = (state) => {
  return {
    colonies: getLoansColonies(state),
    isFormValid: isValid(Forms.MR_PRESTA)(state),
    errors: state.form[Forms.MR_PRESTA].syncErrors,
    postalCode: selector(state, 'postalCode'),
    identityFiles: getLoansIdentityDocuments(state),
    identityFilesBack: getLoansIdentityDocumentsBack(state),
    passportFiles: getLoansPassportDocuments(state),
    addressProofFiles: getLoansProofAddressDocuments(state),
    birthday: selector(state, 'birthday'),
    year: selector(state, 'year'),
    month: selector(state, 'month'),
    day: selector(state, 'day'),
    changeOfferSuccess: state.loans.changeOfferSuccess,
    lastName: selector(state, 'lastName'),
    secondLastName: selector(state, 'secondLastName'),
    gender: selector(state, 'gender'),
    maritalStatus: selector(state, 'maritalStatus'),
    hasCreditCard: selector(state, 'hasCreditCard'),
    hasMortgageCredit: selector(state, 'hasMortgageCredit'),
    hasVehicleCredit: selector(state, 'hasVehicleCredit'),
    colony: selector(state, 'colony'),
    municipality: selector(state, 'municipality'),
    state: selector(state, 'state'),
    street: selector(state, 'street'),
    streetNumber: selector(state, 'streetNumber'),
    sendingForm: getSendingForm(state),
    initialValues: getPrefilledForm(state),
    identityDocumentType: selector(state, 'identityDocumentType'),
    preapprovalSelected: getPreapprovalSelected(state),
    merchantId: getMerchantId(state),
    prestaClipNameChange: getPrestaClipNameChange(state),
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
    preApproval: state.loans.request.preapproval,
  };
};

const mapDispatchToProps = {
  submit,
  turnOnDragFile,
  turnOffDragFile,
  dismissChangeOfferAlert,
};

const ReduxMrPRestaForm = reduxForm({
  form: Forms.MR_PRESTA,
  asyncValidate: asyncValidatePostalCode,
  asyncChangeFields: ['postalCode'],
  touchOnChange: true,
  destroyOnUnmount: false,
})(ExternalForm);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReduxMrPRestaForm),
);

ExternalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  turnOnDragFile: PropTypes.func.isRequired,
  turnOffDragFile: PropTypes.func.isRequired,
  prestaClipNameChange: PropTypes.bool.isRequired,
  step: PropTypes.number,
  changeStep: PropTypes.func.isRequired,
  merchantId: PropTypes.string,
  preapprovalSelected: PropTypes.object,
};
