import React, { useEffect, useState } from 'react';
import text from './text';
import Form from '../Form';
import PropTypes from 'prop-types';
import ClaveTooltip from './ClaveTooltip';
import {EVENTS} from '../../../../helpers/Clevertap'
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { checkSpecialCharacters } from '../../utils';
import { Forms } from '../../../../constants/AppConstants';

let ClaveElector = (props) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const { setOpen, setStep, step, dispatch } =
    props;

  const checkDisabled = () => {
    var notSpecial = checkSpecialCharacters(value);
    if (!notSpecial) {
      return true;
    } else {
      if (!value || value === '' || value.length < 18) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    dispatch(change(Forms.BERLIN_N3, 'electorClave', value));
  }, [value]);

  const validateCharacters = (str) => {
    var notSpecial = checkSpecialCharacters(str);
    if (!notSpecial) {
      setError(text.caracteresEspecialesError);
    } else {
      setError('');
    }
  };

  return (
    <div>
      <Form
        inputs={[
          {
            error: error,
            value: value.toUpperCase(),
            setValue: (e) => {
              validateCharacters(e.target.value);
              setValue(e.target.value);
            },
            label: text.inputPlaceholder,
            help: {
              helpLog: EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT,
              payload: EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.INE_ID_HELP_CLICKED,
              helpText: text.helpText,
              tooltip: <ClaveTooltip />,
            },
          },
        ]}
        title={text.title}
        subtitle={text.subtitle}
        step={step}
        setOpen={setOpen}
        handleClick={() => setOpen(true)}
        btn={{
          text: text.btnText,
          action: () => {
            setStep(step + 1);
          },
          disabled: checkDisabled(),
          event: EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT,
          payload: EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.INE_ID_CONTINUED
        }}
      />
    </div>
  );
};

ClaveElector = reduxForm({
  form: Forms.BERLIN_N3,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(ClaveElector);

ClaveElector.propTypes = {
  setOpen: PropTypes.func,
  dispatch: PropTypes.func,
};
ClaveElector.defaultProps = {
  setOpen: () => {},
  dispatch: () => {},
};

export default connect()(ClaveElector);
