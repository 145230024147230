import React, { Component } from 'react';
import { Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';

import {
  required,
  atLeast3Characters,
  text as isText,
  isAdult,
  maxLength,
} from 'helpers/validate';
import { Forms } from 'constants/AppConstants';
import {
  renderSelectField,
  renderInput,
  renderRadioGroup,
} from 'helpers/FormUtils';
import utils from 'helpers/utils';
import { validExternalProvider, showBirth } from 'constants/ProviderCodes';
import {
  setLoansDays,
  cleanLastOneIdentity,
  cleanLastOneIdentityBack,
  cleanLastOnePassport,
} from 'components/loans/redux/actions';
import {
  getLoansDays,
  getPreapprovalSelected,
  getLastOne,
  hasLastOne,
} from 'components/loans/redux/selectors';
import * as Styled from 'components/loans/Commons/Form/styled';

import text from './text';

export class PersonalInformation extends Component {
  state = {
    yearOptions: utils.getYearOptions(),
    monthOptions: text.monthsOptions,
    maritalStatusOptions: text.maritalOptions,
    documentsDeleted: false,
  };

  updateDays = (value) => {
    const { year, month, change, setLoansDays } = this.props;
    change(Forms.MR_PRESTA, 'addressYear', '');
    change(Forms.MR_PRESTA, 'addressMonth', '');
    if (value > 31) {
      setLoansDays(value, month);
    } else {
      setLoansDays(year, value);
    }
  };

  deleteIdentityDocuments = () => {
    const {
      previousLoans,
      cleanLastOneIdentity,
      cleanLastOneIdentityBack,
      cleanLastOnePassport,
    } = this.props;
    const { documentsDeleted } = this.state;

    if (previousLoans && !documentsDeleted) {
      cleanLastOneIdentity();
      cleanLastOneIdentityBack();
      cleanLastOnePassport();
      this.setState({ documentsDeleted: true });
    }
  };

  render() {
    const { yearOptions, monthOptions, maritalStatusOptions } = this.state;
    const {
      preapprovalSelected = {},
      firstName,
      lastName,
      secondLastName,
      year,
      month,
      day,
      days,
      lastOne,
      hasLastOne,
    } = this.props;
    const { provider_code } = preapprovalSelected;
    const isValidProvider = validExternalProvider(provider_code);
    const isPrefilledByMerchantInfo = hasLastOne && !lastOne.pre_approval_id;
    return (
      <React.Fragment>
        <Styled.FormControl fullWidth>
          <Styled.TextField
            className={
              isPrefilledByMerchantInfo &&
              firstName &&
              firstName === lastOne.personal_information.name &&
              'preloaded'
            }
            name="firstName"
            component={renderInput}
            inputProps={{ maxLength: 35 }}
            type="text"
            label={text.name}
            validate={[required, isText, atLeast3Characters]}
            helperText={maxLength(35)(firstName)}
            onChange={this.deleteIdentityDocuments}
          />
        </Styled.FormControl>
        <Styled.FormControl margin="normal" fullWidth>
          <Styled.DoubleInput>
            <Styled.TextField
              className={
                isPrefilledByMerchantInfo &&
                lastName &&
                lastName === lastOne.personal_information.last_name &&
                'preloaded'
              }
              name="lastName"
              component={renderInput}
              inputProps={{ maxLength: 35 }}
              type="text"
              label={text.fLastName}
              validate={[required, isText, atLeast3Characters]}
              helperText={maxLength(35)(lastName)}
              onChange={this.deleteIdentityDocuments}
            />
            <Styled.TextField
              className={
                isPrefilledByMerchantInfo &&
                secondLastName &&
                secondLastName ===
                  lastOne.personal_information.second_last_name &&
                'preloaded'
              }
              name="secondLastName"
              component={renderInput}
              inputProps={{ maxLength: 35 }}
              label={text.mLastName}
              validate={[required, isText]}
              helperText={maxLength(35)(secondLastName)}
              onChange={this.deleteIdentityDocuments}
            />
          </Styled.DoubleInput>
        </Styled.FormControl>
        {showBirth(provider_code) && (
          <Styled.FormControl margin="normal" fullWidth>
            <Styled.CompoundFormLabel component="legend">
              {text.titleBirthday}
            </Styled.CompoundFormLabel>
            <Styled.TrippleInput>
              <Styled.FormField
                className={
                  isPrefilledByMerchantInfo &&
                  year &&
                  lastOne.personal_information.birthday &&
                  'preloaded'
                }
                component={renderSelectField}
                label={text.year}
                name="year"
                options={yearOptions}
                validate={[required]}
                onChange={this.updateDays}
              />
              <Styled.FormField
                className={
                  isPrefilledByMerchantInfo &&
                  month &&
                  lastOne.personal_information.birthday &&
                  'preloaded'
                }
                component={renderSelectField}
                label={text.month}
                name="month"
                options={monthOptions}
                validate={[required]}
                onChange={this.updateDays}
              />
              <Styled.FormField
                className={
                  isPrefilledByMerchantInfo &&
                  day &&
                  lastOne.personal_information.birthday &&
                  'preloaded'
                }
                component={renderSelectField}
                label={text.day}
                name="day"
                validate={[
                  required,
                  (day, values) => {
                    return isAdult(values.year, values.month, day);
                  },
                ]}
                options={days}
              />
            </Styled.TrippleInput>
          </Styled.FormControl>
        )}
        {isValidProvider && (
          <Styled.FormControl margin="normal" fullWidth>
            <Styled.CompoundFormLabel component="legend">
              {text.titleGender}
            </Styled.CompoundFormLabel>
            <Field
              name="gender"
              component={renderRadioGroup}
              validate={[required]}
            >
              <Styled.FormControlRadio
                value={'F'}
                control={<Styled.PinkRadio />}
                label={text.female}
                onChange={this.deleteIdentityDocuments}
                labelPlacement="end"
              />
              <Styled.FormControlRadio
                value={'M'}
                control={<Styled.BlueRadio />}
                label={text.male}
                onChange={this.deleteIdentityDocuments}
                labelPlacement="end"
              />
            </Field>
          </Styled.FormControl>
        )}
        {isValidProvider && (
          <Styled.FormControl fullWidth>
            <Styled.FormField
              component={renderSelectField}
              label={text.maritalStatus}
              name="maritalStatus"
              options={maritalStatusOptions}
              validate={[required]}
            />
          </Styled.FormControl>
        )}
      </React.Fragment>
    );
  }
}

const selector = formValueSelector(Forms.MR_PRESTA);
const mapStateToProps = (state) => {
  return {
    firstName: selector(state, 'firstName'),
    secondLastName: selector(state, 'secondLastName'),
    lastName: selector(state, 'lastName'),
    year: selector(state, 'year'),
    month: selector(state, 'month'),
    day: selector(state, 'day'),
    days: getLoansDays(state),
    preapprovalSelected: getPreapprovalSelected(state),
    lastOne: getLastOne(state),
    hasLastOne: hasLastOne(state),
  };
};

const mapDispatchToProps = {
  setLoansDays,
  cleanLastOneIdentity,
  cleanLastOneIdentityBack,
  cleanLastOnePassport,
  change,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalInformation);
