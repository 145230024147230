export const styles = (theme) => {
  const rootStyles = {
    container: {
      margin: `${theme.spacing(8)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
      minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    content: {
      margin: `${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
    },
    helpIconButton: {
      padding: 0,
      verticalAlign: 'bottom',
    },
    helpIcon: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
    iconsContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(3),
    },
    swapLogo: {
      marginTop: theme.spacing(0.75),
      marginLeft: theme.spacing(0.5),
    },
    iconsDiv: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
    iconDiv: {
      borderRadius: '100%',
      overflow: 'hidden',
      width: theme.spacing(3.4),
      height: theme.spacing(3.4),
      marginRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icons: {
      width: theme.spacing(4.62),
      height: theme.spacing(4.62),
      color: theme.palette.primary.l50,
    },
    privacy: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      marginBottom: theme.spacing(1),
    },
    privacy2: {
      color: theme.palette.orange.base,
    },
    button: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    textBox: {
      display: 'flex',
    },
    backIcon: {
      color: theme.palette.orange.base,
      marginRight: theme.spacing(2),
    },
    color: {
      fontWeight: 'bold',
      color: theme.palette.secondary.l10,
    },
    color2: {
      fontWeight: 'bold',
    },
    bankTextContainer: {
      marginTop: theme.spacing(3),
    },
    bankText: {
      marginBottom: theme.spacing(2),
    },
    bankContinueText: {
      color: theme.palette.secondary.l10,
      marginBottom: theme.spacing(2),
    },
    drawer: {
      zIndex: 3001,
    },
    paper: {
      borderRadius: '16px 16px 0 0',
      padding: `${theme.spacing(2)}px`,
    },
    listItem: {
      fontSize: `${theme.spacing(2)}px`,
      marginBottom: `${theme.spacing(3)}px`,
    },
  };
  return { ...rootStyles };
};
