import React from 'react';
import { shape, node } from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';

const ThemeProvider = ({ theme, children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);

ThemeProvider.propTypes = {
  theme: shape({}).isRequired,
  children: node.isRequired,
};

export default ThemeProvider;
