import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';

import PropTypes from 'prop-types';
import { getPrestaClipNameChange } from 'components/loans/redux/selectors';
import {
  Forms,
  LoansCurrentProductName,
  LoansNewProductName,
} from 'constants/AppConstants';
import ClevertapField from '../../../../forms/ClevertapField';
import { EVENTS } from '../../../../../helpers/Clevertap';
import { required, isInteger, fourNumbers } from 'helpers/validate';

import RadioField from 'helpers/FormUtils/RadioField';
import TextField from 'helpers/FormUtils/TextField';
import { isInvalidFormField } from 'helpers/FormUtils';

import { StepTitle, StepDescription, StepSection, FormControl } from './styled';
import { lenderPayload } from '../../../Commons/Clevertap';
import text from './text';

export class HistoryStep extends Component {
  static propTypes = {
    change: PropTypes.func.isRequired,
    handleHistoryStep: PropTypes.func.isRequired,
    prestaClipNameChange: PropTypes.bool.isRequired,
    lastCreditCardNumbers: PropTypes.string,
    hasCreditCard: PropTypes.string,
    hasMortgageCredit: PropTypes.string,
    hasVehicleCredit: PropTypes.string,
  };

  deleteLast4 = () => {
    const { change } = this.props;
    change(Forms.MR_PRESTA, 'lastCreditCardNumbers', '');
  };

  enableNextStep = () => {
    const {
      handleHistoryStep,
      hasCreditCard,
      lastCreditCardNumbers,
      hasMortgageCredit,
      hasVehicleCredit,
    } = this.props;

    let nextStepEnabled = true;
    if (
      hasCreditCard === undefined ||
      hasMortgageCredit === undefined ||
      hasVehicleCredit === undefined
    ) {
      nextStepEnabled = false;
    }
    if (hasCreditCard === 'true' && isInvalidFormField(lastCreditCardNumbers)) {
      nextStepEnabled = false;
    }

    handleHistoryStep(nextStepEnabled);
  };

  componentDidMount() {
    this.enableNextStep();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.enableNextStep();
  }

  renderNewProductName = (prevText) => {
    const { prestaClipNameChange } = this.props;
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  render() {
    const {
      hasCreditCard,
      preapprovalSelected,
      showCashAdvance,
      loansFormVersion,
    } = this.props;

    return (
      <Fragment>
        <StepTitle>{text.title}</StepTitle>
        <StepDescription>
          {this.renderNewProductName(text.description)}
        </StepDescription>

        <StepSection>{text.creditCardSection}</StepSection>
        <FormControl fullWidth>
          <ClevertapField
            name="hasCreditCard"
            component={RadioField}
            validate={[required]}
            options={text.creditCardOptions}
            onChange={this.deleteLast4}
            clevertap={{
              listener: 'onChange',
              event: EVENTS.LOANS.CREDIT_HISTORY_SELECTED,
              payload: lenderPayload(preapprovalSelected, {
                showCashAdvance,
                loansFormVersion,
              }),
            }}
          />
          {hasCreditCard === 'true' ? (
            <ClevertapField
              name="lastCreditCardNumbers"
              component={TextField}
              inputProps={{ maxLength: 4 }}
              type="text"
              label={text.creditCardLast4}
              validate={[required, isInteger, fourNumbers]}
              clevertap={{
                listener: 'onChange',
                event: EVENTS.LOANS.CREDIT_HISTORY_COMPLETED,
                payload: lenderPayload(preapprovalSelected, {
                  showCashAdvance,
                  loansFormVersion,
                }),
              }}
            />
          ) : null}
        </FormControl>

        <StepSection>{text.mortgageSection}</StepSection>
        <FormControl fullWidth>
          <ClevertapField
            name="hasMortgageCredit"
            component={RadioField}
            validate={[required]}
            options={text.mortgageOptions}
            clevertap={{
              listener: 'onChange',
              event: EVENTS.LOANS.CREDIT_HISTORY_MC_SELECTED,
              payload: lenderPayload(preapprovalSelected, {
                showCashAdvance,
                loansFormVersion,
              }),
            }}
          />
        </FormControl>

        <StepSection>{text.automobileSection}</StepSection>
        <FormControl fullWidth>
          <ClevertapField
            name="hasVehicleCredit"
            component={RadioField}
            validate={[required]}
            options={text.mortgageOptions}
            clevertap={{
              listener: 'onChange',
              event: EVENTS.LOANS.CREDIT_HISTORY_AC_SELECTED,
              payload: lenderPayload(preapprovalSelected, {
                showCashAdvance,
                loansFormVersion,
              }),
            }}
          />
        </FormControl>
      </Fragment>
    );
  }
}

const selector = formValueSelector(Forms.MR_PRESTA);

const mapStateToProps = (state) => {
  return {
    hasCreditCard: selector(state, 'hasCreditCard'),
    lastCreditCardNumbers: selector(state, 'lastCreditCardNumbers'),
    hasMortgageCredit: selector(state, 'hasMortgageCredit'),
    hasVehicleCredit: selector(state, 'hasVehicleCredit'),
    prestaClipNameChange: getPrestaClipNameChange(state),
    preapprovalSelected: state.loans?.request?.preapproval,
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  change,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryStep);
