import moment from 'moment-timezone';
import {
  successResponse,
  requestState,
  errorResponse,
} from '../../../middleware/apiResolve/reduxUtils';
import {
  MERCHANT_BUM_LIST,
  MERCHANT_BUM_SWITCH,
  MERCHANT_BUM,
  SET_NONCE_HAS_EXPIRED,
  SET_EMAIL_LIST,
  CLEAR_EMAIL_SENT_LISTS,
} from './types';

const initialState = {
  meta: {},
  items: [],
  isLoading: false,
  nonceHasExpired: false,
  emailList: [],
  successEmails: [],
  errorEmails: [],
};

const actionHandlers = new Map([
  [requestState('get', MERCHANT_BUM_LIST), handleLoading],
  [successResponse('get', MERCHANT_BUM_LIST), handleBusinessUnits],
  [errorResponse('get', MERCHANT_BUM_LIST), handleBusinessUnitsError],
  [successResponse('post', MERCHANT_BUM), handleBumEmailSent],
  [errorResponse('post', MERCHANT_BUM), handleBusinessUnitsError],
  [errorResponse('post', MERCHANT_BUM_SWITCH), handleBusinessUnitsError],
  [SET_NONCE_HAS_EXPIRED, handleSetNonceHasExpired],
  [SET_EMAIL_LIST, handleSetEmailList],
  [CLEAR_EMAIL_SENT_LISTS, handleClearEmailSentLists],
]);

export default function businessUnitsManagementReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleLoading(state) {
  return {
    ...state,
    isLoading: true,
  };
}

function handleBusinessUnits(state, action) {
  const items = action.payload?.data?.items;
  items.forEach((i) => {
    switch (i.relationship_status) {
      case 'ACTIVE':
        i.relationship_status = 'Activo';
        break;
      case 'PENDING':
        i.relationship_status = 'Pendiente';
        break;
    }

    i.created_at = moment(i.created_at).format('DD/MM/YYYY hh:mm A');
  });
  return {
    ...state,
    items,
    isLoading: false,
  };
}

function handleBusinessUnitsError(state, action) {
  const nonceExpired = action.payload?.data?.messages?.findIndex(
    (message) => message.code === 'payclip.invalid.session',
  );

  return {
    ...state,
    nonceHasExpired: nonceExpired != -1,
  };
}

function handleBumEmailSent(state, action) {
  const items = action.payload?.data?.items || [];
  let successEmails = [];
  let errorEmails = [];

  items.forEach((item) => {
    if (item.error_message) {
      errorEmails.push(item.child_merchant_email);
    } else {
      successEmails.push(item.child_merchant_email);
    }
  });

  return {
    ...state,
    successEmails,
    errorEmails,
  };
}

function handleClearEmailSentLists(state, _action) {
  return {
    ...state,
    successEmails: [],
    errorEmails: [],
  };
}

function handleSetNonceHasExpired(state, action) {
  const {
    data: { nonceHasExpired },
  } = action.payload;
  return {
    ...state,
    nonceHasExpired,
  };
}

function handleSetEmailList(state, action) {
  const {
    data: { emailList },
  } = action.payload;
  return {
    ...state,
    emailList,
  };
}
