export const styles = (theme) => {
  const rootStyles = {
    stepsContainer: {
      width: '100%',
    },
    stepContainer: {
      display: 'inline-block',
      textAlign: 'center',
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
    },
    steps: {
      width: '100%',
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1fr 2fr 1fr 2fr 1fr',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      },
    },
    stepText: {
      marginTop: theme.spacing.unit,
      padding: 0,
    },
    stepLine: {
      height: theme.spacing(0),
      width: '100%',
      marginTop: theme.spacing(5.5),
      border: `${theme.spacing(0.25)}px solid ${theme.palette.indigo.l80}`,
    },
    activeStepLine: {
      border: `${theme.spacing(0.25)}px solid #CCE3F0`,
    },
    blue: {
      color: theme.palette.celurean.base,
    },
    red: {
      color: theme.palette.sangria.base,
    },
    line: {
      position: 'relative',
      top: '21px',
      zIndex: '-1',
      width: '69%',
      border: 'none',
      borderTop: 'solid 2px #ccd0d3',
      marginTop: 0,
      marginBottom: 0,
    },
  };

  return { ...rootStyles };
};
