export default {
  title: 'Solicita tu Clip Card',
  contact: 'Contacto',
  contactDescription:
    'Queremos mantenerte informado sobre tu pedido. Por favor, completa tus datos.',
  nameField: 'Nombre',
  lastNameField: 'Apellido paterno',
  secondLastNameField: 'Apellido materno',
  phoneField: 'Teléfono',
  address: 'Dirección de envío',
  receiveYourCard: '¿Dónde quieres recibir tu Clip Card?',
  streetField: 'Calle',
  externalNumberField: 'Número exterior',
  internalNumberField: 'Número interior',
  postalCodeField: 'Código postal',
  colonyField: 'Colonia',
  municipalityField: 'Delegación o municipio',
  cityField: 'Ciudad',
  stateField: 'Estado',
  deliveryMessage: 'Indicaciones para el repartidor',
  deliveryQuestion: '¿Dónde quieres recibir tu Clip Card?',
  references: 'Referencias',
  deliveryField: 'Entre calles',
  submitButton: 'Solicitar',
  requiredError: 'Este dato es requerido para completar tu solicitud.',
  navigatorTitle: 'Quiero una Clip Card',
  errorTitle: 'No eres tú, somos nosotros.',
  errorMessage: 'Por favor, inténtalo de nuevo.',
};
