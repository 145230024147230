import React from 'react';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipTypography from '@clipmx/clip-storybook/dist/components/Typography';
import FormControl from '@clipmx/clip-storybook/dist/components/FormControl';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FormButton from '../../../helpers/FormUtils/FormButton';
import TextField from '../../../helpers/FormUtils/TextField';
import { isValidMembership } from '../../../helpers/validate';
import getUrl from '../../../helpers/getUrl';
import urls from '../../../helpers/urls';
import ClipLogo from '../../../assets/images/clip-logo-registered.svg';
import SamsLogo from '../../../assets/images/loans/sams-logo.svg';
import Gift from '../../../assets/images/loans/gift-card.svg';
import GoogleAnalytics from '../GoogleAnalytics';
import { styles } from './styles';
import text from './text';

export const SamsClipIcon = withStyles(styles)(({ classes, small }) => (
  <section className={clsx(classes.bannerHeader, { small: small })}>
    <img alt="Sams Club" src={SamsLogo} className={clsx({ small: small })} />
    <img alt="Presta Clip" src={ClipLogo} className={clsx({ small: small })} />
  </section>
));

export const Sams = ({
  classes,
  handleSubmit,
  lender,
  membership,
  onChange,
  onClose,
  onContinue,
}) => {
  let location = useLocation();
  return (
    <GoogleAnalytics
      defaultUrl={getUrl(
        `${
          location.pathname.startsWith(urls.mobile)
            ? urls.mobile
            : ''
        }${urls.loansSams.replace(':lender', lender)}`
      )}
    >
      <form onSubmit={handleSubmit}>
        <Grid item sm={12} xs={12} className={classes.gridCard}>
          <article className={classes.header}>
            <SamsClipIcon />
            <section className={classes.closeContainer}>
              <button
                type="button"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseRoundedIcon />
              </button>
            </section>
          </article>
          <article className={classes.bannerContainer}>
            <img alt="gift" src={Gift} />
          </article>
          <ClipTypography className={classes.title}>
            {text.title}
          </ClipTypography>
          <ClipTypography className={classes.body1}>
            {text.description}
          </ClipTypography>
          <article>
            <FormControl fullWidth className={classes.formControl}>
              <Field
                name="samsMembership"
                component={TextField}
                inputProps={{ maxLength: 17 }}
                type="text"
                label={text.membership}
                onChange={(event) => onChange(event.target.value)}
                value={membership}
                validate={[isValidMembership]}
              />
            </FormControl>
          </article>
          <section className={classes.buttons}>
            <article className={classes.continue}>
              <FormButton
                type="submit"
                variant="contained"
                text={text.continue}
                onClick={() => {}}
              />
            </article>
            <article className={classes.omit}>
              {text.adviceHowTo}
              <FormButton
                type="button"
                onClick={onContinue}
                variant="outlined"
                text={text.omit}
              />
            </article>
          </section>
        </Grid>
      </form>
    </GoogleAnalytics>
  );
};

Sams.propTypes = {
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  membership: PropTypes.string,
  lender: PropTypes.string,
};
Sams.defaultProps = {
  membership: '',
  lender: '',
};

const SamsForm = reduxForm({
  form: 'SamsForm',
  destroyOnUnmount: false,
})(Sams);

export default withStyles(styles)(SamsForm);
