import React, {useState, useEffect} from 'react';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { KeyboardArrowUp } from '@clipmx/clip-storybook/dist/components/Icons';
import styles from './styles';

const useStyles = makeStyles(styles);

const PrivacyPolicy = ({children}) => {
    const classes = useStyles();
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 250) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Set the top cordinate to 0
    // make scrolling smooth
    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        });
    };
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
        window.removeEventListener('scroll', toggleVisibility);
        }
    }, []);

    return(
        <div>
        {children}
        {isVisible &&
          <div onClick={scrollToTop} className={classes.scrollToTop}>
              <KeyboardArrowUp  className={classes.chevron} />
          </div>
        }
      </div>
    )
}

export default PrivacyPolicy;