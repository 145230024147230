import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

export const useStyles = makeStyles((theme) => {
  return {
    cardNewSale: {
      background: theme.palette.teal.l94,
      borderRadius: '8px 8px 0px 0px',
      marginTop: '48px',
    },
    cardNewPaly: {
      background: theme.palette.teal.l94,
      borderRadius: '0px 0px 0px 0px',
    },
    cardProgress: {
      borderRadius: '0px 0px 0px 0px',
    },
    carMyInterested: {
      background: theme.palette.indigo.l98,
      borderRadius: '0px 0px 8px 8px',
    },
    fontW100: {
      fontWeight: '500',
    },
    button: {
      textTransform: 'none !important',
      borderRadius: `${theme.spacing.unit * 5}px`,
    },
    buttonCenter:{
      margin: 'auto',
      marginBottom: '32px',
    },
    textP:{
      '& > p': {
        marginTop: '0px',
      },
    },
    debt: {
      background: theme.palette.indigo.l92,
      padding: '4px',
      display: 'table',
      borderRadius: '4px',
    },
    bank: {
      background: theme.palette.teal.l94,
      color: theme.palette.teal.l10,
      padding: '4px',
      display: 'table',
      borderRadius: '4px',
    },
    iva: {
      background: theme.palette.mustard.l90,
      color: theme.palette.mustard.l10,
      padding: '4px',
      display: 'table',
      borderRadius: '4px',
    },
    ProgressBar: {
      height: '20px',
      margin: '30px auto 0',
      width: '100%',
      display: 'flex',
      borderRadius: '100px',
      overflow: 'hidden',
    },
    ProgressBarDebt: {
      height: '20px',
      background: theme.palette.indigo.l90,
      float: 'left',
    },
    ProgressBarBank: {
      height: '20px',
      background: theme.palette.teal.l20,
      float: 'left',
    },
    ProgressBarIva: {
      height: '20px',
      float: 'left',
      background: theme.palette.mustard.l40,
    },
  };
});

export default useStyles;
