import { reset } from 'redux-form';

import {
  PaymentRequestStates,
  PaymentRequestAttempts,
} from 'constants/AppConstants';
import PaymentRequestService from '../service';
import {
  dataLoading,
  dataLoaded,
  dataLoadFailed,
} from '../../../actions/AppActions';
import usePublicProfile from '../../../hooks/usePublicProfile';
import {
  CANCEL_PAYMENT_REQUEST,
  CREATED, ENABLE_PUBLIC_PROFILE, END_PAYMENT_REQUEST_FORM,
  FILTER_PAYMENT_LIST,
  GET_PAYMENT_REQUEST_LIST,
  KYC_NOTICE_FLAG,
  MERCHANT_LIMIT,
  MULTILINK_FLAG, PUBLIC_PROFILE_INFO, RESEND_EMAIL,
  SEND_PAYMENT_REQUEST_FORM,
  UPDATE_PRIVACY_INFO,
  UPDATE_ALIAS,
} from './types';
import { Analytics } from 'utils/Analytics';
import { getConfigValue } from 'config/config';

export const reviewPaymentRequest = (data) => (dispatch) => {
  const payload = {
    isFormSent: true,
    requestPayment: data,
  };
  dispatch({ type: CREATED, payload });
};

export const confirmPaymentRequest = (data) => async (dispatch) => {
  const response = await PaymentRequestService.sendPaymentRequest(data);
  const { DEACTIVATED_ACCOUNT, MAX_LIMIT } = PaymentRequestAttempts;
  let payload;
  if (
    response.status === DEACTIVATED_ACCOUNT ||
    response.status === MAX_LIMIT
  ) {
    payload = {
      isFormSent: true,
      status: PaymentRequestStates.CREATED,
      requestPayment: data,
      requestPaymentId: '',
    };
    dispatch({ type: SEND_PAYMENT_REQUEST_FORM, payload });
    return response;
  } else {
    payload = {
      isFormSent: true,
      status: PaymentRequestStates.SENT,
      requestPayment: data,
      requestPaymentId: response,
    };
    dispatch({ type: SEND_PAYMENT_REQUEST_FORM, payload });
    Analytics.xpayRequestPayment(
      'xpay-request-payment',
      'Xpay',
      'Payment request - dashboard',
      '',
      data,
    );
    return response;
  }
};

export const finishPaymenRequest = () => (dispatch) => {
  const payload = {
    isFormSent: false,
    isValid: false,
    status: PaymentRequestStates.NEW,
    requestPayment: '',
  };
  dispatch({ type: END_PAYMENT_REQUEST_FORM, payload });
};

export const cancelPaymentRequest = () => (dispatch) => {
  dispatch({ type: CANCEL_PAYMENT_REQUEST, has_payment_request: true });
};

export const getListPaymentRequest = (page, perPage) => async (dispatch) => {
  const paymentRequestList = await PaymentRequestService.getPaymentRequest(
    page,
    perPage,
  );
  const { transactions } = paymentRequestList;
  const {
    page_number: pageNumber,
    page_size: pageSize,
    total_items: totalItems,
  } = paymentRequestList.pagination;
  const payload = {
    paymentRequestList: transactions,
    pagination: {
      pageNumber,
      pageSize,
      totalItems,
    },
  };
  dispatch({ type: GET_PAYMENT_REQUEST_LIST, payload });
};

export const cancelSentPaymentRequest = (paymentRequestCode) => async dispatch => {
  try {
    const paymentCancelResp = await PaymentRequestService.cancelPaymentRequest(paymentRequestCode);
    return paymentCancelResp;
  }catch (e) {
    return { message: e.message, status: e.status = 500 } ;
  }
};

export const resendEmail = (paymentReqCode) => async (dispatch) => {
  dispatch({ type: RESEND_EMAIL });
  dispatch(dataLoading());
  try {
    const response = await PaymentRequestService.resendEmail(paymentReqCode);
    if (response.status === 0) {
      dispatch(dataLoaded(getListPaymentRequest()));
      return response;
    } else {
      dispatch(dataLoadFailed());
      throw new Error('Error requesting paymentrequest forwarding');
    }
  } catch (error) {
    dispatch(dataLoadFailed());
    throw new Error(error.message);
  }
};

export const filterPaymentList =
  (searchType, params, perPage, page) => async (dispatch) => {
    const filterData = await PaymentRequestService.filterPaymentRequest(
      searchType,
      params,
      perPage,
      page,
    );
    const {
      page_number: pageNumber,
      page_size: pageSize,
      total_items: totalItems,
    } = filterData.pagination;
    const payload = {
      filterData: filterData.transactions,
      pagination: {
        pageNumber,
        pageSize,
        totalItems,
      },
    };
    dispatch({ type: FILTER_PAYMENT_LIST, payload });
  };

export const getMerchantLimit = (user) => async (dispatch) => {
  const LDClient = require('launchdarkly-js-client-sdk');
  const ldClient = LDClient.initialize(getConfigValue('launchDarklyKey'), user);
  ldClient.on('ready', function () {
    const quantity = ldClient.variation(getConfigValue('launchDarklyPaymentRequestLimits'), 3000);
    dispatch({ type: MERCHANT_LIMIT, merchantLimit: quantity});
    const multilinkFlag = ldClient.variation(getConfigValue('launchDarklyMerchantMultilink'), false);
    dispatch({ type: MULTILINK_FLAG, multilinkFlag: multilinkFlag});
  });
};

export const resetForm = () => async (dispatch) => {
  dispatch(reset('PaymentRequestForm'));
};

export const updateAlias = (data) => (dispatch) => {
  dispatch({ type: UPDATE_ALIAS, payload: data})
};

export const getKycNoticeFlag = (user) => (dispatch) => {
  const LDClient = require('launchdarkly-js-client-sdk');
  const ldClient = LDClient.initialize(getConfigValue('launchDarklyKey'), user);
  ldClient.on('ready', function () {
    const kycNoticeFlag = ldClient.variation('kyc-notice-merchant-dashboard', 10000);
    dispatch({ type: KYC_NOTICE_FLAG, kycNoticeFlag: kycNoticeFlag}, false);
  });
};

export function getProfileInfo () {
  return async (dispatch) => {
    const response = await PaymentRequestService.getPublicProfileInfo();
    const data = response.items[0];
    let payload;
    if (data.alias != null){
      payload = {
        profile: {
          alias: data.alias,
          email: data.email,
          image: data.image,
          public_description: data.public_description,
          public_name: data.public_name,
          profile_config: {
            profile_active: data.profile_config.profile_active,
            show_address: data.profile_config.show_address,
            show_email: data.profile_config.show_email,
            show_phone: data.profile_config.show_phone,
          },
        },
        hasProfile: true,
      }
    }
    else{
      const {payload: statePayload} = usePublicProfile({});
      payload = statePayload;
    }
    dispatch({ type: PUBLIC_PROFILE_INFO, payload: payload})
  }
}

export function updatePublicProfileConfig(data) {
  return async (dispatch) => {
    await PaymentRequestService.updatePublicProfileData(data);
    let payload = data.profile_config.profile_active;
    dispatch({ type: ENABLE_PUBLIC_PROFILE, payload });
  }
}

export function updatePrivacyConfig(data, keyUpdated) {
  return async (dispatch) => {
      await PaymentRequestService.updatePublicProfileData(data);

      dispatch({ type: UPDATE_PRIVACY_INFO, payload: keyUpdated })
  }
}
