import {
  LOAD_COLLABORATOR_LIST,
  LOAD_COLLABORATOR,
  CLEAR_COLLABORATOR,
} from './types';

const initialState = {
  collaborators: {
    manager: [],
    cashier: [],
    finance: [],
  },
  collaborator: {},
};

const actionHandlers = new Map([
  [LOAD_COLLABORATOR_LIST, handleCollaboratorList],
  [LOAD_COLLABORATOR, handleSingleCollaborator],
  [CLEAR_COLLABORATOR, handleCollaboratorReset],
]);

export default function Collaborator(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleCollaboratorList(state, action) {
  const { data } = action.payload;

  return data ? { ...state, collaborators: data } : state;
}

function handleSingleCollaborator(state, action) {
  const { data } = action.payload;

  return data ? { ...state, collaborator: data } : state;
}

function handleCollaboratorReset(state) {
  return { ...state, collaborator: {} };
}
