import React, { useEffect, useState } from 'react';
import { ClipBreadcrumb } from '@clipmx/clip-storybook';
import useMediaQuery from '@clipmx/clip-storybook/dist/hooks/useMediaQuery';
import useTheme from '@clipmx/clip-storybook/dist/hooks/useTheme';
import { Link } from 'react-router-dom';
import ClipSnackbar from '@clipmx/clip-storybook/dist/components/Snackbar';
import ClipAlert from '@clipmx/clip-storybook/dist/components/Alert';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { NavigateNext as NavigateNextIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import PageTitle from './NewLayout/PageTitle';

const useStyles = makeStyles(() => {
  return {
    content: (props) => {
      const { textAlign } = props;
      return {
        textAlign,
        display: 'block',
        width: '100%',
      };
    },
    anchorOriginBottomCenter: {
      bottom: 60,
    },
  };
});

const TheContent = (props) => {
  const { content: Content, ...rest } = props;
  const myTheme = useTheme();
  const matches = useMediaQuery(myTheme.breakpoints.down('sm'));
  const newPosition = matches
    ? { vertical: 'bottom', horizontal: 'center' }
    : { vertical: 'top', horizontal: 'center' };
  const [state, setState] = useState({
    open: false,
    severity: 'success',
    message: '',
    textAlign: 'left',
    variant: '',
  });
  const [position, setPosition] = useState({
    vertical: 'top',
    horizontal: 'center',
  });
  const classes = useStyles({ ...props, ...state });
  const { open, severity, message, variant } = state;
  const { vertical, horizontal } = position;

  const openSnackBar = (
    severity,
    message,
    textAlign = 'left',
    variant = 'clip',
  ) => {
    setState({ ...state, open: true, severity, message, textAlign, variant });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    setPosition(newPosition);
  }, [matches]);

  return (
    <>
      {Content &&
        React.cloneElement(Content, {
          ...rest,
          closeSnackBar: handleClose,
          openSnackBar: openSnackBar,
        })}
      <ClipSnackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        autoHideDuration={5000}
        key={vertical + horizontal}
        classes={{ anchorOriginBottomCenter: classes.anchorOriginBottomCenter }}
      >
        <ClipAlert
          classes={{ message: classes.content }}
          elevation={6}
          variant={variant}
          onClose={handleClose}
          icon={false}
          severity={severity}
        >
          {message}
        </ClipAlert>
      </ClipSnackbar>
    </>
  );
};

const RouterWithLayoutNew = (props) => {
  return (
    <>
      {props.breadcrumbNameMap && (
        <div className="breadCrumb">
          <ClipBreadcrumb
            breadcrumbNameMap={props.breadcrumbNameMap}
            locationPathname={props.path}
            linkComponent={Link}
            separator={
              <NavigateNextIcon style={{ width: '1.75rem ', fontSize: '1.75rem'}} fontSize="small" />
            }
          />
        </div>
      )}
      {props.title && <PageTitle>{props.title}</PageTitle>}
      <TheContent {...props} content={props.children} />
    </>
  );
};

export default RouterWithLayoutNew;
