import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {
  LoansTabs,
  LoansStates,   
  CollaboratorRoles,
  LoansDocuments,
  PreapprovalStatus,
  LoansCurrentProductName,
  LoansNewProductName,
  LoanTypes,
} from '../../../constants/AppConstants';
import urls from '../../../helpers/urls';
import getUrl, { getProviderCashAdvanceURL } from '../../../helpers/getUrl';
import { getProviderCodeForURL } from '../../../helpers/getProviderUrl';
import { isEmpty } from '../../../helpers/validate';
import { toCurrencyFormat } from '../../../helpers/formatCurrency';
import {
  getLastOne,
  getUserName,
  getPreapprovals,
  getPreapprovalSelected,
  getLoansIdentityDocuments,
  getLoansIdentityDocumentsBack,
  getLoansPassportDocuments,
  getLoansProofAddressDocuments,
  isLoansFormSent,
  hasLastOne,
  getPrestaClipNameChange,
} from '../redux/selectors';
import {
  changePreapproval,
  saveMerchantData,
  savePreapprovalSelected,
} from '../redux/actions';
import LoansContent from '../Commons/LoansContent';
import ExternalForm from './Form';
import styles from './styles';
import {
  CenterContent,
  FormContainer,
  InitialAlert,
  InitialAlertContent,
  PrefilledAlert,
  PrefilledAlertContent,
  AssignmentTurnedIn,
  RequestAmount,
  ModalContent,
  ModalTitle,
} from './styled';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import {
  showCreditHistory,
  getProviderCode,
  loansProviderCodes,
} from '../../../constants/ProviderCodes';
import LoansStepperV4 from '../Commons/LoansStepperV4';
import LoansOffers from '../Commons/LoansOffers';
import text from './text';

const LoansForm = ({
  lastOne,
  loansFormURL,
  hasLastOne = {},
  username = '',
  preapprovals = [],
  preapprovalSelected,
  identityFiles,
  identityFilesBack,
  passportFiles,
  addressProofFiles = [],
  isFormSent,
  role,
  prestaClipNameChange,
  showCashAdvance,
  saveMerchantData,
}) => {
  const [step, changeStepState] = useState(1);
  const [stepsCompleted, changeStepsCompleted] = useState([]);
  const [open, changeOpen] = useState(false);

  let location = useLocation();
  const classes = styles();

  const history = useHistory();

  const getBaseLocation = () =>
    location.pathname.startsWith(urls.mobile) ? urls.mobile : '';

  const save = useCallback(
    (form) => {
      const lastPreapprovalId = Object.keys(lastOne).length > 0 ? lastOne.pre_approval_id : '';
      const providerCode = preapprovalSelected?.provider_code;
      if (
        ((step < 3 && (providerCode !== loansProviderCodes.MRP || providerCode !== loansProviderCodes.R2 || providerCode !== loansProviderCodes.AVL)) ||
          (step < 4 && (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL))) &&
        stepsCompleted.indexOf(step) < 0
      ) {
        changeStepState((step) => step + 1);
        changeStepsCompleted([...stepsCompleted, step]);
      } else if (
        (step < 3 && (providerCode !== loansProviderCodes.MRP || providerCode !== loansProviderCodes.R2 || providerCode !== loansProviderCodes.AVL)) ||
        (step < 4 && (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL))
      ) {
        changeStepState((step) => step + 1);
      } else {
        const {
          amount,
          pre_approval_id: preApprovalId,
          provider_pre_approval_id: providerPreApprovalId,
          proxy_merchant_token: proxyMerchantToken,
          provider_code: providerCode,
        } = preapprovalSelected;
        saveMerchantData(
          lastPreapprovalId,
          preApprovalId,
          providerPreApprovalId,
          proxyMerchantToken,
          providerCode,
          amount,
          form,
          [
            ...identityFiles.map(mapDocuments(LoansDocuments.ineDocumentType)),
            ...identityFilesBack.map(mapDocuments(LoansDocuments.ineDocumentType)),
            ...passportFiles.map(mapDocuments(LoansDocuments.passportDocumentType)),
          ],
          addressProofFiles.map(mapDocuments(LoansDocuments.addressDocumentType))
        );
      }
    },
    [
      lastOne,
      preapprovalSelected,
      step,
      addressProofFiles,
      identityFiles,
      identityFilesBack,
      passportFiles,
      stepsCompleted,
      saveMerchantData,
    ]);

  const mapDocuments = (type) => (file) => ({
    document_type: type,
    document_content_type: file.type,
    document_key: file.key,
  });

  const changeStep = useCallback((step) => {
    const providerCode = preapprovalSelected.provider_code;
    const providerCodeForURL = getProviderCodeForURL(providerCode);
    let urlLoans;

    switch (step) {
      case 1:
        urlLoans = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormIdentityV4CA,
          urls.loansFormIdentityV4,
        );
          break;
        case 2:
          if (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL) {
            urlLoans = getProviderCashAdvanceURL(
              showCashAdvance,
              providerCodeForURL,
              urls.loansFormHistoryV4CA,
              urls.loansFormHistoryV4
            );
          } else {
            urlLoans = getProviderCashAdvanceURL(
              showCashAdvance,
              providerCodeForURL,
              urls.loansFormAddressV4CA,
              urls.loansFormAddressV4
            );
          }
          break;
        case 3:
          if (providerCode === loansProviderCodes.MRP || providerCode === loansProviderCodes.R2 || providerCode === loansProviderCodes.AVL) {
            urlLoans = getProviderCashAdvanceURL(
              showCashAdvance,
              providerCodeForURL,
              urls.loansFormAddressV4CA,
              urls.loansFormAddressV4
            );
          } else {
            urlLoans = getProviderCashAdvanceURL(
              showCashAdvance,
              providerCodeForURL,
              urls.loansFormDepositV4CA,
              urls.loansFormDepositV4
            );
          }
          break;
        case 4:
        default:
        urlLoans = getProviderCashAdvanceURL(
          showCashAdvance,
          providerCodeForURL,
          urls.loansFormDepositV4CA,
          urls.loansFormDepositV4,
        );
        break;
    }
    changeStepState(step);
    history.replace(getUrl(`${getBaseLocation()}${urlLoans}`));
  },[preapprovalSelected, getBaseLocation, history, showCashAdvance]);

  const redirectWhenNotAuthorized = () => {
    if (
      getBaseLocation() !== urls.mobile &&
      role !== CollaboratorRoles.ADMIN
    ) {
      history.replace(getUrl(urls.dashboard));
    }
  };

  const redirectWhenAmountNotSelected = () => {
    const { amount, status } = preapprovalSelected ? preapprovalSelected : [];
    if (!isFormSent && (status !== LoansStates.AVAILABLE || isEmpty(amount))) {
      const urlLoans = location.pathname
        .replace('/offer/identity', '')
        .replace('/offer/history', '')
        .replace('/offer/address', '')
        .replace('/offer/deposit', '')
        .replace('mobile/', '');
      history.replace(`${getBaseLocation()}${urlLoans}`);
    }
  };

  const redirectWhenAcepted = () => {
    if (isFormSent) {
      const providerCode = preapprovals[0].provider_code;
      const providerCodeForURL = getProviderCodeForURL(providerCode);
      const type = preapprovals[0].type;
      const url = type === LoanTypes.CUSTOM ? urls.congratsPersonalized : urls.congrats;

      const urlCongrats = url.replace(':lender', providerCodeForURL);
      history.replace(getUrl(`${getBaseLocation()}${urlCongrats}`));
    }
  };

  useEffect(() => {
    redirectWhenNotAuthorized();
    redirectWhenAmountNotSelected();
    redirectWhenAcepted();
  })

  const handleOpen = useCallback(() => {
    changeOpen(true);
  },[changeOpen]);

  const handleClose = useCallback(() => {
    changeOpen(false);
  },[changeOpen]);



  const renderNewProductName = (prevText) => {
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  const handleInverseEventStepper = () => {
    changeStep(step - 1);
  };

  const providerCode = getProviderCode(
    location.pathname.toUpperCase(),
  );
  let hasProofAddressDocuments = addressProofFiles.length > 0;
  const withLoans = preapprovals.filter(
    (preapproval) => preapproval.loan_id !== null,
  );
  const availables = preapprovals.filter((preapproval) =>
    [PreapprovalStatus.AVAILABLE, PreapprovalStatus.ACCEPTED].includes(
      preapproval.status,
    ),
  );
  const currentAmount = preapprovalSelected
    ? preapprovalSelected.amount
    : null;
  const hasPreviousLoan = hasLastOne && lastOne.pre_approval_id;

  return (
        
    <LoansContent
      hasPreapprovals
      hasLoans={withLoans.length > 0}
      tab={LoansTabs.NEW_OFFER}
    >
        <LoansStepperV4
          step={step - 1}
          showCreditHistory={showCreditHistory(providerCode)}
          handleClick={handleInverseEventStepper}
          loansFormURL={loansFormURL}
        />
        <FormContainer>
          <ExternalForm
            onSubmit={save}
            step={step}
            changeStep={changeStep}
            lastOne={lastOne}
            username={username}
            hasProofAddressDocuments={hasProofAddressDocuments}
            async={true}
          />
        </FormContainer>
    </LoansContent>
  
  );
}

const mapStateToProps = (state) => {
  return {
    lastOne: getLastOne(state),
    hasLastOne: hasLastOne(state),
    username: getUserName(state),
    preapprovals: getPreapprovals(state),
    preapprovalSelected: getPreapprovalSelected(state),
    identityFiles: getLoansIdentityDocuments(state),
    identityFilesBack: getLoansIdentityDocumentsBack(state),
    passportFiles: getLoansPassportDocuments(state),
    addressProofFiles: getLoansProofAddressDocuments(state),
    isFormSent: isLoansFormSent(state),
    role: state.user.role,
    prestaClipNameChange: getPrestaClipNameChange(state),
    showCashAdvance: state.loans.showCashAdvance,
    loansFormURL: state.loans.loansFormURL,
  };
};

const mapDispatchToProps = {
  saveMerchantData,
  changePreapproval,
  savePreapprovalSelected,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )((withWidth()(LoansForm))),
);

LoansForm.propTypes = {
  saveMerchantData: PropTypes.func.isRequired,
  changePreapproval: PropTypes.func.isRequired,
  savePreapprovalSelected: PropTypes.func.isRequired,
  lastOne: PropTypes.object,
  preapprovalSelected: PropTypes.object,
  hasLastOne: PropTypes.bool.isRequired,
};
