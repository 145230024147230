import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClipButton from '@clipmx/clip-storybook/dist/components/Button';
import ClipMenu from '@clipmx/clip-storybook/dist/components/Menu';
import ClipMenuItem from '@clipmx/clip-storybook/dist/components/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClipTypography from '@clipmx/clip-storybook/dist/components/Typography';
import useTheme from '@clipmx/clip-storybook/dist/hooks/useTheme';
import useStyles from './ButonMenu.styled';
import { useHistory } from 'react-router-dom';
import getUrl from '../../../helpers/getUrl';
import urls from '../../../helpers/urls';

import text from './text';

const ButtonMenu = (props) => {
  let history = useHistory();
  const classes = useStyles(props);
  const theme = useTheme();
  const { merchant, user, goLogout, switched, getBackMainMerchant } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getBackMain = () => {
    getBackMainMerchant();
    history.push(getUrl(urls.dashboard));
  };

  return (
    <>
      <ClipButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClickListItem}
        classes={{ root: classes.buttonMenu }}
        size="small"
      >
        <ClipTypography
          variant={'body1'}
          classes={{
            root: classes.buttonMenuText,
          }}
        >
          {merchant.info.name}
        </ClipTypography>
        <ArrowDropDownIcon
          style={{ color: switched ? '#FFFFFF' : theme.palette.orange.base }}
          className={classes.arrowIcon}
        />
      </ClipButton>
      <ClipMenu
        id="customized-menu"
        classes={{
          paper: classes.menu,
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <li className={classes.liName}>
          <ClipTypography
            variant={'subtitle1'}
            classes={{
              root: classes.textName,
            }}
            fontWeight="bold"
          >
            {`${user.first_name} ${user.last_name}`}
          </ClipTypography>
        </li>
        <li className={classes.liEmail}>
          <ClipTypography
            classes={{
              root: classes.textEmail,
            }}
            variant={'body1'}
          >
            {user.email}
          </ClipTypography>
        </li>
        <li className={classes.divisor} />
        {switched && (
          <ClipMenuItem
            onClick={getBackMain}
            classes={{
              root: classes.logout,
            }}
          >
            {text.getBack}
          </ClipMenuItem>
        )}
        <ClipMenuItem
          onClick={goLogout}
          classes={{
            root: classes.logout,
          }}
        >
          {text.logout}
        </ClipMenuItem>
      </ClipMenu>
    </>
  );
};

ButtonMenu.propTypes = {
  merchant: PropTypes.object,
  user: PropTypes.object,
  goLogout: PropTypes.func,
  getBackMainMerchant: PropTypes.func,
  switched: PropTypes.bool,
};

export default ButtonMenu;
