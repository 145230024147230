import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import urls from '../../../../helpers/urls';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ClipTypography from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import ClipGrid from '@clipmx/clip-storybook/dist/components/Grid';
import { Close as CloseIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import LoanExample from '@clipmx/clip-storybook/dist/components/Illustrations/LoanExample/WebLoanExample/WebLoanExample';
import MovilLoanExample from '@clipmx/clip-storybook/dist/components/Illustrations/LoanExample/MovilLoanExample';
import PersonQuestion from '@clipmx/clip-storybook/dist/components/Illustrations/PersonQuestion';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';
import clsx from 'clsx';
import useStyles from './styles';
import text from './text';

const CashAdvance = (props) => {
  const { modalView, handleClose, } = props;
  const classes = useStyles();
  return (
    <Fragment>
       {!modalView && (
        <div>
          <ClipGrid item xs={12} sm={12} md={12} lg={12}
          className={classes.deskNone}>
            <ClipTypography
              variant="h3"
              className={classes.typographyRoot}
              fontWeight="regular"
              gutterBottom
            >
              {text.title}
            </ClipTypography>
            <ClipGrid item xs={12} sm={12} md={12} lg={12}>
              <div className={classes.meetContainer}>
                <ClipGrid item xs={1} sm={1} md={1} lg={1}  className={classes.imgenLeftDesk}>
                  <PersonQuestion
                    style={{
                      float: 'left',
                      height: '150px',
                      width: 'auto',
                    }}
                  />
                </ClipGrid>
                <ClipGrid item xs={11} sm={11} md={11} lg={11}>
                  <div className={classes.meetContainer}>
                    <ClipTypography
                      variant="h3"
                      className={classes.typographyBody1}
                      fontWeight="regular"
                      gutterBottom
                    >
                      {text.firstParagraph}&nbsp;
                      <b>{text.firstParagraphBold}</b>
                      <p>
                        {text.secondParagraph}&nbsp;
                        <b>{text.secondParagraphBold}&nbsp;</b>
                        {text.secondParagraphComplement}
                      </p>
                    </ClipTypography>
                  </div>
                </ClipGrid>
              </div>
            </ClipGrid>
          </ClipGrid>
          <ClipGrid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.meetgraph}>
              <LoanExample />
            </div>
          </ClipGrid>
        </div>
      )}
    {modalView && (
          <div>
          <ClipDrawer anchor={'bottom'} open={modalView} classes={{ paper: classes.clasModal}}  >
            <ClipGrid
              item
              xs={12}
              sm={12}  
              md={12}
              lg={12}
            >
              <IconButton className={classes.iconButtonCa}>
                <CloseIcon
                  onClick={() => handleClose()}
                />
              </IconButton>
            </ClipGrid>
            <div>
              <ClipGrid>
                <ClipGrid item xs={12} sm={12} md={12} lg={12}>
                  <ClipTypography
                    variant="h3"
                    className={classes.typographyRootMobil}
                    fontWeight="regular"
                    gutterBottom
                  >
                    {text.title}
                  </ClipTypography>
                  <ClipTypography
                    variant="h3"
                    className={classes.typographyBody1Mobil}
                    fontWeight="regular"
                    gutterBottom
                  >
                    {text.firstParagraph}&nbsp;
                    <b>{text.firstParagraphBold}</b>
                  </ClipTypography>
                  </ClipGrid>
               
                  <div className={classes.cardCentral}>
                      <ClipGrid item xs={2} sm={2} md={2} lg={2} className={classes.imgenLeft}>
                        <PersonQuestion 
                          style={{
                            float: 'left',
                            height: 'auto',
                            marginTop: 'auto',
                            width: '300px',
                          }}
                        />
                      </ClipGrid>
                      <ClipGrid item xs={10} sm={10} md={10} lg={10} className={classes.imgenLeft}>
                        <ClipTypography
                          variant="h3"
                          className={classes.typographyBody2Mobil}
                          fontWeight="regular"
                          align="left"
                          gutterBottom
                        >
                          <p>
                            {text.secondParagraph}&nbsp;
                            <b>{text.secondParagraphBold}&nbsp;</b>
                            {text.secondParagraphComplement}
                          </p>
                        </ClipTypography>
                      </ClipGrid>
                      </div>
                </ClipGrid>
           
            </div>
            <ClipGrid item xs={12} sm={12} md={12} lg={12}>
              <div className={classes.meetgraphMobile}>
                <MovilLoanExample />
              </div>
            </ClipGrid>
          </ClipDrawer>
          </div>
      )}
    </Fragment>
  );
};

export default CashAdvance;
