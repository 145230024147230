export default {
  delete: '¿Eliminar documento?',
  maxSize:
    'Tu documento superó el limite de peso, por favor sube uno menor a 100 Mb',
  maxFiles: 'Sólo 1 archivo está permitido',
  incorrectType: 'El formato de tus documentos debe ser pdf, jpg, jpeg o png',
  disconnected:
    'Se perdió la conexión a internet, por favor vuelve a intertarlo',
  tryAgain: 'Ups, algo pasó, por favor vuelve a subir tu documento',
  required: 'Debe subir este archivo antes de continuar',
  notFound: 'Ups, el archivo no fue eliminado vuelve a intentarlo',
  cancelFile: 'Carga de archivo cancelada',
  changeFile: 'Cambiar archivo',
};
