import {
  LOAD_TRANSACTIONS,
  LOAD_TRANSACTIONS_ERROR,
  GET_TRANSACTION_DETAIL,
  GET_TRANSACTION_DETAIL_ERROR,
  DOWNLOAD_TRANSACTION_REPORT,
  DOWNLOAD_TRANSACTION_REPORT_ERROR,
} from './types';

const initialState = {
  transactions: [],
  error: null,
  pagination: {
    total: 0,
    perPage: 0,
    currentPage: 1,
    totalPages: 0,
  },
  detail: {},
  report: null,
  loading: false,
};

const actionHandlers = new Map([
  [LOAD_TRANSACTIONS_ERROR, handleTransactionError],
  [GET_TRANSACTION_DETAIL_ERROR, handleTransactionError],
  [DOWNLOAD_TRANSACTION_REPORT_ERROR, handleTransactionError],

  [LOAD_TRANSACTIONS, handleLoadTransactions],
  [GET_TRANSACTION_DETAIL, handleGetTransaction],
  [DOWNLOAD_TRANSACTION_REPORT, handleReportDownload],
]);

export default function TransactionsReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleLoadTransactions(state, action) {
  const { transactions, pagination } = action.payload;

  return !action.payload.loading && transactions
    ? {
        ...state,
        transactions,
        pagination: {
          total: pagination.total,
          perPage: pagination.per_page,
          currentPage: pagination.current_page,
          totalPages: pagination.total_pages,
        },
      }
    : state;
}

function handleTransactionError(state, action) {
  const message = action.payload;

  return message ? { ...state, error: message } : state;
}

function handleGetTransaction(state, action) {
  const { detail } = action.payload;

  return detail ? { ...state, detail } : state;
}

function handleReportDownload(state, action) {
  const { report, loading } = action.payload;

  return { ...state, report: report ? report : null, loading };
}
