import React, { useState, useRef } from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lottie from 'lottie-react';
import 'react-notifications-component/dist/theme.css';
import imageCompression from 'browser-image-compression';

import { BerlinTheme } from '../../Theme';
import BerlinFileInput from '../../Commons/BerlinFileInput';
import OneStepNavigator from '../../Commons/OneStepNavigator';
import AcceptTerms from '../../Registration/AcceptTerms';

import { Forms } from '../../../../constants/AppConstants';
import IneFrontAnimation from '../../../../assets/images/berlin/lottie/IneFrontAnimation/data.json';
import IneBackAnimation from '../../../../assets/images/berlin/lottie/IneBackAnimation/data.json';
import PassportFrontAnimation from '../../../../assets/images/berlin/lottie/PassportFrontAnimation/data.json';

import Button from 'components/npm/clip-ui/Button';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import text from './text';
import styles from './styles';

export const IdentificationsPhoto = (props) => {
  const {
    classes,
    dispatch,
    ineFront,
    ineBack,
    passFront,
    handleSubmit,
    startAccountCreation,
    setActiveTab,
  } = props;
  const STEPS = {
    IDPHOTOSTART: 'IDPHOTOSTART',
    INEFRONT: 'INEFRONT',
    INEBACK: 'INEBACK',
    PASSPORT: 'PASSWORD',
    CONTINUE: 'CONTINUE',
    TERMS: 'TERMS',
    PRIVACY: 'PRIVACY',
    CONTRACT: 'CONTRACT',
  };
  const [step, setStep] = useState(STEPS.IDPHOTOSTART);
  const [disableCreateButton, setDisableCreateButton] = useState(true);

  const fieldRef = useRef();

  const clickInput = () => {
    fieldRef.current.click();
  };

  const clearIneFront = () => {
    dispatch(change(Forms.REGISTRATION_INFORMATION, 'ineFront', null));
  };

  const clearIneBack = () => {
    dispatch(change(Forms.REGISTRATION_INFORMATION, 'ineBack', null));
  };

  const clearPassFront = () => {
    dispatch(change(Forms.REGISTRATION_INFORMATION, 'passFront', null));
  };

  const selectIne = () => {
    clearPassFront();
    setStep(STEPS.INEFRONT);
  };
  const selectPassport = () => {
    clearIneFront();
    clearIneBack();
    setStep(STEPS.PASSPORT);
  };

  const setStepBack = (step) => {
    setStep(step);
  };

  const completeSubmit = async (values) => {
    const files = [];
    const options = {
      maxSizeMB: 2,
    };
    const frontFileName = 'front';
    const backFileName = 'back';
    const bytesInMb = 1048576;
    let dir = null;

    if (ineFront) {
      dir = 'INE';
      if (ineFront.size / bytesInMb > 2) {
        const compressedFront = await imageCompression(ineFront, options);
        files.push({ file: compressedFront, name: frontFileName });
      } else {
        files.push({ file: ineFront, name: frontFileName });
      }
      if (ineBack.size / bytesInMb > 2) {
        const compressedBack = await imageCompression(ineBack, options);
        files.push({ file: compressedBack, name: backFileName });
      } else {
        files.push({ file: ineBack, name: backFileName });
      }
    } else if (passFront) {
      dir = 'PAS';
      if (passFront.size / bytesInMb > 2) {
        const compressedFront = await imageCompression(passFront, options);
        files.push({ file: compressedFront, name: frontFileName });
      } else {
        files.push({ file: passFront, name: frontFileName });
      }
    }
    startAccountCreation(values, files, dir);
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.IDPHOTOSTART:
        return (
          <>
            <OneStepNavigator
              title={''}
              stepBackFunction={setActiveTab}
              stepBackTarget={2}
            />
            <Typography variant="title" className={classes.title}>
              {text.letsTake}
            </Typography>
            <Typography variant="headline" className={classes.headline}>
              {text.thisHelps}
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.whiteButton}
                onClick={() => selectIne()}
              >
                {text.ineButton}
              </Button>
              <Button
                className={classes.whiteButton}
                onClick={() => selectPassport()}
              >
                {text.passportButton}
              </Button>
            </div>
          </>
        );
      case STEPS.INEFRONT:
        return (
          <>
            <OneStepNavigator
              title={''}
              stepBackFunction={setStep}
              stepBackTarget={STEPS.IDPHOTOSTART}
            />
            {ineFront ? (
              <>
                <Typography variant="title" className={classes.title}>
                  {text.verifyIneFront}
                </Typography>
                <ul className={classes.listMargins}>
                  <li>
                    <Typography
                      variant="body1"
                      className={classes.noBottomMargin}
                    >
                      {text.info1}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="body1"
                      className={classes.noBottomMargin}
                    >
                      {text.info2}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="body1"
                      className={classes.noBottomMargin}
                    >
                      {text.info3}
                    </Typography>
                  </li>
                </ul>
                <img
                  className={classes.inePicture}
                  alt="Frente INE"
                  src={URL.createObjectURL(ineFront)}
                ></img>
                <Button
                  className={classes.halfRetryButton}
                  onClick={() => clearIneFront()}
                >
                  {text.retry}
                </Button>
                <Button
                  className={classes.halfContinueButton}
                  onClick={() => setStep(STEPS.INEBACK)}
                >
                  {text.ready}
                </Button>
              </>
            ) : (
              <>
                <Typography variant="title" className={classes.title}>
                  {text.takeFrontIne}
                </Typography>
                <Typography variant="headline" className={classes.helperText}>
                  {text.makeSure}
                </Typography>
                <Lottie
                  animationData={IneFrontAnimation}
                  className={classes.lottieAnimation}
                ></Lottie>
                <Button
                  className={classes.continueButton}
                  onClick={() => clickInput()}
                >
                  {text.understood}
                </Button>
                <Field
                  refName={fieldRef}
                  name="ineFront"
                  component={BerlinFileInput}
                ></Field>
              </>
            )}
          </>
        );
      case STEPS.INEBACK:
        return (
          <>
            <OneStepNavigator
              title={''}
              stepBackFunction={setStep}
              stepBackTarget={STEPS.INEFRONT}
            />
            {ineBack ? (
              <>
                <Typography variant="title" className={classes.title}>
                  {text.verifyIneBack}
                </Typography>
                <ul className={classes.listMargins}>
                  <li>
                    <Typography
                      variant="body1"
                      className={classes.noBottomMargin}
                    >
                      {text.info1_back}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="body1"
                      className={classes.noBottomMargin}
                    >
                      {text.info2}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="body1"
                      className={classes.noBottomMargin}
                    >
                      {text.info3}
                    </Typography>
                  </li>
                </ul>
                <img
                  className={classes.inePicture}
                  alt="Reverso INE"
                  src={URL.createObjectURL(ineBack)}
                ></img>
                <Button
                  className={classes.halfRetryButton}
                  onClick={() => clearIneBack()}
                >
                  {text.retry}
                </Button>
                <Button
                  className={classes.halfContinueButton}
                  onClick={() => setStep(STEPS.CONTINUE)}
                >
                  {text.ready}
                </Button>
              </>
            ) : (
              <>
                <Typography variant="title" className={classes.title}>
                  {text.takeBackIne}
                </Typography>
                <Typography variant="headline" className={classes.helperText}>
                  {text.makeSure}
                </Typography>
                <Lottie
                  animationData={IneBackAnimation}
                  className={classes.lottieAnimation}
                ></Lottie>
                <Button
                  className={classes.continueButton}
                  onClick={() => clickInput()}
                >
                  {text.understood}
                </Button>
                <Field
                  refName={fieldRef}
                  name="ineBack"
                  component={BerlinFileInput}
                ></Field>
              </>
            )}
          </>
        );
      case STEPS.PASSPORT:
        return (
          <>
            <OneStepNavigator
              title={''}
              stepBackFunction={setStep}
              stepBackTarget={STEPS.IDPHOTOSTART}
            />
            {passFront ? (
              <>
                <Typography variant="title" className={classes.title}>
                  {text.verifyPassportFront}
                </Typography>
                <ul className={classes.listMargins}>
                  <li>
                    <Typography
                      variant="body1"
                      className={classes.noBottomMargin}
                    >
                      {text.info1}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="body1"
                      className={classes.noBottomMargin}
                    >
                      {text.info2}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="body1"
                      className={classes.noBottomMargin}
                    >
                      {text.info3}
                    </Typography>
                  </li>
                </ul>
                <img
                  className={classes.inePicture}
                  alt="Frente pasaporte"
                  src={URL.createObjectURL(passFront)}
                ></img>
                <Button
                  className={classes.halfRetryButton}
                  onClick={() => clearPassFront()}
                >
                  {text.retry}
                </Button>
                <Button
                  className={classes.halfContinueButton}
                  onClick={() => setStep(STEPS.CONTINUE)}
                >
                  {text.ready}
                </Button>
              </>
            ) : (
              <>
                <Typography variant="title" className={classes.title}>
                  {text.takePassportFront}
                </Typography>
                <Typography variant="headline" className={classes.helperText}>
                  {text.makeSure}
                </Typography>
                <Lottie
                  animationData={PassportFrontAnimation}
                  className={classes.lottieAnimation}
                ></Lottie>
                <Button
                  className={classes.continueButton}
                  onClick={() => clickInput()}
                >
                  {text.understood}
                </Button>
                <Field
                  refName={fieldRef}
                  name="passFront"
                  component={BerlinFileInput}
                ></Field>
              </>
            )}
          </>
        );
      case STEPS.CONTINUE:
        return (
          <>
            <OneStepNavigator
              title={''}
              stepBackFunction={setStep}
              stepBackTarget={ineFront ? STEPS.INEBACK : STEPS.PASSPORT}
            />
            <AcceptTerms
              steps={STEPS}
              setStep={setStep}
              documentType={ineFront ? 'ine' : 'passport'}
              disableCreateButton={disableCreateButton}
              setDisableCreateButton={setDisableCreateButton}
            />
            <Button
              disabled={disableCreateButton}
              className={classes.createButton}
              type="submit"
            >
              {text.createAccount}
            </Button>
          </>
        );
      case STEPS.TERMS:
        return (
          <>
            <OneStepNavigator
              title={text.termsAndConditions}
              stepBackFunction={setStep}
              stepBackTarget={STEPS.CONTINUE}
            />
            <Typography variant="subheading" className={classes.termsTextTop}>
              {text.termsAndConditions1}
            </Typography>
          </>
        );
      case STEPS.PRIVACY:
        return (
          <>
            <OneStepNavigator
              title={text.privacyPolicy}
              stepBackFunction={setStep}
              stepBackTarget={STEPS.CONTINUE}
            />
            <Typography variant="subheading" className={classes.termsTextTop}>
              {text.privacyPolicy1}
            </Typography>
          </>
        );
      case STEPS.CONTRACT:
        return (
          <>
            <OneStepNavigator
              title={text.contract}
              stepBackFunction={setStep}
              stepBackTarget={STEPS.CONTINUE}
            />
            <div className={classes.contractPictures}>
              {Array.from({ length: 18 }, (_, i) => i).map((i) => {
                return (
                  <img
                    src={`/images/berlin/cacaoContract/cacao-contract-${
                      i + 1
                    }.jpg`}
                    alt={'Cacao Contract'}
                  />
                );
              })}
            </div>
          </>
        );
      default:
        return;
    }
  };

  return (
    <MuiThemeProvider theme={BerlinTheme}>
      <div className={classes.root}>
        <form onSubmit={handleSubmit(completeSubmit)} className={classes.form}>
          {renderStep()}
        </form>
      </div>
    </MuiThemeProvider>
  );
};

const selector = formValueSelector(Forms.REGISTRATION_INFORMATION);

const mapStateToProps = (state) => {
  return {
    ineFront: selector(state, 'ineFront'),
    ineBack: selector(state, 'ineBack'),
    passFront: selector(state, 'passFront'),
  };
};

IdentificationsPhoto.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  startAccountCreation: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};
IdentificationsPhoto.defaultProps = {
  classes: {},
  dispatch: () => {},
  handleSubmit: () => {},
  startAccountCreation: () => {},
  setActiveTab: () => {},
};

const ReduxIdentificationsPhoto = reduxForm(
  {
    form: Forms.REGISTRATION_INFORMATION,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
  },
  mapStateToProps,
)(IdentificationsPhoto);

export default connect(mapStateToProps)(
  withStyles(styles)(ReduxIdentificationsPhoto),
);
