import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {
  getPreapprovalSelected,
  preapprovalsLoaded,
  isLoansFormSent,
} from '../../redux/selectors';
import FormButton from '../../../../helpers/FormUtils/FormButton';
import urls from '../../../../helpers/urls';
import { toCurrencyFormat } from '../../../../helpers/formatCurrency';
import { LoansTabs } from '../../../../constants/AppConstants';
import GoogleAnalytics from '../../GoogleAnalytics';
import { ExternalLenderFooter } from '../../Commons/Footer';
import LoansRedirection from '../../Commons/Redirection';
import { saveOpenDataOffer } from '../../redux/actions';
import OpenDataPayment from '../Payment';
import { BlueCheckbox } from '../styled';
import { styles } from './styles';
import text from './text';

export class OpenDataConfirmation extends Component {
  state = {
    accepted: false,
    isMobile:
      this.props.location.pathname.startsWith(urls.mobile) ||
      window.innerWidth < 600,
  };

  switchAccepted = () => this.setState({ accepted: !this.state.accepted });

  getBaseLocation = () =>
    this.props.location.pathname.startsWith(urls.mobile)
      ? urls.mobile
      : '';

  confirm = () => {
    const {
      preapprovalSelected: {
        pre_approval_id,
        provider_pre_approval_id,
        proxy_merchant_token,
      },
      saveOpenDataOffer,
    } = this.props;
    saveOpenDataOffer(
      pre_approval_id,
      provider_pre_approval_id,
      proxy_merchant_token,
    );
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile:
          this.props.location.pathname.startsWith(urls.mobile) || window.innerWidth < 600,
      });
    });
  }

  render() {
    const {
      classes,
      isFormSent,
      preapprovalSelected = {},
      preapprovalsLoaded,
    } = this.props;
    const { amount, payback, retention_rate, term_maximum } =
      preapprovalSelected;
    const { accepted, isMobile } = this.state;

    const typographyStyles = {
      root: classes.typographyRoot,
      title: classes.typographyTitle,
      subheading: classes.typographySubheading,
      caption: classes.typographyCaption,
      body1: classes.typographyBody1,
      display1: classes.typographyDisplay1,
    };

    return (
      <LoansRedirection tab={LoansTabs.NEW_OFFER}>
        {preapprovalSelected && !isFormSent && preapprovalsLoaded && (
          <GoogleAnalytics>
            <div className={classes.mainContent}>
              <Typography
                paragraph
                variant={'title'}
                classes={typographyStyles}
              >
                {text.title}
              </Typography>
              <Card className={classes.confirmationCard}>
                <CardContent>
                  <div className={classes.offerContent}>
                    <Typography
                      variant={'subheading'}
                      classes={typographyStyles}
                    >
                      {text.subtitle} <span>{toCurrencyFormat(amount)}</span>
                      <div className={classes.newOfferContainer}>
                        {text.newOffer}
                      </div>
                    </Typography>
                    <Typography
                      variant={'subheading'}
                      classes={typographyStyles}
                      className="amount"
                    >
                      {toCurrencyFormat(amount)}
                    </Typography>
                  </div>

                  <div className={classes.singleContent}>
                    <Typography variant={'caption'} classes={typographyStyles}>
                      {text.termTitle}
                    </Typography>
                    <Typography variant={'body1'} classes={typographyStyles}>
                      {text.termText(parseInt(term_maximum))}
                    </Typography>
                  </div>

                  <div className={classes.singleContent}>
                    <Typography variant={'caption'} classes={typographyStyles}>
                      {text.paymentTitle}
                    </Typography>
                    <Typography variant={'body1'} classes={typographyStyles}>
                      {text.paymentText(parseFloat(retention_rate))}
                    </Typography>
                  </div>

                  <div className={classes.dualContent}>
                    <div className={classes.content}>
                      <Typography
                        variant={'caption'}
                        classes={typographyStyles}
                      >
                        {text.commissionTitle}
                      </Typography>
                      <Typography variant={'body1'} classes={typographyStyles}>
                        {text.commissionText(
                          toCurrencyFormat(
                            parseFloat(payback) - parseFloat(amount),
                          ),
                        )}
                      </Typography>
                    </div>
                    <div className={classes.content}>
                      <Typography
                        variant={'caption'}
                        classes={typographyStyles}
                      >
                        {text.totalTitle}
                      </Typography>
                      <Typography variant={'body1'} classes={typographyStyles}>
                        {toCurrencyFormat(payback)}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.divider} />
                  <div className={classes.termsContainer}>
                    <BlueCheckbox
                      value="acceptedTerms"
                      onChange={(event) => this.switchAccepted()}
                    />
                    <Typography variant={'display1'} classes={typographyStyles}>
                      {text.termsAndConditions}
                    </Typography>
                  </div>
                  <div className={classes.buttonContainer}>
                    <FormButton
                      variant="contained"
                      text={text.confirmButton}
                      disabled={!accepted}
                      onClick={this.confirm}
                    />
                  </div>
                </CardContent>
              </Card>
              <OpenDataPayment
                className={clsx({ desktop: !isMobile })}
                collapsable={isMobile}
              />
              <ExternalLenderFooter />
            </div>
          </GoogleAnalytics>
        )}
      </LoansRedirection>
    );
  }
}

OpenDataConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  preapprovalSelected: PropTypes.object,
};
OpenDataConfirmation.defaultProps = {
  preapprovalSelected: {
    amount: '0',
    payback: '0',
    retention_rate: '0',
    term_maximum: '0',
  },
};

const mapStateToProps = (state) => {
  return {
    preapprovalSelected: getPreapprovalSelected(state),
    preapprovalsLoaded: preapprovalsLoaded(state),
    isFormSent: isLoansFormSent(state),
  };
};

export default withRouter(
  connect(mapStateToProps, { saveOpenDataOffer })(
    withStyles(styles)(OpenDataConfirmation)
  )
);
