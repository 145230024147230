import axios from 'axios';

import config from '../../../config';
import { getRequestOptions } from '../../../helpers/fetchUtil';
import apiPaths from '../../../helpers/apiPaths';

export default class CatalogService {
  static createItem({
    color,
    name,
    price,
    cost,
    sku,
    stock,
    image,
    categoryId,
    description,
    hasVariants,
    skus,
  }) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.catalogItems, config('apiUrl'));
    const body = {
      name,
      price,
      color,
      stock,
      image,
      category_id: categoryId,
      description,
      has_variants: hasVariants,
      skus,
    };

    sku && (body.sku = sku);
    cost && (body.cost = cost);

    return axios.post(url, body, options).then((res) => res.data);
  }

  static getPublishInfo() {
    const options = getRequestOptions();
    const url = new URL(apiPaths.publishInfo, config('apiUrlv2'));

    return axios.get(url, options).then((res) => res.data);
  }

  static setPublishInfo(data) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.publishInfo, config('apiUrlv2'));
    return axios.patch(url, data, options).then((res) => res.data);
  }

  static createManualSku({ name, price }) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.catalogSkus, config('apiUrl'));
    const body = { price, name };

    return axios.post(url, body, options).then((res) => res.data);
  }

  static deleteItem(id) {
    const options = getRequestOptions();
    const url = new URL(`${apiPaths.catalogItems}/${id}`, config('apiUrl'));

    return axios.delete(url, options).then((res) => res.data);
  }

  static updateSku({
    skuId,
    name,
    price,
    cost,
    sku,
    stock,
    image,
    description,
  }) {
    const options = getRequestOptions();
    const url = new URL(`${apiPaths.catalogSkus}/${skuId}`, config('apiUrl'));
    const body = { name, price, stock, image, description };

    sku && (body.sku = sku);
    cost && (body.cost = cost);

    return axios.put(url, body, options).then((res) => res.data);
  }

  static createSku({ name, price, sku, stock, image, itemId }) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.catalogSkus, config('apiUrl'));
    const body = { name, price, stock, image, itemId };

    sku && (body.sku = sku);

    return axios.post(url, body, options).then((res) => res.data);
  }

  static deleteSku(skus) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.catalogSkus, config('apiUrl'));

    return axios
      .delete(url, { ...options, data: { skus } })
      .then((res) => res.data);
  }

  static getItems({ limit, offset, includeSkus, updatedAt }) {
    const options = getRequestOptions();
    const url = new URL(
      `${
        apiPaths.catalogItems
      }?limit=${limit}&offset=${offset}&include_skus=${includeSkus}${
        updatedAt ? `&updated_at=${updatedAt}` : ''
      }`,
      config('apiUrl'),
    );

    return axios.get(url, options).then((res) => res.data);
  }

  static getItem({ itemId, includeSkus, updatedAt }) {
    const options = getRequestOptions();
    const url = new URL(
      `${apiPaths.catalogItems}/${itemId}?include_skus=${includeSkus}${
        updatedAt ? `&updated_at=${updatedAt}` : ''
      }`,
      config('apiUrl'),
    );

    return axios.get(url, options).then((res) => res.data);
  }

  static updateItem({ itemId, color, categoryId, hasVariants, skus }) {
    const options = getRequestOptions();
    const url = new URL(`${apiPaths.catalogItems}/${itemId}`, config('apiUrl'));
    const body = {
      color,
      category_id: categoryId,
      has_variants: hasVariants,
      skus,
    };

    return axios.put(url, body, options).then((res) => res.data);
  }

  static getCategories() {
    const options = getRequestOptions();
    const url = new URL(apiPaths.catalogCategories, config('apiUrl'));

    return axios.get(url, options).then((res) => res.data);
  }

  static createCategories(name, type, isActive, color) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.catalogCategories, config('apiUrl'));
    const body = { name, type, is_active: isActive, color };
    return axios.post(url, body, options).then((res) => res.data);
  }

  static confirmPilotInvitation({ name, email, phone }) {
    const options = getRequestOptions();
    const url = new URL(
      apiPaths.confirmCatalogPilotInvitation,
      config('apiUrl'),
    );
    const body = { name, email, phone };
    return axios.post(url, body, options).then((res) => res.data);
  }

  static uploadBulkTemplate(file, ext) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.bulkUpload, config('apiUrlv2'));
    const body = { file, ext };
    return axios.post(url, body, options).then((res) => res.data);
  }

  static getBulkState() {
    const options = getRequestOptions();
    const url = new URL(apiPaths.bulkState, config('apiUrlv2'));
    return axios
      .get(url, options)
      .then((res) => JSON.parse(JSON.stringify(res.data)));
  }

  static updateBulkState(bulkStatusId, notifiedToMerchant) {
    const options = getRequestOptions();
    const url = new URL(
      `${apiPaths.bulkState}/${bulkStatusId}`,
      config('apiUrlv2'),
    );
    const body = { notified_to_merchant: notifiedToMerchant };
    return axios.put(url, body, options).then((res) => res.data);
  }

  static deleteBulkProcess(bulkIds, newStatus, statuses) {
    const options = getRequestOptions();
    const url = new URL(apiPaths.catalogBulk, config('apiUrlv2'));
    const body = {
      bulk_ids: bulkIds,
      new_status: newStatus,
    };

    statuses && (body.statuses = statuses);

    return axios
      .delete(url, { ...options, data: { ...body } })
      .then((res) => res.data);
  }
}
