import moment from 'moment-timezone';
import text from './dateText';
import logger from 'helpers/logger';

export function removeExtraWhiteSpaces(str) {
  return str.replace(/\s+/g, ' ').trim();
}
export function allowAlphaNumerical(maxLength, str) {
  const notLettersNumbersWhitespaceRegex = /[^a-zA-Z0-9\u00C0-\u017F\s]+/g;
  str = replaceAccents(str);
  return str
    .replaceAll(notLettersNumbersWhitespaceRegex, '')
    .substr(0, maxLength);
}

export function allowAlphabetical(maxLength, str) {
  str = replaceAccents(str);
  const notLettersWhitespaceRegex = /[^a-zA-Z\s]+/g;
  return str.replaceAll(notLettersWhitespaceRegex, '').substr(0, maxLength);
}

export function allowNumerical(maxLength, str) {
  const notNumbersRegex = /[^0-9]+/g;
  return str.replaceAll(notNumbersRegex, '').substr(0, maxLength);
}

export function allowAlphaNumericalNoAccents(maxLength, str) {
  str = replaceAccents(str);
  const notLettersNumbersWhitespaceRegex = /[^a-zA-Z0-9\s]+/g;
  return str
    .replaceAll(notLettersNumbersWhitespaceRegex, '')
    .substr(0, maxLength);
}

export function replaceAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function checkSpecialCharacters(str) {
  var regex = /^[0-9a-zA-Z\_]+$/;
  return regex.test(str);
}

//Transactions utils
export const formatDate = (date) => {
  try {
    const parsedDate = moment(date);
    const today = moment();
    const yesterday = moment().add(-1, 'days');
    if (
      parsedDate.date() === today.date() &&
      parsedDate.month() === today.month()
    ) {
      return text.today;
    }
    if (
      parsedDate.date() === yesterday.date() &&
      parsedDate.month() === yesterday.month()
    ) {
      return text.yesterday;
    }
    return `${parsedDate.date()} ${text.months[parsedDate.month()]}`;
  } catch (e) {
    logger.info(e);
  }
};

export const groupByDate = (movements) => {
  let group = movements.reduce((objectsByKeyValue, obj) => {
    //old endpoint
    //const value = this.formatDate(obj['date']);
    const value = formatDate(obj['movement_date']);
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});
  return group;
};

export const getHour = (date) => {
  const parsedDate = moment(date);
  return parsedDate.format('h:mm a');
};

export const amountFormat = (
  amount,
  type,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = type === 'OUT' ? '-' : '+';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      '$' +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    logger.info(e);
  }
};

export const getAmountColor = (type) => {
  return type === 'IN';
};

export const balanceFormat = (
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      '$' +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    logger.info(e);
  }
};

export const formatDateDetail = (date) => {
  try {
    const parsedDate = moment(date);
    let dateString = '';

    dateString = `${parsedDate.date()} ${
      text.months[parsedDate.month()]
    } ${parsedDate.year()}`;

    return dateString;
  } catch (e) {
    logger.info(e);
  }
};

export const minLength = (length, errorMsg) => (value) => {
  if (value.length < length) {
    return errorMsg;
  }
};

export const checkValidPinConsecutiveNumbers = (errorMsg) => (pin) => {
  const pinSt = pin.toString();
  let a;
  let b;
  let c;
  for (let i = 0; i < pinSt.length - 1; i++) {
    a = pinSt.charAt(i);
    b = pinSt.charAt(i + 1);
    c = pinSt.charAt(i + 2);
    if (parseInt(a) + 1 === parseInt(b) && parseInt(b) + 1 === parseInt(c)) {
      return errorMsg;
    }
  }
  return undefined;
};

export const checkValidPinRepeatedNumbers = (errorMsg) => (pin) => {
  const pinSt = pin.toString();
  let regex;
  for (let i = 0; i < pinSt.length; i++) {
    regex = new RegExp(pinSt.charAt(i), 'g');
    if ((pinSt.match(regex) || []).length > 2) {
      return errorMsg;
    }
  }
  return undefined;
};

export const pad = (num, size) => {
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return num;
};

export const getTimeText = (time) => {
  let minutes = Math.floor(time / 60);
  let seconds = time - minutes * 60;

  return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
};
