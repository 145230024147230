export default {
  tab: 'Credi Clip',
  title: 'Tu solicitud de Credi Clip está en revisión',
  message: (name) => `
    Hola ${name}, estamos revisando tu documentación. En un plazo no mayor a 24 horas hábiles,
    te informaremos  si cumples los requisitos para disfrutar Credi Clip.
  `,
  button: 'Panel de Control',
  titleCongrats: 'Firmaste tu contrato',
  banner: 'Firmado',
  paragraph1: 'Estamos verificando tus documentos.',
  paragraph2:
    'Si todo está en orden, recibirás un correo electrónico de confirmación y ',
  paragraph3: 'depositaremos tu dinero en 24 horas hábiles.',
  understand: 'Entendido',
};
