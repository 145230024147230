import urls from 'helpers/urls';
import { loansClipPhone as ClipPhone } from 'constants/AppConstants';

export default {
  contactMessageNumber: '55 6393-2323',
  contactWeb: 'www.clip.mx',
  contactExternalMessageAdvice:
    '¿Necesitas ayuda? Customer Happiness® te atiende',
  timeMessage: ' 24/7, todo el año.',
  contactExternalMessagePart2: 'Escríbenos por WhatsApp o llámanos al ',
  contactExternalMessagePart3: 'Envía un correo a ',
  contactExternalMessagePart4: ' o solicita apoyo en el chat de clip.mx.',
  contactExternalMessageMobile: `
  Escríbenos por WhatsApp  o llámanos al ${ClipPhone}.
  Envía un correo a ${urls.clipHelpEmail} o solicita apoyo en el chat de clip.mx.`,

  modalTitle: '¿Necesitas ayuda?',
  modalSubtitle: 'Customer Happiness® te atiende 24/7, todo el año',
  firstButtonText: 'Ayuda Vía Whatsapp',
  secondButtonText: 'Llamar ahora',


  helpNeed: '¿Necesitas ayuda?',
  contacCustomer: 'Customer Happiness® te atiende 24/7, todo el año',
  contactExternalWhatsapp: 'Escríbenos en ',
  contactWhatsApp: 'Ayuda vía Whatsapp',
  contactPhone: 'Llamar ahora',
  contactExternalMobile: 'Llámanos al teléfono ',
  contactMobile: `${ClipPhone}`,
  contactEmail: ' Envía un correo a ',
  contactUrlEmail: `${urls.clipHelpEmail}`,
  contactChat: ' o escríbenos en el chat de',
  contactClipMx: 'clip.mx.',

};  
