import React from 'react';
import text from './text';
import PropTypes from 'prop-types';
import CurpImg from '../../../../../assets/images/berlin/CURP-img.svg';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';

import styles from './styles';
const useStyles = makeStyles(styles);

const CurpToolip = (props) => {
  const classes = useStyles(props);
  const { toggleTooltip } = props;
  return (
    <div className={classes.root}>
      <ClipTypography variant="h3" className={classes.tooltipTitle}>
        {text.title}
      </ClipTypography>
      <img className={classes.img} src={CurpImg} alt="" />
      <ClipTypography className={classes.noMargin}>
        {text.message1}
      </ClipTypography>
      <ClipButton
        variant="contained"
        color="primary"
        onClick={() => toggleTooltip(false)}
        className={classes.buttonMargin}
      >
        {text.buttonLabel}
      </ClipButton>
    </div>
  );
};
CurpToolip.propTypes = {
  toggleTooltip: PropTypes.func,
};
CurpToolip.defaultProps = {
  toggleTooltip: () => {},
};
export default CurpToolip;
