const width100 = '100%';
const flex = 'flex';

export const styles = (theme) => {
  const componentsStyles = {
    gridCard: {
      marginBottom: `${theme.spacing.unit * 3}px`,
      width: '606px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: `${theme.spacing.unit * 2.75}px`,
        width: width100,
      },
    },
    bannerContainer: {
      width: width100,
      display: flex,
      marginLeft: `${theme.spacing.unit * 13}px`,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        justifyContent: 'center',
      },
    },
    bannerTop: {
      width: '185px',
      height: `${theme.spacing.unit * 5}px`,
      marginBottom: `${theme.spacing.unit * 2.75}px`,
      [theme.breakpoints.down('xs')]: {
        width: width100,
        marginTop: `${-theme.spacing.unit}px`,
      },
    },
    bannerImage: {
      marginBottom: `${theme.spacing.unit * 2.75}px`,
      width: `${theme.spacing.unit * 22.125}px`,
      height: `${theme.spacing.unit * 24.75}px`,
      [theme.breakpoints.down('xs')]: {
        width: `${theme.spacing.unit * 12.94}px`,
        height: `${theme.spacing.unit * 14.46}px`,
        marginBottom: `${theme.spacing.unit * 3.125}px`,
      },
    },
    title: {
      fontSize: `${theme.spacing.unit * 3}px`,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: `${theme.spacing.unit * 2.75}px`,
      textAlign: 'left',
      marginBottom: `${theme.spacing.unit * 2}px`,
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        justifyContent: 'center',
      },
    },
    subtitle: {
      fontSize: `${theme.spacing.unit * 2.25}px`,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: `${theme.spacing.unit * 2.6875}px`,
      marginBottom: `${theme.spacing.unit * 3}px`,
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        justifyContent: 'center',
      },
    },
    text: {
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: theme.typography.fontWeightLight,
      lineHeight: `${theme.spacing.unit * 2.4}px`,
      marginBottom: `${theme.spacing.unit * 5}px`,
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'justify',
        justifyContent: 'center',
      },
    },
    points: {
      marginBottom: `${theme.spacing.unit * 3}px`,
      flexDirection: 'column',
      display: flex,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
    point: {
      marginBottom: `${theme.spacing.unit * 3}px`,
      display: flex,
      '& > span.icon': {
        marginRight: `${theme.spacing.unit * 1.5}px`,
        backgroundColor: '#FEEDE5',
        width: `${theme.spacing.unit * 5}px`,
        height: `${theme.spacing.unit * 5}px`,
        borderRadius: `${theme.spacing.unit * 3}px`,
        display: flex,
        '& > img': {
          margin: 'auto',
          width: `${theme.spacing.unit * 3}px`,
          height: `${theme.spacing.unit * 3}px`,
        },
      },
      '& > span:last-child': {
        marginTop: `${theme.spacing.unit * 1.25}px`,
        fontSize: `${theme.spacing.unit * 2}px`,
        fontWeight: theme.typography.fontWeightLight,
        lineHeight: `${theme.spacing.unit * 2.4}px`,
      },
    },
  };
  return {
    ...componentsStyles,
  };
};
