const styles = (theme) => ({
  container: {
    margin: '1rem',
    marginTop: '20px',
  },
  textContainer: {
    height: 150,
  },
  btnContainer: {
    alignItems: 'center',
    marginTop: '10rem',
  },
  btn: {
    width: '100%',
    marginBottom: 16,
  },
  title: {
    marginTop: 20,
  },
});

export default styles;
