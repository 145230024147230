import styled from '@emotion/styled';
import { ProcessButtonv2 as CommonSubmitButton } from 'components/common/Buttons';

const inlineBlock = 'inline-block';

export const Container = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 90}px`,
  display: inlineBlock,
  margin: `-${theme.spacing.unit}px -${theme.spacing.unit * 2}px 0`,
  [theme.breakpoints.down('xs')]: {
    margin: 0,
    width: '100%',
  },
}));

export const Title = styled.div(({ theme }) => ({
  width: '100%',
  display: inlineBlock,
  color: theme.palette.naranja.slate,
  fontSize: `${theme.spacing.unit * 3.75}px`,
  fontWeight: theme.typography.fontWeightMedium,
  [theme.breakpoints.down('xs')]: {
    textAlign: 'center',
  },
}));

export const ImageContainer = styled.div(({ theme }) => ({
  position: 'relative',
  width: `${theme.spacing.unit * 26.75}px`,
  height: `${theme.spacing.unit * 25}px`,
  float: 'left',
  zIndex: 2,
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));

export const ImageTitle = styled.img(({ theme }) => ({
  display: 'inline-block',
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));
export const ImageTitleMobile = styled.img(({ theme }) => ({
  marginTop: `${theme.spacing.unit}px`,
  display: 'none',
  [theme.breakpoints.down('xs')]: {
    display: 'inline-block',
  },
}));

export const TextContainer = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 60.25}px`,
  marginLeft: `${theme.spacing.unit * 3}px`,
  float: 'right',
  fontWeight: theme.typography.fontWeightLight,
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    marginLeft: '0',
    textAlign: 'center',
  },
}));

export const BannerTitle = styled.img(({ theme }) => ({
  width: `${theme.spacing.unit * 22}px`,
  height: `${theme.spacing.unit * 5}px`,
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    marginTop: `${-theme.spacing.unit}px`,
  },
}));

export const TextTitle = styled.p(({ theme }) => ({
  marginTop: `${theme.spacing.unit * 2}px`,
  width: `${theme.spacing.unit * 57.5}px`,
  fontSize: `${theme.spacing.unit * 2.5}px`,
  fontWeight: 500,
  fontStretch: 'normal',
  lineHeight: 1.33,
  letterSpacing: 'normal',
  color: '#001724',
  [theme.breakpoints.down('xs')]: {
    marginBottom: '0',
    display: 'inline-block',
    width: '100%',
  },
}));

export const TextSubtitle = styled.p(({ theme }) => ({
  width: `${theme.spacing.unit * 57.5}px`,
  fontSize: `${theme.spacing.unit * 2.25}px`,
  fontWeight: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#001724',
  [theme.breakpoints.down('xs')]: {
    display: 'inline-block',
    width: '100%',
    fontSize: `${theme.spacing.unit * 2}px`,
  },
}));

export const ExpireWarning = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 86.75 + 1}px`,
  maxWidth: '100%',
  height: `${theme.spacing.unit * 5}px`,
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
  borderRadius: `${theme.spacing.unit * 0.5}px`,
  border: 'solid 1px rgba(0, 125, 142, 0.08)',
  borderLeft: 'solid 4px #007d8e',
  backgroundColor: 'rgba(0, 125, 142, 0.06)',
  [theme.breakpoints.down('xs')]: {
    marginTop: 0,
    width: `calc(100% - ${theme.spacing.unit * 0.5}px)`,
    height: `${theme.spacing.unit * 6.25}px`,
  },
}));

export const ExpireWarningContent = styled.div(({ theme }) => ({
  display: 'inline-block',
  fontSize: `${theme.spacing.unit * 1.75}px`,
  color: '#007d8e',
  '& > p': {
    marginTop: `${theme.spacing.unit * 1.5}px`,
    marginBottom: `${theme.spacing.unit * 1.5}px`,
  },
  [theme.breakpoints.down('xs')]: {
    width: '80%',
    '& > p': {
      marginTop: `${theme.spacing.unit}px`,
      marginBottom: `${theme.spacing.unit}px`,
    },
  },
}));

export const HourglassTop = styled.img(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  marginRight: `${theme.spacing.unit * 0.75}px`,
  verticalAlign: 'middle',
  top: `-${theme.spacing.unit * 0.25}px`,
  marginTop: `${theme.spacing.unit}px`,
  marginBottom: `${theme.spacing.unit}px`,
  marginLeft: `${theme.spacing.unit}px`,
  [theme.breakpoints.down('xs')]: {
    top: '-20%',
    marginTop: 0,
    marginBottom: 0,
  },
}));

export const Content = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 87.5}px`,
  position: 'relative',
  float: 'left',
  display: inlineBlock,
  marginTop: `${theme.spacing.unit * 1.5}px`,
  [theme.breakpoints.down('xs')]: {
    marginTop: `${theme.spacing.unit}px`,
    width: '100%',
  },
}));

export const Instructions = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 83.5}px`,
  position: 'relative',
  display: inlineBlock,
  paddingLeft: `${theme.spacing.unit * 2}px`,
  paddingRight: `${theme.spacing.unit * 2}px`,
  color: theme.palette.naranja.slate,
  fontSize: `${theme.spacing.unit * 2.25}px`,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: `${theme.spacing.unit * 3.75}px`,
  textAlign: 'center',
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    paddingLeft: '0',
    textAlign: 'center',
  },
}));

export const AmountsContainer = styled.div(({ theme }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  '&:hover > div': {
    transform: 'scale(0.9)',
    transition: `transform ${theme.transitions.duration.shortest}ms`,
  },
  '&:hover > div:hover': {
    transform: 'scale(1.1)',
    transition: `transform ${theme.transitions.duration.shortest}ms`,
  },
  '& > div:not(:first-child)': {
    marginLeft: `${theme.spacing.unit * 2.5}px`,
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: '0',
    display: 'inline-grid',
    '& > div:not(:first-child)': {
      marginLeft: 0,
    },
  },
}));

export const Amount = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 27.5}px`,
  boxSizing: 'border-box',
  borderRadius: `${theme.spacing.unit * 0.25}px`,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
  opacity: 1,
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    marginBottom: `${theme.spacing.unit * 2}px`,
  },
}));

export const AmountHeader = styled.div(({ theme }) => ({
  width: '100%',
  height: `${theme.spacing.unit * 14}px`,
  borderRadius: `${theme.spacing.unit * 0.25}px`,
  backgroundColor: 'rgba(252, 76, 2, 0.07)',
  [theme.breakpoints.down('xs')]: {
    height: '68px',
  },
}));

export const AmountHeaderContainer = styled.div(({ theme }) => ({
  height: `${theme.spacing.unit * 4.5}px`,
  lineHeight: 'normal',
  paddingTop: `${theme.spacing.unit * 1.25}px`,
  textAlign: 'center',
  fontWeight: '600',
  color: '#001724',
  [theme.breakpoints.down('xs')]: {
    float: 'left',
    textAlign: 'left',
    padding: 0,
    paddingTop: `${theme.spacing.unit * 2}px`,
    paddingLeft: `${theme.spacing.unit * 2}px`,
    paddingBottom: `${theme.spacing.unit * 2}px`,
  },
}));
export const AmountTitle = styled.span(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 3.75}px`,
  verticalAlign: 'middle',
  [theme.breakpoints.down('xs')]: {
    width: `${theme.spacing.unit * 10.25}px`,
  },
}));
export const AmountReduced = styled.span(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 2.25}px`,
  verticalAlign: 'middle',
}));

export const ButtonHeaderContainer = styled.div(({ theme }) => ({
  padding: `${theme.spacing.unit * 2}px`,
  paddingTop: `${theme.spacing.unit}px`,
  [theme.breakpoints.down('xs')]: {
    float: 'right',
    textAlign: 'right',
    padding: 0,
    paddingTop: `${theme.spacing.unit * 1.5}px`,
    paddingRight: `${theme.spacing.unit * 2}px`,
  },
}));
export const ButtonAmount = styled(CommonSubmitButton)(({ theme }) => ({
  height: '',
  [theme.breakpoints.down('xs')]: {
    height: '',
  },
}));

export const AmountContent = styled.div(({ theme }) => ({
  padding: `${theme.spacing.unit * 2}px`,
  '& p': {
    margin: 0,
    fontSize: `${theme.spacing.unit * 1.5 + 1.5}px`,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#33454f',
  },
  '& p:not(:first-child)': {
    marginTop: `${theme.spacing.unit}px`,
  },
  [theme.breakpoints.down('xs')]: {
    paddingTop: `${theme.spacing.unit}px`,
    paddingBottom: `${theme.spacing.unit}px`,
  },
}));
export const OfferDivider = styled.div(({ theme }) => ({
  width: '100%',
  height: `${theme.spacing.unit * 0.125}px`,
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
  backgroundColor: 'rgba(0, 23, 36, 0.14)',
  [theme.breakpoints.down('xs')]: {
    marginTop: `${theme.spacing.unit}px`,
    marginBottom: `${theme.spacing.unit}px`,
  },
}));

export const StepsTitle = styled.div(({ theme }) => ({
  marginTop: `${theme.spacing.unit * 4}px`,
  marginBottom: `${theme.spacing.unit * 4}px`,
  color: theme.palette.naranja.slate,
  fontSize: `${theme.spacing.unit * 3.5}px`,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: theme.typography.display1.fontSize,
  textAlign: 'center',
  [theme.breakpoints.down('xs')]: {
    marginTop: `${theme.spacing.unit * 2}px`,
    fontSize: theme.typography.title.fontSize,
    lineHeight: `${theme.spacing.unit * 3.25}px`,
  },
}));

export const StepsContainer = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 4}px`,
  marginBottom: `${theme.spacing.unit * 4}px`,
  display: 'flex',
  color: theme.palette.naranja.slate,
  '& > div:not(:first-child)': {
    marginLeft: `${theme.spacing.unit * 2.5}px`,
  },
  [theme.breakpoints.down('xs')]: {
    display: 'inline-grid',
    marginTop: `${theme.spacing.unit * 2}px`,
    '& > div:not(:first-child)': {
      marginLeft: 0,
    },
  },
}));

export const Step = styled.div(({ theme }) => ({
  width: `${theme.spacing.unit * 27.5}px`,
  position: 'relative',
  display: 'block',
  textAlign: 'center',
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    marginTop: `${theme.spacing.unit * 3}px`,
  },
}));

export const StepIconContainer = styled.div(({ theme }) => ({
  display: 'inline-flex',
  height: '65px',
  verticalAlign: 'middle',
}));

export const StepIcon = styled.img({
  display: 'block',
});

export const StepTitle = styled.span(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 1}px`,
  display: inlineBlock,
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightMedium,
  [theme.breakpoints.down('xs')]: {
    fontSize: `${theme.spacing.unit * 2.25}px`,
  },
}));

export const StepDescription = styled.span(({ theme }) => ({
  width: '100%',
  minWidth: `${theme.spacing.unit * 12.5}px`,
  marginTop: `${theme.spacing.unit * 1}px`,
  display: inlineBlock,
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: `${theme.spacing.unit * 2.5}px`,
  [theme.breakpoints.down('xs')]: {
    width: '90%',
    textAlign: 'center',
    fontSize: `${theme.spacing.unit * 2.25}px`,
  },
}));
