import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipTypography } from '@clipmx/clip-storybook';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const MerchantInfo = (props) => {
  const classes = useStyles(props);
  const { text, merchantInfo, orderStatus, shipping } = props;

  return (
    <>
      <ClipTypography
        variant={'body2'}
        component={'h3'}
        fontWeight={'semiBold'}
        align={'center'}
        classes={{
          body2: classes.subtitle,
        }}
      >
        {text.name}
      </ClipTypography>
      <ClipTypography variant={'body1'} component={'p'} align={'center'}>
        {`${merchantInfo.person.name} ${merchantInfo.person.last_name}
        ${
          merchantInfo.person.second_last_name === null
            ? ''
            : merchantInfo.person.second_last_name
        }`}
      </ClipTypography>

      <ClipTypography
        variant={'body2'}
        component={'h3'}
        fontWeight={'semiBold'}
        align={'center'}
        classes={{
          body2: classes.subtitle,
        }}
      >
        {text.phone}
      </ClipTypography>
      <ClipTypography variant={'body1'} component={'p'} align={'center'}>
        {merchantInfo.person.phone_number}
      </ClipTypography>

      <ClipTypography
        variant={'body2'}
        component={'h3'}
        fontWeight={'semiBold'}
        align={'center'}
        classes={{
          body2: classes.subtitle,
        }}
      >
        {text.address}
      </ClipTypography>
      <ClipTypography variant={'body1'} component={'p'} align={'center'}>
        {merchantInfo.address.street} {merchantInfo.address.external_number}
        {merchantInfo.address.internal_number
          ? `, ${merchantInfo.address.internal_number}`
          : ','}
        <br />
        {merchantInfo.address.neighborhood},<br />
        {merchantInfo.address.municipality},<br />
        {merchantInfo.address.city}, {merchantInfo.address.state},
        <br />
        {merchantInfo.address.zip_code}
      </ClipTypography>

      {shipping ? (
        <>
          <ClipTypography
            variant={'body2'}
            component={'h3'}
            fontWeight={'semiBold'}
            align={'center'}
            classes={{
              body2: classes.subtitle,
            }}
          >
            {text.orderNumber}
          </ClipTypography>
          <ClipTypography variant={'body1'} component={'p'} align={'center'}>
            {orderStatus.order_number}
          </ClipTypography>
        </>
      ) : (
        ''
      )}

      {shipping > 1 ? (
        <>
          <ClipTypography
            variant={'body2'}
            component={'h3'}
            fontWeight={'semiBold'}
            align={'center'}
            classes={{
              body2: classes.subtitle,
            }}
          >
            {text.guide + orderStatus.delivery_company}
          </ClipTypography>
          <ClipTypography variant={'body1'} component={'p'} align={'center'}>
            {orderStatus.tracking_number}
          </ClipTypography>
        </>
      ) : (
        ''
      )}
    </>
  );
};

MerchantInfo.propTypes = {
  classes: PropTypes.object,
  orderStatus: PropTypes.bool,
  merchantInfo: PropTypes.object,
  shipping: PropTypes.number,
};
MerchantInfo.defaultProps = {
  classes: {},
  orderStatus: {},
  merchantInfo: {},
  shipping: 0,
};

export default MerchantInfo;
