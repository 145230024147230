const styles = (theme) => {
  return {
    root: {
      width: '100vw',
      maxWidth: '23.5rem',
      height: 'calc(100vh - 3.5rem)',
      minHeight: '600px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: '#F3F3F3',
      overflow: 'auto',
      lineHeight: '1.5',
    },
    top: {
      marginTop: '2.75rem',
    },
    nipSuggestion: {
      textAlign: 'left',
      marginBottom: '0',
    },
    nipSuggestionExample: {
      color: theme.palette.text.auxiliar,
    },
    noBottomMargin: {
      marginBottom: 0,
    },
    noTopMargin: {
      marginTop: 0,
    },
    listBottomMargin: {
      marginBottom: '1rem',
    },
    banksImage: {
      width: `calc(100vw - ${theme.spacing.unit * 4}px)`,
      objectFit: 'contain',
      margin: `0 ${theme.spacing.unit}px`,
      marginBottom: '1.5rem',
    },
    howToCheckBalanceClickArea: {
      height: '45px',
      margin: '0 auto',
    },
    howToCheckBalance: {
      fontSize: '1rem',
      color: theme.palette.naranja.bloodOrange,
      fontWeight: '600',
    },
    ifYouDont: {
      fontSize: '1rem',
      marginBottom: '1rem',
      lineHeight: '1.5',
    },
    checkYourBalance: {
      fontSize: '1rem',
      lineHeight: '1.5',
      padding: `0 ${theme.spacing.unit}px`,
    },
    sectionSubheading: {
      fontSize: '1.125rem',
      textAlign: 'left',
      width: '100%',
      lineHeight: '1.5',
      boxSizing: 'border-box',
      marginBottom: '1.5rem',
    },
    module: {
      width: '100%',
      marginTop: 25,
    },
    buttonMargin: {
      marginTop: '2rem !important',
    },
    paper: {
      margin: theme.spacing.unit * 2,
    },
    dialogContainer: {
      textAlign: 'center',
      padding: theme.spacing.unit,
    },
    dialogText: {
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
    },
    groupedButtons: {
      marginTop: theme.spacing.unit * 1.5,
      gridColumnGap: theme.spacing.unit + 'px',
      display: 'grid',
      gridAutoFlow: 'column',
    },
    clearButton: {
      backgroundColor: 'transparent',
      color: theme.palette.naranja.bloodOrange,
      border: 'solid 1px ' + theme.palette.naranja.bloodOrange,
    },
    almostDoneContainer: {
      margin: theme.spacing.unit,
      marginTop: theme.spacing.unit * 5,
    },
    doneTitle: {
      textAlign: 'left',
      padding: `0 ${theme.spacing.unit}px`,
    },
    blueField: {
      width: '100%',
      maxWidth: '20.5rem',
      height: 'auto',
      borderRadius: '2px',
      border: 'solid 1px rgba(2, 136, 209, 0.15)',
      backgroundColor: 'rgba(2, 136, 209, 0.05)',
      marginBottom: '1rem',
      padding: '1rem 1rem 1rem 1rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
    },
    blueFieldText: {
      color: '#0288d1',
      fontSize: '0.825rem',
      marginBottom: '0',
      marginLeft: '0.5rem',
    },
  };
};
export default styles;
