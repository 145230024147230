import styled from '@emotion/styled';

import RssFeedIcon from '@material-ui/icons/Wifi';

const inlineBlock = 'inline-block';
const center = 'center';

export const Container = styled.div(({ theme }) => ({
  maxWidth: `${theme.spacing.unit * 57.5}px`,
}));

export const Group = styled.div(() => ({
  width: '100%',
  textAlign: 'center',
}));

export const Congratulation = styled.h4(({ theme }) => ({
  color: '#001724',
  fontSize: `${theme.spacing.unit * 2.5}px`,
  fontWeight: 600,
  textAlign: 'center',
  marginTop: `${theme.spacing.unit}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
}));

export const CongratulationImage = styled.img(({ theme }) => ({
  width: '100%',
  height: `${theme.spacing.unit * 15}px`,
}));

export const CongratulationMessage = styled.div(({ theme }) => ({
  color: '#001724',
  width: '60%',
  marginTop: `${theme.spacing.unit}px`,
  display: inlineBlock,
  textAlign: 'center',
  '& > p': {
    margin: `${theme.spacing.unit}px 0px`,
    fontSize: `${theme.spacing.unit * 2}px`,
    lineHeight: 1.25,
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  width: '30%',
  textAlign: center,
  margin: `${theme.spacing.unit}px auto`,
  [theme.breakpoints.down('xs')]: {
    width: '96%',
    margin: 'auto',
  },
}));

export const StepsMessage = styled.div(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#f1f7f9',
  color: '#007D8E',
  width: '90%',
  margin: 'auto',
  marginTop: `${theme.spacing.unit * 2}px`,
  padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2 + 1.5}px`,
  borderRadius: `${theme.spacing.unit}px`,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: 400,
  lineHeight: `${theme.spacing.unit * 2.5}px`,
  '&:before': {
    fontWeight: 600,
    marginRight: `${theme.spacing.unit * 2 + 1.5}px`,
  },
  '&.one': {
    marginTop: `${theme.spacing.unit * 3}px`,
    '&:before': {
      content: '"1"',
    },
  },
  '&.two': {
    marginBottom: `${theme.spacing.unit * 3}px`,
    '&:before': {
      content: '"2"',
    },
  },
}));

export const InstructionMessage = styled.div(({ theme }) => ({
  display: 'flex',
  margin: 'auto',
  marginBottom: `${theme.spacing.unit * 3}px`,
  marginLeft: '5.5px',
  width: '100%',
  [theme.breakpoints.down('xs')]: {
    marginLeft: 'auto',
  },
}));

export const WifiBGIcon = styled.div(({ theme }) => ({
  backgroundColor: '#fdede6',
  borderRadius: '100%',
  width: `${theme.spacing.unit * 4.5}px`,
  height: `${theme.spacing.unit * 4.5}px`,
  marginRight: `${theme.spacing.unit * 2}px`,
}));

export const WifiIcon = styled(RssFeedIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: `${theme.spacing.unit * 2.5}px`,
  height: `${theme.spacing.unit * 2.5}px`,
  margin: 'auto',
  display: 'block',
  paddingTop: `${theme.spacing.unit}px`,
}));
