export const styles = (theme) => {
  const rootStyles = {
    toastAlert: {
      alignItems: 'center',
      lineHeight: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },

    container: {
      minHeight: `calc(100vh - ${theme.spacing(7)}px)`,
      width: `calc(100% - ${theme.spacing(6)}px)`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: theme.spacing(7),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },

    requestImageContainer: {
      textAlign: 'center',
      margin: `${theme.spacing(2.75)}px 0px`,
      marginTop: 40,
      '& > img': {
        width: '45vw',
      },
    },

    titleContainer: {
      marginTop: 34
    },

    subtitleContainer: {
      marginTop: 30
    },

    detailsContainer: {
      flex: 1,
      marginTop: 25,
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > img': {
          marginRight: 13,
        }
      }
    },

    middleDetail: {
      marginBottom: 15,
      marginTop: 15
    },

    enrollmentMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&>img': {
        marginRight: '4',
        width: '30',
      }
    },

    buttonContainer: {
      marginBottom: 50,
      width: '100%',
      '& button': {
        width: '100%',
        marginBottom: `${theme.spacing.unit}px`,
        fontWeight: 600,
        fontSize: theme.spacing(2),
      },
    },
  };

  const typographyStyles = {
    bold: {
      fontWeight: 600,
    },
    typographySubtitle: {
      marginTop: theme.spacing(2),
      width: '95%',
    },
    typographyBullet: {
      color: theme.palette.indigo.base,
      fontSize: 16,
      fontWeight: 600,
    },
    typographyBody2: {
      color: '#4C4C4C',
    },
    typographyBody3: {
      color: '#177850',
    }
  };

  return { ...rootStyles, ...typographyStyles };
};
