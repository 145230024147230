import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import text from './text';
import { BaseButton } from 'components/common/Buttons';

const styles = (theme) => {
  return {
    root: {
      width: '100%',
      maxWidth: '22.5rem',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: '#FFFFFF',
      overflow: 'auto',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      boxSizing: 'border-box',
    },
    tooltipTitle: {
      fontSize: '20px',
    },
    lineHeight15: {
      lineHeight: '1.5',
    },
    buttonMargin: {
      marginBottom: '1rem',
    },
  };
};

const WhyPasswordTooltip = (props) => {
  const { classes, togglePasswordTooltip } = props;
  return (
    <div className={classes.root}>
      <Typography variant="title" className={classes.tooltipTitle}>
        {text.title}
      </Typography>
      <Typography variant="body1" className={classes.lineHeight15}>
        {text.text}
      </Typography>
      <BaseButton
        onClick={() => togglePasswordTooltip(false)}
        className={classes.buttonMargin}
      >
        {text.buttonLabel}
      </BaseButton>
    </div>
  );
};

export default withStyles(styles)(WhyPasswordTooltip);
