export default {
  title: 'Clip y Sam’s Club tienen beneficios para ti ',
  description: `Ingresa tu membresía, termina tu solicitud de adelanto de ventas y obtén 
  beneficios adicionales por parte de Clip y Sam's Club.`,
  membership: 'Membresía Sam’s Club',
  continue: 'Agregar y continuar',
  omit: 'Omitir y continuar',
  titleCongrats: '¡Listo!',
  descriptionCongrats: `Tu número de membresía de Sam’s Club ha sido agregado exitosamente. 
  Pronto recibirás noticias por correo electrónico sobre los beneficios que obtendrás.`,
  buttonCongrats: 'Continuar con mi solicitud',
};
