import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { getPrestaClipNameChange } from 'components/loans/redux/selectors';
import ReceiveImage from 'assets/images/receive-icon.svg';
import ProfileImage from 'assets/images/profile-icon.svg';
import RevisionImage from 'assets/images/revision-icon.svg';
import QuestionFAQ from '../QuestionFAQ';
import {
  LoansCurrentProductName,
  LoansNewProductName,
} from 'constants/AppConstants';
import * as Styled from './styled';
import text from './text';

export class WelcomeHeader extends Component {
  static propTypes = {
    amount: PropTypes.string.isRequired,
    totalAmount: PropTypes.string.isRequired,
    flowTitle: PropTypes.string.isRequired,
    hideMessageHeader: PropTypes.bool,
  };
  state = {
    showHowTo: true,
  };

  togglePopup = () => {
    this.setState({ showHowTo: !this.state.showHowTo });
  };

  renderNewProductName = (prevText) => {
    const { prestaClipNameChange } = this.props;
    return prestaClipNameChange
      ? prevText.replace(LoansCurrentProductName, LoansNewProductName)
      : prevText;
  };

  render() {
    return (
      <Styled.HeaderContainer>
        <Styled.CenterContent>
          <Styled.TopDecoration />
          <Styled.ShowHideContainer onClick={this.togglePopup}>
            <Styled.HowToButton>
              {this.state.showHowTo ? text.hide : text.show}
              <Styled.ShowHideIcon
                className={!this.state.showHowTo ? '' : 'hide'}
              />
            </Styled.HowToButton>
          </Styled.ShowHideContainer>
          {this.props.children}
          <Styled.HeaderContent
            id="headerContent"
            className={this.state.showHowTo ? '' : 'hide'}
          >
            {!this.props.hideMessageHeader && (
              <Styled.Message
                dangerouslySetInnerHTML={{
                  __html: text.messageHeader(
                    this.props.amount,
                    this.props.totalAmount,
                  ),
                }}
              />
            )}
            <Styled.TitleFlow>{this.props.flowTitle}</Styled.TitleFlow>
            <Styled.ContainerFlow>
              <ul>
                <li>
                  <Styled.IconStep src={ProfileImage} />
                  <Styled.TitleStep>{text.titleStepOne}</Styled.TitleStep>
                  <Styled.ContentStep>{text.contentStepOne}</Styled.ContentStep>
                </li>
                <li>
                  <Styled.IconStep src={RevisionImage} />
                  <Styled.TitleStep>{text.titleStepTwo}</Styled.TitleStep>
                  <Styled.ContentStep>
                    {this.renderNewProductName(text.contentStepTwo)}
                  </Styled.ContentStep>
                </li>
                <li>
                  <Styled.IconStep src={ReceiveImage} />
                  <Styled.TitleStep>{text.titleStepThree}</Styled.TitleStep>
                  <Styled.ContentStep>
                    {text.contentStepThree}
                  </Styled.ContentStep>
                </li>
              </ul>
            </Styled.ContainerFlow>
            <QuestionFAQ />
          </Styled.HeaderContent>
        </Styled.CenterContent>
      </Styled.HeaderContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  prestaClipNameChange: getPrestaClipNameChange(state),
});

export default connect(mapStateToProps)(WelcomeHeader);
