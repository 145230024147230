export default {
  title: 'Cambia tu NIP',
  subheading1: 'Necesitarás salir de tu negocio.',
  description1:
    'Una vez que generes tu NIP en la app, tendrás que activarlo mediante una consulta de saldo en alguno de estos cajeros: ',
  comissionCheckboxLabel:
    'Entiendo que el banco me cobrará una comisión al consultar mi saldo.',
  createNip: 'Genera un código de 4 dígitos.',
  pin1Label: 'Nuevo NIP',
  pin2Label: 'Confirma tu NIP',
  subheading2: 'Ingresa la contraseña de tu cuenta Clip',
  passwordLabel: 'Contraseña Clip',
  nipSuggestion: 'Para mayor seguridad, no uses:',
  nipSuggestion1: 'Números consecutivos (ej. 1234) o repetidos (ej. 2255).',
  nipSuggestion3: 'Fechas conmemorativas o números telefónicos.',
  nipSuggestion4: 'Evita números telefónicos.',
  consecutiveNumbersError: 'No puedes usar números consecutivos (ej. 1258).',
  repeatedNumbersError: 'No puedes usar números repetidos (ej. 2255).',
  pinLengthError: 'Usa una combinación de 4 dígitos diferentes.',
  pinNotMatchingError: 'El NIP no coincide.',
  passwordLengthError: 'Mínimo de 8 caracteres.',
  invalidPasswordError: 'Contraseña inválida. Inténtalo de nuevo.',
  almostDone: '¡Ya tienes tu NIP!',
  useNewNip: 'Para activarlo -y poder usarlo-, haz una consulta de saldo en:',
  howToCheckBalance: '¿Qué pasa si no activo mi NIP? ',
  ifYouDont:
    'Si no lo activas tendrás que usar el NIP anterior al hacer pagos en tiendas físicas.',
  understood: 'Entendido',
  commence: 'Comenzar',
  continue: 'Continuar',
  navigatorTitle: 'Cambiar NIP',
  dialogTitle: 'Lo que debes saber...',
  dialogMessage:
    'Una vez que generes tu nuevo NIP, el botón de Ver NIP desaparecerá.',
  okDialog: 'Continuar',
  cancelDialog: 'Regresar',
  bankComission:
    'Para activar tu NIP necesitas consultar tu saldo en un cajero ',
  banks:
    'Banorte, BanBajío, Citibanamex, HSBC, Multiva, Santander, Scotiabank, BBVA o Banregio',
  comissionWillApply: '. Se aplicará una ',
  comission: 'comisión bancaria menor a $15 pesos.',
};
