import { useHistory } from 'react-router-dom';
import { initialize } from 'redux-form';

import urls from '../../../helpers/urls';
import getUrl from '../../../helpers/getUrl';
import { clearToken, saveToken } from '../../../helpers/tokenHelpers';
import {
  dataLoading,
  dataLoaded,
  dataLoadFailed,
} from '../../../actions/AppActions';

import { CLEAR_PROMO_STATE } from '../../promos/redux/types';
import { Forms, MerchantStates } from '../../../constants/AppConstants';
import {
  getUserInfo,
  getUserPasswordExpirationCount,
  getIdentityVerification,
  savePasswordExpirationDays,
  setIdentityVerification,
} from '../../../actions/UserActions';
import {
  getDashboardData,
  getDashboardState,
} from '../../../actions/DashboardActions';
import {
  getMerchantInfo,
  updateMerchantState,
} from '../../../actions/MerchantActions';
import {
  clearReferralState,
  getReferralAggregates,
} from '../../../actions/ReferralActions';
import {
  getPreapprovals,
  getLoans,
  cleanLoans,
  changePreapproval,
} from '../../loans/redux/actions';

import {
  CLEAR_DASHBOARD,
  CLEAR_MERCHANT,
  CLEAR_USER,
  EMAIL_VALIDATION_CLEAR,
  SET_USER_TOKEN,
  CLEAR_REFERRAL_STATE,
  EMAIL_VALIDATION_SUCCESS,
} from '../../../actions/Types';
import { CLEAR_ONBOARDING_STATE } from '../../onboarding/redux/types';

import {
  CLEAR_PASSWORD_RESET_TOKEN,
  LOGIN_START,
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  LOGOUT_START,
  LOGOUT_FULFILLED,
  PASSWORD_RESET_EMAIL_ERROR,
  PASSWORD_RESET_EMAIL_SUCCESS,
  PASSWORD_RESET_TOKEN_ERROR,
  RESET_LOGIN,
  SET_PASSWORD_RESET_TOKEN,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOAD_REGISTRATION_META,
  LOAD_REGISTRATION_META_FAIL,
  LOAD_CHECK_EMAIL,
  LOAD_CHECK_EMAIL_FAIL,
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  SET_USER_NONCE,
  USER_NONCE,
  SET_PASSWORD_RESET_EMAIL,
  VALIDATE_SIGNUP_EMAIL,
} from './types';

import AuthService from '../service';
import { CLEAR_BANK_ACCOUNT } from '../../../actions/Types';
import { CLEAR_BANK_INFO } from '../../account/redux/types';
import { getBulkState } from '../../Catalog/redux/actions';
import { CATALOG_CLEAR } from '../../Catalog/redux/types';
import { RESET_DOCUMENTS } from '../../loans/redux/types';

import { createPostRequest } from '../../../middleware/apiResolve/reduxUtils';

export function reLogin(password, onSuccess = () => {}, onFailure = () => {}) {
  const route = '/mlm/nonce';
  return {
    type: createPostRequest(USER_NONCE),
    payload: {
      apiEndPoint: 'v3',
      route,
      data: {
        password,
      },
      success: onSuccess,
      failure: onFailure,
    },
  };
}

export const setUserNonce = (nonce) => {
  return {
    type: SET_USER_NONCE,
    payload: {
      data: { nonce },
    },
  };
};

export const clearMerchantDashboardState = () => (dispatch) => {
  // let history = useHistory();

  clearToken();

  // Clear redux state
  dispatch({ type: CLEAR_DASHBOARD });
  dispatch({ type: CLEAR_MERCHANT });
  dispatch({ type: CLEAR_ONBOARDING_STATE });
  dispatch({ type: CLEAR_PROMO_STATE });
  dispatch({ type: CLEAR_USER });
  dispatch({ type: CLEAR_REFERRAL_STATE });
  dispatch({ type: EMAIL_VALIDATION_CLEAR });
  dispatch({ type: CATALOG_CLEAR });
  dispatch({ type: RESET_LOGIN });
  dispatch({ type: RESET_DOCUMENTS });

  // Redirect the user to the sign in page
  // history.push(getUrl(urls.signIn));
};

export const logoutUser = (email) => (dispatch) => {
  dispatch(dataLoading(LOGOUT_START));
  dispatch({ type: CLEAR_BANK_ACCOUNT });
  dispatch({ type: CLEAR_BANK_INFO });
  return AuthService.logout(email)
    .then(() => {
      dispatch(dataLoaded({ type: LOGOUT_FULFILLED }));
      dispatch({ type: LOGOUT_FULFILLED });
    })
    .catch(() => {
      dispatch({ type: LOGOUT_FULFILLED });
    });
};

export const loginUserWithToken = (token) => (dispatch) => {
  saveToken(token);
  // Add the token to the User in redux
  dispatch({ type: SET_USER_TOKEN, payload: token });

  // Clean loans
  dispatch(cleanLoans());

  // Get user password expiration time
  dispatch(getUserPasswordExpirationCount());

  // Get merchant info
  dispatch(getMerchantInfo());

  // Get user data
  dispatch(getUserInfo());

  dispatch(getIdentityVerification());

  // Get dashboard state
  dispatch(getDashboardState());

  //Get dashboard data
  dispatch(getDashboardData());

  // Get merchant referrals info
  dispatch(getReferralAggregates());

  // Get Loans
  dispatch(getLoans());

  // Get Preapprovals
  dispatch(getPreapprovals());

  //Clean loan forms in redux
  dispatch(initialize(Forms.MR_PRESTA));
  dispatch(initialize(Forms.LOANS));

  //Clean preapproval selected in redux
  dispatch(changePreapproval());

  // Set user as authenticated in redux
  dispatch({ type: LOGIN_FULFILLED });
};

export const loginUser = (email, password) => (dispatch) => {
  dispatch(dataLoading(LOGIN_START));
  return AuthService.login(email, password)
    .then((data) => {
      dispatch(dataLoaded(LOGIN_FULFILLED));
      dispatch(savePasswordExpirationDays(data.days_until_exp, true));
      dispatch(loginUserWithToken(data.access_token));
    })
    .catch((err) => {
      dispatch(dataLoadFailed(LOGIN_REJECTED, err));
      dispatch({
        type: LOGIN_REJECTED,
        payload: {
          email,
          accountLocked: err.response.data.is_locked,
          attempts: err.response.data.attempts,
          error: {
            message: 'No se pudo iniciar sesión.',
            status: err.response.status,
          },
        },
      });
    });
};

export const validatePasswordResetToken =
  (userId, resetToken) => (dispatch) => {
    return AuthService.validateResetToken(userId, resetToken)
      .then(() => {
        dispatch({ type: SET_PASSWORD_RESET_TOKEN, payload: { resetToken } });
      })
      .catch((err) => {
        const email = err.response.data.item
          ? err.response.data.item.email
          : '';

        // Update login state in Redux store
        dispatch({
          type: PASSWORD_RESET_TOKEN_ERROR,
          payload: { resetToken, email },
        });
      });
  };

export const clearPasswordResetToken = () => (dispatch) => {
  dispatch({ type: CLEAR_PASSWORD_RESET_TOKEN });
};

export const sendPasswordResetEmail = (email) => (dispatch) => {
  dispatch(dataLoading(PASSWORD_RESET_EMAIL_SUCCESS));

  return AuthService.getPasswordResetToken(email)
    .then(() => {
      dispatch(dataLoaded(PASSWORD_RESET_EMAIL_SUCCESS));
      dispatch({ type: PASSWORD_RESET_EMAIL_SUCCESS, payload: email });
    })
    .catch((err) => {
      dispatch(dataLoadFailed(PASSWORD_RESET_EMAIL_ERROR, err));
      dispatch({ type: PASSWORD_RESET_EMAIL_ERROR });
      throw err;
    });
};

export const setPasswordResetEmail = (email) => (dispatch) => {
  dispatch({ type: SET_PASSWORD_RESET_EMAIL, payload: email });
};

export const resetPassword = (id, token, pwd, pwdConfirm) => (dispatch) => {
  dispatch(dataLoading(PASSWORD_RESET));

  return AuthService.resetPassword(id, token, pwd, pwdConfirm)
    .then((data) => {
      dispatch(dataLoaded(PASSWORD_RESET));
      dispatch({ type: PASSWORD_RESET_SUCCESS, payload: data });
    })
    .catch((err) => {
      dispatch(dataLoadFailed(PASSWORD_RESET, err));
      dispatch({ type: PASSWORD_RESET_ERROR, payload: err.message });
      throw err;
    });
};

export const continueOnboarding = (options) => (dispatch) => {
  dispatch(dataLoading(REGISTER_START));
  return AuthService.register({ ...options, signupFlow: 'EMAIL_FLOW' })
    .then((data) => {
      if (data.status !== 200) {
        return Promise.reject({ err: data.message });
      }
      dispatch(dataLoaded(REGISTER_START));
      // Update Merchant and User State after each call to register service
      dispatch(getMerchantInfo());
      dispatch(getUserInfo());
    })
    .catch((err) => {
      dispatch(dataLoadFailed(REGISTER_FAIL, err));
      dispatch({
        type: REGISTER_FAIL,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({ type: REGISTER_FAIL, payload: false });
      }, 5000);
      return Promise.reject(err);
    });
};

export const register = (options) => (dispatch) => {
  dispatch(dataLoading(REGISTER_START));
  return AuthService.register(options)
    .then((data) => {
      if (data.status !== 200) {
        return Promise.reject({ err: data.message });
      }
      dispatch(dataLoaded(REGISTER_SUCCESS));
      dispatch({ type: REGISTER_SUCCESS, payload: data });
      dispatch(clearReferralState());
      if (
        options.formValues.password &&
        options.formValues.signupFlow === 'SIMPLE_SIGNUP_FLOW'
      ) {
        dispatch(
          loginUser(options.formValues.email, options.formValues.password),
        );
      }
    })
    .catch((err) => {
      dispatch(dataLoadFailed(REGISTER_FAIL, err));
      dispatch({
        type: REGISTER_FAIL,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({ type: REGISTER_FAIL, payload: false });
      }, 5000);
      if (options.signupFlow === 'EMAIL_FLOW') {
        return Promise.reject(err);
      }
    });
};

export const fetchRegistrationMeta = () => (dispatch) => {
  dispatch(dataLoading(LOAD_REGISTRATION_META));

  return AuthService.getRegistrationMeta()
    .then((data) => {
      dispatch(dataLoaded(LOAD_REGISTRATION_META, data));
      dispatch({ type: LOAD_REGISTRATION_META, payload: data });
    })
    .catch((err) => {
      dispatch(dataLoadFailed(LOAD_REGISTRATION_META, err));
      dispatch({ type: LOAD_REGISTRATION_META_FAIL, payload: err.message });
    });
};

export const verifyEmail = (email) => (dispatch) => {
  dispatch(dataLoading(LOAD_CHECK_EMAIL));

  return AuthService.checkIfEmailExists(email)
    .then((data) => {
      dispatch(dataLoaded(LOAD_CHECK_EMAIL, data));
      dispatch({ type: LOAD_CHECK_EMAIL_FAIL, payload: data });
      return data;
    })
    .catch((err) => {
      dispatch(dataLoadFailed(LOAD_CHECK_EMAIL, err));
      dispatch({ type: LOAD_CHECK_EMAIL_FAIL, payload: err.message });
    });
};

export const validateSignupEmail = (email, validationId) => (dispatch) => {
  dispatch(dataLoading(VALIDATE_SIGNUP_EMAIL));

  return AuthService.validateSignupEmail(email, validationId)
    .then((data) => {
      dispatch(dataLoaded(VALIDATE_SIGNUP_EMAIL, data));
      dispatch({ type: EMAIL_VALIDATION_SUCCESS });
      dispatch(setIdentityVerification(true));
      return data;
    })
    .catch((err) => {
      dispatch(dataLoadFailed(VALIDATE_SIGNUP_EMAIL, err));
      return Promise.reject(err);
    });
};
