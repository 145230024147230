import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

export const useStyles = makeStyles((theme) => {
  return {
    OrderTotalParent: {
      margin: `${theme.spacing.unit * 3}px auto`,
    },
    AmountContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.indigo.l90}`,
      padding: `${theme.spacing.unit * 1.0}px ${0}px ${
        theme.spacing.unit * 1.0
      }px ${0}px`,
    },
    AmountContainerBa: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.indigo.l90}`,
      padding: `${theme.spacing.unit * 1.0}px ${0}px ${
        theme.spacing.unit * 1.0
      }px ${0}px`,
      background: theme.palette.teal.l94,
    },
    MarginTop: {
      margin: `${theme.spacing.unit * 4.0}px ${0}px ${
        theme.spacing.unit * 2.0
      }px ${0}px`,
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    Button: {
      textTransform: 'none !important',
      borderRadius: `${theme.spacing.unit * 5}px`,
      height: `${theme.spacing.unit * 6.25}px`,
    },
    modalPay: {
      width: '450px',
      maxWidth: '100%',
    },
    cardCenter: {
      margin: 'auto',
    },
    customerHappines: {
      margin: `${theme.spacing.unit * 5.0}px ${0}px ${
        theme.spacing.unit * 3.5
      }px ${0}px`,
    },
    buttonCenter: {
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        marginBottom:  `${theme.spacing.unit * 3}px`,
      },
    },
    modalHiden: {
      overflow: 'hidden',
    },
    iconButton: {
      top: '16px',
      color: '#9e9e9e',
      right: '16px',
      position: 'absolute',
    },
    tooltipPaper: {
      padding: '20px',
      borderRadius: '20px 20px 0 0',
      borderLeft: '5px solid rgba(0, 0, 0, 0.5)',
      borderRight: '5px solid rgba(0, 0, 0, 0.5)',
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        top:  `${theme.spacing.unit * 10}px`,
      },
    },
    cardSize: {
      borderRadius: '25px',
    },
  };
});

export default useStyles;
