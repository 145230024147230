export default {
  title: 'Confirmación',
  subtitle: 'Te depositaremos',
  termTitle: 'Plazo máximo de pago',
  termText: (term) => `${term} meses`,
  paymentTitle: 'Forma de pago',
  paymentText: (payment) =>
    `${payment}% de tus ventas diaras + Depósito quincenal`,
  commissionTitle: 'Comisión única',
  commissionText: (commission) => `${commission} + IVA`,
  totalTitle: 'Total a pagar',
  confirmButton: 'Enviar solicitud',
  newOffer: 'Nueva oferta',
  termsAndConditions:
    'Al hacer hacer clic en el botón “Enviar solicitud”, acepto los términos ' +
    'y condiciones de esta oferta.',
};
