import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import { loansProviderCodes } from 'constants/ProviderCodes';
import { saveMerchantData } from 'components/loans/redux/actions';
import {
  getLoansIdentityDocuments,
  getLoansProofAddressDocuments,
  getPreapprovalSelected,
} from 'components/loans/redux/selectors';
import LoansForm from './LoansForm';

const ASYNC = true;
const AMOUNT = 10000;
export class LoansClipLender extends PureComponent {
  saveBorrower = (form) => {
    const { identityFiles, addressProofFiles, saveMerchantData } = this.props;
    const identityDocuments = identityFiles.map((file) => {
      return {
        document_type: 'INE',
        document_content_type: file.type,
        document_key: file.key,
      };
    });
    const proofOfAddressDocuments = addressProofFiles.map((file) => {
      return {
        document_type: 'ADDRESS',
        document_content_type: file.type,
        document_key: file.key,
      };
    });
    saveMerchantData(
      null,
      null,
      null,
      loansProviderCodes.CLIP,
      AMOUNT,
      form,
      identityDocuments,
      proofOfAddressDocuments,
    );
  };

  render() {
    return <LoansForm onSubmit={this.saveBorrower} async={ASYNC} />;
  }
}

const mapStateToProps = (state) => {
  return {
    preapprovalSelected: getPreapprovalSelected(state),
    identityFiles: getLoansIdentityDocuments(state),
    addressProofFiles: getLoansProofAddressDocuments(state),
  };
};
export default connect(mapStateToProps, {
  saveMerchantData,
})(withWidth()(LoansClipLender));
