export default {
  cashAdvance: 'Cash Advance',
  title: '¿Cómo calculamos las ofertas?',
  firstParagraph: 'Las ofertas se calculan de acuerdo a tu historial de transacciones ',
  firstParagraphBold:  'para que puedas pagar sin afectar tus ingresos.',
  secondParagraph: 'Te mostramos un ejemplo de ',
  secondParagraphBold: 'cómo calculamos la oferta ',
  secondParagraphComplement: 'de algunos dueños de negocio:',



 };
