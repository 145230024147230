import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

export const styles = makeStyles((theme) => {
  return {
    OrderTotalParent: {
      borderRadius: `${theme.spacing.unit * 1}px`,
      margin: `${theme.spacing.unit * 3}px auto`,
      padding: `${theme.spacing.unit * 2.0}px ${theme.spacing.unit * 2.0}px`,
      background: theme.palette.indigo.l96,
    },
    AmountContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid #E6E8E9',
      padding: `${theme.spacing.unit * 1.0}px ${0}px ${
        theme.spacing.unit * 1.0
      }px ${0}px`,
    },
    MarginTop: {
      marginTop: `${theme.spacing.unit * 1.5}px`,
      textTransform: 'none !important',
    },
    buttonClose: {
      border: 'none',
      width: `${theme.spacing.unit * 3}px`,
      '&:hover': {
        border: 'none',
        background: theme.palette.indigo.l96,
      },
    },
    Button: {
      textTransform: 'none !important',
      border: `${theme.spacing.unit * 0.25}px solid`,
      marginTop: `${theme.spacing.unit * 1.5}px`,
      borderRadius: `${theme.spacing.unit * 5}px`,
      height: `${theme.spacing.unit * 6.25}px`,
      '&:hover': {
        border: `${theme.spacing.unit * 0.25}px solid`,
      },
    },
    Desk: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    Mobil: {
      padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 3}px`,
      position: 'fixed',
      bottom: '0',
      right: '0',
      left: '0',
      boxShadow: '0px 1px 6px rgb(0 0 0 / 25%)',
      zIndex: 3,
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('xl')]: {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    TextTotal: {
      color: '#66737B',
    },
    tooltipTitleContainer: {
      display: 'flex',
    },
    tooltipTitle: {
      marginBottom: theme.spacing.unit * 3,
    },
    tooltipCodeLabel: {
      marginBottom: theme.spacing.unit * 3,
    },
    tooltipButton: {
      borderRadius: '25px',
    },
    tooltipPaper: {
      padding: '20px',
      borderRadius: '20px 20px 0 0',
      maxHeight: '90%',
    },
    iconButton: {
      alignItems: 'start',
      flexGrow: 1,
      padding: '0 0 0 12px',
      position: 'absolute',
      right: '20px',
    },
    iconBase: {
      '& span': {
        justifyContent: 'end',
        paddingBottom: '18px',
      },
      '& svg': {
        backgroundColor: theme.palette.indigo.l90,
        borderRadius: '14px',
        padding: '3px',
      },
    },
    dialogCard: {
      borderRadius: '25px',
    },
    cardSize: {
      borderRadius: '25px',
    },
    modalContent: {
      overflow: 'scroll',
    },
  };
});

export default styles;
