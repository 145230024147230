import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

export const styles = makeStyles((theme) => {
  return {
    TitleTooltipStyles: {
      width: '100%',
      float: 'left',
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: `${theme.spacing.unit * 3}px`,
      color: theme.palette.naranja.slate,
      marginTop: `${theme.spacing.unit * 1}px`,
      marginBottom: `${theme.spacing.unit * 3}px`,
      textAlign: 'center',
    },
    IdentificationStyles: {
      width: '100%',
      textAlign: 'center',
      float: 'left',
      paddingTop: `${theme.spacing.unit * 1.25}px`,
      paddingBottom: `${theme.spacing.unit * 1.25}px`,
    },
    ImageIdentificationStyles: {
      width: '80%',
    },
    ContactFieldStyles: {
      '& label': {
        boxShadow: 'none',
      },
      '& label > span:first-child': {
        height: '26px',
      },
    },
    FormControlStyles: {
      width: '100%',
      display: 'block',
      float: 'left',
      marginBottom: `${theme.spacing.unit}px`,
      marginTop: `${theme.spacing.unit}px`,
      '& legend': {
        textAlign: 'left',
        marginTop: `${theme.spacing.unit}px`,
        color: `${theme.palette.naranja.slate}`,
      },
      '&:first-child': {
        marginTop: 0,
        marginBottom: `${theme.spacing.unit}px`,
      },
    },
    StepTitleStyles: {
      width: '100%',
      textAlign: 'left',
      fontSize: `${theme.spacing.unit * 2.5}px`,
      fontWeight: 600,
      color: `${theme.palette.naranja.slate}`,
      filter: 'brightness(0.5)',
    },
    StepDescriptionTitleStyles: {
      marginTop: '24px',
      width: '100%',
      textAlign: 'left',
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 600,
      color: `${theme.palette.naranja.slate}`,
      filter: 'brightness(0.5)',
    },
    StepSectionStyles: {
      width: '100%',
      marginTop: `${theme.spacing.unit * 3}px`,
      textAlign: 'left',
      fontSize: `${theme.spacing.unit * 1.75}px`,
      color: `${theme.palette.naranja.slate}`,
      filter: 'brightness(0.5)',
    },
    ErrorHelperTextStyles: {
      width: `calc(100% - ${theme.spacing.unit * 4}px)`,
      margin: '0 auto',
      padding: `${theme.spacing.unit * 0.75}px ${theme.spacing.unit * 1.5}px`,
      borderBottomLeftRadius: `${theme.spacing.unit * 0.5}px`,
      borderBottomRightRadius: `${theme.spacing.unit * 0.5}px`,
      backgroundColor: `${theme.palette.error.light}`,
      fontSize: `${theme.spacing.unit * 1.5}px`,
      color: `${theme.palette.error.dark}`,
    },
    WarningContainerStyles: {
      display: 'flex',
      alignItems: 'center',
      color: `${theme.palette.error.dark}`,
      width: '100%',
      '& svg': {
        width: '0.7em',
        height: '0.7em',
        marginRight: '10px',
      },
    },
    addPhone: {
      [theme.breakpoints.up('md')]: {
      width: '100%',
      display: 'inline-block',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  };   
});

export default styles;
