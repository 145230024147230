import styled from '@emotion/styled';

import RevisionImage from '../../../../assets/images/revision-icon.svg';

export const Container = styled.div(({ theme }) => ({
  width: '100%',
  boxShadow: theme.shadows[1],
}));

export const Title = styled.div(({ theme }) => ({
  width: '100%',
  minHeight: `${theme.spacing.unit * 6}px`,
  fontSize: '28px',
  paddingTop: `${theme.spacing.unit * 3}px`,
  wordSpacing: '2px',
  color: theme.palette.naranja.slate,
  [theme.breakpoints.down('xs')]: {
    display: 'inline-block',
  },
}));

export const TopDecoration = styled.div(({ theme }) => ({
  width: '100%',
  height: '5px',
  backgroundColor: theme.palette.naranja.cerulean,
}));

export const Logo = styled.div(({ theme }) => ({
  width: '15%',
  height: '80px',
  backgroundImage: `url(${RevisionImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  float: 'left',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column-reverse',
    width: '100%',
  },
}));

export const TitleMessage = styled.div(({ theme }) => ({
  width: '75%',
  float: 'left',
  textAlign: 'left',
  paddingTop: `${theme.spacing.unit * 2}px`,
  fontWeight: theme.typography.fontWeightMedium,
  [theme.breakpoints.down('xs')]: {
    width: '94%',
    paddingLeft: '1%',
    paddingRight: '5%',
    paddingTop: '5%',
    textAlign: 'justify',
    paddingBottom: '5%',
  },
}));

export const Content = styled.p(({ theme }) => ({
  paddingLeft: '15%',
  paddingRight: `${theme.spacing.unit * 5}px`,
  fontFamily: theme.typography.fontFamily,
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.naranja.slate,
  lineHeight: `${theme.spacing.unit * 4}px`,
  [theme.breakpoints.down('xs')]: {
    width: '94%',
    paddingLeft: '1%',
    paddingRight: '5%',
    textAlign: 'justify',
  },
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  width: '30%',
  textAlign: 'center',
  margin: `${theme.spacing.unit * 5}px auto`,
  marginBottom: 0,
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
}));
