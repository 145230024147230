import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { IoIosArrowBack } from 'react-icons/io';

export const Container = styled.div(() => ({
  width: '100%',
  '&.mobile': {
    padding: '16px',
    boxSizing: 'border-box',
  },
}));

export const Title = styled.div(({ theme }) => ({
  width: '100%',
  color: '#001724',
  fontSize: '21px',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: 'normal',
  display: 'flex',
  textAlign: 'center',
  marginBottom: `${theme.spacing.unit * 3}px`,
}));

export const Content = styled.div(({ theme }) => ({
  width: '100%',
  paddingTop: '0px',
  paddingBottom: `${theme.spacing.unit}px`,
  color: theme.palette.naranja.slate,
  fontFamily: theme.typography.fontFamily,
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '30px',
  textAlign: '-webkit-center',
}));

export const LeftMessageContent = styled.p(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  marginTop: '0px',
  '& b': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '& a': {
    color: lightBlue[700],
    cursor: 'pointer',
  },
  '& ol': {
    paddingLeft: '20px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
  },
}));

export const LinkContainer = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
}));

export const MonthlyPendingPayment = styled.div(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('xs')]: {
    justifyContent: 'space-between',
    marginTop: 12,
  },
}));

export const BackLinkIcon = styled(IoIosArrowBack)(({ theme }) => ({
  color: theme.palette.naranja.slate,
  verticalAlign: 'text-bottom',
  height: '16px',
  opacity: '.57',
}));

export const Breadcrumb = styled(Link)(({ theme }) => ({
  color: theme.palette.naranja.slate,
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '30px',
  textAlign: 'center',
  opacity: '.57',
  fontFamily: theme.typography.fontFamily,
  '&:hover': {
    color: theme.palette.primary.main,
    opacity: '1',
  },
  '&.actual': {
    color: theme.palette.naranja.slate,
    opacity: '.57',
  },
}));

export const HorizontalDivisor = styled.hr(({ theme }) => ({
  backgroundColor: '#9C9C9C',
  opacity: '0.2',
}));

export const DataTitle = styled.span(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: theme.typography.fontFamily,
}));

export const DataTitleMonthly = styled.span(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: theme.typography.fontFamily,
  color: '#B98500',
  [theme.breakpoints.up('sm')]: {
    flexGrow: 0,
    maxWidth: '65%',
    flexBasis: '65%',
    textAlign: 'left',
  },
}));

export const DataTitleMonthlySecond = styled.span(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.up('sm')]: {
    flexGrow: 0,
    maxWidth: '65%',
    flexBasis: '65%',
    textAlign: 'left',
  },
}));

export const EndDateValue = styled.span(({ theme }) => ({
  color: theme.palette.naranja.slate,
  fontSize: '18px',
  fontWeight: '400',
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down('xs')]: {
    fontSize: '17px',
  },
}));

export const TotalPendingValue = styled.span(({ theme }) => ({
  color: '#B98500',
  fontSize: '18px',
  fontWeight: '400',
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down('xs')]: {
    fontSize: '17px',
  },
}));

export const TotalPendingValueMonthlyFirst = styled.span(({ theme }) => ({
  color: '#B98500',
  fontSize: '18px',
  fontWeight: '400',
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down('xs')]: {
    fontSize: '17px',
  },
  [theme.breakpoints.up('sm')]: {
    flexGrow: 0,
    maxWidth: '35%',
    flexBasis: '35%',
    textAlign: 'left',
  },
}));

export const TotalPendingValueMonthly = styled.span(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: '400',
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down('xs')]: {
    fontSize: '17px',
  },
  [theme.breakpoints.up('sm')]: {
    flexGrow: 0,
    maxWidth: '35%',
    flexBasis: '35%',
    textAlign: 'left',
  },
}));

export const SubTitle = styled.div(({ theme }) => ({
  width: '100%',
  color: theme.palette.naranja.slate,
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: 'normal',
  display: 'flex',
  textAlign: 'center',
  marginBottom: `${theme.spacing.unit}px`,
}));

export const InfoIcon = styled.img(() => ({
  width: '36px',
  height: '36px',
  objectFit: 'contain',
  verticalAlign: 'middle',
  marginRight: '20px',
}));

export const InfoText = styled.span(() => ({
  verticalAlign: 'middle',
}));

export const InfoLink = styled.a(({ theme }) => ({
  color: theme.palette.naranja.cerulean,
}));
