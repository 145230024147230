export default {
  about: 'ACERCA DE CLIP',
  blog: 'CLIP BLOG',
  help: 'SITIO DE AYUDA',
  contact: 'Contacto',
  copyright: (year) => `Copyright ${year} PayClip, Inc.`,
  privacyPolicy: 'Aviso de Privacidad',
  termsAndConditions: 'Términos y Condiciones',
  appleClassName: 'apple-mx',
  androidClassName: 'android-mx',
};
