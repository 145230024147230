export const styles = (theme) => {
  const formControlLabelStyles = {
    formControlLabelRoot: {
      width: '100%',
      margin: 0,
      marginTop: `${theme.spacing.unit}px`,
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      boxShadow: '0 0 0 1px #ccd0d3',
    },
    formControlLabelFocused: {
      margin: 0,
      marginTop: `${theme.spacing.unit}px`,
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      boxShadow: '0 0 0 2px #fc4c02',
    },
    formControlLabelDisabled: {
      backgroundColor: 'rgba(248, 248, 248, 0.5)',
    },
  };

  const radioStyles = {
    radioChecked: {
      color: '#fc4c02!important',
    },
  };

  const errorStyles = {
    errorRoot: {
      width: `calc(100% - ${theme.spacing.unit * 4}px)`,
      margin: '0 auto',
      padding: `${theme.spacing.unit * 0.75}px ${theme.spacing.unit * 1.5}px`,
      borderBottomLeftRadius: `${theme.spacing.unit * 0.5}px`,
      borderBottomRightRadius: `${theme.spacing.unit * 0.5}px`,
      backgroundColor: '#ffe7e7',
      fontSize: `${theme.spacing.unit * 1.5}px`,
      color: '#7c0b0b',
    },
  };

  return { ...formControlLabelStyles, ...radioStyles, ...errorStyles };
};
