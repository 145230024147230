import clevertap from 'clevertap-web-sdk';
import { EVENTS } from './events';
import { SET_CLEVERTAP_LAST_EVENT } from '../../actions/Types';
import reduxStore from '../reduxStore';

const Clevertap = {
  initialize: () => {
    try {
      clevertap.privacy.push({ optOut: false });
      clevertap.privacy.push({ useIP: false });
      clevertap.spa = true;
      clevertap.init(process.env.REACT_APP_CLEVERTAP_APP_ID);
    } catch (error) {
      console.log(error);
    }
  },
  event: (event, action, payload) => {
    const actionValue = action || 'NA';
    if (process.env.NODE_ENV !== 'test') {
      const {
        clevertap: { lastEvent },
      } = reduxStore.getState();
      try {
        if (lastEvent !== event + actionValue) {
          if ((payload && Object.keys(payload).length > 0) || action) {
            console.log(
              `CLEVERTAP PAYLOAD: ${JSON.stringify({
                ...payload,
                action: actionValue,
              })}`,
            );
            clevertap.event.push(event, { ...payload, action: actionValue });
          } else {
            clevertap.event.push(event);
          }
          reduxStore.dispatch({
            type: SET_CLEVERTAP_LAST_EVENT,
            payload: event + actionValue,
          });
          console.log(`CLEVERTAP_EVENT SENT: ${event}`);
        }
      } catch (error) {
        console.error(`CLEVERTAP_EVENT_${event}_ERROR_${error}`);
      }
    }
  },
  profile: (userInfo) => {
    const fullName = `${userInfo.first_name || ''} ${
      userInfo.last_name || ''
    } ${userInfo.second_last_name || ''}`;
    clevertap.onUserLogin.push({
      Site: {
        Name: fullName,
        Identity: userInfo?.email,
        Email: userInfo?.email,
        Phone: `+52${userInfo?.mobile}`,
        DOB: new Date(),
      },
    });
  },
};

const onEventMiddleware =
  (clevertap, onEvent) =>
  (...args) => {
    if (clevertap) {
      const value = args[0]?.target?.value;
      const event =
        typeof clevertap.event === 'object' && value
          ? clevertap.event[value.toLowerCase()]
          : clevertap.event;
      Clevertap.event(event, null, clevertap.payload);
    }
    onEvent?.(...args);
  };

const getStoredValue = (key) =>
  key.split('.').reduce((a, b) => {
    const array = b?.split('__');
    if (
      array &&
      array.length === 2 &&
      Array.isArray(a[array[0]]) &&
      !isNaN(array[1])
    ) {
      return a[array[0]][parseInt(array[1])];
    }
    if (
      array &&
      array.length === 3 &&
      Array.isArray(a[array[0]]) &&
      isNaN(array[1])
    ) {
      return a[array[0]].filter(
        (v) => v[array[1]]?.toLowerCase() == array[2],
      )?.[0];
    }
    return a?.[b];
  }, reduxStore.getState()) ?? '';

/** payload={
  {
    static: {
      testField: 'test-vaue',
    },
    redux: {
      loans: {
        loansFormVersion: 'version',
        showCashAdvance: 'cashAdvance',
        preapprovals__0: {
          provider_code: 'lender',
        },
        preapprovals__status__accepted: {
          provider_code: 'lenderAvailablle',
        },
      },
      merchant: {
        info: {
          proxy_id: { 
            proxy_merchant_token: 'proxyMerchantToken'
          },
        },
      },
    },
  }} */
const createPayloadForClevertap = (json, parent) => {
  if (!json) return {};
  let payload = { ...json.static };
  const rootJson = !parent ? json.redux : json;
  for (let key in rootJson) {
    const value = rootJson[key];
    const fullKey = parent ? `${parent}.${key}` : key;
    if (typeof value === 'string') {
      payload = {
        [value]: getStoredValue(fullKey),
        ...payload,
      };
    } else {
      payload = {
        ...createPayloadForClevertap(value, fullKey),
        ...payload,
      };
    }
  }
  return payload;
};

export { Clevertap, EVENTS, onEventMiddleware, createPayloadForClevertap };
