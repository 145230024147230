export default {
  title: 'Crece tu negocio sin límites',
  header: ' Aumentar límite',
  limit: 'Ahora cuentas con un límite de depósitos de ',
  limitAmount: '$18,000 MXN cada mes.',
  swap: 'Swap',
  content:
    ' te permite recibir el dinero de tus ventas sin límite y mantener todos los beneficios ',
  cartera: 'de Cartera Clip.',
  nextStep: 'Solo sigue estos pasos:',
  download: 'Descarga la app de ',
  complete: 'Completa el registro',
  questions: 'Preguntas frecuentes',
  read: 'He leído y acepto el ',
  privacy: 'Aviso de Privacidad',
  btn: 'Continuar con Swap',
  useClabe: 'Usar CLABE  y recibir mis ventas cada 24 hrs',
  bankTitle: 'Usar datos Bancarios',
  bankText1: 'Al registrar tus datos bancarios:',
  bankText2: 'El dinero de tus ventas serán depositadas en la nueva CLABE Bancaria.',
  bankText3: 'Se perderán tus beneficios de Cartera Clip.',
  bankText4: 'Esta activación tomará 72 hrs o menos.',
  bankText5: 'Depositaremos el dinero de tus ventas en 24-48 hrs.',
  bankContinue: '¿Estas seguro que quieres continuar?',
  bankContinueButton: 'Continuar',
  bankBackButton: 'Volver',
  bankHrefButton: 'https://payclip.page.link/bank-info',
  benefitsTitle: 'Beneficios Cartera Clip',
  benefitsBody: ['El dinero de tus ventas en menos de 4 horas, todos los días.', 'Sin comisiones, sin saldo mínimo, sin costo por apertura y manejo de cuenta.'],
  benefitsBtn: 'Entendido',
  faqsTitle: 'Preguntas Frecuentes',
  faqs: [
    {
      title: '¿Qué pasa si no aumento mi límite?',
      body: 'Si los depósitos a tu cuenta superan los $18,000 al mes, retendremos la cantidad adicional y la depositaremos en tu cuenta el día 1 del siguiente mes.',
    },
    {
      title: '¿Qué es Swap?',
      body: 'Swap es una app con la que puedes enviar, recibir y gestionar tu dinero desde tu celular, así como solicitar una tarjeta visa sin costo.',
    },
    {
      title: '¿Qué pasará con el dinero que tengo en Cartera Clip?',
      body: 'Podrás acceder a tu dinero como siempre y usar tu Cartera Clip con normalidad.',
    },
    {
      title: '¿En cuánto tiempo comenzaré a recibir mis ganancias en swap?',
      body: 'La validación de tu cuenta en Swap puede llevar hasta 3 días naturales. Después de este tiempo podrás recibir ganancias de inmediato.',
    },
    {
      title: '¿Cuáles son los beneficios de Cartera Clip?',
      body: [
        {
          body: 'Recibir el dinero de tus ventas en menos de 4 horas, todos los días.',
        },
        {
          body: 'Sin comisiones, sin saldo mínimo, sin costo por apertura y manejo de cuenta.',
        },
      ],
    },
    {
      title: '¿Qué tengo que hacer para que mis ventas sean depositadas cada 4 hrs en Swap',
      body: 'Aumenta el monto límite de depósitos al mes. Ve a Cartera Clip, selecciona Mi Cuenta y sigue los pasos para registrarte en Swap.',
    },
  ],
};
