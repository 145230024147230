import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import text from './text';
import { BaseButton } from 'components/common/Buttons';

import styles from './styles';

const CheckMyBalanceTooltip = (props) => {
  const { classes, toggleCheckBalanceTooltip } = props;
  return (
    <div className={classes.root}>
      <Typography variant="title" className={classes.tooltipTitle}>
        {text.title}
      </Typography>
      <Typography variant="body1" className={classes.noMargin}>
        {text.message1}
      </Typography>
      <BaseButton
        onClick={() => toggleCheckBalanceTooltip(false)}
        className={classes.buttonMargin}
      >
        {text.buttonLabel}
      </BaseButton>
    </div>
  );
};

export default withStyles(styles)(CheckMyBalanceTooltip);
