export const styles = (theme) => {
    const rootStyles = {
      arrowUp: {
          color: theme.palette.orange.base,
      },
      arrowDown: {
        color: theme.palette.green.base,
      },
    };
    return { ...rootStyles };
  };
  