const styles = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttons: {
    padding: `0 ${theme.spacing(3)}px
    0 ${theme.spacing(3)}px`,
  },
  title: {
    top: '24px',
    left: '15px',
    width: '328px',
    height: '17px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    fontStyle: 'normal',
    textAlign: 'center',
    fontFamily: 'Barlow',
  },
  subTitle: {
    top: '43px',
    left: '15px',
    width: '328px',
    height: '26px',
    fontSize: '13px',
    lineHeight: '26px',
    fontStyle: 'normal',
    textAlign: 'center',
    marginBottom: '20px',
    fontFamily: 'Barlow',
    fontWeight: 'normal',
  },
  btn: {
    width: '100%',
    marginBottom: 15,
    color: '#0074B7',
    borderWidth: '2px',
    borderColor: '#0074B7',
  },
  subContainer: {
    padding: theme.spacing(3),
    width: `calc(100% - ${theme.spacing(6)}px)`,
    textAlign: 'center',
  },
  subContainerTop: {
    marginTop: theme.spacing(1.75),
  },
  kindOfHelp: {
    fontSize: '13px',
    lineHeight: '25px',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: 'Barlow',
  },
  kindOfHelpSmall: {
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'normal',
    alignItems: 'center',
    fontFamily: 'Barlow',
  },
  color: {
    fontSize: 'bold',
    color: '#0074B7',
    textDecoration: 'underline',
  },
});

export default styles;
