export default {
    yourNewSales: 'Tu nuevo adelanto de ventas',
    newPay: 'Nuevo plazo de pago',
    wayToPay: 'Forma de pago',
    pay: (percent) => `${percent}% de tus ventas diarias`,
    youSale: '¿Cómo queda distribuido tu adelanto de ventas?',
    yourCurrentDebt: 'Monto destinado a liquidar tu deuda actual.',
    yourBankAccount: 'Monto que recibirás en tu cuenta bancaria.',
    fixedCharge: 'Cargo fijo + IVA',
    totaltoPay: 'Total a pagar',
    imInterested: ' Me interesa',
    term: (months) => `${months} ${months > 1 ? 'meses' : 'mes'}`,
    expiration: (expirationDate) =>
    `Disponible hasta ${expirationDate}`,
  };
  
