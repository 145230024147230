export const styles = (theme) => {
  const typographyStyles = {
    typographyRoot: {
      margin: 'auto auto',
      lineHeight: 1.29,
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 400,
      textAlign: 'center',
      color: '#001724',
    },
    typographyTitle: {
      margin: 'auto auto',
      marginBottom: `${theme.spacing.unit * 2}px`,
      fontSize: '21px',
      textAlign: 'center',
      fontWeight: 500,
      lineHeight: 1.17,
      color: '#001724',
      width: '100%',
      [theme.breakpoints.down(460)]: {
        fontSize: `${theme.spacing.unit * 2}px`,
      },
    },
  };
  const imageStyles = {
    image: {
      margin: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 2}px 0`,
    },
    desktopImage: {
      display: 'inline-block',
      width: '100%',
    },
    mobileImage: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block',
        width: '100%',
      },
    },
  };

  return { ...typographyStyles, ...imageStyles };
};
