export const getIsSignedIn = (state) => state.auth.login.active;

export const getPasswordReset = (state) => state.auth.passwordReset;

export const getLoginErrors = (state) => state.auth.login.errors;

export const getLoginErrorCount = (state) => state.auth.login.errors.length;

export const getLatestLoginError = (state) => {
  const count = getLoginErrorCount(state);

  return getLoginErrors(state)[count - 1];
};

export const getIsAccountLocked = (state) => state.auth.login.locked;

export const getLoginUsers = (state) => state.auth.login.users;

export const getPrivacyVersion = (state) => {
  const version = state.auth.registration.privacy.version;

  return version ? version.toString() : null;
};

export const getTermsVersion = (state) => {
  const version = state.auth.registration.terms.version;

  return version ? version.toString() : null;
};

export const getIndustries = (state) => state.auth.registration.industries;

export const getIndustryOther = (state) =>
  state.auth.registration.industryOther;

export const getLatestRegistrationError = (state) => {
  const errors = state.auth.registration.errors;

  return errors.length ? errors[errors.length - 1] : undefined;
};
