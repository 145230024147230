export default {
  validate: 'Envío en proceso',
  addressee: 'Destinatario:',
  clabe: 'Cuenta CLABE',
  bank: 'Banco:',
  concept: 'Concepto:',
  reference: 'Referencia:',
  transferDateConnector: 'a las',
  failed: 'Envío Fallido',
  share: 'Compartir',
  getOut: 'Salir',
  status: 'Consulta el estatus de tu envio en: www.banxico.org.mx/cep',
  tryAgain: 'Volver a intentar',
  money: 'El dinero sigue en tu cuenta.',
  saveContact: 'Guardar Contacto',
  asignAlias: 'Asignar Alias',
  alias: 'Alias',
  aliasHelper: 'Asigna un alias para identificar tu contacto.',
  save: 'Guardar',
  errorNameMessage: 'Debe ser mayor a  3 caracteres',
  success: 'Se guardó el contacto exitosamente.',
};
