export const getPaymentRequestStatus = (state) => state.paymentRequest.status;
export const getPaymentRequestRequest = (state) => state.paymentRequest.requestPayment;
export const isPaymentRequestFormSent = (state) => state.paymentRequest.isFormSent;
export const getRequestPaymentData = (state) => state.paymentRequest.paymentRequest;
export const getPaymentRequestList = (state) => state.paymentRequest.paymentRequestList;
export const getMerchantId = (state) => state.merchant.info.id;
export const paymentRequestLDFeatureFlag = (state) =>
  state.merchant.info.proxy_id
    ? state.merchant.info.proxy_id
    : { proxy_merchant_token: null, parameters: [] };
export const filterList = (state) => state.paymentRequest.filterData;
export const pagination = (state) => state.paymentRequest.pagination;
export const getError = (state) => state.paymentRequest.isValid;
export const link = (state) => state.paymentRequest.requestPaymentId.payment_link;
export const merchantLimit = (state) => state.paymentRequest.merchantLimit;
export const multilinkFlag = state => state.paymentRequest.multilinkFlag;
export const getUser = (state) => state.user.email;
export const getCompleteUser = (state) => state.user;
export const kycNoticeFlag = (state) => state.paymentRequest.kycNoticeFlag;
export const getPublicProfileInfo = (state) => state.paymentRequest.profile;
export const screenToRender = (state) => state.paymentRequest.screenToRender;
