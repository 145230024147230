import React from 'react';
import { withRouter } from 'react-router-dom';
import config from 'config';
/**
 *@param userEmail - We'll send user email to sift as a userId
 */

class SiftScript extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLocationChanged: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ isLocationChanged: true });
    }
  }

  sendUserActivity = () => {
    const { isLocationChanged } = this.state;

    if (isLocationChanged) {
      const { userEmail } = this.props;
      const sessionId = window.localStorage.getItem('ve-session-id');
      const _user_id = userEmail;
      const _session_id = sessionId;
      const _sift = (window._sift = window._sift || []);

      _sift.push(['_setAccount', config('siftAccountkey')]);
      _sift.push(['_setUserId', _user_id]);
      _sift.push(['_setSessionId', _session_id]);
      _sift.push(['_trackPageview']);

      const e = document.createElement('script');
      e.src = 'https://cdn.sift.com/s.js';
      document.body.appendChild(e);

      this.setState({ isLocationChanged: false });
    } else {
      return null;
    }
  };

  render() {
    return <div data-testid="siftScript">{this.sendUserActivity()}</div>;
  }
}

export default withRouter(SiftScript);
