export default {
  title: 'Ya casi terminamos',
  info: 'Es necesario que conozcas lo siguiente: ',
  contract: 'Acepto el %link% con  Cacao. ',
  contractLink: 'Contrato de la Cuenta de Fondos  de Pago Electrónico',
  contractFile: 'Contrato',
  acceptPrivacy: 'He leído y acepto el %link1%',
  privacyLink: 'Aviso de Privacidad',
  conditionsLink: 'Términos y condiciones',
  authorizeGeo:
    'Autorizo compartir con Cacao mi geolocalización y datos personales.',
  authorizeDataIne:
    'Autorizo que mis datos sean validados ante el Registro Nacional de Población (“RENAPO”) ante el Instituto Nacional Electoral (“INE”). ',
  authorizeDataPassport:
    'Autorizo que mis datos sean validados ante el Registro Nacional de Población (“RENAPO”)',
};
