import {
  CATALOG_DELETE_ITEM,
  CATALOG_CREATE_ITEM,
  CATALOG_GET_ITEMS,
  CATALOG_GET_ITEM,
  CATALOG_CREATE_SKU,
  CATALOG_DELETE_SKU,
  CATALOG_UPDATE_ITEM,
  CATALOG_UPDATE_SKU,
  CATALOG_GET_CATEGORIES,
  CATALOG_GET_CATEGORIES_RESET,
  CATALOG_BETA,
  CATALOG_BULK_GET_STATE,
  CATALOG_BULK_UPDATE_STATE,
  CATALOG_BULK_IN_PROCESS,
  CATALOG_CLEAR,
  CATALOG_GET_PUBLISH_INFO,
  CATALOG_SET_PUBLISH_INFO,
  CATALOG_GET_URL,
  CATALOG_SET_URL,
} from './types';
import {
  successResponse,
  requestState,
} from '../../../middleware/apiResolve/reduxUtils';

const initialState = {
  meta: {},
  items: {},
  skus: null,
  rawData: {},
  categories: [],
  currentItem: null,
  currentSku: {},
  catalogBeta: false,
  bulkInProcess: false,
  bulkProcess: {
    status: null,
  },
  publishInfo: null,
  catalogUrl: '',
  proxyMerchantToken: '',
};

const actionHandlers = new Map([
  [successResponse('get', CATALOG_GET_URL), handleCatalogGetUrl],
  [requestState('get', CATALOG_GET_URL), handleCatalogGetUrl],
  [CATALOG_DELETE_ITEM, handleCatalogDeleteItem],
  [CATALOG_CREATE_ITEM, handleCatalogCreateItem],
  [CATALOG_DELETE_SKU, handleCatalogDeleteSku],
  [CATALOG_CREATE_SKU, handleCatalogCreateSku],
  [CATALOG_GET_ITEMS, handleCatalogGetItems],
  [CATALOG_GET_ITEM, handleCatalogGetItem],
  [CATALOG_UPDATE_ITEM, handleCatalogUpdateItem],
  [CATALOG_UPDATE_SKU, handleCatalogUpdateSku],
  [CATALOG_GET_CATEGORIES, handleCatalogGetCategories],
  [CATALOG_GET_CATEGORIES_RESET, handleResetCategories],
  [CATALOG_BETA, handleCatalogBeta],
  [CATALOG_BULK_GET_STATE, handleCatalogBulkGetState],
  [CATALOG_BULK_UPDATE_STATE, handleCatalogBulkUpdateState],
  [CATALOG_BULK_IN_PROCESS, handleCatalogBulkInProcess],
  [CATALOG_CLEAR, handleCatalogClear],
  [CATALOG_GET_PUBLISH_INFO, handleGetPublishInfo],
  [CATALOG_SET_PUBLISH_INFO, handleSetPublishInfo],
  [CATALOG_SET_URL, handleChangeUrl],
]);

export default function Collaborator(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleChangeUrl(state, action) {
  const { url } = action.payload;
  return { ...state, url };
}

function handleGetPublishInfo(state, action) {
  const { data: publishInfo } = action.payload;

  return { ...state, publishInfo };
}

function handleSetPublishInfo(state, action) {
  const { data: publishInfo } = action.payload;

  return { ...state, publishInfo };
}

function handleCatalogDeleteItem(state, action) {
  const { data } = action.payload;

  if (!data) {
    return state;
  }

  return { ...state, currentItem: {} };
}

function handleCatalogCreateItem(state, action) {
  const { data } = action.payload;

  if (!data) {
    return state;
  }

  return { ...state, currentItem: data };
}

function handleCatalogCreateSku(state, action) {
  const { data } = action.payload;

  if (!data) {
    return state;
  }

  return { ...state, currentSku: data };
}

function handleCatalogDeleteSku(state, action) {
  const { data } = action.payload;

  if (!data) {
    return state;
  }

  return { ...state, currentSku: {} };
}

function handleCatalogGetItems(state, action) {
  const { data, meta, rawData } = action.payload;

  if (!data || !meta) {
    return state;
  }

  return { ...state, meta, items: data.items, skus: data.skus, rawData };
}

function handleCatalogGetItem(state, action) {
  const { data } = action.payload;
  if (!data) {
    return state;
  }
  const currentItem = data;
  currentItem.skus = [...currentItem.skus];
  currentItem.skus.forEach((sku) => {
    if (currentItem.defaultSkuId === sku.skuId) {
      sku.isProduct = true;
    }
  });
  return { ...state, currentItem };
}

function handleCatalogUpdateItem(state, action) {
  const { data } = action.payload;

  if (!data) {
    return state;
  }

  return { ...state, currentItem: data };
}

function handleCatalogUpdateSku(state, action) {
  const { data } = action.payload;

  if (!data) {
    return state;
  }

  return { ...state, currentSku: data };
}

function handleCatalogGetCategories(state, action) {
  const { data } = action.payload;
  if (!data) {
    return state;
  }

  return { ...state, categories: data.categories };
}

function handleResetCategories(state) {
  return { ...state, categories: [] };
}

function handleCatalogBeta(state, action) {
  return { ...state, catalogBeta: action.payload };
}

function handleCatalogBulkGetState(state, action) {
  if (!action.payload.logs) {
    return {
      ...state,
      bulkProcess: action.payload,
    };
  }

  return {
    ...state,
    bulkProcess: {
      ...action.payload,
      logs: {
        ...action.payload.logs,
        errors: {
          ...action.payload.logs.errors,
        },
        processed: {
          ...action.payload.logs.processed,
        },
      },
    },
  };
}

function handleCatalogBulkUpdateState(state, action) {
  return { ...state, bulkProcess: action.payload };
}

function handleCatalogBulkInProcess(state, action) {
  return { ...state, bulkInProcess: action.payload };
}

function handleCatalogGetUrl(state, action) {
  return {
    ...state,
    catalogUrl: action?.payload?.data?.data?.slug,
    proxyMerchantToken: action?.payload?.data?.data?.proxy_merchant_token,
  };
}

function handleCatalogClear(state, action) {
  return { ...initialState };
}
