const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'MXN',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const currencyFormatWithoutDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'MXN',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const toCurrencyFormat = (number) => {
  return currencyFormat.format(parseFloat(number)).replace('MX', '');
};

export const toCurrencyFormatWithoutDecimals = (number) => {
  return currencyFormatWithoutDecimals
    .format(parseFloat(number))
    .replace('MX', '');
};

export const toCurrency = function (number) {
  if (!number) return '$0.00';

  // Remove '$'' if it exists
  let currency = number.toString();
  currency = currency.replace('$', '');

  // Ensure number is a number
  currency = Number(currency).toFixed(2);

  return `$${currency}`;
};

export const toNegativeCurrency = function (number) {
  if (!number) return '$0.00';

  // Remove '$' and '-' if they exist
  let currency = Math.abs(number).toString();
  currency = currency.replace('$', '');

  // Ensure currency is a number
  currency = Number(currency).toFixed(2);

  if (currency > 0) {
    return `($${currency})`;
  } else {
    return `$${currency}`;
  }
};
