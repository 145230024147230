import axios from 'axios';

import { app } from 'config/config';
import { getRequestOptions } from 'helpers/fetchUtil';
import getText from '../../../actions/Actions.text';
import apiPaths from 'helpers/apiPaths';
import logger from 'helpers/logger';
import {
  CLEAR_PROMO_STATE,
  SET_EXISTING_PROMO,
  SET_PROMO_NAME,
  PROMO_CODE_ATTEMPT,
  REDEEM_CODE_SUCCESS,
  EXISTING_PROMO_FAIL,
  REDEEM_CODE_FAIL,
} from './types';

const text = getText().errors;

/* Clears the promo redux state */
export function clearPromoCode() {
  return (dispatch) => {
    dispatch({ type: CLEAR_PROMO_STATE });
  };
}

/* Updates the promo name in redux state */
export function setPromoName(promoName) {
  return {
    type: SET_PROMO_NAME,
    payload: promoName,
  };
}

/* Checks if a merchant is already in a promotion */
export function checkIfMerchantHasPromo() {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.hasPromo}`;

    return axios
      .get(url, options)
      .then((response) => {
        if (response.data.length) {
          dispatch({ type: SET_EXISTING_PROMO, payload: response.data[0] });
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        logger.error('error checking if merchant exists: ', err);
        dispatch({ type: EXISTING_PROMO_FAIL, payload: text.tryAgain });
        throw err;
      });
  };
}

/* Checks if a promo code is valid (available for redemption) */
export function checkPromoCode(promoCode) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.isPromoCode}${promoCode}`;
    return axios
      .get(url, options)
      .then((response) => {
        dispatch({ type: PROMO_CODE_ATTEMPT, payload: response.data });
        return response.data;
      })
      .catch((err) => {
        logger.error('error checking promo code: ', err);
        throw err;
      });
  };
}

/* Adds a promo code to the background process so it can be redeemed later */
export function storePromoCode(promoCode) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.storePromoCode}`;
    const request = axios.create(options);
    const body = { promo_code: promoCode };

    return request
      .post(url, body)
      .then((response) => {
        if (!response.data.status.error) {
          // Promo code presisted to backend, good to clear the promo redux state
          dispatch({ type: CLEAR_PROMO_STATE });
        }
      })
      .catch((err) => {
        // Not currently showing this error to the user
        logger.error('error storing promo code: ', err.response);
      });
  };
}

/* Adds a promotion to a merchant's account */
export function redeemPromoCode(promoCode) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.redeemPromoCode}`;
    const request = axios.create(options);
    const body = { merchant_promo_code: promoCode };

    return request
      .post(url, body)
      .then((response) => {
        const promo = response.data.redeemed;
        const payload = {
          promoCode: promo.promo_code,
          name: promo.internal_promo_code,
        };
        dispatch({ type: REDEEM_CODE_SUCCESS, payload: payload });
      })
      .catch((err) => {
        logger.error('error redeeming promo code: ', err.response);
        dispatch({ type: REDEEM_CODE_FAIL, payload: text.tryAgain });
      });
  };
}
