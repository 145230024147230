import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import urls from 'helpers/urls';
import { toCurrencyFormat } from 'helpers/formatCurrency';
import {
  getLoans,
  getPrestaClipNameChange,
} from 'components/loans/redux/selectors';
import * as Styled from './styled';
import text from './text';
import { getLoans as loadLoans } from 'components/loans/redux/actions';
import { getProviderCodeForURL } from 'helpers/getProviderUrl';
import getUrl from 'helpers/getUrl';
import { Grid } from '@material-ui/core';
import EmailIcon from 'assets/images/loans/icon-bullet_mail.png';
import WhatsAppIcon from 'assets/images/loans/icon-bullet_whatsapp.png';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Clevertap, EVENTS } from 'helpers/Clevertap';
import { lenderPayload } from '../Clevertap';

const dateTimeFormat = new Intl.DateTimeFormat('es', {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
  timeZone: 'UTC',
});

class AdvancePayment extends Component {
  state = { activeLoan: null };

  static propTypes = {
    loans: PropTypes.array.isRequired,
    loadLoans: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.loadLoans();

    const { provider_code, merchant_token: proxy_merchant_token, installment_type } = this.props.loans[0] || {} ;
    const approvalSelected = { provider_code: provider_code, proxy_merchant_token: proxy_merchant_token }
    const showCashAdvance = this.props.showCashAdvance;
    const monthlyCapCheck = installment_type === 'FIXED';
    Clevertap.event(
      EVENTS.LOANS.ADVANCED_PAYMENT_VIEWED,
      null,
      lenderPayload(approvalSelected, {
        showCashAdvance,
      }, {
        type: monthlyCapCheck ? 'MCAP' : 'regular',
      }),
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const activeLoan = this.findActiveLoan(prevProps.loans);
    if (activeLoan !== prevState.activeLoan) {
      if (activeLoan && this.isInProviderURL(activeLoan.provider_code)) {
        this.setState({ activeLoan: activeLoan });
        return;
      }
      this.props.history.replace(getUrl(`${this.getBaseLocation()}${urls.loans}`));
    }
  }

  getBaseLocation = () => (this.isMobile() ? urls.mobile : '');

  isMobile = () =>
    this.props.location.pathname.startsWith(urls.mobile);

  findActiveLoan(loans) {
    return loans.find((loan) => loan.status === 'ACTIVE');
  }

  // Need a better name for this function
  isInProviderURL(loanProviderCode) {
    const currentPathName = this.props.location.pathname;
    return currentPathName.includes(getProviderCodeForURL(loanProviderCode));
  }

  getEmailComponent = () => {
    if (this.isMobile()) {
      return <span>{urls.clipHelpEmail}</span>;
    } else {
      return (
        <Styled.InfoLink
          style={{ textDecoration: 'underline' }}
          href={`mailto:${urls.clipHelpEmail}`}
          target="_blank"
          rel="noreferrer"
        >
          {urls.clipHelpEmail}
        </Styled.InfoLink>
      );
    }
  };

  getWhatsAppComponent = () => {
    if (this.isMobile()) {
      return <span>{text.whatsAppNumber}</span>;
    } else {
      return (
        <Styled.InfoLink href={urls.clipPhone} target="_blank" rel="noreferrer">
          {text.whatsAppNumber}
        </Styled.InfoLink>
      );
    }
  };

  render() {
    const { prestaClipNameChange } = this.props;
    const installment_type = this.props.loans[0]?.installment_type;
    const installment = this.props.loans[0]?.installment;
    // We only display data for the first active loan
    const activeLoan = this.state.activeLoan;
    const monthlyCapCheck = installment_type === 'FIXED';
    const monthlyPayment = installment?.current_month_balance;
    return (
      activeLoan && (
        <Styled.Container className={this.isMobile() ? 'mobile' : ''}>
          <Styled.LinkContainer>
            <Styled.Breadcrumb
              to={`${this.getBaseLocation()}${
                prestaClipNameChange
                  ? urls.loansMyPrestaClip
                  : urls.loansMyCrediClip
              }`}
            >
              {prestaClipNameChange
                ? text.link.replace('Créditos', 'Préstamos')
                : text.link}
            </Styled.Breadcrumb>
            <Styled.BackLinkIcon />
            <Styled.Breadcrumb className={'actual'}>
              {text.title}
            </Styled.Breadcrumb>
          </Styled.LinkContainer>
          <Styled.Title>{text.title}</Styled.Title>
          <Grid container>
            <Grid item xs="7">
              <Styled.DataTitle>
                {monthlyCapCheck ? text.endDateMonthlyCap : text.endDate}
                <br />
              </Styled.DataTitle>
              <Styled.EndDateValue>
                {dateTimeFormat.format(new Date(monthlyCapCheck ? 
                  installment?.expiration_date : activeLoan.end_date_local))}
              </Styled.EndDateValue>
            </Grid>
            {!monthlyCapCheck && (
              <Grid item xs="12" md="5" style={{ textAlign: 'right' }}>
                <Styled.DataTitle>
                  {text.totalPending}
                  <br />
                </Styled.DataTitle>
                <Styled.TotalPendingValue>
                  {toCurrencyFormat(activeLoan.total_pending)}
                </Styled.TotalPendingValue>
              </Grid>
            )}
            {monthlyCapCheck && (
              <Grid item xs="12" md="5"  style={{ textAlign: 'right' }}>
                <Styled.MonthlyPendingPayment>
                  <Styled.DataTitleMonthly>
                    {text.totalPendingMonth}
                    <br />
                  </Styled.DataTitleMonthly>
                  <Styled.TotalPendingValueMonthlyFirst>
                    {toCurrencyFormat(monthlyPayment)}
                  </Styled.TotalPendingValueMonthlyFirst>
                </Styled.MonthlyPendingPayment>
                <Styled.MonthlyPendingPayment>
                  <Styled.DataTitleMonthlySecond>
                    {text.totalPendingTotal}
                    <br />
                  </Styled.DataTitleMonthlySecond>
                  <Styled.TotalPendingValueMonthly>
                    {toCurrencyFormat(activeLoan.total_pending)}
                  </Styled.TotalPendingValueMonthly>
                </Styled.MonthlyPendingPayment>
              </Grid>
            )}
          </Grid>
          <Styled.HorizontalDivisor />
          <Styled.SubTitle>{text.instuctions}</Styled.SubTitle>
          <Styled.Content>
            <Styled.LeftMessageContent>
              <ReactMarkdown
                source={text.content[activeLoan.provider_code.toLowerCase()]}
              />
            </Styled.LeftMessageContent>
          </Styled.Content>
          <Styled.SubTitle>{text.moreInfo}</Styled.SubTitle>
          <p>
            <Styled.InfoIcon src={WhatsAppIcon} />
            <Styled.InfoText>
              {text.whatsApp} {this.getWhatsAppComponent()}
            </Styled.InfoText>
          </p>
          <p>
            <Styled.InfoIcon src={EmailIcon} />
            <Styled.InfoText>
              {text.writeUs} {this.getEmailComponent()}
            </Styled.InfoText>
          </p>
        </Styled.Container>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loans: getLoans(state),
    prestaClipNameChange: getPrestaClipNameChange(state),
    showCashAdvance: state.loans.showCashAdvance,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    loadLoans,
  })(AdvancePayment)
);
