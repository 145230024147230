const styles = (theme) => {
  return {
    root: {
      width: '100%',
      maxWidth: '90%',
      minHeight: '600px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: 'white',
      overflow: 'auto',
      paddingTop: '4rem',
      boxSizing: 'border-box',
    },
    form: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontWeight: 'normal',
      textAlign: 'left',
      fontSize: '24px',
      width: '100%',
      lineHeight: '1.5rem',
      boxSizing: 'border-box',
      marginTop: '0',
      padding: '0 0 0 0',
      marginBottom: '0.5rem',
    },
    headline: {
      width: '100%',
      textAlign: 'left',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '0 0 0 0',
      marginBottom: '1rem',
      color: theme.palette.naranja.greyBrown,
    },
    helperText: {
      textAlign: 'left',
      width: '100%',
      fontSize: '16px',
      color: theme.palette.naranja.greyBrown,
    },
    whiteButton: {
      margin: '1rem 0rem',
      width: '100%',
      textTransform: 'none',
      color: theme.palette.naranja.bloodOrange,
      border: `solid 1px ${theme.palette.naranja.bloodOrange}`,
      backgroundColor: 'white',
      '&:hover': {
        color: theme.palette.naranja.bloodOrange,
        backgroundColor: 'white',
        border: `solid 1px ${theme.palette.naranja.bloodOrange}`,
      },
    },
    buttonContainer: {
      width: '100%',
      height: '100%',
      marginTop: '50%',
    },
    continueButton: {
      position: 'fixed',
      bottom: '1.5rem',
      width: `calc(100vw - ${theme.spacing.unit * 2}px)`,
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    createButton: {
      margin: 'auto',
      marginBottom: '1.5rem',
      marginTop: '24px',
    },
    halfContinueButton: {
      position: 'fixed',
      bottom: '1.5rem',
      right: '1rem',
      width: `calc((100vw - ${theme.spacing.unit * 2}px) * 0.48)`,
    },
    halfRetryButton: {
      position: 'fixed',
      bottom: '1.5rem',
      left: '1rem',
      width: `calc((100vw - ${theme.spacing.unit * 2}px) * 0.48)`,
      color: theme.palette.naranja.bloodOrange,
      border: `solid 1px ${theme.palette.naranja.bloodOrange}`,
      backgroundColor: 'white',
      '&:hover': {
        color: theme.palette.naranja.bloodOrange,
        backgroundColor: 'white',
        border: `solid 1px ${theme.palette.naranja.bloodOrange}`,
      },
    },
    inePicture: {
      width: '100%',
      maxHeight: '40vh',
      objectFit: 'contain',
      marginTop: '2rem',
    },
    termsPicture: {
      width: '6.25rem',
      objectFit: 'contain',
      marginTop: '4rem',
    },
    list: {
      marginTop: '0',
    },
    noBottomMargin: {
      marginBottom: '0',
      color: theme.palette.naranja.greyBrown,
      fontSize: '16px',
    },
    lottieAnimation: {
      marginTop: '2rem',
    },
    continueText: {
      fontSize: '24px',
      fontWeight: 'normal',
      textAlign: 'left',
      lineHeight: '1.33',
    },
    continueLinks: {
      color: theme.palette.naranja.bloodOrange,
      textDecoration: 'underline',
    },
    termsTextTop: {
      fontSize: '16px',
      fontWeight: 'normal',
      textAlign: 'left',
      lineHeight: '1.33',
      padding: '0 0 0 0',
      marginTop: '1rem',
      marginBottom: '1rem',
      whiteSpace: 'pre-line',
    },
    listMargins: {
      marginTop: '0',
      paddingTop: '1rem',
    },
    contractIframe: {
      width: '100vw',
      height: 'calc(100vh - 100px)',
    },
    contractPictures: {
      width: '100%',
      '& img': {
        width: '100%',
      },
    },
  };
};

export default styles;
