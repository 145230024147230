import React from 'react';
import PropTypes from 'prop-types';

import noTransactions from '../../../../assets/images/berlin/noTransactions.svg';

import { ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { styles } from './styles';
import text from './text';

const useStyles = makeStyles(styles);
const NoTransactions = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.NoTransactionsContainer}>
      <ClipTypography variant={'body1'} component={'p'} align={'center'}>
        {text.advice}
      </ClipTypography>
      <div className={classes.NoTransactionsImageContainer}>
        <img
          src={noTransactions}
          alt={text.advice}
          className={classes.NoTransactionsImage}
        />
      </div>
    </div>
  );
};

NoTransactions.propTypes = {
  classes: PropTypes.object.isRequired,
};

NoTransactions.default = {
  classes: {},
};

export default NoTransactions;
