const styles = (theme) => {
  return {
    container: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '1fr 4fr 1fr',
      marginTop: theme.spacing(2),
    },
    barContainer: {
      justifySelf: 'center',
      backgroundColor: theme.palette.indigo.l90,
      width: '45vw',
      height: '5px',
      borderRadius: '2px',
    },
    barContainerV2: {
      justifySelf: 'center',
      width: '50vw',
      height: '5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: '2px',
    },
    barProgress: (props) => ({
      backgroundColor: theme.palette.teal.base,
      width: `${(props.progress / props.total) * 100}%`,
      height: '5px',
      borderRadius: '2px',
    }),
    barProgressV2: (props) => ({
      backgroundColor: theme.palette.indigo.l90,
      width: `${(90 / props.total)}%`,
      height: '5px',
      borderRadius: '2px',
      float: 'left',
    }),
    active: {
      backgroundColor: `${theme.palette.orange.base}!important`,
    },
    iconButton: {
      padding: 0,
    },
    backIcon: {
      color: theme.palette.orange.base,
    },
    closeIcon: {
      color: theme.palette.indigo.base,
    },
  };
};
export default styles;
