import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import styles from './styles';

const Button = ({ children, ...props }) => (
  <MuiButton {...props}>{children}</MuiButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
};

Button.defaultProps = {
  fullWidth: true,
  variant: 'raised',
  color: 'primary',
};

export default withStyles(styles, {
  name: 'ClipButton',
})(Button);
