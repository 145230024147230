import { loansProviderCodes, showCreditHistory } from 'constants/ProviderCodes';

export default {
  isChangeFormProps(prevProps, props) {
    if (
      prevProps.firstName !== props.firstName ||
      prevProps.lastName !== props.lastName ||
      prevProps.secondLastName !== props.secondLastName ||
      prevProps.year !== props.year ||
      prevProps.month !== props.month ||
      prevProps.day !== props.day ||
      prevProps.gender !== props.gender ||
      prevProps.maritalStatus !== props.maritalStatus ||
      prevProps.hasCreditCard !== props.hasCreditCard ||
      prevProps.hasMortgageCredit !== props.hasMortgageCredit ||
      prevProps.hasVehicleCredit !== props.hasVehicleCredit ||
      prevProps.lastCreditCardNumbers !== props.lastCreditCardNumbers ||
      prevProps.postalCode !== props.postalCode ||
      prevProps.colony !== props.colony ||
      prevProps.municipality !== props.municipality ||
      prevProps.state !== props.state ||
      prevProps.street !== props.street ||
      prevProps.streetNumber !== props.streetNumber ||
      prevProps.optionalStreetNumber !== props.optionalStreetNumber ||
      prevProps.addressYear !== props.addressYear ||
      prevProps.addressMonth !== props.addressMonth ||
      prevProps.ine !== props.ine ||
      prevProps.rfc !== props.rfc ||
      prevProps.curp !== props.curp ||
      prevProps.terms !== props.terms ||
      prevProps.identityDocumentType !== props.identityDocumentType ||
      prevProps.identityFiles !== props.identityFiles ||
      prevProps.identityFilesBack !== props.identityFilesBack ||
      prevProps.passportFiles !== props.passportFiles ||
      prevProps.identityDocumentType !== props.identityDocumentType ||
      prevProps.addressProofFiles !== props.addressProofFiles
    ) {
      return true;
    } else {
      return false;
    }
  },

  countAddressInput(props) {
    let count = 0;
    if (props.postalCode === undefined) {
      count++;
    }
    if (props.colony === undefined) {
      count++;
    }
    if (props.municipality === undefined) {
      count++;
    }
    if (props.street === undefined) {
      count++;
    }
    if (props.streetNumber === undefined) {
      count++;
    }
    return count;
  },

  countEmptyPersonalInformationInput(props, providerCode) {
    let count = 0;
    if (providerCode !== loansProviderCodes.APH) {
      if (props.year === undefined) {
        count++;
      }
      if (props.month === undefined) {
        count++;
      }
      if (props.day === undefined) {
        count++;
      }
    }
    if (props.maritalStatus === undefined) {
      count++;
    }
    if (props.gender === undefined) {
      count++;
    }
    if (showCreditHistory(providerCode)) {
      if (props.hasCreditCard === undefined) {
        count++;
      }
      if (props.hasMortgageCredit === undefined) {
        count++;
      }
      if (props.hasVehicleCredit === undefined) {
        count++;
      }
    }
    if (props.lastCreditCardNumbers === undefined) {
      count++;
    }
    return count;
  },

  countDocumentationInput(props) {
    let count = 0;
    if (props.rfc === undefined) {
      count++;
    }
    if (props.terms === undefined) {
      count++;
    }
    return count;
  },
};
