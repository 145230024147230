import logger from 'helpers/logger';
import {
  setCardBalance,
  setCardMovements,
  setBalanceLoadingState,
  setMovementsLoadingState,
  setColonies,
  setLdFlags,
  setContacts,
} from './berlinActions';
import BerlinService from '../service';
import axios from 'axios';
import { change as updateFormField, updateSyncErrors } from 'redux-form';

import { app } from 'config/config';
import apiPaths from 'helpers/apiPaths';
import { getRequestOptions } from 'helpers/fetchUtil';
import text from './text';

export const getBalanceFromService = (account) => (dispatch) => {
  dispatch(setBalanceLoadingState(true));
  if (account.proxy_account_id) {
    BerlinService.getCardBalanceV2(account.proxy_account_id)
      .then((response) => {
        dispatch(setCardBalance(response.data.message.balance));
        dispatch(setBalanceLoadingState(false));
      })
      .catch((error) => {
        logger.info('Error retrieving card balance');
        dispatch(setCardBalance(0.0));
      });
  } else {
    BerlinService.getCardBalance(
      account.card_representation[0].proxy_card_id
        ? account.card_representation[0].proxy_card_id
        : account.card_representation[0].proxy_card,
    )
      .then((response) => {
        dispatch(setCardBalance(response.data.message.balance));
        dispatch(setBalanceLoadingState(false));
      })
      .catch((error) => {
        logger.info('Error retrieving card balance');
        dispatch(setCardBalance(0.0));
      });
  }
};

export const getMovementsFromService =
  (account, start_date, end_date) => (dispatch) => {
    dispatch(setMovementsLoadingState(true));
    if (account.account_holder_representation?.user_external_id) {
      BerlinService.getSwapCardTransactions(
        account.account_holder_representation.user_external_id,
        start_date,
        end_date,
      )
        .then((response) => {
          dispatch(setCardMovements(response.data.data));
          dispatch(setMovementsLoadingState(false));
        })
        .catch((err) => {
          logger.info('Error retrieving card movements');
          dispatch(setCardMovements([]));
        });
    } else if (account.proxy_account_id) {
      BerlinService.getCardTransactionsV2(
        account.proxy_account_id,
        start_date,
        end_date,
      )
        .then((response) => {
          dispatch(setCardMovements(response.data.message));
          dispatch(setMovementsLoadingState(false));
        })
        .catch((err) => {
          logger.info('Error retrieving card movements');
          dispatch(setCardMovements([]));
        });
    } else {
      BerlinService.getCardTransactions(
        account.card_representation[0].proxy_card_id
          ? account.card_representation[0].proxy_card_id
          : account.card_representation[0].proxy_card,
        start_date,
        end_date,
      )
        .then((response) => {
          dispatch(setCardMovements(response.data.message));
          dispatch(setMovementsLoadingState(false));
        })
        .catch((err) => {
          logger.info('Error retrieving card movements');
          dispatch(setCardMovements([]));
        });
    }
  };

export const uploadIDPictures = (files, uploadUrls, type) => {
  const promises = [];

  files.forEach((file) => {
    switch (file.name) {
      case 'front':
        type === 'INE'
          ? promises.push(
              BerlinService.postDocumentToS3(file.file, uploadUrls.ineFront),
            )
          : promises.push(
              BerlinService.postDocumentToS3(file.file, uploadUrls.pasFront),
            );
        return;
      case 'back':
        promises.push(
          BerlinService.postDocumentToS3(file.file, uploadUrls.ineBack),
        );
        return;
      case 'signature':
        promises.push(
          BerlinService.postDocumentToS3(file.file, uploadUrls.signature),
        );
        return;
      default:
        break;
    }
  });

  return Promise.all(promises);
};

export function getColonies(zipcode, form) {
  return (dispatch) => {
    const options = getRequestOptions();
    const url = `${app.apiUrl}${apiPaths.zipcodes}${zipcode}`;

    return axios
      .get(url, options)
      .then((response) => {
        dispatch(setBerlinColonies(response.data.mexico_zipcodes, form));
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function setBerlinColonies(payload, form) {
  return (dispatch) => {
    const location = {
      zipcode: '',
      municipality: '',
      state: '',
      colonies: [],
      city: '',
    };

    payload.forEach(({ colony }) => {
      location.colonies.push({ id: colony, name: colony });
    });
    if (location.colonies.length > 0) {
      dispatch(setColonies(location.colonies));
      dispatch(updateFormField(form, 'colony', location.colonies[0].id));
      dispatch(updateFormField(form, 'city', payload[0].city));
      dispatch(updateFormField(form, 'municipality', payload[0].municipality));
      dispatch(updateFormField(form, 'state', payload[0].state));
    } else {
      dispatch(updateSyncErrors(form, { postal_code: text.invalid_zipcode }));
    }
  };
}

export function processZipCode(zipcode, form) {
  return (dispatch) => {
    dispatch(setColonies([]));
    dispatch(updateFormField(form, 'colony', ''));
    dispatch(updateFormField(form, 'city', ''));
    dispatch(updateFormField(form, 'municipality', ''));
    dispatch(updateFormField(form, 'state', ''));
    if (zipcode.length === 5) {
      dispatch(getColonies(zipcode, form));
    }
  };
}

export function setContactsFromService(contacts) {
  return (dispatch) => {
    dispatch(setContacts(contacts));
  };
}

export function setLaunchDarklyFlags(flags) {
  return (dispatch) => {
    dispatch(setLdFlags(flags));
  };
}
