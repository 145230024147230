export default {
    mount: 'Monto:',
    maximumTime: 'Plazo',
    monthTerm: 'meses',
    chargeIva: 'Comisión total + IVA',
    subtotalAmount: '$2,256.00',
    retentionRate: 'Retención de tus ventas diarias',
    percentageRate: '20.25% de ventas diarias',
    totalLabel: 'Total a pagar',
    totalAmount: '$108,256.00',
    startRequest: 'Iniciar solicitud',
    amountRequested: 'Monto Solicitado',
    howMuchYouNeed: '¿Cuánto necesitas?',
    amountToPay: 'Monto por pagar',
    minimum: 'Mínimo',
    maximum: 'Máximo',
    paymentSchedule: 'Esquema de pago',
    monthlyPayment: 'Pago mensual aproximado',
    requestBreakdown: 'Desglose de solicitud:',
    changeOffer: 'Cambiar oferta',
    cancel: 'Cancelar',
    exit: 'Salir',
    Retentions: 'Retenciones',
    dayYourLoan: 'A partir del día que recibas tu Presta Clip,',
    dayYourLoan2: 'se descontará automáticamente un porcentaje de tus ventas diarias.',
    dayYourLoan3: 'Te compartimos un ejemplo con $2,000:',
    yourDailySales: 'Tus ventas diarias',
    commissionClip: 'Comisión Clip (3.6% + IVA)',
    yourProfit: 'Tu ganancia',
    prestaClipRetention: (retention) => `Retención Presta Clip en tu ganancia (${retention}%)`,
    youReceive: 'Recibes',
    youHaveDoubts: '¿Tienes dudas?',
    return: 'Volver',
    customerHappines: 'Customer Happiness® te atiende 24/7, todo el año.',
    importantNote: 'Importante: ',
    importantNoteText: 
        'Si no cubres el pago mínimo mediante retenciones, deberás cubrirlo por transferencia ó depósito bancario.',
};