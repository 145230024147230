import React from 'react';
import Proptypes from 'prop-types';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { change } from 'redux-form';
import { ClipTypography, ClipButton } from '@clipmx/clip-storybook';
import { formValueSelector } from 'redux-form';
import { Forms } from '../../../../constants/AppConstants';
import { connect } from 'react-redux';
import {
  Add,
  AccountCircleOutlined,
} from '@clipmx/clip-storybook/dist/components/Icons';
import EmptyState from '../Contacts/EmptyState';
import ContactCard from '../Contacts/ContactCard';
import text from './text';
import { styles } from '../Contacts/styles';

const useStyles = makeStyles(styles);

const ChangeContact = (props) => {
  const {restartFlow, closeDrawer, contacts, alias, change} = props;
  const classes = useStyles(props);

  const handleSelectContact = (e) => {
    change('clabe', e.recipient_account);
    change('name', e.recipient_name);
    change('alias', e.nickname);
    change('bank', e.recipient_bank);
    closeDrawer();
  }

  const renderContacts = () => {
    return(contacts.map(e => e.nickname !== alias && (
      <ContactCard
        onClick={() => handleSelectContact(e)} 
        alias={e.nickname}
        name={e.recipient_name}
        bank={e.recipient_bank}
        id={e.recipient_id}
        clabe={e.recipient_account}/>
    )))
  }

  return (
    <div className={classes.changeContainer}>
      <ClipTypography
        variant={'body1'}
        component={'p'}
        align="center"
        className={classes.actionTitle}
      >
        {text.title}
      </ClipTypography>

      <ClipButton onClick={() => restartFlow()} className={classes.speiOutButton}>
        <Add className={classes.actionButtonIcon} />
        {text.newAccount}
      </ClipButton>
      <div className={classes.contactsTitleContainer}>
        <AccountCircleOutlined className={classes.contactsIcon} />
        <ClipTypography
          variant={'body1'}
          className={classes.contactsTitle}
          component={'p'}
        >
          {contacts.filter(e => e.nickname !== alias).length === 0? text.emptyText : text.savedContacts}
        </ClipTypography>
      </div>
      <div className={classes.bottomContainerDrawer}>
        {contacts.filter(e => e.nickname !== alias).length === 0 ? <EmptyState /> : renderContacts()}
      </div>
    </div>
  );
};

ChangeContact.propTypes = {
  restartFlow: Proptypes.func, 
  Forms: Proptypes.object, 
  closeDrawer: Proptypes.func, 
  contacts: Proptypes.object,
}

const selector = formValueSelector(Forms.BERLIN_SPEI_OUT);

const mapStateToProps = (state) => {
  return {
    contacts: state.berlin.contacts,
    alias: selector(state, 'alias'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: (key, value) =>
      dispatch(change(Forms.BERLIN_SPEI_OUT, key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeContact);
