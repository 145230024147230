export default {
  navigatorTitle: 'Ver NIP',
  title: 'Este es el NIP de tu Clip Card:',
  message: 'Por tu seguridad, lo ocultaremos en ',
  messageEnd: ' segundos.',
  change: '¿Quieres cambiar tu NIP?',
  done: 'Finalizar',
  nextButton: 'Consultar NIP',
  enterPassword: 'Ingresa la contraseña de tu cuenta Clip',
  passwordField: 'Contraseña Clip',
  passwordError: 'Contraseña inválida. Inténtalo de nuevo.',
  errorTitle: 'No eres tú, somos nosotros.',
  errorMessage: 'Por favor, inténtalo de nuevo.',
  serviceName: 'Cartera Clip',
  accountIssued: 'operada por',
};
