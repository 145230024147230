const styles = (theme) => {
  return {
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: '#FFFFFF',
      overflow: 'auto',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: 15,
      boxSizing: 'border-box',
    },
    title: {
      fontSize: '18px',
    },
    message: {
      lineHeight: '20px',
      fontSize: '14px',
      textAlign: 'initial',
    },
    closeButton: {
      width: '100%',
      borderWidth: '2px',
    },
  };
};

export default styles;
