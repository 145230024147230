import styled from '@emotion/styled';

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

export const Container = styled.div({
  width: '100%',
  maxWidth: '540px',
});

export const Title = styled.div(({ theme }) => ({
  width: '100%',
}));

export const TitleContainer = styled.div({
  width: '100%',
});

export const TitleText = styled.h3(({ theme }) => ({
  width: '100%',
  color: theme.palette.naranja.slate,
  fontSize: '24px',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: 'normal',
  display: 'none',
  textAlign: 'center',
  marginTop: 0,
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}));

export const TitleIconContainer = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  marginBottom: '24px',
}));

export const TitleIcon = styled.img({
  width: '162px',
  height: '162px',
  objectFit: 'contain',
});

export const Content = styled.div(({ theme }) => ({
  width: '100%',
  paddingBottom: `${theme.spacing.unit * 4}px`,
  color: theme.palette.naranja.slate,
  fontFamily: theme.typography.fontFamily,
  fontSize: '16px',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: `${theme.spacing.unit * 3}px`,
}));

export const FAQCard = styled.div(({ theme }) => ({
  width: '100%',
  float: 'left',
  borderBottom: `1px solid ${theme.palette.naranja.lightGrey}`,
  '&.margin': {
    marginTop: `${theme.spacing.unit * 3.1}px`,
  },
  '&.selected': {
    backgroundColor: 'rgba(2,136,209,0.03)',
  },
}));

export const SeeMoreText = styled.span(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: theme.typography.fontWeightMedium,
  fontStretch: 'normal',
  fontStyle: 'normal',

  lineHeight: '1.63',
  letterSpacing: 'normal',
  color: '#455a64',

  width: '80%',
  marginLeft: `${theme.spacing.unit * 2}px`,
  marginRight: `${theme.spacing.unit * 2}px`,
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,

  display: 'flex',
  float: 'left',
}));

export const SeeMoreButton = styled.span(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: theme.typography.fontWeightMedium,

  height: '100%',
  cursor: 'pointer',
  color: theme.palette.primary.main,

  marginRight: `${theme.spacing.unit * 2}px`,
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,

  display: 'flex',
  float: 'right',
}));

export const ExpandLess = styled(ExpandLessIcon)({
  color: '#0288d1',
});
export const ExpandMore = styled(ExpandMoreIcon)({
  color: '#0288d1',
});

export const FAQCardContent = styled.div(({ theme }) => ({
  width: '100%',
  float: 'left',
  opacity: 0,
  display: 'none',
  height: 0,
  transition: 'visibility 0s linear 0.33s',
  '&.selected': {
    opacity: 1,
    display: 'block',
    transitionDelay: '0s',
    height: 'auto',
  },
}));

export const FAQCardText = styled.p(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: theme.typography.fontWeightLight,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.38',
  letterSpacing: 'normal',
  paddingLeft: `${theme.spacing.unit * 2}px`,
  paddingRight: `${theme.spacing.unit * 2}px`,
  paddingBottom: `${theme.spacing.unit * 2}px`,
  color: '#455a64',
}));
