import { arrayToHex, base64ToArrayBuffer } from './utils';

let keyObj = null;
let sharedKey = null;

export async function init() {
  if (!keyObj) {
    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: 'ECDH',
        namedCurve: 'P-256',
      },
      true,
      ['deriveKey', 'deriveBits'],
    );

    const publicKeyJwk = await window.crypto.subtle.exportKey(
      'jwk',
      keyPair.publicKey,
    );

    const secretKeyJwk = await window.crypto.subtle.exportKey(
      'jwk',
      keyPair.privateKey,
    );

    const encodedPK = btoa(
      unescape(encodeURIComponent(JSON.stringify(publicKeyJwk))),
    );
    const encodedSK = btoa(
      unescape(encodeURIComponent(JSON.stringify(secretKeyJwk))),
    );

    const iv = window.crypto.getRandomValues(new Uint8Array(12));

    keyObj = {
      publicKey: {
        plain: publicKeyJwk,
        base64: encodedPK,
      },
      secretKey: {
        plain: secretKeyJwk,
        base64: encodedSK,
      },
      iv: {
        arr: iv,
        hex: arrayToHex(iv),
      },
    };
  }
}

export function keys() {
  return keyObj;
}

export async function setServerPublicKey(base64Key) {
  const decodedUserKey = keyObj.secretKey.plain;
  const decodedServerKey = JSON.parse(
    decodeURIComponent(escape(atob(base64Key))),
  );
  // Import user secret key
  const userSecretKey = await window.crypto.subtle.importKey(
    'jwk',
    decodedUserKey,
    {
      name: 'ECDH',
      namedCurve: 'P-256',
    },
    true,
    ['deriveKey', 'deriveBits'],
  );

  // Import public key
  const serverPublicKey = await window.crypto.subtle.importKey(
    'jwk',
    decodedServerKey,
    {
      name: 'ECDH',
      namedCurve: 'P-256',
    },
    true,
    [],
  );

  // Derive shared key
  sharedKey = await window.crypto.subtle.deriveKey(
    {
      name: 'ECDH',
      public: serverPublicKey,
    },
    userSecretKey,
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );
}

export async function decrypt(encryptedData) {
  const buffer = await base64ToArrayBuffer(encryptedData);
  const decryptedData = await window.crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: keyObj.iv.arr,
      tagLength: 128,
    },
    sharedKey,
    buffer,
  );

  return decryptedData;
}

export async function encrypt(decryptedData) {
  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: keyObj.iv.arr,
      tagLength: 128,
    },
    sharedKey,
    decryptedData,
  );
  var base64String = btoa(
    String.fromCharCode.apply(null, new Uint8Array(encryptedData)),
  );

  return base64String;
}

export function generateUuid() {
  return Array.from((window.crypto).getRandomValues(new Uint32Array(4))).map(n => n.toString(16)).join('-');
}