// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {EVENTS, Clevertap} from '../../../helpers/Clevertap';
import ClevertapComponent from '../../../helpers/Clevertap/ClevertapComponent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { withStyles } from '@material-ui/core/styles';
import { store } from 'react-notifications-component';

import text from './text';
import styles from './styles';
import logger from 'helpers/logger';
import BerlinService from '../service';
import CdaHelper from './../CdaHelper';
import BerlinSpinner from '../BerlinSpinner';
import * as Crypto from '../CryptoManager/CryptoManager';
import OneStepNavigator from '../Commons/OneStepNavigator';
import WhatIsANipTooltip from '../PinChange/WhatIsANipTooltip';
import * as CryptoRequests from '../CryptoManager/RequestsCryptoManager';
import { BaseButton } from '../../common/Buttons';
import swapLogo from '../../../assets/images/berlin/swap.svg';
import infoIcon from '../../../assets/images/berlin/infoIcon.svg';
import CacaoTooltip from '../CacaoTooltip';
import { BERLIN_FINANCIAL_SERVICE_PROVIDERS } from '../../../constants/AppConstants';

const ShowPIN = (props) => {
  const {
    classes,
    setActiveScreen,
    account,
    user,
    SCREENS,
    account_holder_id,
    flags: { financialEncryptionAvailableWeb },
    swapPIN,
  } = props;
  const [step, setStep] = useState(0);
  const [pin, setInfo] = useState(null);
  const [seconds, setSeconds] = useState(null);
  const [expireTime, setExpireTime] = useState(null);
  const [tooltips, toggleTooltips] = useState({
    nipTooltip: false,
    passwordTooltip: false,
  });
  const [displaySwapTooltip, setDisplaySwapTooltip] = useState(false);

  useEffect(() => {
    if ((account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.SWAP) && swapPIN) {
      if (!step) {
        setStep(1);
        setInfo(swapPIN.nip.split(''));
        let newTime = new Date();
        newTime.setSeconds(newTime.getSeconds() + 15);
        setExpireTime(newTime);
        setSeconds(
          Math.ceil((newTime.getTime() - new Date().getTime()) / 1000),
        );
      } else {
        let interval = setInterval(() => {
          if (pin) {
            setSeconds(
              Math.ceil((expireTime.getTime() - new Date().getTime()) / 1000),
            );
          }
        }, 1000);
  
        if (seconds <= 0) {
          setExpireTime(null);
          clearInterval(interval);
          stepBack(0);
          toggleTooltips({ ...tooltips, nipTooltip: false });
          setActiveScreen(SCREENS.ACCOUNT_INFO);
        }
        return () => clearInterval(interval);
      }
    } else {
      if (step === 1) {
        let interval = setInterval(() => {
          if (pin) {
            setSeconds(
              Math.ceil((expireTime.getTime() - new Date().getTime()) / 1000),
            );
          }
        }, 1000);
  
        if (seconds <= 0) {
          setExpireTime(null);
          clearInterval(interval);
          stepBack(0);
          toggleTooltips({ ...tooltips, nipTooltip: false });
        }
        return () => clearInterval(interval);
      }
    }    
  }, [seconds, pin]);

  const getPIN = async () => {
    try {
      let result;
      Clevertap.event(
        EVENTS.BERLIN.CARD.EVENT,
        EVENTS.BERLIN.CARD.ACTIONS.VIEW_NIP_VIEWED,
      );
      if (financialEncryptionAvailableWeb) {
        await Crypto.init();
        const serverKey = await CryptoRequests.GetServerKey(
          account_holder_id,
          Crypto.keys().publicKey.base64,
          Crypto.keys().iv.hex,
        );
        await Crypto.setServerPublicKey(serverKey);
        result = await BerlinService.getPINV3(
          account.proxy_account_id
            ? account.proxy_account_id
            : account.card_representation[0].proxy_card_id
            ? account.card_representation[0].proxy_card_id
            : account.card_representation[0].proxy_card,
        );
        const dencrypted = await Crypto.decrypt(result.data.message.pin);
        const decoder = new TextDecoder('utf-8');
        const decodedString = decoder.decode(dencrypted);
        let newTime = new Date();
        newTime.setSeconds(newTime.getSeconds() + 15);
        setExpireTime(newTime);
        setSeconds(
          Math.ceil((newTime.getTime() - new Date().getTime()) / 1000),
        );
        setInfo(decodedString.split(''));
      } else {
        if (account.proxy_account_id) {
          result = await BerlinService.getPINV2(account.proxy_account_id);
        } else {
          result = await BerlinService.getPIN(
            account.card_representation[0].proxy_card_id
              ? account.card_representation[0].proxy_card_id
              : account.card_representation[0].proxy_card,
          );
        }
        let newTime = new Date();
        newTime.setSeconds(newTime.getSeconds() + 15);
        setExpireTime(newTime);
        setSeconds(
          Math.ceil((newTime.getTime() - new Date().getTime()) / 1000),
        );
        setInfo(atob(result.data.message.pin).split(''));
      }
    } catch (error) {
      stepBack(0);
      store.addNotification({
        title: text.errorTitle,
        message: text.errorMessage,
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          showIcon: true,
          duration: 0,
        },
      });
      logger.info(error);
    }
  };

  const validateUser = async () => {
    setStep(1);
    await getPIN();
  };

  const stepBack = (arg) => {
    setStep(arg);
    setInfo(null);
  };

  const renderPIN = () => {
    if (pin) {
      return (
        <>
          { (account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.CACAO) &&
          <OneStepNavigator
            title={text.navigatorTitle}
            stepBackFunction={setActiveScreen}
            stepBackTarget={0}
          />
          }
          { (account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.SWAP) &&
          <div className={classes.accountIssuedContainer}>
            <IconButton
                classes={{ root: classes.cacaoTooltip }}
                onClick={() => setDisplaySwapTooltip(true)}
              >
              <img className={classes.helpIcon} src={infoIcon} alt={'ayuda'} />
              </IconButton>
              <div className={classes.serviceNameContainer}>

              <Typography className={classes.serviceName} variant={'caption'}>
                {text.serviceName}
              </Typography>
              <Typography className={classes.accountIssued} variant={'caption'}>
                {text.accountIssued}
              </Typography>
              <img className={classes.cacaoLogo} src={swapLogo} alt={'swap'} />
              </div>
          </div>
          }
          <div className={classes.form}>
            <div className={classes.container}>
              <Typography className={classes.title} variant="title">
                {text.title}
              </Typography>
              <Typography variant="body1">
                {text.message + seconds + text.messageEnd}
              </Typography>
              <div className={classes.digitsContainer}>
                <Typography className={classes.pinDigit} variant="subheading">
                  {pin[0]}
                </Typography>
                <Typography className={classes.pinDigit} variant="subheading">
                  {pin[1]}
                </Typography>
                <Typography className={classes.pinDigit} variant="subheading">
                  {pin[2]}
                </Typography>
                <Typography className={classes.pinDigit} variant="subheading">
                  {pin[3]}
                </Typography>
                <IconButton
                  className={classes.nipTooltip}
                  aria-label="Toggle password visibility"
                  onClick={() =>
                    toggleTooltips({ ...tooltips, nipTooltip: true })
                  }
                >
                  <HelpOutlineIcon color="primary" />
                </IconButton>
              </div>
            </div>
            
            { (account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.CACAO) &&
              <div className={classes.bottomButton}>
              <ClevertapComponent
                renderComponent={<BaseButton />}
                events={[
                  {
                    callbackName: 'onClick',
                    event: EVENTS.BERLIN.CARD.EVENT,
                    action: EVENTS.BERLIN.CARD.ACTIONS.VIEW_NIP_CHANGE_CLICKED,
                  },
                ]}
                className={classes.clearButton}
                onClick={() => setActiveScreen(SCREENS.CHANGE_PIN)}
              >
                {text.change}
              </ClevertapComponent>
              <BaseButton onClick={() => setActiveScreen(SCREENS.BALANCE)}>
                {text.done}
              </BaseButton>
            </div>
            }
          </div>
          <Drawer anchor="bottom" open={tooltips.nipTooltip}>
            <WhatIsANipTooltip
              toggleNipTooltip={(arg) =>
                toggleTooltips({ ...tooltips, nipTooltip: arg })
              }
            />
          </Drawer>
          <Drawer
              anchor={'bottom'}
              open={displaySwapTooltip}
              classes={{ paper: classes.tooltipPaper }}
            >
              <CacaoTooltip toggleTooltip={() => setDisplaySwapTooltip(false)} />
          </Drawer>
        </>
      );
    }
    return <BerlinSpinner />;
  };

  return step === 0 ? (
    <div className={classes.cdaHelper}>
      <CdaHelper
        user={user}
        recoverCda={true}
        merchantId={account.account_holder_id}
        setActiveScreen={() => validateUser()}
      />
    </div>
  ) : (
    renderPIN()
  );
};

ShowPIN.propTypes = {
  classes: PropTypes.object,
  SCREENS: PropTypes.object,
  setActiveScreen: PropTypes.func,
  account: PropTypes.object.isRequired,
  user: PropTypes.object,
};

ShowPIN.defaultProps = {
  classes: {},
  SCREENS: {},
  setActiveScreen: () => {},
  account: {},
  user: {
    email: '',
  },
};

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    user: state.user,
    account_holder_id: state.berlin.account_representation.account_holder_id,
  };
};

export default withLDConsumer()(
  connect(mapStateToProps)(withStyles(styles)(ShowPIN)),
);
