import React from 'react';
import * as Styled from './styled';

const Loader = () => (
        <Styled.LdsRing>
          <div />
          <div />
          <div />
          <div />
        </Styled.LdsRing>
    );

export default Loader;
