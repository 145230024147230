import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import moment from 'moment-timezone';
import clsx from 'clsx';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import HourglassTopIcon from '../../../../assets/images/icons/HourglassTop.svg';
import TopImage from '../../../../assets/images/topImageCrediClip.svg';
import EasyApplyIcon from '../../../../assets/images/easy-apply-icon-2.svg';
import QuickToGetIcon from '../../../../assets/images/fast-to-get-icon-2.svg';
import PayAsSaleIcon from '../../../../assets/images/pay-as-sale-icon-2.svg';
import LoansBanner from '../../../../assets/images/logo-loans.svg';
import urls from '../../../../helpers/urls';
import getUrl, { getProviderCashAdvanceURL } from '../../../../helpers/getUrl';
import { getProviderCodeForURL } from '../../../../helpers/getProviderUrl';
import { toCurrencyFormat } from '../../../../helpers/formatCurrency';
import GTMCrediClipAnalyticsEvents from '../../../../helpers/GTMCrediClipAnalyticsEvents';
import { PreapprovalStatus } from '../../../../constants/AppConstants';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { Clevertap, EVENTS } from '../../../../helpers/Clevertap';
import { savePreapprovalSelected } from '../../redux/actions';
import { getUserName } from '../../redux/selectors';
import { ExternalLenderFooter } from '../Footer';
import LoansLandingV4 from '../LoansLandingV4';
import CashAdvance from '../CashAdvance';
import { SamsClipIcon } from '../../Sams';
import SamsDialog from '../../Sams/Dialog';
import GoogleAnalytics from '../../GoogleAnalytics';
import Grid from '@material-ui/core/Grid';
import { ClipTypography } from '@clipmx/clip-storybook';
import ClipGrid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipPaper from '@clipmx/clip-storybook/dist/components/Paper';
import ClipSlider from '@clipmx/clip-storybook/dist/components/Slider';
import { ProcessButtonv3 as CommonSubmitButton } from 'components/common/Buttons';
import ClipButton from 'components/npm/clip-ui/Button';
import ClockTime from '@clipmx/clip-storybook/dist/components/Icons/ClockTime';
import { commonPayload, amountPayload, lenderPayload } from '../Clevertap';
import useStyles from './styles';
import text from './text';

const decimalFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const decimalFormatWithDecimals = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const LoansLanding = ({
  savePreapprovalSelected,
  merchantId,
  loansFormVersion,
  showCashAdvance,
  userName,
  preapprovals,
  classes,
  flags: { loansMerchantWithSamsclubBenefitsWeb: isElegibleForSams },
  user,
  merchant,
  merchantInfo,
}) => {
  const [showSamsDialog, setShowSamsDialog] = useState(false);
  const [itemAmount, setItemAmount] = useState(false);
  const [changeSlider, setChangeSlider] = useState(0);

  preapprovals.sort((a,b) => Number(a.amount) - Number(b.amount));

  const location = useLocation();
  const history = useHistory();
  const classesStyles = useStyles();

  const monthlyCapCheck = preapprovals.some(preapproval => preapproval.installment_type === 'FIXED');
  const maxValue = (preapprovals.length) * 100;
  const minAmount = preapprovals[0]?.amount;
  const maxAmount = preapprovals[preapprovals.length-1]?.amount;

  const marks = preapprovals.map((preapproval, index) => {
    return { value: (index + 1) * 100 }
  })

  const [sliderValue, setSliderValue] = useState(maxValue);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const amountValue = () => {
    const index = sliderValue/100;

    return formatter.format(preapprovals[index-1]?.amount);
  }

  const preapprovalSelected = preapprovals[(sliderValue/100)-1];
  const formatedMin = formatter.format(minAmount);
  const formatedMax = formatter.format(maxAmount);
  const totalAmount = formatter.format(preapprovalSelected?.payback);
  const retentionRate = Number(preapprovalSelected?.retention_rate);
  const monthlyPayment = formatter.format(preapprovalSelected?.installment?.breakdown?.[0]?.amount);

  useEffect(() => {
    if (merchantInfo.proxy_id && user) {
      Clevertap.profile(user);
    }
  }, [user, merchant]);

  const selectPreapproval = (preapproval, isElegibleForSams) => {
    if (isElegibleForSams) {
      setShowSamsDialog(true);
      setItemAmount(preapproval);
      return;
    }
    const providerCode = preapproval.provider_code;
    const providerCodeForURL = getProviderCodeForURL(providerCode);

    let urlForm = getProviderCashAdvanceURL(
        showCashAdvance,
        providerCodeForURL,
        urls.loansFormIdentityV4CA,
        urls.loansFormIdentityV4,
      );

    savePreapprovalSelected(preapproval);
    GTMCrediClipAnalyticsEvents.pageView(
      preapproval.pre_approval_id,
      merchantId,
      urlForm,
    );
    history.replace(getUrl(`${getBaseLocation()}${urlForm}`));
  };

  const getBaseLocation = () =>
    location.pathname.startsWith(urls.mobile) ? urls.mobile : '';

  const expirationDate = moment(
    preapprovals[0].expiration_date_local,
    'YYYY-MM-DD',
  ).format('L');
  const preapproval_available = preapprovals.filter(
    ({ status }) => PreapprovalStatus.AVAILABLE === status,
  )[0];
  const {
    provider_code: providerCode,
    proxy_merchant_token: proxyMerchantToken,
    provider_code: lender,
  } = preapproval_available;

  useEffect(() => {
    Clevertap.event(
      EVENTS.LOANS.NEW_OFFERS_VIEW,
      null,
      lenderPayload(preapproval_available, {
        showCashAdvance,
      }, {
        type: monthlyCapCheck ? 'MCAP' : 'regular v2',
        offer: 'available',
      }),
    )
  }, [])

  const beginRequest = () => {
    selectPreapproval(preapprovalSelected, isElegibleForSams);
  };

  const cashAdvanceContent = () => {
    return (
      <>
        {showCashAdvance && <CashAdvance />}
        {!showCashAdvance && (
          <Fragment>
            <div className={classesStyles.stepsTitleStyles}>{text.stepsTitle}</div>
            <div className={classesStyles.instructionsStyles}>{text.stepsDescription}</div>
            <div className={classesStyles.stepsContainerStyles}>
              <div className={classesStyles.stepStyles}>
                <div className={classesStyles.stepIconContainerStyles}>
                  <img className={classesStyles.stepIconStyles} src={EasyApplyIcon} />
                </div>
                <span className={classesStyles.stepTitleStyles}>{text.stepOneTitle}</span>
                <span className={classesStyles.stepDescriptionStyles}>{text.stepOneDescription}</span>
              </div>
              <div className={classesStyles.stepStyles}>
                <div className={classesStyles.stepIconContainerStyles}>
                  <img className={classesStyles.stepIconStyles} src={QuickToGetIcon} />
                </div>
                <span className={classesStyles.stepTitleStyles}>{text.stepTwoTitle}</span>
                <span className={classesStyles.stepDescriptionStyles}>{text.stepTwoDescription}</span>
              </div>
              <div className={classesStyles.stepStyles}>
                <div className={classesStyles.stepIconContainerStyles}>
                  <img className={classesStyles.stepIconStyles} src={PayAsSaleIcon} />
                </div>
                <span className={classesStyles.stepTitleStyles}>{text.stepThreeTitle}</span>
                <span className={classesStyles.stepDescriptionStyles}>
                  {text.stepThreeDescription}
                </span>
              </div>
            </div>
          </Fragment>
        )}

        <ExternalLenderFooter />
      </>
    )
  }

  return (
    <GoogleAnalytics>
      <div className={classesStyles.containerStyles}>
        <div className={classesStyles.expireWarningMobileStyles}>
          <ClockTime className={classesStyles.clockTimeStyles} />
          {/* <HourglassTop src={HourglassTopIcon} /> */}
          <div className={classesStyles.expireWarningContentStyles}>
            <ReactMarkdown
              source={`${text.expiration(expirationDate)}`}
            />
          </div>
        </div>
        <div className={classesStyles.titleStyles}>
          <p className={classesStyles.textTitleStyles}>{monthlyCapCheck ? text.titleMcapP1 : text.titleP1}</p>
          <p className={classesStyles.textSubtitleStyles}>{monthlyCapCheck ? text.titleMcapP2 : text.titleP2}</p>
        </div>

        <div className={classesStyles.expireWarningStyles}>
          <ClockTime className={classesStyles.clockTimeStyles} />
          {/* <HourglassTop src={ClockTime} /> */}
          <div className={classesStyles.expireWarningContentStyles}>
            <ReactMarkdown
              source={`${text.expiration(expirationDate)}`}
            />
          </div>
        </div>

        <ClipGrid container spacing={3} className={classesStyles.containerMethods}>
          <ClipGrid item xs={12} sm={12} md={12} lg={12}>
            <ClipPaper
              className={classesStyles.card}
            >
              <ClipGrid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={classesStyles.cardSlider}
              >
                <div
                  className={classesStyles.slider}
                >
                  <ClipGrid item xs={12} sm={12} md={12} lg={12} className={classesStyles.containerLabels}>
                    <ClipTypography
                      variant="h5"
                      fontWeight="500"
                      gutterBottom
                    >
                      {text.loanPersonalized(userName)}
                    </ClipTypography>
                    <div className={classesStyles.firstContainer}>
                      <div className={classesStyles.textSlider}>
                        <div className={classesStyles.sliderAmountLabel}>
                          <ClipTypography
                            className={classesStyles.maxAmount}
                            variant="subtitle2"
                            fontWeight="regular"
                            gutterBottom
                          >
                            {text.mountSlider}
                          </ClipTypography>
                        </div>
                        <div className={classesStyles.sliderMaxAmountLabel}>
                          <ClipTypography
                            className={classesStyles.maxAmount}
                            variant="subtitle2"
                            fontWeight="regular"
                            gutterBottom
                          >
                            {amountValue()}
                          </ClipTypography>
                        </div>
                      </div>
                      <div className={classesStyles.paddingSlider}>
                      <ClipSlider 
                        classes={{rail: classesStyles.sliderRailStyles, 
                          track: classesStyles.sliderTrackStyles, 
                          thumb: classesStyles.sliderThumbStyles, root: classesStyles.sliderRailStyles}}
                          onChangeCommitted={() => setChangeSlider(count => count + 1)}
                          min={0}
                          max={maxValue} 
                          marks={marks}
                          step={null} value={sliderValue} onChange={handleSliderChange} />
                      </div>
                      <div className={clsx(classesStyles.amountContainer, classesStyles.amountFirstContainer)}>
                        <ClipTypography
                          variant="subtitle2"
                          fontWeight="500"
                          gutterBottom
                        >
                          {text.terms}
                        </ClipTypography>
                        <ClipTypography
                          variant="subtitle2"
                          fontWeight="regular"
                          gutterBottom
                        >
                          {preapprovalSelected?.term_maximum}
                        </ClipTypography>
                      </div>
                    </div>
                  </ClipGrid>
                </div>
              </ClipGrid>
              <ClipGrid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className={classesStyles.cardPay}
              >
                <div className={classesStyles.titleLabelContainer}>
                  <ClipTypography
                    className={classesStyles.subtitleSecondContainer}
                    variant="subtitle1"
                    fontWeight="500"
                    gutterBottom
                  >
                    {text.totalAmount}
                  </ClipTypography>
                  <ClipTypography
                    variant="h1"
                    component="p"
                    color="primary"
                    fontWeight="500"
                    gutterBottom
                    classes={{h1: classesStyles.titleStyle}}
                  >
                    {amountValue()}
                  </ClipTypography>
                </div>
                <ClipTypography
                  variant="subtitle2"
                  gutterBottom
                  classes={{subtitle2: classesStyles.cardSubtitle}}
                >
                  {text.loanDetails}
                </ClipTypography>
                <ClipGrid item xs={12} sm={12} md={12}>
                  <div className={classesStyles.amountContainer}>
                    <ClipTypography
                      variant="body2"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {text.total}
                    </ClipTypography>
                    <ClipTypography
                      variant="body2"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {totalAmount}
                    </ClipTypography>
                  </div>
                </ClipGrid>
                <ClipGrid item xs={12} sm={12} md={12}>
                  <div className={classesStyles.amountContainer}>
                    <ClipTypography
                      variant="body2"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {monthlyCapCheck ? text.fixedPaymentMcap : text.monthlySales}
                    </ClipTypography>
                    <ClipTypography
                      variant="body2"
                      fontWeight="regular"
                      gutterBottom
                    >
                      {monthlyCapCheck ? monthlyPayment : `${retentionRate}%`}
                    </ClipTypography>
                  </div>
                </ClipGrid>
                {monthlyCapCheck && (
                  <ClipGrid item xs={12} sm={12} md={12}>
                    <div className={classesStyles.amountContainer}>
                      <ClipTypography
                        variant="caption"
                        fontWeight="regular"
                        color="textSecondary"
                        gutterBottom
                      >
                        {text.fixedPaymentDetailsMcap(retentionRate)}
                      </ClipTypography>
                    </div>
                  </ClipGrid>
                )}
                <ClevertapComponent
                  renderComponent={<ClipButton />}
                  className={classesStyles.buttonMargin}
                  variant="contained"
                  color="primary"
                  events={[
                    {
                      callbackName: 'onClick',
                      event: EVENTS.LOANS.OFFER_SELECTED,
                      payload: lenderPayload(
                        preapprovalSelected,
                        { showCashAdvance, loansFormVersion },
                        { amount: amountValue(), type: monthlyCapCheck ? 'MCAP' : 'regular v2' },
                      ),
                    },
                  ]}
                  onClick={beginRequest}
                >
                  <span>{text.buttonAmmount}</span>
                </ClevertapComponent>
              </ClipGrid>
            </ClipPaper>
          </ClipGrid>
        </ClipGrid>

        {monthlyCapCheck && (
          <div className={classesStyles.videoStyles}>
            <ClipTypography
              variant="h6"
              fontWeight="medium"
              gutterBottom
            >
              {text.videoTitleMcap}
            </ClipTypography>
            <ClipTypography
              align="center"
              variant="body2"
              fontWeight="regular"
              gutterBottom
            >
              {text.videoSubtitleMcap}
            </ClipTypography>
            <iframe className={classesStyles.videoFrameStyles} width="100%" 
              height="315" src="https://www.youtube.com/embed/XMvINcKT-40?rel=0" 
              title="YouTube video player" frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
                gyroscope; picture-in-picture; web-share" 
              allowfullscreen></iframe>
          </div>
        )}
        
        {!monthlyCapCheck && (
          <div className={classesStyles.contentStyles}>
            {cashAdvanceContent()}
          </div>
        )}
      </div>
      {isElegibleForSams && (
        <SamsDialog
          open={showSamsDialog}
          onClose={() => setShowSamsDialog(false)}
          onSuccess={() => selectPreapproval(itemAmount, false)}
          lender={lender.toLowerCase()}
          proxyMerchantToken={proxyMerchantToken}
        />
      )}
    </GoogleAnalytics>
  );
}

const mapStateToProps = (state) => ({
  loansFormVersion: state.loans.loansFormVersion,
  userName: getUserName(state),
  showCashAdvance: state.loans.showCashAdvance,
  user: state.user,
  merchant: state.merchant,
  merchantInfo: state.merchant.info,
});

const mapDispatchToProps = {
  savePreapprovalSelected,
};

const styles = (theme) => ({
  samsContainer: {
    width: '100%',
    marginTop: `${theme.spacing.unit}px`,
    marginBottom: `${theme.spacing.unit * 1}px`,
  },
  samsTitle: {
    fontSize: `${theme.spacing.unit * 1.375}px`,
    lineHeight: `${theme.spacing.unit * 1.65}px`,
    fontWeight: 400,
    textAlign: 'center',
    color: '#001724',
    marginBottom: `${theme.spacing.unit * 0.5}px`,
    width: '100%',
    margin: 'auto',
    '& sup': {
      fontSize: `${theme.spacing.unit * 0.75}px`,
    },
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(withLDConsumer()(withStyles(styles)(LoansLanding)))),
);
