import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

import MaintenanceBannerIcon from '../../../assets/images/berlin/maintenanceBanner.svg';
import {
  ClipTypography,
  ClipThemeProvider,
  theme,
} from '@clipmx/clip-storybook';

import { styles } from './styles';
import text from './text';

export const useStyles = makeStyles(styles);
export const Maintenance = (props) => {
  const { content } = props;
  const classes = useStyles(props);

  return (
    <ClipThemeProvider theme={theme}>
      <div className={classes.container}>
        <img
          className={classes.imageBanner}
          src={MaintenanceBannerIcon}
          alt={text.title}
        />
        <ClipTypography
          classes={{ root: classes.title }}
          variant={'h3'}
          component={'h1'}
          fontWeight={'bold'}
          align={'center'}
        >
          {text.title}
        </ClipTypography>
        <ClipTypography variant={'body1'} component={'p'} align={'center'}>
          {content}
        </ClipTypography>
      </div>
    </ClipThemeProvider>
  );
};

Maintenance.propTypes = {
  classes: PropTypes.object,
  content: PropTypes.string.isRequired,
};

Maintenance.defaultProps = {
  classes: {},
  content: '""',
};

export default Maintenance;
