import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { ClipTypography } from '@clipmx/clip-storybook';
import { BERLIN_CARD_REQUEST_TABS } from '../../../../constants/AppConstants';
import text from './text';
import OrderSteps from './OrderSteps';
import MerchantInfo from './MerchantInfo';
import BerlinSpinner from '../../BerlinSpinner';
import logger from '../../../../helpers/logger';
import WhatsAppModule from '../../WhatsAppModule';
import OneStepNavigator from '../../Commons/OneStepNavigator';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import notDelivered from '../../../../assets/images/berlin/notDelivered.svg';
import shippedTruck from '../../../../assets/images/berlin/shippedTruck.svg';
import deliveredCard from '../../../../assets/images/berlin/deliveredCard.svg';
import clipCardPackage from '../../../../assets/images/berlin/clipCardPackage.svg';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const statuses = {
  PENDING: 'Solicitud completada',
  SHIPPED: 'En camino',
  DELIVERY_EXCEPTION: 'Problemas en la entrega',
  DELIVERED: 'Entregada',
};

const TrackCard = (props) => {
  let history = useHistory();
  const {
    enrollmentInfo: merchantInfo,
    enrollmentStatus: orderStatus,
    setDeliveryViewedEvent,
  } = props;
  const classes = useStyles(props);

  useEffect(() => {
    setDeliveryViewedEvent(statuses[orderStatus.status]);
  }, []);

  const updateTab = () => {
    history.goBack();
  };

  const renderStatusBody = (step) => {
    return (
      <>
        <OrderSteps text={text} step={step} />
        <MerchantInfo
          merchantInfo={merchantInfo}
          orderStatus={orderStatus}
          shipping={step}
          text={text}
        />
      </>
    );
  };

  const renderStatusInfo = () => {
    switch (orderStatus.status) {
      case 'PENDING':
        return (
          <Grid className={classes.root} container spacing={16}>
            <ClipTypography
              variant={'h3'}
              component={'h1'}
              align={'center'}
              fontWeight={'bold'}
            >
              {text.title}
            </ClipTypography>
            <ClipTypography variant={'body1'} align={'center'}>
              {text.message1}
              <br />
              <b>{merchantInfo.email}</b>
              <br />
              {text.message2}
            </ClipTypography>
            <img
              className={classes.boxImg}
              src={clipCardPackage}
              alt={'PENDING'}
            />
            {renderStatusBody(1)}
          </Grid>
        );
      case 'SHIPPED':
        return (
          <Grid className={classes.root} container spacing={16}>
            <ClipTypography
              variant={'h3'}
              component={'h1'}
              align={'center'}
              fontWeight={'bold'}
            >
              {text.shippedTitle}
            </ClipTypography>
            <ClipTypography variant={'body1'} align={'center'}>
              {text.shippedMessage}
            </ClipTypography>
            <img
              className={classes.boxImg}
              src={shippedTruck}
              alt={'SHIPPED'}
            />
            {renderStatusBody(2)}
          </Grid>
        );
      case 'DELIVERY_EXCEPTION':
        return (
          <Grid className={classes.root} container spacing={16}>
            <ClipTypography
              variant={'h3'}
              component={'h1'}
              align={'center'}
              fontWeight={'bold'}
            >
              {text.errorTitle}
            </ClipTypography>
            <ClipTypography variant={'body1'} align={'center'}>
              {text.errorMessage}
            </ClipTypography>
            <img
              className={classes.boxImg}
              src={notDelivered}
              alt={'DELIVERY_EXCEPTION'}
            />
            {renderStatusBody(4)}
          </Grid>
        );
      case 'DELIVERED':
        return (
          <Grid className={classes.root} container spacing={16}>
            <ClipTypography
              variant={'h3'}
              component={'h1'}
              align={'center'}
              fontWeight={'bold'}
            >
              {text.deliveredTitle}
            </ClipTypography>
            <img
              className={classes.boxImg}
              src={deliveredCard}
              alt={'DELIVERED'}
            />
            {renderStatusBody(3)}
          </Grid>
        );
      default:
        logger.info('Card status not valid');
        store.addNotification({
          title: text.rejectedTitle,
          message: text.rejectedMessage,
          type: 'danger',
          insert: 'top',
          container: 'top-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            showIcon: true,
            duration: 0,
          },
        });
        updateTab();
    }
  };

  return merchantInfo.email ? (
    <>
      <div className={classes.subContainer}>
        <OneStepNavigator
          title={text.navigatorTitle}
          stepBackFunction={updateTab}
          stepBackTarget={BERLIN_CARD_REQUEST_TABS.REQUEST_MENU}
        />
        {renderStatusInfo()}
        <WhatsAppModule />
      </div>
    </>
  ) : (
    <BerlinSpinner />
  );
};

TrackCard.propTypes = {
  classes: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  enrollmentData: PropTypes.object.isRequired,
};

TrackCard.defaultProps = {
  classes: {},
  setActiveTab: () => {},
  enrollmentData: {},
};

export default TrackCard;
