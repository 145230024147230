const styles = (theme) => {
  return {
    root: {
      width: '100%',
      maxWidth: '22.5rem',
      height: 'auto',
      alignSelf: 'center',
      backgroundColor: '#FFFFFF',
      overflow: 'auto',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      boxSizing: 'border-box',
    },
    tooltipTitle: {
      marginTop: '24px',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    lineHeight15: {
      lineHeight: '1.5',
      margin: 0,
    },
    noMargin: {
      marginBottom: '1rem',
    },
    noMarginTop: {
      marginTop: 0,
    },
    buttonMargin: {
      marginBottom: '1rem',
      float: 'right',
      width: '100%',
    },
    img: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '24px',
      marginBottom: '16px',
    },
  };
};

export default styles;
