export const SET_CARD_BALANCE = 'GET_CARD_BALANCE';
export const SET_CARD_MOVEMENTS = 'GET_CARD_MOVEMENTS';
export const SET_ACTIVE_CARD = 'SET_ACTIVE_CARD';
export const SET_COLONIES = 'SET_COLONIES';
export const SET_ACCOUNT_REPRESENTATION = 'SET_ACCOUNT_REPRESENTATION';
export const SET_BALANCE_LOADING_STATE = 'SET_BALANCE_LOADING_STATE';
export const SET_MOVEMENTS_LOADING_STATE = 'SET_MOVEMENTS_LOADING_STATE';
export const SET_LD_FLAGS = 'SET_LD_FLAGS';
export const SET_LAST_LOGIN = 'SET_LAST_LOGIN';
export const SET_ACCOUNT_SECTION_VISITED = 'SET_ACCOUNT_SECTION_VISITED';
export const SET_DOCUMENT_TYPE = 'SET_DOCUMENT_TYPE';
export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_IS_LOGGED = 'SET_IS_LOGGED';