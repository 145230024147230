const styles = (theme) => ({
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  container: {
    margin: '1rem',
    marginTop: '42px',
  },
  image: {
    height: '102px',
  },
  imgContainer: {
    textAlign: 'center',
  },
  btnContainer: {
    right: '1rem',
    position: 'fixed',
    bottom: '1rem',
    '& button': {
      marginLeft: '4px',
    },
  },
  info: {
    marginBottom: '30px',
  },
  dialog: {
    textAlign: 'center',
  },
  dialogActions: {
    width: '100%',
  },
});

export default styles;
