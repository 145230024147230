import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';

import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import FormControl from '@clipmx/clip-storybook/dist/components/FormControl';

import { Forms } from '../../../../../constants/AppConstants';
import renderClipInput from '../../../Commons/BerlinInputs/Input';
import { required } from '../../../../../helpers/validate';
import { isInvalidFormField } from '../../../../../helpers/FormUtils';
import { BERLIN_CARD_REQUEST_TABS } from '../../../../../constants/AppConstants';
import BerlinSpinner from '../../../BerlinSpinner';

import { styles } from './styles';
import text from './text';
import logger from '../../../../../helpers/logger';
import { store } from 'react-notifications-component';
import BerlinService from '../../../service';

const useStyles = makeStyles(styles);
const Instructions = (props) => {
  const {
    betweenStreets,
    addressReferences,
    newAddress,
    merchantId,
    user,
    merchantAddress,
    street,
    number,
    interior,
    zipCode,
    colony,
    city,
    municipality,
    state,
    name,
    lastname,
    secondLastname,
    mobile,
    setActiveTab,
    setEnrollmentStatus,
    setEnrollmentInfo,
    setRequestedCard,
    setRequestedEvent,
  } = props;
  const classes = useStyles(props);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loadingServices, setLoadingServices] = useState(false);

  const requestCard = async () => {
    const reference = {
      house: betweenStreets,
      location: addressReferences,
    };
    const person_representation = {
      name: newAddress ? name : user.first_name,
      last_name: newAddress ? lastname : user.last_name,
      second_last_name: newAddress ? secondLastname : user.second_last_name,
      phone_number: newAddress ? mobile : user.mobile,
    };
    const address_representation = {
      street: newAddress ? street : merchantAddress.street,
      external_number: newAddress ? number : merchantAddress.external_number,
      internal_number: newAddress ? interior : merchantAddress.internal_number,
      neighborhood: newAddress ? colony : merchantAddress.neighborhood,
      city: newAddress ? city : merchantAddress.city,
      zip_code: newAddress ? zipCode : merchantAddress.zip_code,
      municipality: newAddress ? municipality : merchantAddress.municipality,
      state: newAddress ? state : merchantAddress.state,
      country: 'MX',
      reference,
    };

    const body = {
      origin: 'FINANCIAL_ACCOUNT',
      account_id: merchantId,
      email: user.email,
      person_representation,
      address_representation,
    };
    try {
      const requestCard = await BerlinService.postRequestCard(body);
      if (requestCard.status === 200) {
        const enrollmentStatus =
          await BerlinService.getMerchantEnrollmentStatus(merchantId);
        setEnrollmentStatus(enrollmentStatus.data.message);

        const enrollmentInfo = await BerlinService.getMerchantEnrollmentInfo(
          merchantId,
        );
        setEnrollmentInfo(enrollmentInfo.data.message);
        setRequestedCard(true);
      }
      setLoadingServices(false);
      updateTab();
    } catch (error) {
      logger.info(error);
      store.addNotification({
        title: text.rejectedTitle,
        message: text.rejectedMessage,
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          showIcon: true,
          duration: 0,
        },
      });
      setLoadingServices(false);
      updateTabOnError();
    }
  };

  const updateTabOnError = () => {
    setActiveTab(BERLIN_CARD_REQUEST_TABS.ADDRESS_INSTRUCTIONS);
  };

  const updateTab = () => {
    setActiveTab(BERLIN_CARD_REQUEST_TABS.TRACK_CARD);
  };

  const enableNextButton = () => {
    let nextStepDisabled = false;

    if (
      isInvalidFormField(betweenStreets) ||
      isInvalidFormField(addressReferences)
    ) {
      nextStepDisabled = true;
    }

    setButtonDisabled(nextStepDisabled);
  };

  useEffect(() => {
    enableNextButton();
  });

  return loadingServices ? (
    <BerlinSpinner />
  ) : (
    <div className={classes.container}>
      <form
        className={classes.formContent}
        onSubmit={() => {
          setRequestedEvent();
          setLoadingServices(true);
          requestCard();
        }}
      >
        <ClipTypography
          variant={'h3'}
          component={'h1'}
          fontWeight={'regular'}
          align={'left'}
          classes={{ h3: classes.typographyTitle }}
        >
          {text.title}
        </ClipTypography>
        <FormControl className={classes.formControl} fullWidth>
          <Field
            name="between_streets"
            component={renderClipInput}
            inputProps={{ maxLength: 30 }}
            type="text"
            label={text.streets}
            validate={[required]}
            onActiveMessage={text.streetsHelp}
            fullWidth
          />
          <Field
            name="address_references"
            component={renderClipInput}
            inputProps={{ maxLength: 30 }}
            type="text"
            label={text.references}
            validate={[required]}
            onActiveMessage={text.referencesHelp}
            fullWidth
          />
        </FormControl>
        <div className={classes.buttonContainer}>
          <ClipButton
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            fullWidth
            disabled={buttonDisabled}
          >
            {text.submitButton}
          </ClipButton>
        </div>
      </form>
    </div>
  );
};

Instructions.propTypes = {
  classes: PropTypes.object,
  setActiveTab: PropTypes.func,
  setEnrollmentStatus: PropTypes.func,
  setEnrollmentInfo: PropTypes.func,
};

Instructions.defaultProps = {
  classes: {},
  setActiveTab: () => {},
  setEnrollmentStatus: () => {},
  setEnrollmentInfo: () => {},
};

export const RequestCardForm = reduxForm({
  form: Forms.CARD_REQUEST,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(Instructions);
const selector = formValueSelector(Forms.CARD_REQUEST);

const mapStateToProps = (state) => {
  return {
    betweenStreets: selector(state, 'between_streets'),
    addressReferences: selector(state, 'address_references'),
    street: selector(state, 'street'),
    number: selector(state, 'number'),
    interior: selector(state, 'interior'),
    zipCode: selector(state, 'zip_code'),
    colony: selector(state, 'colony'),
    city: selector(state, 'city'),
    municipality: selector(state, 'municipality'),
    state: selector(state, 'state'),
    name: selector(state, 'name'),
    lastname: selector(state, 'lastname'),
    secondLastname: selector(state, 'second_lastname'),
    mobile: selector(state, 'mobile'),
  };
};

export default connect(mapStateToProps)(RequestCardForm);
