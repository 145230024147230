import React, {
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { withRouter, useLocation } from 'react-router-dom';

import { ClipTypography, ClipGrid } from '@clipmx/clip-storybook';
import FormControl from '@clipmx/clip-storybook/dist/components/FormControl';
import { cleanLastOneProofOfAddress } from '../../redux/actions';
import {
  getLoansColonies,
  getLoansProofAddressDocuments,
} from '../../redux/selectors';

import { required } from '../../../../helpers/validate';
import utils from '../../../../helpers/utils';
import { Forms, LoansDocuments } from '../../../../constants/AppConstants';
import { showAddressTime } from '../../../../constants/ProviderCodes';
import textPersonalInfo from '../../Commons/PersonalInformation/text';
import AttachAddressProofDocuments from '../../Commons/AttachDocumentsV2/AttachAddressProofDocuments';

import SelectFieldV2 from '../../../../helpers/FormUtils/SelectFieldV2';
import { isInvalidFormField } from '../../../../helpers/FormUtils';
import { Clevertap, EVENTS } from '../../../../helpers/Clevertap';
import { lenderPayload } from '../../Commons/Clevertap';
import ClevertapField from '../../../forms/ClevertapField';
import styles from './styles';
import {
  PrefilledProofAlert,
  ProofOfAddress,
  PrefilledProofAlertContent,
} from '../../../../components/loans/Commons/Form/styled';
import text from './text';
import ProofOfAddressIcon from '../../../../assets/images/icons/ProofOfAddress.svg';

import PropTypes from 'prop-types';

const AddressStep = (
  {
    month: monthSelected,
    year: yearSelected,
    previousLoan,
    addressProofFiles,
    addressYear,
    addressMonth,
    providerCode,
    hasPreviousLoan,
    preapprovalSelected,
    showCashAdvance,
    loansFormVersion,
  },
  ref,
) => {
  const [proofOfAddressRequired, changeProofOfAddressRequired] =
    useState(false);
  const [showAddressInfo, changeShowAddressInfo] = useState(false);
  const [showAddressInfoUpdated, changeShowAddressInfoUpdated] =
    useState(false);

  const classes = styles();
  const getFullYear = (day) => day.getFullYear();
  const yearOptions = utils.getYearOptions();
  const monthOptions = textPersonalInfo.monthsOptions;

  const getMonthNumber = (day) => day.getMonth() + 1;

  const fileUploaded = () => {
    changeProofOfAddressRequired(false);
    Clevertap.event(
      EVENTS.LOANS.PROOF_ADDRESS_UPLOADED,
      'UPLOADED_PROOF_ADDRESS',
      lenderPayload(preapprovalSelected, {
        showCashAdvance,
      }),
    );
  };

  const monthGreaterThanToday = (month, year) => {
    const currentDate = new Date();
    const actualYear = getFullYear(currentDate);
    const actualMonth = getMonthNumber(currentDate);
    return (
      (year === actualYear && parseInt(month) > actualMonth) ||
      (year === parseInt(yearSelected) &&
        parseInt(month) < parseInt(monthSelected))
    );
  };

  const monthGreaterThanTodayValidation = (addressMonth, values) => {
    const { addressYear } = values;
    return monthGreaterThanToday(addressMonth, addressYear)
      ? text.invalidMonth
      : undefined;
  };

  const enableSections = () => {
    if (!showAddressInfoUpdated && addressProofFiles.length > 0) {
      changeShowAddressInfo(true);
      changeShowAddressInfoUpdated(true);
    }
  };

  useImperativeHandle(ref, () => ({
    verifyProofOfAddress() {
      if (addressProofFiles.length === 0) {
        changeProofOfAddressRequired(true);

        return false;
      }

      return true;
    },
  }));

  useEffect(() => {
    enableSections();
  }, [showAddressInfoUpdated, addressProofFiles, addressYear, addressMonth]);

  const hasProofAddressDocuments = addressProofFiles.length > 0;

  return (
    <Fragment>
      <ClipGrid container spacing={2}>
        <div className={classes.StepTitleStyles}>{text.title}</div>
        <div className={classes.StepDescriptionStyles}>{text.description}</div>

      
        <div className={classes.StepSectionStyles}>
          {text.proofOfAddressSection}
        </div>
        <FormControl className={classes.FormControlStyles} fullWidth>
          <AttachAddressProofDocuments
            fieldRequired={proofOfAddressRequired}
            fileUploaded={fileUploaded}
            previousLoan={hasPreviousLoan && previousLoan.documents}
          />
          <div className={classes.ListContainerStyles}>
            {text.proofOfAddressRequirements.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </div>
        </FormControl>

        {showAddressInfo && showAddressTime(providerCode) && (
          <Fragment>
            <div className={classes.StepSectionStyles}>
              {text.addressTimeSection}
            </div>
            <FormControl className={classes.FormControlStyles}>
              <Field
                name="addressYear"
                component={SelectFieldV2}
                label={text.addressTimeYear}
                options={yearOptions.map((year) => ({
                  ...year,
                  disabled:
                    year.id < yearSelected ||
                    (parseInt(year.id) === parseInt(yearSelected) &&
                      parseInt(addressMonth) < parseInt(monthSelected)),
                }))}
                validate={[required]}
              />

              <ClevertapField
                name="addressMonth"
                component={SelectFieldV2}
                label={text.addressTimeMonth}
                options={monthOptions.map((monthSelected) => ({
                  ...monthSelected,
                  disabled: monthGreaterThanToday(
                    monthSelected.id,
                    addressYear,
                  ),
                }))}
                validate={[required, monthGreaterThanTodayValidation]}
                clevertap={{
                  listener: 'onChange',
                  event: EVENTS.LOANS.RESIDENCE_SELECTED,
                  payload: lenderPayload(preapprovalSelected, {
                    showCashAdvance,
                    loansFormVersion,
                  }),
                }}
              />
            </FormControl>
          </Fragment>
        )}
      </ClipGrid>
    </Fragment>
  );
};

const selector = formValueSelector(Forms.MR_PRESTA);

const mapStateToProps = (state) => {
  return {
    addressProofFiles: getLoansProofAddressDocuments(state),
    colonies: getLoansColonies(state),
    year: selector(state, 'year'),
    month: selector(state, 'month'),
    addressYear: selector(state, 'addressYear'),
    addressMonth: selector(state, 'addressMonth'),
    preapprovalSelected: state.loans?.request?.preapproval,
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
  };
};

const mapDispatchToProps = {
  cleanLastOneProofOfAddress,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    forwardRef(AddressStep),
  ),
);

AddressStep.propTypes = {
  cleanLastOneProofOfAddress: PropTypes.func.isRequired,
  previousLoan: PropTypes.object.isRequired,
  hasPreviousLoan: PropTypes.bool,
};