const REACT_APP_SWAP_API_URL = process.env.REACT_APP_SWAP_API_URL;
const REACT_APP_SWAP_X_API_KEY = process.env.REACT_APP_SWAP_X_API_KEY;
const REACT_APP_SWAP_DYNAMIC_TOKEN_KEY = process.env.REACT_APP_SWAP_DYNAMIC_TOKEN_KEY;
const REACT_APP_TANDEM_APPLICATION_ID = process.env.REACT_APP_TANDEM_APPLICATION_ID;
const REACT_APP_TANDEM_REST_API_KEY = process.env.REACT_APP_TANDEM_REST_API_KEY;

export const app = {
  // App
  env: process.env.REACT_APP_ENV,
  signInCookieDomain: process.env.REACT_APP_SIGN_IN_COOKIE_DOMAIN,
  reCaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
  previewDomain: process.env.REACT_APP_PREVIEW_DOMAIN,

  // Google Maps
  googleMapsToken: process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN,

  // Urls
  apiUrl: process.env.REACT_APP_API_URL,
  apiUrlv1: process.env.REACT_APP_API_URL,
  apiUrlv2: process.env.REACT_APP_API_URL_V2,
  apiUrlv3: process.env.REACT_APP_API_URL_V3,
  shopAppClient: process.env.REACT_APP_SHOP_APP_CLIENT,
  xpayLinkUrl: process.env.REACT_APP_CLIP_MX_XPAY_LINK,

  // Google Tag Manager
  gtm: process.env.REACT_APP_GTM,
  gtmAuth: process.env.REACT_APP_GTM_AUTH,
  gtmPreview: process.env.REACT_APP_GTM_PREVIEW,
  gtmDataLayer: process.env.REACT_APP_GTM_DATA_LAYER || 'dataLayer',

  // Launch Darkly
  launchDarklyKey: process.env.REACT_APP_LAUNCH_DARKLY_KEY,
  launchDarklySiftFlagName: process.env.REACT_APP_LAUNCH_DARKLY_SIFT_FLAG_NAME,
  launchDarklyMerchantCatalogOnlinePublish:
    process.env.REACT_APP_LAUNCH_DARKLY_CATALOG_ONLINE_PUBLISH,
  launchDarklyMerchantDeviceManager:
    process.env.REACT_APP_LAUNCH_DARKLY_DEVICE_MANAGER,
  launchDarklyMaintenanceSetter:
    process.env.REACT_APP_LAUNCH_DARKLY_MAINTENANCE_SETTER,
  launchDarklyPaymentRequestFlagName: process.env.REACT_APP_PAYMENTS_LD_KEY,
  launchDarklyPaymentRequestLimits: process.env.REACT_APP_PAYMENTS_LIMIT_LD_KEY,
  launchDarklyPrestaClipFlagName: process.env.REACT_APP_PRESTA_CLIP_LD_KEY,
  launchDarklyFinancialChangepinAvailable:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_CHANGEPIN,
  launchDarklyFinancialSpeiOutAvailable:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_SPEI_OUT_AVAILABLE,
  launchDarklyFinancialRequestClipcardAvailableWeb:
    process.env
      .REACT_APP_LAUNCH_DARKLY_FINANCIAL_REQUEST_CLIPCARD_AVAILABLE_WEB,
  launchDarklyFinancialChooseNipAvailableWeb:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_CHOOSE_NIP_AVAILABLE_WEB,
  launchDarklyFinancialDynamicCvvAvailableWeb:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_DYNAMIC_CVV_AVAILABLE_WEB,
  launchDarklyFinancialCheckPrintedNipAvailableWeb:
    process.env
      .REACT_APP_LAUNCH_DARKLY_FINANCIAL_CHECK_PRINTED_NIP_AVAILABLE_WEB,
  launchDarklyFinancialGeolocationRequiredSpeiOutAvailableWeb:
    process.env
      .REACT_APP_LAUNCH_DARKLY_FINANCIAL_GEOLOCATION_REQUIRED_SPEI_OUT_AVAILABLE_WEB,
  launchDarklyFinancialOnboardingRequestTimeoutWeb:
    process.env
      .REACT_APP_LAUNCH_DARKLY_FINANCIAL_ONBOARDING_REQUEST_TIMEOUT_WEB,
  launchDarklyb2BControlPanelInfo:
    process.env.REACT_APP_LAUNCH_DARKLY_B2B_CONTROL_PANEL_INFO,
  launchDarklyLoansShowCashAdvanceInfo:
    process.env.REACT_APP_LOANS_SHOW_CASH_ADVANCE_INFO,
  launchDarklyLoansFormV3FlagName: process.env.REACT_APP_LOANS_FORM_V3_LD_KEY,
  launchDarklyFinancialCdaAvailableWeb:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_CDA_AVAILABLE_WEB,
  launchDarklyFinancialUploadPhotoV2AvailableWeb:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_UPLOAD_PHOTO_V2_AVAILABLE_WEB,
  launchDarklyFinancialRequestClipCardV2AvailableWeb:
    process.env
      .REACT_APP_LAUNCH_DARKLY_FINANCIAL_REQUEST_CLIPCARD_V2_AVAILABLE_WEB,
  launchDarklyFinancialTandemPrivacyPolicyWeb:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_TANDEM_PRIVACY_POLICY_WEB,
  launchDarklyOpenDataShowFeature: process.env.REACT_APP_OPEN_DATA_SHOW_FEATURE,
  launchDarklyLoansEligibleOpenData:
    process.env.REACT_APP_PRESTA_CLIP_LD_OPEN_DATA_KEY,
  launchDarklyAphSignShowFeature: process.env.REACT_APP_APH_SIGN_SHOW_FEATURE,
  launchDarklySamsClubShowGift: process.env.REACT_APP_LOANS_SHOW_GIFT_SAMS_CLUB,
  launchDarklyFinancialGeneralMaintenanceAvailableWeb:
    process.env
      .REACT_APP_LAUNCH_DARKLY_FINANCIAL_GENERAL_MAINTENANCE_AVAILABLE_WEB,
  launchDarklyFinancialShowOnboardingV2AvailableWeb:
    process.env
      .REACT_APP_LAUNCH_DARKLY_FINANCIAL_SHOW_ONBOARDING_V2_AVAILABLE_WEB,
  launchDarklyFinancialActivateUpgradeN3Web:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_ACTIVATE_UPGRADE_N3_WEB,
  launchDarklyMdBumAccess: process.env.REACT_APP_LAUNCH_DARKLY_MD_BUM_ACCESS,
  launchDarklyFinancialCdaInStatus:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_CDA_IN_STATUS,
  launchDarklyFinancialEncryptionAvailableWeb:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_ENCRYPTION_AVAILABLE_WEB,
  launchDarklyFinancialAccountCreationAvailableWeb:
    process.env
      .REACT_APP_LAUNCH_DARKLY_FINANCIAL_ACCOUNT_CREATION_AVAILABLE_WEB,
  launchDarklyFinancialActivateSwapUpgradeWeb:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_ACTIVATE_SWAP_UPGRADE_WEB,
  launchDarklyFinancialActivateSwapToastUpgradeWeb:
    process.env
      .REACT_APP_LAUNCH_DARKLY_FINANCIAL_ACTIVATE_SWAP_TOAST_UPGRADE_WEB,
  launchDarklyFinancialGeneralScheduledMaintenance:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_GENERAL_SCHEDULED_MAINTENANCE,
  launchDarklyFinancialShowTandemPrivacyPolicyWeb:
    process.env
      .REACT_APP_LAUNCH_DARKLY_FINANCIAL_SHOW_TANDEM_PRIVACY_POLICY_WEB,
  launchDarklyFinancialTandemFaqsWeb:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_TANDEM_FAQS_WEB,
  launchDarklyFinancialFrecuentContactsWeb:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_FRECUENT_CONTACTS_WEB,
  launchDarklyFinancialMigrationWeb:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_MIGRATION_WEB,
  loansMerchantDashboardFormVersionFrontEnd:
    process.env.REACT_APP_LOANS_MERCHANT_DASHBOARD_FORM_VERSION_FRONT_END,
  loansMerchantDashboardRegularOffersVersion:
    process.env.REACT_APP_LOANS_REGULAR_OFFERS_MERCHANT_DASHBOARD,
  launchDarklyMerchantMultilink:
    process.env.REACT_APP_LAUNCH_DARKLY_MERCHANT_MULTILINK,
  launchDarklyXpayPage: process.env.REACT_APP_LAUNCH_DARKLY_XPAY_PAGE,
  launchDarklyMyStore: process.env.REACT_APP_LAUNCH_DARKLY_MY_STORE,
  launchDarklyPublicProfile: process.env.REACT_APP_LAUNCH_DARKLY_PUBLIC_PROFILE,
  launchDarklyOnlineStoreFeature:
    process.env.REACT_APP_LAUNCH_DARKLY_ONLINE_STORE_FEATURE,
  launchDarklySettlementAllowListBackend:
    process.env.REACT_APP_LAUNCH_DARKLY_SETTLEMENT_ALLOW_LIST_BACKEND,
  launchDarklyXoHosted: process.env.REACT_APP_LAUNCH_DARKLY_XO_HOSTED,
  launchDarklyMfeRedesignPilot:
    process.env.REACT_APP_LAUNCH_DARKLY_MFE_REDESIGN_PILOT,
  launchDarklyFinancialShowRequestClipCard:
    process.env.REACT_APP_LAUNCH_DARKLY_FINANCIAL_SHOW_REQUEST_CLIP_CARD,
  // Sift
  siftAccountkey: process.env.REACT_APP_SIFT_ACCOUNT_KEY,

  //Developers Portal
  devPortalURL: process.env.REACT_APP_DEVELOPER_PORTAL_URL,
  devPortalHost: process.env.REACT_APP_DEVELOPER_PORTAL_HOST,
  devPortalTokens: process.env.REACT_APP_MERCHANT_TOKENS,
  devPortalDocSite: process.env.REACT_APP_DEV_PORTAL_DOC,

  // Catalog
  catalogSite: process.env.REACT_APP_CATALOG_SITE,
  bulkTemplates: {
    xls: process.env.REACT_APP_BULK_TEMPLATE_XLS,
    csv: process.env.REACT_APP_BULK_TEMPLATE_CSV,
  },
  bulkFetchInterval: process.env.REACT_APP_BULK_FETCH_INTERVAL || 3000,
  bulkUploadProcessMaxTime:
    process.env.REACT_APP_BULK_UPLOAD_PROCESS_MAX_TIME || 180000,

  // Berlin
  berlinServices: process.env.REACT_APP_BERLIN_API_URL,
  berlinServicesKey: process.env.REACT_APP_BERLIN_API_KEY,
  berlinImagesBucket: process.env.REACT_APP_BERLIN_IMAGES_BUCKET,
  berlinS3User: process.env.REACT_APP_BERLIN_S3_USER,
  berlinS3Key: process.env.REACT_APP_BERLIN_S3_KEY,
  berlinWhatsApp: process.env.REACT_APP_BERLIN_WHATSAPP,
  berlinTandemApplicationId: REACT_APP_TANDEM_APPLICATION_ID,
  berlinTandemRestAPIKey: REACT_APP_TANDEM_REST_API_KEY,
  berlinTandemAPIKey: process.env.REACT_APP_TANDEM_API_KEY,
  berlinTandemEndpoint: process.env.REACT_APP_TANDEM_ENDPOINT,
  berlinDashboardDeeplink: process.env.REACT_APP_BERLIN_DASHBOARD_DEEPLINK,
  
  // Swap
  swapServices: REACT_APP_SWAP_API_URL,
  swapXAPIKey: REACT_APP_SWAP_X_API_KEY,
  swapDynamicTokenKey: REACT_APP_SWAP_DYNAMIC_TOKEN_KEY,

  // Amplify
  amplifyIdentityPoolId: process.env.REACT_APP_AMPLIFY_IDENTITY_POOL,
  amplifyRegion: process.env.REACT_APP_AMPLIFY_REGION,
  amplifyAppId: process.env.REACT_APP_AMPLIFY_APP_ID,

  // My Store
  onlineStoreURL: process.env.REACT_APP_ONLINE_STORE_URL,

  // Micro-frontend dashboard redesign
  mfeContainerDomain: process.env.REACT_APP_MFE_CONTAINER_DOMAIN,
};

export const getConfigValue = (key) => app[key];
