import {
  errorResponse,
  requestState,
  successResponse,
} from '../../../../middleware/apiResolve/reduxUtils';
import { OB_ZIPCODES } from './types';

const initialState = {
  postalCodes: [],
};

const actionHandlers = new Map([
  [successResponse('get', OB_ZIPCODES), handleZipCodes],
  [requestState('get', OB_ZIPCODES), resetZipCodes],
  [errorResponse('get', OB_ZIPCODES), resetZipCodes],
]);

export default function onBoardV2(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleZipCodes(state, action) {
  const {
    payload: {
      data: { mexico_zipcodes },
    },
  } = action;
  return { ...state, postalCodes: mexico_zipcodes };
}

function resetZipCodes(state, action) {
  return { ...state, postalCodes: [] };
}
