import React, { createContext } from 'react';
export const ScreenContext = createContext();
export const SCREENS = {
  BALANCE: 'balance',
  ACCOUNT_INFO: 'account',
  CHANGE_PIN: 'CHANGE_PIN',
  TRANSFERS: 'TRANSFERS',
  TRANSACTION: 'TRANSACTION',
  DYNAMIC_CVV: 'DYNAMIC_CVV',
  SHOW_PIN: 'SHOW_PIN',
  CDA: 'CDA',
  SPINNER: 'SPINNER',
  CLIPCARD: 'clipcard',
  UPGRADE_ACCOUNT: 'UPGRADE_ACCOUNT',
};
