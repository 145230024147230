export default {
  advice: '¡Buen trabajo! ya casi terminas tu solicitud.',
  title: 'Depósito',
  description:
    'A continuación escribe tu RFC y CURP. Estos nos ayudarán a identificarte mejor para realizar tu solicitud.',
  rfcLabel: 'RFC',
  curpLabel: 'CURP',
  confirmSection:
    'Confirma que los datos sean correctos, pues en esta cuenta depositaremos tu Credi Clip.',
  confirmSubsection: 'Si los datos de la CLABE son incorrectos',
  confirmSubsectionRef: 'ponte en contacto con Customer Hapiness.',
  dataAdvice:
    'Si los datos de la CLABE son incorrectos ponte en contacto con Customer Hapiness.',
  dataAdviceLink: 'mailto:help@clip.mx',
  ownerLabel: 'Titular de la cuenta',
  bankLabel: 'Banco',
  clabeLabel: 'CLABE interbancaria',
};
