// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES2 as ROUTES } from '../constants';
import { BERLIN_FINANCIAL_SERVICE_PROVIDERS } from '../../../constants/AppConstants';
import PropTypes from 'prop-types';
import { store } from 'react-notifications-component';
import { setLastLogin, setIsLogged } from '../redux/berlinActions';

import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Drawer from '@material-ui/core/Drawer';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import * as Crypto from '../CryptoManager/CryptoManager';
import * as CryptoUtils from '../CryptoManager/utils';
import * as CryptoRequests from '../CryptoManager/RequestsCryptoManager';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { SubmitButton, BaseButton } from '../../common/Buttons';
import phoneLock from '../../../assets/images/berlin/phoneLock.svg';
import successIcon from '../../../assets/images/berlin/success-icon.svg';
import readyCda from '../../../assets/images/berlin/readyCda.svg';
import {EVENTS, Clevertap} from '../../../helpers/Clevertap';
import clipAccountLogo from '../../../assets/images/berlin/clipAccountLogo.svg';
import swapLogo from '../../../assets/images/berlin/swap.svg';
import clip2swap from '../../../assets/images/berlin/clip2swap.svg';

import OneStepNavigator from '../Commons/OneStepNavigator';
import OnScreenKeypad from '../OnScreenKeypad';
import WhyPasswordTooltip from '../PinChange/WhyPasswordTooltip';
import BerlinService from '../service';
import BerlinSpinner from '../BerlinSpinner';
import PasswordDots from './PasswordDots';

import text from './text';
import logger from 'helpers/logger';
import styles from './styles';

const STEPS = {
  SPINNER: 'SPINNER',
  INFO: 'INFO',
  PASSWORD: 'PASSWORD',
  CREATE_CDA: 'CREATE_CDA',
  CONFIRM_CDA: 'CONFIRM_CDA',
  DONE: 'DONE',
  LOGIN_CDA: 'LOGIN_CDA',
  RECOVER_CDA: 'RECOVER_CDA',
};

const CdaHelper = ({
  classes,
  user,
  setActiveScreen,
  recoverCda,
  setLastLogin,
  account,
  berlin,
  setIsLogged,
  noRedirect,
}) => {
  const [value, setValue] = useState([]);
  const [password, setPassword] = useState('');
  const [cdaError, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [recoverCdaPath, setRecoverCdaPath] = useState(false);
  const [step, setStep] = useState(
    account.has_security ? STEPS.LOGIN_CDA : STEPS.INFO,
  );
  const [invalid, setInvalid] = useState({
    status: true,
    errorMessage: '',
    error: false,
  });
  const [tooltips, toggleTooltips] = useState({
    nipTooltip: false,
    passwordTooltip: false,
  });

  const [displayTooltip, setDisplayTooltip] = useState(false);

  const maxLength = 6;
  let history = useHistory();

  const [showSplashClip2Swap, setShowSplashClip2Swap] = useState(false);
  
  useEffect(() => {
    setShowSplashClip2Swap(step === STEPS.LOGIN_CDA);
    setTimeout(() => setShowSplashClip2Swap(false), 2000);

    if (step === STEPS.LOGIN_CDA) {
      postSwapEvent(EVENTS.BERLIN.SWAP_MIGRATION.ACTIONS.CLIP_LOG_IN);
    }
  }, []);

  useEffect(() => {
    if (value.length !== 0) {
      setError(null);
    }
    if (value.length === 6) {
      setWaiting(true);
      switch (step) {
        case STEPS.CREATE_CDA:
        case STEPS.RECOVER_CDA:
          createCdaPin();
          return;
        case STEPS.CONFIRM_CDA:
          confirmCdaPin();
          return;
        case STEPS.LOGIN_CDA:
          login();
          return;
        default:
          return;
      }
    }
  }, [value]);

  const resetValue = () => {
    setWaiting(false);
    setValue([]);
  };

  const createCdaPin = async () => {
    try {
      await Crypto.init();
      const serverKey = await CryptoRequests.GetServerKey(
        account.account_holder_id,
        Crypto.keys().publicKey.base64,
        Crypto.keys().iv.hex,
      );
      await Crypto.setServerPublicKey(serverKey);
      var enc = new TextEncoder();
      const encryptedData = await Crypto.encrypt(enc.encode( value.join('')));
      const body = {
        type: account.security_type || 'CDA',
        pin: encryptedData,
        user_id: berlin.account_representation.account_holder_id,
        agent: 'Mozilla',
      };
      await BerlinService.createCdaPin(body);
      resetValue();
      setStep(STEPS.CONFIRM_CDA);
    } catch (err) {
      setError(err.response.data.message);
      resetValue();
      logger.info(err);
    }
  };

  const confirmCdaPin = async () => {
    try {
      await Crypto.init();
      const serverKey = await CryptoRequests.GetServerKey(
        account.account_holder_id,
        Crypto.keys().publicKey.base64,
        Crypto.keys().iv.hex,
      );
      await Crypto.setServerPublicKey(serverKey);
      var enc = new TextEncoder();
      const encryptedData = await Crypto.encrypt(enc.encode( value.join('')));
      const body = {
        type: account.security_type || 'CDA',
        pin: encryptedData,
        user_id: berlin.account_representation.account_holder_id,
        agent: 'Mozilla',
      };
      await BerlinService.confirmCdaPin(body);
      if (recoverCdaPath) {
        store.addNotification({
          title: text.recoverCdaSuccess,
          message: ' ',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            showIcon: true,
            duration: 0,
          },
        });
        Clevertap.event(
          EVENTS.BERLIN.MY_ACCOUNT.EVENT, 
          EVENTS.BERLIN.MY_ACCOUNT.ACTIONS.CHANGE_CDA_CHANGED,
          );
          setIsLogged(true);
        if(!noRedirect){
          if (setActiveScreen) {
            setActiveScreen();
          }
          history.push(`${ROUTES.BALANCE}`);
        }
        
        return;
      }
      setStep(STEPS.DONE);
    } catch (err) {
      setError(err.response.data.message);
      resetValue();
      logger.info(err);
    }
  };

  const login = async () => {
    if (account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.SWAP) {
      try {
        const phoneLastEight = account.account_holder_representation.phone_number.substring(2)
        const dynamicIv = phoneLastEight + phoneLastEight.split('').reverse().join('');
        const password = CryptoUtils.encryptWithIV(value.join(''), dynamicIv);
        const login = await BerlinService.postSwapAccountLogin(
          account.account_holder_representation.email,
          account.account_holder_representation.phone_number,
          password,
        );
        
        if (login.status === 200) {
          setLastLogin(login.data.data.last_session.iso);
          setIsLogged(true);
        }
        if(!noRedirect){
          if (setActiveScreen) {
            setActiveScreen();
          } else {
            history.push(`${ROUTES.BALANCE}`);
          }
        }
      } catch (error) {
        setError(JSON.parse(error.request.response).error.message);
        resetValue();
        logger.info(error);
      }
    } else {
      try {
        await Crypto.init();
        const serverKey = await CryptoRequests.GetServerKey(
          account.account_holder_id,
          Crypto.keys().publicKey.base64,
          Crypto.keys().iv.hex,
        );
        await Crypto.setServerPublicKey(serverKey);
        var enc = new TextEncoder();
        const encryptedData = await Crypto.encrypt(enc.encode( value.join('')));
        const body = {
          pin: encryptedData,
          user_id: berlin.account_representation.account_holder_id,
          agent: 'Mozilla',
          type: account.security_type || 'CDA',
        };
        const login = await BerlinService.postLogin(body);
        if (login.status === 202) {
          setLastLogin(login.data.message.last_session);
          setIsLogged(true);
        }
        if(!noRedirect){
          if (setActiveScreen) {
            setActiveScreen();
          } else {
            history.push(`${ROUTES.BALANCE}`);
          }
        }
        
      } catch (err) {
        setError(err.response.data.message);
        resetValue();
        logger.info(err);
      }
    }
  };

  const renderInputPlaceholders = () => {
    const dots = [];
    let counter = 0;
    while (counter < maxLength) {
      let color = 'white';
      if (counter < value.length) {
        color = 'black';
      }
      dots.push(
        <PasswordDots
          key={counter}
          counter={counter}
          color={color}
          waiting={waiting}
        />,
      );
      counter++;
    }

    return dots;
  };

  const renderStatusMessage = () => {
    if (cdaError) {
      if (step === STEPS.LOGIN_CDA) {
        return `${text.hello}, ${user.first_name}!\n${cdaError}`;
      }
      return cdaError;
    }

    switch (step) {
      case STEPS.CREATE_CDA:
        return text.newCode;
      case STEPS.CONFIRM_CDA:
        return text.confirmCode;
      case STEPS.LOGIN_CDA:
        return `${text.hello}, ${user.first_name}!\n${text.enterCode}`;
      case STEPS.RECOVER_CDA:
        return text.changeCda;
      default:
        return;
    }
  };

  const validateUser = async () => {
    try {
      const response = await BerlinService.getMerchantNonce({ password });

      if (response.data.item.nonce) {
        setInvalid({ status: true, errorMessage: '', error: false });
        if (recoverCdaPath) {
          setStep(STEPS.RECOVER_CDA);
          setPassword('');
          return;
        }

        setStep(STEPS.CREATE_CDA);
        setPassword('');
      } else {
        setInvalid({
          status: false,
          error: true,
          errorMessage: text.passwordError,
        });
        setPassword('');
      }
    } catch (error) {
      setInvalid({
        status: false,
        error: true,
        errorMessage: text.passwordError,
      });
      setPassword('');
    }
  };

  const postSwapEvent = (event) => {
    const EVENT_PROPERTIES = {
      account_level: account.account_level,
      version: localStorage.getItem('appUpToDate') ? 'CON_MIGRACION' : 'SIN_MIGRACION',
      proxy_account_id: account.proxy_account_id,
    }
    try {
      BerlinService.postSwapEvent(account.proxy_account_id, event, EVENT_PROPERTIES);
    } catch (error) {
      logger.info(error);
    }
  }

  const renderRecoverButton = () => {
    if (step === STEPS.LOGIN_CDA && account.has_security) {
      return (
        <BaseButton
          onClick={() => {
            setError(null);
            resetValue();
            setRecoverCdaPath(true);
            setStep(STEPS.PASSWORD);
          }}
          className={classes.clearButton}
        >
          {text.forgotCda}
        </BaseButton>
      );
    }
  };

  const returnToLogin = (target) => {
    setRecoverCdaPath(false);
    setInvalid({ status: true, errorMessage: '', error: false });
    setPassword('');
    resetValue();
    setError(null);
    setStep(target);
  };

  return (
    <>
      {step === STEPS.SPINNER && <BerlinSpinner />}

      {step === STEPS.INFO && (
        <div className={classes.root}>
          <div>
            <img
              className={classes.infoImg}
              src={phoneLock}
              alt={'phone lock'}
            />
            <Typography variant="title" className={classes.infoTitle}>
              {text.infoTitle}
            </Typography>
            <Typography variant="body1" className={classes.infoText}>
              {text.infoText}
            </Typography>
          </div>
          <SubmitButton
            className={classes.infoContinueButton}
            onClick={() => setStep(STEPS.PASSWORD)}
          >
            {text.startButton}
          </SubmitButton>
        </div>
      )}

      {step === STEPS.PASSWORD && (
        <div className={classes.root}>
          {recoverCdaPath && (
            <OneStepNavigator
              title={text.navigatorTitle}
              stepBackFunction={returnToLogin}
              stepBackTarget={STEPS.LOGIN_CDA}
            />
          )}
          <div className={classes.formContainer}>
            <Typography className={classes.titlePassword} variant="title">
              {text.enterPassword}
            </Typography>
            <TextField
              className={classes.formField}
              label={text.passwordField}
              error={invalid.error}
              type="password"
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setInvalid({ ...invalid, status: e.target.value.length < 8 });
              }}
              helperText={invalid.errorMessage}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() =>
                        toggleTooltips({ ...tooltips, passwordTooltip: true })
                      }
                    >
                      <HelpOutlineIcon color="primary" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <SubmitButton
            className={classes.continueButton}
            onClick={(e) => {
              e.preventDefault();
              validateUser();
            }}
          >
            {text.continueButton}
          </SubmitButton>
          <Drawer anchor="bottom" open={tooltips.passwordTooltip}>
            <WhyPasswordTooltip
              togglePasswordTooltip={(arg) =>
                toggleTooltips({ ...tooltips, passwordTooltip: arg })
              }
            />
          </Drawer>
        </div>
      )}

      {(step === STEPS.CREATE_CDA ||
        step === STEPS.CONFIRM_CDA ||
        step === STEPS.LOGIN_CDA ||
        step === STEPS.RECOVER_CDA) && (
        <div>
          {account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.SWAP && showSplashClip2Swap && <div className={classes.splashLogo}>
          <img src={clip2swap} alt={'clip2swap'} />
          <div className={classes.splashText}>

          <Typography variant="subheading">
            {text.redirectText1}
          </Typography>
          <Typography variant="subheading" className={classes.splashTextProvider}>
            {text.redirectText2}
          </Typography>
          </div>
          </div>}
          {(!showSplashClip2Swap || account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.CACAO) && <div>

        
        <div className={classes.keypadContainer}>
          {recoverCdaPath && (
            <OneStepNavigator
              title={text.navigatorTitle}
              stepBackFunction={returnToLogin}
              stepBackTarget={STEPS.LOGIN_CDA}
            />
          )}
          <div className={classes.topContent}>
            <img
              className={classes.logo}
              src={clipAccountLogo}
              alt={'Clipcard Logo'}
            />
            <Typography className={classes.statusMesssage}>
              {renderStatusMessage()}
            </Typography>
            <div
              className={`${classes.dotsContainer} ${
                cdaError ? classes.shakeAnimation : ''
              }`}
            >
              {renderInputPlaceholders()}
            </div>
          </div>
          <div className={classes.keypad}>
            <OnScreenKeypad
              keypadDisabled={waiting}
              maxLength={maxLength}
              value={value}
              setValue={setValue}
            />
          </div>
          {renderRecoverButton()}
          {account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.SWAP && <div className={classes.bottomContent}>
            <Typography className={classes.statusMesssage}>
              { text.bottomText } 
            </Typography>
            <img 
              className={classes.swapLogo} 
              src={swapLogo} 
              alt={'swap'} 
            />
          </div>}
        </div>
          </div>}
          
        </div>
      )}

      {step === STEPS.DONE && (
        <div className={classes.root}>
          <div>
            <img
              className={classes.checkReadyImg}
              src={successIcon}
              alt={'success'}
            />
            <Typography variant="title" className={classes.infoTitle}>
              {text.done}
            </Typography>
            <Typography variant="body1" className={classes.infoText}>
              {text.secureClipCard}
            </Typography>
            <img src={readyCda} alt={'ready'} />
          </div>
          <SubmitButton
            className={classes.continueButton}
            onClick={() => {
              if(!noRedirect){
                if (setActiveScreen) {
                  setActiveScreen();
                } else {
                  history.push(`${ROUTES.BALANCE}`);
                }
              }
            }}
          >
            {text.finalize}
          </SubmitButton>
        </div>
      )}
    </>
  );
};

CdaHelper.defaultProps = {
  classes: {},
  user: {},
};

CdaHelper.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
};

const mapDispatchToProps = {
  setLastLogin,
  setIsLogged,
};

const mapStateToProps = (state) => {
  return {
    account: state.berlin.account_representation,
    user: state.user,
    berlin: state.berlin,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CdaHelper),
);
