import React from 'react';
import MuiBottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withStyles } from '@material-ui/core/styles';
import { any, bool, node, shape } from 'prop-types';

import Styled from './styled';

const BottomNavigationAction = (props) => (
  <MuiBottomNavigationAction {...props} />
);

BottomNavigationAction.propTypes = {
  classes: shape({}),
  icon: node,
  label: node,
  showLabel: bool,
  value: any,
};

BottomNavigationAction.defaultProps = {
  classes: {},
  icon: null,
  label: null,
  showLabel: false,
  value: null,
};

export default withStyles(Styled)(BottomNavigationAction);
