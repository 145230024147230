export const styles = (theme) => {
  const contactInformationStyles = {
    spacedLabel: {
      marginBottom: `${theme.spacing.unit}px`,
    },
    extraFieldContainer: {
      width: '100%',
    },
    extraInputV1: {
      float: 'left',
      width: `calc(100% - ${theme.spacing.unit * 4}px)`,
    },
    buttonV1Dismiss: {
      float: 'left',
      marginTop: `${theme.spacing.unit * 2}px`,
      minWidth: `${theme.spacing.unit * 3}px!important`,
      minHeight: `${theme.spacing.unit * 3}px!important`,
      width: `${theme.spacing.unit * 3}px`,
      height: `${theme.spacing.unit * 3}px`,
      color: '#0288D1',
      '& svg': {
        width: `${theme.spacing.unit * 3}px`,
      },
    },
    buttonV2Dismiss: {
      padding: 0,
      minWidth: `${theme.spacing.unit * 3}px`,
      minHeight: `${theme.spacing.unit * 3}px`,
      width: `${theme.spacing.unit * 3}px`,
      height: `${theme.spacing.unit * 3}px`,
      color: '#0288D1',
      '& svg': {
        width: `${theme.spacing.unit * 3}px`,
      },
    },
    addButtonV1Styles: {
      float: 'left',
      marginTop: `${theme.spacing.unit * 3}px`,
      padding: `${theme.spacing.unit}px`,
      color: '#0288D1',
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 600,
      '& img': {
        marginRight: `${theme.spacing.unit}px`,
      },
    },
    addButtonV3Styles: {
      textTransform: 'none',
    },
    addColorV4Styles: {
      color: '#FC4C02',
    },
    infoLabel: {
      color: '#99A2A7',
    },
    addButtonV4Styles: {
      marginTop: `${theme.spacing.unit * 1.5}px`,
      padding: `${theme.spacing.unit}px`,
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: 600,
      '& img': {
        marginRight: `${theme.spacing.unit}px`,
      },
    },
    extraTelephonesContainer: {
      marginTop: `${theme.spacing.unit * 2}px`,
    },
    addImageV4Styles: {
      filter: 'brightness(0) saturate(100%) invert(34%) sepia(54%) saturate(4835%) hue-rotate(3deg) brightness(105%) contrast(98%)',
    },
  };

  const tooltipStyles = {
    iconButton: {
      alignItems: 'start',
      flexGrow: 1,
      padding: '0 0 0 12px',
    },
    tooltipPaper: {
      borderRadius: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px 0px 0px!important`,
    },
    tooltipContainer: {
      width: '100%',
      height: 'auto',
      alignSelf: 'center',
      backgroundColor: theme.palette.white,
      overflow: 'auto',
      boxSizing: 'border-box',
      padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
      display: 'flex',
      flexFlow: 'column',
    },
    tooltipTitleContainer: {
      display: 'flex',
    },
    tooltipTitle: {
      marginBottom: theme.spacing.unit * 3,
    },
    tooltipCodeLabel: {
      marginTop: theme.spacing.unit * 3,
    },
    tooltipCodeResendButton: {
      marginTop: theme.spacing.unit * 2,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'fit-content',
      },
    },
    tooltipCodeResendButtonDisabled: {
      '&:hover': {
        border: 'none',
      },
    },
    tooltipButtonContainer: {
      marginTop: theme.spacing.unit * 5,
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
      '& button': {
        fontSize: theme.spacing.unit * 2,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 'auto',
        },
      },
      '& :nth-child(1)': {
        [theme.breakpoints.up('sm')]: {
          order: 2,
        },
      },
      '& :nth-child(2)': {
        [theme.breakpoints.up('sm')]: {
          order: 1,
        },
      },
    },
    tooltipButton: {
      width: '100%',
    },
    helpIcon: {
      width: theme.spacing.unit * 2.5,
      marginLeft: theme.spacing.unit * 0.5,
    },
  }

  const errorStyles = {
    warningContainer: {
      display: 'flex',
      alignItems: 'center',
      color: '#A8093C',
      '& svg': {
        width: '0.7em',
        height: '0.7em',
        marginRight: '10px',
      },
    },
  }

  const dialogStyles = {
    rootDialog: {
      [theme.breakpoints.up('sm')]: {
        minWidth: theme.spacing.unit * 62.5,
      },
    },
  }

  const chipStyles = {
    chipContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 0,
    },
  }

  return { ...contactInformationStyles, ...tooltipStyles, ...errorStyles, ...dialogStyles, ...chipStyles };
};
