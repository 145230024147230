import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import { ClipButton } from '@clipmx/clip-storybook';
import { LinearProgress } from '@material-ui/core';
import LockOutlined from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import text from './text';
import styles from './styles';

const Token = (props) => {
  useEffect(()=>{
    const interval = setInterval(() => {
      closeToggle()
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  const { classes, toggleTooltip } = props;
  
  const closeToggle = () => {
    toggleTooltip({response:'data to return'});
  }
  return (
    <div>
      <LinearProgress
        classes={classes.progressBarStyles}
      />
      <div className={classes.root}>
        <Typography variant="title" className={classes.title}>
          {text.title}
        </Typography>
        <Typography variant="subheading" className={classes.message}>
          {text.subtitle}
        </Typography>
        <div className={classes.lockButton}>
          <IconButton disabled onClick={toggleTooltip} >
            <LockOutlined  className={classes.lockIcon}/>
          </IconButton>
        </div>
      </div>
      <div className={classes.digitsContainer}>
        <Typography className={classes.pinDigit} variant="subheading">
          {text.tokenChar}
        </Typography>
        <Typography className={classes.pinDigit} variant="subheading">
          {text.tokenChar}
        </Typography>
        <Typography className={classes.pinDigit} variant="subheading">
          {text.tokenChar}
        </Typography>
        <Typography className={classes.pinDigit} variant="subheading">
          {text.tokenChar}
        </Typography>
        <Typography className={classes.pinDigit} variant="subheading">
          {text.tokenChar}
        </Typography>
      </div>      
    </div>
  );
};
Token.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleTooltip: PropTypes.func,
};

export default withStyles(styles)(Token);