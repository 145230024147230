import {
  SET_CARD_BALANCE,
  SET_CARD_MOVEMENTS,
  SET_ACTIVE_CARD,
  SET_COLONIES,
  SET_ACCOUNT_REPRESENTATION,
  SET_BALANCE_LOADING_STATE,
  SET_MOVEMENTS_LOADING_STATE,
  SET_LD_FLAGS,
  SET_LAST_LOGIN,
  SET_ACCOUNT_SECTION_VISITED,
  SET_DOCUMENT_TYPE,
  SET_CONTACTS,
  SET_IS_LOGGED,
} from './types';

const initialState = {
  cardBalance: 0.0,
  cardMovements: [],
  activeCard: {
    number: '9900004700318318',
    proxy_card: 'cd6af3ca-03ee-4a40-80be-fba9af81bd73',
  },
  account_representation: null,
  isBalanceLoading: true,
  isMovementsLoading: true,
  ineFront: null,
  ineBack: null,
  passport: null,
  colonies: [],
  contacts: [],
  ldFlags: {},
  lastLogin: null,
  accountSectionVisited: false,
  documentType: null,
  isLogged: false,
};

const actionHandlers = new Map([
  [SET_CARD_BALANCE, updateCardBalance],
  [SET_CARD_MOVEMENTS, updateCardTransactions],
  [SET_ACTIVE_CARD, setActiveCard],
  [SET_BALANCE_LOADING_STATE, setBalanceLoadingState],
  [SET_MOVEMENTS_LOADING_STATE, setMovementsLoadingState],
  [SET_COLONIES, setColonies],
  [SET_ACCOUNT_REPRESENTATION, setAccountRepresentation],
  [SET_LD_FLAGS, setLdFlags],
  [SET_CONTACTS, setContacts],
  [SET_LAST_LOGIN, setLastLogin],
  [SET_ACCOUNT_SECTION_VISITED, setAccountSectionVisited],
  [SET_DOCUMENT_TYPE, setDocumentType],
  [SET_IS_LOGGED, setIsLogged],
]);

function updateCardBalance(state, action) {
  return { ...state, ...action.payload };
}

function updateCardTransactions(state, action) {
  return { ...state, ...action.payload };
}

function setActiveCard(state, action) {
  return { ...state, ...action.payload };
}

function setIsLogged(state, action) {
  return { ...state, ...action.payload };
}

function setBalanceLoadingState(state, action) {
  return { ...state, isBalanceLoading: action.payload.isBalanceLoading };
}

function setMovementsLoadingState(state, action) {
  return { ...state, isMovementsLoading: action.payload.isMovementsLoading };
}

function setColonies(state, action) {
  return { ...state, colonies: action.payload.colonies };
}

function setAccountRepresentation(state, action) {
  return { ...state, ...action.payload };
}

function setLdFlags(state, action) {
  return { ...state, ldFlags: action.payload };
}

function setContacts(state, action) {
  return { ...state, contacts: action.payload.contacts };
}

function setLastLogin(state, action) {
  return { ...state, lastLogin: action.payload.lastLogin };
}

function setAccountSectionVisited(state, action) {
  return { ...state, accountSectionVisited: action.payload };
}

function setDocumentType(state, action) {
  return { ...state, documentType: action.payload };
}

export default function reducer(state = initialState, action = { type: null }) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}
