export const styles = (theme) => {
  const rootStyles = {
    container: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    conection: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  };
  return { ...rootStyles };
};
