import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    width: '100%',
    backgroundColor: 'white',
    '& li ': {
      fontSize: '16px',
      fontWeight: '400'
    },
    '& .accordionHeader': {
      minHeight: '57px',
      padding: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& h5': {
        fontSize: '14px',
        color: '#181A36',
        margin: '0'
      },
      '& .arrowUpIcon ': {
        fill: theme.palette.primary.main
      }
    },
    '& .accordionContent.hidden': {
      display: 'none'
    }
  }
}));

export default useStyles;