import React, { useState } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ROUTES } from '../constants';
import { BerlinTheme } from '../Theme';
import * as Styled from '../Commons/BerlinContent/styled';
import { ScreenContext, SCREENS } from '../contexts/ScreenContext';
import ReactNotification from 'react-notifications-component';
import {
  BottomNavigation,
  BottomNavigationAction,
  SvgIcon,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import text from './text';
import styles from '../styles';

const NavigationCardIcon = (props) => {
  const svgPath =
    'M21.1470588,5 C22.17041,5 23,5.82655123 23,6.84615385 L23,6.84615385 L23,19.1538462 ' +
    'C23,20.1734488 22.17041,21 21.1470588,21 L21.1470588,21 L3.85294118,21 C2.82959002,21 2,20.1734488 ' +
    '2,19.1538462 L2,19.1538462 L2,6.84615385 C2,5.82655123 2.82959002,5 3.85294118,5 L3.85294118,5 ' +
    'Z M19.9854257,7 C19.8466593,7 19.7106046,7.02635294 19.5939624,7.07837789 L15.8973945,8.64810984 ' +
    'C15.3705695,8.87092715 15,9.3930283 15,10 L15,11.9854836 C15,12.5456209 15.4541328,13 16.0145982,13 ' +
    'C16.1566976,13 16.2918444,12.9705172 16.4147702,12.9177277 L20.0957006,11.3548767 C20.6260976,11.1336721 ' +
    '21,10.6098387 21,10 L20.9998805,8.00970217 C20.9972165,7.45165542 20.5440395,7 19.9854257,7 Z';
  return (
    <SvgIcon {...props}>
      <path d={svgPath} id="Combined-Shape" fillRule="nonzero" />
    </SvgIcon>
  );
};

const BottomNavigationHOC = (props) => {
  let history = useHistory();
  const { classes, children } = props;

  return (
    <MuiThemeProvider theme={BerlinTheme}>
      <EmotionThemeProvider theme={BerlinTheme}>
        <Styled.BerlinContainer className={'mobile'}>
          <ScreenContext.Provider>
            <ReactNotification />
            {children}
            <BottomNavigation
              showLabels
              className={classes.bottomNavigation}
              value={window.location.href.substring(
                window.location.href.lastIndexOf('/') + 1,
              )}
              onChange={(event, screen) =>
                history.push(`/mobile/berlin/${screen}`)
              }
            >
              <BottomNavigationAction
                label={text.bottomNavigationHome}
                value={SCREENS.BALANCE}
                icon={<HomeIcon />}
              />
              <BottomNavigationAction
                label={text.bottomNavigatorClipCard}
                value={SCREENS.CLIPCARD}
                icon={<NavigationCardIcon />}
              />
              <BottomNavigationAction
                label={text.bottomNavigationAccount}
                value={SCREENS.ACCOUNT_INFO}
                icon={<PersonIcon />}
              />
            </BottomNavigation>
          </ScreenContext.Provider>
        </Styled.BerlinContainer>
      </EmotionThemeProvider>
    </MuiThemeProvider>
  );
};
export default withStyles(styles)(BottomNavigationHOC);
