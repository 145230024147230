import React from 'react';
import PropTypes from 'prop-types';
import { ClipTypography, ClipButton } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ClipCard from '@clipmx/clip-storybook/dist/components/Card';
import { ArrowForwardRounded, Edit } from '@clipmx/clip-storybook/dist/components/Icons';
import Delete from '@clipmx/clip-storybook/dist/components/Icons/Delete';
import { styles } from '../DepositStep/styles';
import text from './text';

const useStyles = makeStyles(styles);

const ContactCard = (props) => {
  const { disabled, alias, name, bank, clabe, id, onClick, onEdit, onDelete, openEdit } = props;
  const classes = useStyles(props);

  return (
    <ClipCard className={classes.card}>
      <div>
        <ClipTypography
          variant={'body1'}
          component={'p'}
          className={`${classes.cardTitle} ${disabled ? classes.disabled : ''}`}
        >
          {alias}
        </ClipTypography>
        <ClipTypography
          variant={'body1'}
          component={'p'}
          className={`${classes.cardInfo} ${disabled ? classes.disabled : ''}`}
        >
          {name}
        </ClipTypography>
        <ClipTypography
          variant={'body1'}
          component={'p'}
          className={`${classes.cardInfo} ${disabled ? classes.disabled : ''}`}
        >
          {`${bank} - ${clabe.substr(clabe.length - 4)}`}
        </ClipTypography>
      </div>
      {!onEdit && <ClipButton
        onClick={onClick}
        className={classes.cardButton}
        disabled={disabled}
        disableFocusRipple
        disableRipple
        color="primary"
      >
        <ArrowForwardRounded />
      </ClipButton>}
      {onEdit && 
      <ClipButton
        onClick={() => onDelete({id, alias})}
        className={classes.cardButtonDelete}
        disabled={disabled}
        disableFocusRipple
        disableRipple
        color="primary"
      >
        <Delete />
      </ClipButton>
      }
      {onEdit && 
      <ClipButton
        onClick={() => openEdit({
          id,
          name,
          clabe,
          bank,
          alias,
        })}
        className={classes.cardButtonEdit}
        disabled={disabled}
        disableFocusRipple
        disableRipple
        color="primary"
      >
        <Edit />
      </ClipButton>
      }
    </ClipCard>
  );
};

ContactCard.propTypes = {
  disabled: PropTypes.bool,
  alias: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  bank: PropTypes.string,
  clabe: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  openEdit: PropTypes.func,
  onEdit: PropTypes.bool,
};

ContactCard.defaultProps = {
  disabled: false,
  alias: text.emptyData.alias,
  name: text.emptyData.acount,
  id: null,
  bank: text.emptyData.bank,
  clabe: text.emptyData.clabe,
  onClick: () => {},
  onDelete: () => {},
  openEdit: () => {},
  onEdit: false,
};

export default ContactCard;
