import React from 'react';
import { BaseButton } from '../../common/Buttons';
import { connect } from 'react-redux';
import { Forms } from '../../../constants/AppConstants';
import { required } from '../../../helpers/validate';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core/styles';
import BerlinService from '../service';
import BerlinSpinner from '../BerlinSpinner';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CdaHelper from '../CdaHelper';
import Dialog from '@material-ui/core/Dialog';
import errorIcon from '../../../assets/images/berlin/error-icon.svg';
import geoSpot from '../../../assets/images/berlin/geoSpot.svg';
import Grid from '@material-ui/core/Grid';
import logger from '../../../helpers/logger';
import Lottie from 'lottie-react';
import moment from 'moment-timezone';
import OneStepNavigator from '../Commons/OneStepNavigator';
import PropTypes from 'prop-types';
import SpeiOutAnimation from '../../../assets/images/berlin/lottie/SpeiOutAnimation/data.json';
import styles from './oldStyles';
import successIcon from '../../../assets/images/berlin/success-icon.svg';
import text from './oldText';
import Typography from '@material-ui/core/Typography';
import WhatsAppModule from '../WhatsAppModule';
import {
  allowAlphabetical,
  allowNumerical,
  allowAlphaNumericalNoAccents,
  minLength,
} from '../utils';
import {
  reduxForm,
  propTypes,
  change as updateFormField,
  updateSyncErrors,
  formValueSelector,
  reset,
} from 'redux-form';
import {
  renderInput,
  renderMaskedInput,
} from '../Commons/BerlinInputs/FormUtils';
import {
  SubmitButton,
  NextStepButton,
  RetryButton,
  ReturnButton,
  FormContainer,
  FormField,
  ClipCardLogo,
  SpeiAmountContainer,
} from './oldStyled';

const validateLength = minLength(3, text.verifyInfoError);

const validatePasswordLength = minLength(8, text.passwordLengthError);

const validateClabeLength = minLength(18, text.clabeLengthError);

const validateAvailableBalance = (value, props, state) => {
  const amountValue = parseFloat(value);
  const balanceValue = parseFloat(state.balance);
  if (amountValue > balanceValue) {
    return `Tu saldo disponible es ${balanceValue.toLocaleString(undefined, {
      style: 'currency',
      currency: 'MXN',
    })}`;
  }
};

const validateMinimumAmount = (value) => {
  const amountValue = parseFloat(value);
  const balanceValue = parseFloat('0');
  if (amountValue <= 0) {
    return `Debes transferir más de ${balanceValue.toLocaleString(undefined, {
      style: 'currency',
      currency: 'MXN',
    })}`;
  }
  return undefined;
};

export class Transfers extends React.PureComponent {
  constructor(props) {
    super(props);
    const geoDialog = localStorage.getItem('geoDialog');
    let showDialog = false;
    if (!geoDialog) {
      showDialog = true;
    }
    this.state = {
      page: -1,
      speiInfo: null,
      speiStatus: null,
      transferButtonEnabled: true,
      validClabe: false,
      geoDialog: showDialog,
    };
  }

  static propTypes = {
    SCREENS: PropTypes.object.isRequired,
    setActiveScreen: PropTypes.func.isRequired,
    ...propTypes,
  };

  static defaultProps = {
    account: {},
    user: {},
    balance: 0,
  };

  componentDidMount() {
    this.location = {};
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.location.latitude = position.coords.latitude
          .toString()
          .substring(0, 10);
        this.location.longitude = position.coords.longitude
          .toString()
          .substring(0, 10);
        this.setState({ page: 1 });
      },
      (error) => {
        logger.info(error);
        if (!this.state.geoDialog) {
          this.setState({ page: 0 });
          return;
        }
        this.setState({ page: 1 });
      },
    );
  }

  isGeoAvailable = () => {
    if (this.location.latitude) {
      this.setState({ page: 1 });
      return;
    }
    this.setState({ page: 0 });
  };

  handleClose = () => {
    this.props.setActiveScreen(this.props.SCREENS.BALANCE);
  };

  sendSpeiCda = (values) => {
    const { account, reset, user } = this.props;
    this.setState({ transferButtonEnabled: false, page: 4 });
    const body = {
      recipient_name: values.name,
      recipient_account: values.clabe,
      description: values.reference,
      amount: values.amount,
      location: this.location,
    };
    this.setState({ speiInfo: body });
    if (account.proxy_account_id) {
      BerlinService.postSpeiOutV2(account.proxy_account_id, body)
        .then((response) => {
          this.setState({
            transferButtonEnabled: true,
            speiStatus: response.data.message,
            page: 3,
          });

          reset(Forms.TRANSFERS_FORM);
        })
        .catch((error) => {
          this.setState({ transferButtonEnabled: true, page: 5 });
        });
    } else {
      BerlinService.postSpeiOut(
        account.card_representation[0].proxy_card_id
          ? account.card_representation[0].proxy_card_id
          : account.card_representation[0].proxy_card,
        body,
      )
        .then((response) => {
          this.setState({
            transferButtonEnabled: true,
            speiStatus: response.data.message,
            page: 3,
          });

          reset(Forms.TRANSFERS_FORM);
        })
        .catch((error) => {
          this.setState({ transferButtonEnabled: true, page: 5 });
        });
    }
  };

  setActiveStep = (step) => {
    this.setState({ page: step });
  };

  nextStep = () => {
    const { page } = this.state;
    this.setState({ page: page + 1 });
    window.scrollTo(0, 0);
  };

  validateCLABE = (e) => {
    const { dispatch } = this.props;
    dispatch(updateFormField(Forms.TRANSFERS_FORM, 'bank', ''));
    if (e.target.value.length === 18) {
      BerlinService.getInterbankAccountInfo(e.target.value)
        .then((response) => {
          dispatch(
            updateFormField(
              Forms.TRANSFERS_FORM,
              'bank',
              response.data.message.bank_acronym,
            ),
          );
        })
        .catch((error) => {
          dispatch(
            updateSyncErrors(Forms.TRANSFERS_FORM, { clabe: text.verifyClabe }),
          );
        });
    }
  };

  allowAlphabetical = (maxLength, e) => {
    e.target.value = allowAlphabetical(maxLength, e.target.value);
  };

  allowNumbers = (maxLength, e) => {
    e.target.value = allowNumerical(maxLength, e.target.value);
  };

  allowAlphaNumerical = (maxLength, e) => {
    e.target.value = allowAlphaNumericalNoAccents(maxLength, e.target.value);
  };

  formatAmount = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands)
      );
    } catch (e) {}
  };

  render() {
    const {
      invalid,
      SCREENS,
      setActiveScreen,
      theme,
      dispatch,
      classes,
      ldFlags,
      user,
      berlin,
      formValues,
    } = this.props;
    const { page, speiStatus, speiInfo } = this.state;

    return page === -1 ? (
      <BerlinSpinner />
    ) : (
      <>
        <form className={classes.form}>
          {page === 0 && (
            <>
              <OneStepNavigator
                title={text.navigatorTitle}
                stepBackFunction={setActiveScreen}
                stepBackTarget={SCREENS.BALANCE}
              />
              <div className={classes.noGeoContainer}>
                <img src={geoSpot} />
                <Typography className={classes.geoTitle} variant={'title'}>
                  {text.shareGeo}
                </Typography>
                <Typography variant={'subheading2'}>{text.geoMessage}</Typography>
              </div>
            </>
          )}
          {page === 1 && (
            <React.Fragment>
              <OneStepNavigator
                title={text.navigatorTitle}
                stepBackFunction={setActiveScreen}
                stepBackTarget={SCREENS.BALANCE}
              />
              <FormContainer>
                <Typography className={classes.title} variant={'title'}>
                  {text.title}
                </Typography>
                <FormField
                  component={renderInput}
                  label={text.clabeField}
                  type={'text'}
                  inputProps={{ inputMode: 'numeric' }}
                  name={'clabe'}
                  validate={[required, validateClabeLength]}
                  onInput={(e) => this.allowNumbers(18, e)}
                  onChange={(e) => this.validateCLABE(e)}
                />
                <FormField
                  component={renderInput}
                  label={text.bankField}
                  type={'text'}
                  name={'bank'}
                  validate={[required]}
                  disabled={true}
                />
                <FormField
                  component={renderInput}
                  label={text.nameField}
                  type={'text'}
                  name={'name'}
                  validate={[required, validateLength]}
                  onInput={(e) => this.allowAlphabetical(40, e)}
                />
                <FormField
                  component={renderMaskedInput}
                  label={text.amountField}
                  type={'text'}
                  name={'amount'}
                  validate={[
                    required,
                    validateAvailableBalance,
                    validateMinimumAmount,
                  ]}
                  inputProps={{ inputMode: 'decimal' }}
                  thousandSeparator={true}
                  prefix={'$'}
                  isNumericString={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onValueChange={(values) => {
                    dispatch(
                      updateFormField(
                        Forms.TRANSFERS_FORM,
                        'amount',
                        values.value,
                      ),
                    );
                  }}
                />
                <FormField
                  component={renderInput}
                  label={text.referenceField}
                  type={'text'}
                  name={'reference'}
                  validate={[required, validateLength]}
                  onInput={(e) => this.allowAlphaNumerical(20, e)}
                />
                <NextStepButton
                  disabled={invalid}
                  onClick={() => {
                    this.nextStep();
                  }}
                >
                  {text.transferButton}
                </NextStepButton>
              </FormContainer>
            </React.Fragment>
          )}
          {page === 2 && (
            <React.Fragment>
              <OneStepNavigator
                title={text.navigatorTitle}
                stepBackFunction={this.setActiveStep}
                stepBackTarget={1}
              />
              {ldFlags.enableCdaLogin ? (
                <CdaHelper
                  cdaReady={true}
                  recoverCda={false}
                  user={user}
                  setActiveScreen={() => this.sendSpeiCda(formValues)}
                  location={this.location}
                  merchantId={berlin.account_representation.account_holder_id}
                />
              ) : (
                <FormContainer>
                  <Typography variant={'title'}>
                    {text.enterPassword}
                  </Typography>
                  <FormField
                    component={renderInput}
                    label={text.passwordField}
                    type={'password'}
                    name={'password'}
                    validate={[required, validatePasswordLength]}
                  />
                  <SubmitButton disabled={invalid}>
                    {text.transferButton}
                  </SubmitButton>
                </FormContainer>
              )}
            </React.Fragment>
          )}
          {page === 3 && (
            <>
              <OneStepNavigator
                title={text.navigatorTitle}
                stepBackFunction={setActiveScreen}
                stepBackTarget={SCREENS.BALANCE}
              />
              <Grid container className={classes.root} spacing={16}>
                <Card className={classes.card}>
                  <CardContent>
                    <ClipCardLogo src={successIcon} />
                    <Typography variant={'display3'} color={'textPrimary'}>
                      {text.successfulTransfer}
                    </Typography>
                    <SpeiAmountContainer>
                      <Typography variant={'display4'}>$</Typography>
                      <Typography
                        variant={'display4'}
                        style={{
                          fontSize: '2rem',
                          fontWeight: '600',
                          paddingRight: '0px',
                        }}
                        color={'textPrimary'}
                      >
                        {this.formatAmount(speiStatus.amount)}
                      </Typography>
                      <Typography variant={'display4'} color={'textPrimary'}>
                        .{speiStatus.amount.substr(-2, 2)}
                      </Typography>
                    </SpeiAmountContainer>
                    <Typography
                      style={{ color: theme.palette.text.primary }}
                      variant={'display1'}
                    >
                      {moment().format(
                        `D MMMM YYYY [ ${text.transferDateConnector} ] HH:mm:ss A`,
                      )}
                    </Typography>
                  </CardContent>
                  <hr className={classes.dashedLine} />
                  <CardContent>
                    <Typography variant={'display1'}>
                      {text.recipient}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      align={'center'}
                      color={'textPrimary'}
                    >
                      {speiStatus.recipient_name}
                    </Typography>
                    <Typography variant={'display1'}>
                      {text.clabeField}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      align={'center'}
                      color={'textPrimary'}
                    >
                      {speiStatus.recipient_account}
                    </Typography>
                    <Typography variant={'display1'}>
                      {text.referenceField}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      align={'center'}
                      color={'textPrimary'}
                    >
                      {speiStatus.description}
                    </Typography>
                    <Typography variant={'display1'}>
                      {text.trackingNumber}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      align={'center'}
                      color={'textPrimary'}
                    >
                      {speiStatus.tracking_key}
                    </Typography>
                    <Typography variant={'display1'}>
                      {text.referenceNumber}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      align={'center'}
                      color={'textPrimary'}
                    >
                      {speiStatus.reference}
                    </Typography>
                  </CardContent>
                </Card>
                <div className={classes.buttons}>
                  <ReturnButton
                    onClick={() => setActiveScreen(SCREENS.BALANCE)}
                    style={{ marginTop: theme.spacing.unit * 1 }}
                  >
                    {text.returnButton}
                  </ReturnButton>
                </div>
              </Grid>
              <WhatsAppModule isSmall={true}/>
            </>
          )}
          {page === 4 && (
            <div className={classes.animationContainer}>
              <Typography className={classes.onTheWayText} variant={'title'}>
                {text.onTheWay}
              </Typography>
              <Lottie
                animationData={SpeiOutAnimation}
                className={classes.onTheWayAnimation}
              />
            </div>
          )}
          {page === 5 && (
            <>
              <OneStepNavigator
                title={text.navigatorTitle}
                stepBackFunction={setActiveScreen}
                stepBackTarget={SCREENS.BALANCE}
              />
              <Grid container className={classes.root} spacing={16}>
                <Card className={classes.card}>
                  <CardContent>
                    <ClipCardLogo src={errorIcon} />
                    <Typography variant={'display3'} color={'textPrimary'}>
                      {text.unsuccessfulTransfer}
                    </Typography>
                    <SpeiAmountContainer>
                      <Typography variant={'display4'}>$</Typography>
                      <Typography
                        variant={'display4'}
                        style={{
                          fontSize: '2rem',
                          fontWeight: 600,
                          paddingRight: '0px',
                        }}
                        color={'textPrimary'}
                      >
                        {this.formatAmount(speiInfo.amount)}
                      </Typography>
                      <Typography variant={'display4'} color={'textPrimary'}>
                        .{speiInfo.amount.substr(-2, 2)}
                      </Typography>
                    </SpeiAmountContainer>
                    <Typography
                      style={{ color: theme.palette.text.primary }}
                      variant={'display1'}
                    >
                      {moment().format(
                        `D MMMM YYYY [ ${text.transferDateConnector} ] HH:mm:ss A`,
                      )}
                    </Typography>
                  </CardContent>
                  <hr className={classes.dashedLine} />
                  <CardContent>
                    <Typography variant={'display1'}>
                      {text.recipient}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      align={'center'}
                      color={'textPrimary'}
                    >
                      {speiInfo.recipient_name}
                    </Typography>
                    <Typography variant={'display1'}>
                      {text.clabeField}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      align={'center'}
                      color={'textPrimary'}
                    >
                      {speiInfo.recipient_account}
                    </Typography>
                    <Typography variant={'display1'}>
                      {text.referenceField}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      align={'center'}
                      color={'textPrimary'}
                    >
                      {speiInfo.description}
                    </Typography>
                    <Typography
                      variant={'display1'}
                      className={classes.failedTransferInfo}
                      color={'textPrimary'}
                    >
                      {text.failedTransferInfo}
                    </Typography>
                  </CardContent>
                </Card>
                <div className={classes.buttons}>
                  <RetryButton onClick={() => this.setState({ page: 1 })}>
                    {text.retry}
                  </RetryButton>
                  <ReturnButton
                    onClick={() => setActiveScreen(SCREENS.BALANCE)}
                  >
                    {text.returnButton}
                  </ReturnButton>
                </div>
              </Grid>
              <WhatsAppModule isSmall={true}/>
            </>
          )}
        </form>
        {ldFlags.enableSpeiGeolocation && (
          <Dialog
            classes={{ paper: classes.paper }}
            open={this.state.geoDialog}
          >
            <div className={classes.dialogContainer}>
              <Typography className={classes.dialogText} variant={'display2'}>
                {text.dialogTitle}
              </Typography>
              <Typography className={classes.dialogText} variant={'display1'}>
                {text.dialogMessage}
              </Typography>
              <div className={classes.groupedButtons}>
                <BaseButton
                  className={classes.clearButton}
                  onClick={() => this.handleClose()}
                >
                  {text.cancelDialog}
                </BaseButton>
                <BaseButton
                  onClick={async () => {
                    localStorage.setItem('geoDialog', 'true');
                    this.setState({ geoDialog: false });
                    await this.isGeoAvailable();
                  }}
                >
                  {text.okDialog}
                </BaseButton>
              </div>
            </div>
          </Dialog>
        )}
      </>
    );
  }
}

const selector = formValueSelector(Forms.TRANSFERS_FORM);

const mapStateToProps = (state) => {
  return {
    account: state.berlin.account_representation,
    user: state.user,
    balance: state.berlin.cardBalance,
    berlin: state.berlin,
    clabe: selector(state, 'clabe'),
    ldFlags: state.berlin.ldFlags,
    merchant: state.merchant,
    formValues: selector(state, 'name', 'clabe', 'reference', 'amount'),
  };
};

const mapDispatchToProps = {
  reset,
};

const ReduxTransfers = reduxForm(
  {
    form: Forms.TRANSFERS_FORM,
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
  },
  mapStateToProps,
)(Transfers);

const ThemedTransfers = withTheme()(ReduxTransfers);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ThemedTransfers),
);
