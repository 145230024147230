export const INVOICES_GET_TAX_PAYER_INFO = 'INVOICES_GET_TAX_PAYER_INFO';
export const INVOICES_SET_TAX_PAYER_INFO = 'INVOICES_SET_TAX_PAYER_INFO';
export const INVOICES_SWITCH_MONTHLY_REQUESTS =
  'INVOICES_SWITCH_MONTHLY_REQUESTS';
export const INVOICES_SET_CURRENT_REQUEST = 'INVOICES_SET_CURRENT_REQUEST';
export const INVOICES_DELETE_CURRENT_REQUEST =
  'INVOICES_DELETE_CURRENT_REQUEST';
export const INVOICES_GET_REQUEST_HISTORY = 'INVOICES_GET_REQUEST_HISTORY';
export const INVOICES_SET_HISTORY_PAGINATION =
  'INVOICES_SET_HISTORY_PAGINATION';
  export const INVOICES_SET_ZIPCODE_VALIDITY = 'INVOICES_SET_ZIPCODE_VALIDITY';
export const INVOICES_SET_CFDI_OPTIONS = 'INVOICES_SET_CFDI_OPTIONS';
export const INVOICES_SET_TAX_REGIME_OPTIONS = 'INVOICES_SET_TAX_REGIME_OPTIONS';
