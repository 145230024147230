import React from 'react';
import PropTypes from 'prop-types';
import { Clevertap } from '../index.js';

const ClevertapComponent = (props) => {
  const { renderComponent, events, ...rest } = props;
  const onEvent = {};

  const CheckAndExecuteCallback =
    (callback, e) =>
    (...args) => {
      if (props.validation) {
        const { payload, event, action } = e;
        event && Clevertap.event(event, action, payload );
      }
      callback?.(...args);
    };

  for (let i = 0; i < events.length; i++) {
    const { callbackName } = events[i];
    onEvent[callbackName] = CheckAndExecuteCallback(
      props[callbackName],
      events[i],
    );
    delete rest[callbackName];
  }
  return React.cloneElement(renderComponent, {
    ...rest,
    ...onEvent,
  });
};

ClevertapComponent.propTypes = {
  renderComponent: PropTypes.element,
  validation: PropTypes.bool,
  events: PropTypes.array,
};

ClevertapComponent.defaultProps = {
  renderComponent: <div />,
  validation: true,
  events: {},
};

export default ClevertapComponent;
