import React, { useContext, memo } from 'react';
import * as Styled from './styled';
import {
  getHour,
  amountFormat,
  getAmountColor,
  balanceFormat,
} from '../../utils';
import {
  ArrowUpwardRounded,
  ArrowDownwardRounded,
} from '@clipmx/clip-storybook/dist/components/Icons';
import { styles } from './styles';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ganancias from '../../../../assets/images/berlin/gananciasClip.svg';
import atm from '../../../../assets/images/berlin/atm.svg';
import compra from '../../../../assets/images/berlin/compra.svg';
import compraOnline from '../../../../assets/images/berlin/compra-online.svg';

import deposito from '../../../../assets/images/berlin/deposito.svg';

const useStyles = makeStyles(styles);

export const Transaction = (props) => {
  const { transaction, selectTransaction } = props;
  const classes = useStyles(props);
  const transactionIcon = (type) => {
    switch (type) {
      case 'CLIP_FINANCIAL_DEPOSIT':
        return <ArrowDownwardRounded className={classes.arrowDown}/>;
      case 'CARD_CONTACTLESS':
      case 'CARD_POS':
        return <ArrowUpwardRounded className={classes.arrowUp}/>;
      case 'CARD_ATM_BALANCE':
      case 'CARD_ATM_WITHDRAWAL':
        return <ArrowUpwardRounded className={classes.arrowUp}/>;
      case 'ACCOUNT_INTERBANK_OUT':
        return <ArrowUpwardRounded className={classes.arrowUp}/>;
      case 'CARD_ECOM':
        return <ArrowUpwardRounded className={classes.arrowUp}/>;
      default:
        return <ArrowDownwardRounded className={classes.arrowDown}/>;
    }
  };
  return (
    <React.Fragment>
      <Styled.TransactionContainer
        onClick={() => selectTransaction(transaction)}
      >
        <Styled.TransactionIconContainer>
          {transactionIcon(transaction.movement_type)}
        </Styled.TransactionIconContainer>
        <Styled.TransactionInfoContainer>
          <Styled.TransactionDescription>
            {transaction.title || transaction.description}
          </Styled.TransactionDescription>
          <Styled.TransactionHour>
            {getHour(transaction.movement_date)}
          </Styled.TransactionHour>
        </Styled.TransactionInfoContainer>
        <Styled.TransactionAmountsContainer>
          <Styled.TransactionAmount
            positive={getAmountColor(transaction.cash_flow)}
          >
            {amountFormat(
              transaction.amount || props.cardBalance,
              transaction.cash_flow,
            )}
          </Styled.TransactionAmount>
          <Styled.TransactionBalance>
            {balanceFormat(transaction.balance_after_movement)}
          </Styled.TransactionBalance>
        </Styled.TransactionAmountsContainer>
      </Styled.TransactionContainer>
      {props.divider ? (
        <Styled.TransactionDivider></Styled.TransactionDivider>
      ) : null}
    </React.Fragment>
  );
};

export default memo(Transaction);
