import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import ReactMarkdown from 'react-markdown';
import urls from '../../../../helpers/urls';
import getUrl from '../../../../helpers/getUrl';
import FormButton from '../../../../helpers/FormUtils/FormButton';
import PrestaClipCongrats from '../../../../assets/images/prestaclipcongrats.svg';
import {
  PreapprovalStatus,
  LoanTypes,
  LoansTabs,
  Forms,
} from '../../../../constants/AppConstants';
import {
  loansProviderCodes,
  getProviderCode,
} from '../../../../constants/ProviderCodes';
import { ExternalLenderFooter } from '../Footer';
import LoansRedirection from '../Redirection';
import {
  getUserName,
  getPreapprovals,
  isLoansFormSent,
} from '../../redux/selectors';
import GoogleAnalytics from '../../GoogleAnalytics';
import {
  Group,
  Congratulation,
  CongratulationImage,
  CongratulationMessage,
  ButtonContainer,
} from './styled';
import text from './text';

const regexAcction = RegExp(
  /\/loans\/[mot,aph,mrp,cto,r2,avl]+\/congrats\/[\w]+[/]*/g,
);
//'/loans/:lender/congrats/:action'
const regexVersion = RegExp(
  /\/loans\/[mot,aph,mrp,cto,r2,avl]+\/[vV]+[\d]{1}\/congrats[/]*/g,
);
//congratsVersion: '/loans/:lender/:version/congrats',
const regexVersionAction = RegExp(
  /\/loans\/[mot,aph,mrp,cto,r2,avl]+\/[vV]+[\d]{1}\/congrats\/[\w]*[/]*/g,
);
//congratsVersionAction: '/loans/:lender/:version/congrats/:action',

const regexActionPersonalized = RegExp(
  /\/loans\/personalized\/[mot,aph,mrp,cto,r2,avl]+\/congrats\/[\w]+[/]*/g,
);
//'/loans/personalized/:lender/congrats/:action'
const regexVersionPersonalized = RegExp(
  /\/loans\/personalized\/[mot,aph,mrp,cto,r2,avl]+\/[vV]+[\d]{1}\/congrats[/]*/g,
);
//congratsVersion: '/loans/:lender/:version/congrats',
const regexVersionActionPersonalized = RegExp(
  /\/loans\/personalized\/[mot,aph,mrp,cto,r2,avl]+\/[vV]+[\d]{1}\/congrats\/[\w]*[/]*/g,
);
//congratsVersionAction: '/loans/:lender/:version/congrats/:action',
class CongratulationPage extends Component {
  getBaseLocation = () =>
    this.props.location.pathname.startsWith(urls.mobile) ? urls.mobile : '';
  getPreapprovalSelected = (preapprovals) =>
    preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.ACCEPTED].includes(preapproval.status) &&
        !preapproval.loan_id,
    );
  goDashboard = () => {
    this.props.history.push(getUrl(urls.dashboard));
  };

  validateCongratsURL = () => {
    const {
      isFormSent,
      loansFormVersion,
      preapprovals = [],
      showCashAdvance,
      flags: { loansAphSignShowFeatureWeb: signAvailable },
    } = this.props;
    const currentURL = this.props.location.pathname.replace(urls.mobile, '');

    const accepted = this.getPreapprovalSelected(preapprovals);
    if (!isFormSent && accepted.length === 0) {
      this.props.history.push(getUrl(`${this.getBaseLocation()}${urls.loans}`));
      return false;
    }
    const lender = accepted[0]?.provider_code.toLowerCase();
    const type = accepted[0]?.type;
    if (
      lender === loansProviderCodes.APH.toLowerCase() &&
      type === LoanTypes.REGULAR &&
      signAvailable &&
      !regexAcction.test(currentURL)
    ) {
      this.props.history.replace(
        getUrl(
          `${this.getBaseLocation()}${urls.loansCongratsAction
            .replace(':lender', lender)
            .replace(':action', 'forsign')}`,
        ),
      );
      return false;
    }
    if (
      isFormSent &&
      type === LoanTypes.REPEAT &&
      (currentURL.indexOf(lender) < 0 || !regexAcction.test(currentURL))
    ) {
      this.props.history.replace(
        getUrl(
          `${this.getBaseLocation()}${urls.loansCongratsAction
            .replace(':lender', lender)
            .replace(':action', 'repeat')}`,
        ),
      );
      return false;
    }
    if (type === LoanTypes.REFILL && !regexAcction.test(currentURL)) {
      this.props.history.replace(
        getUrl(
          `${this.getBaseLocation()}${urls.loansCongratsAction
            .replace(':lender', lender)
            .replace(':action', 'refill')}`,
        ),
      );
      return false;
    }
    if (
      type === LoanTypes.REGULAR &&
      loansFormVersion &&
      showCashAdvance &&
      !regexVersionAction.test(currentURL)
    ) {
      /**  congratsVersionAction: '/loans/:lender/:version/congrats/:action',*/
      let url = urls.congratsVersionAction;
      this.props.history.replace(
        getUrl(
          `${this.getBaseLocation()}${url
            .replace(':lender', lender)
            .replace(
              ':version',
              Forms.VERSION_4.toLowerCase(),
            )
            .replace(':action', 'ca')}`,
        ),
      );
      return false;
    }
    if (
      type === LoanTypes.CUSTOM &&
      loansFormVersion &&
      showCashAdvance &&
      !regexVersionActionPersonalized.test(currentURL)
    ) {
      /**  congratsVersionAction: '/loans/:lender/:version/congrats/:action',*/
      let url = urls.congratsPersonalizedVersionAction;
      this.props.history.replace(
        getUrl(
          `${this.getBaseLocation()}${url
            .replace(':lender', lender)
            .replace(
              ':version',
              Forms.VERSION_4.toLowerCase(),
            )
            .replace(':action', 'ca')}`,
        ),
      );
      return false;
    }
    if (
      type === LoanTypes.CUSTOM &&
      loansFormVersion &&
      !showCashAdvance &&
      !regexVersion.test(currentURL)
    ) {
      /** congratsVersion: '/loans/:lender/:version/congrats', */
      let url = urls.congratsVersion;
      this.props.history.replace(
        getUrl(
          `${this.getBaseLocation()}${url
            .replace(':lender', lender)
            .replace(
              ':version',
              Forms.VERSION_4.toLowerCase(),
            )}`,
        ),
      );
      return false;
    }
    if (
      type === LoanTypes.CUSTOM &&
      loansFormVersion &&
      !showCashAdvance &&
      !regexVersionPersonalized.test(currentURL)
    ) {
      /** congratsVersion: '/loans/:lender/:version/congrats', */
      let url = urls.congratsPersonalizedVersion;
      this.props.history.replace(
        getUrl(
          `${this.getBaseLocation()}${url
            .replace(':lender', lender)
            .replace(
              ':version',
              Forms.VERSION_4.toLowerCase(),
            )}`,
        ),
      );
      return false;
    }
    if (
      type === LoanTypes.REGULAR &&
      !loansFormVersion &&
      showCashAdvance &&
      !regexAcction.test(currentURL)
    ) {
      /** loansCongratsAction: '/loans/:lender/congrats/:action', */
      let url = urls.loansCongratsAction;
      this.props.history.replace(
        getUrl(
          `${this.getBaseLocation()}${url
            .replace(':lender', lender)
            .replace(':action', 'ca')}`,
        ),
      );
      return false;
    }
    if (
      type === LoanTypes.CUSTOM &&
      !loansFormVersion &&
      showCashAdvance &&
      !regexActionPersonalized.test(currentURL)
    ) {
      /** loansCongratsAction: '/loans/:lender/congrats/:action', */
      let url = urls.loansCongratsPersonalizedAction;
      this.props.history.replace(
        getUrl(
          `${this.getBaseLocation()}${url
            .replace(':lender', lender)
            .replace(':action', 'ca')}`,
        ),
      );
      return false;
    }
    if (
      RegExp(/\/loans\/personalized\/[mot,aph,mrp,cto,r2,avl]+\//g).test(currentURL) &&
      currentURL.indexOf(lender) < 0
      && type === LoanTypes.CUSTOM
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${urls.congratsPersonalized.replace(
            ':lender',
            lender,
          )}`,
        ),
      );
      return false;
    }
    if (
      RegExp(/\/loans\/[mot,aph,mrp,cto,r2,avl]+\//g).test(currentURL) &&
      currentURL.indexOf(lender) < 0
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${urls.congrats.replace(
            ':lender',
            lender,
          )}`,
        ),
      );
      return false;
    }
    return true;
  };

  componentDidMount() {
    const { preapprovals = [] } = this.props;
    preapprovals.length > 0 && this.validateCongratsURL();
  }
  componentDidUpdate() {
    const { preapprovals = [] } = this.props;
    preapprovals.length > 0 && this.validateCongratsURL();
  }

  render() {
    const {
      preapprovals,
      flags: { loansAphSignShowFeatureWeb: signAvailable },
    } = this.props;
    const preapprovalSelected = this.getPreapprovalSelected(preapprovals);
    const isMobileWebView = this.props.location.pathname.startsWith(
      urls.mobile,
    );
    const type =
      preapprovalSelected.length > 0 ? preapprovalSelected[0]?.type : '';
    const providerCode =
      preapprovalSelected.length > 0
        ? preapprovalSelected[0]?.provider_code
        : getProviderCode(this.props.location.pathname.toUpperCase());
    return (
      <LoansRedirection tab={LoansTabs.NEW_OFFER}>
        {((providerCode === loansProviderCodes.APH &&
          (type === LoanTypes.REPEAT || !signAvailable)) ||
          providerCode !== loansProviderCodes.APH) && (
          <GoogleAnalytics>
            <Group>
              <Congratulation>{text.congratulation}</Congratulation>
              <CongratulationImage src={PrestaClipCongrats} />
              <CongratulationMessage>
                <ReactMarkdown
                  source={
                    providerCode === loansProviderCodes.MRP
                      ? text.successSubmit
                      : text.successSubmitMO
                  }
                />
              </CongratulationMessage>
              {!isMobileWebView && (
                <ButtonContainer>
                  <FormButton
                    variant="contained"
                    text={text.textButton}
                    onClick={this.goDashboard}
                  />
                </ButtonContainer>
              )}
              <ExternalLenderFooter />
            </Group>
          </GoogleAnalytics>
        )}
      </LoansRedirection>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userName: getUserName(state),
    preapprovals: getPreapprovals(state),
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
    isFormSent: isLoansFormSent(state),
  };
};

export default withRouter(
  connect(mapStateToProps, null)(withLDConsumer()(CongratulationPage)),
);
