const usePublicProfile = ({
  alias = '',
  email = '',
  image = '',
  public_description = '',
  public_name = '',
  profile_active = false,
  show_address = false,
  show_email = false,
  show_phone = false,
  hasProfile = false,
}) => {
  //vamos a recibir el valor por defecto de las props
  let payload = {
      alias: alias,
      email: email,
      image: image,
      public_description: public_description,
      public_name: public_name,
      profile_config: {
          profile_active: profile_active,
          show_address: show_address,
          show_email: show_email,
          show_phone: show_phone,
      },
  };
  const changesProfileConfigs = (profileConfigName, value) => {
      const new_obj = { ...payload };
      new_obj[profileConfigName]=value;
      payload = new_obj;
  }

  const changeProfileConfigsAttributes = ( attributeName, value ) => {
      const new_obj = payload.profile_config;
      new_obj[attributeName] = value;
      changesProfileConfigs('profile_config', new_obj)
  }

  return {
     payload,
     changesProfileConfigs,
     changeProfileConfigsAttributes,
  }
}
export default usePublicProfile;
