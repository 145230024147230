/* eslint-disable  max-len */
export default {
  termsTitle: 'Aviso de privacidad',
  title1: 'Identidad y domicilio del responsable',
  body1: `CACAO PAYCARD SOLUTIONS, S.A. DE C.V., INSTITUCIÓN DE FONDO DE PAGO ELECTRÓNICO (“CACAOPAY”) con domicilio en Boulevard Manuel Ávila Camacho 50, 5º piso, Lomas de Chapultepec, C.P. 11000, Miguel Hidalgo, Ciudad de México, que en este caso es el responsable del uso y protección de sus Datos Personales, en cumplimiento de la Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP), pone a su disposición el presente Aviso de Privacidad para informarle la forma en que recabamos, transferimos y utilizamos sus Datos Personales. Los Datos Personales son obtenidos principalmente del Sitio Web de CACAOPAY y de la plataforma tecnológica denominada Plataforma Paycard.

    El departamento legal de CACAOPAY es el designado para dar trámite a las solicitudes de derechos ARCO que se mencionan más adelante, así como para fomentar la protección de los Datos Personales al interior de CACAOPAY.

    CACAOPAY cuenta con las medidas de seguridad, administrativas, técnicas y físicas necesarias para salvaguardar la privacidad y confidencialidad de sus datos personales, así como para cumplir con los principios de protección de datos personales previstos en LFPDPPP.

    CACAOPAY recibe, recaba, maneja, procesa, trata y conserva los Datos Personales de conformidad con los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad a efecto de asegurar la privacidad, confidencialidad e integridad de conformidad con lo dispuesto por la LFPDPPP. `,
  title2: 'Definiciones',
  body2: [
    {
      subinfo:
        'Para efectos del siguiente Aviso de Privacidad se entiende por:',
      title: 'a. Datos personales',
      body: 'Cualquier información concerniente a una persona física identificada o identificable. ',
    },
    {
      title: 'b. Derechos ARCO',
      body: 'Son los derechos de Acceso, Rectificación, Cancelación u Oposición, respecto a sus Datos Personales.',
    },
    {
      title: 'c. Legislación Vigente Aplicable: ',
      body: `Se refiere a la LFPDPPP, su reglamento, y en
             general a cualquier ordenamiento jurídico que regule la recopilación, almacenamiento y transmisión de Datos Personales. `,
    },
    {
      title: 'd. Responsable',
      body: 'Para los efectos del presente Aviso de Privacidad, el responsable del tratamiento, uso y protección de sus Datos Personales será CACAOPAY.',
    },
    {
      title: 'e. Sitio Web',
      body: `Sitio Web propiedad de CACAOPAY cuya dirección es: www.cacaopaycard.com.
            Plataforma Paycard: Entiéndase de manera indistinta o de manera conjunta la Aplicación y el Sitio Web.`,
    },
    {
      title: 'f. Titular',
      body: 'Persona física a quien corresponden los Datos Personales (en adelante “Usted”). ',
    },
  ],
  title3: 'Datos personales recabados',
  body3: `Los Datos Personales que usted libre, entregue  y voluntariamente proporciona a CACAOPAY incluyen, enunciativamente, sin limitar:

    CACAO PAYCARD SOLUTIONS, S.A. DE C.V. Blvd. Manuel Ávila Camacho número 50 piso 5,
    Lomas de Chapultepec, Miguel Hidalgo, CDMX, CP. 11000 / T. 5005.9900 / cacaopaycard.com
    Datos personales de identificación: nombre, domicilio (calle, número, colonia, código postal, alcaldía o municipio, entidad federativa, ciudad.), fecha de nacimiento, nacionalidad,  CURP, Firma electrónica avanzada.

    Datos de contacto: teléfono y correo electrónico.
    Datos patrimoniales y/o financieros:  RFC, clabe de cuenta bancaria, datos transaccionales para prevención de lavado de dinero y financiamiento al terrorismo.
    Datos laborales: profesión u ocupación, actividad o giro del negocio, ubicación del centro de trabajo.
    Datos de ubicación geográfica: geolocalización del dispositivo electrónico utilizado para el acceso al Sitio Web o a la Plataforma Paycard.

    Se presume que usted proporciona Datos Personales a CACAOPAY, en su carácter de  Titular de los mismos. En caso de que usted proporcione Datos Personales de otros titulares, manifiesta contar con el consentimiento de dichos Titulares para proporcionarnos los Datos Personales y reconoce su obligación de informar a dicho Titular o titulares que ha proporcionado sus datos, así como de los lugares en los que se encuentra a su disposición el presente Aviso de Privacidad para poder llevar a cabo la prestación de cualquier servicio requerido.

    Cabe señalar que CACAOPAY debe recabar los datos necesarios para cumplir con las disposiciones legales aplicables en materia de prevención y detección de actos, omisiones u operaciones que pudieran favorecer, prestar ayuda, auxilio o cooperación de cualquier especie para la comisión de los delitos en materia de lavado de dinero y/o el financiamiento al terrorismo, así como fraude, por lo que podrá solicitarse información adicional en caso de que la regulación aplicable así lo establezca. `,
  title4: 'Finalidades principales y/o usos de los datos personales',
  body4: `El objetivo principal (finalidades primarias) que persigue CACAOPAY con la recopilación de sus datos personales es:
    Registro en la Plataforma Paycard propiedad de CACAOPAY.
    Prestación de servicios de emisión, administración, redención y transmisión de fondos de pago electrónico,  y de los productos por parte de CACAOPAY.
    Identificación de los usuarios, clientes, suscriptores, trabajadores, interesados, o cualquier otra persona que ingrese al Sitio Web o a la Plataforma Paycard.
    Creación de canales de comunicación entre Usted y CACAOPAY.
    Cumplimento con lo requerido por cualquier autoridad federal, estatal o municipal para la operación de CACAOPAY.
    Cumplimiento de actos que sean necesarios para proveer los servicios y productos requeridos por Usted, así como para hacer cumplir y/o ejecutar el contrato respectivo. `,
  title5: 'Finalidades accesorias del uso de los datos personales',
  body5: [
    {
      subinfo: `CACAOPAY tratará los Datos Personales del Titular adicionalmente a las finalidades señaladas en el párrafo anterior, para las siguientes finalidades secundarias:
            Para fines estadísticos.
            Para informar sobre cambios o nuevos productos o servicios.
            Evaluar la calidad del servicio, determinar la calidad de estos.
            Intercambiar información necesaria para atender auditorías internas, externas y por autoridades.
            Para fines comerciales, mercadotécnicos, publicitarios y/o de prospección comercial y para otorgar beneficios y dar cumplimiento a obligaciones contraídas y para realizar estudios internos sobre hábitos de consumo.`,
      title: 'a. Recopilación / Conservación de los datos personales',
      body: `La cantidad de Datos Personales solicitados estará únicamente relacionada con los datos que necesitamos para ofrecer servicios o productos a nuestros clientes y/o usuarios.

            Dichos Datos Personales pueden ser obtenidos directamente de Usted, ya sea personalmente o a través de cualquier medio electrónico, óptico, sonoro, visual o a través de cualquier otra tecnología y de otras fuentes permitidas por las disposiciones legales aplicables, o bien, generados con motivo de los servicios solicitados o contratados por usted con CACAOPAY o cualquiera de las empresas que son subsidiarias o afiliadas.

            Los Datos Personales que proporcione se conservarán o destruirán según las necesidades de CACAOPAY según lo establecido por las leyes locales o cuando así lo solicite Usted. `,
    },
    {
      title: 'b. Transferencia de datos personales',
      body: `CACAOPAY podrá realizar las transferencias nacionales e internacionales de Datos Personales sin el consentimiento del Titular entre sus empresas filiales o afiliadas y en los demás supuestos previstos en el artículo 37 de la LFPDPPP única y exclusivamente para el cumplimiento de los servicios que contrata con CACAOPAY. También, podrá realizar transferencias de Datos Personales a entidades proveedoras de servicios para el cumplimiento de las obligaciones contractuales acordadas.

            Los terceros y las entidades receptores de Datos Personales, en términos del párrafo anterior, asumirán las mismas obligaciones y/o responsabilidades que CACAOPAY ha asumido con Usted, en términos del presente Aviso de Privacidad.

            Usted acepta que sus Datos Personales podrán ser transferidos en los términos descritos en la Ley, su Reglamento y el presente Aviso de Privacidad.

            CACAOPAY no comercializa Datos Personales ni los vende a terceras partes.

            Se informa a usted que la información y documentación relativa a las actividades y servicios que presta CACAOPAYCARD, tendrá el carácter confidencial. `,
    },
    {
      title: 'c. Revocación',
      body: `Usted podrá revocar su consentimiento, así como oponerse para el tratamiento de sus Datos Personales para las finalidades que no son indispensables para la relación jurídica que dio origen al producto o servicio que hubiese contratado.

            Para efectuar dicha revocación, Usted podrá acudir al domicilio de CACAOPAY o comunicarse vía correo electrónico a la dirección: contacto@cacaopaycard.com para obtener el formato respectivo, a través del cual pueda revocar o limitar el uso o divulgación de sus Dato Personales. Asimismo, podrá revocar el consentimiento para tratar sus Datos Personales con finalidad de prospección comercial, enviando correo electrónico a la dirección antes señalada, y lo excluiremos de nuestras campañas con fines mercadotécnicos, publicitarios o de prospección comercial. `,
    },
    {
      title:
        'd. Medios y procedimiento para ejercer los derechos de acceso, rectificación, cancelación u oposición (ARCO).',
      body: `Usted o su representante legal debidamente acreditado podrán limitar el uso o divulgación de sus Datos
            Personales; así como ejercer, cuando procedan, los derechos de Acceso, Rectificación, Cancelación u Oposición que la LFPDPPP prevé mediante el formato que CACAOPAY le proporcione para tales efectos, presentándolo en el domicilio de CACAOPAY o mediante correo electrónico a la dirección: contacto@cacaopaycard.com. Es importante mencionar que el ejercicio de cualquiera de dichos derechos no es requisito previo ni impide el ejercicio de otro derecho.

            Puede utilizar el correo electrónico contacto@cacaopaycard.com para solicitarnos la eliminación, acceso, rectificación, cancelación u oposición (los “Derechos ARCO”) al uso de Datos Personales en nuestras listas de correo o en nuestras operaciones. Para tal situación, Usted deberá redactar la petición, y empezaremos a trabajar diligentemente para localizar y solucionar el problema con prontitud. La anterior solicitud, será sin perjuicio de su derecho de acudir ante el Instituto Nacional de Transparencia Acceso a la Información y Protección de Datos Personales.

            CACAOPAY tendrá un plazo máximo de 20 (veinte) días hábiles contados a partir de la solicitud, para informar sobre la procedencia de la solicitud de acceso, ratificación, cancelación u oposición.

            Esta información se puede almacenar mediante sistemas manuales o electrónicos con acceso limitado, con el objetivo de protegerla contra pérdidas, uso fraudulento, accesos no autorizados, revelación, modificación o destrucción. La solicitud deberá contener y acompañarse de lo siguiente:  (i) su nombre y correo electrónico para comunicarle nuestra respuesta; (ii) los documentos que acrediten su identidad y/o la de su representante legal; (iii) la descripción clara y precisa de los datos personales respecto de los cuales se busca ejercer alguno de los derechos mencionados; (iv) cualquier otro documento o elemento que facilite la localización de sus datos personales; y (v) tratándose de solicitudes de rectificación, deberá indicar además las modificaciones a realizarse y aportar la documentación que sustente su petición, en caso de que ésta sea procedente.

            Conforme a la LFPDPPP se hace de su conocimiento que, sus Datos Personales podrán ser tratados sin su consentimiento cuando: (i) esté previsto en una ley; (ii) los datos personales figuren en fuentes de acceso público; (iii) los datos personales se sometan a un procedimiento previo de disociación; (iv) tenga el propósito de cumplir obligaciones derivadas de una relación jurídica entre el titular y el responsable; (v) exista una situación de emergencia que potencialmente pueda dañar a un individuo en su persona o en sus bienes y/o (vii) se dicte resolución de autoridad competente.

            Al momento en que Usted haga la revocación y/o cancelación del uso de sus Datos Personales existe la posibilidad de que CACAOPAY no pueda seguir prestándole sus Servicios o Productos.  `,
    },
    {
      title: 'e. Uso de cookies y beacons',
      body: `Nuestro Sitio Web puede utilizar cookies, web beacons y otras tecnologías a través de las cuales es posible monitorear su comportamiento como usuario de Internet, brindarle un mejor servicio y experiencia de usuario al navegar en el Sitio Web, así como ofrecerle nuevos productos y servicios basados en sus preferencias.

            Los Datos Personales que obtenemos de estas tecnologías de rastreo son los siguientes: horario de navegación, tiempo de navegación en nuestra página de Internet, secciones consultadas, y páginas de Internet accedidas previo a la nuestra.

            Usted, conoce que el uso de los servicios y productos de CACAOPAY, puede utilizar distintas tecnologías, y se encuentra de acuerdo en que CACAOPAY recabe los datos de geolocalización del dispositivo utilizado para acceder al Sitio Web y Plataforma Paycard, `,
    },
    {
      title: 'f. Consentimiento',
      body: `Usted tendrá pleno control y decisión de sus datos personales, por ello que usted, al proporcionar sus Datos Personales de manera personal o a través de cualquier medio electrónico, óptico, sonoro, visual o a través de cualquier otra tecnología y de otras fuentes permitidas por la LFPDPPP, confirma y acepta que ha leído y otorga su consentimiento expreso a que sus Datos Personales sean tratados de acuerdo con los términos y condiciones de este Aviso de Privacidad.

            Al no existir manifestación de oposición alguna por parte de Usted respecto al presente Aviso de Privacidad, se entenderá que el Usted otorga a CACAOPAY su consentimiento para llevar a cabo el tratamiento de los Datos Personales que hubieran sido proporcionados y/o los que con motivo de alguna de las finalidades establecidas en el presente proporcione en lo futuro.

            La negativa para el uso de sus Datos Personales para estas finalidades podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros. `,
    },
    {
      title: 'g. Modificaciones al aviso de privacidad',
      body: `Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos. Dichas modificaciones estarán disponibles en la presente página de internet o aplicación móvil (en su caso) en el apartado de aviso de privacidad, y se señalará la fecha de última actualización del documento.

            Cualquier modificación al presente aviso le será notificada a través de cualquiera de nuestros canales de comunicación.  `,
    },
    {
      title: 'h. Legislación aplicable, jurisdicción y competencia',
      body: `El presente Aviso de Privacidad que se hace de su conocimiento se rige por la regulación de la LFPDPPP y demás leyes aplicables y vigentes en la Ciudad de México. La aceptación del presente Aviso de Privacidad implica su sometimiento expreso a los tribunales competentes de la Ciudad de México, para cualquier controversia o reclamación en cuanto al mismo.
            [*Casilla de consentimiento] Otorgo mi consentimiento para el tratamiento de mis Datos Personales que realice CACAOPAY para las finalidades que se describen en este Aviso, de manera expresa y particularmente, por lo que hace a mis datos financieros y patrimoniales. `,
    },
  ],
  lastUpdated: 'Última actualización: 24 de noviembre de 2020.',
};
