const styles = theme => {
  return {
    root: {
      width: '100vw',
      maxWidth: '23.5rem',
      minHeight: '600px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: '#F3F3F3',
      overflow: 'auto',
      paddingTop: '4rem',
    },
    title: {
      textTransform: 'lowercase',
      '&::first-letter': {
        textTransform: 'capitalize',

      },
    },
    sectionLine: {
      border: 'none',
      borderTop: 'solid 1px #e6e8e9',
      margin: '0 1.5rem',
    },
    sectionSplit: {
      paddingTop: '1rem',
      paddingBottom: '0.5rem',
    },
    section: {
      width: '100%',
      paddingTop: '1.5rem',
    },
    positive: {
      color: theme.palette.positive.main,
    },
    amount: {
      margin: '0',
      marginTop: '5px',
    },
    dateSection: {
      marginBottom: '1.5rem',
      marginTop: '0.5rem',
    },
    helpSection: {
      marginTop: '2.6rem',
      paddingLeft: '1.5rem ',
      paddingRight: '1.5rem',
    },
    helpText: {
      marginBottom: 0,
      padding: 0,
    },
    phoneContianer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '0.5rem',
    },
    phone: {
      height: '1.5rem',
      width: '1.5rem',
      objectFit: 'contain',
      marginRight: '0.5rem',
    },
  }

};
export default styles;
