export default {
  greeting: 'Hola,',
  crediClip: 'Adelanto de Ventas Clip',
  titleP1: '¡Hola, ',
  titleP2: '! tienes un préstamo disponible para invertir en tu negocio, mira las 3 opciones que tenemos para ti:',
  subtitle:
    'Personaliza tu oferta, realiza tu solicitud y pronto recibirás tu dinero.',
  expiration: (expirationDate) =>
    `**Vigencia:** Tienes hasta el **${expirationDate}** para solicitar tu `,
  maxLimit: 'Plazo:',
  months: 'meses',
  fixedCharge: 'Pago mensual: ',
  total: 'Total a pagar: ',
  refund: 'Forma de pago: ',
  dailySales: 'retención de ventas diarias',
  buttonAmmount: 'Solicitar ',
  recommendedLabel: 'Recomendado',
  amountTitles: ['Crece tu negocio', 'Renuévate', 'Nuevos clientes'],
  amountSubtitles: [
    'Renuévate y date a conocer',
    'Invierte en nuevos productos',
    'Promocionate en redes sociales',
  ],
  stepsTitle: 'Beneficios de tu Presta Clip',
  stepsDescription: `Con Presta Clip podrás recibir un adelanto de tus ventas para llegar 
  a nuevos clientes, renovar o comprar más productos, o crecer tu negocio como tú lo desees.`,
  stepOneTitle: 'Fácil de solicitar',
  stepOneDescription:
    'Elige una de las tres ofertas disponibles e inicia tu solicitud en un solo clic.',
  stepTwoTitle: 'Rápido de obtener',
  stepTwoDescription:
    'Recibe tu Presta Clip en 24 horas, una vez que la solicitud sea aprobada.',
  stepThreeTitle: 'Pagas como vendes',
  stepThreeDescription:
    'Pagarás tu Presta Clip con un porcentaje de tus ventas diarias. Sin enganche ni aval.',
  preSamsGift: '+ un regalo',
  postSamsGift: 'por parte de',
  importantNote: 'Importante: ',
  importantNoteText: 
    'Si no cubres el pago mínimo mediante retenciones, deberás cubrirlo por transferencia ó depósito bancario.',
};