import { createMuiTheme } from '@material-ui/core/styles';
import { rgba } from 'polished';

const ClipTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#FC4C02',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#09A5B5',
      contrastText: '#FFF',
    },
    text: {
      primary: '#6A6A6A',
      secondary: rgba('#6A6A6A', 0.64),
    },
    success: {
      main: '#03DAC6',
      light: '#F2F6F0',
      dark: '#53843B',
    },
    error: {
      main: '#F54B5E',
      light: '#F7E8E8',
      dark: '#A8093C',
    },
    grey: {
      light: '#F0F1F2',
      dark: '#808B91',
    },
    warning: {
      main: '#F8C51C',
    },
    background: {
      paper: '#FFF',
      default: '#FAFAFA',
    },
    action: {
      active: rgba('#FC4C02', 0.12),
      hover: rgba('#FC4C02', 0.12),
      hoverOpacity: 0.12,
      selected: rgba('#FC4C02', 0.14),
    },
    naranja: {
      bloodOrange: '#FC4C02',
      teal: '#0b99ac',
      slate: '#455A64',
      lightGreyBrown: '#6E6E6E',
      greyBrown: '#4A4A4A',
      cerulean: '#0288D1',
      blueGrey: '#78909C',
      grey: '#B1B1B1',
      lightGrey: '#ECEFF1',
      iceBlue: '#F8FBFE',
      neonPink: '#F54B5E',
      neonTeal: '#03DAC6',
      yellow: '#F8C51C',
      bannerShadow: 'rgba(100,100,100,0.22)',
      contentShadow: 'rgba(0,0,0,0.50)',
      boxShadow: 'rgba(0,0,0,0.16)',
    },
    teal: {
      base: '#007D8E',
    },
  },
  customShadows: {
    toolbar: '0 8px 32px 0 rgba(0,0,0,0.08)',
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: ['Barlow', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {
    MuiButton: {
      raised: {
        minHeight: 48,
        fontWeight: 'bold',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#B1B1B1',
        '&$focused': {
          color: '#FC4C02 !important',
        },
      },
      focused: {
        '&$focused': {
          color: '#FC4C02',
        },
      },
    },
  },
});

export default ClipTheme;
