import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { allowAlphaNumerical } from '../../utils';

import { Forms } from '../../../../constants/AppConstants';
import { required } from '../../../../helpers/validate';

import BerlinService from '../../service';

import { renderRadio } from '../../Commons/BerlinInputs/FormUtils';
import ProgressNavigator from '../../Commons/ProgressNavigator';
import {
  shortStates,
  nationalities,
  country,
} from '../../Commons/FormSelectValues';
import renderSelectField from '../../Commons/BerlinInputs/SelectOnboarding';
import renderClipInput from '../../Commons/BerlinInputs/Input';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import {EVENTS, Clevertap} from '../../../../helpers/Clevertap';
import WaitingForDataValidation from '../WaitingForDataValidation';

import text from './text';
import styles from './styles';

const useStyles = makeStyles(styles);
const checkValue = (str, max) => {
  if (str.charAt(0) !== '0' || str === '00') {
    var num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str =
      num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
        ? '0' + num
        : num.toString();
  }
  return str;
};
const formatDate = (value, prevValue) => {
  if (!value) return value;

  const valueOnlyNumbers = value.replace(/[^\d]/g, '');
  const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');

  if (valueOnlyNumbers === prevValueOnlyNumbers) return value;

  const month = valueOnlyNumbers.slice(0, 2);
  const day = valueOnlyNumbers.slice(2, 4);
  const year = valueOnlyNumbers.slice(4, 8);

  if (valueOnlyNumbers.length < 2) return `${month}`;
  if (valueOnlyNumbers.length === 2) return `${month}`;
  if (valueOnlyNumbers.length < 4) return `${month} / ${day}`;
  if (valueOnlyNumbers.length === 4) return `${month} / ${day}`;
  if (valueOnlyNumbers.length > 4) return `${month} / ${day} / ${year}`;
};
const checkAllowAlphaNumerical = (maxLength, e) => {
  e.target.value = allowAlphaNumerical(maxLength, e.target.value);
};

export const validDate = (value) => {
  const formatDate = value.split(' / ').reverse().join('/');
  const birthday = new Date(formatDate);
  birthday.setDate(birthday.getDate() + 1);
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  const year = Math.abs(ageDate.getUTCFullYear() - 1970);
  if (birthday.getFullYear() >= new Date().getFullYear()) {
    return text.dateInvalid;
  }
  return value && year >= 18 ? undefined : text.underAge;
};
export const PersonalInfo = (props) => {
  const {
    nextStep,
    selectedGender,
    skipCurp,
    personalInfoValues,
    setCurp,
    setSkipCurp,
    prevStep,
    step,
    user,
    totalStep,
    invalid,
    dispatch,
    setCurpError,
  } = props;

  const classes = useStyles(props);
  const [waiting, setWaiting] = useState(false);
  const sendInfo = async () => {
    let personData = { ...personalInfoValues };
    personData['birth_date'] = personData['birth_date']
      .split(' / ')
      .reverse()
      .join('-');
    personData.citizenship = personData.nationality;

    if (skipCurp) {
      setWaiting(true);
      try {
        let result = await BerlinService.getCurpFromData({
          person: personData,
        });
        setCurp(result.data.legal_identifier);
        prevStep();
        Clevertap.event(
          EVENTS.BERLIN.ONBOARDING.EVENT,
          EVENTS.BERLIN.ONBOARDING.ACTIONS.ASK_CURP_DATA_VALIDATING_VIEWED,
          );
        return;
      } catch (error) {
        store.addNotification({
          message: text.errorMessage,
          type: 'danger',
          insert: 'bottom',
          container: 'bottom-center',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            showIcon: true,
            duration: 0,
          },
        });
        setCurpError(true);
        prevStep();
        return;
      }
    }
    nextStep();
  };

  useEffect(() => {
    Clevertap.event(
      EVENTS.BERLIN.ONBOARDING.EVENT,
      EVENTS.BERLIN.ONBOARDING.ACTIONS.ASK_CURP_DATA_VIEWED,
      );
  }, []);

  const setNationality = (value) => {
    if (value === 'MEX') {
      if (personalInfoValues === undefined) {
        dispatch(change(Forms.BERLIN_REGISTRATION, 'nationality', '000'));
        return;
      }
      if (value !== personalInfoValues.birth_country) {
        dispatch(change(Forms.BERLIN_REGISTRATION, 'nationality', '000'));
        return;
      }
      return;
    }
    dispatch(change(Forms.BERLIN_REGISTRATION, 'nationality', 'FOREING'));
    dispatch(change(Forms.BERLIN_REGISTRATION, 'birth_place', 'NE'));
  };
  const renderHeader = () => {
    if (skipCurp) {
      return (
        <>
          <ClipTypography variant="h3">{text.title}</ClipTypography>
          <div className={classes.info}>
            <ClipTypography>{text.info}</ClipTypography>
          </div>
        </>
      );
    }
    return (
      <ClipTypography className={classes.title} variant="h3">
        {text.verify}
      </ClipTypography>
    );
  };

  if (waiting) {
    return <WaitingForDataValidation version="1" />;
  }
  return (
    <>
      <ProgressNavigator
        progress={step}
        total={totalStep}
        handleClick={() => {
          setSkipCurp(false);
          prevStep();
        }}
      />
      <form className={classes.container}>
        {renderHeader()}
        <Field
          label={text.names}
          id="name"
          name="name"
          component={renderClipInput}
          fullWidth={true}
          containerMargin={true}
          validate={[required]}
          inputProps={{ maxLength: 36 }}
          onInput={(e) => checkAllowAlphaNumerical(36, e)}
        />
        <Field
          label={text.last_name}
          id="last_name"
          name="last_name"
          component={renderClipInput}
          fullWidth={true}
          containerMargin={true}
          validate={[required]}
          inputProps={{ maxLength: 36 }}
          onInput={(e) => checkAllowAlphaNumerical(36, e)}
        />
        <Field
          label={text.second_last_name}
          id="second_last_name"
          name="second_last_name"
          component={renderClipInput}
          fullWidth={true}
          containerMargin={true}
          inputProps={{ maxLength: 36 }}
          onInput={(e) => checkAllowAlphaNumerical(36, e)}
        />
        <Field
          label={text.birthday}
          placeholder="DD / MM / AAAA"
          id="birth_date"
          name="birth_date"
          normalize={formatDate}
          component={renderClipInput}
          fullWidth={true}
          InputLabelProps={{
            shrink: true,
          }}
          containerMargin={true}
          validate={[required, validDate]}
        />
        <ClipTypography>{text.legalSex}</ClipTypography>
        <label htmlFor="masculino" checked={selectedGender === 'MALE'}>
          <Field
            id="masculino"
            name="gender"
            component={renderRadio}
            type="radio"
            value="MALE"
          />
          {text.male}
        </label>
        <label htmlFor="femenino" checked={selectedGender === 'FEMALE'}>
          <Field
            id="femenino"
            name="gender"
            component={renderRadio}
            type="radio"
            value="FEMALE"
          />
          {text.female}
        </label>
        <Field
          component={renderSelectField}
          label={text.countryOfOrigin}
          id="birth_country"
          name="birth_country"
          onChange={setNationality}
          options={country}
          validate={[required]}
        />
        <Field
          component={renderSelectField}
          label={text.birthPlace}
          name="birth_place"
          id="birth_place"
          options={shortStates}
          validate={[required]}
        />
        <Field
          component={renderSelectField}
          label={text.nationality}
          id="nationality"
          name="nationality"
          options={nationalities}
          validate={[required]}
          disabled={true}
        />
        <div className={classes.btnContainer}>
          <ClevertapComponent
            renderComponent={<ClipButton />}
            events={[
              {
                callbackName: 'onClick',
                event: EVENTS.BERLIN.ONBOARDING.EVENT,
                action: EVENTS.BERLIN.ONBOARDING.ACTIONS.ASK_CURP_DATA_CONTINUED,
              },
            ]}
            variant="contained"
            disabled={invalid || personalInfoValues.gender === undefined}
            onClick={() => {
              sendInfo();
            }}
            color="primary"
          >
            {text.continue}
          </ClevertapComponent>
        </div>
      </form>
    </>
  );
};
const PersonalInfoWithForm = reduxForm({
  form: Forms.BERLIN_REGISTRATION,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(PersonalInfo);

const mapStateToProps = (state, props) => {
  return {
    initialValues: props.preInfo,
    berlin: state.berlin,
    personalInfoValues: getFormValues(Forms.BERLIN_REGISTRATION)(state),
  };
};
PersonalInfo.propTypes = {
  nextStep: PropTypes.func,
  selectedGender: PropTypes.string,
  skipCurp: PropTypes.bool,
  personalInfoValues: PropTypes.object,
  setCurp: PropTypes.func,
  setSkipCurp: PropTypes.func,
  prevStep: PropTypes.func,
  step: PropTypes.number,
  totalStep: PropTypes.number,
  invalid: PropTypes.bool,
  dispatch: PropTypes.func,
  setCurpError: PropTypes.func,
};
PersonalInfo.defaultProps = {
  nextStep: () => {},
  selectedGender: '',
  skipCurp: false,
  personalInfoValues: {},
  setCurp: () => {},
  setSkipCurp: () => {},
  prevStep: () => {},
  step: 5,
  totalStep: 10,
  invalid: false,
  dispatch: () => {},
  setCurpError: () => {},
};

export default connect(mapStateToProps)(PersonalInfoWithForm);
