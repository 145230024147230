import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { styles } from './styles';
import clsx from 'clsx';

const FormButton = (props) => {
  const {
    classes,
    type,
    variant,
    buttonLink,
    text,
    textIcon,
    disabled,
    onClick = {},
  } = props;

  const buttonStyles = {
    root: clsx(classes.buttonRoot, buttonLink && classes.buttonLink),
    outlined: classes.buttonOutlined,
  };

  return (
    <Button
      classes={buttonStyles}
      color="primary"
      type={type}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
    >
      {textIcon ? textIcon : text} {buttonLink && <ArrowDropDownIcon />}
    </Button>
  );
};

FormButton.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
  textIcon: PropTypes.object,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

export default withStyles(styles)(FormButton);
