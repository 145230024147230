import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = () => {
  return {
    subtitle: {
      fontWeight: 500,
    },
  };
};
const MerchantInfo = ({
  classes,
  text,
  merchantInfo,
  orderStatus,
  shipping,
}) => {
  return (
    <>
      <Typography
        className={classes.subtitle}
        variant="display1"
        align="center"
      >
        {text.name}
      </Typography>
      <Typography
        variant="body1"
        align="center"
      >{`${merchantInfo.name} ${merchantInfo.last_name} ${merchantInfo.second_last_name}`}</Typography>
      <Typography
        className={classes.subtitle}
        variant="display1"
        align="center"
      >
        {text.phone}
      </Typography>
      <Typography variant="body1" align="center">
        {merchantInfo.phone_number}
      </Typography>
      <Typography
        className={classes.subtitle}
        variant="display1"
        align="center"
      >
        {text.address}
      </Typography>
      <Typography variant="body1" align="center">
        {merchantInfo.street} {merchantInfo.external_number},{' '}
        {merchantInfo.internal_number}
        <br />
        {merchantInfo.neighborhood} <br />
        {merchantInfo.municipality} <br />
        {merchantInfo.city},{merchantInfo.state}
        <br />
        {merchantInfo.postal_code}
      </Typography>
      {shipping ? (
        <>
          <Typography
            className={classes.subtitle}
            variant="display1"
            align="center"
          >
            {text.orderNumber}
          </Typography>
          <Typography variant="body1" align="center">
            {orderStatus.order_number}
          </Typography>
        </>
      ) : (
        ''
      )}
      {shipping > 1 ? (
        <>
          <Typography
            className={classes.subtitle}
            variant="display1"
            align="center"
          >
            {text.guide + orderStatus.delivery_company}
          </Typography>
          <Typography variant="body1" align="center">
            {orderStatus.tracking_number}
          </Typography>
        </>
      ) : (
        ''
      )}
    </>
  );
};
MerchantInfo.propTypes = {
  classes: PropTypes.object,
  orderStatus: PropTypes.bool,
  merchantInfo: PropTypes.object,
  orderStatus: PropTypes.object,
  shipping: PropTypes.number,
};
MerchantInfo.defaultProps = {
  classes: {},
  orderStatus: {},
  merchantInfo: {},
  orderStatus: {},
  shipping: 0,
};
export default withStyles(styles)(MerchantInfo);
