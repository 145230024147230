// Dashboard Actions
export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';
export const FETCHING_DASHBOARD = 'dashboard/FETCHING_DASHBOARD';
export const FETCHING_DASHBOARD_STATE = 'dashboard/FETCHING_DASHBOARD_STATE';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const SET_DASHBOARD_STATE = 'SET_DASHBOARD_STATE';
export const SET_DASHBOARD_NAV = 'SET_DASHBOARD_NAV';
export const SET_DASHBOARD_TAB = 'SET_DASHBOARD_TAB';

// Deposit Actions
export const GET_LAST_DEPOSIT = 'GET_LAST_DEPOSIT';
export const GET_PENDING_DEPOSITS = 'GET_LAST_DEPOSITS';

// Email Validation Actions
export const EMAIL_VALIDATION_CLEAR = 'EMAIL_VALIDATION_CLEAR';
export const EMAIL_VALIDATION_FAILED = 'EMAIL_VALIDATION_FAILED';
export const EMAIL_VALIDATION_SUCCESS = 'EMAIL_VALIDATION_SUCCESS';
export const EMAIL_VALIDATION_RESEND_SUCCESS =
  'EMAIL_VALIDATION_RESEND_SUCCESS';
export const EMAIL_VALIDATION_RESEND_FAIL = 'EMAIL_VALIDATION_RESEND_FAIL';

// Locale Actions
export const UPDATE_LOCALE = 'UPDATE_LOCALE';

// Merchant Actions
export const FETCHING_MERCHANT_INFO = 'merchant/FETCHING_MERCHANT_INFO';
export const CLEAR_MERCHANT = 'CLEAR_MERCHANT';
export const UPDATE_MERCHANT = 'UPDATE_MERCHANT';
export const MERCHANT_ERROR = 'MERCHANT_ERROR';
export const UPDATE_META_DATA = 'UPDATE_META_DATA';
export const SET_MERCHANT_COLONIES = 'SET_MERCHANT_COLONIES';
export const SET_MERCHANT_STATE = 'SET_MERCHANT_STATE';
export const FORM_CHANGE = '@@redux-form/CHANGE';

// BankAccount Actions
export const SET_BANK_ACCOUNT = 'SET_BANK_ACCOUNT';
export const SET_BANK_ACCOUNT_COMMIT_TYPE = 'SET_BANK_ACCOUNT_COMMIT_TYPE';
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const CLEAR_BANK_ACCOUNT = 'CLEAR_BANK_ACCOUNT';

// Merchant Referral Actions
export const SET_REFERRAL_REGISTRATION_CODE = 'SET_REFERRAL_REGISTRATION_CODE';
export const REFERRAL_CODE_ATTEMPT = 'REFERRAL_CODE_ATTEMPT';
export const CLEAR_REFERRAL_REGISTRATION_CODE =
  'CLEAR_REFERRAL_REGISTRATION_CODE';
export const CLEAR_REFERRAL_STATE = 'CLEAR_REFERRAL_STATE';
export const SEND_REFERRAL_CODE_EMAIL = 'referrals/SEND_REFERRAL_CODE_EMAIL';
export const REGISTERED_WITH_REFERRAL_CODE = 'REGISTERED_WITH_REFERRAL_CODE';
export const SET_REFERRAL_AGGREGATES = 'SET_REFERRAL_AGGREGATES';
export const SET_REFERRER_BENEFIT = 'SET_REFERRER_BENEFIT';

// Settlement Actions
export const CLEAR_DAILY_REPORT = 'CLEAR_DAILY_REPORT';
export const CLEAR_DAILY_REPORTS = 'CLEAR_DAILY_REPORTS';
export const CLEAR_MONTHLY_REPORT = 'CLEAR_MONTHLY_REPORT';
export const GET_DAILY_REPORT = 'GET_DAILY_REPORT';
export const GET_DAILY_REPORTS = 'GET_DAILY_REPORTS';
export const GET_MONTHLY_REPORT = 'GET_MONTHLY_REPORT';
export const GET_MONTHLY_REPORTS = 'GET_MONTHLY_REPORTS';
export const GET_MONTHLY_LEDGER_REPORT = 'GET_MONTHLY_LEDGER_REPORT'
export const GET_APP_TEXT = 'GET_APP_TEXT';

// User Actions
export const FETCH_IDENTITY_VERIFICATION = 'user/FETCH_IDENTITY_VERIFICATION';
export const CLEAR_USER = 'user/CLEAR_USER';
export const DENY_PERMISSION = 'user/DENY_PERMISSION';
export const FETCH_USER = 'user/FETCH_USER';
export const RESET_PERMISSION = 'user/RESET_PERMISSION';
export const UPDATE_USER = 'user/UPDATE_USER';
export const USER_ERROR = 'user/USER_ERROR';
export const SET_USER_TOKEN = 'user/SET_USER_TOKEN';
export const SET_DAYS_UNTIL_EXP = 'user/SET_DAYS_UNTIL_EXP';
export const UPDATE_PASSWORD = 'user/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'user/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'user/UPDATE_PASSWORD_FAIL';
export const RESET_PASSWORD_FAIL = 'user/RESET_PASSWORD_FAIL';

// Feedback component Actions
export const FEEDBACK_SET = 'FEEDBACK_SET';
export const FEEDBACK_CLEAR = 'FEEDBACK_CLEAR';

//Clevertap Events
export const SET_CLEVERTAP_LAST_EVENT = 'SET_CLEVERTAP_LAST_EVENT';
