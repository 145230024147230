import styled from '@emotion/styled';
import { lightBlue } from '@material-ui/core/colors';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ClipCard from '../../common/Card';
import styledWithClasses from '../../../helpers/StyledWithClasses';

export const CenterContent = styled.div(({ theme }) => ({
  position: 'relative',
  textAlign: 'center',
  width: `${theme.spacing.unit * 57.5}px`,
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
}));

export const FormContainer = styled.div(({ theme }) => ({
  width: '100%',
  position: 'relative',
  display: 'block',
  marginTop: `${theme.spacing.unit}px`,
  marginBottom: `${theme.spacing.unit * 12}px`,
  [theme.breakpoints.down('xs')]: {
    marginBottom: `${theme.spacing.unit * 9}px`,
  },
}));

export const RequestAmount = styled.div(({ theme }) => ({
  width: '100%',
  fontSize: `${theme.spacing.unit * 2.5}px`,
  fontWeight: 600,
}));

export const ModalContent = styled.div(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.common.white,
}));

export const ModalTitle = styled.div(({ theme }) => ({
  textAlign: 'center',
  display: 'block',
  fontSize: `${theme.spacing.unit * 2.5}px`,
  fontWeight: 600,
}));

export const InitialAlert = styledWithClasses(
  styled(ClipCard)(({ theme }) => ({
    width: '100%',
    marginBottom: `${theme.spacing.unit * 2}px`,
    borderRadius: `${theme.spacing.unit * 0.375}px`,
    borderColor: `${theme.palette.naranja.cerulean} !important`,
    border: '1px solid',
    boxShadow: '0px 0px',
    fontSize: `${theme.spacing.unit * 1.75}px`,
    '& .alertTitle': {
      marginTop: 0,
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: `${theme.typography.fontWeightMedium}`,
      display: 'inline',
    },
    '& .contentRoot': {
      textAlign: 'left',
      padding: 0,
      paddingLeft: `${theme.spacing.unit * 1.25}px`,
      color: `${theme.palette.naranja.cerulean} !important`,
      backgroundColor: lightBlue[50],
      paddingTop: `${theme.spacing.unit * 1.25}px`,
      paddingBottom: `${theme.spacing.unit * 1.25}px`,
    },
  })),
  {
    contentRoot: 'contentRoot',
  },
);

export const InitialAlertContent = styled.div(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 1.75}px`,
  fontWeight: `${theme.typography.fontWeightLight}`,
  display: 'block',
  paddingLeft: `${theme.spacing.unit * 4}px`,
  paddingRight: `${theme.spacing.unit * 4}px`,
  '& > p': {
    margin: 0,
  },
  '& > li': {
    marginTop: `${theme.spacing.unit}px`,
    marginLeft: `${theme.spacing.unit * 0.5}px`,
  },
}));

export const PrefilledAlert = styledWithClasses(
  styled(ClipCard)(({ theme }) => ({
    width: '100%',
    marginBottom: `${theme.spacing.unit * 2}px`,
    borderRadius: `${theme.spacing.unit * 0.25}px`,
    border: '1px solid #007d8e',
    boxShadow: '0px 0px',
    fontSize: `${theme.spacing.unit * 1.75}px`,
    '& .alertTitle': {
      marginTop: 0,
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: `${theme.typography.fontWeightMedium}`,
      display: 'inline',
    },
    '& .contentRoot': {
      textAlign: 'left',
      padding: 0,
      paddingLeft: `${theme.spacing.unit * 1.25}px`,
      color: '#007d8e',
      backgroundColor: 'rgba(2, 136, 209, 0.05)',
      paddingTop: `${theme.spacing.unit * 1.25}px`,
      paddingBottom: `${theme.spacing.unit * 1.25}px`,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  })),
  {
    contentRoot: 'contentRoot',
  },
);

export const PrefilledAlertContent = styled.div(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 1.75}px`,
  fontWeight: `${theme.typography.fontWeightLight}`,
  display: 'block',
  paddingLeft: `${theme.spacing.unit * 4}px`,
  paddingRight: `${theme.spacing.unit * 4}px`,
}));

export const AssignmentTurnedIn = styled(AssignmentTurnedInIcon)(
  ({ theme }) => ({
    display: 'inline-block',
    marginRight: `${theme.spacing.unit}px`,
    verticalAlign: 'middle',
    width: `${theme.spacing.unit * 3}px`,
  }),
);
