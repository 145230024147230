import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

const useStyles = makeStyles((theme) => {
  return {
    amountContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 48px',
      height: '50px',
      [theme.breakpoints.down('sm')]: {
        height: '40px',
        padding: '8px 12px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '8px 12px',
      },
    },
    amountContainerModal: {
      padding: '8px 18px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 6px',
      },
    },
    totalAmount: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '40px !important',
        marginBottom: '25px !important',
      },
    },
    textSlider: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    textSliderModal: {
      width: '70%',
      margin: 'auto',
    },
    textSliderCenter: {
      display: 'flex',
      justifyContent: 'center',
    },
    buttonMargin: {
      marginTop: '13px',
      textTransform: 'none',
      borderRadius: theme.spacing(5),
      maxWidth: '215px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'initial',
      },
    },
    buttonCenter: {
      margin: 'auto',
      textAlign: 'center',
    },
    card: {
      display: 'inline-block',
      width: '100%',
      boxShadow: '0px 0px 8px rgba(128, 139, 145, 0.267892)',
      [theme.breakpoints.down('sm')]: {
        boxShadow: 'none',
      },
    },
    cardSlider: {
      padding: '12px',
      marginBottom: '10px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    paddingSlider: {
      padding: '0px 8px 0px 8px',
    },
    sliderModal: {
      width: '70%',
      margin: 'auto',
    },
    cardPay: {
      background: theme.palette.indigo.l98,
      padding: '12px',
      paddingBottom: '25px',
      float: 'left',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        background: '#FFFFFF',
        borderRadius: '8px',
        boxShadow: '0px 0px 8px rgba(128, 139, 145, 0.267892)',
      },
      [theme.breakpoints.down('xs')]: {
        background: '#FFFFFF',
      },
    },
    slider: {
      marginTop: '35px',
      maxWidth: '420px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginTop: '16px',
      },
    },
    cardModal: {
      border: 'none',
      borderRadius: 'unset',
      boxShadow: 'none',
    },
    cardModalGrid: {
      maxWidth: '100%',
      marginTop: '10px',
    },
    cardModalLabels: {
      [theme.breakpoints.down('sm')]: {
        '& >div': {
          margin: '20px 0',
        },
      },
    },
    cardDesktopLabels: {
      '& >div': {
        margin: '20px 0',
      },
    },
    addButton: {
      width: '40px',
      height: '40px',
      minHeight: '40px',
      minWidth: '40px',
      borderRadius: '8px',
    },
    sliderTrackStyles: {
      height: 8,
    },
    sliderRailStyles: {
      height: 8,
    },
    sliderThumbStyles: {
      height: 32,
      width: 32,
      marginTop: -14,
      marginLeft: -18,
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    maxAmount: {
      backgroundColor: theme.palette.orange.l94,
      padding: '4px 6px',
      borderRadius: '25px',
    },
    sliderAmountLabel: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    sliderMaxAmountLabel: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    titleLabelContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '14px 0',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    helpButton: {
      position: 'relative',
      top: '6px',
      color: theme.palette.orange.base,
      width: '22px',
      height: '22px',
      cursor: 'pointer',
      marginLeft: '8px',
    },
    helpText: {
      paddingBottom: '4px',
    },
    buttonsModal: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: 0,
      width: '100%',
      maxWidth: '100%',
    },
    buttonsModalMobile: {
      flexDirection: 'column-reverse',
    },
    closeModal: {
      marginTop: '13px',
      maxWidth: '150px',
      textTransform: 'none',
      fontSize: '16px',
    },
    closeModalMobile: {
      maxWidth: '100%',
    },
    sliderModalMobile: {
      width: 'initial',
    },
    importantNoteContainer: {
      width: '100%',
      textAlign: 'center',
    },
    importantNote: {
      fontWeight: 600,
    },
  };
});

export default useStyles;
