import React from 'react';
import PropTypes from 'prop-types';

import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ClaveImg from '../../../../../assets/images/berlin/ClaveElector.svg';
import text from './text';

import styles from './styles';
const useStyles = makeStyles(styles);

const Clave = (props) => {
  const classes = useStyles(props);
  const { setShowTooltip } = props;
  return (
    <div className={classes.root}>
      <ClipTypography variant="h3" className={classes.tooltipTitle}>
        {text.title}
      </ClipTypography>
      <img className={classes.img} src={ClaveImg} alt="" />
      <ClipTypography className={classes.noMargin}>
        {text.message1}
      </ClipTypography>
      <ClipButton
        variant="contained"
        color="primary"
        onClick={() => setShowTooltip(false)}
        className={classes.buttonMargin}
      >
        {text.buttonLabel}
      </ClipButton>
    </div>
  );
};
Clave.propTypes = {
  setShowTooltip: PropTypes.func,
};
Clave.defaultProps = {
  setShowTooltip: () => {},
};
export default Clave;
