import {
  CLEAR_BANK_ACCOUNT,
  SET_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
  SET_BANK_ACCOUNT_COMMIT_TYPE,
} from '../actions/Types';

import { BankAccount as constants } from '../constants/AppConstants';

const initialState = {
  commitType: constants.COMMIT_TYPE_EDIT,
  currentBankNumber: '',
  bankNumber: '',
  confirmBankNumber: '',
  owner: '',
  bankName: '',
  errCode: '',
  isAnUpdate: false,
  isACreate: false,
  isADelete: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_BANK_ACCOUNT:
      return { commitType: state.commitType, ...action.account };
    case UPDATE_BANK_ACCOUNT:
      const bankAccountUpdatedState = { ...state, ...action.account };
      return bankAccountUpdatedState;
    case CLEAR_BANK_ACCOUNT:
      return initialState;
    case SET_BANK_ACCOUNT_COMMIT_TYPE:
      const commitTypeSetState = { ...state, commitType: action.commitType };
      return commitTypeSetState;
    default:
      return state;
  }
}
