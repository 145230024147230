export default {
  title: 'Consejos para el repartidor:',
  streets: 'Entre calles',
  streetsHelp: 'Procura ser breve. Usa menos de 30 caracteres.',
  references: 'Referencias',
  referencesHelp:
    'Procura ser breve. Usa menos de 30 caracteres. Ejemplo: Portón rojo, frente al súper.',
  submitButton: 'Enviar',
  rejectedTitle: 'No eres tú, somos nosotros.',
  rejectedMessage: 'Por favor, inténtalo de nuevo.',
};
