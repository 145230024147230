export const CATALOG_GET_ITEMS = 'CATALOG_GET_ITEMS';
export const CATALOG_GET_ITEM = 'CATALOG_GET_ITEM';
export const CATALOG_DELETE_ITEM = 'CATALOG_DELETE_ITEM';
export const CATALOG_DELETE_SKU = 'CATALOG_DELETE_SKU';
export const CATALOG_UPDATE_ITEM = 'CATALOG_UPDATE_ITEM';
export const CATALOG_CREATE_ITEM = 'CATALOG_CREATE_ITEM';
export const CATALOG_CREATE_SKU = 'CATALOG_CREATE_SKU';
export const CATALOG_UPDATE_SKU = 'CATALOG_UPDATE_SKU';
export const CATALOG_GET_CATEGORIES = 'CATALOG_GET_CATEGORIES';
export const CATALOG_CREATE_CATEGORIES = 'CATALOG_CREATE_CATEGORIES';
export const CATALOG_GET_CATEGORIES_RESET = 'CATALOG_GET_CATEGORIES_RESET';
export const CATALOG_CONFIRM_PILOT_INVITATION =
  'CATALOG_CONFIRM_PILOT_INVITATION';
export const CATALOG_BETA = 'CATALOG_BETA';
export const CATALOG_BULK_UPLOAD = 'CATALOG_BULK_UPLOAD';
export const CATALOG_BULK_GET_STATE = 'CATALOG_BULK_GET_STATE';
export const CATALOG_BULK_UPDATE_STATE = 'CATALOG_BULK_UPDATE_STATE';
export const CATALOG_BULK_IN_PROCESS = 'CATALOG_BULK_IN_PROCESS';
export const CATALOG_CLEAR = 'CATALOG_CLEAR';
export const CATALOG_GET_PUBLISH_INFO = 'CATALOG_GET_PUBLISH_INFO';
export const CATALOG_SET_PUBLISH_INFO = 'CATALOG_SET_PUBLISH_INFO';
export const CATALOG_GET_URL = 'CATALOG_GET_URL';
export const CATALOG_SET_URL = 'CATALOG_SET_URL';
export const CATALOG_CHANGE_URL = 'CATALOG_CHANGE_URL';
