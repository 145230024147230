import styled from '@emotion/styled';
import { rgba } from 'polished';

import grey from '@material-ui/core/colors/grey';
import { withStyles } from '@material-ui/core/styles';
import { Select as MUISelect } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import styledWithClasses from 'helpers/StyledWithClasses';

export const Select = styledWithClasses(
  styled(MUISelect)(({ theme }) => ({
    textAlign: 'left',
    '& .helper-select-icon': {
      color: rgba(theme.palette.primary.main, 0.7),
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.naranja.bloodOrange}`,
    },
  })),
  {
    icon: 'helper-select-icon',
  },
);

export const FormInputLabel = withStyles((theme) => ({
  root: {
    color: grey[500],
  },
}))(InputLabel);

export const ErrorMessage = styled.span(({ theme }) => ({
  width: '100%',
  color: theme.palette.error.main,
  margin: 0,
  fontSize: `${theme.spacing.unit * 1.5}px`,
  textAlign: 'left',
  minHeight: `${theme.spacing.unit * 2}px`,
  lineHeight: `${theme.spacing.unit * 2}px`,
}));
