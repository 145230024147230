// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { change, getFormValues, formValueSelector } from 'redux-form';

import { ClipInput, ClipTypography, ClipButton } from '@clipmx/clip-storybook';
import ClipAlert from '@clipmx/clip-storybook/dist/components/Alert';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ClipCard from '@clipmx/clip-storybook/dist/components/Card';
import { Forms } from '../../../../constants/AppConstants';
import NumberFormat from 'react-number-format';
import { styles } from './styles';
import text from './text';

const useStyles = makeStyles(styles);

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalSeparator="."
      decimalScale={2}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
      prefix="$"
    />
  );
};

const Deposit = (props) => {
  const {
    balance,
    openChange,
    action,
    amountValue,
    conceptValue,
    SpeiOutValues,
    fromSaveContact,
    setFromSaveContact,
    change,
  } = props;
  const classes = useStyles(props);

  const [amountError, setAmountError] = useState(null);
  const [amount, setAmount] = useState(amountValue);
  const [concept, setConcept] = useState(conceptValue);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (amount > parseFloat(balance)) {
      setAmountError(text.error.replace('balance', balance));
    } else {
      setAmountError(null);
    }
  }, [amount, balance]);

  useEffect(() => {
    if (amountValue){
      setAmount(amountValue)
    }
    if(conceptValue){
      setConcept(conceptValue)
    }
  }, []);

  const handleAmountChange = useCallback((event) => {
    change('amount', parseFloat(event));
    setAmount(parseFloat(event));
  }, [change, setAmount]);

  const handleConceptChange = useCallback((event) => {
    change('concept', event);
    let value = event.replace(/[^\w\s]/gi, '');
    value = value.replace(' ', '')
    value = value.replace('_', '')
    setConcept(value);
  }, [change, setConcept]);

  useEffect(() => {
    if (!amountError && amount && concept) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [amountError, amount, concept]);


  useEffect(() => {
    if(fromSaveContact){
      setTimeout(() => {setFromSaveContact(false)}, 3000);
    }
  },[fromSaveContact])

  return (
    <div className={classes.container}>
      {fromSaveContact && <ClipAlert>{text.success}</ClipAlert>}
      <ClipTypography
        variant={'body1'}
        component={'p'}
        color="textSecondary"
        className={classes.title}
        fontWeight="bold"
      >
       {text.title}
      </ClipTypography>
      <ClipCard className={classes.card}>
        <div>
          <ClipTypography
            variant={'body1'}
            component={'p'}
            className={classes.cardTitle}
          >
            {SpeiOutValues.alias}
          </ClipTypography>
          <ClipTypography
            variant={'body1'}
            component={'p'}
            className={classes.cardInfo}
          >
            {SpeiOutValues.name}
          </ClipTypography>
          <ClipTypography
            variant={'body1'}
            component={'p'}
            className={classes.cardInfo}
          >
            {`${SpeiOutValues.bank} - ${SpeiOutValues.clabe.substr(
              SpeiOutValues.clabe.length - 4,
            )}`}
          </ClipTypography>
        </div>
        <ClipButton
          onClick={() => openChange()}
          disableFocusRipple
          disableRipple
          color="primary"
        >
          {text.cambiar}
        </ClipButton>
      </ClipCard>
      <ClipTypography
        variant={'body1'}
        component={'p'}
        className={classes.actionTitle}
      >
        {text.howMuch}
      </ClipTypography>
      <ClipInput
        placeholder={text.amount}
        classes={{
          input: classes.input,
          positionEnd: classes.positionEnd,
        }}
        className={classes.searchInput}
        error={amountError}
        value={amount}
        errormessage={amountError}
        onChange={(e) => handleAmountChange(e.target.value)}
        fullWidth
        inputProps={{
          inputMode: 'decimal',
        }}
        inputComponent={NumberFormatCustom}
        color="secondary"
        on
        helperText={text.helper.replace('balance', amount ? (balance - parseFloat(amount)).toFixed(2) : balance)}
      />
      <ClipInput
        type="text"
        placeholder={text.concept}
        value={concept}
        classes={{
          input: classes.input,
          positionEnd: classes.positionEnd,
        }}
        className={classes.searchInput}
        onChange={(e) => handleConceptChange(e.target.value)}
        fullWidth
        color="secondary"
        on
      />
      <ClipButton
        variant="contained"
        color="primary"
        disabled={disabled}
        className={classes.button}
        onClick={action}
      >
        {text.continue}
      </ClipButton>
    </div>
  );
};

const selector = formValueSelector(Forms.BERLIN_SPEI_OUT);

const mapStateToProps = (state) => {
  return {
    balance: state.berlin.cardBalance,
    SpeiOutValues: getFormValues(Forms.BERLIN_SPEI_OUT)(state),
    aliasValue: selector(state, 'alias'),
    clabeValue: selector(state, 'clabe'),
    bankValue: selector(state, 'bank'),
    nameValue: selector(state, 'name'),
    amountValue: selector(state, 'amount'),
    conceptValue: selector(state, 'concept'),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    change: (key, value) =>
      dispatch(change(Forms.BERLIN_SPEI_OUT, key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deposit);