export const ActionTypes = {
  // User
  SET_USER: 'set user',
  UNSET_USER: 'unset user',
  SET_USER_STATE: 'set user state',
  FAIL_AUTHENTICATION: 'fail authentication',
  PERMISSION_DENIED: 'permission denied',

  // Dashboard
  SET_DASHBOARD_NAV: 'set dashboard nav',
  SET_DASHBOARD_TAB: 'set dashboard tab',

  // Cart
  SET_CART: 'set cart',
  UPDATE_CART_QUANTITY: 'update cart quantity',
  SET_CHECKOUT_INSTRUCTIONS: 'set checkout instructions',

  // Onboarding Nav
  SET_ONBOARDING_NAV: 'set onboarding nav',
  REMOVE_ONBOARDING_NAV: 'remove onboarding nav',

  // Feedback
  SET_FEEDBACK: 'set feedback',
  CLEAR_VIEWED_FEEDBACKS: 'clear viewed feedbacks',

  // Text
  SET_LOCALE: 'set locale',

  // Visit
  VISIT_DASHBOARD: 'visit dashboard',
};

// Business Tools Component States
export const ComponentStates = {
  INITIAL: 'initial',
  EDIT: 'edit',
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const DashboardNavConstants = {
  BUSINESS_TOOLS: 'business tools',
  CATALOG: 'catalog',
  COLLABORATORS: 'collaborators',
  CONTROL: 'control',
  DEPOSITS: 'deposits',
  NEW_COLLABORATOR: 'new collaborator',
  PERSONAL: 'personal',
  REFERRALS: 'referrals',
  TRANSACTIONS: 'transactions',
  INVOICES: 'invoices',
  TOP_UPS: 'top ups',
  LOANS: 'loans',
  PAYMENT_REQUEST: 'payment request',
  CODI: 'codi',
  DEV_PORTAL: 'developers portal',
  BUSINESS_UNITS_MANAGEMENT: 'business units management',
  ONLINE_STORE: 'online store',
};

export const DashboardTabConstants = {
  CONTROL: 'control',
  REFERRALS: 'referrals',
  MERCHANTS_REFERRED: 'referrals how it works',
  TRANSACTIONS: 'transactions',
  TRANSACTION_REPORTS: 'transaction reports',
  MANAGE_CATALOG: 'manage catalog',
  CATALOG_BULK_UPLOAD: 'catalog bulk upload',
  MANAGE_COLLABORATORS: 'manage collaborators',
  MANAGE_INVOICES: 'manage invoices',
  NEW_COLLABORATOR: 'new collaborators',
  USER_INFO: 'user info',
  MERCHANT_INFO: 'merchant info',
  BANK_INFO: 'bank info',
  BUSINESS_TOOLS: 'business tools',
  CLIP_READER: 'clip reader',
  MERCHANT_DEVICES: 'merchant devices',
  DAILY_REPORTS: 'daily reports',
  MONTHLY_REPORTS: 'monthly reports',
  TOP_UPS: 'top ups',
  LOANS: 'loans',
  PAYMENT_REQUEST: 'payment request',
  CODI: 'codi',
  DEV_PORTAL: 'developers portal',
};

export const PreapprovalStatus = {
  AVAILABLE: 'AVAILABLE',
  DECLINED: 'DECLINED',
  ACCEPTED: 'ACCEPTED',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
  CANCELED: 'CANCELED',
  ON_HOLD: 'ON_HOLD',
  SIGNED: 'SIGNED',
  HIDENAV: false,
};

export const LoansStates = {
  CLIP_LENDER: 'CLIP',
  NONE: 'NONE',
  ACTIVE: 'ACTIVE',
  ACCEPTED: 'ACCEPTED',
  AVAILABLE: 'AVAILABLE',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
  ON_HOLD: 'ON_HOLD',
  REFILL: 'REFILL',
  CUSTOM: 'CUSTOM',
};

export const LoanTypes = {
  CUSTOM: 'CUSTOM',
  REFILL: 'REFILL',
  REGULAR: 'REGULAR',
  REPEAT: 'REPEAT',
  OPEN_DATA: 'OPEN_DATA',
};

export const LoansFilesStates = {
  SAVED: 'saved',
  MAX_SIZE: 'maxSize',
  INCORRECT_TYPE: 'incorrectType',
  MAX_FILES: 'maxFiles',
  DISCONNECTED: 'disconnected',
  ERROR: 'error',
  LOADING: 'loading',
  REQUIRED: 'required',
  REMOVED: 'removed',
  NOT_FOUND: 'notFound',
};

export const LoansDocuments = {
  acceptFiles: 'image/jpg,image/jpeg,image/png,application/pdf',
  allowedTypes: /^(image\/(?!gif)\w+|application\/pdf[\w.-]*)$/i,
  identity: 'identity',
  identityBack: 'identityBack',
  passport: 'passport',
  maxBytesLoaded: 100000000,
  maxFilesLoaded: 1,
  proofOfAddress: 'proofOfAddress',
  CANCEL_UPLOAD: 'CANCEL_UPLOAD',
  ineDocumentType: 'INE',
  passportDocumentType: 'PASSPORT',
  addressDocumentType: 'ADDRESS',
  idPrefix: 'ID_0',
  proofPrefix: 'PROOF_0',
};

export const LoanGAnalytics = {
  NO_PREAPPROVAL_SELECTED: 'NOT SELECTED',
};

export const PaymentRequestStates = {
  NEW: 'NEW',
  CREATED: 'CREATED',
  SENT: 'SENT',
  PENDING: 'PENDING',
  RECEIVED: 'RECEIVED',
  CANCELLED: 'CANCELLED',
};

export const PaymentRequestStatus = {
  PAYMENT_ERROR: 'Error',
  PAYMENT_PENDING: 'Pending',
  PAYMENT_COMPLETED: 'Completed',
  PAYMENT_CANCELLED: 'Cancelled',
  PAYMENT_EXPIRED: 'Expired',
};

export const PaymentRequestStatusCode = {
  PENDING: 0,
  COMPLETED: 1,
  CANCELLED: 2,
  EXPIRED: 3,
  ERROR: -1,
};

export const HTTPStatusCodes = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOTALLOWED: 405,
  PRECONDITION_FAILED: 412,
  SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  SERVICE_UNAVAILABLE: 503,
};

export const PaymentMethods = {
  PAYPAL: 'paypal',
  COMPROPAGO: 'compropago',
};

export const OnboardingStates = {
  WELCOME: 'WELCOME',
  BANK_ACCOUNT_INFO: 'BANK_ACCOUNT_INFO',
  BUSINESS_INFO: 'BUSINESS_INFO',
  COLLABORATOR: 'COLLABORATOR',
  INSTALLMENT: 'INSTALLMENT',
  SUMMARY: 'SUMMARY',
};

export const DashboardStates = {
  MIGRATION_INCOMPLETE: 'MIGRATION_INCOMPLETE',
  CREATED: 'CREATED',
  READER_ORDERED: 'READER_ORDERED',
  MERCHANT_COMPLETED: 'MERCHANT_COMPLETED',
};

export const MerchantStates = {
  VALIDATED: 'VALIDATED',
  ACTIVATED: 'ACTIVATED',
  PREACTIVE: 'PREACTIVE',
  SUSPENDED: 'SUSPENDED',
  ACTIVE: 'ACTIVE',
  MERCHANT_INFORMATION: 'MERCHANT_INFORMATION',
};

export const FeedbackTypes = {
  GENERAL: 'general',
  EDIT_INFO: 'edit info',
  PASSWORD_SUCCESS: 'password success',
  PASSWORD_CONFLICT: 'password conflict',
  INSTALLMENTS: 'installments',
  PAYMENT: 'payment',
  DASHBOARD: 'dashboard',
  REPORTS: 'reports',
  BANK_ACCOUNTS: 'bank accounts',
  CATALOG: 'catalog',
  INVOICES: 'invoices',
  TOPUP: 'topup',
};

export const FeedbackQualities = {
  ALERT: 'alert',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const Locales = {
  EN_US: 'en us',
  ES_MX: 'es mx',
};

export const CartModes = {
  DASHBOARD: 'dashboard',
  ONBOARDING: 'onboarding',
};

export const Forms = {
  CATALOG_CONFIRM_PILOT_INVITATION: 'catalogConfirmPilotInvitation',
  CATALOG_ITEM_FORM_ADD: 'catalogItemFormAdd',
  CATALOG_SKU_FORM: 'catalogSkuForm',
  CATALOG_ITEM_FORM_EDIT: 'catalogItemFormEdit',
  CATALOG_ITEM_FORM_DUPLICATE: 'catalogItemFormDuplicate',
  BANK_ACCOUNT_FORM: 'bankAccountForm',
  ONBOARD_PERSONAL_INFO_FORM: 'onboardPersonalInfoForm',
  BUSINESS_INFO_FORM: 'onboardBusinessInfoForm',
  COLLABORATOR_FORM: 'collaboratorForm',
  EDIT_PASSWORD_FORM: 'editPasswordForm',
  INSTALLMENTS_FORM: 'installmentsForm',
  LOGIN_FORM: 'loginForm',
  NEW_BANK_ACCOUNT_FORM: 'newBankAccountForm',
  PERSONAL_INFO_FORM: 'personalInfoForm',
  REGISTRATION_FORM: 'registrationForm',
  REDEEM_PROMO_FORM: 'redeemPromoForm',
  PASSWORD_REQUEST_FORM: 'passwordRequestForm',
  PASSWORD_RESET_FORM: 'passwordResetForm',
  PASSWORD_EDIT_FORM: 'passwordEditForm',
  EDIT_NOTIFICATION_FORM: 'editNotificationForm',
  EDIT_INSTALLMENTS_FORM: 'editInstallmentsForm',
  EDIT_MERCHANT_INFO_FORM: 'editMerchantInfoForm',
  EDIT_POSTBACK_URL_FORM: 'EditPostbackForm',
  EDIT_PUBLIC_PROFILE_FORM: 'EditPublicProfileForm',
  INVOICES_TAX_PAYER_FORM: 'invoicesTaxPayerForm',
  LOANS: 'loansForm',
  MR_PRESTA: 'loansMrPrestaForm',
  MY_CREDICLIP: 'MyCrediClipForm',
  MOT: 'loansMOTForm',
  APH: 'loansAPHForm',
  PAYMENT_REQUEST: 'PaymentRequestForm',
  PAYMENT_SEARCH: 'paymentSearchForm',
  TRANSACTION_SEARCH: 'transactionSearchForm',
  TRANSACTION_REPORT: 'transactionReportForm',
  REGISTRATION_INFORMATION: 'registrationInformation',
  BERLIN_REGISTRATION: 'berlinRegistration',
  BERLIN_N3: 'berlinN3',
  BERLIN_SPEI_OUT: 'berlinSpeiOut',
  PIN_CHANGE: 'pinChange',
  TRANSFERS_FORM: 'transfersForm',
  EXISTING_MERCHANTS_REGISTRATION_FORM: 'existingMerchantsRegistrationForm',
  VERSION_1: 'V1',
  VERSION_2: 'V2',
  VERSION_3: 'V3',
  VERSION_4: 'V4',
  CARD_REQUEST: 'cardRequest',
  CARD_ACTIVATION: 'cardActivation',
  UPGRADE_ACCOUNT_FORM: 'upgradeAccountForm',
};

export const CollaboratorFormModes = {
  NEW: 'new',
  EDIT: 'edit',
};

export const CollaboratorTabs = {
  NEW_COLLABORATOR: 'new_collaborator',
  COLLABORATORS: 'collaborators',
};
export const LoansTabs = {
  NEW_OFFER: 'NEW_OFFER',
  CREDI_CLIP: 'CREDI_CLIP',
  FAQS: 'CREDI_CLIP_FAQ',
  TERMS: 'CREDI_CLIP_TERMS',
};

export const PaymentRequestTabs = {
  PAYMENT_REQUEST: 'PAYMENT_REQUEST',
  PENDING_PAYMENT_REQUESTS: 'PENDING_PAYMENT_REQUESTS',
};

export const CollaboratorRoles = {
  CASHIER: 'cashier',
  MANAGER: 'manager',
  FINANCE: 'finance',
  ADMIN: 'ADMIN',
};

export const ReferralConstants = {
  MAX_ATTEMPTS: 5,
  REWARD_TYPE_FEE_REFUND: 'FEE_REFUND',
  REWARD_TYPE_CASH_AFTER_TPV_EXCEEDED: 'CASH_AFTER_TPV_EXCEEDED',
  REWARD_TYPE_CASH_AFTER_1ST_TRANSACTION: 'CASH_AFTER_1ST_TRANSACTION',
};

export const NumberConstants = {
  TABLET_WIDTH: 960,
};

export const ClipPhone = '55-6393-2323';
export const loansClipPhone = '55 6393-2323';

export const LoansCurrentProductName = 'Credi Clip';
export const LoansNewProductName = 'préstamo';

export const entityTypes = {
  PHYSICAL: 'PHYSICAL',
  MORAL: 'MORAL',
  physicalLength: 13,
  moralLength: 12,
};

export const CatalogBulkStates = {
  INITIAL: 'INITIAL',
  IN_PROCESS: 'IN_PROCESS',
  INVALID_REQUEST_CONTEXT: 'INVALID_REQUEST_CONTEXT',
  PROCESSED: 'PROCESSED',
  PROCESS_FAILED: 'PROCESS_FAILED',
  COMMUNICATION_ERROR: 'COMMUNICATION_ERROR',
  CANCELED_BY_USER: 'CANCELED_BY_USER',
  CANCELED_BY_SYSTEM_ADMIN: 'CANCELED_BY_SYSTEM_ADMIN',
};

export const CatalogErrorTypes = {
  duplicated_in_file: 'Duplicado',
  duplicated_variants_in_file: 'Duplicado',
  duplicated_in_db: 'Duplicado en base de datos',
  max_items_error: 'Excedente',
  max_variants_error: 'Tipo',
  max_item_variants_error: 'Tipo',
  max_item_with_variants_error: 'Excedente',
  item_validation: 'Error de contenido',
};

export const PaymentRequestAttempts = {
  MAX_ATTEMPTS: 3,
  SERVICE_ATTEMPTS: 2,
  DEACTIVATED_ACCOUNT: 502,
  MAX_LIMIT: 412,
};

export const BankAccount = {
  COMMIT_TYPE_EDIT: 'COMMIT_TYPE_EDIT',
  COMMIT_TYPE_CANCEL: 'COMMIT_TYPE_CANCEL',
};

export const LocalStorageData = {
  DISPLAY_TIPS: 'displayTips',
  PADS_COUNT: 'padsCount'
};

export const BERLIN_CARD_REQUEST_TABS = {
  REQUEST_MENU: 'REQUEST_MENU',
  REQUEST_CARD: 'REQUEST_CARD',
  ACTIVATE_CARD: 'ACTIVATE_CARD',
  NEW_ADDRESS: 'NEW_ADDRESS',
  ADDRESS_INSTRUCTIONS: 'ADDRESS_INSTRUCTIONS',
  TRACK_CARD: 'TRACK_CARD',
};

export const BERLIN_FINANCIAL_SERVICE_PROVIDERS = {
  CACAO: 'CACAO',
  SWAP: 'SWAP',
}

export const BERLIN_TANDEM_UPGRADE_STATUS = {
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED',
};

export const BERLIN_CONTACT_LINKS = {
  PHONE: 'tel:+525563932323',
  EMAIL: 'mailto:help@clip.mx',
  SITE: 'https://clip.mx',
};
