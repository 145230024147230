import React from 'react';
import PropTypes from 'prop-types';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import styles from './styles';

const useStyles = makeStyles(styles);

const Question = ({ title, subtitle, buttons }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <ClipTypography className={classes.title} variant="h3">
          {title}
        </ClipTypography>
        {subtitle !== '' && (
          <ClipTypography className={classes.title}>{subtitle}</ClipTypography>
        )}
      </div>
      <div className={classes.btnContainer}>
        {buttons.map(({ disabled, text, action, event, payload, clevertapAction }, i) => (
          <ClevertapComponent
            renderComponent={<ClipButton />}
            events={[
              {
                callbackName: 'onClick',
                event,
                payload,
                action: clevertapAction,
              },
            ]}
            key={i}
            className={classes.btn}
            disabled={disabled}
            variant="outlined"
            color="primary"
            onClick={action}
          >
          {text}
          </ClevertapComponent>
        ))}
      </div>
    </div>
  );
};

Question.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      text: PropTypes.string,
      action: PropTypes.func,
      event: PropTypes.string,
      payload: PropTypes.object,
      clevertapAction: PropTypes.string,
    }),
  ),
};

Question.defaultProps = {
  title: '',
  subtitle: '',
  buttons: [],
};

export default Question;
