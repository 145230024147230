export default (theme) => ({
  root: {
    borderRadius: 0,
  },
  headerRoot: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    borderBottom: `2px solid ${theme.palette.grey['300']}`,
  },
  headerAction: {
    alignSelf: 'auto',
    marginTop: '0',
  },
  headerContent: {
    // Styles for the header content
  },
  headerTitle: {
    // Styles for the header title
  },
  headerSubheader: {
    // Styles for the header subheader
  },
  contentRoot: {
    padding: theme.spacing.unit * 3,
  },
});
