import React, { Fragment } from 'react';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipGrid from '@clipmx/clip-storybook/dist/components/Grid';
import { ClipTypography } from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import ClipButton from 'components/npm/clip-ui/Button';
import useStyles from './styles';
import text from '../LoansLandingV4/text';
import FooterContact from '../FooterContact';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';
import { useLocation } from 'react-router-dom';
import urls from '../../../../helpers/urls';
import ClipDialog from '@clipmx/clip-storybook/dist/components/Dialog';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import { Close as CloseIcon2 } from '@clipmx/clip-storybook/dist/components/Icons';
import { toCurrencyFormat } from 'helpers/formatCurrency';

export default function OutModal(props) {

  const { modalViews, modalFunct, retentionRate } = props;
  const classes = useStyles();

  const closeModal = () => {
    modalFunct(false);
  }

  const dialogFunctionality = () => {
    return (
      <ClipDialog
      classes={{ paper: classes.modalPay }}
        title=""
        open={modalViews}
        onClose={() => modalFunct(false)}
        type="form"
      >
        {validationContent(false)}
      </ClipDialog>
    );
  };

  const Drawer = (props) => {
    return (
      <ClipDrawer anchor={'bottom'} open={modalViews} classes={{ paper: classes.tooltipPaper }}>
        {validationContent(true)}
      </ClipDrawer>
    );
  };
  
  const browserHistory= useLocation();
  const isMobileWebView = browserHistory.pathname.startsWith(urls.mobile);

  const calculation = (rate) => {
    let retention = (1916.48 * (rate /100));
    return retention;
  }

  const receive = calculation(retentionRate) - 1916.48;

  const validationContent = (displayCloseIcon) => {
  return (
    <Fragment>
       {displayCloseIcon && (
          <IconButton
            classes={{ root: classes.iconButton }}
            onClick={() => {
              modalFunct(false);
            }}
            onClose={() => window.scrollTo(0, 0 + 15)}
          >
            <CloseIcon2
              fontSize="small"
              classes={{ root: classes.closeIcon }}
            />
          </IconButton>
      )}
     <div className={classes.modalHiden}>
        <ClipTypography
          variant="subtitle1"
          fontWeight="medium"
          gutterBottom
          className={classes.MarginTop}
        >
          {text.Retentions}
        </ClipTypography>
        <ClipTypography color="textSecondary" variant="body1" fontWeight="regular" component="span">
          {text.dayYourLoan}{' '}
        </ClipTypography>
        <ClipTypography
          variant="body1"
          component="span"
          color="secondary"
          fontWeight="medium"
        >
          {text.dayYourLoan2}{' '}
        </ClipTypography>
        <ClipTypography color="textSecondary" variant="body1" fontWeight="regular" component="span">
          {text.dayYourLoan3}
        </ClipTypography>
        <div className={classes.OrderTotalParent}>
          <div className={classes.AmountContainer}>
            <ClipTypography color="textSecondary" variant="body1" fontWeight="regular">
              {text.yourDailySales}
            </ClipTypography>
            <ClipTypography color="textSecondary" variant="body1" fontWeight="regular">
              {'$2,000'}
            </ClipTypography>
          </div>
          <div className={classes.AmountContainer}>
            <ClipTypography color="textSecondary" variant="body1" fontWeight="regular">
              {text.commissionClip}
            </ClipTypography>
            <ClipTypography color="textSecondary" variant="body1" fontWeight="regular">
              {'-$83.52'}
            </ClipTypography>
          </div>
          <div className={classes.AmountContainer}>
            <ClipTypography color="textSecondary" variant="body1" fontWeight="regular">
              {text.yourProfit}
            </ClipTypography>
            <ClipTypography color="textSecondary" variant="body1" fontWeight="regular">
              {'$1,916.48'}
            </ClipTypography>
          </div>
          <div className={classes.AmountContainer}>
            <ClipTypography variant="body1" fontWeight="medium" gutterBottom>
              {text.prestaClipRetention(retentionRate)} 
            </ClipTypography>
            <ClipTypography variant="body1" fontWeight="medium" gutterBottom>
           -{toCurrencyFormat(
                   calculation(retentionRate)
                )}
            </ClipTypography>
          </div>
          <div className={classes.AmountContainerBa}>
            <ClipTypography variant="body1" fontWeight="medium" gutterBottom>
              {text.youReceive}
            </ClipTypography>
            <ClipTypography variant="body1" fontWeight="medium" style={{color:'#1A82BE'}} gutterBottom>
            {toCurrencyFormat(-receive)}
            </ClipTypography>
          </div>
        </div>
        <ClipTypography
          variant="body1"
          fontWeight="medium"
          align="center"
          gutterBottom
        >
          {text.youHaveDoubts}
        </ClipTypography>
        <Grid
          item
          xs={12}
          sm={9}
          md={9}
          lg={9}
          className={classes.cardCenter}
        >
          <FooterContact />
        </Grid>
        <ClipTypography
         className={classes.customerHappines}
            variant="body2"
            fontWeight="regular"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            {text.customerHappines}
          </ClipTypography>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className={classes.buttonCenter}
        >
          <ClipButton
            className={classes.Button}
            variant="contained"
            color="primary"
            onClick={closeModal}
          >
            {text.return}
          </ClipButton>
        </Grid>
        </div>
      </Fragment>
  )
};
return(
  <Fragment>
      {isMobileWebView && Drawer()}
      {!isMobileWebView && dialogFunctionality()}
  </Fragment>
);
}
