export const styles = (theme) => {
  const rootStyles = {
    container: {
      minHeight: `calc(100vh - ${theme.spacing.unit * 12.25}px)`,
      width: `calc(100% - ${theme.spacing.unit * 6}px)`,
      margin: `${theme.spacing.unit * 5.25}px ${theme.spacing.unit * 3}px
      ${theme.spacing.unit * 7}px ${theme.spacing.unit * 3}px`,
    },
    buttonContainer: {
      display: 'flex',
      marginTop: 'auto',
      justifyContent: 'flex-end',
      '& > button': {
        fontSize: `${theme.spacing.unit * 2}px`,
      },
    },
    formContent: {
      minHeight: 'calc(100vh - 130px)',
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      '& > div': {
        marginBottom: `${theme.spacing.unit * 2}px`,
      },
    },
  };
  const typographyStyles = {
    typographyTitle: {
      marginTop: `${theme.spacing.unit * 4}px`,
      marginBottom: `${theme.spacing.unit * 2}px`,
    },
  };
  return { ...rootStyles, ...typographyStyles };
};
