export default {
  serviceName: 'Cartera Clip',
  accountIssued: 'operada por',
  availableBalance: 'Saldo disponible:',
  speiOut: 'Enviar dinero',
  movements: 'Movimientos',
  emptyMovements: 'Aún no hay movimientos en tu cuenta.',
  toastText: (name) =>
    `${name}, el monto límite de tu Cartera Clip es de $18,000 al mes. `,
  toastLink: 'Aumentar límite',
  toastMigrationHead: 'Pronto tu cuenta Cacao ya no podrá recibir depósitos',
  toastMigrationText1: 'Activa tu ',
  toastMigrationText2: 'cuenta en Cartera Clip',
  toastMigrationText3: ' operada por Swap para seguir recibiendo el dinero de tus ventas en 4 horas.',
  toastMigrationLink: 'Activar nueva cuenta',
  popUp: {
    title: 'Nueva versión disponible',
    body: 'Actualiza la última versión de la app para disfrutar las nuevas funcionalidades de Cartera Clip',
    button: 'Actualizar ahora',
  },
};
