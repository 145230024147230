import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

export const useStyles = makeStyles((theme) => {
  return {
    cardgris: {  
      width: '100%',
      height: '50px',
      borderRadius: '8px',
      marginTop: '15px',
      marginLeft: '10px',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        marginBottom: 15,
      },
    },
    textCardGris: {
      marginTop: '-10px',
      fontFamily: 'AvertaStd',
    },
    textFont: {
      fontSize: '28px',
    },
    fontW500: {
      marginTop: '25px',
      marginLeft: '25px',
      display: 'flex',
      fontWeight: '800',
      fontFamily: 'AvertaStd',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 10,
      },
    },
    icons: {
      display: 'grid',
      '& > img': {
        margin: 'auto',
        marginBottom: '8px',
        marginTop: '32px',
      },
    },
    borderTest: {
      border: '1px solid',
    },
    colorna: {
      display: 'contents', 
      color: theme.palette.primary.main,
    },
    colorBlue: {
      display: 'contents',
      color: theme.palette.secondary.main,
      textDecoration: 'underline red',
    },
    deskNone:{
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    lastFooter: {
      marginTop: '35px',
      display: 'flex',
      justifyContent: 'center',
    },
  };
});

export default useStyles;
