import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { LoansDocuments } from 'constants/AppConstants';
import StyledFileUploader from 'components/loans/Commons/StyledFileUploader';
import { getLoansProofAddressDocuments } from 'components/loans/redux/selectors';
import text from './text';

export class AttachAddressProofDocuments extends Component {
  static propTypes = {
    fieldRequired: PropTypes.bool.isRequired,
    proofAddressFiles: PropTypes.array,
    fileUploaded: PropTypes.func,
    previousLoan: PropTypes.array,
  };

  render() {
    const { fieldRequired, proofAddressFiles, fileUploaded, previousLoan } =
      this.props;

    let hasPreviousProofAddress = false;
    previousLoan &&
      previousLoan.forEach((document) => {
        switch (document.document_type) {
          case 'ADDRESS':
            hasPreviousProofAddress =
              proofAddressFiles.length > 0 &&
              proofAddressFiles[0].key === document.document_key
                ? true
                : hasPreviousProofAddress;
            break;
          default:
            break;
        }
      });

    return (
      <StyledFileUploader
        title={text.proofOfAddress}
        documentType={LoansDocuments.proofOfAddress}
        accept={LoansDocuments.acceptFiles}
        maxFiles={LoansDocuments.maxFilesLoaded}
        fieldRequired={fieldRequired}
        files={proofAddressFiles}
        fileUploaded={fileUploaded}
        idPrefix={LoansDocuments.proofPrefix}
        fileID={1}
        filesCount={proofAddressFiles.length}
        previousLoan={hasPreviousProofAddress}
        withIcon={false}
        uploaderVersion={'2'}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    proofAddressFiles: getLoansProofAddressDocuments(state),
  };
};

export default connect(mapStateToProps)(AttachAddressProofDocuments);
