import styled from '@emotion/styled';
import MuiFormControl from '@material-ui/core/FormControl';

export const FormControl = styled(MuiFormControl)(({ theme }) => ({
  width: '100%',
  display: 'block',
  float: 'left',
  marginBottom: `${theme.spacing.unit}px`,
  marginTop: `${theme.spacing.unit}px`,
  '& legend': {
    textAlign: 'left',
    marginTop: `${theme.spacing.unit}px`,
    color: `${theme.palette.naranja.slate}`,
  },
  '&:first-child': {
    marginTop: 0,
    marginBottom: `${theme.spacing.unit}px`,
  },
}));

export const StepTitle = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  fontSize: `${theme.spacing.unit * 2.5}px`,
  fontWeight: 600,
  color: '#001724',
}));

export const StepDescription = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 2}px`,
  textAlign: 'left',
  fontSize: `${theme.spacing.unit * 2}px`,
  color: '#001724',
}));

export const StepSection = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit * 3}px`,
  textAlign: 'left',
  fontSize: `${theme.spacing.unit * 1.75}px`,
  color: '#001724',
}));

export const ListContainer = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: `${theme.spacing.unit}px`,
  textAlign: 'left',
  fontSize: `${theme.spacing.unit * 1.75}px`,
  color: '#001724',
}));
