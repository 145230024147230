import React from 'react';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import text from './text';
import { styles } from '../Contacts/styles';

const useStyles = makeStyles(styles);

const SaveContactInfo = (props) => {
  const { closeDrawer } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.changeContainer}>
      <ClipTypography
        variant="h3"
        fontWeight="semiBold"
        gutterBottom
        align="center"
      >
        {text.title}
      </ClipTypography>
      <ul>
        <li className={classes.listItem}>{text.info}</li>
      </ul>
      <ClipButton
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={() => closeDrawer()}
      >
        {text.accept}
      </ClipButton>
    </div>
  );
};

export default SaveContactInfo;
