const styles = (theme) => {
  const dialogStyles = {
    closeButton: {
      position: 'absolute',
      left: '90%',
      top: '2%',
      [theme.breakpoints.down('xs')]: {
        left: '83%',
        top: '3%',
      },
    },
    linkDialog: {
      cursor: 'pointer',
    },
    dialog: {
      textAlign: 'center',
      '& .crc-MuiDialogContent-root': {
        margin: `0 ${theme.spacing.unit * 12}px  ${theme.spacing.unit * 2}px ${
          theme.spacing.unit * 12
        }px`,
        [theme.breakpoints.down('xs')]: {
          margin: `0 ${theme.spacing.unit * 3}px  ${theme.spacing.unit * 4}px ${
            theme.spacing.unit * 3
          }px`,
        },
      },
      '& .crc-MuiDialogActions-root': {
        justifyContent: 'center',
        width: '100%',
        padding: `0 0 ${theme.spacing.unit * 4}px 0`,
        '& button': {
          width: '100%',
          margin: `0 ${theme.spacing.unit * 12}px`,
          [theme.breakpoints.down('xs')]: {
            margin: `0 ${theme.spacing.unit * 4}px`,
          },
        },
      },
      '& .crc-MuiDialogActions-spacing': {
        padding: `0 0 ${theme.spacing.unit * 2}px 0`,
      },
    },
    textContainer: {
      paddingRight: `${theme.spacing.unit}px`,
      width: `calc(100% + ${theme.spacing.unit * 0.5}px)`,
    },
    fontContent: {
      fontWeight: 300,
      textAlign: 'justify',
      '& i': {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontStyle: 'normal',
        cursor: 'pointer',
      },
    },
    titleContent: {
      fontWeight: 600,
      fontSize: `${theme.spacing.unit * 2.25}px`,
      lineHeight: `${theme.spacing.unit * 2.7}px`,
      marginBottom: `${theme.spacing.unit * 3}px`,
      marginTop: `${theme.spacing.unit * 3}px`,
    },
    logoContent: {
      justifyContent: 'center',
      display: 'flex',
    },
  };
  const detailStyles = {
    paysContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    textToPay: {
      display: 'inline-block',
      margin: '10px 5px 0 15px',
    },
    amountToPay: {
      display: 'inline-block',
      marginBottom: 0,
      color: '#fc4c02',
    },
    paymentButton: {
      textTransform: 'initial',
      padding: '8px 62px',
      minHeight: 38,
      margin: 10,
      display: 'block',
      float: 'right',
      width: 'auto',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 20px)',
        float: 'none',
      },
    },
  }
  return { ...dialogStyles, ...detailStyles };
};

export default styles;
