export default {
  title: 'Estamos preparando tu paquete',
  message1: 'Te enviaremos un correo a',
  message2: 'con los detalles del envío.',
  info: 'Información del envío:',
  name: 'Nombre',
  phone: 'Teléfono',
  address: 'Dirección',
  okButton: 'Entendido',
  orderNumber: 'Pedido número',
  processing: 'Procesado',
  onItsWay: '¡En camino!',
  delivered: 'Entregado',
  notFound: 'No te encontramos',
  shippedTitle: '¡Tu Clip Card va en camino!',
  shippedMessage:
    'Revisa los detalles de entrega en el SMS que enviaremos a tu celular.',
  guide: 'Guía ',
  deliveredTitle: '¡Tu Clip Card fue entregada!',
  errorTitle: 'No logramos entregar tu Clip Card',
  errorMessage:
    'Intentamos entregar tu orden hoy pero no fue posible. No te preocupes, lo intentaremos nuevamente ' +
    'en los próximos días.',
  rejectedTitle: 'No eres tú, somos nosotros.',
  rejectedMessage: 'Por favor, inténtalo de nuevo.',
  navigatorTitle: 'Ver solicitud',
};
