import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import {
  preapprovalsLoaded,
  getMerchantId,
  getLoansLoaded,
  getPreapprovals,
  getPreapprovalSelected,
} from 'components/loans/redux/selectors';
import { loansProviderCodes } from 'constants/ProviderCodes';
import { PreapprovalStatus } from 'constants/AppConstants';

import GTMCrediClipAnalyticsEvents from 'helpers/GTMCrediClipAnalyticsEvents';

export class GoogleAnalytics extends Component {
  state = {
    eventSentToGA: false,
    currentUrl: 'loans',
    preapprovalId: 'NA',
    merchantId: 'NA',
    providerCode: loansProviderCodes.CLIP,
    accountedUrls: [],
  };

  static propTypes = {
    preapprovalsLoaded: PropTypes.bool,
    merchantId: PropTypes.string,
    loansLoaded: PropTypes.bool,
    preapprovalSelected: PropTypes.object,
    preapprovals: PropTypes.array,
  };

  static defaultProps = {
    preapprovalsLoaded: false,
    merchantId: 'NA',
    loansLoaded: false,
    preapprovalSelected: {},
    preapprovals: [],
  };

  componentDidMount() {
    const { merchantId, preapprovals, preapprovalSelected, defaultUrl } =
      this.props;
    if (defaultUrl) {
      this.sendEventToGoogleAnalytics(null, merchantId, defaultUrl);
      return;
    }

    const accepted = preapprovals.filter((preapproval) =>
      [PreapprovalStatus.ACCEPTED].includes(preapproval.status),
    );

    const baseUrl = this.props.location.pathname;
    let preApprovalId = this.state.preapprovalId;
    if (
      preapprovalSelected.pre_approval_id !== null &&
      preapprovalSelected.pre_approval_id !== undefined
    ) {
      this.setState({ preapprovalId: preapprovalSelected.pre_approval_id });
      preApprovalId = preapprovalSelected.pre_approval_id;
    }
    if (accepted.length > 0) {
      this.setState({ preapprovalId: accepted[0].pre_approval_id });
      preApprovalId = accepted[0].pre_approval_id;
    }
    if (this.state.accountedUrls.includes(baseUrl)) {
      return;
    }

    this.sendEventToGoogleAnalytics(preApprovalId, merchantId, baseUrl);
  }

  sendEventToGoogleAnalytics = (preapprovalId, merchantId, baseUrl) => {
    if (this.isValidUrl(baseUrl.toString())) {
      if (this.state.accountedUrls.includes(baseUrl)) {
        return;
      }
      GTMCrediClipAnalyticsEvents.pageView(preapprovalId, merchantId, baseUrl);
      this.state.accountedUrls.push(baseUrl);
    }
  };

  componentDidUpdate() {
    const { preapprovals, merchantId, defaultUrl } = this.props;
    if (defaultUrl) {
      this.sendEventToGoogleAnalytics(null, merchantId, defaultUrl);
      return;
    }

    const baseUrl = this.props.location.pathname;
    if (this.state.accountedUrls.includes(baseUrl)) {
      return;
    }

    const accepted = preapprovals.filter((preapproval) =>
      [PreapprovalStatus.ACCEPTED].includes(preapproval.status),
    );
    let preApprovalId = this.state.preapprovalId;
    if (accepted.length > 0 && this.state.preapprovalId === 'NA') {
      this.setState({ preapprovalId: accepted[0].pre_approval_id });
      preApprovalId = accepted[0].pre_approval_id;
    }
    this.sendEventToGoogleAnalytics(preApprovalId, merchantId, baseUrl);
  }

  componentWillUnmount() {
    this.setState({ accountedUrls: [], providerCode: loansProviderCodes.CLIP });
  }

  isValidUrl = (url) => {
    const regexAcctionWithLender = RegExp(
      /\/loans\/(mot|aph|mrpresta|cto|r2){1}$/,
    );
    const regexAcctionWithCA = RegExp(
      /\/loans\/[mot,aph,mrpresta,cto,r2]+\/ca$/,
    );

    const baseUrl = url.replace('/mobile', '');

    const isFaqs = baseUrl.includes('faqs');
    const isMyPrestaclip = baseUrl.includes('myprestaclip');
    const isLoans = baseUrl === 'loans';
    const isLoansSlash = baseUrl === '/loans';
    const isUrlWithLender = regexAcctionWithLender.test(baseUrl);
    const isUrlWithCA = regexAcctionWithCA.test(baseUrl);
    const isNotValid2 =
      isFaqs ||
      isMyPrestaclip ||
      isLoans ||
      isLoansSlash ||
      isUrlWithLender ||
      isUrlWithCA;
    return baseUrl.includes('loans') && !isNotValid2;
  };

  render() {
    const { children } = this.props;
    return <React.Fragment>{children}</React.Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    preapprovalsLoaded: preapprovalsLoaded(state),
    preapprovals: getPreapprovals(state),
    merchantId: getMerchantId(state),
    loansLoaded: getLoansLoaded(state),
    preapprovalSelected: getPreapprovalSelected(state),
  };
};

export default withRouter(connect(mapStateToProps)(GoogleAnalytics));
