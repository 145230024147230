import styled from '@emotion/styled';

import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

const inlineBlock = 'inline-block';

const useStyles = makeStyles((theme) => {
  return {
    containerStyles: {
      width: `${theme.spacing.unit * 90}px`,
      display: inlineBlock,
      margin: `-${theme.spacing.unit}px -${theme.spacing.unit * 2}px 0`,
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        width: '100%',
      },
    },
    titleStyles: {
      width: '100%',
      fontFamily: 'AvertaStd',
      marginLeft: `${theme.spacing.unit * 2.5}px`,
      display: inlineBlock,
      color: theme.palette.naranja.slate,
      fontSize: `${theme.spacing.unit * 3.75}px`,
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        margin: 0,
      },
    },
    textTitleStyles: {
      marginTop: `${theme.spacing.unit * 2}px`,
      width: `${theme.spacing.unit * 76.25}px`,
      fontSize: `${theme.spacing.unit * 3.25}px`,
      fontWeight: 400,
      fontStretch: 'normal',
      letterSpacing: 'normal',
      color: '#001724',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0',
        display: 'inline-block',
        width: '100%',
      },
    },
    textSubtitleStyles: {
      marginTop: `${theme.spacing.unit * 2}px`,
      width: `${theme.spacing.unit * 76.25}px`,
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: 300,
      fontStretch: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: '#001724',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0',
        display: 'inline-block',
        width: '100%',
      },
    },
    expireWarningStyles: {
      display: 'inline-block',
      width: `${theme.spacing.unit * 59}px`,
      maxWidth: '100%',
      height: `${theme.spacing.unit * 5}px`,
      marginTop: `${theme.spacing.unit * 2}px`,
      marginBottom: `${theme.spacing.unit * 2}px`,
      marginLeft: `${theme.spacing.unit * 2.5}px`,
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      border: 'solid 1px rgba(0, 125, 142, 0.08)',
      backgroundColor: 'rgba(0, 125, 142, 0.06)',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    expireWarningMobileStyles: {
      display: 'none',
      width: `calc(100% - ${theme.spacing.unit * 0.5}px)`,
      maxWidth: '100%',
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      border: 'solid 1px rgba(0, 125, 142, 0.08)',
      borderLeft: 'solid 4px #007d8e',
      backgroundColor: 'rgba(0, 125, 142, 0.06)',
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block',
      },
    },
    expireWarningContentStyles: {
      display: 'inline-block',
      fontSize: `${theme.spacing.unit * 1.625}px`,
      color: '#007d8e',
      paddingRight: '10px',
      fontWeight: '500',
      '& > p': {
        marginTop: `${theme.spacing.unit * 1.5}px`,
        marginBottom: `${theme.spacing.unit * 1.5}px`,
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        '& > p': {
          marginTop: `${theme.spacing.unit}px`,
          marginBottom: `${theme.spacing.unit}px`,
        },
      },
    },
    clockTimeStyles: {
      display: 'inline-block',
      position: 'relative',
      marginRight: `${theme.spacing.unit * 0.75}px`,
      verticalAlign: 'middle',
      top: `-${theme.spacing.unit * 0.25}px`,
      marginTop: `${theme.spacing.unit}px`,
      marginBottom: `${theme.spacing.unit}px`,
      marginLeft: `${theme.spacing.unit}px`,
      fill: '#007d8e',
      color: '#007d8e',
      [theme.breakpoints.down('xs')]: {
        top: '-20%',
        marginTop: 0,
        marginBottom: 0,
        verticalAlign: 'inherit',
      },
    },
    contentStyles: {
      width: `${theme.spacing.unit * 88}px`,
      position: 'relative',
      float: 'left',
      display: inlineBlock,
      marginTop: `${theme.spacing.unit * 1.5}px`,
      [theme.breakpoints.down('xs')]: {
        marginTop: `${theme.spacing.unit}px`,
        width: '100%',
      },
    },
    instructionsStyles: {
      width: `${theme.spacing.unit * 83.5}px`,
      position: 'relative',
      display: inlineBlock,
      paddingLeft: `${theme.spacing.unit * 2}px`,
      paddingRight: `${theme.spacing.unit * 2}px`,
      color: '#001724',
      fontSize: `${theme.spacing.unit * 2.25}px`,
      fontWeight: theme.typography.fontWeightLight,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        paddingLeft: '0',
        textAlign: 'center',
      },
    },
    amountsContainerStyles: {
      width: `${theme.spacing.unit * 87.5}px`,
      position: 'relative',
      display: 'flex',
      '&:hover > div': {
        transform: 'scale(0.9)',
        transition: `transform ${theme.transitions.duration.shortest}ms`,
      },
      '&:hover > div:hover': {
        transform: 'scale(1.1)',
        transition: `transform ${theme.transitions.duration.shortest}ms`,
      },
      '& > div:not(:first-child)': {
        marginLeft: `${theme.spacing.unit * 2.5}px`,
      },
      '& > .recommended': {
        border: 'solid 2px #007d8e',
        width: `${theme.spacing.unit * 27.5 + 4}px!important`,
      },
      [theme.breakpoints.down('xs')]: {
        paddingBottom: `${theme.spacing.unit * 3}px`,
        width: '100%',
        overflowX: 'scroll',
        overflowY: 'hidden',
        display: 'flex',
        '&:hover > div': {
          transform: 'none',
          transition: 'none',
        },
        '&:hover > div:hover': {
          transform: 'none',
          transition: 'none',
        },
      },
    },
    amountStyles: {
      width: `${theme.spacing.unit * 27.5}px`,
      boxSizing: 'border-box',
      borderRadius: `${theme.spacing.unit}px`,
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
      opacity: 1,
      '& > .recommendedLabel': {
        display: 'block',
        position: 'relative',
        top: `${theme.spacing.unit * 3}px`,
        borderRadius: '0 0 6px 6px',
      },
      '&.recommended:after': {
        content: '"RECOMENDADO"',
        backgroundColor: '#007d8e',
        color: 'white',
        width: '102%',
        left: '-2px',
        top: `${theme.spacing.unit * 2}px`,
        position: 'relative',
        display: 'block',
        textAlign: 'center',
        margin: 'auto auto',
        fontSize: `${theme.spacing.unit * 1.375}px`,
        fontWeight: 600,
        paddingTop: `${theme.spacing.unit * 0.5}px`,
        paddingBottom: `${theme.spacing.unit * 0.5}px`,
        borderBottomRightRadius: `${theme.spacing.unit}px`,
        borderBottomLeftRadius: `${theme.spacing.unit}px`,
      },
      [theme.breakpoints.down('xs')]: {
        width: `${theme.spacing.unit * 30.25}px`,
        '&:first-child': {
          marginLeft: '2px',
        },
        marginTop: '2px',
        display: 'inline-block',
      },
    },
    amountContentStyles: {
      height: `${theme.spacing.unit * 23.75}px`,
      backgroundColor: 'rgba(0, 125, 142, 0.06)',
      padding: `${theme.spacing.unit * 2}px`,
      textAlign: 'center',
    },
    amountTitleStyles: {
      fontSize: `${theme.spacing.unit * 2.25}px`,
      fontWeight: 600,
      color: '#001724',
    },
    amountSubtitleStyles: {
      fontSize: '13px',
      fontWeight: 'normal',
      color: '#001724',
    },
    amountHRStyles: {
      width: `${theme.spacing.unit * 21}px`,
      height: '1px',
      marginTop: `${theme.spacing.unit}px`,
      marginLeft: `${theme.spacing.unit * 1.25}px`,
      backgroundColor: 'rgba(0, 125, 142, 0.24)',
    },
    amountDescriptionStyles: {
      width: '100%',
      marginTop: `${theme.spacing.unit * 1.25}px`,
      fontSize: '11px',
      color: '#001724',
    },
    descriptionTextStyles: {
      float: 'left',
      width: 'calc(50% - 4px)',
      textAlign: 'right',
      height: 90,
    },
    descriptionStyles: {
      margin: 0,
      padding: 0,
      marginTop: `${theme.spacing.unit * 0.5}px`,
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    importantNoteContainerStyles: {
      margin: 0,
      padding: 0,
    },
    importantNoteStyles: {
      margin: 0,
      padding: 0,
      fontWeight: 500,
    },
    importantNoteTextStyles: {
      margin: 0,
      padding: 0,
    },
    valuesTextStyles: {
      float: 'right',
      width: 'calc(50% - 4px)',
      textAlign: 'left',
      height: 90,
    },
    valuesStyles: {
      margin: 0,
      padding: 0,
      marginTop: `${theme.spacing.unit * 0.5}px`,
      [theme.breakpoints.down('xs')]: {
        overflowWrap: 'break-word',
      },
    },
    amountFooterStyles: {
      display: 'flex',
      flexDirection: 'column',
    },
    amountContainerStyles: {
      marginTop: `${theme.spacing.unit}px`,
      textAlign: 'center',
    },
    amountReducedStyles: {
      fontWeight: 'bold',
      color: '#007d8e',
      fontSize: `${theme.spacing.unit * 2.25}px`,
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    amountLoanStyles: {
      fontWeight: 'bold',
      color: '#007d8e',
      fontSize: `${theme.spacing.unit * 3.75}px`,
      marginLeft: `${theme.spacing.unit * 0.25}px`,
      marginRight: `${theme.spacing.unit * 0.25}px`,
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    buttonAmountStyles: {
      margin: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px 0px ${
        theme.spacing.unit * 2
      }px`,
    },
    stepsTitleStyles: {
      marginTop: `${theme.spacing.unit * 7}px`,
      marginBottom: `${theme.spacing.unit * 2}px`,
      color: '#001724',
      fontSize: `${theme.spacing.unit * 2.75}px`,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.33,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: `${theme.spacing.unit * 4}px`,
        // fontSize: theme.typography.title.fontSize,
        lineHeight: `${theme.spacing.unit * 3.25}px`,
      },
    },
    stepsContainerStyles: {
      width: '100%',
      marginTop: `${theme.spacing.unit * 4}px`,
      marginBottom: `${theme.spacing.unit * 4}px`,
      display: 'flex',
      color: theme.palette.naranja.slate,
      '& > div:not(:first-child)': {
        marginLeft: `${theme.spacing.unit * 2.5}px`,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'inline-grid',
        marginTop: `${theme.spacing.unit * 2}px`,
        '& > div:not(:first-child)': {
          marginLeft: 0,
        },
      },
    },
    stepStyles: {
      width: `${theme.spacing.unit * 27.5}px`,
      position: 'relative',
      display: 'block',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: `${theme.spacing.unit * 3}px`,
      },
    },
    stepIconContainerStyles: {
      display: 'inline-flex',
      verticalAlign: 'middle',
    },
    stepIconStyles: {
      width: '40px',
      height: '40px',
      display: 'block',
    },
    stepTitleStyles: {
      width: '100%',
      marginTop: `${theme.spacing.unit * 1}px`,
      display: inlineBlock,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightMedium,
      color: '#001724',
      [theme.breakpoints.down('xs')]: {
        fontSize: `${theme.spacing.unit * 2.25}px`,
      },
    },
    stepDescriptionStyles: {
      width: '100%',
      minWidth: `${theme.spacing.unit * 12.5}px`,
      marginTop: `${theme.spacing.unit * 1}px`,
      display: inlineBlock,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightLight,
      lineHeight: `${theme.spacing.unit * 2.5}px`,
      color: '#001724',
      [theme.breakpoints.down('xs')]: {
        width: '90%',
        textAlign: 'center',
        fontSize: `${theme.spacing.unit * 2.25}px`,
      },
    },
    containerMethods: {
      paddingLeft: 20,
      fontFamily: 'AvertaStd',
      '& .crc-MuiTypography-root': {
        fontFamily: 'AvertaStd !important',
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    card: {
      display: 'flex',
      width: '100%',
      maxWidth: 670,
      boxShadow: '0px 0px 8px rgba(128, 139, 145, 0.267892)',
      [theme.breakpoints.down('sm')]: {
        boxShadow: 'none',
        flexDirection: 'column',
      },
    },
    cardSlider: {
      background: theme.palette.indigo.l98,
      padding: '12px',
      width: '100%',
      display: 'inline-flex',
      justifyContent: 'center',
      float: 'left',
    },
    slider: {
      maxWidth: '420px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginTop: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        textAlign: 'initial',
      },
    },
    paddingSlider: {
      padding: '0px 8px 0px 8px',
    },
    sliderRailStyles: {
      height: 6,
    },
    sliderTrackStyles: {
      height: 6,
    },
    sliderThumbStyles: {
      height: 28,
      width: 28,
      marginTop: -12,
      marginLeft: -20,
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    textSlider: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 10,
      height: 24,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    sliderAmountLabel: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    sliderMaxAmountLabel: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    maxAmount: {
      padding: '4px 6px',
      borderRadius: '25px',
    },
    cardPay: {
      display: 'inline-flex',
      flexDirection: 'column',
      padding: '12px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        background: '#FFFFFF',
        borderRadius: '8px',
        boxShadow: '0px 0px 8px rgba(128, 139, 145, 0.267892)',
      },
      [theme.breakpoints.down('xs')]: {
        background: '#FFFFFF',
        textAlign: 'initial',
      },
    },
    titleLabelContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 0 30px 0',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    titleStyle: {
      fontSize: 44,
      fontWeight: '500',
    },
    cardSubtitle: {
      fontSize: 16,
      fontWeight: '400',
    },
    amountContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: 15,
      [theme.breakpoints.down('sm')]: {
        height: '40px',
        padding: '8px 12px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '8px 0',
      },
    },
    amountFirstContainer: {
      marginLeft: 10,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 5,
        marginRight: 5,
      },
    },
    buttonMargin: {
      marginTop: 40,
      borderRadius: 8,
      textTransform: 'none',
    },
    firstContainer: {
      height: '100%',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    containerLabels: {
      height: 'calc(100% - 64px)',
    },
    subtitleSecondContainer: {
      fontWeight: 500,
    },
  }
})

export default useStyles;
