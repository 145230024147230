import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAccountRep } from '../redux/berlinActions';
import BerlinService from '../service';
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { ROUTES2 as ROUTES } from '../constants';
import CdaHelper from '../CdaHelper';
import BerlinSpinner from '../BerlinSpinner';

const Auth = WrappedComponent => {
 const Hoc = ({isLogged, user, account, merchant, setAccountRep}) => {
    let history = useHistory();
    const [logged, setLogged] = useState(false);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
      setLogged(isLogged)
    }, [isLogged])
  
    useEffect(() => {
      if (merchant && merchant.info.id !== '') {
        BerlinService.accountInformationV2(merchant.info.id)
          .then((response) => {
            setAccountRep(response.data.message);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error)
            console.log('error status');
          });
      }
    }, [merchant]);
  
    const renderScreen = () => {
      if(loading){
        return <BerlinSpinner/>
      }
      if(logged){
        if(WrappedComponent && account){
          return <WrappedComponent/>;
        }
        history.push(`${ROUTES.BALANCE}`);
      }
      return (
        account && <CdaHelper
          user={user}
          noRedirect
          merchantId={account.account_holder_id}
        />
      )
    }
  
    return renderScreen();
  };
  
  const mapStateToProps = (state) => {
    return {
      account: state.berlin.account_representation,
      merchant: state.merchant,
      berlin: state.berlin,
      ldFlags: state.berlin.ldFlags,
      isLogged: state.berlin.isLogged,
      user: state.user,
    };
  };
  
  const mapDispatchToProps = {
    setAccountRep,
  };
  return connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(Hoc));
 }
  

export default Auth;
