import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { SubmitButton as CommonSubmitButton } from 'components/common/Buttons';

const regularText = '#000b11';

export const CreatingAccountContainer = styled.div(() => ({
  background: 'white',
  width: '20.5rem',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  marginTop: '30%',
  minHeight: '600px',
  alignItems: 'center',
}));

export const VerifyingData = styled.div(() => ({
  width: '20.5rem',
  height: '1.8rem',
  fontSize: '1.5rem',
  lineHeight: '1.5rem',
  fontWeight: '600',
  color: regularText,
  textAlign: 'center',
  marginBottom: '1rem',
}));

export const CheckingData = styled.div(() => ({
  width: '20.5rem',
  height: 'auto',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: 'normal',
  color: regularText,
  textAlign: 'center',
}));

export const Thanks = styled.div(() => ({
  width: '20.5rem',
  height: 'auto',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: 'normal',
  color: regularText,
  textAlign: 'center',
  marginBottom: '3rem',
}));

export const ClockImage = styled.img(() => ({
  width: '7rem',
  height: '7rem',
  objectFit: 'contain',
  marginBottom: '3rem',
  alignSelf: 'center',
}));

export const LdsRing = styled.div(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  width: '60px',
  height: '60px',
  marginBottom: theme.spacing.unit * 3,
  '& div': {
    boxSizing: 'border-box',
    display: 'block',
    position: 'absolute',
    width: '60px',
    height: '60px',
    margin: '8px',
    border: '3px solid #fff',
    borderRadius: '50%',
    animation: `${ldsRingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
    borderColor: '#fc4c02 transparent transparent transparent',
  },
  '& div:nth-child(1)': {
    animationDelay: '-0.45s',
  },
  '& div:nth-child(2)': {
    animationDelay: '-0.3s',
  },
  '& div:nth-child(3)': {
    animationDelay: '-0.15s',
  },
}));

const ldsRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
    }
  100% {
    transform: rotate(360deg);
    }
`;

const BaseButtonStyles = ({ theme }) => ({
  marginTop: '5rem',
  width: '20.5rem',
});

export const SubmitButton = styled(CommonSubmitButton)(BaseButtonStyles);
