import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const skeletonFake = 'rgba(230,232,233,0.9)';
const gradientColor = 'rgba(238,241,243,0.9)';

export const NoTransactionContainer = styled.div(() => ({
  background: 'white',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  marginTop: '2rem',
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  borderBottomLeftRadius: '24px',
  borderBottomRightRadius: '24px',
}));

export const DatePlaceholder = styled.div(() => ({
  width: '100%',
  height: '2rem',
  borderRadius: '8px',
  background: skeletonFake,
  opacity: '35%',
}));

export const PlaceholdersContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '2.81rem',
  width: '100%',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
}));

export const PlaceholdersIconContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '2rem',
}));

export const PlaceholdersIcon = styled.div(() => ({
  width: '2rem',
  height: '2rem',
  borderRadius: '8px',
  background: skeletonFake,
  backgroundImage: `linear-gradient(90deg, ${skeletonFake} 0px, ${gradientColor} 40px, ${skeletonFake} 80px)`,
  animation: `${shine} 1.6s infinite linear`,
  backgroundSize: '600px',
  opacity: '90%',
}));

export const PlaceholdersMidContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '9.5rem',
  paddingLeft: '0.5rem',
}));

export const PlaceholderHour = styled.div(() => ({
  width: '4.5rem',
  height: '1rem',
  borderRadius: '8px',
  background: skeletonFake,
  opacity: '90%',
  backgroundImage: `linear-gradient(90deg, ${skeletonFake} 0px, ${gradientColor} 40px, ${skeletonFake} 80px)`,
  animation: `${shine} 1.6s infinite linear`,
  backgroundSize: '600px',
}));

export const PlaceholderDescription = styled.div(() => ({
  width: '9.5rem',
  height: '1rem',
  borderRadius: '8px',
  background: skeletonFake,
  backgroundImage: `linear-gradient(90deg, ${skeletonFake} 0px, ${gradientColor} 40px, ${skeletonFake} 80px)`,
  animation: `${shine} 1.6s infinite linear`,
  backgroundSize: '600px',
  opacity: '90%',
}));

export const PlaceholdersEndContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: '100%',
}));

export const PlaceholderBalance = styled.div(() => ({
  width: '2rem',
  height: '1rem',
  borderRadius: '8px',
  background: skeletonFake,
  backgroundImage: `linear-gradient(90deg, ${skeletonFake} 0px, ${gradientColor} 40px, ${skeletonFake} 80px)`,
  animation: `${shine} 1.6s infinite linear`,
  backgroundSize: '600px',
}));

const shine = keyframes`
  0% {
    background-position: -100px
    }
  40%, 100% {
    background-position: 140px
    }
`;
