export default () => {
  return {
    errors: {
      login: 'Debes de iniciar sesión para continuar.',
      serverError: 'Error del servidor. Por favor, inténtelo de nuevo.',
      permissionDenied: 'Permiso negado.',
      tryAgain: `Hubo un error. Inténtalo de nuevo. Si continua, contacta a
    Customer Happiness.`,
    },
    forms: {
      businessOther: 'Mi negocio es diferente a estas categorías',
    },
  };
};
