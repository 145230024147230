import React from 'react';
import text from './text';
import useStyles from './styles';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import { ClipTypography } from '@clipmx/clip-storybook';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { EVENTS } from '../../../../helpers/Clevertap';
import urls from 'helpers/urls';

const FooterContact = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.cardgris}>
          <ClipTypography
            color="textSecondary"
            variant="body1"
            fontWeight="regular"
            align="center"
            gutterBottom
          >
            {text.contactWriteUs}{' '}
            <ClevertapComponent
              renderComponent={<a className={classes.colorBlue} />}
              events={[
                {
                  callbackName: 'onClick',
                  event: EVENTS.LOANS.PHONECUSTOMER,
                },
              ]}
              href={urls.clipPhone}
              target={'_blank'}
            >
              {text.contactWhatsaap}
            </ClevertapComponent>
            ,{' '}
            {text.contactExternalMessageAdvice}{' '}
            <ClevertapComponent
              renderComponent={<a className={classes.colorBlue} />}
              events={[
                {
                  callbackName: 'onClick',
                  event: EVENTS.LOANS.PHONECUSTOMER,
                },
              ]}
              href={urls.clipPhone}
              target={'_blank'}
            >
              {text.contactMessageNumber}
            </ClevertapComponent>
            ,{' '}
            {text.contactEmial}{' '}
            <ClevertapComponent
              renderComponent={<a className={classes.colorBlue} />}
              events={[
                {
                  callbackName: 'onClick',
                  event: EVENTS.LOANS.MAILCUSTOMER,
                },
              ]}
              href={`mailto:${urls.clipHelpEmail}`}
              target={'_blank'}
            >
              {text.contactUrlEmail}{' '}
            </ClevertapComponent>
            {text.contactChat}{' '}
            <ClevertapComponent
              renderComponent={<a className={classes.colorBlue} />}
              events={[
                {
                  // callbackName: 'onClick',
                  // event: EVENTS.LOANS.MAILCUSTOMER,
                },
              ]}
              href={`mailto:${urls.clipHelpEmail}`}
              target={'_blank'}
            >
              {text.contactWeb}{' '}
            </ClevertapComponent>
            .{' '}
          </ClipTypography>
        </Grid>
      </Grid>
    </>
  );
};

export default FooterContact;