export const styles = (theme) => {
    const rootStyles = {
        animationContainer: {
            height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
        },
        onTheWayText: {
            fontSize: '18px',
            fontWeight: '500',
            marginBottom: '0',
        },
        onTheWayAnimation: {
            height: '8.9rem',
            width: '8.9rem',
        },
    };
    return { ...rootStyles };
  };
  