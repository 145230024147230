export const styles = (theme) => {
  const rootStyles = {
    container: {
      minHeight: `calc(100vh - ${theme.spacing(7)}px)`,
      width: `calc(100% - ${theme.spacing(6)}px)`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: theme.spacing(7),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    listIcon: {
      minWidth: '0px',
      width: theme.spacing(5),
      '& svg': {
        color: '#43A710',
        width: theme.spacing(3.75),
        height: theme.spacing(3.5),
      },
    },
    requestImageContainer: {
      textAlign: 'center',
      margin: `${theme.spacing(2.75)}px 0px`,
      marginTop: theme.spacing(3.25),
      '& > img': {
        width: theme.spacing(24.25),
        height: theme.spacing(15.5),
      },
    },
    buttonContainer: {
      marginTop: 60,
      '& button': {
        width: '100%',
        marginBottom: `${theme.spacing.unit}px`,
        fontWeight: 600,
        fontSize: theme.spacing(2),
      },
    },
    buttonSkeleton: {
      width: '100%',
      height: theme.spacing(6),
      borderRadius: theme.spacing(3),
      marginBottom: theme.spacing(1),
      backgroundImage: `linear-gradient(90deg, ${theme.palette.indigo.l90} 0px,
      ${theme.palette.indigo.l80} 50%, ${theme.palette.indigo.l90} 100%)`,
      animation: 'shine 1.6s infinite linear',
    },
  };

  const listStyles = {
    listItem: {
      padding: `${theme.spacing(0.5)}px 0px`,
    },
    listItemText: {
      '& > span': {
        padding: 0,
      },
    },
    listItemTextAdvice: {
      paddingLeft: theme.spacing(5),
      paddingTop: theme.spacing(0.5),
    },
  };

  const typographyStyles = {
    typographyTitle: {
      marginTop: theme.spacing(3),
    },
    typographySubtitle: {
      marginTop: theme.spacing(2),
      width: '95%',
    },
    typographyBullet: {
      color: theme.palette.indigo.base,
      fontSize: 16,
    },
    typographyBody2: {
      color: '#4C4C4C',
    },
  };

  return { ...rootStyles, ...listStyles, ...typographyStyles };
};
