import styled from '@emotion/styled';

import { withStyles } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Grid } from '@material-ui/core';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { IoIosArrowDown } from 'react-icons/io';
import { FormV3ProcessButton as ClipSubmitButton } from 'components/common/Buttons';
import Card from 'components/common/Card';
import styledWithClasses from 'helpers/StyledWithClasses';

const width100 = '100%';
const gray = '#001724';
const block = 'block';
const flex = 'flex';


export const LoansTitle = styled.div(({ theme }) => ({
  width: width100,
  marginBottom: `${theme.spacing.unit * 2}px`,
  fontSize: `${theme.spacing.unit * 2.63}px`,
  fontWeight: 600,
  color: gray,
}));

export const LoansSubTitle = styled.div(({ theme }) => ({
  width: width100,
  marginBottom: `${theme.spacing.unit * 2}px`,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: theme.typography.fontWeightMedium,
  color: gray,
}));

export const GridCard = styled(Grid)(({ theme }) => ({
  marginBottom: `${theme.spacing.unit * 3}px`,
}));

export const CrediCard = styledWithClasses(
  styled(Card)(({ theme }) => ({
    display: block,
    width: width100,
    height: 'auto',
    paddingBottom: `${theme.spacing.unit * 2}px`,
    '&.margin24': {
      marginBottom: `${theme.spacing.unit * 3}px`,
    },
    '&.paddingTop': {
      paddingTop: `${theme.spacing.unit * 2}px`,
    },
    '&:last-child, & > .common-card-content-root': {
      display: flex,
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      width: width100,
      paddingTop: '0px !important',
      paddingLeft: '1px !important',
      paddingRight: '1px !important',
      paddingBottom: '0 !important',
    },
    '& .common-card-header-root': {
      border: 0,
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    },
    '& .common-card-header-title': {
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.naranja.blueGrey,
    },
    '& .card-header-action': {
      marginRight: 0,
    },
  })),
);

export const Title = styled.p(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 2.25}px`,
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 1.5}px`,
  marginLeft: `${theme.spacing.unit * 2}px`,
  color: gray,
  display: flex,
  width: width100,
  '&.amount': {
    display: 'none',
    [theme.breakpoints.down(425)]: {
      display: 'block',
      marginTop: 0,
      marginBottom: `${theme.spacing.unit * 1.5}px`,
      textAlign: 'left',
    },
  },
  [theme.breakpoints.down(425)]: {
    marginBottom: 0,
  },
}));


export const TitleConfirm = styled.p(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 2.25}px`,
  fontWeight: theme.typography.fontWeightRegular,
  marginTop: `${theme.spacing.unit * 2}px`,
  marginLeft: `${theme.spacing.unit * 2}px`,
  marginright: `${theme.spacing.unit * 2}px`,
  textAlign: 'left',
  color: gray,
  display: flex,
  width: width100,
  [theme.breakpoints.down(425)]: {
    marginBottom: 0,
  },
}));



export const TitleAmount = styled.span(({ theme }) => ({
  display: 'block',
  marginLeft: `${theme.spacing.unit * 0.5}px`,
  [theme.breakpoints.down(425)]: {
    display: 'none',
  },
}));


export const ButtonCentar = styled.span(({ theme }) => ({
  textAlign: '-webkit-center',
}));

export const Subtitle = styled.div(({ theme }) => ({
  width: width100,
  marginBottom: `${theme.spacing.unit * 1.5}px`,
  marginLeft: `${theme.spacing.unit * 2}px`,
  marginRight: `${theme.spacing.unit * 2}px`,
  fontSize: `${theme.spacing.unit * 1.75}px`,
  fontWeight: theme.typography.fontWeightMedium,
  color: gray,
  '&.center': {
    textAlign: 'left',
  },
  '&.normal': {
    fontWeight: theme.typography.fontWeightLight,
  },
}));

export const Bag = styled.span(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 1.5}px`,
  fontWeight: theme.typography.fontWeightMedium,
  padding: '2px 6px',
  backgroundColor: `${theme.palette.naranja.bloodOrange}04`,
  color: theme.palette.naranja.bloodOrange,
  borderRadius: `${theme.shape.borderRadius * 1}px`,
  border: `thin solid ${theme.palette.naranja.bloodOrange}16`,
  marginLeft: `${theme.spacing.unit * 1.25}px`,
  '&.showTop': {
    marginTop: `${theme.spacing.unit}px`,
  },
  '&.message': {
    fontSize: `${theme.spacing.unit * 1.75}px`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 0.625}px`,
    fontWeight: theme.typography.fontWeightLight,
    width: width100,
    marginLeft: `${theme.spacing.unit * 2}px`,
    marginRight: `${theme.spacing.unit * 2}px`,
    marginBottom: `${theme.spacing.unit * 2}px`,
    textAlign: 'left',
    [theme.breakpoints.down(425)]: {
      height: width100,
    },
  },
  [theme.breakpoints.down('xs')]: {
    minWidth: `${theme.spacing.unit * 8.5}px`,
    height: `${theme.spacing.unit * 2}px`,
    marginRight: `${theme.spacing.unit * 5}px`,
  },
}));

export const BlueBag = styled.span(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 1.5}px`,
  fontWeight: theme.typography.fontWeightMedium,
  padding: '2px 6px',
  backgroundColor: '#f7fafc',
  color: '#067584',
  borderRadius: `${theme.shape.borderRadius * 1}px`,
  border: 'thin solid #d0e5f0',
  marginLeft: `${theme.spacing.unit * 1.25}px`,
}));

export const HalfSizeContainer = styled.div(({ theme }) => ({
  width: width100,
  marginLeft: `${theme.spacing.unit * 2}px`,
  marginRight: `${theme.spacing.unit * 2}px`,
  display: 'flex',
  [theme.breakpoints.down(400)]: {
    display: 'block',
  },
}));
export const ItemHalfSize = styled.div(({ theme }) => ({
  width: '50%',
  '&:nth-child(odd)': {
    marginRight: `${theme.spacing.unit * 2}px`,
    [theme.breakpoints.down(400)]: {
      marginRight: 0,
      marginBottom: `${theme.spacing.unit * 0.5}px`,
    },
  },
  [theme.breakpoints.down(400)]: {
    width: width100,
  },
}));

export const Item = styled.div(({ theme }) => ({
  width: width100,
  marginTop: `${theme.spacing.unit * 0.5}px`,
  paddingLeft: `${theme.spacing.unit * 2}px`,
  paddingRight: `${theme.spacing.unit * 2}px`,
  display: flex,
}));

export const KeyVaule = styled.div(({ theme }) => ({
  width: width100,
  color: gray,
  marginBottom: '5px',
}));

export const HalfLeftVaule = styled.div(({ theme }) => ({
  width: '50%',
  color: gray,
  marginBottom: `${theme.spacing.unit * 1}px`,
  textAlign: 'left',
}));

export const LeftVaule = styled.div(({ theme }) => ({
  width: '100%',
  color: gray,
  marginBottom: `${theme.spacing.unit * 1}px`,
  textAlign: 'left',
}));

export const HalfRightVaule = styled.div(({ theme }) => ({
  width: '50%',
  color: gray,
  marginBottom: `${theme.spacing.unit * 1}px`,
  textAlign: 'right',
}));

export const Key = styled.div(({ theme }) => ({
  width: width100,
  fontSize: `${theme.spacing.unit * 1.5}px`,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const Value = styled.div(({ theme }) => ({
  width: width100,
  fontSize: `${theme.spacing.unit * 1.75}px`,
  fontWeight: 400,
  '& > b': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.paid': {
    color: '#109fb0',
    marginBottom: `${theme.spacing.unit * 1}px`,
  },
  '&.topay': {
    color: '#a2a9ae',
    marginBottom: `${theme.spacing.unit * 1}px`,
  },
}));

export const Separator = styled.div(({ theme }) => ({
  width: width100,
  height: '2px',
  backgroundColor: theme.palette.naranja.iceBlue,
  padding: '0 0',
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
  marginLeft: `${theme.spacing.unit * 1}px`,
  marginRight: `${theme.spacing.unit * 1}px`,
  '&.empty': {
    marginTop: 0,
    marginBottom: 0,
  },
  '&.topEmpty': {
    marginTop: 0,
  },
}));

export const ListItem = styled.div(({ theme }) => ({
  width: width100,
  color: gray,
  fontSize: `${theme.spacing.unit * 1.75}px`,
  fontWeight: 400,
  marginBottom: `${theme.spacing.unit * 1}px`,
  paddingLeft: `${theme.spacing.unit * 3}px`,
  '&:before': {
    borderRight: `thin solid ${theme.palette.naranja.teal}`,
    borderBottom: `thin solid ${theme.palette.naranja.teal}`,
    width: '3px',
    height: '7px',
    display: block,
    transform: 'rotate(45deg)',
    content: '""',
    position: 'relative',
    top: `${theme.spacing.unit * 1.5}px`,
    left: `${theme.spacing.unit * -1.5}px`,
  },
  [theme.breakpoints.down('xs')]: {
    marginRight: `${theme.spacing.unit * 3}px`,
  },
}));

export const MarginContainer = styled.div(({ theme }) => ({
  display: flex,
  marginBottom: `${theme.spacing.unit * 2}px`,
  marginTop: `${theme.spacing.unit * 2}px`,
}));

export const BlueCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.orange,
    display: block,
    width: `${theme.spacing.unit * 2.5}px`,
    height: `${theme.spacing.unit * 2.5}px`,
  },
}));
export const Label = styled.div(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 1.5}px`,
  fontWeight: 400,
  color: gray,
  paddingTop: `${theme.spacing.unit * 0.5}px`,
  marginLeft: '7px',
  display: block,
  [theme.breakpoints.down('xs')]: {
    marginRight: `${theme.spacing.unit * 3}px`,
  },
}));

export const SubmitButton = styled(ClipSubmitButton)(({ theme }) => ({
  width: width100,
  flexWrap: 'wrap',
  marginLeft: `${theme.spacing.unit * 2}px`,
  marginRight: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 3}px`,
}));

export const RefillPart = styled.div(({ theme }) => ({
  display: flex,
  padding: `${theme.spacing.unit}px 0 ${theme.spacing.unit}px ${
    theme.spacing.unit * 0.625
  }px`,
  backgroundColor: '#f6f6f6',
  color: '#001724',
  borderRadius: `${theme.shape.borderRadius * 1}px`,
  width: width100,
  flexDirection: 'row',
  flexWrap: 'wrap',
}));

export const BankPart = styled.div(({ theme }) => ({
  display: flex,
  padding: `${theme.spacing.unit}px 0 ${theme.spacing.unit}px ${
    theme.spacing.unit * 0.625
  }px`,
  backgroundColor: `${theme.palette.naranja.bloodOrange}06`,
  color: theme.palette.naranja.bloodOrange,
  borderRadius: `${theme.shape.borderRadius * 1}px`,
  width: width100,
  flexDirection: 'row',
  flexWrap: 'wrap',
  order: 1,
}));

export const ColumnPart = styled.div(({ theme }) => ({
  display: flex,
  width: '75%',
  flexDirection: 'column',
  flexWrap: 'wrap',
  order: 2,
}));

export const PartTitle = styled.span(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 1.5}px`,
  fontWeight: 400,
  height: '48%',
  width: width100,
  order: 1,
}));

export const PartAmount = styled.span(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 1.75}px`,
  fontWeight: theme.typography.fontWeightMedium,
  height: '48%',
  width: width100,
  order: 2,
}));

export const AlertContainer = styled.div(({ theme }) => ({
  display: flex,
  width: width100,
  marginBottom: `${theme.spacing.unit * 2.5}px`,
  marginTop: `${theme.spacing.unit * 1}px`,
  marginLeft: `${theme.spacing.unit * 2}px`,
  marginRight: `${theme.spacing.unit * 2}px`,
}));

export const ExpireWarning = styled.div(({ theme }) => ({
  width: '100%',
  height: `${theme.spacing.unit * 5}px`,
  marginTop: 0,
  marginBottom: 0,
  borderRadius: `${theme.spacing.unit * 0.5}px`,
  border: 'solid 1px rgba(0, 125, 142, 0.08)',
  borderLeft: 'solid 4px #007d8e',
  backgroundColor: 'rgba(0, 125, 142, 0.06)',
  [theme.breakpoints.down('xs')]: {
    marginTop: 0,
    width: `calc(100% - ${theme.spacing.unit * 0.5}px)`,
    height: `${theme.spacing.unit * 6.25}px`,
  },
}));

export const BankIcon = styled(AccountBalanceIcon)(({ theme }) => ({
  display: block,
  color: theme.palette.naranja.bloodOrange,
  verticalAlign: 'middle',
  marginRight: '7px',
  width: 24,
  height: `${theme.spacing.unit * 4.25}px`,
  order: 1,
}));

export const Icon = styled.img(({ theme }) => ({
  display: block,
  verticalAlign: 'middle',
  marginRight: '7px',
  width: 24,
  height: `${theme.spacing.unit * 4.25}px`,
  order: 1,
}));

export const ProgressBG = styled.div(({ theme }) => ({
  width: width100,
  height: '12px',
  borderRadius: `${theme.shape.borderRadius * 1}px`,
  paddingTop: 0,
  paddingBottom: '1px',
  backgroundColor: '#eaeaea',
  marginLeft: `${theme.spacing.unit * 2}px`,
  marginRight: `${theme.spacing.unit * 2}px`,
}));

export const Progress = styled.span(({ theme }) => ({
  height: '13px',
  display: 'block',
  position: 'relative',
  backgroundColor: `${theme.palette.naranja.teal}`,
  backgroundSize: '200% 200%',
  borderRadius: `${theme.shape.borderRadius * 1}px`,
  '&.finished': {
    backgroundColor: `${theme.palette.naranja.grey}`,
  },
}));

export const MaxTitle = styled.p(({ theme }) => ({
  width: width100,
  fontSize: `${theme.spacing.unit * 2.65}px`,
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
  color: gray,
  textAlign: 'center',
}));

export const ReviewTitle = styled.p(({ theme }) => ({
  width: width100,
  fontSize: `${theme.spacing.unit * 2.5}px`,
  fontWeight: 600,
  marginTop: `${theme.spacing.unit * -2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
  color: gray,
  textAlign: 'center',
}));

export const MaxCongrats = styled.div(({ theme }) => ({
  width: width100,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: 400,
  marginBottom: `${theme.spacing.unit * 2}px`,
  marginLeft: `${theme.spacing.unit * 2}px`,
  marginRight: `${theme.spacing.unit * 2}px`,
  color: gray,
  textAlign: 'center',
}));

export const MaxDetail = styled.div(({ theme }) => ({
  width: width100,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: 400,
  marginBottom: `${theme.spacing.unit * 1}px`,
  marginLeft: `${theme.spacing.unit * 2}px`,
  marginRight: `${theme.spacing.unit * 2}px`,
  color: gray,
  textAlign: 'center',
}));
export const CenterDetail = styled.div(({ theme }) => ({
  width: width100,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: 400,
  marginLeft: 'auto',
  marginRight: 'auto',
  color: gray,
  textAlign: 'center',
  lineHeight: 1.25,
  marginBottom: `${theme.spacing.unit * 4.25}px`,
}));

export const FooterContainer = styled.div(({ theme }) => ({
  backgroundColor: '#F5F7FA',
}));

export const TopImage = styled.img(({ theme }) => ({
  display: block,
  width: `${theme.spacing.unit * 22}px`,
  height: `${theme.spacing.unit * 18}px`,
  marginTop: `${theme.spacing.unit * 2.5}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export const TopBanner = styled.img(({ theme }) => ({
  display: block,
  marginTop: `${theme.spacing.unit * 2.5}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export const BannerContainer = styled.div(({ theme }) => ({
  width: width100,
  marginTop: `${theme.spacing.unit * 0.5}px`,
  paddingLeft: 0,
  paddingRight: 0,
  display: flex,
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  width: '100%',
  margin: `0 ${theme.spacing.unit * 2}px`,
}));

export const ShowHideContainer = styled.div(({ theme }) => ({
  width: '100%',
  position: 'relative',
  textAlign: 'right',
  marginTop: `${theme.spacing.unit * 1.25}px`,
  '&.second': {
    marginTop: 0,
  },
  [theme.breakpoints.down('xs')]: {
    marginTop: `${theme.spacing.unit * 1.5}px`,
  },
}));

export const HowToButton = styled.span(({ theme }) => ({
  color: lightBlue[700],
  fontSize: '14px',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '22px',
  textAlign: 'left',
  marginRight: `${theme.spacing.unit * 2.25}px`,
  cursor: 'pointer',
  position: 'absolute',
  right: '0%',
  '&:hover': {
    color: lightBlue[700],
    opacity: '.8',
  },
  '& > span': {
    [theme.breakpoints.down(362)]: {
      display: 'none',
    },
  },
  [theme.breakpoints.down(320)]: {
    top: `${theme.spacing.unit * -0.5}px`,
  },
}));

export const ShowHideIcon = styled(IoIosArrowDown)(({ theme }) => ({
  display: 'inline-block',
  marginLeft: `${theme.spacing.unit}px`,
  verticalAlign: 'middle',
  transform: 'rotate(0deg)',
  transition: 'transform 150ms linear',
  '&.hide': {
    transform: 'rotate(180deg)',
    transition: 'transform 150ms linear',
  },
  [theme.breakpoints.down(370)]: {
    marginLeft: 0,
  },

}));

export const ConfirmationMaxDetail = styled.div(({ theme }) => ({
  width: width100,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: 400,
  marginBottom: `${theme.spacing.unit * 1}px`,
  marginLeft: `${theme.spacing.unit * 2}px`,
  marginRight: `${theme.spacing.unit * 2}px`,
  color: gray,
  textAlign: 'center',
}));
