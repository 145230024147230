import {
  GET_BANK_ACCOUNT_INFORMATION,
  DELETE_BANK_ACCOUNT_CHANGE_REQUEST,
  CLEAR_BANK_INFO,
  PUBLIC_PROFILE_DATA,
} from './types';

const initialState = {
  error: null,
  bankInfo: {
    bankAccountName: '',
    bankAccountNumber: '',
    bankName: '',
    changeAccount: false,
    dateAdded: '',
    updatedAt: '',
    isBankAccountSet: false,
    code: '',
    flowVersion: '',
  },
  profile: {
    description: '',
    businessName: '',
    alias: '',
    image: '',
    hasProfile: false,
  },
};

const actionHandlers = new Map([
  [GET_BANK_ACCOUNT_INFORMATION, handleSearchForBankName],
  [DELETE_BANK_ACCOUNT_CHANGE_REQUEST, handleCancelBankAccountChange],
  [CLEAR_BANK_INFO, clearBankAccountChange],
  [PUBLIC_PROFILE_DATA, publicProfileData],
]);

export default function AccountReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleSearchForBankName(state, action) {
  return { ...state, bankInfo: action.payload };
}

function handleCancelBankAccountChange(state, action) {
  return { ...state, bankInfo: { ...state.bankInfo, ...action.payload } };
}

function clearBankAccountChange(state, action) {
  return { ...state, bankInfo: { ...initialState.bankInfo } };
}

function publicProfileData(state, action) {
  return {...state, profile: {...state.profile, ...action.payload}};
}