import styled from '@emotion/styled';

const regularText = '#000b11';

export const TransactionsGroupContainer = styled.div(({ theme }) => ({
  background: 'white',
  '&.mobile': {
    marginTop: `${theme.spacing.unit * 5}px`,
    marginBottom: `${theme.spacing.unit * 7}px`,
  },
  height: 'auto',
  width: '19.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
}));

export const TransactionsImageContainer = styled.div(({ theme }) => ({
  background: 'white',
  '&.mobile': {
    marginTop: `${theme.spacing.unit * 5}px`,
    marginBottom: `${theme.spacing.unit * 7}px`,
  },
  height: '10.313rem',
  width: '11rem',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
}));

export const TransactionsGroupDate = styled.div(() => ({
  width: '19.5rem',
  height: '2rem',
  fontSize: '1rem',
  lineHeight: '2rem',
  textAlign: 'center',
  borderRadius: '8px',
  background: 'rgba(230, 232, 233, 0.35)',
  color: regularText,
}));
