export const getTransactions = (state) => state.transactions.transactions;

export const getTransactionSearchParams = (state) => state.transactions.params;

export const getTransactionSearchError = (state) => state.transactions.error;

export const getRecentTransactions = (state) =>
  state.dashboard.recent.transaction;

export const getTransactionPagination = (state) =>
  state.transactions.pagination;

export const getTransactionDetail = (state) => state.transactions.detail;

export const getTransactionReport = (state) => state.transactions.report;

export const getTransactionLoadStatus = (state) => state.transactions.loading;

export const getCodiInformation = (state) => state.codi.settings;
