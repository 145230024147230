import React, { useEffect } from 'react';
import text from './text';
import useStyles from './styles';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipCard from '@clipmx/clip-storybook/dist/components/Card';
import { ClipTypography } from '@clipmx/clip-storybook';
import ClipButton from 'components/npm/clip-ui/Button';
import ClipGrid from '@clipmx/clip-storybook/dist/components/Grid';
import getUrl from 'helpers/getUrl';
import urls from 'helpers/urls';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  savePreapprovalSelected,
} from 'components/loans/redux/actions';
import { connect } from 'react-redux';
import { LoansStates, PreapprovalStatus } from 'constants/AppConstants';
import { toCurrencyFormat } from 'helpers/formatCurrency';
import ReactMarkdown from 'react-markdown';
import moment from 'moment-timezone';

import ClevertapComponent from 'helpers/Clevertap/ClevertapComponent';
import { lenderPayload } from 'components/loans/Commons/Clevertap';
import { Clevertap, EVENTS } from 'helpers/Clevertap';

const MyInterested = (props) => {
  const classes = useStyles();
  let history = useHistory();
  let location = useLocation();

  const { preapprovals, loans, showCashAdvance } = props;

  const availables = preapprovals.filter(
    (preapproval) =>
      [PreapprovalStatus.AVAILABLE].includes(preapproval.status) &&
      preapproval.type === LoansStates.REFILL,
  );

  const preapprovalSelected = availables.length > 0 ? availables[0] : {};

  const {
    amount,
    payback,
    term_maximum: termMaximum,
    expiration_date_local: expirationDate,
    retention_rate: retentionRate,
  } = preapprovalSelected;

  const accepted = preapprovals.filter((preapproval) =>
    [PreapprovalStatus.ACCEPTED, PreapprovalStatus.ACTIVE].includes(
      preapproval.status,
    ),
  );

  const loanSelected = loans.filter(
    (loan) =>
      accepted.length > 0 &&
      loan.pre_approval_id === accepted[0].pre_approval_id,
  );

  const toRefill = loanSelected.length > 0 ? loanSelected[0] : { current_balance: 0 };

  const deposit = amount - toRefill.current_balance;
  const comision = payback - amount;
  const totalPayback = payback

  const getBaseLocation = () =>
    location.pathname.startsWith(urls.mobile) ? urls.mobile : '';

  const selectPreapproval = (preapprovalSelected)  => () =>  {
    props.savePreapprovalSelected(preapprovalSelected)
    const {action} = props;
    action(preapprovalSelected);
    history.replace(
      getUrl(
        `${getBaseLocation()}${urls.loansRefillConfirmation.replace(
          ':lender',
          preapprovalSelected.provider_code.toLowerCase(),
        )}`,
      ),
    );
  };

  useEffect(() => {
    const availablesAll = preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.AVAILABLE].includes(preapproval.status) &&
        preapproval.type === LoansStates.REFILL,
    );
    const preapprovalSelAll = availablesAll.length > 0 ? availablesAll[0] : {};

    Clevertap.event(
      EVENTS.LOANS.NEW_OFFERS_VIEW,
      null,
      lenderPayload(preapprovalSelAll, {
        showCashAdvance,
      }, {
        type: 'refill',
        offer: 'available',
      }),
    )
  }, [])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ClipCard className={classes.cardNewSale}>
            <ClipTypography
              className={classes.fontW100}
              align="center"
              variant="subtitle1"
              gutterBottom
            >
              {text.yourNewSales}
            </ClipTypography>
            <ClipTypography align="center" variant="subtitle1" gutterBottom>
            <ReactMarkdown
                      source={`${text.expiration(
                        moment(expirationDate, 'YYYY-MM-DD').format(
                          'DD/MM/YYYY',
                        ),
                      )}`}
                    />
            </ClipTypography>
          </ClipCard>
          <ClipCard className={classes.cardNewPaly}>
            <ClipGrid container spacing={3}>
              <ClipGrid item xs={12} sm={12} md={12} lg={12}>
                <ClipTypography
                  align="center"
                  variant="h3"
                  fontWeight="semiBold"
                  gutterBottom
                >
                  {toCurrencyFormat(amount)}
                </ClipTypography>
              </ClipGrid>
              <ClipGrid item xs={6} sm={6} md={6} lg={6}>
                <ClipTypography
                  align="right"
                  variant="body2"
                  fontWeight="bold"
                  gutterBottom
                >
                  {text.newPay}
                </ClipTypography>
              </ClipGrid>
              <ClipGrid item xs={6} sm={6} md={6} lg={6}>
                <ClipTypography variant="body2" gutterBottom>
                {text.term(termMaximum)}
                </ClipTypography>
              </ClipGrid>
              <ClipGrid item xs={6} sm={6} md={6} lg={6}>
                <ClipTypography
                  align="right"
                  variant="body2"
                  fontWeight="bold"
                  gutterBottom
                >
                  {text.wayToPay}
                </ClipTypography>
              </ClipGrid>
              <ClipGrid item xs={6} sm={6} md={6} lg={6}>
                <ClipTypography
                  className={classes.textP}
                  variant="body2"
                  gutterBottom
                >
                {text.pay(parseFloat(retentionRate))}
                </ClipTypography>
              </ClipGrid>
            </ClipGrid>
          </ClipCard>
          <ClipCard className={classes.cardProgress}>
            <ClipGrid container spacing={3}>
              <ClipGrid item xs={12} sm={12} md={12} lg={12}>
                <ClipTypography
                  className={classes.textFont}
                  fontWeight="regular"
                  gutterBottom
                >
                  {text.youSale}
                </ClipTypography>
                <div className={classes.ProgressBar}>
                  <div
                    className={classes.ProgressBarDebt}
                    style={{ width: ((toRefill.current_balance/payback) * 100) + '%' }}
                  ></div>
                  <div
                    className={classes.ProgressBarBank}
                    style={{ width: (((amount - toRefill.current_balance) / payback) * 100) + '%' }}
                  ></div>
                  <div
                    className={classes.ProgressBarIva}
                    style={{ width: ((comision/payback) * 100) + '%' }}
                  ></div>
                </div>
              </ClipGrid>
              <ClipGrid item xs={12} sm={4} md={4} lg={4}>
                <ClipTypography
                  className={classes.debt}
                  fontWeight="regular"
                  gutterBottom
                >
                  {toCurrencyFormat(toRefill.current_balance)}
                </ClipTypography>
                <ClipTypography
                  className={classes.textFont}
                  fontWeight="regular"
                  gutterBottom
                >
                  {text.yourCurrentDebt}
                </ClipTypography>
              </ClipGrid>
              <ClipGrid item xs={12} sm={4} md={4} lg={4}>
                <ClipTypography
                  className={classes.bank}
                  fontWeight="regular"
                  gutterBottom
                >
                  {toCurrencyFormat(amount - toRefill.current_balance)}
                </ClipTypography>
                <ClipTypography
                  className={classes.textFont}
                  fontWeight="regular"
                  gutterBottom
                >
                  {text.yourBankAccount}
                </ClipTypography>
              </ClipGrid>
              <ClipGrid item xs={12} sm={4} md={4} lg={4}>
                <ClipTypography
                  className={classes.iva}
                  fontWeight="regular"
                  gutterBottom
                >
                  {toCurrencyFormat(comision)}
                </ClipTypography>
                <ClipTypography
                  className={classes.textFont}
                  fontWeight="regular"
                  gutterBottom
                >
                  {text.fixedCharge}
                </ClipTypography>
              </ClipGrid>
            </ClipGrid>
          </ClipCard>
          <ClipCard className={classes.carMyInterested}>
            <ClipGrid container spacing={3}>
              <ClipGrid item xs={6} sm={6} md={6} lg={6}>
                <ClipTypography
                  align="left"
                  variant="body2"
                  fontWeight="bold"
                  gutterBottom
                >
                  {text.totaltoPay}
                </ClipTypography>
              </ClipGrid>
              <ClipGrid item xs={6} sm={6} md={6} lg={6}>
                <ClipTypography
                  align="right"
                  variant="body2"
                  fontWeight="bold"
                  gutterBottom
                >
                  {toCurrencyFormat(totalPayback)}
                </ClipTypography>
              </ClipGrid>
              <ClipGrid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                className={classes.buttonCenter}
              >
                <ClevertapComponent
                  renderComponent={<ClipButton />}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={selectPreapproval(preapprovalSelected)}
                  events={[
                    {
                      callbackName: 'onClick',
                      event: EVENTS.LOANS.REFILL_VIEW,
                      payload: lenderPayload(preapprovalSelected, {
                        showCashAdvance,
                      }),
                    },
                  ]}
                >
                  {text.imInterested}
                </ClevertapComponent>
              </ClipGrid>
            </ClipGrid>
          </ClipCard>
        </Grid>
      </Grid>
    </>
  );
};

export default connect( null, {
  savePreapprovalSelected,
})( withRouter(MyInterested));

MyInterested.propTypes = {
  action: PropTypes.func.isRequired,
};

