import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import logger from '@redux-beacon/logger';

import {
  EMAIL_VALIDATION_SUCCESS,
  UPDATE_MERCHANT,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_USER,
} from '../actions/Types';

import { REGISTER_SUCCESS, LOGIN_REJECTED } from 'components/auth/redux/types';

import {
  email_validation_success,
  signup_form_success,
  get_merchant_info,
  login_attempt_failed,
  update_password_success,
  update_user,
} from '../helpers/GTMEvents';

const eventsMap = {
  [EMAIL_VALIDATION_SUCCESS]: email_validation_success,
  [REGISTER_SUCCESS]: signup_form_success,
  [UPDATE_MERCHANT]: get_merchant_info,
  [LOGIN_REJECTED]: login_attempt_failed,
  [UPDATE_PASSWORD_SUCCESS]: update_password_success,
  [UPDATE_USER]: update_user,
};

// Apply middlewares
const debug = process.env.NODE_ENV === 'production' ? false : true;
const gtm = createMiddleware(
  eventsMap,
  GoogleTagManager(),
  debug ? { logger } : {},
);

export default gtm;
