import { Actions, Categories, Events, Labels } from '../constants/GTMConstants';

export const email_validation_success = () => ({
  hitType: Events.GENERIC,
  eventCategory: Categories.SIGNUP,
  eventAction: Actions.VALIDATED_EMAIL,
});

export const signup_form_success = () => ({
  hitType: Events.GENERIC,
  eventCategory: Categories.SIGNUP,
  eventAction: Actions.COMPLETED_FORM,
});

export const get_merchant_info = (action) => ({
  hitType: Events.AUTHENTICATION,
  userID: action.payload.merchant.id,
});

export const login_attempt_failed = (action) => ({
  hitType: Events.GENERIC,
  eventCategory: Categories.LOGIN,
  eventAction: action.payload.accountLocked
    ? Actions.ACCOUNT_LOCKOUT
    : Actions.ATTEMPT_FAILED,
  eventLabel: action.payload.attempts,
});

export const update_password_success = () => ({
  hitType: Events.GENERIC,
  eventCategory: Categories.MERCHANT_INFORMATION,
  eventAction: Actions.UPDATE,
  eventLabel: Labels.PASSWORD,
});

export const update_user = () => ({
  hitType: Events.GENERIC,
  eventCategory: Categories.MERCHANT_INFORMATION,
  eventAction: Actions.UPDATE,
  eventLabel: Labels.PERSONAL,
});
