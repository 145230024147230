import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { store } from 'react-notifications-component';
import moment from 'moment-timezone';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants';

import CopyIcon from '../../../../assets/images/berlin/copyIcon.svg';
import { setAccountRep } from '../../redux/berlinActions';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import {EVENTS} from '../../../../helpers/Clevertap';
import { ClipTypography, ClipButton } from '@clipmx/clip-storybook';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import ClipCard from '@clipmx/clip-storybook/dist/components/Card';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';
import { ArrowForward as ArrowForwardIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@clipmx/clip-storybook/dist/components/Icons';

import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { BERLIN_TANDEM_UPGRADE_STATUS, BERLIN_FINANCIAL_SERVICE_PROVIDERS } from '../../../../constants/AppConstants';
import { styles } from '../styles';
import text from '../text';

import WhatsAppModule from '../../WhatsAppModule';

const useStyles = makeStyles(styles);
const BankInformation = (props) => {
  let history = useHistory();
  const classes = useStyles(props);
  const {
    account: { account_level, upgrade_status, secondary_upgrade_status },
    account_holder_representation: {
      name,
      last_name,
      second_last_name,
      bank_acronym,
      bank_name,
    },
    interbank_account,
    lastLogin,
    setError,
    setChangeCdaPath,
    setScreen,
    SCREENS,
    flags: { financialActivateSwapUpgradeWeb },
  } = props;
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [yPosition, setYPosition] = useState(0);

  const accountHolderName = text.accountHolder(
    name,
    last_name,
    second_last_name,
  );
  moment.locale('es-mx');
  const lastSession = moment(lastLogin).format('DD MMMM YYYY, HH:mm') + ' hrs';

  const copyText = (textToCopy) => {
    if (window.clipAndroidInterface) {
      window.clipAndroidInterface.textToClipboard(textToCopy);
      store.addNotification({
        title: text.copySuccessNotification,
        message: ' ',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 1500,
          onScreen: false,
        },
      });
      return;
    }
    navigator.clipboard.writeText(textToCopy).then(() => {
      store.addNotification({
        title: text.copySuccessNotification,
        message: ' ',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 1500,
          onScreen: false,
        },
      });
    });
  };

  const copyAllText = () => {
    const textToCopy = `${text.copyAllGreeting}
          ${text.name}
          ${name} ${last_name} ${second_last_name}

          ${text.clabe}
          ${interbank_account}

          ${text.bank}
          ${bank_name} (${bank_acronym})
        `;
    copyText(textToCopy);
  };

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <div className={classes.title}>
          <ClipTypography variant={'h3'} component={'h1'} fontWeight={'bold'}>
            {text.infoGreeting(name)}
          </ClipTypography>
          <ClipTypography variant={'body1'} component={'p'}>
            {text.lastSession(lastSession)}
          </ClipTypography>
        </div>
        <div className={classes.content}>
          <ClipTypography
            variant={'body1'}
            component={'p'}
            fontWeight={'medium'}
          >
            {text.accountInfo}
          </ClipTypography>
          <ClipCard classes={{ root: classes.accountCard }}>
            <div className={classes.actionHolder}>
              <div>
                <ClipTypography variant={'body1'} component={'p'}>
                  {text.name}
                </ClipTypography>
                <ClipTypography
                  variant={'body1'}
                  component={'p'}
                  fontWeight={'bold'}
                >
                  {accountHolderName}
                </ClipTypography>
              </div>
              <IconButton onClick={() => copyText(accountHolderName)}>
                <img src={CopyIcon} alt={accountHolderName} />
              </IconButton>
            </div>
            <div className={classes.cardDivider} />
            <div className={classes.actionHolder}>
              <div>
                <ClipTypography variant={'body1'} component={'p'}>
                  {text.clabe}
                </ClipTypography>
                <ClipTypography
                  variant={'body1'}
                  component={'p'}
                  fontWeight={'bold'}
                >
                  {interbank_account}
                </ClipTypography>
              </div>
              <IconButton onClick={() => copyText(interbank_account)}>
                <img src={CopyIcon} alt={interbank_account} />
              </IconButton>
            </div>
            <div className={classes.cardDivider} />
            <div className={classes.actionHolder}>
              <div className={classes.infoHolder}>
                <ClipTypography variant={'body1'} component={'p'}>
                  {text.bank}
                </ClipTypography>
                <ClipTypography
                  variant={'body1'}
                  component={'p'}
                  fontWeight={'bold'}
                >
                  {`${bank_name} (${bank_acronym})`}
                </ClipTypography>
              </div>
              <IconButton
                onClick={() => copyText(`(${bank_acronym}) ${bank_name}`)}
              >
                <img src={CopyIcon} alt={bank_name} />
              </IconButton>
            </div>
            <div className={classes.cardDivider} />
            <div className={classes.copyAll}>
              <ClipButton
                classes={{ root: classes.copyAllButton }}
                color={'primary'}
                onClick={() => copyAllText()}
              >
                {text.copyAllButton}
              </ClipButton>
            </div>
          </ClipCard>
          <ClipTypography
            variant={'body1'}
            component={'p'}
            fontWeight={'medium'}
          >
            {text.settings}
          </ClipTypography>
          {financialActivateSwapUpgradeWeb &&
            secondary_upgrade_status !==
              BERLIN_TANDEM_UPGRADE_STATUS.COMPLETED &&
            upgrade_status !== BERLIN_TANDEM_UPGRADE_STATUS.COMPLETED && (
              <ClipCard classes={{ root: classes.settingsCard }}>
                <div>
                  <div className={classes.inlineLimit}>
                    <ClipTypography variant={'body1'} component={'p'}>
                      {text.tooltipTitle}
                    </ClipTypography>
                    <IconButton
                      onClick={() => {
                        setDisplayTooltip(true);
                        setYPosition(window.pageYOffset);
                      }}
                    >
                      <HelpOutlineOutlinedIcon className={classes.helpIcon} />
                    </IconButton>
                  </div>
                  <ClipTypography
                    variant={'body1'}
                    component={'p'}
                    fontWeight={'bold'}
                  >
                    {text.amountN2}
                  </ClipTypography>
                </div>
                <ClevertapComponent
                  renderComponent={<ClipButton />}
                  events={[
                    {
                      callbackName: 'onClick',
                      event: EVENTS.BERLIN.ACCOUNT_UPGRADE.EVENT,
                      action: EVENTS.BERLIN.ACCOUNT_UPGRADE.ACTIONS.CLICKED,
                    },
                  ]}
                  validation={!props.upgrade_status}
                  color={'primary'}
                  variant={'outlined'}
                  onClick={() => {
                    history.push(ROUTES.BERLINSWAP);
                  }}
                >
                  {props.upgrade_status === 'REQUESTED'
                    ? text.showRequest
                    : props.upgrade_status === 'REJECTED'
                    ? text.showRequest
                    : text.increase}
                </ClevertapComponent>
              </ClipCard>
            )}
          { false && props.account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.SWAP &&
          <>
            <ClipCard classes={{ root: classes.settingsCard }}>
              <ClipTypography variant={'body1'} component={'p'} fontWeight={'medium'}>
                {text.beneficiaries}
              </ClipTypography>
              <ArrowForwardIcon />
            </ClipCard>
            <ClipCard classes={{ root: classes.settingsCard }}>
              <ClipTypography variant={'body1'} component={'p'} fontWeight={'medium'}>
                {text.accountStatus}
              </ClipTypography>
              <ArrowForwardIcon />
            </ClipCard>
          </>
          }
          { props.account.provider_code !== BERLIN_FINANCIAL_SERVICE_PROVIDERS.SWAP &&
          <>
            <ClipCard classes={{ root: classes.settingsCard }}>
              <ClipTypography
                variant={'body1'}
                component={'p'}
                fontWeight={'medium'}
              >
                {text.changeCDA}
              </ClipTypography>
              <ClevertapComponent
                renderComponent={<IconButton />}
                events={[
                  {
                    callbackName: 'onClick',
                    event: EVENTS.BERLIN.MY_ACCOUNT.EVENT,
                    action: EVENTS.BERLIN.MY_ACCOUNT.ACTIONS.CHANGE_CDA_CLICKED,
                  },
                ]}
                onClick={() => {
                  setError(null);
                  setChangeCdaPath(true);
                  setScreen(SCREENS.PASSWORD);
                }}
              >
                <ArrowForwardIcon />
              </ClevertapComponent>
            </ClipCard>
            <ClipCard classes={{ root: classes.settingsCard }}>
              <ClipTypography variant={'body1'} component={'p'} fontWeight={'medium'}>
                {text.changePass}
              </ClipTypography>
              <ArrowForwardIcon />
            </ClipCard>
          </>
          }
        </div>
      </div>
      <WhatsAppModule />
      <ClipDrawer
        anchor={'bottom'}
        open={displayTooltip}
        classes={{ paper: classes.tooltipPaper }}
      >
        <div className={classes.tooltipContainer}>
          <ClipTypography
            variant={'h3'}
            component={'h2'}
            align={'center'}
            fontWeight={'semiBold'}
            classes={{
              root: classes.tooltipTitle,
            }}
          >
            {text.tooltipTitle}
          </ClipTypography>
          <ClipTypography variant={'body1'} component={'p'} align={'left'}>
            {text.tooltipAdvice(
              account_level === 2 ? text.amountN2 : text.amountN3,
            )}
          </ClipTypography>
          <ClipTypography variant={'body1'} component={'p'} align={'left'}>
            {text.tooltipAdvice2(
              account_level === 2 ? text.amountN2 : text.amountN3,
            )}
          </ClipTypography>
          <div className={classes.tooltipButtonContainer}>
            <ClipButton
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                setDisplayTooltip(false);
              }}
              onClose={() => window.scrollTo(0, yPosition + 15)}
            >
              {text.tooltipButton}
            </ClipButton>
          </div>
        </div>
      </ClipDrawer>
    </div>
  );
};

BankInformation.propTypes = {
  account_holder_representation: PropTypes.object,
  account: PropTypes.object,
  interbank_account: PropTypes.string.isRequired,
  lastLogin: PropTypes.string.isRequired,
  setError: PropTypes.func,
  setChangeCdaPath: PropTypes.func,
  setScreen: PropTypes.func,
  SCREENS: PropTypes.object,
};

BankInformation.default = {
  account_holder_representation: {},
  account: {},
  interbank_account: '',
  lastLogin: '',
  setError: () => {},
  setChangeCdaPath: () => {},
  setScreen: () => {},
  SCREENS: {},
};

const mapDispatchToProps = {
  setAccountRep,
};

const mapStateToProps = (state) => {
  return {
    account: state.berlin.account_representation,
    upgrade_status: state.berlin.account_representation.upgrade_status,
    merchant: state.merchant,
    user: state.user,
  };
};

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(BankInformation),
);
