export const Events = {
  GENERIC: 'GAEvent',
  AUTHENTICATION: 'Authentication',
  VIRTUAL_PAGE_VIEW: 'VirtualPageview',
  REPORT_DOWNLOAD: 'DownloadCSV',
  CREDICLIP_PAGE_VIEW: 'Pageview',
};

export const Categories = {
  SIGNUP: 'Signup',
  CHECKOUT: 'Checkout',
  LOGIN: 'Login',
  BUY_READER_LINK: 'Buy reader link',
  CSV_DETAIL_DOWNLOAD: 'Download CSV',

  ONBOARDING: 'Onboarding',
  PROMO_CODES: 'Promo codes',
  MERCHANT_REFERRALS: 'Merchant Referrals',
  INSTALLMENTS: 'Installments',
  PAYMENT_NOTIFICATIONS: 'Payment notifications',
  POSTBACK_NOTIFICATIONS: 'Postback notifications',
  COLLABORATORS: 'Collaborators',
  MERCHANT_INFORMATION: 'Merchant information',
  BUSINESS_INFORMATION: 'Business information',
  BANK_ACCOUNTS: 'Bank accounts',
  CREDICLIP: 'Crediclip',
  CREDICLIP_FONEL: 'Crediclip-fonel',
};

export const Actions = {
  VALIDATED_EMAIL: 'Validated email',
  COMPLETED_FORM: 'Completed Form',
  CHECKOUT_COMPLETED: 'Completed',
  ORDER_CREATED: 'Order created',
  DOWNLOAD_CSV: 'Deposits CSV download',

  ACCOUNT_SIGNUP: 'Account Signup',
  BUSINESS_INFORMATION: 'Business',
  BANK_INFORMATION: 'Bank',
  INSTALLMENTS_INFORMATION: 'Installments',
  COLLABORATORS_INFORMATION: 'Collaborators',

  PROMO_REFERRAL_LANDING: 'Registration Landing',
  REFERRAL_CODE_LANDING: 'Landing Page',
  ENTER_PROMO_CODE: 'Entered',
  ENTER_REFERRAL_CODE: 'Code Entered',
  ENTER_PROMO_AND_REFERRAL_CODE: 'Enter Promo and Referral Code',
  CORRECT_PROMO_CODE: 'Correct',
  CORRECT_REFERRAL_CODE: 'Correct Code',
  INCORRECT_PROMO_CODE: 'Incorrect',
  INCORRECT_REFERRAL_CODE: 'Incorrect Code',
  INCORRECT_REFERRAL_CODE_REENTER: 'Incorrect Code Reenter',
  INCORRECT_REFERRAL_CODE_NEXT: 'Incorrect Code Next',
  INCORRECT_PROMO_CODE_REENTER: 'Incorrect Reenter',
  INCORRECT_PROMO_CODE_NEXT: 'Incorrect Next',

  ATTEMPT_FAILED: 'Attempt failed',
  ACCOUNT_LOCKOUT: 'Account lockout',
  CANCEL_CHANGE: 'Cancel change',

  //Crediclip
  CREDICLIP_FONEL_PAGEVIEW: 'Pageview',

  // Generic actions
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  UPDATE: 'Update',
  DELETE: 'Delete',
  INSERT: 'Insert',
};

export const Labels = {
  PERSONAL: 'Personal',
  PASSWORD: 'Password',
};
