import React, { useEffect } from 'react';
import text from './text';
import styles from './styles';
import PropTypes from 'prop-types';
import successImg from '../../../assets/images/berlin/successImgv2.svg';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ClevertapComponent from '../../../helpers/Clevertap/ClevertapComponent';
import {EVENTS} from '../../../helpers/Clevertap';
import AccountCreated from '../../../assets/images/berlin/AccountCreated.svg';
import { ROUTES, ROUTES2 } from '../constants';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';

const useStyles = makeStyles(styles);

const Activation2 = (props) => {
  let history = useHistory();
  const media = window.matchMedia('(max-height: 625px)');
  const classes = useStyles();
  const {
    flags: { financialActivateUpgradeN3Web },
  } = props;

  const routeOnClick = (url) => {
    history.push(url);
  };

  const renderUpgrade = () => {
    if (media.matches) {
      return (
        <div className={classes.upgradeContainerForSE}>
          <ClipTypography
            align={'center'}
            variant={'body2'}
            component={'p'}
            fontWeight={'bold'}
            className={classes.color}
          >
            {text.upgradeN3}
          </ClipTypography>
          <ClevertapComponent
            renderComponent={<ClipTypography />}
            component={'p'}
            events={[
              {
                callbackName: 'onClick',
                event: EVENTS.ACCOUNT_CREATED_UPGRADE_CLICKED,
              },
            ]}
            onClick={() => {
              history.push(ROUTES.UPGRADE);
            }}
            align={'center'}
            variant={'body2'}
            fontWeight={'bold'}
            className={classes.colorSub}
          >
            {text.upgradeN3Sub}
          </ClevertapComponent>
        </div>
      );
    }
    return (
      <div className={classes.upgradeContainer}>
        <ClipTypography
          align={'center'}
          variant={'body2'}
          component={'p'}
          fontWeight={'bold'}
          className={classes.color}
        >
          {text.upgradeN3}
        </ClipTypography>
        <ClevertapComponent
          renderComponent={<ClipTypography />}
          events={[
            {
              callbackName: 'onClick',
              event: EVENTS.ACCOUNT_CREATED_UPGRADE_CLICKED,
            },
          ]}
          onClick={() => {
            history.push(ROUTES.UPGRADE);
          }}
          align={'center'}
          variant={'body2'}
          fontWeight={'bold'}
          component={'p'}
          className={classes.colorSub}
        >
          {text.upgradeN3Sub}
        </ClevertapComponent>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <img
        className={media.matches ? classes.okImgForSE : classes.okImg}
        src={successImg}
        alt=""
      />
      <ClipTypography
        className={media.matches ? classes.titleForSE : classes.title}
      >
        {text.title}
      </ClipTypography>
      <ClipTypography
        className={media.matches ? classes.subTitleForSE : classes.subTitle}
      >
        {text.accountCreatedN3} <b>{text.amount}</b>
      </ClipTypography>
      <ClipTypography
        className={media.matches ? classes.infoForSE : classes.info}
      >
        {text.checkInfo}
      </ClipTypography>
      <img className={classes.LandingImage} src={AccountCreated} alt="" />
      {financialActivateUpgradeN3Web &&
      props.account_representation &&
      props.account_representation.account_level === 2 ? (
        renderUpgrade()
      ) : (
        <>
          <ClipTypography
            className={
              media.matches ? classes.growAccountSE : classes.growAccount
            }
          >
            {text.upgradeN3}
          </ClipTypography>
          <ClipTypography className={classes.customerHappines}>
            {text.contact}
          </ClipTypography>
        </>
      )}

      <div className={classes.btnContainer}>
        <ClipButton
          className={classes.btn}
          variant="contained"
          color="primary"
          onClick={() => routeOnClick(ROUTES2.AUTH)}
        >
          {text.done}
        </ClipButton>
      </div>
    </div>
  );
};

Activation2.propTypes = {
  flags: PropTypes.object.isRequired,
};

Activation2.defaultProps = {
  flags: {},
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    account_representation: state.berlin.account_representation,
  };
};

export default withLDConsumer()(connect(mapStateToProps)(Activation2));
