import capitalize from 'lodash/capitalize';
import { Actions, Categories, Events } from '../constants/GTMConstants';

function _sendEvent(send, eventName, category, action, label, value) {
  let triesRemaining = 10;

  if (typeof ga === 'function') {
    window.ga(send, eventName, category, action, label, value);
  } else {
    const interval = setInterval(() => {
      if (triesRemaining === 0) {
        clearInterval(interval);
        return;
      }

      if (typeof ga === 'function') {
        window.ga(send, eventName, category, action, label, value);
        clearInterval(interval);
      }

      triesRemaining -= 1;
    }, 100);
  }
}

function _pushDataLayer(event, category, action, label = null, value = null) {
  window.dataLayer.push({
    event: event || Event.GENERIC,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value,
  });
}

export default {
  // Page View
  pageView: (path) => {
    if (!window.dataLayer) return;

    window.dataLayer.push({
      event: Events.VIRTUAL_PAGE_VIEW,
      virtualPageURL: path,
      virtualTagTitle: '',
    });
  },

  //Catalog
  catalogClick: (label, value) => {
    _sendEvent('send', 'event', 'catalog', 'click', label, value);
  },

  catalogCreate: (label, value) => {
    _sendEvent('send', 'event', 'catalog', 'create', label, value);
  },

  catalogUpdate: (label, value) => {
    _sendEvent('send', 'event', 'catalog', 'update', label, value);
  },

  catalogDelete: (label, value) => {
    _sendEvent('send', 'event', 'catalog', 'delete', label, value);
  },

  // Support
  supportHeader: () => {
    _sendEvent('send', 'event', 'support', 'click', 'web_header_menu');
  },

  supportFooter: () => {
    _sendEvent('send', 'event', 'support', 'click', 'web_footer');
  },

  // Help
  helpDashboard: () => {
    _sendEvent('send', 'event', 'help_email', 'click', 'dashboard_help');
  },

  helpBank: () => {
    _sendEvent(
      'send',
      'event',
      'help_email',
      'click',
      'dashboard_add_bankacct',
    );
  },

  // App Store
  footerIos: () => {
    _sendEvent('send', 'event', 'ios_button', 'click', 'web_footer');
  },

  footerAndroid: () => {
    _sendEvent('send', 'event', 'android_button', 'click', 'web_footer');
  },

  // Social
  // Social Footer
  socialFooterTwitter: () => {
    _sendEvent('send', 'event', 'tw_clipmx', 'click', 'web_footer');
  },

  socialFooterFacebook: () => {
    _sendEvent('send', 'event', 'fb_clipmx', 'click', 'web_footer');
  },

  socialFooterYoutube: () => {
    _sendEvent('send', 'event', 'yt_clipmx', 'click', 'web_footer');
  },

  // Social Dashboard
  socialDashboardTwitter: () => {
    _sendEvent('send', 'event', 'tw_clipmx', 'click', 'dashboard');
  },

  socialDashboardFacebook: () => {
    _sendEvent('send', 'event', 'fb_clipmx', 'click', 'dashboard');
  },

  socialDashboardGoogle: () => {
    _sendEvent('send', 'event', 'gp_clipmx', 'click', 'dashboard');
  },

  downloadTransactionsReport: (fileType) => {
    _sendEvent('send', 'event', 'transactions_report', 'click', fileType);
  },

  // Language
  languageEspanol: () => {
    _sendEvent('send', 'event', 'espanol', 'click', 'web_footer');
  },

  languageEnglish: () => {
    _sendEvent('send', 'event', 'english', 'click', 'web_footer');
  },

  // Sign out
  // Sign out user menu
  signOut: () => {
    _sendEvent('send', 'event', 'signout', 'click', 'web_header_menu');
  },

  // Sign out onboarding
  signOutOnboarding: () => {
    // Not implemented.
    _sendEvent('send', 'event', 'sign_out', 'click', 'leave_pers_page');
  },

  //CSV Export Detail
  csvDownloader: () => {
    _pushDataLayer(
      Events.REPORT_DOWNLOAD,
      Categories.CSV_DETAIL_DOWNLOAD,
      Actions.DOWNLOAD_CSV,
      window.location.pathname,
    );
  },

  // Register
  register: () => {
    _pushDataLayer(
      null,
      Categories.FORM_SUBMISSION,
      Actions.ACCOUNT_SIGNUP,
      window.location.pathname,
    );
  },

  buyReaderLink: (section) => {
    _pushDataLayer(
      null,
      Categories.BUY_READER_LINK,
      capitalize(section),
      window.location.pathname,
    );
  },

  // Order Reader
  orderReader: (paymentMethod) => {
    _pushDataLayer(
      null,
      Categories.CHECKOUT,
      Actions.ORDER_CREATED,
      capitalize(paymentMethod),
    );
  },

  // Order Reader Confirm
  confirmOrderReader: (paymentMethod) => {
    _pushDataLayer(
      null,
      Categories.CHECKOUT,
      Actions.CHECKOUT_COMPLETED,
      capitalize(paymentMethod),
    );
  },

  // Business Info
  businessInfo: () => {
    _pushDataLayer(
      null,
      Categories.ONBOARDING,
      Actions.BUSINESS_INFORMATION,
      'Submit',
    );
  },

  // Bank Account Info
  bankInfo: () => {
    _pushDataLayer(
      null,
      Categories.ONBOARDING,
      Actions.BANK_INFORMATION,
      'Submit',
    );
  },

  // Bank Account Info Skip
  skipBank: () => {
    _pushDataLayer(
      null,
      Categories.ONBOARDING,
      Actions.BANK_INFORMATION,
      'Skip',
    );
  },

  // Installments Info
  installmentInfo: () => {
    _pushDataLayer(
      null,
      Categories.ONBOARDING,
      Actions.INSTALLMENTS_INFORMATION,
      'Submit',
    );
  },

  // Installments Info Skip
  installmentSkip: () => {
    _pushDataLayer(
      null,
      Categories.ONBOARDING,
      Actions.INSTALLMENTS_INFORMATION,
      'Skip',
    );
  },

  // Collaborators Info Submit
  collaboratorsInfo: () => {
    _pushDataLayer(
      null,
      Categories.ONBOARDING,
      Actions.COLLABORATORS_INFORMATION,
      'Submit',
    );
  },

  // Collaborators Info Skip
  collaboratorsSkip: () => {
    _pushDataLayer(
      null,
      Categories.ONBOARDING,
      Actions.COLLABORATORS_INFORMATION,
      'Skip',
    );
  },

  toggleInstallments: (enabled) => {
    const action = enabled ? Actions.ENABLE : Actions.DISABLE;
    _pushDataLayer(
      null,
      Categories.INSTALLMENTS,
      action,
      window.location.pathname,
    );
  },

  updateInstallments: (term, price) => {
    _pushDataLayer(null, Categories.INSTALLMENTS, Actions.UPDATE, term, price);
  },

  insertInstallments: (term, price) => {
    _pushDataLayer(null, Categories.INSTALLMENTS, Actions.INSERT, term, price);
  },

  togglePaymentNotifications: (enabled) => {
    const action = enabled ? Actions.ENABLE : Actions.DISABLE;
    _pushDataLayer(
      null,
      Categories.PAYMENT_NOTIFICATIONS,
      action,
      window.location.pathname,
    );
  },

  updatePaymentNotificationEmail: () => {
    _pushDataLayer(null, Categories.PAYMENT_NOTIFICATIONS, Actions.UPDATE);
  },

  deleteCollaborator: (role) => {
    _pushDataLayer(
      null,
      Categories.COLLABORATORS,
      Actions.DELETE,
      capitalize(role),
    );
  },

  insertCollaborator: (role) => {
    _pushDataLayer(
      null,
      Categories.COLLABORATORS,
      Actions.INSERT,
      capitalize(role),
    );
  },

  editCollaborator: (role) => {
    _pushDataLayer(
      null,
      Categories.COLLABORATORS,
      Actions.UPDATE,
      capitalize(role),
    );
  },

  updateBusinessInformation: (industry) => {
    _pushDataLayer(
      null,
      Categories.BUSINESS_INFORMATION,
      Actions.UPDATE,
      capitalize(industry),
    );
  },

  updateBankAccount: (bank) => {
    _pushDataLayer(
      null,
      Categories.BANK_ACCOUNTS,
      Actions.UPDATE,
      capitalize(bank),
    );
  },

  insertBankAccount: (bank) => {
    _pushDataLayer(
      null,
      Categories.BANK_ACCOUNTS,
      Actions.INSERT,
      capitalize(bank),
    );
  },

  cancelBankAccountChange: () => {
    _pushDataLayer(null, Categories.BANK_ACCOUNTS, Actions.CANCEL_CHANGE);
  },

  promoReferralLanding: () => {
    _pushDataLayer(
      null,
      Categories.PROMO_CODES,
      Actions.PROMO_REFERRAL_LANDING,
    );
  },

  referralCodeLanding: () => {
    _pushDataLayer(
      null,
      Categories.MERCHANT_REFERRALS,
      Actions.REFERRAL_CODE_LANDING,
    );
  },

  enterPromoCode: () => {
    _pushDataLayer(null, Categories.PROMO_CODES, Actions.ENTER_PROMO_CODE);
  },

  enterReferralCode: () => {
    _pushDataLayer(
      null,
      Categories.MERCHANT_REFERRALS,
      Actions.ENTER_REFERRAL_CODE,
    );
  },

  enterPromoAndReferralCode: () => {
    _pushDataLayer(
      null,
      Categories.MERCHANT_REFERRALS,
      Actions.ENTER_PROMO_AND_REFERRAL_CODE,
    );
  },

  correctPromoCode: () => {
    _pushDataLayer(null, Categories.PROMO_CODES, Actions.CORRECT_PROMO_CODE);
  },

  correctReferralCode: () => {
    _pushDataLayer(
      null,
      Categories.MERCHANT_REFERRALS,
      Actions.CORRECT_REFERRAL_CODE,
    );
  },

  incorrectPromoCode: () => {
    _pushDataLayer(null, Categories.PROMO_CODES, Actions.INCORRECT_PROMO_CODE);
  },

  incorrectReferralCode: () => {
    _pushDataLayer(
      null,
      Categories.MERCHANT_REFERRALS,
      Actions.INCORRECT_REFERRAL_CODE,
    );
  },

  incorrectReferralCodeReenter: () => {
    _pushDataLayer(
      null,
      Categories.MERCHANT_REFERRALS,
      Actions.INCORRECT_REFERRAL_CODE_REENTER,
    );
  },

  incorrectPromoCodeReenter: () => {
    _pushDataLayer(
      null,
      Categories.PROMO_CODES,
      Actions.INCORRECT_PROMO_CODE_REENTER,
    );
  },

  incorrectReferralCodeNext: () => {
    _pushDataLayer(
      null,
      Categories.MERCHANT_REFERRALS,
      Actions.INCORRECT_REFERRAL_CODE_NEXT,
    );
  },

  incorrectPromoCodeNext: () => {
    _pushDataLayer(
      null,
      Categories.PROMO_CODES,
      Actions.INCORRECT_PROMO_CODE_NEXT,
    );
  },
};
