export const LOGIN_START = 'auth/LOGIN_START';
export const LOGIN_FULFILLED = 'auth/LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'auth/LOGIN_REJECTED';
export const RESET_LOGIN = 'auth/RESET_LOGIN';

export const SET_PASSWORD_RESET_TOKEN = 'auth/SET_PASSWORD_RESET_TOKEN';
export const CLEAR_PASSWORD_RESET_TOKEN = 'auth/CLEAR_PASSWORD_RESET_TOKEN';
export const PASSWORD_RESET_TOKEN_ERROR = 'auth/PASSWORD_RESET_TOKEN_ERROR';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'auth/PASSWORD_RESET_EMAIL_SUCCESS';
export const PASSWORD_RESET_EMAIL_ERROR = 'auth/PASSWORD_RESET_EMAIL_ERROR';

export const SET_PASSWORD_RESET_EMAIL = 'auth/SET_PASSWORD_RESET_EMAIL';

export const PASSWORD_RESET = 'auth/PASSWORD_RESET';
export const PASSWORD_RESET_SUCCESS = 'auth/PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'auth/PASSWORD_ERROR';

export const LOGOUT_START = 'auth/LOGOUT_START';
export const LOGOUT_FULFILLED = 'auth/LOGOUT_FULFILLED';

export const REGISTER_START = 'auth/REGISTER_START';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_FAIL = 'auth/REGISTER_FAIL';
export const LOAD_REGISTRATION_META = 'auth/LOAD_REGISTRATION_META';
export const LOAD_REGISTRATION_META_FAIL = 'auth/LOAD_REGISTRATION_META_FAIL';
export const LOAD_CHECK_EMAIL = 'auth/LOAD_CHECK_EMAIL';
export const LOAD_CHECK_EMAIL_FAIL = 'auth/LOAD_CHECK_EMAIL_FAIL';
export const FORM_CHANGE = '@@redux-form/CHANGE';

export const SET_USER_NONCE = 'auth/SET_USER_NONCE';
export const USER_NONCE = 'auth/USER_NONCE';

export const VALIDATE_SIGNUP_EMAIL = 'auth/VALIDATE_SIGNUP_EMAIL';
