import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import ReviewImage from '../../../../assets/images/loans/find_in_page.svg';
import {
  LoansTabs,
  LoanTypes,
  PreapprovalStatus,
} from '../../../../constants/AppConstants';
import { getPreapprovals, isLoansFormSent } from '../../redux/selectors';
import GoogleAnalytics from '../../GoogleAnalytics';
import LoansRedirection from '../../Commons/Redirection';
import { ExternalLenderFooter } from '../../Commons/Footer';
import { styles } from '../styles';
import text from '../text';

export const InReview = (props) => {
  const { classes, isFormSent, preapprovals } = props;
  const typographyStyles = {
    body1: classes.typographyBody6,
    title: classes.typographyTitle3,
    subheading: classes.typographySubTitle,
    display1: classes.typographyBody5,
  };

  const accepted = preapprovals.filter(
    ({ loan_id, status, type }) =>
      [PreapprovalStatus.ACCEPTED].includes(status) &&
      !loan_id &&
      type === LoanTypes.OPEN_DATA,
  );

  return (
    <LoansRedirection tab={LoansTabs.NEW_OFFER}>
      {!isFormSent && accepted.length > 0 && (
        <GoogleAnalytics>
          <Grid item sm={12} xs={12} className={classes.gridCard}>
            <img
              className={classes.bannerInReview}
              alt="PrestaClip Review"
              src={ReviewImage}
            />
            <Typography variant={'title'} classes={typographyStyles}>
              {text.titleReview}
            </Typography>
            <Typography variant={'body1'} classes={typographyStyles}>
              {text.messageReview}
            </Typography>
            <Typography variant={'display1'} classes={typographyStyles}>
              {text.thanksReview}
            </Typography>
            <ExternalLenderFooter />
          </Grid>
        </GoogleAnalytics>
      )}
    </LoansRedirection>
  );
};

InReview.propTypes = {
  classes: PropTypes.object.isRequired,
  preapprovals: PropTypes.array.isRequired,
  isFormSent: PropTypes.bool.isRequired,
};
InReview.defaultProps = {
  classes: {},
  preapprovals: [],
  isFormSent: false,
};

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
    isFormSent: isLoansFormSent(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(InReview));
