import styled from '@emotion/styled';

export const TransactionsContainer = styled.div(({ theme }) => ({
  background: 'white',
  '&.mobile': {
    marginTop: `${theme.spacing.unit * 5}px`,
    marginBottom: `${theme.spacing.unit * 7}px`,
  },
  height: 'auto',
  width: '19.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  marginTop: '1.5rem',
}));

export const TransactionsImageContainer = styled.div(({ theme }) => ({
  background: 'white',
  '&.mobile': {
    marginTop: `${theme.spacing.unit * 5}px`,
    marginBottom: `${theme.spacing.unit * 7}px`,
  },
  height: '10.313rem',
  width: '11rem',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
}));

export const TransactionDate = styled.div(() => ({
  width: '19.5rem',
  height: '2rem',
  borderRadius: '8px',
  background: 'rgba(230 232 233, 0.35)',
  opacity: '35%',
}));

export const TransactionsVerTodos = styled.div(() => ({
  width: '100%',
  height: '2rem',
  borderRadius: '8px',
  background: 'white',
  lineHeight: '2rem',
  marginBottom: '1rem',
  textAlign: 'center',
}));
