import axios from 'axios';
import { app } from 'config/config';
import config from 'config';
import { getRequestOptions, getRequestOptionsV2 } from 'helpers/fetchUtil';
import apiPath from 'helpers/apiPaths';
import { HTTPStatusCodes } from 'constants/AppConstants';

class AccountService {
  static getBankName(code) {
    const options = getRequestOptions();
    const url = new URL(`${apiPath.bankJson}${code}`, config('apiUrl'));

    return axios.get(url, options);
  }

  static getBankAccountInformation() {
    const options = getRequestOptions();
    const url = new URL(apiPath.bankDetails, config('apiUrl'));

    return axios.get(url, options);
  }

  static deleteBankAccountChangeRequest() {
    const options = getRequestOptions();
    const url = new URL(apiPath.cancelBankInformation, config('apiUrl'));

    return axios.post(url, {}, options);
  }

  static addBankAccount(data) {
    const options = getRequestOptionsV2();
    const request = axios.create(options);
    const url = new URL(apiPath.bankInformationV2, config('apiUrl'));

    return request.post(url, data);
  }

  static updateBankAccount(data) {
    const options = getRequestOptionsV2();

    const url = new URL(apiPath.bankInformationIam, config('apiUrlv3'));
    return axios.put(url, data, options);
  }

  static stopBankAccountUpdate(data) {
    const options = getRequestOptionsV2();

    const url = new URL(apiPath.cancelBankInformationV2, config('apiUrl'));
    return axios.post(url, data, options);
  }

  static getBankAccountInformationV2() {
    const options = getRequestOptionsV2();
    const url = new URL(apiPath.bankInformationV2, config('apiUrl'));

    return axios.get(url, options);
  }

  static async getPublicProfileData(){
    try {
      const url = `${app.apiUrlv2}${apiPath.getPublicProfile}?public_profile_request_type=MERCHANT_PUBLIC_PROFILE_GET_BY_MERCHANT_ID`;
      const options = getRequestOptions();
      const response = await axios.get(url, options);

      return response.data;
    } catch (error) {
      return {
        items: error.response ? error.response.data.items : [],
        messages: {
          code:  error.response ? error.response.data.messages.code : HTTPStatusCodes.BAD_REQUEST,
          description: error.response ? error.response.data.messages.description : '',
        }
      }
    }
  };

  static async createPublicProfileData(formData, config){
    const url = `${app.apiUrlv2}${apiPath.createPublicProfile}`;
    const options = getRequestOptions();
    const {
      businessDescription,
      businessName,
      businessUrl,
      triggerOpengraph,
      image
    } = formData

    const params= {
        public_name: businessName,
        alias: businessUrl,
        public_description:businessDescription,
        public_profile_request_type: 'MERCHANT_PUBLIC_PROFILE_UPDATE',
        profile_config: config,
        trigger_opengraph: triggerOpengraph,
        image
    }
    try {
      const response = await axios.post(url, params, options);
      if (response.status === HTTPStatusCodes.OK) {
        return response.data;
      }
    } catch (error) {
      return {
        items: error.response ? error.response.data.items : [],
        messages: {
          code:  error.response ? error.response.data.messages.code : HTTPStatusCodes.BAD_REQUEST,
          description: error.response ? error.response.data.messages.description : '',
        }
      }
    }
  };

  static async uploadPublicProfileImage(proxy_merchant_id, image) {
    const config = getRequestOptions();
    let formData = new FormData();
    formData.append('file', image);
    return axios.post(
      `${app.apiUrlv2}/public-profile/upload/${proxy_merchant_id}`,
      formData,
      { headers: { ...config.headers, 'content-type': 'multipart/form-data' }}
    ).then( resp => {
      return resp.data;
    });
  };
  
}

export default AccountService;