export default {
  title: '¿Cómo pagaré mi Presta Clip?',
  firstParagraph:
    'Con un porcentaje de tus ventas diarias más un depósito quincenal, hasta cubrir tu préstamo:',
  secondParagraph:
    'El monto del depósito será calculado al final de cada quincena  y tendrás 7 días para pagarlo.',
  automatic: 'Automático',
  automaticText: 'Con un % de tus ventas con Clip',
  payment: 'Pago quincenal',
  paymentText: 'Depósito bancario',
};
