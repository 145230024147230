export const styles = (theme) => {
  const listStyles = {
    listRoot: {
      height: `${theme.spacing.unit * 6}px`,
      marginTop: `${theme.spacing.unit * 0.75}px`,
      padding: 0,
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      border: 'solid 1px #ccd0d3',
    },
    listItemRoot: {
      height: `${theme.spacing.unit * 6}px`,
      padding: `${theme.spacing.unit * 1.5}px`,
      paddingLeft: `${theme.spacing.unit}px`,
    },
    listItemRootWithoutIcon: {
      paddingLeft: `${theme.spacing.unit * 2}px`,
    },
    listItemIconRoot: {
      color: 'rgba(0, 23, 36, 0.8)',
      marginRight: `${theme.spacing.unit}px`,
    },
    listItemTextRoot: {
      paddingLeft: 0,
      paddingRight: `${theme.spacing.unit}px`,
      marginRight: `${theme.spacing.unit * 3}px`,
      height: `${theme.spacing.unit * 4.5}px`,
    },
    listItemTextPrimary: {
      fontSize: `${theme.spacing.unit * 1.5}px`,
      fontWeight: theme.typography.fontWeightMedium,
      color: '#66737b',
    },
    listItemTextPrimaryEmpty: {
      marginTop: `${theme.spacing.unit}px`,
      fontSize: `${theme.spacing.unit * 1.7}px`,
      color: '#66737b',
    },
    listItemTextSecondary: {
      marginTop: `-${theme.spacing.unit * 0.25}px`,
      fontSize: `${theme.spacing.unit * 1.75}px`,
      fontWeight: theme.typography.fontWeightMedium,
      color: '#001724',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  };

  const iconButtonStyles = {
    iconButtonRoot: {
      width: `${theme.spacing.unit * 3}px`,
      height: `${theme.spacing.unit * 3}px`,
      margin: `auto ${theme.spacing.unit * 1.5}px`,
      color: theme.palette.naranja.bloodOrange,
      position: 'relative',
    },
    iconButtonFilled: {
      width: `${theme.spacing.unit * 3}px`,
      height: `${theme.spacing.unit * 3}px`,
      margin: `auto ${theme.spacing.unit * 1.5}px`,
      color: '#001724',
    },
    iconButtonProgress: {
      color: theme.palette.naranja.bloodOrange,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  };

  const errorStyles = {
    errorRoot: {
      width: `calc(100% - ${theme.spacing.unit * 4}px)`,
      margin: '0 auto',
      padding: `${theme.spacing.unit * 0.75}px ${theme.spacing.unit * 1.5}px`,
      borderBottomLeftRadius: `${theme.spacing.unit * 0.5}px`,
      borderBottomRightRadius: `${theme.spacing.unit * 0.5}px`,
      backgroundColor: '#ffe7e7',
      fontSize: `${theme.spacing.unit * 1.5}px`,
      color: '#7c0b0b',
    },
  };

  const progressBarStyles = {
    progressBarHolder: {
      display: 'flex',
      width: `calc(100% - ${theme.spacing.unit * 2}px)`,
    },
    progressBarRoot: {
      width: '90%',
      margin: 'auto auto',
      flex: 1,
    },
    progressBarButton: {
      width: `${theme.spacing.unit * 1.5}px`,
      color: theme.palette.naranja.bloodOrange,
    },
  };

  const fieldStyles = {
    hidden: {
      display: 'none',
    },
  };

  return {
    ...listStyles,
    ...iconButtonStyles,
    ...errorStyles,
    ...fieldStyles,
    ...progressBarStyles,
  };
};
