import styled from '@emotion/styled';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import pink from '@material-ui/core/colors/pink';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiFormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { lightBlue } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LockIcon from '@material-ui/icons/Lock';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BottomNavigation, {
  BottomNavigationAction,
} from 'components/npm/clip-ui/BottomNavigation';
import { ProcessButton as CommonSubmitButton } from 'components/common/Buttons';
import { FormV3ProcessButton as ClipSubmitButton } from 'components/common/Buttons';
import ClipCard from 'components/common/Card';
import styledWithClasses from 'helpers/StyledWithClasses';

export const Container = styled.div(({ theme }) => ({
  padding: `0 ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
  width: `calc(100% - ${theme.spacing.unit * 4}px)`,
  float: 'left',
  '&.mobile': {
    marginTop: `${theme.spacing.unit * 5}px`,
    marginBottom: `${theme.spacing.unit * 7}px`,
  },
}));

export const Image = styled.img(({ theme }) => ({
  position: 'relative',
  display: 'block',
}));

export const Form = styled.form(({ theme }) => ({
  color: theme.palette.naranja.slate,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
}));

export const Title = styled.span(({ theme }) => ({
  color: theme.palette.naranja.slate,
  fontSize: `${theme.spacing.unit * 3}px`,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: 'normal',
  display: 'flex',
  marginBottom: `${theme.spacing.unit * 3}px`,
}));

export const Subtitle = styled.span(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  color: `${theme.palette.naranja.slate}`,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: `${theme.typography.fontWeightMedium}`,
  lineHeight: `${theme.spacing.unit * 3}px`,
  position: 'relative',
  display: 'block',
  float: 'left',
}));

export const Subtitlev2 = styled.span(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  color: `${theme.palette.naranja.slate}`,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: `${theme.typography.fontWeightMedium}`,
  fontColor: '#282321',
  position: 'relative',
  display: 'block',
  float: 'left',
  marginTop: `${theme.spacing.unit * 1}px`,
}));

export const AdviceLink = styled.a();
export const AdviceBR = styled.br();

export const SubtitleLabel = styled.span(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  color: `${theme.palette.naranja.slate}`,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: 300,
  lineHeight: `${theme.spacing.unit * 3}px`,
  position: 'relative',
  display: 'block',
  float: 'left',
  marginTop: `${theme.spacing.unit * 1}px`,
}));

export const Advice = styled.span(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  color: `${theme.palette.naranja.bloodOrange} !important`,
  fontSize: `${theme.spacing.unit * 1.75}px`,
  lineHeight: '1',
  letterSpacing: 'normal',
  fontWeight: theme.typography.fontWeightLight,
  position: 'relative',
  display: 'block',
  float: 'left',
  marginBottom: `${theme.spacing.unit * 3}px`,
  '& a': {
    color: `${theme.palette.naranja.bloodOrange} !important`,
  },
}));

export const Spacer = styled.div(({ theme }) => ({
  float: 'left',
  width: '60%',
  height: `${theme.spacing.unit * 4}px`,
  display: 'block',
}));
export const Spacer2 = styled.div(({ theme }) => ({
  float: 'left',
  width: '60%',
  height: `${theme.spacing.unit}px`,
  display: 'block',
}));
export const Spacer3 = styled.div(({ theme }) => ({
  float: 'left',
  width: '60%',
  height: `${theme.spacing.unit * 3}px`,
  display: 'block',
}));

export const Advicev2 = styled.span(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  color: '#282321',
  fontSize: `${theme.spacing.unit * 2}px`,
  lineHeight: 'normal',
  letterSpacing: 'normal',
  fontWeight: `${theme.typography.fontWeightMedium}`,
  position: 'relative',
  display: 'block',
  float: 'left',
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
}));

export const BR = styled.br(({ theme }) => ({
  lineHeight: `${theme.spacing.unit * 3}px`,
}));

export const FormAdvice = styled.span(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  color: `${theme.palette.naranja.grey}`,
  fontSize: `${theme.typography.fontSize}px`,
  lineHeight: '17px',
  position: 'relative',
  display: 'block',
  float: 'left',
  marginTop: '7px',
}));

export const FormControl = styled(MuiFormControl)(({ theme }) => ({
  width: '100%',
  display: 'block',
  float: 'left',
  marginBottom: `${theme.spacing.unit}px`,
  marginTop: `${theme.spacing.unit}px`,
  '& legend': {
    textAlign: 'left',
    marginTop: `${theme.spacing.unit}px`,
    color: `${theme.palette.naranja.slate}`,
  },
  '&:first-child': {
    marginTop: 0,
    marginBottom: `${theme.spacing.unit}px`,
  },
}));

export const FormControlNotMargin = styled(MuiFormControl)(({ theme }) => ({
  width: '100%',
  display: 'block',
  float: 'left',
  marginTop: 0,
  marginBottom: `${theme.spacing.unit * 1}px`,
  '& legend': {
    textAlign: 'left',
    color: `${theme.palette.naranja.slate}`,
  },
}));

export const DoubleInput = styled(MuiFormControl)(({ theme }) => ({
  width: '100%',
  float: 'left',
  display: 'block',
  flexDirection: 'unset',
  '& > div input': {
    paddingLeft: 0,
  },
  '& > div:nth-child(1)': {
    width: '48%',
    marginRight: '2%',
    float: 'left',
    marginBottom: `${theme.spacing.unit * 2}px`,
  },
  '& > div:nth-child(2)': {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      marginTop: `${theme.spacing.unit * 1}px`,
      marginBottom: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& > div:nth-child(1), & > div:nth-child(2)': {
      width: '100%',
      marginRight: 0,
    },
  },
}));

export const TrippleInput = styled(MuiFormControl)(({ theme }) => ({
  width: '100%',
  float: 'left',
  display: 'block',
  flexDirection: 'unset',
  '& > div:nth-child(1), & > div:nth-child(2)': {
    width: '31.3%',
    marginRight: '2%',
    float: 'left',
  },
  '& > div:nth-child(3)': {
    width: '33.4%',
    float: 'left',
    marginBottom: 0,
  },
  [theme.breakpoints.down('sm')]: {
    '& > div:nth-child(1), & > div:nth-child(2), & > div:nth-child(3)': {
      width: '100%',
      marginRight: 0,
    },
  },
  '& > div:nth-child(2), & > div:nth-child(3)': {
    [theme.breakpoints.down('sm')]: {
      marginTop: `${theme.spacing.unit * 2}px`,
    },
  },
}));

export const TextField = styled(Field)(({ theme }) => ({
  '& label': {
    paddingLeft: 0,
  },
  '& input': {
    paddingLeft: 0,
  },
  '&.preloaded > label': {
    color: '#007e8f',
    fontWeight: 500,
  },
  '&.preloaded > div': {
    background: '#f1f7f9',
  },
  '& input[name=rfc], & input[name=curp], & input[name=ine]': {
    textTransform: 'uppercase',
  },
}));

export const FormField = styled(Field)(({ theme }) => ({
  width: '100%',
  '&.preloaded > label': {
    color: '#007e8f',
    fontWeight: 500,
  },
  '&.preloaded > div': {
    background: '#f1f7f9',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: `${theme.spacing.unit}px`,
    '& label': {
      fontSize: `${theme.typography.fontSize}px`,
      paddingLeft: 0,
    },
  },
}));

export const BlueRadio = withStyles((theme) => ({
  root: {
    color: `${theme.palette.naranja.grey}`,
    '&$checked': {
      color: `${theme.palette.naranja.cerulean}`,
    },
    '&:hover': {
      color: `${theme.palette.naranja.cerulean}`,
      backgroundColor: `${theme.palette.naranja.cerulean}40`,
      transition: 'background-color 150ms linear',
    },
  },
  checked: {
    '& + span': {
      color: `${theme.palette.text.primary}`,
    },
  },
}))(Radio);

export const PinkRadio = withStyles((theme) => ({
  root: {
    color: `${theme.palette.naranja.grey}`,
    '&$checked': {
      color: pink[500],
    },
    '&:hover': {
      color: pink[500],
      backgroundColor: `${pink[500]}40`,
      transition: 'background-color 150ms linear',
    },
  },
  checked: {
    '& + span': {
      color: `${theme.palette.text.primary}`,
    },
  },
}))(Radio);

export const FormControlRadio = withStyles((theme) => ({
  label: {
    fontSize: '18px',
    lineHeight: `${theme.spacing.unit * 2.75}px`,
    color: theme.palette.grey[500],
  },
}))(FormControlLabel);

export const CompoundFormLabel = withStyles((theme) => ({
  focused: {
    color: `${theme.palette.primary.main}`,
    fontSize: `${theme.typography.fontSize}px`,
    transition: 'font-size 150ms linear',
  },
}))(FormLabel);

export const ButtonContainer = styled.div(({ theme }) => ({
  width: '600px',
  display: 'block',
  marginTop: `${theme.spacing.unit * 2}px`,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const CheckboxContainer = styled.div(({ theme }) => ({
  width: '100%',
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 0,
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const LastButtonContainer = styled.div(({ theme }) => ({
  width: '600px',
  display: 'block',
  marginTop: `${theme.spacing.unit * 5}px`,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const LoansFormV3ButtonContainer = styled.div(({ theme }) => ({
  width: '100%',
  margin: `${theme.spacing.unit * 3}px 0`,
}));

export const SubmitButton = styled(CommonSubmitButton)({
  width: '100%',
  flexWrap: 'wrap',
});

export const SubmitButtonV3 = styled(ClipSubmitButton)({
  width: '100%',
  flexWrap: 'wrap',
});

export const AlmostReady = styled.div(({ theme }) => ({
  width: '60%',
  marginTop: `${theme.spacing.unit * 3}px`,
  marginBottom: `${theme.spacing.unit * 3}px`,
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: `${theme.typography.fontWeightMedium}`,
  color: '#007d8e',
  '&:nth-child(2)': {
    marginTop: 0,
  },
  [theme.breakpoints.down('xs')]: {
    textAlign: 'left',
  },
}));

export const TermsAndConditions = styled.div(({ theme }) => ({
  maxWidth: '600px',
  textAlign: 'left',
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: `${theme.spacing.unit * 3}px`,
  color: theme.palette.naranja.slate,
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
  a: {
    color: `${theme.palette.naranja.bloodOrange} !important`,
  },
}));

export const FooterAttach = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  fontSize: `${theme.spacing.unit * 1.75}px`,
  color: '#86949b',
  marginBottom: `${theme.spacing.unit * 3}px`,
}));

export const INELink = styled.a(({ theme }) => ({
  marginTop: `${theme.spacing.unit * 0.75}px`,
  color: theme.palette.naranja.cerulean,
  lineHeight: '23px',
  float: 'left',
  cursor: 'pointer',
}));

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    display: 'inline-block',
    backgroundColor: theme.palette.naranja.iceBlue,
    maxWidth: '400px',
    border: '1px solid #dadde9',
    paddingBottom: `${theme.spacing.unit * 1.5}px`,
  },
}))(Tooltip);

export const TitleTooltip = styled.span(({ theme }) => ({
  width: '100%',
  float: 'left',
  fontSize: `${theme.spacing.unit * 2}px`,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: `${theme.spacing.unit * 3}px`,
  color: theme.palette.naranja.slate,
  marginTop: `${theme.spacing.unit * 1}px`,
  marginBottom: `${theme.spacing.unit * 3}px`,
  textAlign: 'center',
}));

export const Identification = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  float: 'left',
  paddingTop: `${theme.spacing.unit * 1.25}px`,
  paddingBottom: `${theme.spacing.unit * 1.25}px`,
}));

export const ImageIdentification = styled.img({
  width: '80%',
});

export const ProgressIcon = styled(CircularProgress)(({ theme }) => ({
  color: `${theme.palette.naranja.cerulean}`,
  animationDuration: '550ms',
}));

export const Warning = styled(WarningIcon)(({ theme }) => ({
  display: 'inline-block',
  marginRight: 10,
  verticalAlign: 'middle',
  width: 18,
  height: 18,
}));

export const Lock = styled(LockIcon)(({ theme }) => ({
  width: `${theme.spacing.unit * 3}px`,
  height: `${theme.spacing.unit * 3}px`,
  display: 'inline-block',
  position: 'absolute',
  right: 0,
  top: `${theme.spacing.unit * 2}px`,
  color: '#86949b',
}));

export const AssignmentTurnedIn = styled(AssignmentTurnedInIcon)(
  ({ theme }) => ({
    display: 'inline-block',
    marginRight: 10,
    verticalAlign: 'middle',
    width: 24,
  }),
);

export const ProofOfAddress = styled.img(({ theme }) => ({
  display: 'inline-block',
  marginRight: 10,
  verticalAlign: 'middle',
  width: 24,
}));

export const PrefilledProofAlert = styledWithClasses(
  styled(ClipCard)(({ theme }) => ({
    width: '100%',
    float: 'left',
    marginTop: `${theme.spacing.unit * 2}px`,
    borderRadius: `${theme.spacing.unit * 0.375}px`,
    borderColor: `${theme.palette.naranja.cerulean} !important`,
    border: '1px solid',
    boxShadow: '0px 0px',
    fontSize: `${theme.spacing.unit * 1.75}px`,
    '& .alertTitle': {
      marginTop: 0,
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: `${theme.typography.fontWeightMedium}`,
      display: 'inline',
    },
    '& .contentRoot': {
      textAlign: 'left',
      padding: 0,
      paddingLeft: `${theme.spacing.unit * 1.25}px`,
      color: `${theme.palette.naranja.cerulean} !important`,
      backgroundColor: lightBlue[50],
      paddingTop: `${theme.spacing.unit * 1.25}px`,
      paddingBottom: `${theme.spacing.unit * 1.25}px`,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  })),
  {
    contentRoot: 'contentRoot',
  },
);

export const PrefilledProofAlertContent = styled.div(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 1.75}px`,
  fontWeight: `${theme.typography.fontWeightLight}`,
  display: 'block',
  paddingLeft: `${theme.spacing.unit * 4}px`,
  paddingRight: `${theme.spacing.unit * 4}px`,
}));

export const PrefilledAlert = styledWithClasses(
  styled(ClipCard)(({ theme }) => ({
    width: '60%',
    float: 'left',
    marginTop: `${theme.spacing.unit * 2}px`,
    marginBottom: `${theme.spacing.unit * 2}px`,
    borderRadius: `${theme.spacing.unit * 0.25}px`,
    border: '1px solid #007d8e',
    boxShadow: '0px 0px',
    fontSize: `${theme.spacing.unit * 1.75}px`,
    '& .alertTitle': {
      marginTop: 0,
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: `${theme.typography.fontWeightMedium}`,
      display: 'inline',
    },
    '& .contentRoot': {
      textAlign: 'left',
      padding: 0,
      paddingLeft: `${theme.spacing.unit * 1.25}px`,
      color: '#007d8e',
      backgroundColor: 'rgba(2, 136, 209, 0.05)',
      paddingTop: `${theme.spacing.unit * 1.25}px`,
      paddingBottom: `${theme.spacing.unit * 1.25}px`,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  })),
  {
    contentRoot: 'contentRoot',
  },
);

export const PrefilledAlertContent = styled.div(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 1.75}px`,
  fontWeight: `${theme.typography.fontWeightLight}`,
  display: 'block',
  paddingLeft: `${theme.spacing.unit * 4}px`,
  paddingRight: `${theme.spacing.unit * 4}px`,
}));

export const InitialAlert = styledWithClasses(
  styled(ClipCard)(({ theme }) => ({
    width: '60%',
    float: 'left',
    marginTop: `${theme.spacing.unit * 2}px`,
    marginBottom: `${theme.spacing.unit * 2}px`,
    borderRadius: `${theme.spacing.unit * 0.375}px`,
    borderColor: `${theme.palette.naranja.cerulean} !important`,
    border: '1px solid',
    boxShadow: '0px 0px',
    fontSize: `${theme.spacing.unit * 1.75}px`,
    '& .alertTitle': {
      marginTop: 0,
      fontSize: `${theme.spacing.unit * 2}px`,
      fontWeight: `${theme.typography.fontWeightMedium}`,
      display: 'inline',
    },
    '& .contentRoot': {
      textAlign: 'left',
      padding: 0,
      paddingLeft: `${theme.spacing.unit * 1.25}px`,
      color: `${theme.palette.naranja.cerulean} !important`,
      backgroundColor: lightBlue[50],
      paddingTop: `${theme.spacing.unit * 1.25}px`,
      paddingBottom: `${theme.spacing.unit * 1.25}px`,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  })),
  {
    contentRoot: 'contentRoot',
  },
);

export const InitialAlertContent = styled.div(({ theme }) => ({
  fontSize: `${theme.spacing.unit * 1.75}px`,
  fontWeight: `${theme.typography.fontWeightLight}`,
  display: 'block',
  paddingLeft: `${theme.spacing.unit * 4}px`,
  paddingRight: `${theme.spacing.unit * 4}px`,
}));
export const AlertUL = styled.ul();
export const AlertLI = styled.li();

export const AlertAddressProofDocsExpired = styledWithClasses(
  styled(ClipCard)(({ theme }) => ({
    width: '100%',
    float: 'left',
    marginTop: `${theme.spacing.unit * 2}px`,
    borderRadius: `${theme.spacing.unit * 0.375}px`,
    borderColor: `${theme.palette.naranja.cerulean} !important`,
    border: '1px solid',
    boxShadow: '0px 0px',
    fontSize: `${theme.spacing.unit * 2}px`,
    '& .contentRoot': {
      textAlign: 'left',
      padding: 0,
      paddingLeft: `${theme.spacing.unit * 1.25}px`,
      color: `${theme.palette.naranja.cerulean} !important`,
      backgroundColor: lightBlue[50],
      display: 'flex',
      paddingTop: `${theme.spacing.unit * 1.25}px`,
      paddingBottom: `${theme.spacing.unit * 1.25}px`,
    },
  })),
  {
    contentRoot: 'contentRoot',
  },
);

export const AlertAddressProofDocs = styledWithClasses(
  styled(ClipCard)(({ theme }) => ({
    width: '100%',
    float: 'left',
    marginTop: `${theme.spacing.unit * 2}px`,
    borderRadius: `${theme.spacing.unit * 0.375}px`,
    borderColor: `${theme.palette.naranja.cerulean} !important`,
    border: '1px solid',
    boxShadow: '0px 0px',
    fontSize: `${theme.spacing.unit * 2}px`,
    '& .contentRoot': {
      textAlign: 'left',
      padding: 0,
      paddingLeft: `${theme.spacing.unit * 1.25}px`,
      color: `${theme.palette.naranja.cerulean} !important`,
      backgroundColor: lightBlue[50],
      display: 'flex',
      paddingTop: `${theme.spacing.unit * 1.25}px`,
      paddingBottom: `${theme.spacing.unit * 1.25}px`,
    },
  })),
  {
    contentRoot: 'contentRoot',
  },
);

export const BottomNavAction = styledWithClasses(
  styled(BottomNavigationAction)(() => ({
    paddingTop: '8px !important',
  })),
  { label: 'label-styling', selected: 'selected-styling' },
);

export const BottomNav = styled(BottomNavigation)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100vw',
  zIndex: 2,
  boxShadow: theme.shadows[2],

  '> button': {
    fontSize: 25,
  },
}));

export const BottomNavImage = styled.img(({ theme }) => ({
  position: 'relative',
  display: 'block',
  height: `${theme.spacing.unit * 4.5}px`,
  width: `${theme.spacing.unit * 8}px`,
}));

export const ErrorCheckboxIcon = styled(CheckBoxIcon)(({ theme }) => ({
  color: `${theme.palette.error.main}`,
}));

export const OrangeCheckbox = withStyles((theme) => ({
  root: {
    color: `${theme.palette.naranja.grey}`,
    '&$checked': {
      color: `${theme.palette.naranja.bloodOrange}`,
    },
  },
  checked: {},
}))(Checkbox);

export const ErrorIconContainer = styled.div(({ theme }) => ({
  order: 1,
  flex: '1 6%',
  [theme.breakpoints.down('xs')]: {
    flex: '1 10%',
  },
}));

export const ErrorSpace = styled.div(({ theme }) => ({
  order: 3,
  flex: '1 6%',
  [theme.breakpoints.down('xs')]: {
    flex: '1 10%',
  },
}));

export const ErrorWarning = styled.div(({ theme }) => ({
  maxWidth: `${theme.spacing.unit * 75}px`,
  marginTop: `${theme.spacing.unit * 2}px`,
  marginBottom: `${theme.spacing.unit * 2}px`,
  borderRadius: `${theme.spacing.unit * 0.5}px`,
  border: 'solid 1px #e3baba',
  borderLeft: `solid ${theme.spacing.unit * 0.5}px ${theme.palette.error.main}`,
  color: `${theme.palette.error.main}`,
  backgroundColor: '#fae5e5',
  padding: `${theme.spacing.unit}px ${theme.spacing.unit * 1.5}px`,
  display: 'flex',
  flexFlow: 'row wrap',
  '& > *': {
    flex: '1 100%',
  },
  '& > label': {
    order: 2,
    flex: '1 94%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '& > span': {
    order: 4,
    flex: '1 94%',
  },
  [theme.breakpoints.down('xs')]: {
    marginTop: 0,
    '& > label': {
      flex: '1 90%',
    },
    '& > span': {
      flex: '1 90%',
    },
  },
}));