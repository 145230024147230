import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import Dialog from '@material-ui/core/Dialog';
import { ROUTES2 } from '../../constants';

import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import text from './text';

import styles from './styles';
const useStyles = makeStyles(styles);

const CloseDialog = (props) => {
  let history = useHistory();
  const classes = useStyles(props);
  const { open, setOpen } = props;
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <ClipTypography fontWeight="bold">{text.title}</ClipTypography>
      <ClipTypography align="center" className={classes.desc}>
        {text.desc}
        </ClipTypography>
        <div className={classes.btnsContainer}>
            <ClipButton
                onClick={() => setOpen(false)}
                className={classes.btn1}
                variant="outlined"
                color="primary"
            >
                {text.btnCancel}
            </ClipButton>
            <ClipButton
                onClick={() => window.history.back()}
                className={classes.btn2}
                variant="contained"
                color="primary"
                onClick={() => history.push(ROUTES2.AUTH)}
            >
                {text.btnClose}
            </ClipButton>
        </div>
        
    </Dialog>
  );
};
CloseDialog.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
};
CloseDialog.defaultProps = {
  setOpen: () => {},
  open: false,
};
export default CloseDialog;
