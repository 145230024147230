import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ROUTES2 as ROUTES } from '../../constants';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ClipList from '@clipmx/clip-storybook/dist/components/List';
import ClipListItem from '@clipmx/clip-storybook/dist/components/List/ListItem';
import ClipListItemIcon from '@clipmx/clip-storybook/dist/components/List/ListItemIcon';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { EVENTS } from '../../../../helpers/Clevertap';
import ClipListItemText from '@clipmx/clip-storybook/dist/components/List/ListItemText';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';

import text from './text';
import ClipCardIcon from '../../../../assets/images/berlin/clipCard.svg';
import { Done as DoneIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import { styles } from './styles';

import CardRequestDisable from '../RequestCardDisable';

const useStyles = makeStyles(styles);
const RequestCardNavigation = (props) => {
  let history = useHistory();
  const {
    setFlow,
    requestedCard,
    financialShowRequestClipCard
  } = props;
  const classes = useStyles(props);

  const renderRequestButton = () =>
    requestedCard ? (
      <ClevertapComponent
        renderComponent={<ClipButton />}
        events={[
          {
            callbackName: 'onClick',
            event: EVENTS.BERLIN.CARD.EVENT,
            action: EVENTS.BERLIN.CARD.ACTIONS.DELIVERY_STATUS_CLICKED,
          },
        ]}
        onClick={() => {
          history.push(`${ROUTES.REQUESTCARD}`);
          setFlow('TRACK')
        }}
        variant={'contained'}
        color={'primary'}
      >
        {text.buttonTrack}
      </ClevertapComponent>
    ) : (
      <ClevertapComponent
        renderComponent={<ClipButton />}
        events={[
          {
            callbackName: 'onClick',
            event: EVENTS.BERLIN.CARD.EVENT,
            action: EVENTS.BERLIN.CARD.ACTIONS.REQUEST_CLICKED,
          },
        ]}
        onClick={() => {
          history.push(`${ROUTES.REQUESTCARD}`)
          setFlow('REQUEST')
        }}
        variant={'contained'}
        color={'primary'}
      >
        {text.buttonRequest}
      </ClevertapComponent>
    );

  if (!financialShowRequestClipCard)
    return <CardRequestDisable />

  return (
    <div className={classes.container}>
      <ClipTypography
        variant={'h2'}
        component={'h1'}
        fontWeight={'semiBold'}
        align={'center'}
        classes={{
          h2: classes.typographyTitle,
        }}
      >
        {text.requestTitle}
      </ClipTypography>
      <ClipTypography
        variant={'subtitle1'}
        component={'h2'}
        fontWeight={'regular'}
        align={'center'}
        classes={{
          subtitle1: classes.typographySubtitle,
        }}
      >
        {text.requestSubtitle}
      </ClipTypography>
      <div className={classes.requestImageContainer}>
        <img src={ClipCardIcon} alt={text.requestTitle} />
      </div>
      <ClipList component={'nav'}>
        {Array.from({ length: 3 }, (_, i) => i).map((i) => {
          return (
            <ClipListItem classes={{ root: classes.listItem }}>
              <ClipListItemIcon classes={{ root: classes.listIcon }}>
                <DoneIcon />
              </ClipListItemIcon>
              <ClipListItemText classes={{ root: classes.listItemText }}>
                <ClipTypography
                  variant={'body2'}
                  component={'p'}
                  fontWeight={'regular'}
                  classes={{
                    body2: classes.typographyBullet,
                  }}
                >
                  <b>{text.requestBullets[i]}</b>
                </ClipTypography>
              </ClipListItemText>
            </ClipListItem>
          );
        })}
      </ClipList>
      <div className={classes.buttonContainer}>
        {renderRequestButton()}
        <ClevertapComponent
          renderComponent={<ClipButton />}
          events={[
            {
              callbackName: 'onClick',
              event: EVENTS.BERLIN.CARD.EVENT,
              action: EVENTS.BERLIN.CARD.ACTIONS.ACTIVATE_CLICKED,
            },
          ]}
          onClick={() => {
            history.push(`${ROUTES.CARDACTIVATION}`)
            setFlow('ACTIVATE')
          }}
          variant={'outlined'}
          color={'primary'}
        >
          {text.buttonAlready}
        </ClevertapComponent>
        {/*<ClipButton
          onClick={() => {
            setCardActivateEvent();
            history.push(`${ROUTES.CARDACTIVATION}`)
            setFlow('ACTIVATE')
          }}
          variant={'outlined'}
          color={'primary'}
        >
          
        </ClipButton>*/}
      </div>
    </div>
  );
};

RequestCardNavigation.propTypes = {
  classes: PropTypes.object,
  setFlow: PropTypes.func,
  requestedCard: PropTypes.bool.isRequired,
  financialShowRequestClipCard: PropTypes.bool,
};

RequestCardNavigation.default = {
  classes: {},
  setFlow: () => { },
  requestedCard: false,
  financialShowRequestClipCard: false
};

export default RequestCardNavigation;
