import React from 'react';
import { styles } from './styles';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { toCurrency } from 'helpers/formatCurrency';
import { Forms } from '../../../../constants/AppConstants';
import PropTypes from 'prop-types';
import ClipDivider from '@clipmx/clip-storybook/dist/components/Divider';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import Commissions from '@clipmx/clip-storybook/dist/components/Icons/Commissions';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import {EVENTS} from '../../../../helpers/Clevertap';
import {
  ClipButton,
  ClipTypography,
  ClipIconButton,
} from '@clipmx/clip-storybook';
import {
  AccountCircleOutlined,
  Edit,
} from '@clipmx/clip-storybook/dist/components/Icons';
import text from './text';
const useStyles = makeStyles(styles);

const SPEIConfirmation = (props) => {
  const {
    openChange,
    prevStep,
    alias,
    clabe,
    bank,
    name,
    amount,
    concept,
    action,
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <div>
        <ClipTypography
          variant="h3"
          compoponent="h1"
          fontWeight="semiBold"
          className={classes.sectionTitle}
        >
          {text.title}
        </ClipTypography>

        <div>
          <div className={classes.cardTitle}>
            <div className={classes.iconTitle}>
              <AccountCircleOutlined className={classes.icon} />
              <ClipTypography
                variant="h6"
                compoponent="h1"
                fontWeight="semiBold"
              >
                {text.who}
              </ClipTypography>
            </div>
            <ClipIconButton color="primary" size="small" onClick={openChange}>
              <Edit />
            </ClipIconButton>
          </div>
          <div className={classes.cardInfo}>
            {alias && (
              <ClipTypography
                variant="body1"
                component="p"
                fontWeight="semiBold"
                className={classes.greyText}
              >
                {alias}
              </ClipTypography>
            )}
            <ClipTypography
              variant="body1"
              component="p"
              fontWeight="semiBold"
              className={classes.greyText}
            >
              {name}
            </ClipTypography>
            <ClipTypography variant="body1" component="p" fontWeight="semiBold">
              {`${bank} - ${clabe}`}
            </ClipTypography>
          </div>
        </div>

        <ClipDivider className={classes.divider} />

        <div>
          <div className={classes.cardTitle}>
            <div className={classes.iconTitle}>
              <Commissions className={classes.icon} />
              <ClipTypography
                variant="h6"
                compoponent="h1"
                fontWeight="semiBold"
              >
                {text.what}
              </ClipTypography>
            </div>
            <ClipIconButton color="primary" size="small" onClick={prevStep}>
              <Edit />
            </ClipIconButton>
          </div>
          <div className={classes.cardInfo}>
            <ClipTypography variant="h3" component="p" fontWeight="semiBold">
              {toCurrency(amount)}
            </ClipTypography>
            <ClipTypography variant="caption" component="p">
              {text.comision}
            </ClipTypography>
            <ClipTypography
              variant="body1"
              component="p"
              fontWeight="semiBold"
              className={classes.concept}
            >
              {text.concept} <span className={classes.conceptText}>{concept}</span>
            </ClipTypography>
          </div>
        </div>
      </div>
      <div>
        <ClevertapComponent
          renderComponent={<ClipButton />}
          events={[
            {
              callbackName: 'onClick',
              event: EVENTS.BERLIN.BALANCE.EVENT,
              action: EVENTS.BERLIN.BALANCE.ACTIONS.ENVIAR_DINERO_CONTINUED,
            },
          ]}
          variant="contained"
          color="primary"
          fullWidth
          onClick={action}
          >
            {text.accept}
        </ClevertapComponent>
      </div>
    </div>
  );
};

SPEIConfirmation.propTypes = {
  openChange: PropTypes.object.isRequired,
  prevStep: PropTypes.object.isRequired,
};

const selector = formValueSelector(Forms.BERLIN_SPEI_OUT);

const mapStateToProps = (state) => {
  return {
    alias: selector(state, 'alias'),
    clabe: selector(state, 'clabe'),
    bank: selector(state, 'bank'),
    name: selector(state, 'name'),
    amount: selector(state, 'amount'),
    concept: selector(state, 'concept'),
  };
};

export default connect(mapStateToProps, null)(SPEIConfirmation);
