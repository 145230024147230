export default {
  requestFlowTitle: 'Mejorar cuenta',
  step1:
    '¿Desempeñas o has desempeñado funciones públicas (ej. Jefe de Estado o Gobierno, político, militar, etc.) en México o el extranjero?',
  step2: 'Ingresa tu información',
  step3:
    '¿Tienes algún parentesco familiar o legal con una Persona Expuesta Políticamente (ej. Jefe de Estado o Gobierno, político, militar, etc.)?',
  step4: 'Ingresa la información de la Persona Expuesta Políticamente',
  step5: '¿Eres o fuiste socio(a) de una Persona Expuesta Políticamente?',
  step6: 'Ingresa la información de la Persona Expuesta Políticamente',
  step7: 'En promedio, ¿cuánto dinero ingresarás en tu cuenta al mes?',
  step8:
    'En promedio, ¿cuántas veces crees ingresar dinero en tu cuenta al mes?',
  subtitle: 'Completa tus ventas con Clip y transferencias SPEI.',
  step9:
    'En promedio, ¿con qué frecuencia crees ingresar dinero en tu cuenta al mes?',
};
