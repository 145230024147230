import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import moment from 'moment-timezone';
import clsx from 'clsx';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import HourglassTopIcon from '../../../../assets/images/icons/HourglassTop.svg';
import TopImage from '../../../../assets/images/topImageCrediClip.svg';
import EasyApplyIcon from '../../../../assets/images/easy-apply-icon-2.svg';
import QuickToGetIcon from '../../../../assets/images/fast-to-get-icon-2.svg';
import PayAsSaleIcon from '../../../../assets/images/pay-as-sale-icon-2.svg';
import LoansBanner from '../../../../assets/images/logo-loans.svg';
import urls from '../../../../helpers/urls';
import getUrl, { getProviderCashAdvanceURL } from '../../../../helpers/getUrl';
import { getProviderCodeForURL } from '../../../../helpers/getProviderUrl';
import { toCurrencyFormat } from '../../../../helpers/formatCurrency';
import GTMCrediClipAnalyticsEvents from '../../../../helpers/GTMCrediClipAnalyticsEvents';
import { PreapprovalStatus } from '../../../../constants/AppConstants';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { Clevertap, EVENTS } from '../../../../helpers/Clevertap';
import { savePreapprovalSelected } from '../../redux/actions';
import { getUserName } from '../../redux/selectors';
import { ExternalLenderFooter } from '../Footer';
import LoansLandingV4 from '../LoansLandingV4';
import CashAdvance from '../CashAdvance';
import { SamsClipIcon } from '../../Sams';
import SamsDialog from '../../Sams/Dialog';
import GoogleAnalytics from '../../GoogleAnalytics';
import Grid from '@material-ui/core/Grid';
import { commonPayload, amountPayload, lenderPayload } from '../Clevertap';
import {
  Container,
  Title,
  ImageContainer,
  ImageTitle,
  TextContainer,
  BannerTitle,
  ImageTitleMobile,
  TextTitle,
  TextSubtitle,
  Content,
  AmountsContainer,
  Amount,
  AmountContent,
  ExpireWarning,
  ExpireWarningMobile,
  HourglassTop,
  ExpireWarningContent,
  AmountTitle,
  AmountSubtitle,
  AmountHR,
  AmountDescription,
  DescriptionText,
  Description,
  ValuesText,
  Values,
  AmountFooter,
  AmountContainer,
  AmountReduced,
  AmountLoan,
  ButtonAmount,
  StepsTitle,
  Instructions,
  StepsContainer,
  Step,
  StepIconContainer,
  StepIcon,
  StepTitle,
  StepDescription,
  ImportantNote,
  ImportantNoteText,
  ImportantNoteContainer,
} from './styled';
import text from './text';

const decimalFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const decimalFormatWithDecimals = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const LoansLandingRegular = ({
  savePreapprovalSelected,
  merchantId,
  loansFormVersion,
  showCashAdvance,
  userName,
  preapprovals,
  classes,
  flags: { loansMerchantWithSamsclubBenefitsWeb: isElegibleForSams },
  user,
  merchant,
  merchantInfo,
}) => {
  const [showSamsDialog, setShowSamsDialog] = useState(false);
  const [itemAmount, setItemAmount] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (merchantInfo.proxy_id && user) {
      Clevertap.profile(user);
    }
  }, [user, merchant]);

  const selectPreapproval = (preapproval, isElegibleForSams) => () => {
    if (isElegibleForSams) {
      setShowSamsDialog(true);
      setItemAmount(preapproval);
      return;
    }
    const providerCode = preapproval.provider_code;
    const providerCodeForURL = getProviderCodeForURL(providerCode);

    let urlForm = getProviderCashAdvanceURL(
        showCashAdvance,
        providerCodeForURL,
        urls.loansFormIdentityV4CA,
        urls.loansFormIdentityV4,
      );

    savePreapprovalSelected(preapproval);
    GTMCrediClipAnalyticsEvents.pageView(
      preapproval.pre_approval_id,
      merchantId,
      urlForm,
    );
    history.replace(getUrl(`${getBaseLocation()}${urlForm}`));
  };

  const getBaseLocation = () =>
    location.pathname.startsWith(urls.mobile) ? urls.mobile : '';

  const expirationDate = moment(
    preapprovals[0].expiration_date_local,
    'YYYY-MM-DD',
  ).format('L');
  const preapproval_available = preapprovals.filter(
    ({ status }) => PreapprovalStatus.AVAILABLE === status,
  )[0];
  const {
    provider_code: providerCode,
    proxy_merchant_token: proxyMerchantToken,
    provider_code: lender,
  } = preapproval_available;

  useEffect(() => {
    Clevertap.event(
      EVENTS.LOANS.NEW_OFFERS_VIEW,
      null,
      lenderPayload(preapproval_available, {
        showCashAdvance,
      }, {
        type: 'regular',
        offer: 'available',
      }),
    )
  }, [])

  const cashAdvanceContent = () => {
    return (
      <>
        {showCashAdvance && <CashAdvance />}
        {!showCashAdvance && (
          <Fragment>
            <StepsTitle>{text.stepsTitle}</StepsTitle>
            <Instructions>{text.stepsDescription}</Instructions>
            <StepsContainer>
              <Step>
                <StepIconContainer>
                  <StepIcon src={EasyApplyIcon} />
                </StepIconContainer>
                <StepTitle>{text.stepOneTitle}</StepTitle>
                <StepDescription>{text.stepOneDescription}</StepDescription>
              </Step>
              <Step>
                <StepIconContainer>
                  <StepIcon src={QuickToGetIcon} />
                </StepIconContainer>
                <StepTitle>{text.stepTwoTitle}</StepTitle>
                <StepDescription>{text.stepTwoDescription}</StepDescription>
              </Step>
              <Step>
                <StepIconContainer>
                  <StepIcon src={PayAsSaleIcon} />
                </StepIconContainer>
                <StepTitle>{text.stepThreeTitle}</StepTitle>
                <StepDescription>
                  {text.stepThreeDescription}
                </StepDescription>
              </Step>
            </StepsContainer>
          </Fragment>
        )}

        <ExternalLenderFooter />
      </>
    )
  }

  return (
    <GoogleAnalytics>
      <Container>
        <Title>
          <ImageContainer>
            <ImageTitle src={TopImage} />
          </ImageContainer>
          <TextContainer>
            <BannerTitle src={LoansBanner} />
            <ImageTitleMobile src={TopImage} />
            <TextTitle>{`${text.titleP1}${userName}${text.titleP2}`}</TextTitle>
          </TextContainer>
        </Title>

        <ExpireWarning>
          <HourglassTop src={HourglassTopIcon} />
          <ExpireWarningContent>
            <ReactMarkdown
              source={`${text.expiration(expirationDate)} ${text.crediClip}`}
            />
          </ExpireWarningContent>
        </ExpireWarning>

        <Content>
          <ClevertapComponent
            renderComponent={<AmountsContainer />}
            events={[
              {
                callbackName: 'onScroll',
                event: EVENTS.LOANS.OFFERS_PAGE_SCROLLED_RIGHT,
                payload: commonPayload(
                  { proxy_merchant_token: proxyMerchantToken },
                  { showCashAdvance, loansFormVersion, providerCode },
                ),
              },
            ]}
          >
            {preapprovals
              .sort((a, b) => b.amount - a.amount)
              .map((itemAmount, index) => (
                <Amount
                  className={clsx({ recommended: index === 0 })}
                  key={`itemAmmount_${index}`}
                >
                  <AmountContent>
                    <AmountTitle>{text.amountTitles[index]}</AmountTitle>
                    <br />
                    <AmountSubtitle>
                      {text.amountSubtitles[index]}
                    </AmountSubtitle>
                    <AmountHR />
                    <AmountDescription>
                      <DescriptionText>
                        <Description>{text.maxLimit}</Description>
                        <Description>{text.total}</Description>
                        <Description>{text.refund}</Description>
                      </DescriptionText>
                      <ValuesText>
                        <Values>
                          {itemAmount.term_maximum} {text.months}
                        </Values>
                        <Values>
                          {toCurrencyFormat(itemAmount.payback)}
                        </Values>
                        <Values>
                          {decimalFormatWithDecimals.format(
                            itemAmount.retention_rate,
                          )}
                          % {text.dailySales}
                        </Values>
                      </ValuesText>
                      <ImportantNoteContainer>
                        <ImportantNote>
                          {text.importantNote}
                        </ImportantNote>
                        <ImportantNoteText>
                          {text.importantNoteText}
                        </ImportantNoteText>
                      </ImportantNoteContainer>
                    </AmountDescription>
                  </AmountContent>
                  <AmountFooter>
                    <AmountContainer>
                      <article>
                        <AmountReduced>$</AmountReduced>
                        <AmountLoan>
                          {`${decimalFormat.format(itemAmount.amount)}`}
                        </AmountLoan>
                      </article>
                      {isElegibleForSams && (
                        <article className={classes.samsContainer}>
                          <Typography className={classes.samsTitle}>
                            {text.preSamsGift}
                            <sup>1</sup> {text.postSamsGift}
                          </Typography>
                          <SamsClipIcon small={true} />
                        </article>
                      )}
                    </AmountContainer>
                    <ClevertapComponent
                      renderComponent={<ButtonAmount />}
                      events={[
                        {
                          callbackName: 'onClick',
                          event:
                            index === 0
                              ? EVENTS.LOANS.HIGHER_OFFER_SELECTED
                              : index === 1
                              ? EVENTS.LOANS.MEDIUM_OFFER_SELECTED
                              : EVENTS.LOANS.LOWER_OFFER_SELECTED,
                          payload: amountPayload(
                            itemAmount,
                            { showCashAdvance, loansFormVersion },
                            index,
                          ),
                        },
                      ]}
                      onClick={selectPreapproval(
                        itemAmount,
                        isElegibleForSams,
                      )}
                    >
                      <span>{text.buttonAmmount}</span>
                    </ClevertapComponent>
                  </AmountFooter>
                </Amount>
              ))}
          </ClevertapComponent>

          <ExpireWarningMobile>
            <HourglassTop src={HourglassTopIcon} />
            <ExpireWarningContent>
              <ReactMarkdown
                source={`${text.expiration(expirationDate)} ${
                  text.crediClip
                }`}
              />
            </ExpireWarningContent>
          </ExpireWarningMobile>

          {cashAdvanceContent()}
        </Content>
      </Container>
      {isElegibleForSams && (
        <SamsDialog
          open={showSamsDialog}
          onClose={() => setShowSamsDialog(false)}
          onSuccess={selectPreapproval(itemAmount, false)}
          lender={lender.toLowerCase()}
          proxyMerchantToken={proxyMerchantToken}
        />
      )}
    </GoogleAnalytics>
  );
}

const mapStateToProps = (state) => ({
  loansFormVersion: state.loans.loansFormVersion,
  userName: getUserName(state),
  showCashAdvance: state.loans.showCashAdvance,
  user: state.user,
  merchant: state.merchant,
  merchantInfo: state.merchant.info,
});

const mapDispatchToProps = {
  savePreapprovalSelected,
};

const styles = (theme) => ({
  samsContainer: {
    width: '100%',
    marginTop: `${theme.spacing.unit}px`,
    marginBottom: `${theme.spacing.unit * 1}px`,
  },
  samsTitle: {
    fontSize: `${theme.spacing.unit * 1.375}px`,
    lineHeight: `${theme.spacing.unit * 1.65}px`,
    fontWeight: 400,
    textAlign: 'center',
    color: '#001724',
    marginBottom: `${theme.spacing.unit * 0.5}px`,
    width: '100%',
    margin: 'auto',
    '& sup': {
      fontSize: `${theme.spacing.unit * 0.75}px`,
    },
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(withLDConsumer()(withStyles(styles)(LoansLandingRegular)))),
);