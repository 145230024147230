import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';

import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StoreIcon from '@material-ui/icons/Store';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

import { styles } from './styles';
import text from './text';

class LoansStepper extends Component {
  state = {
    activeStep: 0,
    completed: {},
  };

  handleStep = (step) => () => {
    this.setState({
      activeStep: step,
    });
  };

  completedSteps() {
    return Object.keys(this.state.completed).length;
  }

  render() {
    const { classes, step, showCreditHistory } = this.props;
    const steps = showCreditHistory ? text.creditHistoryStepper : text.stepper;

    const stepperStyles = {
      root: classes.stepperRoot,
    };
    const stepLabelStyles = {
      label: classes.stepLabel,
      active: classes.stepActive,
      iconContainer: classes.stepIconContainer,
    };
    const stepConnectorStyles = {
      root: classes.stepConnector,
    };

    return (
      <Stepper
        classes={stepperStyles}
        alternativeLabel
        connector={<StepConnector classes={stepConnectorStyles} />}
        activeStep={step}
      >
        {steps.map((label, index) => {
          let icon = <PersonIcon />;
          switch (index) {
            case 1:
              icon = steps.length === 4 ? <AssignmentIcon /> : <StoreIcon />;
              break;
            case 2:
              icon = steps.length === 4 ? <StoreIcon /> : <LocalAtmIcon />;
              break;
            case 3:
              icon = <LocalAtmIcon />;
              break;
            default:
              break;
          }

          return (
            <Step key={label}>
              <StepLabel classes={stepLabelStyles} icon={icon}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  }
}

LoansStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  showCreditHistory: PropTypes.bool.isRequired,
};

export default withStyles(styles)(LoansStepper);
