export const GET_LASTONE = 'GET_LASTONE';
export const SET_LASTONE_DOCUMENTATION = 'SET_LASTONE_DOCUMENTATION';
export const SET_PREFILLED_FORM = 'SET_PREFILLED_FORM';
export const SET_PREFILLED_COLONIES = 'SET_PREFILLED_COLONIES';
export const SET_PREFILLED_DAYS = 'SET_PREFILLED_DAYS';
export const GET_PREAPPROVALS = 'GET_PREAPPROVALS';
export const GET_LOANS = 'GET_LOANS';
export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const SEND_REQUEST_LOAN = 'SEND_REQUEST_LOAN';
export const LOAD_REQUEST_FORM = 'LOAD_REQUEST_FORM';
export const DELETE_DOCUMENTS_NOT_SAVED = 'DELETE_DOCUMENTS_NOT_SAVED';
export const SET_PREAPPROVAL_SELECTED = 'SET_PREAPPROVAL_SELECTED';
export const CLEAN_PREAPPROVAL = 'CLEAN_PREAPPROVAL';
export const CLEAN_LOANS = 'CLEAN_LOANS';
export const DRAG_FILE_TURNON = 'DRAG_FILE_TURNON';
export const DRAG_FILE_TURNOFF = 'DRAG_FILE_TURNOFF';
export const SET_MERCHANT_LOANS_COLONIES = 'SET_MERCHANT_LOANS_COLONIES';
export const SET_MERCHANT_LOANS_DAYS = 'SET_MERCHANT_LOANS_DAYS';
export const SENDING_FORM = 'SENDING_FORM';
export const RESET_DOCUMENTS = 'RESET_DOCUMENTS';
export const CLEAN_IDENTITY = 'CLEAN_IDENTITY';
export const CLEAN_IDENTITY_BACK = 'CLEAN_IDENTITY_BACK';
export const CLEAN_PASSPORT = 'CLEAN_PASSPORT';
export const CLEAN_PROOF_OF_ADDRESS = 'CLEAN_PROOF_OF_ADDRESS';
export const SET_CONFIRM_ADDRESS = 'SET_CONFIRM_ADDRESS';
export const SET_LOANS_LOADED = 'SET_LOANS_LOADED';
export const SET_PRESTA_CLIP_NAME_CHANGE = 'SET_PRESTA_CLIP_NAME_CHANGE';
export const SET_SHOW_CASH_ADVANCE = 'SET_SHOW_CASH_ADVANCE';
export const REFILL_REQUESTED = 'REFILL_REQUESTED';
export const SET_LOANS_FORM_VERSION = 'SET_LOANS_FORM_VERSION';
export const SET_LOANS_REGULAR_OFFERS_VERSION = 'SET_LOANS_REGULAR_OFFERS_VERSION';
export const SET_LOANS_FORM_URL = 'SET_LOANS_FORM_URL';
export const SET_SHOW_LOANS_TESTING_FORM = 'SET_SHOW_LOANS_TESTING_FORM';
export const SET_LD_FLAGS_READY = 'SET_LD_FLAGS_READY';
export const SET_AMOUNT_SENT_ELEGIBLE = 'SET_AMOUNT_SENT_ELEGIBLE';
export const SET_DISMISS_OFFER_ALERT = 'SET_DISMISS_OFFER_ALERT';
export const SET_SHOW_OFFER_ALERT = 'SET_SHOW_OFFER_ALERT';
