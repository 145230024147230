import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Card from '../../../../../components/common/Card';
import HourglassTopIcon from '../../../../../assets/images/icons/HourglassTop.svg';
import PrestaClipBanner from '../../../../../assets/images/prestaclip.svg';
import TopImage from '../../../../../assets/images/topImageCrediClip.svg';
import { PreapprovalStatus } from '../../../../../constants/AppConstants';
import { getPreapprovals, getUserName } from '../../../redux/selectors';
import GoogleAnalytics from '../../../GoogleAnalytics';
import { ExternalLenderFooter } from '../../../Commons/Footer';
import OpenDataPayment from '../../Payment';
import OfferItem from '../OfferItem';
import { ExpireWarningContent } from '../../../MrPresta/styled';
import { styles } from '../../styles';
import text from '../../text';

export const Offers = (props) => {
  const { classes, preapprovals, username } = props;

  const typographyStyles = {
    body1: classes.typographyBodyDesktop,
    body2: classes.typographyBody2Desktop,
    title: classes.typographyTitleDesktop,
    subheading: classes.typographySubTitleDesktop,
  };
  const expirationDate = preapprovals
    .filter(({ status }) => PreapprovalStatus.AVAILABLE === status)
    .map(({ expiration_date_local }) => expiration_date_local)
    .shift();

  return (
    <GoogleAnalytics>
      <Grid item sm={12} xs={12} className={clsx(classes.gridCard, 'offers')}>
        <div className={classes.bannerContainer}>
          <img
            className={classes.bannerTop}
            alt="PrestaClip Banner"
            src={PrestaClipBanner}
          />
        </div>
        <Card className={clsx('margin24', classes.crediCard)}>
          <div className={classes.headerDesktopContainer}>
            <img
              className={clsx(classes.image, 'desktop')}
              alt="PrestaClip top"
              src={TopImage}
            />
            <div className={clsx(classes.textContainer, 'desktop')}>
              <Typography variant={'subheading'} classes={typographyStyles}>
                {text.subtitle}
              </Typography>
              <Typography variant={'title'} classes={typographyStyles}>
                {text.titleElegible}
              </Typography>
              <Typography variant={'body2'} classes={typographyStyles}>
                !{text.congrats(username)} {text.congratsMessage}
              </Typography>
              <Typography variant={'body1'} classes={typographyStyles}>
                {text.adviseOffer}
              </Typography>
            </div>
          </div>
          <div className={clsx(classes.alertContainer, 'notTopMargin')}>
            <div className={clsx(classes.expireWarning, 'desktop')}>
              <img
                className={classes.calcLeftIcon}
                alt="PrestaClip Calc"
                src={HourglassTopIcon}
              />
              <ExpireWarningContent>
                <ReactMarkdown
                  source={text.adviceTermEnd(
                    moment(expirationDate, 'YYYY-MM-DD').format('L'),
                  )}
                />
              </ExpireWarningContent>
            </div>
          </div>
        </Card>
        <div className={clsx(classes.offersContainer, 'desktop')}>
          {preapprovals
            .filter(
              ({ status, loan_id }) =>
                PreapprovalStatus.AVAILABLE === status && !loan_id,
            )
            .sort((a, b) => b.amount - a.amount)
            .map((preapproval, index) => (
              <OfferItem
                preapproval={preapproval}
                index={index}
                className="desktop"
                key={`OD_offer_${index}`}
              />
            ))}
        </div>
        <div className={classes.paymentContainer}>
          <OpenDataPayment className="desktop" collapsable={false} />
        </div>
        <ExternalLenderFooter />
      </Grid>
    </GoogleAnalytics>
  );
};
Offers.propTypes = {
  classes: PropTypes.object.isRequired,
  preapprovals: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
    username: getUserName(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Offers));
