import {
  FETCH_CODI,
  FETCH_CODI_ERROR,
  SET_CODI,
  SET_CODI_DETAIL,
  FETCH_CODI_DETAIL_ERROR,
  DOWNLOAD_CODI_REPORT,
  DOWNLOAD_CODI_REPORT_ERROR,
  GET_CODI_SETTINGS,
} from './types';

import { formatTimestamp } from '../../../helpers/dateHelpers';

const initialState = {
  transactions: [],
  pagination: {
    total: 0,
    current_page: 1,
    total_pages: 0,
  },
  loading: false,
  error: null,
  detail: {
    customer: {},
    merchant: {},
  },
  report: null,
  settings: {
    activated: false,
    registered: false,
  },
};

const actionHandlers = new Map([
  [FETCH_CODI, handleFetchCodi],
  [SET_CODI, handleSetCodi],
  [FETCH_CODI_ERROR, handleCodiError],
  [SET_CODI_DETAIL, handleFetchCodiDetail],
  [FETCH_CODI_DETAIL_ERROR, handleCodiError],
  [DOWNLOAD_CODI_REPORT, handleCodiReportDownload],
  [DOWNLOAD_CODI_REPORT_ERROR, handleCodiError],
  [GET_CODI_SETTINGS, handleGetCodiSettings],
]);

export default function (state = initialState, action = { type: null }) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleSetCodi(state, action) {
  const { transactions, pagination } = action.payload;

  return {
    ...state,
    transactions: transactions.map((txn) => ({
      ...txn,
      ...{ requested_at: formatTimestamp(txn.requested_at) },
      ...{ completed_at: formatTimestamp(txn.completed_at) },
    })),
    pagination,
  };
}

function handleFetchCodi(state, action) {
  return { ...state, loading: action.payload.loading };
}

function handleCodiError(state, action) {
  const message = action.payload;
  return message ? { ...state, error: message } : state;
}

function handleFetchCodiDetail(state, action) {
  return { ...state, detail: { ...action.payload } };
}

function handleCodiReportDownload(state, action) {
  const { report, loading } = action.payload;
  return { ...state, report: report ? report : null, loading };
}

function handleGetCodiSettings(state, action) {
  return { ...state, settings: action.payload };
}
