import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import ExpandMore from '@material-ui/icons/ExpandMore';

import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { styles } from './styles';
import clsx from 'clsx';

const useStyles = makeStyles(styles);
const SelectFieldV2 = (props) => {
  const {
    input,
    label,
    children = {},
    helperText,
    options,
    disabled,
    meta: { touched, error },
    ...rest
  } = props;
  const classes = useStyles(props);

  const hasError = !!((touched && error) || helperText);
  const errorText = (touched && error) || helperText;

  const selectStyles = {
    root: clsx(
      classes.selectRoot,
      hasError && classes.selectError,
      disabled && classes.selectDisabled,
    ),
    select: classes.selectSelect,
    selectMenu: classes.selectMenu,
    icon: classes.selectIcon,
  };
  const menuItemStyles = {
    root: classes.menuItemRoot,
    selected: classes.menuItemSelected,
  };
  const labelStyles = {
    root: classes.labelRoot,
    shrink: classes.labelShrink,
  };
  const errorStyles = { root: classes.errorRoot };

  return (
    <FormControl className={classes.formControlRoot} fullWidth {...rest}>
      <InputLabel error={hasError} classes={labelStyles}>
        {label}
      </InputLabel>
      <Select
        classes={selectStyles}
        onChange={(event) => input.onChange(event.target.value)}
        disableUnderline={true}
        IconComponent={ExpandMore}
        error={hasError}
        disabled={disabled}
        {...input}
      >
        {options
          ? options.map((option, key) => (
              <MenuItem
                classes={menuItemStyles}
                key={key}
                value={option.id}
                disabled={option.disabled}
              >
                {option.name}
              </MenuItem>
            ))
          : children}
      </Select>
      {hasError && (
        <FormHelperText classes={errorStyles}>{errorText}</FormHelperText>
      )}
    </FormControl>
  );
};

SelectFieldV2.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.object,
};

export default SelectFieldV2;
