import React, { useState } from 'react';
import { ClipButton, ClipTypography, ClipInput } from '@clipmx/clip-storybook';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import HelpIcon from '../../../../assets/images/berlin/helpIcon.svg';
import styles from './styles';

const useStyles = makeStyles(styles);

const Form = ({ title, subtitle, inputs, btn }) => {
  const { disabled, text, action, event, payload, clevertapAction } = btn;
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className={classes.container}>
      <ClipTypography className={subtitle === '' && classes.title} variant="h3">
        {title}
      </ClipTypography>
      <div className={classes.info}>
        <ClipTypography>{subtitle}</ClipTypography>
      </div>

      {inputs.map(({ label, value, setValue, error, help }, i) => {
        let infoArray = [
          <ClipInput
            key={i}
            className={classes.input}
            type="text"
            label={label}
            adornmentIcon={
              help && (
                <ClevertapComponent
                  renderComponent={<div />}
                  events={[
                    {
                      callbackName: 'onClick',
                      event: help.helpLog,
                      payload: help.payload,
                    },
                  ]}
                  onClick={() => {
                    setShowTooltip(true);
                  }}
                  >
                    {help.helpText}
                    <img src={HelpIcon} className={classes.helpIcon} alt="" />
                  </ClevertapComponent>
              )
            }
            color="primary"
            fullWidth={true}
            error={error !== ''}
            errormessage={error}
            value={value}
            onChange={setValue}
            clickable
          />,
        ];
        if (help) {
          infoArray.push(
            <Drawer
              setShowTooltip={setShowTooltip}
              onClose={() => setShowTooltip(false)}
              className={classes.drawer}
              classes={{ paper: classes.paper }}
              anchor="bottom"
              open={showTooltip}
            >
              {React.cloneElement(help.tooltip, { setShowTooltip })}
            </Drawer>,
          );
        }
        return infoArray;
      })}
      <div className={classes.btnContainer}>
        <ClevertapComponent
          renderComponent={<ClipButton />}
          events={[
            {
              callbackName: 'onClick',
              event: event,
              payload,
              action: clevertapAction,
            },
          ]}
          className={classes.btn}
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={() => action()}
          >
          {text}
        </ClevertapComponent>
      </div>
    </div>
  );
};

Form.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      setValue: PropTypes.func,
      error: PropTypes.string,
    }),
  ),
  btn: PropTypes.shape({
    disabled: PropTypes.bool,
    text: PropTypes.string,
    action: PropTypes.func,
    event: PropTypes.string,
    clevertapAction: PropTypes.string,
  }),
  setOpen: PropTypes.func,
  step: PropTypes.number,
};

Form.defaultProps = {
  title: '',
  subtitle: '',
  inputs: [],
  btn: {},
  setOpen: () => {},
  step: 0,
};

export default Form;
