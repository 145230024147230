import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipButton, ClipTypography } from '@clipmx/clip-storybook';
import { NotificationsActiveOutlined } from '@clipmx/clip-storybook/dist/components/Icons';
import ClipAlert from '@clipmx/clip-storybook/dist/components/Alert';

import text from './text';
import { styles } from './styles';
import ClipCardIcon from '../../../../assets/images/berlin/clipCard.svg';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';

import BerlinSpinner from '../../BerlinSpinner';

import SuccessIcon from '../../../../assets/images/berlin/checkMark.svg';
import thumbsUpGreenIcon from '../../../../assets/images/berlin/thumbsUpGreen.svg';

import BerlinService from '../../service';
import logger from '../../../../helpers/logger';
import { EVENTS } from '../../../../helpers/Clevertap';

const useStyles = makeStyles(styles);

const CardRequestDisable = (props) => {

    const classes = useStyles(props);
    const { merchant, user } = props;
    const [enrollmentInfo, setEnrollmentInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (enrollmentInfo === null) getEnrollmentInfo();
        postSwapEvent(EVENTS.BERLIN.SWAP_MIGRATION.ACTIONS.CLIP_CLIP_CARD_WAITING_LIST);
    }, []);


    const getEnrollmentInfo = async () => {
        setLoading(true);
        try {
            const enrollmentInfo = await BerlinService.getMerchantEnrollmentInfo(merchant.info.id);
            setEnrollmentInfo(enrollmentInfo.data.message);
        } catch (error) {
            logger.info(error);
        }
        setLoading(false);
    };

    const postSwapEvent = async(event) => {
        try {
            const accountStatus = await BerlinService.accountInformationV2(merchant.info.id)    
            const EVENT_PROPERTIES = {
                account_level: accountStatus.data.message.account_level,
                version: localStorage.getItem('appUpToDate') ? 'CON_MIGRACION' : 'SIN_MIGRACION',
                proxy_account_id: accountStatus.data.message.proxy_account_id,
            }
            BerlinService.postSwapEvent(accountStatus.data.message.proxy_account_id, event, EVENT_PROPERTIES);
        } catch (error) {
            logger.info(error);
        }
    }

    const postWaitingList = async () => {
        setLoading(true);
        try {
            const body = {
                'email': user.email,
                'origin_type': 'CARD_WAITING_LIST'
            };
            const waitingList = await BerlinService.postWaitingList(merchant.info.id, body);
            setEnrollmentInfo(waitingList.data.message);
        } catch (error) {
            setError(text.error);
            logger.info(error);
        }
        setLoading(false);
    }

    if (loading)
        return <BerlinSpinner />

    return (
        <div className={classes.container}>
            {error && (
                <ClipAlert
                    severity="warning"
                    onClose={() => setError(null)}
                    className={classes.toastAlert}
                >
                    <ClipTypography variant={'caption'}>
                        {error}
                    </ClipTypography>
                </ClipAlert>
            )}
            <div className={classes.requestImageContainer}>
                <img src={ClipCardIcon} alt={''} />
            </div>
            <div className={classes.titleContainer}>
                <ClipTypography
                    variant={'h2'}
                    component={'h1'}
                    fontWeight={'semiBold'}
                    align={'center'}
                    classes={{ h2: classes.typographyTitle }}
                >
                    {text.title1}
                </ClipTypography>
                <ClipTypography
                    variant={'h2'}
                    component={'h1'}
                    fontWeight={'semiBold'}
                    align={'center'}
                    classes={{ h2: classes.typographyTitle }}
                >
                    {text.title2}
                </ClipTypography>
            </div>
            <div className={classes.subtitleContainer}>
                <ClipTypography
                    variant={'subtitle1'}
                    component={'h2'}
                    fontWeight={'regular'}
                    align={'center'}
                    classes={{ subtitle1: classes.typographySubtitle }}
                >
                    {text.subTitle1}
                    <span className={classes.bold}>{text.subTitle2}</span>
                    {text.subTitle3}
                </ClipTypography>
            </div>
            <div className={classes.detailsContainer}>
                <div>
                    <img src={SuccessIcon} alt={''} />
                    <ClipTypography>
                        <span className={classes.bold}>{text.details1}</span>
                        {text.details12}
                    </ClipTypography>
                </div>
                <div className={classes.middleDetail}>
                    <img src={SuccessIcon} alt={''} />
                    <ClipTypography>
                        <span className={classes.bold}>{text.zero}</span>
                        {text.details2}
                        <span className={classes.bold}>{text.details22}</span>
                        {text.details23}
                    </ClipTypography>
                </div>
                <div>
                    <img src={SuccessIcon} alt={''} />
                    <ClipTypography>{text.details3}</ClipTypography>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                {
                    enrollmentInfo ?
                        (
                            <div className={classes.enrollmentMessage}>
                                <img src={thumbsUpGreenIcon} alt={''} />
                                <ClipTypography className={classes.typographyBody3}>{text.enrolmmentMessage}</ClipTypography>
                            </div>
                        ) :
                        (
                            <ClevertapComponent
                                renderComponent={<ClipButton />}
                                events={[]}
                                onClick={postWaitingList}
                                variant={'outlined'}
                                color={'secondary'}
                                startIcon={<NotificationsActiveOutlined />}
                            >
                                {text.enrollmentButton}
                            </ClevertapComponent>
                        )
                }
            </div>
        </div >
    );
}

const mapStateToProps = ({ merchant, user }) => {
    return {
        user,
        merchant
    };
};

export default connect(mapStateToProps, null)(CardRequestDisable);