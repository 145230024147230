import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipTypography from '@clipmx/clip-storybook/dist/components/Typography';
import getUrl from '../../../../helpers/getUrl';
import urls from '../../../../helpers/urls';
import FormButton from '../../../../helpers/FormUtils/FormButton';
import SamsSuccess from '../../../../assets/images/loans/trophy-congrats.svg';
import { saveSamsclubMembership } from '../../redux/actions';
import GoogleAnalytics from '../../GoogleAnalytics';
import { SamsClipIcon } from '../index';
import { styles } from '../styles';
import text from '../text';

export const Confirmed = ({
  classes,
  lender,
  membership,
  onClose,
  onSuccess,
  proxyMerchantToken,
  saveSamsclubMembership,
}) => {
  let location = useLocation();

  return (
    <GoogleAnalytics
      defaultUrl={getUrl(
        `${
          location.pathname.startsWith(urls.mobile)
            ? urls.mobile
            : ''
        }${urls.loansSamsConfirmed.replace(':lender', lender)}`
      )}
    >
      <Grid item sm={12} xs={12} className={classes.gridCard}>
        <article className={classes.header}>
          <SamsClipIcon />
          <section className={classes.closeContainer}>
            <button
              type="button"
              data-testid="onCloseBtn"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseRoundedIcon />
            </button>
          </section>
        </article>
        <article className={classes.bannerContainer}>
          <img alt="gift" src={SamsSuccess} />
        </article>
        <ClipTypography className={clsx(classes.title, 'confirm')}>
          {text.titleCongrats}
        </ClipTypography>
        <ClipTypography className={clsx(classes.body1, 'confirm')}>
          {text.descriptionCongrats}
        </ClipTypography>
        <section className={classes.buttons}>
          <article className={classes.continue}>
            <FormButton
              type="button"
              onClick={() => {
                const membershipNumber =
                  typeof membership === 'object' &&
                  membership.hasOwnProperty('samsMembership')
                    ? membership.samsMembership
                    : membership;
                saveSamsclubMembership(
                  proxyMerchantToken,
                  membershipNumber,
                  onSuccess
                );
              }}
              variant="contained"
              text={text.buttonCongrats}
            />
          </article>
        </section>
      </Grid>
    </GoogleAnalytics>
  );
};

Confirmed.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  proxyMerchantToken: PropTypes.string,
  membership: PropTypes.string,
  lender: PropTypes.string,
};
Confirmed.defaultProps = {
  proxyMerchantToken: '',
  membership: '',
  lender: '',
};

export default withStyles(styles)(
  connect(null, { saveSamsclubMembership })(Confirmed),
);
