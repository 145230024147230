import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { styles } from './styles';

const TextField = (props) => {
  const {
    classes,
    input,
    label,
    helperText,
    type,
    inputProps,
    startAdornment,
    endAdornment,
    endTooltip,
    meta: { touched, error },
    ...rest
  } = props;

  const renderShrinkLabel = (type, labelStyles, label) => {
    if (type === 'date') {
      return (
        <InputLabel classes={labelStyles} htmlFor={label} shrink={true}>
          {label}
        </InputLabel>
      );
    }
    return (
      <InputLabel classes={labelStyles} htmlFor={label}>
        {label}
      </InputLabel>
    );
  };

  const hasError = !!(touched && error);
  const hasHelp = helperText;
  const errorText = (touched && error) || helperText;

  const labelStyles = {
    root: classes.labelRoot,
    shrink: classes.labelShrink,
  };
  const inputStyles = {
    root: classes.inputRoot,
    input: classes.inputInput,
    inputType: classes.inputInputType,
    focused: classes.inputFocused,
    error: clsx(
      hasHelp !== undefined && !hasError && classes.inputHelp,
      hasError && classes.inputError,
    ),
    disabled: classes.inputDisabled,
  };
  const inputAdornmentStyles = {
    positionStart: classes.inputAdornmentPositionStart,
    positionEnd: classes.inputAdornmentPositionEnd,
  };
  const errorStyles = {
    root: clsx(
      classes.alertRoot,
      hasHelp !== undefined && !hasError && classes.helperRoot,
      hasError && classes.errorRoot,
    ),
  };

  const startAdornmentIcon = startAdornment ? (
    <InputAdornment classes={inputAdornmentStyles} position="start">
      {startAdornment}
    </InputAdornment>
  ) : null;
  const endAdornmentIcon = endAdornment ? (
    <InputAdornment classes={inputAdornmentStyles} position="end">
      {endAdornment}
    </InputAdornment>
  ) : null;

  return (
    <FormControl fullWidth>
      {renderShrinkLabel(type, labelStyles, label)}
      <Input
        id={label}
        type={type}
        classes={inputStyles}
        disableUnderline={true}
        inputProps={inputProps}
        startAdornment={startAdornmentIcon}
        endAdornment={endAdornmentIcon}
        error={hasError || hasHelp !== undefined}
        {...input}
        {...rest}
      />
      {(hasError || hasHelp) && (
        <FormHelperText classes={errorStyles}>{errorText}</FormHelperText>
      )}
    </FormControl>
  );
};

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  type: PropTypes.string.isRequired,
  inputProps: PropTypes.object.isRequired,
  startAdornment: PropTypes.object,
  endAdornment: PropTypes.object,
  endTooltip: PropTypes.object,
  meta: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextField);
