export default {
  landingText1Bold:
    'Con tu Cartera Clip, recibes el dinero de tus ventas en menos de 4 h, todos los días',
  landingText1sub:
    'En transacciones de 9 h a 20 h. Después de las 20 h, se deposita al día siguiente con Clip Plus, Clip Plus 2, Pro, Total y Mini.',
  landingText2a: '0% comisiones:',
  landingText2b: '0% por saldo mínimo.',
  landingText2c: '0% por manejo de cuenta.',
  landingText2d: '0% por apertura.',
  landingText3: 'Acepta hasta $18 mil pesos al mes en tu Cartera Clip.',
  landingText3b: ' Si lo deseas, podrás aumentarlo hasta $500 mil pesos.',
  landingText4a:
    'Además, solicita tu Clip Card gratis y úsala en millones de comercios.',
  haveACardButton: 'Tengo una Clip Card',
  waitingListButton: 'Me interesa',
  verifyingData: 'Verificando datos',
  checkingData:
    'Estamos revisando tus datos. Mientras tanto, puedes seguir usando tu app Clip.',
  thanksForChoosing: '¡Gracias por elegir Clip Card!',
  requestClipCard: 'Solicitar Clip Card',
  requestStatus: 'Ver solicitud',
  start: 'Comenzar',
};
