import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import TransactionsSection from '../TransactionsSection';
import { getBalance } from '../redux/selectors';
import { getBalanceFromService } from '../redux/actions';
import SpeiOutIcon from '../../../assets/images/berlin/icon-send.svg';
import swapLogo from '../../../assets/images/berlin/swap.svg';
import cacaoLogo from '../../../assets/images/berlin/cacao-gris.svg';
import helpIcon from '../../../assets/images/berlin/helpIcon.svg';
import infoIcon from '../../../assets/images/berlin/infoIcon.svg';
import warningIcon from '../../../assets/images/berlin/warningIcon.svg';
import BottomNavigation from '../BottomNavigation';
import ClevertapComponent from '../../../helpers/Clevertap/ClevertapComponent';
import {EVENTS, Clevertap} from '../../../helpers/Clevertap';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import ClipDrawer from '@clipmx/clip-storybook/dist/components/Drawer';
import ClipAlert from '@clipmx/clip-storybook/dist/components/Alert';
import CacaoTooltip from '../CacaoTooltip';
import { ClipTypography, ClipLink, ClipButton } from '@clipmx/clip-storybook';
import { BERLIN_TANDEM_UPGRADE_STATUS, BERLIN_FINANCIAL_SERVICE_PROVIDERS } from '../../../constants/AppConstants';
import { ROUTES } from '../constants';
import { styles } from './styles';
import text from './text';
import ClipDialog from '@clipmx/clip-storybook/dist/components/Dialog';
import ClipDialogActions from '@clipmx/clip-storybook/dist/components/Dialog/DialogActions';
import ClipContainer from '@clipmx/clip-storybook/dist/components/Container';
import updateAppLogo from '../../../assets/images/berlin/update-app.svg';
import urls from 'helpers/urls';
import logger from 'helpers/logger';
import BerlinService from '../service';

const useStyles = makeStyles(styles);
const BalanceInformation = (props) => {
  const classes = useStyles(props);
  const {
    getBalanceFromService,
    account: {
      upgrade_status,
      secondary_upgrade_status,
      account_holder_representation: { name },
    },
    account,
    cardBalance,
    berlin,
    setActiveScreen,
    SCREENS,
    selectTransaction,
    flags: { financialActivateSwapToastUpgradeWeb, financialMigrationWeb },
  } = props;
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [showToast, setShowToast] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [appStoreUrl, setappStoreUrl] = useState('');
  const [appUpToDate, setappUpToDate] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search)

  const onHandleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getNavigatorAgent = () => {
    let os;
    if (/Android/i.test(navigator.userAgent)) {
      os = 'android'
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      os = 'ios'
    } else {
      os = 'web'
    }
    return os;
  }
  const amountParts = cardBalance.toString().split('.');
  let entirePart = amountParts[0] ? amountParts[0] : '0';
  let decimalPart = amountParts[1] ? amountParts[1] : '00';
  if (decimalPart.length === 1) decimalPart += '0';

  const decimalFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  useEffect(() => {
    const os = getNavigatorAgent();
    switch (os) {
      case 'android':
        setappStoreUrl(urls.googlePlay);
        break;
      case 'ios':
        setappStoreUrl(urls.appStore);
        break;
      default:
        // web - no appstore
        break;
    }
    const dialogUpdate = queryParameters.get('dialogUpdate');

    if (dialogUpdate === 'noShow') {
      // app up to date
      localStorage.setItem('appUpToDate', '1')
    }

    if (localStorage.getItem('appUpToDate')) {
      // app up to date
      setIsModalOpen(false);
      setappUpToDate(true);
    } else {
      // app not updated
      // PATCH: setIsModalOpen(true);
      setIsModalOpen(false);
      setappUpToDate(false);
    }

    postSwapEvent(EVENTS.BERLIN.SWAP_MIGRATION.ACTIONS.CLIP_DASHBOARD);
  }, [])

  useEffect(() => {
    if (account) {
      getBalanceFromService(account);
    }
  }, [account]);

  useEffect(() => {
    const {user, merchantInfo} = props;
    if (merchantInfo.proxy_id && user) {
      Clevertap.profile(user);
    }
  }, [props.user, props.merchantInfo]);

  const postSwapEvent = (event) => {
    let EVENT_PROPERTIES = {
      account_level: account.account_level,
      version: localStorage.getItem('appUpToDate') ? 'CON_MIGRACION' : 'SIN_MIGRACION',
      proxy_account_id: account.proxy_account_id,
    }
    
    if (event === EVENTS.BERLIN.SWAP_MIGRATION.ACTIONS.CLIP_DASHBOARD) {
      EVENT_PROPERTIES = {...EVENT_PROPERTIES,
        provider: account.provider_code,
        has_legal_identifier: account.account_holder_representation.has_legal_identifier,
      }
    }

    try {
      BerlinService.postSwapEvent(account.proxy_account_id, event, EVENT_PROPERTIES);
    } catch (error) {
      logger.info(error);
    }
  }

  const renderBalance = () => (
    <>
      <ClipTypography variant={'body1'} component={'p'}>
        $
      </ClipTypography>
      <ClipTypography
        variant={'h2'}
        component={'p'}
        fontWeight={'bold'}
        classes={{ root: classes.amount }}
      >
        {decimalFormat.format(entirePart)}
      </ClipTypography>
      <ClipTypography variant={'body1'} component={'p'}>
        {`.${decimalPart}`}
      </ClipTypography>
    </>
  );

  return (
    <BottomNavigation>
      <div className={classes.container}>
      <ClipDialog
      title=""
      open={isModalOpen}
      onClose={onHandleCloseModal}
      type="form"
    >
      <ClipContainer className={classes.flexCenter}>
        <img className={classes.updateLogo} src={updateAppLogo} alt={'update app'} />
      <ClipTypography 
      className={classes.updateMessage} 
      variant={'body1'} 
      component={'p'} 
      fontWeight={'semiBold'} 
      >
        {text.popUp.title}
      </ClipTypography>    
      <ClipTypography className={classes.updateMessage} variant={'body1'} component={'p'} align="center">
        {text.popUp.body}
      </ClipTypography>
      <ClipDialogActions>
        <ClipButton
            className={classes.updateAcceptButton}
            variant="outlined"
            color="primary"
            href={appStoreUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onHandleCloseModal}
            >
            <ClipTypography align="center" fontWeight="semiBold">
            {text.popUp.button}
            </ClipTypography>
          </ClipButton>
        </ClipDialogActions>
      </ClipContainer>
    </ClipDialog>

        {financialActivateSwapToastUpgradeWeb &&
          upgrade_status !== BERLIN_TANDEM_UPGRADE_STATUS.COMPLETED &&
          secondary_upgrade_status !== BERLIN_TANDEM_UPGRADE_STATUS.COMPLETED &&
          showToast && (
            <ClipAlert
              severity="warning"
              onClose={() => setShowToast(false)}
              className={classes.toastAlert}
            >
              <ClipTypography variant={'caption'}>
                {text.toastText(name)}
              </ClipTypography>
              <ClipLink variant={'caption'} href={ROUTES.BERLINSWAP}>
                {text.toastLink}
              </ClipLink>
            </ClipAlert>
          )}
          <div className={classes.accountIssuedContainer}>
            <IconButton
              classes={{ root: classes.cacaoTooltip }}
              onClick={() => {
                setDisplayTooltip(true)
                if (account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.SWAP) {
                  postSwapEvent(EVENTS.BERLIN.SWAP_MIGRATION.ACTIONS.CLIP_SWAP_REGULATORY_INFO)
                }
              }}
            >
            <img className={classes.helpIcon} src={infoIcon} alt={'ayuda'} />
            </IconButton>
            <div className={classes.serviceNameContainer}>
              <ClipTypography className={classes.serviceName} variant={'caption'} component={'p'} fontWeight={'medium'}>
                {text.serviceName}
              </ClipTypography>
              <ClipTypography variant={'caption'}>
                {text.accountIssued}
              </ClipTypography>
              <img 
              className={classes.cacaoLogo} 
              src={account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.CACAO ? cacaoLogo : swapLogo} 
              alt={account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.CACAO ? 'cacao' : 'swap'} 
              />
            </div>
          </div>
         {appUpToDate && financialMigrationWeb && account.provider_code === BERLIN_FINANCIAL_SERVICE_PROVIDERS.CACAO && <div
            className={classes.toastAlert}
          >
            <div className={classes.migrationWarning}>
              <img src={warningIcon} alt={'info'} />
            </div>
            <div>
              <ClipTypography variant={'h3'} component={'h2'}>
                {text.toastMigrationHead}
              </ClipTypography>
              <div className={classes.migrationText}>            
                <ClipTypography variant={'caption'} className={classes.migrationTextSize}>
                  {text.toastMigrationText1}
                </ClipTypography>
                <ClipTypography variant={'caption'} className={classes.migrationHighlight}>
                  {text.toastMigrationText2}
                </ClipTypography>
                <ClipTypography variant={'caption'} className={classes.migrationTextSize}>
                  {text.toastMigrationText3}
                </ClipTypography>
              </div>
              <ClipButton
                className={classes.migrationButton}
                variant="contained"
                color="primary"
                href="clip://clipcard-migration"
                >
                <ClipTypography variant={'body2'} component={'h3'} align="center">
                  {text.toastMigrationLink}
                </ClipTypography>
              </ClipButton>
            </div>
          </div>}
        
        <ClipTypography variant={'body2'} component={'h3'} align={'center'}>
          {text.availableBalance}
        </ClipTypography>
        <div className={classes.balanceContainer}>
          {berlin.isBalanceLoading ? (
            <div className={classes.balanceSkeleton} />
          ) : (
            renderBalance()
          )}
        </div>
        <div className={classes.speiOutContainer}>
          <ClevertapComponent
            renderComponent={<button />}
            disabled={berlin.isBalanceLoading}
            events={[
              {
                callbackName: 'onClick',
                event: EVENTS.BERLIN.BALANCE.EVENT,
                action: EVENTS.BERLIN.BALANCE.ACTIONS.ENVIAR_DINERO_CLICKED,
              },
            ]}
            onClick={() => {
              setActiveScreen(SCREENS.TRANSFERS);
            }}
            className={classes.speiOutButton}
          >
            <img
              className={classes.actionButtonIcon}
              src={SpeiOutIcon}X
              alt={text.speiOut}
            />
            <ClipTypography
              classes={{ root: classes.actionButtonText }}
              variant={'body2'}
              component={'span'}
              fontWeight={'bold'}
            >
              {text.speiOut}
            </ClipTypography>
          </ClevertapComponent>
        </div>
        <TransactionsSection selectTransaction={selectTransaction} />
        <ClipDrawer
          anchor={'bottom'}
          open={displayTooltip}
          classes={{ paper: classes.tooltipPaper }}
        >
          <CacaoTooltip toggleTooltip={() => setDisplayTooltip(false)} provider={account.provider_code} />
        </ClipDrawer>
      </div>    
    </BottomNavigation>
  );
};

BalanceInformation.propTypes = {
  classes: PropTypes.object,
  cardBalance: PropTypes.number,
  berlin: PropTypes.object,
  account: PropTypes.object,
};

BalanceInformation.default = {
  classes: {},
  cardBalance: 0,
  berlin: {},
  account: {},
};

const mapDispatchToProps = {
  getBalanceFromService,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cardBalance: getBalance(state),
    berlin: state.berlin,
    account: state.berlin.account_representation,
    merchantInfo: state.merchant.info,
  };
};

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(BalanceInformation),
);
