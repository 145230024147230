export default {
  title: 'Editar contacto',
  clabe: 'CLABE INTERBANCARIA',
  clabeLenght: 'Número de 18 dígitos.',
  bank: 'BANCO',
  name: 'Nombre del destinatario',
  fullName: 'Nombre completo',
  alias: 'Alias',
  aliasHelper: 'Este nombre te ayudará a identificar tu contacto',
  clabeError: 'CLABE no válida, revísala y vuelve a intentarlo.',
  clabeErrorLength: 'Número dede contenter 18 dígitos',
  errorNameMessage: 'Debe ser mayor a  3 caracteres',
  save:'Guardar cambios',
};
