import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { renderFileInput } from 'helpers/FormUtils';
import { LoansFilesStates } from 'constants/AppConstants';
import * as Styled from './styled';
import text from './text';

export class FileUploader extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    accept: PropTypes.string.isRequired,
    maxFiles: PropTypes.number,
    notFound: PropTypes.bool.isRequired,
    files: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onCancelUpload: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
  };

  state = {
    dragOverClass: '',
    status: LoansFilesStates.NONE,
    deleting: [],
  };

  deletingTimeout = null;

  componentWillUnmount = () => {
    clearTimeout(this.deletingTimeout);
  };

  openFileDialog = () => {
    const formDomRoot = this.fileInputRef
      ? ReactDOM.findDOMNode(this.fileInputRef)
      : ReactDOM.findDOMNode(this.lastFileInputRef);
    formDomRoot.click();
  };

  onUploadFile = (event) => {
    const { files: filesLoaded, maxFiles, onChange } = this.props;
    const files = event.target.files || event.dataTransfer.files;
    const filesLoadedLength = filesLoaded.filter((file) =>
      [LoansFilesStates.SAVED, LoansFilesStates.LOADING].includes(file.status),
    ).length;

    if (files.length + filesLoadedLength > maxFiles) {
      this.setState({ status: LoansFilesStates.MAX_FILES });
    } else {
      this.setState({ status: LoansFilesStates.NONE });
      onChange(files);
    }
    event.target.value = '';
  };

  onDeleteFile = (id) => {
    const { deleting } = this.state;
    this.setState({ deleting: [...deleting, id] });
    this.deletingTimeout = setTimeout(() => {
      this.setState({ deleting: deleting.filter((itemId) => itemId !== id) });
    }, 5000);
  };

  onDeleteFileConfirmation = (file) => {
    this.props.onDelete(file);
    this.setState({ status: LoansFilesStates.NONE });
  };

  onDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ dragOverClass: 'dragover' });
  };

  onDragLeave = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ dragOverClass: '' });
  };

  onDrop = (event) => {
    this.onDragLeave(event);
    const { name } = this.props;
    const files = event.dataTransfer.items || event.dataTransfer.files;
    const mappedFiles = [];
    for (let index = 0; index < files.length; index++) {
      mappedFiles[index] = files[index].getAsFile
        ? files[index].getAsFile()
        : files[index];
    }
    mappedFiles.length > 0 &&
      this.onUploadFile({
        target: {
          files: mappedFiles,
          name,
        },
      });
  };

  renderFileIconAction = (file) => {
    const { deleting } = this.state;
    const { status, id } = file;
    switch (status) {
      case LoansFilesStates.NOT_FOUND:
      case LoansFilesStates.SAVED:
        return deleting.includes(file.id) ? (
          <Styled.DeleteText
            onClick={() => {
              this.onDeleteFileConfirmation(file);
            }}
          >
            {text.delete}
          </Styled.DeleteText>
        ) : (
          <Styled.DeleteLink
            onClick={() => {
              this.onDeleteFile(id);
            }}
          >
            <span />
            <span />
          </Styled.DeleteLink>
        );
      case LoansFilesStates.ERROR:
      case LoansFilesStates.MAX_SIZE:
      case LoansFilesStates.INCORRECT_TYPE:
      case LoansFilesStates.DISCONNECTED:
        return (
          <React.Fragment>
            <Styled.PublishIconContainer
              key={'PI' + id}
              onClick={this.openFileDialog}
            >
              <Styled.PublishIcon>
                <Styled.BodyArrow />
              </Styled.PublishIcon>
            </Styled.PublishIconContainer>
            <Styled.ChangeFileMessage
              key={'CHFM' + id}
              onClick={this.openFileDialog}
            >
              {text.changeFile}
            </Styled.ChangeFileMessage>
          </React.Fragment>
        );
      default:
        return '';
    }
  };

  renderStatusFileIcon = (status) => {
    switch (status) {
      case LoansFilesStates.SAVED:
        return <Styled.DoneIconGreen />;
      case LoansFilesStates.LOADING:
        return (
          <Styled.ContainerLoadingIcon>
            <Styled.BgLoadingIcon />
            <Styled.LoadingIcon>
              <Styled.LoadingPart />
              <Styled.LoadingPart />
              <Styled.LoadingPart />
              <Styled.LoadingPart />
            </Styled.LoadingIcon>
          </Styled.ContainerLoadingIcon>
        );
      case LoansFilesStates.ERROR:
      case LoansFilesStates.MAX_SIZE:
      case LoansFilesStates.INCORRECT_TYPE:
      case LoansFilesStates.MAX_FILES:
      case LoansFilesStates.DISCONNECTED:
      case LoansFilesStates.REQUIRED:
      case LoansFilesStates.NOT_FOUND:
        return <Styled.ErrorIcon />;
      default:
        return <Styled.DoneIcon />;
    }
  };

  renderMessageErrorByFile = (status) => {
    switch (status) {
      case LoansFilesStates.MAX_SIZE:
        return <Styled.MessageError>{text.maxSize}</Styled.MessageError>;
      case LoansFilesStates.INCORRECT_TYPE:
        return <Styled.MessageError>{text.incorrectType}</Styled.MessageError>;
      case LoansFilesStates.DISCONNECTED:
        return <Styled.MessageError>{text.disconnected}</Styled.MessageError>;
      case LoansFilesStates.ERROR:
        return <Styled.MessageError>{text.tryAgain}</Styled.MessageError>;
      case LoansFilesStates.NOT_FOUND:
        return <Styled.MessageError>{text.notFound}</Styled.MessageError>;
      default:
        return '';
    }
  };
  renderProgressBar = (file, index) => {
    if (file.status === LoansFilesStates.LOADING) {
      const { onCancelUpload } = this.props;
      const loaded = Math.round(file.loaded % 101);
      return (
        <Styled.ProgressBar key={'progress_' + index}>
          <Styled.Progress style={{ width: `${loaded}%` }} />
          <Styled.CancelProgress
            onClick={() => {
              onCancelUpload(file);
            }}
          >
            X
          </Styled.CancelProgress>
        </Styled.ProgressBar>
      );
    }
    return '';
  };

  render() {
    const { title, accept, name, notFound, files, maxFiles } = this.props;
    const { status, dragOverClass } = this.state;

    return (
      <Styled.FileUploader
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
        onDragLeave={this.onDragLeave}
        className={dragOverClass}
      >
        <Styled.TitleContainer>
          <Styled.Title>
            {(notFound || status === LoansFilesStates.MAX_FILES) && (
              <Styled.ErrorIcon />
            )}
            {title}
          </Styled.Title>
          <Styled.FileInputContainer>
            <Field
              component={renderFileInput}
              accept={accept}
              multiple
              name={name}
              onChange={this.onUploadFile}
              ref={(input) => {
                if (input) this.fileInputRef = input;
              }}
            />
          </Styled.FileInputContainer>
          <Styled.PlusLink onClick={this.openFileDialog}>
            <Styled.PlusLinkPart />
            <Styled.PlusLinkPart />
          </Styled.PlusLink>
        </Styled.TitleContainer>
        {(notFound || status === LoansFilesStates.MAX_FILES) && (
          <Styled.MessageError>
            {status === LoansFilesStates.MAX_FILES
              ? text.maxFiles
              : text.required}
          </Styled.MessageError>
        )}
        <Styled.FileItems>
          {files
            .filter((_, index) => index < maxFiles)
            .map((file, index) => (
              <Styled.FileItem key={`file_${index}`}>
                {this.renderStatusFileIcon(file.status)}
                <Styled.FileName>{file.name}</Styled.FileName>
                {this.renderFileIconAction(file)}
                {this.renderProgressBar(file, index)}
                {this.renderMessageErrorByFile(file.status)}
              </Styled.FileItem>
            ))}
        </Styled.FileItems>
      </Styled.FileUploader>
    );
  }
}

export default FileUploader;
