import React from 'react';

import * as Styled from './styled';
import TransactionsGroup from '../TransactionsGroup';

import { getMovements } from '../../redux/selectors';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { groupByDate } from '../../utils';

export class Transactions extends React.Component {
  static propTypes = {
    movements: PropTypes.array,
  };

  static defaultProps = {
    movements: [],
  };

  getDateGroups = () => {
    const groups = groupByDate(this.props.movements);
    let movementGroups = [];
    for (const group in groups) {
      movementGroups.push(
        <TransactionsGroup
          selectTransaction={this.props.selectTransaction}
          key={group}
          date={group}
          movements={groups[group]}
        ></TransactionsGroup>,
      );
    }
    return movementGroups;
  };

  render() {
    return (
      <Styled.TransactionsContainer>
        {this.getDateGroups()}
      </Styled.TransactionsContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    movements: getMovements(state),
  };
};

export default connect(mapStateToProps)(Transactions);
