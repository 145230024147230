import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import { ArrowBack as ArrowBackIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import { Close as CloseIcon } from '@clipmx/clip-storybook/dist/components/Icons';

import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import styles from './styles';

const useStyles = makeStyles(styles);
const ProgressNavigator = (props) => {
  const classes = useStyles(props);
  const { handleClick, progress, handleClose, total, v2 } = props;

  const renderProgress = () => {
    if (progress) {
      return (
        <div className={classes.barContainer}>
          <div className={classes.barProgress}></div>
        </div>
      );
    }
  };

  const renderProgressV2 = () => {
    let elements = [];
    if (progress) {
      for (let i = 0; i < total; i++) {
        elements.push(<div 
                        key={i} 
                        className={`${classes.barProgressV2} ${(progress - 1) ===  i ? classes.active : ''}`}/>)
      }
    }
    return(elements)
  };
  return (
    <div className={classes.container}>
      {handleClick && (
        <IconButton
          classes={{ root: classes.iconButton }}
          onClick={() => handleClick()}
        >
          <ArrowBackIcon classes={{ root: classes.backIcon }} />
        </IconButton>
      )}
      {!handleClick && <div className={classes.iconButton} />}
      {v2 ?<div className={classes.barContainerV2}>{renderProgressV2()}</div> :renderProgress()}
      {handleClose && (
        <IconButton
          classes={{ root: classes.iconButton }}
          onClick={() => handleClose()}
        >
          <CloseIcon classes={{ root: classes.closeIcon }} />
        </IconButton>
      )}
    </div>
  );
};

ProgressNavigator.propTypes = {
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  progress: PropTypes.number,
  total: PropTypes.number,
  v2: PropTypes.bool,
};

ProgressNavigator.defaultProps = {
  handleClick: () => {},
  handleClose: () => {},
  progress: 1,
  total: 1,
  v2: false,
};

export default ProgressNavigator;
