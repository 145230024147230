import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { LoansFilesStates, LoansDocuments } from 'constants/AppConstants';
import FileUploader from 'components/loans/Commons/FileUploader';
import { default as FileUploaderV2 } from 'components/loans/Commons/FileUploaderV2';
import LoansService from 'components/loans/service';
import {
  getPreapprovalSelected,
  getOnDragFileLoans,
} from 'components/loans/redux/selectors';
import {
  uploadFile,
  deleteFile,
  addFile,
  deleteFilesNotSaved,
  cleanLastOneIdentity,
  cleanLastOneIdentityBack,
  cleanLastOnePassport,
  cleanLastOneProofOfAddress,
} from 'components/loans/redux/actions';
import {
  FilesContainer,
  FormControl,
} from 'components/loans/Commons/FileUploader/styled';

export class StyledFileUploader extends Component {
  static propTypes = {
    fieldRequired: PropTypes.bool.isRequired,
    fileUploaded: PropTypes.func,
    files: PropTypes.array,
    title: PropTypes.string.isRequired,
    documentType: PropTypes.string.isRequired,
    maxFiles: PropTypes.number.isRequired,
    isFileBeingDragged: PropTypes.bool,
    previousLoan: PropTypes.bool,
    withIcon: PropTypes.bool,
    uploaderVersion: PropTypes.string.isRequired,
  };

  render() {
    const {
      fieldRequired,
      files,
      title,
      documentType,
      accept,
      maxFiles,
      isFileBeingDragged,
      previousLoan,
      withIcon,
      uploaderVersion,
      requiredV4,
    } = this.props;

    if (uploaderVersion && uploaderVersion === '2') {
      return (
        <FileUploaderV2
          title={title}
          name={documentType}
          accept={accept}
          maxFiles={maxFiles}
          notFound={fieldRequired}
          files={files}
          className={isFileBeingDragged ? 'dragover' : ''}
          onChange={(files) => {
            this.uploadFiles(documentType, files);
          }}
          onDelete={(file) => {
            this.deleteFileByType(documentType, file, previousLoan);
          }}
          onCancelUpload={(file) => {
            this.cancelUpload(file);
          }}
          withIcon={withIcon}
          versionV4={requiredV4}
        />
      );
    }

    return (
      <FilesContainer className={isFileBeingDragged ? 'dragover' : ''}>
        <FormControl margin="normal" fullWidth>
          <FileUploader
            title={title}
            name={documentType}
            accept={accept}
            maxFiles={maxFiles}
            notFound={fieldRequired}
            files={files}
            onChange={(files) => {
              this.uploadFiles(documentType, files);
            }}
            onDelete={(file) => {
              this.deleteFileByType(documentType, file, previousLoan);
            }}
            onCancelUpload={(file) => {
              this.cancelUpload(file);
            }}
          />
        </FormControl>
      </FilesContainer>
    );
  }

  uploadFiles = (documentType, files) => {
    const {
      uploadFile,
      addFile,
      deleteFilesNotSaved,
      preapprovalSelected,
      idPrefix,
      filesCount,
      fileID,
      uploaderVersion,
    } = this.props;
    deleteFilesNotSaved(documentType);
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const { type, name, size } = file;
      const token = LoansService.generateCancelToken();
      const id = this.getDocumentId(
        idPrefix,
        uploaderVersion === '1' ? filesCount : fileID,
        index,
        name,
      );
      addFile(documentType, {
        status: LoansFilesStates.LOADING,
        name,
        type,
        id,
        loaded: 0,
        token,
        key: null,
        base64: null,
      });
      if (!LoansDocuments.allowedTypes.test(type)) {
        addFile(documentType, {
          status: LoansFilesStates.INCORRECT_TYPE,
          name,
        });
      } else if (size > LoansDocuments.maxBytesLoaded) {
        addFile(documentType, {
          status: LoansFilesStates.MAX_SIZE,
          name,
        });
      } else {
        const { pre_approval_id: preApprovalId } = preapprovalSelected;
        uploadFile(
          preApprovalId,
          documentType,
          file,
          id,
          this.onUploadProgress,
          this.fileUploaded,
          token,
        );
      }
    }
  };

  getDocumentId = (idPrefix, fileID, index, name) => {
    const { uploaderVersion } = this.props;
    fileID = uploaderVersion === '1' ? fileID + 1 : fileID;

    return `${idPrefix}${index + fileID}_${name}`;
  };

  onUploadProgress = (type, name, progressEvent) => {
    const { addFile } = this.props;
    const loaded = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    addFile(type, {
      status: LoansFilesStates.LOADING,
      loaded,
      name,
    });
  };

  fileUploaded = (type, name, key) => {
    const { addFile } = this.props;
    addFile(type, {
      status: LoansFilesStates.SAVED,
      loaded: 100,
      name,
      key,
    });
    this.props.fileUploaded && this.props.fileUploaded(type);
  };

  deleteFileByType = (documentType, file, previousLoan) => {
    if (previousLoan) {
      switch (documentType) {
        case 'identity':
          this.props.cleanLastOneIdentity();
          break;
        case 'identityBack':
          this.props.cleanLastOneIdentityBack();
          break;
        case 'passport':
          this.props.cleanLastOnePassport();
          break;
        case 'proofOfAddress':
          this.props.cleanLastOneProofOfAddress();
          break;
        default:
          break;
      }
    } else {
      const { deleteFile, preapprovalSelected } = this.props;
      const { pre_approval_id: preApprovalId } = preapprovalSelected;
      deleteFile(file.id, preApprovalId, documentType, file.name);
    }
  };

  cancelUpload = (file) => {
    file.token && file.token.cancel(LoansDocuments.CANCEL_UPLOAD);
  };
}

const mapStateToProps = (state) => {
  return {
    preapprovalSelected: getPreapprovalSelected(state),
    isFileBeingDragged: getOnDragFileLoans(state),
  };
};

const mapDispatchToProps = {
  uploadFile,
  deleteFile,
  addFile,
  deleteFilesNotSaved,
  cleanLastOneIdentity,
  cleanLastOneIdentityBack,
  cleanLastOnePassport,
  cleanLastOneProofOfAddress,
};
export default connect(mapStateToProps, mapDispatchToProps)(StyledFileUploader);
