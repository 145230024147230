import { getToken } from '../../helpers/tokenHelpers';
import getConfigValue from '../../config';

const axios = require('axios');

export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const PATCH = 'path';
export const DELETE = 'delete';

export const makeFullUrl = (url, apiEndPoint = 'v2') => {
  const baseUrl = getConfigValue(`apiUrl${apiEndPoint}`) || 'no configured';
  return `${baseUrl}${url}`;
};

export const getRequestOptions = (token = getToken(), newHeaders = null) => {
  const headers = {
    Accept: 'application/vnd.com.payclip.v1+json',
    ...newHeaders,
  };

  if (token) {
    headers.Authorization = token;
  }

  return headers;
};

/**
 * Post data to our middleware server.
 */
export function apiSendData(
  method,
  url,
  params,
  data = null,
  newHeaders = null,
  apiEndPoint = 'v2',
  token,
) {
  const axiosConfig = {
    method,
    url: makeFullUrl(url, apiEndPoint),
    data,
    params,
    headers: getRequestOptions(token, newHeaders),
  };
  return new Promise((resolve, reject) => {
    axios(axiosConfig)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
