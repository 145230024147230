import urls from 'helpers/urls';
import { loansClipPhone as ClipPhone } from 'constants/AppConstants';

export default {
    helpNeed: '¿Necesitas ayuda?',
    contacCustomer: 'Customer Happiness® te atiende 24/7, todo el año',
    contactExternalWhatsapp: 'Escríbenos en ',
    contactWhatsApp: 'Ayuda vía Whatsapp',
    contactPhone: 'Llamar ahora',
    contactExternalMessageAdvice: 'Llámanos al teléfono ',
    contactMobile: `${ClipPhone}`,
    contactEmail: ' Envía un correo a ',
    contactUrlEmail: `${urls.clipHelpEmail}`,
    contactChat: ' o escríbenos en el chat de ',
    contactClipMx: 'clip.mx.',

}
