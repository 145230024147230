import {
  SET_CARD_BALANCE,
  SET_CARD_MOVEMENTS,
  SET_ACTIVE_CARD,
  SET_COLONIES,
  SET_ACCOUNT_REPRESENTATION,
  SET_BALANCE_LOADING_STATE,
  SET_MOVEMENTS_LOADING_STATE,
  SET_LD_FLAGS,
  SET_LAST_LOGIN,
  SET_ACCOUNT_SECTION_VISITED,
  SET_DOCUMENT_TYPE,
  SET_CONTACTS,
  SET_IS_LOGGED,
} from './types';

export const setCardBalance = (cardBalance) => ({
  type: SET_CARD_BALANCE,
  payload: { cardBalance },
});

export const setCardMovements = (cardMovements) => ({
  type: SET_CARD_MOVEMENTS,
  payload: { cardMovements },
});

export const setActiveCard = (activeCard) => ({
  type: SET_ACTIVE_CARD,
  payload: { activeCard },
});

export const setIsLogged = (isLogged) => ({
  type: SET_IS_LOGGED,
  payload: { isLogged },
});

export const setBalanceLoadingState = (isBalanceLoading) => ({
  type: SET_BALANCE_LOADING_STATE,
  payload: { isBalanceLoading },
});

export const setMovementsLoadingState = (isMovementsLoading) => ({
  type: SET_MOVEMENTS_LOADING_STATE,
  payload: { isMovementsLoading },
});

export const setColonies = (colonies) => ({
  type: SET_COLONIES,
  payload: { colonies },
});

export const setAccountRep = (account_representation) => ({
  type: SET_ACCOUNT_REPRESENTATION,
  payload: { account_representation },
});

export const setDocumentType = (document_type) => ({
  type: SET_DOCUMENT_TYPE,
  payload: document_type,
});

export const setLdFlags = (flags) => ({
  type: SET_LD_FLAGS,
  payload: { ...flags },
});

export const setContacts = (contacts) => ({
  type: SET_CONTACTS,
  payload: { contacts },
});

export const setLastLogin = (lastLogin) => ({
  type: SET_LAST_LOGIN,
  payload: { lastLogin },
});

export const setAccountSectionVisited = (accountSectionVisited) => ({
  type: SET_ACCOUNT_SECTION_VISITED,
  payload: { accountSectionVisited },
});
