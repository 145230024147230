import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import { getFormValues } from 'redux-form';
import { store } from 'react-notifications-component';

import { Forms } from '../../../constants/AppConstants';

import { DialogProvider } from '@clipmx/clip-storybook/dist/components/Dialog/dialogContext';
import { uploadIDPictures } from '../redux/actions';
import { setAccountRep, setDocumentType } from '../redux/berlinActions';
import { shortStates } from '../Commons/FormSelectValues';

import ProgressNavigator from '../Commons/ProgressNavigator';
import { ROUTES2 as ROUTES, ACCOUNT } from '../constants';
import BerlinService from '../service';
import AcceptTerms from './AcceptTerms';
import TermsAndConditions from './TermsAndConditions';
import InputCurp from './InputCurp';
import PersonalInfo from './PersonalInfo';
import AddressForm from './AddressForm';
import IdentificationsPhoto from './IdentificationsPhoto';
import WaitingForDataValidation from './WaitingForDataValidation';
import OnboardingError from './OnboardingError';

import text from './text';

export const Registration2 = (props) => {
  let history = useHistory();
  const { merchant, user, ldFlags } = props;
  const [step, setStep] = useState(1);
  const [curpValue, setCurp] = useState('');
  const [curpInfo, setCurpInfo] = useState(null);
  const [skipCurp, setSkipCurp] = useState(false);
  const [curpError, setCurpError] = useState(false);
  const [error, setError] = useState(null);
  let counter = 0;
  const totalStep = 8;
  const nextStep = (forward = 1) => {
    setStep(step + forward);
  };

  const prevStep = (back = 1) => {
    setStep(step - back);
  };

  const pollStatus = () => {
    let poll = setInterval(() => {
      if (counter === 9) {
        clearInterval(poll);
      }
      counter++;
      BerlinService.accountInformationV2(merchant.info.id).then((response) => {
        if (response.data.message.account_status === ACCOUNT.STATUS.ACTIVATED) {
          props.setAccountRep(response.data.message);
          props.setDocumentType(
            response.data.message.account_holder_representation.document_type,
          );
          clearInterval(poll);
          history.push(ROUTES.ACTIVATION);
          return;
        }
        if (response.data.message.account_status === ACCOUNT.STATUS.REJECTED) {
          const { card_status_description = 'UNKNOWN' } =
            response.data.message.card_representation[0];
          props.setAccountRep(response.data.message);
          clearInterval(poll);
          setError(card_status_description);
          setStep(1);
          return;
        }
      });
    }, 30000);
  };
  const submitInfo = (values, files, dir) => {
    setStep(-1);
    const document = {
      INE: 'INE',
      PASSPORT: 'PASSPORT',
    };
    values['legal_identifier'] = curpValue;
    if (!navigator.geolocation) {
      setStep(5);
      store.addNotification({
        title: '',
        message: 'No pudimos obtener tu ubicación',
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      return;
    }
    navigator.geolocation.getCurrentPosition(async (position) => {
      try {
        const fullState = shortStates.find(
          (state) => state.id === values.birth_place,
        );
        const payload = {
          merchant_id: merchant.info.id,
          document: dir === document.INE ? 'INE' : 'PASSPORT',
          person: {
            name: values.name.trim(),
            last_name: values.last_name.trim(),
            second_last_name: values.second_last_name.trim(),
            citizenship: values.nationality === '000' ? 'MEXICAN' : 'FOREING',
            birth_place: fullState.name,
            birth_date: values.birth_date.split(' / ').reverse().join('-'),
            gender: values.gender,
            legal_identifier: curpValue.trim(),
            tax_identifier: null,
            email: user.email,
            phone_number: user.mobile,
            occupation: merchant.info.industry_name,
            profession: merchant.info.industry_name,
            business_sector: merchant.info.industry_name,
          },
          location: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
          address: {
            city: values.city,
            street: values.street,
            external_number: values.number,
            internal_number: values.interior,
            neighborhood: values.colony,
            municipality: values.municipality,
            state: values.state,
            zip_code: values.zip_code,
            country: '004',
          },
        };
        let documentsUrlResponse = await BerlinService.getDocumentsUrl();
        await uploadIDPictures(files, documentsUrlResponse.data, dir);
        await BerlinService.postAccountRegistration(payload);
        /*setTimeout(() => {
                    pollStatus()
                }, parseInt(ldFlags.onboardingRequestTimeout));*/
      } catch (error) {
        setStep(5);
        switch (error.response.status) {
          case 409:
            store.addNotification({
              title: '',
              message: error.response.data.message,
              type: 'danger',
              insert: 'top',
              container: 'top-center',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                showIcon: true,
                duration: 0,
              },
            });
            break;
          case 404:
            store.addNotification({
              title: text.ErrorIsUsTitle,
              message: text.ErrorIsUsMessage,
              type: 'danger',
              insert: 'top',
              container: 'top-center',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
            break;
          default:
            store.addNotification({
              title: text.CallCustomerHappinesTitle,
              message: text.CallCustomerHappinesMessage,
              type: 'danger',
              insert: 'top',
              container: 'top-center',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
        }
      }
    });
  };
  const renderStep = () => {
    if (error) {
      return (
        <OnboardingError clearError={() => setError(null)} error={error} />
      );
    }
    switch (step) {
      case -1:
        return (
          <>
            <WaitingForDataValidation version="2" />
          </>
        );
      case 0:
        return (
          <>
            {' '}
            <ProgressNavigator handleClick={() => setStep(1)} />
            <TermsAndConditions />
          </>
        );
      case 1:
        return (
          <DialogProvider>
            <ProgressNavigator
              progress={step}
              total={totalStep}
              handleClick={() => {
                window.location.href = '/mobile/berlin';
              }}
            />
            <AcceptTerms user={user} nextStep={nextStep} seeTerms={prevStep} />
          </DialogProvider>
        );
      case 2:
        return (
          <InputCurp
            step={step}
            user={user}
            failedToCreateCurp={curpError}
            setFailedToCreateCurp={setCurpError}
            totalStep={totalStep}
            prevStep={prevStep}
            setCurpInfo={setCurpInfo}
            setCurp={setCurp}
            curpValue={curpValue}
            setSkipCurp={setSkipCurp}
            skipCurp={skipCurp}
            nextStep={nextStep}
          />
        );
      case 3:
        return (
          <PersonalInfo
            step={step}
            user={user}
            setCurpError={setCurpError}
            totalStep={totalStep}
            skipCurp={skipCurp}
            setSkipCurp={setSkipCurp}
            prevStep={prevStep}
            setCurp={setCurp}
            preInfo={curpInfo}
            nextStep={nextStep}
          />
        );
      case 4:
        return (
          <>
            <ProgressNavigator
              progress={step}
              total={totalStep}
              handleClick={() => {
                skipCurp ? prevStep(2) : prevStep();
              }}
            />
            <AddressForm user={user} nextStep={nextStep} />
          </>
        );

      default:
        return (
          <>
            <IdentificationsPhoto
              user={user}
              totalStep={totalStep}
              navStep={step}
              nextStep={nextStep}
              prevStep={prevStep}
              startAccountCreation={submitInfo}
            />
          </>
        );
    }
  };
  return (
    <>
      <ReactNotification />
      {renderStep()}
    </>
  );
};

Registration2.propTypes = {
  merchant: PropTypes.object,
  user: PropTypes.object,
  ldFlags: PropTypes.object,
};
Registration2.defaultProps = {
  merchant: {},
  user: {},
  ldFlags: {},
};
const mapDispatchToProps = {
  setAccountRep,
  setDocumentType,
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    merchant: state.merchant,
    ldFlags: state.berlin.ldFlags,
    formValues: getFormValues(Forms.BERLIN_REGISTRATION)(state),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Registration2);
