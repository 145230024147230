import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ClipCall from '@clipmx/clip-storybook/dist/components/Illustrations/ClipCall/ClipCall';
import {
  LoansStates,
  LoansTabs,
  PreapprovalStatus,
} from 'constants/AppConstants';
import { getUserName, getPreapprovals } from 'components/loans/redux/selectors';
import LoansRedirection from 'components/loans/Commons/Redirection';
import {
  ReviewTitle,
  CenterDetail,
} from '../styled';
import text from 'components/loans/Refill/text';
import PropTypes from 'prop-types';
import HelpNeed from '../helpneed';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';

const RefillReview = (props) => {
  const { merchantName, preapprovals = [] } = props;

  return (
    <LoansRedirection tab={LoansTabs.NEW_OFFER}>
      {preapprovals.filter(
        (preapproval) =>
          [LoansStates.REFILL].includes(preapproval.type) &&
          [PreapprovalStatus.ACCEPTED, PreapprovalStatus.ON_HOLD].includes(
            preapproval.status,
          ),
      ).length > 0 ? (
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <ClipCall
              style={{
                margin: 'auto',
                display: 'block',
                width: '150',
                height: 'auto',
                marginTop: '38px',
              }}
            />
            <ReviewTitle>{text.titleReview}</ReviewTitle>
            <CenterDetail>{text.detailReview(merchantName)}</CenterDetail>
            <HelpNeed />
          </Grid>
        </Grid>
      ) : null}
    </LoansRedirection>
  );
};

RefillReview.propTypes = {
  merchantName: PropTypes.string.isRequired,
  preapprovals: PropTypes.array,
};
RefillReview.defaultProps = {
  preapprovals: [],
};

const mapStateToProps = (state) => {
  return {
    merchantName: getUserName(state),
    preapprovals: getPreapprovals(state),
  };
};
export default withRouter(connect(mapStateToProps)(RefillReview));
