const styles = (theme) => {
  return {
    carouselContainer: {
      background: 'white',
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    LandingLogoImage: {
      width: '5.75rem',
      height: '5.75rem',
      objectFit: 'contain',
      marginBottom: '1rem',
    },
    LandingImage: {
      width: '19.5rem',
      height: '9.625rem',
      objectFit: 'contain',
      marginBottom: '2rem',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    LandingText: {
      padding: '0 16px',
    },
    boldText: {
      fontWeight: 'bold',
    },
    lightText: {
      fontWeight: '400',
      paddingTop: '25px',
    },
    SelectedIndicatorStyles: {
      background: theme.palette.orange,
      width: 8,
      height: 8,
      display: 'inline-block',
      margin: '0 8px',
      borderRadius: '4px',
    },
    IndicatorStyles: {
      background: '#d8d8d8',
      width: 8,
      height: 8,
      display: 'inline-block',
      margin: '0 8px',
      borderRadius: '4px',
    },
    startButtonContainer: {
      position: 'fixed',
      width: '-moz-calc(100% - 2rem)',
      width: '-webkit-calc(100% - 2rem)',
      width: '-o-calc(100% - 2rem)',
      width: 'calc(100% - 2rem)',
      left: '1rem',
      bottom: '1rem',
    },
    startButton: {
      width: '100%',
    },
  };
};
export default styles;
