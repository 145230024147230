import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AppBar from '@clipmx/clip-storybook/dist/components/AppBar';
import ToolBar from '@clipmx/clip-storybook/dist/components/Toolbar';
import ClipTypography from '@clipmx/clip-storybook/dist/components/Typography';

import AnalyticsEvents from '../../../helpers/AnalyticsEvents';
import urls from '../../../helpers/urls';
import getUrl from '../../../helpers/getUrl';
import { getToken, BUM_TOKEN_NAME } from '../../../helpers/tokenHelpers';
import { AnalyticsSectionList } from '../../../utils/Analytics';
import { logoutUser } from '../../auth/redux/actions';
import { getBackMainMerchant } from '../../BusinessUnitsManagement/redux/actions';

import ButtonMenu from './ButtonMenu';
import ClipLogoIcon from './Logo';
import useStyles from './styled';

import text from './text';
import { LinearProgress } from '@material-ui/core';
import { getIsLoading } from '../../../helpers/ReduxSelectors';

export const Header = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const { user, merchant, logoutUser, getBackMainMerchant, fetchingDashboard } = props;
  const [switched, setSwitched] = useState(false);

  useEffect(() => {
    const token = getToken(BUM_TOKEN_NAME);
    setSwitched(!!token);
  }, [merchant]);

  const goHome = () => {
    history.push(getUrl(urls.dashboard));
  };

  const goSupport = () => {
    AnalyticsEvents.supportHeader();
    window.open(urls.clipSupport, '_blank');
  };

  const goLogout = () => {
    logoutUser(user.email);
  };

  return (
    <AppBar
      color={switched ? 'primary' : 'default'}
      classes={{ root: classes.root }}
    >
      <ToolBar variant="regular" classes={{ root: classes.toolbar }}>
        <div className={classes.toolbarChild}>
          <ClipLogoIcon
            switched={switched}
            className={classes.logo}
            onClick={goHome}
          />
          <div className={classes.endAdormentContainer}>
            <ClipTypography
              variant={'body1'}
              classes={{
                root: switched ? classes.supportSwitched : classes.support,
              }}
              id={AnalyticsSectionList.subHeader}
              onClick={goSupport}
            >
              {text.support}
            </ClipTypography>
            <div
              className={switched ? classes.dividerSwitched : classes.divider}
            />
            <ButtonMenu
              merchant={merchant}
              user={user}
              goLogout={goLogout}
              switched={switched}
              getBackMainMerchant={getBackMainMerchant}
            />
          </div>
        </div>
      </ToolBar>
      {fetchingDashboard ? <LinearProgress /> : null}
    </AppBar>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    merchant: state.merchant,
    fetchingDashboard: getIsLoading(state),
  };
}

export default connect(mapStateToProps, { logoutUser, getBackMainMerchant })(
  Header,
);
