export const GET_BANK_ACCOUNT_INFORMATION =
  'account/GET_BANK_ACCOUNT_INFORMATION';
export const GET_BANK_ACCOUNT_INFORMATION_ERROR =
  'account/GET_BANK_ACCOUNT_INFORMATION_ERROR';
export const DELETE_BANK_ACCOUNT_CHANGE_REQUEST =
  'account/DELETE_BANK_ACCOUNT_CHANGE_REQUEST';
export const DELETE_BANK_ACCOUNT_CHANGE_REQUEST_ERROR =
  'account/DELETE_BANK_ACCOUNT_CHANGE_REQUEST_ERROR';
export const CLEAR_BANK_INFO = 'account/CLEAR_BANK_INFO';
export const ADD_BANK_ACCOUNT = 'account/ADD_BANK_ACCOUNT';
export const UPDATE_BANK_ACCOUNT = 'account/UPDATE_BANK_ACCOUNT';
export const ADD_BANK_ACCOUNT_ERROR = 'account/ADD_BANK_ACCOUNT_ERROR';
export const UPDATE_BANK_ACCOUNT_ERROR = 'account/UPDATE_BANK_ACCOUNT_ERROR';
export const PUBLIC_PROFILE_DATA = 'PUBLIC_PROFILE_DATA';
