import React, { useState } from 'react';
import useStyles from './styles';
// arrows
import arrowUpIcon from '../../../../assets/images/Arrow-Chevron-Up.svg';
import arrowDownIcon from '../../../../assets/images/Arrow-Chevron-Down.svg';

export const Accordion = (props) => {
    const { title, children } = props;
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <section className={classes.accordionContainer}>
            <div className="accordionHeader" onClick={toggle}>
                <h5>{title}</h5>
                <img className={`${isOpen ? 'arrowUpIcon' : 'arrowDownIcon'}`} src={isOpen ? arrowUpIcon : arrowDownIcon} alt={`Arrow ${isOpen ? 'Up' : 'Down'}`} />
            </div>
            <div className={`accordionContent ${isOpen ? '' : 'hidden'}`}>
                {children}
            </div>
        </section>
    )
}

export default Accordion;


