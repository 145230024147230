import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { toCurrencyFormat } from 'helpers/formatCurrency';
import { savePreapprovalSelected } from 'components/loans/redux/actions';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import moment from 'moment-timezone';
import { ClipTypography } from '@clipmx/clip-storybook';
import ClipGrid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipPaper from '@clipmx/clip-storybook/dist/components/Paper';
import ClipSlider from '@clipmx/clip-storybook/dist/components/Slider';
import ClipButton from 'components/npm/clip-ui/Button';
import { getUserName } from '../../redux/selectors';

import useStyles from './styles';

import HourglassTopIcon from 'assets/images/icons/HourglassTop.svg';
import ClockTime from '@clipmx/clip-storybook/dist/components/Icons/ClockTime';
import ClevertapComponent from '../../../../helpers/Clevertap/ClevertapComponent';
import { EVENTS } from '../../../../helpers/Clevertap';
import { amountPayload, lenderPayload } from '../Clevertap';

import {
  Container,
  ExpireWarning,
  HourglassTop,
  ExpireWarningContent,
  AmountsContainer,
  Amount,
  AmountContent,
  AmountTitle,
  AmountSubtitle,
  AmountHR,
  AmountDescription,
  DescriptionText,
  Description,
  ValuesText,
  Values,
  AmountFooter,
  AmountContainer,
  AmountReduced,
  AmountLoan,
  ButtonAmount,
  AmountRecommended,
  RecommendedLabel,
} from './styled';
import text from './text';
import clsx from 'clsx';

const decimalFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const decimalFormatWithDecimals = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const LoansOffers = (props) => {
  const history = useLocation();
  const classesStyles = useStyles();
  const changePreapproval = (preapproval) => {
    const { savePreapprovalSelected, closeModal } = props;
    savePreapprovalSelected(preapproval);
    closeModal();
  };

  const orderPreapprovals = (
    preapprovals,
    maxAmount,
    currentAmount,
    nonSelectedMaxAmount,
    nonSelectedMinAmount,
    showCashAdvance,
    loansFormVersion,
  ) => {
    const pathname = history.pathname.toUpperCase();
    let orderedPreapprovals = [];

    preapprovals
      .sort((a, b) => b.amount - a.amount)
      .forEach((itemAmount, index) => {
        let AmountCard = (
          <Amount
            className={
              parseFloat(itemAmount.amount) === maxAmount ? 'recommended' : ''
            }
            key={`itemAmount_${index}`}
            id={`offer_${index}`}
          >
            <AmountContent>
              <AmountTitle>{text.amountTitles[index]}</AmountTitle>
              <br />
              <AmountSubtitle>{text.amountSubtitles[index]}</AmountSubtitle>
              <AmountHR />
              <AmountDescription>
                <DescriptionText>
                  <Description>{text.maxLimit}</Description>
                  <Description>{text.total}</Description>
                  <Description>{text.refund}</Description>
                </DescriptionText>
                <ValuesText>
                  <Values>
                    {itemAmount.term_maximum} {text.months}
                  </Values>
                  <Values>{toCurrencyFormat(itemAmount.payback)}</Values>
                  <Values>
                    {decimalFormatWithDecimals.format(
                      itemAmount.retention_rate,
                    )}
                    % {text.dailySales}
                  </Values>
                </ValuesText>
              </AmountDescription>
            </AmountContent>
            <AmountFooter>
              <AmountContainer>
                <AmountReduced>$</AmountReduced>
                <AmountLoan>{`${decimalFormat.format(
                  itemAmount.amount,
                )}`}</AmountLoan>
              </AmountContainer>
              <ClevertapComponent
                renderComponent={<ButtonAmount />}
                events={[
                  {
                    callbackName: 'onClick',
                    event: EVENTS.LOANS.AMOUNT_MODIFIED(
                      pathname.includes('IDENTITY')
                        ? 'IDENTITY'
                        : pathname.includes('ADDRESS')
                        ? 'ADDRESS'
                        : pathname.includes('DEPOSIT')
                        ? 'DEPOSIT'
                        : 'HISTORY',
                    ),
                    payload: amountPayload(
                      itemAmount,
                      { showCashAdvance },
                      index,
                    ),
                  },
                ]}
                onClick={() => changePreapproval(itemAmount)}
                disabled={itemAmount.amount === currentAmount}
              >
                <span>
                  {itemAmount.amount === currentAmount
                    ? text.buttonSelected
                    : text.buttonAmmount}
                </span>
              </ClevertapComponent>
            </AmountFooter>
            <AmountRecommended
              className={
                parseFloat(itemAmount.amount) === maxAmount
                  ? 'recommendedLabel'
                  : ''
              }
            >
              <RecommendedLabel>{text.recommendedLabel}</RecommendedLabel>
            </AmountRecommended>
          </Amount>
        );

        switch (parseFloat(itemAmount.amount)) {
          case parseFloat(currentAmount):
            orderedPreapprovals[0] = AmountCard;
            break;
          case nonSelectedMinAmount:
            orderedPreapprovals[2] = AmountCard;
            break;
          case nonSelectedMaxAmount:
            orderedPreapprovals[1] = AmountCard;
            break;
          default:
            break;
        }
      });

    return orderedPreapprovals;
  };

  const newOffersApprovals = () => {
    return (
      <ClipGrid container spacing={3} className={classesStyles.containerMethods}>
        <ClipGrid item xs={12} sm={12} md={12} lg={12}>
          <ClipPaper
            className={classesStyles.card}
          >
            <ClipGrid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classesStyles.cardSlider}
            >
              <div
                className={classesStyles.slider}
              >
                <ClipGrid item xs={12} sm={12} md={12} lg={12} className={classesStyles.containerLabels}>
                  <ClipTypography
                    variant="h5"
                    fontWeight="500"
                    gutterBottom
                  >
                    {text.loanPersonalized(props?.userName)}
                  </ClipTypography>
                  <div className={classesStyles.firstContainer}>
                    <div className={classesStyles.textSlider}>
                      <div className={classesStyles.sliderAmountLabel}>
                        <ClipTypography
                          className={classesStyles.maxAmount}
                          variant="subtitle2"
                          fontWeight="regular"
                          gutterBottom
                        >
                          {text.mount}
                        </ClipTypography>
                      </div>
                      <div className={classesStyles.sliderMaxAmountLabel}>
                        <ClipTypography
                          className={classesStyles.maxAmount}
                          variant="subtitle2"
                          fontWeight="regular"
                          gutterBottom
                        >
                          {amountValue()}
                        </ClipTypography>
                      </div>
                    </div>
                    <div className={classesStyles.paddingSlider}>
                    <ClipSlider 
                      classes={{rail: classesStyles.sliderRailStyles, 
                        track: classesStyles.sliderTrackStyles, 
                        thumb: classesStyles.sliderThumbStyles, root: classesStyles.sliderRailStyles}}
                        onChangeCommitted={() => setChangeSlider(count => count + 1)}
                        min={0}
                        max={maxValue} 
                        marks={marks}
                        step={null} value={sliderValue} onChange={handleSliderChange} />
                    </div>
                    <div className={clsx(classesStyles.amountContainer, classesStyles.amountFirstContainer)}>
                      <ClipTypography
                        variant="subtitle2"
                        fontWeight="500"
                        gutterBottom
                      >
                        {text.terms}
                      </ClipTypography>
                      <ClipTypography
                        variant="subtitle2"
                        fontWeight="regular"
                        gutterBottom
                      >
                        {preapprovalSelected?.term_maximum}
                      </ClipTypography>
                    </div>
                  </div>
                </ClipGrid>
              </div>
            </ClipGrid>
            <ClipGrid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classesStyles.cardPay}
            >
              <div className={classesStyles.titleLabelContainer}>
                <ClipTypography
                  className={classesStyles.subtitleSecondContainer}
                  variant="subtitle1"
                  fontWeight="500"
                  gutterBottom
                >
                  {text.totalAmount}
                </ClipTypography>
                <ClipTypography
                  variant="h1"
                  component="p"
                  color="primary"
                  fontWeight="500"
                  gutterBottom
                  classes={{h1: classesStyles.titleStyle}}
                >
                  {amountValue()}
                </ClipTypography>
              </div>
              <ClipTypography
                variant="subtitle2"
                gutterBottom
                classes={{subtitle2: classesStyles.cardSubtitle}}
              >
                {text.loanDetails}
              </ClipTypography>
              <ClipGrid item xs={12} sm={12} md={12}>
                <div className={classesStyles.amountContainer}>
                  <ClipTypography
                    variant="body2"
                    fontWeight="regular"
                    gutterBottom
                  >
                    {text.total}
                  </ClipTypography>
                  <ClipTypography
                    variant="body2"
                    fontWeight="regular"
                    gutterBottom
                  >
                    {totalAmount}
                  </ClipTypography>
                </div>
              </ClipGrid>
              <ClipGrid item xs={12} sm={12} md={12}>
                <div className={classesStyles.amountContainer}>
                  <ClipTypography
                    variant="body2"
                    fontWeight="regular"
                    gutterBottom
                  >
                    {monthlyCapCheck ? text.fixedPaymentMcap : text.monthlySales}
                  </ClipTypography>
                  <ClipTypography
                    variant="body2"
                    fontWeight="regular"
                    gutterBottom
                  >
                    {monthlyCapCheck ? monthlyPayment : `${retentionRate}%`}
                  </ClipTypography>
                </div>
              </ClipGrid>
              {monthlyCapCheck && (
                <ClipGrid item xs={12} sm={12} md={12}>
                  <div className={classesStyles.amountContainer}>
                    <ClipTypography
                      variant="caption"
                      fontWeight="regular"
                      color="textSecondary"
                      gutterBottom
                    >
                      {text.fixedPaymentDetailsMcap(retentionRate)}
                    </ClipTypography>
                  </div>
                </ClipGrid>
              )}
              <ClevertapComponent
                renderComponent={<ClipButton />}
                className={classesStyles.buttonMargin}
                variant="contained"
                color="primary"
                events={[
                  {
                    callbackName: 'onClick',
                    event: EVENTS.LOANS.OFFER_SELECTED,
                    payload: lenderPayload(
                      preapprovalSelected,
                      { showCashAdvance, loansFormVersion },
                      { amount: amountValue(), type: monthlyCapCheck ? 'MCAP' : 'regular v2' },
                    ),
                  },
                ]}
                onClick={beginRequest}
              >
                <span>{text.buttonAmmount}</span>
              </ClevertapComponent>
            </ClipGrid>
          </ClipPaper>
        </ClipGrid>
      </ClipGrid>
    )
  }

  const { preapprovals, currentAmount, showCashAdvance, loansFormVersion, loansRegularOffers } =
    props;
  const expirationDate = moment(
    preapprovals[0].expiration_date_local,
    'YYYY-MM-DD',
  ).format('L');
  const minAmount = Math.min.apply(
    Math,
    preapprovals.map((preapproval) => parseFloat(preapproval.amount)),
  );
  const maxAmount = Math.max.apply(
    Math,
    preapprovals.map((preapproval) => parseFloat(preapproval.amount)),
  );
  const nonSelectedAmounts = preapprovals.filter(
    (preapproval) => preapproval.amount !== currentAmount,
  );
  const nonSelectedMaxAmount = Math.max.apply(
    Math,
    nonSelectedAmounts.map((preapproval) => parseFloat(preapproval.amount)),
  );
  const nonSelectedMinAmount = Math.min.apply(
    Math,
    nonSelectedAmounts.map((preapproval) => parseFloat(preapproval.amount)),
  );

  const reOrderedPreapprovals = orderPreapprovals(
    preapprovals,
    maxAmount,
    currentAmount,
    nonSelectedMaxAmount,
    nonSelectedMinAmount,
    showCashAdvance,
    loansFormVersion,
  );

  preapprovals.sort((a,b) => Number(a.amount) - Number(b.amount));

  const monthlyCapCheck = preapprovals.some(preapproval => preapproval.installment_type === 'FIXED');
  const maxValue = (preapprovals.length) * 100;
  
  const marks = preapprovals.map((preapproval, index) => {
    return { value: (index + 1) * 100 }
  })

  const [sliderValue, setSliderValue] = useState(maxValue);
  const [changeSlider, setChangeSlider] = useState(0);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const preapprovalSelected = preapprovals[(sliderValue/100)-1];

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const amountValue = () => {
    const index = sliderValue/100;

    return formatter.format(preapprovals[index-1]?.amount);
  }

  const monthlyPayment = formatter.format(preapprovalSelected?.installment?.breakdown?.[0]?.amount);
  const totalAmount = formatter.format(preapprovalSelected?.payback);
  const formatedMin = formatter.format(minAmount);
  const formatedMax = formatter.format(maxAmount);
  const retentionRate = Number(preapprovalSelected?.retention_rate);

  const beginRequest = () => {
    changePreapproval(preapprovalSelected);
  };

  return (
    <Container>
      <ExpireWarning>
        {!loansRegularOffers && (
          <>
            <HourglassTop src={HourglassTopIcon} />
            <ExpireWarningContent
              dangerouslySetInnerHTML={{
                __html: text.expiration(expirationDate),
              }}
            />
          </>
        )}
        
        {loansRegularOffers && (
          <>
            <ClockTime className={classesStyles.clockTimeStyles} />
            <div className={classesStyles.expireWarningContentStyles}>
              <ReactMarkdown
                source={`${text.expirationOffer(expirationDate)}`}
              />
            </div>
          </>
        )}
      </ExpireWarning>

      <AmountsContainer>
        {loansRegularOffers ? newOffersApprovals() : 
          reOrderedPreapprovals.map((AmountCard) => AmountCard)
        }
      </AmountsContainer>
    </Container>
  );
};
LoansOffers.propTypes = {
  savePreapprovalSelected: PropTypes.func,
  closeModal: PropTypes.func,
  preapprovals: PropTypes.array.isRequired,
  currentAmount: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  loansFormVersion: state.loans.loansFormVersion,
  showCashAdvance: state.loans.showCashAdvance,
  loansRegularOffers: state.loans.loansRegularOffers,
  userName: getUserName(state),
});

const mapDispatchToProps = {
  savePreapprovalSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoansOffers);
