const styles = (theme) => ({
  root: {
    backgroundColor: '#fff',
    textAlign: 'center',
    width: '100%',
    display: 'grid',
    height: '100vh',
    maxHeight: '-webkit-fill-available',
    gridTemplateRows: '80% 1fr',
    justifyContent: 'center',
    alignContent: 'center',
  },
  keypadContainer: {
    backgroundColor: '#fff',
    textAlign: 'center',
    width: '100%',
    display: 'grid',
    maxHeight: '-webkit-fill-available',
    gridTemplateRows: '1fr 1fr',
    justifyContent: 'center',
    alignContent: 'center',
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  logo: {
    maxHeight: '64px',
    margin: '16px 0',
  },
  statusMesssage: {
    textAlign: 'center',
    height: '38px',
    display: 'grid',
    alignItems: 'end',
  },
  keypad: {
    width: '78vw',
    height: '40vh',
    position: 'relative',
    float: 'left',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dotsContainer: {
    textAlign: 'center',
    margin: '8px 0',
  },
  titlePassword: {
    fontSize: theme.spacing.unit * 2.25,
    padding: 0,
    textAlign: 'left',
    marginTop: 0,
    paddingTop: theme.spacing.unit * 1.5,
  },
  formContainer: {
    background: 'white',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing.unit}px ${theme.spacing.unit}px`,
    margin: `${theme.spacing.unit * 7}px ${theme.spacing.unit}px ${
      theme.spacing.unit
    }px`,
    borderRadius: '24px',
    boxSizing: 'border-box',
  },
  continueButton: {
    position: 'fixed',
    bottom: '1.5rem',
    width: `calc(100vw - ${theme.spacing.unit * 2}px)`,
    left: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
  },
  infoContinueButton: {
    position: 'fixed',
    bottom: '1.5rem',
    width: `calc(100vw - ${theme.spacing.unit * 2}px)`,
    left: theme.spacing.unit,
    backgroundColor: '#0B99AC',
  },
  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate3d(-1px, 0, 0)',
    },

    '20%, 80%': {
      transform: 'translate3d(2px, 0, 0)',
    },

    '30%, 50%, 70%': {
      transform: 'translate3d(-4px, 0, 0)',
    },

    '40%, 60%': {
      transform: 'translate3d(4px, 0, 0)',
    },
  },

  shakeAnimation: {
    animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
    transform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000px',
  },
  infoImg: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 3,
    justifySelf: 'center',
  },
  checkReadyImg: {
    marginTop: '15%',
    marginBottom: theme.spacing.unit,
    justifySelf: 'center',
  },
  infoTitle: {
    marginTop: '0',
    fontSize: '18px',
    height: '1.44rem',
  },
  infoText: {
    fontSize: '16px',
    textAlign: 'center',
    paddingLeft: theme.spacing.unit * 1.5,
    paddingRight: theme.spacing.unit * 1.5,
  },
  clearButton: {
    background: 'transparent',
    color: theme.palette.naranja.bloodOrange,
    boxShadow: 'none',
    fontWeight: 'normal',
    marginBottom: theme.spacing.unit / 2,
    '&:hover': {
      background: 'transparent',
      color: theme.palette.naranja.bloodOrange,
      boxShadow: 'none',
      fontWeight: 'normal',
      marginBottom: theme.spacing.unit / 2,
    },
  },
  swapLogo: {
    height: '16px',
    marginLeft: '4px',
    marginTop: '22px',
  },
  bottomContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    overflow: 'auto',
    width: '100%',
    justifyContent: 'center',
    marginTop: '20px',
  },
  splashLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
  },
  splashText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px',
    flexDirection: 'row',
  },
  splashTextProvider:{
    marginLeft: '5px',
    color: '#2257C8',
    fontWeight: 'bold',
  },
});
export default styles;
