const styles = (theme) => {
  return {
    root: {
      textAlign: 'center',
      margin: '16px',
    },
    title: {
      fontWeight: 'bold',
      marginTop: '48px',
      marginBottom: '36px',
    },
    LandingImage: {
      width: '19.5rem',
      height: '9.625rem',
      objectFit: 'contain',
      marginBottom: '2rem',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    messsage: {
      marginBottom: '19px',
    },
  };
};
export default styles;
