import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import urls from '../../../../helpers/urls';
import getUrl from '../../../../helpers/getUrl';
import FormButton from '../../../../helpers/FormUtils/FormButton';
import InterestedImage from '../../../../assets/images/prestamax.svg';
import {
  LoansTabs,
  LoanTypes,
  PreapprovalStatus,
} from '../../../../constants/AppConstants';
import { getPreapprovals, isLoansFormSent } from '../../redux/selectors';
import GoogleAnalytics from '../../GoogleAnalytics';
import LoansRedirection from '../../Commons/Redirection';
import { ExternalLenderFooter } from '../../Commons/Footer';
import { styles } from '../styles';
import text from '../text';

export const Approved = props => {
  let history = useHistory();
  let location = useLocation();
  const { classes, isFormSent, preapprovals } = props;
  const typographyStyles = {
    body1: classes.typographyBody6,
    title: classes.typographyTitle2,
    subheading: classes.typographySubTitle,
    display1: classes.typographyBody5,
  };

  const accepted = preapprovals.filter(
    ({ loan_id, status, type }) =>
      [PreapprovalStatus.ACCEPTED].includes(status) &&
      !loan_id &&
      type === LoanTypes.OPEN_DATA,
  );
  const isMobileWebView = location.pathname.startsWith(urls.mobile);

  return (
    <LoansRedirection tab={LoansTabs.NEW_OFFER}>
      {isFormSent && accepted.length > 0 && (
        <GoogleAnalytics>
          <Grid item sm={12} xs={12} className={classes.gridCard}>
            <Typography variant={'title'} classes={typographyStyles}>
              {text.titleApproved}
            </Typography>
            <img
              className={classes.bannerApproved}
              alt="PrestaClip Interested"
              src={InterestedImage}
            />
            <Typography variant={'body1'} classes={typographyStyles}>
              {text.approvedSuccessful}
            </Typography>
            <Typography variant={'display1'} classes={typographyStyles}>
              {text.approvedMessage}
            </Typography>
            {!isMobileWebView && (
              <div className={classes.containerApproved}>
                <FormButton
                  variant="contained"
                  text={text.understand}
                  onClick={() => history.push(getUrl(urls.dashboard))}
                />
              </div>
            )}
            <ExternalLenderFooter />
          </Grid>
        </GoogleAnalytics>
      )}
    </LoansRedirection>
  );
};

Approved.propTypes = {
  classes: PropTypes.object.isRequired,
  preapprovals: PropTypes.array.isRequired,
  isFormSent: PropTypes.bool.isRequired,
};
Approved.defaultProps = {
  classes: {},
  preapprovals: [],
  isFormSent: false,
};

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
    isFormSent: isLoansFormSent(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Approved));
