import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getPreapprovals } from 'components/loans/redux/selectors';
import LoansRedirection from 'components/loans/Commons/Redirection';
import { ExternalLenderFooter } from 'components/loans/Commons/Footer';
import { LoansTabs, PreapprovalStatus } from 'constants/AppConstants';
import getUrl from 'helpers/getUrl';
import urls from 'helpers/urls';
import GoogleAnalytics from 'components/loans/GoogleAnalytics';

import FormButton from 'helpers/FormUtils/FormButton';
import CongratsTopImage from 'assets/images/prestamax.svg';
import { styles } from './styled';
import text from '../text';

export const Signed = props => {
  let history = useHistory();
  let location = useLocation();

  const { classes, preapprovals = [] } = props;

  const typographyStyles = {
    root: classes.typographyRoot,
    headline: classes.typographyHeadline,
    body1: classes.typographyBody1,
    body2: classes.typographyBody2,
  };

  const isMobileWebView = location.pathname.startsWith(urls.mobile);

  const goDashboard = () => { 
    history.push(getUrl(urls.dashboard)); 
  };

  const getSigned = () =>
    preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.ACCEPTED, PreapprovalStatus.SIGNED].includes(
          preapproval.status,
        ) && !preapproval.loan_id,
    );

  return (
    <LoansRedirection tab={LoansTabs.NEW_OFFER}>
      {getSigned().length > 0 && (
        <GoogleAnalytics>
          <Typography variant={'headline'} classes={typographyStyles}>
            {text.titleCongrats}
          </Typography>
          <img
            className={classes.banner}
            src={CongratsTopImage}
            alt={text.banner}
          />
          <Typography variant={'body1'} classes={typographyStyles}>
            {text.paragraph1}
          </Typography>
          <Typography variant={'body2'} classes={typographyStyles}>
            {text.paragraph2} <b>{text.paragraph3}</b>
          </Typography>
          {!isMobileWebView && (
            <div className={classes.buttonContainer}>
              <FormButton
                variant="contained"
                text={text.understand}
                onClick={goDashboard}
              />
            </div>
          )}
          <ExternalLenderFooter />
        </GoogleAnalytics>
      )}
    </LoansRedirection>
  );
};

Signed.propTypes = {
  classes: PropTypes.object.isRequired,
  preapprovals: PropTypes.array,
};
Signed.defaultProps = {
  preapprovals: [],
};

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Signed));
