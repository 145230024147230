const styles = (theme) => {
    return {
      noGeoContainer: {
        textAlign: 'center',
        padding: '0 ' + theme.spacing(2) + 'px',
        paddingTop: theme.spacing(7.2),
        backgroundColor: 'white',
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      geoTitle: {
        fontSize: theme.spacing(2.25),
        marginTop: theme.spacing(3),
      },
    };
  };
  export default styles;
  