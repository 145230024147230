import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import ClipButton from '@clipmx/clip-storybook/dist/components/Button';
import ClipTypography from '@clipmx/clip-storybook/dist/components/Typography';
import { DialogContext } from '@clipmx/clip-storybook/dist/components/Dialog/dialogContext';

const useStyles = makeStyles(() => {
  return {
    dialogMessage: {
      margin: '12px 8px',
    },
    dialogButtons: {
      textAlign: 'right',
      '& button:last-child': {
        marginLeft: '12px',
      },
    },
  };
});

export const isFunction = (functionToCheck) => {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
};

export const ActionButtons = (props) => {
  const { buttons, dialogName = 'publishDialog' } = props;
  const { dialogActions } = useContext(DialogContext);
  return buttons.map((b, i) => {
    if (b.caption) {
      return (
        <ClipButton
          key={b.caption}
          className={b.className}
          variant={b.variant || 'contained'}
          color={b.color || 'secondary'}
          style={{ marginRight: i + 1 < buttons.length ? 24 : 0 }}
          onClick={(e) => {
            e.preventDefault();
            if (isFunction(b.action)) b.action();
            dialogActions.closeDialog(dialogName);
          }}
        >
          {b.caption}
        </ClipButton>
      );
    }
    return <div key={b}>b</div>;
  });
};

ActionButtons.propTypes = {
  buttons: PropTypes.array.isRequired,
};

const ItemDialog = (props) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <ClipTypography
      className={classes.dialogMessage}
      component="div"
      variant="body1"
      gutterBottom
    >
      {children}
    </ClipTypography>
  );
};

ItemDialog.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ItemDialog;
