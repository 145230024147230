import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

export const useStyles = makeStyles((theme) => {
  return {
    meetCardModal: {   
      width: '100%',
      height: '300px',
    },
    clasModal: {
      borderRadius: '20px 20px 0 0',
      borderLeft: '5px solid rgba(0, 0, 0, 0.5)',
      borderRight: '5px solid rgba(0, 0, 0, 0.5)',
    },
    
    cardModalGrid: {
        float: 'left',
        marginLeft: '15px',
        width: '400px',
        maxWidth: '600px',
        borderRadius: '10px',
        background: '#FFFFFF',
      },
    cardgris: {
      background: '#FAFAFA',
      marginTop: '20px',
      height: '60px',
    },
    fontHelp: { 
        padding: '10px',
        textAlign: 'center',
        fontFamily: 'Barlow',
        fontSize: '13px',
        fontWeight: '300',        
    },
    fontNeedHelpMobil: {
      marginTop: '20px',
      fontFamily: 'Barlow',
      fontSize: '14px',
      fontWeight: '600',
      textAlign: 'center',
    },


    textFont: {
      fontSize: '28px',
    },
    fontNeedHelp: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
      fontFamily: 'Barlow',
      fontSize: '14px',
      fontWeight: '600',
    },
    buttonHelpContainer: {
      marginBottom: '-36px',
    },
    helpButtonStyles: {
      color: theme.palette.teal.l10,
      marginLeft: '30%',
      paddingBottom: '5px',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    helpHeadsetStyles: {
      fontSize: '20px',
      position: 'relative',
      top: '3px',
      marginLeft: '8px',
      color: '#1A7E8C',
    },


    icons: {
      display: 'grid',
      '& > img': {
        margin: 'auto',
        marginBottom: '8px',
        marginTop: '32px',
      },
    },
    borderTest: {
      border: '1px solid',
    },
    colorBlue: {
      display: 'contents',
      color: '#0074B7',
      fontWeight: '500',      
      textDecoration: 'none',
    },

    customerInfo: {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Barlow',
        fontSize: '12px',
        fontWeight: '400',        
      },

      customerInfoMobil: {
        fontFamily: 'Barlow',
        fontSize: '12px',
        fontWeight: '400',
        textAlign: 'center',     
      },

    buttonRigth: {
      marginRigth: '5px',
    },
    iconButtonCa: {
      marginTop:'-25px',
      position: 'absolute',
      right: 0,
    },
    closeIcon: {
      color: theme.palette.indigo.base,
    },
    buttonHelp: {
      marginTop: '18px',
      marginLeft: '38px',
      display: 'flex', 
      width: '85%', 
      fontFamily: 'Barlow',
      fontSize: '16px',
      fontWeight: '500',
    },
    copiaCardgris: {
      background: '#FAFAFA',
      marginTop: '20px',
      marginRight: '1px',

      width: '500px',
      height: '60px',
    },
  };
});

export default useStyles;
