import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import text from './text';
import useStyles from './styles';
import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import ClipGrid from '@clipmx/clip-storybook/dist/components/Grid';
import { ClipTypography } from '@clipmx/clip-storybook';
import LoansRedirection from 'components/loans/Commons/Redirection';
import Money from '@clipmx/clip-storybook/dist/components/Icons/Money';
import Calendar from '@clipmx/clip-storybook/dist/components/Icons/Calendar';
import Done from '@clipmx/clip-storybook/dist/components/Icons/Done';
import { LoansTabs } from 'constants/AppConstants';
import ClipPresta from '@clipmx/clip-storybook/dist/components/Illustrations/ClipPresta/ClipPresta';
import LoansLogoBanner from '../../../assets/images/logo-loans.svg';
import ClipWallet from '@clipmx/clip-storybook/dist/components/Illustrations/ClipWallet/ClipWallet';
import HelpNeed from './helpneed';
import MyInterested from './iminterested';
import { connect } from 'react-redux';
import {
  getLoans as loadLoans,
  getPreapprovals as loadPreapprovals,
  savePreapprovalSelected,
} from 'components/loans/redux/actions';
import {
  getLoans,
  getLoansLoaded,
  getPreapprovals,
  getUserName,
  preapprovalsLoaded,
} from 'components/loans/redux/selectors';
import { Clevertap } from 'helpers/Clevertap';

const Refill = ({ 
  merchantName, 
  preapprovals, 
  loans,
  user,
  merchant,
  merchantInfo,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (merchantInfo.proxy_id && user) {
      Clevertap.profile(user);
    }
  }, [user, merchant]);

  return (
    <>
      <LoansRedirection tab={LoansTabs.NEW_OFFER}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <div className={classes.TopBanner}>
              <img
                src={LoansLogoBanner}
                alt={text.title}
                className={classes.TopBannerImage}
              />
            </div>
            <div className={classes.TopImage}>
              <ClipWallet/>
            </div>
            <ClipTypography
              className={classes.textFont}
              fontWeight="regular"
              align="center"
              gutterBottom
            >
              {text.hello(merchantName)}
            </ClipTypography>
            <ClipTypography
              className={classes.fontW100}
              variant="subtitle1"
              gutterBottom
            >
              {text.goodHistory}
            </ClipTypography>
            <ClipGrid container spacing={3}>
              <ClipGrid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                className={classes.icons}
              >
                <Money className={classes.circle} />
                <ClipTypography
                  align="center"
                  variant="body2"
                  fontWeight="regular"
                  gutterBottom
                >
                  {text.beneOne}
                </ClipTypography>
              </ClipGrid>
              <ClipGrid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                className={classes.icons}
              >
                <Calendar className={classes.circle} />
                <ClipTypography
                  align="center"
                  variant="body2"
                  fontWeight="regular"
                  gutterBottom
                >
                  {text.beneTwo}
                </ClipTypography>
              </ClipGrid>
              <ClipGrid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                className={classes.icons}
              >
                <Done className={classes.circleDone} />
                <ClipTypography
                  align="center"
                  variant="body2"
                  fontWeight="regular"
                  gutterBottom
                >
                  {text.beneThree}
                </ClipTypography>
              </ClipGrid>
            </ClipGrid>
            <MyInterested
              preapprovals={preapprovals}
              loans={loans}
              action={savePreapprovalSelected}
            />
            <HelpNeed />
          </Grid>
        </Grid>
      </LoansRedirection>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loans: getLoans(state),
    loansLoaded: getLoansLoaded(state),
    preapprovals: getPreapprovals(state),
    preapprovalsLoaded: preapprovalsLoaded(state),
    merchantName: getUserName(state),
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
    user: state.user,
    merchant: state.merchant,
    merchantInfo: state.merchant.info,
  };
};

export default withRouter(connect(mapStateToProps, {
  loadLoans,
  loadPreapprovals,
  savePreapprovalSelected,
})(Refill));
