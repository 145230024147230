export const CLEAR_ONBOARDING_STATE = 'CLEAR_ONBOARDING_STATE';
export const SET_ONBOARDING_ACTIVE_STATE = 'SET_ONBOARDING_ACTIVE_STATE';
export const SET_ONBOARDING_STATE = 'SET_ONBOARDING_STATE';
export const SUBMIT_BANK_INFORMATION_FULFILLED =
  'SUBMIT_BANK_INFORMATION_FULFILLED';
export const SUBMIT_BANK_INFORMATION_ERROR = 'SUBMIT_BANK_INFORMATION_ERROR';
export const GET_BANK_ACCOUNT_FULFILLED = 'GET_BANK_ACCOUNT_FULFILLED';
export const GET_BANK_ACCOUNT_ERROR = 'GET_BANK_ACCOUNT_ERROR';
export const UPDATE_BUSINESS_INFO = 'UPDATE_BUSINESS_INFO';
export const SET_BUSINESS_INFO_ERROR = 'SET_BUSINESS_INFO_ERROR';
export const GET_ONBOARDING_BUSINESS_INFO = 'GET_ONBOARDING_BUSINESS_INFO'; // nobody uses it
export const CREATE_COLLABORATOR_FULFILLED = 'CREATE_COLLABORATOR_FULFILLED';
export const CREATE_COLLABORATOR_ERROR = 'CREATE_COLLABORATOR_ERROR';
export const GET_COLLABORATORS_FULFILLED = 'GET_COLLABORATORS_FULFILLED';
export const GET_COLLABORATORS_ERROR = 'GET_COLLABORATORS_ERROR'; // No reducer is using it
export const DELETE_COLLABORATOR_FULFILLED = 'DELETE_COLLABORATOR_FULFILLED';
export const UPDATE_COLLABORATOR_SUCCESS = 'UPDATE_COLLABORATOR_SUCCESS';
export const UPDATE_INSTALLMENTS = 'UPDATE_INSTALLMENTS';
export const SET_INSTALLMENTS_ERROR = 'SET_INSTALLMENTS_ERROR';
export const SET_BUSINESS_INFO_LOCATION = 'SET_BUSINESS_INFO_LOCATION';
export const SET_BUSINESS_INFO_INDUSTRIES = 'SET_BUSINESS_INFO_INDUSTRIES';

export const SET_DASHBOARD_STATE = 'SET_DASHBOARD_STATE';
