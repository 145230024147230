import React from 'react';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { ClipInput, ClipTypography } from '@clipmx/clip-storybook';
import styles from './styles';

const useStyles = makeStyles(styles);

const RenderClipInput = ({
  input,
  onActiveMessage,
  containerMargin,
  meta,
  ...rest
}) => {
  const classes = useStyles();
  const { error, touched, active, ...metaRest } = meta;
  return (
    <div className={containerMargin && classes.container}>
      <ClipInput
        InputLabelProps={{ style: { zIndex: 13 } }}
        error={touched && error !== undefined}
        errormessage={error}
        {...input}
        {...metaRest}
        {...rest}
      />
      {active && onActiveMessage && (
        <ClipTypography
          classes={{ root: classes.helperText }}
          variant={'body2'}
          component={'p'}
        >
          {onActiveMessage}
        </ClipTypography>
      )}
    </div>
  );
};

export default RenderClipInput;
