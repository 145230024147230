/* eslint-disable  max-len */
export default {
  letsTake: 'Necesitamos tu  identificación oficial vigente',
  thisHelps: 'Esto nos permite acreditar tu identidad.',
  ineButton: 'Credencial de elector (INE)',
  passportButton: 'Pasaporte mexicano',
  takeFrontIne: 'Parte frontal de la credencial',
  takeBackIne: 'Reverso de la credencial',
  takePassportFront: 'Parte frontal del pasaporte',
  makeSure:
    'Busca un espacio iluminado para que todos tus datos se vean con claridad.',
  understood: 'Tomar foto',
  ready: 'Siguiente',
  continue: 'Continuar',
  retry: 'Reintentar',
  verifyIneFront: 'Parte frontal de la credencial',
  verifyIneBack: 'Reverso de la credencial',
  verifyPassportFront: 'Parte frontal del pasaporte',
  info1: 'Todos los datos tienen que estar visibles.',
  info1_back: 'Tu firma tiene que estar visible.',
  info2: 'Las 4 esquinas tienen que salir en la foto.',
  info2Passport: 'Las 4 esquinas de tu pasaporte son visibles.',
  info3: 'La imagen no debe estar borrosa ni movida.',
  continueText1: 'Al continuar acuerdas con los ',
  termsAndConditions: 'Términos y Condiciones',
  continueText2: ' y el ',
  privacyPolicy: 'Aviso de privacidad',
  continueText3: ' de Clip Card',
  accept: 'Aceptar',
  createAccount: 'Crear cuenta',
  termsAndConditions1: `Por medio del presente documento, CACAO PAYCARD SOLUTIONS, S.A. DE C.V., INSTITUCIÓN DE FONDOS DE PAGO ELECTRÓNICO (en adelante “CACAO”), manifiesta que es una sociedad mercantil constituida en los Estados Unidos Mexicanos con domicilio social en Boulevard Manuel Ávila Camacho 118, oficina 1103, piso 11, Colonia Lomas de Chapultepec, Alcaldía Miguel Hidalgo, Código Postal 11000, Ciudad de México, México, y pone a disposición del USUARIO los siguientes Términos y Condiciones Generales de Uso que tienen carácter obligatorio y vinculante (en adelante “TCG”) que regulan el acceso y uso de las aplicaciones informáticas, interfaces, página de Internet www.cacaopaycard.com o cualquier otro medio de comunicación electrónica o digital que CACAO utiliza para operar con sus usuarios (en lo sucesivo la “PLATAFORMA CACAO”).

    1. MANIFESTACIONES.
    Usted, (en lo sucesivo el “USUARIO”) manifiesta ser una persona física mayor de 18 años, con capacidad para obligarse en términos de los presentes TCG; así como manifestar expresamente que actúa por cuenta propia y conoce que CACAO se encuentra regulada conforme lo establecido en la Ley para Regular las Instituciones de Tecnología Financiera, las Disposiciones de carácter general aplicables a las Instituciones de tecnología Financiera y demás normativa aplicable vigente (en lo sucesivo “Ley Fintech”).
    Los TCG se encuentran a su disposición y se muestra la última fecha de su actualización con el objetivo de hacer de su conocimiento cualquier modificación.
    Al hacer uso de la PLATAFORMA CACAO, el USUARIO manifiesta que (i) conoce este documento, así como que se encuentra de acuerdo con su contenido y acepta sujetarse a las disposiciones de éste, al seguir usando los productos y servicios de CACAO y al expresamente continuar con el registro en la PLATAFORMA CACAO, lo que se considera como una aceptación expresa; (ii) garantiza, bajo protesta de decir verdad, ser mayor de edad, residente mexicano, tener domicilio u establecimiento en los Estados Unidos Mexicanos y (iii) declara a través de la aceptación de los TCG que se encuentra actuando por cuenta propia, en términos del artículo 11 de las Disposiciones de Carácter General a que se refiere el artículo 58 de la Ley para Regular las Instituciones de Tecnología Financiera.
    Adicional a la aceptación de los TCG, el USUARIO, que desee hacer uso de los productos o servicios de CACAO, deberá formalizar con CACAO un contrato de emisión y depósito mercantil de fondos de pago electrónico (en adelante “el Contrato”), el cual se pone a disposición del USUARIO de manera electrónica a través de la PLATAFORMA CACAO.
    El USUARIO acuerda leer los TCG y manifestar su aceptación sujeto en todo momento al cumplimiento de las Disposiciones de carácter general de la CONDUSEF en materia de transparencia y sanas prácticas aplicables a las instituciones de tecnología financiera, en adelante (“Disposiciones CONDUSEF”).
    Cualquier USUARIO que no acepte en forma absoluta y completa los TCG aquí consignados, deberá abstenerse de acceder, utilizar y observar el Sitio Web y la PLATAFORMA CACAO.


    2. DEFINICIONES
    a) Cuenta: A la cuenta de fondos de pago electrónico que CACAO abra a nombre del USUARIO.
    b) Día Hábil Bancario: Significa a los días en que las instituciones de crédito no estén obligadas a cerrar sus puertas ni a suspender Operaciones, en términos de las disposiciones de carácter general que, para tal efecto, emita la Comisión Nacional Bancaria y de Valores.
    c) FPE: A los Fondos de Pago Electrónico, que se emiten por CACAO contra la recepción de una cantidad de dinero.
    d) Ley Fintech: A la Ley para Regular las Instituciones de Tecnología Financiera.
    e)PlataformaCACAO:Entiéndase de manera indistinta o de manera conjunta la Aplicación y el Sitio Web.
    f) Sitio Web: Sitio Web propiedad de CACAO cuya dirección es:
    www.cacaopaycard.com.
    g) Tarjeta CACAO: Se refiere al producto ofrecido por CACAO, consistente en
    un medio de disposición respecto de los FPE de la Cuenta del USUARIO.
    h) TCG: Al presente documento, denominado Términos y Condiciones
    Generales del Uso de la PLATAFORMA PAYCARD.
    i) Transferencias: A aquella operación realizada entre CACAO y otra
    institución de fondos de pago electrónico o Entidad Financiera, conforme al cual CACAO realiza el abono o una orden en una Cuenta, derivada del cargo que esa otra institución de fondos de pago electrónico o entidad haga en la cuenta correspondiente, equivalente a aquella cantidad de dinero que el USUARIO haya indicado.
    j) Transferencias de FPE: A aquella operación realizada por CACAO de conformidad con los contratos celebrados con sus USUARIOS para la apertura de Cuentas, de acuerdo con la cual dicha institución abona una cantidad determinada de fondos de pago electrónico en una de dichas Cuentas, derivado del cargo por la referida cantidad en alguna otra de esas Cuentas.
    k) Usuario: La persona física que hace uso de los servicios que se prestan en la PLATAFORMA CACAO.

    3. ACEPTACIÓN.
    Al ingresar a la PLATAFORMA CACAO, el USUARIO reconoce que CACAO le está otorgando una licencia de uso temporal de la PLATAFORMA CACAO, limitada, no

    Perif. Blvd. Manuel Ávila Camacho 118, Lomas de Chapultepec, Miguel Hidalgo, 11000, CDMX
     exclusiva, no sublicenciable, revocable y no transferible, para la realización de operaciones y el uso de la Tarjeta CACAO.
    El USUARIO, respecto de la PLATAFORMA CACAO, está de acuerdo que su uso se realiza bajo su propia responsabilidad, con relación a los servicios y/o productos que ahí se prestan, y en específico respecto de la Tarjeta CACAO y que conoce los posibles riesgos tecnológicos, cibernéticos y de fraude en los productos y servicios financieros comercializados por CACAO, tales como el envío de notificaciones de operaciones inusuales vía telefónica, mensajes de texto SMS o correo electrónico, entre otros, por lo que para tales efectos CACAO, asume la responsabilidad de las consecuencias que pudieran llegar a presentarse por los referidos incidentes, a fin de garantizar y velar por los derechos de los USUARIOS.
    La PLATAFORMA CACAO, incluyendo sin limitación alguna, todo el contenido, como son de forma enunciativa más no limitativa, textos, fotografías, ilustraciones, gráficos, videos, audio, diseños, códigos, datos y cualquier otra información son propiedad única y exclusiva de CACAO, sin condición a, cualesquiera de los derechos de autor, derechos de marca, derechos de patente, derechos de base de datos, derechos morales, y otras propiedades intelectuales y derechos patrimoniales del mismo, toda vez que se encuentran regulados y protegidos por la Ley Federal del Derecho de Autor, la Ley de la Propiedad Industrial, así como cualquier otro ordenamiento en materia de Propiedad Intelectual aplicable.
    Los signos distintivos, incluyendo las marcas y avisos comerciales, expuestos en los servicios y en la Tarjeta CACAO serán en todo momento propiedad de CACAO, aun cuando los mismos se encuentren registrados o no, y no pueden ser usados por el USUARIO sin consentimiento expreso y por escrito de CACAO.

    4. ACCESO A PLATAFORMA CACAO.
    El USUARIO reconoce, acepta y entiende que para hacer uso de la PLATAFORMA CACAO, deberá contar con un equipo de cómputo con las características operativas mínimas para la ejecución de la PLATAFORMA CACAO, debiendo ser conseguido y costeado por él mismo; en caso de contravenir esta disposición, el USUARIO será responsable de cualquier tipo de daño que pudiera sucederles, inclusive por virus trasmitidos por medios informáticos o vías de comunicación y, en general, por cualquier otro tipo de pérdida, menoscabo, destrucción o deterioro que pudiera sufrir el USUARIO en el uso de dicho equipo de cómputo.
    Los servicios ofrecidos en la PLATAFORMA CACAO y la Tarjeta CACAO sólo están disponibles para personas que tengan capacidad legal para contratar. Por lo tanto, no podrán utilizar los servicios las personas que no tengan esa característica o los menores de edad.

    5. REGISTRO Y DATOS PROPORCIONADOS
    Para poder acceder y hacer uso de los productos y servicios, el USUARIO deberá registrarse en la PLATAFORMA CACAO completando los formularios que CACAO
    Perif. Blvd. Manuel Ávila Camacho 118, Lomas de Chapultepec, Miguel Hidalgo, 11000, CDMX
     ponga a su disposición para tales efectos, con los datos personales solicitados, los cuales de manera enunciativa mas no limitativa podrán ser: (i) nombre completo o razón social, (ii) domicilio, -compuesto por calle, número exterior e interior, código postal, colonia, demarcación política, estado, país-, (iii) ocupación o actividad, (iv) correo electrónico, (v) contraseña, (vi) teléfono celular, (vii) perfiles en redes sociales y, (viii) geolocalización, entre otros.
    El USUARIO manifiesta, bajo protesta de decir verdad que, toda la información y documentación que ha proporcionado y/o proporcione con relación a cualquier actividad que se promueva en la PLATAFORMA CACAO, es verdadera, completa y correcta quedando, por ende, obligado a indemnizar y sacar en paz y a salvo a CACAO de cualquier daño, perjuicio, demanda y/o acción que dicha omisión o falsedad lo provoque.
    CACAO podrá utilizar diversos medios para identificar a sus USUARIOS, pero CACAO no se responsabiliza por la certeza de los Datos Personales provistos por sus USUARIOS. Asimismo, se reserva el derecho de solicitar algún comprobante y/o dato adicional y/o documentación a efectos de corroborar los Datos Personales recabados para el registro en la PLATAFORMA CACAO, mismos que serán tratados conforme al Aviso de Privacidad puesto a disposición del USUARIO.
    En caso de existir sospecha de falsedad de información, documentos o manifestaciones por los USUARIOS, CACAO podrá suspender temporal o definitivamente a aquellos USUARIOS cuyos datos no hayan podido ser confirmados. En este entendido, el USUARIO es el único responsable del resguardo de dichos factores de autenticación, contraseñas y claves de acceso al sistema, por lo que en este acto se comprometen a tomar las medidas de seguridad necesarias a fin de que no sean utilizadas por ningún tercero en contraposición a lo establecido en los presentes TCG. Está prohibido que un mismo USUARIO posea más de una cuenta de USUARIO en la PLATAFORMA CACAO. En caso de que CACAO detecte cuentas de USUARIO que contengan datos relacionados o coincidentes, podrá cancelaras o inhabilitarlas, realizando la debida notificación al USUARIO.
    El USUARIO autoriza a CACAO, directamente o a través de terceros, para hacer cualquier pregunta o investigación que considere necesaria para validar su identidad y/o autenticar su identidad e información para la utilización de la PLATAFORMA CACAO. Esto puede incluir, pero no está limitado a información y/o documentación acerca del uso de la PLATAFORMA CACAO o requerir que tome acciones para confirmar dirección de correo electrónico, número de teléfono celular/móvil, y la verificación de la información en bases de datos de terceros o por medio de otras fuentes. Este proceso es para fines de verificación interna.

    6. AUTENTICACIÓN Y RESPONSABILIDAD DE CONTRASEÑAS
    Para el acceso a la Cuenta del USUARIO, inmediato posterior al proceso de registro, CACAO le podrá proporcionar y validar una contraseña de acceso, factores de autenticación, claves de acceso y/o algún medio de identificación (en lo sucesivo
    Perif. Blvd. Manuel Ávila Camacho 118, Lomas de Chapultepec, Miguel Hidalgo, 11000, CDMX
     “Contraseña”), las cuales se considerarán un elemento personalísimo y habilitador para acceder a los servicios de la PLATAFORMA CACAO.
    Al segundo acceso a la PLATAFORMA CACAO, el USUARIO deberá generar una Contraseña que tendrá carácter personal e intransferible, por lo que El USUARIO se obliga a guardar la confidencialidad de su Contraseña de acceso, asumiendo en caso contrario, las consecuencias que pudieran derivarse del mal uso de esta por terceras personas distintas del USUARIO, debiendo mantener en paz y a salvo a CACAO por cualquier tipo de uso no autorizado de la Contraseña.
    Derivado de lo anterior el USUARIO reconoce que será el único responsable por todas las operaciones efectuadas con su Contraseña, por lo que CACAO no tendrá responsabilidad alguna.
    Asimismo, el USUARIO podrá modificar en cualquier momento su Contraseña de acceso a la PLATAFORMA CACAO a través del procedimiento señalado e informado por CACAO dentro de la PLATAFORMA CACAO.

    7. NIVELES DE CUENTA DE FPE
    CACAO clasificará cada una de las Cuentas en alguno de los tres niveles indicados a continuación, dependiendo de los criterios y requisitos para la apertura de cuenta de que se trate.
    Nivel 1 Nivel 2 Nivel 3
    750 UDIS (mensual) y Saldos no mayores a 1000 UDIS 3000 UDIS (mensual)
    Sin límite
    Cabe señalar, que se dará a conocer al USUARIO a la contratación del servicio con CACAO la información y/o documentación necesaria para integrar su expediente de identificación.

    8. PROTECCIÓN DE DATOS PERSONALES.
    El USUARIO reconoce y acepta que CACAO realiza el tratamiento de sus datos personales y patrimoniales de conformidad con los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad en términos de lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (“LFPDPPP”), y en apego a su aviso de privacidad dado a conocer con anticipación en la PLATAFORMA CACAO.
    CACAO se obliga a asegurar la privacidad, confidencialidad e integridad de los datos personales, así como responder por cualquier sanción o multa a la que pudiera ser acreedor, por el mal uso de datos personales de sus USUARIOS.

    9. NOTIFICACIONES
    El USUARIO reconoce que CACAO recibirá y realizará notificaciones de confirmación o cancelación de operaciones, cambios y recepción de información por medio de la PLATAFORMA CACAO, no obstante, las mismas podrá realizarlas por medios electrónicos previamente informados al USUARIO, por lo que, bajo ninguna circunstancia CACAO utilizará canales de comunicación no oficiales, por su parte el USUARIO se obliga a realizar las siguientes notificaciones:

    9.1. CAMBIOS
    El USUARIO deberá notificar a CACAO a través de la PLATAFORMA CACAO, a la brevedad posible, si existe algún cambio en sus datos personales proporcionados conforme al primer párrafo del apartado anterior y, en general, de cualquier información que sea actualizada o modificada.
    9.2. ROBO O EXTRAVÍO DE CONTRASEÑAS
    En caso de extravío, robo o vulneración de los factores de autenticación, claves de acceso y/o contraseñas en general el USUARIO deberá comunicar tal hecho de forma inmediata a CACAO a través del correo electrónico contacto@cacaopaycard.com, en el cual deberá interponer una queja, aclaración o reclamación, en el entendido de que, en ninguna circunstancia, CACAO será responsable de los cargos u operaciones y/o autorizaciones dadas con anterioridad al reporte de robo o extravió, aun cuando se hayan realizado por un tercero.
    9.3. ROBO O EXTRAVÍO DE TARJETA CACAO
    En caso de que el USUARIO extravíe o sea objeto del robo de la Tarjeta CACAO, éste deberá avisar a CACAO en cualquier día natural a través del correo electrónico contacto@cacaopaycard.com, respecto de dicha circunstancia.
    El aviso anterior, podrá realizarse también por los cargos no reconocidos respecto de la Tarjeta CACAO desde el momento del aviso de robo o extravío.
    CACAO no solicita un trámite adicional para el caso de robo o extravío de la Tarjeta CACAO.
    CACAO le proporcionará por correo electrónico un acuse de recibo y un folio que indique el número progresivo de atención a su solicitud, indicando la fecha y hora de la solicitud. Esta información se dará a conocer al USUARIO al momento de la solicitud o a más tardar el día hábil siguiente a la solicitud.
    10. USOS Y RESTRICCIONES DE LA PLATAFORMA CACAO.

    10.1. USOS DE LA PLATAFORMA.
    La PLATAFORMA CACAO es utilizada para brindar a sus Usuarios una herramienta funcional con la cual puedan llevar a cabo diferentes operaciones, tales como:
    • Consulta de saldos y movimientos
    • Transferencias interbancarias en moneda nacional
    • Transferencias a Cuentas de Fondos de Pago Electrónico
    • Cambio de NIP
    • Visualización de estado de movimientos
    • Cambio de contraseña.
    Para que el USUARIO pueda acceder a la PLATAFORMA CACAO, será necesario que este se autentique a través de la Contraseña que CACAO le solicité, y los cuales le fueron asignados durante el proceso de registro. Una vez autenticado podrá hacer uso de la PLATAFORMA CACAO para efectuar las operaciones.
    Mediante los TCG, se le informa al USUARIO que a través de la PLATAFORMA CACAO éste podrá contratar con CACAO la apertura de una Cuenta de FPE con medio de disposición consistente en la Tarjeta CACAO.
    Al respecto los servicios que se ofrecen en la PLATAFORMA CACAO, son los consistentes en el abono de recursos a la Cuenta, Transferencias, Transferencias de FPE, remisión de FPE y administración de la Cuenta de FPE, incluyendo el registro de las operaciones que en su caso realice el USUARIO.
    Como consecuencia del registro de las operaciones que se realicen con la Tarjeta CACAO, el USUARIO podrá consultar en la propia PLATAFORMA CACAO sus movimientos y saldos.
    El USUARIO acepta que no todos los usos o funcionalidades estarán disponibles de inicio, ya que algunos de ellos podrán ser utilizados solamente con posterioridad a su contratación, activación o registro, según se indique en las condiciones de contratación que se establezcan en la documentación respectiva.
    No obstante lo anterior, CACAO informa que la PLATAFORMA CACAO no podrá ser usada para fines distintos a los expresamente enunciados en los presente TCG.
    10.2. RESTRICCIONES DE USO DE LA PLATAFORMA
    a) La utilización de la PLATAFORMA CACAO por cualquier persona que no tenga la calidad de USUARIO. Su utilización implica la adhesión a los presentes TCG.
    b) El USUARIO reconoce que el uso de los contendidos no le proporciona ningún derecho de propiedad sobre los mismos.
    c) CACAO es el único encargado de proporcionar al USUARIO una clave de usuario y contraseña.
    d) Es responsabilidad del USUARIO notificar inmediatamente a CACAO, en dado caso de que sospeche que alguien más está usando su Cuenta para acceder a la PLATAFORMA CACAO.
    e) El USUARIO se obliga a utilizar el contenido de la PLATAFORMA CACAO de forma correcta y se sujeta a respetar todos los derechos de propiedad industrial sobre el referido contenido.
    f) Si el USUARIO requiere notificar de cualquier caso del cual crea que se está infringiendo los derechos de autor, propiedad intelectual o de cualquier otro, así como material inapropiado deberá reportarlo a CACAO.
    g) Queda prohibida la reproducción total o parcial de los contenidos de esta página, por cualquier medio o procedimiento, sin la autorización previa, expresa y por escrito de CACAO.

    11. MODIFICACIONES
    11.1. MODIFICACIONES A LA PLATAFORMA CACAO.
    CACAO se reserva el derecho de suprimir o modificar el contenido de la PLATAFORMA CACAO, así mismo, no será responsable por su vigencia ni alteración del contenido. No obstante, salvo que se deba a una falla o retraso justificado por CACAO respecto de la edición y/o actualización del presente documento, CACAO será responsable de cualquier daño y/o perjuicio, directo o indirecto, causado en virtud de la confianza del USUARIO en la información obtenida a través de la PLATAFORMA CACAO.
    De esta forma, el USUARIO está de acuerdo en revisar dichos TCG periódicamente con la finalidad de mantenerse al tanto de dichas modificaciones. No obstante, lo anterior, cada vez que el USUARIO acceda al sitio señalado se considerará como una aceptación absoluta a las modificaciones del presente acuerdo.

    11.2. MODIFICACIONES A TCG
    Las partes reconocen y aceptan que estos TCG, así como cualquier otro documento que forme parte del presente acuerdo, podrán ser modificadas de forma unilateral por CACAO, bastando para ello la notificación previa con 30 (treinta) días naturales de anticipación a la fecha en que la modificación surta efectos.
    Las modificaciones serán válidas mediante la aceptación expresa o tácita del USUARIO, por lo que se entiende que una vez que dichos documentos hayan sido entregados o dados a conocer al USUARIO, y siempre y cuando no manifieste nada en contrario dentro de los 3 (Tres) días hábiles siguientes a su entrega o conocimiento, dichos documentos y su contenido serán aplicables y válidos para el USUARIO.

    12. DISPONIBILIDAD DE LA PLATAFORMA.
    El USUARIO tendrá acceso a la PLATAFORMA CACAO en cualquier momento, es decir los 365 días naturales durante las 24 horas del día, para realizar operaciones en la Cuenta siempre que se cuente con los FPE suficientes. En caso contrario, deberá sujetarse a los horarios que la Comisión Nacional Bancaria y de Valores establezca en Disposiciones de carácter general para determinar los horarios en que las instituciones financieras de las cuentas bancarias mediante las cuales realizarán Transferencias o Transferencias de Fondos de Pago Electrónico a sus Cuentas deban cerrar sus puertas, suspender operaciones, así como la prestación de servicios al público en la República Mexicana.
    Las operaciones realizadas en la PLATAFORMA CACAO distintas a la emisión ejecutadas con éxito dentro de los días y horas permitidos, según se pacta anteriormente, surtirán sus efectos el día en que se efectúen, en el entendido de que las operaciones que hayan quedado pendientes por no haberse ejecutado dentro de los días y horarios establecidos anteriormente surtirán sus efectos al siguiente Día Hábil al que se realicen, siempre y cuando no sobreviniere algún impedimento para su ejecución.
    Respecto de las operaciones de emisión de FPE, CACAO los emitirá en los tres segundos inmediatos posteriores a aquel en que CACAO reciba los recursos respectivos, para lo cual CACAO cuenta a través de éstos TGC y del Contrato con el consentimiento expreso del USUARIO para llevar a cabo la referida emisión de FPE. Dicha autorización consta al momento de realizar el registro y la aceptación del Contrato correspondiente.
    En caso de fallas en la PLATAFORMA CACAO llevará a cabo todas las diligencias necesarias a fin de lograr la continuidad en los servicios en la PLATAFORMA CACAO. Cualquier notificación que deba hacerse al USUARIO respecto de fallas del sistema, procesos y/u opciones para garantizar la continuidad de los servicios y operaciones de la PLATAFORMA CACAO serán hechas a través del correo electrónico registrado por el USUARIO.
    En caso de que estas fallas presenten una duración por más de 24 (veinticuatro) horas, CACAO notificará a la CONDUSEF, mediante correo electrónico remitido a la cuenta suptech@condusef.gob.mx, para poder informar al USUARIO los medios alternos para la continuidad de los servicios provistos por CACAO y, en su caso, la CONDUSEF pueda atender las consultas y las reclamaciones de los USUARIOS de CACAO.
    Dicha notificación deberá contener la fecha y hora de inicio de la falla; por lo que CACAO indicará si continúa la falla o en su caso ya concluyó; así como una explicación del tipo de afectación y el plazo en que estará resuelta.

    13. PRIVACIDAD DE LA INFORMACIÓN
    CACAO garantiza que toda la información personal se procesa y almacena en servidores o medios magnéticos que mantienen altos estándares de seguridad y protección tanto física como tecnológica, adicional a que garantiza el cumplimiento del artículo 73 de la Ley Fintech.

    14. DOCUMENTOS RELACIONADOS
    El USUARIO reconoce que cualquier documento impreso o digital donde se establezcan obligaciones derivadas de la utilización de la PLATAFORMA CACAO, es parte integrante de estos TCG y por lo tanto tendrá completa validez y hará prueba plena.

    15. RIESGOS DE USO DE PLATAFORMA CACAO
    Todos los riesgos derivados de la utilización de la PLATAFORMA CACAO, el uso o contratación de cualquiera de los servicios ofrecidos en o en relación con CACAO, y /o el uso de cualquier contenido serán responsabilidad del USUARIO, por lo que CACAO recomienda actuar con prudencia y sentido común al momento de realizar operaciones y uso de la PLATAFORMA CACAO. En ningún caso, CACAO o cualquiera de sus directores, empleados, agentes o afiliados serán responsables por daños, directos, indirectos, derivados por el uso de estas condiciones.
    Adicional se informa al USUARIO que el Gobierno Federal y las Entidades de la Administración Pública Paraestatal no podrán responsabilizarse o garantizar los recursos de los USUARIOS que sean utilizados en las operaciones que celebren con CACAO o frente a otros, ni asumir alguna responsabilidad por las obligaciones contraídas por CACAO o por algún USUARIO frente a otro, en virtud de las operaciones que celebren.

    16. INDEMNIZACIÓN
    El USUARIO indemnizará a CACAO, sus filiales, empresas relacionadas, directivos, administradores, representantes, empleados, proveedores, vendedores y asesores por cualquier acción, demanda o reclamación derivadas de cualquier incumplimiento por parte del USUARIO a los presente TCG; incluyendo, sin limitación de alguna de las derivadas de: i) Cualquier aspecto relativo al uso de la PLATAFORMA CACAO; ii) La información contenida o disponible en o a través de dicho sitio o de injurias, difamación o cualquier otra conducta violatoria del presente documento por parte del USUARIO en el uso de la PLATAFORMA CACAO señalada; iii) La violación a las leyes aplicables o tratados internacionales relativos a los derechos de propiedad intelectual, contenidos o disponibles en, o a través de dicho sitio web.

    17. ACLARACIONES Y COMUNICACIÓN.
    Cualquier aclaración o reclamación que los USUARIOS tuvieran relacionados con estos TCG o en su caso con el Contrato especifico, deberán ser canalizadas para su atención directamente a CACAO con la documentación que acredite su validez y de conformidad con la cláusula que se indique en dicho documento.
    El USUARIO que desee interponer una queja, aclaración o reclamación deberá ponerse en contacto con la Unidad Especializada de Atención a USUARIOS de CACAO (UNE) en el teléfono 55 5005 9999, correo electrónico une@cacaopaycard.com
    Adicional, CACAO pone a disposición los siguientes medios de comunicación con el USUARIO:
    PáginaWeb www.cacaopaycard.com
    LinkedIn www.linkedin.com/company/cacaopaycard

    18. CARGOS NO RECONOCIDOS.
    En caso de cargos no reconocidos, el USUARIO debe notificar a CACAO un aviso por dicho concepto a través de correo electrónico a contacto@cacaopaycard.com, en un plazo máximo de 90 (noventa) días a que fue realizado el cargo, en cuyo caso CACAO deberá abonar, a más tardar el segundo Día Hábil Bancario posterior a aquel en que haya recibido el aviso respectivo, el monto equivalente al cargo no reconocido, siempre y cuando el citado aviso haya sido generado en el plazo mencionado en este apartado.
    Al respecto, se da a conocer al USUARIO que CACAO no estará obligada a realizar el abono al que se refiere el párrafo anterior, cuando CACAO compruebe que en la operación que haya ocasionado el cargo no reconocido se utilizaron, al momento de realizarla, dos de los elementos independientes aplicables para autenticar las operaciones como autorizadas por el USUARIO, es decir dos Contraseñas, o bien, se haya utilizado solo uno de dichos elementos al momento de realizar la operación y otro de dichos elementos al momento de entregar el bien o servicio adquirido en virtud de dicha operación.
    Al respecto, CACAO no responderá respecto de los cargos no reconocidos en la Cuenta o en la Tarjeta CACAO anteriores al aviso de robo o extravío del medio de disposición referido, mientras que el USUARIO no será responsable desde el momento en que realice el aviso. En todo caso, CACAO tendrá el derecho de exigir el pago de los cargos realizados y autorizados previamente por el USUARIO.
    CACAO deberá abonar, a más tardar el segundo Día Hábil Bancario contado a partir del día en que el USUARIO haya presentado el aviso correspondiente a robo o extravío de la Tarjeta CACAO, el monto equivalente a los cargos reclamados, siempre que se cumplan con los siguientes supuestos:
    a) CACAO no compruebe, mediante el dictamen con lenguaje claro y sencillo respecto de los factores de autenticación utilizados en las operaciones reclamadas, así como la hora de la operación y el nombre del receptor de pagos en donde se originó la operación, que los cargos reclamados derivan de operaciones en las que, para su realización, requieran al USUARIO utilizar, al menos, dos elementos independientes para autenticar las operaciones como autorizadas por el mismo. Este supuesto no es aplicable en caso de cargos que hubieren sido producto de una falla operativa imputable a CACAO que reciba el aviso correspondiente o, tratándose de cargos realizados hacia otra institución de fondos de pago electrónico o Entidad Financiera, a la institución de fondos de pago electrónico o Entidad Financiera adquirente. Los factores de autenticación referidos deberán ser cualquiera de los siguientes:
    • Información que CACAO proporciona al USUARIO o permite a éste generar, a efecto de que solamente él la conozca, para que la pueda ingresar al sistema autorizado por dicha institución para iniciar la operación de que se trate, tales como contraseña o número de identificación personal.
    • Información contenida, recibida o generada por medios o dispositivos electrónicos que solo posee el USUARIO, incluyendo la almacenada en un circuito integrado o chip que sea procesada conforme a los estándares que el Banco de México determine en la regulación correspondiente, así como la obtenida por dispositivos generadores de contraseñas dinámicas que CACAO proporcione al USUARIO. Lo anterior, siempre y cuando dicha información sea generada con propiedades que impidan su duplicación o alteración y que sea información dinámica que no pueda ser utilizada en más de una ocasión.
    • Información derivada de características propias del USUARIO, como aquellas de carácter biométrico, incluyendo huellas dactilares, geometría de la mano o de la cara, patrones en iris o retina, entre otros.
    • Cualquier otro elemento distinto a los previstos en los puntos anteriores que quede determinado en las disposiciones de carácter general que emitan conjuntamente el Banco de México y la Comisión Nacional Bancaria y de Valores de conformidad con el artículo 56, segundo párrafo, de la Ley Fintech.
    b) Sea respecto de avisos de robo o extravío, y dichos cargos no sean reconocidos por el USUARIO y correspondan a operaciones que se hayan realizado durante las cuarenta y ocho horas previas al aviso y que no se hayan autenticado utilizando los dos factores de autenticación.
    c) En caso de reclamaciones por cargos no reconocidos, el aviso se realice dentro de los noventa Días Hábiles Bancarios posteriores a la fecha en que se hubiere realizado el cargo no reconocido.

    En caso de que resulte procedente la devolución de los recursos al USUARIO respectivo por cargos reclamados que se hubieren efectuado hacia otra institución de fondos de pago electrónico, la institución de fondos de pago electrónico adquirente que tramitó el cargo no reconocido, tendrá la obligación de resarcir a la institución emisora los recursos monetarios vinculados con dicho cargo en un plazo que no podrá exceder de dos Días Hábiles Bancarios contados a partir de que reciba la notificación correspondiente por parte de la institución emisora, en caso de que la institución emisora haya requerido la utilización de factores de autenticación conforme a lo establecido en el primer numeral de este apartado, pero la institución de fondos de pago electrónico adquirente no haya proporcionado a la institución emisora los elementos suficientes para validar la autenticidad de dichos factores. La institución de fondos de pago electrónico adquirente únicamente podrá repercutir los recursos mencionados en el presente párrafo al comercio, en los casos en los que le haya proporcionado a éste los elementos necesarios para solicitar factores de autenticación conforme a lo establecido en el primer numeral de este apartado y haya pactado con el comercio que éste asumirá los costos por cargos no reconocidos en los que haya decidido no solicitar factores de autenticación, asimismo, en ningún caso la institución de fondos de pago electrónico adquirente podrá obligar al comercio a utilizar los factores de autenticación mencionados anteriormente.
    El abono a que se refiere el párrafo anterior no resultará aplicable cuando la institución emisora, dentro del plazo mencionado, compruebe al USUARIO que los cargos reclamados corresponden a operaciones con la Tarjeta CACAO y fueron realizadas de acuerdo con las Contraseñas indicadas en este apartado, a menos de que exista evidencia de que el cargo fue producto de una falla operativa imputable a la institución emisora o a la institución de fondos de pago electrónico adquirente, como sería el caso de un cargo duplicado.

    19. VIGENCIA Y PERIODO DE GRACIA.
    La vigencia de los presentes TCG será indefinida a partir de la fecha de aceptación, no obstante, el USUARIO podrá cerrar en cualquier momento su Cuenta, mediante la debida notificación.
    El USUARIO contará con un periodo de diez días hábiles posteriores a la firma del Contrato respectivo, para cancelarlo sin responsabilidad para el mismo, en cuyo caso CACAO no podrá cobrar comisión alguna, debido a que el USUARIO no haya utilizado u operado los productos contratados, bastando para ello la presentación de una solicitud de cancelación a través de la PLATAFORMA CACAO. Dicha cancelación será procedente siempre y cuando el USUARIO no haya dispuesto de los servicios.

    20. TERMINACIÓN Y VENCIMIENTO ANTICIPADO a) Terminación Anticipada
    El USUARIO podrá solicitar en cualquier momento a CACAO, durante la vigencia de los de la solicitud. Esta información se dará a conocer al USUARIO al momento de la solicitud o a más tardar el Día Hábil siguiente a la solicitud.
    En atención a dichas solicitudes, CACAO, deberá cerciorarse de la autenticidad y veracidad de la identidad del USUARIO. A la terminación anticipada, CACAO ya no dará curso a las solicitudes de altas de aquellos USUARIOS, ni les proporcionará algún otro servicio.
    b) Vencimiento Anticipado
    CACAO podrá dar por terminada la relación derivada de estos TCG, sin responsabilidad a su cargo, dando aviso al USUARIO por escrito a través del correo electrónico que el USUARIO haya señalado para tal efecto en su registro, y sin necesidad de declaración judicial,
    Queda expresamente convenido por las PARTES que al darse por terminado el acuerdo de estos TCG conforme a lo dispuesto en el Inciso b) anterior, se extinguirá de inmediato el compromiso de CACAO de realizar con el USUARIO las operaciones y servicios a que se refieren los presentes TCG, y se tendrán por vencidas y pagaderas de inmediato todas las cantidades que adeude en dicho momento el USUARIO a CACAO, sin requisito de presentación, requerimiento, demanda, solicitud u otro aviso de cualquier naturaleza, a todo lo cual el USUARIO renuncia expresamente por este medio.
    21. NO TRANSFERIBILIDAD.
    El USUARIO ni CACAO podrán ceder o transferir los derechos y obligaciones del
    presente acuerdo de TCG.
    22. JURISDICCIÓN Y LEY APLICABLE
    Estos TCG estarán regidos en todos sus puntos por las leyes vigentes en los Estados Unidos Mexicanos, en particular respecto de mensajes de datos, contratación y comercio electrónicos se regirá por lo dispuesto por la legislación federal respectiva. Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida a las leyes aplicables y a los Tribunales competentes. Estos TCG estarán sujetos y serán interpretados de acuerdo con las leyes y ante los tribunales de la Ciudad de México.
    23. AUTORIZACIÓN PARA SOLICITAR INFORMACIÓN CREDITICIA
    El USUARIO autoriza por este medio electrónico por una sola ocasión a CACAO por conducto de sus funcionarios facultados que lleve a cabo investigaciones sobre su comportamiento crediticio en las sociedades de información crediticia que estime conveniente y en caso de así requerirlo. El USUARIO manifiesta estar consciente y aceptar expresamente que este medio electrónico y/o mensaje de datos quede bajo propiedad de CACAO y/o cualquier Sociedad de Información Crediticia a quien solicite el servicio de información crediticia, para efectos de control y cumplimiento del artículo 28 de la Ley para regular a las Sociedades de Información Crediticia.

    24. DOMICILIO
    Se señala como domicilio físico de CACAO: Boulevard Manuel Ávila Camacho 118, oficina 1103, piso 11, Colonia Lomas de Chapultepec, Alcaldía Miguel Hidalgo, Código Postal 11000, Ciudad de México, México, número telefónico 55 5005 9999 y como contacto electrónico contacto@cacaopaycard.com.

    25. AUTORIZACIÓN CON FINES MERCADOTÉCNICOS, PUBLICITARIOS Y CON RESPECTO AL TRATAMIENTO DE DATOS PERSONALES.
    • Autorización para Fines Mercadotécnicos y Publicitarios.
    El USUARIO podrá autorizar CACAO, para que la información contenida en los TGC sea utilizada con fines mercadotécnicos y publicitarios, así como para recibir en su domicilio publicidad de CACAO. No obstante, lo anterior, el USUARIO podrá en cualquier momento revocar la autorización proporcionada a CACAO en términos de su Aviso de Privacidad.
    • Autorización para el Tratamiento de Datos Personales.
    En términos de lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, el USUARIO podrá otorgar a CACAO su consentimiento expreso para el tratamiento de Datos Personales, aceptando los presentes TCG, mediante los cuales CACAO tendrá la obligación de informar al USUARIO, a través del Aviso de Privacidad, la información que recaba del mismo y con qué fines.

    26. PREVENCIÓN DE LAVADO DE DINERO Y FINANCIAMIENTO AL TERRORISMO.
    EL USUARIO declara y garantiza que los fondos que serán utilizados para realizar las operaciones con CACAO, en ejecución de los presentes TCG, no provienen ni provendrán de actividades relacionadas con el narcotráfico, terrorismo u otras actividades ilícitas.
    EL USUARIO reconoce que se obliga a proporcionar la información solicitada por CACAO, para la debida identificación de EL USUARIO en cumplimiento con la normatividad nacional en materia de prevención de lavado de dinero y financiamiento al terrorismo.

    27. ANTICORRUPCIÓN
    Las Partes se comprometen a que, en la fecha de aceptación de los presentes TCG, ni ellas, ni sus directores, funcionarios o empleados habrán ofrecido, prometido, entregado, autorizado, solicitado o aceptado ninguna ventaja indebida, económica o de otro tipo (o insinuado que lo harán o podrían hacerlo en algún momento futuro) relacionada de algún modo con los presentes TCG y que habrán adoptado medidas razonables para evitar que lo hagan los subcontratistas, agentes o cualquier otro tercero que esté sujeto a su control o a su influencia determinante.

    28. SEGURIDAD
    Lo que debes tomar en cuenta sobre los fraudes cibernéticos:
    Los fraudes cibernéticos son aquellas estafas que se emplean a través de las redes para realizar transacciones ilícitas. El delincuente usa herramientas tecnológicas sofisticadas para acceder a distancia a tu información de identidad cibernética.
    Muchas veces las personas que realizan este tipo de fraudes se aprovechan del desconocimiento o del poco cuidado que las personas llegan a tener al utilizar cualquier equipo cibernético, como pueden ser una tablet, un teléfono o laptop, convirtiéndose en un blanco fácil para los estafadores.
    Estas estafas pueden darse de muchas maneras y en cualquier momento por eso es recomendable conocer las vertientes más típicas que utilizan estos delincuentes, entre las más frecuentes se encuentran:
    1. El correo basura
    Comúnmente conocido como SPAM, se trata de un mensaje enviado a varios destinatarios que usualmente no lo solicitaron, con fines aparentemente publicitarios o comerciales.
    La información contenida en dicho correo te invita a visitar una página o descargar algún archivo que generalmente es un virus que roba la información de tu dispositivo.
    2. Smishing
    En esta modalidad de fraude, te envían mensajes SMS a tu teléfono móvil con la finalidad de que visites una página web fraudulenta, con la finalidad de obtener tu información bancaria, para realizar transacciones en tu nombre.
    3. Phishing
    En este tipo de fraude se hacen pasar por una Institución Financiera, enviando un mensaje indicándote un error en tu cuenta bancaria, y al ingresar tus datos financieros, obtienen tu información confidencial como: números de tus tarjetas, claves, datos de cuentas bancarias, contraseñas, etc.
    Una variante a este fraude es el phishing telefónico (vishing), en donde los delincuentes simulan ser empleados de alguna Institución Financiera y te informan que tus cuentas están registrando cargos irregulares o te requieren alguna información confidencial.
    4. Pharming
    En esta modalidad te redirigen a una página de internet falsa mediante ventanas emergentes, para robar tu información. Suelen mostrar leyendas similares en las cuales te indican que fuiste acreedor a un premio por visitar su página.
    5. Transferencias Electrónicas
    En este caso se hacen pasar por una empresa que realiza ofertas muy llamativas, por lo que al comprar te piden realizar una transferencia a cambio de dicho producto y al acudir a dicha empresa te das cuenta de que, nunca existió dicha oferta ni compra.
    6. Malware
    En este tipo de fraude roban tus datos por medio de un Malware (código maligno/software malicioso), el cual se inserta en tu teléfono móvil al ingresar a la aplicación, el Malware muestra una venta falsa, en donde se solicita tus datos confidenciales como son los datos financieros los cuales son enviados a los estafadores para que estos puedan hacer un uso indebido de los mismos.
    7. Descarga de Software
    Al descargar de una página poco confiable o de manera gratuita algún software, éste puede contener un virus que permite al estafador tomar tu información personal directamente de tu computadora.
    ¿Cómo lo puedes evitar?
    a) Instalaentucomputadoraodispositivomóvilunbuenantivirus,utilizaun software tipo firewall o anti-spyware para proteger tus equipos de algún virus malicioso.
    b) No des "clic" o abras vínculos sospechosos.
    c) Si descargas aplicaciones y/o software realízalo por medio de las tiendas y
    desarrolladores oficiales.
    d) No respondas mensajes de correo que te dicen haber ganado un premio,
    viaje o sorteo.
    e) Verifica que el sitio en el que navegas cuente con el protocolo de seguridad
    "https://" y un candado cerrado en la barra de direcciones.
    f) Nunca entregues tus datos por correo electrónico.
    g) Las empresas y bancos nunca te van a solicitar tus datos financieros o
    números de tarjetas por cualquier medio, tales como por teléfono o internet, cuando no seas tú quien inicie una operación. Si aún te queda duda, llama a los números de atención de CACAO y verifica los hechos.
    h) Desconfía de las compras en línea cuando te pidan que hagas un depósito a cuentas bancarias distintas de la empresa.
    i) Cuida tus claves personales, número de identificación personal (NIP), cámbialas periódicamente.
    Recuerda que las personas que realizan este tipo de fraudes son hábiles y te engañan con tácticas alarmistas o solicitudes urgentes para preocuparte y evitar que pienses bien la situación, no entres en pánico y antes de realizar cualquier acción verifica la fuente de procedencia.

    29. CONSENTIMIENTO.
    En ningún caso el USUARIO podrá hacer uso de los servicios que presta CACAO a menos que haya aceptado, o que se considere que ha aceptado (mediante su acceso a la PLATAFORMA CACAO el uso de los servicios). Por lo que acepta y reconoce que la aceptación de los presentes TCG tiene el mismo efecto jurídico que el poner su firma física en cualquier contrato o acuerdo de voluntades, constituyendo su voluntad y, consentimiento libre de cualquier vicio, aceptando todos los términos contenidos.
    30. CONDUSEF
    En cualquier tiempo, el USUARIO podrá acudir directamente a la CONDUSEF para cualquier aclaración o consulta, o bien, contactarla a través de su Centro de Atención Telefónica (01 800 999 80 80 / 53 40 09 99), su página de internet www.condusef.gob.mx, o en los correos electrónicos: y asesoria@condusef.gob.mx.
    31. DIVULGACIÓN DE LA INFORMACIÓN.
    CACAO no incurrirá en responsabilidad alguna, cuando la Información Confidencial que se le haya proporcionado, sea conocida por cualquier tercero, por alguna de las siguientes causas:
    a) CuandolaInformaciónConfidencialsehagadeldominiopúblico,
    b) Cuando el titular de la Información Confidencial autorice por escrito que la Información Confidencial sin restricciones a terceras Personas, según lo
    establezca; o
    c) En caso de que, por disposición legal, administrativa o por orden judicial a
    entregar, total o parcialmente la Información Confidencial.
    d) Cuando obtenga la Información Confidencial de un tercero sin que medie
    incumplimiento.

    En todo caso, la revelación de cualquier tipo de información del USUARIO por parte de CACAO a las Autoridades Financieras no implicará transgresión alguna a la obligación de confidencialidad legal, ni constituirá violación a las restricciones sobre revelación de información establecidas por cualquier vía contractual.

    Última actualización: 24 de noviembre de 2020.`,
  privacyPolicy1: `I. IDENTIDAD Y DOMICILIO DEL RESPONSABLE.

    CACAO PAYCARD SOLUTIONS, S.A. DE C.V., INSTITUCIÓN DE FONDO DE PAGO ELECTRÓNICO (“CACAOPAY”) con domicilio en Boulevard Manuel Ávila Camacho 50, 5º piso, Lomas de Chapultepec, C.P. 11000, Miguel Hidalgo, Ciudad de México, que en este caso es el responsable del uso y protección de sus Datos Personales, en cumplimiento de la Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP), pone a su disposición el presente Aviso de Privacidad para informarle la forma en que recabamos, transferimos y utilizamos sus Datos Personales. Los Datos Personales son obtenidos principalmente del Sitio Web de CACAOPAY y de la plataforma tecnológica denominada Plataforma Paycard.

    El departamento legal de CACAOPAY es el designado para dar trámite a las solicitudes de derechos ARCO que se mencionan más adelante, así como para fomentar la protección de los Datos Personales al interior de CACAOPAY.

    CACAOPAY cuenta con las medidas de seguridad, administrativas, técnicas y físicas necesarias para salvaguardar la privacidad y confidencialidad de sus datos personales, así como para cumplir con los principios de protección de datos personales previstos en LFPDPPP.

    CACAOPAY recibe, recaba, maneja, procesa, trata y conserva los Datos Personales de conformidad con los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad a efecto de asegurar la privacidad, confidencialidad e integridad de conformidad con lo dispuesto por la LFPDPPP.

    II.  DEFINICIONES.

    Para efectos del siguiente Aviso de Privacidad se entiende por:

    Datos Personales: Cualquier información concerniente a una persona física identificada o identificable.
    Derechos ARCO: Son los derechos de Acceso, Rectificación, Cancelación u Oposición, respecto a sus Datos Personales.
    Legislación Vigente Aplicable: Se refiere a la LFPDPPP, su reglamento, y en general a cualquier ordenamiento jurídico que regule la recopilación, almacenamiento y transmisión de Datos Personales.
    Responsable: Para los efectos del presente Aviso de Privacidad, el responsable del tratamiento, uso y protección de sus Datos Personales será CACAOPAY.
    Sitio Web: Sitio Web propiedad de CACAOPAY cuya dirección es: www.cacaopaycard.com.
    Plataforma Paycard: Entiéndase de manera indistinta o de manera conjunta la Aplicación y el
    Sitio Web
    Titular: Persona física a quien corresponden los Datos Personales (en adelante “Usted”).

    III. 	DATOS PERSONALES RECABADOS.

    Los Datos Personales que usted libre, entregue  y voluntariamente proporciona a CACAOPAY incluyen, enunciativamente, sin limitar:

    CACAO PAYCARD SOLUTIONS, S.A. DE C.V. Blvd. Manuel Ávila Camacho número 50 piso 5,
    Lomas de Chapultepec, Miguel Hidalgo, CDMX, CP. 11000 / T. 5005.9900 / cacaopaycard.com
    Datos personales de identificación: nombre, domicilio (calle, número, colonia, código postal, alcaldía o municipio, entidad federativa, ciudad.), fecha de nacimiento, nacionalidad,  CURP, Firma electrónica avanzada.
    Datos de contacto: teléfono y correo electrónico.
    Datos patrimoniales y/o financieros:  RFC, clabe de cuenta bancaria, datos transaccionales para prevención de lavado de dinero y financiamiento al terrorismo.
    Datos laborales: profesión u ocupación, actividad o giro del negocio, ubicación del centro de trabajo.
    Datos de ubicación geográfica: geolocalización del dispositivo electrónico utilizado para el acceso al Sitio Web o a la Plataforma Paycard.

    Se presume que usted proporciona Datos Personales a CACAOPAY, en su carácter de  Titular de los mismos. En caso de que usted proporcione Datos Personales de otros titulares, manifiesta contar con el consentimiento de dichos Titulares para proporcionarnos los Datos Personales y reconoce su obligación de informar a dicho Titular o titulares que ha proporcionado sus datos, así como de los lugares en los que se encuentra a su disposición el presente Aviso de Privacidad para poder llevar a cabo la prestación de cualquier servicio requerido.

    Cabe señalar que CACAOPAY debe recabar los datos necesarios para cumplir con las disposiciones legales aplicables en materia de prevención y detección de actos, omisiones u operaciones que pudieran favorecer, prestar ayuda, auxilio o cooperación de cualquier especie para la comisión de los delitos en materia de lavado de dinero y/o el financiamiento al terrorismo, así como fraude, por lo que podrá solicitarse información adicional en caso de que la regulación aplicable así lo establezca.

    IV. FINALIDADES PRINCIPALES Y/O USOS DE LOS DATOS PERSONALES.

    El objetivo principal (finalidades primarias) que persigue CACAOPAY con la recopilación de sus datos personales es:
    Registro en la Plataforma Paycard propiedad de CACAOPAY.
    Prestación de servicios de emisión, administración, redención y transmisión de fondos de pago electrónico,  y de los productos por parte de CACAOPAY.
    Identificación de los usuarios, clientes, suscriptores, trabajadores, interesados, o cualquier otra persona que ingrese al Sitio Web o a la Plataforma Paycard.
    Creación de canales de comunicación entre Usted y CACAOPAY.
    Cumplimento con lo requerido por cualquier autoridad federal, estatal o municipal para la operación de CACAOPAY.
    Cumplimiento de actos que sean necesarios para proveer los servicios y productos requeridos por Usted, así como para hacer cumplir y/o ejecutar el contrato respectivo.

    V. FINALIDADES ACCESORIAS DEL USO DE LOS DATOS PERSONALES.

    CACAOPAY tratará los Datos Personales del Titular adicionalmente a las finalidades señaladas en el párrafo anterior, para las siguientes finalidades secundarias:
    Para fines estadísticos.
    Para informar sobre cambios o nuevos productos o servicios.
    Evaluar la calidad del servicio, determinar la calidad de estos.
    Intercambiar información necesaria para atender auditorías internas, externas y por autoridades.
    Para fines comerciales, mercadotécnicos, publicitarios y/o de prospección comercial y para otorgar beneficios y dar cumplimiento a obligaciones contraídas y para realizar estudios internos sobre hábitos de consumo.

    RECOPILACIÓN / CONSERVACIÓN DE LOS DATOS PERSONALES.

    La cantidad de Datos Personales solicitados estará únicamente relacionada con los datos que necesitamos para ofrecer servicios o productos a nuestros clientes y/o usuarios.

    Dichos Datos Personales pueden ser obtenidos directamente de Usted, ya sea personalmente o a través de cualquier medio electrónico, óptico, sonoro, visual o a través de cualquier otra tecnología y de otras fuentes permitidas por las disposiciones legales aplicables, o bien, generados con motivo de los servicios solicitados o contratados por usted con CACAOPAY o cualquiera de las empresas que son subsidiarias o afiliadas.

    Los Datos Personales que proporcione se conservarán o destruirán según las necesidades de CACAOPAY según lo establecido por las leyes locales o cuando así lo solicite Usted.

    TRANSFERENCIA DE DATOS PERSONALES.

    CACAOPAY podrá realizar las transferencias nacionales e internacionales de Datos Personales sin el consentimiento del Titular entre sus empresas filiales o afiliadas y en los demás supuestos previstos en el artículo 37 de la LFPDPPP única y exclusivamente para el cumplimiento de los servicios que contrata con CACAOPAY. También, podrá realizar transferencias de Datos Personales a entidades proveedoras de servicios para el cumplimiento de las obligaciones contractuales acordadas.

    Los terceros y las entidades receptores de Datos Personales, en términos del párrafo anterior, asumirán las mismas obligaciones y/o responsabilidades que CACAOPAY ha asumido con Usted, en términos del presente Aviso de Privacidad.

    Usted acepta que sus Datos Personales podrán ser transferidos en los términos descritos en la Ley, su Reglamento y el presente Aviso de Privacidad.

    CACAOPAY no comercializa Datos Personales ni los vende a terceras partes.

    Se informa a usted que la información y documentación relativa a las actividades y servicios que presta CACAOPAYCARD, tendrá el carácter confidencial.

    REVOCACIÓN.

    Usted podrá revocar su consentimiento, así como oponerse para el tratamiento de sus Datos Personales para las finalidades que no son indispensables para la relación jurídica que dio origen al producto o servicio que hubiese contratado.

    Para efectuar dicha revocación, Usted podrá acudir al domicilio de CACAOPAY o comunicarse vía correo electrónico a la dirección: contacto@cacaopaycard.com para obtener el formato respectivo, a través del cual pueda revocar o limitar el uso o divulgación de sus Dato Personales. Asimismo, podrá revocar el consentimiento para tratar sus Datos Personales con finalidad de prospección comercial, enviando correo electrónico a la dirección antes señalada, y lo excluiremos de nuestras campañas con fines mercadotécnicos, publicitarios o de prospección comercial.

    MEDIOS Y PROCEDIMIENTO PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN (ARCO).

    Usted o su representante legal debidamente acreditado podrán limitar el uso o divulgación de sus Datos
    Personales; así como ejercer, cuando procedan, los derechos de Acceso, Rectificación, Cancelación u Oposición que la LFPDPPP prevé mediante el formato que CACAOPAY le proporcione para tales efectos, presentándolo en el domicilio de CACAOPAY o mediante correo electrónico a la dirección: contacto@cacaopaycard.com. Es importante mencionar que el ejercicio de cualquiera de dichos derechos no es requisito previo ni impide el ejercicio de otro derecho.

    Puede utilizar el correo electrónico contacto@cacaopaycard.com para solicitarnos la eliminación, acceso, rectificación, cancelación u oposición (los “Derechos ARCO”) al uso de Datos Personales en nuestras listas de correo o en nuestras operaciones. Para tal situación, Usted deberá redactar la petición, y empezaremos a trabajar diligentemente para localizar y solucionar el problema con prontitud. La anterior solicitud, será sin perjuicio de su derecho de acudir ante el Instituto Nacional de Transparencia Acceso a la Información y Protección de Datos Personales.

    CACAOPAY tendrá un plazo máximo de 20 (veinte) días hábiles contados a partir de la solicitud, para informar sobre la procedencia de la solicitud de acceso, ratificación, cancelación u oposición.

    Esta información se puede almacenar mediante sistemas manuales o electrónicos con acceso limitado, con el objetivo de protegerla contra pérdidas, uso fraudulento, accesos no autorizados, revelación, modificación o destrucción. La solicitud deberá contener y acompañarse de lo siguiente:  (i) su nombre y correo electrónico para comunicarle nuestra respuesta; (ii) los documentos que acrediten su identidad y/o la de su representante legal; (iii) la descripción clara y precisa de los datos personales respecto de los cuales se busca ejercer alguno de los derechos mencionados; (iv) cualquier otro documento o elemento que facilite la localización de sus datos personales; y (v) tratándose de solicitudes de rectificación, deberá indicar además las modificaciones a realizarse y aportar la documentación que sustente su petición, en caso de que ésta sea procedente.

    Conforme a la LFPDPPP se hace de su conocimiento que, sus Datos Personales podrán ser tratados sin su consentimiento cuando: (i) esté previsto en una ley; (ii) los datos personales figuren en fuentes de acceso público; (iii) los datos personales se sometan a un procedimiento previo de disociación; (iv) tenga el propósito de cumplir obligaciones derivadas de una relación jurídica entre el titular y el responsable; (v) exista una situación de emergencia que potencialmente pueda dañar a un individuo en su persona o en sus bienes y/o (vii) se dicte resolución de autoridad competente.

    Al momento en que Usted haga la revocación y/o cancelación del uso de sus Datos Personales existe la posibilidad de que CACAOPAY no pueda seguir prestándole sus Servicios o Productos.

    USO DE COOKIES Y BEACONS.

    Nuestro Sitio Web puede utilizar cookies, web beacons y otras tecnologías a través de las cuales es posible monitorear su comportamiento como usuario de Internet, brindarle un mejor servicio y experiencia de usuario al navegar en el Sitio Web, así como ofrecerle nuevos productos y servicios basados en sus preferencias.

    Los Datos Personales que obtenemos de estas tecnologías de rastreo son los siguientes: horario de navegación, tiempo de navegación en nuestra página de Internet, secciones consultadas, y páginas de Internet accedidas previo a la nuestra.

    Usted, conoce que el uso de los servicios y productos de CACAOPAY, puede utilizar distintas tecnologías, y se encuentra de acuerdo en que CACAOPAY recabe los datos de geolocalización del dispositivo utilizado para acceder al Sitio Web y Plataforma Paycard,

    CONSENTIMIENTO.

    Usted tendrá pleno control y decisión de sus datos personales, por ello que usted, al proporcionar sus Datos Personales de manera personal o a través de cualquier medio electrónico, óptico, sonoro, visual o a través de cualquier otra tecnología y de otras fuentes permitidas por la LFPDPPP, confirma y acepta que ha leído y otorga su consentimiento expreso a que sus Datos Personales sean tratados de acuerdo con los términos y condiciones de este Aviso de Privacidad.

    Al no existir manifestación de oposición alguna por parte de Usted respecto al presente Aviso de Privacidad, se entenderá que el Usted otorga a CACAOPAY su consentimiento para llevar a cabo el tratamiento de los Datos Personales que hubieran sido proporcionados y/o los que con motivo de alguna de las finalidades establecidas en el presente proporcione en lo futuro.

    La negativa para el uso de sus Datos Personales para estas finalidades podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.

    MODIFICACIONES AL AVISO DE PRIVACIDAD.

    Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos. Dichas modificaciones estarán disponibles en la presente página de internet o aplicación móvil (en su caso) en el apartado de aviso de privacidad, y se señalará la fecha de última actualización del documento.

    Cualquier modificación al presente aviso le será notificada a través de cualquiera de nuestros canales de comunicación.

    LEGISLACIÓN APLICABLE, JURISDICCIÓN Y COMPETENCIA

    El presente Aviso de Privacidad que se hace de su conocimiento se rige por la regulación de la LFPDPPP y demás leyes aplicables y vigentes en la Ciudad de México. La aceptación del presente Aviso de Privacidad implica su sometimiento expreso a los tribunales competentes de la Ciudad de México, para cualquier controversia o reclamación en cuanto al mismo.
    [*Casilla de consentimiento] Otorgo mi consentimiento para el tratamiento de mis Datos Personales que realice CACAOPAY para las finalidades que se describen en este Aviso, de manera expresa y particularmente, por lo que hace a mis datos financieros y patrimoniales.
    `,
};
