import React, { useContext } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { DialogContext } from '@clipmx/clip-storybook/dist/components/Dialog/dialogContext';
import IconButton from '@clipmx/clip-storybook/dist/components/IconButton';
import { ArrowBack as ArrowBackIcon } from '@clipmx/clip-storybook/dist/components/Icons/';
import { Close as CloseIcon } from '@clipmx/clip-storybook/dist/components/Icons';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import PropTypes from 'prop-types';
import styles from './styles';
import OutModal from './Outmodal/OutModal';

export const outModal = 'outModal';
  const useStyles = makeStyles(styles);
  const LoansStepperV4 = (props) => {
  const classes = useStyles(props);
  const { dialogActions } = useContext(DialogContext);
  const handleClose = () => setOpen(false);
  const { handleClick, step, showCreditHistory, loansFormURL } = props;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  let location = useLocation();

  const showModal = () => {
    dialogActions.registerDialog(outModal, {
      open: true,
      type: 'form',
      title: '',
      children: (
        <OutModal
         loansFormURL={loansFormURL}
          modalName={outModal}
          history={history}
        />
      ),
      name: outModal,
    });
  };

  
  const renderStep = () => {
    const steps = showCreditHistory ? [1, 1, 1, 1] : [1, 1, 1];
    if ([0, 1, 2, 3].indexOf(step) !== -1) {
      return (
        <div className={classes.barContainer}>
          {steps.map((value, index) => {
            return (
              <div
                key={index}
                className={clsx({
                  [classes.barProgress]: true,
                  [classes.barProgressActive]: index <= step,
                })}
              ></div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className={classes.stepper}>
      {step > 0 ? (
        <IconButton
          classes={{ root: classes.iconButton }}
          onClick={() => handleClick()}
        >
          <ArrowBackIcon classes={{ root: classes.arrowBackActive }} />
        </IconButton>
      ) : (
        <IconButton classes={{ root: classes.iconButton }}>
          <ArrowBackIcon classes={{ root: classes.arrowBack }} />
        </IconButton>
      )}
      {!handleClick && <div className={classes.iconButton} />}
      {renderStep()}
      {handleClose && (
        <IconButton classes={{ root: classes.iconButtonCa }}>
          <CloseIcon
            classes={{ root: classes.closeIcon }}
            onClick={showModal}
          />
        </IconButton>
      )}
    </div>
  );
};

LoansStepperV4.propTypes = {
  handleClick: PropTypes.func,
};

export default withRouter(LoansStepperV4);
