import React from 'react';
import Input from 'components/npm/clip-ui/Input';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { ClipInput } from '@clipmx/clip-storybook/dist/components/Input/Input';

import { TermsCheckbox } from 'components/loans/Commons/TermsCheckbox';
import { Select, FormInputLabel, ErrorMessage } from './styled';

export const renderFileInput = (props) => {
  const { input, ...rest } = props;

  delete rest.meta;
  delete input.value;

  return <input type={'file'} {...input} {...rest} />;
};

export const renderInput = ({
  input,
  label,
  helperText,
  meta: { touched, error },
  ...rest
}) => (
  <Input
    label={label}
    error={error && touched}
    helperText={(touched && error) || helperText}
    color="primary"
    {...input}
    {...rest}
  />
);

export const renderCheckbox = ({
  input,
  label,
  labelWithLinks,
  meta: { touched, error },
  ...rest
}) => (
  <FormControl fullWidth {...rest}>
    <TermsCheckbox
      label={label}
      labelWithLinks={labelWithLinks}
      error={error}
      input={input}
      rest={rest}
      touched={touched}
    />
  </FormControl>
);

export const renderSelectField = ({
  input,
  label,
  children,
  options,
  meta: { touched, error },
  helperText,
  ...rest
}) => {
  const hasError = touched && error;
  return (
    <FormControl fullWidth {...rest}>
      <FormInputLabel>{label}</FormInputLabel>
      <Select
        {...input}
        onChange={(event) => input.onChange(event.target.value)}
      >
        {options
          ? options.map((option, key) => (
              <MenuItem key={key} value={option.id} disabled={option.disabled}>
                {option.name}
              </MenuItem>
            ))
          : children}
      </Select>
      {hasError && (
        <FormHelperText style={{ paddingLeft: 8 }} error>
          {error}
        </FormHelperText>
      )}
      {!hasError && (
        <FormHelperText style={{ paddingLeft: 8 }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export const renderRadioGroup = ({
  input,
  children,
  meta: { touched, error },
  ...rest
}) => (
  <RadioGroup
    row
    {...input}
    {...rest}
    valueselected={input.value}
    onChange={(event, value) => input.onChange(value)}
  >
    {children}
    {touched && error && <ErrorMessage>{error}</ErrorMessage>}
  </RadioGroup>
);

function isNumber(charCode) {
  return charCode >= 48 && charCode <= 57;
}

function isAlpha(charCode) {
  return (
    (charCode >= 65 && charCode <= 90) ||
    (charCode >= 97 && charCode <= 122) ||
    (charCode >= 160 && charCode <= 165)
  );
}

function isCharacter(charCode) {
  return charCode >= 32 && charCode <= 47;
}

function isAlphaNumeric(charCode) {
  return isNumber(charCode) || isAlpha(charCode);
}

function isAlphaNumericWithCharactes(charCode) {
  return isNumber(charCode) || isAlpha(charCode) || isCharacter(charCode);
}

export const inputNumbersOnly = (event) => {
  if (!isNumber(event.charCode)) event.preventDefault();
};

export const inputAlphaOnly = (event) => {
  if (!isAlpha(event.charCode)) event.preventDefault();
};

export const inputAlphaNumericOnly = (event) => {
  if (!isAlphaNumeric(event.charCode)) event.preventDefault();
};

export const inputAlphaNumericWithCharactersOnly = (event) => {
  if (!isAlphaNumericWithCharactes(event.charCode)) event.preventDefault();
};

export const inputRFCOnly = (event) => {
  var charCode = event.which || event.keyCode;
  if (
    !isAlphaNumeric(event.charCode) &&
    String.fromCharCode(charCode) !== '&' &&
    String.fromCharCode(charCode) !== 'Ñ'
  )
    event.preventDefault();
};

export const focusInputWithErrors = (errors) => {
  const inputs = document.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    for (let j = 0; j < Object.keys(errors).length; j++)
      if (inputs[i].name === Object.keys(errors)[j]) {
        if (inputs[i].type === 'hidden') {
          window.scrollTo(
            1,
            window.scrollY +
              inputs[i].parentNode.getBoundingClientRect().top -
              100,
          );
        } else if (inputs[i].type === 'radio') {
          window.scrollTo(
            1,
            window.scrollY +
              inputs[i].parentNode.parentNode.getBoundingClientRect().top -
              100,
          );
        } else {
          inputs[i].focus();
        }
        return;
      }
  }
};

export const focusFileInputType = (fieldName) => {
  const inputs = document.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    if (inputs[i].name === fieldName) {
      window.scrollTo(
        1,
        window.scrollY +
          inputs[i].parentNode.parentNode.getBoundingClientRect().top -
          100,
      );
      return;
    }
  }
};

export const isInvalidFormField = (field) => {
  return field === '' || field === null || field === undefined;
};

export const isInvalidArray = (array) => {
  let isInvalidArray = false;
  for (let element in array) {
    if (!array[element] || array[element] === 0) {
      isInvalidArray = true;
    }
  }

  return isInvalidArray;
};

export const renderClipInput = ({
  input,
  label,
  helperText,
  meta: { touched, error },
  ...rest
}) => {
  return (
    <ClipInput
      id="primary"
      label={label}
      error={error && touched}
      errormessage={error}
      helperText={(touched && error) || helperText}
      color="secondary"
      {...input}
      {...rest}
    />
  );
};
