import {
  SET_PAYMENT_NOTIFICATION_STATE,
  SET_EMAIL_NOTIFICATION,
  SET_PAYMENT_INITIAL_DATA,
  SET_INSTALLMENTS_NOTIFICATION_STATE,
  SET_HAS_INSTALLMENTS,
  SET_INSTALLMENTS_TERM,
  SET_INSTALLMENTS_PRICE_THRESHOLD,
  SET_INSTALLMENTS_INITIAL_DATA,
  SET_ENABLED_TIP,
  FETCH_TIPS_CONFIG,
  SET_TIPS_STATE,
  HANDLE_REDUX_TIPS_STATE,
  HANDLE_TIPS_ROUNDED_STATE,
  CANCEL_TIPS_EDITION,
  CAN_MANAGE_TIPS,
} from './types';
import { ComponentStates } from 'constants/AppConstants';
import { installmentOptions } from '../helpers';

const { ENABLED, DISABLED } = ComponentStates;

const initialState = {
  installmentsNotificationState: ComponentStates.INITIAL,
  installmentsTerm: installmentOptions[0].id,
  installmentsPriceThreshold: null,
  hasInstallments: false,
  paymentNotificationState: ComponentStates.ENABLED,
  emailNotification: '',
  topupSettlementTypeState: ComponentStates.ENABLED,
  hasTopupSettlementTypeUpdate: false,
  topupSettlementTypeCurrentData: {},
  topupSettlementTypeScheduledUpdateData: {},
  tipsComponentState: ComponentStates.DISABLED,
  tipsEnabled: false,
  tipFromService: false,
  tipsOptionSelected: false,
  canManageTips: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENT_NOTIFICATION_STATE:
      return { ...state, paymentNotificationState: action.payload };
    case SET_EMAIL_NOTIFICATION:
      return { ...state, emailNotification: action.payload };
    case SET_INSTALLMENTS_NOTIFICATION_STATE:
      return { ...state, installmentsNotificationState: action.payload };
    case SET_HAS_INSTALLMENTS:
      return { ...state, hasInstallments: action.payload };
    case SET_INSTALLMENTS_TERM:
      return { ...state, installmentsTerm: action.payload };
    case SET_INSTALLMENTS_PRICE_THRESHOLD:
      return { ...state, installmentsPriceThreshold: action.payload };
    case SET_INSTALLMENTS_INITIAL_DATA:
      return {
        ...state,
        installmentsNotificationState: action.payload.enabled
          ? ENABLED
          : DISABLED,
        installmentsTerm: action.payload.term,
        installmentsPriceThreshold: action.payload.price_threshold,
        hasInstallments: true,
      };
    case SET_PAYMENT_INITIAL_DATA:
      return {
        ...state,
        paymentNotificationState:
          parseInt(action.payload.enabled, 10) === 1 ? ENABLED : DISABLED,
        emailNotification: action.payload.destination,
      };
    case SET_ENABLED_TIP:
      return {
        ...state,
        tipsEnabled: action.payload.tipsEnabled,
        tipFromService: action.payload.tipFromService,
      };
    case FETCH_TIPS_CONFIG:
      return {
        ...state,
        tipsEnabled: action.payload.tipsEnabled,
        tipFromService: action.payload.tipFromService,
        tipsComponentState: action.payload.tipsComponentState,
      };
    case SET_TIPS_STATE:
      return { ...state, tipsComponentState: action.payload.componentState };
    case HANDLE_REDUX_TIPS_STATE:
      return { ...state, ...action.payload };
    case HANDLE_TIPS_ROUNDED_STATE:
      return {
        ...state,
        tipsOptionSelected: action.payload.tipsOptionSelected,
      };
    case CANCEL_TIPS_EDITION:
      return {
        ...state,
        tipsComponentState: action.payload.tipsComponentState,
      };
    case CAN_MANAGE_TIPS:
      return { ...state, canManageTips: action.payload.canManageTips };
    default:
      return state;
  }
}
