import {
  GTM,
  AnalyticsClassList as gtmClassList,
  AnalyticsSectionList as gtmSectionsList,
  AnalyticsCategories as gtmCategories,
} from './gtm';

export const AnalyticsClassList = {
  ...gtmClassList,
};

export const AnalyticsSectionList = {
  ...gtmSectionsList,
};
export const AnalyticsCategories = {
  ...gtmCategories,
};

export const stepsEventsOnboarding = [
  {
    id: 1,
    event: 'onboarding-step1-registration',
  },
  {
    id: 2,
    event: 'onboarding-step2-emailValidation',
  },
  {
    id: 3,
    event: 'onboarding-step3-personalInfoComplete',
  },
  {
    id: 4,
    event: 'onboarding-step4-collaborators',
  },
  {
    id: 5,
    event: 'onboarding-step5-installments',
  },
  {
    id: 6,
    event: 'onboarding-step6-bankInfo',
  },
  {
    id: 7,
    event: 'onboarding-step7-summary',
  },
];

export const Analytics = class {
  static populateStepOnboarding = (
    stepId,
    evenCategory,
    evenAction,
    eventlabel,
    errors,
  ) => {
    GTM.populateStepOnboarding(
      stepId,
      evenCategory,
      evenAction,
      eventlabel,
      errors,
    );
  };
  static xpayRequestPayment = (
    event,
    evenCategory,
    evenAction,
    eventlabel,
    data,
  ) => {
    GTM.xpayRequestPayment(event, evenCategory, evenAction, eventlabel, data);
  };
};
