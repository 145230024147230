import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

export const useStyles = makeStyles((theme) => {
  return {
    OrderTotalParent: {
      borderRadius: `${theme.spacing.unit * 1}px`,
      margin: `${theme.spacing.unit * 3}px auto`,
      padding: `${theme.spacing.unit * 2.0}px ${theme.spacing.unit * 2.0}px`,
      backgroundColor: theme.palette.indigo.l96,
    },

    AmountContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.indigo.l90}`,
      padding: `${theme.spacing.unit * 1.0}px ${0}px ${theme.spacing.unit * 1.0}px ${0}px`,
    },

    MarginTop: {
      marginTop: `${theme.spacing.unit * 1.5}px`,
      textTransform: 'none !important',
    },

    Button: {
      textTransform: 'none !important',
      marginTop: `${theme.spacing.unit * 1.5}px`,
      borderRadius: `${theme.spacing.unit * 5}px`,
      height: `${theme.spacing.unit * 6.25}px`,
    },

    Desk: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    Mobil: {
      padding: `${theme.spacing.unit * 1.50}px ${theme.spacing.unit * 3}px`,
      position: 'fixed',
      bottom: '0',
      right: '0',
      left: '0',
      boxShadow: '0px 1px 6px rgb(0 0 0 / 25%)',
      zIndex: 2,
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('xl')]: {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
      TextTotal: {
      color: theme.palette.indigo.l40,
    },
  };
});

export default useStyles;
