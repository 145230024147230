import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { LOGOUT_FULFILLED } from '../components/auth/redux/types';

import ClipDevicesReducer from '../components/ClipDevices/redux/reducer';
import accountInfoReducer from '../components/account/redux/reducers';
import app from './app';
import authReducer from '../components/auth/redux';
import bankAccountReducer from './bankAccountReducer';
import berlinReducer from '../components/Berlin/redux';
import businessToolsReducer from '../components/BusinessTools/redux/reducer';
import businessUnitsManagement from '../components/BusinessUnitsManagement/redux/reducer';
import catalogReducer from '../components/Catalog/redux/reducer';
import clipReaderOrdersReducer from '../components/ClipReaderOrders/redux/reducer';
import codiReducer from '../components/Codi/redux/reducer';
import collaboratorsReducer from '../components/Collaborators/redux';
import dashboardNavReducer from './dashboardNavReducer';
import dashboardReducer from './dashboardReducer';
import depositsReducer from './depositsReducer';
import emailValidationReducer from './emailValidationReducer';
import feedbackReducer from '../components/common/Feedback/redux/reducer';
import invoicesReducer from '../components/Invoices/redux/reducer';
import ldReducers from './ldReducers';
import loansReducer from '../components/loans/redux';
import localeReducer from './localeReducer';
import merchantReducer from './merchantReducer';
import onboardingReducer from '../components/onboarding/redux/reducer';
import paymentRequestReducer from '../components/paymentrequest/redux';
import promoReducer from '../components/promos/redux/reducer';
import referralReducer from './referralReducer';
import resourceReducer from './resourceReducer';
import textReducer from './textReducer';
import topUpsReducer from '../components/TopUps/redux/reducer';
import transactionsReducer from '../components/Transactions/redux';
import userReducer from './userReducer';
import toastReducer from '../components/Toast/reducer';
import clevertapEvents from './clevertapReducer';
import onBoardV2 from '../components/onboarding/v2/redux/reducer';

import { clearToken, BUM_TOKEN_NAME } from '../helpers/tokenHelpers';

const appReducer = combineReducers({
  accountInfo: accountInfoReducer,
  app,
  auth: authReducer,
  bankAccount: bankAccountReducer,
  berlin: berlinReducer,
  bum: businessUnitsManagement,
  businessTools: businessToolsReducer,
  catalog: catalogReducer,
  clipReaderOrders: clipReaderOrdersReducer,
  codi: codiReducer,
  collaborators: collaboratorsReducer,
  dashboard: dashboardReducer,
  dashboardNav: dashboardNavReducer,
  deposits: depositsReducer,
  devices: ClipDevicesReducer,
  emailValidation: emailValidationReducer,
  feedback: feedbackReducer,
  form: formReducer,
  invoices: invoicesReducer,
  ld: ldReducers,
  loans: loansReducer,
  locale: localeReducer,
  merchant: merchantReducer,
  onboarding: onboardingReducer,
  paymentRequest: paymentRequestReducer,
  promo: promoReducer,
  referral: referralReducer,
  resources: resourceReducer,
  text: textReducer,
  topUps: topUpsReducer,
  transactions: transactionsReducer,
  user: userReducer,
  toast: toastReducer,
  clevertap: clevertapEvents,
  onBoardV2,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_FULFILLED) {
    clearToken();
    clearToken(BUM_TOKEN_NAME);
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
