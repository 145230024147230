export default {
  title: 'Revisa si eres elegible',
  subtitle: 'Estatus de tu oferta:',
  messageTitle: '¡Felicidades por tu transaccionalidad!',
  message: `Puedes recibir una oferta de hasta $50,000. 
  Realiza una consulta sin compromisos y si eres elegible te haremos llegar tres ofertas.`,
  note: 'Este proceso lo realizarás desde el sitio de KEO, nuestro aliado.',
  button: 'Iniciar solicitud',
  advice:
    '**Consejo:** Ten a la mano una copia de tu INE, comprobante de domicilio e ingresos.',
  titleDescription: '¿Qué es Adelanto de Ventas Clip?',
  messageOneDescription: `Dinero extra para que lo uses como tú quieras: 
  imprevistos, pagos pendientes o emergencias.`,
  messageTwoDescription: `Tus ofertas se calculan de acuerdo a tu historial de transacciones 
  para que puedas pagar sin afectar tus ingresos.`,
  titleNotElegible: 'Sin ofertas',
  messageNotElegible:
    'En este momento no tenemos ofertas de Adelanto de Ventas Clip para tu negocio.',
  adviceNotElegible: `**Consejo:** Realiza 2 transacciones semanales como mínimo y mantén ventas 
  por $5,000 pesos durante 2 meses con Clip.`,
  titleElegible: '¡Tu negocio es elegible!',
  congrats: (name) => `Hola, ${name}`,
  congratsMessage: '¡Tienes un adelanto de ventas pre-aprobado!',
  adviseOffer:
    'Elige una oferta, completa tu solicitud y recibe tu dinero en 24 horas hábiles:',
  adviceTermEnd: (termDate) =>
    `**Vigencia:** Tienes hasta el ${termDate} para solicitar tu Adelanto de Ventas Clip`,
  buttonAmount: 'Elegir',
  titleAmountItem: ['Crece tu negocio', 'Renuévate', 'Nuevos clientes'],
  toDeposit: 'Te depositamos',
  toPay: 'Vas a pagar',
  termMaximun: 'Plazo máximo',
  months: 'meses',
  howMuch: '¿Cuánto dinero necesitas?',
  interested: 'Me interesa',
  messageInterested: `Gracias por tu interés. En cuanto tengamos ofertas listas para tu negocio te lo haremos saber. 
  Sigue transaccionando con Clip para recibir mejores ofertas.`,
  understand: 'Entendido',
  adviceUnderstand: `**Consejo:** Realiza 2 transacciones semanales como mínimo y mantén ventas por $5,000 
  durante 2 meses con Clip.`,
  titleApproved: '¡Tu Adelanto de Ventas Clip ya está aprobado!',
  approvedSuccessful: 'La aprobación de tu Adelanto de Ventas Clip fue exitosa.',
  approvedMessage: `Desembolsaremos tu dinero en las próximas 24 horas hábiles (de lunes a viernes)  y 
  lo recibirás en tu cuenta bancaria.`,
  watchStatus: 'Ver estatus',
  titleReview: 'Espera un poquito más…',
  messageReview: `Seguimos trabajando en tu Adelanto de Ventas Clip; te enviaremos un correo de confirmación cuando 
  se desembolse el dinero.`,
  thanksReview: '¡Gracias por confiar en Adelanto de Ventas Clip!',
};
