import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { LoansDocuments } from 'constants/AppConstants';
import StyledFileUploader from 'components/loans/Commons/StyledFileUploader';
import {
  getLoansIdentityDocuments,
  getLoansProofAddressDocuments,
  getOnDragFileLoans,
} from 'components/loans/redux/selectors';
import * as Styled from 'components/loans/Commons/FileUploader/styled';
import text from './text';

export class AttachDocuments extends Component {
  static propTypes = {
    identityDocumentsRequired: PropTypes.bool.isRequired,
    proofAddressDocumentsRequired: PropTypes.bool.isRequired,
    fileUploaded: PropTypes.func,
  };

  render() {
    const {
      identityDocumentsRequired,
      identityFiles,
      proofAddressDocumentsRequired,
      proofAddressFiles,
      preapprovalSelected,
      fileUploaded,
    } = this.props;
    return (
      <Styled.FilesContainer
        className={this.props.isFileBeingDragged ? 'dragover' : ''}
      >
        <StyledFileUploader
          preapprovalSelected={preapprovalSelected}
          title={text.identityDocument}
          documentType={LoansDocuments.identity}
          accept={LoansDocuments.acceptFiles}
          maxFiles={LoansDocuments.maxFilesLoaded}
          fieldRequired={identityDocumentsRequired}
          files={identityFiles}
          fileUploaded={fileUploaded}
          idPrefix={LoansDocuments.idPrefix}
          filesCount={identityFiles.length}
          uploaderVersion={'1'}
        />
        <Styled.SeparatorFiles />
        <StyledFileUploader
          preapprovalSelected={preapprovalSelected}
          title={text.proofOfAddress}
          documentType={LoansDocuments.proofOfAddress}
          accept={LoansDocuments.acceptFiles}
          maxFiles={LoansDocuments.maxFilesLoaded}
          fieldRequired={proofAddressDocumentsRequired}
          files={proofAddressFiles}
          fileUploaded={fileUploaded}
          idPrefix={LoansDocuments.proofPrefix}
          filesCount={proofAddressFiles.length}
          uploaderVersion={'1'}
        />
      </Styled.FilesContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    identityFiles: getLoansIdentityDocuments(state),
    proofAddressFiles: getLoansProofAddressDocuments(state),
    isFileBeingDragged: getOnDragFileLoans(state),
  };
};

export default connect(mapStateToProps)(AttachDocuments);
