import React, { useState } from 'react';
import text from './text';
import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';
import { connect } from 'react-redux';
import { styles } from '../Contacts/styles';
import { Forms } from '../../../../constants/AppConstants';
import { change, formValueSelector } from 'redux-form';
import { ClipTypography, ClipButton, ClipInput } from '@clipmx/clip-storybook';
import ClipDivider from '@clipmx/clip-storybook/dist/components/Divider';
import { AccountCircleOutlined } from '@clipmx/clip-storybook/dist/components/Icons';

const useStyles = makeStyles(styles);

const Drawer = (props) => {
  const { dispatch, savedContact } = props;
  const classes = useStyles(props);

  const [alias, setAlias] = useState(null);
  const [erroAlias, setErrorAlias] = useState(false);
  const [errorAliasMessage, setErrorAliasMessage] = useState(
    text.errorNameMessage,
  );

  const validateAlias = (value) => {
    setErrorAlias(false);
    setAlias(value);
    dispatch(change(Forms.BERLIN_SPEI_OUT, 'alias', value));
    if (value.length < 3) {
      setErrorAlias(true);
      setErrorAliasMessage(text.errorNameMessage);
    }
  };

  const validateButton = () => {
    if (!alias || alias.trim() === '' || alias.length < 3) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.changeContainer}>
      <ClipTypography
        variant={'body1'}
        component={'p'}
        align="center"
        className={classes.actionTitle}
      >
        {text.saveContact}
      </ClipTypography>
      <div className={classes.contactsTitleContainer}>
        <AccountCircleOutlined className={classes.contactsIcon} />
        <ClipTypography
          variant={'body1'}
          className={classes.contactsTitle}
          component={'p'}
        >
          {text.asignAlias}
        </ClipTypography>
      </div>
      <ClipDivider className={classes.divider} />
      <ClipInput
        fullWidth
        type="text"
        color="secondary"
        inputProps={{ maxLength: 20 }}
        helperText={text.aliasHelper}
        label={text.alias}
        error={erroAlias}
        errormessage={errorAliasMessage}
        value={alias}
        onChange={(e) =>
          validateAlias(e.target.value.replace(/[^\w\s\][^,]/gi, ''))
        }
      />
      <ClipDivider className={classes.divider} />
      <ClipButton
        fullWidth
        color="primary"
        variant="contained"
        disabled={validateButton()}
        onClick={savedContact}
      >
        {text.save}
      </ClipButton>
    </div>
  );
};

const selector = formValueSelector(Forms.BERLIN_SPEI_OUT);

const mapStateToProps = (state) => {
  return {
    account: state.berlin.account_representation,
    alias: selector(state, 'alias'),
  };
};

export default connect(mapStateToProps)(Drawer);
