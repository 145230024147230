import {
  createGetRequest,
  createDeleteRequest,
} from '../../../middleware/apiResolve/reduxUtils';
export const MERCHANT_DEVICES = 'MERCHANT_DEVICES';

export function getDevices(token, onSuccess = () => {}, onFailure = () => {}) {
  const route = '/devices';
  const query = { pagination_token: token };
  return {
    type: createGetRequest(MERCHANT_DEVICES),
    payload: {
      route,
      query,
      success: onSuccess,
      failure: onFailure,
    },
  };
}

export function deleteDevice(
  deviceID,
  onSuccess = () => {},
  onFailure = () => {},
) {
  const route = `/devices/${deviceID}`;
  return {
    type: createDeleteRequest(MERCHANT_DEVICES),
    payload: {
      route,
      success: onSuccess,
      failure: onFailure,
    },
  };
}
