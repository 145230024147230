import { LOAD_ORDERS, LOAD_ORDERS_ERROR } from './types';

const initialState = {
  orders: [],
  error: null,
};

const actionHandlers = new Map([
  [LOAD_ORDERS, handleLoadOrders],
  [LOAD_ORDERS_ERROR, handleLoadOrdersError],
]);

export default function ClipReaderOrdersReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}

function handleLoadOrders(state, action) {
  const { orders } = action.payload;

  return !action.payload.loading && orders
    ? {
        ...state,
        orders,
      }
    : state;
}

function handleLoadOrdersError(state, action) {
  const message = action.payload;
  return message ? { ...state, error: message } : state;
}
