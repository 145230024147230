import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Card from '../../../../components/common/Card';
import urls from '../../../../helpers/urls';
import HourglassTopIcon from '../../../../assets/images/icons/HourglassTop.svg';
import PrestaClipBanner from '../../../../assets/images/prestaclip.svg';
import {
  LoansTabs,
  LoanTypes,
  PreapprovalStatus,
} from '../../../../constants/AppConstants';
import { getPreapprovals, getUserName } from '../../redux/selectors';
import OpenDataPayment from '../Payment';
import GoogleAnalytics from '../../GoogleAnalytics';
import LoansRedirection from '../../Commons/Redirection';
import { RefillFooter } from '../../Commons/Footer';
import OfferItem from './OfferItem';
import DesktopOffers from './Desktop';
import { ExpireWarningContent } from '../../MrPresta/styled';
import { styles } from '../styles';
import text from '../text';

export class Offers extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    preapprovals: PropTypes.array.isRequired,
  };
  state = {
    isMobile:
      this.props.location.pathname.startsWith(urls.mobile) ||
      window.innerWidth < 600,
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        isMobile:
          this.props.location.pathname.startsWith(urls.mobile) || window.innerWidth < 600,
      });
    });
  }

  render() {
    const { classes, preapprovals, username } = this.props;
    const { isMobile } = this.state;
    const typographyStyles = {
      body1: classes.typographyBody,
      body2: classes.typographyBody2,
      title: classes.typographyTitle,
      subheading: classes.typographySubTitle,
    };
    const availables = preapprovals.filter(
      ({ loan_id, status, type }) =>
        [PreapprovalStatus.AVAILABLE].includes(status) &&
        !loan_id &&
        type === LoanTypes.OPEN_DATA,
    );
    const expirationDate = availables
      .map(({ expiration_date_local }) => expiration_date_local)
      .shift();

    return (
      <LoansRedirection tab={LoansTabs.NEW_OFFER}>
        {!isMobile && availables.length > 0 && <DesktopOffers />}
        {isMobile && availables.length > 0 && (
          <GoogleAnalytics>
            <Grid
              item
              sm={12}
              xs={12}
              className={clsx(classes.gridCard, 'offers')}
            >
              <div className={classes.bannerContainer}>
                <img
                  className={classes.bannerTop}
                  alt="PrestaClip Banner"
                  src={PrestaClipBanner}
                />
              </div>
              <Card className={clsx('margin24', classes.crediCard)}>
                <div className={classes.textContainer}>
                  <Typography variant={'subheading'} classes={typographyStyles}>
                    {text.subtitle}
                  </Typography>
                  <Typography variant={'title'} classes={typographyStyles}>
                    {text.titleElegible}
                  </Typography>
                  <div className={clsx(classes.separator, 'gray')} />
                  <Typography variant={'body2'} classes={typographyStyles}>
                    {text.congrats(username)}
                    <br />
                    {text.congratsMessage}
                  </Typography>
                  <Typography variant={'body1'} classes={typographyStyles}>
                    {text.adviseOffer}
                  </Typography>
                </div>
                <div className={clsx(classes.alertContainer, 'notTopMargin')}>
                  <div className={classes.expireWarning}>
                    <img
                      className={classes.calcLeftIcon}
                      alt="PrestaClip Calc"
                      src={HourglassTopIcon}
                    />
                    <ExpireWarningContent>
                      <ReactMarkdown
                        source={text.adviceTermEnd(
                          moment(expirationDate, 'YYYY-MM-DD').format('L'),
                        )}
                      />
                    </ExpireWarningContent>
                  </div>
                </div>
                <div className={classes.offersContainer}>
                  {preapprovals
                    .filter(
                      ({ status, loan_id }) =>
                        PreapprovalStatus.AVAILABLE === status && !loan_id,
                    )
                    .sort((a, b) => b.amount - a.amount)
                    .map((preapproval, index) => (
                      <OfferItem
                        preapproval={preapproval}
                        index={index}
                        key={`OD_offer_${index}`}
                      />
                    ))}
                </div>
              </Card>
              <OpenDataPayment collapsable={false} />
              <RefillFooter />
            </Grid>
          </GoogleAnalytics>
        )}
      </LoansRedirection>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    preapprovals: getPreapprovals(state),
    username: getUserName(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(Offers))
);

