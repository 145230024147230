import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Card from '../../../../components/common/Card';
import InterestedImage from '../../../../assets/images/prestamax.svg';
import CalcIcon from '../../../../assets/images/icons/calc.svg';
import PrestaClipBanner from '../../../../assets/images/prestaclip.svg';
import { LoansTabs } from '../../../../constants/AppConstants';
import getUrl from '../../../../helpers/getUrl';
import urls from '../../../../helpers/urls';
import GoogleAnalytics from '../../GoogleAnalytics';
import LoansRedirection from '../../Commons/Redirection';
import { RefillFooter } from '../../Commons/Footer';
import { ExpireWarningContent } from '../../MrPresta/styled';
import Description from '../Description';
import { styles } from '../styles';
import { BlueButton } from '../styled';
import text from '../text';

export const Interested = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { classes, amountSentForElegible } = props;
  const typographyStyles = {
    body1: classes.typographyRoot,
    title: classes.typographyTitle,
    subheading: classes.typographySubTitle,
  };

  console.log('LOCATION', location.pathname);
  return (
    <LoansRedirection tab={LoansTabs.NEW_OFFER}>
      {amountSentForElegible && (
        <GoogleAnalytics>
          <Grid item sm={12} xs={12} className={classes.gridCard}>
            <div className={classes.bannerContainer}>
              <img
                className={classes.bannerTop}
                alt="PrestaClip Banner"
                src={PrestaClipBanner}
              />
            </div>
            <Card className={clsx('margin24', classes.crediCard)}>
              <div className={classes.textContainer}>
                <Typography variant={'subheading'} classes={typographyStyles}>
                  {text.subtitle}
                </Typography>
                <Typography variant={'title'} classes={typographyStyles}>
                  {text.titleNotElegible}
                </Typography>
                <div className={classes.separator} />
                <Typography variant={'body1'} classes={typographyStyles}>
                  {text.messageInterested}
                </Typography>
              </div>
              <div className={classes.container}>
                <img
                  className={classes.bannerInterested}
                  alt="PrestaClip Interested"
                  src={InterestedImage}
                />
                {!location.pathname.startsWith(urls.mobile) && (
                  <BlueButton
                    className={classes.blueButton}
                    variant="contained"
                    onClick={() => history.push(getUrl(urls.dashboard))}
                  >
                    {text.understand}
                  </BlueButton>
                )}
              </div>
              <div className={clsx(classes.alertContainer, 'notElegible')}>
                <div className={classes.expireWarning}>
                  <img
                    className={classes.calcLeftIcon}
                    alt="PrestaClip Calc"
                    src={CalcIcon}
                  />
                  <ExpireWarningContent>
                    <ReactMarkdown source={text.adviceUnderstand} />
                  </ExpireWarningContent>
                </div>
              </div>
            </Card>
            <Card className={clsx('margin24', classes.crediCard)}>
              <div className={classes.descriptionContainer}>
                <Description />
              </div>
            </Card>
            <RefillFooter />
          </Grid>
        </GoogleAnalytics>
      )}
    </LoansRedirection>
  );
};

Interested.propTypes = {
  classes: PropTypes.object.isRequired,
  amountSentForElegible: PropTypes.bool.isRequired,
};
Interested.defaultProps = {
  classes: {},
  amountSentForElegible: true,
};

const mapStateToProps = (state) => {
  return {
    amountSentForElegible: state.loans.amountSentForElegible,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Interested));
