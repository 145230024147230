import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getNavActiveTab } from '../../../../reducers/dashboardNavReducer';
import getUrl from '../../../../helpers/getUrl';

import * as Styled from '../styled';

class Tab extends Component {
  static propTypes = {
    activeSecondaryTab: PropTypes.bool,
  };

  static defaultProps = {
    activeSecondaryTab: false,
  };

  render() {
    let isTabActive = this.props.activeTab === this.props.tab;

    if (!isTabActive && this.props.activeSecondaryTab) {
      isTabActive = this.props.activeSecondaryTab;
    }

    return (
      <Styled.Tab active={isTabActive} id={this.props.id}>
        <Link onClick={this.props.onClick} to={getUrl(this.props.url)}>
          {this.props.name}
        </Link>
      </Styled.Tab>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeTab: getNavActiveTab(state),
  };
}

export default connect(mapStateToProps)(Tab);
