import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { styles } from './styles';
import clsx from 'clsx';

const RadioField = (props) => {
  const {
    classes,
    input,
    children = {},
    options,
    disabled = false,
    meta: { touched, error },
    ...rest
  } = props;

  const radioStyles = { checked: classes.radioChecked };
  const errorStyles = { root: classes.errorRoot };

  return (
    <FormControl fullWidth>
      <RadioGroup value={input.value} {...input} {...rest}>
        {options
          ? options.map((option, key) => (
              <FormControlLabel
                classes={{
                  root: clsx(
                    classes.formControlLabelRoot,
                    input.value === option.id &&
                      classes.formControlLabelFocused,
                    disabled && classes.formControlLabelDisabled,
                  ),
                }}
                key={key}
                value={option.id}
                label={option.name}
                control={<Radio disabled={disabled} classes={radioStyles} />}
              />
            ))
          : children}
      </RadioGroup>
      {touched && error && (
        <FormHelperText classes={errorStyles}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

RadioField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.object,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(RadioField);
