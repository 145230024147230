export default {
  submitButton: 'Activar',
  cardNumber: 'Número de Clip Card',
  requestFlowTitle: 'Quiero mi Clip Card',
  title: 'Ingresa el número de tu Clip Card',
  streets: 'Número de Clip Card',
  help: 'Ayuda',
  tooltipTitle: '¿Dónde está el número de mi Clip Card?',
  tooltipAdvice: 'Copia estos 16 dígitos.',
  tooltipButton: 'Entendido',
  rejectedTitle: 'No eres tú, somos nosotros.',
  rejectedMessage: 'Por favor, inténtalo de nuevo.',
};
