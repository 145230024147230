export default {
  title: 'Completa tu información',
  subtitle: 'Datos personales',
  subtitle2: 'Escríbelos tal como aparecen en tu identificación oficial.',
  name: 'Nombre(s)',
  lastName: 'Apellido paterno',
  secondLastName: 'Apellido materno',
  legalGender: '¿Cuál es tu sexo legal?',
  female: 'Femenino',
  male: 'Masculino',
  birthDate: 'Fecha de nacimiento',
  birthPlace: 'Lugar de nacimiento',
  occupation: '¿A qué te dedicas?',
  businessSector: '¿Cuál es el giro de tu negocio?',
  rfcCurp: 'CURP o RFC con homoclave',
  address: 'Dirección',
  addressReminder: 'Ingresa la dirección de tu domicilio actual. ',
  street: 'Calle',
  externalNumber: 'Número exterior',
  internalNumber: 'Número interior',
  city: 'Ciudad',
  postalCode: 'Código postal',
  colony: 'Colonia',
  municipality: 'Delegación o municipio',
  state: 'Estado',
  contact: 'Contacto',
  contactDescription:
    'Si es necesario, te contactaremos con información de tu Clip Card en:',
  phone: 'Teléfono',
  email: 'Correo electrónico',
  buttonText: 'Siguiente',
};
