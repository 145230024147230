import React, { Component } from 'react';

export default class BerlinFileInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const {
      input: { onChange },
    } = this.props;
    onChange(e.target.files[0]);
  }

  render() {
    const { refName } = this.props;
    return (
      <input
        style={{ width: '0.1px', height: '0.1px', opacity: '0' }}
        type="file"
        accept=".jpg"
        onChange={this.onChange}
        capture="camera"
        ref={refName}
      />
    );
  }
}
