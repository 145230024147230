import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

export const useStyles = makeStyles((theme) => {
  return {
    cardgris: {
      borderRadius: '8px',
      background: '#FAFAFA',
      marginTop: '30px',
    },
    colorBlue: {
      color: '#0074B7',
      textDecoration: 'revert',
      fontWeight: '500',
    },
  };
});

export default useStyles;