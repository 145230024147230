import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CollapsableSection from '../CollapsableSection';

const Accordion = ({ data, showIndex, strong }) => {
    const [openState, setOpenState] = useState(0);

    useEffect(() => {
        if (data) {
            setOpenState(data.map(e => false));
        }
    }, [data],
    )
    return (<div>
        {data.map((e, i) => (
            <CollapsableSection
                showIndex={showIndex}
                strong={strong}
                key={`accordion_element_${e.title}`}
                index={i + 1}
                title={e.title}
                body={e.body}
                isOpen={openState}
                open={setOpenState}
            />
        ))}
    </div>)
}

Accordion.propTypes = {
    data: PropTypes.array,
    showIndex: PropTypes.bool,
    strong: PropTypes.bool,
}

Accordion.defaultProps = {
    data: [],
    showIndex: true,
    strong: true,
}

export default Accordion;