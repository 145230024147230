import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

const supportBase = () => ({
  cursor: 'pointer',
  padddingRight: '8px',
});

const dividerBase = () => ({
  marginLeft: '16px',
  marginRight: '16px',
  width: '1px',
  height: '18px',
});

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: 'auto',
    },
    logo: {
      cursor: 'pointer',
    },
    support: {
      ...supportBase(),
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    supportSwitched: {
      ...supportBase(),
    },
    toolbar: {
      justifyContent: 'center',
      paddingTop: '4px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: 8,
      },
    },
    toolbarChild: {
      maxWidth: '100%',
      width: '1450px',
      justifyContent: 'space-between',
      display: 'flex',
    },
    divider: {
      background: theme.palette.indigo.l80,
      ...dividerBase(),
    },
    dividerSwitched: {
      background: theme.palette.orange.l50,
      ...dividerBase(),
    },
    endAdormentContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  };
});

export default useStyles;
