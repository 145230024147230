import { makeStyles } from '@clipmx/clip-storybook/dist/theme/styles';

const inlineBlock = 'inline-block';

const useStyles = makeStyles((theme) => {
  console.log(theme.typography)
  return {
    helpButton: {
      position: 'relative',
      top: '6px',
      width: '22px',
      height: '22px',
      cursor: 'pointer',
      marginLeft: '8px',
    },
    imageTitleStyles: {
      display: 'inline-block',
      width: '200px',
      height: '170px',
      padding: '12px',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    imageTitleMobileStyles: {
      marginTop: `${theme.spacing.unit}px`,
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        margin: 'auto',
      },
    },
    bannerTitleStyles: {
      marginTop: 12,
      marginBottom: 10,
      width: `${theme.spacing.unit * 28.75}px`,
      height: `${theme.spacing.unit * 6.5}px`,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: `${-theme.spacing.unit}px`,
      },
    },
    textTitleStyles: {
      margin: 0,
      fontSize: `${theme.spacing.unit * 2.5}px`,
      fontWeight: 500,
      fontStretch: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: '#001724',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0',
        display: 'inline-block',
        width: '100%',
        textAlign: 'center',
        fontWeight: 400,
      },
    },
    helpIconStyles: {
      fontSize: '20px',
      position: 'relative',
      top: '2px',
      marginLeft: '8px',
    },
    textKnowOfferStyles: {
      textAlign: 'center',
      marginTop: `${theme.spacing.unit * 2.5}px`,
      color: theme.palette.teal.l10,
      paddingBottom: '5px',
      '&:hover': {
        backgroundColor: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      
    },
    textSubtitleStyles: {
      fontSize: `${theme.spacing.unit * 2.25}px`,
      fontWeight: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#001724',
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block',
        width: '100%',
        fontSize: `${theme.spacing.unit * 2}px`,
      },
    },
    expiredWarningStyles: {
      width: 'fit-content',
      marginTop: `${theme.spacing.unit * 2}px`,
      marginBottom: `${theme.spacing.unit * 2}px`,
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      border: 'solid 1px rgba(0, 125, 142, 0.08)',
      borderLeft: 'solid 4px #007d8e',
      backgroundColor: 'rgba(0, 125, 142, 0.06)',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    expiredWarningMobileStyles: {
      width: 'fit-content',
      marginTop: `${theme.spacing.unit * 2}px`,
      marginBottom: `${theme.spacing.unit * 2}px`,
      borderRadius: `${theme.spacing.unit * 0.5}px`,
      border: 'solid 1px rgba(0, 125, 142, 0.08)',
      borderLeft: 'solid 4px #007d8e',
      backgroundColor: 'rgba(0, 125, 142, 0.06)',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    expiredWarningContentStyles: {
      display: 'inline-block',
      fontSize: `${theme.spacing.unit * 1.75}px`,
      color: '#007d8e',
      paddingRight: '30px',
      '& > p': {
        marginTop: `${theme.spacing.unit * 1.5}px`,
        marginBottom: `${theme.spacing.unit * 1.5}px`,
      },
      [theme.breakpoints.down('xs')]: {
        '& > p': {
          marginTop: `${theme.spacing.unit}px`,
          marginBottom: `${theme.spacing.unit}px`,
        },
      },
    },
    hourglassTopStyles: {
      display: 'inline-block',
      position: 'relative',
      marginRight: `${theme.spacing.unit * 0.75}px`,
      verticalAlign: 'middle',
      top: `-${theme.spacing.unit * 0.25}px`,
      marginTop: `${theme.spacing.unit}px`,
      marginBottom: `${theme.spacing.unit}px`,
      marginLeft: `${theme.spacing.unit}px`,
      color: theme.palette.teal.l10,
      [theme.breakpoints.down('xs')]: {
        top: '-12px',
        marginTop: 0,
        marginBottom: 0,
      },
    },
    bannerWarningStyles: {
      [theme.breakpoints.down('xs')]: {
        '& > p': {
          fontSize: '16px',
        },
      },
    },
    instructionStyles: {
      width: `${theme.spacing.unit * 83.5}px`,
      position: 'relative',
      display: inlineBlock,
      paddingLeft: `${theme.spacing.unit * 2}px`,
      paddingRight: `${theme.spacing.unit * 2}px`,
      color: '#001724',
      fontSize: `${theme.spacing.unit * 2.25}px`,
      fontWeight: theme.typography.fontWeightLight,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        paddingLeft: '0',
        textAlign: 'center',
      },
    },
    stepsTitleStyles: {
      marginTop: `${theme.spacing.unit * 7}px`,
      marginBottom: `${theme.spacing.unit * 2}px`,
      color: '#001724',
      fontSize: `${theme.spacing.unit * 2.75}px`,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.33,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: `${theme.spacing.unit * 4}px`,
        fontSize: theme.typography.h1.fontSize,
        lineHeight: `${theme.spacing.unit * 3.25}px`,
      },
    },
    stepsContainerStyles: {
      width: '100%',
      marginTop: `${theme.spacing.unit * 4}px`,
      marginBottom: `${theme.spacing.unit * 4}px`,
      display: 'flex',
      color: theme.palette.naranja.slate,
      '& > div:not(:first-child)': {
        marginLeft: `${theme.spacing.unit * 2.5}px`,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'inline-grid',
        marginTop: `${theme.spacing.unit * 2}px`,
        '& > div:not(:first-child)': {
          marginLeft: 0,
        },
      },
    },
    stepStyles: {
      width: `${theme.spacing.unit * 27.5}px`,
      position: 'relative',
      display: 'block',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: `${theme.spacing.unit * 3}px`,
      },
    },
    stepIconContainerStyles: {
      display: 'inline-flex',
      verticalAlign: 'middle',
    },
    stepIconStyles: {
      width: '40px',
      height: '40px',
      display: 'block',
    },
    stepTitleStyles: {
      width: '100%',
      marginTop: `${theme.spacing.unit * 1}px`,
      display: inlineBlock,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightMedium,
      color: '#001724',
      [theme.breakpoints.down('xs')]: {
        fontSize: `${theme.spacing.unit * 2.25}px`,
      },
    },
    stepDescriptionStyles: {
      width: '100%',
      minWidth: `${theme.spacing.unit * 12.5}px`,
      marginTop: `${theme.spacing.unit * 1}px`,
      display: inlineBlock,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightLight,
      lineHeight: `${theme.spacing.unit * 2.5}px`,
      color: '#001724',
      [theme.breakpoints.down('xs')]: {
        width: '90%',
        textAlign: 'center',
        fontSize: `${theme.spacing.unit * 2.25}px`,
      },
    },
  }
})

export default useStyles;
