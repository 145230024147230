import React from 'react';
import Input from 'components/npm/clip-ui/Input';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';

import { rgba } from 'polished';
import styled from '@emotion/styled';

import grey from '@material-ui/core/colors/grey';
import { withStyles } from '@material-ui/core/styles';
import { Select as MUISelect } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import styledWithClasses from '../../../../helpers/StyledWithClasses';
import NumberFormat from 'react-number-format';

import { TermsCheckbox } from './TermsCheckbox';

export const renderInput = ({
  input,
  label,
  helperText,
  meta: { touched, error },
  inputProps,
  ...rest
}) => (
  <Input
    InputProps={{
      inputProps: inputProps,
      ...inputProps,
    }}
    label={label}
    error={error && touched}
    helperText={(touched && error) || helperText}
    color="primary"
    {...input}
    {...rest}
  />
);

export const renderMaskedInput = ({
  input,
  label,
  helperText,
  meta: { touched, error },
  ...rest
}) => (
  <NumberFormat
    label={label}
    error={error && touched}
    helperText={(touched && error) || helperText}
    color="primary"
    {...input}
    {...rest}
    customInput={Input}
  />
);

export const renderRadio = ({
  input,
  label,
  name,
  meta: { touched, error },
  ...rest
}) => (
  <Radio
    label={label}
    name={name}
    error={error && touched}
    color="primary"
    {...input}
    {...rest}
  />
);

export const renderSelectField = ({
  input,
  label,
  children,
  options,
  meta: { touched, error },
  ...rest
}) => (
  <FormControl fullWidth {...rest}>
    <FormInputLabel style={{ paddingLeft: '8px' }}>{label}</FormInputLabel>
    <Select {...input} onChange={(event) => input.onChange(event.target.value)}>
      {options
        ? options.map((option, key) => (
            <MenuItem key={key} value={option.id} disabled={option.disabled}>
              {option.name}
            </MenuItem>
          ))
        : children}
    </Select>
    {touched && error && (
      <FormHelperText style={{ paddingLeft: '8px' }} error>
        {error}
      </FormHelperText>
    )}
  </FormControl>
);

export const Select = styledWithClasses(
  styled(MUISelect)(({ theme }) => ({
    textAlign: 'left',
    '& .helper-select-icon': {
      color: rgba(theme.palette.primary.main, 0.7),
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.naranja.bloodOrange}`,
    },
  })),
  {
    icon: 'helper-select-icon',
  },
);

export const renderCheckbox = ({
  input,
  label,
  labelWithLinks,
  meta: { touched, error },
  ...rest
}) => (
  <FormControl fullWidth {...rest}>
    <TermsCheckbox
      label={label}
      labelWithLinks={labelWithLinks}
      error={error}
      input={input}
      rest={rest}
      touched={touched}
    />
  </FormControl>
);

export const renderRadioGroup = ({
  input,
  children,
  meta: { touched, error },
  ...rest
}) => (
  <RadioGroup
    row
    {...input}
    {...rest}
    valueselected={input.value}
    onChange={(event, value) => input.onChange(value)}
  >
    {children}
    {touched && error && <ErrorMessage>{error}</ErrorMessage>}
  </RadioGroup>
);

export const FormInputLabel = withStyles((theme) => ({
  root: {
    color: grey[500],
  },
}))(InputLabel);

export const ErrorMessage = styled.span(({ theme }) => ({
  width: '100%',
  color: theme.palette.error.main,
  margin: 0,
  fontSize: `${theme.spacing.unit * 1.5}px`,
  textAlign: 'left',
  minHeight: `${theme.spacing.unit * 2}px`,
  lineHeight: `${theme.spacing.unit * 2}px`,
}));
