const styles = (theme) => ({
  centerContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 24px',
  },
  icon: {
    width: '210px',
    margin: 'auto',
    marginBottom: '-15px',
  },
  message: {
    marginTop: '17px',
  },
  subtext: {
    fontWeight: 'bold',
  },
});
export default styles;
