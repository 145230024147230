import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import utils from '../../../../helpers/utils';
import getUrl, { getProviderCashAdvanceURL } from '../../../../helpers/getUrl';
import urls from '../../../../helpers/urls';
import { getProviderCodeForURL } from '../../../../helpers/getProviderUrl';
import { loansProviderCodes } from '../../../../constants/ProviderCodes';
import {
  CollaboratorRoles,
  Forms,
  LoanTypes,
  LoansStates,
  LoansTabs,
  PreapprovalStatus,
} from '../../../../constants/AppConstants';
import {
  getPreapprovals,
  getPreapprovalSelected,
  isLoansFormSent,
  preapprovalsLoaded,
} from '../../redux/selectors';
import LoansContent from '../LoansContent';

const regexCanceled = RegExp(/\/loans\/[mot,aph,mrp,cto,r2,avl]+\/canceled/g);
const regexRefill = RegExp(/\/loans\/[mot,aph,mrp,cto,r2,avl]+\/refill/g);
const regexRefillCongrats = RegExp(
  /\/loans\/(mot|aph|mrp|cto|r2|avl){1}\/congrats\/refill[/]*/g,
);
//const regexPersonalized = RegExp(/\/loans\/personalized\/[mot,aph,mrp,cto,r2,avl]+/g);
const regexPersonalized = RegExp(
  /\/loans\/personalized\/(mot|aph|mrpresta|cto|r2|avl){1}(\/v[1-4]){0,1}(\/ca){0,1}[/]*/);
const regexPersonalizedCongrats = RegExp(
  /\/loans\/(mot|aph|mrp|cto|r2|avl){1}\/congrats\/personalized[/]*/g,
);
const regexAcction = RegExp(
  /\/loans\/[mot,aph,mrp,cto,r2,avl]+\/congrats\/[\w]+[/]*/g,
);
const regexPersonalizedAction = RegExp(
  /\/loans\/personalized\/[mot,aph,mrp,cto,r2,avl]+\/congrats\/[\w]+[/]*/g,
);
const regexConfirm = RegExp(
  /\/loans\/[mot,aph,mrp,cto,r2,avl]+\/refill\/confirmation[/]*/g,
);
const regexSingleLoans = RegExp(
  /\/loans\/(mot|aph|mrpresta|cto|r2|avl){1}(\/v[1-4]){0,1}(\/ca){0,1}[/]*/g,
);
const regexCongrats = RegExp(
  /\/loans\/congrats\/(mot|aph|mrpresta|cto|r2|avl){1}(\/v[1-4]){0,1}(\/ca){0,1}[/]*/g,
);

export class LoansRedirection extends Component {
  getBaseLocation = () => {
    return this.props.location.pathname.startsWith(urls.mobile)
      ? urls.mobile
      : '';
  };

  getUrlForOrigin = (providerCode) => {
    const { showCashAdvance } = this.props;
    const providerCodeForURL = getProviderCodeForURL(providerCode);

    return getProviderCashAdvanceURL(
      showCashAdvance,
      providerCodeForURL,
      urls.loansV4CA,
      urls.loansV4,
    );
  };
  getUrlForPersonalizedOrigin = (providerCode) => {
    const { showCashAdvance } = this.props;
    const providerCodeForURL = getProviderCodeForURL(providerCode);

    return getProviderCashAdvanceURL(
      showCashAdvance,
      providerCodeForURL,
      urls.loansPersonalizedV4CA,
      urls.loansPersonalizedV4,
    );
  };
  getUrlForCongrats = (providerCode) => {
    const { showCashAdvance } = this.props;
    const providerCodeForURL = getProviderCodeForURL(providerCode);
    
    return getProviderCashAdvanceURL(
      showCashAdvance,
      providerCodeForURL,
      urls.congratsV4CA,
      urls.congratsV4,
    );
  };
  getURLWhenPreApprovalSelected = (currentURL, providerCode) => {
    const { showCashAdvance } = this.props;
    const providerCodeForURL = getProviderCodeForURL(providerCode);

    if (currentURL.indexOf('refill') >= 0) {
      return currentURL;
    }

    return getProviderCashAdvanceURL(
      showCashAdvance,
      providerCodeForURL,
      urls.loansFormIdentityV4CA,
      urls.loansFormIdentityV4,
    );
  };
  getExpiredURL = (loansFormVersion, providerCode) => {
    return urls.preapprovalsExpiredV4;
  };
  getAvailables = (preapprovals) =>
    preapprovals.filter(
      (preapproval) =>
        [
          PreapprovalStatus.AVAILABLE,
          PreapprovalStatus.ACCEPTED,
          PreapprovalStatus.SIGNED,
          PreapprovalStatus.ACTIVE,
        ].includes(preapproval.status) && !preapproval.loan_id,
    );
  getWithLoans = (preapprovals) =>
    preapprovals.filter(
      (preapproval) =>
        preapproval.loan_id ||
        [
          PreapprovalStatus.ACTIVE,
          PreapprovalStatus.PAID,
          PreapprovalStatus.ON_HOLD,
        ].includes(preapproval.status) ||
        ([PreapprovalStatus.CANCELLED, PreapprovalStatus.CANCELED].includes(
          preapproval.status,
        ) &&
          preapproval.loan_id),
    );
  filterByStatus = (status) => (preapproval) => preapproval.status === status;

  getLastUpdatedPreapproval = (preapprovals) =>
    preapprovals && preapprovals.length > 0
      ? preapprovals.reduce((preapproval, currentPreapproval) => {
          const timePreapproval = utils.getTimeFromStrDate(
            preapproval.created_at,
          );
          const timeCurrentPreapproval = utils.getTimeFromStrDate(
            currentPreapproval.created_at,
          );
          if (timePreapproval > timeCurrentPreapproval) return preapproval;
          if (timePreapproval < timeCurrentPreapproval)
            return currentPreapproval;
          if (
            timePreapproval === timeCurrentPreapproval &&
            preapproval.status !== PreapprovalStatus.DECLINED
          )
            return preapproval;
          if (timePreapproval === timeCurrentPreapproval)
            return currentPreapproval;
          return {};
        })
      : {};

  /**Redirection methods */
  redirectWhenNotAuthorized = () => {
    const { loginActive, role } = this.props;
    if (
      this.getBaseLocation() !== urls.mobile &&
      role !== CollaboratorRoles.ADMIN
    ) {
      this.props.history.push(getUrl(urls.dashboard));
      return false;
    }
    if (!loginActive && this.getBaseLocation() === urls.mobile) {
      this.props.history.push(
        getUrl(`${this.getBaseLocation()}${urls.loansFAQs}`),
      );
      return false;
    }
    return true;
  };
  redirectWhenPreapprovalsExpired = () => {
    const { isFormSent, preapprovals = [], loansFormVersion } = this.props;
    const availables = this.getAvailables(preapprovals);
    const expired = preapprovals.filter((preapproval) =>
      [PreapprovalStatus.EXPIRED].includes(preapproval.status),
    );
    const lastPreapprovalExpired =
      this.getLastUpdatedPreapproval(preapprovals).status ===
      PreapprovalStatus.EXPIRED;
    const currentURL = this.props.location.pathname.replace(urls.mobile, '');

    if (
      !isFormSent &&
      loansFormVersion !== '' &&
      availables.length === 0 &&
      expired.length > 0 &&
      lastPreapprovalExpired &&
      currentURL.indexOf('expired') < 0
    ) {
      const { provider_code: providerCode } = expired[0];
      const url = this.getExpiredURL(loansFormVersion, providerCode).replace(
        ':lender',
        getProviderCodeForURL(providerCode),
      );
      this.props.history.push(getUrl(`${this.getBaseLocation()}${url}`));
      return false;
    }
    return true;
  };
  redirectWhenThereAreOnlyLoans = () => {
    const { preapprovals = [], tab } = this.props;
    const availables = this.getAvailables(preapprovals);
    const withLoans = this.getWithLoans(preapprovals);
    const expired = preapprovals.filter((preapproval) =>
      [PreapprovalStatus.EXPIRED].includes(preapproval.status),
    );
    const lastPreapprovalIsCanceled = [
      PreapprovalStatus.CANCELLED,
      PreapprovalStatus.CANCELED,
    ].includes(this.getLastUpdatedPreapproval(preapprovals).status);

    if (
      availables.length === 0 &&
      expired.length === 0 &&
      !lastPreapprovalIsCanceled &&
      withLoans.length > 0 &&
      tab !== LoansTabs.CREDI_CLIP
    ) {
      this.props.history.push(
        getUrl(`${this.getBaseLocation()}${urls.loansMyPrestaClip}`),
      );
      return false;
    }
    return true;
  };
  redirectWhenStatusDoesntExist = () => {
    const { preapprovals = [] } = this.props;

    const anyStatus = preapprovals.filter(
      (preapproval) => preapproval.status in PreapprovalStatus,
    );
    if (preapprovals.length > 0 && anyStatus.length === 0) {
      this.props.history.push(getUrl(urls.dashboard));
      return false;
    }
    return true;
  };
  redirectWhenIsCongrats = () => {
    const {
      isFormSent,
      preapprovals = [],
      flags: { loansAphSignShowFeatureWeb: signAvailable },
    } = this.props;
    const currentURL = this.props.location.pathname.replace(urls.mobile, '');
    const accepted = preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.ACCEPTED].includes(preapproval.status) &&
        !preapproval.loan_id,
    );
    if (
      preapprovalsLoaded &&
      isFormSent &&
      accepted.length > 0 &&
      accepted[0].type === LoanTypes.OPEN_DATA &&
      currentURL !== urls.loansOpenDataApproved
    ) {
      this.props.history.push(
        getUrl(`${this.getBaseLocation()}${urls.loansOpenDataApproved}`),
      );
      return false;
    }
    if (
      isFormSent &&
      accepted.length > 0 &&
      currentURL.indexOf('congrats') < 0 &&
      accepted[0].type === LoanTypes.CUSTOM
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${urls.congratsPersonalized.replace(
            ':lender',
            accepted[0].provider_code.toLowerCase(),
          )}`,
        ),
      );
      return false;
    }
    if (
      isFormSent &&
      accepted.length > 0 &&
      currentURL.indexOf('congrats') < 0
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${urls.congrats.replace(
            ':lender',
            accepted[0].provider_code.toLowerCase(),
          )}`,
        ),
      );
      return false;
    }
    if (
      isFormSent &&
      accepted.length > 0 &&
      (accepted[0].provider_code !== loansProviderCodes.APH ||
        !signAvailable ||
        (accepted[0].provider_code === loansProviderCodes.APH &&
          !accepted[0].signature_redirect_url)) &&
      (currentURL.indexOf('forsign') > 0 || currentURL.indexOf('signed') > 0)
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${urls.congrats.replace(
            ':lender',
            accepted[0].provider_code.toLowerCase(),
          )}`,
        ),
      );
      return false;
    }
    return true;
  };

  redirectWhenIsInReview = () => {
    const {
      isFormSent,
      preapprovals = [],
      flags: { loansAphSignShowFeatureWeb: signAvailable },
    } = this.props;
    const currentURL = this.props.location.pathname.replace(urls.mobile, '');
    const availables = preapprovals.filter(
      this.filterByStatus(PreapprovalStatus.AVAILABLE),
    );
    const accepted = preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.ACCEPTED].includes(preapproval.status) &&
        !preapproval.loan_id,
    );
    const signed = preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.SIGNED].includes(preapproval.status) &&
        !preapproval.loan_id,
    );

    const refillAvailable = preapprovals.filter(
      (preapproval) =>
        [LoansStates.REFILL].includes(preapproval.type) &&
        [PreapprovalStatus.AVAILABLE].includes(preapproval.status),
    );
    const lender =
      signed.length > 0
        ? signed[0].provider_code.toLowerCase()
        : accepted.length > 0
        ? accepted[0].provider_code.toLowerCase()
        : '';
    const type =
      signed.length > 0
        ? signed[0].type
        : accepted.length > 0
        ? accepted[0].type
        : LoanTypes.REGULAR;
    if (
      availables.length === 0 &&
      (accepted.length > 0 || signed.length > 0) &&
      lender === loansProviderCodes.APH.toLowerCase() &&
      signAvailable &&
      type === LoanTypes.REGULAR &&
      currentURL.indexOf('signed') > 0
    ) {
      return true;
    }
    if (
      availables.length === 0 &&
      !isFormSent &&
      accepted.length === 1 &&
      type === LoanTypes.OPEN_DATA &&
      lender === loansProviderCodes.MOT.toLowerCase() &&
      currentURL !== urls.loansOpenDataInReview
    ) {
      this.props.history.push(
        getUrl(`${this.getBaseLocation()}${urls.loansOpenDataInReview}`),
      );
      return false;
    }
    if (
      (availables.length > 0 || refillAvailable.length > 0) &&
      (currentURL === urls.inreview || regexAcction.test(currentURL) 
      || currentURL === urls.loansPersonalizedInReview || regexPersonalizedAction.test(currentURL))
    ) {
      this.props.history.replace(
        getUrl(`${this.getBaseLocation()}${urls.loans}`),
      );
      return false;
    }
    if (
      !isFormSent &&
      refillAvailable.length === 0 &&
      availables.length === 0 &&
      accepted.length === 1 &&
      lender === loansProviderCodes.APH.toLowerCase() &&
      type === LoanTypes.REGULAR &&
      signAvailable &&
      accepted[0].signature_redirect_url &&
      (currentURL.indexOf('forsign') < 0 || !regexAcction.test(currentURL))
    ) {
      this.props.history.replace(
        getUrl(
          `${this.getBaseLocation()}${urls.loansCongratsAction
            .replace(':lender', lender)
            .replace(':action', 'forsign')}`,
        ),
      );
      return false;
    }
    if (
      !isFormSent &&
      refillAvailable.length === 0 &&
      availables.length === 0 &&
      accepted.length === 1 &&
      ![LoanTypes.OPEN_DATA, LoanTypes.REFILL].includes(type) &&
      type === LoanTypes.CUSTOM &&
      currentURL !== urls.loansPersonalizedInReview &&
      (lender !== loansProviderCodes.APH.toLowerCase() ||
        !signAvailable ||
        !accepted[0].signature_redirect_url)
    ) {
      this.props.history.push(
        getUrl(`${this.getBaseLocation()}${urls.loansPersonalizedInReview}`),
      );
      return false;
    }
    if (
      !isFormSent &&
      refillAvailable.length === 0 &&
      availables.length === 0 &&
      accepted.length === 1 &&
      ![LoanTypes.OPEN_DATA, LoanTypes.REFILL, LoanTypes.CUSTOM].includes(type) &&
      currentURL !== urls.inreview &&
      (lender !== loansProviderCodes.APH.toLowerCase() ||
        !signAvailable ||
        !accepted[0].signature_redirect_url)
    ) {
      this.props.history.push(
        getUrl(`${this.getBaseLocation()}${urls.inreview}`),
      );
      return false;
    }
    if (
      !isFormSent &&
      refillAvailable.length === 0 &&
      availables.length === 0 &&
      signed.length === 1 &&
      lender === loansProviderCodes.APH.toLowerCase() &&
      ![LoanTypes.OPEN_DATA, LoanTypes.REFILL, LoanTypes.CUSTOM].includes(type) &&
      currentURL !== urls.inreview
    ) {
      this.props.history.push(
        getUrl(`${this.getBaseLocation()}${urls.inreview}`),
      );
      return false;
    }
    if (
      isFormSent &&
      refillAvailable.length === 0 &&
      availables.length === 0 &&
      accepted.length === 1 &&
      type === LoanTypes.REPEAT &&
      currentURL.indexOf('/repeat') < 0
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${urls.loansCongratsAction
            .replace(':lender', lender)
            .replace(':action', 'repeat')}`,
        ),
      );
      return false;
    }
    if (
      !isFormSent &&
      refillAvailable.length === 0 &&
      availables.length === 0 &&
      accepted.length === 1 &&
      type === LoanTypes.REPEAT &&
      currentURL !== urls.inreview
    ) {
      this.props.history.push(
        getUrl(`${this.getBaseLocation()}${urls.inreview}`),
      );
      return false;
    }
    if (
      (currentURL === urls.inreview || currentURL === urls.loansPersonalizedInReview) &&
      refillAvailable.length === 0 &&
      accepted.length === 0 &&
      signed.length === 0
    ) {
      this.props.history.push(getUrl(`${this.getBaseLocation()}${urls.loans}`));
      return false;
    }
    return true;
  };
  redirectWhenPreapprovalSelected = () => {
    const { isFormSent, preapprovalSelected, preapprovals, tab } = this.props;
    const currentURL = this.props.location.pathname.replace(urls.mobile, '');
    const preapprovalsAvailables = preapprovals.filter(
      ({ status, loan_id }) =>
        status === PreapprovalStatus.AVAILABLE && !loan_id,
    );
    const refillAvailable = preapprovals.filter(
      (preapproval) =>
        [LoanTypes.REFILL].includes(preapproval.type) &&
        [PreapprovalStatus.AVAILABLE].includes(preapproval.status),
    );

    if (
      preapprovalSelected &&
      tab === LoansTabs.NEW_OFFER &&
      (preapprovalSelected.type === LoanTypes.REGULAR || preapprovalSelected.type === LoanTypes.CUSTOM)
    ) {
      const { provider_code: providerCode } = preapprovalSelected;
      const newUrl = this.getURLWhenPreApprovalSelected(
        currentURL,
        providerCode,
      );
      if (newUrl !== currentURL && refillAvailable.length <= 0) {
        this.props.history.push(getUrl(`${this.getBaseLocation()}${newUrl}`));
        return false;
      }
    }
    if (
      preapprovalSelected &&
      !isFormSent &&
      preapprovalsLoaded &&
      preapprovalsAvailables.length > 0 &&
      preapprovalSelected.type === LoanTypes.OPEN_DATA &&
      currentURL !== urls.loansOpenDataConfirmation
    ) {
      this.props.history.push(
        getUrl(`${this.getBaseLocation()}${urls.loansOpenDataConfirmation}`),
      );
      return false;
    }
    return true;
  };
  redirectWhenPreapprovalsAvailables() {
    const {
      preapprovals = [],
      preapprovalsLoaded,
      preapprovalSelected,
      isFormSent,
    } = this.props;
    const currentURL = this.props.location.pathname.replace(urls.mobile, '');
    const preapprovalsAvailables = preapprovals.filter(
      ({ status, loan_id }) =>
        status === PreapprovalStatus.AVAILABLE && !loan_id,
    );
    const preapprovalType = preapprovalsAvailables
      .map(({ type }) => type)
      .shift();

    if (
      isFormSent &&
      preapprovalsLoaded &&
      preapprovalsAvailables.length > 0 &&
      !regexCongrats.test(currentURL)
    ) {
      const { provider_code: providerCode } = preapprovalsAvailables[0];
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${this.getUrlForCongrats(providerCode)}`,
        ),
      );
      return false;
    }
    if (
      !isFormSent &&
      preapprovalsLoaded &&
      preapprovalsAvailables.length > 0 &&
      !preapprovalSelected &&
      preapprovalType === LoanTypes.OPEN_DATA &&
      currentURL !== urls.loansOpenDataOffers
    ) {
      this.props.history.push(
        getUrl(`${this.getBaseLocation()}${urls.loansOpenDataOffers}`),
      );
      return false;
    }
    if (
      !isFormSent &&
      preapprovalsLoaded &&
      preapprovalsAvailables.length > 0 &&
      preapprovalType === LoanTypes.REGULAR &&
      !regexSingleLoans.test(currentURL)
    ) {
      const { provider_code: providerCode } = preapprovalsAvailables[0];
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${this.getUrlForOrigin(providerCode)}`,
        ),
      );
      return false;
    }
    return true;
  }

  redirectWhenIsRefill() {
    const { preapprovals = [], isFormSent, preapprovalSelected } = this.props;
    const currentURL = this.props.location.pathname.replace(urls.mobile, '');
    const availables = preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.AVAILABLE].includes(preapproval.status) &&
        !preapproval.loan_id,
    );
    const refillAvailable = preapprovals.filter(
      (preapproval) =>
        [LoansStates.REFILL].includes(preapproval.type) &&
        preapproval.status !== PreapprovalStatus.DECLINED,
    );
    const providerCode =
      refillAvailable.length > 0 ? refillAvailable[0].provider_code : 'any';
    /**When there are pre-approvals with status AVAILABLE but they are not refill
     * and the URL is related to refill
     */
    if (
      availables.length > 0 &&
      refillAvailable.length === 0 &&
      currentURL.indexOf('refill') > 0
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${this.getUrlForOrigin(
            availables[0].provider_code,
          )}`,
        ),
      );
      return false;
    }
    /**When there are not refill pre-approvals */
    if (refillAvailable.length === 0) {
      return true;
    }
    /**When there are refill pre-approvals with status AVAILABLE
     * and any pre-approval has been selected or sent
     * and the URL is different to the correct one*/
    if (
      [PreapprovalStatus.AVAILABLE].includes(refillAvailable[0].status) &&
      !preapprovalSelected &&
      !isFormSent &&
      !regexRefill.test(currentURL)
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${urls.loansRefill.replace(
            ':lender',
            providerCode.toLowerCase(),
          )}`,
        ),
      );
      return false;
    }
    /**When there are refill pre-approvals with status AVAILABLE
     * and one pre-approval was selected but it was not sent yet
     * and the URL is different to the correct one*/
    if (
      [PreapprovalStatus.AVAILABLE].includes(refillAvailable[0].status) &&
      preapprovalSelected &&
      !isFormSent &&
      !regexConfirm.test(currentURL)
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${urls.loansRefillConfirmation.replace(
            ':lender',
            providerCode.toLowerCase(),
          )}`,
        ),
      );
      return false;
    }
    /**When there are refill pre-approvals with status AVAILABLE or in review(ACCEPTED, ON_HOLD)
     * and the URL is related to refill but it is not the inreview page
     * and the URL contains a different provider_code*/
    if (
      [
        PreapprovalStatus.AVAILABLE,
        PreapprovalStatus.ACCEPTED,
        PreapprovalStatus.ON_HOLD,
      ].includes(refillAvailable[0].status) &&
      currentURL.indexOf('refill') > 0 &&
      currentURL.indexOf('inreview') < 0 &&
      currentURL.indexOf(providerCode.toLowerCase()) < 0
    ) {
      const providersRegex = RegExp(/mot|aph|mrp|cto|r2|avl/g);
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${currentURL.replace(
            providersRegex,
            providerCode.toLowerCase(),
          )}`,
        ),
      );
      return false;
    }
    /**When there are refill pre-approvals in review (ACCEPTED, ON_HOLD)
     * and one pre-approval was just requested
     * and the URL is different to the correct one (congrats)*/
    if (
      [PreapprovalStatus.ACCEPTED, PreapprovalStatus.ON_HOLD].includes(
        refillAvailable[0].status,
      ) &&
      isFormSent &&
      !regexRefillCongrats.test(currentURL)
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${urls.loansRefillCongrats.replace(
            ':lender',
            providerCode.toLowerCase(),
          )}`,
        ),
      );
      return false;
    }
    /**When there are refill pre-approvals in review (ACCEPTED, ON_HOLD)
     * and one pre-approval was requested before the page was refreshed
     * and the URL is different to the correct one (inreview)*/
    if (
      [PreapprovalStatus.ACCEPTED, PreapprovalStatus.ON_HOLD].includes(
        refillAvailable[0].status,
      ) &&
      !isFormSent &&
      currentURL !== urls.loansRefillInReview
    ) {
      this.props.history.push(
        getUrl(`${this.getBaseLocation()}${urls.loansRefillInReview}`),
      );
      return false;
    }
    return true;
  }

  redirectWhenIsPersonalized() {
    const { preapprovals = [], isFormSent, preapprovalSelected } = this.props;
    const currentURL = this.props.location.pathname.replace(urls.mobile, '');
    const availables = preapprovals.filter(
      (preapproval) =>
        [PreapprovalStatus.AVAILABLE].includes(preapproval.status) &&
        !preapproval.loan_id,
    );
    const customAvailable = preapprovals.filter(
      (preapproval) =>
        [LoansStates.CUSTOM].includes(preapproval.type) &&
        preapproval.status !== PreapprovalStatus.DECLINED,
    );
    const providerCode =
      customAvailable.length > 0 ? customAvailable[0].provider_code : 'any';
    /**When there are pre-approvals with status AVAILABLE but they are not custom
     * and the URL is related to custom
     */
    if (
      availables.length > 0 &&
      customAvailable.length === 0 &&
      currentURL.indexOf('personalized') > 0
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${this.getUrlForOrigin(
            availables[0].provider_code,
          )}`,
        ),
      );
      return false;
    }
    /**When there are not custom pre-approvals */
    if (customAvailable.length === 0) {
      return true;
    }
    /**When there are custom pre-approvals with status AVAILABLE
     * and any pre-approval has been selected or sent
     * and the URL is different to the correct one*/
    if (
      [PreapprovalStatus.AVAILABLE].includes(customAvailable[0].status) &&
      !preapprovalSelected &&
      !isFormSent &&
      !regexPersonalized.test(currentURL)
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${this.getUrlForPersonalizedOrigin(providerCode)}`,
        ),
      );
      return false;
    }
    /**When there are custom pre-approvals with status AVAILABLE or in review(ACCEPTED, ON_HOLD)
     * and the URL is related to custom but it is not the inreview page
     * and the URL contains a different provider_code*/
    if (
      [
        PreapprovalStatus.AVAILABLE,
        PreapprovalStatus.ACCEPTED,
        PreapprovalStatus.ON_HOLD,
      ].includes(customAvailable[0].status) &&
      currentURL.indexOf('personalized') > 0 &&
      currentURL.indexOf('inreview') < 0 &&
      currentURL.indexOf(providerCode.toLowerCase()) < 0
    ) {
      const providersRegex = RegExp(/mot|aph|mrp|cto|r2|avl/g);
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${currentURL.replace(
            providersRegex,
            providerCode.toLowerCase(),
          )}`,
        ),
      );
      return false;
    }
    return true;
  }

  redirectWhenAnyPreapproval() {
    const {
      flags: {
        loansOpenDataEligibleMerchantsWeb: isEligibleOpenData,
        loansOpenDataShowFeatureWeb: isOpenDataEnabled,
      },
      preapprovals = [],
    } = this.props;
    const withoutPreapprovals =
      preapprovals.length === 0 ||
      preapprovals.length ===
        preapprovals.filter(
          ({ status, loan_id }) =>
            [PreapprovalStatus.DECLINED].includes(status) && !loan_id,
        ).length;
    const withoutPreapprovalsAvailable =
      preapprovals.filter(
        ({ status, loan_id }) =>
          [PreapprovalStatus.AVAILABLE, PreapprovalStatus.ACCEPTED].includes(
            status,
          ) && !loan_id,
      ).length === 0;
    const withPreapprovalsCanceled =
      preapprovals.filter(
        ({ status, loan_id }) =>
          [PreapprovalStatus.CANCELLED, PreapprovalStatus.CANCELED].includes(
            status,
          ) && !loan_id,
      ).length > 0;
    const lastPreapprovalIsCanceled = [
      PreapprovalStatus.CANCELLED,
      PreapprovalStatus.CANCELED,
    ].includes(this.getLastUpdatedPreapproval(preapprovals).status);
    const currentURL = this.props.location.pathname.replace(urls.mobile, '');
    if (
      withoutPreapprovalsAvailable &&
      withPreapprovalsCanceled &&
      lastPreapprovalIsCanceled &&
      !regexCanceled.test(currentURL)
    ) {
      this.props.history.push(
        getUrl(
          `${this.getBaseLocation()}${urls.loansCanceled.replace(
            ':lender',
            preapprovals
              .filter(
                ({ status, loan_id }) =>
                  [
                    PreapprovalStatus.CANCELLED,
                    PreapprovalStatus.CANCELED,
                  ].includes(status) && !loan_id,
              )[0]
              .provider_code.toLowerCase(),
          )}`,
        ),
      );
      return false;
    }
    if (
      withoutPreapprovals &&
      (!isEligibleOpenData || !isOpenDataEnabled) &&
      currentURL !== urls.loansOpenDataNotElegible
    ) {
      const url = getUrl(
        `${this.getBaseLocation()}${urls.loansOpenDataNotElegible}`,
      );
      this.props.history.replace(url);
      return false;
    }
    if (
      isOpenDataEnabled &&
      withoutPreapprovals &&
      isEligibleOpenData &&
      currentURL !== urls.loansOpenDataElegible
    ) {
      const url = getUrl(
        `${this.getBaseLocation()}${urls.loansOpenDataElegible}`,
      );
      this.props.history.replace(url);
      return false;
    }
    return true;
  }

  redirectValidations() {
    const { preapprovalsLoaded } = this.props;
    this.redirectWhenNotAuthorized() &&
      preapprovalsLoaded &&
      this.redirectWhenAnyPreapproval() &&
      this.redirectWhenPreapprovalsExpired() &&
      this.redirectWhenThereAreOnlyLoans() &&
      this.redirectWhenStatusDoesntExist() &&
      this.redirectWhenPreapprovalsAvailables() &&
      this.redirectWhenPreapprovalSelected() &&
      this.redirectWhenIsCongrats() &&
      this.redirectWhenIsInReview() &&
      this.redirectWhenIsRefill() && 
      this.redirectWhenIsPersonalized();
  }

  componentDidMount() {
    this.redirectValidations();
  }

  componentDidUpdate() {
    this.redirectValidations();
  }

  render() {
    const { children, preapprovals = [], tab } = this.props;
    const withLoans = this.getWithLoans(preapprovals);

    return (
      <LoansContent hasLoans={withLoans.length > 0} tab={tab}>
        {children}
      </LoansContent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loansFormVersion: state.loans.loansFormVersion,
    showCashAdvance: state.loans.showCashAdvance,
    isFormSent: isLoansFormSent(state),
    preapprovals: getPreapprovals(state),
    preapprovalSelected: getPreapprovalSelected(state),
    role: state.user.role,
    loginActive: state.auth.login.active,
    preapprovalsLoaded: preapprovalsLoaded(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withLDConsumer()(LoansRedirection)),
);
