import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

import { app, getConfigValue } from 'config/config';
import { isTimestampExpired } from './dateHelpers';

const previewDomain = getConfigValue('previewDomain');

/**
 * Gets the domain name of a hostname
 * @param hostName - the hostname
 * @return string - The domain name
 */
function getDomainName(hostName) {
  return hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1,
  );
}

/**
 * Returns the entire hostname if the hostname is the one used for previews
 * or returns the configured hostname
 * @return string - The domain name
 */
export function getCookieDomain() {
  const hostname = window ? window.location.hostname : '';
  const domain = getDomainName(hostname);
  return domain === previewDomain
    ? window.location.hostname
    : app.signInCookieDomain;
}

/**
 * Creates a name for a cookie depending on the environment
 * @param env - The environment name
 * @param baseName - The base name for the cookie
 * @return string - The cookie name
 */
function createCookieName(env, baseName) {
  if (env === 'production') return baseName;
  return `${getConfigValue('env')}_${baseName}`;
}

/**
 * Determines if a JWT token is expired
 * @param token - JWT token
 * @return bool - wether or not the token exp field is expired
 */
export const isTokenExpired = (token) => {
  if (typeof token === 'undefined' || token === null || token === '')
    return true;

  const decoded = jwtDecode(token);
  return isTimestampExpired(decoded.exp);
};

export const TOKEN_NAME = 'access_token';
export const BUM_TOKEN_NAME = 'bum_access_token';

export const clearToken = (name = TOKEN_NAME) => {
  const options = { expires: 60, domain: getCookieDomain() };
  const cookieName = createCookieName(getConfigValue('env'), name);
  Cookies.remove(cookieName, options);
  clearCookie('nonce');
};

export const saveToken = (token, name = TOKEN_NAME) => {
  const options = { expires: 60, domain: getCookieDomain() };
  const cookieName = createCookieName(getConfigValue('env'), name);
  Cookies.set(cookieName, token, options);
};

export const getToken = (name = TOKEN_NAME) => {
  return Cookies.get(createCookieName(getConfigValue('env'), name));
};

function reverseString(str) {
  if (!str) return '';
  const splitString = str.split('');
  const reverseArray = splitString.reverse();
  return reverseArray.join('');
}

export const clearCookie = (name) => {
  const options = { expires: 60, domain: getCookieDomain() };
  const cookieName = createCookieName(getConfigValue('env'), name);
  Cookies.remove(cookieName, options);
};

export const saveCookie = (name, value) => {
  const options = { expires: 60, domain: getCookieDomain() };
  const cookieName = createCookieName(getConfigValue('env'), name);
  Cookies.set(cookieName, reverseString(btoa(JSON.stringify(value))), options);
};

export const getCookie = (name, defaultValue = false) => {
  const value = Cookies.get(createCookieName(getConfigValue('env'), name));
  return !value ? defaultValue : JSON.parse(atob(reverseString(value)));
};
