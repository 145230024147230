import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAccountRep } from '../redux/berlinActions';
import BerlinService from '../service';
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { ROUTES2 as ROUTES } from '../constants';
import CdaHelper from '../CdaHelper';
import BerlinSpinner from '../BerlinSpinner';

const Auth = ({isLogged, children, user, account, merchant}) => {
  let history = useHistory();
  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    setLogged(isLogged)
  }, [isLogged])

  useEffect(() => {
    if (merchant && merchant.info.id !== '') {
      BerlinService.accountInformationV2(merchant.info.id)
        .then((response) => {
          setAccountRep(response.data.message);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error)
          console.log('error status');
        });
    }
  }, [merchant]);

  const renderScreen = () => {
    if(loading){
      return <BerlinSpinner/>
    }
    if(logged){
      if(children){
        return children;
      }
      history.push(`${ROUTES.BALANCE}`);
    }
    return (
      account && <CdaHelper
        user={user}
        merchantId={account.account_holder_id}
      />
    )
  }

  return renderScreen();
};

const mapStateToProps = (state) => {
  return {
    account: state.berlin.account_representation,
    merchant: state.merchant,
    berlin: state.berlin,
    ldFlags: state.berlin.ldFlags,
    isLogged: state.berlin.isLogged,
    user: state.user,
  };
};

const mapDispatchToProps = {
  setAccountRep,
};

export default connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(Auth));
