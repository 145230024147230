export default {
  greeting: 'Hola,',
  crediClip: 'Presta Clip',
  titleP1: '¡Hola, ',
  titleP2: '!',
  titleP3: 'Aqui tienes un adelanto de ventas',
  subtitle:
    'Personaliza tu oferta, realiza tu solicitud y pronto recibirás tu dinero.',
  expiration: (expirationDate) =>
    `Oferta por tiempo limitado hasta el **${expirationDate}**`,
  limitedOffer: '**Oferta por tiempo limitado**',
  untilThen: (expirationDate) => `Hasta el ${expirationDate}`,
  knowMoreOffer: 'Saber más sobre mi oferta',
  maxLimit: 'Plazo máximo:',
  months: 'meses',
  fixedCharge: 'Cargo fijo + IVA: ',
  total: 'Total a pagar: ',
  refund: 'Forma de pago: ',
  dailySales: 'de tus ventas diarias',
  buttonAmmount: 'Solicitar ',
  recommendedLabel: 'Recomendado',
  amountTitles: ['Crece tu negocio', 'Renuévate', 'Nuevos clientes'],
  amountSubtitles: [
    'Renuévate y date a conocer',
    'Invierte en nuevos productos',
    'Promocionate en redes sociales',
  ],
  stepsTitle: 'Beneficios de tu Presta Clip',
  stepsDescription: `Con Presta Clip podrás recibir un adelanto de tus ventas para llegar 
  a nuevos clientes, renovar o comprar más productos, o crecer tu negocio como tú lo desees.`,
  stepOneTitle: 'Fácil de solicitar',
  stepOneDescription:
    'Elige una de las tres ofertas disponibles e inicia tu solicitud en un solo clic.',
  stepTwoTitle: 'Rápido de obtener',
  stepTwoDescription:
    'Recibe tu Presta Clip en 24 horas, una vez que la solicitud sea aprobada.',
  stepThreeTitle: 'Pagas como vendes',
  stepThreeDescription:
    'Pagarás tu Presta Clip con un porcentaje de tus ventas diarias. Sin enganche ni aval.',
  preSamsGift: '+ un regalo',
  postSamsGift: 'por parte de',
};
