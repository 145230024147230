import React, { PureComponent } from 'react';
import { withRouter, Link } from 'react-router-dom';

import urls from 'helpers/urls';

import * as Styled from './styled';
import text from './text';

 class QuestionFAQ extends PureComponent {
  render() {
    const baseLocation = this.props.location.pathname.startsWith(urls.mobile)
    ? urls.mobile
    : '';
  
    return (
      <Styled.FAQDoubts>
        <Styled.Question>
          {text.questions}{' '}
          <Link to={`${baseLocation}${urls.loansFAQs}`}>
            {text.linkQuestions} <Styled.LinkIcon />
          </Link>
        </Styled.Question>
      </Styled.FAQDoubts>
    );
  }
}

export default withRouter(QuestionFAQ);