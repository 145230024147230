import moment from 'moment-timezone';
import 'moment/locale/es';

// Formats date to spanish
// Languages other than English must be imported separately
moment.locale('es');

/**
 * Returns a date as short and full year (i.e Jan 2017)
 * @param date - date to be formatted
 * @return date - formatted date
 */
export function toMonthAndYear(date) {
  if (!date) return '';

  return moment(date).format('MMM YYYY').toUpperCase();
}

/**
 * Returns a full date in a short format (i.e 7 Jan 2017)
 * @param date - date to be formatted
 * @return date - formatted date
 */
export function toShortDate(date) {
  if (!date) return '';

  return moment(date).format('D MMM YYYY').toUpperCase();
}

/**
 * Returns a standard date
 * Without full month spelling (i.e 07 Jan 2017)
 * @param date - date to be formatted
 * @return date - formatted date
 */
export function toDate(date) {
  if (!date) return '';

  return moment(date).format('DD MMM YYYY').toUpperCase().replace('.', '');
}

/**
 * Returns a standard date with time
 * Without full month spelling (i.e 07 Jan 2017)
 * @param date - date to be formatted
 * @return date - formatted date
 */
export function toDateTime(date) {
  if (!date) return '';

  const dateTime = moment(date).format('hh:mm A');
  return `${moment(date).format('DD MMM YYYY').toUpperCase()} ${dateTime} CST`;
}

/**
 * Returns a full length date
 * With full month spelling (i.e 07 January 2017)
 * @param date - date to be formatted
 * @return date - formatted date
 */
export function toFullDate(date) {
  if (!date) return '';

  return moment(date).format('DD MMMM YYYY').toUpperCase();
}

/**
 * Determines if a date is within a specified period
 * @param date - date to be evaluated
 * @param start - start date of time period
 * @param end - end date of time period
 * @return bool - wether or not date is between given params
 */
export function isBetween(date, start, end) {
  date = moment(date).format('YYYY-MM-DD');
  start = moment(start).format('YYYY-MM-DD');
  end = moment(end).format('YYYY-MM-DD');

  return moment(date).isBetween(start, end, null, '[]');
}

/**
  * Determines if a date is the same or after a given limit
  * @param date - date to be evaluated
  * @param limit  - number of units of time to be subtracted
                  - from current date to find the start date
  * @param level  - unit type to be subtracted from start date
                  - options are 'day', 'month', or 'year'
  * @return bool - whether or not date is same or after start date
*/
export function isSameOrAfter(date, limit, level) {
  if (!date) return false;

  const limitDate = moment().subtract(limit, `${level}s`);

  return moment(date).isSameOrAfter(limitDate, `${level}`);
}

/**
 * Determines if a date is expired (date provided is before current date/time)
 * @param date - date to be evaluated (timestamp in seconds)
 * @return bool - wether or not date is expired
 */
export function isTimestampExpired(date) {
  date = moment(date * 1000); // seconds to milliseconds

  return moment().isSameOrAfter(date);
}

/**
 * Returns the current date timestamp
 * @return current timestamp
 */
export function timestamp() {
  return moment().format('X');
}

/**
 * Returns an ISO date
 * (i.e 2018-08-23)
 * @param {string} date - valid momentJS date to be parsed
 * @returns {string} - ISO formatted date
 */
export function toISODate(date) {
  if (!date) return '';

  return moment(date).format('YYYY-MM-DD');
}

export function formatTimestamp(date) {
  return moment(date, 'X')
    .format('DD MMM Y hh:mm A z')
    .toUpperCase()
    .replace(/\./g, '');
}

/**
 * Defines a timezone based on browser data
 * (i.e America/Mexico_City)
 * @returns {string} - Best guess for user timezone
 */
export function getTimezone() {
  return moment.tz.guess();
}

export function getDifferenceInMonths(date) {
  const today = moment();
  const lastDate = moment(moment(date).format(moment.HTML5_FMT.DATE));

  return today.diff(lastDate, 'months', true);
}

export function getDifferenceInDays(date, format) {
  const today = moment();
  const lastDate = moment(date, format);

  return lastDate.diff(today, 'days', true) + 1;
}
