import styled from '@emotion/styled';
import { Field } from 'redux-form';
import {
  SubmitButton as CommonSubmitButton,
  BaseButton,
} from 'components/common/Buttons';
const circleSize = '2.25rem';

export const ChangePinContainer = styled.div(() => ({
  background: 'white',
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  minHeight: '600px',
  alignItems: 'center',
  paddingTop: '3.5rem',
  paddingBottom: '3.5rem',
}));

export const ChangePin = styled.div(() => ({
  background: 'white',
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  minHeight: '600px',
  alignItems: 'center',
  paddingTop: '1.5rem',
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  boxSizing: 'border-box',
}));

export const ClipImage = styled.img(() => ({
  width: '7.5rem',
  height: '7.313rem',
  objectFit: 'contain',
  alignSelf: 'center',
  marginBottom: '1.5rem',
}));

export const LandingMenuTextLines = styled.div(({ theme }) => ({
  width: '16.5rem',
  height: 'auto',
  fontSize: '1rem',
  fontWeight: '500',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.125rem',
  letterSpacing: 'normal',
  color: theme.palette.text.primary,
}));

const BaseButtonStyles = ({ theme }) => ({
  margin: '0',
  width: '100%',
  textTransform: 'none',
  marginBottom: '2rem',
});
const DoneButtonStyles = ({ theme }) => ({
  width: `calc(100vw - ${theme.spacing.unit * 3}px)`,
  margin: `0 ${theme.spacing.unit * 1.5}px`,
  textTransform: 'none',
  marginBottom: '2rem',
});

const BaseSubmitButtonStyles = ({ theme }) => ({
  margin: '0',
  width: '100%',
  textTransform: 'none',
});
export const LandingMenu = styled.li(() => ({
  counterIncrement: 'theNumber',
  margin: '0 0 1.5rem 0',
  position: 'relative',
  '&:before': {
    content: 'counter(theNumber)',
    position: 'absolute',
    background: '#FEEDE5',
    color: '#fc4c02',
    fontWeight: '900',
    borderRadius: '50%',
    textAlign: 'center',
    top: '0',
    '--size': circleSize,
    left: 'calc(-1 * var(--size) - 10px)',
    lineHeight: 'var(--size)',
    width: 'var(--size)',
    height: 'var(--size)',
  },
}));

export const FormField = styled(Field)(({ theme }) => ({
  width: '100%',
  marginBottom: '3rem',
  alignSelf: 'center',
  style: '-webkit-text-security: disc',
}));

export const StepTitleContainer = styled.div(() => ({
  width: '19.5rem',
  fontSize: '1.5rem',
  fontWeight: '500',
  height: 'auto',
  marginTop: '2rem',
  marginLeft: '1.5rem',
  marginBottom: '1rem',
  display: 'flex',
  flexDirection: 'row',
}));

export const StepTitleWarn = styled.div(() => ({
  width: '19.5rem',
  fontSize: '0.875rem',
  height: 'auto',
  paddingLeft: '8rem',
  marginBottom: '1rem',
}));

export const StepTitle = styled.div(() => ({
  width: '16.5rem',
  fontSize: '1.5rem',
  fontWeight: '500',
  height: 'auto',
  marginLeft: '1rem',
}));

export const StepNumberImage = styled.img(() => ({
  width: circleSize,
  height: circleSize,
  objectFit: 'contain',
}));

export const IDReminder = styled.div(() => ({
  width: '19.5rem',
  height: '3.5rem',
  fontSize: '0.875rem',
  color: '#0288d1',
  alignSelf: 'center',
  textAlign: 'left',
  lineHeight: '1.43rem',
  borderRadius: '2px',
  border: 'solid 1 px rgba(2, 136, 209, 0.05)',
  backgroundColor: 'rgba(2, 136, 209, 0.05)',
  marginBottom: '1rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const SubmitButton = styled(CommonSubmitButton)(BaseSubmitButtonStyles);

export const CommonButton = styled(BaseButton)(BaseButtonStyles);
export const DoneButton = styled(BaseButton)(DoneButtonStyles);
