import { PaymentRequestStates } from 'constants/AppConstants';
import {
  CANCEL_PAYMENT_REQUEST,
  CANCEL_SENT_PAYMENT_REQUEST,
  CREATED, ENABLE_PUBLIC_PROFILE,
  END_PAYMENT_REQUEST_FORM, FILTER_PAYMENT_LIST,
  GET_PAYMENT_REQUEST_DETAIL, GET_PAYMENT_REQUEST_LIST,
  KYC_NOTICE_FLAG, MERCHANT_LIMIT,
  MULTILINK_FLAG,
  PUBLIC_PROFILE_INFO,
  RESEND_EMAIL,
  SEND_PAYMENT_REQUEST_FORM,
  UPDATE_PRIVACY_INFO,
  UPDATE_ALIAS,
} from './types';

const initialState = {
  isFormSent: false,
  status: PaymentRequestStates.NEW,
  expirationDate: '',
  requestPayment: '',
  paymentRequestList: [],
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    totalItems: 0,
  },
  has_payment_request: false,
  merchantLimit: 0,
  multilinkFlag: false,
  kycNoticeFlag: false,
  profile: {
    public_name:'',
    public_description:'',
    alias:'',
    image:'',
    email:'',
    profile_config: {
        profile_active: false,
        show_address: false,
        show_email: false,
        show_phone: false,
    },
  },
  hasProfile: false,
};

const createdPaymentRequest = (state, action) => {
  return { ...state, ...action.payload };
};

const sendPaymentRequest = (state, action) => {
  return { ...state, ...action.payload };
};

const endPaymentRequest = (state, action) => {
  return { ...state, ...action.payload };
};

const getPaymentRequestList = (state, action) => {
  return { ...state, ...action.payload };
};

const getPaymentRequestDetail = (state, action) => {
  return { ...state, ...action.payload };
};

const cancelPaymentRequest = (state, action) => {
  return { ...state, has_payment_request: action.has_payment_request };
};

const cancelSentPaymentRequest = (state, action) => {
  return { ...state, ...action.payload };
};

const resendEmail = (state, action) => {
  return { ...state, ...action.payload };
};

const filterPaymentsRequests = (state, action) => {
  return { ...state, ...action.payload };
};

const setMerchantLimit = (state, action) => {
  return { ...state, merchantLimit: action.merchantLimit };
};
const setMultilinkFlag = (state, action) => {
  return { ...state, multilinkFlag: action.multilinkFlag};
}
const setKycNoticeFlag = (state, action) => {
  return { ...state, kycNoticeFlag: action.kycNoticeFlag}
}
function publicProfileInfo(state, action) {
  return {...state, profile: {...action.payload.profile }, hasProfile: action.payload.hasProfile};
}
function updateAlias(state, action) {
  return {...state, profile: {
    ...state.profile,
    alias: action.payload,
  }}
}
function updateProfileConfig(state, action) {
  let { profile_config } = state.profile;
  return {...state, profile: {
    ...state.profile,
    profile_config: { ...profile_config, profile_active: action.payload },
    },
  }
}

function handlePrivacy(state, action){
  let { profile_config } = state.profile;
  return { ...state, profile: {
    ...state.profile,
    profile_config: {
      ...profile_config,
      ...action.payload,
    }
  } }

}

const actionHandlers = new Map([
  [SEND_PAYMENT_REQUEST_FORM, sendPaymentRequest],
  [CREATED, createdPaymentRequest],
  [CANCEL_PAYMENT_REQUEST, cancelPaymentRequest],
  [END_PAYMENT_REQUEST_FORM, endPaymentRequest],
  [GET_PAYMENT_REQUEST_LIST, getPaymentRequestList],
  [GET_PAYMENT_REQUEST_DETAIL, getPaymentRequestDetail],
  [CANCEL_SENT_PAYMENT_REQUEST, cancelSentPaymentRequest],
  [RESEND_EMAIL, resendEmail],
  [FILTER_PAYMENT_LIST, filterPaymentsRequests],
  [MERCHANT_LIMIT, setMerchantLimit],
  [MULTILINK_FLAG, setMultilinkFlag],
  [KYC_NOTICE_FLAG, setKycNoticeFlag],
  [PUBLIC_PROFILE_INFO, publicProfileInfo],
  [UPDATE_ALIAS, updateAlias],
  [ENABLE_PUBLIC_PROFILE, updateProfileConfig],
  [UPDATE_PRIVACY_INFO, handlePrivacy],
]);

export default function PaymentRequestReducer(
  state = initialState,
  action = { type: null },
) {
  return actionHandlers.has(action.type)
    ? actionHandlers.get(action.type)(state, action)
    : state;
}
