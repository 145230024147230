import styled from '@emotion/styled';

export const Container = styled('div')({
  label: 'auth-container',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

export const Content = styled('div')(({ theme, maxWidth = true }) => ({
  label: 'auth-content',
  flex: '1 0 auto',
  width: '100%',
  maxWidth: maxWidth ? theme.spacing.unit * 180 : 'auto',
  margin: '0 auto',
}));
