import { stepsEventsOnboarding } from './index';

export const AnalyticsClassList = {
  helpLink: 'help-analytics-link',
  registrationLink: 'registration-analytics-link',
  installmentsLink: 'installments-analytics-link',
  downloadAppGoogleLink: 'downloadApp-google',
  downloadAppAppleLink: 'downloadApp-apple',
  summaryLink: 'summary-analytics-link',
  emailValidationClick: 'emailValidation-analytics-click',
  collaboratorsClick: 'collaborators-analytics-click',
  summaryClick: 'summary-analytics-click',
};

export const AnalyticsSectionList = {
  registration: 'Registration',
  emailValidation: 'EmailValidation',
  personalInfo: 'PersonalInfo',
  collaborators: 'Collaborators',
  installments: 'Installments',
  bankInfo: 'BankInfo',
  summary: 'Summary',
  onboarding: 'Onboarding',
  login: 'Login',
  subHeader: 'SubHeader',
};

export const AnalyticsCategories = {
  ONBOARDING: 'Onboarding',
  LOGIN: 'Login',
  REGISTRATION: 'Registration',
  EMAILVALIDATION: 'EmailValidation',
  PERSONALINFO: 'PersonalInfo',
  COLLABORATORS: 'Collaborators',
  INSTALLMENTS: 'Installments',
  BACKINFO: 'BankInfo',
  SUMMARY: 'Summary',
};

export class GTM {
  static isAvailableAnalytics = () => {
    return !(
      typeof window === 'undefined' || typeof window.dataLayer === 'undefined'
    );
  };

  static populateStepOnboarding = (
    stepId,
    evenCategory,
    evenAction,
    eventlabel,
    errors,
  ) => {
    const step = stepsEventsOnboarding.find((step) => step.id === stepId);
    GTM.pushDataLayer(step.event, evenCategory, evenAction, eventlabel);

    if (errors && errors !== 'Submit Validation Failed') {
      GTM.errorForm(step.event, evenCategory, errors);
    }
  };

  static errorForm = (step, evenCategory, errors) => {
    if (!(Object.prototype.toString.call(errors) === '[object Object]')) {
      errors = { email: `${errors}` };
    }
    for (let [key, value] of Object.entries(errors)) {
      GTM.pushDataLayer(step, evenCategory, `Error-${key}`, `${value}`);
    }
  };

  static pushDataLayer = (event, evenCategory, evenAction, eventlabel) => {
    if (!GTM.isAvailableAnalytics()) {
      return;
    }
    window.dataLayer.push({
      event: event,
      eventCategory: evenCategory,
      eventAction: evenAction,
      eventLabel: eventlabel || '',
    });
  };

  static xpayRequestPayment = (
    event,
    evenCategory,
    evenAction,
    eventlabel,
    data,
  ) => {
    if (!GTM.isAvailableAnalytics()) {
      return;
    }
    window.dataLayer.push({
      event: event,
      eventCategory: evenCategory,
      eventAction: evenAction,
      eventLabel: data.consumer_email,
    });
  };
}
