export default {
  title: 'Por el momento no hay ofertas disponibles',
  description: `En este momento no tenemos ninguna oferta personalizada para ti. 
  Continua utilizando tu Clip para hacer todas tus transacciones y espera pronto recibir 
  noticias sobre una oferta de Presta Clip ideal para ti.`,
  howTo: '¿Cómo obtener una oferta personalizada en Presta Clip?',
  pointOne: 'Mantén una actividad transaccional constante utilizando tu Clip.',
  pointTwo:
    'Asegúrate de realizar transacciones durante los días previos a tu solicitud.',
  adviceHowTo:
    'Sigue nuestras recomendaciones y pronto recibirás una nueva oferta en tu correo electrónico.',
};
