import { app } from '../../../config/config'
const crypto = require('crypto');

export function encryptWithIV (token, iv) {
  const ENV_KEY = app.swapDynamicTokenKey;
  try {	
		let cipher = crypto.createCipheriv('aes-128-cbc', ENV_KEY, iv);
		let crypted = cipher.update(token, 'utf8', 'base64');
		crypted += cipher.final('base64');
		return crypted;
	} catch (error) {
		return null;
	}
};

export function arrayToHex(arr) {
  let hexStr = '';

  arr.forEach((num) => {
    if (num < 16) {
      hexStr += `0${num.toString(16)}`;
    } else {
      hexStr += num.toString(16);
    }
  });
  return hexStr;
}

export function hexToArray(hexStr) {
  const arr = new Uint8Array(hexStr.length / 2);
  for (let i = 0; i < hexStr.length; i += 2) {
    arr[i / 2] = parseInt(hexStr.charAt(i) + hexStr.charAt(i + 1), 16);
  }

  return arr;
}

export function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export function binaryStringToArrayBuffer(binaryString) {
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export function asciiToUint8Array(str) {
  const chars = [];
  for (let i = 0; i < str.length; ++i) chars.push(str.charCodeAt(i));
  return new Uint8Array(chars);
}

export function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint16Array(buf));
}

export function generateObjectURIFromBase64(base64, mimeType) {
  // Decode base64
  const byteString = atob(base64);

  // Write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // Create a view into the buffer
  const ia = new Uint8Array(ab);

  // Set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Write the ArrayBuffer to a blob
  const blob = new Blob([ab], { type: mimeType });

  // Generate the URI and you are done
  return URL.createObjectURL(blob);
}
