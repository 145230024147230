import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export const SpinnerContainer = styled.div(() => ({
  background: 'white',
  width: '100%',
  height: '100vh',
  minHeight: '600px',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SpinnerText = styled.div(() => ({
  width: '100%',
  height: 'auto',
  marginBottom: '1rem',
  fontSize: '1.313rem',
  fontWeight: '500',
  textAlign: 'center',
}));

export const LdsRing = styled.div(() => ({
  display: 'inline-block',
  position: 'relative',
  width: '60px',
  height: '60px',
  '& div': {
    boxSizing: 'border-box',
    display: 'block',
    position: 'absolute',
    width: '60px',
    height: '60px',
    marginTop: '8px',
    border: '3px solid #fff',
    borderRadius: '50%',
    animation: `${ldsRingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
    borderColor: '#fc4c02 transparent transparent transparent',
  },
  '& div:nth-child(1)': {
    animationDelay: '-0.45s',
  },
  '& div:nth-child(2)': {
    animationDelay: '-0.3s',
  },
  '& div:nth-child(3)': {
    animationDelay: '-0.15s',
  },
}));

const ldsRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
    }
  100% {
    transform: rotate(360deg);
    }
`;
