export default {
  landingText1: 'Recibe el dinero de tus ventas más rápido ',
  landingText2: 'con Clip Card. La tarjeta de débito Clip respaldada por Visa.',
  landingText3_1: 'Sin saldos mínimos ni cobros por uso. ',
  landingText3_2: 'Activa tu Clip Card en 5 minutos desde tu celular.',
  landingText4: 'Lleva el control de tu dinero con Clip Card',
  landingText5: 'Completa tu información personal.',
  landingText6:
    'Sube una foto de tu identificación oficial (INE o pasaporte mexicano).',
  landingText7: 'Espera unos minutos mientras validamos tus datos.',
  landingText8: 'Activa tu Clip Card.',
  landingText8_nip: 'Activa tu Clip Card y crea tu NIP.',
  landingText9_1: 'Acepta hasta $18,000 mil pesos al mes en tu cuenta',
  landingText9_2: ', ya sea por ventas o por transferencias a tu Clip Card.',
  landingText10: '¡Así de fácil!',
  haveACardButton: 'Tengo una Clip Card',
  waitingListButton: 'Me interesa',
  verifyingData: 'Verificando datos',
  checkingData:
    'Estamos revisando tus datos. Mientras tanto, puedes seguir usando tu app Clip.',
  thanksForChoosing: '¡Gracias por elegir Clip Card!',
  requestClipCard: 'Solicitar Clip Card',
  requestStatus: 'Ver solicitud',
};
