import Cookies from 'js-cookie';
import { UPDATE_LOCALE } from '../actions/Types';
import { Locales } from '../constants/AppConstants';

const initialState = Locales.ES_MX;

const handleUpdateLocale = (state, action) => {
  const locale = action.payload || Locales.ES_MX;
  Cookies.set('locale', locale);
  return locale || Locales.ES_MX;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_LOCALE:
      return handleUpdateLocale(state, action);
    default:
      return state;
  }
}
