import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import getUrl from '../../../../../helpers/getUrl';
import urls from '../../../../../helpers/urls';
import {
  toCurrencyFormat,
  toCurrencyFormatWithoutDecimals,
} from '../../../../../helpers/formatCurrency';
import FormButton from '../../../../../helpers/FormUtils/FormButton';
import { savePreapprovalSelected } from '../../../redux/actions';
import text from '../../text';
import { styles } from './styles';

export const OfferItem = props => {
  let location = useLocation();
  let history = useHistory();
  const {
    className,
    index,
    savePreapprovalSelected,
    preapproval,
    classes,
  } = props;

  const getBaseLocation = () =>
    location.pathname.startsWith(urls.mobile)
      ? urls.mobile
      : '';

  return (
    <div
      data-testid="offerItem"
      className={clsx(classes.item, {
        Recomended: index === 0,
        desktop: className === 'desktop',
      })}
    >
      <div className={classes.container}>
        <div className={classes.amount}>
          {toCurrencyFormatWithoutDecimals(
            preapproval.amount.toString().split('.')[0],
          )}
          <sup>.{preapproval.amount.toString().split('.')[1]}</sup>
        </div>
        <div className={classes.buttonContainer}>
          <FormButton
            variant="contained"
            text={text.buttonAmount}
            onClick={(event) => {
              savePreapprovalSelected(preapproval);
              history.push(
                getUrl(`${getBaseLocation()}${urls.loansOpenDataConfirmation}`)
              );
            }}
          />
        </div>
        <div className={classes.information}>
          <div className={classes.title}>{text.titleAmountItem[index]}</div>
          <div className={classes.separator} />
          <div className={classes.keyValue}>
            <div className={classes.key}>{text.toDeposit}</div>
            <div className={classes.value}>
              {toCurrencyFormat(preapproval.amount)}
            </div>
          </div>
          <div className={classes.keyValue}>
            <div className={classes.key}>{text.toPay}</div>
            <div className={classes.value}>
              {toCurrencyFormat(preapproval.payback)}
            </div>
          </div>
          <div className={classes.keyValue}>
            <div className={classes.key}>{text.termMaximun}</div>
            <div className={classes.value}>
              {preapproval.term_maximum} {text.months}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
OfferItem.propTypes = {
  preapproval: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
};
OfferItem.defaultProps = {
  className: '',
  index: 0,
};

const mapDispatchToProps = {
  savePreapprovalSelected,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(OfferItem));
