import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { store } from 'react-notifications-component';
import logger from 'helpers/logger';
import { setAccountRep } from '../redux/berlinActions';
import Auth from '../Auth/authHOC';
import * as Crypto from '../CryptoManager/CryptoManager';
import * as CryptoRequests from '../CryptoManager/RequestsCryptoManager';

import text from './text';
import { isInvalidFormField } from '../../../helpers/FormUtils';
import { EVENTS, Clevertap } from '../../../helpers/Clevertap';
import BerlinService from '../service';
import BottomNavigation from '../BottomNavigation';
import BankInformation from './BankInformation';
import PasswordCheck from './PasswordCheck';
import CdaKeypad from './CdaKeypad';

export const AccountInformation = (props) => {
  const SCREENS = {
    ACCOUNT: 'ACCOUNT',
    PASSWORD: 'PASSWORD',
    CREATE_CDA: 'CREATE_CDA',
    CONFIRM_CDA: 'CONFIRM_CDA',
  };
  
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [screen, setScreen] = useState(SCREENS.ACCOUNT);
  const [password, setPassword] = useState('');
  const [changeCdaPath, setChangeCdaPath] = useState(false);
  const [value, setValue] = useState([]);
  const [waiting, setWaiting] = useState(false);
  const [cdaError, setError] = useState(null);

  const enableContinueButton = () => {
    let continueEnabled = false;
    if (isInvalidFormField(password)) {
      continueEnabled = true;
    }
    setButtonDisabled(continueEnabled);
  };

  useEffect(() => {
    enableContinueButton();
    if (value.length !== 0) {
      setError(null);
    }
    if (value.length === 6) {
      setWaiting(true);
      switch (screen) {
        case SCREENS.CREATE_CDA:
          createCdaPin();
          return;
        case SCREENS.CONFIRM_CDA:
          confirmCdaPin();
          return;
        default:
          return;
      }
    }
  }, [value, password]);

  useEffect(() => {
    if (props.merchant) {
      BerlinService.accountInformationV2(props.merchant.info.id)
        .then((response) => {
          props.setAccountRep(response.data.message);
        })
        .catch(() => {
          console.log('error status');
        });
    }
  }, [props.merchant]);

  useEffect(() => {
    const {user, merchantInfo} = props;
    if (merchantInfo.proxy_id && user) {
      Clevertap.profile(user);
    }
  }, [props.user, props.merchantInfo]);

  const resetValue = () => {
    setWaiting(false);
    setValue([]);
  };

  const createCdaPin = async () => {
    try {
      await Crypto.init();
      const serverKey = await CryptoRequests.GetServerKey(
        props.account.account_holder_id,
        Crypto.keys().publicKey.base64,
        Crypto.keys().iv.hex,
      );
      await Crypto.setServerPublicKey(serverKey);
      var enc = new TextEncoder();
      const encryptedData = await Crypto.encrypt(enc.encode( value.join('')));
      const body = {
        type: props.account.security_type || 'CDA',
        pin: encryptedData,
        user_id: props.account.account_holder_id,
        agent: 'Mozilla',
      };
      await BerlinService.createCdaPin(body);
      resetValue();
      setScreen(SCREENS.CONFIRM_CDA);
    } catch (err) {
      setError(err.response.data.message);
      resetValue();
      logger.info(err);
    }
  };

  const confirmCdaPin = async () => {
    try {
      await Crypto.init();
      const serverKey = await CryptoRequests.GetServerKey(
        props.account.account_holder_id,
        Crypto.keys().publicKey.base64,
        Crypto.keys().iv.hex,
      );
      await Crypto.setServerPublicKey(serverKey);
      var enc = new TextEncoder();
      const encryptedData = await Crypto.encrypt(enc.encode( value.join('')));
      const body = {
        type: props.account.security_type || 'CDA',
        pin: encryptedData,
        user_id: props.account.account_holder_id,
        agent: 'Mozilla',
      };
      await BerlinService.confirmCdaPin(body);
      if (changeCdaPath) {
        store.addNotification({
          title: text.recoverCdaSuccess,
          message: ' ',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            showIcon: true,
            duration: 0,
          },
        });
        Clevertap.event(
          EVENTS.BERLIN.MY_ACCOUNT.EVENT, 
          EVENTS.BERLIN.MY_ACCOUNT.ACTIONS.CHANGE_CDA_CHANGED,
          );
        resetValue();
        setScreen(SCREENS.ACCOUNT);
        return;
      }
      resetValue();
      setScreen(SCREENS.ACCOUNT);
    } catch (err) {
      setError(err.response.data.message);
      resetValue();
      logger.info(err);
    }
  };

  const setFromCDAScreen = (screen) => {
    setValue([]);
    setScreen(screen);
  };

  const renderScreen = (screen) => {
    switch (screen) {
      case SCREENS.ACCOUNT:
      default:
        return (
          <BankInformation
            account_holder_representation={
              props.account.account_holder_representation
            }
            interbank_account={props.account.interbank_account}
            lastLogin={props.lastLogin}
            setError={setError}
            setChangeCdaPath={setChangeCdaPath}
            setScreen={setScreen}
            SCREENS={SCREENS}
          />
        );

      case SCREENS.PASSWORD:
        return (
          <PasswordCheck
            email={props.user.email}
            password={password}
            buttonDisabled={buttonDisabled}
            changeCdaPath={changeCdaPath}
            setScreen={setScreen}
            setPassword={setPassword}
            SCREENS={SCREENS}
          />
        );

      case SCREENS.CREATE_CDA:
      case SCREENS.CONFIRM_CDA:
        return (
          <CdaKeypad
            waiting={waiting}
            screen={screen}
            cdaError={cdaError}
            value={value}
            setScreen={setFromCDAScreen}
            setValue={setValue}
            SCREENS={SCREENS}
          />
        );
    }
  };

  return (
      <BottomNavigation>
        {props && props.account && renderScreen(screen)}
      </BottomNavigation>
   
  );
};

AccountInformation.propTypes = {
  user: PropTypes.object,
  account: PropTypes.object.isRequired,
  lastLogin: PropTypes.string.isRequired,
  SCREENS: PropTypes.object,
  setActiveScreen: PropTypes.func,
};

AccountInformation.default = {
  user: {},
  account: {},
  lastLogin: '',
  SCREENS: {},
  setActiveScreen: () => {},
};

const mapStateToProps = (state) => {
  return {
    account: state.berlin.account_representation,
    lastLogin: state.berlin.lastLogin,
    user: state.user,
    merchant: state.merchant,
    merchantInfo: state.merchant.info,
  };
};

const mapDispatchToProps = {
  setAccountRep,
};

export default Auth(connect(mapStateToProps, mapDispatchToProps)(AccountInformation));
