import BerlinService from '../service';

export const GetServerKey = async (account_holder_id, public_key, iv) => {
  const clip_public_key = await BerlinService.postAccountHandShake({
    account_holder_id,
    public_key,
    iv,
  })
    .then((response) => {
      return response.data.message.clip_public_key;
    })
    .catch((error) => {
      console.log('Handshake Error');
    });
  return clip_public_key;
};

export const GetSwapServerKey = async (publicKey, iv) => {
  const swap_public_key = await BerlinService.swapCardHandShake({
    publicKey,
    iv,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log('Handshake Error');
    });
  return swap_public_key;
};
